<div class="full-w" fxLayout="column" fxLayoutAlign="center center" *ngIf="checkComponent()">

  <div class="documentHintBoxFlex">
    <div class="documentHintBox" *ngFor="let item of structureOfNecessaryDocuments() | keyvalue">

      <h3>{{'practice_workflow_manager.' + item.key | translate}}</h3>
      <p>
        {{'DOCUMENTMANAGER.totaleFileCaricati' | translate}}: <strong>{{item.value.total}}</strong><br>
        {{'DOCUMENTMANAGER.documentiMinimiRichiesti' | translate}}: <strong>{{item.value.min}}</strong><br>
        {{'DOCUMENTMANAGER.documentiMassimiRichiesti' | translate}}: <strong>{{item.value.max ? item.value.max : '-' }}</strong><br>
        {{'DOCUMENTMANAGER.statoDelCaricamento' | translate}}: <mat-icon class="material-icons"
          [ngClass]="getColor(item.value.status)">{{getIcon(item.value.status)}}</mat-icon>
      </p>

    </div>
  </div>

  <div fxLayout="row">

    <div style="margin:5px; width: 100%;">
      <div class="photo-block">
        <div class="photo-button-block">
          <button class="my-bounce btn btn-primary fx-button aln" (click)="openUploadDialog()">
            <mat-icon class="material-icons" style="color:#293683;">upload</mat-icon><span
              style="color:#293683;">{{'LABEL.Carica' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
    <br />
  </div>

</div>