

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  ss:any;
  sended: boolean = false;
  certurl: SafeResourceUrl;
  title = "Help Desk";
  subTitle = "EDAC - Help Desk";
  constructor(public navService: NavService, public translate: TranslatorService) { }


  ngDoCheck() {

    if (this.sended == false) {
      var frame: HTMLIFrameElement = document.getElementById('TicketsIframe') as HTMLIFrameElement;
      if (frame != null) {
        this.sended = true;
        frame.contentWindow?.postMessage('sstorage', this.ss);
        this.certurl = environment.baseUrlTicket;
      }
    }

  }

  loadLabels(){
    this.translate.get('LABEL.TICKET').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.ss = window.sessionStorage;
    console.log('send message to iframe %o',this.ss);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
    ];
    this.navService.setActions(actions);
  }

}