import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  constructor(public libService: LibService) { }

  getAllChannels(): Observable<any> {
    return of([
      {name: 'telefono'},
      {name: 'mail'},
      {name: 'web'},
      {name: 'social'},
      {name: 'point'},
      {name: 'ricerca_attiva'},
      {name: 'evento'},
      {name: 'telefono_web'},
      {name: 'telefono_google_ads'},
    ])
  }
}

