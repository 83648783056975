import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, FormGroup } from '@angular/forms';

import { ChangeSalesManagerModal } from '../modals/change-sales-manager-modal/change-sales-manager-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestService } from '../../services/request.service';
import { PracticeService } from '../../services/practice.service';
import { LibService } from '../../services/libService';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-practice-change-sales-manager',
  templateUrl: './practice-change-sales-manager.component.html',
  styleUrls: ['./practice-change-sales-manager.component.scss']
})
export class PracticeChangeSalesManagerComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() components: any;
  @Input() respComm: any = [];

  componentName: any = 'practice-change-sales-manager'

  constructor(
    private dialog: MatDialog,
    private requestService: RequestService,
    private libService: LibService,
    private practiceService: PracticeService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges practice change sales manager')
  }

  ngOnInit(): void {
    console.log('ngOnInit practice change sales manager')
  }

  checkComponentFast(componentName: any = null) {
    let key = componentName || this.componentName
    let status = this.components?.codes?.includes(key)
    return status
  }

  openChangeSalesManagerEdit() {

    let dataStructure: any = {
      respComm: this.respComm,
    }

    const dialogRef = this.dialog.open(ChangeSalesManagerModal, {
      width: '400px',
      data: dataStructure,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (![null, undefined].includes(result)) {

        let practiceId = this.form?.get('PracticeId')?.value
        let responsabileCommerciale = result.responsabileCommerciale || {}
        this.libService.lockPage('');

        this.practiceService.changeSalesManager(practiceId, responsabileCommerciale).subscribe((res: any) => {
          let currentSalesManager = this.respComm.find((item: any) => item.id === res.id)
          this.form.controls['responsabileCommerciale']?.patchValue(currentSalesManager)
          this.libService.unlockPage();
        })

      }

    });
  }

}
