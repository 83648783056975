<mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="changeTab($event)">

  <mat-tab>

    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">maps_home_work</mat-icon>
      <span class="ml-1">{{ 'LABEL.LOCALIZATION' | translate }}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <app-crm-form-localization [form]="formSubject" [toDisable]="toDisable" [isNew]="isNew" [mainForm]="mainForm"
      [type]="type" [subjectAttributes]="subjectAttributes"></app-crm-form-localization>
  </mat-tab>

  <mat-tab *ngIf="checkSection('subjectForm')">
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">person</mat-icon>
      <span class="ml-1">{{ 'LABEL.ANAGRAFICA' | translate }}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <fieldset ng-disabled="boolView">
      <app-crm-form-data *ngIf="components && formSubject" [subject]="subject" [components]="components"
        [form]="formSubject" [allTypes]="(allTypes$ | async)!" [types]="(types$ | async)!" [statuses]="statuses"
        [companies]="companies" [loggedUser]="loggedUser" [users]="oplist2" [disable]="toDisable" [isNew]="isNew"
        [globalType]="type" [subjectAttributes]="subjectAttributes"></app-crm-form-data>
    </fieldset>

  </mat-tab>

  <mat-tab *ngIf="checkSection('subjectMarketingForm')">
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">info</mat-icon>
      <span class="ml-1">{{ 'LABEL.MARKETING' | translate }}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <app-crm-form-marketing *ngIf="components && formSubject" [subject]="subject" [components]="components"
      [form]="formSubject" [subjectAttributes]="subjectAttributes"></app-crm-form-marketing>
  </mat-tab>

  <mat-tab [disabled]="isNew" *ngIf="checkSection('crmDocumentManager') && documentsSection">
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">folder</mat-icon>
      <span class="ml-1">{{ 'LABEL.Documenti' | translate }}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <app-crm-form-documents *ngIf="components" [components]="components" [form]="formSubject"
      [toDisable]="toDisable"></app-crm-form-documents>
  </mat-tab>

  <mat-tab *ngIf="!isNew && checkSection('questionnaires') && questionnairesSection">
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">list</mat-icon>
      <span class="ml-1">{{ 'LABEL.Questionari' | translate }}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <app-crm-form-survey [form]="formSubject" [toDisable]="toDisable"></app-crm-form-survey>
  </mat-tab>

  <mat-tab [disabled]="isNew" #tabRelations *ngIf="checkSection('subjectRelations') && subjectRelationsSection">
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">group</mat-icon>
      <span class="ml-1">{{ 'LABEL.Relazioni' | translate }}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <div *ngIf="tabRelations.isActive">

      <app-crm-form-relations [form]="formSubject" [toDisable]="toDisable"
        [loggedUser]="loggedUser"></app-crm-form-relations>

    </div>
  </mat-tab>

  <mat-tab [disabled]="isNew" *ngIf="checkSection('subjectActivities') && subjectActivitiesSection">

    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">settings</mat-icon>
      <span class="ml-1">{{ 'LABEL.ACTIVITIES' | translate }}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <div class="full-w">
      <app-activities *ngIf="subject !== null" [subject]="subject" [isInput]="true"
        [toDisable]="disableActivity"></app-activities>
    </div>
  </mat-tab>

  <mat-tab [disabled]="isNew" #tabOrders *ngIf="checkSection('subjectPractices') && subjectPracticesSection">

    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">description</mat-icon>
      <span class="ml-1">{{ 'LABEL.PRACTICES' | translate }}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <div class="full-w" *ngIf="tabOrders.isActive">
      <app-crm-form-practices *ngIf="(subject !== null)" [subject]="subject"
        [form]="formSubject"></app-crm-form-practices>
    </div>
  </mat-tab>

</mat-tab-group>