<h3 class="title">{{ 'MARKETINGNOTES.titleForm' | translate }}</h3>

<form [formGroup]="form" class="form-container" (ngSubmit)="update()">

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'MARKETINGNOTES.label' | translate }}</mat-label>
        <input matInput formControlName="label" (focusout)="setCode()">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'MARKETINGNOTES.code' | translate }}</mat-label>
        <input readonly matInput formControlName="code">
    </mat-form-field>

    <br>

    <div appearance="fill" class="form-subject">
        <mat-slide-toggle color="primary" formControlName="subject">{{ 'MARKETINGNOTES.forSubject' | translate }}</mat-slide-toggle>
    </div>

    <div appearance="fill" class="form-subject">
        <mat-slide-toggle color="primary" formControlName="request">{{ 'MARKETINGNOTES.forRequest' | translate }}</mat-slide-toggle>
    </div>

    <br>


    <div appearance="fill" class="form-subject">
        <mat-slide-toggle color="primary" formControlName="disabled">{{ 'MARKETINGNOTES.disabled' | translate }}</mat-slide-toggle>
    </div>




    <div class="cardBox form-subject">

        <mat-card class="cardItem">
            <mat-card-header>
                <mat-card-title>{{ 'MARKETINGNOTES.translations' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>

                <ng-container formArrayName="translations">

                    <div *ngFor="let translation of translations.controls; let p = index">
                        <div [formGroup]="translation" class="form-subject languageCodeBox">

                            <mat-form-field appearance="fill" class="languageCodeItem">
                                <mat-label>{{ 'MARKETINGNOTES.languageCode' | translate }}</mat-label>
                                <mat-select formControlName="languageCode" disabled="true">
                                    <mat-option *ngFor="let languageCode of languageCodeList"
                                        [value]="languageCode.code">{{languageCode.label}}
                                        ({{languageCode.code}})</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="languageCodeItem">
                                <mat-label>{{ 'MARKETINGNOTES.translationText' | translate }}</mat-label>
                                <textarea matInput formControlName="text"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </ng-container>


            </mat-card-content>
        </mat-card>
    </div>

</form>





<div class="buttons-right  my-2">

    <button style="background-color:transparent;" [matTooltip]="translate.instant('MARKETINGNOTES.back')" type="button" class="my-bounce btn btn-primary mx-2"
        (click)="back()">
        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
    </button>

    <button style="background-color:transparent;" [matTooltip]="translate.instant('MARKETINGNOTES.save')" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()" [disabled]="!form.valid">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>

</div>