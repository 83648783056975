import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Document } from 'src/app/models/document';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-crm-form-documents',
  templateUrl: './crm-form-documents.component.html',
  styleUrls: ['./crm-form-documents.component.scss']
})
export class CrmFormDocumentsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() toDisable: boolean;
  @Input() components: any;
  documents: Document[];

  public webcamImage: WebcamImage;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public deviceId: string;
  public multipleWebcamsAvailable = false;

  public errors: WebcamInitError[] = [];
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };

  public itemId: number;
  public itemType: string = 'subjectId';

  constructor(
    public dialog: MatDialog,
    private documentTypeService: DocumentTypeService,
    private subjectService: SubjectService,
  ) { }

  ngOnInit(): void {
    this.itemId = this.form?.get('id')?.value
    this.documents = this.form?.get('documents')?.value

    this.form.get('documents')?.valueChanges.subscribe(documents => {
      this.documents = documents
    })
    this.form.get('id')?.valueChanges.subscribe(id => {
      this.itemId = id
    })

    this.form.get('type')?.valueChanges.subscribe(type => {
      this.itemType = 'subjectId'
    })
  }

  checkComponent(code: any) {
    let currentComponent: any = {}
    if (this.components) currentComponent = this.components.find((component: any) => component.componentCode == code)
    return currentComponent.componentCode == code
  }

  getDocumentTypes() {

    return this.documentTypeService.getAllGeneric()/* .pipe(

      map(documentTypes => {
        return documentTypes.push(this.form.get('status')?.value.documentTypes)
      })
    ) */
  }
}
