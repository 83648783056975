<div class="search-wrapper mat-elevation-z2">
  <div class="search" fxLayout="row" fxLayoutAlign="start ">

      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text"
      placeholder="{{ 'LABEL.SEARCH' | translate }}"
      [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="fulltextSearch($event)"
      [formControl]="myControl" matInput
      [matAutocomplete]="auto"
      >
      <!-- <div *ngIf="(dropdown)"> -->
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)" >
          <mat-option  *ngFor="let option of options " [value]="option">
            <span *ngIf="!(option.name == null) && !(option.surname == null)">{{option.name + ' ' + option.surname}}</span>
            <span *ngIf="!(option.name == null) && (option.surname == null)">{{option.name}}</span>
          </mat-option>
        </mat-autocomplete>
      <!-- </div> -->
      <button style="background-color:transparent;" matTooltip="Clear" type="button" class="btn" (click)="clear()">
        <mat-icon class="material-icons" style="color:#293683;">clear</mat-icon><!--  btn-primary mx-2 -->
      </button>

  </div>
</div>
