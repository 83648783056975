import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService {

  constructor(
    public LibService: LibService,
    public AuthService: AuthService,
    public userSessionService: UserSessionService,
    public guardService: GuardService
  ) {
    super(LibService, AuthService)
    this.baseApi = environment.rootApiFoundation
    this.model = 'countries'
  }

  determinesConfiguration(isoCode: any, attributes: any = {}): Observable<any> {
    return this.libService.postDataWithAccessToken<any>(this.tokenId, attributes, this.baseApi, `${this.model}/${isoCode}/determinesConfiguration`);
  }

  getCompaniesbyBusinessLineId(businessLineId: any,): Observable<any> {
    return this.libService.postDataWithAccessToken<any>(this.tokenId, {businessLineId:businessLineId}, this.baseApi, `${this.model}/getCompaniesbyBusinessLineId`);
  }

  getBusinessLines(countryCode: any,): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/${countryCode}/getBusinessLines`);
  }

  getBusinessLinesAndCategories(countryCode: any,): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/${countryCode}/getBusinessLinesAndCategories`);
  }

}