<div class="container-fluid my-container">
	<div class="full-w">
		<form [formGroup]="formTargets">
			<br>
			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.PERC_REQ' | translate}}</mat-label>
				<input type="number" matNativeControl formControlName="percStep">
			</mat-form-field>
			<br>
			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.PERC_INC_CORE_SOCIETY' | translate}}</mat-label>
				<input type="number" matNativeControl formControlName="percIncrement">
			</mat-form-field>
			<br>
			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.PERC_INC_FR_SOCIETY' | translate}}</mat-label>
				<input type="number" matNativeControl formControlName="percIncrementFranchising">
			</mat-form-field>
		</form>

		<div class="buttons-right my-2">
			<button style="background-color:transparent;" [disabled]="!formTargets.valid || formTargets.pristine"
				matTooltip="{{ 'LABEL.SAVE' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
				(click)="update()">
				<mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
			</button>
		</div>
	</div>
</div>