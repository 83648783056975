import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedUserService {

  constructor() { }

  getProfiles(loggedUser: any): Array<any>{
    let profiles = [];
    console.log('UTENTE LOGGATO SERVIZIO --> ', loggedUser);
    profiles = loggedUser.UsersProfilesRel.map((a: any) => a.name);
    return profiles;
  }

  getCompanies(loggedUser: any) {

  }
}
