<form [formGroup]="form" class="form-container" (ngSubmit)="update()">

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'EVENTS.eventType' | translate }}</mat-label>
        <mat-select matNativeControl formControlName="EventTypeId">
            <mat-option *ngFor="let eventType of eventTypes" [value]="eventType?.id">
                {{ eventType.label | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'EVENTS.label' | translate }}</mat-label>
        <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'EVENTS.description' | translate }}</mat-label>
        <textarea matInput rows="15" formControlName="description"
            placeholder="{{ 'EVENTS.description' | translate }}"></textarea>
    </mat-form-field>

    <!--
     <div [innerHTML]="form?.get('description')?.value"></div>
    -->

    <mat-card class="example-card" appearance="outlined" class="form-subject">
        <mat-card-header>
            <mat-card-title>Link Evento</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <!-- Paragrafo di spiegazione -->
            <p class="event-description">
                Questo link permette di <strong>invitare gli utenti</strong> a partecipare all'evento direttamente
                nell'app di Acrobatica. Puoi copiare il link e condividerlo, oppure scaricare il QR Code
                che gli utenti possono scansionare per accedere.
            </p>
            <!-- Campo con il link generato -->
            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>Link</mat-label>
                <input matInput #eventLink [value]="eventUrl" readonly>
                <button mat-icon-button matSuffix (click)="copyLink(eventLink)" type="button">
                    <mat-icon>content_copy</mat-icon>
                </button>
            </mat-form-field>

            <!-- QR Code -->
            <div class="qr-container">
                <canvas #qrCodeCanvas></canvas>
                <button mat-raised-button color="primary" (click)="downloadQrCode()" class="download-btn" type="button">
                    <mat-icon>cloud_download</mat-icon>
                    <span>Scarica QR Code</span>
                </button>
            </div>
        </mat-card-content>
    </mat-card>


    <br>

    <!-- Toggle per utenti interni -->
    <div appearance="fill" class="form-subject">
        <mat-slide-toggle color="primary" formControlName="everyUserCanParticipate"
            (change)="onToggleChange('internal', $event.checked)">
            {{ 'EVENTS.internalUserToggle' | translate }}
        </mat-slide-toggle>
    </div>

    <!-- Sezione di selezione utenti interni -->
    <div *ngIf="!internalToggle" class="user-selection-container">
        <h3>{{ 'EVENTS.internalUserSelectionTitle' | translate }}</h3>
        <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'EVENTS.internalUserPlaceholder' | translate }}</mat-label>
            <mat-select formControlName="invitedInternalUsers" multiple>
                <input matInput [placeholder]="'EVENTS.internalUserPlaceholder' | translate"
                    [formControl]="internalUserSearchControl" class="padd-input">
                <mat-option *ngFor="let user of filteredInternalUsers" [value]="user.id">
                    {{ user.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Toggle per utenti esterni -->
    <div appearance="fill" class="form-subject toggle-ext">
        <mat-slide-toggle color="primary" formControlName="everyExternalUserCanParticipate"
            (change)="onToggleChange('external', $event.checked)">
            {{ 'EVENTS.externalUserToggle' | translate }}
        </mat-slide-toggle>
    </div>

    <!-- Sezione di selezione utenti esterni -->
    <div *ngIf="!externalToggle" class="user-selection-container">
        <h3>{{ 'EVENTS.externalUserSelectionTitle' | translate }}</h3>
        <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'EVENTS.externalUserPlaceholder' | translate }}</mat-label>
            <mat-select formControlName="invitedExternalUsers" multiple>
                <input matInput [placeholder]="'EVENTS.externalUserPlaceholder' | translate"
                    [formControl]="externalUserSearchControl" class="padd-input">
                <mat-option *ngFor="let user of filteredExternalUsers" [value]="user.id">
                    {{ user.name }} {{ user.surname }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <br>

    <mat-card class="example-card" appearance="outlined" class="form-subject">
        <mat-card-header>
            <mat-card-title>{{ 'EVENTS.eventDates' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.startTime' | translate }}</mat-label>
                <input matInput type="time" formControlName="startTime" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.endDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" formControlName="endDate" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.endTime' | translate }}</mat-label>
                <input matInput type="time" formControlName="endTime" />
            </mat-form-field>
        </mat-card-content>
    </mat-card>

    <br>

    <mat-card class="example-card" appearance="outlined" class="form-subject">
        <mat-card-header>
            <mat-card-title>{{ 'EVENTS.visibilityDates' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDateVisibility" formControlName="startDateVisibility" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDateVisibility"></mat-datepicker-toggle>
                <mat-datepicker #startDateVisibility></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.startTime' | translate }}</mat-label>
                <input matInput type="time" formControlName="startTimeVisibility" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.endDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDateVisibility" formControlName="endDateVisibility" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDateVisibility"></mat-datepicker-toggle>
                <mat-datepicker #endDateVisibility></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.endTime' | translate }}</mat-label>
                <input matInput type="time" formControlName="endTimeVisibility" />
            </mat-form-field>
        </mat-card-content>
    </mat-card>

    <br>

    <mat-card class="example-card" appearance="outlined" class="form-subject">
        <mat-card-header>
            <mat-card-title>{{ 'EVENTS.registrationsDates' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDateRegistrations" formControlName="startDateRegistrations" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDateRegistrations"></mat-datepicker-toggle>
                <mat-datepicker #startDateRegistrations></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.startTime' | translate }}</mat-label>
                <input matInput type="time" formControlName="startTimeRegistrations" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.endDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDateRegistrations" formControlName="endDateRegistrations" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDateRegistrations"></mat-datepicker-toggle>
                <mat-datepicker #endDateRegistrations></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'EVENTS.endTime' | translate }}</mat-label>
                <input matInput type="time" formControlName="endTimeRegistrations" />
            </mat-form-field>
        </mat-card-content>
    </mat-card>

    <br>

</form>

<div class="buttons-right my-2">
    <button style="background-color:transparent;" [matTooltip]="translate.instant('EVENTS.save')" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()" [disabled]="!form.valid">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>
</div>