import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private isSidebarCollapsed: boolean = false;

  constructor() { }

  public setCollapsed(value: boolean): void {
    this.isSidebarCollapsed = value;
  }

  public getCollapsed(): boolean {
    return this.isSidebarCollapsed;
  }
}
