<h1 mat-dialog-title>{{ titleLabel | translate }}</h1>

<mat-dialog-content class="mat-typography">

  <h3 class="centerTitle">Selezionare un Opzione:</h3>

  <div class="buttonBox">
    <mat-button-toggle-group (change)="changeInsertMethod($event)" [(ngModel)]="insertMethod" name="fontStyle"
      aria-label="Font Style">

      <mat-button-toggle value="search">
        <mat-icon aria-hidden="false" aria-label="search building" fontIcon="search"></mat-icon>
        Cerca Edificio Esistente
      </mat-button-toggle>

      <mat-button-toggle value="insert">
        <mat-icon aria-hidden="false" aria-label="search building" fontIcon="add_business"></mat-icon>
        Inserisci Nuovo Edificio
      </mat-button-toggle>

    </mat-button-toggle-group>

    <hr>
  </div>

  <!-- RICERCA -->
  <div *ngIf="insertMethod == 'search'">
    <app-fulltextsearch2 [service]="buildingService" searchType="Edificio"
      (selected)="selBuilding($event)"></app-fulltextsearch2>
  </div>

  <!-- INSERIMENTO -->
  <div *ngIf="showForm()">
    <app-building-form [subjectBuildingRelationTypes]="subjectBuildingRelationTypes" [form]="formBuilding" [companies]="companies" *ngIf="formBuilding && companies"></app-building-form>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">{{ cancelButtonLabel | translate }}</button>
  <button [disabled]="!formBuilding?.valid" mat-button [mat-dialog-close]="getStructure()">{{ confirmButtonLabel |
    translate }}</button>
</mat-dialog-actions>