import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
  ) { }

  createBranch(branch: any): Observable<any> {
    return this.libService.postData<any>(branch, this.baseApi, 'branches');
  }

  updateBranch(branch: any): Observable<any> {
    return this.libService.putData<any>(branch, this.baseApi, `branches/${branch.id.toString()}`);
  }

  deleteBranch(branch: any): Observable<any> {
    return this.libService.deleteData<any>(branch, this.baseApi, `branches/${branch.id.toString()}`);
  }

  getAllBranches(): Observable<any> {
    //console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, 'branches/');
  }

  getBranc(id: string) {
    return this.libService.getData<any>(this.baseApi, `branches/${id.toString()}`);
  }

  getBranchesByLangCode(code:string){
    return this.libService.getData<any>(this.baseApi, `branches/langcode/${code.toString()}`);
  }

  getSocietiesAndCountries(businessLineIds:any){
    let attributes = {
      businessLineIds: businessLineIds
    }
    return this.libService.postData<any>(attributes, this.baseApi, `branches/getSocietiesAndCountries`);
  }
}