import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function BuildingValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        //console.log('----- BUILDING VALIDATOR for %o',control);
        const value = control.value;

        if (value && value.id > 0) {
            return null;
        }
        else
            return { 'buildingInvalid': true };

    }
}