<!--
    <div class="container-fluid my-container">
   

        <router-outlet></router-outlet>

   

</div>
-->


<div class="container-fluid my-container">
    <mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedTabIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
                <span class="ml-1">{{ 'LABEL.SUBJECTS' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <!-- <app-request-list></app-request-list> -->
                <app-subject *ngIf="selectedTabIndex === 0"></app-subject>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
                <span class="ml-1">{{ 'LABEL.REQUESTS' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
              <mat-tab-group class="wbck" animationDuration="300ms" #tabGroup ><!-- (selectedTabChange)="tabChanged($event)" -->

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
                            <span class="ml-1">{{ 'LABEL.REQUESTS' | translate }}</span>
                        </ng-template>
                        <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                    <app-request-list-new *ngIf="selectedTabIndex === 1"></app-request-list-new>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="my-bounce material-icons">report</mat-icon>
                            <span class="ml-1">{{ 'LABEL.SOLICITS' | translate }}</span>
                        </ng-template>
                        <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                  <app-solicit-list *ngIf="selectedTabIndex === 1"></app-solicit-list>
                        </div>
                    </mat-tab>


                </mat-tab-group>
                <!-- <app-request-list></app-request-list> -->

            </div>
        </mat-tab>

        <mat-tab>

            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">settings</mat-icon>
                <span class="ml-1">{{ 'LABEL.ACTIVITIES' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

              <app-activities *ngIf="selectedTabIndex === 2"></app-activities>

            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">report</mat-icon>
                <span class="ml-1">{{ 'LABEL.SEGNALAZIONI' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <app-rapport-section *ngIf="selectedTabIndex === 3"></app-rapport-section>
            </div>
        </mat-tab>

    </mat-tab-group>

</div>
