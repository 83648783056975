import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddEvent, CancelEvent, EditEvent, RemoveEvent, SaveEvent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { combineLatest, switchMap } from 'rxjs';
import { Transaction } from 'src/app/models/transaction';
import { LibService } from 'src/app/services/libService';
import { SubjectService } from 'src/app/services/subject.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { process } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DatatableAction } from 'src/app/shared/data-table/data-table.component';
import { BehaviorSubject, of } from 'rxjs';
import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { DocumentsGridComponent } from 'src/app/shared/documents-grid/documents-grid.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component'
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { MatDialog } from '@angular/material/dialog';
import { NgZone, EventEmitter, Input, OnChanges, AfterViewInit, Output, SimpleChanges, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalFormComponent } from 'src/app//forms/modal-form/modal-form.component';

export class DatatableColumn<T> {
  name: string;
  title: Observable<string>;
  type?: 'text' | 'link' | 'icon' | 'templateRef' = 'text';
  flex?: string;
  sticky?: boolean;
  color?: (element: T) => 'primary' | 'warn' | 'error';
  cssClass?: (element: T) => string[];
  sortDisabled?: boolean;
  routerLink?: (element: T) => string[];
  value(element: T): any {
    return null;
  }
  templateRef?: (element: T) => Observable<TemplateRef<any>>;
  tooltip?: (element: T) => Observable<String>;

  constructor(column: Partial<DatatableColumn<T>>) {
    Object.assign(this, column);
  }
}

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  search: any;

  @Input() itemId: any = null;

  formTransaction: UntypedFormGroup;
  formDates: UntypedFormGroup;
  formAll: UntypedFormGroup;
  transaction: Transaction;
  transactions: Transaction[];
  allTransactions: Transaction[];
  subject: any;
  whileEditing: boolean = false;

  transactionCommit$: any;
  transactions$ = this.transactionService.allWithPagination();

  constructor(private libService: LibService,
    private transactionService: TransactionsService,
    public subjectService: SubjectService,
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private documentTypeService: DocumentTypeService,
    private dialog: MatDialog,
    private router: Router,
  ) {

    this.formTransaction = this.fb.group({
      id: [null],
      orderNumberEA: [null, Validators.required],
      damageYear: [null],
      complainant: [null],
      streetName: [null],
      streetNumber: [null],
      city: [null],
      province: [null],
      country: [null],
      zip: [null],
      refundDescription: [null],
      damageCauseDescription: [null],
      type: [null],
      typeDescription: [null],
      recognizedDamageValue: [null],
      paidFromEA: [null],
      deductiblePaid: [null],
      note: [null]
    });

    this.formDates = this.fb.group({
      agreementDate: [null],
      expiringPaymentDate: [null],
      accountingDate: [null]
    });

    this.formAll = this.fb.group({
      ...this.formTransaction.controls,
      ...this.formDates.controls
    })

  }

  private timeout?: number;


  dataSource: any;

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;

  filterOptions: any = {};
  currentPage = 0;
  companyIdsFilter: any = []
  responsiblesFilter: any = []
  idsFilter: any = []
  orderOptions: any = {}
  allGlobalDocumentTypes: any = []

  documentTypes: any = []

  test: any = []

  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

  actions: DatatableAction<any>[] = [
    {
      label: of(''),
      icon: item => {
        return 'edit'
      },
      onClick: item => this.openEditDialog(item),
      color: 'primary'
    },
    /*
    {
      label: of(''),
      icon: item => {
        return 'upload_file'
      },
      onClick: item => this.openUploadDialog('new', item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'folder'
      },
      onClick: item => this.viewDocuments(item?.documents),
      color: 'primary'
    },
    */
    /*
    {
      label: of(''),
      icon: subject => {
        return 'delete'
      }, // 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    }
    */
  ];

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }

  loadDataSource(reset: boolean = false) {
    console.log('loadDataSource')
    this.libService.lockPage('');

    /*
      if (reset) {
        this.resetOrder()
        this.resetPagination()
        this.filterOptions.ids = []
      }
        */

      if(this.itemId){
        this.filterOptions.ids = [this.itemId]
      }

    this.transactionService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      this.transactions = responseData.items;
      this.allTransactions = responseData.items;
      this.totalRows = responseData.count;
      this.libService.resetLockPage();
    }, (err: any) => {
      // this.lockrequest = false;
      this.libService.resetLockPage();
      //this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });

  }

  goTo(e: any) {
    this.router.navigate(['request/' + e.id]);
  }

  public openEditDialog(item: any) {

    this.dialog.open(ModalFormComponent, {
      data: {
        type: 'transactions',
        item: item,
      },
      width: '70%',
      scrollStrategy: new NoopScrollStrategy(),
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        if (res) {
          this.loadDataSource()
        }
      })
  }

  public openUploadDialog(mode: 'new' | 'current', item: any) {

    if (mode === 'new') {

      let docTypes = item.status?.allowedDocumentTypes.filter((a: any) => a.code != 'SINISTRO' || a.code != 'TRANSITIVO');

      this.documentTypes = [...this.allGlobalDocumentTypes, ...docTypes]

      this.dialog.open(FileUploadModalComponent, {
        data: {
          inputMode: 'legals',
          toEntityId: item.id,
          documentTypes: of(this.documentTypes)
        },
        scrollStrategy: new NoopScrollStrategy(),
      })
        .afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ', res);
          //this.subject.documents.push(res)
          if (res) {
            // this.form?.get('documents')?.setValue([...this.form?.get('documents')?.value, res.document])
            // this.updateStatus.emit(false);
          }
        })
    }

  }

  public viewDocuments(documents: any) {
    console.log("DOCUMENTI --> ", documents)
    if (documents.length > 0) {
      let docs = this.dialog.open(DocumentsGridComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });
      let instance = docs.componentInstance;
      instance.documents = documents;
    } else {
      this.dialog.open(SimpleDialogComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });

    }
    // console.log("DOCUMENTS INSIDE -->", );
  }

  displayedColumns: string[] = [
    'id',
    'orderNumberEA',
    'damageYear',
    'streetName',
    'streetNumber',
    'city',
    'province',
    'country',
    'zip',
    'complainant',
    'refundDescription',
    'damageCauseDescription',
    'type',
    'typeDescription',
    'agreementDate',
    'recognizedDamageValue',
    'expiringPaymentDate',
    'paidFromEA',
    'deductiblePaid',
    'accountingDate',
    'note'
  ];

  columns: DatatableColumn<any>[] = [
    {
      name: "id",
      flex: '7',
      title: this.translate.get('LABEL.ID'),
      cssClass: item => ['column-padding'],
      value: item => item.id
    },
    {
      name: "orderNumberEA",
      title: this.translate.get('COMPLAINTS.Pratica EDAC'),
      cssClass: item => ['column-padding'],
      value: item => item.orderNumberEA as string,
    },
    {
      name: "damageYear",
      title: this.translate.get('COMPLAINTS.Anno Del Danno'),
      cssClass: item => ['column-padding'],
      value: item => item.damageYear as string,
    },
    {
      name: "streetName",
      title: this.translate.get('COMPLAINTS.Via'),
      cssClass: item => ['column-padding'],
      value: item => item.streetName as string,
    },
    {
      name: "streetNumber",
      title: this.translate.get('COMPLAINTS.Numero'),
      cssClass: item => ['column-padding'],
      value: item => item.streetNumber as string,
    },
    {
      name: "city",
      title: this.translate.get('COMPLAINTS.Citta'),
      cssClass: item => ['column-padding'],
      value: item => item.city as string,
    },
    {
      name: "province",
      title: this.translate.get('COMPLAINTS.Provincia'),
      cssClass: item => ['column-padding'],
      value: item => item.province as string,
    },
    {
      name: "country",
      title: this.translate.get('COMPLAINTS.Nazione'),
      cssClass: item => ['column-padding'],
      value: item => item.country as string,
    },
    {
      name: "zip",
      title: this.translate.get('COMPLAINTS.CAP'),
      cssClass: item => ['column-padding'],
      value: item => item.zip as string,
    },
    {
      name: "complainant",
      title: this.translate.get('COMPLAINTS.Reclamante'),
      cssClass: item => ['column-padding'],
      value: item => `${item?.complainant?.name ? item?.complainant?.name : ''} ${item?.complainant?.surname ? item?.complainant?.surname : ''}`
    },
    {
      name: "refundDescription",
      title: this.translate.get('COMPLAINTS.Descrizione Rimborso'),
      cssClass: item => ['column-padding'],
      value: item => item.refundDescription as string,
    },
    {
      name: "damageCauseDescription",
      title: this.translate.get('COMPLAINTS.Descrizione Causa Del Danno'),
      cssClass: item => ['column-padding'],
      value: item => item.damageCauseDescription as string,
    },
    {
      name: "type",
      title: this.translate.get('COMPLAINTS.Tipo'),
      cssClass: item => ['column-padding'],
      value: item => item.type as string,
    },
    {
      name: "typeDescription",
      title: this.translate.get('COMPLAINTS.Descrizione Tipo'),
      cssClass: item => ['column-padding'],
      value: item => item.typeDescription as string,
    },
    {
      name: "agreementDate",
      title: this.translate.get('COMPLAINTS.Data Accordo'),
      cssClass: item => ['column-padding'],
      value: item => item.agreementDate as string,
    },
    {
      name: "recognizedDamageValue",
      title: this.translate.get('COMPLAINTS.Valore Del Danno Riconosciuto'),
      cssClass: item => ['column-padding'],
      value: item => item.recognizedDamageValue as string,
    },
    {
      name: "expiringPaymentDate",
      title: this.translate.get('COMPLAINTS.Data Scadenza Pagamento'),
      cssClass: item => ['column-padding'],
      value: item => item.expiringPaymentDate as string,
    },
    {
      name: "paidFromEA",
      title: this.translate.get('COMPLAINTS.Pagato Da Edac'),
      cssClass: item => ['column-padding'],
      value: item => item.paidFromEA as string,
    },
    {
      name: "deductiblePaid",
      title: this.translate.get('COMPLAINTS.Pagato Deducibile'),
      cssClass: item => ['column-padding'],
      value: item => item.deductiblePaid as string,
    },
    {
      name: "accountingDate",
      title: this.translate.get('COMPLAINTS.Data Acconto'),
      cssClass: item => ['column-padding'],
      value: item => item.accountingDate as string,
    },
    {
      name: "note",
      title: this.translate.get('COMPLAINTS.Note'),
      cssClass: item => ['column-padding'],
      value: item => item.note as string,
    },
  
  ]

  openEdit(complaintTypeSection: any){
    let queryParams: any = {
      type: 3,
      complaintTypeSection: complaintTypeSection
    }
    this.router.navigate(['request/0'], { queryParams: queryParams });
}

  ngOnInit(): void {

    if(this.itemId){
      this.filterOptions.ids = [this.itemId]
    }

    this.transactions$ = this.transactionService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize);


    combineLatest([this.transactions$, this.globalDocumentTypes$]).subscribe({
      next: ([trans, globalDocumentTypes]) => {
        console.log("TRANSACTIONS INIT --> ", this.transactions);
        this.transactions = trans.items;
      this.allTransactions = trans.items;
      this.totalRows = trans.count;
      this.allGlobalDocumentTypes = globalDocumentTypes;

      },
      error: (error) => {
        this.libService.showMessageError(error.message);
      }
    })
  }

  addHandler(e: AddEvent) {
    this.whileEditing = true;
    console.log("EVENTO AGGIUNGI --> ", e);
    this.formTransaction.reset;
    this.formDates.reset;
    this.formAll.reset();
    console.log("FORM ALL --> ", this.formAll);
    e.sender.addRow(this.formAll);
  }

  removeHandler(e: RemoveEvent) {
    let calls = this.transactionService.deleteTransaction(e.dataItem.id, e.dataItem).pipe(switchMap((val: any) => {
      return this.transactions$
    }));
    this.libService.lockPage('');
    calls.subscribe((val: any) => {
      this.transactions = val;
      this.libService.unlockPage();
    })
  }

  saveHandler(e: SaveEvent) {
    this.whileEditing = false;
    this.transaction = this.formAll.getRawValue();

    // this.complaint.creationDate = this.formDates.get('startDate')?.value;
    // this.complaint.closingDate = this.formDates.get('endDate')?.value;
    // this.complaint.deliberateDate = this.formDates.get('paymentDateFromEA')?.value;

    console.log("EVENTO DEL SALVATAGGIO --> ", e);
    console.log("TRANSACTION DA SALVARE A DB --> ", this.transaction);

    e.rowIndex == -1 ?
      this.transactionCommit$ = this.transactionService.createTransaction(this.transaction) :
      this.transactionCommit$ = this.transactionService.updateTransaction(this.transaction.id?.toString(), this.transaction);

    this.libService.lockPage(' ');
    let calls = this.transactionCommit$.pipe(switchMap((val: any) => {
      e.sender.closeRow(e.rowIndex);
      return this.transactions$
    }));

    calls.subscribe((val: any) => {
      this.transactions = val;
      console.log("TRANSACTIONS --> ", this.transactions)
      this.libService.unlockPage();
    })

    this.formTransaction.reset;
    this.formDates.reset;
  }

  cancelHandler(e: CancelEvent) {
    this.whileEditing = false;
    e.sender.closeRow(e.rowIndex);
  }

  editHandler(e: EditEvent) {
    this.whileEditing = true;
    // console.log("CONTROLLARE LA DATA FINE --> ", e.dataItem)
    for (const property in e.dataItem) {
      (property.toLowerCase().includes("date") && e.dataItem[property] !== null) ?
        e.dataItem[property] = new Date(e.dataItem[property]) : undefined;
    }
    this.formAll.patchValue(e.dataItem);
    console.log("FORM DI TUTTO --> ", this.formAll);
    e.sender.editRow(e.rowIndex, this.formAll);

  }

  setSubject(e: any) {
    console.log("SET SUBJECT --> ", e);
    this.subject = e;
  }

  setFormValue(e: any) {
    console.log("FORM VALUE --> ", e);
    this.formAll.patchValue({
      complainant: this.subject
    })
  }

  onFilter(): void {
    window.clearTimeout(this.timeout);

    if (!this.search || this.search.length == 0) {
      this.transactions = this.allTransactions
      this.filterOptions.search = null

    } else {
      this.filterOptions.search = this.search
    }

    if (this.search.length < 2 && this.search.length != 0) return

    this.timeout = window.setTimeout(() => this.loadDataSource(), 500);
  }

}
