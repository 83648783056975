<div >
  <script src="//ajax.googleapis.com/ajax/libs/angularjs/1.4.9/angular.min.js"></script>

  <form [formGroup]="formAddr">
    <mat-form-field appearance="fill" class="form-request" style="width: 40%;">
      <mat-label>{{ 'ADDRESS.ADDRESS' | translate }} :</mat-label>
        <input matInput type="text" id="autocomplete" [(ngModel)]="address" ng-app="MyApp"
                    ngx-google-places-autocomplete  formControlName="address"
                     placeholder="{{ 'LABEL.INSERISCI_INDIRIZZO' | translate }}" [disabled]="isWorking" (onAddressChange)="handleAddressChange($event)">
                    <!-- (ngModelChange)="onType($event)" (keydown.enter)="findAddress()" -->
    </mat-form-field>

    <button style="width: 18%; margin-left: 2%;  transform: translateY(-15%);" mat-stroked-button color="primary" (click)="validateAddress()">Convalida Indirizzo</button>

  <mat-accordion class="example-headers-align">
    <mat-expansion-panel style="width:60%; margin-left: 20%; " #accordion>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'ADDRESS.DETAIL' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <table>
        <tr>
          <td style="width: 95%">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>{{ 'ADDRESS.STREETNAME' | translate }}</mat-label>
              <input matInput type="text" formControlName="streetName" >
            </mat-form-field>
          </td>
          <td style="width: 5%">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>{{ 'ADDRESS.NUMBER' | translate }}</mat-label>
              <input matInput type="text" formControlName="streetNumber" >
            </mat-form-field>
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td style="width: 45%">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>{{ 'ADDRESS.CITY' | translate }}</mat-label>
              <input matInput type="text" formControlName="city" >
            </mat-form-field>

          </td>
          <td style="width: 45%">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>{{ 'ADDRESS.STATE' | translate }}</mat-label>
              <input matInput type="text" formControlName="country" >
            </mat-form-field>
          </td>
          <td style="width: 15%">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>{{ 'ADDRESS.ZIP' | translate }}</mat-label>
              <input matInput type="text" formControlName="zip" >
            </mat-form-field>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</form>
</div>
