import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, map, tap, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Company } from 'src/app/models/company';
import { Status } from 'src/app/models/status';
import { SubjectType } from 'src/app/models/subjectType';
import { User } from 'src/app/models/user';
import { WorkType } from 'src/app/models/workType';
import { BuildingService } from 'src/app/services/building.service';
import { ChannelService } from 'src/app/services/channel.service';
import { CompanyService } from 'src/app/services/companyService';
import { FTIndexAllService } from 'src/app/services/ftindex-all.service';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { WorkTypeService } from 'src/app/services/worktype.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { ItemCategoryService } from 'src/app/services/itemCategory.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { CountryService } from 'src/app/services/country.service';
import { LibService } from 'src/app/services/libService'
import { ContactReasonService } from 'src/app/services/contact-reason.service'
import { StateService } from 'src/app/services/state.service'
import { T } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-workflow-configurator',
  templateUrl: './workflow-configurator.component.html',
  styleUrls: ['./workflow-configurator.component.scss']
})
export class WorkflowConfiguratorComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() id: any;
  @Input() types: any
  @Input() origins: any;
  @Input() edit: boolean;
  @Input() isNew: any;

  states: any;


  statusTemporary: Status
  controlTypes = ['Privato', 'Amministratore'];
  hideLegalEntity: boolean = true;
  workTypes: any;
  wt: any;
  // companies$: any = this.itemCategoryService.index();
  businessLines: any = []

  contactChannels$ = this.channelService.getAllChannels();
  countries: any = [];
  societies: any = [];

  contactReasons$ = this.contactReasonService.getAll()


  companies: Company[]

  companies$ = this.companyService.indexWithBusinessLines().pipe(
    map((companies: any[]) => {
      return companies.sort((a, b) => a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      this.companies = [...new Set(companies)]
      this.companies.push({ id: 0, code: undefined, name: '' })
    })
  );

  workTypes$ = this.workTypeService.getAllWorkTypes()//.pipe(
  // map(workTypes => {
  //   return workTypes.filter(workType => workType.codLanguage?.toLocaleLowerCase() === this.form.get('building.company')?.value)//this.translate.currentLang
  // })
  //)

  constructor(
    public subjectService: SubjectService,
    public buildingService: BuildingService,
    private companyService: CompanyService,
    private workTypeService: WorkTypeService,
    private statusService: StatusService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private channelService: ChannelService,
    private workflowService: WorkflowService,
    private itemCategoryService: ItemCategoryService,
    private userSessionService: UserSessionService,
    private countryService: CountryService,
    private libService: LibService,
    private contactReasonService: ContactReasonService,
    private stateService: StateService
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes - workflow init')
    //console.log(changes)

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'form': {
            let form = changes[propName].currentValue
            let id = form.get('id')?.value
            let countryCode = form.get('countryCode')?.value
            let societyId = form.get('societyId')?.value
            if (id != null) {
              this.initCountries()
              //this.initSocieties(countryCode)
              this.initBusinessLines(countryCode)
              this.setEditabilityOfFields()
              this.initStates()
            } else {
              this.initCountries()
            }
          }
        }
      }
    }

  }

  initStates() {
    this.stateService.getStates().subscribe((response: any) => {
      this.states = response
    })
  }

  initCountries() {
    console.log('INIT COUNTRIES')
    this.countries = this.userSessionService.getCountries()
    if (this.countries.length == 1){
      this.form.get('countryCode')?.setValue(this.countries[0])
      this.initBusinessLines(this.countries[0])
    } 
  }

  initSocieties(countryCode: any) {
    console.log('INIT SOCIETIES')
    this.countryService.determinesConfiguration(countryCode).subscribe((response) => {
      response.forEach((configuration: any) => {

        let tmpSociety: any = { id: configuration.societyId, code: configuration.societyCode }
        let societyIds = this.societies.map((society: any) => society.id)

        if (!societyIds.includes(tmpSociety.id)) this.societies.push(tmpSociety)
      })
    });
  }

  initBusinessLines(countryCode: any) {
    console.log('INIT BUSINESS LINES')
    let currentBusinessLineId: any = this.form.get('businessLineId')?.value
    this.countryService.getBusinessLines(countryCode).subscribe((response) => {
      console.log(response)
      response.forEach((configuration: any) => {

        let tmpbusinessLine: any = { id: configuration.businessLineId, code: configuration.businessLineCode }
        let businessLineIds = this.businessLines.map((businessLine: any) => businessLine.id)

        if (!businessLineIds.includes(tmpbusinessLine.id)) this.businessLines.push(tmpbusinessLine)
      })
    this.initBusinessLineCode(currentBusinessLineId)
    });
  }

  initBusinessLineCode(businessLineId : any){
    let currentBusinessLineCode = this.businessLines.find((businessLine: any) => businessLine.id == businessLineId)
    this.form.get('businessLineCode')?.setValue(currentBusinessLineCode?.code)
  }


  ngOnInit(): void {


  }

  resetFormValues(items: any = []) {

    items.forEach((item: any) => {
      this.form.get(item.formField)?.setValue(null) // Reset valore form
      eval(`this.${item.selectCollection} = []`) // Reset valori select
    })

  }

  getSocieties(event: any) {
    console.log('getSocieties')
    this.libService.lockPage('');


    this.resetFormValues([
      { formField: 'societyId', selectCollection: 'societies' },
      { formField: 'businessLineId', selectCollection: 'businessLines' },
    ]) // Reset form values

    this.countryService.determinesConfiguration(event.value).subscribe((response) => {
      console.log(response)
      response.forEach((configuration: any) => {

        let tmpSociety: any = { id: configuration.societyId, code: configuration.societyCode }
        let societyIds = this.societies.map((society: any) => society.id)

        if (!societyIds.includes(tmpSociety.id)) this.societies.push(tmpSociety)
      })

      this.libService.unlockPage();
    });
  }

  getBusinessLinesByCountryCode(event: any) {
    console.log('getSocieties')
    this.libService.lockPage('');


    this.resetFormValues([
      { formField: 'societyId', selectCollection: 'societies' },
      { formField: 'businessLineId', selectCollection: 'businessLines' },
    ]) // Reset form values

    this.countryService.getBusinessLines(event.value).subscribe((response) => {
      console.log(response)
      response.forEach((configuration: any) => {

        let tmpbusinessLine: any = { id: configuration.businessLineId, code: configuration.businessLineCode }
        let businessLineIds = this.businessLines.map((businessLine: any) => businessLine.id)

        if (!businessLineIds.includes(tmpbusinessLine.id)) this.businessLines.push(tmpbusinessLine)
      })

      this.libService.unlockPage();
    });
  }

  getBusinessLines(event: any) {
    console.log('getBusinessLines')
    this.libService.lockPage('');


    this.resetFormValues([
      { formField: 'businessLineId', selectCollection: 'businessLines' }
    ]) // Reset form values

    let countryCode = this.form.get('countryCode')?.value
    let societyId = this.form.get('societyId')?.value

    this.countryService.determinesConfiguration(countryCode, { societyId: societyId }).subscribe((response) => {
      console.log(response)
      response.forEach((configuration: any) => {

        let tmpbusinessLine: any = { id: configuration.businessLineId, code: configuration.businessLineCode }
        let businessLineIds = this.businessLines.map((businessLine: any) => businessLine.id)

        if (!businessLineIds.includes(tmpbusinessLine.id)) this.businessLines.push(tmpbusinessLine)
      })

      this.libService.unlockPage();
    });
  }

  setContactReasonId(event: any) {
    let contactReasonId = event.value.id
    this.form.get('contactReasonId')?.setValue(contactReasonId)

    this.setValidation(contactReasonId)
  }

  setValidation(contactReasonId: any){
    console.log('setValidation')
    let keys: any = [
      'countryCode', 
      //'societyId', 
      'businessLineId'
    ]
    keys.forEach((key: any) => {
      if(contactReasonId == 1){
       this.form!.get(key)?.addValidators(Validators.required) 
      }else{ 
        this.form!.get(key)?.clearValidators()
        this.form!.get(key)?.addValidators([])
      }
    })
  }

  getContactReasonId() {
    return this.form?.get('contactReasonId')?.value
  }

  disabledSocietyIdSelect() {
    return this.form.get('countryCode')?.value == null && this.societies.length == 0
  }

  disabledBusinessLineIdSelect() {
    return this.form.get('societyId')?.value == null && this.businessLines.length == 0
  }

  ngOnDestroy(): void {
    this.form.get('subject')?.reset()
    this.form.get('building')?.reset()
  }

  selSubject(event: any) {
    console.log('Selected: ', event);

    switch (event.type) {
      case 'Edificio':
        this.clearForm('building')
        this.form.get('building')?.patchValue(event)
        break;
      default:
        this.clearForm('subject')
        this.form.get('subject')?.patchValue(event)
        break;
    }
  }

  clearForm(formName: string) {
    //set status provvisorio per subject e building
    this.form.get(formName)?.reset()
    this.form.get(formName)?.get('status')?.setValue(this.statusTemporary)
  }

  get addressSubjectValid() {
    let streetName = this.form.get('subject')?.get('streetName')?.valid
    let streetNumber = this.form.get('subject')?.get('streetNumber')?.valid
    let zip = this.form.get('subject')?.get('zip')?.valid
    let city = this.form.get('subject')?.get('city')?.valid
    let state = this.form.get('subject')?.get('state')?.valid
    let country = this.form.get('subject')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get addressBuildingValid() {
    let streetName = this.form.get('building')?.get('streetName')?.valid
    let streetNumber = this.form.get('building')?.get('streetNumber')?.valid
    let zip = this.form.get('building')?.get('zip')?.valid
    let city = this.form.get('building')?.get('city')?.valid
    let state = this.form.get('building')?.get('state')?.valid
    let country = this.form.get('building')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get subjectForm() {
    return this.form.controls['subject'] as UntypedFormGroup
  }

  get buildingForm() {
    return this.form.controls['building'] as UntypedFormGroup
  }

  compareFn(o1: WorkType, o2: WorkType) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  private setCompanyLocationAndResponsibles(companyCode: string) {
    if (companyCode && (this.companies && this.companies.length > 0)) {
      let companyLocal = this.companies.find(company => company.code === companyCode)
      try {
        this.form.get('companyLocation')?.setValue(companyLocal!.CompaniesSocietiesRel![0].name)
      } catch (error) {
        this.toastr.error(this.translate.instant('ERROR.COMPANYLOC'))
      }

      try {
        let responsibles = companyLocal!.CompaniesUsersRel!.map((user: User) => user.email)
        let responsible = responsibles.includes(this.authService.user().name) ? this.authService.user().name : responsibles[0]
        this.form.get('responsible')?.setValue(responsible)
      } catch (error) {
        this.toastr.error(this.translate.instant('ERROR.COMPANYRESP'))
      }
    }
  }

  setEditabilityOfFields() {
    console.log('setEditabilityOfFields - Init Workflow')
    let keys: any = [
      {
        code: 'stateId',
        conditions: true
      },
      {
        code: 'countryCode',
        conditions: !this.isNew
      },
      {
        code: 'societyId',
        conditions: (!this.isNew || this.disabledSocietyIdSelect())
      },
      {
        code: 'businessLineId',
        conditions: (!this.isNew || this.disabledBusinessLineIdSelect())
      },
      {
        code: 'contactReason',
        conditions: !this.isNew
      }
    ]
    keys.forEach((key: any) => {
      key.conditions ? this.form!.get(key.code)?.disable() : this.form!.get(key.code)?.enable()
    })
  }

}
