import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter, Subscription } from 'rxjs';
import { OutgoingEmailSettings } from 'src/app/models/email-template';
import { OutgoingEmailService } from 'src/app/services/outgoing-email.service';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {

  form: UntypedFormGroup;
  hoverRow: any;
  settings: OutgoingEmailSettings;

  summernoteConfig = {
    placeholder: '',
    tooltip: false,
    tabsize: 2,
    //height: '400px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['picture', 'link', 'hr']]
    ],
    fontNames: [
      'pfdintex',
      'Helvetica',
      'Arial',
      'Courier New',
      'Roboto',
      'Times'
    ]
  };

  currentLang: string = this.translate.currentLang;
  currentCountry: string = '*'
  currentStoreType: string = 'inStore'
  languages = this.translate.langs;
  storeTypes = [{
    code: 'inStore',
    desc: 'In Store'
  },
  {
    code: 'oca',
    desc: 'OCA'
  }]

  currentLanguageControl = new UntypedFormControl(this.translate.currentLang);
  currentCountryControl = new UntypedFormControl('*');
  currentStoreTypeControl = new UntypedFormControl('inStore')

  get titleFormArray() {
    return this.form.get('title') as UntypedFormArray;
  }
  get textFormArray() {
    return this.form.get('text') as UntypedFormArray;
  }

  private curLangControlSub: Subscription;
  private curCountryControlSub: Subscription;
  private curStoreTypeControlSub: Subscription;
  private navSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private settingsService: OutgoingEmailService,
  ) {
    this.form = this.fb.group({
      id: '',
      title: this.fb.array([], Validators.required),
      text: this.fb.array([], Validators.required)
    });

    //this.currentCountryControl.setValue('')

    this.curLangControlSub = this.currentLanguageControl.valueChanges.subscribe(
      lang => {
        this.currentLang = lang;
        this.checkTextForLang(lang);
        this.checkTitleForLang(lang);
      }
    );



    this.navSub = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        this.currentLang = this.translate.currentLang;
        this.currentLanguageControl.setValue(this.currentLang);
        this.checkTextForLang(this.currentLang);
        this.checkTitleForLang(this.currentLang);
      });
  }

  ngOnInit() {
    this.route.data.subscribe(data => this.initForm(data['settings']));
  }

  ngOnDestroy() {
    if (this.navSub) {
      this.navSub.unsubscribe();
    }

    if (this.curLangControlSub) {
      this.curLangControlSub.unsubscribe();
    }

    if (this.curCountryControlSub) {
      this.curCountryControlSub.unsubscribe();
    }

    if (this.curStoreTypeControlSub) {
      this.curStoreTypeControlSub.unsubscribe();
    }
  }

  initForm(settings: OutgoingEmailSettings = {}) {
    this.settings = settings;
    this.form.patchValue(this.settings);

    const titleGroups = this.settings.title!.map(n => this.fb.group(n));
    this.form.setControl('title', this.fb.array(titleGroups));

    const textGroups = this.settings.text!.map(n => this.fb.group(n));
    this.form.setControl('text', this.fb.array(textGroups));
  }

  save() {
    if (this.form.valid) {

      this.settingsService
        .update(this.form.value.id, this.cleanEmptyProperties(this.form.value))
        .subscribe(
          (settings: any) => {

            this.toastr.success(
              this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
            this.initForm(settings);
          },
          (err: any) => this.handleError(err)
        );
    }
  }

  handleError(error: any) {
    // this.notifications.openError(error);
  }

  checkTitleForLang(lang: string) {
    const i = this.titleFormArray.controls.findIndex(
      c => c.get('lang')?.value === (this.currentLang || 'en')
    );
    if (i < 0) {
      this.titleFormArray.push(
        this.fb.group({
          lang: lang,
          text: ''
        })
      );
    }
  }

  checkTextForLang(lang: string) {
    const i = this.textFormArray.controls.findIndex(
      c => c.get('lang')?.value === (this.currentLang || 'en')
    );
    if (i < 0) {
      this.textFormArray.push(
        this.fb.group({
          lang: lang,
          text: ''
        })
      );
    }
  }

  cleanEmptyProperties(object: any) {
    const obj = Object.assign({}, object);
    Object.keys(obj).forEach((key) => (!obj[key] || obj[key].length === 0) && delete obj[key]);
    return obj;
  }
}
