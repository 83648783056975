<mat-form-field class="w-100" appearance="outline" >
  <mat-label>{{ label | translate }}</mat-label>
  <mat-select [multiple]="multiple" [(ngModel)]="currentValue" (selectionChange)="selectionChanged($event, team, type)" #singleSelect>
    <mat-option>
      <ngx-mat-select-search
        *ngIf="true"
        [formControl]="filterControl"
        [preventHomeEndKeyPropagation]="true"
        [placeholderLabel]="'LABEL.SEARCH' | translate">
        <span ngxMatSelectNoEntriesFound>
          {{noMatchLabel}}
        </span>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      <strong>{{ item.name }}</strong> - {{ item.ruolo }}
    </mat-option>
  </mat-select>
</mat-form-field>
