import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  EventEmitter,
  Output
} from '@angular/core';

import { ResourcePlan } from '../../models/resource-plan';

import {
  map,
  EMPTY,
  filter,
  switchMap,
  throttleTime,
  tap,
  Observable,
  Subscription,
  combineLatest,
} from 'rxjs';

import { BryntumSchedulerProComponent, BryntumSchedulerProProjectModelComponent } from '@bryntum/schedulerpro-angular-thin';
import { Button, EventHelper, StringHelper, TextField, Store, MessageDialog, LocaleManager, DateHelper, Checkbox, DataField, DateField } from '@bryntum/core-thin';
import { SchedulerPro, CalendarModel } from '@bryntum/schedulerpro-thin';
import { ColumnStore } from '@bryntum/grid-thin';

import { schedulerProConfig, projectConfig } from './planner-scheduler-energy.config';
import { localeIt } from './locate-it';
import { localeFr } from './locate-fr';
import { localeEs } from './locate-es';

import { PlannerService } from '../../providers/planner.service';
import { FinanceService } from '../../../services/finance.service'
import { UserSessionService } from '../../../services/user-session.service'

import { LibService } from '../../../services/libService'
import { PlanningService } from '../../../services/planning.service'
import { OrderService } from '../../../services/order.service'
import { TranslatorService } from '../../../services/translate.service';
import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MyTimeRangeStore } from './MyTimeRange';
import { CheckBox } from '@syncfusion/ej2-angular-buttons';
import * as moment from 'moment';
import { UserRole } from '../../models/userRole';
import { Task } from 'src/app/models/task';
import { Event } from '../../models/event';
import { O_DIRECT } from 'constants';
import { MatDialog } from '@angular/material/dialog';
import { AddEventDialogComponent } from './components/add-event-dialog/add-event-dialog.component';

import { EventTypeService } from '../../../services/eventType.service';
import { EventCategoryService } from '../../../services/eventCategory.service';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'planner-scheduler-energy',
  templateUrl: './planner-scheduler-energy.component.html',
  styleUrls: ['./planner-scheduler-energy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannerSchedulerEnergyComponent implements OnInit, AfterViewInit {
  private schedulerPro: SchedulerPro;

  @Output() setPlanningFullScreen = new EventEmitter();
  @Input() fullScreen = false;

  eventTypes: any = []
  eventCategories: any = []
  plannerViewOperators: any = false;

  planningStartDate: any = moment().subtract(1, 'months').toDate()
  planningEndDate: any = moment().add(3, 'months').toDate()

  backupResources: any = []

  plannerType: string = this.router.url.split('/')[2]
    ? this.router.url.split('/')[2]
    : 'core';

  myTimeRangeStore = new MyTimeRangeStore();

  teamLeaderLabel = 'CAPO SQUADRA';

  columns: any = [];

  cls = 'custom-style';
  consuntivoLabel = this.translate.instant('SIDEBAR.Consuntivi');

  calendars: any = [
    // Calendar definition
    {
      id: 'weekends',
      name: 'Weekends',
      // Intervals to define all SA and SU as non-working days
      intervals: [
        {
          recurrentStartDate: 'on Sat at 0:00',
          recurrentEndDate: 'on Mon at 0:00',
          isWorking: false,
        },
      ],
    },
  ];

  timeRanges: any = [
    {
      id: 1,
      name: '',
      recurrenceRule: 'FREQ=WEEKLY;BYDAY=SU;',
      startDate: '2021-07-17T00:00Z',
      endDate: '2021-07-18T00:00Z',
    },
    {
      id: 2,
      name: '',
      recurrenceRule: 'FREQ=WEEKLY;BYDAY=SA;',
      startDate: '2021-07-17T00:00Z',
      endDate: '2021-07-18T00:00Z',

    },
  ];

  eventLayout: any = {
    type: 'stack',
    //groupBy: (event: any) => (event.prio === 'high' ? 1 : 2)
    groupBy: 'prio',
  };

  hashCode(text: any) {
    var hash = 0,
      i,
      chr;
    if (text.length === 0) return hash;
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  changeColumsStructure(event: any) {
    this.plannerViewOperators = event.checked;
    if (this.plannerViewOperators) this.router.navigate(['planner', 'energyOperators']);
  }

  getEventCategoryName(id: any) {
    let currentEventCategory = this.eventCategories.find((eventCategory: any) => eventCategory.id == id)?.label
    return currentEventCategory
  }

  features: any = {
    group: 'orderCode',
    sort: 'eventType',
    dependencyEdit: false,
    dependencies: false,
    percentBar: {
      allowResize: false,
      showPercentage: false, // showing a tooltip instead
    },
    timeRanges: {
      enableResizing: false,
      showCurrentTimeLine: false,
      showHeaderElements: false,
    },
    nonWorkingTime: true,
    eventNonWorkingTime: {
      enableResizing: true,
    },
    cellEdit: false,
    cellMenu: {
      items: {
        removeRow: false,
      },
    },
    eventTooltip: {
      align: 'l-r',
      template: (data: any) => {
        console.log(data)
        let templateTooltip = '';
        if (data?.eventRecord?.originalData?.eventTypology == 'event') {



          templateTooltip = `<dl>
          ${data.eventRecord.get('name')
              ? `<dt>${this.translate.instant(
                'PLANNER.TITLE'
              )}:</dt><dd>${data.eventRecord.get('name')}</dd>`
              : ''
            }
          ${data.eventRecord.get('description')
              ? `<dt>${this.translate.instant(
                'PLANNER.DESCRIPTION'
              )}:</dt><dd>${data.eventRecord.get('description')}</dd>`
              : ''
            }
          ${data.eventRecord.get('note')
              ? `<dt>${this.translate.instant(
                'PLANNER.NOTE'
              )}:</dt><dd>${data.eventRecord.get('note')}</dd>`
              : ''
            }
            ${data.eventRecord.get('eventCategoryLabel')
              ? `<dt>${this.translate.instant(
                'EVENT_CATEGORIES.Title'
              )}:</dt><dd>${data.eventRecord.get('eventCategoryLabel')}</dd>`
              : ''
            }
          </dl>`;

        } else {

          if (
            data.eventRecord.get('finalBalancer') ||
            data.eventRecord.get('withFinalBalancer')
          ) {

            templateTooltip = `<dl>
${data.eventRecord.get('code')
                ? `<dt>${this.translate.instant(
                  'PLANNER.CODE_COMMESSA'
                )}:</dt><dd>${data.eventRecord.get('code')}</dd>`
                : ''
              }
${data.eventRecord.get('clientName')
                ? `<dt>${this.translate.instant(
                  'PLANNER.SUBJ'
                )}:</dt><dd>${data.eventRecord.get('clientName')}</dd>`
                : ''
              }
${data.eventRecord.get('nameBuilding')
                ? `<dt>${this.translate.instant(
                  'PLANNER.Edificio'
                )}:</dt><dd>${data.eventRecord.get('nameBuilding')}</dd>`
                : ''
              }
${data.eventRecord.get('assistantsName')
                ? `<dt>${this.translate.instant(
                  'PLANNER.WORKER_ASSISTANT'
                )}:</dt><dd>- ${data.eventRecord
                  .get('assistantsName')
                  .join('<br>- ')}</dd>`
                : ''
              }
<hr>
${data.eventRecord.get('OrderHoursTotal').toString()
                ? `<dt>${this.translate.instant(
                  'PLANNER.OreDeliberate'
                )}:</dt><dd>${data.eventRecord.get('OrderHoursTotal')}</dd>`
                : ''
              }
${data.eventRecord.get('TaskHoursTotal').toString()
                ? `<dt>${this.translate.instant(
                  'PLANNER.OrePianificate'
                )}:</dt><dd>${data.eventRecord.get('TaskHoursTotal')}</dd>`
                : ''
              }
${data.eventRecord.get('PassiveHoursTotal').toString()
                ? `<dt>${this.translate.instant(
                  'PLANNER.OreConsuntivate'
                )}:</dt><dd>${data.eventRecord.get('PassiveHoursTotal')}</dd>`
                : ''
              }
<hr>
${data.eventRecord.get('deliberateQuantityToBeProduced')
                ? `<dt>${this.translate.instant(
                  'PLANNER.QuantitaTotaleDeliberata'
                )}:</dt><dd>${data.eventRecord.get(
                  'deliberateQuantityToBeProduced'
                )}</dd>`
                : ''
              }
${data.eventRecord.get('totalSAL')
                ? `<dt>${this.translate.instant(
                  'PLANNER.QuantitaTotaleProdotta'
                )}:</dt><dd>${data.eventRecord.get('totalSAL')} ( ${data.eventRecord.get(
                  'percentDone'
                )}% )</dd>`
                : ''
              }
${data.eventRecord.get('weekSAL')
                ? `<dt>${this.translate.instant(
                  'PLANNER.QuantitaSettimanaleProdotta'
                )}:</dt><dd>${data.eventRecord.get('weekSAL')} ( ${data.eventRecord.get(
                  'weekSALpercent'
                )}% )</dd>`
                : ''
              }
<hr>
${data.eventRecord.get('deliberateValueToBeProduced')
                ? `<dt>${this.translate.instant(
                  'PLANNER.ValoreTotaleDeliberato'
                )}:</dt><dd>${data.eventRecord.get('deliberateValueToBeProduced')}</dd>`
                : ''
              }
${data.eventRecord.get('totalSALValue')
                ? `<dt>${this.translate.instant(
                  'PLANNER.ValoreTotaleProdotto'
                )}:</dt><dd>${data.eventRecord.get(
                  'totalSALValue'
                )} ( ${data.eventRecord.get('totalSALValuePercent')}% )</dd>`
                : ''
              }
${data.eventRecord.get('weekSALValue')
                ? `<dt>${this.translate.instant(
                  'PLANNER.ValoreSettimanaleProdotto'
                )}:</dt><dd>${data.eventRecord.get(
                  'weekSALValue'
                )} ( ${data.eventRecord.get('weekSALValuePercent')}% )</dd>`
                : ''
              }
<hr>
${this.translate.instant('PLANNER.GGCONSUNTIVATI')}:
<table class="consTable">
<th class="${data.eventRecord.get('weekDays').includes('Mo') ? 'mark' : ''
              }">L</th>
<th class="${data.eventRecord.get('weekDays').includes('Tu') ? 'mark' : ''
              }">M</th>
<th class="${data.eventRecord.get('weekDays').includes('We') ? 'mark' : ''
              }">M</th>
<th class="${data.eventRecord.get('weekDays').includes('Th') ? 'mark' : ''
              }">G</th>
<th class="${data.eventRecord.get('weekDays').includes('Fr') ? 'mark' : ''
              }">V</th>
<th class="${data.eventRecord.get('weekDays').includes('Sa') ? 'mark' : ''
              }">S</th>
<th class="${data.eventRecord.get('weekDays').includes('Su') ? 'mark' : ''
              }">D</th>
</table>
</dl>`;
          } else {
            templateTooltip = `<dl>
${data.eventRecord.get('code')
                ? `<dt>${this.translate.instant(
                  'PLANNER.CODE_COMMESSA_ENERGY'
                )}:</dt><dd>${data.eventRecord.get('code')}</dd>`
                : ''
              }
              ${data.eventRecord.get('corePracticeCode')
                ? `<dt>${this.translate.instant(
                  'PLANNER.CODE_COMMESSA_RIFATTURAZIONE'
                )}:</dt><dd>${data.eventRecord.get('corePracticeCode')}</dd>`
                : ''
              }
${data.eventRecord.get('clientName')
                ? `<dt>${this.translate.instant(
                  'PLANNER.SUBJ'
                )}:</dt><dd>${data.eventRecord.get('clientName')}</dd>`
                : ''
              }
${data.eventRecord.get('nameBuilding')
                ? `<dt>${this.translate.instant(
                  'PLANNER.Edificio'
                )}:</dt><dd>${data.eventRecord.get('nameBuilding')}</dd>`
                : ''
              }
${data.eventRecord.get('assistantsName')
                ? `<dt>${this.translate.instant(
                  'PLANNER.WORKER_ASSISTANT'
                )}:</dt><dd>- ${data.eventRecord
                  .get('assistantsName')
                  .join('<br>- ')}</dd>`
                : ''
              }
<hr>
${data.eventRecord.get('OrderHoursTotal')?.toString()
                ? `<dt>${this.translate.instant(
                  'PLANNER.OreDeliberate'
                )}:</dt><dd>${data.eventRecord.get('OrderHoursTotal')}</dd>`
                : ''
              }
${data.eventRecord.get('TaskHoursTotal')?.toString()
                ? `<dt>${this.translate.instant(
                  'PLANNER.OrePianificate'
                )}:</dt><dd>${data.eventRecord.get('TaskHoursTotal')}</dd>`
                : ''
              }
${data.eventRecord.get('PassiveHoursTotal')?.toString()
                ? `<dt>${this.translate.instant(
                  'PLANNER.OreConsuntivate'
                )}:</dt><dd>${data.eventRecord.get('PassiveHoursTotal')}</dd>`
                : ''
              }
</dl>`;
          }
        }
        return templateTooltip;
      },
    },
    taskEdit: false,
    eventMenu: {
      items: {
        // Add extra items shown for each event
        reset: {
          ref: 'reset',
          text: 'Reset',
          icon: 'b-fa b-fa-fw b-fa-xmark',
          weight: 520,
          onItem: ({ eventRecord }: any) =>
            this.resetOfThePlannedOrder(eventRecord),
        },

        consuntivazione: {
          ref: 'consuntivazione',
          text: this.consuntivoLabel,
          icon: 'b-fa b-fa-fw b-fa-wallet',
          weight: 520,
          onItem({ eventRecord }: any) {
            window.open(
              `/final-balancer/${eventRecord.code}/${eventRecord.version}`,
              '_blank'
            );
          },
        },

        modificaEvento: {
          ref: 'modificaEvento',
          text: 'Modifica',
          icon: 'b-fa b-fa-fw b-fa-pen-to-square',
          weight: 520,
          onItem: ({ eventRecord }: any) =>
            this.editPracticeEvent(eventRecord),
        },

        annullaEvento: {
          ref: 'annullaEvento',
          text: 'Annulla',
          icon: 'b-fa b-fa-fw b-fa-circle-xmark',
          weight: 520,
          onItem: ({ eventRecord }: any) =>
            this.cancelPracticeEvent(eventRecord),
        },

        // Edit a built in item
        editEvent: {},

        // Hide a built in item
        unassignEvent: false,
        splitEvent: false,
        cutEvent: false,
        copyEvent: false
      },
      processItems({ eventRecord, items }: any) {
        console.log(eventRecord.eventType)
        console.log(items)
        if (['Evento'].includes(eventRecord.eventType)) {
          // Add a custom item for meetings
          items.reset = false
          items.consuntivazione = false
          items.editEvent = false
          items.deleteEvent = false

        } else if (['Milestone'].includes(eventRecord.eventType)) {
          items.reset = false
          items.consuntivazione = false
          items.editEvent = false
          items.deleteEvent = false
          items.modificaEvento = false
          items.annullaEvento = false
        } else {
          items.modificaEvento = false
          items.annullaEvento = false
        }

        // Prevent menu for "canceled" event
        return !eventRecord.canceled;
      },
    },
    scheduleMenu: {
      items: {
        // Remove "Add event" default item
        addEvent: false,
      },
    },
  };

  tbarConfig() {
    let structureTbarConfig: any = {
      items: [
        {
          type: 'textfield',
          ref: 'filterByName',
          icon: 'b-fa b-fa-filter',
          placeholder: this.translate.instant('PLANNER.cercacommessa'),
          clearable: true,
          width: '15em',
          keyStrokeChangeDelay: 100,
          triggers: {
            filter: {
              align: 'start',
              cls: 'b-fa b-fa-filter',
            },
          },
        },
        {
          type: 'button',
          id: 'addEvent',
          ref: 'addEvent',
          icon: 'b-fa-plus',
          text: 'Aggiungi Evento',
        },
        {
          type: 'datefield',
          ref: 'startDateField',
          width: 190,
          editable: false,
          step: 1,
          value: this.planningStartDate
        },
        {
          type: 'datefield',
          ref: 'endDateField',
          width: 190,
          editable: false,
          step: 1,
          value: this.planningEndDate
        },
        '->',
        {
          type: 'button',
          id: 'fullPlanning',
          ref: 'fullPlanning',
          icon: 'b-icon-fullscreen',
          text: '',
        },
        {
          type: 'button',
          ref: 'loadTasksButton',
          icon: 'b-icon-sync',
          text: '',
        },
        {
          type: 'button',
          ref: 'zoomInButton',
          icon: 'b-icon-search-plus',
          text: 'Zoom in',
        },
        {
          type: 'button',
          ref: 'zoomOutButton',
          icon: 'b-icon b-icon-search-minus',
          text: 'Zoom out',
        },
      ],
    };

    if (this.plannerType == 'core') {
      let labelConsuntivi = this.translate.instant('PLANNER.Consuntivi')
      structureTbarConfig.items.push(
        {
          type: 'checkbox',
          ref: 'showFinalBalances',
          label: labelConsuntivi,
          checked: true,
        },
      )
    }

    return structureTbarConfig
  }



  listeners = {};

  resetOfThePlannedOrder(eventRecord: any) {
    console.log('RESET');
    console.log(eventRecord);

    let orderId = eventRecord.orderId;

    MessageDialog.confirm({
      title: 'Reset schedule',
      message: 'Are you sure you want to reset the schedule?',
    }).then((result: any) => {
      if (result === 1) {
        this.planningService.resetPlanning(orderId).subscribe(
          (responseData: any) => {
            this.loadTasks(true);
            //context.finalize(true);
          },
          (err) => {
            //context.finalize(false);
          }
        );
      } else {
        // context.finalize(false);
      }
    });
  }

  /*
  eventRenderer({ eventRecord, resourceRecord, renderData }: any) {
    renderData.cls[`b-type-${eventRecord.type}`] = 1;

    return [
        {
            class    : 'header',
            children : [
                {
                    class    : 'progress-outer',
                    children : [{
                        class : 'progress-fill',
                        style : {
                            width : (eventRecord.progress || 50) + '%'
                        }
                    }]
                },
                {
                    class : 'b-fa b-fa-ellipsis'
                }
            ]
        },
        {
            class    : 'footer',
            children : [
                eventRecord.type === 'meeting' ? {
                    class : 'b-fa b-fa-people-group'
                } : null,
                {
                    tag  : 'span',
                    text : eventRecord.name
                },

            ]
        }
    ];
}
*/

  goToFinalBalancer(code: any, version: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/final-balancer/', `${code}`, `${version}`])
    );

    window.open(url, '_blank');
  }
  resources: any = [];

  events: any = [];

  assignments: any = [
    { event: 1, resource: 1 },
    { event: 2, resource: 2 },
  ];

  dependencies: any = [];

  schedulerProConfig = schedulerProConfig;
  projectConfig = projectConfig;

  ordersView: any = false;

  public teamLeaders: any = [];
  public assistants: any = [];
  public assistantOptions: any = [];

  //@ViewChild('schedulerpro') schedulerProComponent!: BryntumSchedulerProComponent;
  @ViewChild(BryntumSchedulerProComponent)
  schedulerProComponent: BryntumSchedulerProComponent;
  @ViewChild('project')
  projectComponent!: BryntumSchedulerProProjectModelComponent;

  public orderCodes: any = [];
  public orderIds: any = [];
  orders: any[] = [];

  public eventStructure = [];
  public tasksCodeStructure: any = {};
  public finalBalancesCodeStructure: any = {};

  public tasksEvents: any = [];
  public finalBalancesEvents: any = [];

  public tasksWithFinalBalancesEvents: any = [];
  public tasksWithFinalBalancesAssignments: any = [];

  public tasksAssignments: any = [];
  public finalBalancesAssignments: any = [];
  orderColors: any = {};

  constructor(
    private plannerService: PlannerService,
    private financeService: FinanceService,
    private userSessionService: UserSessionService,
    private libService: LibService,
    private planningService: PlanningService,
    private orderService: OrderService,
    public translate: TranslatorService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public eventTypeService: EventTypeService,
    public eventCategoryService: EventCategoryService,

  ) { }

  getAndSetOrderColor(orderCode: any) {
    let color = this.getRandomColor();
    if (!this.orderColors[orderCode]) this.orderColors[orderCode] = color;
    return this.orderColors[orderCode];
  }

  getNextDay(startDate: any, numberOfDays: any) {
    let start = moment(startDate, 'YYYY-MM-DD');
    return start.add(numberOfDays, 'days');
  }


  initLoadTasks(loading: any = false) {
    this.getResources();
    this.loadTasks(loading);
  }

  resetFilterByName() {
    // Ripulisce il campo che filtra sulla base del nome della commessa
    if (this.schedulerPro) {
      const { filterByName } = this.schedulerPro.widgetMap as {
        filterByName: TextField;
      };
      filterByName.clear();
    }
  }

  getCurrentWeekNumber() {
    let today = new Date();
    let structure = {
      year: today.getUTCFullYear(),
      month: today.getUTCMonth(),
      day: today.getUTCDate(),
    };
    let week = moment(
      `${structure.year}-${structure.month + 1}-${structure.day}`,
      'YYYY-MM-DD'
    ).format('WW');
    return week;
  }

  loadTasks(loading: any = false) {
    console.log('LOAD TASKS AND EVENTS');
    if (loading) this.libService.lockPage('');
    let currentUser = this.userSessionService.getState('user');

    this.resetFilterByName();

    // Reset Variabili
    this.tasksCodeStructure = {};
    this.tasksEvents = [];
    this.tasksAssignments = [];
    this.backupResources = [];
    this.orders = [];

    console.log(this.plannerType);

    const events$ = this.planningService.getEventsEnergyWithPagination(this.planningStartDate, this.planningEndDate);
    const tasks$ = this.planningService.getOrdersAndTasksEnergyWithPagination(this.planningStartDate, this.planningEndDate);
    combineLatest([events$, tasks$]).subscribe(([events, tasks]) => {
      this.manageEnergyData(events, tasks, loading);
    });

  }

  addPracticeCode(code: any, practiceId: any) {
    let currentCodeStructure = { code: code, id: practiceId }
    if (!this.orders.includes(currentCodeStructure)) this.orders.push(currentCodeStructure);
  }

  manageEnergyData(events: Event[], tasks: Task[], loading: any = false) {

    // Genera tutti gli slot di eventi per le commesse di tutti gli eventi e tutti i task (considerando unicità)

    // TASK

    const checkedOrderCodes = new Set();

    for (const task of tasks) {

      let keyCode = task.mainCode ? task.mainCode : task.Order.code
      if (!checkedOrderCodes.has(keyCode)) {
        this.addPracticeCode(keyCode, task.Order.PracticeId);

        [
          'Cantiere',
          'Elettricisti',
          'Evento',
          'Milestone'
        ].forEach((type: any) => {

          let currentResource: any = {
            orderCode: keyCode,
            eventType: type,
            id: `${type}-${keyCode}`
          }

          this.schedulerPro.resourceStore.add(currentResource);
          this.backupResources.push(currentResource);
        })

        checkedOrderCodes.add(keyCode);


      }
    }

    let setTaskStructure = this.setTaskStructure(tasks)
    console.log(setTaskStructure)

    for (const [key, value] of Object.entries(setTaskStructure)) {
      //console.log(this.codeStructure[key].days)
      if (setTaskStructure[key].days.length != 0) {
        setTaskStructure[key].days.sort(function (x: any, y: any) {
          return x - y;
        });
        let structure = this.setTaskForScheduling(value, key)
        // console.log(structure)

        this.tasksEvents.push(structure);
        this.tasksAssignments.push({
          event: key,
          resource: structure.teamLeaderId,
        });
      }


    }

    // EVENTS
    for (const event of events) {

      let keyCode = event.practice.code
      if (!checkedOrderCodes.has(keyCode)) {

        this.addPracticeCode(keyCode, event.practice.id);

        [
          'Cantiere',
          'Elettricisti',
          'Evento',
          'Milestone'
        ].forEach((type: any) => {

          let currentResource: any = {
            orderCode: keyCode,
            eventType: type,
            id: `${type}-${keyCode}`
          }

          this.schedulerPro.resourceStore.add(currentResource);
          this.backupResources.push(currentResource)
        })

        checkedOrderCodes.add(keyCode);

      }

      let structure = this.setEventForScheduling(event)
      console.log(structure)
      this.tasksEvents.push(structure);
      this.tasksAssignments.push({
        event: structure.id,
        resource: `${structure.eventType}-${keyCode}`,
      });
    }


    this.codeStructureUnion();

    if (loading) this.libService.unlockPage();

  };

  setTaskStructure(tasks: any) {

    let tmp_code: any = {};
    let tasksCodeStructure: any = {};


    tasks.forEach((task: any) => {
      let order = task.Order;
      let taskCode = task.code;

      let orderCode = task?.mainCode || order.code;
      let corePractice = task?.mainCode ? true : false
      let corePracticeCode = corePractice ? order.code : null
      let icon = task?.mainCode ? 'b-fa b-fa-hammer' : 'b-fa b-fa-bolt'
      let eventType = task?.mainCode ? 'construction_site_event' : 'electricians_event'
      let tmpDate = this.getDateFromTimestampInPieces(task.tsStart);
      let tmpDateEnd = this.getDateFromTimestampInPieces(task.tsEnd);
      let week = moment(
        `${tmpDate.year}-${tmpDate.month + 1}-${tmpDate.day}`,
        'YYYY-MM-DD'
      ).format('WW');
      let weekCode = `${tmpDate.year}_${week}`;
      let old = task?.mainCode ? true : false;

      let startDate = moment(
        `${tmpDate.year}-${tmpDate.month + 1}-${tmpDate.day}`,
        'YYYY-MM-DD'
      );
      let endDate = moment(
        `${tmpDateEnd.year}-${tmpDateEnd.month + 1}-${tmpDateEnd.day}`,
        'YYYY-MM-DD'
      );
      let targetDateIndex = 0;

      if (taskCode.length < 8) {
        taskCode = `${orderCode}_${week}`;
        //old = true;
      }

      if (task.code.length > 8) {
        // Recupero il code di tutti gli order visualizzati
        if (!this.orderCodes.includes(orderCode))
          this.orderCodes.push(orderCode);
        if (!this.orderIds.includes(order.id)) this.orderIds.push(order.id);

        let name = order.nameSubject;
        if (['', null, undefined].includes(name))
          name = order.nameAdminCondominius;

        if (!tasksCodeStructure[taskCode]) {
          tasksCodeStructure[taskCode] = {
            teamLeaderId: null,
            assistantIds: [],
            assistantNameList: [],
            days: [],
            orderCode: orderCode,
            corePracticeCode: corePracticeCode,
            orderId: order.id,
            eventColor: this.getAndSetOrderColor(orderCode),
            clientName: name,
            nameBuilding: `${order.nameBuilding} - ${order.city}`,
            version: order.version,
            weekCode: weekCode,
            multipleCodeWeek: [],
            old: old,
            PassiveHoursTotal: task.PassiveHoursTotal,
            OrderHoursTotal: task.OrderHoursTotal,
            TaskHoursTotal: task.TaskHoursTotal,
            week: week,
            currentWeek: this.getCurrentWeekNumber(),
            iconCls: icon,
            eventType: eventType
          };

          if (!task?.mainCode) {
            tasksCodeStructure[taskCode].draggable = true;
            tasksCodeStructure[taskCode].resizable = true;
          } else {
            tasksCodeStructure[taskCode].draggable = false;
            tasksCodeStructure[taskCode].resizable = false;
          }
        }

        while (
          this.getNextDay(startDate, targetDateIndex).isSameOrBefore(endDate)
        ) {
          let curretTargetDate = this.getNextDay(startDate, targetDateIndex);

          let tmpCodeWeek = `${curretTargetDate.format(
            'YYYY'
          )}_${curretTargetDate.format('WW')}`;
          if (
            !tasksCodeStructure[taskCode].multipleCodeWeek.includes(
              tmpCodeWeek
            )
          )
            tasksCodeStructure[taskCode].multipleCodeWeek.push(
              tmpCodeWeek
            );
          targetDateIndex += 1;
        }

        if (old || task?.mainCode) {
          tasksCodeStructure[taskCode].draggable = false;
          tasksCodeStructure[taskCode].resizable = false;
        }

        // se il task è di un assistente, lo aggiunge all'array e termina il ciclo
        if (task.userRoleId != 36 && task.userRoleId != UserRole.ENERGY) {
          if (
            !tasksCodeStructure[taskCode].assistantIds.includes(
              task.userId
            )
          )
            tasksCodeStructure[taskCode].assistantIds.push(task.userId);
          if (
            !tasksCodeStructure[taskCode].assistantNameList.includes(
              task.userName
            )
          )
            tasksCodeStructure[taskCode].assistantNameList.push(
              task.userName
            );
          return;
        } else if (task.userRoleId == UserRole.ENERGY) {
          if (
            !tasksCodeStructure[taskCode].assistantIds.includes(
              task.userId
            )
          )
            tasksCodeStructure[taskCode].assistantIds.push(task.userId);
          if (
            !tasksCodeStructure[taskCode].assistantNameList.includes(
              task.userName
            )
          )
            tasksCodeStructure[taskCode].assistantNameList.push(
              task.userName
            );
        }

        if (!tasksCodeStructure[taskCode].days.includes(task.tsStart)) {
          tasksCodeStructure[taskCode].days.push(task.tsStart);
        }

        if (!tasksCodeStructure[taskCode].days.includes(task.tsEnd)) {
          tasksCodeStructure[taskCode].days.push(task.tsEnd);
        }

        if (task.userRoleId == UserRole.ENERGY || task.userRoleId == 36) {
          tasksCodeStructure[taskCode].teamLeaderId = task.mainCode ? 'Cantiere-' + orderCode : 'Elettricisti-' + orderCode
        }
      }

      if (!tmp_code[taskCode]) {
        tmp_code[taskCode] = [];
      }
      tmp_code[taskCode].push(task);
    });

    return tasksCodeStructure

  }

  addEventResourceName(eventType: any) {
    let name = null
    switch (eventType) {
      case 'event_types_generic':
        name = 'Evento'
        break;
      case 'event_types_milestone':
        name = 'Milestone'
        break;
    }
    return name
  }

  setEventForScheduling(event: any) {
    let key = 'event_' + event.id
    let eventType = this.addEventResourceName(event.eventType.code)
    let eventCategoryLabel = event.eventCategory?.code ? this.translate.instant('EVENT_CATEGORIES.' + event.eventCategory?.code) : null
    let helpTextCanceled = event.canceled ? '(ANNULLATO) ' : ''

    let tmpEvent = {
      id: key,
      startDate: new Date(event.tsStart),
      endDate: new Date(event.tsEnd),
      name: `${helpTextCanceled}${event.title} - ${event.practice.code}`,
      note: event.note,
      description: event.description,
      eventTypology: 'event',
      eventType: eventType,
      orderCode: event.practice.code,
      draggable: false,
      resizable: false,
      old: true,
      eventCategoryLabel: eventCategoryLabel,
      iconCls: event?.eventCategory?.icon,
      eventColor: event.canceled ? '#ef5350' : event?.eventCategory?.color,
      source: event,
      canceled: event.canceled,
      eventStyle: event.canceled ? 'hollow' : 'plain'
    };
    return tmpEvent
  }

  setTaskForScheduling(event: any, key: any) {

    let name = `${event.orderCode} - ${event.clientName} - ${event.nameBuilding}`

    let tmpEvent = {
      id: key,
      startDate: this.getDateFromTimestamp(
        event.days[0]
      ),
      endDate: this.getDateFromTimestamp(
        event.days[
        event.days.length - 1
        ]
      ),
      name: name,
      code: event.orderCode,
      corePracticeCode: event.corePracticeCode,
      orderId: event.orderId,
      clientName: event.clientName,
      nameBuilding: event.nameBuilding,
      assistants: event.assistantIds,
      assistantsName: event.assistantNameList,
      eventColor: event.eventColor,
      version: event.version,
      finalBalancer: false,
      weekCode: `${event.teamLeaderId}_${event.weekCode}`,
      multipleCodeWeek: event.multipleCodeWeek.map(
        (cw: any) =>
          `${event.teamLeaderId}_${cw}_${event.orderCode}`
      ),
      days: event.days,
      prio: 'high',
      draggable: event.draggable,
      resizable: event.resizable,
      old: event.old,
      PassiveHoursTotal: event.PassiveHoursTotal,
      OrderHoursTotal: event.OrderHoursTotal,
      TaskHoursTotal: event.TaskHoursTotal,
      teamLeaderId: event.teamLeaderId,
      week: event.week,
      currentWeek: event.currentWeek,
      iconCls: event.iconCls,
      eventType: event.eventType
    };
    return tmpEvent
  }

  initEventTypes() {
    this.eventTypeService.index().subscribe((res: any) => {
      //console.log(res)
      this.eventTypes = res.filter((eventType: any) => !eventType.disabled)
    })
  }

  initEventCategories() {
    this.eventCategoryService.index().subscribe((res: any) => {
      //console.log(res)
      this.eventCategories = res.filter((eventCategory: any) => !eventCategory.disabled)
    })
  }

  ngOnInit(): void {
    this.initEventTypes();
    this.initEventCategories();
    this.initLoadTasks();
    this.columns = [
      { type: 'resourceCollapse' },
      {
        type: 'resourceInfo',
        text: 'Commesse',
        field: 'eventType',
        showEventCount: true,
        width: 200,
      },
    ];
  }

  getRandomColor() {
    let colorList = [
      //'red',
      'pink',
      'purple',
      'magenta',
      'violet',
      'indigo',
      'blue',
      'cyan',
      'teal',
      'green',
      'gantt-green',
      'lime',
      'yellow',
      'orange',
      'deep-orange',
      //'gray',
      //'light-gray'
    ];
    return colorList[Math.floor(Math.random() * colorList.length)];
  }

  getResources() {
    this.financeGetRisorse(UserRole.ENERGY); // Assistants
  }

  getCompany() {
    const company = this.userSessionService.getState('working_company');
    if (!(company == null) && !(company.name == '*')) {
      return company.id;
    } else return 0;
  }

  financeGetRisorse(idRole: any) {
    console.log('financeGetRisorse')
    let currentUser = this.userSessionService.getState('user');
    this.financeService
      .getRisorse(currentUser.email, this.getCompany(), idRole)
      .subscribe({
        next: (res: any) => {
          if (res.esito == 'OK') {

            this.assistants = res.item.Table;
            this.assistants.forEach((assistant: any) => {
              this.assistantOptions.push({
                id: assistant.id,
                name: assistant.name,
              });
            });

          }
          console.log(this.assistants)
        },
        error: (e: any) => {
          console.log(e);
          this.teamLeaders = [];
        },
      });
  }

  getDateFromTimestamp(ts: any) {
    let timestamp = parseInt(ts);
    let date = new Date(timestamp * 1000);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes()
    );
  }

  getDateFromTimestampWithHour(ts: any, start: boolean = true) {
    let timestamp = parseInt(ts);
    let date = new Date(timestamp * 1000);
    if (start)
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0
      );
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23,
      59
    );
  }

  getDateFromTimestampWithHourNotUTC(ts: any, start: boolean = true) {
    let timestamp = parseInt(ts);
    let date = new Date(timestamp * 1000);
    if (start)
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0
      );
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59
    );
  }

  getDateFromTimestampInPieces(ts: any) {
    let timestamp = parseInt(ts);
    let date = new Date(timestamp * 1000);
    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth(),
      day: date.getUTCDate(),
    };
  }

  getEventEdit() {
    return {
      editorConfig: {
        width: '32em',
      },

      // Uncomment to make event editor readonly from the start
      // readOnly : true,
      // Add items to the event editor
      items: {
        nameField: {
          readOnly: true,
        },
        startDateField: false,
        startTimeField: false,
        endDateField: false,
        endTimeField: false,
        recurrenceCombo: false,
        resourceField: false,

        assistantsField: {
          type: 'combo',
          name: 'assistants',
          label: 'Assistants',
          required: true,
          multiSelect: true,
          valueField: 'id',
          displayField: 'name',
          weight: 120,
          items: this.assistantOptions,
        },
        /*
        linkField: {
          type: 'displayfield',
          label: 'Link',
          name: 'id',
          weight: 600,
          template: (link: any) => `<a href='//your.app/task/${link}' target='blank'>Open in new tab</a></div>`
        }
        */
      },
    };
  }

  dateForBryntum(date: any) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  clickZoomIn(): void {
    this.schedulerPro.zoomIn();
  }

  clickZoomOut(): void {
    this.schedulerPro.zoomOut();
  }

  filterEvents(event: any): void {
    //console.log(this.schedulerPro.eventStore)

    this.schedulerPro.eventStore.clearFilters()
    this.schedulerPro.resourceStore.clearFilters()

    const value = event.value.replace(/[.*+?^${}(),[\]\\]/g, '\\$&');
    this.schedulerPro.eventStore.filter({
      filters: (event: any) => event?.name?.match(new RegExp(value, 'i')) || event?.code?.match(new RegExp(value, 'i')) || event?.corePracticeCode?.match(new RegExp(value, 'i')),
      //replace: true,
    });

    // Filtro i Team Leader sulla base delle commesse ricercate
    this.schedulerPro.resourceStore.filter({
      filters: (resource: any) =>
        resource.events.some((e: any) => e.name.match(new RegExp(value, 'i'))),
      //replace: true,
    });
  }

  filterEventsClear(event: any): void {
    console.log('reset filter')
    this.schedulerPro.eventStore.clearFilters()
    this.schedulerPro.resourceStore.clearFilters()

  }

  async refreshRows() {
    this.loadTasks(true);
  }

  getWeekNumber(d: any) {
    // Copy date so don't modify original

    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // Set to nearest Thursday: current date + 4 - current day number

    // Make Sunday's day number 7

    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    // Get first day of year

    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Thursday

    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);

    // Return array of year and week number

    return [d.getUTCFullYear(), weekNo];
  }

  getDateFromUnixTimestamp(timestamp: any) {
    let ts = timestamp * 1000;
    return new Date(ts);
  }

  codeStructureUnion() {
    // Reset Variabili
    this.events = [];
    this.assignments = [];


    this.events = [...this.tasksEvents, ...[]];
    this.assignments = [...this.tasksAssignments, ...[]];

    console.log(this.events);
    // Aggiornamento diello schedulerPro
    if (this.schedulerPro) {
      //console.log('Reload')
      this.schedulerPro.eventStore.data = this.events;
      this.schedulerPro.assignmentStore.data = this.assignments;
    }
  }

  getAllIndexes(arr: any, val: any) {
    var indexes = [],
      i;
    for (i = 0; i < arr.length; i++)
      if (arr[i]?.multipleCodeWeek.includes(val)) indexes.push(i);
    return indexes;
  }

  showFinalBalances(event: any) {
    this.codeStructureUnion();
  }

  fullPlanning() {
    console.log('fullPlanning');
    this.fullScreen = !this.fullScreen;
    this.setPlanningFullScreen.next(this.fullScreen);
  }

  setStartDate({ value, userAction }: any) {
    if (userAction) {
      console.log(value)
      this.planningStartDate = value
      this.loadTasks(true);
    }

  }
  setEndDate({ value, userAction }: any) {
    if (userAction) {
      let endDate: any = moment(value).utc(true).hours(23).minutes(59).seconds(59).milliseconds(59).toDate()
      this.planningEndDate = endDate
      this.loadTasks(true);
    }
  }


  async addEvent() {
    const currentUser = this.userSessionService.getState('user');
    let event: Event = {
      title: '',
      AuthorId: 0,
      tsStart: '',
      tsEnd: '',
      EventTypeId: 0,
      EventCategoryId: 0,
      PracticeId: 0
    };

    const dialog = this.dialog.open(AddEventDialogComponent, {
      width: '500px',
      data: {
        eventTypes: this.eventTypes,
        eventCategories: this.eventCategories,
        orders: this.orders,
        event
      }
    });
    dialog.afterClosed().subscribe((event: Event) => {
      if (event) {
        event.AuthorId = currentUser.id;

        this.libService.lockPage('');
        this.planningService.addEventEnergy(event).subscribe((event: Event) => {

          this.libService.unlockPage();
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));

          this.loadTasks(true)

        });
      }
    });
  }

  cancelPracticeEvent(eventRecord: any) {
    let source = eventRecord.originalData.source
    let id = source.id
  
    MessageDialog.confirm({
      title: 'Annulla Evento',
      message: 'Sei sicuro di voler annullare questo Evento/Milestone?',
    }).then((result: any) => {
      if (result === 1) {
        this.libService.lockPage('');
        this.planningService.canceledEvent(id).subscribe((event: Event) => {
    
          this.libService.unlockPage();
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
    
          this.loadTasks(true)
    
        });
      }
    
    });

  

  }


  editPracticeEvent(eventRecord: any) {
    //console.log(eventRecord.originalData.source)
    let source = eventRecord.originalData.source
    let id = source.id
    const currentUser = this.userSessionService.getState('user');
    let event: Event = {
      title: '',
      AuthorId: 0,
      tsStart: '',
      tsEnd: '',
      EventTypeId: 0,
      EventCategoryId: 0,
      PracticeId: 0
    };

    const dialog = this.dialog.open(AddEventDialogComponent, {
      width: '500px',
      data: {
        eventTypes: this.eventTypes,
        eventCategories: this.eventCategories,
        orders: this.orders,
        source: source,
        event
      }
    });
    dialog.afterClosed().subscribe((event: Event) => {
      //console.log(event)
      if (event) {
        this.libService.lockPage('');

        this.planningService.editEventEnergy(id, event).subscribe((event: Event) => {

          this.libService.unlockPage();
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));

          this.loadTasks(true)

        });


      }
    });
  }

  /**
   * Called after View is initialized
   */
  ngAfterViewInit(): void {
    // SchedulerPro instance
    this.schedulerPro = this.schedulerProComponent.instance;
    this.applySchedulerLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: any) => {
      this.applySchedulerLocale(event.lang);
      this.consuntivoLabel = this.translate.instant('SIDEBAR.Consuntivi');
      // console.log(this.schedulerPro)
    });

    this.schedulerPro.scrollToDate(new Date(), {
      block: 'start',
    });

    const {
      zoomInButton,
      zoomOutButton,
      filterByName,
      loadTasksButton,
      showFinalBalances,
      fullPlanning,
      addEvent,
      startDateField,
      endDateField,
    } = this.schedulerPro.widgetMap as {
      loadTasksButton: Button;
      zoomInButton: Button;
      zoomOutButton: Button;
      filterByName: TextField;
      showFinalBalances: Checkbox;
      fullPlanning: Button;
      addEvent: Button;
      startDateField: DateField;
      endDateField: DateField;
    };

    addEvent.on({ click: this.addEvent, thisObj: this });
    loadTasksButton.on({ click: this.refreshRows, thisObj: this });
    zoomInButton.on({ click: this.clickZoomIn, thisObj: this });
    zoomOutButton.on({ click: this.clickZoomOut, thisObj: this });
    filterByName.on({ change: this.filterEvents, thisObj: this });
    startDateField.on({ change: this.setStartDate, thisObj: this });
    endDateField.on({ change: this.setEndDate, thisObj: this });
    filterByName.on({ clear: this.filterEventsClear, thisObj: this });




    if (this.plannerType == 'core') {
      showFinalBalances.on({ change: this.showFinalBalances, thisObj: this });
    }

    fullPlanning.on({ click: this.fullPlanning, thisObj: this });

    this.schedulerPro.on('beforeeventdropfinalize', ({ context }: any) => {
      console.log('EVENTO SPOSTATO');
      this.libService.lockPage('');
      let currentUser = this.userSessionService.getState('user');
      let structure = this.getStructureForDrop(context, currentUser);
      //console.log(structure)
      context.async = true;
      let code = this.getCode(context);

      this.planningService.updateSchedule(code, structure).subscribe(
        (responseData: any) => {
          context.finalize(true);
          this.libService.unlockPage();
          //this.toastr.success(this.translate.instant('LABEL.DATA_ERROR'),this.translate.instant('TOASTR.WARNING'));
          this.loadTasks(true);
        },
        (err) => {
          context.finalize(false);
          //this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'),this.translate.instant('TOASTR.WARNING'));
          this.libService.unlockPage();
        }
      );
    });

    this.schedulerPro.on('beforeEventResizeFinalize', ({ context }: any) => {
      console.log('EVENTO RIDIMENSIONATO');
      //console.log(context)

      let startDate = this.setDateToUTCZone(context.startDate);
      let endDate = this.setDateToUTCZone(context.endDate);

      if (context.edge == 'right') {
        endDate = this.setDateToUTCZone(context.date);
      } else {
        startDate = this.setDateToUTCZone(context.date);
      }

      let currentUser = this.userSessionService.getState('user');

      this.libService.lockPage('');
      let structure = this.getStructureForResize(
        context,
        startDate,
        endDate,
        currentUser
      );
      // console.log(structure)
      context.async = true;
      let code = this.getCode(context);

      //context.eventRecord.split(); // wip

      this.planningService.resizePlanning(code, structure).subscribe(
        (responseData: any) => {
          context.finalize(true);
          this.libService.unlockPage();
          this.loadTasks(true);
        },
        (err) => {
          context.finalize(false);
          this.libService.unlockPage();
        }
      );
    });

    this.schedulerPro.on('beforeEventEdit', (event: any) => {
      console.log('EDIT');
      console.log(event)

      // Rimozione degli utenti con due ruoli, ha dato problemi il 24/08/2023 per la sede di "Perpignan 1"
      let tmpAssistantOptions: any = [];
      let countId: any = [];
      this.assistantOptions.forEach((assistant: any) => {
        if (!countId.includes(assistant.id))
          tmpAssistantOptions.push(assistant);
        countId.push(assistant.id);
      });

      event.eventEdit.editor.widgetMap.assistantsField.items =
        tmpAssistantOptions;
      navigator.clipboard.writeText(event?.eventRecord?.originalData?.orderCode);

      // Se l'evento è una vecchi pianificazione o un evento di consuntivazione blocca l'azione
      let status = false;
      status = event.eventRecord.old;
      return !status;
    });

    this.schedulerPro.on('eventMenuBeforeShow', (event: any) => {
      console.log('SHOW MENU');
      console.log(event)
      let code = event?.eventRecord?.originalData?.orderCode || event?.eventRecord?.originalData?.code
      navigator.clipboard.writeText(code);

      // Se il task è un cantiere blocca l'azione
      let status = false;
      status = (event.eventRecord.originalData.eventType == 'construction_site_event' || event.eventRecord.originalData.canceled)

      return !status;
    });

    this.schedulerPro.on('beforePaste', (event: any) => {
      console.log('INCOLLA');
      //console.log(event)
      //console.log(this.teamLeaders)
      let assistantIds = event.eventRecords[0].assistants;
      let resources = this.getAssistants(assistantIds);
      let teamLeaderId = event.resourceRecord.originalData.id;
      resources.push(this.getTeamLeader(teamLeaderId));

      let currentUser = this.userSessionService.getState('user');

      // this.libService.lockPage('');
      let structure = this.getStructureForCopy(event, resources, currentUser);
      //console.log(structure)
      let code = structure.id;

      //context.eventRecord.split(); // wip

      this.planningService.copyPlanning(code, structure).subscribe(
        (responseData: any) => {
          this.libService.unlockPage();
          this.loadTasks(true);
          return true;
        },
        (err) => {
          this.libService.unlockPage();
          return false;
        }
      );
    });

    this.schedulerPro.on('beforeDragCreate', (resource: any) => {
      // console.log('beforeDragCreate')
      return false;
    });

    this.schedulerPro.on(
      'beforeEventDelete',
      ({ eventRecords, context }: any) => {
        console.log('RIMUOVI');
        console.log(eventRecords);
        //console.log(context)
        let code = eventRecords[0].id;
        // Show custom confirmation dialog (pseudo code)
        let structure = this.getStructureForDelete(eventRecords[0]);

        MessageDialog.confirm({
          title: 'Dialog Title',
          message: 'Are you sure to Delete?',
        }).then((result) => {
          if (result === 1) {
            this.planningService.deletePlanning(code, structure).subscribe(
              (responseData: any) => {
                this.loadTasks(true);
                context.finalize(true);
              },
              (err) => {
                context.finalize(false);
              }
            );
          } else {
            context.finalize(false);
          }
        });

        // Prevent default behaviour
        return false;
      }
    );

    // Reset del blocco di Bryntum
    localStorage.removeItem('b-schedulerpro-verify-date');
    localStorage.removeItem('b-schedulerpro-trial-start');
  }

  getTeamLeader(id: any) {
    return this.teamLeaders.find((t: any) => t.id == id);
  }

  getAssistants(ids: any) {
    return this.assistants.filter((t: any) => ids.includes(t.id));
  }

  saveEvent(event: any) {
    console.log('SAVE');
    //console.log(event)
    this.libService.lockPage('');
    let values = event.values;
    let assistantIds = values.assistants;

    let currentUser = this.userSessionService.getState('user');

    let code = event.eventRecord.id;

    let params = {
      code: code,
      assistants: this.getAssistants(assistantIds),
      currentUser: currentUser.email,
      authorId: currentUser.id,
    };

    //context.eventRecord.split(); // wip
    //console.log(params)

    this.planningService.changeAssistants(code, params).subscribe(
      (responseData: any) => {
        this.libService.unlockPage();
        this.loadTasks(true);
        return true;
      },
      (err) => {
        this.libService.unlockPage();
        return false;
      }
    );
  }

  getStructureForDrop(context: any, currentUser: any) {
    let originalData = context.eventRecord.originalData;

    let id = originalData.id;
    let assistants = originalData.assistants;
    let startDate = this.setDateToUTCZone(context.startDate);
    let endDate = this.setDateToUTCZone(context.endDate);
    let newResource = context.newResource.originalData;
    let teamLeaderId = originalData.teamLeaderId;
    let orderId = originalData.orderId;

    return {
      id: id,
      assistants: assistants,
      startDate: startDate,
      endDate: endDate,
      resource: newResource,
      currentUser: currentUser.email,
      authorId: currentUser.id,
      teamLeaderId: teamLeaderId,
      orderId: orderId,
    };
  }

  setDateToUTCZone(date: any) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let newDate = new Date(Date.UTC(year, month, day));
    return newDate;
  }

  setDateToUTCZoneWithHour(date: any) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let newDate = new Date(Date.UTC(year, month, day, hour, minute, second));
    return newDate;
  }

  getStructureForResize(
    context: any,
    startDate: any,
    endDate: any,
    currentUser: any
  ) {
    let originalData = context.eventRecord.originalData;
    let id = originalData.id;
    let assistants = originalData.assistants;
    let teamLeaderId = originalData.teamLeaderId;
    let orderId = originalData.orderId;

    return {
      id: id,
      assistants: assistants,
      startDate: startDate,
      endDate: endDate,
      currentUser: currentUser.email,
      authorId: currentUser.id,
      teamLeaderId: teamLeaderId,
      orderId: orderId,
    };
  }

  getStructureForDelete(eventRecord: any) {
    let originalData = eventRecord.originalData;
    let teamLeaderId = originalData.teamLeaderId;
    let orderId = originalData.orderId;

    return {
      teamLeaderId: teamLeaderId,
      orderId: orderId,
    };
  }

  getStructureForCopy(context: any, resources: any, currentUser: any) {
    let resource = context.resourceRecord.originalData;
    let eventRecords = context.eventRecords[0];
    let code = eventRecords.code;
    let assistants = eventRecords.assistants;

    let id = eventRecords.id;

    //let startDate = this.setDateToUTCZone(context.startDate)
    //let endDate = this.setDateToUTCZone(context.endDate)
    let structure = {
      id: id,
      resources: resources,
      newStartDate: this.setDateToUTCZone(context.date),
      // startDate: startDate,
      // endDate: endDate,
      currentUser: currentUser.email,
      authorId: currentUser.id,
    };

    return structure;
  }

  getCode(context: any) {
    let originalData = context.eventRecord.originalData;
    return originalData.id;
  }

  applySchedulerLocale = (schedulerLocale: string): void => {
    switch (schedulerLocale) {
      case 'it':
        LocaleManager.applyLocale(localeIt);
        break;
      case 'fr':
        LocaleManager.applyLocale(localeFr);
        break;
      case 'es':
        LocaleManager.applyLocale(localeEs);
        break;
      case 'en':
        LocaleManager.locale = 'En';
        break;
      default:
        LocaleManager.locale = 'En';
        break;
    }
  };

  viewOrdersSection() {
    return this.plannerType == 'core' ? true : false
  }

}
