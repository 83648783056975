import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, forkJoin } from 'rxjs';
import { DocumentService } from '../../../services/document.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-file-dialog',
    templateUrl: './absence-module-document-dialog.html',
    styleUrls: ['./absence-module-document-dialog.scss']
})
export class FileDialogComponent implements OnInit {

    signedUrls$: Observable<any[]>; // Array di URLs firmati

    constructor(
        public dialogRef: MatDialogRef<FileDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, // I dati includono i documenti
        private documentService: DocumentService,
    ) { }

    ngOnInit(): void {
        // Per ogni documento, ottieni l'URL firmato
        const signedUrlRequests: Observable<string>[] = this.data.documents.map((document: any) =>
            this.documentService.getSignedUrl(document.awsPath)
        );

        // Utilizza forkJoin per attendere che tutte le richieste vengano completate
        this.signedUrls$ = forkJoin(signedUrlRequests).pipe(
            map((signedUrls: string[]) => {
                // Ritorna un array di documenti con l'URL firmato
                return this.data.documents.map((doc: any, index: number) => ({
                    ...doc,
                    signedUrl: signedUrls[index] // Aggiungi l'URL firmato al documento
                }));
            })
        );
    }

    downloadDocument(document: any): void {
        this.documentService.getSignedUrl(document.awsPath).subscribe({
            next: (signedUrl: string) => {
                window.open(signedUrl, '_blank'); // Apri il file in una nuova scheda
            },
            error: (err: any) => {
                console.error('Errore durante il download:', err);
            }
        });
    }

    onClose(): void {
        this.dialogRef.close();
    }

}
