import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LicenceService } from '../services/licenseService';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-admin-license-old',
  templateUrl: './admin-license.component.html',
  styleUrls: ['./admin-license.component.scss']
})
export class AdminLicenseOldComponent implements OnInit {
  displayedColumns = ['id', 'code', 'startDate', 'endDate', 'type', 'numberOfUsers', 'cost','isOpen', 'isUsed', 'editAction', 'deleteAction'];
  dataSource: any;
  kwSearch: any;
  title = "Licenze";
  subTitle = "EDAC - Licenze";
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  constructor(
    public licenseService: LicenceService,
    public libService: LibService,
    public navService: NavService,
    public translate: TranslatorService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  loadLabels(){
    this.translate.get('LABEL.LICENCES').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }


  ngOnInit(): void {
    this.loadLabels();
    this.loadLicenze();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
      {name:'new',url:'ad-licence/0'},
      {name:'export',url:''},
      {name:'upload',url:''},
    ];
    this.navService.setActions(actions);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  getColorOpen(p: any) {
    if (p)
      return 'color:green;';
    else
      return 'color:azure;';
  }

  getColorUsed(p: any) {
    if (p == null)
      return 'far fa-user green';
    else
      return 'fas fa-user blue';
  }


  loadLicenze(): void {

    this.libService.lockPage('');
    this.licenseService.getAllLicense().subscribe((response) => {
      console.log('loadLicenze response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
  }

}
