import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class ClaimStatusService {

  baseApi = environment.rootApiCrm + 'v1.0';

  constructor(public libService: LibService) { }

  getAllClaimStatus(): Observable<any> {
    console.log("SERVICE FRONT: "+this.baseApi);
    return this.libService.getData<any>(this.baseApi, `/claimStatus`);
  }
}
