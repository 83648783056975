import { MatSelect, MatSelectChange } from '@angular/material/select';
import { CompanyService } from 'src/app/services/companyService';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BranchService } from 'src/app/services/branch.service';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { Company } from 'src/app/models/company';
import { codeCharacter } from 'src/app/validators/code.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-branch-detail',
  templateUrl: './branch-detail.component.html',
  styleUrls: ['./branch-detail.component.scss']
})
export class BranchDetailComponent implements OnInit {

  isNew: boolean;
  id: any;
  target: any;
  target$: Observable<any>;
  formTargets: UntypedFormGroup;
  countryOptions = ['IT', 'ES', 'FR', 'MC']
  langOptions = ['IT', 'ES', 'FR']
  companies: any;
  allCompanies: any;
  selectedCompanies: number[] = [];
  allSelected: boolean

  @ViewChild('companiesSelection') companiesSelection: MatSelect;

  constructor(private fb: UntypedFormBuilder,
    private branchService: BranchService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private libService: LibService,
    public translate: TranslatorService,
    public navService: NavService,
    private toastService: ToastrService,
    private router: Router) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if(this.id != 0) this.target$ = this.branchService.getBranc(this.id);
    });
    this.companyService.getAllCompaniesFast().subscribe(companies => {
      this.allCompanies = companies.sort((a, b) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
      this.companies = companies.sort((a, b) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
    })
    this.formTargets = this.fb.group({
      company: [null],
      id: [null],
      code: [null, [Validators.required, codeCharacter()]],
      name: [null, Validators.required],
      codCountry: [null, Validators.required],
      codLanguage: [null, Validators.required],
      isActive: [null],
      cOrario: [null, Validators.required],
      multCostoOrario: [null, Validators.required],
      isStaff: [null ], /* Validators.required */
    })
  }

  selectAllCompanies(){
    console.log('Selecting all companies')
    if(this.allSelected){
      this.allSelected = false
      this.selectedCompanies = []
      this.formTargets.controls['company'].setValue([])
    }else{
      this.allSelected = true
      this.selectedCompanies = this.allCompanies.map((c: any) => c.id)
      this.formTargets.controls['company'].setValue(this.selectedCompanies)
    }
  }

  editCompanies(){
    this.router.navigate(['branch-company/' + this.id]);
  }

  updateCompaniesSelection(selection: MatSelectChange){
    const company = (selection.value) as number[]
    this.selectedCompanies = company
  }

  onChangeFilterCompanies(event: KeyboardEvent) {
    const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
    this.companies = this.allCompanies.filter((mp: any) => mp.name.toLowerCase().startsWith(searchStr))
    this.companiesSelection.value = this.selectedCompanies
  }

  onOpenSearch(searchElement: HTMLInputElement){
    searchElement.value = '';
    searchElement.focus();
  }

  onCloseSearch(){
    this.companies = this.allCompanies;
  }

  update() {
    const formObject = this.formTargets.getRawValue()
    const toSend = {
      branch: {...formObject},
      companies: {ids: formObject.company},
    }
    delete toSend.branch.company
    if(toSend.branch.id == null){
      // Creation
      let createdID = 0
      delete toSend.branch.id
      this.branchService.createBranch(toSend).subscribe({
        next(success){
          console.log(success)
          createdID=success?.Branch?.id
        },
        error: (err) => {
          console.error(err)
          this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
        complete: () => {
          console.log('Done')
          this.toastService.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigateByUrl(`genconfig`).then(console.log).catch(console.error)
        }
      })
    }else{
      // Update
      this.branchService.updateBranch({id: toSend.branch.id, branch: toSend.branch}).subscribe({
        next(success){
          console.log(success)
        },
        error: (err) => {
          console.error(err)
          this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
        complete: () => {
          console.log('Done')
          this.toastService.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        }
      })
    }
  }

  ngOnInit(): void {
    this.initNavbar();
    console.log('Branch ID: ', this.id)
    if (this.id != 0) {
      this.libService.lockPage('');
      this.target$.subscribe(val => {
        this.formTargets.controls['company'].disable()
        this.formTargets.controls['code'].disable()
        this.formTargets.controls['name'].disable()
        this.formTargets.controls['codCountry'].disable()
        this.formTargets.controls['codLanguage'].disable()
        this.allSelected = val.BCRel?.length == this.allCompanies?.length
        this.target = val;
        console.log("val -->", val);
        this.formTargets.patchValue(this.target);
        this.formTargets.controls["company"].setValue(val.BCRel.map((c: any) => c.id))
        this.libService.unlockPage();
      })
    }
  }

  initNavbar(){
    setTimeout(() => {
      let actions: any;
      actions = [
        { name: 'back', url: 'genconfig' }
      ];

      let title = this.formTargets.get('id')?.value !== null?
        this.translate.instant('LABEL.EDIT', { field: 'Ramo' }):
        this.translate.instant('LABEL.NEW', { field: 'Ramo' })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }

}
