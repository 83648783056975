<div class="full-w" >
  <br>
  <div style="margin:5px; width: 100%;">
    <!-- <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="my-bounce material-icons">camera</span>
        <div class="fw-bolder text-dark">Allegati</div>
      </mat-panel-title>
    </mat-expansion-panel-header> -->
    <div class="photo-block">
      <!-- <webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
        *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
        [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
        (initError)="handleInitError($event)"></webcam> -->
      <div class="photo-button-block">
       <!--  <button class="my-bounce btn btn-primary fx-button" (click)="triggerSnapshot();">
          <mat-icon class="material-icons" style="color:#293683;">photo_camera</mat-icon> Fotografa
        </button>

        <button class="my-bounce btn btn-primary fx-button">
          <mat-icon class="material-icons" style="color:#293683;">settings</mat-icon> Elabora
        </button> -->

        <button class="my-bounce btn btn-primary fx-button"
          (click)="openUploadDialog('new')" [disabled]="toDisable">
          <mat-icon class="material-icons" style="color:#293683;">upload</mat-icon> {{ 'FILE-UPLOAD.UPLOAD' | translate }}
        </button>
      </div>
    </div>
    <div class="snapshot" *ngIf="webcamImage">
      <h2></h2>
      <img [src]="webcamImage.imageAsDataUrl" />
    </div>
  </div>

  <app-documents-grid [documents]="documents"></app-documents-grid>
</div>
