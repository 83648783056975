import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap, of,combineLatest, tap } from "rxjs";
import { Licence } from 'src/app/models/licence';
import { ConfService } from 'src/app/services/conf.service';
import { LibService } from 'src/app/services/libService';
import { LicenceService } from 'src/app/services/licenseService';
import { NavService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-admin-licence-detail',
  templateUrl: './admin-licence-detail.component.html',
  styleUrls: ['./admin-licence-detail.component.scss']
})
export class AdminLicenceDetailComponent implements OnInit {

  licence: Licence;
  conf: any;
  isNew: boolean;
  form: UntypedFormGroup;

  id$ = this.route.paramMap.pipe(
    map(params => {
      this.isNew = params.get('id') === '0'? true: false;
      return params.get('id')
    })
  )

  licence$ = this.id$.pipe(
    switchMap(id => {
      return this.isNew ? of(null): this.licenceService.getLicense(id!)
    })
  )

  conf$ = this.confService.getAllConfs().pipe(
    map(conf => conf[0])
  );

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private licenceService: LicenceService,
    private confService: ConfService,
    public translate: TranslateService,
    private navService: NavService,
    private libService: LibService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.form = this.fb.group({
      id: null,
      code: null,
      startDate: null,
      endDate: null,
      type: null,
      numberOfUsers: 0,
      isOpen: false,
      isUsed: null,
      cost: null,
      isActive: null,
      currentlyConnectedUsers: null
    })
  }

  ngOnInit(): void {
    this.initForm()
    this.setNavBar()
    this.form.get('type')?.valueChanges.subscribe(type => {
      switch (type) {
        case 'silver':
          this.form.patchValue({
            numberOfUsers: this.conf.n_utenti_lic_silver || 0,
            cost: this.conf.cost_lic_silver });
          break;
        case 'bronze':
          this.form.patchValue({
            numberOfUsers: this.conf.n_utenti_lic_bronze || 0,
            cost: this.conf.cost_lic_bronze });
          break;
        case 'gold':
          this.form.patchValue({
            numberOfUsers: this.conf.n_utenti_lic_gold || 0,
            cost: this.conf.cost_lic_gold });
          break;
        case 'silver_l2':
          this.form.patchValue({
            numberOfUsers: this.conf.n_utenti_lic_silver_l2 || 0,
            cost: this.conf.cost_lic_silver_l2 });
          break;
        case 'bronze_l2':
          this.form.patchValue({
            numberOfUsers: this.conf.n_utenti_lic_bronze_l2 || 0,
            cost: this.conf.cost_lic_bronze_l2 });
          break;
        case 'gold_l2':
          this.form.patchValue({
            numberOfUsers: this.conf.n_utenti_lic_gold_l2 || 0,
            cost: this.conf.cost_lic_gold_l2 });
          break;
        default:
          this.form.patchValue({ numberOfUsers: 0 });
          break;
      }
    })
  }

  initForm(){
    combineLatest([this.conf$, this.licence$]).subscribe(([conf,licence]) => {
      this.conf = conf
      if(!this.isNew){
        this.form.patchValue(licence!)
      }
    })
  }

  save(){
    if(this.form.valid){
      this.libService.lockPage('');
      let saveOrUpdate$
      if(this.isNew){
        saveOrUpdate$ = this.licenceService.insertLicense(this.form.getRawValue()).pipe(
          tap(res => {
            console.log('insert response', res);
            this.router.navigate(['ad-license/' + res.id])
          })
        )
      }else{
        saveOrUpdate$ = this.licenceService.updateLicense(this.form.get('id')?.value,this.form.getRawValue())
      }
      saveOrUpdate$.subscribe({
        next: (value) => {
          this.libService.unlockPage();
          this.toastr.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        },
        error:(err) => {
          this.libService.unlockPage();
          this.toastr.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
      })
    }
  }

  setNavBar(){
    let title$ = this.isNew?
      this.translate.get('LABEL.NEW_LICENCE'):
      this.translate.get('LABEL.EDIT_LICENCE')
    title$.subscribe(res => {
      this.navService.setTitle(res);
      this.navService.setSubTitle("EDAC - " + res);
    })

    let actions = [
      { name: 'back', url: 'ad-license' }
    ];
    this.navService.setActions(actions);
  }

}
