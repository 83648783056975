
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, switchMap } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ClaimService } from 'src/app/services/claim.service';
import { LibService } from 'src/app/services/libService';
import { Claim } from 'src/app/models/claim';
import { SubjectService } from 'src/app/services/subject.service';
import { AddEvent, CancelEvent, EditEvent, RemoveEvent, SaveEvent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { ClaimTypesService } from 'src/app/services/claim-types.service';
import { ClaimStatusService } from 'src/app/services/claim-status.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusService } from 'src/app/services/status.service';
import { ComplaintTypesService } from 'src/app/services/complaint-types.service';
import { process } from '@progress/kendo-data-query';

import { Observable, Subject, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DatatableAction } from 'src/app/shared/data-table/data-table.component';
import { BehaviorSubject, of } from 'rxjs';
import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { DocumentsGridComponent } from 'src/app/shared/documents-grid/documents-grid.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component'
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { MatDialog } from '@angular/material/dialog';
import { NgZone, EventEmitter, Input, OnChanges, AfterViewInit, Output, SimpleChanges, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalFormComponent } from 'src/app//forms/modal-form/modal-form.component';


export class DatatableColumn<T> {
  name: string;
  title: Observable<string>;
  type?: 'text' | 'link' | 'icon' | 'templateRef' = 'text';
  flex?: string;
  sticky?: boolean;
  minWidth?: any;
  color?: (element: T) => 'primary' | 'warn' | 'error';
  cssClass?: (element: T) => string[];
  sortDisabled?: boolean;
  routerLink?: (element: T) => string[];
  value(element: T): any {
    return null;
  }
  templateRef?: (element: T) => Observable<TemplateRef<any>>;
  tooltip?: (element: T) => Observable<String>;

  constructor(column: Partial<DatatableColumn<T>>) {
    Object.assign(this, column);
  }
}

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  @Input() itemId: any = null;

  search: any;
  claims: Claim[];
  allClaims: Claim[];
  formClaims: UntypedFormGroup;
  formDates: UntypedFormGroup;
  formAll: UntypedFormGroup;
  claim: Claim;
  claimCommit$: any;
  types: any;
  typesList: Array<string> = [];
  status: any;
  subject: any;
  whileEditing: boolean = false;
  upStatus: boolean;
  disableUpload: boolean = false;

  types$ = this.complaintTypesService.getComplaintTypesByCategory('claims');
  // status$ = this.claimStatusService.getAllClaimStatus();
  
  claimStatus$ = this.statusService.getAll('Claims');

  claims$: Observable<any>;

  constructor(private claimService: ClaimService,
    private fb: UntypedFormBuilder,
    private libService: LibService,
    public subjectService: SubjectService,
    public claimTypesService: ClaimTypesService,
    public complaintTypesService: ComplaintTypesService,
    public claimStatusService: ClaimStatusService,
    public translate: TranslateService,
    private statusService: StatusService,
    private datePipe: DatePipe,
    private documentTypeService: DocumentTypeService,
    private dialog: MatDialog,
    private router: Router,
  ) {

    this.formDates = this.fb.group({
      startDate: [null],
      endDate: [null],
      paymentDateFromEA: [null]
    });

    this.formClaims = this.fb.group({
      id: [null],
      version: [null],
      marshNumberClaim: [null, Validators.required],
      orderNumberEA: [null, Validators.required],
      orderVersion: [null],
      policyNumber: [null],
      delegatingCompany: [null],
      mainWarranty: [null],
      claimCompanyNumber: [null],
      complainant: [null],
      description: [null],
      type: [null],
      typeDescription: [null],
      causeDescription: [null],
      claimStatus: [null],
      claimStatusDescription: [null],
      location: [null],
      province: [null],
      damageValue: [null],
      deductible: [null],
      due: [null],
      paid: [null],
      paidFromEA: [null],
      paymentDateFromEA: [null],
      activities: [null],
      status: [null],
      documents: null

    });
    this.formAll = this.fb.group({
      ...this.formClaims.controls,
      ...this.formDates.controls,
    })
  }

  private timeout?: number;


  dataSource: any;

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;

  filterOptions: any = {};
  currentPage = 0;
  companyIdsFilter: any = []
  responsiblesFilter: any = []
  idsFilter: any = []
  orderOptions: any = {}
  allGlobalDocumentTypes: any = []

  documentTypes: any = []

  test: any = []

  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

  actions: DatatableAction<any>[] = [
    {
      label: of(''),
      icon: item => {
        return 'edit'
      },
      onClick: item => this.openEditDialog(item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'upload_file'
      },
      onClick: item => this.openUploadDialog('new', item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'folder'
      },
      onClick: item => this.viewDocuments(item?.documents),
      color: 'primary'
    },
    /*
    {
      label: of(''),
      icon: subject => {
        return 'delete'
      }, // 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    }
    */
  ];

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }

  loadDataSource(reset: boolean = false) {
    console.log('loadDataSource')
    this.libService.lockPage('');

    /*
      if (reset) {
        this.resetOrder()
        this.resetPagination()
        this.filterOptions.ids = []
      }
        */

      if(this.itemId){
        this.filterOptions.ids = [this.itemId]
      }

    this.claimService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      this.claims = responseData.items;
      this.allClaims = responseData.items;
      this.totalRows = responseData.count;
      this.libService.resetLockPage();
    }, (err: any) => {
      // this.lockrequest = false;
      this.libService.resetLockPage();
      //this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });

  }

  goTo(e: any) {
    this.router.navigate(['request/' + e.id]);
  }

  public openEditDialog(item: any) {

    this.dialog.open(ModalFormComponent, {
      data: {
        type: 'claims',
        item: item,
      },
      width: '70%',
      scrollStrategy: new NoopScrollStrategy(),
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        if (res) {
          this.loadDataSource()
        }
      })
  }

  public openUploadDialog(mode: 'new' | 'current', item: any) {

    if (mode === 'new') {

      let docTypes = item.status?.allowedDocumentTypes.filter((a: any) => a.code === 'SINISTRO' || a.code === 'TRANSITIVO');

      this.documentTypes = [...this.allGlobalDocumentTypes, ...docTypes]

      this.dialog.open(FileUploadModalComponent, {
        data: {
          inputMode: 'claims',
          toEntityId: item.id,
          documentTypes: of(this.documentTypes)
        },
        scrollStrategy: new NoopScrollStrategy(),
      })
        .afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ', res);
          if (res) {
            this.loadDataSource()
          }
        })
    }

  }

  public viewDocuments(documents: any) {
    console.log("DOCUMENTI --> ", documents)
    if (documents.length > 0) {
      let docs = this.dialog.open(DocumentsGridComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });
      let instance = docs.componentInstance;
      instance.documents = documents;
    } else {
      this.dialog.open(SimpleDialogComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });

    }
    // console.log("DOCUMENTS INSIDE -->", );
  }

  displayedColumns: string[] = [
    'id',
    'marshNumberClaim',
    'orderNumberEA',
    'type',
    'status',
    'startDate',
    'endDate',
    'policyNumber',
    'delegatingCompany',
    'mainWarranty',
    'claimCompanyNumber',
    'complainant',
    'description',
    'typeDescription',
    'causeDescription',
    'claimStatusDescription',
    'location',
    'province',
    'damageValue',
    'deductible',
    'due',
    'paid',
    'paidFromEA',
    'paymentDateFromEA'
  ];

  minWidthCell: number = 120;

  columns: DatatableColumn<any>[] = [
    {
      name: "id",
      flex: '7',
      title: this.translate.get('LABEL.ID'),
      cssClass: item => ['column-padding'],
      value: item => item.id,
      minWidth: 50
    },
    {
      name: "marshNumberClaim",
      title: this.translate.get('COMPLAINTS.Numero Marsh'),
      cssClass: item => ['column-padding'],
      value: item => item.marshNumberClaim as string,
      minWidth: this.minWidthCell
    },
    {
      name: "orderNumberEA",
      title: this.translate.get('COMPLAINTS.Pratica EDAC'),
      cssClass: item => ['column-padding'],
      value: item => item.orderNumberEA as string,
      minWidth: this.minWidthCell
    },
    {
      name: "type",
      title: this.translate.get('CLAIMS.TYPE'),
      cssClass: item => ['column-padding'],
      value: item => item?.type?.uiLabel ? this.translate.instant(item?.type?.uiLabel) : item?.type?.uiLabel,
      minWidth: this.minWidthCell
    },
    {
      name: "status",
      title: this.translate.get('COMPLAINTS.STATUS'),
      cssClass: item => ['column-padding'],
      value: item => item?.status?.uiLabel ? this.translate.instant(item?.status?.uiLabel) : item?.status?.uiLabel,
      minWidth: this.minWidthCell
    },
    {
      name: "startDate",
      title: this.translate.get('COMPLAINTS.Data Inizio'),
      cssClass: item => ['column-padding'],
      value: item => item.startDate as string,
      minWidth: this.minWidthCell
    },
    {
      name: "endDate",
      title: this.translate.get('COMPLAINTS.Data Fine'),
      cssClass: item => ['column-padding'],
      value: item => item.endDate as string,
      minWidth: this.minWidthCell
    },
    {
      name: "policyNumber",
      title: this.translate.get('COMPLAINTS.Numero Polizza'),
      cssClass: item => ['column-padding'],
      value: item => item.policyNumber as string,
      minWidth: this.minWidthCell
    },
    {
      name: "delegatingCompany",
      title: this.translate.get('COMPLAINTS.Compagnia Delegataria'),
      cssClass: item => ['column-padding'],
      value: item => item.delegatingCompany as string,
      minWidth: this.minWidthCell
    },
    {
      name: "mainWarranty",
      title: this.translate.get('COMPLAINTS.Garanzia Principale'),
      cssClass: item => ['column-padding'],
      value: item => item.mainWarranty as string,
      minWidth: this.minWidthCell
    },
    {
      name: "claimCompanyNumber",
      title: this.translate.get('COMPLAINTS.Numero Sinistri Compagnia'),
      cssClass: item => ['column-padding'],
      value: item => item.claimCompanyNumber as string,
      minWidth: this.minWidthCell
    },
    {
      name: "complainant",
      title: this.translate.get('COMPLAINTS.Reclamante'),
      cssClass: item => ['column-padding'],
      value: item => `${item?.complainant?.name ? item?.complainant?.name : ''} ${item?.complainant?.surname ? item?.complainant?.surname : ''}`,
      minWidth: this.minWidthCell
    },
    {
      name: "description",
      title: this.translate.get('COMPLAINTS.Descrizione'),
      cssClass: item => ['column-padding'],
      value: item => item.description as string,
      minWidth: this.minWidthCell
    },
    {
      name: "typeDescription",
      title: this.translate.get('COMPLAINTS.Descrizione Tipo'),
      cssClass: item => ['column-padding'],
      value: item => item.typeDescription as string,
      minWidth: this.minWidthCell
    },
    {
      name: "causeDescription",
      title: this.translate.get('COMPLAINTS.Descrizione Causa'),
      cssClass: item => ['column-padding'],
      value: item => item.causeDescription as string,
      minWidth: this.minWidthCell
    },
    {
      name: "claimStatusDescription",
      title: this.translate.get('COMPLAINTS.Descrizione Stato Sinistro'),
      cssClass: item => ['column-padding'],
      value: item => item.claimStatusDescription as string,
      minWidth: this.minWidthCell
    },
    {
      name: "location",
      title: this.translate.get('COMPLAINTS.Localita Sinistro'),
      cssClass: item => ['column-padding'],
      value: item => item.location as string,
      minWidth: this.minWidthCell
    },
    {
      name: "province",
      title: this.translate.get('COMPLAINTS.Provincia Sinistro'),
      cssClass: item => ['column-padding'],
      value: item => item.province as string,
      minWidth: this.minWidthCell
    },
    {
      name: "damageValue",
      title: this.translate.get('COMPLAINTS.Valore del Danno'),
      cssClass: item => ['column-padding'],
      value: item => item.damageValue as string,
      minWidth: this.minWidthCell
    },
    {
      name: "deductible",
      title: this.translate.get('COMPLAINTS.Franchigia'),
      cssClass: item => ['column-padding'],
      value: item => item.deductible as string,
      minWidth: this.minWidthCell
    },
    {
      name: "due",
      title: this.translate.get('COMPLAINTS.Riservato'),
      cssClass: item => ['column-padding'],
      value: item => item.due as string,
      minWidth: this.minWidthCell
    },
    {
      name: "paid",
      title: this.translate.get('COMPLAINTS.Pagato'),
      cssClass: item => ['column-padding'],
      value: item => item.paid as string,
      minWidth: this.minWidthCell
    },
    {
      name: "paidFromEA",
      title: this.translate.get('COMPLAINTS.Pagato Da Ea'),
      cssClass: item => ['column-padding'],
      value: item => item.paidFromEA as string,
      minWidth: this.minWidthCell
    },
    {
      name: "paymentDateFromEA",
      title: this.translate.get('COMPLAINTS.Data Pagamento Edac'),
      cssClass: item => ['column-padding'],
      value: item => item.paymentDateFromEA as string,
      minWidth: this.minWidthCell
    },
  ]

  openEdit(complaintTypeSection: any){
    let queryParams: any = {
      type: 3,
      complaintTypeSection: complaintTypeSection
    }
    this.router.navigate(['request/0'], { queryParams: queryParams });
}

  ngOnInit(): void {
    this.libService.lockPage('');
    if(this.itemId){
      this.filterOptions.ids = [this.itemId]
    }

    this.claims$ = this.claimService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize);
    
    combineLatest([this.claims$, this.types$, this.claimStatus$, this.globalDocumentTypes$]).subscribe({
      next: ([claims, types, status, globalDocumentTypes]) => {
        // console.log("TUTTI I CLAIMS -->", claims)
        this.claims = claims.items;
        this.allClaims = claims.items;
        this.totalRows = claims.count;
        this.allGlobalDocumentTypes = globalDocumentTypes;
        this.types = types;
        this.status = status;
        this.libService.unlockPage();
      },
      error: (error) => {
        this.libService.showMessageError(error.message);
      }
    })
    // console.log("INIT SINISTRI ");

  }

  addHandler(e: AddEvent) {
    this.whileEditing = true;
    console.log("EVENTO AGGIUNGI --> ", e);
    this.formAll.reset();
    console.log("FORM ALL --> ", this.formAll);
    e.sender.addRow(this.formAll);
  }

  removeHandler(e: RemoveEvent) {
    let calls = this.claimService.deleteClaim(e.dataItem.id, e.dataItem).pipe(switchMap((val: any) => {
      return this.claims$
    }));
    this.libService.lockPage('');
    calls.subscribe((val: any) => {
      this.claims = val;
      this.libService.unlockPage();
    })
  }

  saveHandler(e: SaveEvent) {
    this.whileEditing = false;
    this.claim = this.formAll.getRawValue();
    console.log("EVENTO DEL SALVATAGGIO --> ", e);

    const typeCode = this.types.find((arr: any) => { return arr.claimType === this.claim.type });
    this.claim.type = typeCode;
    // const statusCode = this.status.find((arr:any) => { return arr.claimStatus === this.claim.claimStatus});
    // this.claim.claimStatus = statusCode;
    delete this.claim.claimStatus;
    delete this.claim.status;
    console.log("CLAIM DA SALVARE A DB --> ", this.claim);
    e.rowIndex == -1 ?
      this.claimCommit$ = this.claimService.createClaim(this.claim) :
      this.claimCommit$ = this.claimService.updateClaim(this.claim.id?.toString(), this.claim);

    this.libService.lockPage(' ');
    let calls = this.claimCommit$.pipe(switchMap((val: any) => {
      e.sender.closeRow(e.rowIndex);
      return this.claims$;
    }));

    calls.subscribe((val: any) => {
      this.claims = val;
      console.log("CLAIMS --> ", this.claims);
      this.libService.unlockPage();
    })

    this.formClaims.reset();
    this.formDates.reset();
  }

  cancelHandler(e: CancelEvent) {
    this.whileEditing = false;
    console.log("EVENTO CANEL --> ", e);
    e.sender.closeRow(e.rowIndex);
  }

  editHandler(e: EditEvent) {
    this.whileEditing = true;
    console.log("CONTROLLARE LA DATA FINE --> ", e.dataItem);
    for (const property in e.dataItem) {
      (property.toLowerCase().includes("date") && e.dataItem[property] !== null) ?
        e.dataItem[property] = new Date(e.dataItem[property]) : undefined;
    }
    this.formAll.patchValue(e.dataItem);
    console.log("FORM DI TUTTO --> ", this.formAll);

    console.log("STATUS ---> ", e.dataItem.status)
    console.log("TYPE --> ", e.dataItem.type)

    if (e.dataItem.status !== null && e.dataItem.status !== null && (e.dataItem.status.id === 5 || e.dataItem.status.id === 6)) {
      this.disableUpload = true;
    }


    console.log("STATUS ---> ", e.dataItem.status)
    console.log("TYPE --> ", e.dataItem.type)

    if (e.dataItem.status !== null && e.dataItem.status !== null && (e.dataItem.status.id === 5 || e.dataItem.status.id === 6)) {
      this.disableUpload = true;
    }

    this.formClaims.patchValue({
      type: e.dataItem.type,
      claimStatus: e.dataItem.claimStatus?.claimStatus
    });
    console.log("FORM CLAIMS --> ", this.formClaims);
    e.sender.editRow(e.rowIndex, this.formAll);
  }

  setSubject(e: any) {
    console.log("SET SUBJECT --> ", e);
    this.subject = e;
  }

  setFormValue(e: any) {
    console.log("FORM VALUE --> ", e);
    this.formAll.patchValue({
      complainant: this.subject
    })
  }

  onlyNumber(event: any): boolean {
    console.log("ONLY NUMBERS");
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  updateStatus(e: any) {
    this.upStatus = e;
  }

  compareFn(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  onFilter(): void {
    if (!this.search || this.search.length === 0) {
      this.claims = this.allClaims
      return
    }

    this.claims = process(this.allClaims, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "marshNumberClaim",
            operator: "contains",
            value: this.search,
          },
          {
            field: "orderNumberEA",
            operator: "contains",
            value: this.search,
          },
          {
            field: "type",
            operator: function (itemValue: any, value: any) {
              return itemValue?.complaintType && itemValue.complaintType.toLowerCase().includes(value.toLowerCase())
            },
            value: this.search,
          },
          {
            field: "status",
            operator: function (itemValue: any, value: any) {
              return itemValue?.uiLabel && itemValue.uiLabel.toLowerCase().includes(value.toLowerCase())
            },
            value: this.search,
          },
          {
            field: "startDate",
            operator: "contains",
            value: this.search,
          },
          {
            field: "endDate",
            operator: "contains",
            value: this.search,
          },
          {
            field: "policyNumber",
            operator: "contains",
            value: this.search,
          },
          {
            field: "delegatingCompany",
            operator: "contains",
            value: this.search,
          },
          {
            field: "mainWarranty",
            operator: "contains",
            value: this.search,
          },
          {
            field: "claimCompanyNumber",
            operator: "contains",
            value: this.search,
          },
          {
            field: "complainant",
            operator: function (itemValue: any, value: any) {
              return (itemValue?.name && itemValue.name.toLowerCase().includes(value.toLowerCase())) || (itemValue?.surname && itemValue.surname.toLowerCase().includes(value.toLowerCase()))
            },
            value: this.search,
          },
          {
            field: "description",
            operator: "contains",
            value: this.search,
          },
          {
            field: "typeDescription",
            operator: "contains",
            value: this.search,
          },
          {
            field: "causeDescription",
            operator: "contains",
            value: this.search,
          },
          {
            field: "claimStatusDescription",
            operator: "contains",
            value: this.search,
          },
          {
            field: "location",
            operator: "contains",
            value: this.search,
          },
          {
            field: "province",
            operator: "contains",
            value: this.search,
          },
          {
            field: "damageValue",
            operator: "contains",
            value: this.search,
          },
          {
            field: "deductible",
            operator: "contains",
            value: this.search,
          },
          {
            field: "due",
            operator: "contains",
            value: this.search,
          },
          {
            field: "paid",
            operator: "contains",
            value: this.search,
          },
          {
            field: "paidFromEA",
            operator: "contains",
            value: this.search,
          },
          {
            field: "paymentDateFromEA",
            operator: "contains",
            value: this.search,
          },
        ],
      },
    }).data;
    this.dataBinding.skip = 0;
  }

}


