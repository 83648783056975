import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Status } from '../models/status';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  baseApi = environment.rootApiCrm + 'v1.0/status'

  constructor(private libService: LibService) {}

  getAll(statusType: 'Subject'  |'Building' | 'Request' | 'Appointment' | 'Activities' | 'Claims' | 'Contact'){
    return this.libService.getData<Status[]>(`${this.baseApi}`, statusType)
  }

  getById(id: number, statusType: 'Subject' | 'Building'  | 'Request' | 'Appointment' | 'Activities' | 'Claims' | 'Contact'){
    return this.libService.getData<Status>(`${this.baseApi}`, statusType+`/${id}`)
  }
}
