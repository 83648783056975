<div class="full-w" >
  
         <!-- COMPONENTE DOCUMENTALE -->
         <!-- <div *ngIf="checkComponent('crmDocumentManager'); else elseBlock">-->
          <app-crm-document-manager *ngIf="checkComponent('crmDocumentManager') && form"
              [disableUpload]="disableUpload" [components]="components" [documents]="documents" [form]="form"
             [itemType]="itemType" [itemId]="itemId"></app-crm-document-manager>
      <!--</div>
      <ng-template #elseBlock>
          <strong style="color:red">{{'practice_workflow_manager.noDocumentsNeed' | translate}}</strong>
      </ng-template>-->


  <br>

  <app-documents-grid [documents]="documents"></app-documents-grid>
</div>
