<!--
<span>Definire un dominio tramite una query che seleziona tutti gli elementi che quel dominio rappresenta.</span><br>
<span>STEP uno: definire le classi di appartenza del dominio (soggetti, edifici).</span><br>
<span>STEP due: definire le condizioni da applicare alle classi.</span>
<br>
<div class="control_wrapper">
    SOGGETTI
    <ejs-querybuilder width="70%">
        <e-columns>
          <e-column field="SoggettoId" label="Id Soggetto" type="number"></e-column>
          <e-column field="SoggettoType" label="Tipo Soggetto" type="string"></e-column>
          <e-column field="SoggettoNation" label="Nazione" type="string"></e-column>
          <e-column field="SoggettoRegione" label="Regione" type="string"></e-column>
          <e-column field="SoggettoProvincia" label="Provincia" type="string"></e-column>
          <e-column field="SoggettoComune" label="Comune" type="string"></e-column>
          <e-column field="SoggettoCAP" label="CAP" type="string"></e-column>
          <e-column field="SoggettoGeoCode" label="Codice GeoMarketing" type="string"></e-column>
        </e-columns>
      </ejs-querybuilder>
      EDIFICI
      <ejs-querybuilder width="70%">
        <e-columns>
                <e-column field="EdificioId" label="Id Edificio" type="number"></e-column>
                <e-column field="EdificioType" label="Tipo Edificio" type="string"></e-column>
                <e-column field="EdificioNation" label="Nazione" type="string"></e-column>
                <e-column field="EdificioRegione" label="Regione" type="string"></e-column>
                <e-column field="EdificioProvincia" label="Provincia" type="string"></e-column>
                <e-column field="EdificioComune" label="Comune" type="string"></e-column>
                <e-column field="EdificioCAP" label="CAP" type="string"></e-column>
                <e-column field="EdificioGeoCode" label="Codice GeoMarketing" type="string"></e-column>
        </e-columns>
      </ejs-querybuilder>
</div>

<div class="wrap">

    <a ejs-button>Aggiorna</a>
</div>

<div class="wrap">

    <a ejs-button>Consolida</a>
</div>

<div class="wrap">

    <a ejs-button>Cancella</a>
</div>
-->

<div class="container-fluid my-container">
  <div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
      [placeholder]="translate.instant('LABEL.SEARCH')" />
    </div>
  </div>
  <div style="overflow-x: auto;">
  <table mat-table [dataSource]="dataSource" matSort style="width:100%">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.ID' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element">
        {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.NAME' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.name}}</b></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.DESCRIPTION' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element">
        {{element.description}} </td>
    </ng-container>
    <ng-container matColumnDef="howMany">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CARDINALITY' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.howMany}}</b></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CREATION_DATE' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LASTMODIFIED_DATE' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="editAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="goTo(element)"><i class="my-bounce material-icons ic">edit</i>
    </ng-container>

    <ng-container matColumnDef="deleteAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"><i class="my-bounce material-icons ic">delete</i>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  </div>
  <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
  </mat-paginator>
</div>
