<div class="container-fluid ">

    <div class="search-wrapper mat-elevation-z2">
      <div class="search" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
        <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
          placeholder="{{ 'LABEL.SEARCH' | translate }}" />
      </div>
    </div>
    <app-data-table
      [data]="dataSource"
      [columns]="columns"
      [displayedColumns]="displayedColumns"
      [actions]="actions">
    </app-data-table>
    <!-- <div style="overflow-x: auto;">
      <table mat-table [dataSource]="dataSource" matSort style="width:100%">
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.Codice' | translate }}</th>
          <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.code}}</b></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.Versione' | translate }}</th>
          <td class="tdg" mat-cell *matCellDef="let element">
            {{element.version}} </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.VALUE' | translate }}</th>
          <td class="tdg" mat-cell *matCellDef="let element">
            <span *ngIf="element.realPrice > 0">{{element.realPrice | number: '1.2-2'}}</span></td>
        </ng-container>
        <ng-container matColumnDef="subjectId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.SUBJ' | translate }}</th>
          <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.nameSubject}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="buildingId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.BUILDING' | translate }}</th>
          <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.nameBuilding}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.NOTE' | translate }}</th>
          <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.note}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CREATION_DATE' | translate }}</th>
          <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LAST_MODIFIED_DATE' | translate }}</th>
          <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="editAction">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" (click)="goTo(element)"><i class="material-icons ic">edit</i>
        </ng-container>
        <ng-container matColumnDef="goToFinalBalancer">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" (click)="goToFinalBalancer(element)">
            <span [matTooltip]="'LABEL.Consuntivo' | translate">
              <i class="material-icons ic">account_balance_wallet</i>
            </span> 
        </ng-container>
        <ng-container matColumnDef="deleteAction">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element"><i class="material-icons ic">delete</i>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [length]="dataSource.length" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" *ngIf="true">
    </mat-paginator> -->
  </div>
