import { LibService } from 'src/app/services/libService';
import { switchMap, combineLatest } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { TargetsService } from 'src/app/services/targets.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TargetDetailComponent } from '../target-detail/target-detail.component';

@Component({
  selector: 'app-targets-list',
  templateUrl: './targets-list.component.html',
  styleUrls: ['./targets-list.component.scss']
})
export class TargetsListComponent implements OnInit {

  targets: any;
  displayedColumns = ['id', 'description', 'entityName', 'editAction', 'deleteAction'];

  targetsCompany$ = this.targetService.getTargetTypesCompany();
  targetProfile$ = this.targetService.getTargetTypesProfile();


  constructor(private targetService: TargetsService,
              private libService: LibService,
              private router: Router,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.libService.lockPage('');
    combineLatest([this.targetsCompany$, this.targetProfile$]).subscribe({
      next: ([targetsCompany, targetsProfile]: any) => {
        this.targets = [...targetsCompany, ...targetsProfile];
        this.targets.sort((a: any, b: any) =>  a.description-b.description);
        console.log("TARGETS TOTALI  --> ", this.targets);
        this.libService.unlockPage();
      }
    })

    let actions : any;
    actions = [
      {name:'new',url:'target/0'},
      {name:'export',url:''}
    ];
  }

  goTo(e: any) {
    this.router.navigate(['target/' + e.id]);
  }

  deleteRow(e: any) {

  }

  create() {
    this.router.navigate(['target/0']);
  }
}
