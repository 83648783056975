<div class="full-w">

  <form [formGroup]="form" (submit)="update()" class="form-container">

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.STATUS' | translate }}</mat-label>
      <input disabled matInput [value]="item.status.code" [placeholder]="translate.instant('COMPLAINTS.Company')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.TYPE' | translate }}</mat-label>
      <input matInput disabled [value]="item.complaintType.complaintType"
        [placeholder]="translate.instant('COMPLAINTS.TYPE')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Company' | translate }}</mat-label>
      <input disabled matInput [value]="item.company" [placeholder]="translate.instant('COMPLAINTS.Company')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Pratica EDAC' | translate }}</mat-label>
      <input matInput disabled [value]="item.orderNumberEA"
        [placeholder]="translate.instant('COMPLAINTS.Pratica EDAC')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.Versione' | translate }}</mat-label>
      <input matInput disabled [value]="item.orderVersion" [placeholder]="translate.instant('LABEL.Versione')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Area Manager' | translate }}</mat-label>
      <input matInput disabled [value]="item.managerArea" [placeholder]="translate.instant('COMPLAINTS.Area Manager')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data creazione' | translate }}</mat-label>
      <input matInput disabled [value]="item.creationDate"
        [placeholder]="translate.instant('COMPLAINTS.Data creazione')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data chiusura' | translate }}</mat-label>
      <input matInput disabled [value]="item.closingDate" [placeholder]="translate.instant('COMPLAINTS.Data chiusura')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Ritardo' | translate }}</mat-label>
      <input matInput disabled [value]="item.delay" [placeholder]="translate.instant('COMPLAINTS.Ritardo')">
    </mat-form-field>

    <br>

    <div class="cardBox form-subject">

      <mat-card class="cardItem">
        <mat-card-header>
          <mat-card-title>
            Soggetti Coinvolti
          </mat-card-title>
        </mat-card-header>
        <mat-card-subtitle><button type="button" class="btn btn-primary ps-7 text-dark"
            (click)="addRelationshipWithSubject()">Aggiungi Soggetto</button></mat-card-subtitle>
        <mat-card-content>

          <ng-container formArrayName="relationshipWithSubjects">

            <div *ngFor="let relationshipWithSubject of relationshipWithSubjects.controls; let p = index"
              class="boxSoggetto">
              <div [formGroup]="relationshipWithSubject" class="form-subject languageCodeBox">

                <mat-form-field appearance="fill" class="form-subject">
                  <mat-label>{{ 'LABEL.TipologiaRelazioneConSoggetto' | translate }}</mat-label>
                  <mat-select formControlName="subjectRelationshipTypeId">
                    <mat-option *ngFor="let subjectRelationshipType of subjectRelationshipTypes"
                      [value]="subjectRelationshipType.id" [disabled]="subjectRelationshipType.disabled">
                      {{ 'ORIGINS.'+subjectRelationshipType.code | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <app-search-or-create-user [form]="relationshipWithSubject"></app-search-or-create-user>


              </div>
            </div>

          </ng-container>


        </mat-card-content>
      </mat-card>
    </div>

    <br>

    <div class="buttonBox">
      <button [disabled]="!form.valid" class="submitButton" type="submit" mat-raised-button color="primary">
        <mat-icon>save</mat-icon> {{ 'LABEL.SAVE' | translate }}
      </button>
    </div>
    <br>
  </form>

</div>