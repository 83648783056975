import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddEvent, CancelEvent, EditEvent, RemoveEvent, SaveEvent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { combineLatest, switchMap } from 'rxjs';
import { Legal } from 'src/app/models/legal';
import { LegalService } from 'src/app/services/legal.service';
import { LibService } from 'src/app/services/libService';
import { SubjectService } from 'src/app/services/subject.service';
import { process } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DatatableAction } from 'src/app/shared/data-table/data-table.component';
import { BehaviorSubject, of } from 'rxjs';
import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { DocumentsGridComponent } from 'src/app/shared/documents-grid/documents-grid.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component'
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { MatDialog } from '@angular/material/dialog';
import { NgZone, EventEmitter, Input, OnChanges, AfterViewInit, Output, SimpleChanges, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalFormComponent } from 'src/app//forms/modal-form/modal-form.component';

export class DatatableColumn<T> {
  name: string;
  title: Observable<string>;
  type?: 'text' | 'link' | 'icon' | 'templateRef' = 'text';
  flex?: string;
  sticky?: boolean;
  color?: (element: T) => 'primary' | 'warn' | 'error';
  cssClass?: (element: T) => string[];
  sortDisabled?: boolean;
  routerLink?: (element: T) => string[];
  value(element: T): any {
    return null;
  }
  templateRef?: (element: T) => Observable<TemplateRef<any>>;
  tooltip?: (element: T) => Observable<String>;

  constructor(column: Partial<DatatableColumn<T>>) {
    Object.assign(this, column);
  }
}


@Component({
  selector: 'app-legals',
  templateUrl: './legals.component.html',
  styleUrls: ['./legals.component.scss']
})
export class LegalsComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  search: any;

  @Input() itemId: any = null;

  legals: Legal[];
  allLegals: Legal[];
  legal: Legal;
  formLegal: UntypedFormGroup;
  formDates: UntypedFormGroup;
  formAll: UntypedFormGroup;
  legalsYesNo: any;
  yesNo: any;
  whileEditing: boolean = false;

  legalCommit$: any;

  legalsYesNo$ = this.legalService.getAllLegalsYesNo();
  legals$ = this.legalService.allWithPagination();



  constructor(private libService: LibService,
    private legalService: LegalService,
    public subjectService: SubjectService,
    public fb: UntypedFormBuilder,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private documentTypeService: DocumentTypeService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.formLegal = this.fb.group({
      id: [null],
      // version: [null],
      orderNumberEA: [null, Validators.required],
      year: [null],
      magistrateATP: [null],
      outOfCourt: [null],
      mediationAgreement: [null],
      company: [null],
      companyCity: [null],
      province: [null],
      promotingSubject: [null],
      lawFirm: [null],
      legalUpdates: [null],
      legalStatus: [null],
      note: [null],
      legalOutgoings: [null],
      agreementOutgoings: [null]
    });

    this.formDates = this.fb.group({
      measureDate: [null]
    });

    this.formAll = this.fb.group({
      ...this.formLegal.controls,
      ...this.formDates.controls
    });
  }

  private timeout?: number;


  dataSource: any;

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;

  filterOptions: any = {};
  currentPage = 0;
  companyIdsFilter: any = []
  responsiblesFilter: any = []
  idsFilter: any = []
  orderOptions: any = {}
  allGlobalDocumentTypes: any = []

  documentTypes: any = []

  test: any = []

  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

  actions: DatatableAction<any>[] = [
    {
      label: of(''),
      icon: item => {
        return 'edit'
      },
      onClick: item => this.openEditDialog(item),
      color: 'primary'
    },
    /*
    {
      label: of(''),
      icon: item => {
        return 'upload_file'
      },
      onClick: item => this.openUploadDialog('new', item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'folder'
      },
      onClick: item => this.viewDocuments(item?.documents),
      color: 'primary'
    },
    */
    /*
    {
      label: of(''),
      icon: subject => {
        return 'delete'
      }, // 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    }
    */
  ];

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }

  loadDataSource(reset: boolean = false) {
    console.log('loadDataSource')
    this.libService.lockPage('');

    /*
      if (reset) {
        this.resetOrder()
        this.resetPagination()
        this.filterOptions.ids = []
      }
        */

      if(this.itemId){
        this.filterOptions.ids = [this.itemId]
      }

    this.legalService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      this.legals = responseData.items;
      this.allLegals = responseData.items;
      this.totalRows = responseData.count;
      this.libService.resetLockPage();
    }, (err: any) => {
      // this.lockrequest = false;
      this.libService.resetLockPage();
      //this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });

  }

  goTo(e: any) {
    this.router.navigate(['request/' + e.id]);
  }

  public openEditDialog(item: any) {

    this.dialog.open(ModalFormComponent, {
      data: {
        type: 'legals',
        item: item,
      },
      width: '70%',
      scrollStrategy: new NoopScrollStrategy(),
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        if (res) {
          this.loadDataSource()
        }
      })
  }

  public openUploadDialog(mode: 'new' | 'current', item: any) {

    if (mode === 'new') {

      let docTypes = item.status?.allowedDocumentTypes.filter((a: any) => a.code != 'SINISTRO' || a.code != 'TRANSITIVO');

      this.documentTypes = [...this.allGlobalDocumentTypes, ...docTypes]

      this.dialog.open(FileUploadModalComponent, {
        data: {
          inputMode: 'legals',
          toEntityId: item.id,
          documentTypes: of(this.documentTypes)
        },
        scrollStrategy: new NoopScrollStrategy(),
      })
        .afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ', res);
          //this.subject.documents.push(res)
          if (res) {
            // this.form?.get('documents')?.setValue([...this.form?.get('documents')?.value, res.document])
            // this.updateStatus.emit(false);
          }
        })
    }

  }

  public viewDocuments(documents: any) {
    console.log("DOCUMENTI --> ", documents)
    if (documents.length > 0) {
      let docs = this.dialog.open(DocumentsGridComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });
      let instance = docs.componentInstance;
      instance.documents = documents;
    } else {
      this.dialog.open(SimpleDialogComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });

    }
    // console.log("DOCUMENTS INSIDE -->", );
  }

  displayedColumns: string[] = [
    'id',
    'orderNumberEA',
    'magistrateATP',
    'outOfCourt',
    'measureDate',
    'year',
    'mediationAgreement',
    'company',
    'companyCity',
    'province',
    'promotingSubject',
    'lawFirm',
    'legalUpdates',
    'status',
    'note',
    'legalOutgoings',
    'agreementOutgoings'
  ];

  columns: DatatableColumn<any>[] = [
    {
      name: "id",
      flex: '7',
      title: this.translate.get('LABEL.ID'),
      cssClass: item => ['column-padding'],
      value: item => item.id
    },
    {
      name: "orderNumberEA",
      title: this.translate.get('COMPLAINTS.Pratica EDAC'),
      cssClass: item => ['column-padding'],
      value: item => item.orderNumberEA as string,
    },
    {
      name: "magistrateATP",
      title: this.translate.get('COMPLAINTS.Giudice ATP'),
      cssClass: item => ['column-padding'],
      value: item => item.magistrateATP as string,
    },
    {
      name: "outOfCourt",
      title: this.translate.get('COMPLAINTS.Stragiudiziali'),
      cssClass: item => ['column-padding'],
      value: item => item.outOfCourt as string,
    },
    {
      name: "measureDate",
      title: this.translate.get('COMPLAINTS.Data provvedimento'),
      cssClass: item => ['column-padding'],
      value: item => item.measureDate as string,
    },
    {
      name: "year",
      title: this.translate.get('COMPLAINTS.Anno'),
      cssClass: item => ['column-padding'],
      value: item => item.year as string,
    },
    {
      name: "mediationAgreement",
      title: this.translate.get('LEGALS.MEDIATION_AGREEMENT'),
      cssClass: item => ['column-padding'],
      value: item => item.mediationAgreement as string,
    },
    {
      name: "company",
      title: this.translate.get('COMPLAINTS.Compagnia'),
      cssClass: item => ['column-padding'],
      value: item => item.company as string,
    },
    {
      name: "companyCity",
      title: this.translate.get('COMPLAINTS.Citta Compagnia'),
      cssClass: item => ['column-padding'],
      value: item => item.companyCity as string,
    },
    {
      name: "province",
      title: this.translate.get('COMPLAINTS.Provincia'),
      cssClass: item => ['column-padding'],
      value: item => item.province as string,
    },
    {
      name: "promotingSubject",
      title: this.translate.get('COMPLAINTS.Soggetto Promotore'),
      cssClass: item => ['column-padding'],
      value: item => item.promotingSubject as string,
    },
    {
      name: "lawFirm",
      title: this.translate.get('COMPLAINTS.Studio Legale'),
      cssClass: item => ['column-padding'],
      value: item => item.lawFirm as string,
    },
    {
      name: "legalUpdates",
      title: this.translate.get('COMPLAINTS.Aggiornamento Dai Legali'),
      cssClass: item => ['column-padding'],
      value: item => item.legalUpdates as string,
    },
    {
      name: "status",
      title: this.translate.get('COMPLAINTS.Stato Provv'),
      cssClass: item => ['column-padding'],
      value: item => item?.status?.uiLabel ? this.translate.instant(item?.status?.uiLabel) : item?.status?.uiLabel
    },
    {
      name: "note",
      title: this.translate.get('COMPLAINTS.Note'),
      cssClass: item => ['column-padding'],
      value: item => item.note as string,
    },
    {
      name: "legalOutgoings",
      title: this.translate.get('COMPLAINTS.Spese Legali'),
      cssClass: item => ['column-padding'],
      value: item => item.legalOutgoings as string,
    },
    {
      name: "agreementOutgoings",
      title: this.translate.get('COMPLAINTS.Spese Accordi'),
      cssClass: item => ['column-padding'],
      value: item => item.agreementOutgoings as string,
    },
    
  ]

  openEdit(complaintTypeSection: any){
    let queryParams: any = {
      type: 3,
      complaintTypeSection: complaintTypeSection
    }
    this.router.navigate(['request/0'], { queryParams: queryParams });
}

  ngOnInit(): void {
    let val: any;
    if(this.itemId){
      this.filterOptions.ids = [this.itemId]
    }

    this.legals$ = this.legalService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize);


    combineLatest([this.legals$, this.legalsYesNo$, this.globalDocumentTypes$]).subscribe({
      next: ([legals, yesno, globalDocumentTypes]: any) => {
        this.legals = legals.items;
        this.allLegals = legals.items;
        this.totalRows = legals.count;
        this.allGlobalDocumentTypes = globalDocumentTypes;
        this.legalsYesNo = yesno;
        val = this.legalsYesNo.map((u: any) => Object.assign({}, u));
        val.pop();
        this.yesNo = val;
      },
      error: (error) => {
        this.libService.showMessageError(error.message);
      }
    })
  }

  addHandler(e: AddEvent) {
    this.whileEditing = true;
    console.log("EVENTO AGGIUNGI --> ", e);
    this.formLegal.reset;
    this.formDates.reset;
    this.formAll.reset();
    console.log("FORM ALL --> ", this.formAll);
    e.sender.addRow(this.formAll);

  }

  removeHandler(e: RemoveEvent) {
    let calls = this.legalService.deleteLegal(e.dataItem.id, e.dataItem).pipe(switchMap((val: any) => {
      return this.legals$
    }));
    this.libService.lockPage('');
    calls.subscribe((val: any) => {
      this.legals = val;
      this.libService.unlockPage();
    })
  }

  saveHandler(e: SaveEvent) {
    this.whileEditing = false;
    this.legal = this.formAll.getRawValue();

    // this.complaint.creationDate = this.formDates.get('startDate')?.value;
    // this.complaint.closingDate = this.formDates.get('endDate')?.value;
    // this.complaint.deliberateDate = this.formDates.get('paymentDateFromEA')?.value;
    const outOfCourt = this.legalsYesNo.find((arr: any) => { return arr.value === this.legal.outOfCourt });
    this.legal.outOfCourt = outOfCourt;
    const magistrateATP = this.legalsYesNo.find((arr: any) => { return arr.value === this.legal.magistrateATP });
    this.legal.magistrateATP = magistrateATP;
    const mediation = this.legalsYesNo.find((arr: any) => { return arr.value === this.legal.mediationAgreement });
    this.legal.mediationAgreement = mediation;

    console.log("EVENTO DEL SALVATAGGIO --> ", e);
    console.log("LEGAL DA SALVARE A DB --> ", this.legal);

    e.rowIndex == -1 ?
      this.legalCommit$ = this.legalService.createLegal(this.legal) :
      this.legalCommit$ = this.legalService.updateLegal(this.legal.id?.toString(), this.legal);

    this.libService.lockPage(' ');
    let calls = this.legalCommit$.pipe(switchMap((val: any) => {
      e.sender.closeRow(e.rowIndex);
      return this.legals$
    }));

    calls.subscribe((val: any) => {
      this.legals = val;
      this.libService.unlockPage();
    })

    this.formLegal.reset;
    this.formDates.reset;
  }

  cancelHandler(e: CancelEvent) {
    this.whileEditing = false;
    e.sender.closeRow(e.rowIndex);
  }

  editHandler(e: EditEvent) {
    this.whileEditing = true;
    console.log("CONTROLLARE LA DATA FINE --> ", e.dataItem)
    for (const property in e.dataItem) {
      (property.toLowerCase().includes("date") && e.dataItem[property] !== null) ?
        e.dataItem[property] = new Date(e.dataItem[property]) : undefined;
    }
    this.formAll.patchValue(e.dataItem);

    this.formLegal.patchValue({
      magistrateATP: e.dataItem.magistrateATP?.value,
      outOfCourt: e.dataItem.outOfCourt?.value,
      mediationAgreement: e.dataItem.mediationAgreement?.value,

    });
    console.log("FORM DI TUTTO --> ", this.formAll);
    e.sender.editRow(e.rowIndex, this.formAll);

  }

  setSubject(e: any) {
    console.log("EVENTO SELEZIONE SOGGETTO --> ", e);
    this.formLegal.patchValue({
      promotingSubject: e
    })
  }

  setFormValue(e: any) {
    console.log("EVENTO SET FORM VALUE --> ", e);
  }

  onFilter(): void {
    window.clearTimeout(this.timeout);

    if (!this.search || this.search.length == 0) {
      this.legals = this.allLegals
      this.filterOptions.search = null

    } else {
      this.filterOptions.search = this.search
    }

    if (this.search.length < 2 && this.search.length != 0) return

    this.timeout = window.setTimeout(() => this.loadDataSource(), 500);
  }


}
