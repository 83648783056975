import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class HolidayCalendarService extends BaseService {

  constructor(
    public LibService: LibService,
    public AuthService: AuthService,
    public userSessionService: UserSessionService,
    public guardService: GuardService
  ) {
    super(LibService, AuthService)
    this.baseApi = environment.rootApiFoundation
    this.model = 'holidayCalendars'
  }

  deleteByCode(code: string): Observable<any> {
    return this.LibService.deleteDataWithAccessToken<any>(
      this.tokenId,
      {},
      this.baseApi,
      `${this.model}/code/${code}`
    );
  }

  deleteByCodeAndCreate(code: string, newEventData: any): Observable<any> {
    return this.LibService.postDataWithAccessToken<any>(
      this.tokenId,
      newEventData,
      this.baseApi,
      `${this.model}/${code}/deleteAndCreate`
    );
  }

  getUniqueEvents(): Observable<any> {
    return this.LibService.getDataWithAccessToken<any>(
      this.tokenId,
      this.baseApi,
      `${this.model}/unique`
    );
  }



}