import { Component, OnInit } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LicenceService } from '../../services/licenseService';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../services/libService';
import { Observable } from 'rxjs';
import { ConfService } from '../../services/conf.service';
import { TranslatorService } from 'src/app/services/translate.service';


@Component({
  selector: 'app-ad-lic-dtail',
  templateUrl: './ad-lic-dtail.component.html',
  styleUrls: ['./ad-lic-dtail.component.scss']
})
export class AdLicDtailComponent implements OnInit {
  title = "Nuova Licenza";
  subTitle = "EDAC - Nuova Licenza";
  form: UntypedFormGroup;
  isNew: boolean = true;
  conf: any;
  id: any;
  actualLic: any = {};
  confs!: Observable<any>;

  constructor(
    public navService: NavService,
    private router: Router,
    private route: ActivatedRoute,
    public licenseService: LicenceService,
    private fb: UntypedFormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public confService: ConfService,
    public translate: TranslatorService
  ) { }

  onChangeTipoLic(t: any) {
    console.log('select change %o for check in %o', t.value, this.conf);
    switch (t.value) {
      case 'silver':
        this.form.patchValue({ numeroutenti: this.conf.n_utenti_lic_silver });
        this.form.patchValue({ costo: this.conf.cost_lic_silver });
        break;
      case 'bronze':
        this.form.patchValue({ numeroutenti: this.conf.n_utenti_lic_bronze });
        this.form.patchValue({ costo: this.conf.cost_lic_bronze });
        break;
      case 'gold':
        this.form.patchValue({ numeroutenti: this.conf.n_utenti_lic_gold });
        this.form.patchValue({ costo: this.conf.cost_lic_gold });
        break;
      case 'silver_l2':
        this.form.patchValue({ numeroutenti: this.conf.n_utenti_lic_silver_l2 });
        this.form.patchValue({ costo: this.conf.cost_lic_silver_l2 });
        break;
      case 'bronze_l2':
        this.form.patchValue({ numeroutenti: this.conf.n_utenti_lic_bronze_l2 });
        this.form.patchValue({ costo: this.conf.cost_lic_bronze_l2 });
        break;
      case 'gold_l2':
        this.form.patchValue({ numeroutenti: this.conf.n_utenti_lic_gold_l2 });
        this.form.patchValue({ costo: this.conf.cost_lic_gold_l2 });
        break;
      default:
        this.form.patchValue({ numeroutenti: 0 });
        break;
    }
  }

  loadConfs(): void {
    this.libService.lockPage('');
    this.confs = this.confService.getAllConfs();
    this.confs.subscribe(
      c => {
        console.log('get conf %o', c);
        this.libService.unlockPage();
        this.conf = c[0];
        if (this.id && parseInt(this.id) > 0) {
          this.isNew = false;
          this.libService.lockPage('');
          this.licenseService.getLicense(this.id).subscribe(
            l => {
              console.log('licnse find %o', l);
              this.libService.unlockPage();
              this.form = this.fb.group({
                datainizio: [null, [Validators.required]],
                datafine: [null],
                tipo: ['bronze', [Validators.required]],
                numeroutenti: [this.conf.n_utenti_lic_bronze, [Validators.required]],
                costo: [this.conf.cost_lic_bronze, [Validators.required]],
                open: [false, [Validators.required]]
              });
            },
            err => {
              this.libService.unlockPage();
              this.toastr.error(this.translate.instant('TOASTR.MESSAGE_4'), this.translate.instant('TOASTR.WARNING'));
            }
          );

        }
        else {
          this.isNew = true;
          this.form = this.fb.group({
            datainizio: [null, [Validators.required]],
            datafine: [null],
            tipo: ['bronze', [Validators.required]],
            numeroutenti: [this.conf.n_utenti_lic_bronze, [Validators.required]],
            costo: [this.conf.cost_lic_bronze, [Validators.required]],
            open: [false, [Validators.required]]
          });
        }
      },
      err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_5'), this.translate.instant('TOASTR.WARNING'));
      }
    );
  }

  loadLabels() {
    this.translate.get('LABEL.NEW_LICENCE').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.loadLabels();
    console.log('conf in manage lic %o', this.conf);
    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id && parseInt(this.id) > 0) {
      this.title = this.translate.instant('LICENSES.EDIT');
      this.subTitle = this.translate.instant('LICENSES.EDAC_EDIT');
    }
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');

    let actions: any;
    actions = [
      { name: 'back', url: 'ad-license' }
    ];
    this.navService.setActions(actions);
    this.loadConfs();
  }

  update() {

    this.actualLic.startDate = this.form.value.datainizio;
    this.actualLic.endDate = this.form.value.datafine;
    this.actualLic.type = this.form.value.tipo;
    this.actualLic.numberOfUsers = this.form.value.numeroutenti;
    this.actualLic.cost = this.form.value.costo;
    this.actualLic.isOpen = this.form.value.open;

    console.log('update lic from from %o to %o', this.form.value, this.actualLic);

    this.libService.lockPage('');
    if (this.form.valid) {
      if (this.isNew == false) {
        this.licenseService.updateLicense(this.id, this.actualLic).subscribe((response) => {
          this.libService.unlockPage();
          console.log('res update licence %o', response);
          // this.subject = response;
          this.toastr.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
          console.log(response);
        },
          err => {
            this.libService.unlockPage();
            this.toastr.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
          });
      }
      else {
        this.licenseService.insertLicense(this.actualLic).subscribe((response) => {
          this.libService.unlockPage();
          console.log('res insert licence %o', response);
          // this.subject = response;
          this.toastr.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
          console.log(response);
        },
          err => {
            this.libService.unlockPage();
            this.toastr.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
          });
      }
    }
    else {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE_1'), this.translate.instant('TOASTR.WARNING'));
    }

  }

}
