<div mat-dialog-content class="Tooltip">
  <h1 mat-dialog-title>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.AGGIUNGI_CALENDARIO_ASSENZA' | translate }}</h1>
  <p>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.DESCRIZIONE_FORM_CALENDARIO' | translate }}</p>
  <form [formGroup]="form" class="d-flex flex-column">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.NOME_CALENDARIO' | translate }}</mat-label>
      <input matInput formControlName="description" placeholder="Inserisci nome calendario">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_NAZIONE' | translate }}</mat-label>
      <mat-select formControlName="CountryId">
        <input class="input_padd" matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="countrySearchControl">
        <mat-option *ngFor="let country of filteredCountries" [value]="country">
          {{ country }}
          <span *ngIf="form.get('CountryId')?.value === country" class="clear-container">
            <!-- Usa Font Awesome o Mat Icon per la "x" -->
            <i class="fas fa-times clear-button" (click)="onDeselect($event, 'CountryId')"></i>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_SOCIETA' | translate }}</mat-label>
      <mat-select formControlName="SocietyId">
        <input class="input_padd" matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="societySearchControl">
        <mat-option *ngFor="let society of filteredSocieties" [value]="society.id">
          {{ society.name }}
          <span *ngIf="form.get('SocietyId')?.value === society.id" class="clear-container">
            <i class="fas fa-times clear-button" (click)="onDeselect($event, 'SocietyId')"></i>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_SEDE' | translate }}</mat-label>
      <mat-select formControlName="CompanyId">
        <input class="input_padd" matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="companySearchControl">
        <mat-option *ngFor="let company of filteredCompanies" [value]="company.id">
          {{ company.name }}
          <span *ngIf="form.get('CompanyId')?.value === company.id" class="clear-container">
            <i class="fas fa-times clear-button" (click)="onDeselect($event, 'CompanyId')"></i>
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'COMPANIES.START_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="startDate" formControlName="startDate" />
      <mat-hint>GG/MM/AAAA</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'COMPANIES.END_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDate" [min]="minEndDate | date : 'yyyy-MM-dd'"
        [max]="maxEndDate | date : 'yyyy-MM-dd'" formControlName="endDate" />
      <mat-hint>GG/MM/AAAA</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.ORE' | translate }}</mat-label>
      <input matInput formControlName="hours" type="number" min="1" max="8" placeholder="Inserisci ore" class="hours">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_LA_CAUSALE_DI_ASSENZA_LAVORO' | translate }}</mat-label>
      <mat-select formControlName="WorkCausalId">
        <input class="input_padd" matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="causalSearchControl">
        <mat-option *ngFor="let workCausal of filteredCausals" [value]="workCausal.id">
          {{ workCausal.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox formControlName="cyclical">
      {{ 'PLANNER.PLANNER_CALENDAR_DIALOG.CYCLICAL' | translate }}
    </mat-checkbox>
  </form>
  <mat-dialog-actions>
    <button mat-button (click)="onCancel()">{{ 'LABEL.CANCEL' | translate }}</button>
    <button mat-button (click)="onSave()" color="primary" [disabled]="!form.valid">{{ 'PROFILES.SAVE' | translate
      }}</button>
  </mat-dialog-actions>
</div>