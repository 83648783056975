import { CalendarConfig, ProjectModelConfig } from '@bryntum/calendar-thin';
import { environment } from 'src/environments/environment';
import { TranslatorService } from 'src/app/services/translate.service';




export const projectConfig: Partial<ProjectModelConfig> = {
  // Empty project config
};

const baseApi = environment.rootApiFoundation;

// export const calendarConfig: Partial<CalendarConfig> = {



  
//   date: new Date(),
//   timeZone: 0,

//   crudManager: {
//     // autoSync: true,
//     transport: {
//       load: {
//         url: `${baseApi}/holidayCalendars/`,
//         credentials: 'omit',
//       },
//     },
//   },
//   modes: {
//     day: null,
//     week: null,
//     month: {
//       showResourceAvatars: true,
//       autoCreate: true
//     },
//     year: {
//       autoCreate: true
//     },
//     agenda: null
//   },
//   features: {

//     eventTooltip: {
//       align: 'b-t',
//       showOn: 'mouseover', // Specifica l'evento per mostrare il tooltip
//       renderer: (eventRecord: any) => {

//         let templateTooltip = `<dl>
//         ${console.log(eventRecord),
//           eventRecord.eventRecord.get('code')
//             ? `<dt>
//                 translate.instant('LABEL.INS_USER_NUMB'):</dt><dd>${eventRecord.eventRecord.data.countryCode}</dd>`
//             : ''
//           }
//         </dl>`

//         return templateTooltip

//       },
//     }
//   },
// };
