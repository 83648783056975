<div mat-content class="Tooltip container">
    <h1 class="mt-5 mb-7">{{ formTitle }}</h1>
    <form [formGroup]="form" class="d-flex flex-column">
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.NOME_CONTRATTO' | translate }}</mat-label>
            <input matInput formControlName="description"
                placeholder="{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.NOME_CONTRATTO_PLACEHOLDER' | translate }}">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_NAZIONE' | translate }}</mat-label>
            <mat-select formControlName="countries" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="countrySearchControl"
                    class="padd-input">
                <mat-option *ngFor="let country of filteredCountries" [value]="country.id">
                    {{ country.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_SOCIETA' | translate }}</mat-label>
            <mat-select formControlName="societies" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="societySearchControl"
                    class="padd-input">
                <mat-option *ngFor="let society of filteredSocieties" [value]="society.id">
                    {{ society.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_SEDE' | translate }}</mat-label>
            <mat-select formControlName="companies" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="companySearchControl"
                    class="padd-input">
                <mat-option *ngFor="let company of filteredCompanies" [value]="company.id">
                    {{ company.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.PROFILES' | translate }}</mat-label>
            <mat-select formControlName="profiles" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="profilesSearchControl"
                    class="padd-input">
                <mat-option *ngFor="let profile of filteredProfiles" [value]="profile.id">
                    {{ profile.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.BRANCHES' | translate }}</mat-label>
            <mat-select formControlName="branches" multiple>
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="branchesSearchControl"
                    class="padd-input">
                <mat-option *ngFor="let branche of filteredBranches" [value]="branche.id">
                    {{ branche.name }} ({{ branche.codeLang }})
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.MASSIMO_ORE_ORDINARIE_SETTIMANALI' | translate }}</mat-label>
            <input matInput formControlName="maximumWeeklyHours" type="number" min="0"
                placeholder="{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.INSERISCI_ORE_ORDINARIE_SETTIMANALI' | translate }}"
                class="hours">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.MASSIMO_ORE_EXTRA_SETTIMANALI' | translate }}</mat-label>
            <input matInput formControlName="maximumExtraHoursPerWeek" type="number" min="0"
                placeholder="{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.INSERISCI_ORE_EXTRA_SETTIMANALI' | translate }}"
                class="hours">
        </mat-form-field>

        <div class="boxDocument">
            <mat-card-header class="justify-content-center">
                <mat-card-title>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.GESTIONE_DATE_CREAZIONE_CONTRATTO' | translate
                    }}</mat-card-title>
            </mat-card-header>
            <p>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.DESCRIZIONE_PERIODI_CONTRATTO' | translate }}</p>

            <mat-form-field appearance="fill">
                <mat-label>{{ 'COMPANIES.START_DATE' | translate }} - {{
                    'PLANNER.PLANNER_CALENDAR_DIALOG.IMPOSTA_DATA_INIZIO_CONTRATTO' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDateOfValidity" formControlName="startDateOfValidity" />
                <mat-hint>GG/MM</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDateOfValidity"></mat-datepicker-toggle>
                <mat-datepicker #startDateOfValidity></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'COMPANIES.END_DATE' | translate }} - {{
                    'PLANNER.PLANNER_CALENDAR_DIALOG.IMPOSTA_DATA_FINE_CONTRATTO' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDateOfValidity" formControlName="endDateOfValidity" />
                <mat-hint>GG/MM</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDateOfValidity"></mat-datepicker-toggle>
                <mat-datepicker #endDateOfValidity></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'COMPANIES.START_DATE' | translate }} - {{
                    'PLANNER.PLANNER_CALENDAR_DIALOG.DATA_INIZIO_VALIDITA_CONTRATTO' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" />
                <mat-hint>GG/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'COMPANIES.END_DATE' | translate }} - {{
                    'PLANNER.PLANNER_CALENDAR_DIALOG.DATA_FINE_VALIDITA_CONTRATTO' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" [min]="minEndDate | date : 'yyyy-MM-dd'"
                    formControlName="endDate" />
                <mat-hint>GG/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="boxDocument">
            <mat-card-header class="justify-content-center">
                <mat-card-title>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_CAUSALE_ASSENZA' | translate
                    }}</mat-card-title>
            </mat-card-header>
            <p>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.DESCRIZIONE_CAUSALE_ASSENZA' | translate }}</p>
            <!-- <mat-form-field appearance="fill">
                <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_LA_CAUSALE_DI_ASSENZA_LAVORO' | translate
                    }}</mat-label>
                <mat-select formControlName="workCausals" multiple>
                    <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                        [formControl]="causalSearchControl" class="padd-input">
                    <mat-option *ngFor="let workCausal of filteredCausals" [value]="workCausal.id">
                        {{ workCausal.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
            <mat-form-field appearance="fill">
                <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_LA_CAUSALE_DI_ASSENZA_LAVORO' | translate
                    }}</mat-label>
                <mat-select formControlName="workCausals" multiple>

                    <!-- Checkbox per selezionare/deselezionare tutti -->
                    <mat-checkbox class="padd-input-select-all" [checked]="areAllSelected()" (change)="toggleAllSelection($event.checked)">
                        {{ 'PLANNER.SELECT_ALL' | translate }}
                    </mat-checkbox>

                    <!-- Campo di input per la ricerca -->
                    <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                        [formControl]="causalSearchControl" class="padd-input">

                    <!-- Lista di WorkCausals -->
                    <mat-option *ngFor="let workCausal of filteredCausals" [value]="workCausal.id">
                        {{ workCausal.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <!-- Sezione per associare i documenti ai workCausals -->
            <div formArrayName="workCausalDocuments">
                <div *ngFor="let docControl of workCausalDocumentsArray.controls; let i = index" [formGroupName]="i">
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_DOCUMENTO' | translate }} <strong>{{
                                getWorkCausalName(docControl.get('workCausalId')?.value) }}</strong></mat-label>
                        <mat-select formControlName="documentId"
                            placeholder="{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_DOCUMENTO' | translate }}"
                            multiple>
                            <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                [formControl]="documentSearchControl" class="padd-input">
                            <mat-option *ngFor="let doc of filteredDocumentList" [value]="doc.id">{{ doc.label
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex justify-content-center" *ngIf="form.get('workCausals')?.value?.length > 0">
                <button mat-raised-button color="primary" (click)="addNewDocument()">{{
                    'PLANNER.PLANNER_CALENDAR_DIALOG.AGGIUNGI_NUOVA_TIPOLOGIA_DOCUMENTO' | translate }}</button>
            </div>
        </div>

        <div class="cardBox form-subject">
            <mat-card class="cardItem">
                <mat-card-header class="justify-content-center">
                    <mat-card-title>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SCHEDULAZIONE_SETTIMANALE' | translate
                        }}</mat-card-title>
                </mat-card-header>
                <p>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.DESCRIZIONE_SCHEDULAZIONE' | translate }}</p>
                <mat-card-content>
                    <ng-container formArrayName="weeklySchedules">
                        <div *ngFor="let weeklySchedule of weeklySchedules.controls; let p = index">
                            <label class="languageCodeLabel">{{ weekly[p].dayOfWeekLabel }}</label>

                            <div [formGroup]="weeklySchedule" class="form-subject languageCodeBox">
                                <mat-form-field appearance="fill" class="languageCodeItem">
                                    <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.MASSIMO_ORE_ORDINARIE_PER_GIORNO' |
                                        translate }}</mat-label>
                                    <input matInput formControlName="maxHours" type="number" min="0"
                                        placeholder="{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.INSERISCI_ORE_ORDINARIE_PER_GIORNO' | translate }}"
                                        class="hours">
                                </mat-form-field>

                                <mat-form-field appearance="fill" class="languageCodeItem">
                                    <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.MASSIMO_ORE_EXTRA_PER_GIORNO' |
                                        translate }}</mat-label>
                                    <input matInput formControlName="maxExtraHours" type="number" min="0"
                                        placeholder="{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.INSERISCI_ORE_EXTRA_PER_GIORNO' | translate }}"
                                        class="hours">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
    <mat-dialog-actions class="justify-content-end">
        <div class="buttons-right my-2 d-flex align-items-center">
            <button style="background-color:transparent;" [matTooltip]="translate.instant('MARKETINGNOTES.back')"
                type="button" class="my-bounce btn btn-primary mx-2" (click)="back()">
                <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
            </button>
            <button style="background-color:transparent;" [matTooltip]="translate.instant('MARKETINGNOTES.save')"
                type="button" class="my-bounce btn btn-primary mx-2" (click)="onSave()" [disabled]="!form.valid">
                <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
            </button>
        </div>
    </mat-dialog-actions>
</div>