
<!--
  <h1 mat-dialog-title>{{ "PLANNER.reasonNonProgrammableOrders" | translate }}</h1>
-->
<div mat-dialog-content>


  <form [formGroup]="form" class="form-container">
  <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
    <mat-label>{{ 'SUBJECT_RELATION_TYPES.label' | translate }}</mat-label>
    <mat-select  matNativeControl formControlName="subjectRelationTypeId">
      <mat-option *ngFor="let subjectRelationType of data.subjectRelationTypes" [value]="subjectRelationType.id">
        <!-- | async -->
        {{ "SUBJECT_RELATION_TYPES."+ subjectRelationType.code | translate }} {{requiresApproval(subjectRelationType) | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-subject-fulltextsearch class="form-subject" searchType="Soggetto" (selected)="selSubject($event)"
  (clearOut)="clearSubject()"></app-subject-fulltextsearch>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="">{{ "LABEL.CANCEL" | translate }}</button>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="!form.valid">{{ "LABEL.ADD" | translate }}</button>
</div>