import { Activity } from "./activity";
import { Contact } from "./contact";
import { Request } from "./request";
import { Status } from "./status";
import { SubjectType } from "./subjectType";

export class Subject {
        id?: number= undefined;
        name?: string= undefined;
        surname?: string= undefined;
        legalEntity?: string= undefined;
        type?: string= undefined;
        vat?: string= undefined;
        personalFiscalCode?: string= undefined;
        taxCode?: string= undefined;
        telephone1?: string= undefined; // mobile
        telephone2?: string= undefined; // fixTelephone
        email?: string= undefined;
        streetName?: string= undefined;
        streetNumber?: string= undefined;
        otherInfo?: string= undefined;
        city?: string= undefined;
        province?: string= undefined;
        state?: string= undefined;
        zip?: string= undefined;
        country?: string= undefined;
        coordX?: number= undefined;
        coordY?: number= undefined;
        officeCode?: string= undefined;
        isProspect?: boolean= undefined;
        subjectType?: SubjectType= undefined;
        condominiums?: string= undefined;
        subjectscondominiums?: string= undefined;
        subjectRatings?: string= undefined;
        activities?: Activity[]= undefined;
        requests?: Request[]= undefined;
        status?: Status | number= undefined;
        contacts?: Contact[]= undefined;
        clientStatus?: string= undefined;
        company?: string= undefined;
        companyLocation?: string= undefined;
        geographicArea?: string= undefined;
        geographicLocation?: string= undefined;
        responsible?: string= undefined;
        contactChannel?: string= undefined;
        origin?: string= undefined;
        administeredCondominiums?: number= undefined;
        subscribedToFBGroup?: boolean= undefined;
        fbLink?: string= undefined;
        igLink?: string= undefined;
        linkedinLink?: string= undefined;
        twitterLink?: string= undefined;
        webSite?: string= undefined;
        gender?: string= undefined;
        birthdate?: string= undefined;
        contactPreference?: string= undefined;
        subscribedToEdacApp?: boolean= undefined;
        fax?: string= undefined;
        storicBuilding?: boolean= undefined;
        totAdministeredCondominiums?: number= undefined;
        notes?: string= undefined;
        businessName?: string= undefined;
        sysCodeDelta?: string= undefined;
        createdAt?: Date= undefined;
        updatedAt?: Date= undefined;
        deletedAt?: Date= undefined;
        document?: Document= undefined;
        statusId?: any= undefined;
}
