<form [formGroup]="form" class="form-container" (ngSubmit)="update()">

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Tipologia Evento</mat-label>
        <mat-select matNativeControl formControlName="EventTypeId">
            <mat-option *ngFor="let eventType of eventTypes" [value]="eventType?.id">
                {{eventType.label | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'EVENTS.name' | translate }}</mat-label>
        <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'EVENTS.description' | translate }}</mat-label>
        <textarea matInput rows="15" formControlName="description" placeholder="Descrizione"></textarea>
    </mat-form-field>

    <!--
     <div [innerHTML]="form?.get('description')?.value"></div>
    -->


    <br>

    <div appearance="fill" class="form-subject">
        <mat-slide-toggle color="primary" formControlName="everyUserCanParticipate" [disabled]="true">Tutti gli utenti
            interni
            possono
            partecipare</mat-slide-toggle>
    </div>

    <div appearance="fill" class="form-subject">
        <mat-slide-toggle color="primary" formControlName="everyExternalUserCanParticipate" [disabled]="true">Tutti gli
            utenti
            esterni
            possono partecipare</mat-slide-toggle>
    </div>

    <br>

    <mat-card class="example-card" appearance="outlined" class="form-subject">
        <mat-card-header>
            <mat-card-title>Date dell'evento</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'COMPANIES.START_DATE' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>Orario inizio</mat-label>
                <input matInput type="time" formControlName="startTime" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'COMPANIES.END_DATE' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" formControlName="endDate" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>Orario di fine</mat-label>
                <input matInput type="time" formControlName="endTime" />
            </mat-form-field>

        </mat-card-content>

    </mat-card>

    <br>

    <mat-card class="example-card" appearance="outlined" class="form-subject">
        <mat-card-header>
            <mat-card-title>Date di visibilità dell'evento</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'COMPANIES.START_DATE' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDateVisibility" formControlName="startDateVisibility" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDateVisibility"></mat-datepicker-toggle>
                <mat-datepicker #startDateVisibility></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>Orario inizio</mat-label>
                <input matInput type="time" formControlName="startTimeVisibility" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'COMPANIES.END_DATE' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDateVisibility" formControlName="endDateVisibility" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDateVisibility"></mat-datepicker-toggle>
                <mat-datepicker #endDateVisibility></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>Orario di fine</mat-label>
                <input matInput type="time" formControlName="endTimeVisibility" />
            </mat-form-field>
        </mat-card-content>

    </mat-card>

    <br>

    <mat-card class="example-card" appearance="outlined" class="form-subject">
        <mat-card-header>
            <mat-card-title>Date di apertura delle registrazioni</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'COMPANIES.START_DATE' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDateRegistrations" formControlName="startDateRegistrations" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="startDateRegistrations"></mat-datepicker-toggle>
                <mat-datepicker #startDateRegistrations></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>Orario inizio</mat-label>
                <input matInput type="time" formControlName="startTimeRegistrations" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'COMPANIES.END_DATE' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDateRegistrations" formControlName="endDateRegistrations" />
                <mat-hint>GG/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="endDateRegistrations"></mat-datepicker-toggle>
                <mat-datepicker #endDateRegistrations></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject">
                <mat-label>Orario di fine</mat-label>
                <input matInput type="time" formControlName="endTimeRegistrations" />
            </mat-form-field>
        </mat-card-content>

    </mat-card>

    <br>


</form>

<div class="buttons-right  my-2">

    <button style="background-color:transparent;" [matTooltip]="translate.instant('EVENTS.save')" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()" [disabled]="!form.valid">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>

</div>