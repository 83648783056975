import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, take } from 'rxjs';
import { OutgoingEmailSettings } from '../models/email-template';
import { OutgoingEmailService } from '../services/outgoing-email.service';

@Injectable({
  providedIn: 'root'
})
export class OutgoingEmailResolverService implements Resolve<OutgoingEmailSettings> {

  routeBack: any[] = ['/outoing-email-settings'];

  constructor(
    private emailService: OutgoingEmailService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OutgoingEmailSettings>{
    const id = route.paramMap.get('id');
    return this.emailService.findOne(id!).pipe(
      take(1)
    )
  }
}
