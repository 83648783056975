import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSessionService } from '../../services/user-session.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
})
export class NotificationsDialogComponent implements OnInit {

  notifications: any = [
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userSessionService: UserSessionService,
    private notificationService: NotificationService,
  ) {



  }

  ngOnInit(): void {
      this.notifications = this.notificationService.getNotifications()
  }

}
