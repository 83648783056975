import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestListNewComponent } from './request-list/request-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RequestDataComponent } from './request-detail/request-data/request-data.component';
import { PreventiveRequestComponent } from './request-detail/request-data/preventive-request/preventive-request.component';
import { FullTextSearchModule } from '../fulltextsearch2/fulltextsearch.module';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RequestDocumentComponent } from './request-detail/request-document/request-document.component';
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import { PreventiveSolicitComponent } from './request-detail/request-data/preventive-solicit/preventive-solicit.component';
import { ComplaintComponent } from './request-detail/request-data/complaint/complaint.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { WorkWithUsComponent } from './request-detail/request-data/work-with-us/work-with-us.component';
import { RequestFilterComponent } from './request-filter/request-filter.component';
import { RequestFilterWithPaginationComponent } from './request-filter-with-pagination/request-filter-with-pagination.component';
import { createTranslateLoader } from '../app.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ActivitiesModule } from '../activities/activities.module';
import { SolicitListComponent } from './solicit-list/solicit-list.component';
import { MatRadioModule } from '@angular/material/radio';
import { SolicitFilterComponent } from './solicit-filter/solicit-filter.component';

const MATERIAL_MODULES = [
  MatTableModule,
  MatPaginatorModule,
  MatTabsModule,
  MatIconModule,
  MatFormFieldModule,
  MatSelectModule,
  MatRadioModule,
  MatCardModule,
  MatInputModule,
  MatExpansionModule,
  MatTooltipModule,
  MatAutocompleteModule
]

@NgModule({
  declarations: [
    RequestListNewComponent,
    RequestDetailComponent,
    RequestDataComponent,
    PreventiveRequestComponent,
    RequestDocumentComponent,
    PreventiveSolicitComponent,
    ComplaintComponent,
    WorkWithUsComponent,
    RequestFilterComponent,
    RequestFilterWithPaginationComponent,
    SolicitListComponent,
    SolicitFilterComponent
  ],
  imports: [
    MATERIAL_MODULES,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    FullTextSearchModule,
    SharedModule,
    ComponentsModule,
    TranslateModule.forChild(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        },
      }
    ),
    FlexLayoutModule,
    // GooglePlaceModule,
    // GoogleMapsModule,
    FullTextSearchModule,
    HttpClientModule,
    SharedModule,
    ActivitiesModule,
    FullTextSearchModule,
  ],
  exports: [
    RequestListNewComponent,
    SolicitListComponent
  ]
})
export class RequestModule { }
