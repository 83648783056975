import { Component, OnInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LibService } from 'src/app/services/libService';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-associations-between-pecs',
  templateUrl: './associations-between-pecs.component.html',
  styleUrls: ['./associations-between-pecs.component.scss']
})
export class AssociationsBetweenPecsComponent implements OnInit {

  @Input() pecId: any;

  withFilter: boolean = false
  withSearchBar: boolean = true
  addButton: boolean = true
  viewMode: string = 'pecsAssociationsList'

  constructor(
    private libService: LibService,
    public subjectService: SubjectService,
    public translate: TranslateService,
  ) { }


  ngOnInit(): void {}

}
