<!-- [formControlName]="controlName"<form [formGroup]="form"> [formControl]="myControl"(ngModelChange)="fulltextSearch($event)" -->
<form [formGroup]="formFieldSearch">

  <mat-form-field appearance="fill" class="form-activities" style="width: 100%; margin-left: auto; margin-right:auto;">
    <mat-label>{{label}}</mat-label>
    <input matInput
      [placeholder]="translate.instant('FORM_FIELD.SEARCH')"
      [value]="kwSearch"
      [matAutocomplete]="auto"
      [(ngModel)]="kwSearch"
      (ngModelChange)="fullTextSearch($event)"
      [formControl]="myControl"
      [formControlName]="controlName"
      [id]="controlName"
      [readonly]="readonly"
      >
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">
    <mat-option *ngFor="let option of optionList" [value]="option">
      <span *ngIf="(service.name == 'SubjectService') && !(option.name == null) && !(option.surname == null)">{{option.name + ' ' + option.surname}}  <b>{{option.type}}</b></span>
      <span *ngIf="(service.name == 'SubjectService') && !(option.name == null) && (option?.surname == null)">{{option?.name }} <b>{{option.type}}</b></span>
      <span *ngIf="(service.name == 'BuildingService') && !(option.name == null) && (option.surname == null)">{{option.name}}  <b>{{option.type}}</b></span>
      <span *ngIf="(service.name == 'BuildingService') && (option.name == null) && (option.surname == null)">{{option.streetName + ' ' + option.streetNumber + ', ' + option.city + ', ' + option.state}}  <b>{{option.type}}</b></span>
      <span *ngIf="(service.name == 'RequestService') && !(option.workType == null) ">{{option.workType}}</span>
      <span *ngIf="(service.name == 'OrderService') && !(option.code == null) ">{{option.code}}</span>
      <span *ngIf="(service.name == 'CondominiumService' || service.name == 'FTIndexAllService') && (option?.name == null) && (option?.surname == null)">{{option?.streetName + ' ' + option?.streetNumber + ', ' + option?.city }} <b>{{option?.type}}</b></span>
    </mat-option>
  </mat-autocomplete>
</form>


