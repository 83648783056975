<button *ngIf="form && checkComponent() && enableCancelButton()" matTooltip="Annulla" type="button"
    class="my-bounce btn closeRequestButton mx-2 closeRequestDanger" (click)="openModal('cancel')">
    {{ 'CHANGESTATEBUTTON.' + getCancelButtonTitleCode() | translate }}
</button>


<button *ngIf="form && checkComponent() && checkStateList()" matTooltip="stato Successivo" type="button"
    [disabled]="disabled" class="my-bounce btn styleButton mx-2 clsButtonDefault" (click)="openModal('next')">
    {{ 'CHANGESTATEBUTTON.'+ objectStructure.settings.code +'_title' | translate }}
</button>

<ng-container *ngIf="attributes?.linkList">
    <button *ngFor="let link of attributes?.linkList" matTooltip="link" type="button"
        class="my-bounce btn styleButton mx-2 clsButtonDefault" (click)="goTo(link?.url)">
        {{ 'CHANGESTATEBUTTON.'+ link?.code +'_title'| translate }}
    </button>
</ng-container>

<ng-container *ngIf="checkrestorePreviousStateButton()">
    <button matTooltip="restore" type="button" [disabled]="restoreDisabled(objectStructure.restorePreviousStateButton?.documentControlRequired)"
        class="my-bounce btn styleButton mx-2 clsButtonDefault" (click)="openModal('restore')">
        {{ 'CHANGESTATEBUTTON.'+ objectStructure.restorePreviousStateButton.code +'_title' | translate }}
    </button>
</ng-container>