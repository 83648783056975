import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Licence } from '../models/licence';

@Injectable({
    providedIn: 'root'
})
export class LicenceService {

    baseApi = environment.rootApiFoundation;

    constructor(
        public libService: LibService,
    ) { }

    getAllLicense(): Observable<any> {
        return this.libService.getData<any>(this.baseApi + 'licences','');
    }

    getLicense(id: string) {
        return this.libService.getData<Licence>(this.baseApi + 'licences/', id.toString());
    }

    insertLicense(license: any) {
        return this.libService.postData<any>({license: license}, this.baseApi, 'licences/')
    }

    updateLicense(id: string, license: any) {
        return this.libService.putData<any>({license: license}, this.baseApi, `licences/${id.toString()}`)
    }

    deleteLicense(license: Licence){
      return this.libService.deleteData<any>({license: license}, this.baseApi, `licences/${license.id?.toString()}`)
    }

    findManyByCompanies(options: string[]){
      return this.libService.postData<any[]>(options, this.baseApi, 'licences/findManyByCompanies')
    }

}
