import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NavService } from '../services/nav.service';
import { AuthService } from '../auth/auth.service';
import { TranslatorService } from '../services/translate.service';
import { UserSessionService } from '../services/user-session.service'
import { Subscription, switchMap } from 'rxjs';


@Component({
  selector: 'app-my-headquarters',
  templateUrl: './my-headquarters.component.html',
  styleUrls: ['./my-headquarters.component.scss']
})
export class MyHeadquartersComponent implements OnInit {
  ss: any;
  user: any;
  sended: boolean = false;
  myBranchUrl: SafeResourceUrl;
  title = "La Mia Sede";
  subTitle = "EDAC - La Mia Sede";
  selectedVenue: any = false
  private sub: Subscription;
  currentCompany: any = null
  innerHeight: any;

  constructor(
    public navService: NavService,
    public translate: TranslatorService,
    private authService: AuthService,
    private userSessionService: UserSessionService,
  ) { }


  ngDoCheck() {

    if (this.sended == false) {
      var frame: HTMLIFrameElement = document.getElementById('MyHeadquartersIframe') as HTMLIFrameElement;
      if (frame != null) {
        this.sended = true;
        frame.contentWindow?.postMessage('sstorage', this.ss);
        this.currentCompany = this.getCompany()
        this.myBranchUrl = environment.rootMyHeadquarters + this.user.sub + `&idSede=${this.currentCompany}`;
      }
    }

  }

  setPageVisibility(company: any) {
    this.selectedVenue = company != 0 ? true : false
  }

  getCompany() {
    const company = this.userSessionService.getState('working_company');
    if (!(company == null) && !(company.name == '*')) {
      return company.id;
    } else return 0;
  }

  loadLabels() {
    this.translate.get('SIDEBAR.La Mia Sede').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {

    this.currentCompany = this.getCompany()
    //console.log(currentCompany)
    this.setPageVisibility(this.currentCompany)

    this.sub = this.userSessionService.$changeCompanyDetector.subscribe(res => {
      window.location.reload();
      //this.currentCompany = this.getCompany()
      //this.setPageVisibility(this.currentCompany)
      //this.myBranchUrl = environment.rootMyHeadquarters + this.user.sub + `&idSede=${this.currentCompany}`;
    })

    this.ss = window.sessionStorage;
    this.user = this.authService.user();
    console.log('send message to iframe %o', this.ss, this.user);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
    ];
    this.navService.setActions(actions);

    this.innerHeight = (window.innerHeight - 74) + 'px';
  }

}


