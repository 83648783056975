import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-building-marketing',
  templateUrl: './building-marketing.component.html',
  styleUrls: ['./building-marketing.component.scss']
})
export class BuildingMarketingComponent implements OnInit {

  @Input()form : UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
