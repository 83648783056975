import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { Subscriber, Observable, Subject, debounceTime, switchMap, forkJoin, distinctUntilChanged, pipe, takeUntil, combineLatest, mergeMap, merge , tap, map, of, Subscription} from 'rxjs';


@Component({
  selector: 'app-fulltextsearch3',
  templateUrl: './fulltextsearch3.component.html',
  styleUrls: ['./fulltextsearch3.component.scss']
})
export class Fulltextsearch3Component implements OnInit {

  @Input() service: any;
  @Input() component: any;
  @Input() searchType?: 'Soggetto'| 'Edificio' | 'Order' | 'Condominio';
  @Input() events: Observable<void>;
  @Input() edit: boolean;
  @Input() filterIDS: any;
  @Input() searchCleaner: boolean;
  @Input() form: UntypedFormGroup;
  @Input() fieldName: string;
  @Input() disabled: boolean;
  type: string;
  @Output() selected = new EventEmitter;
  @Output() clearOut = new EventEmitter;
  @Output() list = new EventEmitter;


  noResults: boolean = false;
  fullTextSearchSubscriber: Subscriber<any>;
  myControl: UntypedFormControl = new UntypedFormControl('');
  kwSearch: any;
  isLoading: boolean = false;
  options: any;
  richiesteDB: any[] = [];
  form1 : UntypedFormGroup;
  private eventsSubscription: Subscription;

  optionList: Array<any>;
  input = new Subject<string>();
  get field() {
    let campo: string = '';
    // console.log("SERVIZIO DI RICERCA --> ", this.service.name);
    switch (this.service.name) {
      case "SubjectService":
        campo = "idSubject";
        break;

      case "BuildingService":
        campo = "idBuilding";

        break;

      case "CondominiumService":
        campo = "idCondominium";

        break;

      case "FTIndexAllService":
        campo = "";

        break;
      case "OrderService":
        campo = ""

        break;
    }
    return campo;
  }

  selectedSub: any;
  clearFields: boolean = false;
  inputValue$ = this.input.pipe(debounceTime(500), distinctUntilChanged());
  searchIds$ = this.inputValue$.pipe(
    switchMap((val) => val.length > 2? this.service.getSubjectIdsFTSearch({ kwsearch: val.toLowerCase() }) as Observable<any>: this.optionList = []),
    tap((subs) => {

      console.log("Soggetti --> ", subs, subs.length)
      subs.length == 0? this.optionList = []: this.isLoading = true;
      this.type = subs.type
    }),
    map(subs => subs.data)
  );

  subjects$ = this.searchIds$

  // subjects$ = this.searchIds$.pipe(
  //   mergeMap(subs => {
  //     let ret: any;
  //     this.richiesteDB = [];
  //     if (subs.length > 0) {
  //       forkJoin(subs.map((sub: any) => {
  //         this.richiesteDB.push(sub[this.field]);
  //       }))
  //       ret = this.service.getById(this.richiesteDB)
  //     }
  //     else {
  //       ret = of([]);
  //     }
  //     return ret;
  //   }))

  indexAll$ = this.searchIds$.pipe(
    mergeMap(subs => {
      return subs.length > 0?
        forkJoin([[subs]].map((sub: any) => {
            let input = sub.flat(2);
            console.log("VETTORE FLATTATO --> ", sub.flat(2));
            return this.service.getById(input);
        }))
      :
      of([]);
    }),
    tap(subs => {
      this.type = 'all'
    }))

  constructor(public translate: TranslateService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe) {
      this.form1 = this.fb.group({
        campo1: null
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.searchCleaner === true? this.clear(): undefined;

  }

  ngOnInit(): void {
    // console.log("EVENTS --> ", this.events);
    if (this.events !== undefined && this.events !== null) {
      this.eventsSubscription = this.events.subscribe(() =>{
        console.log("change search type -->");
        this.kwSearch = "";
      });
    }

    this.form.valueChanges.subscribe(form => {
      if (form !== null && form !== undefined) {
        console.log("TRIGERRING")
        form.orderCode !== null && form.orderCode !== undefined? this.myControl.setValue(form.orderCode): undefined;
        form.subject !== null &&  form.subject!== undefined? this.myControl.setValue(form.subject?.name + ' ' + form.subject?.surname): undefined;
      }
    })

    this.searchType? this.kwSearch = '': undefined;
    if (this.service?.name == "FTIndexAllService") {

      this.indexAll$.subscribe((res:any) => {
        // this.unSub();
        console.log("IS LOADING? --> ", this.isLoading);
        console.log('index all mio: ', res.flat());
        this.isLoading = false;
        this.noResults = res.length > 0? false: true;
        this.optionList = res.flat();
        this.list.emit(this.optionList);
      });
    } else {
      this.subjects$.subscribe((res:any) => {
        // this.unSub();
        console.log("SERVIZIO DI RICERCA --> ", this.service.name);
        console.log('subjects mio: ', res);
        if (this.service.name === 'OrderService') {
          this.optionList = res[0];
          this.filterList();
        } else {
          this.optionList = res;
        }
        this.noResults = this.optionList.length > 0? false: true;
        this.isLoading = false;
        this.list.emit(this.optionList);
      });
    }
    this.isLoading = false;


  }

  fulltextSearch(event: any) {
    // this.unSub();
    if (!event) {
      console.log("ricerca VUOTA");
      this.optionList = [];
      this.list.emit(this.optionList);
    }else {
      event.length > 2?
        this.input.next(event):
        undefined;
    }
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    console.log("TIPO DI SELEZIONE --> ", typeof event.option.value)
    if (typeof event.option.value == 'undefined') {
      console.log("DO NOTHING --> ", )
    } else {
      this.selectedSub = event.option.value;

      console.log("SOGGETTO SELEZIONATO --> ", this.selectedSub);

      switch (this.type) {
        case 'building':case'condominium':
        this.myControl.setValue(this.selectedSub.streetName + ', ' + this.selectedSub.streetNumber + ', ' + this.selectedSub.city);
          break;
        case 'subject':
          this.myControl.setValue(this.selectedSub.name + ' ' + this.selectedSub.surname);
          break;
        case 'orders':
          this.myControl.setValue(this.selectedSub.code);
          break;
        case 'all':
          switch (this.selectedSub.type) {
            case 'Edificio':case 'Condominio':
              this.myControl.setValue(this.selectedSub.streetName + ', ' + this.selectedSub.streetNumber + ', ' + this.selectedSub.city);
              break;
            default: //Subject
              this.myControl.setValue(this.selectedSub.name + ' ' + this.selectedSub.surname);
              break;
          }
          break;
      }
      this.selected.emit(this.selectedSub);
    }
  }

  clear() {
    this.clearFields = true;
    this.kwSearch = "";
    this.clearOut.emit(this.clearFields);
    this.clearFields = false;
  }

  unSub() {
    console.log("SUBSCRIPTION --> ", this.eventsSubscription);
    this.eventsSubscription.unsubscribe();
  }

  filterList() {
    // console.log("LISTA PRE FILTRO --> ", this.optionList);
    if (this.filterIDS?.buildingId !== null && this.filterIDS?.buildingId !== undefined && this.filterIDS?.buildingId !== 0 ) {
      this.optionList = this.optionList.filter(option => option.idBuilding === this.filterIDS.buildingId);
    }
    if (this.filterIDS?.subjectId !== null && this.filterIDS?.subjectId !== undefined && this.filterIDS?.subjectId !== 0 ) {
      this.optionList = this.optionList.filter(option => option.idSubject === this.filterIDS.subjectId);
    }
    // console.log("LISTA POST FILTRO --> ", this.optionList);
  }

}
