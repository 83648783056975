<div class="full-w" >
  <br>
  <form [formGroup]="form" class="form-container">

    <mat-radio-group readonly name="fbgroup" class="form-subject"
      formControlName="subscribedToFBGroup">
      <label id="example-radio-group-label">{{'LABEL.EDAC_FBGROUP_SUB' | translate}}</label>
      <br>
      <br>
      <mat-radio-button [value]="true">

        {{ 'LABEL.YES' | translate }}

      </mat-radio-button>
      <mat-radio-button [value]="false">

        {{ 'LABEL.NO' | translate }}

      </mat-radio-button>
      <br>
      <br>

    </mat-radio-group>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.IG_LINK' | translate }}</mat-label>
      <input matInput maxlength="128" placeholder="{{ 'LABEL.INSERISCI_LINK_INSTAGRAM' | translate }}"
        formControlName="igLink" #igLinkInput>
      <!-- <mat-hint>Lunghezza minima 10: {{ legalEntityInput.value.length }}/10</mat-hint>
            <mat-error>La ragione sociale è obbligatoria</mat-error>
            <mat-error **ngIf="(formAnagrafica.get('legalEntity')).errors?.minlength">
                E' necessario inserire almeno 10 caratteri
            </mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.FB_LINK' | translate }}</mat-label>
      <input matInput maxlength="128" placeholder="{{ 'LABEL.INSERISCI_LINK_FACEBOOK' | translate }}" formControlName="fbLink"
        #fbLinkInput>
      <!-- <mat-hint>Lunghezza minima 10: {{ legalEntityInput.value.length }}/10</mat-hint>
            <mat-error>La ragione sociale è obbligatoria</mat-error>
            <mat-error **ngIf="(formAnagrafica.get('legalEntity')).errors?.minlength">
                E' necessario inserire almeno 10 caratteri
            </mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.TWITTER_LINK' | translate }}</mat-label>
      <input matInput maxlength="128" placeholder="{{ 'LABEL.INSERISCI_LINK_TWITTER' | translate }}"
        formControlName="twitterLink" #twitterLinkInput>
      <!-- <mat-hint>Lunghezza minima 10: {{ legalEntityInput.value.length }}/10</mat-hint>
            <mat-error>La ragione sociale è obbligatoria</mat-error>
            <mat-error **ngIf="(formAnagrafica.get('legalEntity')).errors?.minlength">
                E' necessario inserire almeno 10 caratteri
            </mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.LINKEDIN_LINK' | translate }}</mat-label>
      <input matInput maxlength="128" placeholder="{{ 'LABEL.INSERISCI_LINK_LINKEDIN' | translate }}"
        formControlName="linkedinLink" #linkedinLinkInput>
      <!-- <mat-hint>Lunghezza minima 10: {{ legalEntityInput.value.length }}/10</mat-hint>
            <mat-error>La ragione sociale è obbligatoria</mat-error>
            <mat-error **ngIf="(formAnagrafica.get('legalEntity')).errors?.minlength">
                E' necessario inserire almeno 10 caratteri
            </mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.WEBSITE' | translate }}</mat-label>
      <input matInput maxlength="128" placeholder="{{ 'LABEL.INSERISCI_LINK_SITO_WEB' | translate }}" formControlName="webSite"
        #webSiteInput>
      <!-- <mat-hint>Lunghezza minima 10: {{ legalEntityInput.value.length }}/10</mat-hint>
            <mat-error>La ragione sociale è obbligatoria</mat-error>
            <mat-error **ngIf="(formAnagrafica.get('legalEntity')).errors?.minlength">
                E' necessario inserire almeno 10 caratteri
            </mat-error> -->
    </mat-form-field>

    <mat-form-field readonly appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.CONTACT_PREFERENCE' | translate }}</mat-label>
      <input matInput maxlength="128" placeholder="{{ 'LABEL.INSERISCI_PREFERENZA_CONTATTO' | translate }}"
        formControlName="contactPreference" #contactPreferenceInput>
      <!-- <mat-hint>Lunghezza minima 10: {{ legalEntityInput.value.length }}/10</mat-hint>
            <mat-error>La ragione sociale è obbligatoria</mat-error>
            <mat-error **ngIf="(formAnagrafica.get('legalEntity')).errors?.minlength">
                E' necessario inserire almeno 10 caratteri
            </mat-error> -->
    </mat-form-field>

    <mat-radio-group readonly  name="privacyConsent" class="form-subject" formControlName="privacyConsent"
      >
      <br>
      <label id="example-radio-group-label">{{ 'LABEL.PRIVACY_POLICY' | translate }}</label>
      <br>
      <br>
      <mat-radio-button [value]="true">

        {{ 'LABEL.YES' | translate }}

      </mat-radio-button>
      <mat-radio-button [value]="false">

        {{ 'LABEL.NO' | translate }}

      </mat-radio-button>
      <br>
      <br>

    </mat-radio-group>

    <mat-form-field appearance="fill" class="form-subject" >
      <mat-label>{{ 'LABEL.BIRTH_DATE' | translate }}</mat-label>
      <input matInput type="date" maxlength="128" placeholder="{{ 'LABEL.BIRTH_DATE' | translate }}"
        formControlName="birthdate">
      <!-- <mat-hint>Lunghezza minima 10: {{ legalEntityInput.value.length }}/10</mat-hint>
            <mat-error>La ragione sociale è obbligatoria</mat-error>
            <mat-error **ngIf="(formAnagrafica.get('legalEntity')).errors?.minlength">
                E' necessario inserire almeno 10 caratteri
            </mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" >
      <mat-label>{{ 'LABEL.EA_COURSES' | translate }}</mat-label>
      <input matInput maxlength="128" placeholder="{{ 'LABEL.EA_COURSES' | translate }}"
        formControlName="eaCourses" #eaCoursesInput>
      <!-- <mat-hint>Lunghezza minima 10: {{ legalEntityInput.value.length }}/10</mat-hint>
            <mat-error>La ragione sociale è obbligatoria</mat-error>
            <mat-error **ngIf="(formAnagrafica.get('legalEntity')).errors?.minlength">
                E' necessario inserire almeno 10 caratteri
            </mat-error> -->
    </mat-form-field>

    <mat-radio-group formControlName="appEa"  name="appEa" class="form-subject">
      <label id="example-radio-group-label">{{ 'LABEL.APP_EA_SUB' | translate }}</label>
      <br>
      <br>
      <mat-radio-button [value]="true">

        {{ 'LABEL.YES' | translate }}

      </mat-radio-button>
      <mat-radio-button [value]="false">

        {{ 'LABEL.NO' | translate }}

      </mat-radio-button>
      <br>
      <br>
      <br>

    </mat-radio-group>
  </form>
</div>
