<ng-container *ngIf="selectedVenue; else noData">
  <main [ngClass]="{
      'container-full': planningFullPage,
      'container-fluid': !planningFullPage
    }" class="d-flex flex-column gap-5">

    <planner-search [plannerType]="plannerType" *ngIf="!planningFullPage"></planner-search>

    <mat-expansion-panel expanded *ngIf="!planningFullPage">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>Stock</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <planner-boxs [plannerType]="plannerType"></planner-boxs>
    </mat-expansion-panel>

    <router-outlet *ngIf="!planningFullPage"></router-outlet>

    <div *ngIf="plannerType === 'core'">
      <div *ngIf="planningFullPage; else elseBlock">
        <planner-scheduler [fullScreen]="planningFullPage" (setPlanningFullScreen)="setPlanningFullScreen($event)">
        </planner-scheduler>
      </div>
      <ng-template #elseBlock>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <strong>{{ "PLANNER.UPDATE_PLAN" | translate }}</strong>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <planner-scheduler [fullScreen]="planningFullPage" (setPlanningFullScreen)="setPlanningFullScreen($event)">
          </planner-scheduler>
        </mat-expansion-panel>
      </ng-template>
    </div>

    <div *ngIf="plannerType === 'energy'">
      <div *ngIf="planningFullPage; else elseBlock">
        <planner-scheduler-energy [fullScreen]="planningFullPage"
          (setPlanningFullScreen)="setPlanningFullScreen($event)">
        </planner-scheduler-energy>
      </div>
      <ng-template #elseBlock>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <strong>{{ "PLANNER.UPDATE_PLAN" | translate }}</strong>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <planner-scheduler-energy [fullScreen]="planningFullPage"
            (setPlanningFullScreen)="setPlanningFullScreen($event)">
          </planner-scheduler-energy>
        </mat-expansion-panel>
      </ng-template>
    </div>

    <!-- PLANNER OPERATORI ENERGY -->
    <div *ngIf="plannerType === 'energyOperators'">
      <div *ngIf="planningFullPage; else elseBlock">
        <planner-scheduler-energy-operators [fullScreen]="planningFullPage"
          (setPlanningFullScreen)="setPlanningFullScreen($event)">
        </planner-scheduler-energy-operators>
      </div>
      <ng-template #elseBlock>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <strong>{{ "PLANNER.UPDATE_PLAN" | translate }}</strong>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <planner-scheduler-energy-operators [fullScreen]="planningFullPage"
            (setPlanningFullScreen)="setPlanningFullScreen($event)">
          </planner-scheduler-energy-operators>
        </mat-expansion-panel>
      </ng-template>
    </div>

    <div *ngIf="plannerType === 'multi'">
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ "PLANNER.UPDATE_PLAN" | translate }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <planner-calendar></planner-calendar>
      </mat-expansion-panel>
    </div>

  </main>
</ng-container>
<ng-template #noData>
  <header class="noData">
    <h1>{{ "PLANNER.NO_ORDERS" | translate }}</h1>
  </header>
</ng-template>