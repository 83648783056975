<h1 mat-dialog-title>{{ "LABEL.addSubjectToPractice" | translate }}</h1>

<div mat-dialog-content>


  <form [formGroup]="form" class="form-container">

    <div class="form-subject">
      <mat-radio-group formControlName="type" name="type">
        <mat-radio-button style="margin-right:20px;" value="subjects" name="subjects">
          {{ "LABEL.searchSubject" | translate }}
        </mat-radio-button>
        <mat-radio-button style="margin-right:20px;" value="addSubjects" name="addSubjects">
          {{ "LABEL.addSubject" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- RICERCA UTENTE/SOGGETTO -->
    <mat-card class="search-card" *ngIf="isSubjectType()">
      <mat-card-title>{{ "LABEL.subjectSearch" | translate }}</mat-card-title>
      <mat-card-content class="search-container">

        <div style="width: 100%;" appearance="fill" class="flex50">
          <input formControlName="kwSearchName" placeholder="Nome" type="text" class="searchBox">
        </div>

        <div style="width: 100%;" appearance="fill" class="flex50">
          <input formControlName="kwSearchSurname" placeholder="Cognome" type="text" class="searchBox">
        </div>

        <div *ngIf="isSubjectType()" style="width: 100%;" appearance="fill" class="flex50">
          <input formControlName="kwSearchLegalEntity" placeholder="Entità Legale" type="text" class="searchBox">
        </div>

        <div style="width: 100%;" appearance="fill" class="flex50">
          <input formControlName="kwSearchEmail" placeholder="Email" type="text" class="searchBox">
        </div>

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="button" mat-button (click)="search()" class="btn clsButtonDefault btnSearch">
          {{
          'LABEL.SEARCH' | translate }}</button>
      </mat-card-actions>
    </mat-card>

    <!-- AGGIUNTA SOGGETTO -->
    <mat-card class="search-card" *ngIf="isAddSubjectType()">
      <mat-card-title>{{ "LABEL.newSubjectAdd" | translate }}</mat-card-title>
      <mat-card-content class="search-container">

        <app-crm-form style="width:100%;" [mainForm]="form" class="subjectFormBox" [documentsSection]="false"
          [companies]="[]" [subjectAttributes]="subjectAttributes"></app-crm-form>



      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="button" mat-button (click)="addSubject()"
          [disabled]="!this.form.get('subject').valid" class="btn clsButtonDefault btnSearch">
          {{
          'LABEL.CREATE' | translate }}</button>
      </mat-card-actions>
    </mat-card>


    <mat-form-field *ngIf="isSubjectType()" style="width: 100%;" appearance="fill" class="form-activities">
      <mat-label>{{ 'LABEL.subject' | translate }}</mat-label>
      <mat-select #selectSubjects matNativeControl formControlName="subjectId"
        (selectionChange)="setSubject($event)">
        <mat-option *ngFor="let item of searchResults" [value]="item.id">
          {{ getFullname(item) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
      <mat-label>{{ 'LABEL.subjectRelatedToPracticeTypology' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="subjectRelatedToPracticeTypologyId">
        <mat-option *ngFor="let item of subjectRelatedToPracticeTypologies" [value]="item.id">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
      <mat-label>{{ 'LABEL.noteText' | translate }}</mat-label>
      <textarea matInput rows="5" formControlName="note"
        placeholder="{{ 'LABEL.noteText' | translate }}"></textarea>
    </mat-form-field>

  </form>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="disabledConfirmButton()">{{ confirmButtonLabel |
    translate }}</button>
  <button mat-button [mat-dialog-close]="">{{ cancelButtonLabel | translate }}</button>
</div>