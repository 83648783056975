import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ReviewService } from 'src/app/services/review.service';
import { LibService } from 'src/app/services/libService';
import { SubjectService } from 'src/app/services/subject.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AddUsersModal } from './notification-system-modals/add-users-modal/add-users-modal.component';
import { UserDataService } from '../services/users.service';
import { FinanceService } from 'src/app/services/finance.service';
import { UserSessionService } from 'src/app/services/user-session.service';



@Component({
  selector: 'app-notification-system',
  templateUrl: './notification-system.component.html',
  styleUrls: ['./notification-system.component.scss'],
})
export class NotificationSystemComponent implements OnInit {
  @Input() users: any = [];
  @Input() global: boolean = false;
  usersSelected: any = [];
  usersSelectedList: any = [];
  form: FormGroup;
  internalUsers: any = []
  externalUsers: any = []

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private reviewService: ReviewService,
    private libService: LibService,
    public subjectService: SubjectService,
    public orderService: OrderService,
    public authService: AuthService,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    public financeService: FinanceService,
    private userSessionService: UserSessionService,

  ) { }

  notificationChannels: any = [
    { code: 'notification_channels_email', label: 'Email', disabled: false },
    { code: 'notification_channels_push', label: 'Push', disabled: false },
    { code: 'notification_channels_sms', label: 'SMS', disabled: true },
  ];

  ngOnInit(): void {
    // Creazione Form
    this.form = this.fb.group({
      title: [null, [Validators.required]],
      body: [null, [Validators.required]],
      notificationChannels: [null],
    });

    if (this.global) this.initUsers()
    if (this.global) this.initExtenalUsers()
  }

  initUsers(): void {
    let tmpUsers: any = []
    this.userDataService.index().subscribe((res: any) => {
      console.log(res)
      res.forEach((user: any) => {
        tmpUsers.push({
          UserId: user.id,
          user: user,
          company: {},
          type: "internal"
        })
      });

      this.internalUsers = tmpUsers

    }, err => {
      console.log(err)
    });

  }

  initExtenalUsers(): void {
    let tmpUsers: any = []
    this.userDataService.indexExt().subscribe((res: any) => {
      console.log(res)
      res.forEach((user: any) => {
        tmpUsers.push({
          UserId: user.id,
          user: user,
          company: {},
          type: "external"
        })
      });

      this.externalUsers = tmpUsers

    }, err => {
      console.log(err)
    });

  }

  sendNotificationSettings() {
    if (this.form.invalid) {
      this.toastr.error(this.translate.instant('Compila tutti i campi obbligatori'));
      return;
    }
    let currentUser = this.userSessionService.getState('user');
    const emailSent = this.form.value.notificationChannels.includes('notification_channels_email') ? "0" : null;
    const pushSent = this.form.value.notificationChannels.includes('notification_channels_push') ? "0" : null;
    console.log(this.usersSelected)
    
    const notificationPayload = {
      usersFinalNotifications: {
        id: "0",
        title: this.form.value.title,
        body: this.form.value.body,
        icon: "", // Icon vuota
        action: "",
        url: "",
        // url: emailSent === "0" ? "https://www.aapp.ai/app/home" : "", // Se emailSent è valorizzato, imposta l'URL
        emailSent: emailSent,
        pushSent: pushSent,
        pushRead: null, // Inizialmente non letto
        type: "NOTIFICA_EVENTO", // da mettere il code NOTIFICHE_EVENTO
        status: "1",
        externalReferenceId: "0", // External Reference ID vuoto
      },
      usersNotificationsRighe: this.usersSelected.map((user: any) => ({
        userId: user.id,
        userType: user.type
      }))

    };
    console.log('payload: ', notificationPayload)
  
    this.financeService.setUsersNotification(currentUser.email, notificationPayload).subscribe(
      response => {
        this.toastr.success(this.translate.instant('Notifica inviata con successo'));
        this.form.reset();
        this.usersSelected = [];
      },
      error => {
        this.toastr.error(this.translate.instant('Errore nell’invio della notifica'));
        console.error(error);
      }
    );
  }
  

  openUserModal(type: string) {
    let data: any = {
      global: this.global
    };

    if (this.global) {
      if (type == 'internal') data.users = this.internalUsers
      if (type == 'external') data.users = this.externalUsers
    } else {
      data.users = this.users.filter(
        (registration: any) => registration.type == type
      );
    }


    const dialogRef = this.dialog.open(AddUsersModal, {
      width: '90%',
      data: data,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.forEach((user: any) => {
          let status = this.usersSelected.find(
            (u: any) => u.id === user.id && u.type == user.type
          );
          if (!status) this.usersSelected.push(user);
        });
      }
    });
  }

  removeUser(user: any) {
    console.log(user.id)
    console.log(user.type)
    this.usersSelected = this.usersSelected.filter(
      (u: any) => u.id !== user.id || u.type !== user.type
    );
  }

  getColor(user: any) {
    return user.type == 'internal' ? 'primary' : 'accent';
  }
}
