<div class="container-fluid my-container">
  <h1>Translation Settings</h1>
  <button (click)="goToPage({id:0})" id="kt_sign_in_submit" class="btn btn-lg btn-primary">
    <span class="indicator-label text-dark">Aggiungi Traduzione</span>
  </button>
  <div appearance="fill" class="form-subject">
    <mat-grid-list cols="2" rowHeight="5:1">

      <mat-grid-tile>
        <mat-select (selectionChange)="filterByTranslationCategoryId($event)" placeholder="Translation Categories">
          <mat-option value="">
           -
          </mat-option>
          <mat-option *ngFor="let translationCategory of translationCategories" [value]="translationCategory.id">
            {{ translationCategory.label }} ({{ translationCategory.code }})
          </mat-option>
        </mat-select>
      </mat-grid-tile>

      <!--
      <mat-grid-tile>
        <mat-select (selectionChange)="filter($event)" placeholder="Action Categories">
          <mat-option *ngFor="let actionCategory of actionCategories" [value]="actionCategory.id">
            {{ actionCategory.label }}
          </mat-option>
        </mat-select>
      </mat-grid-tile>
      -->

    </mat-grid-list>
  </div>

  <br>


  <div style="overflow-x: auto;">

    <app-settings-data-table [data]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns"
      [actions]="actions" sortActive=""></app-settings-data-table>


  </div>

</div>


<ng-template #itemDialog>
  <app-action-form></app-action-form>
</ng-template>