<h1 mat-dialog-title>{{ titleLabel | translate }}</h1>

<div mat-dialog-content>

  <p>
    {{ textLabel | translate }}
  </p>

  <form *ngIf="formIsActive" [formGroup]="form" class="form-container">

    <mat-radio-group *ngIf="actions.length > 0" aria-labelledby="example-radio-group--label" class="example-radio-group"
      formControlName="esit">
      <mat-radio-button color="primary" class="example-radio-button" *ngFor="let action of actions" [value]="action">
        {{ "CLOSEREQUEST." + action | translate }}
      </mat-radio-button>
    </mat-radio-group>

    <mat-form-field *ngIf="estimateDeliveryMethods" style="width: 100%;" appearance="fill" class="form-activities">
      <mat-label>{{ 'ESTIMATEDELIVERYMETHOD.metodo_di_consegna_al_cliente' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="EstimateDeliveryMethodId">
        <mat-option *ngFor="let estimateDeliveryMethod of estimateDeliveryMethods"
          [value]="''+estimateDeliveryMethod.id">
          <!-- | async -->
          {{ "ESTIMATEDELIVERYMETHOD."+ estimateDeliveryMethod.code | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="listOfStatesAvailableForMigration?.length > 0" style="width: 100%;" appearance="fill" class="form-activities">
      <mat-label>{{ 'LABEL.STATE' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="migrationState">
        <mat-option *ngFor="let state of listOfStatesAvailableForMigration" [disabled]="state.disabled" [value]="state.id">
          <!-- | async -->
          {{ state.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="reasons" style="width: 100%;" appearance="fill" class="form-activities">
      <mat-label>{{ 'REASONS.title' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="ReasonCode" (selectionChange)="onChangeReasonsSelect($event)">
        <mat-option *ngFor="let reason of reasons" [value]="''+reason.code">
          <!-- | async -->
          {{ "REASONS."+ reason.code | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p *ngIf="reasons">{{ "REASONS.reasonNextStateAlert" | translate }}: <strong>{{getReasonAlert()}}</strong></p>

    <mat-form-field *ngIf="requiresNotesField" class="example-full-width">
      <mat-label>Note</mat-label>
      <textarea matInput rows="5" formControlName="notes" placeholder="Note"></textarea>
    </mat-form-field>

  </form>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="disabledConfirmButton()">{{ confirmButtonLabel |
    translate }}</button>
  <button mat-button [mat-dialog-close]="">{{ cancelButtonLabel | translate }}</button>
</div>