import { LibService } from './../libService';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './../base.service';
import { UserSessionService } from './../user-session.service';
import { GuardService } from './../guard.service';
import { AuthService } from '../../auth/auth.service'
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class ConsuntivatoreDubaiService extends BaseService {

    constructor(
        public LibService: LibService,
        public AuthService: AuthService,
        public userSessionService: UserSessionService,
        public guardService: GuardService
    ) {
        super(LibService, AuthService)
        this.baseApi = environment.rootApiFinance;
        this.model = ''
    }
    get headers(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };

        return new HttpHeaders(headersConfig);
    }

    getOperators(userToken: string, email: string, country: string, idSede: string, idProfilo: string): Observable<any> {
        return this.libService.getDataWithAccessToken<any>(userToken, this.baseApi, `/V1/output/getRisorse?account=${email}&country=${country}&idSede=${idSede}&idProfilo=${idProfilo}`);
    }

    getOperators2(userToken: string, email: string): Observable<any> {
        return this.libService.getDataWithAccessToken<any>(userToken, this.baseApi, `/V1/output/getOperai?account=${email}`);
    }
    getConsuntivazioneDubai(userToken: string, email: string, idSede: string, searchTerm?: string): Observable<any> {
        let url = `/V1/output/getCommesseConsuntivazioneDubai?account=${email}&idSede=${idSede}`;

        if (searchTerm && searchTerm.trim() !== '') {
            url += `&parametro=${encodeURIComponent(searchTerm)}`;
        }

        return this.libService.getDataWithAccessToken<any>(userToken, this.baseApi, url);
    }

    setConsuntivazioneDubaiAssociazionePraticaOperatore(
        userToken: string,
        email: string,
        associazione: { practiceId: number; teamLeaderId: number }
    ): Observable<any> {
        const url = `/V1/input/SetConsuntivazioneDubaiAssociazionePraticaOperatore?account=${email}`;

        // 🔹 Aggiungiamo l'ID richiesto dalla API
        const body = {
            practiceId: associazione.practiceId,
            teamLeaderId: associazione.teamLeaderId
        };

        return this.libService.postDataWithAccessToken<any>(
            userToken,    // 🔹 Token di autenticazione
            body,         // 🔹 Corpo della richiesta nel formato corretto
            this.baseApi, // 🔹 URL base della API
            url           // 🔹 Endpoint specifico
        );
    }

    deleteConsuntivazioneDubaiAssociazionePraticaOperatore(
        userToken: string,
        email: string,
        practiceId: number,
        teamLeaderId: number
    ): Observable<any> {
        const url = `/V1/delete/DeleteConsuntivazioneDubaiAssociazionePraticaOperatore?account=${email}`;

        // 🔹 Dati da inviare
        const body = {
            practiceId: practiceId,
            teamLeaderId: teamLeaderId
        };

        return this.libService.postDataWithAccessToken<any>(
            userToken,    // 🔹 Token di autenticazione
            body,         // 🔹 Corpo della richiesta JSON
            this.baseApi, // 🔹 URL base della API
            url           // 🔹 Endpoint specifico
        );
    }

    getCommesseConsuntivazioneDubai(userToken: string, email: string): Observable<any> {
        const url = `/V1/output/GetCommesseConsuntivazioneOperai?account=${email}`;

        return this.libService.getDataWithAccessToken<any>(
            userToken,    // 🔹 Token di autenticazione
            this.baseApi, // 🔹 URL base della API
            url           // 🔹 Endpoint specifico
        );
    }

}