<mat-tab-group class="wbck" animationDuration="300ms" #tabGroup (selectedTabChange)="tabChanged($event)"
    [selectedIndex]="selectedTabIndex">

    <mat-tab>

        <ng-template mat-tab-label>
            <mat-icon class="my-bounce material-icons">pending_actions</mat-icon>
            <span class="ml-1">{{ 'LABEL.RECENSIONI' | translate }}</span>
        </ng-template>
        <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

            <app-reviews *ngIf="selectedTabIndex === 0"></app-reviews>

        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="my-bounce material-icons">report</mat-icon>
            <span class="ml-1">{{ 'LABEL.SOLICITS' | translate }}</span>
        </ng-template>
        <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

            <app-solicit-list *ngIf="selectedTabIndex === 1"></app-solicit-list>

        </div>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="my-bounce material-icons">policy</mat-icon>
            <span class="ml-1">{{ 'LABEL.LAMENTELE' | translate }}</span>
        </ng-template>
        <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

            <app-grievances *ngIf="selectedTabIndex === 2"></app-grievances>

        </div>
    </mat-tab>

    <!-- COMPLAINTS/CLAIMS -->
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="my-bounce material-icons">report</mat-icon>
            <span class="ml-1">{{ 'LABEL.COMPLAINTS' | translate }}</span>
        </ng-template>
        <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
            <app-claim-workflow *ngIf="selectedTabIndex === 3" [selectedTabIndex]="selectedSubTabIndex" [itemId]="itemId"></app-claim-workflow>
        </div>
    </mat-tab>

</mat-tab-group>