import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FTSearchResponse } from '../models/FTSearchResponse';
import { LibService } from './libService';
import { LogService } from './log.service';
import { UserSessionService } from './user-session.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  baseApi = environment.rootApiCrm + 'v1.0';
  name = "RequestService";

  constructor(public libService: LibService,
              public userSessionService: UserSessionService,
              private logService: LogService,
              public authService: AuthService
              ) { }

  getRequest(id: string) {
    return this.libService.getData<any>(this.baseApi, `/request/${id}`);
  }

  getAllRequests(): Observable<any> {
    // let requests = this.userSessionService.getState('getAllRequests');
    // if (requests !== null && requests !== undefined) {
    //   return of(requests);
    // } else {
      return this.libService.getData<any>(this.baseApi, `/requests`)//.pipe(
      //   tap(reqs => {
      //     this.userSessionService.saveState('getAllRequests', reqs);
      //   })
      // );
    // }
    // console.log("SERVICE FRONT: "+this.baseApi);

  }

  allWithPagination(filterOptions: any, orderOptions: any, skip: number, take: number){
    console.log("allWithPagination")
    let tokenId = this.authService.idToken()
    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption
    }
    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `/requests/allWithPagination`)
  }

  getBySubjectAndBuilding(ids: any): Observable<any> {
    return this.libService.postData<any>(ids, this.baseApi, `/requestsBySubjectAndBuilding`);
  }

  getAllSolicits(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `/solicits`)
  }

  getAllRequestsByAssignedOperator(email: string): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `/requestsOperator/${email.toString()}`);
  }

  getAllRequestsByOperatorAndStatus(email: string): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `/requestsOperatorAndStatus/${email.toString()}`);
  }

  updateRequest(id: string, request: any) {
    console.log("[Front Service Update] : ", request);
    return this.libService.postData<any>(request, this.baseApi, `/request/${id.toString()}`).pipe(
      tap(response => {
          this.logService.writeLog('POST', this.baseApi + `/request/${id.toString()}`, request, 'OK')
      }),
      catchError(error => {
          this.logService.writeLog('POST', this.baseApi + `/request/${id.toString()}`, request, error.status)
          return of(error);
      })
    );
  }

  deleteRequest(id: string, request: any) {
    return this.libService.deleteData<any>(request, this.baseApi, `/request/${id.toString()}`);
  }

  createRequest(request: any) {
    console.log("[Front Service] : ", request);
    return this.libService.postData<any>(request, `${this.baseApi}`,'/request').pipe(
      tap(response => {
          this.logService.writeLog('POST', this.baseApi + '/request', request, 'OK')
      }),
      catchError(error => {
          this.logService.writeLog('POST', this.baseApi + '/request', request, error.status)
          return of(error);
      })
    );
  }

  getAllStatus() {
    //return this.libService.getData<any>(this.baseApi, `/requestStatus`);
    return this.libService.getData<any>(this.baseApi, `/statusRequest`);
  }

  getSubjectIdsFTSearch(keyword: any) {
    console.log("getRequestIdsFTSearch - KEYWORD SERVICE FRONT --> ", keyword)
    return this.libService.postData<FTSearchResponse>(keyword, environment.rootApiCrm + 'v1.0', '/requestIndexsearch');
  }

  getById(id: string) {
    console.log("REQUEST SERVICE FRONT END ID --> ", id)
    if (id) {
      return this.libService.getData<any>(this.baseApi, `/request/${id.toString()}`);
    } else {
      return '';
    }
  }

  findRequestsByFilter(options: {key: string, values: string[]}[]){
    return this.libService.postData<Request[]>(options, this.baseApi, '/requestsFind')
  }

  findSolicitsByFilter(options: {key: string, values: string[]}[]){
    return this.libService.postData<Request[]>(options, this.baseApi, '/solicitsFind')
  }

  initWorkflow(attributes: any) {
    console.log("initWorkflow")
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<FTSearchResponse>(tokenId, attributes, environment.rootApiCrm + 'v1.0', '/requests/initWorkflow');
  }

  closeRequest(id: any, attributes: any) {
    console.log("closeRequest")
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<FTSearchResponse>(tokenId, attributes, environment.rootApiCrm + 'v1.0', `/requests/${id}/close`);
  }

  customerContacted(id: any, attributes: any) {
    console.log("customerContacted")
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<FTSearchResponse>(tokenId, attributes, environment.rootApiCrm + 'v1.0', `/requests/${id}/customerContacted`);
  }

  addConvention(id: number, conventionId: number) {
    console.log("addConvention")
    let tokenId = this.authService.idToken()
    let attributes = { 
      conventionId: conventionId
    }
    return this.libService.postDataWithAccessToken<FTSearchResponse>(tokenId, attributes, environment.rootApiCrm + 'v1.0', `/requests/${id}/addConvention`);
  }

}
