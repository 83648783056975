import { LibService } from './../services/libService';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, forkJoin, map, of, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserAreaEditModalComponent } from '../modals/user-area-edit-modal/user-area-edit-modal.component';
import { User } from '../models/user';
import { UserSessionService } from '../services/user-session.service';
import { RequestService } from '../services/request.service';
import { FileUploadModalComponent } from '../modals/file-upload-modal/file-upload-modal.component';
import { ActivitiesService } from '../services/activities.service';

@Component({
  selector: 'app-user-area',
  templateUrl: './user-area.component.html',
  styleUrls: ['./user-area.component.scss']
})
export class UserAreaComponent implements OnInit {

  defaultUserProfileImgUrl = "assets/media/avatars/300-6.jpg"
  userProfileImgUploadUrl: string
  profileImgSignedUrl: string;

  user: User = {}
  editMode: boolean = false
  languages = [{
    code: 'en',
    name: 'English'
  },
  {
    code: 'it',
    name: 'Italiano'
  }]

  requests: any[];
  activities: any[];         // User Requests
  dataSource: any[] = [{}];
  requests$ = this.requestService.getAllRequestsByOperatorAndStatus(this.authService.user().email);
  activities$ = this.activitiesService.getActivityByOwner(this.authService.user().email)
  count_requests = 0;

  user$ = this.userService.getUser(this.authService.user().email).pipe(
    map(user => user[0])
  )
  userprofileImgSigned$ = this.user$.pipe(
    switchMap(user => {
      return user.profileImageUrl? this.userService.getSignedUrl(user.profileImageUrl): of(this.defaultUserProfileImgUrl)
    })
  )

  constructor(
    private dialog: MatDialog,
    private userService: UserSessionService,
    private requestService: RequestService,
    private activitiesService: ActivitiesService,
    private authService: AuthService,
    private libService: LibService) {

  }

  ngOnInit(): void {

    combineLatest([this.user$, this.userprofileImgSigned$]).subscribe(([user, userProfileImgSigned]) => {
      this.user = user
      //console.log('user: ',this.user);
      this.userProfileImgUploadUrl = this.userService.baseApi + '/uploadImage/'+ this.user.id;

      this.profileImgSignedUrl = userProfileImgSigned
    })
    this.libService.lockPage('');
    this.user$.subscribe(res => {
      this.user = res
      //console.log('user: ',this.user);
      combineLatest([this.requests$, this.activities$]).subscribe({
        next: ([req, act]: any) => {
          this.requests = req;
          this.activities = act;
          Array.prototype.push.apply(this.requests,this.activities);
          this.libService.unlockPage();
          console.log("DATASOURCE --> ", this.requests);
        }

      })
      // this.requests$.subscribe(res => {
      //   this.requests = res;
      //   //console.log("REQUESTS --> ", this.requests);
      //   this.libService.unlockPage();
      // });
      this.userProfileImgUploadUrl = this.userService.baseApi + '/uploadImage/'+ this.user.id
    })
  }

  toggleEditMode(){
    this.dialog.open(UserAreaEditModalComponent, {data: this.user})
    .afterClosed()
    .subscribe(res => {
      console.log(res);

    })
  }

  openUploadModal(){
    this.dialog.open(FileUploadModalComponent, {
      data: {
        inputMode: 'users',
        toEntityId: this.user.id!
      }
    })
  }
}
