<mat-dialog-content>
  <form [formGroup]="formSurvey" class="form-container">
      <div>
        {{surveyMasterOriginName}}
      </div>
    <div *ngFor="let answerForm of answerControls; index as idx; last as isLast">
        <form [formGroup]="answerForm" class="form-container">
            <div>
              {{questionText(answerForm)}}
            </div>
            <!-- <mat-form-field class="form-subject">
              <mat-label>Domada</mat-label>
              <input matInput formControlName="text">
            </mat-form-field> -->
          <mat-form-field class="form-subject">
            <mat-label>{{ 'LABEL.ANSWER' | translate }}</mat-label>
            <input matInput formControlName="value">
          </mat-form-field>
        </form>
      </div>
      <div class="buttons-right">
        <mat-icon class="check-ok" *ngIf="formSurvey.valid">check</mat-icon>
        <mat-icon class="check-ko" *ngIf="!formSurvey.valid">warning</mat-icon>
        <button style="background-color:transparent;" [disabled]="!formSurvey.valid" matTooltip="Salva" type="button"
            class="my-bounce btn btn-primary mx-2" (click)="save()">
            <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
        </button>
      </div>
  </form>
</mat-dialog-content>
