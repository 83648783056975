import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { CollectionsService } from '../services/collections.service';
import { AuthService } from '../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {

  displayedColumns = [
  'CODICE_COMMESSA',
  'CODICE_SOGGETTO',
  'DATA_INCASSO',
  'DATA_SCADENZA',
  'DESCRIZIONE_SOGGETTO',
  'ID_COMMESSA',
  'ID_FATTURA',
  'IMPORTO_FATTURA',
  'IMPORTO_INCASSO',
  'IdSede',
  'NUMERO_FATTURA',
  'NUMERO_FATTURA_STORNATA',
  'NomeSede',
  'ORIGINE_INCASSO',
  'RESPONSABILE',
  'RESPONSABILE_CREDITO',
  'RESPONSABILE_ID',
  'SOCIETA',
  'SOCIETA_ID',
  'TIPO_SOCIETA',
  'TOTALE_NC',
  'TipoSede',
  'code'];
  
  dataSource: any;
  kwSearch: any;
  usr: any;
  @Input() practice: any;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;


  constructor(
    private collectionsService: CollectionsService,
    public libService: LibService,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslatorService
    ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.loadCollections();
  }


  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  loadCollections(): void {

    this.libService.lockPage('');
    this.collectionsService.getCollections(this.authService.user().email, this.practice).subscribe((response) => {
      console.log('loadCollections response %o', response);
      this.libService.unlockPage();
      if (response.esito == 'OK') {
        if (!(response.item == null) && !(response.item.Table == null))
        this.dataSource.data = response.item.Table;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    },
    err => {
      this.libService.unlockPage();
      //this.toastr.error(this.translate.instant('LABEL.DATA_COLLECTION_ERROR'), this.translate.instant('LABEL.Attenzione'));
    });

  }

}
