<div class="container-fluid my-container">
  <br>
  <h1>{{ 'EVENTS.mainTitle' | translate }}</h1>
  <button (click)="goToPage({id:0})" id="kt_sign_in_submit" class="btn btn-lg btn-primary">
    <span class="indicator-label text-dark">{{ 'EVENTS.add' | translate }}</span>
  </button>
  <div appearance="fill" class="form-subject">
    <mat-grid-list cols="2" rowHeight="5:1">

      <!-- FILTRI -->
       
    </mat-grid-list>
  </div>

  <br>


  <div style="overflow-x: auto;">

      <app-data-table-with-pagination [data]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns"
      [actions]="actions" [totalRows]="totalRows" [pageInit]="pageInit" [currentPage]="currentPage"
      (changePagination)="pageChanged($event)" (changeOrder)="updateDataBySortParams($event)"
      sortActive=""></app-data-table-with-pagination>

  </div>

</div>


<ng-template #itemDialog>
  <app-action-form></app-action-form>
</ng-template>