import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { FinanceService } from './finance.service';
import { LibService } from './libService';
import { UserSessionService } from './user-session.service';
import { AuthService } from '../auth/auth.service'


@Injectable({ providedIn: 'root' })
export class NotificationService {

    constructor(
        public libService: LibService,
        public financeService: FinanceService,
        public userSessionService: UserSessionService,
        public authService: AuthService,
    ) {}


    private notifications: any = [];
  
    public setNotifications(value: boolean): void {
      this.notifications = value;
    }
  
    public getNotifications(): boolean {
      return this.notifications;
    }


    async setUserToken() {
        let user = this.authService.user()
        let attributes = {
            id: '',
            userId: user.id.toString(),
            deviceToken: '',
            deviceOS: navigator.userAgent,
            status: '1',
        };

        try {
            //let res = await this.financeService.setUserToken(user.email, attributes)
        } catch (err) {
            console.log(err)
            throw err
        }
    }

}