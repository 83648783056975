<form [formGroup]="form" class="form-container" *ngIf="form && checkComponent()">

  <mat-form-field *ngIf="checkVisibility('companyId')" appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.COMPANY' | translate }}</mat-label>
    <mat-select (selectionChange)="getOperatorList($event)" formControlName="companyId">
      <mat-option *ngFor="let company of companies" [value]="company.id">
        <strong>{{company.name}}</strong> ({{company?.code}})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="form-subject" *ngIf="(form.get('id')?.value !== null && form.get('id')?.value !== undefined) && checkVisibility('solicitHandled')">
    <mat-radio-group formControlName="solicitHandled" name="solicitHandled">
      <label id="example-radio-group-label">{{ 'LABEL.SOLICIT_HANDLED' | translate }}</label>
      <br>
      <br>
      <mat-radio-button [value]="true" name="solicitHandled">

        {{ 'LABEL.YES' | translate }}

      </mat-radio-button>
      <mat-radio-button [value]="false" name="solicitHandled" style="margin-left: 2em;">

        {{ 'LABEL.NO' | translate }}

      </mat-radio-button>
      <br>
      <br>

    </mat-radio-group>
  </div>

  
  <mat-form-field *ngIf="checkVisibility('workType')" appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.WORKTYPE' | translate }}</mat-label>
    <mat-select formControlName="workType" [compareWith]="compareFn">
      <mat-option *ngFor="let workType of worktypes" [value]="workType">
        {{workType.workType}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="checkVisibility('solicitReason')" appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.SOLICIT_REASON' | translate }}</mat-label>
    <mat-select formControlName="solicitReason">
      <mat-option *ngFor="let reason of solicitReasons" [value]="reason.code">
        {{ 'LABEL.' + reason.uiLabel | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="checkVisibility('complaintType')" appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.COMPLAINT_REASON' | translate }}</mat-label>
    <mat-select formControlName="complaintType">
      <mat-option *ngFor="let type of complaintTypes" [value]="type">
        {{type.uiLabel | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="checkVisibility('notes')" appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.NOTE' | translate }}</mat-label>
    <input matInput formControlName="notes">
  </mat-form-field>

  <mat-form-field *ngIf="checkVisibility('contactChannel')" appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.CHANNEL' | translate }}</mat-label>
    <mat-select matNativeControl formControlName="contactChannel">
      <mat-option *ngFor="let cc of contactChannels$ | async" [value]="cc.name">
        {{ 'CHANNEL.'+cc.name | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="checkVisibility('origin')" appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.ORIGIN' | translate }}</mat-label>
    <mat-select formControlName="origin" #originInput>
      <mat-option *ngFor="let origin of origins$ | async" [value]="origin.origin" [disabled]="origin.disabled">
        {{ 'ORIGINS.'+origin.code | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="checkIfPoints()" appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.POINT' | translate }}</mat-label>
    <mat-select formControlName="pointId" #pointIdInput>
      <mat-option *ngFor="let point of points" [value]="point.id" [disabled]="!point.isOpen">
        {{ point.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="checkVisibility('marketingNoteId')" appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.MARKETINGNOTES' | translate }}</mat-label>
    <mat-select formControlName="marketingNoteId" #marketingNoteIdInput>
      <mat-option *ngFor="let marketingNote of marketingNotes" [value]="marketingNote.id" [disabled]="marketingNote.disabled">
        {{ marketingNote.translations[getCurrentLanguage()] }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="checkVisibility('assignmentOperator')" appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.ASS_OPERATOR' | translate }}</mat-label>
    <mat-select matNativeControl matTooltip="Prima di selezionare il responsabile collegare l'edificio ad una sede"
      formControlName="assignmentOperator">
      <ng-container *ngFor="let cc of operatorList">
        <mat-option *ngIf="cc.email !== ''" [value]="cc.email">
          {{cc.email}} <b> {{cc.UsersProfilesRel[0].name}} </b>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  
</form>