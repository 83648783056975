import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { DashboardService } from '../services/dashboard.service';
import { NavService } from '../services/nav.service';
import * as QuicksightEmbedding from 'amazon-quicksight-embedding-sdk';

@Component({
  selector: 'app-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent implements OnInit, OnChanges {

  @Input() dashboardId: string = '';

  dashboard: any;
  showSpinner = true;

  constructor(
    public navService: NavService,
    private dashboardService: DashboardService,
    private translate: TranslateService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dashboardId'] && changes['dashboardId'].currentValue !== changes['dashboardId'].previousValue) {
      this.dashboardService.getDashboardUrl(this.dashboardId).subscribe(res => {
        console.log(res);
        if (!(res == null) && !(res['url'] == null))
          this.getDashboard(res['url'])
      })
    }
  }

  public getDashboard(embeddedURL: any) {
    this.dashboard = null;
    this.showSpinner = true;
    let containerDiv = document.getElementById("dashboardContainer");
    containerDiv!.textContent = '';
    const options = {
      loadCallback: this.hideSpinner(),
      url: embeddedURL,
      container: containerDiv,
      scrolling: "yes",
      height: "AutoFit",
      width: "100%",
      printEnabled: true
    };
    this.dashboard = QuicksightEmbedding.embedDashboard(options);
  }

  hideSpinner() {
    console.log("Fully loaded Dashboard");
    this.showSpinner = false;
  }
}
