import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  baseApi = environment.rootApiCrm + 'v1.0';

  constructor(
    public libService: LibService,
    public authService: AuthService,
  ) { }

  getTransaction(id: string) {
    return this.libService.getData<any>(this.baseApi, `/transaction/${id.toString()}`);
  }

  getAllTransactions(): Observable<any> {
    console.log("SERVICE FRONT: "+this.baseApi);
    return this.libService.getData<any>(this.baseApi, `/transactions`);
  }

  updateTransaction(id: any, transaction: any) {
    return this.libService.postData<any>(transaction, this.baseApi, `/transaction/${id.toString()}`)
  }

  deleteTransaction(id: any, transaction: any) {
    return this.libService.deleteData<any>(transaction, this.baseApi, `/transaction/${id.toString()}`);
  }

  createTransaction(transaction: any) {
    return this.libService.postData<any>(transaction, `${this.baseApi}`,'/transaction');
  }

  allWithPagination(filterOptions: any = [], orderOptions: any = [], skip: number = 0, take: number = 10) {
    console.log("allWithPagination")
    let tokenId = this.authService.idToken()
    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption
    }
    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `/transactions/allWithPagination`)
  }

}
