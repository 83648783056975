import { map, tap, switchMap, of, Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TargetsService } from 'src/app/services/targets.service';
import { LibService } from 'src/app/services/libService';
import { TranslatorService } from 'src/app/services/translate.service';
import { NavService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-target-detail',
  templateUrl: './target-detail.component.html',
  styleUrls: ['./target-detail.component.scss']
})
export class TargetDetailComponent implements OnInit {

  isNew: boolean;
  id: any;
  target: any;
  target$: Observable<any>;
  formTargets: UntypedFormGroup;
  entities = [{name: 'Company', label: 'LABEL.COMPANY'}, {name: 'Profile', label: 'LABEL.PROFILE'}];





  constructor(private fb: UntypedFormBuilder,
              private targetService: TargetsService,
              private route: ActivatedRoute,
              private libService: LibService,
              public translate: TranslatorService,
              public navService: NavService,
              private router: Router)
  {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.id === 0? undefined:
      this.target$ = this.targetService.getTargetTypesById(this.id);
    });
    this.formTargets = this.fb.group({
      id: [null],
      typeCode: [null],
      description: [null, Validators.required],
      entityName: [null, Validators.required]
    })
  }

  ngOnInit(): void {
    this.initNavbar();
    if (this.id !== 0) {
      this.libService.lockPage('');
      this.target$.subscribe(val => {
        this.target = val;
        console.log("val -->", val);
        this.formTargets.patchValue(this.target);
        this.libService.unlockPage();
      })
    }
  }

  update(): void {
    if (this.formTargets.valid) {
      let targetTypeCommit: any;
      this.target = this.formTargets.getRawValue();
      this.id == 0?
        targetTypeCommit = this.targetService.createTargetType(this.target):
        targetTypeCommit = this.targetService.updateTargetType(this.target);

      this.libService.lockPage('');
      targetTypeCommit.subscribe((val: any) => {
        console.log("risultato chiamata --> ", val);
        this.router.navigate(['genconfig/']);
        this.libService.unlockPage();
      })
    }


  }

  initNavbar(){
    setTimeout(() => {
      let actions: any;
      // console.log("CRONOLOGIA --> ", this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString());
      actions = [
        { name: 'back', url: 'genconfig' }
      ];

      let title = this.formTargets.get('id')?.value !== null?
        this.translate.instant('LABEL.EDIT', { field: 'Obiettivo' }):
        this.translate.instant('LABEL.NEW', { field: 'Obiettivo' })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }

}
