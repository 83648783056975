import { MatDialog } from '@angular/material/dialog';
import { Observable, forkJoin } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { CompanyService } from '../../services/companyService';
import { LibService } from '../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSessionService } from '../../services/user-session.service';
import { NavService } from '../../services/nav.service';
import { SocietyService } from '../../services/societyService';
import { TranslatorService } from '../../services/translate.service';
import { MatPaginator } from '@angular/material/paginator';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { ActionService } from '../../services/action.service'
import { ActionCategoryService } from '../../services/actionCategory.service'
import { WorkflowService } from '../../services/workflow.service'
import { StateCategoryService } from '../../services/state-category.service'
import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';

import { SettingsModalFormComponent } from '../settings-modal-form/settings-modal-form.component';


@Component({
  selector: 'app-multi-action-settings',
  templateUrl: './multi-action-settings.component.html',
  styleUrls: ['./multi-action-settings.component.scss']
})

export class MultiActionSettingsComponent implements OnInit, OnDestroy {

  itemType = "actions"
  dataSource: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  sort: MatSort

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('itemDialog') itemDialog: TemplateRef<any>
  @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  dropdownListSocieties: any[] = [];
  societies: any[] = [];
  selectedSociety: any[] = [];

  dropdownListLicences: any[] = [];
  licences: any = [];
  selectedLicences: any[] = [];

  dropdownLicencesSettings: IDropdownSettings = {};

  dropdownSocietySettings: IDropdownSettings = {};
  activeCompany: any = {};

  searchTimeout: any;

  actionList: any = [];
  actionCategories: any = [];
  workflows: any = [];
  stateCategories: any = [];

  constructor(public navService: NavService,
    private societyService: SocietyService,
    private companyService: CompanyService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    public translate: TranslatorService,
    private toastr: ToastrService,
    private matDialog: MatDialog,
    private actionService: ActionService,
    private actionCategoryService: ActionCategoryService,
    private workflowService: WorkflowService,
    private stateCategoryService: StateCategoryService,
  ) {
    this.dataSource = new MatTableDataSource();
    //this.profileService.getAllProfiles().subscribe(r => this.data = r);
  }


  displayedColumns = ['id', 'label', 'code', 'category'];

  structure: any = {
    id: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
    name: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
  }



  columns: any = [
    {
      name: 'id',
      flex: '6',
      title: this.translate.get('LABEL.ID'),
      cssClass: (item: any) => [item.disabled ? 'disabledRow' : ''],
      value: (item: any) => item?.id
    },
    {
      name: "label",
      title: this.translate.get('LABEL.name'),
      cssClass: (item: any) => [item.disabled ? 'disabledRow' : ''],
      value: (item: any) => item?.label
    },
    {
      name: "code",
      title: this.translate.get('LABEL.keyFrontEnd'),
      cssClass: (item: any) => [item.disabled ? 'disabledRow' : ''],
      value: (item: any) => item?.code
    },
    {
      name: "category",
      title: this.translate.get('LABEL.actionCategory'),
      cssClass: (item: any) => [item.disabled ? 'disabledRow' : ''],
      value: (item: any) => item?.itemCategory?.label
    },
  ]



  actions: any = [];


  setUserActions() {
    // SHOW

      this.actions.push({
        label: of(''),
        cssClass: (item: any) => [item.disabled ? 'disabledRow' : ''],
        icon: (item: any) => {
          return this.iconRoutine(item);
        },
        onClick: (item: any) => this.goToPage(item),
        color: 'primary'
      })
    
  }

  iconRoutine(subject: any) {
    return 'edit';
  }

  deleteItem(item: any) {

    if (confirm("Sei sicuro di voler cancellare l'Action?") == true) {
      this.libService.lockPage('');

      this.actionService.destroy(item.id).subscribe((responseData: any) => {
        console.log('Destroy Item response %o', responseData);
        this.loadData()
        this.libService.unlockPage();
      }, err => {
        console.log(err)
        this.libService.unlockPage();
      });
    }

  }




  testlogin() {
    /*
    let py = {
      js: {
        triggerSource: 'testTrigger',
        userPoolId: 'testPool',
        userName: 'testName',
        callerContext: { clientId: '12345' },
        response: {}
      },
      user: {
        name: 'myname 21',
        username: 'myusername 21',
        locale: 'it-IT',
        email: 'myemail 23'
      }
    };
    this.libService.postData(py,'https://albertfoundation.dune.it/users/login','').subscribe(t => {

      console.log('t after testlogin %o', t)
    });
    */

    let py = {
      "str": "1fc2889af1a2eb75d58ec532d025b3847ff8461c6c6b7e1cd897dc776c20bab3",
      "key": "199c2470cbdef654dderf477d907b1eu",
      "iv": "c9e466c7518362ee"
    };
    this.libService.postData(py, 'https://albertfoundation.dune.it/cr/decrypt', '').subscribe(t => {

      console.log('t after testlogin %o', t)
    });

  }

  loadLabels() {
    // this.translate.get('SIDEBAR.Sedi').subscribe(res => {
    //   this.title = res;
    //   this.subTitle = "EDAC - " + res;
    // })
    this.title = this.translate.instant('SIDEBAR.Sedi');
    this.subTitle = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  setActiveCompany(e: any) {
    this.activeCompany = e;
  }

  selectAttributesLicense(e: any) {
    this.mapsLicenseState(e);
    const dialogRef = this.matDialog.open(this.licensesDialog, {
      panelClass: 'custom-dialog-container',
      width: '60%'
    })
  }

  openItemDialog(item: any) {
    //this.mapsSocietyState(e);
    const dialogRef = this.matDialog.open(this.itemDialog, {
      panelClass: 'custom-dialog-container',
      width: '60%'
    })
  }

  getSocieties() {
    let p: any[] = [];
    for (let a = 0; a < this.societies.length; a++) {
      p.push({ id: this.societies[a].id, name: this.societies[a].name });
    }
    return p;
  }

  getLicences() {
    let p: any[] = [];
    for (let a = 0; a < this.licences.length; a++) {
      p.push({ id: this.licences[a].id, code: this.licences[a].code });
    }
    return p;
  }

  saveCompany(company: any) {
    console.log('save Company %o activeUser %o', company, this.activeCompany);

    company.societies = this.selectedSociety.map(ds => {
      return ds.id
    });

    company.licences = this.selectedLicences.map(ds => {
      return ds.id
    });

    console.log('company for update %o', company);
    this.libService.lockPage('');
    this.companyService.setSociety(company).subscribe(r => {
      this.companyService.setLicences(company).subscribe(r => {
        this.libService.unlockPage();
        this.toastr.success(
          this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
      }, err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_6') + err.message, this.translate.instant('TOASTR.ERROR'));
      });
    },
      err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_6') + err.message, this.translate.instant('TOASTR.ERROR'));
      });
  }

  noEdit(element: any) {
    // console.log('element %o activeUser %o', element, this.activeUser);
    if (element == null)
      return true;
    if (this.activeCompany == null)
      return true;
    if (this.activeCompany.id == element.id)
      return false;
    else
      return true;
  }

  mapsLicenseState(company: any) {
    this.selectedLicences = company.CompaniesLicencesRel.map((e: any) => {
      return { id: e.id, code: e.code }
    });
  }

  mapsSocietyState(company: any) {
    this.selectedSociety = company.CompaniesSocietiesRel.map((e: any) => {
      return { id: e.id, name: e.name }
    });
  }


  ngOnInit(): void {

    this.initWorkflow()

    this.getActionCategories()

    this.selectedSociety = [
    ];

    this.dropdownSocietySettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessuna società disponibile',
      allowSearchFilter: true
    };

    this.selectedLicences = [
    ];

    this.dropdownLicencesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      selectAllText: 'Seleziona Tutti',
      unSelectAllText: 'Deseleziona Tutti',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessuna licenza disponibile',
      allowSearchFilter: true
    };

    this.loadLabels();
    this.loadData();
    this.setUserActions();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      { name: 'new', url: 'actions/0' },
     // { name: 'export', url: '' },
    ];
    this.navService.setActions(actions);

    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

    this.libService.lockPage('');
    this.societyService.getAllSocieties().subscribe((response) => {
      console.log('loadSocieties response %o', response);
      if (response.length > 0) {
        this.societies = response;
        this.dropdownListSocieties = this.getSocieties();
      }
    },
      err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_7') + err.message, this.translate.instant('TOASTR.ERROR'));
      });

  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  updateCompaniesData() {
    this.libService.lockPage('')
    const curr = this.paginator.pageIndex * this.paginator.pageSize
    const size = this.paginator.pageSize
    const observables = []
    const displayed = this.dataSource?.sortData(this.dataSource.filteredData, this.dataSource.sort)
    const fullData = this.dataSource.data
    const filter = this.dataSource.filter

    const indexesToFind = []
    for (let i = curr; i < curr + size; i++) {
      indexesToFind.push(displayed[i]?.id)
    }


    const indexes: number[] = []
    indexesToFind.forEach(iToFind => {
      for (let i = 0; i < fullData?.length; i++) {
        if (iToFind == fullData[i].id) {
          indexes.push(i)
          return
        }
      }
    })

    for (let i = 0; i < size; i++) {
      if (fullData[indexes[i]]?.id)
        observables.push(this.companyService.getCompany(fullData[indexes[i]].id))
    }
    if (observables.length == 0) this.libService.unlockPage()
    forkJoin(observables).subscribe(fullusers => {
      for (let i = 0; i < size; i++) {
        if (fullusers[i]) fullData[indexes[i]] = fullusers[i]
      }
      this.dataSource.data = fullData
      this.libService.unlockPage()
    })
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();

    clearTimeout(this.searchTimeout)
    // this.searchTimeout = setTimeout(() => this.updateCompaniesData(), 1000)
  }

  initWorkflow() {
    this.workflowService.index().subscribe((responseData: any) => {
      this.workflows = responseData.filter((workflow: any)=> !workflow.disabled);
    });
  }

  initStateCategories() {
    this.stateCategoryService.index().subscribe((responseData: any) => {
      this.stateCategories = responseData;
    });
  }

  loadData(): void {

    this.libService.lockPage('');

    this.stateCategoryService.index().subscribe((responseData: any) => {
      this.libService.unlockPage();
      this.actionList = responseData
      this.dataSource = responseData;
      this.stateCategories = responseData;
    }, err => {
      console.log(err)
    });

  }

  goToPage(item: any) {
    this.router.navigate(['settings/multi-action-settings/' + item.id]);
  }

  openModalForm(item: any) {
    const dialogRef = this.matDialog.open(SettingsModalFormComponent, {
      width: '600px',
      data: {
        item: item,
        model: {
          id: {
            type: "number",
            value: item.id,
            label: "Id",
            rules: {
              required: true,
            }
          },
          name: {
            type: "text",
            value: item.name,
            label: "Label",
            rules: {
              required: true,
            }
          },
          keyFrontEnd: {
            type: "text",
            value: item.keyFrontEnd,
            label: "Code",
            rules: {
              required: true,
            }
          },
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (![null, undefined].includes(result)) {
        this.saveForm(result)
      }
    });


  }

  saveForm(attributes: any) {
    console.log(attributes)
  }

  filter(event:any){
    console.log(event)
    let actions = this.actionList.filter((action:any)=> action.ActionCategoryId == event.value)
    this.dataSource = actions
  }

  filterByWorkflow(event: any) {
    let workflowId = event.value

    let actions = this.actionList.filter((action: any) => {
      let status = false
      action.componentsStates.forEach((componentsStates: any) => {
        if (componentsStates.state.WorkflowId == workflowId) status = true
      })
      return status
    });
    this.dataSource = actions
  }

  getActionCategories(){
    this.actionCategoryService.index().subscribe((res:any)=>{
      this.actionCategories = res
    })
  }

}
