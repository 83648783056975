import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, mergeMap } from 'rxjs/operators';
import { DocumentService } from './document.service';
import { Order } from '../models/order';
import { FTSearchResponse } from '../models/FTSearchResponse';

@Injectable({
  providedIn: 'root'
})
export class TargetsService {
  name = "TargetsService";

  baseApi = environment.rootApiFoundation;
  /* baseApi = "http://localhost:80/"; */

  constructor(
    public libService: LibService,
    private documentService: DocumentService
  ) { }

  //TARGET TYPES ------------------------------------------------------------------------------------------
  getTargetTypesProfile(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, 'targetTypes/profile');
  }

  getTargetTypesCompany(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, 'targetTypes/company');
  }

  createTargetType(targetType: any): Observable<any> {
    return this.libService.postData<any>(targetType, this.baseApi, 'targetTypes/');
  }

  updateTargetType(targetType: any): Observable<any> {
    return this.libService.putData<any>(targetType, this.baseApi, `targetTypes/${targetType.id.toString()}`);
  }

  getTargetTypesById(id: any): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `targetTypes/${id.toString()}`);
  }

  //COMPANY TARGETS ---------------------------------------------------------------------------------------

  getCompanyTargets(): Observable<any> {
    // console.log("SERVICE FRONT: "+this.baseApi);
    return this.libService.getData<any>(this.baseApi, `companyTargets/`);
  }

  getCompanyTargetsByTargetAndYear(type: any, year: any): Observable<any> {
    let body = { type: type, year: year};
    console.log("BODY REQUEST -->", body)
    // console.log("TYPE SERVICE FRONT END --> ", body);
    return this.libService.postData<any>(body, this.baseApi, 'companyTargets/companyTargetsByTargetAndYear');
  }

  createCompanyTarget(companyTarget: any): Observable<any> {
    // console.log("TYPE SERVICE FRONT END --> ", companyTarget);
    return this.libService.postData<any>(companyTarget, this.baseApi, 'companyTargets/');
  }

  createCompanyTargetFromArray(array: any): Observable<any> {
    // console.log("ARRAY SERVICE FRONT END --> ", array);
    console.log("ARRAY BODY --> ",array);
    return this.libService.postData<any>(array, this.baseApi, 'companyTargets/array');
  }

  updateCompanyTarget(companyTarget: any): Observable<any> {
    // console.log("TYPE SERVICE FRONT END --> ", companyTarget);
    return this.libService.putData<any>(companyTarget, this.baseApi, `companyTargets/${companyTarget.id.toString()}`);
  }

  //PROFILE TARGETS ---------------------------------------------------------------------------------------

  getProfileTargets(): Observable<any> {
    // console.log("SERVICE FRONT: " + this.baseApi);
    return this.libService.getData<any>(this.baseApi, `profileTargets/`);
  }

  getProfileTargetsByTargetAndYear(type: any, year: any): Observable<any> {
    let body = { type: type, year: year};
    console.log("TYPE SERVICE FRONT END --> ", body);
    return this.libService.postData<any>(body, this.baseApi, 'profileTargets/profileTargetsByTargetAndYear');
  }

  createProfileTarget(companyTarget: any): Observable<any> {
    // console.log("TYPE SERVICE FRONT END --> ", companyTarget);
    return this.libService.postData<any>(companyTarget, this.baseApi, 'profileTargets/');
  }

  createProfileTargetFromArray(array: any): Observable<any> {
    // console.log("ARRAY SERVICE FRONT END --> ", array);
    return this.libService.postData<any>(array, this.baseApi, 'profileTargets/array');
  }

  updateProfileTarget(companyTarget: any): Observable<any> {
    // console.log("TYPE SERVICE FRONT END --> ", companyTarget);
    return this.libService.putData<any>(companyTarget, this.baseApi, `profileTargets/${companyTarget.id.toString()}`);
  }
}
