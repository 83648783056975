import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../services/dashboard.service';
import { DataSetService } from '../services/dataset.service';
import { LibService } from '../services/libService';
import { NavService } from '../services/nav.service';
import { UserSessionService } from '../services/user-session.service';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {

  displayedColumns = ['id', 'name','dashId','country', 'createdAt', 'updatedAt', 'editAction', 'deleteAction'];
  dataSource: any;
  kwSearch: any;
  title = "Dashboards";
  subTitle = "EDAC - Dashboards";
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  currentUrl: string;

  constructor(
    public navService: NavService,
    private dashboardService: DashboardService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    public translate: TranslatorService) {
    this.dataSource = new MatTableDataSource();
    //this.profileService.getAllProfiles().subscribe(r => this.data = r);
  }
  
  loadLabels(){
    this.translate.get('LABEL.DASHBOARDS').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.loadLabels();
    this.loadDataSets();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
      {name:'new',url:'dashboard/0'},
      {name:'export',url:''},
    ];
    this.navService.setActions(actions);
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  goTo(e: any) {
    this.router.navigate(['dashboard-edit/' + e.id]);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  loadDataSets(): void {

    this.libService.lockPage('');
    this.dashboardService.getAllDashboards().subscribe((response) => {
      console.log('loadDashboards response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
  }
}
