import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { LibService } from './libService';
import { Condominium } from '../models/condominium';
import { FTSearchResponse } from '../models/FTSearchResponse';
import { UserSessionService } from './user-session.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class CondominiumService {

  name = 'CondominiumService';
  baseApi = environment.rootApiCrm + 'v1.0' ; //'v1.0/condominiums'

  constructor(public libService: LibService,
              public userSessionService: UserSessionService,
              private logService: LogService) { }

  create(condominium: Condominium){
    return this.libService.postData<Condominium>(condominium, this.baseApi, '/condominium').pipe(
      tap(response => {
          this.logService.writeLog('POST', this.baseApi + '/condominium', condominium, 'OK')
      }),
      catchError(error => {
          this.logService.writeLog('POST', this.baseApi + '/condominium', condominium, error.status)
          return of(error);
      })
    )
  }

  update(condominium: Condominium){
    return this.libService.postData<Condominium>(condominium, this.baseApi, `/condominiums/${condominium.id}`).pipe(
      tap(response => {
          this.logService.writeLog('POST', this.baseApi +`/condominiums/${condominium.id}`, condominium, 'OK')
      }),
      catchError(error => {
          this.logService.writeLog('POST', this.baseApi + `/condominiums/${condominium.id}`, condominium, error.status)
          return of(error);
      })
    )
  }

  findCondominiumById(idCondominium: number) {
    return this.libService.getData<any>(environment.rootApiCrm + 'v1.0/', `condominium/${idCondominium}`);
  }

  getAllCondominiums(userEmail: string): Observable<any> {
    // let condominiums = this.userSessionService.getState('getAllCondominiums');
    // if (condominiums !== null && condominiums !== undefined) {
    //   return of(condominiums);
    // } else {
      return this.libService.postData<any>({userEmail: userEmail}, this.baseApi, '/condominiums');//.pipe(
    //     tap(condoms => {
    //       this.userSessionService.saveState('getAllCondominiums', condominiums);
    //     })
    //   );
    // }

  }

  getSubjectIdsFTSearch(keyword: any) {
    console.log("getSubjectIdsFTSearch - KEYWORD SERVICE FRONT --> ", keyword)
    return this.libService.postData<FTSearchResponse>(keyword, this.baseApi, '/condominiums/condominiumsIndexsearch');
  }

  getById(ids: any) {
    console.log("id Condominium da cercare nel DB --> ", ids)
    let uniqueIds = [...new Set(ids)];
    if (uniqueIds) {
      return this.libService.postData<any>(uniqueIds, this.baseApi, `/condominiumById`);
    } else {
      return '';
    }
  }

  find(options: {key: string, values: string[]}[]){
    return this.libService.postData<Condominium[]>(options, this.baseApi, '/condominiumsFind')
  }

  checkVisibility(id: string, userEmail: string){
    return this.libService.postData<boolean>({id: id, userEmail: userEmail}, this.baseApi, '/condominiumCheckVisibility')
  }

  findCondominiumByAddress(condominium: any) {
    return this.libService.postData<Condominium>(condominium, this.baseApi, '/condominiumByAddress')
  }
}
