import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Answer, Survey, SurveyMaster } from 'src/app/models/survey';
import { SurveyMasterService } from 'src/app/services/survey-master.service';
import { SurveyViewComponent } from 'src/app/survey/survey-view/survey-view.component';

@Component({
  selector: 'app-building-survey',
  templateUrl: './building-survey.component.html',
  styleUrls: ['./building-survey.component.scss']
})
export class BuildingSurveyComponent implements OnInit {

  @Input()form: UntypedFormGroup;
  @Input() toDisable: boolean;

  selectedSurvey: SurveyMaster;
  formSurvey : any;
  surveyMasters$: Observable<SurveyMaster[]>
  filteredSubjectSurveys: any[];

  constructor(private surveyMasterService: SurveyMasterService, private fb: UntypedFormBuilder, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.form.get('surveys')?.valueChanges.subscribe((surveys: Survey[]) => {
      console.log('[SubjectSurvey] surveys valuechanges:', surveys);
      if(surveys){
        this.filteredSubjectSurveys = [...new Set(surveys.map(survey => survey.surveyMasterOrigin?.name))]
        this.filteredSubjectSurveys = this.filteredSubjectSurveys.map((type: string) => {
          return {
            name: type,
            surveys: surveys.filter(survey => survey.surveyMasterOrigin?.name === type)
          }
        })
      }

      console.log('[SubjectSurvey]: filteredSubjectSurveys: ', this.filteredSubjectSurveys);

    })


    this.surveyMasters$ = this.surveyMasterService.getByEntityName(this.form.get('type')?.value)
    /* this.subscribe(surveyMaster => {


      this.generateSurveyFromMaster(surveyMaster[0])
    }) */
  }

  get answers(){
    return this.formSurvey.controls['answers'] as UntypedFormArray
  }


  generateSurveyFromMaster(surveyMaster: SurveyMaster){

    return this.fb.group({
      id: null,
      surveyMasterOrigin: surveyMaster,
      building: this.form.get('id')?.value,
      answers: this.fb.array(surveyMaster.questions!.map(question => {return this.fb.group({
        id: null,
        value: [null, question?.mandatory ? Validators.required : null],
        question: this.fb.group(
          {
            id: question?.id,
            text: question?.text
          })
      })}))
    })
  }

  generateSurveyFromSurvey(survey: Survey){
    return this.fb.group({
      id: survey.id,
      surveyMasterOrigin: survey.surveyMasterOrigin,
      building: this.form.get('id')?.value,
      answers: this.fb.array(survey.answers!.map(answer => {return this.fb.group({
        id: answer.id,
        value: [answer.value, answer.question?.mandatory ? Validators.required : null],
        question: this.fb.group(
          {
            id: answer.question?.id,
            text: answer.question?.text
          })
      })}))
    })
  }

  openSurveyDialog(survey: Survey){
    this.dialog.open(SurveyViewComponent, {
      data: {
        surveyForm: this.generateSurveyFromSurvey(survey)
      }
    })
    .afterClosed()
    .subscribe(survey => {
      console.log('surveyView response: ', survey);
      let surveys = this.form.get('surveys')?.value
      let index = surveys.findIndex((surveyL: Survey) => surveyL.id === survey.id)
      surveys[index]=survey;
      this.form.get('surveys')?.setValue(surveys)
    })
  }

  openSurveyDialogNew(survey: SurveyMaster){
    this.dialog.open(SurveyViewComponent, {
      data: {
        surveyForm: this.generateSurveyFromMaster(survey)
      }
    })
    .afterClosed()
    .subscribe(survey => {
      console.log('surveyView response: ', survey);
      let surveys = (this.form.get('surveys')?.value)
      surveys.push(survey)
      this.form.get('surveys')?.setValue(surveys)
    })
  }
  selectedChanged(event: any){
    //this.generateSurveyFromMaster(event.value)
  }
}
