<div style="width: 800px;">
  <div class="modal-header">
    <h5 class="modal-title">{{ 'USER_AREA.USER_LOG' | translate }}</h5>
    <a matTooltip="Esporta dati" class="nav-link p-0" role="button" (click)="downloadLogs()" aria-expanded="false">
      <fa-icon [icon]="faFileArrowDown" class="text-muted h2 mx-3"></fa-icon>
    </a>
    <button #closeModalLicences type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Chiudi"
      (click)="closeDialog()"></button>
  </div>
  <mat-dialog-content>
    <div class="modal-body">
      <div class="mb-3">
        <div class="search-wrapper mat-elevation-z2">
          <div class="search" fxLayout="row" fxLayoutAlign="start center">
            <form [formGroup]="dateForm">
              <label>{{ 'USER_AREA.FROM' | translate }}</label>
              <input type="date" formControlName="from" />

              <label>{{ 'USER_AREA.TO' | translate }}</label>
              <input type="date" formControlName="to" />

              <button type="button" class="btn" style="background-color:transparent;" (click)="submitSearch()"><span
                  class="material-icons icsave">search</span></button>
            </form>

          </div>
        </div>
        <div style="overflow-x: auto;">
          <table mat-table [dataSource]="dataSource.data" matSort style="width:100%;">

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Id</th>
              <td style="font-size: 9px;" mat-cell *matCellDef="let element">
                {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="timestamp">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'USER_AREA.TIMESTAMP' | translate }}</th>
              <td style="font-size: 9px;" mat-cell *matCellDef="let element">
                {{element.timestamp | date: 'short' }} </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'USER_AREA.ACTION' | translate }}</th>
              <td  style="font-size: 9px;" mat-cell *matCellDef="let element">
                <pre>{{element.action.path | json}}</pre> <!-- {{element.action}} --> </td>
            </ng-container>

            <ng-container matColumnDef="response">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'USER_AREA.RESPONSE' | translate }}</th>
              <td style="font-size: 9px;" mat-cell *matCellDef="let element">
                <b>{{element.response}}</b> </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
        </mat-paginator>
      </div>
    </div>
  </mat-dialog-content>
</div>
