import { AuthService } from './../../auth/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, tap, pairwise, of } from 'rxjs';
import { Company } from 'src/app/models/company';
import { Society } from 'src/app/models/society';
import { User } from 'src/app/models/user';
import { BuildingService } from 'src/app/services/building.service';
import { CompanyService } from 'src/app/services/companyService';
import { CondominiumService } from 'src/app/services/condominium.service';
import { LibService } from 'src/app/services/libService';
import { RequestService } from 'src/app/services/request.service';
import { SocietyService } from 'src/app/services/societyService';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { UserDataService } from 'src/app/services/users.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { StateCategoryService } from 'src/app/services/state-category.service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-practices-filter',
  templateUrl: './practices-filter.component.html',
  styleUrls: ['./practices-filter.component.scss']
})
export class PracticesFilterComponent implements OnInit {

  @Input() type: string;
  @Input() branches: any = [];
  @Input() disabled: boolean;
  @Input() states: any;
  @Input() stateCategories: any;

  @Output() filterResult = new EventEmitter;
  @Output() getFilterSelection = new EventEmitter;

  societies: Society[] = [];
  responsibles: string[] = [];
  companies: Company[] = [];
  years: string[] = [];
  statuses: any;
  lock: boolean = false;

  noUsers: boolean = true;

  form: UntypedFormGroup;

  /* companies$ = this.companyService.getAllCompanies().pipe(
    map((companies: Company[]) => {
      return companies.sort((a, b) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      companies.map(company => {
        if(company.CompaniesSocietiesRel !== undefined){
          this.societies.push(...company.CompaniesSocietiesRel)
        }
        if(company.CompaniesUsersRel !== undefined){
          this.responsibles.push(...company.CompaniesUsersRel!.map(user => user.email!))
        }
      })
      this.societies = this.unique(this.societies, ['code'] )
      this.responsibles = [...new Set(this.responsibles)]
      this.companies = companies
    })
  ); */

  logUsr$ = this.sessionService.getUser(this.authService.user().email)

  // this.companyService.getAllCompaniesFast().pipe(
  //   tap(companies => {
  //     this.companies = companies
  //   })
  // )

  // societies$ = this.societyservice.getAllSocieties().pipe(
  //   tap(societies => {
  //     this.societies = societies
  //   })
  // )

  responsibles$ = this.userService.getAllUsersFast().pipe(
    tap(users => {
      users = users.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
      let pippo = users.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
      this.responsibles = pippo.map((user: User) => user.email)
    })
  )

  status$ = this.orderService.getOrderStatuses().pipe(
    tap(status => {
      console.log("STATUS --> ", status)
      this.statuses = status;
      if (this.statuses !== null && this.statuses !== undefined)
        this.statuses.sort((a : any , b: any) => this.translate.instant('LABEL.' + a.descx!) <= this.translate.instant('LABEL.' + b.descx!) ? -1 : 1)
    })
  )

  constructor(
    private fb: UntypedFormBuilder,
    private companyService: CompanyService,
    private userService: UserDataService,
    private sessionService: UserSessionService,
    private requestService: RequestService,
    private toastr: ToastrService,
    private libService: LibService,
    public translate: TranslateService,
    public orderService: OrderService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.generateYears();
    this.form = this.fb.group({
      company: [[], Validators.required ],/* Validators.required */
      companyLocation: [[], null],
      branch: [[], null],
      year: [[] ,/* Validators.required */],/* Validators.required */
      assignmentOperator: [[], /* Validators.required */ ],/* Validators.required */
      states: [[], /* Validators.required */ ]
    })
  }

  ngOnInit(): void {

    this.form.valueChanges.subscribe(form1 => {

      this.sessionService.saveState('OrderFilters', form1);
    })
    this.form.get('company')?.valueChanges.subscribe((current) => {
      console.log(current);
      this.noUsers = true;
      this.cdr.detectChanges();
      if (current.length > 0) {
        this.noUsers = false;
        this.cdr.detectChanges();
        this.userService.getByCompanyCode(current.map((a: any)=> a.code)).subscribe(users => {
          console.log("COMPANY CODES --> ", current);
          console.log("USERS BY COMPANY CODE --> ", users);
          users = users.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
          let pippo = users.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
          this.responsibles = pippo;
          // this.responsibles = users;
        })
      }
    })
    combineLatest([this.logUsr$, this.status$]).subscribe(([loggedUser, status]: any) => {
      console.log("COMPANIES --> ", loggedUser)
      this.companies = loggedUser[0].UsersCompaniesRel;
      let prevFilters = this.sessionService.getState('OrderFilters');
      if (prevFilters?.company?.length > 0 || prevFilters?.year?.length > 0|| prevFilters?.assignmentOperator?.length > 0 || prevFilters?.statusId?.length > 0) {
        this.form.patchValue(prevFilters);
        this.filter();
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // this.disabled === true? Object.keys(this.form.controls).forEach(control => {this.form.get(control)?.disable()}): console.log("");
    // this.cdr.detectChanges();
  }

  getFilter(){

      let formValues = this.form.getRawValue()
      let resFilters : any = {}
      // console.log("FORM VALUE --> ", this.form.getRawValue())
      //salvataggio filtri nel session Storage

      this.sessionService.saveState('OrderFilters', formValues);
      let options = Object.keys(formValues).map((key, index, array) => {
        let option: any
        //let values: any
        switch (key) {
          case 'company':
            let valuesCompany = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value['id']):
              [null]
       
            resFilters[key] = valuesCompany
            break;
            case 'branch':
            let valuesbranch = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value):
              [null]
      
            resFilters[key] = valuesbranch
            break;
          case 'year':
            let valuesCompanyLocation = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value):
              [null]
      
            resFilters[key] = valuesCompanyLocation
            break;
          case 'assignmentOperator':
            let valuesResponsible = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => ({id: value.id, email: value.email})):
              [null]
       
            resFilters[key] = valuesResponsible
            break;
          case 'states':
            let stateCategoryIds = formValues[key]?.map((stateCategory:any)=> stateCategory.id)
            let statuses = stateCategoryIds?.length !== undefined?
            this.states.filter((state:any)=> stateCategoryIds.includes(state.StateCategoryId))?.map((state: any)=> state.id) :
              [null]
      
            resFilters[key] = statuses
            break;

          default:
            break;
        }
        return resFilters
      })

      this.getFilterSelection.next(resFilters);
  }

  filter(){

    if (this.form.valid  === true) {
      let formValues = this.form.getRawValue()
      // console.log("FORM VALUE --> ", this.form.getRawValue())
      //salvataggio filtri nel session Storage

      this.sessionService.saveState('OrderFilters', formValues);


      let options = Object.keys(formValues).map((key, index, array) => {
        let option: any
        //let values: any
        switch (key) {
          case 'company':
            let valuesCompany = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value['id']):
              [null]
            option = {
              key: key,
              values: valuesCompany
            }
            break;
            case 'branch':
            let valuesBranch = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value):
              [null]
            option = {
              key: key,
              values: valuesBranch
            }
            break;
          case 'year':
            let valuesCompanyLocation = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value):
              [null]
            option = {
              key: key,
              values: valuesCompanyLocation
            }
            break;
          case 'assignmentOperator':
            let valuesResponsible = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => ({id: value.id, email: value.email})):
              [null]
            option = {
              key: key,
              values: valuesResponsible
            }

            break;
          case 'states':
            let statuses = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value['id']):
              [null]
            option = {
              key: key,
              values: statuses
            }

            break;

          default:
            break;
        }
        return option
      })
      console.log("OPTIONS FILTER --> ", options);
      options = options.filter(option => option?.values?.length > 0)
      // options.forEach(element => {
      //   if (element.values !== null && element.values !== undefined) {
      //     if (element.values.length > 1) {
      //       this.toastr.error(this.translate.instant("TOASTR.MESSAGE42"), this.translate.instant("TOASTR.WARNING"))
      //       this.lock = true;
      //       return;
      //     }
      //   }
      // });

      if (this.lock === false) {
        this.libService.lockPage('')
        switch (this.type) {
          case 'Richiesta':
            this.requestService.findRequestsByFilter(options).subscribe({
              next: (requests => {
                requests.sort((a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt))
                this.filterResult.next({data: requests, clear: false});
                this.libService.unlockPage()
              }),
              error: (err => {
                this.toastr.error(err)
                this.libService.unlockPage()
              })
            })
            break;
          case 'Pratica':
            //DA IMPLEMENTARE LA CHIAMATA A BACKEND
            this.orderService.findByFilter(options).subscribe({
              next: (requests => {
                requests.sort((a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt))
                this.filterResult.next({data: requests, clear: false});
                this.libService.unlockPage()
              }),
              error: (err => {
                this.toastr.error(err)
                this.libService.unlockPage()
              })
            })
            break;
        }
      } else {
        this.lock = false;
      }
    } else {
      //this.toastr.warning(this.translate.instant("TOASTR.MESSAGE41"), this.translate.instant("TOASTR.WARNING"));
    }

  }

  clear(){
    this.form.reset({
      company: [],
      branch: [],
      companyLocation: [],
      responsible: [],
      assignmentOperator: [],
      year: [],
      states: []
    })
    //reset filtri session storage
    this.sessionService.saveState('OrderFilters', this.form.getRawValue());
    this.filterResult.next({data: [], clear: true})
    this.getFilterSelection.next(this.form.getRawValue());
  }

  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
     const key = keyProps.map(k => entry[k]).join('|');
     return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
   }

   get companyControl(){
    return this.form.get('company') as UntypedFormControl
   }

   get companyLocationControl(){
    return this.form.get('company') as UntypedFormControl
   }

   get responsibleControl(){
    return this.form.get('company') as UntypedFormControl
   }

   private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      if (typeof obj[propName] === 'object') {
        obj[propName] = this.clean(obj[propName])
      }
    }
    return obj
  }

  generateYears() {

    const moment = require('moment');
    let yToPush = new Date().getFullYear();
    // let i = 1;
    // yToPush = yToPush + ;
    for(let i = 0; i <= 10; i++) {
      this.years.push(yToPush.toString());
      yToPush--;
    }
    console.log("years -->", this.years)
  }
}
