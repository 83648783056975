<form [formGroup]="dynamicFormGroup" (ngSubmit)="submit()" class="form-container">
    <div *ngFor="let field of fields">
        <app-field-input [field]="field"></app-field-input>
        <app-dynamic-error [fieldName]="field.fieldName"></app-dynamic-error>
    </div>

    
    <div class="buttonBox">
        <button type="submit" mat-raised-button color="primary" [disabled]="!dynamicFormGroup.valid">
          <mat-icon>save</mat-icon> Save
        </button>
        <!--
        <button mat-raised-button color="warn" (click)="close()">
          <mat-icon>close</mat-icon> {{'FORM.close' | translate}}
        </button>
        -->
      </div>
</form>
