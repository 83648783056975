<div class="container-fluid my-container">
    <h3 *ngIf="isNew == false">{{company.name}}</h3>
    <div class="buttons-right my-2">
        <button style="background-color:transparent;" matTooltip="{{ 'TOOLTIP.SEDI.SALVA' | translate }}" type="button"
            class="my-bounce btn btn-primary mx-2" (click)="update()">
            <mat-icon class="material-icons" style="color:#293683;" *ngIf="hasSave">save</mat-icon>
        </button>
    </div>
    <hr>
    <mat-tab-group class="wbck" animationDuration="300ms" #tabGroup (selectedTabChange)="changeTab($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">description</mat-icon>
                <span class="ml-1">{{ 'LABEL.REGISTRY' | translate }}</span>
                <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
                    class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center" style="overflow:hidden">
                <br><br>
                <form [formGroup]="form" class="form-container" (ngSubmit)="update()">
                    <!--
                    codezone: this.company.codezone,
                    manager: this.company.manager,
                    society: this.company.society
-->
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.SOCIETY' | translate }}</mat-label>
                        <input matInput formControlName="society">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.STATUS' | translate }}</mat-label>
                        <input matInput formControlName="status">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.TYPE' | translate }}</mat-label>
                        <input matInput formControlName="type">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.NAME' | translate }}</mat-label>
                        <input matInput maxlength="128" inputmode="tel" [placeholder]="translate.instant('LABEL.INSERISCI_NOME')"
                            formControlName="name" #nameInput>
                        <!-- <mat-hint>{{ 'LABEL.MIN_LENGTH' | translate:{'n':'10'} }}: {{ nameInput.value.length }}/10
                        </mat-hint> -->
                        <mat-error *ngIf="(form.get('name'))?.errors?.['minlength']">
                            {{ 'LABEL.MIN_LENGTH_MEX' | translate:{'n':'10'} }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.CODE' | translate }}</mat-label>
                        <input matInput maxlength="128" inputmode="tel" placeholder="inserisci il codice"
                            formControlName="code" #nameInput>
                        <mat-error *ngIf="!(form.get('code'))?.errors?.['valid']">
                            {{ 'LABEL.CODE_ERROR' | translate }}
                        </mat-error>    
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.MANAGER' | translate}}</mat-label>
                        <mat-select 
                            matNativeControl formControlName="manager" 
                            (opened)="onOpenSearch(managerSearch)" 
                            (closed)="onCloseSearch('manager')">
                            <mat-form-field id="searchSelect" appearance="fill">
                                <mat-label>{{ 'LABEL.SEARCH' | translate}}</mat-label>
                                <input type="text" matInput #managerSearch (keyup)="onChangeFilterUsersManager($event)">
                            </mat-form-field>
                            <mat-option *ngFor="let user of usersManager" [value]="user.id"> {{ user.name }}</mat-option>
                            <mat-option *ngIf="usersManager?.length == 0" disabled> Nessun risultato </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.AREA_MANAGER' | translate }}</mat-label>
                        <mat-select matNativeControl formControlName="areaManager" 
                            (opened)="onOpenSearch(areaManagerSearch)" 
                            (closed)="onCloseSearch('areaManager')">
                            <mat-form-field id="searchSelect" appearance="fill">
                                <mat-label>{{ 'LABEL.SEARCH' | translate}}</mat-label>
                                <input type="text" matInput #areaManagerSearch (keyup)="onChangeFilterUsersAreaManager($event)">
                            </mat-form-field>
                            <mat-option *ngFor="let user of usersAreaManager" [value]="user.id"> {{ user.name }}</mat-option>
                            <mat-option *ngIf="usersAreaManager?.length == 0" disabled> Nessun risultato </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.MACROAREA' | translate}}</mat-label>
                        <input matInput formControlName="macroArea">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.CODCOUNTRY' | translate }}</mat-label>
                        <mat-select matNativeControl name="country" formControlName="country">
                            <mat-option value="IT">IT</mat-option>
                            <mat-option value="ES">ES</mat-option>
                            <mat-option value="FR">FR</mat-option>
                            <mat-option value="MC">MC</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'ADDRESS.STREETNAME' | translate }}</mat-label>
                        <input matInput formControlName="streetName">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'ADDRESS.NUMBER' | translate }}</mat-label>
                        <input matInput formControlName="streetNumber">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'ADDRESS.ZIP' | translate }}</mat-label>
                        <input matInput formControlName="zip">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'ADDRESS.CITY' | translate }}</mat-label>
                        <input matInput formControlName="city">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.COUNTRY' | translate }}</mat-label>
                        <input matInput formControlName="state">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.SOCIETY_codezone' | translate }}</mat-label>
                        <input matInput formControlName="codezone">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.LATITUDE' | translate }}</mat-label>
                        <input matInput type="number" formControlName="latitude">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.LONGITUDE' | translate }}</mat-label>
                        <input matInput type="number" formControlName="longitude">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.PHONE' | translate }}</mat-label>
                        <input type="number" matInput formControlName="phone">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.EMAIL' | translate }}</mat-label>
                        <input matInput formControlName="email" type="email">
                        <mat-error *ngIf="(form.get('email'))?.errors?.['email']">
                            {{ 'LABEL.VALID_EMAIL_MEX' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.SOCIETY_piva' | translate }}</mat-label>
                        <input matInput formControlName="piva">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.INVOICESYSTEMCODE' | translate }}</mat-label>
                        <input matInput formControlName="invoiceCode">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="form-subject">
                        <mat-label>{{ 'LABEL.MASTERCODE' | translate }}</mat-label>
                        <input matInput formControlName="masterCode">
                    </mat-form-field>
                    <div class="form-subject">
                        <mat-checkbox appearance="fill" matNativeControl formControlName="isOpen">{{ 'LABEL.OPEN' |
                            translate }}</mat-checkbox>
                    </div>
                </form>
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">maps_home_work</mat-icon>
                <span class="ml-1">{{ 'LABEL.LOCALIZATION' | translate }}</span>
                <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
                    class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <br>
                <br>
                <div class="col col-md-8 offset-md-2">
                    <div class="row">
                        <div class="col pr-0">
                            <input type="text" id="autocomplete" class="form-control" [(ngModel)]="address"
                                ngx-google-places-autocomplete (keydown.enter)="findAddress()"
                                [placeholder]="translate.getInsertLabel('LABEL.ADDRESS') | async"
                                [disabled]="isWorking">
                        </div>
                        <div class="col-auto ml-auto group-button-map">
                            <button mat-button color="primary" (click)="findAddress()"
                                [disabled]="isWorking || !address?.length" class="with-icon">
                                <mat-icon>search</mat-icon> <span>{{ 'LABEL.FIND' | translate }}</span>
                            </button>
                            <button mat-button color="primary" (click)="getCurrentLocation()" [disabled]="isWorking"
                                class="with-icon">
                                <mat-icon>maps</mat-icon> <span>{{ 'LABEL.LOCALIZE_ME' | translate }}</span>
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12 col-md-6 pr-md-0">
                            <div id="info" class="shadow-sm rounded border p-3 h-100">
                                <h5>{{ 'LABEL.ADDRESS' | translate }}</h5>
                                <p>
                                    {{ formattedAddress?.length ? formattedAddress : 'N/A' }}
                                </p>
                                <h5>{{ 'LABEL.SOCIETY_codezone' | translate }}</h5>
                                <p>{{company?.codezone }}</p>
                                <h5>{{ 'LABEL.COORDINATES' | translate }}</h5>
                                <p class="mb-0">
                                    {{ 'LABEL.LATITUDE' | translate }}: {{ locationCoords ? locationCoords?.lat() :
                                    'N/A' }}<br>
                                    {{ 'LABEL.LONGITUDE' | translate }}: {{ locationCoords ? locationCoords?.lng() :
                                    'N/A' }}
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="google_map_container shadow-sm rounded mt-3 mt-md-0">
                                <google-map [zoom]="mapZoom" [center]="mapCenter" [options]="mapOptions">
                                    <map-marker #marker="mapMarker" [position]="mapCenter" [options]="markerOptions"
                                        (mapClick)="openInfoWindow(marker)" (mapDragend)="onMapDragEnd($event)">
                                    </map-marker>
                                    <map-info-window>{{ markerInfoContent }}</map-info-window>
                                </google-map>
                            </div>
                        </div>
                    </div>
                </div>

                <!--
                    <iframe width="600" height="450" style="border:0" loading="lazy" allowfullscreen
                        referrerpolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBMKHOxlZc_oirDPSyqQj150G1m5Sy3DDQ&q=Milano">
                    </iframe>
                -->

            </div>
        </mat-tab>
        <mat-tab>

            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">gavel</mat-icon>
                <span class="ml-1">{{ 'LABEL.SOCIETY' | translate }}</span>
                <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
                    class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <br>
                <br>
                <div class="col col-md-8 offset-md-2">
                    <div *ngIf="!(company.CompaniesSocietiesRel == null) && company.CompaniesSocietiesRel.length > 0"
                        class="row">
                        <table class="container">
                            <tbody>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_code' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].code }}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.MASTERCODE' | translate }}</td>
                                    <td><span *ngIf="!(company.CompaniesSocietiesRel[0].mastercode == null)"><b>{{company.CompaniesSocietiesRel[0].mastercode
                                                }}</b></span>
                                        <span
                                            *ngIf="(company.CompaniesSocietiesRel[0].mastercode == null)">{{'LABEL.NOT_PRESENT'
                                            | translate }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_type' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].type }}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_status' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].status }}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_name' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].name }}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_nation' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].nation }}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_currency' | translate }}</td>
                                    <td><span *ngIf="!(company.CompaniesSocietiesRel[0].currencyCode == null)"><b>{{company.CompaniesSocietiesRel[0].currencyCode
                                                }}</b></span>
                                        <span
                                            *ngIf="(company.CompaniesSocietiesRel[0].currencyCode == null)">{{'LABEL.NOT_PRESENT'
                                            | translate}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_ricMateriali' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].ricMateriali }}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_ricOreManodopera' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].ricOreManodopera }}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_percOneriSicurezza' | translate }}</td>
                                    <td><b>%{{company.CompaniesSocietiesRel[0].percOneriSicurezza | number:
                                            '1.2-2'}}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_startValidityOneriSicurezza' | translate }}</td>
                                    <td><span
                                            *ngIf="!(company.CompaniesSocietiesRel[0].startValidityOneriSicurezza == null)"><b>{{company.CompaniesSocietiesRel[0].startValidityOneriSicurezza
                                                | date}}</b></span>
                                        <span
                                            *ngIf="(company.CompaniesSocietiesRel[0].startValidityOneriSicurezza == null)">{{'LABEL.NOT_PRESENT'
                                            | translate}}</span>
                                    </td>
                                </tr>
                                <!--
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_costoManodoperaRif' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].costoManodoperaRif }}</b></td>
                                </tr>
                                -->
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_codezone' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].codezone }}</b></td>
                                </tr>
                                <tr>
                                    <td>{{ 'LABEL.SOCIETY_piva' | translate }}</td>
                                    <td><b>{{company.CompaniesSocietiesRel[0].piva }}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr>

                </div>

            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">fingerprint</mat-icon>
                <span class="ml-1">{{ 'LABEL.LICENCES' | translate }}</span>
                <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
                    class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <button style="background-color:transparent; position: absolute; right: 15%; top: 5px;"
					matTooltip="{{ 'LABEL.EDIT_COMPANIES_LICENCES_RELATION' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
					(click)="toggleEditLicence()" *ngIf="id != 0">
					<mat-icon class="material-icons" style="color:#293683;">{{ isEditingLicences ? 'close' : 'edit' }}</mat-icon>
				</button>
                <br>
                <br>
                <div class="col col-md-8 offset-md-2">
                    <div *ngIf="isEditingLicences">
                        <form [formGroup]="licenceForm" class="form-container" (ngSubmit)="updateLicences()">
                            <table class="container">
                                <tbody>
                                    <tr>
                                        <th>{{ 'COMPANIES.CODE' | translate }}</th>
                                        <th>{{ 'COMPANIES.START_DATE' | translate }}</th>
                                        <th>{{ 'COMPANIES.END_DATE' | translate }}</th>
                                        <th>{{ 'COMPANIES.TYPE' | translate }}</th>
                                        <th>{{ 'COMPANIES.TOT_USERS' | translate }}</th>
                                        <th>{{ 'COMPANIES.PRICE' | translate }}</th>
                                        <th>{{ 'COMPANIES.OPEN' | translate }}</th>
                                        <th>{{ 'COMPANIES.IN_USE' | translate }}</th>
                                        <th>{{ 'LABEL.DELETE' | translate }}</th>
                                    </tr>
                                    <tr *ngFor="let u of editingLicences">
                                        <td>{{ u.code }}</td>
                                        <td>{{ u.startDate | date}}</td>
                                        <td>{{ u.endDate | date}}</td>
                                        <td>{{ u.type}}</td>
                                        <td>{{ u.numberOfUsers }}</td>
                                        <td>{{ u.cost | number: '1.2-2'}}</td>
                                        <td>{{ u.isOpen }}</td>
                                        <td>{{ u.isUsed }}</td>
                                        <td>
                                            <button style="background-color:transparent;"
                                                matTooltip="{{ 'LABEL.DELETE' | translate }}" type="button" class="btn btn-danger mx-2"
                                                (click)="removeLicence(u)">
                                                <mat-icon class="material-icons" style="color:#293683;">delete</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style="display: flex; flex-direction: row; width: 100%" class="container">
                                <mat-form-field appearance="fill" class="form-subject mt-5" style="width: 100%">
                                    <mat-label>{{ 'LABEL.ADD_LICENCE' | translate}}</mat-label>
                                    <mat-select 
                                        matNativeControl formControlName="licenceSelect"
                                        (opened)="onOpenSearch(licenceSearch)" 
                                        (closed)="onCloseSearch('licences')">
                                        <mat-form-field id="searchSelect" appearance="fill">
                                            <mat-label>{{ 'LABEL.SEARCH' | translate}}</mat-label>
                                            <input type="text" matInput #licenceSearch (keyup)="onChangeFilterLicences($event)">
                                        </mat-form-field>
                                        <mat-option *ngFor="let licence of licences" [value]="licence.id"> {{ licence.code }}</mat-option>
                                        <mat-option *ngIf="licences?.length == 0" disabled> Nessun risultato </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button style="background-color:transparent;"
                                    matTooltip="{{ 'LABEL.ADD' | translate }}" type="button" class="btn btn-primary mx-2"
                                    (click)="addLicence()">
                                    <mat-icon class="material-icons" style="color:#293683;">add</mat-icon>
                                </button>
                            </div>
                        </form>
                    </div>
                    <table class="container" *ngIf="!isEditingLicences">
                        <tbody>
                            <tr>
                                <th>{{ 'COMPANIES.CODE' | translate }}</th>
                                <th>{{ 'COMPANIES.START_DATE' | translate }}</th>
                                <th>{{ 'COMPANIES.END_DATE' | translate }}</th>
                                <th>{{ 'COMPANIES.TYPE' | translate }}</th>
                                <th>{{ 'COMPANIES.TOT_USERS' | translate }}</th>
                                <th>{{ 'COMPANIES.PRICE' | translate }}</th>
                                <th>{{ 'COMPANIES.OPEN' | translate }}</th>
                                <th>{{ 'COMPANIES.IN_USE' | translate }}</th>
                            </tr>
                            <tr *ngFor="let u of company['CompaniesLicencesRel']">
                                <td>{{ u.code }}</td>
                                <td>{{ u.startDate | date}}</td>
                                <td>{{ u.endDate | date}}</td>
                                <td>{{ u.type}}</td>
                                <td>{{ u.numberOfUsers }}</td>
                                <td>{{ u.cost | number: '1.2-2'}}</td>
                                <td>{{ u.isOpen }}</td>
                                <td>{{ u.isUsed }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">person</mat-icon>
                <span class="ml-1">{{ 'LABEL.RESOURCES' | translate }}</span>
                <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
                    class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <br>
                <br>
                <div>
                    <table class="container">
                        <tbody>
                            <tr>
                                <th>Id</th>
                                <th>{{ 'LABEL.USERNAME' | translate}}</th>
                                <th>{{ 'LABEL.EMAIL' | translate}}</th>
                                <th>
                                    {{ 'LABEL.PROFILE' | translate}}
                                </th>
                                <th>
                                    {{ 'LABEL.LEVEL' | translate}}
                                </th>
                            </tr>
                            <tr *ngFor="let u of companiesFilterDeleted(company['CompaniesUsersRel'])">
                                <td>{{ u.id }}</td>
                                <td>{{ u.username }}</td>
                                <td>{{ u.email }}</td>
                                <td>
                            <tr *ngFor="let p of profilesFilterDeleted(u['UsersProfilesRel'])">
                                <td style="border:none !important;">{{ p.name ? ('WORKTYPE_PROFILES.' + p.name | translate) : '' }}</td>
                            </tr>
                            </td>
                            <td>
                                <tr *ngFor="let p of profilesFilterDeleted(u['UsersProfilesRel'])">
                                    <td style="border:none !important;">{{ p.UsersProfiles.level ? ('WORKTYPE_LEVELS.' + p.UsersProfiles.level | translate) : '' }}</td>
                                </tr>
                            </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </mat-tab>

    </mat-tab-group>

    <div class="buttons-right  my-2">

        <button style="background-color:transparent;" matTooltip="{{ 'TOOLTIP.SEDI.SALVA' | translate }}"type="button"
            class="my-bounce btn btn-primary mx-2" (click)="update()" *ngIf="hasSave">
            <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
        </button>

    </div>

</div>