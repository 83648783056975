<div class="container-fluid my-container">
  <div class="full-w" >

    <form [formGroup]="formTargets">
      <br>
      <mat-form-field appearance="fill" style="margin-left: 40%;">
        <mat-label>{{ 'LABEL.TARGET_DESCR' | translate}}</mat-label>
        <input type="text" matNativeControl formControlName="description" #descriptionInput>
      </mat-form-field>
      <br>
      <mat-form-field style="" appearance="fill" style="margin-left: 40%;">
        <mat-label>{{ 'LABEL.ENTITY_NAME' | translate}}</mat-label>
        <mat-select matNativeControl formControlName="entityName" #typeInput>
          <mat-option *ngFor="let entity of entities" [value]="entity.name">
            <!-- | async -->
            {{ entity.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>


    </form>
    <div class="buttons-right my-2">
      <mat-icon class="check-ok" *ngIf="formTargets.valid">check</mat-icon>
      <button style="background-color:transparent;" [disabled]="!formTargets.valid" matTooltip="{{ 'LABEL.SAVE' | translate }}" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>
    </div>
  </div>
</div>
