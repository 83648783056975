import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './data-table/data-table.component';
import { DocumentsGridComponent } from './documents-grid/documents-grid.component';
import { DynamicFieldsComponent } from './dynamic-fields/dynamic-fields.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AddressSearchComponent } from './address-search/address-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { AddressDetailFormComponent } from './address-detail-form/address-detail-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { PlannerSelectSearchComponent } from './planner-select-search/planner-select-search.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
// import { SafeUrlPipe } from '../pipes/safeUrl';
// import { SafeHtmlPipe } from '../pipes/safeHtml';
import { OrdersFilterComponent } from './orders-filter/orders-filter.component';
import { PracticesFilterComponent } from './practices-filter/practices-filter.component';
import { AutoBaseTableComponent } from './auto-base-table/auto-base-table.component';
import { MaterialSearchComponent } from './material-search/material-search.component';
import { DataTableWithPaginationComponent } from './data-table-with-pagination/data-table-with-pagination.component';
import { MatTabsModule } from '@angular/material/tabs';
import { GoogleMapsModule } from '@angular/google-maps';

import { CrmFormComponent } from './crm-form/crm-form.component';
import { CrmFormDataComponent } from './crm-form/crm-form-data/crm-form-data.component';
//import { CrmFormDialogComponent } from './crm-form/crm-form-dialog/crm-form-dialog.component';
import { CrmFormDocumentsComponent } from './crm-form/crm-form-documents/crm-form-documents.component';
import { CrmFormLocalizationComponent } from './crm-form/crm-form-localization/crm-form-localization.component';
import { CrmFormMarketingComponent } from './crm-form/crm-form-marketing/crm-form-marketing.component';
import { CrmFormPracticesComponent } from './crm-form/crm-form-practices/crm-form-practices.component';
import { CrmFormRelationsComponent } from './crm-form/crm-form-relations/crm-form-relations.component';
import { CrmFormTypeRadioComponent } from './crm-form/crm-form-type-radio/crm-form-type-radio.component';

import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ComponentCardBoxComponent } from './component-card-box/component-card-box.component';
import { ComponentAttributeCardBoxComponent } from './component-attribute-card-box/component-attribute-card-box.component';
import { ComponentFieldsComponent } from './component-fields/component-fields.component';


const MATERIAL_MODULES = [
  MatTableModule,
  MatPaginatorModule,
  MatButtonModule,
  MatSortModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatTabsModule,
  MatRadioModule,
  MatCardModule,
  MatSlideToggleModule,
]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    DataTableComponent,
    DataTableWithPaginationComponent,
    DocumentsGridComponent,
    DynamicFieldsComponent,
    AddressSearchComponent,
    AddressDetailFormComponent,
    MultiSelectDropdownComponent,
    SelectSearchComponent,
    PlannerSelectSearchComponent,
    OrdersFilterComponent,
    PracticesFilterComponent,
    AutoBaseTableComponent,
    MaterialSearchComponent,
    CrmFormComponent,
    CrmFormDataComponent,
    //CrmFormDialogComponent,
    CrmFormDocumentsComponent,
    CrmFormLocalizationComponent,
    CrmFormMarketingComponent,
    CrmFormPracticesComponent,
    CrmFormRelationsComponent,
    CrmFormTypeRadioComponent,
    ComponentCardBoxComponent,
    ComponentAttributeCardBoxComponent,
    ComponentFieldsComponent,
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    RouterModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    NgxMatSelectSearchModule,
    TranslateModule.forChild(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        },
      }
    ),
    MATERIAL_MODULES
  ],
  exports: [
    DocumentsGridComponent,
    AddressSearchComponent,
    DynamicFieldsComponent,
    AddressDetailFormComponent,
    DataTableComponent,
    DataTableWithPaginationComponent,
    MultiSelectDropdownComponent,
    SelectSearchComponent,
    PlannerSelectSearchComponent,
    OrdersFilterComponent,
    PracticesFilterComponent,
    AutoBaseTableComponent,
    MaterialSearchComponent,
    CrmFormComponent,
    CrmFormDataComponent,
    //CrmFormDialogComponent,
    CrmFormDocumentsComponent,
    CrmFormLocalizationComponent,
    CrmFormMarketingComponent,
    CrmFormPracticesComponent,
    //CrmFormRelationsComponent,
    CrmFormTypeRadioComponent,
    ComponentCardBoxComponent,
    ComponentAttributeCardBoxComponent,
    ComponentFieldsComponent,
  ]
})
export class SharedModule { }
