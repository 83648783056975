<div>
  <br>
  <br>
  <input type="text" class="form-control"
  [placeholder]="translate.instant('FORM_FIELD.SEARCH')"
  [(ngModel)]="search" [value]="search" (ngModelChange)="onFilter()"
  >

  <br>

  <div class="d-flex flex-row">
    <div class="col-4">
      <button class="btn btn-primary btn-sm me-3 text-dark reviewAdd" (click)="openEdit('complaints')">
        <div>{{'LABEL.AggiungiNuovo' | translate}}</div>
      </button>
    </div>
    <div class="col-4">
    </div>
  </div>

  <br>

  <app-data-table-with-pagination
  [data]="complaints"
  [columns]="columns"
  [displayedColumns]="displayedColumns"
  [actions]="actions"
  [totalRows]="totalRows"
  [pageInit]="pageInit"
  [currentPage]="currentPage"
  (changePagination)="pageChanged($event)"
  (changeOrder)="updateDataBySortParams($event)"
  sortActive=""
  ></app-data-table-with-pagination>

</div>
