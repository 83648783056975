import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubjectService } from 'src/app/services/subject.service';
import { LibService } from 'src/app/services/libService';

@Component({
  selector: 'app-crm-form-dialog',
  templateUrl: './crm-form-dialog.component.html',
  styleUrls: ['./crm-form-dialog.component.scss']
})
export class CrmFormDialogComponent implements OnInit {
  name: string;
  question: string;
  companies: Array<any> = []
  subjectTypes: Array<any> = []

  geoForm: FormGroup;

  constructor(public dialog: MatDialog,
    public dialogModule: MatDialogModule,
    public dialogRef: MatDialogRef<CrmFormDialogComponent>,
    public translate: TranslateService,
    public fb: FormBuilder,
    public subjectService: SubjectService,
    public libService: LibService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.geoForm = this.fb.group({
      company: [null, Validators.required],
      subjectType: [null, Validators.required],
      subjectTypeArray: [[], []]
    });
  }

  ngOnInit(): void {

    //console.log(this.data);
    // this.name = this.data.features[0].attributes.SEDE_EDAC;
    this.name = this.data.SEDE_EDAC;
    if (this.data.length > 0) {

      let allCompanies = this.data || []
      this.companies = allCompanies
      if (this.companies.length == 1) {
        this.geoForm.patchValue({
          company: this.data.find((sede: any) => ![null, undefined].includes(sede.id))
        })
        this.getSubjectType()
      }

      this.question = this.translate.instant('DIALOG.MESSAGE1') + '</b>?';

    } else {
    }


  }

  getSubjectType(event: any = null) {
    // Ricerca Subject Type Abilitati
    this.libService.lockPage('');
    let currentCompany: any = this.geoForm?.get('company')?.value
    this.subjectTypes = [] // Reset
    this.subjectService.getTypesByCompanyId(currentCompany.id).subscribe((response: any) => {
      this.libService.unlockPage();
      this.subjectTypes = response
      this.geoForm?.get('subjectTypeArray')?.setValue(response)
    })
  }

}
