import { Component, EventEmitter, Input, OnChanges, AfterViewInit, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class DatatableColumn<T> {
  name: string;
  title: Observable<string>;
  type?: 'text' | 'link' | 'icon' | 'templateRef' = 'text';
  flex?: string;
  sticky?: boolean;
  color?: (element: T) => 'primary' | 'warn' | 'error';
  cssClass?: (element: T) => string[];
  sortDisabled?: boolean;
  routerLink?: (element: T) => string[];
  value(element: T): any {
    return null;
  }
  templateRef?: (element: T) => Observable<TemplateRef<any>>;
  tooltip?: (element: T) => Observable<String>;

  constructor(column: Partial<DatatableColumn<T>>) {
    Object.assign(this, column);
  }
}

export class DatatableAction<T> {
  label?: Observable<string>;
  color?: string;
  icon(element: T): string {
    return ''
  }
  iconButton?: boolean = true;
  spinner?: boolean;
  disabled?(element: T): boolean {
    return false
  }

  constructor(action: Partial<DatatableAction<T>>) {
    Object.assign(this, action);
  }

  onClick(element: T): void { }
}

@Component({
  selector: 'app-settings-data-table',
  templateUrl: './settings-data-table.component.html',
  styleUrls: ['./settings-data-table.component.scss']
})
export class SettingsDataTableComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() data: any[];
  @Input() columns: DatatableColumn<any>[];
  @Input() displayedColumns: string[] = [];
  @Input() actions: DatatableAction<any>[] = [];
  @Input() sortActive = 'id';
  @Input() sortDirection: SortDirection = 'asc';
  @Input() hasPagination = true;
  @Input() hasHeader = true;
  @Input() pageSize = 10;
  @Input() pageSizeOptions = [5, 10, 20];
  @Input() selected: any;
  @Output() rowSelected = new EventEmitter<any>();
  @Output() selectionChanged = new EventEmitter();


  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<any>()
  hoverRow: any;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {

    //this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'price': return item.realPrice;
        case 'workType': return item.workType.workType;
        case 'address': return item.building.streetName;
        case 'statusRequest': return this.translate.instant('LABEL' + item.status.uiLabel);
        case 'subjectId': return item.nameSubject;
        case 'buildingId': return item.nameBuilding;
        default: return item[property];
      }
    };
    // this.dataSource.sort = sort;

    if (this.actions.length > 0 && !this.displayedColumns.includes('actions')) {
      this.displayedColumns.push('actions');
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue !== changes['data'].previousValue) {
      this.dataSource.data = changes['data'].currentValue
    }
    if (this.actions.length > 0 && !this.displayedColumns.includes('actions')) {
      this.displayedColumns.push('actions');
    }
  }

  selectRow(row: any) {
    this.rowSelected.next(row);
  }

  onMatRowHover(event: any, row: any) {
    if (event.leave) {
      this.hoverRow = null;
    } else {
      this.hoverRow = row;
    }
  }

  onSelectionChange(event: any, element: any) {
    this.selectionChanged.next({ event: event, element: element })
  }

}
