import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  baseApi: string = '';
  model: string = '';
  tokenId: string = '';

  constructor(public libService: LibService, public authService: AuthService) {
    this.tokenId = this.authService.idToken();
  }

  index(): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(
      this.tokenId,
      this.baseApi,
      `${this.model}/`
    );
  }

  indexByAttributes(attributes: any): Observable<any> {
    return this.libService.postDataWithAccessToken<any>(
      this.tokenId,
      attributes,
      this.baseApi,
      `${this.model}/indexByAttributes`
    );
  }

  show(id: any): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(
      this.tokenId,
      this.baseApi,
      `${this.model}/${id.toString()}`
    );
  }

  showByAttributes(attributes: any): Observable<any> {
    return this.libService.postDataWithAccessToken<any>(
      this.tokenId,
      attributes,
      this.baseApi,
      `${this.model}/showByAttributes`
    );
  }

  create(attributes: any): Observable<any> {
    return this.libService.postDataWithAccessToken<any>(
      this.tokenId,
      attributes,
      this.baseApi,
      `${this.model}/`
    );
  }

  update(id: any, attributes: any): Observable<any> {
    return this.libService.putDataWithAccessToken<any>(
      this.tokenId,
      attributes,
      this.baseApi,
      `${this.model}/${id.toString()}`
    );
  }

  destroy(id: any, attributes: any = {}): Observable<any> {
    return this.libService.deleteDataWithAccessToken<any>(
      this.tokenId,
      attributes,
      this.baseApi,
      `${this.model}/${id.toString()}`
    );
  }
}
