import { BranchService } from 'src/app/services/branch.service';
import { CategoryService } from 'src/app/services/category.service';
import { MatSelect } from '@angular/material/select';
import { UnitMeasureService } from './../../services/unit-measure.service';
import { WorkTypeService } from './../../services/worktype.service';
import { MacroPhaseService } from './../../services/macrophase.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { PhaseService } from 'src/app/services/phase.service';
import { codeCharacter } from 'src/app/validators/code.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-phase-detail',
  templateUrl: './phase-detail.component.html',
  styleUrls: ['./phase-detail.component.scss']
})
export class PhaseDetailComponent implements OnInit {
  isNew: boolean;
  id: any;
  target: any;
  target$: Observable<any>;
  formTargets: UntypedFormGroup;
  countryOptions = ['IT', 'ES', 'FR', 'MC']
  langOptions = ['IT', 'ES', 'FR']
  causals: any;
  unitMeasures: any;
  allUnitMeasures: any;
  allMacroPhases: any;
  macroPhases: any;
  @ViewChild('macroSelection') macroSelection: MatSelect

  /** Tree */
  cachedCategories: any;
  cachedBranches: any;
  category: any;
  branch: any

  constructor(private fb: UntypedFormBuilder,
    private phaseService: PhaseService,
    private causalService: WorkTypeService,
    private branchService: BranchService,
    private categoryService: CategoryService,
    private macroPhaseService: MacroPhaseService,
    private unitMeasureService: UnitMeasureService,
    private route: ActivatedRoute,
    private libService: LibService,
    public translate: TranslatorService,
    private toastService: ToastrService,
    public navService: NavService,
    private router: Router) {
    this.causalService.getAllWorkTypes().subscribe(workTypes => {
      this.causals = workTypes
    })
    this.unitMeasureService.getAllUnitMeasures().subscribe(unitMeasures => {
      this.allUnitMeasures = unitMeasures
      this.unitMeasures = unitMeasures
    })
    this.macroPhaseService.getAllMacroPhases().subscribe(macroPhases => {
      this.allMacroPhases = macroPhases.sort((a:any, b :any) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
      this.macroPhases = macroPhases.sort((a:any, b: any) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
    })
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if(this.id != 0) this.target$ = this.phaseService.getPhase(this.id);
    });
    this.formTargets = this.fb.group({
      macrophase: [null],
      id: [null],
      code: [null, [Validators.required, codeCharacter()]],
      name: [null, Validators.required],
      codCountry: [null, Validators.required],
      codLanguage: [null, Validators.required],
      isActive: [null],
      qProdOraria: [null],
      cMater: [null],
      isPercAmmaloramento: [null],
      isOpereSpeciali: [null],
      isAdditionalMaterialCost: [null],
      isPreselected: [null],
      desL: [null],
      position: [null],
      UnitMeasureIdId: [null],
      isOtherCost: [null],
    })

    this.categoryService.getAllCategories().subscribe((categories: any) => {
      this.cachedCategories = categories
    })
    this.branchService.getAllBranches().subscribe((branches: any) => {
      this.cachedBranches = branches
    })
  }

  onOpenSearch(searchElement: HTMLInputElement){
    searchElement.value = '';
    searchElement.focus();
  }

  onCloseSearch(topic: String){
    if (topic == 'Macrophase') this.macroPhases = this.allMacroPhases;
    if (topic == 'UnitMeasure') this.unitMeasures = this.allUnitMeasures;
  }

  update() {
    const formObject = this.formTargets.getRawValue()
    console.log("FORM OBJECT -> ", formObject);
    const macrophase = this.allMacroPhases.find((m: any) => m.id == formObject.macrophase)
    const toSend = {
      phase: {...formObject},
      macroPhase: {...macrophase}
    }
    delete toSend.phase.macrophase
    delete toSend.macroPhase.MacroPhasesPhasesRel
    if(toSend.phase.id == null){
      // Creation
      delete toSend.phase.id
      let createdID = 0
      this.phaseService.createPhase(toSend).subscribe({
        next(success){
          console.log(success)
          createdID=success?.Phase?.id
        },
        error: (err) => {
          console.error(err)
          this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
        complete: () => {
          console.log('Done')
          this.toastService.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigateByUrl(`genconfig`).then(console.log).catch(console.error)
        }
      })
    }else{
      // Update
      this.phaseService.updatePhase(toSend.phase).subscribe({
        next(success){
          console.log(success)
        },
        error: (err) => {
          console.error(err)
          this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
        complete: () => {
          console.log('Done')
          this.toastService.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        }
      })
    }
  }

  updateTree(){
    const selection = this.macroSelection.value
    const macrophase = this.allMacroPhases.find((m: any) => m.id == selection)

    this.category = undefined
    this.branch = undefined

    if(macrophase == undefined) return

    const category = this.cachedCategories.find((c: any) => c.CategoriesMacroPhasesRel.find((cm: any) => cm.id == macrophase.id) != undefined)
    this.category = category

    if(category == undefined) return

    const branch = this.cachedBranches.find((b: any) => b.BranchesCategoriesRel.find((bc: any) => bc.id == category.id))
    this.branch = branch
  }

  onChangeFilterUnitMeasure(event: KeyboardEvent) {
    const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
    this.unitMeasures = this.allUnitMeasures.filter((option:any) => option.code.toLowerCase().includes(searchStr));
  }

  onChangeFilterMacroPhase(event: KeyboardEvent) {
    const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
    this.macroPhases = this.allMacroPhases.filter((mp: any) => mp.name.toLowerCase().includes(searchStr))
  }

  ngOnInit(): void {
    this.initNavbar();
    console.log('Phase ID: ', this.id)
    if (this.id != 0) {
      this.libService.lockPage('');
      this.target$.subscribe(val => {
        this.formTargets.controls['macrophase'].disable()
        this.formTargets.controls['code'].disable()
        this.formTargets.controls['name'].disable()
        this.formTargets.controls['codCountry'].disable()
        this.formTargets.controls['codLanguage'].disable()
        this.target = val;
        console.log("val -->", val);
        this.formTargets.patchValue(this.target);
        this.formTargets.controls['macrophase'].setValue(val.PhasesMacroPhasesRel[0]?.id)
        this.libService.unlockPage();
        this.updateTree()
      })
    } else {
      this.formTargets.patchValue({
        isActive: 0
      })
    }
  }

  initNavbar(){
    setTimeout(() => {
      let actions: any;
      actions = [
        { name: 'back', url: 'genconfig' }
      ];

      let title = this.formTargets.get('id')?.value !== null?
        this.translate.instant('LABEL.EDIT', { field: 'Fase' }):
        this.translate.instant('LABEL.NEW_F', { field: 'Fase' })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }
}
