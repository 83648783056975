<div class="container-fluid">
    <div class="search-wrapper mat-elevation-z2">
        <div class="search" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
            <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
                placeholder="Cerca" />
        </div>
    </div>
    <div style="overflow-x: auto;">
        <table mat-table [dataSource]="dataSource" matSort style="width:100%">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">Id</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.code'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.code}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="mustSigned">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.mustSigned'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.mustSigned}}</b></span>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
    </mat-paginator>
</div>

