import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import {saveAs} from "file-saver";
import { DocumentType } from 'src/app/models/document';
import { DocumentService } from 'src/app/services/document.service';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';

@Component({
  selector: 'app-generic-file-uploader',
  templateUrl: './generic-file-uploader.component.html',
  styleUrls: ['./generic-file-uploader.component.scss']
})
export class GenericFileUploaderComponent implements OnInit {

  @Input()documentTypes: DocumentType[] | null
  @Input() order: any | null 
  @Input() attributes: any

  @Output()selectFileClickedEvent = new EventEmitter();

  items: any = []

  columns: DatatableColumn<DocumentType>[] = [
    {
      name: "documentType",
      title: this.translate.get('LABEL.DocumentType'),
      cssClass: documentType => ['column-padding'],
      value: documentType => this.translate.instant('LABEL.' + documentType.uiLabel)
    }
  ]

  actions: DatatableAction<DocumentType>[] = [
    {
      label: of(''),
      icon: subject => {
        return 'upload'
      },
      // 'upload',
      onClick: documentType => this.selectFileClicked(documentType),
      color: 'primary'
    },
    {
      label: of(''),
      icon: subject => {
        return 'download'
      },
      onClick: documentType => this.downloadTemplate(documentType),
      color: 'primary',
      disabled: documentType => (documentType.templateDocument || (!(documentType.urlDocComposition == null) && (documentType.urlDocComposition.length > 0))) ? false: true
    }
  ];

  displayedColumns: string[] = ['documentType'];


  constructor(
    public translate: TranslateService,
    private documentService: DocumentService,
    private toastr: ToastrService,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    console.log('ATTRIBUTES')
    console.log(this.attributes)
    console.log(changes['documentTypes'].currentValue)
    this.items = []
    Object.entries(changes['documentTypes'].currentValue).forEach(entry => {
      const [key, value]: any = entry;
      this.items.push( {code
        : 
        "INSP",
        documentTemplate
        : 
        undefined,
        id
        : 
        1,
        isGeneric
        : 
        false,
        requireEsit
        : 
        true,
        uiLabel
        : 
        "SITEINSPECTION"})
    });
  }


  ngOnInit(): void {
   
  }

  selectFileClicked(documentType: DocumentType){
    this.selectFileClickedEvent.next(documentType)
  }

  downloadTemplate(documentType: DocumentType) {
    console.log('[DocumentTypeTableComponent.downloadTemplate] - documentType %o', documentType.urlDocComposition);
    if (!(documentType.urlDocComposition == null) && (documentType.urlDocComposition.length > 0)) {
      console.log('[DocumentTypeTableComponent.downloadTemplate] - callDocumentComposition %o', documentType.urlDocComposition);
      let payLoad={
        numeroPratica:this.order.code,
        versionePratica:this.order.version.toString()
      }
      this.documentService.callDocComp(documentType.urlDocComposition, payLoad).subscribe(res =>{
        console.log('[DocumentTypeTableComponent.downloadTemplate.] call callDocComp payload %o response %o',payLoad,res['document']);
        let str = res['document'];
        var byteCharacters = atob(str);
        var bytes = new Array(byteCharacters.length);
        for(var i = 0; i<byteCharacters.length; i++ )
        {
          bytes[i]= byteCharacters.charCodeAt(i);
        }
        var byteCharactersN = new Uint8Array(bytes);

        const blob = new Blob([byteCharactersN], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = documentType.code + ".docx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

      });
    }
    else {
      if (documentType.templateDocument) {
        this.documentService.getSignedUrl(documentType.templateDocument.awsPath!).subscribe({
          next: (signedUrl: string) => {
            window.open(signedUrl)
          },
          error: (err: any) => {
            console.log(err);
            this.toastr.error(err.message)
          }
        })
      }
    }
  }



}
