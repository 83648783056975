import { Component, Input, OnInit, Output, EventEmitter  } from '@angular/core';
import { forkJoin, Subject, pipe } from 'rxjs';
import { TeamService } from '../../services/team.service';
import { CompanyService } from '../../services/companyService';
import { LibService } from '../../services/libService';
import { NavService } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss']
})
export class TeamDetailComponent implements OnInit {
  public route_id: any = this.route.snapshot.params['id'] !== undefined ? this.route.snapshot.params['id'] : '';
  private innerWidth: any;
  private innerHeight: any;
  private title: string = 'Squadra - Dettaglio';
  private subTitle: string = 'EDAC - Squadra - Dettaglio';

  previousUrl: string = '';
  currentUrl: string = '';

  public teamToEdit: any = {
    resources: [],
  };
  public companies: any[] = [];
  public users: any[] = [];
  public usersSelected: any[] = [];
  public leaderSeleced!: any;
  public companySelected!: any;
  public companySelectedData!: any;

  @Input() external: boolean = false;
  @Input() team_selected: string = '';
  @Output() public closeTeam = new EventEmitter<any>();
  @Output() public saveTeamAction = new EventEmitter<any>();

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private teamService: TeamService,
    private navService: NavService,
    private libService: LibService,
    private router: Router,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {

    console.log(this.route_id, 'route_id');
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');

    this.libService.lockPage('');

    if ((this.route_id !== "new" && this.route_id) || (this.team_selected !== "new" && this.team_selected)) {
      forkJoin({
        teamToEdit: this.teamService.getTeam(this.team_selected ? this.team_selected : this.route_id),
        companies: this.companyService.getAllCompanies(),
      }).subscribe(res => {
        this.libService.unlockPage();

        this.getCompanyUsers(res.teamToEdit.office);
        this.teamToEdit = {
          ...res.teamToEdit,
          resources: JSON.parse(res.teamToEdit.resources),
        };
        this.companies = res.companies;
        this.companySelected = res.teamToEdit.office;
        this.leaderSeleced = res.teamToEdit.idTeamLeaderUser;
      });
    } else {
      forkJoin({
        companies: this.companyService.getAllCompanies(),
      }).subscribe(res => {
        this.libService.unlockPage();
        this.companies = res.companies;
      });
    }
  }

  public getCompanyUsers(id?: any) {
    const idC = id ? id : this.companySelected;
    this.teamToEdit.office = idC;
    this.companyService.getCompany(idC).subscribe((response: any) => {
      this.companySelectedData = response;
      this.users = response.CompaniesUsersRel;

      console.log(this.users, 'users');
      if((this.route_id !== "new" && this.route_id) || (this.team_selected !== "new" && this.team_selected)) {
        this.usersSelected = this.teamToEdit.resources.length > 0 ? this.teamToEdit.resources : []
      }

    }), (err: any) => {
      console.log(err);
    };
  }

  public setLeader(): void {
    const user = this.users?.filter((us: any) => us.id == this.leaderSeleced)[0];
    console.log(typeof this.leaderSeleced, this.leaderSeleced, user, 'leaderSeleced');
    if(user)
      this.teamToEdit.name = user?.name;
      this.teamToEdit.emailTeamLeaderUser = user?.email;
      this.teamToEdit.nameTeamLeaderUser = user?.name;
      this.teamToEdit.idTeamLeaderUser = user?.id;
  }

  public setUsersLead(): void {
    this.teamToEdit.resources = this.usersSelected;
    this.leaderSeleced = '';
    this.teamToEdit.name = '';
    this.teamToEdit.emailTeamLeaderUser = '';
    this.teamToEdit.nameTeamLeaderUser = '';
    this.teamToEdit.idTeamLeaderUser = '';
  }

  public usersLeads(): Array<any> {
    return this.users.filter(u => this.usersSelected.includes(u.id));
  }

  public cleanResources(): void {
    this.getCompanyUsers();
    this.teamToEdit.resources = [];
    this.usersSelected = [];
    this.teamToEdit.name = '';
    this.teamToEdit.emailTeamLeaderUser = '';
    this.teamToEdit.nameTeamLeaderUser = '';
    this.teamToEdit.idTeamLeaderUser = '';
  }

  public closeExternalEditing(): void {
    this.closeTeam.emit();
  }

  public saveTeam(): void {
    const data = {
      ...this.teamToEdit,
      resources: JSON.stringify(this.usersSelected),
    };

    this.libService.lockPage('');
    if ((this.route_id !== "new" && this.route_id) || (this.team_selected !== "new" && this.team_selected)) {
      this.teamService.actionTeam(data, this.team_selected ? this.team_selected : this.route_id).subscribe((response: any) => {
        this.toastr.success(this.translate.instant('TOASTR.MESSAGE32'), this.translate.instant('TOASTR.INFO'));
        this.libService.unlockPage();
        if (this.team_selected) {
          this.closeExternalEditing();
          this.saveTeamAction.emit();
          this.team_selected = '';
        } else {
          this.router.navigate(['/teams']);
        }
      }), (err: any) => {
        console.log(err);
      };
    } else {
      this.teamService.actionTeam(data).subscribe((response: any) => {
        this.toastr.success(this.translate.instant('TOASTR.MESSAGE32'), this.translate.instant('TOASTR.INFO'));
        this.libService.unlockPage();
        if (this.team_selected) {
          this.closeExternalEditing();
          this.saveTeamAction.emit();
          this.team_selected = '';
        } else {
          this.router.navigate(['/teams']);
        }
      }), (err: any) => {
        console.log(err);
      };
    }
  }

}
