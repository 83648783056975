import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericModal } from '../modals/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ComponentService } from '../../services/component.service';
import { PracticeService } from '../../services/practice.service';
import { LibService } from '../../services/libService';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-practice-state-migration',
  templateUrl: './practice-state-migration.component.html',
  styleUrls: ['./practice-state-migration.component.scss']
})
export class PracticeStateMigrationComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isNew: any;
  @Input() components: any;
  @Input() practice: any;
  @Input() costoVenduto: any;

  attributes: any;

  disabled: any = true;
  actions: any = [];

  componentName: any = 'practiceStateMigration'

  objectStructure: any = {}

  listOfStatesAvailableForMigration: any = []


  constructor(
    private dialog: MatDialog,
    private componentService: ComponentService,
    private router: Router,
    private libService: LibService,
    private practiceService: PracticeService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) { }

  getClass() {
    if (this.objectStructure?.settings?.type) return 'clsButton' + this.objectStructure?.settings?.type
    return 'clsButtonDefault'
  }

  ngOnInit(): void {
    console.log('ngOnInit practiceStateMigration')
    this.getAvailableStatuses()
    this.setActions()
  }

  getAvailableStatuses() {
    this.practiceService.getAvailableStatuses(this.practice.code).subscribe((res: any) => {
      console.log(res)
      this.listOfStatesAvailableForMigration = res.filter((state:any)=> state.disabled == false)
    })
  }

  openModal() {

    let code = 'migrate_state'

    let dataStructure: any = {
      titleLabel: 'CHANGESTATEBUTTON.' + code + '_title',
      textLabel: 'CHANGESTATEBUTTON.' + code + '_text',
      listOfStatesAvailableForMigration: this.listOfStatesAvailableForMigration,
      notes: {required: true}
    }

    const dialogRef = this.dialog.open(GenericModal, {
      width: '400px',
      data: dataStructure
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (![null, undefined].includes(result)) {
        let attributes = result;
        this.libService.lockPage('');
        
        this.practiceService.stateMigration(this.practice.code, attributes.migrationState, attributes.notes).subscribe(
          ret => {
            this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
            this.libService.unlockPage();
            this.router.navigate(['practices/' + this.practice.code]).then(_res => {
              window.location.reload()
            })
          },
          err => {
            //this.toastr.error(this.translate.instant('LABEL.Error'));
            this.libService.unlockPage();
          }
        );
        
      }

    });
  }

  getAttributes(componentCode: any) {
    let component = this.components?.settings?.find((component: any) => component.componentCode == componentCode)
    return component?.attributes
  }

  setActions() {
    this.setAttributes()
    if (this.attributes) {
      this.actions = []
      let structure: any = this.componentService.getStructure(this.attributes)
      structure.forEach((s: any) => {
        if (s.type == 'action' && !this.actions.includes(s.type)) this.actions.push(s.code)
      })
    }
  }

  setAttributes() {
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
    if (this.attributes) this.objectStructure = this.componentService.getObjectStructure(this.attributes)
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) {
      this.setAttributes()
      this.setActions()
    }
    return status
  }

}
