<div class="container-fluid my-container">
	<div class="full-w">
		<form [formGroup]="formTargets">
			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="branch"
					(opened)="onOpenSearch(searchSelect)"
					(closed)="onCloseSearch()">
					<mat-form-field id="searchSelect" appearance="fill">
						<mat-label>{{ 'LABEL.SEARCH' | translate}}</mat-label>
						<input matNativeControl type="text" (keyup)="onChangeFilterBranches($event)" #searchSelect>
					</mat-form-field>
					<mat-option *ngFor="let branch of branches" [value]="branch.id"> {{ branch.name }} ({{branch.codCountry}})</mat-option>
                    <mat-option *ngIf="branches?.length == 0" disabled> Nessun risultato </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.CODE' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="code">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.NAME' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="name">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH_STATE' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="codCountry">
					<mat-option *ngFor="let country of countryOptions" [value]="country"> {{ country }} </mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH_LANG' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="codLanguage">
					<mat-option *ngFor="let lang of langOptions" [value]="lang"> {{ lang }} </mat-option>
				</mat-select>
			</mat-form-field>

			<!-- <mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.costoManodopera' | translate }}</mat-label>
				<input matNativeControl type="number" formControlName="costoManodoperaRif">
			</mat-form-field> -->

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.percIncrementoNotturnoRif' | translate }}</mat-label>
				<input matNativeControl type="number" formControlName="percIncrementoNotturnoRif">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.percIncrementoFestivoRif' | translate }}</mat-label>
				<input matNativeControl type="number" formControlName="percIncrementoFestivoRif">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.percIncrementoTrasfertaRif' | translate }}</mat-label>
				<input matNativeControl type="number" formControlName="percIncrementoTrasfertaRif">
			</mat-form-field>

			<mat-checkbox matNativeControl formControlName="isFreeDescription">{{ 'LABEL.isFreeDescription' | translate }}</mat-checkbox>

			<mat-checkbox appearance="fill" matNativeControl formControlName="isActive">{{ 'LABEL.ISACTIVE' | translate }}</mat-checkbox>
		</form>

		<div class="buttons-right my-2">
			<button style="background-color:transparent;" [disabled]="!formTargets.valid || formTargets.pristine"
				matTooltip="{{ 'LABEL.SAVE' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
				(click)="update()">
				<mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
			</button>
		</div>
	</div>
</div>
