
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { InvoicesService } from '../services/invoices.service';
import { AuthService } from '../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  displayedColumns = ['NUMERO_FATTURA', 'TOTALE_FATTURA', 'TOTALE_INCASSI', 'AREA_MANAGER', 'CESSIONE', 'CODICE_CLIENTE', 'COMMESSA', 'DATA_SCADENZA', 'DATA_ULTIMO_INCASSO', 'DESCRIZIONE_RATA', 'DataDocumento', 'IDFATTURA', 'IDSTATO_FATTURA', 'ID_CLIENTE', 'ID_RESPONSABILE', 'ID_RESPONSABILE_CREDITO', 'ID_SEDE_FATTURA', 'ID_SOCIETA_FATTURA', 'ID_STATO', 'IVA', 'NOME_CLIENTE', 'NOME_SEDE_FATTURA', 'NOME_SOCIETA', 'NOME_SOCIETA_FATTURA', 'PDF', 'RESPONSABILE', 'RESPONSABILE_CREDITO', 'STATO', 'TIPO_FATTURA', 'TIPO_SOCIETA', 'TOTALE_NC', 'TOTALE_RITENUTA', 'TipoDocumento'];
  dataSource: any;
  kwSearch: any;
  usr: any;
  @Input() practice: any;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;


  constructor(private invoicesService: InvoicesService,
    public libService: LibService,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslatorService) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.loadInvoices();
  }


  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  loadInvoices(): void {

    this.libService.lockPage('');
    this.invoicesService.getInvoices(this.authService.user().email, this.practice).subscribe((response) => {
      console.log('loadInvoices response %o', response.item.Table);
      this.libService.unlockPage();
      if (response.esito == 'OK') {
        if (!(response.item == null) && !(response.item.Table == null))
          this.dataSource.data = response.item.Table;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    },
      err => {
        this.libService.unlockPage();
        //this.toastr.error(this.translate.instant('LABEL.DATA_INVOICE_ERROR'), this.translate.instant('LABEL.Attenzione'));
      });

  }

}


