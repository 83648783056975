import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class FTIndexAllService {
  name = 'FTIndexAllService';
  baseApi = environment.rootApiCrm + 'v1.0';
  constructor(public libService: LibService) { }

  getIndex(id: string) {
    return this.libService.getData<any>(this.baseApi, `/FTIndexAll/${id.toString()}`);
  }

  getAllIndexes(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, '/FTIndexAll');
  }

  createIndex(obj: any) {
    console.log("SERVICE FRONT END INDEX SUBJECT --> ", obj)
    return this.libService.postData<any>(obj, this.baseApi,'/FTIndexAll');
  }

  deleteIndex( obj: any) {
    return this.libService.deleteData<any>(obj, this.baseApi, `/FTIndexAll/${obj.idEntity.toString()}`);
  }

  getSubjectIdsFTSearch(keyword: any) {
    console.log("getSubjectIdsFTSearch - KEYWORD SERVICE FRONT --> ", keyword)
    return this.libService.postData<any>(keyword, this.baseApi, '/FTIndexAllSearch');
  }

  getById(obj: any) {

    console.log("INPUT --> ",  obj);
    if (obj) {
      return this.libService.postData<any>(obj, this.baseApi, `/FTIndexAllById`);
    } else {
      return '';
    }
  }

}
