import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  Observable,
  shareReplay,
} from 'rxjs';

export class Stream<T> {
  public name: string;
  public loaded: boolean = false;
  protected intialValue: T;
  protected $state: BehaviorSubject<T>;

  protected constructor(name: string, initialValue: T) {
    this.name = name + 'Store';
    this.intialValue = initialValue;
    this.$state = new BehaviorSubject<T>(this.intialValue);
  }

  protected get state(): T {
    return this.$state.getValue();
  }

  protected set next(val: T) {
    this.$state.next(val);
  }

  public get $stream(): Observable<T> {
    return this.$state.asObservable().pipe(shareReplay(1));
  }

  public getValue(): T {
    return this.state;
  }

  public save(val: T): void {
    val != this.state && (this.next = val);
    !this.loaded && (this.loaded = true);
  }
}

export class Store<T> extends Stream<T> {
  constructor(name: string, initialValue: T) {
    super(name, initialValue);
  }

  public saveKey(key: keyof T, val: T[keyof T]): void {
    this.next = {
      ...this.state,
      [key]: val,
    };
  }

  public update(val: Partial<T>): void {
    this.next = {
      ...this.state,
      val,
    };
  }
}

export class StreamList<T> extends Stream<T[]> {
  constructor(name: string, initialValue: T[]) {
    super(name, initialValue);
  }

  public get length(): number {
    return this.state.length;
  }

  public push(val: T): void {
    this.next = [...this.state, val];
  }

  public delete(index: number): void {
    this.next = this.state.filter((_, i) => i === index);
  }

  public findByIndex(index: number): T | undefined {
    return this.state[index];
  }

  public findByKey(key: keyof T, value: unknown): T | undefined {
    return this.state.find((e: T) => e[key] === value);
  }

  public patchByKey(key: keyof T, value: unknown, updates: T): void {
    const results = this.state.map((e: T) =>
      e[key] === value ? (e = updates) : e
    );
    this.save(results);
  }
}

export class StreamQuery extends Stream<string> {
  private debounceTime: number;

  override get $stream(): Observable<string> {
    return this.$state.asObservable().pipe(
      filter((query) => query != null),
      distinctUntilChanged(),
      debounceTime(this.debounceTime),
      shareReplay(1)
    );
  }

  constructor(name: string, debounceTime?: number) {
    super(name, '');
    this.debounceTime = debounceTime ?? 400;
  }
}
