import { Component, OnInit, ElementRef } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../services/libService';
import { ConfService } from '../../services/conf.service';
import { ViewChild } from '@angular/core';
import { WikiService } from '../../services/wikiservice.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { ComponentService } from '../../services/component.service';

import { v4 as uuidv4 } from 'uuid';

import { ConventionService } from '../../services/convention.service'

@Component({
  selector: 'app-conventions-form',
  templateUrl: './conventions-form.component.html',
  styleUrls: ['./conventions-form.component.scss']
})
export class ConventionsFormComponent implements OnInit {


  form: UntypedFormGroup;

  id: any;

  typeGlobal: any = '';
  convention: any = null;


  componentList: any = [];

  conventionSettings: any = []

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public confService: ConfService,
    public translate: TranslatorService,
    public wikiService: WikiService,
    private componentService: ComponentService,
    private router: Router,
    private conventionService: ConventionService,
  ) {

    this.form = this.fb.group({
      id: [],
      label: [null, [Validators.required]],
      code: [null, [Validators.required]],
      startDate: [null],
      endDate: [null],
      componentIds: [],
      subjectId: [null, [Validators.required]],
      subject: [null],
      components: this.fb.array([]),
    })
  }

  clearSubject(){
    console.log('clearSubject')
    this.form?.get('subjectId')?.setValue(null)
    this.form?.get('subject')?.setValue(null)
  }

  selSubject(event: any) {
    console.log('Selected: ', event);
    if(event?.id){
      this.form?.get('subjectId')?.setValue(event.id)
      this.form?.get('subject')?.setValue({
        name: event.name,
        surname: event.surname,
        legalEntity: event.legalEntity,
        type: event.type
      })
    } 
  }

  get subjectId(){
    return this.form?.get('subjectId')?.value
  }

  get subject(){
    return this.form?.get('subject')?.value
  }

  addComponentGroup(values: any): FormGroup {

    console.log('this.conventionSettings')
    console.log(this.conventionSettings)

    let attributesArray: any = []
    if (values.attributes) {
      values.attributes.forEach((attribute: any) => {
        attributesArray.push(this.addAttributeGroup(attribute))
      })
    }


    let component: FormGroup = this.fb.group({
      id: [values.id],
      code: [values.code],
      label: [values.label],
      attributes: this.fb.array(attributesArray)
    });

    return component

  }



  addAttributeGroup(values: any): FormGroup {
    let parametersArray: any = []
    let parametersArrayGroup: any = []
    let tmpGroups: any = {}
    let multiple = values.multiple || false

    if (values.parameters && !multiple) {
      values.parameters.forEach((parameter: any) => {
        parametersArray.push(this.addParameterGroup(parameter, values.id))
      })
    }

    if (values.parameters && multiple) {
      values.parameters.forEach((parameter: any) => {
        let tmpValue = this.conventionSettings.filter((conventionSetting: any) => conventionSetting.ConventionId == this.id && conventionSetting.key == parameter.key && conventionSetting.ComponentAttributeId == values.id)
        if (tmpValue) {
          tmpValue.forEach((row: any) => {
            if (!tmpGroups[row.groupCode]) tmpGroups[row.groupCode] = []

            tmpGroups[row.groupCode].push(this.addParameterGroupCode(parameter, row, row.groupCode))
          })
        } else {

        }

      })
    }

    Object.entries(tmpGroups).forEach(object => {
      const [key, value]: any = object;
      parametersArrayGroup.push(
        this.fb.group({
          codeGroup: key,
          parameters: this.fb.array(value),
        })
      )
    })

    let active = this.conventionSettings.filter((conventionSetting: any) => conventionSetting.ConventionId == this.id && conventionSetting.ComponentAttributeId == values.id)

    return this.fb.group({
      id: [values.id],
      code: [values.code],
      label: [values.label],
      groupCodes: this.fb.array(parametersArrayGroup),
      parameterStructure: [values.parameters],
      parameters: this.fb.array(parametersArray),
      active: active?.length > 0,
      multiple: [multiple]
    });
  }

  addParameterGroupCode(values: any, value: any, groupCode: any = null): FormGroup {

    let tmpValue = value || null
    let tmpGroupCode = groupCode || values.groupCode
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
      groupCode: [tmpGroupCode]
    });
  }

  addParameterGroup(values: any, componetAttributeId: any): FormGroup {
    let tmpValue = this.conventionSettings.find((conventionSetting: any) => conventionSetting.ConventionId == this.id && conventionSetting.key == values.key && conventionSetting.ComponentAttributeId == componetAttributeId)
    tmpValue = tmpValue || null
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
    });
  }

  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }

  get components(): FormArray {
    return <FormArray>this.form.get('components');
  }

  addConventionSettings(attribute: any) {
    console.log('addConventionSettings');
    let groupCode = uuidv4();
    let parametersArray: any = []
    let parameterStructure = attribute.get('parameterStructure').value
    if (parameterStructure) {
      parameterStructure.forEach((parameter: any) => {
        let tmpStructure = this.addParameterGroupCode(parameter, null, groupCode)
        parametersArray.push(tmpStructure)
      })
    }


    attribute.get('groupCodes').push(this.fb.group({
      codeGroup: groupCode,
      parameters: this.fb.array(parametersArray),
    }))
    console.log(attribute)
  }

  componentAttributes(index: number): FormArray {
    return this.components.at(index).get("attributes") as FormArray
  }

  setStructure() {
    console.log(this.form)
    let structure: any = {
      id: this.form?.get('id')?.value,
      label: this.form?.get('label')?.value,
      code: this.form?.get('code')?.value,
      startDate: this.form?.get('startDate')?.value,
      endDate: this.form?.get('endDate')?.value,
      subjectId: this.form?.get('subjectId')?.value,
      conventionSettings: [],
      componentIds: this.form?.get('componentIds')?.value || []
    }

    // Setta visibilità
    let componentIds = this.form?.get('componentIds')?.value || []

    // Setta convention settings solo componenti selezionati
    let components = this.form?.get('components')?.value
    let currentComponents = components.filter((component: any) => componentIds.includes(component.id))

    currentComponents.forEach((component: any) => {
      component.attributes.forEach((attribute: any) => {
        if (attribute.active) {
          console.log(attribute)

          if (!attribute.multiple) {
            attribute.parameters.forEach((parameter: any) => {
              structure.conventionSettings.push({
                key: parameter.key,
                value: parameter.value,
                type: parameter.type,
                ComponentAttributeId: attribute.id
              })
            })
          } else {
            attribute.groupCodes.forEach((groupCode: any) => {
              groupCode.parameters.forEach((parameter: any) => {
                structure.conventionSettings.push({
                  key: parameter.key,
                  value: parameter.value,
                  type: parameter.type,
                  ComponentAttributeId: attribute.id,
                  groupCode: parameter.groupCode
                })
              })
            })
          }

        }
      })
    })
    return structure
  }



  update() {
    // this.libService.lockPage('');
    console.log(this.form.getRawValue())
    let structure = this.setStructure();
    console.log(structure)

    this.conventionService.createSettings(structure).subscribe((response: any) => {
      console.log(response)
      this.toastr.success(
        'ok', this.translate.instant('LABEL.Info'));

      this.id = response.id
      this.form?.get('id')?.setValue(this.id)
      this.router.navigate(['/conventions/', this.id]);
      this.libService.unlockPage();
    })




  }

  showCategory(id: any) {
    let componentIds: any = this.form?.get('componentIds')?.value || []
    return componentIds.includes(id)
  }

  back() {
    this.router.navigate(['conventions/']);
  }

  initComponents() {
    console.log('initComponents')
    this.componentService.full('component_categories_convention').subscribe((response: any) => {
      console.log(response)
      this.componentList = response
      response.forEach((component: any) => {
        this.components.push(this.addComponentGroup(component))
      })
      console.log(this.form)
      this.libService.unlockPage();
    })
  }

  ngOnInit(): void {
    this.libService.lockPage('');
    this.id = this.route.snapshot.paramMap.get('id');

    this.conventionService.showWithSettings(this.id).subscribe((response: any) => {
      console.log(response)
      if (response && Object.keys(response).length > 0) {
        this.convention = response
        if (response?.settings) this.conventionSettings = response?.settings
        this.form?.get('id')?.setValue(this.id)
        this.form?.get('label')?.setValue(this.convention?.label)
        this.form?.get('code')?.setValue(this.convention?.code)
        this.form?.get('startDate')?.setValue(this.convention?.startDate)
        this.form?.get('endDate')?.setValue(this.convention?.endDate)
        this.form?.get('subjectId')?.setValue(this.convention?.subject?.id)
        this.form?.get('subject')?.setValue(this.convention?.subject)

        let componentIds: any = []

        this.convention?.components.forEach((component: any) => {
          if (!componentIds.includes(component.ComponentId)) componentIds.push(component.ComponentId)
        });

        console.log(componentIds)

        this.form?.get('componentIds')?.setValue(componentIds)

        this.initComponents()


      } else {
        this.initComponents()
        this.libService.unlockPage();
      }
    })




  }





  setCode() {
    console.log('setCode')
    if ([undefined, null, 0, '0'].includes(this.id)) {
      let label = this.form?.get('label')?.value
      let code = `conventions_${this.snakeCase(label)}`
      this.form?.get('code')?.setValue(code)
    }
  }



  snakeCase(string: any) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word: any) => word.toLowerCase())
      .join('_');
  };







  removeGroup(indexController: any, indexAttribute: any, indexGroup: any) {
    console.log(indexController)
    console.log(indexAttribute)
    console.log(indexGroup)
    console.log(this.components.controls[indexController])
    let currentController = this.components.controls[indexController]
    let currentAttributes: FormArray = currentController?.get('attributes') as FormArray
    let currentAttribute = currentAttributes.controls[indexAttribute]
    let currentGroups: FormArray = currentAttribute?.get('groupCodes') as FormArray
    currentGroups.removeAt(indexGroup)
  }



}

