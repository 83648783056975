import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file-uploader-button',
  templateUrl: './file-uploader-button.component.html',
  styleUrls: ['./file-uploader-button.component.scss']
})
export class FileUploaderButtonComponent implements OnInit {

  @Input() url: string;
  @Input() buttonContentTemplate: TemplateRef<any>
  @Input() disabled: boolean;

  fileUploader: FileUploader;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.fileUploader = new FileUploader({
      url: this.url,
      autoUpload: true,
      //authToken: 'Bearer ' + this.auth.getJwtToken(),
      removeAfterUpload: true,
      disableMultipart: true
    });

    this.fileUploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.fileUploader.onCompleteAll = () => {};

    this.fileUploader.response.pipe().subscribe(res => {
      const response = JSON.parse(res);
      if (response.started) {
        this.toastr.success(this.translate.instant('TOASTR.MESSAGE10'))
      } else if (response.statusCode !== 200) {
        this.toastr.error(response.message);
      }
    });
  }
}
