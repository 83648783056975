import { ToastrService } from 'ngx-toastr';
import { CategoryService } from './../../services/category.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BranchService } from 'src/app/services/branch.service';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { codeCharacter } from 'src/app/validators/code.validator';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss']
})
export class CategoryDetailComponent implements OnInit {

  isNew: boolean;
  id: any;
  target: any;
  target$: Observable<any>;
  formTargets: UntypedFormGroup;
  countryOptions = ['IT', 'ES', 'FR', 'MC']
  langOptions = ['IT', 'ES', 'FR']
  branches: any;
  allBranches: any;

  constructor(private fb: UntypedFormBuilder,
    private categoryService: CategoryService,
    private branchService: BranchService,
    private route: ActivatedRoute,
    private libService: LibService,
    public translate: TranslatorService,
    public navService: NavService,
    private toastService: ToastrService,
    private router: Router) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if(this.id != 0) this.target$ = this.categoryService.getCategory(this.id);
    });
    this.branchService.getAllBranches().subscribe(branches => {
      this.allBranches = branches.sort((a: any, b: any) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
      this.branches = branches.sort((a: any, b: any) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
    })
    this.formTargets = this.fb.group({
      branch: [null],
      id: [null],
      code: [null, [Validators.required, codeCharacter()]],
      name: [null, Validators.required],
      codCountry: [null, Validators.required],
      codLanguage: [null, Validators.required],
      isActive: [null],
      costoManodoperaRif: [null, Validators.required],
      percIncrementoNotturnoRif: [null],
      percIncrementoFestivoRif: [null],
      percIncrementoTrasfertaRif: [null],
      isFreeDescription: [null],
    })
  }

  onChangeFilterBranches(event: KeyboardEvent) {
    const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
    this.branches = this.allBranches.filter((mp: any) => mp.name.toLowerCase().includes(searchStr))
  }

  onOpenSearch(searchElement: HTMLInputElement){
    searchElement.value = '';
    searchElement.focus();
  }

  onCloseSearch(){
    this.branches = this.allBranches;
  }

  update() {
    const formObject = this.formTargets.getRawValue()
    const branch = this.allBranches.find((b:any) => b.id == formObject.branch)
    const toSend = {
      category: {...formObject},
      branch: {...branch},
    }
    delete toSend.category.branch
    delete toSend.branch.BCRel
    delete toSend.branch.BranchesCategoriesRel
    if(toSend.category.id == null){
      // Creation
      delete toSend.category.id
      let createdID = 0
      this.categoryService.createCategory(toSend).subscribe({
        next(success){
          console.log(success)
          createdID=success?.Category?.id
        },
        error: (err) => {
          console.error(err)
          this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
        complete: () => {
          console.log('Done')
          this.toastService.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigateByUrl(`genconfig`).then(console.log).catch(console.error)
        }
      })
    }else{
      // Update
      this.categoryService.updateCategory(toSend.category).subscribe({
        next(success){
          console.log(success)
        },
        error: (err) => {
          console.error(err)
          this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
        complete: () => {
          console.log('Done')
          this.toastService.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        }
      })
    }
  }

  ngOnInit(): void {
    for(let lang of this.langOptions){console.log(lang)}
    this.initNavbar();
    console.log('Category ID: ', this.id)
    if (this.id != 0) {
      this.libService.lockPage('');
      this.target$.subscribe(val => {
        this.formTargets.controls['branch'].disable()
        this.formTargets.controls['code'].disable()
        this.formTargets.controls['name'].disable()
        this.formTargets.controls['codCountry'].disable()
        this.formTargets.controls['codLanguage'].disable()
        this.target = val;
        console.log("val -->", val);
        this.formTargets.patchValue(this.target);
        this.formTargets.controls['branch'].setValue(
          this.allBranches?.filter((b: any) => b.BranchesCategoriesRel.find((c: any) => c.id == val.id) != undefined)[0]?.id
        )
        this.libService.unlockPage();
      })
    }
  }

  initNavbar(){
    setTimeout(() => {
      let actions: any;
      actions = [
        { name: 'back', url: 'genconfig' }
      ];

      let title = this.formTargets.get('id')?.value !== null?
        this.translate.instant('LABEL.EDIT', { field: 'Categoria' }):
        this.translate.instant('LABEL.NEW', { field: 'Categoria' })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }
}
