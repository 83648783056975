<div>
  <!-- Heading -->
  <div id="sidebarHeading" [class.collapsed]="collapsed"
    class="d-flex justify-content-between align-items-center px-5 crs">
    <img (click)="goHome()" src="../../assets/img/acrobatica_logo_giallo.svg" alt="logo albert 4.0" height="40px" *ngIf="!collapsed">
    <img (click)="goHome()" src="../../assets/img/acrobatica_symbol_giallo.svg" alt="logo albert 4.0 min" height="40px" *ngIf="collapsed">
    <div>
      <fa-icon [icon]="faBars" style="cursor: pointer; color: white" (click)="setCollapsed(!collapsed)"></fa-icon>
      <fa-icon [icon]="faArrowRightFromBracket" style="cursor: pointer; color: white" (click)="logout()"
        id="logoutButton"></fa-icon>
    </div>
  </div>
  <!-- Collapsible content -->
  <div id="collapsibleContent" class="vh-100" [class.collapsed]="collapsed">
    <!-- User -->
    <div id="user" class="p-5">
      <div class="row">
        <div class="col-3">
          <img src="../../assets/img/av.png" alt="avatar mock" height="60px" id="avatar">
        </div>
        <div class="col-7" style="padding-left: 20px;" *ngIf="!collapsed">
          <span class="h5 text-white">{{isUser().given_name}} {{isUser().family_name}}</span><br />
          <span class="text-yellow">{{ 'SIDEBAR.PROFILE' | translate }}</span><br />
        </div>
        <small *ngIf="!collapsed" class="text-success">{{isUser().email}}</small>
        <!-- <div class="col-2 text-end px-2" *ngIf="!collapsed" >
          <span style="cursor: pointer;" (click)="goTo('/user-area')">
            <fa-icon [icon]="faCog" class="h3 text-white" ></fa-icon>
          </span>
        </div> -->
      </div>
    </div>
    <!-- /User -->
    <!-- Divider -->
    <hr style="color: black; width: 100%; height: 2px;" class="m-0 mb-5">
    <!-- /Divider -->
    <!-- List menu -->


    <div *ngFor="let item of data$ | async" class="px-3">

        <mat-accordion *ngIf="item.items.length > 0">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="sidebarGroupTitle">{{'SIDEBAR.' + item.label | translate}}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="list-unstyled">
              <li *ngFor="let subitem of item.items" style="cursor: pointer;">
    
                <a class="nav-link d-flex justify-content-between" routerLink="{{subitem.url}}" [routerLinkActiveOptions]="{exact: true}"
                  routerLinkActive="active-link" (click)="goTo(subitem.url)"
                  *ngIf="hasNode(subitem.label) && !(subitem.url == null) && !(subitem.url.indexOf('https') >= 0)">
                  <div>
                    <mat-icon class="sidebarIcon" aria-hidden="false" aria-label="Example home icon" [fontIcon]="subitem.icon || 'tabs'"></mat-icon>
                    <span [matTooltip]="getTooltip(subitem.label)" *ngIf="!collapsed">{{ 'SIDEBAR.' + subitem.label |
                      translate }}</span>
                  </div>
                  <fa-icon [icon]="faChevronRight" class="my-auto" *ngIf="!collapsed"></fa-icon>
                </a>
                <a class="nav-link d-flex justify-content-between" target="_blank" (click)="goToExt(subitem.url)" [routerLinkActiveOptions]="{exact: true}"
                  *ngIf="hasNode(subitem.label) && !(subitem.url == null) && (subitem.url.indexOf('https') >= 0)">
                  <div>
                    <mat-icon class="sidebarIcon" aria-hidden="false" aria-label="Example home icon" [fontIcon]="subitem.icon || 'tabs'"></mat-icon>
                    <span [matTooltip]="getTooltip(subitem.label)" *ngIf="!collapsed">{{ 'SIDEBAR.' + subitem.label |
                      translate }}</span>
                  </div>
                  <fa-icon [icon]="faChevronRight" class="my-auto" *ngIf="!collapsed"></fa-icon>
                </a>
              </li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>

     
    </div>

    <!-- /List menu -->
  </div>
  <!-- /Collapsible content -->
</div>