import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  baseApi = environment.rootApiCrm + 'v1.0';

  constructor(
    public libService: LibService,
    public authService: AuthService,
  ) { }

  getClaim(id: string) {
    return this.libService.getData<any>(this.baseApi, `/claim/${id.toString()}`);
  }

  getAllClaims(): Observable<any> {
    console.log("SERVICE FRONT: "+this.baseApi);
    return this.libService.getData<any>(this.baseApi, `/claims`);
  }

  updateClaim(id: any, claim: any) {
    return this.libService.postData<any>(claim, this.baseApi, `/claim/${id.toString()}`)
  }

  deleteClaim(id: any, claim: any) {
    return this.libService.deleteData<any>(claim, this.baseApi, `/claim/${id.toString()}`);
  }

  createClaim(claim: any) {
    return this.libService.postData<any>(claim, `${this.baseApi}`,'/claim');
  }

  allWithPagination(filterOptions: any = [], orderOptions: any = [], skip: number = 0, take: number = 10) {
    console.log("allWithPagination")
    let tokenId = this.authService.idToken()
    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption
    }
    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `/claims/allWithPagination`)
  }

}
