import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstimateDeliveryMethodService {

  baseApi = environment.rootApiPaperwork;

  constructor(
    public libService: LibService,
  ) { }

  getEstimateDeliveryMethods(): Observable<any> {
    return this.libService.getData<any>(
      this.baseApi, 
      'estimateDeliveryMethods'
      );
  }

}
