<h1 mat-dialog-title>{{ titleLabel | translate }}</h1>

<div mat-dialog-content>

  <p>
    {{ textLabel | translate }}
  </p>

  <form [formGroup]="form" class="form-container">

    <mat-form-field *ngIf="listOfStatesAvailableForMigration?.length > 0" style="width: 100%;" appearance="fill"
      class="form-activities">
      <mat-label>{{ 'LABEL.STATE' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="migrationState">
        <mat-option *ngFor="let state of listOfStatesAvailableForMigration" [disabled]="state.disabled"
          [value]="state.id">
          <!-- | async -->
          {{ state.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>File</mat-label>
      <input type="file" id="fileInput" (change)="onFileSelected($event, 0)" class="file-input" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'EVENTS.name' | translate }}</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'EVENTS.description' | translate }}</mat-label>
      <textarea matInput rows="15" formControlName="description" placeholder="Descrizione"></textarea>
    </mat-form-field>

    <mat-form-field *ngIf="listOfStatesAvailableForMigration?.length > 0" style="width: 100%;" appearance="fill"
      class="form-activities">
      <mat-label>{{ 'LABEL.STATE' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="migrationState">
        <mat-option *ngFor="let state of listOfStatesAvailableForMigration" [disabled]="state.disabled"
          [value]="state.id">
          <!-- | async -->
          {{ state.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="disabledConfirmButton()">{{ confirmButtonLabel |
    translate }}</button>
  <button mat-button [mat-dialog-close]="">{{ cancelButtonLabel | translate }}</button>
</div>