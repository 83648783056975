import { Address } from './../models/address';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, HostBinding, Optional, Self, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GeocoderResponse } from '../models/geocoder';
import { GeocodingService } from '../services/geocoding.service';
import { UserSessionService } from '../services/user-session.service';
import { UntypedFormGroup, UntypedFormBuilder, NgControl } from '@angular/forms';

import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {

  @Input() request: any;
  @Output() addrOut = new EventEmitter;
  @ViewChild(MatExpansionPanel) accordion: MatExpansionPanel;


  arrayAddr: any[];


  addrObj = {
    address: "",
    streetName: "",
    streetNumber: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    coordX: null,
    coordY: null
  };

  geocoderWorking = false;
  address: any;
  subject: any;
  formAddr: UntypedFormGroup;

  constructor(private geocodingService: GeocodingService,
              private fb:UntypedFormBuilder,
              private toastr: ToastrService,
              private userSessionService: UserSessionService,
              @Optional() @Self() public ngControl: NgControl,)
  {


  }

  ngOnInit(): void {
    //HANDLE THE OBSERVABLE THAT IS ARRIVING FROM REQUESTCOMPONENT
    this.request.subscribe((req:any) => {{
      if (req?.id) {
        let addr =  req.building.streetName + ', ' + req.building.streetNumber + ', ' +
                    req.building.zip + ', ' + req.building.city + ', ' + req.building.country;
        this.formAddr.patchValue({
          address: addr,
          streetName: req.building.streetName,
          streetNumber: req.building.streetNumber,
          city: req.building.city,
          country: req.building.country,
          zip: req.building.zip
        })

        this.addrObj.address = addr;
        this.addrObj.streetName = req.building.streetName;
        this.addrObj.streetNumber = req.building.streetNumber;
        this.addrObj.city = req.building.city;
        this.addrObj.country = req.building.country;
        this.addrObj.zip = req.building.zip;

        this.addrOut.emit(this.addrObj);
      }

    }})

    //HANDLE THE CHANGE VALUE WHEN SELECTING FROM AUTOCOMPLETE GOOGLE MAPS
    let autocomplete = new google.maps.places.Autocomplete(this.address);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {

          this.address = autocomplete.getPlace();
    });

    this.formAddr = this.fb.group({
      address: [null],
      streetName: [null],
      streetNumber: null,
      city: null,
      country: null,
      zip: null
    })

    this.formAddr.get('address')?.valueChanges.subscribe( val =>
    {
      console.log("VALUECHANGE: " + this.addrObj.address + " != " + val);
      this.addrObj.address =  val;
      this.addrOut.emit(this.addrObj);
    });

    /* console.log("THIS.ADDRESS --> ", this.address) */
  }

  get isWorking(): boolean {
    return this.geocoderWorking;
  }

  findAddress() {

    /* console.log (" --> ", this.address) */
    this.address = JSON.stringify(this.address);
    if (!this.address || this.address.length === 0) {
      return;
    }

    let currentLanguage = this.userSessionService.getCurrentLanguage()

    this.geocoderWorking = true;
    this.geocodingService
      .getLocation(this.address, currentLanguage)
      .subscribe(
        (response: GeocoderResponse)  => {
          /* console.log('response for geocoding %o', response); */
          if (response.status === 'OK' && response.results?.length) {

            this.placeChangedCallback(response.results[0]);
            const location = response.results[0];
            const loc: any = location.geometry.location;
            this.addrObj.coordX = loc.lng;
            this.addrObj.coordY = loc.lat;

            this.address = location.formatted_address;

            this.formAddr.patchValue({
              streetName: this.addrObj.streetName,
              streetNumber: this.addrObj.streetNumber,
              city: this.addrObj.city,
              country: this.addrObj.country,
              zip: this.addrObj.zip
            })

          } else {
            this.toastr.error(response.error_message, response.status);
          }
        },
        (err: HttpErrorResponse) => {
          console.error('geocoder error', err);
        }
      )
      .add(() => {
        this.geocoderWorking = false;
      });

  }

  tabGroupClicked(e: any) {

  }

  tabIndexChange(e: any) {

  }

  tabIndexAnagChange(e: any) {

  }

  tabGroupAnagClicked(e: any) {

  }

  handleAddressChange(address: any) {
    this.formAddr.get('address')?.setValue(address.formatted_address);
    this.address = Object.assign(address.formatted_address);

 }

  /* onChange(e: any) {

    console.log(e);
  } */

  placeChangedCallback(place: any) {
    /* console.log('place %o',place); */
    place.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        /* console.log('place %o', add); */
        if (addType == "street_number")
          this.addrObj.streetNumber = add.short_name;
        if (addType == "route")
          this.addrObj.streetName = add.long_name;
        if (addType == "locality" || addType == "sublocality_level_1")
          this.addrObj.city = add.long_name;
        if (addType == "administrative_area_level_1")
          this.addrObj.state = add.long_name;
        if (addType == "country")
          this.addrObj.country = add.long_name;
        if (addType == "postal_code")
          this.addrObj.zip = add.long_name;
      });

    });
  }

  onType(e:any) {
    this.formAddr.get('address')?.updateValueAndValidity();
    console.log(e);
  }

  validateAddress() {
    this.accordion.open()
    this.findAddress();
    this.addrOut.emit(this.addrObj);

  }
}
