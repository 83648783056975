<!--
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Altro
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Tipo Survey</mat-label>
        <mat-select appearance="fill" [(ngModel)]="selectedSurvey" (selectionChange)="selectedChanged($event)">
          <mat-option *ngFor="let surveyMaster of surveyMasters$ | async" [value]="surveyMaster">{{surveyMaster.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <button class="btn btn-primary btn-sm me-3 text-dark" [disabled]="!selectedSurvey" (click)="openSurveyDialogNew(selectedSurvey)">
          <div>Aggiungi nuovo</div>
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngFor="let surveysByType of filteredSubjectSurveys">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{surveysByType.name}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngFor="let survey of surveysByType.surveys">
        <a style="cursor:pointer;" (click)="openSurveyDialog(survey)">{{survey.updatedAt | date:'short'}}</a>
      </div>
      <button class="btn btn-primary btn-sm me-3 text-dark" (click)="openSurveyDialogNew(surveysByType.surveys[0].surveyMasterOrigin)">
        <mat-icon>add</mat-icon>
      </button>
    </mat-expansion-panel>
  -->


<div class="newsurveycontainer">
<mat-form-field appearance="fill" class="form-subject" >
  <mat-label>{{'LABEL.TipoQuestionario' | translate}}</mat-label>
  <mat-select appearance="fill" [(ngModel)]="selectedSurvey" (selectionChange)="selectedChanged($event)" [disabled]="toDisable">
    <mat-option *ngFor="let surveyMaster of surveyMasters$ | async" [value]="surveyMaster">{{surveyMaster.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<div>
  <button class="btn btn-primary btn-sm me-3 text-dark" [disabled]="!selectedSurvey"
    (click)="openSurveyDialogNew(selectedSurvey)">
    <div>{{'LABEL.AggiungiNuovo' | translate}}</div>
    <mat-icon>add</mat-icon>
  </button>
</div>
</div>
<br>
<div *ngFor="let surveysByType of filteredSubjectSurveys">
  <div *ngFor="let survey of surveysByType.surveys">
    <a style="cursor:pointer; font-size:14px;" (click)="openSurveyDialog(survey)">{{surveysByType.name}}&nbsp;{{'LABEL.del' | translate}}&nbsp;<b>{{survey.updatedAt | date:'short'}}</b></a>
  </div>
</div>
