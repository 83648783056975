<form [formGroup]="form" class="form-container" (ngSubmit)="update()">

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'ACTIONS.name' | translate }}</mat-label>
        <input matInput formControlName="name" (focusout)="setCode()">
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'ACTIONS.code' | translate }}</mat-label>
        <input readonly matInput formControlName="keyFrontEnd">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Category</mat-label>
        <mat-select formControlName="actionCategoryId">
            <mat-option *ngFor="let actionCategory of actionCategories"
                [value]="actionCategory.id">{{actionCategory.code}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Business Lines</mat-label>
        <mat-select formControlName="branchIds" multiple (selectionChange)="getSocietiesByBusinessLines($event)">
            <mat-option *ngFor="let branch of branchesFilter" [value]="branch.id">{{branch.code}}
                ({{branch.codLanguage}})</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Societies</mat-label>
        <mat-select formControlName="societyIds" multiple (selectionChange)="companiesFilter($event)">
            <mat-option *ngFor="let society of societies" [value]="society.id">{{society.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Companies</mat-label>
        <mat-select formControlName="companyIds" multiple>
            <mat-option *ngFor="let company of companiesFiltered" [value]="company.id">{{company.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Subject Types</mat-label>
        <mat-select formControlName="subjectTypeIds" multiple>
            <mat-option *ngFor="let subjectType of subjectTypes"
                [value]="subjectType.id">{{subjectType.description}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Profili</mat-label>
        <mat-select formControlName="profileIds" multiple>
            <mat-option *ngFor="let profile of profiles" [value]="profile.id">{{profile.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Componenti</mat-label>
        <mat-select formControlName="componentIds" multiple>
            <mat-option *ngFor="let component of componentList" [value]="component.id">{{component.code}}</mat-option>
        </mat-select>
    </mat-form-field>


    <ng-container formArrayName="components">
        <ng-container *ngFor="let component of components.controls; let i = index">
            <div [formGroup]="component" class="form-subject">

                <mat-accordion *ngIf="showCategory(component.get('id').value)">
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{component.get('code').value}}
                            </mat-panel-title>
                            <mat-panel-description>
                                Attributi: {{component.get('attributes').value.length}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-container formArrayName="attributes">
                            <div class="cardBox">

                                <mat-card class="cardItem"
                                    *ngFor="let attribute of component.get('attributes').controls; let c = index">
                                    <ng-container [formGroup]="attribute">
                                        <mat-card-header>
                                            <mat-card-title>{{attribute.get('code').value}}</mat-card-title>
                                            <mat-card-subtitle>
                                                code: {{attribute.get('code').value}}<br>
                                                id: {{attribute.get('id').value}}<br>
                                                multiple: {{attribute.get('multiple').value}}<br>
                                                <mat-slide-toggle color="primary"
                                                    formControlName="active">Attivo</mat-slide-toggle>
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content>


                                            <ng-container formArrayName="groupCodes"
                                                *ngIf="attribute.get('active').value && attribute.get('multiple').value">

                                                <button *ngIf="attribute.get('multiple').value" matTooltip="Back"
                                                    type="button" class="my-bounce btn btn-primary mx-2"
                                                    (click)="addActionSettings(attribute)">
                                                    Aggiungi
                                                </button>

                                                <div
                                                    *ngFor="let groupCode of attribute.get('groupCodes')?.controls; let gc = index">
                                                    <ng-container [formGroup]="groupCode">
                                                        <div class="boxParameters"
                                                            *ngIf="groupCode?.get('parameters')?.controls">
                                                            <p><strong>Group Code:</strong>
                                                                {{groupCode.get('codeGroup')?.value }}<br>
                                                                <mat-icon (click)="removeGroup(i, c, gc)"
                                                                    class="material-icons"
                                                                    style="color:#293683;">delete</mat-icon>
                                                            </p>


                                                            <ng-container formArrayName="parameters">
                                                                <div
                                                                    *ngFor="let parameter of groupCode?.get('parameters')?.controls; let p = index">
                                                                    <ng-container [formGroup]="parameter">
                                                                        <div [ngSwitch]="parameter.get('type').value">
                                                                            <p *ngSwitchCase="'boolean'">
                                                                                <mat-slide-toggle color="primary"
                                                                                    formControlName="value">{{parameter.get('key').value}}</mat-slide-toggle>
                                                                            </p>

                                                                            <p *ngSwitchCase="'number'">

                                                                                <container-element
                                                                                    [ngSwitch]="parameter.get('key').value">


                                                                                    <some-element *ngSwitchDefault>
                                                                                        <mat-form-field
                                                                                            appearance="fill"
                                                                                            class="form-subject">
                                                                                            <mat-label>{{parameter.get('key').value}}</mat-label>
                                                                                            <input matInput
                                                                                                formControlName="value">
                                                                                        </mat-form-field>
                                                                                    </some-element>

                                                                                </container-element>



                                                                            </p>

                                                                            <p *ngSwitchCase="'string'">

                                                                                <container-element
                                                                                    [ngSwitch]="parameter.get('key').value">

                                                                                    <some-element
                                                                                        *ngSwitchCase="'nextStateCode'">
                                                                                        <mat-form-field
                                                                                            appearance="fill"
                                                                                            class="form-subject">
                                                                                            <mat-label>Next
                                                                                                State</mat-label>
                                                                                            <mat-select
                                                                                                formControlName="value">
                                                                                                <mat-option
                                                                                                    *ngFor="let state of currentStates"
                                                                                                    [value]="state.code">{{getStatusLabel(state.id)}}</mat-option>
                                                                                            </mat-select>
                                                                                        </mat-form-field>
                                                                                    </some-element>

                                                                                    <some-element
                                                                                        *ngSwitchCase="'reasonCode'">
                                                                                        <mat-form-field
                                                                                            appearance="fill"
                                                                                            class="form-subject">
                                                                                            <mat-label>Reason</mat-label>
                                                                                            <mat-select
                                                                                                formControlName="value">
                                                                                                <mat-option
                                                                                                    *ngFor="let reason of reasons"
                                                                                                    [value]="reason.code">{{reason.label}}</mat-option>
                                                                                            </mat-select>
                                                                                        </mat-form-field>
                                                                                    </some-element>

                                                                                    <some-element
                                                                                        *ngSwitchCase="'documentTypeCode'">
                                                                                        <mat-form-field
                                                                                            appearance="fill"
                                                                                            class="form-subject">
                                                                                            <mat-label>Document
                                                                                                Type</mat-label>
                                                                                            <mat-select
                                                                                                formControlName="value">
                                                                                                <mat-option
                                                                                                    *ngFor="let documentType of documentTypes"
                                                                                                    [value]="documentType.code">{{documentType.code}}</mat-option>
                                                                                            </mat-select>
                                                                                        </mat-form-field>
                                                                                    </some-element>


                                                                                    <some-element *ngSwitchDefault>
                                                                                        <mat-form-field
                                                                                            appearance="fill"
                                                                                            class="form-subject">
                                                                                            <mat-label>{{parameter.get('key').value}}</mat-label>
                                                                                            <input matInput
                                                                                                formControlName="value">
                                                                                        </mat-form-field>
                                                                                    </some-element>

                                                                                </container-element>


                                                                            </p>

                                                                        </div>



                                                                    </ng-container>
                                                                </div>

                                                            </ng-container>


                                                        </div>
                                                    </ng-container>
                                                </div>

                                            </ng-container>

                                            <ng-container formArrayName="parameters"
                                                *ngIf="attribute.get('active').value && !attribute.get('multiple').value">
                                                <div
                                                    *ngFor="let parameter of attribute?.get('parameters')?.controls; let p = index">
                                                    <ng-container [formGroup]="parameter">
                                                        <div [ngSwitch]="parameter.get('type').value">

                                                            <p *ngSwitchCase="'boolean'">
                                                                <mat-slide-toggle color="primary"
                                                                    formControlName="value">{{parameter.get('key').value}}</mat-slide-toggle>
                                                            </p>

                                                            <p *ngSwitchCase="'number'">

                                                                <container-element
                                                                    [ngSwitch]="parameter.get('key').value">

                                                                    <some-element *ngSwitchCase="'documentTypeId'">
                                                                        <mat-form-field appearance="fill"
                                                                            class="form-subject">
                                                                            <mat-label>Document Type</mat-label>
                                                                            <mat-select formControlName="value">
                                                                                <mat-option
                                                                                    *ngFor="let documentType of documentTypes"
                                                                                    [value]="documentType.id">{{documentType.code}}</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </some-element>

                                                                    <some-element *ngSwitchCase="'nextStateId'">
                                                                        <mat-form-field appearance="fill"
                                                                            class="form-subject">
                                                                            <mat-label>Next State</mat-label>
                                                                            <mat-select formControlName="value">
                                                                                <mat-option
                                                                                    *ngFor="let state of currentStates"
                                                                                    [value]="state.id">{{getStatusLabel(state.id)}}</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </some-element>

                                                                    <some-element *ngSwitchCase="'practiceWorkflowId'">
                                                                        <mat-form-field appearance="fill"
                                                                            class="form-subject">
                                                                            <mat-label>Next Workflow</mat-label>
                                                                            <mat-select formControlName="value">
                                                                                <mat-option
                                                                                    *ngFor="let workflow of workflows"
                                                                                    [value]="workflow.id">{{workflow.label}}</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </some-element>

                                                                    <some-element *ngSwitchDefault>
                                                                        <mat-form-field appearance="fill"
                                                                            class="form-subject">
                                                                            <mat-label>{{parameter.get('key').value}}</mat-label>
                                                                            <input matInput formControlName="value">
                                                                        </mat-form-field>
                                                                    </some-element>

                                                                </container-element>


                                                            </p>

                                                            <p *ngSwitchCase="'string'">
                                                                <container-element
                                                                    [ngSwitch]="parameter.get('key').value">

                                                                    <some-element *ngSwitchCase="'nextStateCode'">
                                                                        <mat-form-field appearance="fill"
                                                                            class="form-subject">
                                                                            <mat-label>Next State</mat-label>
                                                                            <mat-select formControlName="value">
                                                                                <mat-option
                                                                                    *ngFor="let state of currentStates"
                                                                                    [value]="state.code">{{getStatusLabel(state.id)}}</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </some-element>

                                                                    <some-element
                                                                        *ngSwitchCase="'translationGroupCode'">

                                                                        <mat-form-field appearance="fill"
                                                                            class="form-subject">
                                                                            <mat-label>Translation Group</mat-label>
                                                                            <mat-select formControlName="value">
                                                                                <mat-option
                                                                                    *ngFor="let translationGroup of translationGroups"
                                                                                    [value]="translationGroup.code">{{translationGroup.label}}</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>

                                                                        <!--
                                                                        <app-multi-select-dropdown fxFlex
                                                                            formControlName="value"
                                                                            [items]="translationGroups"
                                                                            firstSelectionViewField="translationGroupCode"
                                                                            [firstSelectionLabel]="'Translation Group'"
                                                                            [noUsers]="disabled"
                                                                            [disableChipList]="disabled" fxFlex>
                                                                        </app-multi-select-dropdown>
                                                                        -->



                                                                    </some-element>

                                                                    <some-element *ngSwitchDefault>
                                                                        <mat-form-field appearance="fill"
                                                                            class="form-subject">
                                                                            <mat-label>{{parameter.get('key').value}}</mat-label>
                                                                            <input matInput formControlName="value">
                                                                        </mat-form-field>
                                                                    </some-element>

                                                                </container-element>


                                                            </p>

                                                        </div>



                                                    </ng-container>
                                                </div>

                                            </ng-container>


                                        </mat-card-content>
                                    </ng-container>
                                </mat-card>
                            </div>
                        </ng-container>


                    </mat-expansion-panel>
                </mat-accordion>


            </div>
        </ng-container>
    </ng-container>

</form>





<div class="buttons-right  my-2">

    <button style="background-color:transparent;" matTooltip="Back" type="button" class="my-bounce btn btn-primary mx-2"
        (click)="back()">
        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
    </button>

    <button style="background-color:transparent;" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()" [disabled]="!form.valid">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>

</div>