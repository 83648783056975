import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NavService } from '../services/nav.service';
import {
  faGlobe,
  faUser,
  faBuilding,
  faEnvelope,
  faArrowRightFromBracket,
  faFile,
  faFileArrowUp,
  faFileArrowDown,
  faFileMedical,
  faArrowLeft,
  faCircleExclamation,
  faSync,
  faBell
} from '@fortawesome/free-solid-svg-icons';
import { SidebarService } from '../services/sidebar.service';
import { WikiService } from '../services/wikiservice.service';
import { TranslateService } from '@ngx-translate/core';
import { UserSessionService } from '../services/user-session.service';
import { ActiveSessionService } from '../services/active-session.service';
import { switchMap } from 'rxjs';
import { LibService } from '../services/libService';
import { UserDataService } from '../services/users.service';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';

import { CompanyManagementService } from '../services/company-management.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  faSync = faSync;
  faGlobe = faGlobe;
  faUser = faUser;
  faEnvelope = faEnvelope;
  faArrowRightFromBracket = faArrowRightFromBracket;
  faFile = faFile;
  faFileArrowUp = faFileArrowUp;
  faFileArrowDown = faFileArrowDown;
  faFileMedical = faFileMedical;
  faArrowLeft = faArrowLeft;
  faBuilding = faBuilding;
  faCircleExclamation = faCircleExclamation;
  faBell = faBell;
  workingCompany: any = {};
  companies: any = [];
  isOperational: boolean = false;
  isStaff: boolean = false;
  isAdmin: boolean = false;
  ip: string;
  searchText: string = '';
  filteredCompanies: any = [];


  constructor(
    private authService: AuthService,
    private router: Router,
    public navService: NavService,
    public sidebarService: SidebarService,
    public translate: TranslateService,
    public wikiService: WikiService,
    public userSessionService: UserSessionService,
    private activeSessionService: ActiveSessionService,
    private toastr: ToastrService,
    private libService: LibService,
    private userService: UserDataService,
    private companyManagementService: CompanyManagementService
  ) {
    this.companyManagementService.itemAdded$.subscribe((item: any) => {
      this.workingCompany = item;
    });
  }

  getIcon(name: any) {
    let icon: any = faFile;
    switch (name) {

      case 'faFileArrowUp':
        icon = faFileArrowUp
        break;
      case 'faFileArrowDown':
        icon = faFileArrowDown
        break;
      case 'faFileMedical':
        icon = faFileMedical
        break;

    }
    return icon
  }

  ngOnInit(): void {
    // console.log(
    //   'this.userSessionService.getState(working_company) %o',
    //   this.userSessionService.getState('working_company')
    // );
    this.workingCompany = this.userSessionService.getState('working_company');
    if (this.workingCompany == null)
      this.userSessionService.saveState('working_company', {
        isOpen: true,
        name: '*',
        code: '*',
        id: 0,
      });

    this.redirectWorkflow();

    //this.initSessionGuard();
  }

  redirectWorkflow() {
    if (this.user() !== null) {
      let redirectUrl = this.userSessionService.getState(
        'post_login_redirect'
      ) as string;
      if (redirectUrl !== null && redirectUrl !== undefined) {
        this.userSessionService.saveState('post_login_redirect', null);
        //redirectUrl = redirectUrl.replace(window.location.host, '')
        this.router.navigateByUrl(redirectUrl);
      }
    }
  }

  isSidebarCollapsed() {
    return this.sidebarService.getCollapsed();
  }

  goToWiki() {
    // console.log(
    //   'gotowiki %o',
    //   this.wikiService.getWikiUrl(this.navService.getWikiKey())
    // );
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }

  hasAction(name: string): boolean {
    //console.log('ACTIONS %o', this.navService.getActions());
    for (let a = 0; a < this.navService.getActions().length; a++) {
      if (name == this.navService.getActions()[a]['name']) return true;
    }
    return false;
  }

  getAction(name: string) {
    let action = this.navService
      .getActions()
      .filter((a: any) => a.name == name);
    return action.length == 1 ? action[0] : null;
  }

  changeCompany(cp: any) {
    //console.log('change company for %o', cp);
    // if (e.isUserInput == true) {
    this.userSessionService.saveState('working_company', cp);
    this.workingCompany = cp;
    // }
  }

  filterCompanies() {
    if (Array.isArray(this.companies)) {
      const searchTextLower = this.searchText.toLowerCase();
      const startsWithSearchText = this.companies.filter(cp =>
        cp.name.toLowerCase().startsWith(searchTextLower)
      );
      const includesSearchText = this.companies.filter(cp =>
        !cp.name.toLowerCase().startsWith(searchTextLower) && cp.name.toLowerCase().includes(searchTextLower)
      );
      this.filteredCompanies = [...startsWithSearchText, ...includesSearchText];
    } else {
      console.error('companies is not an array');
      this.filteredCompanies = [];
    }
  }

  initSessionGuard() {
    if (this.user() !== null) {
      //console.log('setting session subscriber');
      this.userService.checkSession(this.user().email).subscribe((canLogin) => {
        if (!canLogin) {
          this.libService.lockPageAdmin(
            this.translate.instant('ERROR.CONCURRENT_USERS')
          );
        }
      });
      /* let user$ = this.userSessionService.getUser(this.user().email)
      let ip$ = this.userService.getIp().pipe(
        map((ip: any) => ip.ip)
      )

      combineLatest([user$, ip$]).subscribe(([user, ip]) => {
        console.log(user, ip);
        if (user && ip) {
          //let allLicences = user[0].UsersCompaniesRel?.map(company => company.CompaniesLicencesRel?.map(licence => licence))
          // console.log('AllLicences:', allLicences);
          this.ip = ip
          const session = user[0].UsersActiveSessionsRel?.find(session => session.ipAddress === ip)
          const licence = user[0].UsersCompaniesRel![0].CompaniesLicencesRel![0]
          let newActiveSession = {
            ipAddress: ip,
            lastLoggedInAt: new Date(),
            licence: licence.id,
            user: user[0].id
          }

          if (!session) {
            if (licence.numberOfUsers! > licence.currentlyConnectedUsers!) {
              //licence has space available
              let newActiveSession = {
                ipAddress: ip,
                lastLoggedInAt: new Date(),
                licence: licence.id,
                user: user[0].id
              }
              this.activeSessionService.create(newActiveSession).subscribe(res => {
                console.log(res)
              })
            } else {
              this.libService.lockPageAdmin(this.translate.instant('ERROR.CONCURRENT_USERS'))
            }

          }
        }
      })*/
    }
  }

  login() {
    this.authService.login();
  }

  goTo(name: string): string {
    let queryParams: any = {}
    for (let a = 0; a < this.navService.getActions().length; a++) {
      if (name == this.navService.getActions()[a]['name'])

        if (this.navService.getActions()[a].queryParams) queryParams = this.navService.getActions()[a].queryParams

      if (this.navService.getActions()[a].params) {
        this.router.navigate([
          this.navService.getActions()[a]['url'],
          this.navService.getActions()[a]['params'],
        ], { queryParams: queryParams });
      } else {
        this.router.navigate([this.navService.getActions()[a]['url']], { queryParams: queryParams });
      }
    }
    return '';
  }

  sessionLogout() {
    let user$ = this.userSessionService.getUser(this.user().email);

    let deleteSession$ = user$.pipe(
      switchMap((user) => {
        //console.log('user$ %o',user)
        let session = user[0].UsersActiveSessionsRel?.find(
          (session) => session.ipAddress === this.ip
        );
        return this.activeSessionService.delete(session!.id!);
      })
    );

    return deleteSession$;
  }

  logout() {
    let sessionLogout$ = this.sessionLogout();
    sessionLogout$.subscribe({
      next: (res) => {
        //console.log(res);
        this.userSessionService.saveState('working_company', null);
        this.authService.logout();
        let urlLogout =
          environment.cognitoEntryPoint +
          '/logout?client_id=' +
          environment.cognitoAppClientId +
          '&redirect_uri=' +
          environment.postLogoutRedirectUri;
        // console.log('LOGOUT navbar on %o', urlLogout);
        window.location.href = urlLogout;
      },
      error: (err) => {
        this.userSessionService.saveState('working_company', null);
        this.authService.logout();
        let urlLogout =
          environment.cognitoEntryPoint +
          '/logout?client_id=' +
          environment.cognitoAppClientId +
          '&redirect_uri=' +
          environment.postLogoutRedirectUri;
        // console.log('LOGOUT navbar on %o', urlLogout);
        window.location.href = urlLogout;
      },
    });
  }

  user() {
    //console.log('user %o', this.authService.user());
    return this.authService.user();
  }

  ngDoCheck() {
    if (this.companies == null || this.companies.length < 2) {
      this.getCompaniesStat();
    }
    if (
      this.isOperational == false &&
      this.isStaff == false &&
      this.isAdmin == false
    ) {
      if (!(this.userSessionService.getState('user') == null)) {
        for (
          let a = 0;
          a < this.userSessionService.getState('user').UsersProfilesRel.length;
          a++
        ) {
          //console.log('-----------------------------------> profile %o', this.userSessionService.getState('user').UsersProfilesRel[a].code)
          switch (
          this.userSessionService.getState('user').UsersProfilesRel[a].code
          ) {
            case 'SLS-TC':
              this.isOperational = true;
              break;
            case 'PRD-CO':
              this.isOperational = true;
              break;
            case 'PRD-RA':
              this.isStaff = true;
              break;
            case 'AFF':
              this.isStaff = true;
              break;
            case 'HQ-AM':
              this.isAdmin = true;
              break;
            case 'HQ-BRD':
              this.isAdmin = true;
              break;
            default:
              break;
          }
        }
      }
    }
  }

  getTooltip(id: any) {
    switch (id) {
      case 0:
        if (this.isOperational == true)
          return this.translate.instant('LABEL.PROFILE_OPERATIONAL');
        else return '';
      case 1:
        if (this.isStaff == true)
          return this.translate.instant('LABEL.PROFILE_STAFF');
        else return '';
      case 2:
        if (this.isAdmin == true)
          return this.translate.instant('LABEL.PROFILE_ADMIN');
        else return '';
      default:
        return '';
    }
  }

  getClassProfiles(id: any) {
    switch (id) {
      case 0:
        if (this.isOperational == true) return 'green material-icons';
        else return 'grey material-icons';
      case 1:
        if (this.isStaff == true) return 'green material-icons';
        else return 'grey material-icons';
      case 2:
        if (this.isAdmin == true) return 'green material-icons';
        else return 'grey material-icons';
      default:
        return 'grey material-icons';
    }
  }

  getCompanies() {
    let ret: any[] = [];
    let us = this.userSessionService.getState('user');

    ret.push({
      isOpen: true,
      name: '*',
      code: '*',
      id: 0,
    });

    if (!(us == null) && !(us.UsersCompaniesRel == null)) {
      // console.log('this.userSessionService.getState(user) %o', us.UsersCompaniesRel);
      for (let a = 0; a < us.UsersCompaniesRel.length; a++) {
        ret.push({
          isOpen: us.UsersCompaniesRel[a].isOpen,
          name: us.UsersCompaniesRel[a].name,
          code: us.UsersCompaniesRel[a].code,
          id: us.UsersCompaniesRel[a].id,
        });
      }
    }
    ret = ret.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return ret;
  }

  getCompaniesStat() {
    this.companies = [];
    let us = this.userSessionService.getState('user');

    this.companies.push({
      isOpen: true,
      name: '*',
      code: '*',
    });

    if (!(us == null) && !(us.UsersCompaniesRel == null)) {
      // console.log(
      //   'this.userSessionService.getState(user) %o',
      //   us.UsersCompaniesRel
      // );
      for (let a = 0; a < us.UsersCompaniesRel.length; a++) {
        this.companies.push({
          isOpen: us.UsersCompaniesRel[a].isOpen,
          name: us.UsersCompaniesRel[a].name,
          code: us.UsersCompaniesRel[a].code,
          id: us.UsersCompaniesRel[a].id,
          country: us.UsersCompaniesRel[a]?.country,
        });
      }
    }
    this.companies = this.companies.sort((a: any, b: any) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    this.filteredCompanies = this.companies;
  }

  setLanguage(lang: string) {
    //console.log('user %o', this.getCompanies());
    this.translate.use(lang);
    this.userSessionService.changeLanguage(lang).subscribe((response: any) => {
      //console.log(response)
      let locale = this.userSessionService.formatLanguageCode(response.locale);
      this.userSessionService.setCurrentLanguage(locale);
    });
  }

  downloadExportInPage() {
    if (this.downloadExportInPageIsActive()) {
      // se è presente almeno un dato avvia la generazione dell'export
      let actionParams = this.getAction('export_in_page'); // recupera le informazioni collegate all'action "export_in_page"
      let sheet = XLSX.utils.json_to_sheet(actionParams.items);
      XLSX.utils.sheet_add_aoa(sheet, [actionParams.header], { origin: 'A1' });
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, sheet, actionParams.sheetName);

      var bin = XLSX.writeFile(workbook, actionParams.fileName);
    } else {
      // altrimenti restituisce un messaggio di errore
      this.toastr.warning(
        this.translate.instant('TOASTR.MESSAGE47'),
        this.translate.instant('LABEL.Info')
      );
    }
  }

  downloadExportInPageIsActive() {
    let actionParams = this.getAction('export_in_page');
    return actionParams.items.length != 0;
  }

  reloadUserVisibility() {
    //console.log('reloadUserVisibility')
    this.libService.lockPage('');
    // Setta le actions per le visibilità
    this.userSessionService.setVisibilities().subscribe(
      (v) => {
        //console.log(v)
        this.libService.unlockPage();
      },
      (err) => {
        console.log(err);
        this.libService.unlockPage();
      }
    );
  }

  // getUser() {
  //   return this.oauthService.getIdentityClaims();
  //   // todo connect to db
  // }

  // isUserLogged() {
  //   return this.oauthService.checkSession();
  // }

  // login() {
  //   this.oauthService.initLoginFlow();
  // }

  // logout() {
  //   this.oauthService.logOut();
  // }
}
