import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class InspectionContactService {
  baseApi = environment.rootApiCrm + 'v1.0';
  constructor(public libService: LibService) { }

  getAllInspectionContacts(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, '/inspectionContacts');
  }
}
