import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef, Output, EventEmitter, Inject } from '@angular/core';
import { LibService } from '../../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UserSessionService } from '../../../services/user-session.service';
import { NavService } from '../../../services/nav.service';
import { TranslatorService } from '../../../services/translate.service';
import { MatPaginator } from '@angular/material/paginator';
import { ActionService } from '../../../services/action.service'
import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';
import { MarketingNoteService } from '../../../services/marketingNote.service';
import { EventService } from '../../../services/event.service';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import * as XLSX from 'xlsx';
import { EventExternalUserRegistrationService } from '../../../services/eventExternalUserRegistration.service';
import * as QRCode from 'qrcode';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-event-registrations',
  templateUrl: './event-registrations.component.html',
  styleUrls: ['./event-registrations.component.scss']
})

export class EventRegistrationsComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Output() reload = new EventEmitter;

  itemType = "marketing_notes"
  sectionName = "marketing-note-settings"
  dataSource: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  sort: MatSort

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('itemDialog') itemDialog: TemplateRef<any>
  @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  searchTimeout: any;

  marketingNoteList: any = [];

  events: any = []

  constructor(public navService: NavService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    public translate: TranslatorService,
    private matDialog: MatDialog,
    private actionService: ActionService,
    private marketingNoteService: MarketingNoteService,
    private eventService: EventService,
    private eventExternalUserRegistrationService: EventExternalUserRegistrationService,
  ) {
    this.dataSource = new MatTableDataSource();
  }


  displayedColumns = ['type', 'fullname', 'email', 'company', 'lunch', 'intoleranceDetails', 'createdAt', 'updatedAt', 'canceledAt', 'approvalStatus',];

  structure: any = {
    id: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
    name: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
  }



  columns: any = [
    {
      name: 'type',
      flex: '6',
      title: this.getTitle('type'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.type
    },
    {
      name: "fullname",
      title: this.getTitle('fullname'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => this.getFullname(item)
    },
    {
      name: "email",
      title: this.getTitle('email'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item?.user?.email
    },
    {
      name: "company",
      title: this.getTitle('company'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item?.company?.name
    },
    {
      name: "lunch",
      title: this.getTitle('lunch'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`, this.lunchClass(item)],
      value: (item: any) => item?.lunch ? 'Si' : 'No'
    },
    {
      name: "intoleranceDetails",
      title: this.getTitle('intoleranceDetails'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item?.intoleranceDetails
    },
    {
      name: "createdAt",
      title: this.getTitle('createdAt'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item?.createdAt
    },
    {
      name: "updatedAt",
      title: this.getTitle('updatedAt'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.updatedAt
    },
    {
      name: "canceledAt",
      title: this.getTitle('canceledAt'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.canceledAt
    },
    {
      name: "approvalStatus",
      title: this.getTitle('approvalStatus'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => this.getApprovalDate(item)
    }
  ]

  getFullname(item: any) {
    return `${item?.user?.name || ''} ${item?.user?.surname || ''}`
  }

  lunchClass(item: any) {
    return item?.lunch ? 'green' : 'red'
  }

  getApprovalDate(item: any) {
    if (item.canceledAt) return 'Registrazione Annullata'
    if (item.type == 'internal') return 'Non Richiede Approvazione'
    if (item.approvalDate) return 'Approvato'
    if (item.notApprovalDate) return 'Non Approvato'
    return 'In Attesa di Approvazione'
  }



  getTitle(type: any) {
    let title: any = this.translate.get('LABEL.NOT_PRESENT')
    switch (type) {
      case 'type':
        title = this.translate.get('LABEL.type')
        break;
      case 'fullname':
        title = this.translate.get('LABEL.fullname')
        break;
      case 'name':
      case 'surname':
        title = this.translate.get('LABEL.surname')
        break;
        title = this.translate.get('LABEL.email')
        break;
      case 'company':
        title = this.translate.get('LABEL.company')
        break;
      case 'createdAt':
        title = this.translate.get('LABEL.createdAt')
        break;
      case 'updatedAt':
        title = this.translate.get('LABEL.updatedAt')
        break;
      case 'canceledAt':
        title = this.translate.get('LABEL.canceledAt')
        break;
      case 'approvalStatus':
        title = this.translate.get('LABEL.approvalStatus')
        break;
      case 'lunch':
      case 'intoleranceDetails':
        title = this.translate.get('LABEL.intoleranceDetails')
        break;
      case 'qrcode':
        title = this.translate.get('LABEL.qrcode')
        break;

    }
  }


  getBooleanLabel(value: any) {
    if ([null, undefined].includes(value)) {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    } else {
      return value ? this.translate.instant('LABEL.YES') : this.translate.instant('LABEL.NO') as string
    }
  }



  actions: any = [];

  setRegistrationStatus(item: any, status: boolean) {
    if (item.type == 'external' && item.canceledAt == null) {
      let attributes = {}
      if (status) {
        attributes = {
          approvalDate: new Date(),
          notApprovalDate: null
        }
      } else {
        attributes = {
          approvalDate: null,
          notApprovalDate: new Date()
        }
      }

      this.eventExternalUserRegistrationService.approval(item.id, attributes).subscribe((responseData: any) => {
        //console.log(responseData)
        this.loadData();
      })
    }
  }

  confirmEntrance(element: any) {
    if (confirm("Sei sicuro di voler confermare l'ingresso?") == true) {
      let userId = element.UserId || element.ExternalUserId
      this.eventService.updateQrCodeUsageDate(userId, element.type, element.EventId).subscribe((responseData: any) => {
        console.log(responseData)
        this.loadData();
      });
    }
  }

  async openQrCodeDialog(element: any) {
    let qrcode = await this.generateQrcode(element)
    const dialogRef = this.matDialog.open(QrCodeDialogComponent, {
      width: '600px',
      data: { qrcode: qrcode }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Il dialog è stato chiuso');
    });
  }

  setUserActions() {

    // SHOW
    this.actions.push({
      label: of(''),
      cssClass: (item: any) => [item.type == 'internal' || item.canceledAt ? 'disabledButton' : 'greenButton'],
      icon: (item: any) => {
        return 'check_circle';
      },
      onClick: (item: any) => this.setRegistrationStatus(item, true),
      color: 'primary'
    })

    this.actions.push({
      label: of(''),
      cssClass: (item: any) => [item.type == 'internal' || item.canceledAt ? 'disabledButton' : 'redButton'],
      icon: (item: any) => {
        return 'cancel';
      },
      onClick: (item: any) => this.setRegistrationStatus(item, false),
      color: 'primary'
    })

    // Pulsante per aprire il QR code
    this.actions.push({
      label: of(''),
      cssClass: (item: any) => ['purpleButton'],
      icon: (item: any) => {
        return 'qr_code';
      },
      onClick: async (item: any) => this.openQrCodeDialog(item),
      color: 'primary'
    });

    // Pulsante per confermare l'ingresso
    this.actions.push({
      label: of(''),
      cssClass: (item: any) => ['blueButton'],
      icon: (item: any) => {
        return 'login';
      },
      onClick: (item: any) => this.confirmEntrance(item),
      color: 'primary'
    });

  }



  deleteItem(item: any) {

    if (confirm("Sei sicuro di voler cancellare l'Action?") == true) {
      this.libService.lockPage('');

      this.actionService.destroy(item.id).subscribe((responseData: any) => {
        console.log('Destroy Item response %o', responseData);
        this.loadData()
        this.libService.unlockPage();
      }, err => {
        console.log(err)
        this.libService.unlockPage();
      });
    }

  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }



  openItemDialog(item: any) {
    //this.mapsSocietyState(e);
    const dialogRef = this.matDialog.open(this.itemDialog, {
      panelClass: 'custom-dialog-container',
      width: '60%',
      scrollStrategy: new NoopScrollStrategy(),
    })
  }


  ngOnInit(): void {

    this.loadData();
    this.setUserActions();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      { name: 'new', url: 'actions/0' },
      //{ name: 'export', url: '' },
    ];
    this.navService.setActions(actions);

    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  search(e: any) {
    if (e) {
      this.dataSource = this.events.filter((item: any) => {
        return item?.type?.toLowerCase().includes(e.toLowerCase()) || item?.user?.name?.toLowerCase().includes(e.toLowerCase()) || item?.user?.surname?.toLowerCase().includes(e.toLowerCase()) || item?.user?.email?.toLowerCase().includes(e.toLowerCase()) || item?.company?.name?.toLowerCase().includes(e.toLowerCase()) || item?.company?.city?.toLowerCase().includes(e.toLowerCase())
      })
    }


    clearTimeout(this.searchTimeout)
    // this.searchTimeout = setTimeout(() => this.updateCompaniesData(), 1000)
  }



  loadData(): void {

    this.libService.lockPage('');

    let eventId = this.form?.get('id')?.value

    this.eventService.registrations(eventId).subscribe((responseData: any) => {
      //console.log('loadDataSets response %o', responseData);
      this.libService.unlockPage();
      let currentStructure: any = []

      this.events = responseData;
      this.dataSource = responseData;
    }, err => {
      console.log(err)
    });

  }

  async generateQrcode(item:any){
    let userId = item.UserId || item.ExternalUserId
    let key = `${item.EventId}_${item.type}_${userId}`
    let qrcode = await QRCode.toDataURL(key);
    return qrcode
  }

  goToPage(item: any) {
    this.router.navigate([`settings/${this.sectionName}/` + item.id]);
  }

  saveForm(attributes: any) {
    console.log(attributes)
  }

  filter(event: any) {
    console.log(event)
    let actions = this.marketingNoteList.filter((action: any) => action.TranslationCategoryId == event.value)
    this.dataSource = actions
  }

  downloadExport() {

    let exportStructure = this.dataSource.map((item: any) => {
      return {
        type: item.type,
        fullname: this.getFullname(item),
        email: item?.user?.email,
        company: item?.company?.name,
        lunch: item.lunch ? 'Si' : 'No',
        intoleranceDetails: item.intoleranceDetails,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        canceledAt: item.canceledAt,
        approvalStatus: this.getApprovalDate(item),
      }

    })

    let stampDate = new Date().getTime()
    let sheet = XLSX.utils.json_to_sheet(exportStructure);
    XLSX.utils.sheet_add_aoa(sheet, [this.displayedColumns], { origin: 'A1' });
    let workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, `EventRegistrations`);

    var bin = XLSX.writeFile(workbook, `${stampDate}_eventRegistrations.xlsx`);
  }

}

// Aggiungi un componente per il dialog del QR code
@Component({
  selector: 'qr-code-dialog',
  template: `
    <h1 mat-dialog-title>QR Code</h1>
    <div mat-dialog-content style="text-align:center;">
      <img style="width:500px;" [src]="data.qrcode" alt="QR Code">
    </div>
    <!--
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Chiudi</button>
    </div>
-->
  `,
})
export class QrCodeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<QrCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
