import { MatDialog } from '@angular/material/dialog';
import { Observable, forkJoin, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { CompanyService } from '../services/companyService';
import { LibService } from '../services/libService';
import { LicenceService } from '../services/licenseService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSessionService } from '../services/user-session.service';
import { NavService } from '../services/nav.service';
import { SocietyService } from '../services/societyService';
import { TranslatorService } from '../services/translate.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})

export class CompaniesComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  private onDestroy = new Subject<void>(); 

  displayedColumns = ['id', 'code', 'invoiceSystemCode','masterCode' ,'type', 'name', 'society', 'isOpen', 'status', 'nation', 'provincie', 'city', 'streetName', 'streetNumber', 'zip', 'vat', 'manageSoc', 'manageLicence', 'editAction', 'deleteAction', 'saveAction'];
  dataSource: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  sort: MatSort
  langChangeSubscription: any;
  previousLang: string;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }


  @ViewChild('societyDialog') societyDialog: TemplateRef<any>
  @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  dropdownListSocieties: any[] = [];
  societies: any[] = [];
  selectedSociety: any[] = [];

  dropdownListLicences: any[] = [];
  licences: any = [];
  selectedLicences: any[] = [];

  dropdownLicencesSettings: IDropdownSettings = {};

  dropdownSocietySettings: IDropdownSettings = {};
  activeCompany: any = {};

  searchTimeout: any;

  constructor(public navService: NavService,
    private societyService: SocietyService,
    private companyService: CompanyService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    public translate: TranslatorService,
    private toastr: ToastrService,
    private licenseService: LicenceService,
    private matDialog: MatDialog,
    public MatPaginatorIntl: MatPaginatorIntl) {
    this.dataSource = new MatTableDataSource();
    //this.profileService.getAllProfiles().subscribe(r => this.data = r);
  }

  testlogin() {
    /*
    let py = {
      js: {
        triggerSource: 'testTrigger',
        userPoolId: 'testPool',
        userName: 'testName',
        callerContext: { clientId: '12345' },
        response: {}
      },
      user: {
        name: 'myname 21',
        username: 'myusername 21',
        locale: 'it-IT',
        email: 'myemail 23'
      }
    };
    this.libService.postData(py,'https://albertfoundation.dune.it/users/login','').subscribe(t => {

      console.log('t after testlogin %o', t)
    });
    */

    let py = {
      "str": "1fc2889af1a2eb75d58ec532d025b3847ff8461c6c6b7e1cd897dc776c20bab3",
      "key": "199c2470cbdef654dderf477d907b1eu",
      "iv": "c9e466c7518362ee"
    };
    this.libService.postData(py, 'https://albertfoundation.dune.it/cr/decrypt', '').subscribe(t => {

      console.log('t after testlogin %o', t)
      });

  }

  loadLabels() {
    // this.translate.get('SIDEBAR.Sedi').subscribe(res => {
    //   this.title = res;
    //   this.subTitle = "EDAC - " + res;
    // })
    this.title = this.translate.instant('SIDEBAR.Sedi');
    this.subTitle = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  }

  setActiveCompany(e: any) {
    this.activeCompany = e;
  }

  selectAttributesLicense(e: any) {
    this.mapsLicenseState(e);
    const dialogRef = this.matDialog.open(this.licensesDialog, {
      panelClass: 'custom-dialog-container',
      width: '60%'
  })
  }
  selectAttributesSociety(e: any) {
    this.mapsSocietyState(e);
    const dialogRef = this.matDialog.open(this.societyDialog, {
      panelClass: 'custom-dialog-container',
      width: '60%'
  })
  }

  getSocieties() {
    let p: any[] = [];
    for (let a = 0; a < this.societies.length; a++) {
      p.push({ id: this.societies[a].id, name: this.societies[a].name });
    }
    return p;
  }

  getLicences() {
    let p: any[] = [];
    for (let a = 0; a < this.licences.length; a++) {
      p.push({ id: this.licences[a].id, code: this.licences[a].code });
    }
    return p;
  }

  saveCompany(company: any) {
    console.log('save Company %o activeUser %o', company, this.activeCompany);

    company.societies = this.selectedSociety.map(ds => {
      return ds.id
    });

    company.licences = this.selectedLicences.map(ds => {
      return ds.id
    });

    console.log('company for update %o', company);
    this.libService.lockPage('');
    this.companyService.setSociety(company).subscribe(r => {
      this.companyService.setLicences(company).subscribe(r => {
            this.libService.unlockPage();
            this.toastr.success(
          this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
      }, err => {
            this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_6') + err.message, this.translate.instant('TOASTR.ERROR'));
      });
      },
      err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_6') + err.message, this.translate.instant('TOASTR.ERROR'));
      });
  }

  noEdit(element: any) {
    // console.log('element %o activeUser %o', element, this.activeUser);
    if (element == null)
      return true;
    if (this.activeCompany == null)
      return true;
    if (this.activeCompany.id == element.id)
      return false;
    else
      return true;
  }

  mapsLicenseState(company: any) {
    this.selectedLicences = company.CompaniesLicencesRel.map((e: any) => {
      return { id: e.id, code: e.code }
    });
  }

  mapsSocietyState(company: any) {
    this.selectedSociety = company.CompaniesSocietiesRel.map((e: any) => {
      return { id: e.id, name: e.name }
    });
  }


  ngOnInit(): void {

    // Ascolta i cambiamenti di lingua
    this.setTranslationsItemsPerPageLabel();
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setTranslationsItemsPerPageLabel();
    });
    
    this.selectedSociety = [
    ];

    this.dropdownSocietySettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessuna società disponibile',
      allowSearchFilter: true
    };

    this.selectedLicences = [
    ];

    this.dropdownLicencesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      selectAllText: 'Seleziona Tutti',
      unSelectAllText: 'Deseleziona Tutti',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessuna licenza disponibile',
      allowSearchFilter: true
    };

    this.loadLabels();
    this.loadCompanies();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      { name: 'new', url: 'companies/0' },
      //{ name: 'export', url: '' },
    ];
    this.navService.setActions(actions);

    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

    this.libService.lockPage('');
    this.societyService.getAllSocieties().subscribe((response) => {
        console.log('loadSocieties response %o', response);
        if (response.length > 0) {
          this.societies = response;
          this.dropdownListSocieties = this.getSocieties();
        }
      this.licenseService.getAllLicense().subscribe((response2) => {
            console.log('loadLicences response %o', response2);
            if (response2.length > 0) {
              this.licences = response2;
              this.dropdownListLicences = this.getLicences();
            }
            this.libService.unlockPage();
      }, err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_8') + err.message, this.translate.instant('TOASTR.ERROR'));
      });
    },
      err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_7') + err.message, this.translate.instant('TOASTR.ERROR'));
      });

      
  }



  setTranslationsItemsPerPageLabel() {
    this.MatPaginatorIntl.itemsPerPageLabel = this.translate.instant('LABEL.ELEMENTI_PER_PAGINA');
    this.MatPaginatorIntl.nextPageLabel = this.translate.instant('LABEL.PROSSIMA_PAGINA');
    this.MatPaginatorIntl.previousPageLabel = this.translate.instant('LABEL.PAGINA_PRECEDENTE');
    this.MatPaginatorIntl.changes.next();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch });
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  goTo(e: any) {
    this.router.navigate(['companies/' + e.id]);
  }

  updateCompaniesData() {
    this.libService.lockPage('')
    const curr = this.paginator.pageIndex*this.paginator.pageSize
    const size = this.paginator.pageSize
    const observables = []
    const displayed = this.dataSource?.sortData(this.dataSource.filteredData, this.dataSource.sort)
    const fullData = this.dataSource.data
    const filter = this.dataSource.filter

    const indexesToFind = []
    for(let i=curr; i<curr+size; i++){
      indexesToFind.push(displayed[i]?.id)
    }


    const indexes: number[] = []
    indexesToFind.forEach(iToFind => {
      for(let i=0; i<fullData?.length; i++){
        if(iToFind == fullData[i].id){
          indexes.push(i)
          return
        }
      }
    })

    for(let i=0; i<size; i++){
      if(fullData[indexes[i]]?.id)
        observables.push(this.companyService.getCompany(fullData[indexes[i]].id))
    }
    if(observables.length == 0) this.libService.unlockPage()
    forkJoin(observables).subscribe(fullusers => {
      for(let i=0; i<size; i++){
        if(fullusers[i]) fullData[indexes[i]] = fullusers[i]
      }
      this.dataSource.data = fullData
      this.libService.unlockPage()
    })
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();

    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => this.updateCompaniesData(), 1000)
  }

  loadCompanies(): void {

    this.libService.lockPage('');
    this.companyService.getAllCompaniesFast().subscribe((response) => {
      console.log('loadDataSets response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        this.updateCompaniesData()
      } else {
        // for (const message in response.Messages) {
        //   this.libService.showMessageError(message);
        // }
      }
    });
  }

}
