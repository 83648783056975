import { Sidebar } from "@syncfusion/ej2-angular-navigations";

export const localeEs: any = {

    localeName: 'Es',
    localeDesc: 'Español (Es)',
    localeCode: 'es-ES',

    Object: {
        Yes: 'Sí',
        No: 'No',
        Cancel: 'Cancelar',
        Ok: 'OK',
        Week: 'Semana',
        newEvent: 'Nuevo Evento',
        Day: 'Día',
        Month: 'Mes',
        Year: 'Año',
        Agenda: 'Agenda'
    },

    TimePicker: {
        hour: 'Hora',
        minute: 'Minuto',
        second: 'Segundo'
    },

    List: {
        loading: 'Cargando...',
        selectAll: 'Seleccionar todo'
    },

    Popup: {
        close: 'Cerrar'
    },

    DateHelper: {
        locale: 'es-ES',
        weekStartDay: 1,
        nonWorkingDays: {
            0: true,
            6: true
        },
        weekends: {
            0: true,
            6: true
        },
        unitNames: [
            { single: 'milisegundo', plural: 'ms', abbrev: 'ms' },
            { single: 'segundo', plural: 'segundos', abbrev: 's' },
            { single: 'minutos', plural: 'minutos', abbrev: 'min' },
            { single: 'hora', plural: 'horas', abbrev: 'h' },
            { single: 'día', plural: 'días', abbrev: 'd' },
            { single: 'semana', plural: 'semanas', abbrev: 'w' },
            { single: 'mes', plural: 'meses', abbrev: 'mon' },
            { single: 'quarter', plural: 'quarters', abbrev: 'q' },
            { single: 'año', plural: 'años', abbrev: 'yr' },
            { single: 'década', plural: 'décadas', abbrev: 'dec' }
        ],
        unitAbbreviations: [
            ['mil'],
            ['s', 'sec'],
            ['m', 'min'],
            ['h', 'hr'],
            ['d'],
            ['w', 'wk'],
            ['mo', 'mon', 'mnt'],
            ['q', 'quar', 'qrt'],
            ['y', 'yr'],
            ['dec']
        ],
        parsers: {
            L: 'DD/MM/YYYY',
            LT: 'HH:mm A',
            LTS: 'HH:mm:ss A'
        },
    },

    CellMenu: {
        removeRow: 'Eliminar'
    },

    RowCopyPaste: {
        copyRecord: 'Copiar',
        cutRecord: 'Cortar',
        pasteRecord: 'Pegar',
        rows: 'líneas',
        row: 'línea'
    },

    CellCopyPaste: {
        copy: 'Copiar',
        cut: 'Cortar',
        paste: 'Pegar'
    },

    EventEdit: {
        Name: 'Nombre',
        Resource: 'Recursos',
        Start: 'Inicio',
        End: 'Fin',
        Save: 'Guardar',
        Delete: 'Eliminar',
        Cancel: 'Cancelar',
        'Edit event': 'Editar',
        Repeat: 'Repite'
    },

    SchedulerBase: {
        'Add event': 'Agregar',
        'Delete event': 'Eliminar',
        'Unassign event': 'Desasignar',
        color: 'Color'
    },

    TimeAxisHeaderMenu: {
        pickZoomLevel: 'Zoom',
        activeDateRange: 'Range Date',
        startText: 'Fecha de inicio',
        endText: 'Fecha de fin',
        todayText: 'Hoy'
    },

    EventCopyPaste: {
        copyEvent: 'Copiar',
        cutEvent: 'Cortar',
        pasteEvent: 'Pegar'
    },

    EventFilter: {
        filterEvents: 'Filtr',
        byName: 'por nombre',
    },

    PresetManager: {
        secondAndMinute: {
            displayDateFormat: 'll LTS',
            name: 'Segundos'
        },
        minuteAndHour: {
            topDateFormat: 'ddd DD/MM, hA',
            displayDateFormat: 'll LST'
        },
        hourAndDay: {
            topDateFormat: 'ddd DD/MM',
            middleDateFormat: 'LST',
            displayDateFormat: 'll LST',
            name: 'Día'
        },
        day: {
            name: 'Día/Horas'
        },
        week: {
            name: 'Mes/Horas'
        },
        dayAndWeek: {
            displayDateFormat: 'll LST',
            name: 'Mes/Días'
        },
        dayAndMonth: {
            name: 'Mes'
        },
        weekAndDay: {
            displayDateFormat: 'll LST',
            name: 'Semana'
        },
        weekAndMonth: {
            name: 'Semanas'
        },
        weekAndDayLetter: {
            name: 'Semanas/Fin de semana'
        },
        weekDateAndMonth: {
            name: 'Meses/Semanas'
        },
        monthAndYear: {
            name: 'Meses'
        },
        year: {
            name: 'Años'
        },
        manyYears: {
            name: 'Años múltiples'
        }
    },
    Calendar: {
        Today: 'Hoy',
        None: 'Ninguno',
        Week: 'Semana',
        Month: 'Mes',
        YEAR: 'Año',
        Yesterday: 'Ayer',
        Tomorrow: 'Mañana'
    }

}