
  <!-- <div class="search-wrapper mat-elevation-z2"> -->
  <form [formGroup]="form1">
  <!-- <div class="search" fxLayout="row" fxLayoutAlign="start "> -->
    <div *ngIf="(isLoading == true)"><mat-spinner  style="float:right;" diameter="25"></mat-spinner></div>

    <mat-form-field appearance="fill" class="form-activities" style="width: 100%; margin-left: auto; margin-right:auto;" >
      <!-- <mat-icon [ngClass]="{'animate' : true }">search</mat-icon> -->
      <mat-label>{{ 'LABEL.SEARCH' | translate }}</mat-label>
      <input matInput
      [placeholder]="translate.instant('FORM_FIELD.SEARCH')"
      [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="fulltextSearch($event)"
      [formControl]="myControl"
      [matAutocomplete]="auto"
      formControlName="campo1"
      [disabled]="disabled"

      >
    </mat-form-field>

    <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">

      <div>
        <mat-option *ngFor="let option of optionList " [value]="option" [disabled]="edit">
          <span *ngIf="(service.name == 'SubjectService' || service.name == 'FTIndexAllService') && !(option.name == null) && !(option?.surname == null)">{{option?.name + ' ' + option?.surname}} <b>{{option.type}}</b></span>
          <span *ngIf="(service.name == 'SubjectService' || service.name == 'FTIndexAllService') && !(option.name == null) && (option?.surname == null)">{{option?.name }} <b>{{option.type}}</b></span>
          <span *ngIf="(service.name == 'BuildingService' || service.name == 'FTIndexAllService') && !(option?.name == null) && (option?.surname == null)">{{option?.name}} <b>{{option?.type}}</b></span>
          <span *ngIf="(service.name == 'BuildingService' || service.name == 'FTIndexAllService') && (option?.name == null) && (option?.surname == null)">{{option?.streetName + ' ' + option?.streetNumber + ', ' + option?.city + ', ' + option?.state}} <b>{{option?.type}}</b></span>
          <span *ngIf="(service.name == 'CondominiumService' || service.name == 'FTIndexAllService') && (option?.name == null) && (option?.surname == null)">{{option?.streetName + ' ' + option?.streetNumber + ', ' + option?.city }} <b>{{option?.type}}</b></span>
          <span *ngIf="(service.name == 'OrderService')">{{option?.code}} <b>{{'LABEL.ORDER' | translate }}</b></span>
          <span *ngIf="(service.name == 'RequestService')">{{option?.id + ', ' + option.createdAt + ', ' + option.subject.name + ' ' + option.subject.surname + ', ' + option.assignmentOperator + ', (' + option.building.streetName + ', ' + option.building.streetNumber + ' ' + option.building.city + ') '}} <b>Order</b></span>

        </mat-option>
      </div>
      <div *ngIf="(noResults == true)">
        <mat-option disabled>
          <span><b>{{'LABEL.NoSearchResults' | translate}}</b></span>
        </mat-option>
      </div>
    </mat-autocomplete>

    <!-- <button style="background-color:transparent;" matTooltip="Clear" type="button" class="btn" (click)="clear()">
      <mat-icon class="material-icons" style="color:#293683;">clear</mat-icon>
    </button> -->

  <!-- </div> -->

</form>
<!-- </div> -->
