import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of, combineLatest, map, switchMap } from 'rxjs';
import { ComponentsFileUploadModalComponent } from '../modals/components-file-upload-modal/components-file-upload-modal.component';
import { Document, DocumentType } from 'src/app/models/document';
import { DocumentTemplateService } from 'src/app/services/document-template.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { ComponentService } from 'src/app/services/component.service';
import { DocumentService } from 'src/app/services/document.service';
import { PracticeService } from 'src/app/services/practice.service';

@Component({
  selector: 'app-practice-workflow-manager',
  templateUrl: './practice-workflow-manager.component.html',
  styleUrls: ['./practice-workflow-manager.component.scss']
})
export class PracticeWorkflowManagerComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() disableUpload: boolean;
  @Input() isNew: boolean;
  @Input() components: any;
  @Input() practice: any;
  @Input() order: any;

  attributes: any;

  componentName: any = 'practice_workflow_manager'

  documents: Document[];
  documentTypes: DocumentType[];
  documentTypes$: DocumentType[];
  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()
  items: any = []



  constructor(
    private dialog: MatDialog,
    private documentTypeService: DocumentTypeService,
    private documentTemplateService: DocumentTemplateService,
    private componentService: ComponentService,
    private documentService: DocumentService,
    private practiceService: PracticeService,
  ) { }

  ngOnInit(): void {

    this.getDocuments()

  }

  getDocuments(){
    if (this.practice?.id) {
      this.documentService.getPracticeDocuments(this.practice?.id).subscribe(
        (documents: any) => {
        this.documents = documents
        this.setItems()
        console.log(this.form)
        this.form?.get('documents')?.setValue(this.documents)
        console.log(this.form?.get('documents'))
      },
      (err:any)=>{
        console.log(err)
        this.setItems()
      })
    }
  }

  setItems() {
    this.setAttributes()
    if (this.attributes) {
      console.log('setItems')

      let ids: any = []
      let codes: any = []
      let structures = this.componentService.getObjectStructure(this.attributes)
      let documentList = this.attributes?.documentList
      console.log(documentList)
      ids = documentList.map((structure: any) => structure.documentTypeId)
      codes = documentList.map((structure: any) => structure.documentTypeCode)
      let tmpStructure: any = {}

      documentList.forEach((doc: any) => {
        let key = `document${doc.documentTypeCode}`
        if (!tmpStructure[key]) tmpStructure[key] = []
        tmpStructure[key].push(doc)
      })

      console.log(tmpStructure)
      let documentStructures = this.componentService.checkNecessaryDocumentsForPractices(this.documents, tmpStructure, this.form, this.order).structure
      console.log('documentStructures')
      console.log(structures)
      console.log(documentStructures)
      this.documentTypeService.getByCodes(codes).subscribe((response: any) => {
        this.items = []
        response.forEach((documentType: any) => {
          console.log(documentType)
        
          let currestDocumentStructure: any = documentStructures[`document${documentType.code}`]
          let tmpDocumentType = documentType
          if (currestDocumentStructure.hasOwnProperty('min')) tmpDocumentType.min = currestDocumentStructure.min
          if (currestDocumentStructure.hasOwnProperty('max')) tmpDocumentType.max = currestDocumentStructure.max
          if (currestDocumentStructure.hasOwnProperty('total')) tmpDocumentType.total = currestDocumentStructure.total
          if (currestDocumentStructure.hasOwnProperty('status')) tmpDocumentType.status = currestDocumentStructure.status
          if (currestDocumentStructure.hasOwnProperty('required')) tmpDocumentType.required = currestDocumentStructure.required
          this.items.push(tmpDocumentType)
        })
        console.log(this.items)
      })
      
    }

  }

  isDisabled() {
    if (this.form.get('status') == null || this.form.get('status')?.value == null || this.form.get('status')?.value.code == null)
      return true;
    if (this.form.get('status')?.value.code == 'NEW')
      return false;
    else
      return true;
  }

  getClassWorkflow(step: any) {
    if (!(this.form == null) && !(this.form.value == null) && !(this.form.value.status == null)) {
      let stepP = this.form.value.status.step;//ex 2 <- actual status
      //console.log('request status %o',this.form.value.status.step);

      if (step == stepP)
        return 'fs-6 fw-bold aln row-al mrg-top card-title';

      return 'fs-6 fw-bold text-muted aln row-al mrg-top card-title'
    }
    return '';

  }

  getClassIconWorkflow(step: any) {
    if (!(this.form == null) && !(this.form.value == null) && !(this.form.value.status == null)) {
      let stepF = this.form.value.status.step; //ex 2 <- actual status

      if (step < stepF) {
        return 'check-ok';
      }

      if (step == stepF)
        return 'check-warning';
    }
    return ''

  }

  public openUploadDialog(mode: 'new' | 'current') {
    if (mode === 'new') {
      this.dialog.open(ComponentsFileUploadModalComponent, {
        data: {
          practiceId: this.practice?.id,
          practiceCode: this.practice?.code,
          orderId: this.order?.id,
          orderVersion: this.order?.version,
          documentTypes: of(this.items),
          attributes: this.attributes,
          itemId: this.practice?.id,
          itemType: 'practiceId',
        }
      })
        .afterClosed()
        .subscribe((res: any) => {
          console.log('uploadDialogres: ', res);
          //this.subject.documents.push(res)
          if (res) {
            this.getDocuments()
          }
        })
    }
  }

  documentTypeSelected(documentType: DocumentType) {
    this.form.get('typeName')?.setValue(documentType);
    document.getElementById('file-input')!.click();
  }

  setDocumentTypes() {
    console.log('setDocumentTypes')
    if (this.isNew === false) {
      console.log('ping 1')
      console.log(this.form.get('status')?.value)
      combineLatest([this.form.get('status')!.valueChanges, this.globalDocumentTypes$]).pipe(
        map(([status, globalDocumentTypes]) => {
          console.log('ping 2')
          if (status && status.allowedDocumentTypes) {
            return [...status.allowedDocumentTypes, ...globalDocumentTypes]
          } else {
            return globalDocumentTypes
          }
        }),
        switchMap((documentTypes: DocumentType[]) => {
          console.log('ping 3')
          let documentTypesIds = documentTypes.map(documentType => documentType.id!)
          let societyId = this.form.get('building')?.get('companyLocation')!.value
          if (societyId !== "") {
            return combineLatest([
              this.documentTemplateService.getManyByDocumentTypeAndSociedyId(documentTypesIds, societyId),
              of(documentTypes)])
          } else {
            return combineLatest([
              of([]), of(documentTypes)
            ])
          }

        }),
        map(([documentTemplates, documentTypes]) => {
          console.log('ping 4')
          documentTypes = this.unique(documentTypes, ['code'])
          return documentTypes.map((documentType: any) => {
            return {
              ...documentType,
              documentTemplate: documentTemplates.find((documentTemplate: any) => documentTemplate.typeName === documentType.id)
            }
          })
        })
      ).subscribe((documentTypes: any) => {
        console.log('ping 5')
        console.log(documentTypes)
        this.documentTypes = documentTypes
        console.log("DOCUMENT TYPES --> ", this.documentTypes);
      })
    }
  }

  structureOfNecessaryDocuments() {
    //console.log('checkNecessaryDocuments - MAIN')
    let documentManagerAttributes = this.attributes
    let documentList = documentManagerAttributes?.documentList
    let tmpStructure: any = {}

    documentList.forEach((doc: any) => {
      let key = `document${doc.documentTypeCode}`
      if (!tmpStructure[key]) tmpStructure[key] = []
      tmpStructure[key].push(doc)
    })

    return this.componentService.checkNecessaryDocumentsForPractices(this.documents, tmpStructure, this.form, this.order).structure
  }

  getIcon(status: any) {
    return status ? 'done' : 'cancel'
  }

  getColor(status: any) {
    return status ? 'iconOK' : 'iconKO'
  }

  checkBuilding() {
    return ![undefined, null].includes(this.form.get('building')?.value?.id)
  }

  setAttributes() {
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) this.setAttributes()
    return status
  }


  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }
}
