import { Component, OnInit, ElementRef } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { ConfService } from '../../../services/conf.service';
import { CompanyService } from '../../../services/companyService';
import { ViewChild } from '@angular/core';
import { WikiService } from '../../../services/wikiservice.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { ActionService } from '../../../services/action.service';
import { ComponentService } from '../../../services/component.service';
import { ProfileService } from '../../../services/profileService';
import { ActionSettingService } from '../../../services/actionSetting.service';
import { StateService } from '../../../services/state.service';
import { ActionCategoryService } from '../../../services/actionCategory.service';
import { WorkflowService } from '../../../services/workflow.service'
import { DocumentTypeService } from '../../../services/document-type.service'
import { ReasonService } from '../../../services/reason.service'
import { StateCategoryService } from '../../../services/state-category.service'
import { BranchService } from '../../../services/branch.service'
import { TypologiesRelationshipsBetweenPracticeService } from '../../../services/typologiesRelationshipsBetweenPractice.service'
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-action-settings-form',
  templateUrl: './action-settings-form.component.html',
  styleUrls: ['./action-settings-form.component.scss']
})
export class ActionSettingsFormComponent implements OnInit {

  company: any;
  form: UntypedFormGroup;
  licenceForm: UntypedFormGroup;
  isNew: boolean = true;
  conf: any;
  id: any;
  actualLic: any = {};
  societies: any;
  licences: any;
  editingLicences: any;
  allSocieties: any;
  usersManager: any;
  usersAreaManager: any;
  allUsers: any;
  isEditingLicences: boolean = false
  allLicences: any
  hasSave: boolean = true

  mapZoom = 12;
  mapCenter: google.maps.LatLng;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
  };

  markerInfoContent = '';
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,
  };

  geocoderWorking = false;
  geolocationWorking = false;

  address: string;
  formattedAddress?: string | null = null;
  locationCoords?: google.maps.LatLng | null = null;

  typeGlobal: any = '';
  action: any = null;

  actionCategories: any = [];
  profiles: any = [];
  states: any = [];
  currentStates: any = [];
  currentStatesCopy: any = [];
  componentList: any = [];

  actionSettings: any = []
  workflows: any = []
  documentTypes: any = []
  reasons: any = []
  stateCategories: any = []
  typologiesRelationshipsBetweenPractices: any = []
  branches: any = []
  stateCategoriesRelationPractices: any = []

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public confService: ConfService,
    public translate: TranslatorService,
    private companyService: CompanyService,
    public wikiService: WikiService,
    private actionService: ActionService,
    private componentService: ComponentService,
    private profileService: ProfileService,
    private actionSettingService: ActionSettingService,
    private stateService: StateService,
    private actionCategoryService: ActionCategoryService,
    private router: Router,
    private workflowService: WorkflowService,
    private documentTypeService: DocumentTypeService,
    private reasonService: ReasonService,
    private stateCategoryService: StateCategoryService,
    private typologiesRelationshipsBetweenPracticeService: TypologiesRelationshipsBetweenPracticeService,
    private branchService: BranchService,
  ) {

    this.form = this.fb.group({
      id: [],
      name: [],
      keyFrontEnd: [],
      actionCategoryId: [],
      profileIds: [],
      stateIds: [],
      workflowIds: [],
      componentIds: [],
      components: this.fb.array([]),
      copyStateIds: [],
      copyName: [],
      copyKeyFrontEnd: [],
      copyActionCategoryId: [],
      copyWorkflow: []
    })
  }

  addComponentGroup(values: any): FormGroup {

    console.log('this.actionSettings')
    console.log(this.actionSettings)

    let attributesArray: any = []
    if (values.attributes) {
      values.attributes.forEach((attribute: any) => {
        attributesArray.push(this.addAttributeGroup(attribute))
      })
    }


    let component: FormGroup = this.fb.group({
      id: [values.id],
      code: [values.code],
      label: [values.label],
      attributes: this.fb.array(attributesArray)
    });

    return component

  }



  addAttributeGroup(values: any): FormGroup {
    let parametersArray: any = []
    let parametersArrayGroup: any = []
    let tmpGroups: any = {}
    let multiple = values.multiple || false

    if (values.parameters && !multiple) {
      values.parameters.forEach((parameter: any) => {
        parametersArray.push(this.addParameterGroup(parameter, values.id))
      })
    }

    if (values.parameters && multiple) {
      values.parameters.forEach((parameter: any) => {
        let tmpValue = this.actionSettings.filter((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.key == parameter.key && actionSetting.ComponentAttributeId == values.id)
        if (tmpValue) {
          tmpValue.forEach((row: any) => {
            if (!tmpGroups[row.groupCode]) tmpGroups[row.groupCode] = []

            tmpGroups[row.groupCode].push(this.addParameterGroupCode(parameter, row, row.groupCode))
          })
        } else {

        }

      })
    }

    Object.entries(tmpGroups).forEach(object => {
      const [key, value]: any = object;
      parametersArrayGroup.push(
        this.fb.group({
          codeGroup: key,
          parameters: this.fb.array(value),
        })
      )
    })

    let active = this.actionSettings.filter((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.ComponentAttributeId == values.id)

    return this.fb.group({
      id: [values.id],
      code: [values.code],
      label: [values.label],
      groupCodes: this.fb.array(parametersArrayGroup),
      parameterStructure: [values.parameters],
      parameters: this.fb.array(parametersArray),
      active: active?.length > 0,
      multiple: [multiple]
    });
  }

  addParameterGroupCode(values: any, value: any, groupCode: any = null): FormGroup {

    let tmpValue = value || null
    let tmpGroupCode = groupCode || values.groupCode
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
      groupCode: [tmpGroupCode]
    });
  }

  addParameterGroup(values: any, componetAttributeId: any): FormGroup {
    let tmpValue = this.actionSettings.find((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.key == values.key && actionSetting.ComponentAttributeId == componetAttributeId)
    tmpValue = tmpValue || null
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
    });
  }

  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }

  get components(): FormArray {
    return <FormArray>this.form.get('components');
  }

  addActionSettings(attribute: any) {
    console.log('addActionSettings');
    let groupCode = uuidv4();
    let parametersArray: any = []
    let parameterStructure = attribute.get('parameterStructure').value
    if (parameterStructure) {
      parameterStructure.forEach((parameter: any) => {
        let tmpStructure = this.addParameterGroupCode(parameter, null, groupCode)
        parametersArray.push(tmpStructure)
      })
    }


    attribute.get('groupCodes').push(this.fb.group({
      codeGroup: groupCode,
      parameters: this.fb.array(parametersArray),
    }))
    console.log(attribute)
  }

  componentAttributes(index: number): FormArray {
    return this.components.at(index).get("attributes") as FormArray
  }

  deleteLesson(lessonIndex: number) {
    this.components.removeAt(lessonIndex);
  }

  getStateCategories() {
    this.stateCategoryService.index().subscribe((response: any) => {
      this.stateCategories = response;
    });

  }

  getBranch() {
    this.branchService.getAllBranches().subscribe((res: any) => {
      this.branches = res.filter((branch: any) => branch.isActive)
    })
  }

  getTypologiesRelationshipsBetweenPractices() {
    this.typologiesRelationshipsBetweenPracticeService.index().subscribe((response: any) => {
      this.typologiesRelationshipsBetweenPractices = response;
    });

  }

  getReasons() {
    this.reasonService.index().subscribe((response: any) => {
      this.reasons = response;
    });

  }

  getDocumentTypes() {
    this.documentTypeService.getAll().subscribe((response: any) => {
      this.documentTypes = response;
    });

  }

  getWorkflows() {
    this.workflowService.index().subscribe((response: any) => {
      this.workflows = response;
    });

  }

  getActionCategories() {
    this.actionCategoryService.index().subscribe((response: any) => {
      this.actionCategories = response
    })
  }

  getProfiles() {
    this.profileService.getAllProfiles().subscribe((response: any) => {
      this.profiles = response
    })
  }

  getStates() {
    this.stateService.index().subscribe((response: any) => {
      this.states = response
      this.currentStates = response
    })
  }


  getComponentList() {
    this.componentService.index().subscribe((response: any) => {
      this.componentList = response
    })
  }

  onChangeStartWorkflowCode(event: any) {
    console.log(event)
    let currentWorkflow = this.workflows.find((workflow: any) => workflow.code == event.value)
    this.workflowService.getAvailableStatuses(currentWorkflow.id).subscribe((res: any) => {
      console.log('getWorkflowAvailableStatuses')
      console.log(res)
      this.stateCategoriesRelationPractices = res.filter((state: any) => state.disabled == false)
    })
  }

  setStructure(stateIds: any) {
    console.log(this.form)
    let structure: any = {
      id: this.form?.get('id')?.value,
      name: this.form?.get('name')?.value,
      isDeveloper: false,
      keyFrontEnd: this.form?.get('keyFrontEnd')?.value,
      ActionCategoryId: this.form?.get('actionCategoryId')?.value,
      profileIds: this.form?.get('profileIds')?.value,
      visibilities: [],
      actionSettings: []
    }

    // Setta visibilità
    let componentIds = this.form?.get('componentIds')?.value || []
    stateIds.forEach((stateId: any) => {
      componentIds.forEach((componentId: any) => {
        structure.visibilities.push({
          stateId: stateId,
          componentId: componentId
        })
      })
    })


    // Setta action settings solo componenti selezionati
    let components = this.form?.get('components')?.value
    let currentComponents = components.filter((component: any) => componentIds.includes(component.id))

    currentComponents.forEach((component: any) => {
      component.attributes.forEach((attribute: any) => {
        if (attribute.active) {
          console.log(attribute)

          if (!attribute.multiple) {
            attribute.parameters.forEach((parameter: any) => {
              structure.actionSettings.push({
                key: parameter.key,
                value: parameter.value,
                type: parameter.type,
                ComponentAttributeId: attribute.id
              })
            })
          } else {
            attribute.groupCodes.forEach((groupCode: any) => {
              groupCode.parameters.forEach((parameter: any) => {
                structure.actionSettings.push({
                  key: parameter.key,
                  value: parameter.value,
                  type: parameter.type,
                  ComponentAttributeId: attribute.id,
                  groupCode: parameter.groupCode
                })
              })
            })
          }

        }
      })
    })
    return structure
  }

  copy() {
    console.log('copy')
    let stateIds = this.form?.get('copyStateIds')?.value || []
    let structure = this.setStructure(stateIds);

    structure.ActionCategoryId = this.form?.get('copyActionCategoryId')?.value
    structure.id = null
    structure.keyFrontEnd = this.form?.get('copyKeyFrontEnd')?.value
    structure.name = this.form?.get('copyName')?.value
    console.log(structure)

    this.actionSettingService.createSettings(structure).subscribe((response: any) => {
      console.log(response)
      //this.id = response.id
      //this.form?.get('id')?.setValue(this.id)
      // this.router.navigate(['/settings/action-settings/', this.id]);
      this.toastr.success(
        response.id, this.translate.instant('LABEL.Info'));
      this.libService.unlockPage();
    })

  }

  update() {
    // this.libService.lockPage('');
    let stateIds = this.form?.get('stateIds')?.value || []
    let structure = this.setStructure(stateIds);
    console.log(structure)

    this.actionSettingService.createSettings(structure).subscribe((response: any) => {
      console.log(response)
      this.toastr.success(
        'ok', this.translate.instant('LABEL.Info'));

      this.id = response.id
      this.form?.get('id')?.setValue(this.id)
      this.router.navigate(['/settings/action-settings/', this.id]);
      this.libService.unlockPage();
    })



  }

  showCategory(id: any) {
    let componentIds: any = this.form?.get('componentIds')?.value || []
    return componentIds.includes(id)
  }

  back() {
    this.router.navigate(['settings/action-settings/']);
  }

  initComponents() {
    this.componentService.full().subscribe((response: any) => {
      console.log(response)
      response.forEach((component: any) => {
        this.components.push(this.addComponentGroup(component))
      })
      console.log(this.form)
      this.libService.unlockPage();
    })
  }

  ngOnInit(): void {
    this.libService.lockPage('');
    this.id = this.route.snapshot.paramMap.get('id');

    this.getTypologiesRelationshipsBetweenPractices();
    this.getStateCategories();
    this.getBranch();
    this.getReasons();
    this.getDocumentTypes();
    this.getWorkflows();
    this.getActionCategories()
    this.getProfiles()
    this.getStates()
    this.getComponentList()


    console.log('action settings')
    console.log(this.id)

    this.actionService.showWithSettings(this.id).subscribe((response: any) => {
      if (response) {
        this.action = response
        if (response?.settings) this.actionSettings = response?.settings
        this.form?.get('id')?.setValue(this.id)
        this.form?.get('name')?.setValue(this.action?.name)
        this.form?.get('keyFrontEnd')?.setValue(this.action?.keyFrontEnd)
        this.form?.get('actionCategoryId')?.setValue(this.action?.ActionCategoryId)
        this.form?.get('profileIds')?.setValue(this.action?.profiles.map((profile: any) => profile.id))

        let workflowIds: any = []
        let stateIds: any = []
        let componentIds: any = []

        this.action?.componentsStates.forEach((componentsState: any) => {
          let currentState = componentsState.state
          if (!workflowIds.includes(currentState.WorkflowId)) workflowIds.push(currentState.WorkflowId)
          if (!stateIds.includes(currentState.id)) stateIds.push(currentState.id)
          if (!componentIds.includes(componentsState.ComponentId)) componentIds.push(componentsState.ComponentId)
        })

        this.form?.get('workflowIds')?.setValue(workflowIds)
        this.form?.get('stateIds')?.setValue(stateIds)
        this.form?.get('componentIds')?.setValue(componentIds)

        this.currentStates = this.states.filter((state: any) => workflowIds.includes(state.WorkflowId))

        this.initComponents()


      } else {
        this.initComponents()
        this.libService.unlockPage();
      }
    })



  }

  onChangeWorkflow(event: any) {
    let ids = event.value
    this.currentStates = this.states.filter((state: any) => ids.includes(state.WorkflowId))
  }

  onChangeWorkflowCopy(event: any) {
    let id = event.value
    this.currentStatesCopy = this.states.filter((state: any) => state.WorkflowId == id)
  }


  setCode() {
    if ([undefined, null, 0, '0'].includes(this.id)) {
      let label = this.form?.get('name')?.value
      let code = `actions_${this.snakeCase(label)}`
      this.form?.get('keyFrontEnd')?.setValue(code)
    }
  }

  setCodeCopy() {
    let label = this.form?.get('copyName')?.value
    let code = `actions_${this.snakeCase(label)}`
    this.form?.get('copyKeyFrontEnd')?.setValue(code)
  }

  snakeCase(string: any) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word: any) => word.toLowerCase())
      .join('_');
  };

  getStatusLabel(stateId: any) {
    if (stateId !== null && this.stateCategories) {
      let currentState = this.stateCategories.find((stateCategory: any) => stateCategory?.states?.map((state: any) => state.id)?.includes(stateId))
      return this.translate.instant('STATECATEGORIES.' + currentState?.code) as string
    } else {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    }
  }

  getStatusDisabled(stateId: any) {
    if (stateId !== null && this.stateCategories) {
      let currentState = this.stateCategories.find((stateCategory: any) => stateCategory?.states?.map((state: any) => state.id)?.includes(stateId))
      return currentState?.disabled
    }
    return false
  }

  getRelationshipTypeLabel(relationshipType: any) {
    return this.translate.instant('TYPOLOGIESRELATIONSHIPSBETWEENPRACTICES.' + relationshipType?.code) as string
  }

  getBranchLabel(branch: any) {
    return `${this.translate.instant('ACTIVITIES.' + branch.code)} (${branch.codCountry})` as string
  }

  removeGroup(indexController: any, indexAttribute: any, indexGroup: any) {
    console.log(indexController)
    console.log(indexAttribute)
    console.log(indexGroup)
    console.log(this.components.controls[indexController])
    let currentController = this.components.controls[indexController]
    let currentAttributes: FormArray = currentController?.get('attributes') as FormArray
    let currentAttribute = currentAttributes.controls[indexAttribute]
    let currentGroups: FormArray = currentAttribute?.get('groupCodes') as FormArray
    currentGroups.removeAt(indexGroup)
  }



}

