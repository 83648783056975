import { Component, EventEmitter, Input, OnChanges, AfterViewInit, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-component-fields',
  templateUrl: './component-fields.component.html',
  styleUrls: ['./component-fields.component.scss']
})
export class ComponentFieldsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() index: number = 0;
  @Input() parameter: any;
  @Input() selectFields: any = {};

  buttonTypes: any = [
    'cancel',
    'migration',
    'next',
    'options',
    'restore'
  ]

  constructor(private translate: TranslateService) { }

  get components(): FormArray {
    return <FormArray>this.form.get('components');
  }

  ngOnInit(): void { };

  getStatusLabel(stateId: any) {
    if (stateId !== null && this.selectFields.stateCategories) {
      let currentState = this.selectFields.stateCategories.find((stateCategory: any) => stateCategory?.states?.map((state: any) => state.id)?.includes(stateId))
      return this.translate.instant('STATECATEGORIES.' + currentState?.code) as string
    } else {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    }
  }

  getRelationshipTypeLabel(relationshipType: any) {
    return this.translate.instant('TYPOLOGIESRELATIONSHIPSBETWEENPRACTICES.' + relationshipType?.code) as string
  }

}
