import { Component, OnInit,  TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, switchMap, take, map } from 'rxjs';
import { ComplaintService } from 'src/app/services/complaint.service';
import { ReviewService } from 'src/app/services/review.service';
import { LibService } from 'src/app/services/libService';
import { SubjectService } from 'src/app/services/subject.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { DatatableAction } from 'src/app/shared/data-table/data-table.component';
import { BehaviorSubject, of } from 'rxjs';

import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { DocumentsGridComponent } from 'src/app/shared/documents-grid/documents-grid.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { ModalFormComponent } from 'src/app//forms/modal-form/modal-form.component';

export class DatatableColumn<T> {
  name: string;
  title: Observable<string>;
  type?: 'text' | 'link' | 'icon' | 'templateRef' = 'text';
  flex?: string;
  sticky?: boolean;
  color?: (element: T) => 'primary' | 'warn' | 'error';
  cssClass?: (element: T) => string[];
  sortDisabled?: boolean;
  routerLink?: (element: T) => string[];
  value(element: T): any {
    return null;
  }
  templateRef?: (element: T) => Observable<TemplateRef<any>>;
  tooltip?: (element: T) => Observable<String>;

  constructor(column: Partial<DatatableColumn<T>>) {
    Object.assign(this, column);
  }
}

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  search: any;

  items: any;
  allItems: any;

  whileEditing: boolean = false;
  statuses: any;
  upStatus: boolean;
  disableUpload: boolean = false;

  documentTypes: any = []

  items$ = this.reviewService.allWithPagination();

  private timeout?: number;


  dataSource: any;

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;

  filterOptions: any = {};
  currentPage = 0;
  companyIdsFilter: any = []
  responsiblesFilter: any = []
  idsFilter: any = []
  orderOptions: any = {}
  allGlobalDocumentTypes: any = []

  test: any = []

  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

  actions: DatatableAction<any>[] = [
    {
      label: of(''),
      icon: item => {
        return 'edit'
      },
      onClick: item => this.openEditDialog(item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'upload_file'
      },
      onClick: item => this.openUploadDialog(item),
      color: 'primary'
    },
    {
      label: of(''),
      icon: item => {
        return 'folder'
      },
      onClick: item => this.viewDocuments(item?.documents),
      color: 'primary'
    },
    /*
    {
      label: of(''),
      icon: subject => {
        return 'delete'
      }, // 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    }
    */
  ];

  public openEditDialog(item: any) {

    this.dialog.open(ModalFormComponent, {
      data: {
        type: 'reviews',
        item: item,
      },
      width: '70%',
      scrollStrategy: new NoopScrollStrategy(),
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        if (res) {
          this.loadDataSource()
        }
      })
  }

  public openUploadDialog(item: any) {

    this.documentTypes = [...this.allGlobalDocumentTypes]

    this.dialog.open(FileUploadModalComponent, {
      data: {
        inputMode: 'reviews',
        toEntityId: item.id,
        documentTypes: of(this.documentTypes)
      },
      scrollStrategy: new NoopScrollStrategy(),
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        if (res) {
          this.loadDataSource()
        }
      })

  }

  public viewDocuments(documents: any) {
    console.log("DOCUMENTI --> ", documents)
    if (documents.length > 0) {
      let docs = this.dialog.open(DocumentsGridComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });
      let instance = docs.componentInstance;
      instance.documents = documents;
    } else {
      /*
      this.dialog.open(SimpleDialogComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });
      */

    }
    // console.log("DOCUMENTS INSIDE -->", );
  }

  displayedColumns: string[] = [
    'id',
    'practiceId',
    'esit',
    'reviewEsitType',
    'description',
    'createdAt',
    'updatedAt',
    'origin',
    'subject',
  ];

  columns: DatatableColumn<any>[] = [
    {
      name: "id",
      flex: '7',
      title: this.translate.get('LABEL.ID'),
      cssClass: item => ['column-padding'],
      value: item => item.id
    },
    {
      name: "practiceId",
      title: this.translate.get('REVIEWS.practice'),
      cssClass: item => ['column-padding'],
      value: item => item?.practice?.code
    },
    {
      name: "esit",
      title: this.translate.get('REVIEWS.esit'),
      cssClass: item => ['column-padding'],
      value: item => item.esit ? 'Positivo' : 'Negativo',
    },
    {
      name: "reviewEsitType",
      title: this.translate.get('REVIEWS.reason'),
      cssClass: item => ['column-padding'],
      value: item => item.reviewEsitType?.label,
    },
    {
      name: "description",
      title: this.translate.get('REVIEWS.description'),
      cssClass: item => ['column-padding'],
      value: item => item.description as string,
    },
    {
      name: "origin",
      title: this.translate.get('REVIEWS.origin'),
      cssClass: item => ['column-padding'],
      value: item => item.origin?.origin as string,
    },
    {
      name: "subject",
      title: this.translate.get('REVIEWS.subject'),
      cssClass: item => ['column-padding'],
      value: item => this.getSubjectName(item),
    },
    {
      name: "createdAt",
      title: this.translate.get('LABEL.CREATION_DATE'),
      cssClass: item => ['column-padding'],
      value: item => item.createdAt as string,
    },
    {
      name: "updatedAt",
      title: this.translate.get('LABEL.UPDATE_DATE'),
      cssClass: item => ['column-padding'],
      value: item => item.updatedAt as string,
    },
  ]

  getSubjectName(item: any) {
    let value = '';
    if (item?.subject) value = `${item?.subject?.name ? item?.subject?.name : ''} ${item?.subject?.surname ? item?.subject?.surname : ''}`
    if (item?.subjectAlias) value = item?.subjectAlias + ' (Alias)'
    return value;
  }

  constructor(private libService: LibService,
    public complaintService: ComplaintService,
    public subjectService: SubjectService,
    private router: Router,
    public translate: TranslateService,
    private dialog: MatDialog,
    private documentTypeService: DocumentTypeService,
    private reviewService: ReviewService,
  ) {}

  loadDataSource(reset: boolean = false) {
    console.log('loadDataSource')
    this.libService.lockPage('');

    /*
      if (reset) {
        this.resetOrder()
        this.resetPagination()
        this.filterOptions.ids = []
      }
        */

    this.reviewService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      this.items = responseData.items;
      this.allItems = responseData.items;
      this.totalRows = responseData.count;
      this.libService.resetLockPage();
    }, (err: any) => {
      // this.lockrequest = false;
      this.libService.resetLockPage();
      //this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });

  }

  goTo(e: any) {
    this.router.navigate(['request/' + e.id]);
  }

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }

  ngOnInit(): void {
    this.libService.lockPage('');
    combineLatest([this.items$, this.globalDocumentTypes$]).subscribe({
      next: ([items, globalDocumentTypes]) => {
        this.libService.unlockPage();
        this.items = items.items;
        this.allItems = items.items;
        this.totalRows = items.count;


        this.allGlobalDocumentTypes = globalDocumentTypes;
        this.items.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return b.updatedAt.localeCompare(a.updatedAt);
        });
        this.allItems.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return b.updatedAt.localeCompare(a.updatedAt);
        });
        console.log("STATUSESSSSSS --> ", this.statuses);
      },
      error: (error) => {
        this.libService.showMessageError(error.message);
      }
    })
  }


  onFilter(): void {
    window.clearTimeout(this.timeout);



    if (!this.search || this.search.length == 0) {
      this.items = this.allItems
      this.filterOptions.search = null

    } else {
      this.filterOptions.search = this.search
    }

    if (this.search.length < 2 && this.search.length != 0) return

    this.timeout = window.setTimeout(() => this.loadDataSource(), 500);
  }

}
