
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class DocumentWorkflowService  {

  baseApi = environment.rootApiPaperwork + 'orderStatus';
  ordersApi = environment.rootApiPaperwork;

  constructor(public libService: LibService) { }

  getDocWorkflow(id: string) {
    return this.libService.getData<any>(this.baseApi, `/docchanges/${id}`);
  }

  getAllDocWorkflow() {
    return this.libService.getData<any>(this.baseApi, `/docchanges`);
  }


  getByStatusId(account: string, idStatus: any) {

    let py = {
      user:account,
      id:idStatus
    };
    return this.libService.postData<any>(py, this.ordersApi, 'orders/getdocumentsfromstatusid');
  }

}