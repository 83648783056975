<div class="container-fluid my-container">
  <div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
      [placeholder]="translate.instant('LABEL.SEARCH')" />
    </div>
  </div>
  <div style="overflow-x: auto;">
  <table mat-table [dataSource]="dataSource" matSort style="width:100%">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.ID' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element">
        {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.NAME' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.name}}</b></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="dashId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.DESCRIPTION' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element">
        {{element.dashId}} </td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CARDINALITY' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.country}}</b></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CREATION_DATE' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LASTMODIFIED_DATE' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="editAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="goTo(element)"><i class="material-icons ic">edit</i>
    </ng-container>

    <ng-container matColumnDef="deleteAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"><i class="material-icons ic">delete</i>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  </div>
  <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
  </mat-paginator>
</div>
