import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { LogService } from 'src/app/services/log.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-users-logs',
  templateUrl: './users-logs.component.html',
  styleUrls: ['./users-logs.component.scss']
})
export class UsersLogsComponent implements OnInit {
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  faFileArrowDown = faFileArrowDown
  fileName = this.data.email + '-logs.xlsx'

  dataSource = new MatTableDataSource();
  displayedColumns = ['id', 'timestamp', 'action', 'response'];
  options: any = {
    search: {
      userEmail: this.data.email
    }
  }

  dateForm: UntypedFormGroup

  logs$ = this.logService.find(this.options)
  logSub: Subscription


  constructor(
    public dialogRef: MatDialogRef<UsersLogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private logService: LogService,
    private fb: UntypedFormBuilder
  ) {
    this.dateForm = this.fb.group({
      from: '',
      to: ''
    });
    this.dataSource = new MatTableDataSource();
   }


  ngOnInit(): void {


    this.logSub = this.logs$.subscribe(res => {
      this.dataSource.data = res

      this.dataSource.data.map( (val:any) =>
        {
          //console.log("PATH --> ", JSON.parse(val.action))
          val.action = JSON.parse(val.action);
        })

      //console.log("RES --> ", res) --> ARRIVA COME ARRAY DI JSON
    })

  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }


  submitSearch(){
    let fromDate = moment(this.dateForm.get('from')!.value).toDate()
    let toDate = moment(this.dateForm.get('to')!.value).toDate()

   /*  this.options.fromDate = fromDate
    this.options.toDate = toDate? toDate: null */
    if(fromDate){
      this.options.search.timestamp = {
        ...this.options.search.timestamp,
        $gte: fromDate
      }
    }
    if(toDate){
      this.options.search.timestamp = {
        ...this.options.search.timestamp,
        $lte: toDate
      }
    }
   /*  this.options.search.timestamp = {
      $gte: fromDate,
      $lte: toDate
    } */

    /* this.options.where.timestamp = {
      $gt: fromDate
    } */

    this.logService.find(this.options).subscribe(res => {
      this.dataSource.data = res;
    })
  }

  downloadLogs(){
    let sheet = XLSX.utils.json_to_sheet(this.dataSource.data)
    let workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, sheet)

    var bin = XLSX.writeFile(workbook, this.fileName)
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
