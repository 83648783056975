import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { catchError, map } from 'rxjs/operators';
import { plainToClassFromExist } from 'class-transformer';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Moment } from 'moment';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Log } from '../models/log';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LibService {
  @BlockUI() blockUI!: NgBlockUI;
  adminLock: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    public authService: AuthService,
    public translate: TranslateService,
    private auth: AuthService
  ) {}

  handleError = (error: HttpErrorResponse) => {
    console.log(error)
    //console.log(error.error instanceof ErrorEvent)
    if (error.error instanceof ErrorEvent) {
      this.showMessageError('An error occurred:' + error.error.message);
    } else {
      if (error.status !== 401) {
        let message = error.error?.message;
        if ([undefined, null].includes(message)) message = error?.error?.error;
        if (!(message instanceof String)) message = error?.error?.error?.message;
        this.showMessageError(
          'Backend returned code ' +
            error.status +
            ' - ' +
            error.statusText +
            '.\n Message: ' +
            message
        );
      } else {
        this.showMessageError('Session expired');
        this.authService.logout();
      }
    }
    return throwError('Something bad happened; please try again later.');
  };

  showMessageError = (parMessage: string, parMessages: string[] = []) => {
    this.toastr.error(`${parMessage}`, this.translate.instant('TOASTR.ERROR'));
    // message
    this.unlockPage();
  };

  readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!file) {
        resolve('');
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const text = reader.result?.toString();
        resolve(text!);
      };

      reader.readAsText(file);
    });
  }

  lockPageAdmin = (parMessage: string) => {
    this.adminLock = true;
    // console.log('lockPageAdmin');
    setTimeout(() => {
      this.blockUI.start(parMessage);
    });
  };

  lockPage = (parMessage: string) => {
    // console.log('lockPage');
    setTimeout(() => {
      this.blockUI.start(parMessage);
    });
  };

  unlockPage = () => {
    if (this.adminLock === false) {
      // console.log('unlockPage');
      setTimeout(() => {
        this.blockUI.stop();
      });
    }
  };

  resetLockPage = () => {
    if (this.adminLock === false) {
      // console.log('unlockPage');
      setTimeout(() => {
        this.blockUI.reset();
      });
    }
  };

  postData = <T>(
    parRequest: any,
    parBaseUrl: string,
    parPath: string
  ): Observable<T> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-user-email': this.auth.user()?.email,
      }),
    };
    return this.http
      .post<T>(parBaseUrl + parPath, parRequest, httpOptions)
      .pipe(
        map((response: T) => plainToClassFromExist(response, response)),
        /* tap(response => {
        this.writeLog('POST', parBaseUrl + parPath, parRequest, 'OK')
      }), */
      catchError(error => {
        // this.writeLog('POST', parBaseUrl + parPath, parRequest, error.status)
        return this.handleError(error)
      })
    );
  }

  postDataWithAccessToken = <T>(
    accesstoken: string,
    parRequest: any,
    parBaseUrl: string,
    parPath: string
  ): Observable<T> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-user-email': this.auth.user()?.email,
        accesstoken: accesstoken,
      }),
    };
    return this.http
      .post<T>(parBaseUrl + parPath, parRequest, httpOptions)
      .pipe(
        map((response: T) => plainToClassFromExist(response, response)),
        /* tap(response => {
        this.writeLog('POST', parBaseUrl + parPath, parRequest, 'OK')
      }), */
        catchError((error) => {
          // this.writeLog('POST', parBaseUrl + parPath, parRequest, error.status)
          return this.handleError(error);
        })
      );
  };

  postDataWithOptions = (
    parBaseUrl: string,
    parPath: string,
    parAuth: any
  ): Observable<any> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': 'Basic ' + btoa(parAuth)
        Authorization: 'Basic ZHVuZWdyb3VwOmlBRkdsMHlzQTcxcHomS0Y=',
      }),
    };
    //return this.http.get<any>(parBaseUrl + parPath,{ username: 'dunegroup', password: 'iAFGl0ysA71pz&KF'});
    return this.http.post<any>(parBaseUrl + parPath, {}, httpOptions);
  };

  putData = <T>(
    parRequest: any,
    parBaseUrl: string,
    parPath: string
  ): Observable<T> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-user-email': this.auth.user()?.email,
      }),
    };
    return this.http.put<T>(parBaseUrl + parPath, parRequest, httpOptions).pipe(
      map((response: T) => plainToClassFromExist(response, response)),
      /* tap(response => {
        this.writeLog('PUT', parBaseUrl + parPath, parRequest, 'OK')
      }), */
      catchError((error) => {
        // this.writeLog('PUT', parBaseUrl + parPath, parRequest, error.status)
        return this.handleError(error);
      })
    );
  };

  putDataWithAccessToken = <T>(
    accesstoken: string,
    parRequest: any,
    parBaseUrl: string,
    parPath: string
  ): Observable<T> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-user-email': this.auth.user()?.email,
        accesstoken: accesstoken,
      }),
    };
    return this.http.put<T>(parBaseUrl + parPath, parRequest, httpOptions).pipe(
      map((response: T) => plainToClassFromExist(response, response)),
      /* tap(response => {
        this.writeLog('PUT', parBaseUrl + parPath, parRequest, 'OK')
      }), */
      catchError((error) => {
        // this.writeLog('PUT', parBaseUrl + parPath, parRequest, error.status)
        return this.handleError(error);
      })
    );
  };

  deleteData = <T>(
    parRequest: any,
    parBaseUrl: string,
    parPath: string
  ): Observable<T> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-user-email': this.auth.user()?.email,
      }),
      body: parRequest,
    };
    return this.http.delete<T>(parBaseUrl + parPath, httpOptions).pipe(
      map((response: any) => plainToClassFromExist(response, response)),
      /* tap(response => {
        this.writeLog('DELETE', parBaseUrl + parPath, parRequest, 'OK')
      }), */
      catchError((error) => {
        // this.writeLog('DELETE', parBaseUrl + parPath, parRequest, error.status)
        return this.handleError(error);
      })
    );
  };

  deleteDataWithAccessToken = <T>(
    accesstoken: string,
    parRequest: any,
    parBaseUrl: string,
    parPath: string
  ): Observable<T> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-user-email': this.auth.user()?.email,
        accesstoken: accesstoken,
      }),
    };
    return this.http.delete<T>(parBaseUrl + parPath, httpOptions).pipe(
      map((response: any) => plainToClassFromExist(response, response)),
      /* tap(response => {
        this.writeLog('DELETE', parBaseUrl + parPath, parRequest, 'OK')
      }), */
      catchError((error) => {
        // this.writeLog('DELETE', parBaseUrl + parPath, parRequest, error.status)
        return this.handleError(error);
      })
    );
  };

  getData = <T>(parBaseUrl: string, parPath: string): Observable<T> => {
    return this.http.get<T>(parBaseUrl + parPath).pipe(
      map((response: T) => plainToClassFromExist(response, response)),
      /* tap(response => {
        if (!(parBaseUrl.toLowerCase().indexOf('foundation') > 0 || parBaseUrl.toLowerCase().indexOf('foundation') == 0) || !(parPath.toLowerCase().indexOf('foundation') > 0 || parPath.toLowerCase().indexOf('foundation') == 0))
          this.writeLog('GET', parBaseUrl + parPath, null, 'OK')
      }), */
      catchError((error) => {
        // if (!(parBaseUrl.toLowerCase().indexOf('foundation') > 0 || parBaseUrl.toLowerCase().indexOf('foundation') == 0) || !(parPath.toLowerCase().indexOf('foundation') > 0 || parPath.toLowerCase().indexOf('foundation') == 0))
        // this.writeLog('GET', parBaseUrl + parPath, null, error.status)
        return this.handleError(error);
      })
    );
  };

  getDataWithAccessToken = <T>(
    accesstoken: string,
    parBaseUrl: string,
    parPath: string
  ): Observable<T> => {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-user-email': this.auth.user()?.email,
        accesstoken: accesstoken,
      }),
    };
    return this.http.get<T>(parBaseUrl + parPath, httpOptions).pipe(
      map((response: T) => plainToClassFromExist(response, response)),
      catchError((error) => {
        return this.handleError(error);
      })
    );
  };

  navigateTo = (parPath: string) => {
    this.router.navigate([parPath]);
  };

  paginate = (parArray: [any], pageSize: number, pageNumber: number) => {
    --pageNumber;
    return {
      array: parArray.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize),
      pages: Math.ceil(parArray.length / pageSize),
    };
  };

  confirm = (parMessage: string) => {
    return self.confirm(parMessage);
  };

  cloneObject<T>(parObject: any): T {
    return JSON.parse(JSON.stringify(parObject));
  }

  getlocalStorage = (parKey: string, parDefault: any) => {
    const result = localStorage.getItem(parKey) || parDefault;
    return result;
  };

  setlocalStorageItem(key: string, item: any): void {
    if (item) {
      localStorage.setItem(key, JSON.stringify(item));
    } else {
      localStorage.removeItem(key);
    }
  }

  getlocalStorageItem(key: string): any {
    let result = null;
    const data: any = localStorage.getItem(key);
    if (!data) {
      return null;
    }
    try {
      result = JSON.parse(data);
    } catch (error) {
      console.error(error);
      result = null;
    }
    return result;
  }

  getLanguage = (parLanguageKeyStorage: string, parDefaultLanguage: string) => {
    const language =
      localStorage.getItem(parLanguageKeyStorage) || parDefaultLanguage;
    return language;
  };

  getLocale = (parLocaleKeyStorage: string, parDefaultLocale: string) => {
    const locale =
      localStorage.getItem(parLocaleKeyStorage) || parDefaultLocale;
    return locale;
  };

  setLanguage = (
    parLanguageKeyStorage: string,
    parLocaleKeyStorage: string,
    parLanguage: any
  ) => {
    localStorage.setItem(parLanguageKeyStorage, parLanguage.Lng);
    localStorage.setItem(parLocaleKeyStorage, parLanguage.Loc);
    window.location.reload();
  };

  timeToMoment(parTime: string, parFormat: string = 'HH:mm:ss'): Moment {
    return moment.utc(parTime, parFormat);
  }
  timeToDateIso(parTime: string, parFormat: string = 'HH:mm:ss'): string {
    return moment.utc(parTime, parFormat).format('YYYY-MM-DDTHH:mm:ss');
  }

  timeToDate(parTime: string, parFormat: string = 'HH:mm:ss'): string {
    return moment(parTime, parFormat).format('YYYY-MM-DDTHH:mm:ss');
  }

  getDaysDelta(date: Date, compared: Date) {
    return moment(date).diff(compared, 'days');
  }

  getAge(parDate: any): number {
    return moment().diff(parDate, 'years', false);
  }

  writeLog(method: string, path: string, request?: any, response?: any) {
    let action = {
      method: method,
      path: path,
      params: request,
    };
    let uname = 'not logged';
    if (this.authService.user()) uname = this.authService.user().name;
    let log: Log = {
      userEmail: uname,
      timestamp: new Date(),
      action: JSON.stringify(action),
      response: response,
    };

    const baseApiUrl = environment.rootApiLog + 'log';

    /* this.http.post<any>(baseApiUrl + '', log).subscribe(res => {
      // ?
    }) */
  }
}
