import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-orders-endorsement',
  templateUrl: './orders-endorsement.component.html',
  styleUrls: ['./orders-endorsement.component.scss']
})
export class OrdersEndorsementComponent implements OnInit {
  ss:any;
  user:any;
  sended: boolean = false;
  certurl: SafeResourceUrl;
  title = "Certificati";
  subTitle = "EDAC - Certificati";
  innerHeight: any;
  constructor(public navService: NavService, public translate: TranslatorService, private authService: AuthService) { }


  ngDoCheck() {

    if (this.sended == false) {
      var frame: HTMLIFrameElement = document.getElementById('OrdersEndorsementIframe') as HTMLIFrameElement;
      if (frame != null) {
        this.sended = true;
        frame.contentWindow?.postMessage('sstorage', this.ss);
        this.certurl = environment.rootOrdersEndorsement + this.user.sub;
      }
    }

  }

  loadLabels(){
    this.translate.get('LABEL.ORDERSENDORSEMENT').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.ss = window.sessionStorage;
    this.user =  this.authService.user();
    console.log('send message to iframe %o',this.ss,this.user);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
    ];
    this.navService.setActions(actions);
    this.innerHeight = (window.innerHeight - 74) + 'px';
  }

}


