import { Component, Input, OnInit, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Document } from '../../models/document';
import { DocumentService } from '../../services/document.service';
import { Observable, combineLatest } from 'rxjs'
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-documents-grid',
  templateUrl: './documents-grid.component.html',
  styleUrls: ['./documents-grid.component.scss']
})
export class DocumentsGridComponent implements OnInit, OnChanges {

  @Input() documents: Document[]
  @ViewChild('container') container: ElementRef;

  testUrl: string;
  title: boolean;

  constructor(
    private dialog: MatDialog,
    private documentService: DocumentService,
    private toastr: ToastrService,
    public translate: TranslateService,
    public fb: UntypedFormBuilder
  )
  {

  }

  ngOnInit(): void {
    // this.formTitle.valueChanges.subscribe(val => {
    //   this.title !== null && undefined?
    //     this.container.nativeElement.innerHTML  = `<h1>${this.translate.instant('LABEL.Documenti')}</h1`: undefined;
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['documents'] && changes['documents'].currentValue !== changes['documents'].previousValue) {
      if (!(this.documents == null) && !(this.documents[1] == null) && !(this.documents[1].awsPath == null)) {
        this.documentService.getSignedUrl(this.documents[1].awsPath!).subscribe(signedUrl => {
          this.testUrl = signedUrl
        })
        this.documents = this.documents.map(document => {
          return {
            ...document,
            sourceUrl: this.documentService.getSignedUrl(document.awsPath!)
          }
        })
      }
    }
  }

  downloadDocument(document: Document) {
    this.documentService.getSignedUrl(document.awsPath!).subscribe({
      next: (signedUrl: string) => {
        window.open(signedUrl)
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.message)
      }
    })
  }

  getImageUrl(document: Document): Observable<string> {
    return this.documentService.getSignedUrl(document.awsPath!)
  }
}
