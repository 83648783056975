<div id="navbarContainer" class="d-flex justify-content-between position-fixed align-items-center px-5"
  [class.collapsed]="isSidebarCollapsed()">
  <div>
    <span class="h4">{{navService.getTitle()}}</span><br />
    <div>
      <span class="text-muted h5">{{navService.getSubTitle()}}</span>
      <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
        class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </div>
  </div>
  <div style="display: flex; flex-direction: row;">
    <div *ngIf="this.navService.getActions().length > 0" class="d-flex flex-row border-end"
      style="border-color: rgb(167, 167, 167) !important">
      <span class="text-muted h6 m-0 my-auto">{{ 'LABEL.actions' | translate }}:</span>
      
      <div *ngFor="let action of this.navService.getActions()">

        <a [matTooltip]="translate.instant('NAVBUTTON.'+ action.name)" class="nav-link p-0" role="button" (click)="goTo(action.name)" aria-expanded="false"
          *ngIf="action.type == 'dynamic'">
          <fa-icon [icon]="getIcon(action.icon)" class="text-muted h2 mx-3"></fa-icon>
        </a>

        <a matTooltip="Dettaglio" class="nav-link p-0" role="button" (click)="goTo('detail')" aria-expanded="false"
          *ngIf="hasAction('detail')">
          <fa-icon [icon]="faFile" class="text-muted h2 mx-3"></fa-icon>
        </a>
        <a matTooltip="Nuovo" class="nav-link p-0" role="button" (click)="goTo('new')" aria-expanded="false"
          *ngIf="hasAction('new')">
          <fa-icon [icon]="faFileMedical" class="text-muted h2 mx-3"></fa-icon>
        </a>
        <a matTooltip="Carica dati" class="nav-link p-0" role="button" (click)="goTo('upload')" aria-expanded="false"
          *ngIf="hasAction('upload')">
          <fa-icon [icon]="faFileArrowUp" class="text-muted h2 mx-3"></fa-icon>
        </a>
        <a matTooltip="Esporta dati" class="nav-link p-0" role="button" (click)="goTo('export')" aria-expanded="false"
          *ngIf="hasAction('export')">
          <fa-icon [icon]="faFileArrowDown" class="text-muted h2 mx-3"></fa-icon>
        </a>
        <a matTooltip="Esporta dati" class="nav-link p-0" role="button" (click)="downloadExportInPage()"
          aria-expanded="false" *ngIf="hasAction('export_in_page')">
          <fa-icon [icon]="faFileArrowDown" [ngClass]="downloadExportInPageIsActive() ? 'exportActive' : 'text-muted'"
            class="h2 mx-3"></fa-icon>
        </a>
        <a matTooltip="Back" class="nav-link p-0" role="button" (click)="goTo('back')" aria-expanded="false"
          *ngIf="hasAction('back')">
          <fa-icon [icon]="faArrowLeft" class="text-muted h2 mx-3"></fa-icon>
        </a>

      </div>

    </div>
    <span style="margin-left: 10px;" [ngClass]="getClassProfiles(0)" [matTooltip]="getTooltip(0)">
      engineering
    </span>
    <span style="margin-left: 10px;" [ngClass]="getClassProfiles(1)" [matTooltip]="getTooltip(1)">
      supervisor_account
    </span>
    <span style="margin-left: 10px;" [ngClass]="getClassProfiles(2)" [matTooltip]="getTooltip(2)">
      admin_panel_settings
    </span>
    <div class="dropdown">
      <a class="nav-link p-0" href="#" id="companyDropdown" role="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        <fa-icon [icon]="faBuilding" class="h2" style="margin-left: 10px;"></fa-icon>
        <span *ngIf="!(this.workingCompany == null)" class="m-3">{{this.workingCompany.name}}</span>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark" style="min-width: 400px;">
        <li class="px-3 py-2">
          <input class="form-control" type="text" [placeholder]="translate.instant('LABEL.CERCA_SEDE')"
            [(ngModel)]="searchText" (input)="filterCompanies()">
        </li>
        <li *ngFor="let cp of filteredCompanies">
          <a class="dropdown-item cursor-pointer" (click)="changeCompany(cp)">
            <fa-icon *ngIf="!cp.isOpen" [icon]="faCircleExclamation" style="margin-right: 10px;"></fa-icon>
            <span>{{ cp.name }}</span>
          </a>
        </li>
      </ul>
    </div>
    <!--
    <mat-select>
      <mat-option *ngFor="let cp of companies" (onSelectionChange)="changeCompany(cp,$event)" [value]="cp">
        {{cp.name}}
      </mat-option>
    </mat-select>
  -->
    <div class="dropdown">
      <a class="nav-link p-0" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        <fa-icon [icon]="faGlobe" class="h2" style="margin-left: 10px;"></fa-icon>
        <span class="m-3">{{translate.currentLang}}</span>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark">
        <li><a class="dropdown-item cursor-pointer" (click)="setLanguage('fr')">{{ 'LANG.FR' | translate }}</a></li>
        <li><a class="dropdown-item cursor-pointer" (click)="setLanguage('en')">{{ 'LANG.EN' | translate }}</a></li>
        <li><a class="dropdown-item cursor-pointer" (click)="setLanguage('it')">{{ 'LANG.IT' | translate }}</a></li>
        <li><a class="dropdown-item cursor-pointer" (click)="setLanguage('es')">{{ 'LANG.ESP' | translate }}</a></li>
      </ul>
    </div>

    <!-- Reload User Visibility -->
    <a matTooltip="Reload Visibility" class="nav-link p-0" role="button" (click)="reloadUserVisibility()"
      aria-expanded="false">
      <fa-icon [icon]="faSync" class="h2 mx-3"></fa-icon>
    </a>

    <!-- User Notification -->
    <!--
        <app-notifications></app-notifications>
        -->

    <a class="nav-link p-0" role="button" aria-expanded="false" (click)="logout()">
      <fa-icon [icon]="faArrowRightFromBracket" class="h2" style="margin-left: 10px;"></fa-icon>
    </a>
  </div>
</div>

<!-- <div class="container-fluid d-flex justify-content-end p-1 px-4" id="navbarContainer">
  <ul class="navbar-nav d-flex flex-row gap-5">
    <li class="nav-item dropdown" *ngIf="user()">
      <a href="#" class="nav-link dropdown-toggle text-grey text-end" id="userDropdown" role="button" data-bs-toggle="dropdown">
        <fa-icon [icon]="faUser"></fa-icon> {{ user().given_name }} {{ user().family_name }}<br />
        <small><fa-icon [icon]="faEnvelope"></fa-icon> {{ user().email }}</small>
      </a>
      <ul class="dropdown-menu" aria-labelledby="userDropdown">
        <li>
          <a href="#" class="dropdown-item" (click)="logout()">
            Logout
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item dropdown my-auto">
      <a class="nav-link text-grey" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <fa-icon [icon]="faGlobe"></fa-icon>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark">
        <li><a class="dropdown-item" href="#">Italiano</a></li>
      </ul>
    </li>
    <li class="nav-item" *ngIf="!user()">
      <a href="#" class="nav-link text-grey" (click)="login()">Login</a>
    </li>
    <li class="nav-item" *ngIf="user()">
      <a href="#" class="nav-link text-grey" (click)="logout()">Logout</a>
    </li>
  </ul>
</div> -->