import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, FormArray } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { TranslatorService } from 'src/app/services/translate.service';
import { map, Observable, startWith } from "rxjs";
import { v4 as uuidv4 } from 'uuid'; // Importa la funzione v4 da uuid



@Component({
  selector: 'edit-event-calendar-dialog',
  templateUrl: './edit-event-dialog.html',
  styleUrls: ['./edit-event-dialog.scss'],
})
export class EditEventCalendarDialog implements OnInit {
  faSync = faSync;
  filteredResources: any[];
  resourcesData: any[];
  availableResources: any[][] = [];
  filteredSocieties: any[];
  filteredCompanies: any[];
  test: any[];
  ; // questa proprietà viene impiegata per tenere traccia delle risorse disponibili
  availableResourcesFiltered: any[][] = [];
  filteredResourcesToAdd: any[][] = [];  // per filtrare resourcesToAdd
  //aggiunti per modifica ricerca (codice sottostante)
  countrySearchControl = new FormControl();
  causalSearchControl = new FormControl();

  societySearchControl = new FormControl();  // Aggiunto
  companySearchControl = new FormControl();  // Aggiunto

  filteredOrders: any[];
  countries: any[];
  societies: any[];
  companies: any[];
  causals: any[];

  filteredCountries: any[];
  filteredCausals: any[];

  resourcesToReplaceSearchControls: FormControl[] = [];
  resourcesToAddSearchControls: FormControl[] = [];

  showRoleMismatchWarning: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditEventCalendarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public translate: TranslatorService
  ) { }

  form: any = this.fb.group({
    description: [null, [Validators.required]],
    CountryId: [null, [Validators.required]],
    CompanyId: [null],
    SocietyId: [null],
    startDate: [new Date(), [Validators.required]],
    endDate: [new Date(), [Validators.required]],
    hours: [null, [Validators.required, this.hoursValidator]],
    WorkCausalId: [null, [Validators.required]],
    cyclical: [false]
  }, { validator: this.dateRangeValidator }) as FormGroup<EditEventCalendarDialogForm<EditEventCalendarDialogFormValue>>;

  maxEndDate: Date; // Aggiungi questa proprietà
  minEndDate: Date = new Date;
  todayDate: Date = new Date;
  isValid: boolean;

  public dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.get('startDate')?.value || !control.get('endDate')?.value) {
      return null;
    }


    let startDate: any = control?.get('startDate')?.value
    let endDate: any = control?.get('endDate')?.value
    let startDateMoment = moment(startDate).utc(true).hours(0).minutes(0).seconds(0).milliseconds(0)
    let endDateMoment = moment(endDate).utc(true).hours(23).minutes(59).seconds(59).milliseconds(59)
    const isValid = startDateMoment.isBefore(endDateMoment)

    if (!isValid) {
      control?.get('startDate')?.setErrors({ notEquivalent: true })
      control?.get('endDate')?.setErrors({ notEquivalent: true })
      return { invalidDateRange: true };
    } else {
      control?.get('startDate')?.setErrors(null)
      control?.get('endDate')?.setErrors(null)
    }

    return null;
  }

  hoursValidator(control: AbstractControl): ValidationErrors | null {
    const hours = control.value;
    if (hours < 1 || hours > 8) {
      return { invalidHours: true };
    }
    return null;
  }


  ngOnInit() {

    // console.log(this.data.workCausals);

    //filtro le nazioni con il campo di ricerca
    this.filteredCountries = this.data.countries;
    // console.log(this.filteredCountries)
    this.countrySearchControl.valueChanges.subscribe(value => {
      //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
      this.filteredCountries = this.data.countries.filter(country =>
        !value || country.toLowerCase().includes(value.toLowerCase())
      );
    });



    this.companies = this.data.companies;
    // console.log(this.companies)

    this.societies = this.removeDuplicates(this.data.societies);
    // console.log(this.societies)
    // console.log(this.data.societies)



    this.form.get('CountryId')?.valueChanges.subscribe((value: any) => {
      this.filterSocietiesByCountry(value);
      this.form.get('SocietyId').reset();
      this.form.get('CompanyId').reset();
    });


    this.form.get('SocietyId')?.valueChanges.subscribe((value: any) => {
      this.filterCompanyBySociety(value);
      this.form.get('CompanyId').reset();
    });

    this.societySearchControl.valueChanges.subscribe(value => {
      //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
      this.filteredSocieties = this.societies.filter(society =>
        !value || society.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    this.companySearchControl.valueChanges.subscribe(value => {
      //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
      this.filteredCompanies = this.companies.filter(company =>
        !value || company.name.toLowerCase().includes(value.toLowerCase())
      );
    });
    // console.log(this.filteredCompanies)

    this.filteredCausals = this.data.workCausals;
    this.causalSearchControl.valueChanges.subscribe(value => {
      this.filteredCausals = this.data.workCausals.filter(causal =>
        !value || causal?.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    this.form.get('startDate')?.valueChanges.subscribe((value: any) => {
      this.minEndDate = value;
    })

    this.form.patchValue({
      description: this.data?.description,
      CountryId: this.data?.countryCode,
      SocietyId: this.data?.SocietyId,
      CompanyId: this.data?.CompanyId,
      startDate: new Date(this.data?.startDate),
      endDate: new Date(this.data?.endDate),
      hours: this.data?.hours,
      WorkCausalId: this.data?.WorkCausalId,
      cyclical: this.data?.cyclical
    });
  }

  filterSocietiesByCountry(country: string) {
    if (country) {
      this.filteredSocieties = this.societies.filter(society => society.societyCountry === country);
    } else {
      this.filteredSocieties = [];
    }
  }

  filterCompanyBySociety(societyId: string) {
    // console.log(societyId)
    if (societyId) {
      this.filteredCompanies = this.companies.filter(company => company.societyId === societyId);
    } else {
      this.filteredCompanies = [];
    }
  }

  removeDuplicates(societies: any) {
    const uniqueSocieties = societies.reduce((acc: any, current: any) => {
      // Usa un oggetto per tracciare gli id unici
      if (!acc.some((society: any) => society.id === current.id)) {
        acc.push(current);
      }
      return acc;
    }, []);
    return uniqueSocieties;
  }

  convertCountryId(countryCode: string): number {
    const countryList = this.data.countries;
    const index = countryList.indexOf(countryCode);
    if (index === -1) {
      throw new Error(`Country code ${countryCode} not found in the list.`);
    }
    return index + 1;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.form.valid) {
      let formValue = this.form.value;
      formValue.startDate = moment(formValue.startDate).format('YYYY-MM-DD');
      formValue.endDate = moment(formValue.endDate).format('YYYY-MM-DD');
      formValue.createdAt = moment(new Date()).format('YYYY-MM-DD');
      formValue.updatedAt = moment(new Date()).format('YYYY-MM-DD');

      // Conserva il valore originale di CountryId
      const originalCountryId = formValue.CountryId;
      // Converti il CountryId se è presente nella lista
      if (formValue.CountryId) {
        formValue.CountryId = this.convertCountryId(formValue.CountryId);
      }
      // Aggiungi il campo countryCode con il valore originale di CountryId
      formValue.countryCode = originalCountryId;

      formValue = this.enrichPayloadWithNames(formValue);

      // console.log(formValue); // Log dei valori del form
      this.dialogRef.close(formValue);
    }
  }


  formatDateForSQL(date: Date): string {
    // Formatta la data come 'YYYY-MM-DD HH:mm:ss'
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }


  enrichPayloadWithNames(formValue: any): any {
    const company = this.getCompanyById(formValue?.CompanyId);
    const society = this.getSocietyById(formValue?.SocietyId);
    const workCausal = this.getWorkCausalById(formValue?.WorkCausalId);
    const code = uuidv4();
    // console.log(workCausal.name);

    return {
      ...formValue,
      CompanyName: company ? company.name : '',
      SocietyName: society ? society.name : '',
      WorkCausalName: workCausal ? workCausal.name : '',
      code: code
    };
  }

  getCompanyById(id: number): any {
    return this.data.companies.find((company: any) => company.id === id);
  }

  getSocietyById(id: number): any {
    return this.data.societies.find((society: any) => society.id === id);
  }

  getWorkCausalById(id: number): any {
    console.log(this.data.workCausals)
    return this.data.workCausals.find((workCausal: any) => workCausal?.id === id);
  }

  onDeselect(event: Event, controlName: string) {
    event.stopPropagation();  // Evita che il click si propaghi e selezioni nuovamente l'opzione
    this.form.get(controlName)?.setValue(null);  // Deseleziona il valore del campo
  }

}





interface DialogData {
  country: any;
  description: string
  countries: any[]
  societies: any[]
  companies: any[]
  hours: number
  workCausals: any[],
  countryCode: any,
  SocietyName: any,
  CompanyName: any,
  WorkCausalId: any
  startDate: Date,
  endDate: Date
  CountryId: any,
  SocietyId: any,
  CompanyId: any,
  cyclical: any
}
export interface EditEventCalendarDialogFormValue {
  description: string | null;
  CountryId: string | null;
  SocietyId: string | null;
  CompanyId: string | null;
  startDate: Date;
  endDate: Date;
  hours: number;
  WorkCausalId: string | null;
}


interface EditEventCalendarDialogForm<T extends EditEventCalendarDialogFormValue> {
  description: FormControl<T['description']>;
  CountryId: FormControl<T['CountryId']>;
  SocietyId: FormControl<T['SocietyId']>;
  CompanyId: FormControl<T['CompanyId']>;
  startDate: FormControl<T['startDate']>;
  endDate: FormControl<T['endDate']>;
  hours: FormControl<T['hours']>;
  WorkCausalId: FormControl<T['WorkCausalId']>;

}