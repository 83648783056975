import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, FormGroup } from '@angular/forms';

import { GenericModal } from '../modals/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestService } from '../../services/request.service';
import { ComponentService } from '../../services/component.service';
import { PracticeService } from '../../services/practice.service';
import { UserSessionService } from '../../services/user-session.service';
import { CompanyManagementService } from '../../services/company-management.service';
import { LibService } from '../../services/libService';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-change-state-button',
  templateUrl: './change-state-button.component.html',
  styleUrls: ['./change-state-button.component.scss']
})
export class ChangeStateButtonComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isNew: any;
  @Input() components: any;
  @Input() practice: any;
  @Input() costoVenduto: any;
  @Input() order: any;

  attributes: any;

  disabled: any = true;
  actions: any = [];

  componentName: any = 'change_state_button'

  objectStructure: any = {}


  constructor(
    private dialog: MatDialog,
    private requestService: RequestService,
    private componentService: ComponentService,
    private router: Router,
    private libService: LibService,
    private practiceService: PracticeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userSessionService: UserSessionService,
    private companyManagementService: CompanyManagementService,
  ) { }

  getClass() {
    if (this.objectStructure?.settings?.type) return 'clsButton' + this.objectStructure?.settings?.type
    return 'clsButtonDefault'
  }

  closeRequest(attributes: any) {
    this.libService.lockPage('');
    let id = this.form.get('id')?.value
    this.requestService.closeRequest(id, attributes).subscribe(
      (res: any) => {
        console.log(res)
        this.router.navigate(['request/' + id]).then(_res => {
          window.location.reload()
        })
      },
      (err: any) => {
        console.log(err)
        this.libService.unlockPage();
      },
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    console.log('ngOnInit request close button')
    this.setActions()
    this.setStatusButton()
    this.form?.get('documents')?.valueChanges.subscribe(val => {
      this.setStatusButton()
    });
  }

  setStatusButton() {
    //console.log('setStatusButton')
    let tmpStatus = false
    this.setAttributes()
    //if (!this.form?.valid) tmpStatus = true
    if (!this.checkNecessaryDocuments()) tmpStatus = true
    this.disabled = tmpStatus
  }

  enableCancelButton() {
    return 'cancelButton' in this.objectStructure
  }

  getCancelButtonTitleCode() {
    return this.objectStructure?.cancelButton?.code + '_title'
  }

  setThePracticeAsDeliberate(){
    let status = false
    if(this.components?.codes?.includes('setThePracticeAsDeliberate')) status = true
    return status
  }

  openModal(type: any) {
    let code = this.objectStructure?.settings?.code
    if (type == 'cancel') code = this.objectStructure?.cancelButton?.code
    if (type == 'restore') code = this.objectStructure?.restorePreviousStateButton?.code

    let checkSignaler = this?.order?.checkSignaler
    let signalers = checkSignaler?.currentSignalers
    let signaler = signalers.length == 1 ? signalers[0] : null

    let dataStructure: any = {
      actions: this.actions,
      titleLabel: 'CHANGESTATEBUTTON.' + code + '_title',
      textLabel: 'CHANGESTATEBUTTON.' + code + '_text',
      type: type,
    }
    if(!signaler && signalers.length > 1 && this.setThePracticeAsDeliberate()) dataStructure.currentSignalers = signalers || []

    console.log(this.objectStructure)

    let hasReasons = this.objectStructure?.cancelButton?.hasReasons
    if (type == 'next') dataStructure.initEstimateDeliveryMethods = this.objectStructure?.selectDeliverToCustomer?.require
    if (type == 'cancel') dataStructure.initReasons = hasReasons
    if (type == 'cancel' && hasReasons) dataStructure.reasonList = this.objectStructure?.reasonList || []

    const dialogRef = this.dialog.open(GenericModal, {
      width: '400px',
      data: dataStructure
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (![null, undefined].includes(result)) {
        let attributes = result;
        attributes.attributeCode = this.componentName
        attributes.costoVenduto = this.costoVenduto

        if(signaler) attributes.currentSignaler = signaler

        this.libService.lockPage('');

        if(attributes.type == 'restore'){
          this.practiceService.restorePreviousState(this.practice.id).subscribe(
            ret => {
              this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
              this.libService.unlockPage();
              this.router.navigate(['practices/' + this.practice.code]).then(_res => {
                window.location.reload()
              })
            },
            err => {
              //this.toastr.error(this.translate.instant('LABEL.Error'));
              this.libService.unlockPage();
            }
          );
        }else{
          this.practiceService.changeState(this.practice.id, attributes, this.componentName, type).subscribe(
            ret => {
              this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
              this.libService.unlockPage();
              this.router.navigate(['practices/' + this.practice.code]).then(_res => {
                window.location.reload()
              })
            },
            err => {
              //this.toastr.error(this.translate.instant('LABEL.Error'));
              this.libService.unlockPage();
            }
          );
        }
 
      }

    });


  }

  getAttributes(componentCode: any) {
    let component = this.components?.settings?.find((component: any) => component.componentCode == componentCode)
    return component?.attributes
  }

  checkNecessaryDocuments() {
    //console.log('checkNecessaryDocuments - MAIN')
    let requestDocumentManagerAttributes = this.getAttributes('practice_workflow_manager')
    let documentList = requestDocumentManagerAttributes?.documentList;
    let tmpStructure: any = {}
    if (documentList) {
      documentList.forEach((doc: any) => {
        let key = `document${doc.documentTypeCode}`
        if (!tmpStructure[key]) tmpStructure[key] = []
        tmpStructure[key].push(doc)
      })
    }

    return this.componentService.checkNecessaryDocumentsForPractices(this.form?.get('documents')?.value, tmpStructure, this.form, this.order).status
  }

  setActions() {
    this.setAttributes()
    if (this.attributes) {
      this.actions = []
      let structure: any = this.componentService.getStructure(this.attributes)
      structure.forEach((s: any) => {
        if (s.type == 'action' && !this.actions.includes(s.type)) this.actions.push(s.code)
      })
      //console.log(this.actions)
    }
  }

  setAttributes() {
    //console.log('setAttributes')
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
    if (this.attributes) this.objectStructure = this.componentService.getObjectStructure(this.attributes)
    //console.log('this.objectStructure')
    //console.log(this.objectStructure)
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) {
      this.setAttributes()
      this.setActions()
      this.setStatusButton()
    }
    return status
  }

  checkStateList() {
   return 'stateList' in this.attributes
  }

  checkrestorePreviousStateButton() {
    if(this.attributes){
      return 'restorePreviousStateButton' in this.attributes
    }
    return false
   }

   restoreDisabled(documentControlRequired: any = false){
    return documentControlRequired ? this.disabled : false
   }

  goTo(e: any) {
    this.setPracticeCompanyOnUI()
    this.router.navigate([e]);
  }

  setPracticeCompanyOnUI(){
    let user = this.userSessionService.getState('user');
    let workingCompany = this.userSessionService.getState('working_company');

    // Setta la company della pratica
    if (workingCompany == null || workingCompany.id != this.practice.idSede) {
      let tmp_company = user.UsersCompaniesRel.find((c: any) => c.id == this.practice.idSede);
      let res = {
        isOpen: tmp_company.isOpen,
        name: tmp_company.name,
        code: tmp_company.code,
        id: tmp_company.id,
        country: tmp_company?.country
      }
      this.userSessionService.saveState('working_company', res);

      this.companyManagementService.add(res);

    }
  }

}
