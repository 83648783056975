import { Component, EventEmitter, OnInit, Output, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, map, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Company } from 'src/app/models/company';
import { Society } from 'src/app/models/society';
import { User } from 'src/app/models/user';
import { BuildingService } from 'src/app/services/building.service';
import { CompanyService } from 'src/app/services/companyService';
import { CondominiumService } from 'src/app/services/condominium.service';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { SocietyService } from 'src/app/services/societyService';
import { SubjectService } from 'src/app/services/subject.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { UserDataService } from 'src/app/services/users.service';

@Component({
  selector: 'app-subject-filter',
  templateUrl: './subject-filter.component.html',
  styleUrls: ['./subject-filter.component.scss']
})
export class SubjectFilterComponent implements OnInit {

  @Input()type: string;
  @Output() filterResult = new EventEmitter;

  societies: Society[] = []
  responsibles: string[] = []
  companies: Company[] = []

  form: UntypedFormGroup

  noUsers: boolean = true;

  /* companies$ = this.companyService.getAllCompanies().pipe(
    map((companies: Company[]) => {
      return companies.sort((a, b) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      companies.map(company => {
        if(company.CompaniesSocietiesRel !== undefined){
          this.societies.push(...company.CompaniesSocietiesRel)
        }
        if(company.CompaniesUsersRel !== undefined){
          this.responsibles.push(...company.CompaniesUsersRel!.map(user => user.email!))
        }
      })
      this.societies = this.unique(this.societies, ['code'] )
      this.responsibles = [...new Set(this.responsibles)]
      this.companies = companies
    })
  ); */

  logUsr$ = this.sessionService.getUser(this.authService.user().email)
  // companies$ = this.companyService.getAllCompaniesFast().pipe(
  //   tap(companies => {
  //     this.companies = companies
  //   })
  // )

  societies$ = this.societyservice.getAllSocieties().pipe(
    tap(societies => {
      this.societies = societies

    })
  )

  // responsibles$ = this.userService.getAllUsersFast().pipe(
  //   tap(users => {
  //     users = users.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
  //     let pippo = users.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(37) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
  //     this.responsibles = pippo.map((user: User) => user.email)
  //   })
  // )

  constructor(
    private fb: UntypedFormBuilder,
    private companyService: CompanyService,
    private societyservice: SocietyService,
    private userService: UserDataService,
    private subjectService: SubjectService,
    private buildingService: BuildingService,
    private condominiumService: CondominiumService,
    private authService: AuthService,
    private sessionService: UserSessionService,
    private toastr: ToastrService,
    private libService: LibService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      company: [[], Validators.required],
      companyLocation: [[], null],
      responsible: [[], null]
    })
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(form1 => {

      this.sessionService.saveState('SubjectFilters', form1);
    })
    this.form.get('company')?.valueChanges.subscribe((current) => {
      console.log(current);
      this.noUsers = true;
      this.cdr.detectChanges();
      if (current.length > 0) {
        this.noUsers = false;
        this.cdr.detectChanges();
        this.userService.getByCompanyCode(current.map((a: any)=> a.code)).subscribe(users => {
          console.log("COMPANY CODES --> ", current);
          console.log("USERS BY COMPANY CODE --> ", users);
          users = users.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
          let pippo = users.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
          // if (pippo !== null && pippo !== undefined)
          //   pippo.sort((a : any , b: any) => a.email! <= b.email! ? -1 : 1)
          this.responsibles = pippo.sort((a : any , b: any) => a.email! <= b.email! ? -1 : 1);//.map((user: User) => user.email)
          // this.responsibles = users;
        })
      }
    })

    combineLatest([this.logUsr$, this.societies$]).subscribe(([loggedUser, societies]: any) => {
      let prevFilters = this.sessionService.getState('SubjectFilters');
      this.companies = loggedUser[0].UsersCompaniesRel.sort((a : any , b: any) => a.code! <= b.code! ? -1 : 1);
      console.log("PREVIOUS fILTERS --> ", prevFilters);
      if (prevFilters?.company?.length > 0 || prevFilters?.responsible?.length > 0) {
        this.form.patchValue(prevFilters);
        this.filter();
      }
      this.form.patchValue(prevFilters);
    })

  }

  filter(){
    let formValues = this.form.getRawValue();

    this.sessionService.saveState('SubjectFilters', formValues);
    if (this.form.valid === true) {


    let options = Object.keys(formValues).map((key, index, array) => {
      let option: any
      //let values: any
      switch (key) {
        case 'company':
          let valuesCompany = formValues[key].length !== undefined?
            formValues[key].map((value: any) => value['code']):
            [null]
          option = {
            key: key,
            values: valuesCompany
          }
          break;
        case 'companyLocation':
          let valuesCompanyLocation = formValues[key].length !== undefined?
            formValues[key].map((value: any) => value['code']):
            [null]
          option = {
            key: key,
            values: valuesCompanyLocation
          }
          break;
        case 'responsible':
          let valuesResponsible = formValues[key].length !== undefined?
            formValues[key].map((value: any) => value['email']):
            [null]
          option = {
            key: key,
            values: valuesResponsible
          }
          break;
        default:
          break;
      }
      return option
    })

    options = options.filter(option => option.values.length > 0)
    this.libService.lockPage('')
    switch (this.type) {
      case 'Soggetto':
        this.subjectService.find(options).subscribe({
          next: (subjects => {
            this.filterResult.next(subjects)
            this.libService.unlockPage()
          }),
          error: (err => {
            this.toastr.error(err)
            this.libService.unlockPage()
          })
        })
        break;
      case 'Edificio':
        this.buildingService.find(options).subscribe({
          next: (buildings => {
            this.filterResult.next(buildings)
            this.libService.unlockPage()
          }),
          error: (err => {
            this.toastr.error(err)
            this.libService.unlockPage()
          })
        })
        break;
      case 'Condominio':
        this.condominiumService.find(options).subscribe({
          next: (condominiums => {
            this.filterResult.next(condominiums)
            this.libService.unlockPage()
          }),
          error: (err => {
            this.toastr.error(err)
            this.libService.unlockPage()
          })
        })
        break;
    }
  } else {
    this.toastr.warning(this.translate.instant("TOASTR.MESSAGE41"), this.translate.instant("TOASTR.WARNING"));
  }
  }

  clear(){
    this.form.reset({
      company: [],
      companyLocation: [],
      responsible: []
    })
    this.sessionService.saveState('SubjectFilters', this.form.getRawValue());
    this.filterResult.next(null)
  }

  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
     const key = keyProps.map(k => entry[k]).join('|');
     return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
   }

   get companyControl(){
    return this.form.get('company') as UntypedFormControl
   }

   get companyLocationControl(){
    return this.form.get('company') as UntypedFormControl
   }

   get responsibleControl(){
    return this.form.get('company') as UntypedFormControl
   }

   private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      if (typeof obj[propName] === 'object') {
        obj[propName] = this.clean(obj[propName])
      }
    }
    return obj
  }
}
