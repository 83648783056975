import { LevelService } from './../services/level.service';
import { Observable, forkJoin, Subject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UserDataService } from '../services/users.service';
import { LicenceService } from '../services/licenseService';
import { ProfileService } from '../services/profileService';
import { DataSetService } from '../services/dataset.service';
import { CompanyService } from '../services/companyService';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DataSet } from '../models/dataset';
import { NavService } from '../services/nav.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { UsersLogsComponent } from '../modals/users-logs/users-logs.component';
import { TranslatorService } from '../services/translate.service';
import { ActionService } from '../services/action.service';
import { UserSessionService } from '../services/user-session.service';
import { LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  private onDestroy = new Subject<void>();
  private languageChange = new Subject<void>();

  displayedColumns = ['id', 'username', 'locale', 'email', 'createdAt', 'lastlogin'];
  dataSource: any;
  licences: any = [];
  profiles: any = [];
  datasets: any = [];
  companies: any = [];
  levels: any = [];
  kwSearch: any;

  title = "Utenti";
  subTitle = "EDAC - Utenti"

  dropdownListCompanies: any[] = [];
  selectedCompanies: any[] = [];
  dropdownCompaniesSettings: IDropdownSettings = {};

  dropdownListProfiles: any[] = [];
  selectedProfiles: any[] = [];
  dropdownProfilesSettings: IDropdownSettings = {};

  dropdownListLicences: any[] = [];
  selectedLicences: any[] = [];
  dropdownLicencesSettings: IDropdownSettings = {};

  dropdownListDomains: any[] = [];
  selectedDomains: any[] = [];
  dropdownDomainsSettings: IDropdownSettings = {};

  dropdownListlevels: any;
  selectedLevel: any;
  dropdownLevelSettings: IDropdownSettings = {};

  selectedUser: any;

  activeUser: any;
  sort: MatSort
  langChangeSubscription: any;

  limitedProfileCodes: any = [
    'PRD-OP',
    'PRD-OP-LEADER',
    'MLT-OP',
    'GRD-OP',
    'PRD-CO',
    'ENE-OP',
    'SLS-TC'
  ]
  profileNotForAreaManager: any = [
    'HQ-DIR',
    'HQ-IT',
    'HQ-BRD'
  ]

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }


  searchTimeout: any;

  @ViewChild('closeModalProfiles') closeModalProfiles: ElementRef;

  constructor(
    private userDataService: UserDataService,
    private licenseService: LicenceService,
    private dataSetService: DataSetService,
    private profileService: ProfileService,
    private companyService: CompanyService,
    public libService: LibService,
    public navService: NavService,
    private toastr: ToastrService,
    private levelService: LevelService,
    public dialog: MatDialog,
    public translate: TranslatorService,
    public MatPaginatorIntl: MatPaginatorIntl,
    public actionService: ActionService,
    public userSessionService: UserSessionService,

  ) {
    this.dataSource = new MatTableDataSource();

    //this.profileService.getAllProfiles().subscribe(r => this.data = r);
  }

  ngOnInit(): void {


    this.setTranslationsItemsPerPageLabel();
    this.loadUsers();

    // Ascolta i cambiamenti di lingua
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setTranslationsItemsPerPageLabel();
      this.languageChange.next();
    });

    this.languageChange.subscribe(() => {
      this.updateTranslations();
    });

    let actions: any;
    actions = [
      { name: 'upload', url: '' },
      { name: 'export', url: '' },
    ];
    this.navService.setActions(actions);
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');

    this.selectedCompanies = [
    ];

    this.dropdownCompaniesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleziona Tutte',
      unSelectAllText: 'Deseleziona Tutte',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessuna sede disponibile',
      allowSearchFilter: true
    };

    this.selectedProfiles = [
    ];

    this.dropdownProfilesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleziona Tutti',
      unSelectAllText: 'Deseleziona Tutti',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessun profilo disponibile',
      allowSearchFilter: true
    };

    this.selectedLicences = [
    ];

    this.dropdownLicencesSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      selectAllText: 'Seleziona Tutti',
      unSelectAllText: 'Deseleziona Tutti',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessuna licenza disponibile',
      allowSearchFilter: true
    };

    this.selectedDomains = [
    ];

    this.dropdownDomainsSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleziona Tutti',
      unSelectAllText: 'Deseleziona Tutti',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessun dominio disponibile',
      allowSearchFilter: true
    };

    this.dropdownLevelSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleziona Tutti',
      unSelectAllText: 'Deseleziona Tutti',
      searchPlaceholderText: 'Cerca',
      noDataAvailablePlaceholderText: 'Nessun livello disponibile',
      allowSearchFilter: true
    }

    this.initColumns()
  }

  initColumns() {

    this.displayedColumns = ['id', 'username', 'locale', 'email', 'createdAt', 'lastlogin'];

    if (this.canViewLogs()) this.displayedColumns.push('log')
    if (this.canAssignLocations()) this.displayedColumns.push('company')
    if (this.canAssignUserLicenses()) this.displayedColumns.push('licence')
    if (this.canAssignUserProfiles()) this.displayedColumns.push('profile')
    if (this.canAssignUserDomains()) this.displayedColumns.push('dataset')

    this.displayedColumns.push('saveAction')
  }


  checkPermissions(itemType: any, actionType: any) {
    return this.actionService.checkPermissions(`${itemType}_${actionType}`)
  }


  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  setTranslationsItemsPerPageLabel() {
    this.MatPaginatorIntl.itemsPerPageLabel = this.translate.instant('LABEL.ELEMENTI_PER_PAGINA');
    this.MatPaginatorIntl.nextPageLabel = this.translate.instant('LABEL.PROSSIMA_PAGINA');
    this.MatPaginatorIntl.previousPageLabel = this.translate.instant('LABEL.PAGINA_PRECEDENTE');
    this.MatPaginatorIntl.changes.next();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  updateTranslations() {
    this.dropdownListProfiles = this.getProfiles();
    this.dropdownListLicences = this.getLicences();
    this.dropdownListDomains = this.getDomains();
    this.dropdownListlevels = this.getLevels();
    this.dropdownListCompanies = this.getCompanies();

    // Aggiorna anche le traduzioni visualizzate nella tabella, se necessario
    this.dataSource.data = this.dataSource.data.map((user: { profileName: string; levelName: string; }) => {
      return {
        ...user,
        profileName: this.translate.instant('WORKTYPE_PROFILES.' + user.profileName),
        levelName: this.translate.instant('WORKTYPE_LEVELS.' + user.levelName)
        // Continua a tradurre altri campi necessari
      };
    });
  }

  openLog(element: any) {
    this.dialog.open(UsersLogsComponent, {
      data: element
    })
  }

  getProfiles() {
    let p: any[] = [];

    let isAdmin: any = this.userSessionService.isAdmin()
    let isAreaManager: any = this.userSessionService.isAreaManager()

    for (let a = 0; a < this.profiles.length; a++) {
      if (isAdmin) {
        p.push({ id: this.profiles[a].id, name: this.translate.instant('WORKTYPE_PROFILES.' + this.profiles[a].name) });
      } else if (isAreaManager) {
        if (!this.profileNotForAreaManager.includes(this.profiles[a].code)) p.push({ id: this.profiles[a].id, name: this.translate.instant('WORKTYPE_PROFILES.' + this.profiles[a].name) });
      } else {
        if (this.limitedProfileCodes.includes(this.profiles[a].code)) p.push({ id: this.profiles[a].id, name: this.translate.instant('WORKTYPE_PROFILES.' + this.profiles[a].name) });
      }

    }
    return p;
  }

  getLicences() {
    let p: any[] = [];
    for (let a = 0; a < this.licences.length; a++) {
      p.push({ id: this.licences[a].id, code: this.licences[a].code });
    }
    return p;
  }

  getDomains() {
    let p: any[] = [];
    for (let a = 0; a < this.datasets.length; a++) {
      p.push({ id: this.datasets[a].id, name: this.datasets[a].name });
    }
    return p;
  }

  getLevels() {
    let p: any[] = [];
    for (let a = 0; a < this.levels.length; a++) {
      p.push({ id: this.levels[a]?.id, name: this.translate.instant('WORKTYPE_LEVELS.'+ this.levels[a].code), code: this.levels[a].code });
    }
    console.log(p)
    return p;
  }

  getCompanies() {
    let p: any[] = [];
    for (let a = 0; a < this.companies.length; a++) {
      p.push({ id: this.companies[a].id, name: this.companies[a].name });
    }
    return p;
  }

  noEdit(element: any) {
    // console.log('element %o activeUser %o', element, this.activeUser);
    if (element == null)
      return true;
    if (this.activeUser == null)
      return true;
    if (this.activeUser.id == element.id)
      return false;
    else
      return true;
  }

  onSelectAll(items: any) {
    console.log(items);
  }



  updateUserData() {
    this.libService.lockPage('')
    const curr = this.paginator.pageIndex * this.paginator.pageSize
    const size = this.paginator.pageSize
    const observables = []
    const displayed = this.dataSource?.sortData(this.dataSource.filteredData, this.dataSource.sort)
    const fullData = this.dataSource.data
    const filter = this.dataSource.filter

    const indexesToFind = []
    for (let i = curr; i < curr + size; i++) {
      indexesToFind.push(displayed[i]?.id)
    }


    const indexes: number[] = []
    indexesToFind.forEach(iToFind => {
      for (let i = 0; i < fullData?.length; i++) {
        if (iToFind == fullData[i].id) {
          indexes.push(i)
          return
        }
      }
    })

    for (let i = 0; i < size; i++) {
      if (fullData[indexes[i]]?.id)
        observables.push(this.userDataService.getUser(fullData[indexes[i]].id))
    }
    if (observables.length == 0) this.libService.unlockPage()
    forkJoin(observables).subscribe(fullusers => {
      for (let i = 0; i < size; i++) {
        if (fullusers[i]) fullData[indexes[i]] = fullusers[i]
      }
      this.dataSource.data = fullData
      this.libService.unlockPage()
    })
  }

  search(e: any) {
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
    else this.dataSource.filter = ''

    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => this.updateUserData(), 1000)
  }

  loadUsers(): void {

    this.libService.lockPage('');
    this.companyService.getAllCompaniesFast().subscribe((response5) => {
      console.log('loadCompanies response %o', response5);
      if (response5.length > 0) {
        this.companies = response5;
        this.dropdownListCompanies = this.getCompanies();
      }
      this.userDataService.index().subscribe((response) => {
        console.log('loadUsers response %o', response);
        if (response.length > 0) {
          this.dataSource.data = response;

        }
        this.licenseService.getAllLicense().subscribe((response2) => {
          console.log('loadLicences response %o', response2);
          if (response2.length > 0) {
            this.licences = response2;
            this.dropdownListLicences = this.getLicences();
          }
          this.profileService.getAllProfilesFast().subscribe((response3) => {
            console.log('loadProfiles response %o', response3);
            if (response3.length > 0) {
              this.profiles = response3;
              this.dropdownListProfiles = this.getProfiles();
            }
            this.dataSetService.getAllDataSets().subscribe((response4) => {
              console.log('loadDataSets response %o', response4);
              if (response4.length > 0) {
                this.datasets = response4;
                this.dropdownListDomains = this.getDomains();
              }
              this.levelService.getAllLevels().subscribe((response6) => {
                console.log('loadLevels response %o', response6);
                if (response6.length > 0) {
                  this.levels = response6
                  this.dropdownListlevels = this.getLevels();
                }
                this.updateUserData()
                this.libService.unlockPage();
              })
            });
          });
        });
      });
    });

  }

  handleProfilesDeselection() {
    if (this.selectedProfiles && this.selectedProfiles.length == 0) this.selectedLevel = null
  }

  reloadUserData(user: any) {
    this.activeUser = null
    this.userDataService.getUser(user.id).subscribe((dbUser) => {
      this.dataSource.data[this.dataSource.data.findIndex((el: any) => el.id === user.id)] = dbUser;
      this.dataSource.data = new Array(...this.dataSource.data);
    })
  }

  checkUserProfiles(user: any) {
    let isAdmin: any = this.userSessionService.isAdmin()
    let isAreaManager: any = this.userSessionService.isAreaManager()

    let selectedProfileIds = this.selectedProfiles.map((profile: any) => profile.id)

    if (!isAdmin && isAreaManager) {

      // Verifico se i profili dell'utente non di base sono presenti all'inteno dei profili già presenti
      user.UsersProfilesRel.forEach((profile: any) => {
        // Se il profilo corrente non è di base ed è stato rimosso dalla select, ri aggiungilo ai valori selezionati
        if (this.profileNotForAreaManager.includes(profile.code) && !selectedProfileIds.includes(profile.id)) {
          this.selectedProfiles.push({ id: profile.id, name: profile.name })
        }
      })

    } else if (!isAdmin) {

      // Verifico se i profili dell'utente non di base sono presenti all'inteno dei profili già presenti
      user.UsersProfilesRel.forEach((profile: any) => {
        // Se il profilo corrente non è di base ed è stato rimosso dalla select, ri aggiungilo ai valori selezionati
        if (!this.limitedProfileCodes.includes(profile.code) && !selectedProfileIds.includes(profile.id)) {
          this.selectedProfiles.push({ id: profile.id, name: profile.name })
        }
      })

    }

    if (this.selectedProfiles && this.selectedProfiles.length == 0) this.selectedLevel = null
  }

  saveUser(user: any) {
    console.log('save User %o activeUser %o', user, this.activeUser);
    console.log('Profili %o', this.selectedProfiles);
    console.log('Licenze %o', this.selectedLicences);
    console.log('Domini %o', this.selectedDomains);
    console.log('Sedi %o', this.selectedCompanies);
    console.log('Livello %o', this.selectedLevel);

    this.checkUserProfiles(user)

    user.datasets = this.selectedDomains.map(dataset => {
      return dataset.id
    })

    user.licences = this.selectedLicences.map(licence => {
      return licence.id
    })

    user.profiles = this.selectedProfiles.map(profile => {
      return profile.id
    })

    user.companies = this.selectedCompanies.map(company => {
      return company.id
    })

    user.levels = this.selectedLevel?.code
    // console.log(user.levels)

    user.level = this.dropdownListlevels.find((level: any) => level?.id === this.selectedLevel[0]?.id)?.code;

    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].id == user.id) this.dataSource.data[i] = user
    }



    console.log(user)
    //console.log('user for update %o',user);
    this.libService.lockPage('');

    this.userDataService.setProfiles(user).subscribe(res1 => {

      this.userDataService.setCompanies(user).subscribe(res2 => {

        this.userDataService.setLicences(user).subscribe(res3 => {

          this.userDataService.setDatasets(user).subscribe(res => {

            if (this.selectedProfiles?.length == 0) {
              this.libService.unlockPage();
              this.toastr.success(
                this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
              this.reloadUserData(user);
            } else {
              this.userDataService.setLevel(user).subscribe(res0 => {
                this.libService.unlockPage();
                this.toastr.success(
                  this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
                this.activeUser = null
                console.log(res1);
                console.log(res2);
                console.log(res3);
                console.log(res);
                console.log(res0);
                this.reloadUserData(user);
              },
                error => {
                  this.libService.unlockPage();
                  this.toastr.error(this.translate.instant('TOASTR.MESSAGE33') + error.message, this.translate.instant('TOASTR.ERROR'));
                })
            }
          },
            error => {
              this.libService.unlockPage();
              this.toastr.error(this.translate.instant('TOASTR.MESSAGE33') + error.message, this.translate.instant('TOASTR.ERROR'));
            }
          )
        }
          ,
          error => {
            this.libService.unlockPage();
            this.toastr.error(this.translate.instant('TOASTR.MESSAGE35') + error.message, this.translate.instant('TOASTR.ERROR'));
          }
        )
      }
        ,
        error => {
          this.libService.unlockPage();
          this.toastr.error(this.translate.instant('TOASTR.MESSAGE36') + error.message, this.translate.instant('TOASTR.ERROR'));
        }
      )
    },
      error => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE34') + error.message, this.translate.instant('TOASTR.ERROR'));
      });
  }

  setActiveUser(element: any) {
    this.activeUser = element;
    //console.log('active user %o', element);
  }

  mapsState(user: any) {
    this.selectedDomains = user.UsersDatasetsRel
      .filter((up: any) => up.UsersDatasets.deletedAt == null)
      .map((dataset: any) => {
        return { id: dataset.id, name: this.translate.instant('WORKTYPE_PROFILES.' + dataset.name) }
      });
    this.selectedProfiles = user.UsersProfilesRel
      .filter((up: any) => up.UsersProfiles.deletedAt == null)
      .map((dataset: any) => {
        return { id: dataset.id, name: this.translate.instant('WORKTYPE_PROFILES.' + dataset.name) }
      });
    const codeLevel = user.UsersProfilesRel[0]?.UsersProfiles.level
    console.log('codeLevel', codeLevel)
    this.selectedLevel = [{id: this.levels.find((l: any) => l.code == codeLevel)?.id, name: codeLevel 
      ? this.translate.instant("WORKTYPE_LEVELS." + this.levels.find((l: any) => l.code === codeLevel)?.code)
      : ''}]
    this.selectedCompanies = user.UsersCompaniesRel
      .filter((up: any) => up.CompaniesUsers.deletedAt == null)
      .map((dataset: any) => {
        return { id: dataset.id, name: dataset.name }
      });
    this.selectedLicences = user.UsersLicencesRel
      .filter((up: any) => up.LicencesUsers.deletedAt == null)
      .map((dataset: any) => {
        return { id: dataset.id, code: dataset.code }
      });
  }

  selectDomain(user: any) {
    console.log(user);
    this.mapsState(user);
    /*
    this.selectedDomains = user.UsersDatasetsRel.map((dataset: DataSet) => {
      return { id: dataset.id, name: dataset.name }
    })
    */
  }

  selectProfile(user: any) {
    this.mapsState(user);
    /*
    this.selectedProfiles = user.UsersProfilesRel.map((dataset: DataSet) => {
      return { id: dataset.id, name: dataset.name }
    })
    */
  }

  selectLevel(user: any) {
    this.mapsState(user);
  }

  selectCompany(user: any) {
    console.log(user);
    this.mapsState(user);
    /*
    this.selectedCompanies = user.UsersCompaniesRel.map((dataset: DataSet) => {
      return { id: dataset.id, name: dataset.name }
    })
    */
  }

  selectLicence(user: any) {
    console.log(user);
    this.mapsState(user);
    /*
    this.selectedLicences = user.UsersLicencesRel.map((dataset: DataSet) => {
      return { id: dataset.id, name: dataset.name }
    })
    */
  }

  datasetsFilterDeleted(element: any) {
    return element?.filter((a: any) => a.UsersDatasets?.deletedAt === null)
  }

  licencesFilterDeleted(element: any) {
    return element?.filter((a: any) => a.LicencesUsers?.deletedAt === null)
  }

  companiesFilterDeleted(element: any) {
    return element?.filter((a: any) => a.CompaniesUsers?.deletedAt === null)
  }

  profilesFilterDeleted(element: any) {
    return element?.filter((a: any) => a.UsersProfiles?.deletedAt === null)
  }

  canViewLogs() {
    return this.actionService.checkPermissions(`actions_users_canViewLogs`)
  }

  canAssignLocations() {
    return this.actionService.checkPermissions(`actions_users_canAssignLocations`)
  }

  canAssignUserLicenses() {
    return this.actionService.checkPermissions(`actions_users_canAssignUserLicenses`)
  }

  canAssignUserProfiles() {
    return this.actionService.checkPermissions(`actions_users_canAssignUserProfiles`)
  }

  canAssignUserDomains() {
    return this.actionService.checkPermissions(`actions_users_canAssignUserDomains`)
  }



}
