import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Document, DocumentCreationParams, OrderDocument } from '../models/document';
import { LibService } from './libService';
import { AuthService } from '../auth/auth.service'
import { FindManyOptions, LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  baseApi = environment.rootApiCrm + 'v1.0/documents'

  constructor(
    private libService: LibService, 
    private logService: LogService,
    private authService: AuthService
    ) { }

  createDocument(document: DocumentCreationParams): Observable<Document>{
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<Document>(tokenId, document, this.baseApi, '/upload').pipe(
      tap(response => {
        let documentRes = {
          ...document,
          file: null
        }
          this.logService.writeLog('POST', this.baseApi, documentRes, 'OK')
      }),
      catchError(error => {
        let documentRes = {
          ...document,
          file: null
        }
          this.logService.writeLog('POST', this.baseApi, documentRes, error.status)
          return of(error);
      })
    )
  }

  upload(document: DocumentCreationParams): Observable<Document>{
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<Document>(tokenId, document, this.baseApi, '').pipe(
      tap(response => {
        let documentRes = {
          ...document,
          file: null
        }
          this.logService.writeLog('POST', this.baseApi, documentRes, 'OK')
      }),
      catchError(error => {
        let documentRes = {
          ...document,
          file: null
        }
          this.logService.writeLog('POST', this.baseApi, documentRes, error.status)
          return of(error);
      })
    )
  }

  getSignedUrl(documentUrl: string ){
    return this.libService.postData<string>({documentUrl}, this.baseApi, '/getSignedUrl')
  }

  find(options: FindManyOptions): Observable<Document[]>{
    return this.libService.postData<Document[]>(options, this.baseApi, '/find')
  }

  findByOrder(id: string): Observable<Document[]>{
    return this.libService.getData<Document[]>(this.baseApi, `/findByOrder/${id}`)
  }

  callDocComp(path:string, payload:any): Observable<any>{
    return this.libService.postData<Document[]>(payload, path, '')
  }

  findByOrders(ordersIds: number[]){
    return this.libService.postData<OrderDocument[]>(ordersIds, this.baseApi, '/findByOrders')
  }

  findLegacyDocumentsByOrders(orderCode: string){
    return this.libService.getData<any[]>(this.baseApi, `/findLegacyOrderDocuments/${orderCode}`)
  }

  getPracticeDocuments(practiceId: any){
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any[]>(tokenId, this.baseApi, `/practices/${practiceId}`)
  }

}
