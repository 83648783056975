import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-convention-modal',
  templateUrl: './add-convention-modal.component.html',
  styleUrls: ['./add-convention-modal.component.scss']
})
export class AddConventionModalComponent implements OnInit {

  editMode: boolean = false
  form: UntypedFormGroup

  conventions: any = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
    )
  {
    this.form = this.fb.group({
      conventionId: [null, [Validators.required]],
    })

    if(data){
      //if(data?.conventions.length == 1) this.form.get('conventionId')?.setValue(`${data?.conventions[0].id}`)
      if(data?.conventions) this.conventions = data?.conventions
    }


  }

  ngOnInit(): void {
  }

  clearSubject(){
    console.log('clearSubject')
    this.form?.get('subjectToId')?.setValue(null)
  }

  selSubject(event: any) {
    console.log('Selected: ', event);
    if(event?.id){
      this.form?.get('subjectToId')?.setValue(event.id)
    } 
  }

  requiresApproval(subjectRelationType: any){
    let requiresApprovalString = ``
    if(subjectRelationType.requiresApproval) requiresApprovalString = 'SUBJECT_RELATION_TYPES.requiresApproval'
    return requiresApprovalString
  }

}
