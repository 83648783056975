<div class="full-w" fxLayout="row" fxLayoutAlign="center center">
    <div class="row" fxFill>
      <div class="col pr-0">
        <input type="text" id="autocomplete" class="form-control" [(ngModel)]="address" (onAddressChange)="handleOnAddressChange($event)"
                    ngx-google-places-autocomplete (keydown.enter)="findAddress()" placeholder="{{ 'LABEL.INSERISCI_INDIRIZZO' | translate }}"
                    [disabled]="isWorking" [disabled]="edit">
      </div>
      <div class="col-auto ml-auto group-button-map">

        <button mat-button color="primary" (click)="findAddress()" [disabled]="edit || isWorking || !address?.length"
        class="with-icon">
          <mat-icon>search</mat-icon> <span>{{ 'ADDRESS.FIND' | translate }}</span>
        </button>
        <button *ngIf="!noButtonSearchCompany" mat-button color="primary" (click)="cercaZonaJaka()" [disabled]="edit || isWorking || !address?.length" class="with-icon">
          <mat-icon>search</mat-icon> <span>{{ 'ADDRESS.FIND_COMPANY' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

