import { C } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Request } from 'src/app/models/request';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { RequestService } from 'src/app/services/request.service';
import { RoleService } from 'src/app/services/role.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';
import { StateCategoryService } from 'src/app/services/state-category.service';
import { BranchService } from 'src/app/services/branch.service';

import { ActionService } from 'src/app/services/action.service';

@Component({
  selector: 'app-request-list-new',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss']
})
export class RequestListNewComponent implements OnInit, AfterViewInit {

  @ViewChild('workTypeCell', { read: TemplateRef }) workTypeCell: TemplateRef<any>;
  workTypeCellTemplateRef$ = new BehaviorSubject<any>(null);
  @ViewChild('addressCell', { read: TemplateRef }) addressCell: TemplateRef<any>;
  addressCellTemplateRef$ = new BehaviorSubject<any>(null);

  loggedUser: any;
  acceptedCompanies: any;
  profiles: any;
  lockrequest: any = false;

  columns: DatatableColumn<Request>[] = [
    {
      name: "id",
      flex: '7',
      title: this.translate.get('LABEL.ID'),
      cssClass: request => ['column-padding'],
      value: request => request.id
    },
    {
      name: "branch",
      title: this.translate.get('LABEL.BRANCH'),
      cssClass: request => ['column-padding'],
      value: request => {
        if (request.businessLineId !== null && this.branches) {
          let currentBranch = this.branches.find((b: any) => b.id == request.businessLineId)
          return this.translate.instant('ACTIVITIES.' + currentBranch?.code) as string
        } else {
          return this.translate.instant('LABEL.NOT_PRESENT') as string
        }
      }
    },
    {
      name: "workType",
      title: this.translate.get('LABEL.WORKTYPE'),
      cssClass: request => ['column-padding'],
      value: request => request.workType?.workType as string,
    },
    {
      name: "address",
      type: 'templateRef',
      title: this.translate.get('LABEL.ADDRESS'),
      cssClass: request => ['column-padding'],
      value: request => request,//request?.building?.streetName + ', ' + request.building?.streetNumber + ', ' + request.building?.zip + ', ' + request.building?.city + ', ' + request.building?.country,
      templateRef: request => this.addressCellTemplateRef$
    },
    {
      name: "assignmentOperator",
      flex: '20',
      title: this.translate.get('LABEL.WORKER_ASS'),
      cssClass: request => ['column-padding'],
      value: request => request.assignmentOperator
    },
    {
      name: "stateRequest",
      title: this.translate.get('LABEL.STATUS'),
      cssClass: request => ['column-padding'],
      value: request => {
        if (request.stateId !== null && this.stateCategories) {
          let currentState = this.stateCategories.find((stateCategory: any) => stateCategory?.states?.map((state:any) => state.id )?.includes(request.stateId))
          return this.translate.instant('STATECATEGORIES.' + currentState?.code) as string
        } else {
          return this.translate.instant('LABEL.NOT_PRESENT') as string
        }
      }


    },
    {
      name: "notes",
      title: this.translate.get('LABEL.NOTE'),
      cssClass: request => ['column-padding'],
      value: request => request.notes
    },
    {
      name: "createdAt",
      flex: '5',
      title: this.translate.get('LABEL.CREATION_DATE'),
      cssClass: request => ['column-padding'],
      value: request => this.datePipe.transform(request.createdAt?.toString())
    },
    {
      name: "updatedAt",
      flex: '5',
      title: this.translate.get('LABEL.LASTMODIFIED_DATE'),
      cssClass: request => ['column-padding'],
      value: request => this.datePipe.transform(request.updatedAt?.toString())
    }
  ]

  actions: DatatableAction<Request>[] = [
    {
      label: of(''),
      icon: request => {
        return this.iconRoutine(request)
      },
      onClick: request => this.goTo(request),
      color: 'primary'
    },
    /*
    {
      label: of(''),
      icon: subject => {
        return 'delete'
      }, // 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    }
    */
  ];

  displayedColumns: string[] = ['id', 'branch', 'workType', 'address', 'assignmentOperator', 'stateRequest', 'notes', 'createdAt', 'updatedAt']; //'contact','phone_mail',
  dataSource: any;
  requests: any;
  kwSearch: any;
  //title = "Requests";
  //subTitle = "EDAC - Requests";
  // @ViewChild(MatSort) set matSort(sort: MatSort) {
  //   this.dataSource.sort = sort;
  // }

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;

  filterOptions: any = {};
  currentPage = 0;
  companyIdsFilter: any = []
  responsiblesFilter: any = []
  idsFilter: any = []
  orderOptions: any = {}

  currentUrl: string;
  request: any;
  itemType: string = 'requests'

  stateCategories: any = null

  branches: any = null
  activeBranches: any = null

  constructor(
    public navService: NavService,
    public requestService: RequestService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private roleService: RoleService,
    private actionService: ActionService,
    private stateCategoryService: StateCategoryService,
    private branchService: BranchService
  ) {
    this.userSessionService.getUser(this.authService.user().email).subscribe(val => {
      this.loggedUser = val;
      this.profiles = this.loggedUser[0]?.UsersProfilesRel.map((a: any) => a.name);
      this.acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
    });
  }

  ngOnInit(): void {

    this.stateCategoryService.index().subscribe((response: any) => {
      this.stateCategories = response.filter((stateCategory:any)=> stateCategory.itemCategory.code == 'item_categories_requests')
    })

    this.branchService.getAllBranches().subscribe((response: any) => {
      this.branches = response
      this.activeBranches = response.filter((branch:any)=> branch.isActive)
    })

    this.loadDataSource();
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }
  }

  ngAfterViewInit() {
    // this.workTypeCellTemplateRef$.next(this.workTypeCell);
    this.addressCellTemplateRef$.next(this.addressCell);
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  checkPermissions(actionType: any) {
    return this.actionService.checkPermissions(`${this.itemType}_${actionType}`)
  }

  goTo(e: any) {
    this.router.navigate(['request/' + e.id]);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  deleteRow(e: any) {

    if (confirm(this.translate.instant('LABEL.DELETE_CONFIRMATION')) == true) {
      const id = e.id;
      if (id && parseInt(id) > 0) {
        console.log("ID: " + id);
        this.requestService.deleteRequest(id, this.request).subscribe((response: any) => {
          this.libService.unlockPage();
          this.toastr.success(this.translate.instant('LABEL.ROW_DELETED'), this.translate.instant('TOASTR.INFO'));
          console.log(response);
          window.location.reload();
        },
          err => {
            console.log(err);
            this.libService.unlockPage();
            this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
          });
      }
      else {
        this.toastr.success(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.INFO'));
      }
    }


  }

  resetOrder() {
    this.orderOptions = {}
  }

  resetPagination() {
    this.currentPage = 0;
    this.pageInit = 0;
  }

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }

  loadDataSource(reset: boolean = false) {
    console.log('loadDataSource')
    this.libService.lockPage('');
    if (!this.lockrequest) {
      this.lockrequest = true;



      if (reset) {
        this.resetOrder()
        this.resetPagination()
        this.filterOptions.ids = []
      }

      this.requestService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize).subscribe((responseData: any) => {

        this.totalRows = responseData.count;
        this.dataSource = responseData.items
        this.requests = responseData.items;
        this.lockrequest = false;
        this.libService.resetLockPage();
      }, (err: any) => {
        this.lockrequest = false;
        this.libService.resetLockPage();
        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
      });
    }

  }

  iconRoutine(request: any) {
    //INIZIO ROUTINE PER VISIBILITY
    let profile: any;

    this.roleService.getMainRole(this.loggedUser);

    switch (this.loggedUser[0].role) {
      case 'Responsabile Area': case 'Affiliato':
        return this.acceptedCompanies.includes(request.building?.company) || request.assignmentOperator === this.loggedUser[0].email ? 'edit' : 'visibility';
        break;

      case 'Tecnico Commerciale':
        // !(this.acceptedCompanies.includes(request.building?.company) &&
        return !(request.assignmentOperator === this.loggedUser[0].email) ? 'visibility' : 'edit'
        break;

      case 'BackOffice':
        return 'visibility';
        break;
      case 'Responsabile Commerciale': case 'Coordinatore Country': case 'Direzione': case 'Top Management': case 'Contabilita': case 'Amministrazione': case 'HR': case 'Responsabile Mkt':
        return this.acceptedCompanies.includes(request.building?.company) || request.assignmentOperator === this.loggedUser[0].email ? 'edit' : 'visibility';

        break;
      case 'Coordinatore':
        this.dataSource = [];
        return 'visibility';
        break;
      default:
        return 'edit'
        break;
    }
  }

  filterResultEmitted(event: any) {
    console.log(event)
    if (Object.keys(event).length != 0) {
      this.filterOptions = {
        companies: event.companies,
        responsibles: event.responsibles,
        years: event.years,
        states: event.states,
        businessLines: event.branches,
        ids: this.idsFilter
      }
      this.resetPagination()
      this.loadDataSource();
    }
  }

  filterResultResetEmitted(event: any) {
    console.log('RESET FILTERS')
    this.filterOptions = {
      companies: [],
      responsibles: [],
      years: [],
      states: [],
      ids: this.idsFilter
    }

    this.resetPagination()
    this.loadDataSource();

  }

  updateList(e: any) {
    console.log("EVENTO  LISTA --> ", e);
    if (e.length > 0) {
      //  this.dataSource = e;
      console.log(e)
      this.idsFilter = e.map((item: any) => item.id)
      this.filterOptions.ids = this.idsFilter
    }
    this.loadDataSource()
  }

  clearList(e: any) {
    console.log("CANCELLA --> ", e);
    if (e) {
      this.idsFilter = []
      this.filterOptions.ids = []
      this.loadDataSource()
    }
  }

}
