import { ChangeDetectorRef, Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap, of, tap, Observable, combineLatest, forkJoin, concatMap, pairwise, Subject as Subject2 } from 'rxjs'
import { Status } from 'src/app/models/status';
import { Subject } from 'src/app/models/subject';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { CompanyService } from 'src/app/services/companyService';
import { WikiService } from 'src/app/services/wikiservice.service';
import { CrmFormPracticesComponent } from './crm-form-practices/crm-form-practices.component';
import { AuthService } from 'src/app/auth/auth.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { UserDataService } from 'src/app/services/users.service';
import { RoleService } from 'src/app/services/role.service';
import { C } from '@angular/cdk/keycodes';


// TODO: alcuni nomi non combaciano con il model CRM, rivederli
@Component({
  selector: 'app-crm-form',
  templateUrl: './crm-form.component.html',
  styleUrls: ['./crm-form.component.scss']
})
export class CrmFormComponent implements OnInit, OnChanges {

  @Input() mainForm: any;
  @Input() documentsSection: any = false;
  @Input() questionnairesSection: any = false;
  @Input() subjectRelationsSection: any = false;
  @Input() subjectActivitiesSection: any = false;
  @Input() subjectPracticesSection: any = false;
  @Input() companies: any = []
  @Input() subjectAttributes: any = []

  @ViewChild('child') child: CrmFormPracticesComponent;
  matTabSelectedIndex = 0;
  isNew: boolean;
  //formSubject: UntypedFormGroup;
  subject: Subject;
  statuses: Status[];
  statusesSubject: Status[];
  subjectIndex: any;
  type: string;
  types: any;
  newSubject: any;

  nome: any;
  validationFields = ['telephone2', 'email'];
  save: boolean = false;
  loggedUser: any;
  toDisable: boolean = false;
  users: any;
  totUsers: any;
  operatorList: any;
  disableActivity: boolean;
  companyLocal: any;
  checkDuplicate$: Observable<any>;
  typeFix: any;
  resp: any;
  potenzialeBuilding: any;
  potenzialeSubject: any;
  oplist2: any = [];
  selectedTabIndex: number;

  components: any = null

  loggedUser$ = this.userSessionService.getUser(this.authService.user().email).pipe(map((val: any) => this.loggedUser = val));

  // users$ = this.loggedUser$.pipe(
  //   switchMap((val:any) => {
  //     this.roleService.getMainRole(this.loggedUser);
  //     let profiles: Array<any> = [];
  //     switch (this.loggedUser[0].role) {
  //       case 'Area Manager': case 'Responsabile Area':
  //         // this.edit = false;
  //         profiles = [12, 13, 14];
  //         return this.loggedUser$.pipe(switchMap((val: any) => this.userService.getByProfiles(profiles)));
  //         break;

  //       default:
  //         profiles = [12, 13, 14];
  //         // // this.formSubject.get('assignmentOperator')?.disable();
  //         return this.loggedUser$.pipe(switchMap((val: any) => this.userService.getByProfiles(profiles)));
  //         // return this.loggedUser$.pipe(switchMap((val: any) => this.userService.getAllUsers()));
  //         break;
  //     }
  //   })
  // );


  companies$ = this.companyService.getAllCompaniesForRequest().pipe(
    map((companies: any[]) => {
      return companies.sort((a, b) => a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      this.companies = [...new Set(companies)]//TODO: FIX
      this.companies.push({ id: 0, code: null, name: '' })
    })
  );

  params$ = this.route.paramMap.pipe(
    map(params => {
      return {
        id: params.get('id'),
        type: params.get('type')
      }
    }),
    tap(params => {
      if (params.id !== '0') {
        this.isNew = false;
      } else {
        this.isNew = true;
      }

      this.formSubject.get('type')?.setValue(params.type, { emitEvent: false })
      // this.typeFix = params.type;
    })
  );


  subject$ = this.params$.pipe(
    switchMap(params => {
      //console.log('subject$')
      let subjectId = this.formSubject?.get('id')?.value
      let obs: any;
      obs = params.id !== '0' ? this.subjectService.getSubjectWithAccessoryAttributes(subjectId) : of('');
      return obs;
    })
  )

  typesFixed = ['Amministratore', 'Edificio', 'Condominio'];
  types$ = this.subjectService.getAllTypes().pipe(
    map(types => types.filter(type => !(this.typesFixed.includes(type.description!)))),
    tap(types => types.sort((a, b) => a.description!.toUpperCase() <= b.description!.toUpperCase() ? -1 : 1))
  );

  allTypes$ = this.subjectService.getAllTypes().pipe(
    tap(types => types.sort((a, b) => a.description!.toUpperCase() <= b.description!.toUpperCase() ? -1 : 1))
  );

  statusesSubject$ = this.statusService.getAll('Subject');

  constructor(
    public navService: NavService,
    public translate: TranslatorService,
    private route: ActivatedRoute,
    private router: Router,
    private subjectService: SubjectService,
    private fb: UntypedFormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    private statusService: StatusService,
    private companyService: CompanyService,
    public wikiService: WikiService,
    private authService: AuthService,
    private userSessionService: UserSessionService,
    private userService: UserDataService,
    private cdr: ChangeDetectorRef,
    private roleService: RoleService) {
    
  }


  goToWiki() {
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }


  getStructure(subjectTypeId: number) {
    console.log('getStructure')
    let companyCode = this.formSubject.get('company')?.value || null
    let currentCompany = this.companies.find((company: any) => company.code == companyCode)
    let companyId = currentCompany?.id || null

    this.subjectService.getSubjectFormStructure(subjectTypeId, companyId).subscribe((response: any) => {
      this.components = response
      this.formSubject?.get('components')?.setValue(response)
    })
  }

  getName() {
    let name = this.formSubject.get('name')?.value || null
    let surname = this.formSubject.get('surname')?.value || null
    let fullName = []

    if (name) fullName.push(name)
    if (surname) fullName.push(surname)

    if (fullName.length > 0) return fullName.join(' ')
    return null
  }
  getLegalEntity() {
    let legalEntity = this.formSubject.get('legalEntity')?.value || null
    return legalEntity
  }

  getSubjectType() {
    let subjectType = this.formSubject.get('subjectType')?.value || null
    return subjectType?.description
  }

  checkSection(sectionCode: string) {
    if (this.components) {
      let currentComponent = this.components.find((component: any) => component.componentCode == sectionCode)
      if (currentComponent) return true
    }
    return false
  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes - CRM FORM')
    console.log(changes)

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'formSubject': {
            console.log(changes[propName].currentValue)
          }
        }
      }
    }
  }

  getComponents() {
    return this.formSubject.get('components')?.value
  }

  get formSubject() {
    return this.mainForm.controls['subject'] as UntypedFormGroup
  }

  ngOnInit(): void {

    // console.log('GET WIKI KEY %o', this.navService.getWikiKey());

    this.formSubject.get('responsible')?.valueChanges.subscribe((val: any) => {
      console.log("FIRED --> ");
    })

    this.formSubject.get('components')?.valueChanges.subscribe((components: any) => {
      this.components = components
    })

    this.formSubject.get('subjectType')?.valueChanges.subscribe((type: any) => {
      console.log('subjectType')
      console.log(type?.id)
      let subjectTypeId = type?.id
      if (subjectTypeId) this.getStructure(subjectTypeId)
    })

    this.navService.setWikiKey('NODEF');
    this.types$.subscribe(val => {
      this.types = val;
    })
    this.initNavabar();

    this.formSubject.get('company')?.valueChanges.pipe(pairwise()).subscribe(([prev, val]: any) => {
      console.log("CURRENT VALUE COMPANY --> ", val)
      if ((val !== null && val !== undefined) && (prev !== val)) {
        this.roleService.getMainRole(this.loggedUser);
        this.isNew === true ?
          this.handleUsers(val) :
          console.log("NO USERS FOR COMPANY");
        let sessionComp = this.userSessionService.getState(val);
        if (sessionComp !== null && sessionComp !== undefined) {
          this.companyLocal = sessionComp;
        }
        else {
          if (!(val == null) && !(val.company == null)) {
            this.companyService.getCompanyByCode(val.company).subscribe(
              (com) => {
                this.companyService.getCompanyOperational(com.id).subscribe(company => {
                  console.log("COMPANY TROVATA --> ", company);
                  this.companyLocal = company;
                  this.userSessionService.saveState(this.companyLocal.code, this.companyLocal)
                })
              }
            );
          }
        }
        if (val !== prev) {
          let r = this.userSessionService.getState(this.formSubject.get('responsible')?.value);
          r !== null && r !== undefined ? this.resp = r : undefined;
          ((this.companyLocal !== null && this.companyLocal !== undefined) ?
            ((this.companyLocal.UsersCompaniesManagerRelId !== null && this.companyLocal.UsersCompaniesManagerRelId !== undefined) ?
              this.userService.getUser(this.companyLocal.UsersCompaniesManagerRelId).subscribe(usr => {
                let ind: any;
                if (usr.role !== "EX_RISORSA") {
                  ind = this.users?.indexOf(this.resp);
                  ind > -1 ? this.users.splice(ind, 1) : undefined;
                  this.resp !== null && this.resp !== undefined ? this.users.push(this.resp) : undefined;
                  this.resp = usr;
                  this.userSessionService.saveState(this.resp.email, this.resp);
                  console.log("RESPOSNSNSNSAL-->", this.resp);
                }

              })
              : undefined)
            : undefined);
        }
        this.companyLocal !== null && this.companyLocal !== undefined ?
          this.updateResponsible(val) : undefined;
      }
      //this.cdr.detectChanges();

    })

    this.formSubject.get('type')?.valueChanges.subscribe((type: any) => {
      this.type = type
      this.statuses = this.statusesSubject
      let newStatus = this.statuses?.find(status => status.code === 'NEW');
      this.isNew ?
        this.formSubject.patchValue({ status: newStatus }) :
        undefined;
    });

    this.formSubject.get('id')?.valueChanges.subscribe((id: any) => {
      this.initNavabar();
    })
    this.libService.lockPage('');
    combineLatest([this.subject$, this.statusesSubject$, this.loggedUser$]).subscribe({
      next: ([subject, statusesSubject]: any) => {
        console.log('subject: controllare il responsible-->', subject);
        // this.totUsers = users;
        this.statusesSubject = statusesSubject;
        if (subject?.responsible !== null && subject?.responsible !== undefined && subject?.responsible.trim() !== "") {
          //this.oplist2.push(subject.responsible);
          this.oplist2 = [subject.responsible]
          this.formSubject.patchValue({ responsible: subject.responsible })
          // this.formSubject.get('responsible')?.updateValueAndValidity();
          // this.cdr.detectChanges();
        }

        this.potenzialeSubject = this.statusesSubject.find(status => status.code === 'SS8');
        this.statuses = this.statusesSubject

        if (subject !== '') {
          console.log("THIS.SUBJECT -->", subject);
          this.subject = subject;
          console.log("OPTION LIST --> ", this.oplist2);
          this.formSubject.patchValue(this.subject, { emitEvent: true });
          this.type !== 'Condominio' && this.type !== 'Edificio' && this.type !== 'Privato' ?
            this.formSubject.patchValue({ legalEntity: this.subject.legalEntity }) : undefined;
          this.checkVisibility();
          this.subject.company ? this.formSubject.get('company')?.disable() : undefined;

        } else {

        }

        let newStatus = this.statuses.find(status => status.code === 'NEW');
        this.isNew ?
          this.formSubject.patchValue({ status: newStatus }) :
          undefined;
        this.libService.unlockPage();

      },
      error: (err) => {
        console.log(err);
        this.libService.unlockPage();
      }
    })
  }

  update() {

    this.setBuildingNameOrFail();
    this.checkPhoneMail();
    this.checkDuplicateSubject();
    let action = '';

    if (this.formSubject.valid && this.save) {

      switch (this.formSubject.get('type')?.value) {
        default:
          this.userSessionService.saveState('getAllSubjects', null);
          break;
      }

      const type = this.types.filter((item: any) => item.description === this.formSubject.get('type')?.value);

      (type.description !== 'Amministratore' && type.description !== 'Condominio' && (this.formSubject.get('name')?.value == null)) ? this.formSubject.patchValue({ name: this.formSubject.get('legalEntity')?.value }) : undefined;
      if (this.isNew) {
        action = 'create';
        let subjectCreation$;

        let duplicate: any;
        switch (this.formSubject.get('type')?.value) {

          default:
            this.formSubject.patchValue({ status: this.potenzialeSubject });
            subjectCreation$ = this.checkDuplicate$.pipe(switchMap(v => {
              console.log("risposta duplicate soggetto --> ", v); duplicate = v;
              return (duplicate !== null) ? of(null) :
                this.subjectService.createWithAccessoryAttributes(this.formSubject.getRawValue());
            }))
            break;
        }
        subjectCreation$.subscribe(
          (response: any) => {
            if (response !== null) {
              this.libService.unlockPage();
              this.newSubject = response;
              console.log("new subject -------> ", this.newSubject)
              let req = response.body !== null && response.body !== undefined ? response.body : response

              this.router.navigate(['subject/' + req.id, { type: 'Soggetto' }])//

              this.libService.unlockPage();
              // this.router.navigate(['subject/' + response.body.id, { type: response.body.type }])
              this.toastr.success(
                this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
              console.log(response);
            } else {

              this.toastr.error(this.translate.instant('TOASTR.MESSAGE25') + `<br> ${duplicate.businessName ? duplicate.businessName : ''} ${duplicate.name ? duplicate.name : ''} ${duplicate.surname ? duplicate.surname : ''}`, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
            }

          },
          (error: any) => {
            console.log(error);
            this.toastr.error(error, this.translate.instant('TOASTR.WARNING'));
            this.libService.unlockPage();
          }
        );
      } else {

        action = 'update';
        console.log('update form %o', this.formSubject.getRawValue())
        let subjectUpdate$;
        let duplicate: any;
        switch (this.formSubject.get('type')?.value) {
          default:
            this.formSubject.get('status')?.value === null || this.formSubject.get('status')?.value === undefined || this.formSubject.get('status')?.value === " " ?
              this.formSubject.patchValue({ status: this.potenzialeSubject }) : null;
            subjectUpdate$ = this.checkDuplicate$.pipe(switchMap(v => {
              console.log("risposta duplicate --> ", v); duplicate = v;
              // this.formSubject.get('id')?.value
              return v?.id !== null && v?.id !== undefined && v?.id !== this.formSubject.get('id')?.value ?
                of(null) :
                this.subjectService.updateSubjectWithAccessoryAttributes(this.formSubject.get('id')?.value, this.formSubject.getRawValue());
            }))
            // subjectUpdate$ = this.subjectService.updateSubjectWithAccessoryAttributes(this.formSubject.get('id')?.value, this.formSubject.getRawValue())
            break;
        }
        subjectUpdate$.subscribe({
          next: (response) => {

            this.libService.unlockPage();
            if (response !== null) {
              console.log("SOGGETTO CREATO --> ", response);
              // this.subject = response;
              this.toastr.success(
                this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
              // this.saveIndex();
              switch (response.type) {
                default:
                  this.router.navigate(['subject/' + response.id, { type: 'Soggetto' }]).then(() => {
                    // window.location.reload();
                  })
                  break;
              }
              // this.router.navigate(['subject/' + response.id, { type: response.type }])
              console.log("UPDATE RESPONSE -->", response);
              // this.saveIndex(action)
            } else {
              this.toastr.error(this.translate.instant('TOASTR.MESSAGE25') + `<br> ${duplicate.businessName ? duplicate.businessName : ''} ${duplicate.name ? duplicate.name : ''} ${duplicate.surname ? duplicate.surname : ''}`, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });

            }
          },
          error: (error: any) => {
            console.log(error);
            this.toastr.error(this.translate.instant('TOASTR.MESSAGE27'), this.translate.instant('TOASTR.WARNING'));
            this.libService.unlockPage();
          }
        });
      }
    } else {
      this.save == false ?
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE24'), this.translate.instant('TOASTR.WARNING')) :
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_1'), this.translate.instant('TOASTR.WARNING'));
    }
  }

  onTypeChange(type: string) {

    if (type) {
      let controlArrayRequired: (AbstractControl | null)[] = []
      let controlArrayEmail: (AbstractControl | null)[] = []
      console.log("ON TYPE CHANGE SUBJECT DETAIL --> ", type);
      switch (type) {
        case 'Privato':
          controlArrayRequired.push(this.formSubject.get('name'))
          controlArrayRequired.push(this.formSubject.get('surname'))
          controlArrayEmail.push(this.formSubject.get('email'))
          controlArrayRequired.push(this.formSubject.get('mobileTelephone'))
          controlArrayRequired.push(this.formSubject.get('streetName'))
          controlArrayRequired.push(this.formSubject.get('streetNumber'))
          controlArrayRequired.push(this.formSubject.get('city'))
          controlArrayRequired.push(this.formSubject.get('country'))
          controlArrayRequired.push(this.formSubject.get('zip'))
          controlArrayRequired.push(this.formSubject.get('responsible'))
          controlArrayRequired.push(this.formSubject.get('origin'))
          controlArrayRequired.push(this.formSubject.get('type'))
          break;

        case 'Società' || 'Ente' || 'Negozio' || 'Società immobiliare' || 'Hotel' || 'Studio tecnico' || 'Ospedale' || 'Struttura religiosa' || 'Pubblica amministrazione' || 'Scuola':
          controlArrayRequired.push(this.formSubject.get('legalEntity'))
          controlArrayEmail.push(this.formSubject.get('email'))
          controlArrayRequired.push(this.formSubject.get('mobileTelephone'))
          controlArrayRequired.push(this.formSubject.get('streetName'))
          controlArrayRequired.push(this.formSubject.get('streetNumber'))
          controlArrayRequired.push(this.formSubject.get('city'))
          controlArrayRequired.push(this.formSubject.get('country'))
          controlArrayRequired.push(this.formSubject.get('zip'))
          controlArrayRequired.push(this.formSubject.get('responsible'))
          controlArrayRequired.push(this.formSubject.get('origin'))
          controlArrayRequired.push(this.formSubject.get('type'))
          break;

        case 'Amministratore':
          controlArrayRequired.push(this.formSubject.get('name'))
          controlArrayRequired.push(this.formSubject.get('surname'))
          controlArrayRequired.push(this.formSubject.get('legalEntity'))
          controlArrayRequired.push(this.formSubject.get('mobileTelephone'))
          controlArrayRequired.push(this.formSubject.get('streetName'))
          controlArrayRequired.push(this.formSubject.get('streetNumber'))
          controlArrayRequired.push(this.formSubject.get('city'))
          controlArrayRequired.push(this.formSubject.get('country'))
          controlArrayRequired.push(this.formSubject.get('zip'))
          controlArrayRequired.push(this.formSubject.get('responsible'))
          controlArrayRequired.push(this.formSubject.get('origin'))
          break;
      }

      controlArrayRequired.forEach(control => {
        control?.setValidators([Validators.required])
        control?.updateValueAndValidity()
      })

      controlArrayEmail.forEach(control => {
        control?.setValidators([Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")])
        control?.updateValueAndValidity()
      })
    }
  }

  initNavabar() {
    setTimeout(() => {
      let actions: any;
      actions = [
        { name: 'back', url: 'crm', params: { tabName: 'subjects' } }
      ];
      // console.log('id: ', this.formSubject.get('id')?.value);

      let title = this.formSubject.get('id')?.value !== null ?
        this.translate.instant('LABEL.EDIT', { field: this.translate.instant('LABEL.SUBJ') }) :
        this.translate.instant('LABEL.NEW', { field: this.translate.instant('LABEL.SUBJ') })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }

  checkOther() {
    let type = this.formSubject.get('type')?.value
    let typesVisualized = ['Edificio', 'Amministratore', 'Condominio']
    return !typesVisualized.includes(type)
  }

  getFormValidationErrors() {
    let ret = '';
    Object.keys(this.formSubject.controls).forEach(key => {
      //console.log(' - check: %o',this.formSubject.get(key)?.errors);
      var controlErrors: any = this.formSubject.get(key)?.errors;
      if (!(controlErrors == null)) {
        //console.log(' - check: %o',this.formSubject.get(key)?.errors);
        Object.keys(controlErrors).forEach(keyError => {
          ret = ret + this.translate.instant('LABEL.CAMPO_VALIDAZIONE') + key + this.translate.instant('LABEL.ERRORE_VALIDAZIONE') + keyError;
        });
      }
    });
    return ret;
  }

  getErrorTooltip() {
    return this.translate.instant('LABEL.FORM_ERROR_VALITATION') + this.getFormValidationErrors();
  }


  setBuildingNameOrFail() {

    if (this.formSubject.get('type')?.value === 'Edificio') {
      let streetName = this.formSubject.get('streetName')?.value
      let streetNumber = this.formSubject.get('streetNumber')?.value
      this.formSubject.get('name')?.setValue(streetName + ' ' + streetNumber)
    }
  }

  alertSubject() {

    if ((this.formSubject.get('telephone2')?.value === null || this.formSubject.get('telephone2')?.value === "") &&
      (this.formSubject.get('email')?.value === null || this.formSubject.get('email')?.value === "")) {
      this.formSubject.get('type')?.value !== 'Edificio' && this.formSubject.get('type')?.value !== 'Condominio' ?
        console.log('SONO IO QUELLO CHE CERCHI') : undefined;
      // 'È obbligatorio compilare alternativamente o Email o Numero Telefono Mobile'
    }
    if (this.subject?.type === 'Amministratore' && this.isNew == false) {
      this.toastr.warning(this.translate.instant('TOASTR.MESSAGE26'), this.translate.instant('TOASTR.MEMO'));
    }
  }

  checkPhoneMail() {
    let count = 0;
    let subj = this.formSubject.getRawValue();
    if (subj.type !== 'Edificio' && subj.type !== 'Condominio') {
      this.validationFields.forEach(element => {
        if (subj[element] !== null && subj[element].toString().trim() !== "") {
          count++;
        }
      })
      count > 0 ? this.save = true : this.save = false;
    } else {
      this.save = true;
    }
  }

  checkVisibility() {

    let acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
    this.roleService.getMainRole(this.loggedUser);

    console.log("USER LOGGATO CHECK VISIBILITY --> ", this.loggedUser);

    switch (this.loggedUser[0].role) {
      case 'Area Manager': case 'Responsabile Area': case 'Affiliato':
        if (!acceptedCompanies.includes(this.subject.company)) {
          //PEZZO DI CODICE PER DISABILITARE TUTTI I CAMPI
          this.toDisable = true;
          Object.keys(this.formSubject.controls).forEach(key => {
            this.formSubject.get(key)?.disable();
          });
          //______________FINE QUI________________________
        }
        break;

      case 'Tecnico Commerciale':
        if (!(this.subject.responsible === this.loggedUser[0].email)) {
          this.toDisable = true;
          this.disableActivity = true;
          Object.keys(this.formSubject.controls).forEach(key => {
            this.formSubject.get(key)?.disable();
          });
        }
        break;
      case 'BackOffice':
        // BACKOFFICE PUO MODIFICARE TUTTI I SOGGETTI
        this.disableActivity = true;
        this.toDisable = false;
        break;
      case 'Coordinatore':
        // BACKOFFICE PUO MODIFICARE TUTTI I SOGGETTI
        this.disableActivity = true;
        this.toDisable = false;
        break;
      case 'Responsabile Commerciale': case 'Coordinatore Country': case 'Direzione': case 'Top Management': case 'Contabilita': case 'Amministrazione': case 'HR': case 'Responsabile Mkt':
        if (!(acceptedCompanies.includes(this.subject.company) && this.subject.responsible === this.loggedUser[0].email)) {
          //PEZZO DI CODICE PER DISABILITARE TUTTI I CAMPI
          this.toDisable = true;
          this.disableActivity = true;
          Object.keys(this.formSubject.controls).forEach(key => {
            this.formSubject.get(key)?.disable();
          });
          //______________FINE QUI________________________
        }
        break;
      default:
        break;
    }
  }

  checkDuplicateSubject() {
    let subject = this.formSubject.getRawValue();
    console.log("SUBJCT: CONTROLLARE IL TIPO --> ", subject)

    // if (this.isNew) {
    switch (subject.type) {
      default:
        this.checkDuplicate$ = this.subjectService.findSubjectByPhoneAndMail(subject);
        break;
    }
    // }
  }


  handleUsers(company: any) {
    try {
      let acceptedCompanies: Array<any> = [];
      acceptedCompanies.push(company);
      let opList: Array<any> = [];

      if (acceptedCompanies.length > 0) {

        this.userService.getByCompanyCode(acceptedCompanies).subscribe(comps => {
          this.users = comps.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
          this.users.forEach((element: any) => {
            element.UsersCompaniesRel.forEach((element2: any) => {
              acceptedCompanies?.includes(element2.code) ? opList.push(element) : undefined;
            });
          });
          this.operatorList = [... new Set(opList.map(tag => tag))];

          this.oplist2 = this.operatorList.map((u: any) => u.email);
          //this.cdr.detectChanges();

        })
      }

      console.log("OPERATOR LIST --> ", this.operatorList);
    } catch {

    }
  }
  ngAfterViewChecked() {
    //this.cdr.detectChanges();
  }

  updateResponsible(input: any) {
    this.roleService.getMainRole(this.loggedUser);
    let companyLocal: any; let responsible: any;
    companyLocal = this.companyLocal;
    console.log("COMPANY LOCAL TROVATA --> ", this.companyLocal);
    let acceptedCompanies = this.loggedUser !== undefined ? this.loggedUser[0]?.UsersCompaniesRel?.map((a: any) => a.code) : [];
    if (this.loggedUser !== undefined && input !== null && input !== undefined) {
      switch (this.loggedUser[0]?.role) {
        case 'Tecnico Commerciale':
          console.log("acceptedCompanies --> ", acceptedCompanies);
          this.formSubject.get('responsible')?.disable();
          // companyLocal = this.companies.find((company: any) => company.code === input);
          console.log("COMPANY LOCAL --> ", companyLocal);
          if (acceptedCompanies.includes(companyLocal?.code)) {
            this.isNew === true ?
              responsible = this.loggedUser[0] : responsible = this.resp//companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);;
          } else {
            responsible = this.resp  //companyLocal.CompaniesUsersRel.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          }
          console.log("RESPONSABILE RETRIEVED --> ", responsible);
          this.formSubject.patchValue({ responsible: responsible?.email })
          break;

        case 'Responsabile Area': case 'Affiliato':
          console.log("acceptedCompanies --> ", acceptedCompanies);
          // this.formSubject.get('responsible')?.disable();
          // companyLocal = this.companies.find((company: any) => company.code === input);
          console.log("COMPANY LOCAL --> ", companyLocal);
          if (acceptedCompanies.includes(companyLocal?.code) && this.formSubject.get('responsible')?.value !== null && undefined) {
            responsible = this.loggedUser[0];
          } else {
            responsible = this.resp// companyLocal?.CompaniesUsersRel?.find((user: any) => user.id === companyLocal.UsersCompaniesManagerRelId);
          }
          console.log("RESPONSABILE RETRIEVED --> ", responsible);
          if (!(this.formSubject.get('responsible')?.value))
            this.formSubject.patchValue({ responsible: responsible?.email })
          break;

        default:
          break;
      }
    }
  }

  changeTab(event: any) {
    this.matTabSelectedIndex = event.index
    console.log("INDICE DI SELEZIONE DEL FORM SOGGETTO --> ", event);
  }

}
