import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLicenceComponent } from './admin-licence.component';
import { SharedModule } from '../shared/shared.module';
import { AdminLicenceDetailComponent } from './admin-licence-detail/admin-licence-detail.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AdminLicenceFilterComponent } from './admin-licence-filter/admin-licence-filter.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const MATERIAL_MODULES = [
  MatIconModule,
  MatSelectModule,
  MatInputModule,
  MatButtonModule,
  MatSlideToggleModule,
  MatDatepickerModule
]

@NgModule({
  declarations: [
    AdminLicenceComponent,
    AdminLicenceDetailComponent,
    AdminLicenceFilterComponent
  ],
  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule
  ],
  exports: [
    AdminLicenceComponent
  ]
})
export class AdminLicenceModule { }
