import { Component, ChangeDetectionStrategy, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FinanceService } from '../../../services/finance.service';
import { UserSessionService } from '../../../services/user-session.service';
import { LibService } from '../../../services/libService';

@Component({
  selector: 'planner-boxs',
  templateUrl: './planner-boxs.component.html',
  styleUrls: ['./planner-boxs.component.scss'],
})
export class PlannerBoxsComponent {
  rowHeight: string = '240px';
  loading: boolean = false;
  currentOrder: any;

  @Input() plannerType: any = 'core';
  cols: number;

  stockLoad: boolean = false;

  constructor(
    private router: Router,
    private financeService: FinanceService,
    private userSessionService: UserSessionService,
    private libService: LibService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  @HostListener('window:resize', ['$event'])
  ngOnInit(event: any) {
    this.setStocks();
    this.financeGetStockTotali();
    this.setCols(event?.target?.innerWidth);
  }
  ngAfterViewInit() {
    // Forza un rilevamento delle modifiche dopo che la vista è stata inizializzata
    this.cdr.detectChanges();
    // Forza un ridimensionamento per garantire che il layout sia aggiornato
    this.onResize({ target: { innerWidth: window?.innerWidth } });
  }

  onResize(event: any) {
    this.setCols(event?.target?.innerWidth);
  }

  setCols(width: number) {
    if (width <= 600) {
      this.cols = 1;  // Una colonna per schermi piccoli
    } else if (width <= 960) {
      this.cols = 2;  // Due colonne per schermi medi
    } else if (width <= 1152) {
      this.cols = 2;  // Tre colonne per schermi grandi
    } else if (width <= 1481) {
      this.cols = 5;  // Quattro colonne per schermi molto grandi
    } else {
      this.cols = 5;  // Quattro colonne per schermi molto grandi
    }
  }

  stocks: any = [
    {
      title: 'Deliberate (no progr.) da più di 7 gg',
      stockNumber: '0',
      numberOfOrders: '0',
      code: 'deliberatedForMoreThan7Days',
      color: 'boxOrange',
    },
    {
      title: 'Deliberate (no progr.) da meno di 7 gg',
      stockNumber: '0',
      numberOfOrders: '0',
      code: 'approvedForLessThan7Days',
      color: 'boxYellow',
    },
    {
      title: 'Commesse in programmazione',
      stockNumber: '0',
      numberOfOrders: '0',
      code: 'planningOrders',
      color: 'boxGreen',
    },
    {
      title: 'Commesse sospese',
      stockNumber: '0',
      numberOfOrders: '0',
      code: 'suspendedOrders',
      color: 'boxRed',
    },

    {
      title: 'Commesse non programmabili',
      stockNumber: '0',
      numberOfOrders: '0',
      code: 'nonProgrammableOrders',
      color: 'boxPurple',
    },
  ];

  setStocks() {
    if (['energy', 'energyOperators'].includes(this.plannerType)) {
      this.stocks = [{
        title: 'Deliberate (no progr.)',
        stockNumber: '0',
        numberOfOrders: '0',
        code: 'bothUnscheduled',
        color: 'boxOrange',
      },
      {
        title: 'Programmate Building',
        stockNumber: '0',
        numberOfOrders: '0',
        code: 'unplannedEnergy',
        color: 'boxYellow',
      },
      {
        title: 'Programmate Energy',
        stockNumber: '0',
        numberOfOrders: '0',
        code: 'buildingsNotScheduled',
        color: 'boxGreen',
      },
      {
        title: 'Programmate',
        stockNumber: '0',
        numberOfOrders: '0',
        code: 'bothScheduled',
        color: 'boxRed',
      },

      {
        title: 'Non Programmabili',
        stockNumber: '0',
        numberOfOrders: '0',
        code: 'nonProgrammable',
        color: 'boxPurple',
      }
      ]
    }
    return this.stocks
  }

  getCompany() {
    const company = this.userSessionService.getState('working_company');
    if (!(company == null) && !(company.name == '*')) {
      return company.id;
    } else return 0;
  }

  setStockTotaliCall(email: any, company: any) {
    let currentCall$ = this.financeService.getStockTotali(email, company);


    switch (this.plannerType) {
      case 'core':
        currentCall$ = this.financeService.getStockTotali(email, company);
        break;
      case 'multi':
        currentCall$ = this.financeService.getStockTotaliServices(
          email,
          company
        );
        break;
      case 'energy':
        currentCall$ = this.financeService.getStockTotaliEnergy(email, company);
        break;
      case 'energyOperators':
        currentCall$ = this.financeService.getStockTotaliEnergy(email, company);
        break;
      default:
        currentCall$ = this.financeService.getStockTotali(email, company);
        break;
    }

    return currentCall$;
  }

  financeGetStockTotali() {
    if (!this.stockLoad) {
      this.libService.lockPage('');
      this.loading = true;
      let currentUser = this.userSessionService.getState('user');
      let call$ = this.setStockTotaliCall(currentUser.email, this.getCompany());
      call$.subscribe({
        next: (res: any) => {
          if (res.esito == 'OK') {
            //console.log(res.item.Table)
            let resStocks = res.item.Table;
            resStocks.forEach((stock: any) => {
              switch (stock.id.toString()) {
                case '1':
                  this.stocks.find(
                    (s: any) => ['suspendedOrders', 'bothUnscheduled'].includes(s.code)
                  ).stockNumber = stock.Stock;
                  this.stocks.find(
                    (s: any) => ['suspendedOrders', 'bothUnscheduled'].includes(s.code)
                  ).numberOfOrders = stock.Commesse;
                  break;

                case '2':
                  this.stocks.find(
                    (s: any) => ['planningOrders', 'unplannedEnergy'].includes(s.code)
                  ).stockNumber = stock.Stock;
                  this.stocks.find(
                    (s: any) => ['planningOrders', 'unplannedEnergy'].includes(s.code)
                  ).numberOfOrders = stock.Commesse;
                  break;

                case '3':
                  this.stocks.find(
                    (s: any) => ['approvedForLessThan7Days', 'buildingsNotScheduled'].includes(s.code)
                  ).stockNumber = stock.Stock;
                  this.stocks.find(
                    (s: any) => ['approvedForLessThan7Days', 'buildingsNotScheduled'].includes(s.code)
                  ).numberOfOrders = stock.Commesse;
                  break;

                case '4':
                  this.stocks.find(
                    (s: any) => ['deliberatedForMoreThan7Days', 'bothScheduled'].includes(s.code)
                  ).stockNumber = stock.Stock;
                  this.stocks.find(
                    (s: any) => ['deliberatedForMoreThan7Days', 'bothScheduled'].includes(s.code)
                  ).numberOfOrders = stock.Commesse;
                  break;

                case '5':
                  this.stocks.find(
                    (s: any) => ['nonProgrammableOrders', 'nonProgrammable'].includes(s.code)
                  ).stockNumber = stock.Stock;
                  this.stocks.find(
                    (s: any) => ['nonProgrammableOrders', 'nonProgrammable'].includes(s.code)
                  ).numberOfOrders = stock.Commesse;
                  break;
              }
            });
          }
          this.loading = false;
          this.stockLoad = true;
          this.libService.unlockPage();
        },
        error: (e: any) => {
          console.log(e);
          this.libService.unlockPage();
        },
      });
    }
  }

  getSpecificPath() {
    return this.plannerType == 'core' ? '' : `${this.plannerType}/`;
  }

  openList(code: any) {
    let specificPath = this.getSpecificPath();
    this.router.navigate([`/planner/${specificPath}`, 'list', code]);
  }
}
