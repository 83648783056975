<div *ngIf="form && checkComponent() && (orderCode !== undefined && orderCode.length > 0)">
    <div style="margin-top: 30px;
              float: right;
              text-align: left;
              font-family: 'Roboto';
              background-color:  white;
              border: 1px solid  lightgrey;
              padding: 1%;">
      <p>{{ 'LABEL.ORDER_LINK' | translate }}</p> <a [href]="'practices/' + orderCode">{{ orderCode }}</a>
    </div>
  </div>