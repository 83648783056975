<div id="container">
    <div class="slideBox">
        <mat-slide-toggle color="primary" (change)="changeColumsStructure($event)">{{'PLANNER.visualizzazione_commesse'
            | translate}}</mat-slide-toggle>
    </div>

    <bryntum-scheduler-pro-project-model #project [resources]="resources" [events]="events" [assignments]="assignments"
        [timeRangeStore]="myTimeRangeStore" [timeRanges]="timeRanges"></bryntum-scheduler-pro-project-model>


    <bryntum-scheduler-pro #schedulerpro [columns]="columns" [cls]="cls" [project]="project"
        [barMargin]="schedulerProConfig.barMargin" [eventStyle]="schedulerProConfig.eventStyle"
        [viewPreset]="schedulerProConfig.viewPreset" [infiniteScroll]="schedulerProConfig.infiniteScroll"
        [eventLayout]="eventLayout" [features]="features" [tbar]="tbarConfig" [createEventOnDblClick]="false"
        [listeners]="listeners" [eventRenderer]="" [eventEditFeature]="getEventEdit()"
        (onBeforeEventSave)="saveEvent($event)"></bryntum-scheduler-pro>
</div>