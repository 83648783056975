import { Injectable } from '@angular/core';
import { catchError, first, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { UserSessionService } from './user-session.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class SubjectRelationTypeService {

  baseApi = environment.rootApiCrm + 'v1.0';
  foundationApi = environment.rootApiFoundation;
  model = 'subjectRelationTypes'

  constructor(public libService: LibService,
    public userSessionService: UserSessionService,
    private authService: AuthService,
  ) { }

  index() {
    console.log("index")
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `/${this.model}`)
  }

}
