import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ComplaintService } from 'src/app/services/complaint.service';
import { LibService } from 'src/app/services/libService';
import { SubjectRelationshipTypeService } from 'src/app/services/subjectRelationshipType.service';
import { initialEnd } from '@syncfusion/ej2-angular-schedule';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'app-complaint-form',
  templateUrl: './complaint-form.component.html',
  styleUrls: ['./complaint-form.component.scss']
})
export class ComplaintFormComponent implements OnInit {

  @Input() item: any;
  @Output() closeModal = new EventEmitter;

  form: FormGroup;

  subjectRelationshipTypes :any = []

  subjectRelationshipTypes$ = this.subjectRelationshipTypeService.index();

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private complaintService: ComplaintService,
    private libService: LibService,
    private subjectRelationshipTypeService: SubjectRelationshipTypeService,
  ) {
  }

  ngOnInit(): void {

    
    combineLatest([this.subjectRelationshipTypes$]).subscribe(([subjectRelationshipTypes]) => {
      this.subjectRelationshipTypes = subjectRelationshipTypes
    })

    // Creazione Form
    this.form = this.fb.group({
      id: [null],
      // version: [null, Validators.required],
      company: [null],
      orderNumberEA: [null, Validators.required],
      orderVersion: [null],
      orderId: [null],
      practiceId: [null],
      complaintType: [null],
      managerArea: [null],
      delay: [null],
      deliberateValue: [null],
      totalProducedValue: [null],
      totalNetTurnover: [null],
      totalGrossTurnover: [null],
      totalGrossReceived: [null],
      totalHours: [null],
      hoursValue: [null],
      materialsValue: [null],
      NCNumber: [null],
      NCValue: [null],
      status: [null],
      totalGrossExpired: [null],
      averageDays: [null],
      motherOrder: [null, Validators.required],
      documents: null,
      relationshipWithSubjects: this.fb.array([])
    })

    this.form.patchValue(this.item)
    this.initRelationshipWithSubjects()
  }

  get relationshipWithSubjects(): FormArray {
    return <FormArray>this.form.get('relationshipWithSubjects');
  }

  setRelationshipWithSubject(structure: any){
    let relationshipWithSubject = this.fb.group(structure)
    return relationshipWithSubject
  }

  initRelationshipWithSubjects(){
    this.item?.relationshipWithSubjects.forEach((relationshipWithSubject :any) => {
      let subject = relationshipWithSubject.subject
      let structure: any = {
        id: [relationshipWithSubject.id],
        insertSubjectType: [null],
        subject: this.fb.group({
          id: [subject?.id],
          name: [subject?.name, Validators.required],
          surname: [subject?.surname, Validators.required],
          legalEntity: [subject?.legalEntity],
          email: [subject?.email],
          telephone2: [subject?.telephone2],
        }),
        subjectRelationshipTypeId: [null],
        subjectAlias: [null]
      }
      this.relationshipWithSubjects.push(this.setRelationshipWithSubject(structure))
    })
 
  }

  addRelationshipWithSubject(){
    let structure: any = {
      id: [null],
      insertSubjectType: [null],
      subject: this.fb.group({
        id: [null],
        name: [null, Validators.required],
        surname: [null, Validators.required],
        legalEntity: [null],
        email: [null],
        telephone2: [null],
      }),
      subjectRelationshipTypeId: [null],
      subjectAlias: [null]
    }
    this.relationshipWithSubjects.push(this.setRelationshipWithSubject(structure))
  }

  ngOnChanges(changes: SimpleChanges): void { }

  update() {
    //console.log(this.form)
    this.libService.lockPage('');
    this.complaintService.updateComplaint(this.item?.id?.toString(), this.form.getRawValue()).subscribe((res: any) => {
      //console.log(res);
      this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
      this.closeModal.emit(true);
      this.libService.unlockPage();
    })
  }

}