<!--
<form (submit)="onSignIn(signInForm)" #signInForm="ngForm">
    <label for="email"><b>Email address</b></label>
    <input name="email" ngModel type="email" #emailInput="ngModel" [(ngModel)]="email_address" email
        placeholder="Enter Username" required>

    <label for="password"><b>Password</b></label>
    <input [(ngModel)]="password" ngModel type="password" minlength="8" #passwordInput="ngModel" required
        name="password" placeholder="Enter Password">

    <button ejs-button type="submit">Login</button>
</form>
-->

<div class="wrap">
    <form (submit)="onSignIn(signInForm)" #signInForm="ngForm">
        <h4>{{ 'LABEL.EMAIL_ADDRESS' | translate }}</h4>
        <input name="email" class="rnd e-input" ngModel type="email" #emailInput="ngModel" [(ngModel)]="email_address" email
        [placeholder]="translate.getInsertLabel('LABEL.USERNAME') | async" required floatLabelType="Auto" />

        <h4>{{ 'LABEL.PASSWORD' | translate }}</h4>
        <input class="rnd e-input" [(ngModel)]="password" ngModel type="password" minlength="8" #passwordInput="ngModel" required
        name="password" [placeholder]="translate.getInsertLabel('LABEL.PASSWORD') | async"  floatLabelType="Auto">

        <button ejs-button type="submit">{{ 'LABEL.LOGIN' | translate }}</button>
    </form>
</div>

