import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, Output, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { LibService } from '../services/libService';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { UserSessionService } from '../services/user-session.service';
import { BuildingService } from 'src/app/services/building.service';
import { CondominiumService } from '../services/condominium.service';
import { combineLatest } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Building } from '../models/building';
import { Subject } from '../models/subject';
import { DatatableAction, DatatableColumn } from '../shared/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { StatusService } from '../services/status.service';
import { Status } from '../models/status';
import { RoleService } from '../services/role.service';


@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss']
})
export class BuildingComponent implements OnInit {

  @ViewChild('nameCell', { read: TemplateRef }) nameCell: TemplateRef<any>;
  nameCellTemplateRef$ = new BehaviorSubject<any>(null);

  eventsSubject: Subject2<void> = new Subject2<void>();
  //displayedColumns = ['id', 'name', 'legalEntity', 'type', 'status', 'fixTelephone', 'mobileTelephone', 'email', 'city', 'createdAt', 'updatedAt', 'editAction', 'deleteAction'];
  displayedColumns = ['id', 'name', 'responsible', 'company', 'type', 'status', 'email', 'city', 'createdAt', 'updatedAt'];
  loggedUser: any;
  profiles: any;
  acceptedCompanies: any;

  columns: DatatableColumn<Subject>[] = [
    {
      name: 'id',
      flex: '6',
      title: this.translate.get('LABEL.ID'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.id
    },
    {
      name: "name",
      type: 'templateRef',
      title: this.translate.get('LABEL.name'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.type !== 'Amministratore' && subject.type !== 'Condominio'? subject.name: subject.legalEntity,
      templateRef: subject => this.nameCellTemplateRef$
    },
    {
      name: "businessName",
      type: 'templateRef',
      title: this.translate.get('LABEL.name'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.businessName,
      templateRef: subject => this.nameCellTemplateRef$
    },
    {
      name: 'responsible',
      //flex: '20',
      title: this.translate.get('LABEL.responsible'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.responsible
    },
    {
      name: 'company',
      //flex: '7',
      title: this.translate.get('LABEL.company'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.company
    },
    {
      name: 'type',
      //flex: '7',
      title: this.translate.get('LABEL.type'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.type
    },
    {
      name: 'status',
      title: this.translate.get('LABEL.STATUS'),
      value: subject => {

        let statusId: any = subject.status;
        let status: any;
        if (typeof(statusId) === 'number') {
          switch (subject.type) {
            case 'Edificio':
              status = this.buildingStatuses.find(statusB => statusB.id === statusId)
              break;
            case 'Condominio':
              status = this.condominiumStatuses.find(statusC => statusC.id === statusId)
              break;
            default: //soggetto, privato ecc...
              status = this.buildingStatuses.find(statusB => statusB.id === statusId)
              break;
          }
        } else if (statusId?.uiLabel !== undefined && statusId?.uiLabel !== null) {
          status = subject.status;
        }
        return status !== undefined ?
          this.translate.instant('LABEL.' + status!.uiLabel) :
          this.translate.instant('LABEL.NOT_PRESENT')
      }
    },
    {
      name: 'email',
      //flex: '15',
      title: this.translate.get('LABEL.email'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.email
    },
    {
      name: 'city',
      title: this.translate.get('LABEL.city'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.city
    },
    {
      name: "createdAt",
      flex: '5',
      title: this.translate.get('LABEL.CREATION_DATE'),
      cssClass: request => ['column-padding'],
      value: request => this.datePipe.transform(request.createdAt)
    },
    {
      name: "updatedAt",
      flex: '5',
      title: this.translate.get('LABEL.LASTMODIFIED_DATE'),
      cssClass: request => ['column-padding'],
      value: request => this.datePipe.transform(request.updatedAt)
    },
  ]



  actions: DatatableAction<Subject>[] = [
    {
      label: of(''),
      icon: (subject: any) => {
        return this.iconRoutine(subject);
      },
      onClick: subject => this.goTo(subject),
      color: 'primary'
    }/* ,
    {
      label: of(''),
      icon: 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    } */
  ];
  dataSourceBuilding: any;
  kwSearch: any;
  searchType: string = 'Edificio';
  title = "Edifici";
  subTitle = "EDAC - Edifici";
  firstSubjects: any;
  firstBuildings: any;
  firstCondominiums: any;
  searchService: any;

  // @ViewChild(MatSort) set matSort(sort: MatSort) {
  //   this.dataSource.sort = sort;
  // }
  // @ViewChild(MatPaginator)
  // paginator: MatPaginator;

  currentUrl: string;

  buildingStatuses: Status[] = []
  condominiumStatuses: Status[] = []


  buildingStatuses$ = this.statusService.getAll('Building')
  condominiumStatuses$ = this.statusService.getAll('Subject')
  // loggedUser$ = this.userSessionService.getUser(this.authService.user().email).pipe(map(val => this.loggedUser = val));

  buildings$ = this.buildingService.getAllBuildings(this.authService.user().email)
  condominiums$ = this.condominiumService.getAllCondominiums(this.authService.user().email)

  constructor(
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    public buildingService: BuildingService,
    public condominiumService: CondominiumService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private statusService: StatusService,
    private cdr: ChangeDetectorRef,
    private roleService: RoleService) {
    this.userSessionService.getUser(this.authService.user().email).subscribe(val => {
      this.loggedUser = val;
      this.profiles = this.loggedUser[0]?.UsersProfilesRel.map((a: any) => a.name);
      this.acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
    });
  }

  onSearchTypeChange(e: any) {
    console.log('change search type %o', e);

    switch (e) {
      case 'Edificio':
          this.libService.lockPage('');
          this.buildings$.subscribe(buildings => {
            this.firstBuildings = buildings;
            this.dataSourceBuilding = this.firstBuildings;
            this.searchService = this.buildingService;
            this.displayedColumns = ['id', 'name', 'responsible', 'company', 'type', 'status', 'email', 'city', 'createdAt', 'updatedAt'];
            this.emitEventToFTSearch();
            this.libService.unlockPage();
          });
        // this.dataSource = this.firstBuildings;
        // this.searchService = this.buildingService;
        // this.emitEventToFTSearch();
        break;
      case 'Condominio':
        this.libService.lockPage('');
        this.condominiums$.subscribe(condoms => {
          this.firstCondominiums = condoms;
          this.dataSourceBuilding = this.firstCondominiums;
          this.searchService = this.condominiumService;

          this.displayedColumns = ['id', 'businessName', 'responsible', 'company', 'type', 'status', 'email', 'city', 'createdAt', 'updatedAt'];
          this.emitEventToFTSearch();
          this.libService.unlockPage();
        })
        // this.dataSource = this.firstCondominiums;
        // this.searchService = this.condominiumService;
        // this.emitEventToFTSearch();
        break;
      default:
        break;
    }
  }

  ngAfterViewInit() {
    this.nameCellTemplateRef$.next(this.nameCell);
  }

  ngOnInit(): void {
    this.searchService = this.buildingService;

    //GET STATUSES
    combineLatest([this.buildingStatuses$, this.condominiumStatuses$]).subscribe(([buildingStatuses, condominiumStatuses]) => {
      this.buildingStatuses = buildingStatuses
      this.condominiumStatuses = condominiumStatuses

    })
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  goTo(e: any) {
    this.router.navigate(['buildings/' + e.id, { type: this.searchType }]);
    /*
    switch (e.type) {
      case 'Edificio':
        this.router.navigate(['building/' + e.id, e.type]);
        break;
      default:
        this.router.navigate(['building/' + e.id, e.type]);
        break;
    }
    */
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSourceBuilding.filter = e.trim().toLowerCase();
  }

  updateList(e: any) {
    console.log("EVENTO  LISTA --> ", e);
    if (e.length > 0) {
      this.dataSourceBuilding = e;
    } else {

      combineLatest([this.buildings$, this.condominiums$]).subscribe({
        next: ([buildings, condominiums]) => {
          // console.log('loadBuildings response %o', buildings);
          // console.log('loadCondominiums response %o', condominiums);

          this.firstBuildings = buildings.length > 0 ? buildings : null;
          this.firstCondominiums = condominiums.length > 0 ? condominiums : null;

          switch (this.searchType) {
            case 'Edificio':
              this.dataSourceBuilding = buildings;
              break;
            case 'Condominio':
              this.dataSourceBuilding = condominiums;
              break;
            default:
              break;
          }
          // this.dataSource.paginator = this.paginator;
          this.libService.unlockPage();
        },
        error: (error) => {
          this.libService.showMessageError(error.message);
        }
      })

    }
  }

  clearList(e: any) {
    console.log("CANCELLA --> ", e);
    if (e) {
      combineLatest([this.buildings$, this.condominiums$]).subscribe({
        next: ([buildings, condominiums]) => {

          console.log('loadBuildings response %o', buildings);
          console.log('loadCondominiums response %o', condominiums);


          this.firstBuildings = buildings.length > 0 ? buildings : null;
          this.firstCondominiums = condominiums.length > 0 ? condominiums : null;

          switch (this.searchType) {
            case 'Edificio':
              this.dataSourceBuilding = buildings;
              break;
            case 'Condominio':
              this.dataSourceBuilding = condominiums;
              break;
            default:
              break;
          }
          // this.dataSource.paginator = this.paginator;
          this.libService.unlockPage();
        },
        error: (error) => {
          this.libService.showMessageError(error.message);
        }
      })
    }
  }

  filterResultEmitted(event: Subject[]) {
    if (event !== null) {
      this.dataSourceBuilding = event
    } else {
      this.toastr.warning(this.translate.instant('TOASTR.MESSAGE_2'))
      this.dataSourceBuilding = this.firstSubjects
    }
  }

  emitEventToFTSearch() {
    console.log("EVENTO INNESTATO");
    this.eventsSubject.next();
  }

  checkCompanyUserSubject() {

  }

  iconRoutine(subject: any) {
    //INIZIO ROUTINE PER VISIBILITY
    let profile: any;
    this.roleService.getMainRole(this.loggedUser);
    // if (this.profiles?.includes('BackOffice') || this.profiles?.includes('Tecnico Commerciale') || this.profiles?.includes('Area Manager') || this.profiles?.includes('Responsabile Area')) {
    //   this.profiles.includes('BackOffice')? profile = 'BackOffice': undefined;
    //   this.profiles.includes('Tecnico Commerciale')? profile = 'Tecnico Commerciale': undefined;
    //   this.profiles.includes('Area Manager') || this.profiles.includes('Responsabile Area')? profile = 'Area Manager': undefined;
    // }

    //console.log("PROFILO DELL'UTENTE -->", this.loggedUser[0].role);
    switch (this.loggedUser[0].role) {
      case 'Area Manager': case 'Responsabile Area': case 'Affiliato':
        return !this.acceptedCompanies.includes(subject.company) ? 'visibility' : 'edit';
        break;

      case 'Tecnico Commerciale':
        return !(subject.responsible === this.loggedUser[0].email) ? 'visibility' : 'edit'
        break;

      case 'BackOffice':
        return 'edit';
        break;

      case 'Direzione':
        return 'edit';
        break;

      case 'Responsabile Commerciale': case 'Coordinatore Country': case 'Direzione': case 'Top Management': case 'Contabilita': case 'Amministrazione': case 'HR': case 'Responsabile Mkt':
        return !(this.acceptedCompanies.includes(subject.company) && subject.responsible === this.loggedUser[0].email) ? 'visibility' : 'edit';
        break;
      case 'Coordinatore':
        this.dataSourceBuilding = [];
        this.cdr.detectChanges();
        return 'visibility';
        break;
      default:
        return 'edit'
        break;
    }
  }

}
