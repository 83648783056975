import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentService } from '../../services/component.service';
import { SubjectToPracticeModal } from '../modals/subject-to-practice-modal/subject-to-practice-modal.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { SubjectRelatedToPracticesService } from '../../services/subjectRelatedToPractices.service';
import { UserSessionService } from '../../services/user-session.service';
import { ActionService } from '../../services/action.service';
import { LibService } from '../../services/libService';
import { DatePipe } from '@angular/common';


import { TranslateService } from '@ngx-translate/core';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';

@Component({
  selector: 'app-add-subject-to-practice-button',
  templateUrl: './add-subject-to-practice-button.component.html',
  styleUrls: ['./add-subject-to-practice-button.component.scss']
})
export class AddSubjectToPracticeButtonComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() components: any;

  componentName: any = 'add-subject-to-practice-button'
  subjectRelatedToPractices: any = []

  columns: DatatableColumn<any>[] = [
    {
      name: "fullname",
      title: this.translate.get('LABEL.fullname'),
      cssClass: item => ['column-padding'],
      value: item => this.getFullname(item?.subject)
    },
    {
      name: "email",
      title: this.translate.get('LABEL.email'),
      cssClass: item => ['column-padding'],
      value: item => item?.subject?.email
    },
    {
      name: "type",
      title: this.translate.get('LABEL.typology'),
      cssClass: item => ['column-padding'],
      value: item => item?.relationshipType?.label
    },
    {
      name: "note",
      title: this.translate.get('LABEL.NOTE'),
      cssClass: item => ['column-padding'],
      value: item => item?.note
    },
    {
      name: "author",
      title: this.translate.get('LABEL.author'),
      cssClass: item => ['column-padding'],
      value: item => item?.author?.name
    },
    {
      name: "createdAt",
      title: this.translate.get('LABEL.createdAt'),
      cssClass: item => ['column-padding'],
      value: item => this.datePipe.transform(item?.createdAt?.toString(), 'd/M/yy, h:mm a')
    }
  ]

  actions: any = [];

  displayedColumns: string[] = ['fullname', 'email', 'type', 'note', 'createdAt', 'author'];

  sortActive: any = 'createdAt'

  constructor(
    private dialog: MatDialog,
    private componentService: ComponentService,
    private subjectRelatedToPracticesService: SubjectRelatedToPracticesService,
    private userSessionService: UserSessionService,
    private libService: LibService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private actionService: ActionService,
  ) { }

  ngOnInit(): void {
    this.getSubjectRelatedToPractices()
    this.setActions()
    console.log(this.form.get('subjectRelatedToPractices')?.value)
  }

  getFullname(item: any) {
    let fullname = `${item.name} ${item.surname}`
    if (item.legalEntity) fullname = item.legalEntity
    return fullname
  }

  checkPermissions(key: string) {
    return this.actionService.checkPermissions(key)
  }


  setActions() {

    // DESTROY
    if (this.checkPermissions(`add_subject_to_practice_destroy`)) {
      this.actions.push({
        label: '',
        icon: (item: any) => {
          return 'delete';
        },
        cssClass: (item: any) => ['redButton'],
        onClick: (item: any) => this.deleteItem(item),
        color: 'danger'
      })
    }

  }

  deleteItem(item: any) {
    if (confirm((this.translate.instant('LABEL.confirmDelete')))) {
      this.subjectRelatedToPracticesService.destroy(item.id).subscribe((response: any) => {
        console.log(response);
        this.getSubjectRelatedToPractices();
      });
    }
  }


  openModal() {

    let dataStructure: any = {
    }

    const dialogRef = this.dialog.open(SubjectToPracticeModal, {
      width: '85%',
      data: dataStructure,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (![null, undefined].includes(result)) {

        if (result.type == 'subjects' || result.type == 'addSubjects') {
          let currentUser = this.userSessionService.getState('user');
          let PracticeId = this.form.get('PracticeId')?.value
          let SubjectId = result.subjectId
          let note = result.note
          let SubjectRelatedToPracticeTypologyId = result.subjectRelatedToPracticeTypologyId

          let attrubutes = {
            AuthorId: currentUser.id,
            SubjectId: SubjectId,
            note: note,
            PracticeId: PracticeId,
            SubjectRelatedToPracticeTypologyId: SubjectRelatedToPracticeTypologyId,
          }


          this.libService.lockPage('');
          this.subjectRelatedToPracticesService.create(attrubutes).subscribe((response: any) => {
            console.log(response);
            this.getSubjectRelatedToPractices()
            this.libService.unlockPage();
          });


        }

      }


    });


  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    return status
  }

  disabledButton() {
    let status = false
    let createdBySignaler = this.form.get('createdBySignaler')?.value
    if (createdBySignaler) status = true
    return status
  }

  getSubjectRelatedToPractices() {
    let PracticeId = this.form.get('PracticeId')?.value
    this.subjectRelatedToPracticesService.indexByAttributes({ PracticeId: PracticeId }).subscribe((response: any) => {
      this.subjectRelatedToPractices = response;
      this.form.get('subjectRelatedToPractices')?.setValue(response)
    });
  }

}
