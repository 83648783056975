<main class="container-fluid">
  <mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="tabChanged($event)"
    [selectedIndex]="selectedTabIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">group</mat-icon>
        <span class="ml-1">{{ 'SIDEBAR.AnomalieConsuntivazione' | translate }}</span>
      </ng-template>
      <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">calendar_month</mat-icon>
        <span class="ml-1">{{ 'SIDEBAR.Holiday Calendar' | translate }}</span>
      </ng-template>
      <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
        <br>
        <bryntum-calendar-project-model #project [events]="events"></bryntum-calendar-project-model>
        <bryntum-calendar #calendar [config]="calendarConfig" [crudManager]="calendarConfig.crudManager"
          [loadOnDemandFeature]="calendarConfig.features?.loadOnDemand" [date]="calendarConfig.date!"
          [project]="project" [modes]="calendarConfig.modes" minHeight="800px" [allowOverlap]="true"
          [eventTooltipFeature]="calendarConfig.features?.eventTooltip" [tbar]="getTbarConfig()"></bryntum-calendar>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">assignment</mat-icon>
        <span class="ml-1">{{ 'SIDEBAR.Contratti' | translate }}</span>
      </ng-template>
      <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">event_busy</mat-icon>
        <span class="ml-1">{{ 'SIDEBAR.ModuloAssenze' | translate }}</span>
      </ng-template>
      <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
      </div>
    </mat-tab>
  </mat-tab-group>
</main>