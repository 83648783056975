import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of, combineLatest, map, switchMap } from 'rxjs';
import { ComponentsFileUploadModalComponent } from '../modals/components-file-upload-modal/components-file-upload-modal.component';
import { Document, DocumentType } from 'src/app/models/document';
import { DocumentTemplateService } from 'src/app/services/document-template.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { ComponentService } from 'src/app/services/component.service';
import { DocumentService } from 'src/app/services/document.service';
import { PracticeService } from 'src/app/services/practice.service';

@Component({
  selector: 'app-crm-document-manager',
  templateUrl: './crm-document-manager.component.html',
  styleUrls: ['./crm-document-manager.component.scss']
})
export class CrmDocumentManagerComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() disableUpload: boolean;
  @Input() components: any;
  @Input() practice: any;
  @Input() itemId: any;
  @Input() itemType: any;
  @Input() documents: any;

  attributes: any;

  componentName: any = 'crmDocumentManager'

  documentTypes: DocumentType[];
  documentTypes$: DocumentType[];
  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()
  items: any = []

  currentComponent: any = null;


  constructor(
    private dialog: MatDialog,
    private documentTypeService: DocumentTypeService,
    private documentTemplateService: DocumentTemplateService,
    private componentService: ComponentService,
    private documentService: DocumentService,
    private practiceService: PracticeService,
  ) { }


  ngOnInit(): void {
    console.log('init crmDocumentManager')
    console.log(this.components)
    this.getCurrentComponent()

  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes - crmDocumentManager')
    console.log(changes)

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'components': 
            this.getCurrentComponent()
            break
          case 'documents': 
            this.setItems(changes[propName].currentValue)
            break
        }
      }
    }

  }


  setItems(documents: any = []) {

    console.log('setItems')

    let currentDocuments = documents || this.documents

    let codes: any = []
    let documentList = this.currentComponent?.attributes?.documentList
    //console.log(documentList)


    codes = documentList.map((structure: any) => structure.documentTypeCode)
    let tmpStructure: any = {}

    documentList.forEach((doc: any) => {
      let key = `document${doc.documentTypeCode}`
      if (!tmpStructure[key]) tmpStructure[key] = []
      tmpStructure[key].push(doc)
    })

    //console.log(tmpStructure)

    let documentStructures = this.componentService.checkNecessaryDocumentsForPractices(currentDocuments, tmpStructure, null).structure
    //console.log('documentStructures')
    //console.log(documentStructures)
    this.documentTypeService.getByCodes(codes).subscribe((response: any) => {
      this.items = []
      response.forEach((documentType: any) => {
        //console.log(documentType)

        let currestDocumentStructure: any = documentStructures[`document${documentType.code}`]
        let tmpDocumentType = documentType
        if (currestDocumentStructure.hasOwnProperty('min')) tmpDocumentType.min = currestDocumentStructure.min
        if (currestDocumentStructure.hasOwnProperty('max')) tmpDocumentType.max = currestDocumentStructure.max
        if (currestDocumentStructure.hasOwnProperty('total')) tmpDocumentType.total = currestDocumentStructure.total
        if (currestDocumentStructure.hasOwnProperty('status')) tmpDocumentType.status = currestDocumentStructure.status
        if (currestDocumentStructure.hasOwnProperty('required')) tmpDocumentType.required = currestDocumentStructure.required
        this.items.push(tmpDocumentType)
      })
      //console.log(this.items)
    })
  }


  getCurrentComponent() {
    if (this.components) {
      this.currentComponent = this.components.find((component: any) => component.componentCode == this.componentName)
    }
  }


  openUploadDialog() {

    let data: any = {
      itemId: this.itemId,
      itemType: this.itemType,
      documentTypes: of(this.items),
    }

    if(this.itemType == 'requestId'){
      let buildingId = this.form.get('building')?.get('id')?.value
      data.buildingId = buildingId
    }

    this.dialog.open(ComponentsFileUploadModalComponent, {
      data: data
    })
      .afterClosed()
      .subscribe((res: any) => {
        console.log('uploadDialogres: ', res);
        //this.subject.documents.push(res)
        if (res) {
          if (res) {
            if(this.itemType == 'requestId'){
              this.form.get('building')?.get('documents')?.setValue([...this.form.get('building')?.get('documents')?.value, res.document])
            } else{
              this.form.get('documents')?.setValue([...this.form.get('documents')?.value, res.document])
            }
            
          }
        }
      })

  }


  documentTypeSelected(documentType: DocumentType) {
    this.form.get('typeName')?.setValue(documentType);
    document.getElementById('file-input')!.click();
  }


  structureOfNecessaryDocuments() {
    //console.log('checkNecessaryDocuments - MAIN')
    let documentList: any = this.currentComponent?.attributes?.documentList || []
    let tmpStructure: any = {}

    documentList.forEach((doc: any) => {
      let key = `document${doc.documentTypeCode}`
      if (!tmpStructure[key]) tmpStructure[key] = []
      tmpStructure[key].push(doc)
    })

    return this.componentService.checkNecessaryDocumentsForPractices(this.documents, tmpStructure, this.form).structure
  }


  getIcon(status: any) {
    return status ? 'done' : 'cancel'
  }


  getColor(status: any) {
    return status ? 'iconOK' : 'iconKO'
  }


  checkComponent() {
    return this.currentComponent?.componentCode == this.componentName
  }

}
