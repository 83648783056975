import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of, combineLatest, map, switchMap } from 'rxjs';
import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { Document, DocumentType } from 'src/app/models/document';
import { DocumentTemplateService } from 'src/app/services/document-template.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { ComponentService } from 'src/app/services/component.service';

@Component({
  selector: 'app-request-document-manager',
  templateUrl: './request-document-manager.component.html',
  styleUrls: ['./request-document-manager.component.scss']
})
export class RequestDocumentManagerComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() disableUpload: boolean;
  @Input() isNew: boolean;
  @Input() components: any;

  attributes: any;

  componentName: any = 'request_document_manager'

  documents: Document[];
  documentTypes: DocumentType[];
  documentTypes$: DocumentType[];
  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()
  items: any = []



  constructor(
    private dialog: MatDialog,
    private documentTypeService: DocumentTypeService,
    private documentTemplateService: DocumentTemplateService,
    private componentService: ComponentService
  ) { }

  ngOnInit(): void {
    this.form.get('building')?.get('documents')?.value !== null ?
      this.documents = this.form.get('building')?.get('documents')?.value.filter((document: Document) => document.requestId === this.form.get('id')?.value) :
      console.log("=null")
    console.log('this.documents')
    console.log(this.documents)
    this.form.get('building')?.get('documents')?.valueChanges.subscribe((documents: Document[]) => {
      this.documents = documents?.filter(document => document.requestId === this.form.get('id')?.value)
    })
    console.log('this.form.get(status) %o', this.form.get('status'));

    this.setDocumentTypes()
    this.setItems()

  }

  setItems() {
    this.setAttributes()
    if (this.attributes) {
      console.log('setItems')

      let ids: any = []
      let structures = this.componentService.getStructure(this.attributes)
      ids = structures.map((structure: any) => structure.documentTypeId)

      let documentStructures = this.componentService.checkNecessaryDocuments(this.attributes, this.form).structure

      this.documentTypeService.getByIds(ids).subscribe((response: any) => {
        this.items = []
        response.forEach((documentType: any) => {
          let currestStructure: any = structures.find((s: any) => s.documentTypeId == documentType.id)
          let currestDocumentStructure: any = documentStructures[currestStructure.code]
          let tmpDocumentType = documentType
          if (currestDocumentStructure.hasOwnProperty('min')) tmpDocumentType.min = currestDocumentStructure.min
          if (currestDocumentStructure.hasOwnProperty('max')) tmpDocumentType.max = currestDocumentStructure.max
          if (currestDocumentStructure.hasOwnProperty('total')) tmpDocumentType.total = currestDocumentStructure.total
          if (currestDocumentStructure.hasOwnProperty('status')) tmpDocumentType.status = currestDocumentStructure.status
          if (currestDocumentStructure.hasOwnProperty('required')) tmpDocumentType.required = currestDocumentStructure.required
          this.items.push(tmpDocumentType)
        })
        console.log(this.items)
      })
    }

  }

  isDisabled() {
    if (this.form.get('status') == null || this.form.get('status')?.value == null || this.form.get('status')?.value.code == null)
      return true;
    if (this.form.get('status')?.value.code == 'NEW')
      return false;
    else
      return true;
  }

  getClassWorkflow(step: any) {
    if (!(this.form == null) && !(this.form.value == null) && !(this.form.value.status == null)) {
      let stepP = this.form.value.status.step;//ex 2 <- actual status
      //console.log('request status %o',this.form.value.status.step);

      if (step == stepP)
        return 'fs-6 fw-bold aln row-al mrg-top card-title';

      return 'fs-6 fw-bold text-muted aln row-al mrg-top card-title'
    }
    return '';

  }

  getClassIconWorkflow(step: any) {
    if (!(this.form == null) && !(this.form.value == null) && !(this.form.value.status == null)) {
      let stepF = this.form.value.status.step; //ex 2 <- actual status

      if (step < stepF) {
        return 'check-ok';
      }

      if (step == stepF)
        return 'check-warning';
    }
    return ''

  }

  public openUploadDialog(mode: 'new' | 'current') {
    if (mode === 'new') {
      this.dialog.open(FileUploadModalComponent, {
        data: {
          //file: this.webcamImage.imageAsDataUrl,
          inputMode: 'buildings',
          toEntityId: this.form.get('building')?.get('id')?.value,
          requestId: this.form.get('id')?.value,
          documentTypes: of(this.items),
          attributes: this.attributes
        }
      })
        .afterClosed()
        .subscribe((res: any) => {
          console.log('uploadDialogres: ', res);
          //this.subject.documents.push(res)
          if (res) {
            this.form.get('building')?.get('documents')?.setValue([...this.form.get('building')?.get('documents')?.value, res.document])
            this.setItems()
          }
        })
    }
  }

  documentTypeSelected(documentType: DocumentType) {
    this.form.get('typeName')?.setValue(documentType);
    document.getElementById('file-input')!.click();
  }

  setDocumentTypes() {
    console.log('setDocumentTypes')
    if (this.isNew === false) {
      console.log('ping 1')
      console.log(this.form.get('status')?.value)
      combineLatest([this.form.get('status')!.valueChanges, this.globalDocumentTypes$]).pipe(
        map(([status, globalDocumentTypes]) => {
          console.log('ping 2')
          if (status && status.allowedDocumentTypes) {
            return [...status.allowedDocumentTypes, ...globalDocumentTypes]
          } else {
            return globalDocumentTypes
          }
        }),
        switchMap((documentTypes: DocumentType[]) => {
          console.log('ping 3')
          let documentTypesIds = documentTypes.map(documentType => documentType.id!)
          let societyId = this.form.get('building')?.get('companyLocation')!.value
          if (societyId !== "") {
            return combineLatest([
              this.documentTemplateService.getManyByDocumentTypeAndSociedyId(documentTypesIds, societyId),
              of(documentTypes)])
          } else {
            return combineLatest([
              of([]), of(documentTypes)
            ])
          }

        }),
        map(([documentTemplates, documentTypes]) => {
          console.log('ping 4')
          documentTypes = this.unique(documentTypes, ['code'])
          return documentTypes.map((documentType: any) => {
            return {
              ...documentType,
              documentTemplate: documentTemplates.find((documentTemplate: any) => documentTemplate.typeName === documentType.id)
            }
          })
        })
      ).subscribe((documentTypes: any) => {
        console.log('ping 5')
        console.log(documentTypes)
        this.documentTypes = documentTypes
        console.log("DOCUMENT TYPES --> ", this.documentTypes);
      })
    }
  }

  structureOfNecessaryDocuments() {
    //console.log('checkNecessaryDocuments - MAIN')
    let requestDocumentManagerAttributes = this.attributes
    return this.componentService.checkNecessaryDocuments(requestDocumentManagerAttributes, this.form).structure
  }

  getIcon(status: any) {
    return status ? 'done' : 'cancel'
  }

  getColor(status: any) {
    return status ? 'iconOK' : 'iconKO'
  }

  checkBuilding() {
    return ![undefined, null].includes(this.form.get('building')?.value?.id)
  }

  setAttributes() {
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) this.setAttributes()
    return status
  }


  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }
}
