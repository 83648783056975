import { OrderRow } from "./orderrow";
import { Funding } from "./funding";
import { Status } from "./status"
export class Order {
	id: number;
	code: string;
	version: number;
	who: string;
	note: string;
	sede: any;
	responsabileCommerciale: any;
	coordinatore: any;
	documents: any;
	realPrice: number;
	costoCessioni:number;
	costoOneri: number;
	realDescont: number;
	idStatus: number;
	isLast:any;
	isMultiservice: any;
	isSedeInterna: any;
	idRifRequest: any;
	idSede: any;
	rows: OrderRow[];
	isDeliberato: any;
	dateDelibera: any;
	streetName: any;
	streetNumber: any;
	otherInfo: any;
	city: any;
	province: any;
	state: any;
	zip: any;
	country: any;
	probChiusuraPratica: any;
	isAssemblea: any;
	dataPrevistaConfermaOrdine: any;
	dataPrevistaAssemblea: any;
	isTCAssemblea: any;
	deadLine: any;
	OPRel:any;
	percOneriSicurezza:any;
	isSalCento:any;
	fatturato?: any;
	incassato?: any;
	certificato?: any;
	valoreProdotto?: any;
	valoreSalTotal?: any;
	finalMaterial?: any;
	salProgressPercentage?: any;
	qtOreManodoperaTotal?: any;
	salTotal?: any;
	totaleOreConsuntivate?: any;
	materialePreventivato?: any;
	startx?:any;
	endx?:any;
	starty?:any;
	endy:any;
	km?:any;
	kmRitorno?:any;
	financingSectionActivation?: any;
	funding?: Funding;
	fundingId?: any;
	fundedValue?: any;
	maggiorazioneFinanziamento?: any;
	anteprimaFinanziamento?: any;
	mlcCommessa?: any;
	paymentMethodId: any;
	estimateDeliveryMethodId?: any;
	numberOfCondominiums?: any;
	numberOfCondominiumsThatFinance?: any;
	maximumFinancingInstallmentValue?: any;
	bankTaxForFinancing?: any;
	numberOfFinancingInstallments?: any;
	advanceFinancing?: any;
    advanceFinancingWithVAT?: any;
    financedByBancaSellaWithVAT?: any;
    installmentValueCondominium?: any;
    maximumInstallmentValue?: any;
    albertNoVATIncrease?: any;
    financingForecast90SoldWithVAT?: any;
    financingIncreaseValue?: any;
    vatCodeMultiplier?: any;
    maximumFundableMultiplier?: any;
	PracticeId?: any;
	ordreDeService?: any;
	percentageFinancing?: any;
	percentageFinancingWithVAT?: any;
	totalPayment?: any;
}
