<form [formGroup]="form" class="form-container" (ngSubmit)="update()">

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'WORKFLOWS.label' | translate }}</mat-label>
        <input matInput formControlName="label" (focusout)="setCode()">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'WORKFLOWS.code' | translate }}</mat-label>
        <input readonly matInput formControlName="code">
    </mat-form-field>

    <div appearance="fill" class="form-subject">
        <mat-slide-toggle formControlName="disabled">
            Disabled
        </mat-slide-toggle>
    </div>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Stato di inizio</mat-label>
        <mat-select formControlName="firstStateId">
            <mat-option *ngFor="let state of states" [value]="state.id">{{state.code}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Item Category</mat-label>
        <mat-select formControlName="ItemCategoryId" (selectionChange)="onChangeItemCategory($event)">
            <mat-option *ngFor="let itemCategory of itemCategories"
                [value]="itemCategory.id">{{itemCategory.code}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Business Line</mat-label>
        <mat-select formControlName="BusinessLineId">
            <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">{{businessLine.code}}
                ({{businessLine.codCountry}})</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Contact Reason</mat-label>
        <mat-select formControlName="ContactReasonId">
            <mat-option *ngFor="let contactReason of contactReasons"
                [value]="contactReason.id">{{contactReason.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>State Categories</mat-label>
        <mat-select formControlName="stateCategoryIds" multiple>
            <mat-option *ngFor="let stateCategory of currentStateCategories" [value]="stateCategory.id">
                {{ 'STATECATEGORIES.' + stateCategory.code | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <ng-container formArrayName="components">
        <ng-container *ngFor="let component of components.controls; let i = index">
            <div [formGroup]="component" class="form-subject">

                <mat-accordion *ngIf="showCategory(component.get('id').value)">
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{component.get('code').value}}
                            </mat-panel-title>
                            <mat-panel-description>
                                Attributi: {{component.get('attributes').value.length}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <ng-container formArrayName="attributes">
                            <div class="cardBox">

                                <mat-card class="cardItem"
                                    *ngFor="let attribute of component.get('attributes').controls; let c = index">
                                    <ng-container [formGroup]="attribute">
                                        <mat-card-header>
                                            <mat-card-title>{{attribute.get('code').value}}</mat-card-title>
                                            <mat-card-subtitle>
                                                code: {{attribute.get('code').value}}<br>
                                                id: {{attribute.get('id').value}}<br>
                                                multiple: {{attribute.get('multiple').value}}<br>
                                                <mat-slide-toggle color="primary"
                                                    formControlName="active">Attivo</mat-slide-toggle>
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content>


                                            <ng-container formArrayName="groupCodes"
                                                *ngIf="attribute.get('active').value && attribute.get('multiple').value">

                                                <button *ngIf="attribute.get('multiple').value" matTooltip="Back"
                                                    type="button" class="my-bounce btn btn-primary mx-2"
                                                    (click)="addActionSettings(attribute)">
                                                    Aggiungi
                                                </button>

                                                <div
                                                    *ngFor="let groupCode of attribute.get('groupCodes')?.controls; let p = index">
                                                    <ng-container [formGroup]="groupCode">
                                                        <div class="boxParameters"
                                                            *ngIf="groupCode?.get('parameters')?.controls">
                                                            <p><strong>Group Code:</strong>
                                                                {{groupCode.get('codeGroup')?.value }}</p>


                                                            <ng-container formArrayName="parameters">
                                                                <div
                                                                    *ngFor="let parameter of groupCode?.get('parameters')?.controls; let p = index">
                                                                    <ng-container [formGroup]="parameter">
                                                                        <div [ngSwitch]="parameter.get('type').value">
                                                                            <p *ngSwitchCase="'boolean'">
                                                                                <mat-slide-toggle color="primary"
                                                                                    formControlName="value">{{parameter.get('key').value}}</mat-slide-toggle>
                                                                            </p>

                                                                            <p *ngSwitchCase="'number'">
                                                                                <mat-form-field appearance="fill"
                                                                                    class="form-subject">
                                                                                    <mat-label>{{parameter.get('key').value}}</mat-label>
                                                                                    <input matInput
                                                                                        formControlName="value">
                                                                                </mat-form-field>
                                                                            </p>

                                                                            <p *ngSwitchCase="'string'">
                                                                                <mat-form-field appearance="fill"
                                                                                    class="form-subject">
                                                                                    <mat-label>{{parameter.get('key').value}}</mat-label>
                                                                                    <input matInput
                                                                                        formControlName="value">
                                                                                </mat-form-field>
                                                                            </p>

                                                                        </div>



                                                                    </ng-container>
                                                                </div>

                                                            </ng-container>


                                                        </div>
                                                    </ng-container>
                                                </div>

                                            </ng-container>

                                            <ng-container formArrayName="parameters"
                                                *ngIf="attribute.get('active').value && !attribute.get('multiple').value">
                                                <div
                                                    *ngFor="let parameter of attribute?.get('parameters')?.controls; let p = index">
                                                    <ng-container [formGroup]="parameter">
                                                        <div [ngSwitch]="parameter.get('type').value">

                                                            <p *ngSwitchCase="'boolean'">
                                                                <mat-slide-toggle color="primary"
                                                                    formControlName="value">{{parameter.get('key').value}}</mat-slide-toggle>
                                                            </p>

                                                            <p *ngSwitchCase="'number'">
                                                                <mat-form-field appearance="fill" class="form-subject">
                                                                    <mat-label>{{parameter.get('key').value}}</mat-label>
                                                                    <input matInput formControlName="value">
                                                                </mat-form-field>
                                                            </p>

                                                            <p *ngSwitchCase="'string'">
                                                                <mat-form-field appearance="fill" class="form-subject">
                                                                    <mat-label>{{parameter.get('key').value}}</mat-label>
                                                                    <input matInput formControlName="value">
                                                                </mat-form-field>
                                                            </p>

                                                        </div>



                                                    </ng-container>
                                                </div>

                                            </ng-container>


                                        </mat-card-content>
                                    </ng-container>
                                </mat-card>
                            </div>
                        </ng-container>


                    </mat-expansion-panel>
                </mat-accordion>


            </div>
        </ng-container>
    </ng-container>
</form>





<div class="buttons-right  my-2">

    <button style="background-color:transparent;" matTooltip="Back" type="button" class="my-bounce btn btn-primary mx-2"
        (click)="back()">
        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
    </button>

    <button style="background-color:transparent;" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()" *ngIf="hasSave" [disabled]="!form.valid">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>

</div>



<div [innerHTML]="HTMLStructure"></div>