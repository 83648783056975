
<div class="full-w" fxLayout="row" fxLayoutAlign="center center" style="text-align: center;" >
  <!-- <div class="card-title flex-column mrg">
    <h2 class="mb-1">{{'LABEL.HINT' | translate}}</h2>
    <div [ngClass]="getClassWorkflow(1)"><mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(1)">circle</mat-icon>&nbsp;&nbsp;{{'LABEL.HINT_REQUEST' | translate}}</div>
  </div>
  <br> -->
  <div style="margin:5px; width: 50%;">
    <div class="photo-block">
      <div class="photo-button-block">
        <button class="my-bounce btn btn-primary fx-button aln"
          (click)="openUploadDialog('new')"  [disabled]="disableUpload"><!-- [disabled]="isDisabled()" -->
          <mat-icon class="material-icons" style="color:#293683;">upload</mat-icon><span style="color:#293683;">{{'LABEL.Documenti' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
  <br/>
  <button class="my-bounce btn btn-primary fx-button aln"
    (click)="viewDocuments()" ><!-- ><!-- [disabled]="isDisabled()" [disabled]="disableUpload" -- -->
    <mat-icon class="material-icons" style="color:#293683;">search</mat-icon><span style="color:#293683;">{{'LABEL.VEDI_DOCS' | translate}}</span>
  </button>
  <!-- <app-documents-grid [documents]="documents"></app-documents-grid> -->
</div>

