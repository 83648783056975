import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, from, map, Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { DocumentService } from 'src/app/services/document.service';
import { LibService } from 'src/app/services/libService';
import { UserSessionService } from 'src/app/services/user-session.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { DocumentType } from 'src/app/models/document';
import { InspectionContactService } from 'src/app/services/inspection-contact.service';

export interface FileUploadModalDataInput {
  file?: File | Blob | string;
  documentTypes: Observable<DocumentType[]>;
  orderId?: number;
  buildingId?: number;
  orderVersion?: number;
  practiceId: number;
  practiceCode?: number;
  itemId: any;
  itemType: any;
}

@Component({
  selector: 'app-components-file-upload-modal',
  templateUrl: './components-file-upload-modal.component.html',
  styleUrls: ['./components-file-upload-modal.component.scss'],
})
export class ComponentsFileUploadModalComponent implements OnInit {

  inspectionContacts$ = this.inspectionContactService.getAllInspectionContacts();


  imageChangedEvent: any = '';
  preview: any;
  file: any;
  //documentTypes$ = this.documentTypeService.getAll()
  documentTypes$: any;

  orderObject: any = {}

  formGroup = this.fb.group({
    file: [null, Validators.required],
    filename: [null, Validators.pattern(/^[a-zA-Z0-9\-\_.]+$/)],
    description: [null, ''],
    type: [null, ''],
    typeName: [null, Validators.required],
    userUploader: [this.authService.user().email, Validators.required],
    practiceId: [null, null],
    orderId: [null, null],
    orderVersion: [null, null],
    subjectId: [null, null],
    buildingId: [null, null],
    requestId: [null, null],
    claimId: [null, null],
    complaintId: [null, null],
    itemType: [null, Validators.required],
    inspectionContactId: [null, null]
  });

  showWebcam: boolean = false;
  showFilename: boolean = false;
  showDescription: boolean = false;
  showPreview: boolean = false;
  fileTypeAccept: string;

  constructor(
    private fb: UntypedFormBuilder,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: FileUploadModalDataInput,
    public dialogRef: MatDialogRef<ComponentsFileUploadModalComponent>,
    private toastr: ToastrService,
    private userService: UserSessionService,
    private authService: AuthService,
    private libService: LibService,
    private documentTypeService: DocumentTypeService,
    public translate: TranslatorService,
    private inspectionContactService: InspectionContactService
  ) { }

  ngOnInit(): void {
    this.formGroup.get('type')?.valueChanges.subscribe((value: string) => {
      this.checkFileType(value);
    })

    if (this.data.file) {
      this.file = this.handleInputFile(this.data.file)
      this.onFileChange({
        target: {
          files: [this.file]
        }
      })
    }

    console.log(this.data)
    console.log(this.data.itemType)
    if (this.data.itemType) {

      this.formGroup.get(this.data.itemType)?.addValidators(Validators.required);
      this.formGroup?.get(this.data.itemType)?.setValue(this.data.itemId);
      this.formGroup?.get('itemType')?.setValue(this.data.itemType);


      switch (this.data.itemType) {
        case 'practiceId':
          this.formGroup.patchValue({
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion
          })
          break;
        case 'subjectId':

          break;
        case 'requestId':
          this.formGroup.patchValue({
            buildingId: this.data.buildingId,
          })
          break;
        case 'buildingId':

          break;


      }
    }

    this.showDescription = true;


    console.log("THIS-DATA  (FILEUPLOAD MODEL) -->", this.data);


    this.orderObject.code = this.data.practiceCode
    this.orderObject.version = this.data.orderVersion


    this.setValidators()

    this.documentTypes$ = this.data.documentTypes
    this.documentTypes$.subscribe((res: any) => {
      console.log("DOCUMENT TYPES --> ", res);
    })

    console.log('this.formGroup')
    console.log(this.formGroup)

  }

  public onFileChange(event: any) {
    console.log(event)
    if (event.target.files && event.target.files.length) {
      this.showFilename = true

      let filename = (event.target.files[0].name as string).replace(new RegExp(' ', 'g'), '-')
      console.log('previous: ', event.target.files[0].name, 'next: ', filename);

      this.formGroup.get('filename')?.setValue(filename);
      this.formGroup.get('filename')?.markAsTouched();
      const type = event.target.files[0].type;

      this.formGroup.get('type')?.setValue(type);

      const [file] = event.target.files;

      file.size !== 0 && file.size > 0 ?
        this.readFile(file) :
        console.log("file Senza dimensioni");
      this.imageChangedEvent = event;
    }
  }

  readFile(file: File | Blob) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      this.formGroup.patchValue({
        file: reader.result,
      });
      if (this.formGroup.get('type')?.value.includes('image')) {
        this.preview = reader.result
      }
    };

  }

  imageCropped(event: ImageCroppedEvent) {
    this.readFile(base64ToFile(event.base64!))
    console.log('image cropped');
  }

  private checkFileType(type: string) {

  }

  public onSubmit(): void {
    //TODO: add service
    this.libService.lockPage('');

    console.log(' - [this.documentService.upload] this.formGroup.getRawValue() %o', this.formGroup.getRawValue());


    this.documentService.createDocument(this.formGroup.getRawValue()).subscribe(
      (res: any) => {
        console.log(res)
        this.toastr.success(
          this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'))
        this.closeDialog(res)
        this.libService.unlockPage()
      },
      (err: any) => {
        console.log(err)
        this.toastr.error(err)
      }
    )

  }

  closeDialog(res: any) {
    this.dialogRef.close(res);
  }

  onImageEmitted(image: any) {
    this.file = this.handleInputFile(image.imageAsDataUrl);
    this.onFileChange({
      target: {
        files: [this.file]
      }
    })
  }

  handleInputFile(inputFile: Blob | File | string) {
    console.log(typeof inputFile);
    let file: File = new File([], '', {});
    switch (typeof inputFile) {
      case 'string':
        let arr = inputFile.split(',')
        let mime = arr[0].match(/:(.*?);/)![1]
        let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        file = new File([u8arr], 'newPhoto.jpg', { type: mime });
        break;

      default:
        break;
    }
    return file;
  }

  onShowWebcam() {
    this.showWebcam = !this.showWebcam
  }

  get selectedDocumentType() {

    return this.formGroup.get('typeName')?.value?.code == "INSP"
  }

  documentTypeSelected(documentType: DocumentType) {
    console.log(documentType)
    this.formGroup.get('typeName')?.setValue(documentType);

    if (this.formGroup.get('typeName')?.value?.code == "INSP") {
      this.formGroup.controls["inspectionContactId"].addValidators([Validators.required]);
    } else {
      this.formGroup.controls["inspectionContactId"].addValidators([]);
    }

    document.getElementById('file-input')!.click();
  }

  setValidators() {
    this.clearFormValidators();
    let controlArrayRequired: (AbstractControl | null)[] = []
    let controlArrayFileName: (AbstractControl | null)[] = []

    controlArrayFileName.push(this.formGroup.get('filename'))
    controlArrayRequired.push(this.formGroup.get('file'))
    controlArrayRequired.push(this.formGroup.get('typeName'))


    controlArrayRequired.forEach(control => {
      control?.setValidators([Validators.required])
      control?.updateValueAndValidity()
    })

    controlArrayFileName.forEach(control => {
      control?.setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9\-\_.]+$/)])
      control?.updateValueAndValidity()
    })
  }

  clearFormValidators() {
    let controls = Object.keys(this.formGroup.controls).filter(control => control !== 'type')
    console.log('clearFormValidators: ', controls);

    controls.forEach(controlName => {
      //console.log("Validator previous for", controlName, ':',this.form.get(controlName)?.validator)
      this.formGroup.get(controlName)?.clearValidators();
      this.formGroup.get(controlName)?.updateValueAndValidity();
      //console.log("Validator for", controlName, ':',this.form.get(controlName)?.validator)
    });
  }
}
