import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs';
import { NavService } from '../../services/nav.service';
import { WikiService } from '../../services/wikiservice.service';
import { ActionService } from '../../services/action.service';
import { Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-crm-sections',
  templateUrl: './crm-sections.component.html',
  styleUrls: ['./crm-sections.component.scss']
})
export class CrmSectionsComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "CRM";
  subTitle = "EDAC - SOGGETTI";
  actions: any;
  selectedTabIndex: number = 0
  sectionType: any = 'subjects'
  private subRoute: Subscription;

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    public wikiService: WikiService,
    private cdr: ChangeDetectorRef,
    private actionService: ActionService
  ) {}

  setSection() {
    switch (this.sectionType) {
      case 'subjects':
        this.selectedTabIndex = 0
        this.actions = [
          //{ name: 'export', url: '' },
          { name: 'new', url: 'subject/0', params: { type: 'Soggetto' } }
        ];
        this.subTitle = 'SOGGETTI'
        break;
      case 'requests':
        this.selectedTabIndex = 1
        this.actions = [
          //{ name: 'export', url: '' },
          { name: 'new', url: 'request/0' }
        ];
        this.subTitle = 'RICHIESTE'
        break;
      case 'activities':
        this.selectedTabIndex = 2
        this.actions = [
          //{ name: 'export', url: '' },
          { name: 'new', url: 'activity/0' }
        ];
        this.subTitle = 'ATTIVITA\''
        break;
      case 'claims':
        this.selectedTabIndex = 3
        this.actions = [
          //{ name: 'export', url: '' },
          { name: 'new', url: 'claim/0' }
        ];
        this.subTitle = 'RECLAMI'
        break;
      default:
        this.selectedTabIndex = 0
        this.actions = [
         // { name: 'export', url: '' },
          { name: 'new', url: 'subject/0', params: { type: 'Soggetto' } }
        ];
        break;
    }

    this.navService.setActions(this.actions);
    this.navService.setSubTitle(this.subTitle);
    this.cdr.detectChanges();
  }

  goToWiki() {
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }

  checkPermissions(itemType: any, actionType: any) {
    return this.actionService.checkPermissions(`${itemType}_${actionType}`)
  }

  goToWikiByKey(k: any) {
    window.open(this.wikiService.getWikiUrl(k));
  }

  ngOnInit(): void {
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);

    this.actions = [
      //{ name: 'export', url: '' },
      { name: 'new', url: 'subject/0', params: { type: 'Soggetto' } }
    ];
    console.log("ACTIONS --> ", this.actions)
    this.navService.setActions(this.actions);
    /* console.log("INIT INDEX --> ", this.tabGroup.selectedIndex); */
    this.navService.setWikiKey('CRM');

    this.subRoute = this.route
    .data
    .subscribe((v: any) => {
      console.log(v)
      if ('sectionType' in v) this.sectionType = v.sectionType
      this.setSection()
    });


  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subRoute.unsubscribe();
  }

}
