import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { DocumentService } from './document.service';
import { Order } from '../models/order';
import { Orders } from '../final-balancer/models/final-balance';
import { ODataSettings } from '@progress/kendo-data-query/dist/npm/odata.operators';
import { LogService } from './log.service';
import { Task } from 'src/app/models/task';
import { OrderPlanningMulti } from '../planner/models/orderPlanningMulti';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  name = 'OrderService';

  baseApi = environment.rootApiPaperwork;
  /* baseApi = "http://localhost:80/"; */

  constructor(
    public libService: LibService,
    private documentService: DocumentService,
    private logService: LogService,
    private authService: AuthService,
  ) {}

  findByFilter(options: any): Observable<Order[]> {
    console.log('OPTIONS --> ', options);
    // return of([]);
    return this.libService.postData<Order[]>(
      options,
      this.baseApi,
      'orders/allByFilter'
    );
  }

  getOrders(usr: string, id: any): Observable<Order[]> {
    console.log(this.baseApi);
    return this.libService.getData<Order[]>(
      this.baseApi,
      `orders/all/${usr.toString()}/${id}`
    );
  }

  getOrdersWithPagination(userToken: string, filterOptions: any, orderOptions: any, skip: number, take: number, enableFinancialData: true): Observable<Order[]> {

    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption,
      enableFinancialData: enableFinancialData || false,
    };
    return this.libService.postData<Order[]>(
      options,
      this.baseApi,
      `orders/allWithPagination`
    );
  }

  getOrdersPlanning(usr: any, id: any): Observable<Order[]> {
    console.log('[ordersService] - usr %o id %o', usr, id);
    return this.libService.postData<Order[]>(
      { user: usr, id: id },
      this.baseApi,
      'orders/getforplanningV3'
    );
  }

  getAdminOrdersPlanning(usr: any, id: any, idSede: any): Observable<Order[]> {
    console.log('[ordersService] - usr %o', idSede);
    return this.libService.postData<Order[]>(
      { user: usr, id: id, idSede: idSede },
      this.baseApi,
      'orders/getforplanningbysede'
    );
  }

  getOrdersAndTasks(usr: any, id: any, idSede: any): Observable<Order[]> {
    //console.log('[ordersService] - usr %o',idSede);
    return this.libService.postData<Order[]>(
      { user: usr, id: id, idSede: idSede },
      this.baseApi,
      'plannings/getOrdersAndTasks'
    );
  }

  getOrdersAndTasksMulti(
    user: string,
    id: number,
    idSede: number
  ): Observable<Task[]> {
    //console.log('[ordersService] - usr %o',idSede);
    return this.libService.postData<Task[]>(
      { user, id, idSede },
      this.baseApi,
      'plannings/getOrdersAndTasksMulti'
    );
  }
  getOrdersAndTasksEnergy(
    user: string,
    idSede: number
  ): Observable<Task[]> {
    return this.libService.postData<any>(
      { user, idSede },
      this.baseApi,
      'plannings/getOrdersAndTasksEnergy'
    );
  }

  getOrdersBalancing(
    user: any,
    id: any,
    isStaff: boolean,
    idSede: number
  ): Observable<Orders[]> {
    return this.libService.postData<Orders[]>(
      { user, id, isStaff, idSede },
      this.baseApi,
      'orders/getforbalancingv4'
    );
  }

  getNewCode(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, 'seedOrderCode/');
  }

  getOrder(id: string) {
    let order$ = this.libService.getData<Order>(
      this.baseApi,
      `orders/${id.toString()}`
    );
    let documents$ = this.documentService.findByOrder(id);

    forkJoin([order$, documents$]).pipe(
      map(([order, documents]) => {
        return {
          ...order,
          documents: documents,
        };
      })
    );

    return this.libService
      .getData<any>(this.baseApi, `orders/${id.toString()}`)
      .pipe();
  }

  getOrderDetail(id: string) {
    return this.libService.getData<any>(
      this.baseApi,
      `orders/${id.toString()}`
    );
  }

  getOrderStatuses() {
    return this.libService.getData<any>(this.baseApi, `orderStatus`);
  }
  /*
  getOrderByCode(code: string) {
    /* return this.libService.getData<Order[]>(this.baseApi, `orders/code/${code}`).pipe(
      mergeMap(orders => forkJoin(orders.map(order => this.documentService.findByOrder(order.id.toString()).pipe(
        map(documents => {
          return {
            ...order,
            documents: documents
          }
        })
      ))))
    ); */

  getOrderByCode(code: string) {
    return this.libService.getData<Order[]>(
      this.baseApi,
      `orders/codev3/${code}`
    );
  }

  getOrderVersions(code: string) {
    return this.libService.getData<any>(
      this.baseApi,
      `orders/versions/${code}`
    );
  }

  getOrderByCodeVersionPlanner(code: string, version: any) {
    return this.libService.postData<any>(
      { code: code, version: version },
      this.baseApi,
      `orders/findbycodeversionplanner`
    );
  }

  getOrderByCodeVersion(code: string, version: any) {
    return this.libService.postData<any>(
      { code: code, version: version },
      this.baseApi,
      `orders/findbycodeversionV3`
    );
  }

  getLastOrder(code: string) {
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `practices/${code}/getLastOrder`);
  }

  getOrderByCodeVersionV2(code: string, version: any) {
    return this.libService.postData<any>(
      { code: code, version: version },
      this.baseApi,
      `orders/findbycodeversionv2`
    );
  }

  updateOrder(id: string, order: any) {
    return this.libService
      .putData<any>({ order: order }, this.baseApi, `orders/${id.toString()}`)
      .pipe(
        tap((response) => {
          this.logService.writeLog(
            'POST',
            this.baseApi + `orders/${id.toString()}`,
            order,
            'OK'
          );
        }),
        catchError((error) => {
          this.logService.writeLog(
            'POST',
            this.baseApi + `orders/${id.toString()}`,
            order,
            error.status
          );
          return of(error);
        })
      );
  }

  createOrder(order: any) {
    console.log('create order service %o', order);
    return this.libService
      .postData<any>({ order: order }, this.baseApi, 'orders')
      .pipe(
        tap((response) => {
          this.logService.writeLog('POST', this.baseApi, order, 'OK');
        }),
        catchError((error) => {
          this.logService.writeLog('POST', this.baseApi, order, error.status);
          return of(error);
        })
      );
  }

  deleteOrder(id: number) {
    return this.libService.deleteData<any>(
      {},
      this.baseApi,
      `orders/${id.toString()}`
    );
  }

  presentOrder(order: any, value: any) {
    console.log('present order service %o', order);
    let str = 'orders/present';
    if (value > 20000) str = 'orders/presentover';
    return this.libService.postData<any>({ order: order }, this.baseApi, str);
  }

  nullOrder(idorder: any) {
    console.log('null order service %o', idorder);
    let order = { id: idorder };
    return this.libService.postData<any>(
      { order: order },
      this.baseApi,
      'orders/nullall'
    );
  }

  findByBuildingAndSubject(buildingId: string, subjectId: string) {
    let options = {
      buildingId: buildingId,
      subjectId: subjectId,
    };
    return this.libService.postData<Order[]>(
      options,
      this.baseApi,
      `orders/findByBuildingAndSubject`
    );
  }

  getSubjectIdsFTSearch(kwsearch: any, filterIDS?: any) {
    console.log('TRIGGER RICERCA --> ', kwsearch);
    console.log('[Filtri per ricerca] -> ', filterIDS);
    if (
      (filterIDS?.buildingId !== 0 && filterIDS?.buildingId !== null) ||
      (filterIDS?.subjectId !== 0 && filterIDS?.subjectId !== null)
    ) {
      return this.libService.postData<any>(
        { kwsearch: kwsearch, filterIDS: filterIDS },
        this.baseApi,
        'orders/orderSearch'
      );
    } else {
      return this.libService.postData<any>(
        { kwsearch: kwsearch },
        this.baseApi,
        'orders/orderSearch'
      );
    }
  }

  // getSubjectIdsFTSearch(code: {kwsearch: string}){
  //   return this.libService.getData<FTSearchResponse>(this.baseApi, `orders/findByLikeCode/${code.kwsearch}`);
  // }

  getById(ids: any) {}

  genGaranzia(orderId: number) {
    let obj = {
      order: {
        data: {
          toEntityId: orderId,
        },
      },
    };
    return this.libService.postData<any>(obj, this.baseApi, 'orders/garanzia');
  }

  getOrderBySubjectId(userMail: string, idSubject: number, type: string) {
    let obj = {
      user: userMail,
      id: idSubject,
      type: type,
    };
    // console.log("OBJ SERVIZIO FRONT END PRIMA DI CHIAMATA --> ", obj)
    return this.libService.postData<Order[]>(
      obj,
      this.baseApi,
      'orders/getbysubject'
    );
  }

  getOrderBySubjectIdWithPagination(
    userMail: string,
    idSubject: number,
    type: string
  ) {
    let obj = {
      user: userMail,
      id: idSubject,
      type: type,
    };
    // console.log("OBJ SERVIZIO FRONT END PRIMA DI CHIAMATA --> ", obj)
    return this.libService.postData<Order[]>(
      obj,
      this.baseApi,
      'orders/getbysubjectWithPagination'
    );
  }

  getDocumentsFromStatusId(statusId: number, userEmail: string) {
    return this.libService.postData<any[]>(
      { id: statusId, user: userEmail },
      this.baseApi,
      'orders/getdocumentsfromstatusid'
    );
  }

  find(options: { key: string; values: string[] }[]) {
    return of([]);
    return this.libService.postData<Request[]>(
      options,
      this.baseApi,
      '/requestsFind'
    );
  }

  getOrderByCodeVersionArray(options: any[]) {
    return this.libService.postData<any>(
      options,
      this.baseApi,
      `orders/findbycodeversionArray`
    );
  }

  getOrderByRifRequest(id: any) {
    return this.libService.getData<any>(
      this.baseApi,
      `orders/findByRifRequest/${id}`
    );
  }

  checkNullable(id: any) {
    return this.libService.getData<any>(this.baseApi, `orders/checknullable/${id}`);
  }

  addEstimateDeliveryMethod(orderId: any, estimateDeliveryMethodId: any): Observable<any> {
    return this.libService.postData<any>(
      {},
      this.baseApi,
      `orders/${orderId}/addEstimateDeliveryMethod/${estimateDeliveryMethodId}`
    );
  }

  getOrderForPlanning(id: number): Observable<OrderPlanningMulti[]> {
    return this.libService.getData<OrderPlanningMulti[]>(
      this.baseApi,
      `orders/${id}/forPlanning`
    );
  }
}
