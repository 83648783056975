import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TaxStepService } from 'src/app/services/taxstep.service';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-taxstep-detail',
    templateUrl: './taxstep-detail.component.html',
    styleUrls: ['./taxstep-detail.component.scss']
})
export class TaxstepDetailComponent implements OnInit {

    id: any;
    target: any;
    target$: Observable<any>;
    formTargets: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder,
        private taxStepService: TaxStepService,
        private route: ActivatedRoute,
        private libService: LibService,
        public translate: TranslatorService,
        public navService: NavService,
        private toastService: ToastrService,
        private router: Router) {
        this.route.paramMap.subscribe(params => {
            this.id = params.get('id');
            if (this.id != 0) this.target$ = this.taxStepService.getStep(this.id);
        });
        this.formTargets = this.fb.group({
            id: [null],
            percStep: [null, Validators.required],
            percIncrement: [null, Validators.required],
            percIncrementFranchising: [null, Validators.required],
        })
    }

    update() {
        const formObject = this.formTargets.getRawValue();
        if (formObject.id == null) {
            // Creation
            delete formObject.id;
            this.taxStepService.createStep(formObject).subscribe({
                error: (err) => {
                    console.error(err);
                    this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
                },
                complete: () => {
                    this.toastService.success(
                        this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
                    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this.router.navigateByUrl(`genconfig`).then(console.log).catch(console.error)
                }
            })
        }
        else {
            // Update
            this.taxStepService.updateStep(formObject).subscribe({
                next(success) {
                    console.log(success);
                },
                error: (err) => {
                    console.error(err);
                    this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
                },
                complete: () => {
                    this.toastService.success(
                        this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
                    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this.router.navigateByUrl(`genconfig`).then(console.log).catch(console.error)
                }
            })
        }
    }

    ngOnInit(): void {
        this.initNavbar();
        if (this.id != 0) {
            this.libService.lockPage('');
            this.target$.subscribe(val => {
                this.target = val;
                console.log("val -->", val);
                this.formTargets.patchValue(this.target);
                this.libService.unlockPage();
            })
        }
    }

    initNavbar() {
        setTimeout(() => {
            let actions: any;
            actions = [
                { name: 'back', url: 'genconfig' }
            ];

            let title = this.formTargets.get('id')?.value !== null ?
                this.translate.instant('LABEL.EDIT', { field: this.translate.instant('GEN_CONFIG.CREDIT_CESSION') }) :
                this.translate.instant('LABEL.NEW', { field: this.translate.instant('GEN_CONFIG.CREDIT_CESSION') })
            let subTitle = "EDAC - " + title
            this.navService.setActions(actions);
            this.navService.setTitle(title);
            this.navService.setSubTitle(subTitle);
        }, 1000);

    }

}
