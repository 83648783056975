import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subscriber } from 'rxjs';


@Component({
  selector: 'app-fulltextsearch',
  templateUrl: './fulltextsearch.component.html',
  styleUrls: ['./fulltextsearch.component.scss']
})
export class FulltextsearchComponent implements OnInit {

  @Input() service: any;
  @Input() component: any;
  @Input() dropdown: boolean;

  @Output() selected = new EventEmitter;
  @Output() clearOut = new EventEmitter;
  @Output() list = new EventEmitter;

  fullTextSearchSubscriber: Subscriber<any>;
  myControl: UntypedFormControl = new UntypedFormControl('');
  kwSearch: string = '';
  options: any;
  selectedSub: any;
  clearFields: boolean = false;



  constructor() { }

  ngOnInit(): void {
  }

  fulltextSearch(event: any) {
    if (this.fullTextSearchSubscriber) {
      console.log("UNNSUBSCRIBED --> ", this.fullTextSearchSubscriber);
      this.fullTextSearchSubscriber.unsubscribe();
    }

    let obj = { kwsearch: event }
    this.fullTextSearchSubscriber = this.service.getFTSearchResult(obj)
      .subscribe((opt: any) => {
        this.options = opt
        this.list.emit(this.options);
      });


  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.selectedSub = event.option.value;
    console.log("SOGGETTO SELEZIONATO --> ", this.selectedSub);
    if (!(this.selectedSub.surname == null))
      this.myControl.setValue(this.selectedSub.name + ' ' + this.selectedSub.surname);
    else
      this.myControl.setValue(this.selectedSub.name);

    this.selected.emit(this.selectedSub);

  }

  clear() {
    this.clearFields = true;
    this.kwSearch = "";
    this.clearOut.emit(this.clearFields);
    this.clearFields = false;


  }

}
