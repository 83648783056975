<h1>Aggiungi evento</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline">
    <mat-label>Titolo</mat-label>
    <input matInput placeholder="Titolo" formControlName="title" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Descrizione</mat-label>
    <textarea matInput placeholder="Descrizione" formControlName="description"></textarea>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Note</mat-label>
    <textarea matInput placeholder="Note" formControlName="note"></textarea>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Data di inizio</mat-label>
    <input matInput [matDatepicker]="tsStart" formControlName="tsStart" />
    <mat-hint>GG/MM/AAAA</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="tsStart"></mat-datepicker-toggle>
    <mat-datepicker #tsStart></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Data di fine</mat-label>
    <input matInput [matDatepicker]="tsEnd" formControlName="tsEnd" />
    <mat-hint>GG/MM/AAAA</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="tsEnd"></mat-datepicker-toggle>
    <mat-datepicker #tsEnd></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Codice commessa</mat-label>
    <mat-select formControlName="practiceId">
      <mat-option *ngFor="let order of data.orders" [value]="order.id">
        {{ order.code }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-radio-group color="primary" aria-label="Categoria" formControlName="eventTypeId" class="example-radio-group">
    <mat-label>Categoria:</mat-label>
    <mat-radio-button class="example-radio-button" *ngFor="let eventType of data.eventTypes" [value]="eventType.id">{{eventType.label}}</mat-radio-button>
  </mat-radio-group>

  <mat-radio-group color="primary" aria-label="Tipologia" formControlName="eventCategoryId" class="example-radio-group">
    <mat-label>Tipologia:</mat-label>
    <mat-radio-button class="example-radio-button" *ngFor="let eventCategory of data.eventCategories" [value]="eventCategory.id">{{eventCategory.label}}</mat-radio-button>
  </mat-radio-group>

  <div *ngIf="form.hasError('invalidDateRange')">
    <br>
    <mat-card appearance="outlined" class="errorModal">
      <mat-card-content>
        <strong>
          La data di inizio non può essere successiva alla data di fine.
        </strong>
      </mat-card-content>
    </mat-card>

  </div>
  <div class="actions">
    <button type="button" mat-stroked-button mat-dialog-close color="warn">
      Annulla
    </button>
    <button type="submit" [disabled]="form.invalid" mat-flat-button color="primary">Salva</button>
  </div>
</form>