import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Document } from 'src/app/models/document';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-practice-document',
  templateUrl: './practice-document.component.html',
  styleUrls: ['./practice-document.component.scss']
})
export class PracticeDocumentComponent implements OnInit,OnChanges {

  @Input() orderVersions: any
  @Input() documents: any

  legacyDocuments: Document[] = []

  constructor(
    private documentService: DocumentService
  ) { }

  ngOnInit(): void {
    console.log('[Init Order DocumentsComponent]');

  }
  

  ngOnChanges(changes: SimpleChanges): void {
  }

  legacyOpened(){
    let code = this.orderVersions[0].code
        this.documentService.findLegacyDocumentsByOrders(code).subscribe(documents => {
          console.log('legacy documents', documents);

          this.legacyDocuments = documents.map(document => {
            let convertedDocument = {
              name: document.NomeFile,
              typeName: {
                uiLabel: document.DocumentType
              },
              userUploader: document.UtenteCreatoreId,
              createdAt: document.DataCreazione as Date,
              awsPath: document.RemoteFile
            }

            return convertedDocument as Document
          })
        })
  }

  getNumberDocuments(){
    return this.documents.length
  }

  getNumberLegacyDocuments(){
    return this.legacyDocuments.length
  }


  /* ngOnChanges(changes: SimpleChanges): void {
    console.log('[DocumentsComponent loading documents]');
      if(changes['orderVersions'].previousValue !== changes['orderVersions'].currentValue){
        this.documents = []
        this.orderVersions.forEach((orderVersion: any) => {
          this.documents.push(...orderVersion.documents)
        });
      }
  } */


}
