import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(public libService: LibService) { }

  getNews() {
    return this.libService.postDataWithOptions(environment.rootNews,'get-news',environment.rootToken);
  }
}
;
