import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  baseApi = environment.rootApiInvoices;

  constructor(public libService: LibService) { }

  getInvoices(usr: string, practice: string) {

    let py = {
      fattura: {
        Id: '',
        Commessa: practice,
        CommessaId: '',
        Sede: '',
        Responsabile: '',
        DataInizio: '',
        DataFine: '',
        TipoSocieta: '',
        Stato: '',
        SocietaId: ''
      }
    };
    return this.libService.postData<any>(py, this.baseApi, '?account=' + usr);
  }

}