import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function codeCharacter(): ValidatorFn {
	return (control:AbstractControl) : ValidationErrors | null => {

		const value = control.value;

		if (!value) {
			return null;
		}
		if(value.replace(/[a-zA-Z0-9_]*/, '').length > 0){
			// Non a-Z character are present
			return {valid: false}
		}

		return null;
	}
}