export enum UserRole {
  TEAMLEADER = 36,
  ASSISTANCE = 35,
  MULTI = 62,
  ENERGY = 87
}

export class UserRoleUtils {
  protected constructor() {}

  public static defineLabel(id: number) {
    switch (id) {
      case UserRole.TEAMLEADER: {
        return 'Teamleader';
      }
      case UserRole.ASSISTANCE: {
        return 'Assistant';
      }
      case UserRole.MULTI: {
        return 'Multiservices';
      }
      case UserRole.ENERGY: {
        return 'Energy';
      }
      default: {
        return 'No role';
      }
    }
  }
}
