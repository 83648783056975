import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';
import { DocumentWorkflowService } from '../services/document-workflow.service';
import { trigger, state,style,transition,animate} from '@angular/animations';

@Component({
  selector: 'app-document-workflow',
  templateUrl: './document-workflow.component.html',
  styleUrls: ['./document-workflow.component.scss'],
  animations: [
    trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4,0.0, 0.2, 1)')),
    ])]
})
export class DocumentWorkflowComponent implements OnInit {

  displayedColumns = ['id', 'codeStatusFrom','labelStatusFrom','codeStatusTo','labelStatusTo','viewDocumentsAction'];
  displayedColumnsSlave = [ 'code', 'mustSigned'];

  dataSource: any;
  kwSearch: any;
  expandedElement: any;
  oldSelectedElement:any;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;


  constructor(private documentWorkflowService: DocumentWorkflowService, public libService: LibService, private router: Router) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.loadStatuses();
  }

  clickPP(element:any) {

    this.expandedElement = this.expandedElement === element ? null : element;

    if (this.expandedElement === element) {
      console.log('UGUALI');
    }
    else {
      console.log('DIVERSI');
    }

    if (this.oldSelectedElement != null && this.oldSelectedElement != element)
      this.oldSelectedElement.selected = false;

    if (element.selected == false) {
      if (this.expandedElement != null)
        this.expandedElement.selected = true;
      element.selected = true;
      element.touched = true;
    }
    else {
      element.selected = false;
      if (this.expandedElement != null)
        this.expandedElement.selected = false;

    }
    this.oldSelectedElement = element;

  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  loadStatuses(): void {
    
    this.libService.lockPage('');
    this.documentWorkflowService.getAllDocWorkflow().subscribe((response) => {
      console.log('loadWorkFlows response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
    
  }

}



