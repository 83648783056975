import { LibService } from 'src/app/services/libService';

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, combineLatestAll, forkJoin, map, merge, Observable, of, race, startWith, Subject, switchMap, tap } from 'rxjs';
import { Order } from 'src/app/models/order';
import { Status } from 'src/app/models/status';
import { BuildingService } from 'src/app/services/building.service';
import { CompanyService } from 'src/app/services/companyService';
import { CondominiumService } from 'src/app/services/condominium.service';
import { FTIndexAllService } from 'src/app/services/ftindex-all.service';
import { OrderService } from 'src/app/services/order.service';
import { RequestService } from 'src/app/services/request.service';
import { SolictReasonsService } from 'src/app/services/solict-reasons.service';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { UserDataService } from 'src/app/services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { RoleService } from 'src/app/services/role.service';
import { ComponentService } from 'src/app/services/component.service';

@Component({
  selector: 'app-request-search-practice-request',
  templateUrl: './request-search-practice-request.component.html',
  styleUrls: ['./request-search-practice-request.component.scss']
})
export class RequestSearchPracticeRequestComponent implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() isNew: boolean;
  @Input() components: any;

  componentName: any = 'request_search_practice_and_request'
  attributes: any

  orders: Order[] = []
  filterIDS: any = { buildingId: 0, subjectId: 0, subjectType: '' };
  statusTemporary: Status
  filteredOrders: any
  controlTypes = ['Privato', 'Amministratore'];
  hideLegalEntity: boolean = true;
  solicitReasons: any;
  eventSearch: Subject<void> = new Subject<void>();
  clean: boolean = false;
  selectedTab: number = 0;
  selectedOrder: any;
  requests: any;
  selectedRequest: any;
  myControl = new UntypedFormControl;
  filterControl = new UntypedFormControl;
  filteredItems: any;
  formSelect: UntypedFormGroup;
  statuses: any = [];
  statusDescription: any;
  disable: boolean = false;
  loggedUser: any;

  solicitReasons$ = this.solicitReasonService.getAllSolicitReasons();
  statuses$ = this.orderService.getOrderStatuses();
  subjectId$: Observable<any>;
  buildingId$: Observable<any>;
  subjectType$: Observable<any>;
  loggedUser$ = this.userSessionService.getUser(this.authService.user().email).pipe(
    map((val) => this.loggedUser = val));

  constructor(
    public subjectService: SubjectService,
    public buildingService: BuildingService,
    private statusService: StatusService,
    private userSessionService: UserSessionService,
    private authService: AuthService,
    public orderService: OrderService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    public requestService: RequestService,
    private companyService: CompanyService,
    private condominiuService: CondominiumService,
    private libService: LibService,
    private userService: UserDataService,
    public translate: TranslateService,
    private roleService: RoleService,
    private solicitReasonService: SolictReasonsService,
    private componentService: ComponentService
  ) {
    this.formSelect = this.fb.group({
      listaOrdini: null
    })
  }

  ngOnInit(): void {
    if (this.form && this.checkComponent()) {
      this.initComponent()
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initComponent()
  }

  initComponent() {

    this.addValidations()

    forkJoin([this.solicitReasons$]).subscribe(([reasons]: any) => {
      this.solicitReasons = reasons;
      if (this.solicitReasons !== null && this.solicitReasons !== undefined)
        this.solicitReasons.sort((a: any, b: any) => this.translate.instant('LABEL.' + a.uiLabel!) <= this.translate.instant('LABEL.' + b.uiLabel!) ? -1 : 1)

      //this.disableFields();
    })


    this.form.get('subject')?.disable();
    this.form.get('building')?.disable();
    this.form.get('order')?.disable();
    this.form.get('solicitRequest')?.disable();

    this.formSelect.get('listaOrdini')?.valueChanges.subscribe(order => {
      this.selOrder(order);
    })
    console.log(this.form)
    let orderId = this.form.get('orderId')?.value;
    let reqId = this.form.get('requestId')?.value;
    console.log('SET TAB')
    console.log(orderId)
    console.log(reqId)
    if (orderId !== null && orderId !== undefined) {
      this.selectedTab = 0;
    }
    if (reqId !== null && reqId !== undefined) {
      this.selectedTab = 1;
    }

    this.statusService.getById(1, 'Subject').subscribe(res => {
      this.statusTemporary = res
      this.form.get('subject')?.get('status')?.setValue(this.statusTemporary)
      this.form.get('building')?.get('status')?.setValue(this.statusTemporary)
    })

    this.subjectId$ = this.form.get('subject')!.get('id')!.valueChanges.pipe(
      map(subjectId => {
        return {
          subjectId: subjectId,
          // subjectType: subject.type,
          buildingId: this.form.get('building')?.get('id')?.value
        }
      })
    )
    this.subjectType$ = this.form.get('subject')!.get('type')!.valueChanges.pipe(
      map(subjectType => {

        return {
          subjectType: subjectType,
          // subjectType: subject.type,
          buildingId: this.form.get('building')?.get('id')?.value
        }
      })
    )
    this.buildingId$ = this.form.get('building')!.get('id')!.valueChanges.pipe(
      map(buildingId => {
        return {
          subjectId: this.form.get('subject')?.get('id')?.value,
          buildingId: buildingId
        }
      })
    )

    merge(this.subjectId$, this.buildingId$).pipe(
      switchMap((value) => {
        this.filterIDS = value;

        if (this.clean === false) {
          if (this.selectedTab === 0) {
            return (this.filterIDS.buildingId !== null && this.filterIDS.buildingId !== undefined) ||
              (this.filterIDS.subjectId !== null && this.filterIDS.subjectId !== undefined) ?
              this.orderService.findByBuildingAndSubject(value.buildingId, value.subjectId) :
              of([]);
          } else {
            return (this.filterIDS.buildingId !== null && this.filterIDS.buildingId !== undefined) ||
              (this.filterIDS.subjectId !== null && this.filterIDS.subjectId !== undefined) ?
              this.requestService.getBySubjectAndBuilding(this.filterIDS) :
              of([]);
          }
        } else {
          return of([])
        }
      })
    ).subscribe(values => {
      if (values?.length > 0) {
        console.log("RISULTATO CHIAMATA --> ", values);
        this.orders = [];
        this.requests = [];

        this.selectedTab === 0 ?
          this.orders = values :
          this.requests = values;
      }
    })

    this.form.get('subject')?.valueChanges.subscribe(subject => {
      console.log("REQUEST SUBJECT --> ", subject);
      if (this.controlTypes.includes(subject.type)) {
        this.hideLegalEntity = true;
        //this.form.get('subject')?.get('surname')?.setValidators([Validators.required]);this.form.updateValueAndValidity();
        this.cdr.detectChanges();
      } else {
        this.hideLegalEntity = false;
        // this.form.get('subject')?.get('surname')?.clearValidators();this.form.updateValueAndValidity();
        this.cdr.detectChanges();
      }
    })
  }

  ngOnDestroy(): void {
    if (this.form && this.checkComponent()) {
      this.form?.get('subject')?.reset()
      this.form?.get('building')?.reset()

      this.form?.get('subject')?.enable()
      this.form?.get('building')?.enable()
    }
  }

  getDisabled() {
    return false
    return this.form.get('id')?.value !== null && this.form.get('id')?.value !== undefined
  }

  selSubject(event: any) {
    console.log('Selected: ', event);
    switch (event.type) {
      case 'Edificio':
        this.clearForm('building')
        this.form.get('building')?.patchValue(event)
        break;
      default:
        this.clearForm('subject')
        this.form.get('subject')?.patchValue(event)
        break;
    }
  }

  selOrder(event: any) {
    console.log('Selected: ', event);
    this.orderService.getOrderByCode(event.code).subscribe((order: any) => {
      console.log("[Order Flattened] ->", order.flat())
      this.patchAllOrderDetails(order[0])
    })
  }

  selRequest(event: any) {
    console.log('Selected: ', event);
    this.patchAllRequestDetails(event)
  }

  clearForm(formName: string) {

    this.form.get(formName)?.reset()
    if (formName === 'order') {
      this.clean = true;
      this.form.get('subject')?.reset();
      this.form.get('building')?.reset();
      this.form.get('solicitRequest')?.reset();
      this.orders = [];
      this.requests = [];
      this.statusDescription = {};
      this.clean = false;
    }
    //set status provvisorio per subject e building
    this.form.get(formName)?.get('status')?.setValue(this.statusTemporary)
  }

  get addressSubjectValid() {
    let streetName = this.form.get('subject')?.get('streetName')?.valid
    let streetNumber = this.form.get('subject')?.get('streetNumber')?.valid
    let zip = this.form.get('subject')?.get('zip')?.valid
    let city = this.form.get('subject')?.get('city')?.valid
    let state = this.form.get('subject')?.get('state')?.valid
    let country = this.form.get('subject')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get addressBuildingValid() {
    let streetName = this.form.get('building')?.get('streetName')?.valid
    let streetNumber = this.form.get('building')?.get('streetNumber')?.valid
    let zip = this.form.get('building')?.get('zip')?.valid
    let city = this.form.get('building')?.get('city')?.valid
    let state = this.form.get('building')?.get('state')?.valid
    let country = this.form.get('building')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get subjectForm() {
    return this.form.controls['subject'] as UntypedFormGroup
  }

  get buildingForm() {
    return this.form.controls['building'] as UntypedFormGroup
  }


  patchAllOrderDetails(event: any) {
    let company$: any, building$: any, subject$: any, status$: any, condominium$: any, admin$: any, responsible$: any;
    event.idSede !== null && event.idSede !== undefined ? company$ = this.companyService.getCompany(event.idSede) : company$ = of(null);
    event.idBuilding !== null && event.idBuilding !== undefined ? building$ = this.buildingService.getById([event.idBuilding]) : building$ = of(null);
    event.idSubject !== null && event.idSubject !== undefined ? subject$ = this.subjectService.getById([event.idSubject]) : subject$ = of(null);
    event.idCondominius !== null && event.idCondominius !== undefined ? condominium$ = this.condominiuService.getById([event.idCondominius]) : condominium$ = of(null);
    event.idAdminCondominius !== null && event.idAdminCondominius !== undefined ? admin$ = this.subjectService.getById([event.idAdminCondominius]) : admin$ = of(null);
    event.idRespCommerciale !== null && event.idRespCommerciale !== undefined ? responsible$ = this.userService.getUser(event.idRespCommerciale.toString()) : responsible$ = of(null);

    this.statusDescription = this.statuses.find((stat: any) => stat.id === event.idStatus);

    this.libService.lockPage('');
    forkJoin([company$, building$, subject$, condominium$, admin$, responsible$]).subscribe({
      next: ([company, building, subject, condominium, admin, resp]: any) => {

        let subj = subject !== null && subject !== undefined ? subject[0] : null;
        admin !== null && admin !== undefined ? subj = admin[0] : admin = admin;
        this.form.patchValue({
          assignmentOperator: resp.email,
          company: company.code,
          building: building !== null && building !== undefined ? building[0] : null,
          subject: subj !== null && subj !== undefined ? subj : null,
          order: {
            id: event.id,
            code: event.code,
            nameBuilding: event.nameBuilding,
            nameCondominius: event.nameCondominius,
            nameAdminCondominius: event.nameAdminCondominius,
            nameSubject: event.nameSubject,
            idStatus: event.idStatus,
            statusDescription: this.statusDescription
          }
        })
        this.libService.unlockPage();
        // console.log("VALORE FORM PRIMA DI SALVARE --> ", this.form.getRawValue());
      }
    })
  }

  compareFn(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  onTabChanged(e: any) {
    this.selectedTab = e.index;
    if (this.selectedTab === 0) {
      if ((this.filterIDS.buildingId !== null && this.filterIDS.buildingId !== undefined) ||
        (this.filterIDS.subjectId !== null && this.filterIDS.subjectId !== undefined)) {
        this.form.get('solicitRequest')?.reset();
        this.orderService.findByBuildingAndSubject(this.filterIDS.buildingId, this.filterIDS.subjectId).subscribe(
          values => {
            this.orders = values;
          }
        )
      }

    } else {
      if ((this.filterIDS.buildingId !== null && this.filterIDS.buildingId !== undefined) ||
        (this.filterIDS.subjectId !== null && this.filterIDS.subjectId !== undefined)) {
        this.form.get('order')?.reset();
        this.requestService.getBySubjectAndBuilding(this.filterIDS).subscribe(
          values => {
            this.requests = values;
          }
        )
      }
    }
  }

  patchAllRequestDetails(event: any) {
    this.form.patchValue({
      assignmentOperator: event.assignmentOperator,
      building: event?.building !== null && event?.building !== undefined ? event.building : null,
      subject: event?.subject !== null && event?.subject !== undefined ? event.subject : null,
      solicitRequest: {
        id: event.id,
        workType: event?.workType?.workType,
        assignmentOperator: event?.assignmentOperator,
        nameBuilding: event?.building?.name,
        nameSubject: event?.subject?.name

      }
    })
  }

  onKey(e: any) {
    if (!this.orders) {
      return;
    }
    // get the search keyword
    let search = this.filterControl.value;
    if (!search) {
      this.filteredItems.next(this.orders.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredItems.next(
      this.orders.filter(item =>
        item['code'].toLowerCase().indexOf(search) > -1
      ));
  }
  /*
    disableFields() {
      this.roleService.getMainRole(this.loggedUser);
      if (this.loggedUser[0].role === 'Responsabile Area') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    }
    */

  setAttributes() {
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) this.setAttributes()
    return status
  }

  mandatoryVerification(field: any) {
    return this.componentService.mandatoryVerification(field, this.attributes)
  }

  addValidations() {
    //console.log('addValidation - Practice/Request')
    //  console.log(this.attributes)
    if (this.attributes) {
      for (const [key, value] of Object.entries(this.attributes)) {
        if (this.mandatoryVerification(key)) this.form.get('subject')!.get(key)?.addValidators(Validators.required);
      }
    }

  }

}
