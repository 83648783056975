import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of, Subscriber, tap, Subject as Subject2 } from 'rxjs';
import { SubjectsRelations } from 'src/app/models/subjectsRelations';
import { BuildingService } from 'src/app/services/building.service';
import { CondominiumService } from 'src/app/services/condominium.service';
import { LibService } from 'src/app/services/libService';
import { RoleService } from 'src/app/services/role.service';
import { SubjectService } from 'src/app/services/subject.service';
import { SubjectsRelationsService } from 'src/app/services/subjects-relations.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';

@Component({
  selector: 'app-crm-form-relations',
  templateUrl: './crm-form-relations.component.html',
  styleUrls: ['./crm-form-relations.component.scss']
})
export class CrmFormRelationsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() toDisable: boolean;
  @Input() loggedUser: any;

  relationSearchTo: string = '';
  optionsTo: any;
  controlTo = new UntypedFormControl('');
  selTo: any;
  selFrom : {id?: any, type?: string} = {};
  searchType: string = 'Soggetto';
  searchService: any = this.subjectService;

  columns: DatatableColumn<SubjectsRelations>[] = [
    {
      name: 'id',
      flex: '10',
      title: of(this.translate.instant('LABEL.ID')),
      cssClass: relation => ['column-padding'],
      value: relation => relation.id
    },
    {
      name: 'fromName',
      // flex: '25',
      title: this.translate.get('LABEL.SoggettoRelazioneDA'),//this.translate.instant('')
      cssClass: relation => ['column-padding'],
      value: relation => (this.form.get('name')?.value !== null?
                            this.form.get('name')?.value + " " + (this.form.get('surname')?.value !== null? this.form.get('surname')?.value: ''):
                            this.form.get('businessName')?.value) + " (" +  this.form.get('type')?.value + ")"
    },
    {
      name: 'toName',
      // flex: '25',
      title: this.translate.get('LABEL.SoggettoRelazioneA'),
      cssClass: relation => ['column-padding'],
      value: relation => {
        switch(relation.subjectToType){
          case 'Condominio':
            return `${relation.related.businessName} (${relation.related.type})`;
          case 'Edificio':
            return `${relation.related.name} (${relation.related.type})`
          default:
            return `${relation.related.name} ${relation.related.surname} (${relation.related.type})`
        }
      }
    }
  ]

  actions: DatatableAction<SubjectsRelations>[] = [
    {
      label: of(''),
      icon: relation => 'delete',
      onClick: relation => this.deleteRow(relation),
      color: 'primary'
    }
  ];

  dataSource: any[];
  eventsSubject: Subject2<void> = new Subject2<void>();

  displayedColumns = ['id', 'fromName', 'toName'];

  fullTextSearchSubscriberTo: Subscriber<any>;

  constructor(
    private toastr: ToastrService,
    private relationService: SubjectsRelationsService,
    public condominiumService: CondominiumService,
    public libService: LibService,
    public buildingService: BuildingService,
    public subjectService: SubjectService,
    public translate: TranslatorService,
    private roleService: RoleService)
  {

  }

  ngOnInit(): void {
    this.roleService.getMainRole(this.loggedUser);
    this.loggedUser !== undefined? (this.loggedUser[0].role === 'BackOffice'? this.actions = []: undefined) : undefined;
    let id = this.form.get('id')?.value
      if(id !== 0){
        id !== undefined && id !== null?
        this.loadDatasets(): undefined;
      }
  }

  loadDatasets() {
    this.libService.lockPage('');
    // const type = (this.form.get('type')?.value === 'Condominio' || this.form.get('type')?.value === 'Edificio')? this.form.get('type')?.value : 'Soggetto';
    const type = this.form.get('type')?.value;
    this.relationService.getSubjectRelationsFull(this.form.get('id')?.value, type).subscribe((response) => {
      //set AdministeredCondominiums count
      this.form.get('totAdministeredCondominiums')?.setValue(response.filter((element: any) => element.subjectToType === 'Condominio').length)
      console.log("RELAZIONI --> ", response);
      this.dataSource = response

      this.libService.unlockPage();
      this.form.get('id')?.value > 0? this.alertSubject(): undefined;
    },
      err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
      });

  }

  fulltextSearchTo(event: any) {
    if (this.fullTextSearchSubscriberTo) {
      this.fullTextSearchSubscriberTo.unsubscribe();
    }
    console.log(event)
    let obj = { kwsearch: event };
    this.fullTextSearchSubscriberTo == this.subjectService.getFTSearchResult(obj)
      .subscribe((opt: any) => {
        this.optionsTo = opt;
      });
  }

  setSubject(event: MatAutocompleteSelectedEvent) {
    console.log("SOGGETTO SELEZIONATO --> ", event);
    this.selTo = event;
    // this.controlTo.setValue(event.option.value.name + ' ' + event.option.value.surname);

  }

  creaRelazione() {
    let newRelation: SubjectsRelations = {};
    this.selFrom = this.form.getRawValue();
    console.log("TO --> ", this.selTo);
    console.log("FROM --> ", this.selFrom);
    if (this.selTo && this.selFrom) {

      newRelation.subjectTo = this.selTo.id;
      newRelation.subjectToType = this.selTo.type;
      newRelation.subjectFrom = this.selFrom.id;
      newRelation.subjectFromType = this.selFrom.type;

      console.log("NEW RELATION --> ", newRelation);
      this.libService.lockPage('');
      this.relationService.createSubjectRelation(newRelation).subscribe(response => {
        this.libService.unlockPage();
        // this.subject = response;
        this.loadDatasets();
        this.toastr.success(
          this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        //console.log(response);
        //this.loadDatasets();
      });
    } else {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE30'), this.translate.instant('TOASTR.WARNING'));
    }

  }

  onSearchTypeChange(e: any) {
    switch (e) {
      case 'Soggetto':
        // this.dataSource = this.firstSubjects;
        this.searchService = this.subjectService;
        this.emitEventToFTSearch();
        break;
      case 'Edificio':
        // this.dataSource = this.firstBuildings;
        this.searchService = this.buildingService;
        this.emitEventToFTSearch();
        break;
      case 'Condominio':
        // this.dataSource = this.firstCondominiums;
        this.searchService = this.condominiumService;
        this.emitEventToFTSearch();
        break;
      default:
        break;
    }
  }

  deleteRow(e: any) {
    //console.log("DELETE ROW --> %o in %o", e, this.relations.data);
    this.libService.lockPage('');
    this.relationService.deleteSubjectsRelation(e.id, e).subscribe(r => {
      console.log("RISPOSTA DELETE --> ", r);
      this.loadDatasets();
      this.libService.unlockPage();
      //console.log('r after delete %o',r);
      this.toastr.success(
        this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
      let cp: any = [];
      // for (let a = 0; a < this.relations.data.length; a++) {
      //   if (!(e.id == this.relations.data[a].id)) {
      //     cp.push(this.relations.data[a]);
      //   }
      // }
      // this.relations.data = cp;
    }, err => {
      this.libService.unlockPage();
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), 'Attenzione');
    });
  }

  alertSubject() {
    if ((this.form.get('telephone2')?.value === null || this.form.get('telephone2')?.value === "") &&
        (this.form.get('email')?.value === null || this.form.get('email')?.value === ""))
      {
        this.form.get('type')?.value !== 'Edificio' && this.form.get('type')?.value !== 'Condominio'?
          this.toastr.warning(this.translate.instant('TOASTR.MESSAGE24'), this.translate.instant('TOASTR.WARNING')): undefined;
          //
      }
      if (this.form.get('type')?.value === 'Amministratore' && this.form.get('type')?.value !== null) {
        this.toastr.warning(this.translate.instant('TOASTR.MESSAGE26'), this.translate.instant('TOASTR.MEMO'));
      }
  }

  getMainRole() {
    if (this.loggedUser !== undefined) {
      let role: any;
      let profiles = [ {name: 'BackOffice', value: 1}, {name: 'Tecnico Commerciale', value: 2}, {name: 'Responsabile Area', value: 3},{name: 'Area Manager', value: 3},{name: 'Admin', value: 3}];
      this.loggedUser[0]?.UsersProfilesRel?.forEach((element: any) => {
        if (!role) {
          role = profiles.filter(function (e) { return e.name === element.name })[0];
        } else {
          profiles.filter(e => e.name === element.name)[0]?.value > role?.value?
            role = profiles.filter(e => e.name === element.name)[0]: undefined;
        }
      });
      this.loggedUser[0].role = role.name;
    }
  }

  emitEventToFTSearch() {
    console.log("EVENTO INNESTATO");
    this.eventsSubject.next();
  }
}
