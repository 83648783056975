<div class="container-fluid">
    <div class="search-wrapper mat-elevation-z2">
        <div class="search" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
            <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
                placeholder="{{ 'LABEL.SEARCH' | translate }}" />
        </div>
    </div>
    <div style="overflow-x: auto;">
        <table mat-table [dataSource]="dataSource" matSort style="width:100%" multiTemplateDataRows>

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">Id</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="codeStatusFrom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">
                    {{'LABEL.codeStatusFrom'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.codeStatusFrom}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="labelStatusFrom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">
                    {{'LABEL.labelStatusFrom'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.labelStatusFrom}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="codeStatusTo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">
                    {{'LABEL.codeStatusTo'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.codeStatusTo}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="labelStatusTo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">
                    {{'LABEL.labelStatusTo'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.labelStatusTo}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="viewDocumentsAction">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" (click)="clickPP(element)"><i
                    *ngIf="element['StatusChangesDocTypesRel'] == null || element['StatusChangesDocTypesRel'].length == 0"
                    class="my-bounce material-icons ic">feed</i>
                  <i *ngIf="!(element['StatusChangesDocTypesRel']== null) && element['StatusChangesDocTypesRel'].length > 0"
                    class="my-bounce material-icons icsave">feed</i>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div class="ee-element-detail" [@detailExpand]="element ===
                expandedElement ? 'expanded' : 'collapsed'">
        
                    <table mat-table [dataSource]="element['StatusChangesDocTypesRel']" style="width:100%">
        
                      <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef>{{'LABEL.code' | translate}}</th>
                        <td mat-cell *matCellDef="let element2" style="color:#008DE1"> {{element2.code}}
                        </td>
                      </ng-container>
        
                      <ng-container matColumnDef="mustSigned">
                        <th mat-header-cell *matHeaderCellDef>{{'LABEL.mustSigned' | translate}}</th>
                        <td mat-cell *matCellDef="let element2" style="color:#008DE1"> {{element2.mustSigned}}
                        </td>
                      </ng-container>
        
                      <tr mat-header-row *matHeaderRowDef="displayedColumnsSlave;"></tr>
                      <tr mat-row *matRowDef="let row; let element2; columns: displayedColumnsSlave;">
                      </tr>
                    </table>
        
                  </div>
                </td>
              </ng-container>

              
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="ee-element-row"
                [class.ee-expanded-row]="expandedElement === element">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="ee-detail-row"></tr>
        </table>
    </div>
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
    </mat-paginator>
</div>