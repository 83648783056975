<div class="container-fluid my-container">
  <h1>Action Profile Settings</h1>
  <button (click)="goToPage({id:0})" id="kt_sign_in_submit" class="btn btn-lg btn-primary">
    <span class="indicator-label text-dark">Aggiungi Action</span>
  </button>
  <div appearance="fill" class="form-subject">
    <mat-grid-list cols="2" rowHeight="5:1">

      <mat-grid-tile>
        <mat-select (selectionChange)="filterByWorkflow($event)" placeholder="Workflows">
          <mat-option *ngFor="let workflow of workflows" [value]="workflow.id">
            {{ workflow.label }}
          </mat-option>
        </mat-select>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-select (selectionChange)="filter($event)" placeholder="Action Categories">
          <mat-option *ngFor="let actionCategory of actionCategories" [value]="actionCategory.id">
            {{ actionCategory.label }}
          </mat-option>
        </mat-select>
      </mat-grid-tile>

    </mat-grid-list>
  </div>

  <br>



  <div style="overflow-x: auto;">


    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-responsive">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{element?.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="states">
        <th mat-header-cell *matHeaderCellDef> States </th>
        <td mat-cell *matCellDef="let element"> {{getStates(element).join(', ')}} </td>
      </ng-container>

      <!-- Profiles Column -->
      <ng-container [matColumnDef]="getProfileKey(profile)" *ngFor="let profile of currentProfiles;">
        <th mat-header-cell *matHeaderCellDef class="myHeader"> {{profile.name}} </th>
        <td mat-cell *matCellDef="let element" class="myCell">
          <mat-checkbox class="example-margin" [checked]="profileIsChecked(element, profile)"
            (change)="updateProfile($event.checked, element.id, profile.id)"></mat-checkbox>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedTabledColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedTabledColumns;"></tr>
    </table>

    <mat-paginator [length]="dataSource.length" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>

  </div>

</div>