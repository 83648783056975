<mat-form-field fxFill >
  <mat-label>{{label}}</mat-label>
  <mat-select [(ngModel)]="currentValue" (selectionChange)="selectionChanged($event)" #singleSelect [disabled]="noUsers" [multiple]="multiple">
    <mat-option>
      <ngx-mat-select-search
        *ngIf="true"
        [formControl]="filterControl"
        [preventHomeEndKeyPropagation]="true"
        [placeholderLabel]="'LABEL.SEARCH' | translate">
        <span ngxMatSelectNoEntriesFound>
          {{noMatchLabel}}
        </span>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option> - </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      <div *ngIf="viewField === 'uiLabel' || viewField === 'descx'">
        {{ 'LABEL.' + item[viewField] | translate }}
      </div>
      <div *ngIf="viewField !== '' && viewField !== 'uiLabel' && viewField !== 'descx' && (viewField !== 'code')">
        {{item[viewField]}}
      </div>
      <div *ngIf="viewField === ''">
        {{item}}
      </div>
      <div *ngIf="(viewField === 'code')">
        <span >
          {{ item[viewField] }}
        </span>

      </div>
      <div *ngIf="(viewField === 'codePratica')">
        <span >
          {{ item['code'] + " - " }} <b> {{ 'LABEL.ORDER' | translate }}</b>
        </span>

      </div>
      <div *ngIf="viewField === 'stateCode'">
        {{ 'STATECATEGORIES.' + item['code'] | translate }}
      </div>

      <div *ngIf="viewField === 'translationGroupCode'">
        {{ item['label']  }}
      </div>

      <div *ngIf="viewField === 'branchCode'">
        {{ 'ACTIVITIES.' + item['code'] | translate }} ({{item['codCountry']}})
      </div>

      <div *ngIf="viewField === 'company'">
        {{  item['code']  }}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
