<form [formGroup]="form" class="form-container" (ngSubmit)="update()">

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'ACTIONS.id' | translate }}</mat-label>
        <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'ACTIONS.name' | translate }}</mat-label>
        <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'ACTIONS.code' | translate }}</mat-label>
        <input matInput formControlName="keyFrontEnd">
    </mat-form-field>
</form>





<div class="buttons-right  my-2">

    <button style="background-color:transparent;" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()" *ngIf="hasSave">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>

</div>