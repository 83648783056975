import { C } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Order } from 'src/app/models/order';
import { ComplaintService } from 'src/app/services/complaint.service';
import { LibService } from 'src/app/services/libService';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss']
})
export class OrderDialogComponent implements OnInit {

  formOrders: UntypedFormGroup;
  complaint: any;

  constructor(
    public dialogRef: MatDialogRef<OrderDialogComponent>,
    public fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private complaintService: ComplaintService,
    private orderService: OrderService,
    private libService: LibService)
    {
      this.formOrders = this.fb.group({
        code: null,
        city: null,
        AreaManager: null,
        VALORE_DELIBERATO: null,
        VALORE_PRODOTTO: null,
        FATTURATO: null,
        INCASSATO:null,
        DATA_DELIBERA: null,
        primoIns: null,//(?)
        ultimoIns: null,
        QuantitaOre: null,
        ValoreOre: null,
        Valore: null,
        address: null,
        // idBuilding: null,
        // idCondominius: null,
        // idCoordinatore: null,
        // idRespCommerciale: null,
        // idRifRequest: null,
        // idSede: null,
        // idStatus: null,
        // idSubject: null,
        // isAssemblea: null,
        // isDeliberato: null,
        // isLast: null,
        // isMultiservice: null,
        // isSalCento: null,
        // isSedeInterna: null,
        // isTCAssemblea: null,
        // km: null,
        nameAdminCondominius: null,
        nameBuilding: null,
        nameCondominius: null,
        nameSubject: null,
        note: null,
        GG_MEDI_RIT: null,
        TOT_SCADUTO_LORDO: null,
        TOTALE_FATTURA: null,
        NUMERO_FATTURA: null
        // offerType: null,
        // otherInfo: null,
        // percOneriSicurezza: null,
        // probChiusuraPratica: null,
        // province: null,
        // realDescont: null,
        // realPrice: null,
        // // startx: null,
        // // starty: null,
        // // state: null,
        // streetName: null,
        // streetNumber: null,
        // // ts: null,
        // version: null,
        // who: null,
        // zip: null
      });
    }

  ngOnInit(): void {
    this.complaint = this.data.complaint;
    console.log("CODE INSIDE DIALOG COMPONENT --> ", this.complaint);
    if (this.complaint !== null && this.complaint !== undefined) {
      this.getAndPatchOrder()
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getAndPatchOrder() {
    // PATCH ORDER DA INTEGRARE CON LA CHIAMATA ALLA VIEW
    let orderDetail$ = this.complaintService.getOrderDetail(this.complaint);
    let order$ = this.orderService.getOrderByCodeVersion(this.complaint.orderNumberEA, this.complaint.orderVersion);
    this.libService.lockPage('');
    forkJoin([orderDetail$, order$]).subscribe(([ord, order]: any) => {
      console.log("[chiamata views] --> ", ord);
      console.log("[order] --> ", order)
      let oreReclami: any;

      // Patch della view Commesse - COMMESSE
      ord.commessa.length > 0?
        this.formOrders.patchValue({
          code: this.complaint.orderNumberEA,
          DATA_DELIBERA: ord.commessa[0].DATA_DELIBERA,
          VALORE_DELIBERATO: ord.commessa[0].VALORE_DELIBERATO,
          VALORE_PRODOTTO: ord.commessa[0].VALORE_PRODOTTO,
          FATTURATO: ord.commessa[0].FATTURATO,
          INCASSATO: ord.commessa[0].INCASSATO
        }):
        console.log();

      //Calcolo giorni di ritardo medi + somma delle fatture NC che hanno passato la data di scadenza - FATTURAZIONE
      let totScaduto: any, ggMediRit: number = 0;
      let i: number = 0;
      ord.fatturazione.forEach((fatturazione: any) => {
        if (new Date(fatturazione.DATA_SCADENZA) < new Date()) {
          const diffDays = new Date().getTime() - new Date(fatturazione.DATA_SCADENZA).getTime() / (1000 * 60 * 60 * 24);
          ggMediRit = ggMediRit + diffDays;
          totScaduto = totScaduto + fatturazione.TOT_SCADUTO_LORDO
        }
        i++;
      });
      ggMediRit = ggMediRit > 0? ggMediRit / i: 0;

      // Patch della view Fatturazione_Fatture
      ord.fatturazione.length > 0?
        this.formOrders.patchValue({
          code: this.complaint.orderNumberEA,
          GG_MEDI_RIT: ggMediRit,
          TOT_SCADUTO_LORDO: totScaduto,
          TOTALE_FATTURA: ord.fatturazione[0].TOTALE_FATTURA, // la somma dei valori del totale delle fatture NC
          NUMERO_FATTURA: ord.fatturazione[0].NUMERO_FATTURA //in realta questo campo è il count delle NC
        }):
        console.log();

      // Patch dei valori dell'ordine (tabella: Orders) - ORDER
      let patcher = order.data[0];
      let date = new Date(patcher.dateDelibera).toLocaleDateString();

      this.formOrders.patchValue({
        code: this.complaint.orderNumberEA,
        address:
                (patcher.streetName !== null && patcher.streetName !== undefined?
                  patcher.streetName + " ,":
                  '') +
                (patcher.streetNumber !== null && patcher.streetNumber !== undefined?
                  patcher.streetNumber + " ,":
                  ''),
        DATA_DELIBERA: date,
        VALORE_DELIBERATO: patcher.isDeliberato === true? patcher.realPrice: 0,
        city: patcher.city,
        country: patcher.country,
        nameSubject: patcher.nameSubject,
        nameCondominius: patcher.nameCondominius,
        nameBuilding: patcher.nameBuilding
      });

      // Logica per incrocio dati con QV_ORE (recupero delle fasi di garanzia) - QV_ORE + ORDERS
      let fasiGaranzia: any = [];
      oreReclami = patcher.OrdersOrderRowsRel;
      oreReclami.forEach((element: any) => {
        element.OrderRowsOrderPhasesRel.forEach((el2: any) => {
          if (el2.isGaranzia === true) //da decommentare (el2.id === 1268193)//
            fasiGaranzia.push(el2)
        });
      });
      let valOre: number = 0, qtyOre: number = 0, index: number = 0;
      let primoIns: Date, ultimoIns: Date;
      // QV_ORE filtrata per solo le righe di reclamo (qui sotto)
      if (fasiGaranzia.length > 0) {
        fasiGaranzia.forEach((element: any) => {
          ord.ore = ord.ore.filter((ore: any) => parseInt(ore.IdOrdineFase) === element.id);
        });

        // Calcolo Totale quantità Ore
        ord.ore.forEach((ora: any) => {
          primoIns === undefined?
            primoIns = new Date(ora.DataCompetenza):
            console.log();

          ultimoIns === undefined?
            ultimoIns = new Date(ora.DataCompetenza):
            console.log();

          if (new Date(ora.DataCompetenza) < primoIns) {
            primoIns = new Date(ora.DataCompetenza);
          }
          if (new Date(ora.DataCompetenza) > ultimoIns) {
            ultimoIns = new Date(ora.DataCompetenza);
          }
          valOre = valOre + ora.ValoreOre;
          qtyOre = qtyOre + ora.QuantitaOre;
          index++;
        });

        index > 0? valOre = parseFloat(valOre.toFixed(2)): console.log();

        ord.ore.length > 0?
          this.formOrders.patchValue({
            code: this.complaint.orderNumberEA,
            primoIns: primoIns! !== undefined?  primoIns.toLocaleDateString(): '',
            ultimoIns: ultimoIns! !== undefined? ultimoIns.toLocaleDateString(): '',
            QuantitaOre: qtyOre,
            ValoreOre: valOre,
            // Valore: ord.ore.Valore
          }):
          console.log();
      }

      // Recupero informazioni riguardo i costi - QV_COSTI
      let valMateriali: number = 0;
      if (ord.costi.length > 0) {
        ord.costi.forEach((costo: any) => {
          costo.tipoCosto === 'M'?
            valMateriali =  valMateriali + costo.Valore :
            console.log();
        });
      }
      this.libService.unlockPage();
    })

  }
}


