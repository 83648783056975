import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponentSubject implements OnInit {
  name: string;
  question: string;
  companies: Array<any> = []

  geoForm: FormGroup;

  constructor(public dialog: MatDialog,
    public dialogModule: MatDialogModule,
    public dialogRef: MatDialogRef<DialogComponentSubject>,
    public translate: TranslateService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.geoForm = this.fb.group({
      company: [null, Validators.required],
    });
  }

  ngOnInit(): void {

    console.log(this.data);
    // this.name = this.data.features[0].attributes.SEDE_EDAC;
    this.name = this.data.SEDE_EDAC;
    if (this.data) {

      let allCompanies = this.data || []
      this.companies = allCompanies.filter((company: any) => company.isOpen )
      if (this.companies.length == 1) {
        this.geoForm.patchValue({
          company: this.data[0]
        })
      }



      this.question = this.translate.instant('DIALOG.MESSAGE1') + '</b>?';

    } else {
    }


  }

}
