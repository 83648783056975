<div style="overflow-x: auto; width: 100%;">
  <mat-table [dataSource]="dataSource" matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection"
    matSortDisableClear style="display: table; width: 100%;">
    <!-- data columns definitions -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <mat-header-cell *matHeaderCellDef mat-sort-header [style.min-width.px]="column.minWidth || 'auto'" style="padding: 0 8px;">
        <strong>{{ column.header | translate }}</strong>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [style.min-width.px]="column.minWidth || 'auto'" style="padding: 0 8px;">
        {{ column.cell(element) }}
      </mat-cell>
    </ng-container>
    <!-- action cell -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef aria-label="row actions" style="padding: 0 8px;" style="min-width: 160px;">
        &nbsp;
      </mat-header-cell>
      <mat-cell *matCellDef="let element" style="padding: 0 8px;" style="min-width: 160px;">
        <ng-container *ngIf="actionTpl">
          <ng-container *ngTemplateOutlet="actionTpl; context: { $implicit: element }"></ng-container>
        </ng-container>
      </mat-cell>
    </ng-container>
    <!-- table header row -->
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table"></mat-header-row>
    <!-- table row -->
    <mat-row matRipple *matRowDef="let element; columns: displayedColumns"></mat-row>
  </mat-table>
  <!-- table paginator -->
  <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
  </mat-paginator>
</div>