import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { iif, map, Observable, Subject } from 'rxjs';
import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { Document } from 'src/app/models/document';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-building-documents',
  templateUrl: './building-documents.component.html',
  styleUrls: ['./building-documents.component.scss']
})
export class BuildingDocumentsComponent implements OnInit {

  @Input()form: UntypedFormGroup;
  @Input() toDisable: boolean;
  documents: Document[];

  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  public webcamImage: WebcamImage;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public deviceId: string;
  public multipleWebcamsAvailable = false;

  public errors: WebcamInitError[] = [];
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };

  constructor(
    public dialog: MatDialog,
    private documentTypeService: DocumentTypeService
  ) { }

  ngOnInit(): void {
    this.form.get('documents')?.valueChanges.subscribe(documents => {
      this.documents = documents
    })
  }

  public openUploadDialog(mode: 'new' | 'current') {

    let documentTypes$ = this.getDocumentTypes()



    if (mode === 'new') {
      this.dialog.open(FileUploadModalComponent, {
        data: {
          //file: this.webcamImage.imageAsDataUrl,
          inputMode: this.form.get('type')?.value === 'Edificio' ? 'buildings' : 'subjects',
          toEntityId: this.form.get('id')?.value,
          documentTypes: documentTypes$
        }
      })
        .afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ',res);
          //this.subject.documents.push(res)
          if(res){
            this.form.get('documents')?.setValue([...this.form.get('documents')?.value, res.document])
          }
        })
    } else if (mode === 'current') {
      this.dialog.open(FileUploadModalComponent, {
        data: {
          file: this.webcamImage.imageAsDataUrl,
          inputMode: this.form.get('type')?.value === 'Edificio' ? 'buildings' : 'subjects',
          toEntityId: this.form.get('id')?.value
        }
      })
        .afterClosed()
        .subscribe(res => {
          console.log(res);

        })
    }
  }

  getDocumentTypes(){

    return this.documentTypeService.getAllGeneric()/* .pipe(

      map(documentTypes => {
        return documentTypes.push(this.form.get('status')?.value.documentTypes)
      })
    ) */
  }
}
