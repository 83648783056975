import { Component, ViewChild, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-planner-select-search',
  templateUrl: './planner-select-search.component.html',
  styleUrls: ['./planner-select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PlannerSelectSearchComponent,
      multi: true
    }
  ],
})
export class PlannerSelectSearchComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('singleSelect') matSelect: MatSelect

  @Input() items: any[]
  @Input() type: any
  @Input() team: any
  @Input() multiple: boolean


  @Input() label: string;

  @Output() getResources: EventEmitter<any> = new EventEmitter();

  currentValue: any;
  disabled: boolean;
  resources: any = [];

  filterControl: UntypedFormControl = new UntypedFormControl();

  filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.filteredItems.next(this.items?.slice());

    // listen for search field value changes
    this.filterControl.valueChanges
      .subscribe(() => {
        this.filterItems();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!(changes['items'] == null) && (changes['items'].currentValue !== changes['items'].previousValue)) {
      this.filteredItems.next(this.items.slice());
    }
  }

  selectionChanged(event: any, team: any, type: any) {
    this.onChange(event.value)
    let res = {
      values: event.value,
      team: team,
      type: type
    }
    this.getResources.emit(res);
  }


  protected filterItems() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.filterControl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredItems.next(
      this.items.filter(item => item['name'].toLowerCase().indexOf(search) > -1)
    );
  }

  //--------ControlValueAccessor things -------------------->
  onChange = (value: any) => { };

  onTouched = () => { };

  writeValue(obj: any): void {
    this.currentValue = obj;
    if (obj === null && this.matSelect !== undefined) {
      this.matSelect.value = []
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
