import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectSectionFilterComponent } from './subject-section-filter/subject-section-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../shared/shared.module';
import { SubjectSectionComponent } from './subject-section.component';
import { FullTextSearchModule } from '../fulltextsearch2/fulltextsearch.module';
import { MatRadioModule } from '@angular/material/radio';
import { SubjectSectionDetailComponent } from './subject-section-detail/subject-section-detail.component';
import { SubjectSectionTypeRadioComponent } from './subject-section-detail/subject-section-type-radio/subject-section-type-radio.component';
import { SubjectsSectionPracticesComponent } from './subject-section-detail/subjects-section-practices/subjects-section-practices.component';
import { SubjectSectionSurveyComponent } from './subject-section-detail/subject-section-survey/subject-section-survey.component';
import { SubjectSectionRelationsComponent } from './subject-section-detail/subject-section-relations/subject-section-relations.component';
import { SubjectSectionSubjectRelationsComponent } from './subject-section-detail/subject-section-subject-relations/subject-section-subject-relations.component';
import { SubjectSectionMarketingComponent } from './subject-section-detail/subject-section-marketing/subject-section-marketing.component';
import { SubjectSectionLocalizationComponent } from './subject-section-detail/subject-section-localization/subject-section-localization.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SubjectSectionDocumentsComponent } from './subject-section-detail/subject-section-documents/subject-section-documents.component';
import { SubjectSectionDataComponent } from './subject-section-detail/subject-section-data/subject-section-data.component';
import { SubjectSectionConsentsComponent } from './subject-section-detail/subject-section-consents/subject-section-consents.component';
import { ActivitiesModule } from '../activities/activities.module';
import { SelectSearchSubjectSectionComponent } from './select-search-subject-section/select-search-subject-section.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ComponentsModule } from '../components/components.module';

const MATERIAL_MODULES = [
  MatIconModule,
  MatFormFieldModule,
  MatRadioModule,
  MatTableModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatTabsModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatInputModule,
  MatSelectModule
]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    SubjectSectionFilterComponent,
    SubjectSectionComponent,
    SubjectSectionDetailComponent,
    SubjectSectionTypeRadioComponent,
    SubjectsSectionPracticesComponent,
    SubjectSectionSurveyComponent,
    SubjectSectionRelationsComponent,
    SubjectSectionSubjectRelationsComponent,
    SubjectSectionMarketingComponent,
    SubjectSectionLocalizationComponent,
    SubjectSectionDocumentsComponent,
    SubjectSectionDataComponent,
    SubjectSectionConsentsComponent,
    SelectSearchSubjectSectionComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    GooglePlaceModule,
    GoogleMapsModule,
    FullTextSearchModule,
    HttpClientModule,
    SharedModule,
    NgxMatSelectSearchModule,
    ActivitiesModule,
    FullTextSearchModule,
    ComponentsModule,
    TranslateModule.forChild(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        },
      }
    ),
    MATERIAL_MODULES
  ],
  exports: [
    SubjectSectionComponent
  ]
})
export class SubjectSectionModule { }
