import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewChild,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { TranslatorService } from '../../services/translate.service';

@Component({
  selector: 'auto-base-table',
  templateUrl: './auto-base-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoBaseTableComponent implements OnInit, OnChanges {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() columns: any[];
  @Input() actionTpl?: TemplateRef<any> | null = null;
  @Input() sortActive = 'date';
  @Input() sortDirection: SortDirection = 'desc'
  public displayedColumns: any[];

  private onDestroy = new Subject<void>(); 
  langChangeSubscription: any;

  constructor(
    public MatPaginatorIntl: MatPaginatorIntl,
    public translate: TranslatorService,
  ){}


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  setTranslationsItemsPerPageLabel() {
    this.MatPaginatorIntl.itemsPerPageLabel = this.translate.instant('LABEL.ELEMENTI_PER_PAGINA');
    this.MatPaginatorIntl.nextPageLabel = this.translate.instant('LABEL.PROSSIMA_PAGINA');
    this.MatPaginatorIntl.previousPageLabel = this.translate.instant('LABEL.PAGINA_PRECEDENTE');
    this.MatPaginatorIntl.changes.next();
  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.setSort()
    }

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'columns': {
            this.setColumns(changes[propName].currentValue);
          }
        }
      }
    }
    
  }

  setSort() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name': return item.NOME_OPERATORE;
        case 'society': return item.NOME_SOCIETA;
        case 'company': return item.NOME_SEDE;
        case 'hours': return item.ORE_LAVORATE;
        case 'isPresence': return item.STATO_PRESENZA;
        case 'causal': return item.CAUSALE_ORARIA;
        case 'date': return item.DATA_CONSUNTIVO;
        case 'user': return item.EMAIL_USER_CONSUNTIVATORE;
        default: return item[property];
      }
    };
  }

  ngOnInit(): void {

    this.setTranslationsItemsPerPageLabel();

    // Ascolta i cambiamenti di lingua
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setTranslationsItemsPerPageLabel();
    });
    
    if (this.actionTpl) {
      this.setColumns(this.columns);
    } else {
      this.displayedColumns = this.columns.map((c) => c.columnDef);
    }

    this.setSort()

  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
  

  setColumns(columns: any) {
    this.displayedColumns = [
      ...columns.map((c: any) => c.columnDef),
      'action',
    ];
  }

}
