import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EstimateDeliveryMethodService } from '../../services/estimate-delivery-method.service';
import { ReasonService } from '../../services/reason.service';
import { LibService } from '../../services/libService';
import { StateService } from '../../services/state.service';
import { TranslatorService } from '../../services/translate.service';

@Component({
  selector: 'app-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss']
})
export class ModalFormComponent implements OnInit {

  editMode: boolean = false
  formIsActive: boolean = false
  titleLabel: string = ''
  textLabel: string = ''
  confirmButtonLabel: any = 'LABEL.SAVE'
  cancelButtonLabel: any = 'LABEL.CANCEL'

  form: FormGroup

  type: any
  item: any
  companies: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalFormComponent>,
    private estimateDeliveryMethodService: EstimateDeliveryMethodService,
    private fb: UntypedFormBuilder,
    private reasonService: ReasonService,
    private libService: LibService,
    private stateService: StateService,
    private translate: TranslatorService
  ) {
    let formIsActive = false
    let formStructure: any = {}

    if (data) {
      console.log(data)
      if (data.type) this.type = data.type
      if (data.item) this.item = data.item
      if (data.companies) this.companies = data.companies


      this.formIsActive = formIsActive
      this.form = this.fb.group(formStructure)
    }



  }

  ngOnInit(): void {
  }

  closeModal(){
    this.dialogRef.close(true);
  }

}
