<main class="container-fluid">
    <mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="tabChanged($event)"
        [selectedIndex]="selectedTabIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">group</mat-icon>
                <span class="ml-1">{{ 'SIDEBAR.AnomalieConsuntivazione' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">calendar_month</mat-icon>
                <span class="ml-1">{{ 'SIDEBAR.Holiday Calendar' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">assignment</mat-icon>
                <span class="ml-1">{{ 'SIDEBAR.Contratti' | translate }}</span>
            </ng-template>
            <!-- <div class="container-fluid mb-5 mt-5">
                <button (click)="goToPageForm()" id="kt_sign_in_submit" class="btn btn-lg btn-primary mb-5">
                    <span class="indicator-label text-dark">Aggiungi contratto</span>
                </button>
                <div appearance="fill" class="form-subject">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Ricerca </mat-panel-title>
                            <mat-panel-description>
                                Imposta i parametri di ricerca
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <form class="historySearchForm" [formGroup]="searchForm">
                                <div class="formGrid">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_NAZIONE' | translate
                                            }}</mat-label>
                                        <mat-select formControlName="countries" multiple>
                                            <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                                [formControl]="countrySearchControl" class="padd-input">
                                            <mat-option *ngFor="let country of filteredCountries" [value]="country.id">
                                                {{ country.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_SOCIETA' | translate
                                            }}</mat-label>
                                        <mat-select formControlName="societies" multiple>
                                            <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                                [formControl]="societySearchControl" class="padd-input">
                                            <mat-option *ngFor="let society of filteredSocieties" [value]="society.id">
                                                {{ society.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_SEDE' | translate
                                            }}</mat-label>
                                        <mat-select formControlName="companies" multiple>
                                            <input class="padd-input" matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                                [formControl]="companySearchControl" class="padd-input">
                                            <mat-option *ngFor="let company of filteredCompanies" [value]="company.id">
                                                {{ company.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Profili</mat-label>
                                        <mat-select formControlName="profiles" multiple>
                                            <input class="padd-input" matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                                [formControl]="profilesSearchControl" class="padd-input">
                                            <mat-option *ngFor="let profile of filteredProfiles" [value]="profile.id">
                                                {{ profile.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Branches</mat-label>
                                        <mat-select formControlName="branches" multiple>
                                            <input class="padd-input" matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                                [formControl]="branchesSearchControl" class="padd-input">
                                            <mat-option *ngFor="let branche of filteredBranches" [value]="branche.id">
                                                {{ branche.name }} ({{ branche.codeLang }})
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Causale d'assenza</mat-label>
                                        <mat-select formControlName="workCausals" multiple>
                                            <input class="padd-input" matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                                                [formControl]="causalSearchControl" class="padd-input">
                                            <mat-option *ngFor="let workCausal of filteredCausals"
                                                [value]="workCausal.id">
                                                {{ workCausal.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button mat-button type="button" (click)="reset()" class="resetButton"
                                        aria-label="warning">
                                        Reset
                                    </button>
                                    <button mat-button type="submit" (click)="onSubmit()" color="primary" aria-label="find"
                                        [disabled]="!searchForm.valid">
                                        Cerca
                                    </button>
                                </div>
                            </form>

                        </ng-template>
                    </mat-expansion-panel>
                </div>

                <br>


                <div style="overflow-x: auto;">
                    <app-data-table-with-pagination [data]="dataSource" [columns]="columns"
                        [displayedColumns]="displayedColumns" [actions]="actions" [totalRows]="totalRows"
                        [pageInit]="pageInit" [currentPage]="currentPage" (changePagination)="pageChanged($event)"
                        (changeOrder)="updateDataBySortParams($event)" sortActive=""></app-data-table-with-pagination>

                </div>

            </div> -->


            <ng-template #itemDialog>
                <app-action-form></app-action-form>
            </ng-template>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">event_busy</mat-icon>
                <span class="ml-1">{{ 'SIDEBAR.ModuloAssenze' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
            </div>
        </mat-tab>
    </mat-tab-group>
</main>