import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-for-non-programmable-orders',
  templateUrl: './modal-for-non-programmable-orders.component.html',
  styleUrls: ['./modal-for-non-programmable-orders.component.scss']
})
export class ModalForNonProgrammableOrders implements OnInit {

  editMode: boolean = false
  form: UntypedFormGroup

  reasons: any = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
    )
  {
    this.form = this.fb.group({
      OrderId: [null, [Validators.required]],
      ReasonId: [null, [Validators.required]],
      ReasonNotes: [null, [Validators.required]]
    })

    if(data){
      this.form.get('OrderId')?.setValue(`${data.OrderId}`)
    }


  }

  ngOnInit(): void {
  }

}
