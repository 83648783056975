import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Company } from '../models/company';
import { UserSessionService } from './user-session.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  companies: any;
  companiesForRequest: any;
  baseApi = environment.rootApiFoundation + 'companies';

  constructor(
    public libService: LibService,
    public userSessionService: UserSessionService,
    public authService: AuthService,
  ) { }

  getAllCompanies(): Observable<Company[] | any> {
    if (this.companies !== null && this.companies !== undefined) {
      return of(this.companies);
    } else {
      return this.libService.getData<any>(this.baseApi, '').pipe(
        tap((companies: any) => {
          this.companies = companies
        }
      ));
    }
  }

  getAllCompaniesFast(): Observable<Company[]>{
    return this.libService.getData(this.baseApi, '/forRequestv2')
  }

  getAllCompaniesForRequest(): Observable<Company[] | any> {
    if (this.companiesForRequest !== null && this.companiesForRequest !== undefined) {
      return of(this.companiesForRequest);
    } else {
      return this.libService.getData<any>(this.baseApi, '/forRequestv2').pipe(
        tap((companies: any) => {
          this.companiesForRequest = companies
        }
      ));
    }
  }

  getAllOpenCompanies(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, '/open');
  }

  getCompany(id: string) {
    return this.libService.getData<any>(this.baseApi + '/', id.toString());
  }

  getCompanyByCode(code: string) {
    let body = { code: code };
    return this.libService.postData<any>(body, this.baseApi , '/findByCode');
  }

  getCompanyOperational(id: string) {
    return this.libService.getData<any>(this.baseApi + '/operational/', id.toString());
  }

  getOperational(id: string) {
    return this.libService.getData<any>(this.baseApi + '/operational/', id.toString());
  }

  setSociety(company:any)
  {
    return this.libService.postData<any>(company, this.baseApi + '/', 'setSociety')
  }

  setLicences(company:any)
  {
    return this.libService.postData<any>(company, this.baseApi + '/', 'setLicences')
  }

  updateCompany(company: any) {
    return this.libService.putData<any>({company: company}, this.baseApi, `/${company.id.toString()}`);
  }

  updateCompanyLicences(company: any) {
    return this.libService.postData<any>(company, this.baseApi, `/setLicences`)
  }

  createCompany(company: string) {
    return this.libService.postData<any>({company: company}, this.baseApi,'');
  }

  indexWithBusinessLines(){
    return this.libService.getData<any>(this.baseApi + '/', 'indexWithBusinessLines');
  }

  points(attributes: any = {}) {
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<any>(tokenId, attributes, this.baseApi, `/points`)
  }
  getSocietiesAndCountries(){
    return this.libService.getData<any>(this.baseApi + '/', 'getSocietiesAndCountries');
  }

}
