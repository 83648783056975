import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DocumentType } from '../models/document';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  baseApi = environment.rootApiCrm + 'v1.0/'

  constructor(private libService: LibService) { }

  getAll(){
    return this.libService.getData<DocumentType[]>(this.baseApi , 'documentTypes')
  }

  getAllGeneric(){
    return this.libService.getData<DocumentType[]>(this.baseApi , 'documentTypesGeneric')
  }

  getByIds(ids: any){
    return this.libService.postData<DocumentType[]>({ids: ids}, this.baseApi , 'getByIds')
  }

  getByCodes(codes: any){
    return this.libService.postData<DocumentType[]>({codes: codes}, this.baseApi , 'getByCodes')
  }

}
