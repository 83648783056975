<div class="mrg-com-cont">
  <mat-expansion-panel [matBadge]="getNumberDocuments()">
    <mat-expansion-panel-header>
      <b>{{'DOCUMENTS.All' | translate}}</b>
    </mat-expansion-panel-header>
    <app-documents-grid [documents]="documents"></app-documents-grid>
  </mat-expansion-panel>
</div>
<div class="mrg-com-cont">
  <mat-expansion-panel (opened)="legacyOpened()" [matBadge]="getNumberLegacyDocuments()">
    <mat-expansion-panel-header>
      <b>{{'DOCUMENTS.Imported' | translate}}</b>
    </mat-expansion-panel-header>
    <app-documents-grid [documents]="legacyDocuments"></app-documents-grid>
  </mat-expansion-panel>
</div>