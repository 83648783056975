import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, map, merge } from 'rxjs';
import { GeocoderResponse } from 'src/app/models/geocoder';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { FinanceService } from 'src/app/services/finance.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { SubjectService } from 'src/app/services/subject.service';
import { BuildingService } from 'src/app/services/building.service';
import { CondominiumService } from 'src/app/services/condominium.service';
import { MapService } from 'src/app/services/map.service';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() edit: boolean;
  @Input() businessLineCode: any;
  @Input() multiCompany: any = false;
  @Input() mainForm: any;
  @Input() noButtonSearchCompany: any = false;
  @Output() searchRes = new EventEmitter;

  address: string;
  geocoderWorking: boolean;
  formattedAddress: string;

  structure: any = [
    'streetName',
    'streetNumber',
    'zip',
    'city',
    'province',
    'state',
    'country'
  ]

  get isWorking(): boolean {
    return this.geocoderWorking;
  }

  constructor(
    private geocodingService: GeocodingService,
    private toastr: ToastrService,
    private http: HttpClient,
    private financeService: FinanceService,
    private userSessionService: UserSessionService,
    private translate: TranslatorService,
    private subjectService: SubjectService,
    private buildingService: BuildingService,
    private condominiumService: CondominiumService,
    private mapService: MapService,
  ) { }

  ngOnInit(): void {
    this.initAddressSubscriber()
  }

  handleOnAddressChange(event: any) {
    console.log(event);
    this.address = event.formatted_address
  }

  findAddress() {
    if (!this.address || this.address.length === 0) {
      return;
    }

    let currentLanguage = this.userSessionService.getCurrentLanguage()

    this.geocoderWorking = true;
    this.geocodingService
      .getLocation(this.address, currentLanguage)
      .subscribe(
        (response: GeocoderResponse) => {
          if (response.status === 'OK' && response.results?.length) {
            console.log('response for geocoding %o', response);
            let structure = this.mapService.getStructure(response.results[0])
            console.log(structure)
            this.mapService.setStructure(this.form, structure)
            const location = response.results[0];
            this.address = location.formatted_address;
            this.formattedAddress = location.formatted_address;

            //OGNI VOLTA CHE CERCO UN INDIRIZZO AGGIORNO IL FORM DEI DETTAGLI INDIRIZZO
            this.form.patchValue({
              streetName: this.form.get('streetName')!.value,
              streetNumber: this.form.get('streetNumber')!.value,
              city: this.form.get('city')!.value,
              province: this.form.get('province')?.value,
              zip: this.form.get('zip')!.value,
              country: this.form.get('country')!.value,
            });
            this.checkduplicate()
          } else {
            this.toastr.error(response.error_message, response.status);
          }
        },
        (err: HttpErrorResponse) => {
          console.error('geocoder error', err);
        }
      )
      .add(() => {
        this.geocoderWorking = false;
      });
  }

  placeChangedCallback(place: any) {
    //console.log('place %o',place);
    place.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        console.log('place %o', add);
        switch (addType) {
          case 'street_number':
            this.form.get('streetNumber')?.setValue(add.short_name);
            break;
          case 'route':
            this.form.get('streetName')?.setValue(add.long_name);
            break;
          case 'locality': case 'administrative_area_level_3':
            this.form.get('city')?.setValue(add.long_name);
            break;
          case 'administrative_area_level_1':
            this.form.get('state')?.setValue(add.long_name);
            break;
          case 'administrative_area_level_2':
            this.form.get('province')?.setValue(add.short_name);
            break;
          case 'country':
            this.form.get('country')?.setValue(add.long_name);
            break;
          case 'postal_code':
            this.form.get('zip')?.setValue(add.long_name);
            break;

          default:
            break;
        }
      });
    });
  }

  cercaZonaJaka() {

    if (this.multiCompany) {
      let currentUser = this.userSessionService.getState('user');
      let lat = this.form.get('coordY')!.value;
      let lon = this.form.get('coordX')!.value;
      this.financeService.getGeoArea(currentUser.email, lat, lon, this.businessLineCode).subscribe((response: any) => {
        console.log('getGeoArea')
        // console.log(response)
        if (response.esito == 'OK') {
          let geo: any = response.item.Table[0]

          if (geo) {
            console.log(geo)
            //this.mainForm?.get('companyId')?.setValue(geo.id)
            //this.mainForm?.get('companyCode')?.setValue(geo.code)
            //this.mainForm?.get('societyId')?.setValue(geo.SocietiesCompaniesRelId)
            this.form?.get('company')?.setValue(geo.code)
            this.searchRes.emit(true)
          } else {
            this.searchRes.emit(false)
            this.toastr.error(this.translate.instant('TOASTR.MESSAGEGEOAREANOTFOUND'), this.translate.instant('TOASTR.WARNING'));
          }
        } else {
          this.searchRes.emit(false)
          console.log("CATCH ERROR PATCH VALUE REQUEST");

        }
      })
    } else {
      console.log("CATCH ERROR PATCH VALUE REQUEST");
    }

  }

  private chiamaHttp(link: any): any {
    let res = this.http.get(link);
    return res;

  }

  initAddressSubscriber() {
    let streetName$ = this.form.get('streetName')!.valueChanges.pipe(
      map(value => {
        return {
          streetName: value,
          streetNumber: this.form.get('streetNumber')?.value,
          zip: this.form.get('zip')?.value,
          city: this.form.get('city')?.value,
          province: this.form.get('province')?.value,
          state: this.form.get('state')?.value,
          country: this.form.get('country')?.value
        }
      })
    )
    let streetNumber$ = this.form.get('streetNumber')!.valueChanges.pipe(
      map(value => {
        return {
          streetName: this.form.get('streetName')?.value,
          streetNumber: value,
          zip: this.form.get('zip')?.value,
          city: this.form.get('city')?.value,
          province: this.form.get('province')?.value,
          state: this.form.get('state')?.value,
          country: this.form.get('country')?.value
        }
      })
    )
    let zip$ = this.form.get('zip')!.valueChanges.pipe(
      map(value => {
        return {
          streetName: this.form.get('streetName')?.value,
          streetNumber: this.form.get('streetNumber')?.value,
          zip: value,
          city: this.form.get('city')?.value,
          province: this.form.get('province')?.value,
          state: this.form.get('state')?.value,
          country: this.form.get('country')?.value
        }
      })
    )
    let city$ = this.form.get('city')!.valueChanges.pipe(
      map(value => {
        return {
          streetName: this.form.get('streetName')?.value,
          streetNumber: this.form.get('streetNumber')?.value,
          zip: this.form.get('zip')?.value,
          city: value,
          province: this.form.get('province')?.value,
          state: this.form.get('state')?.value,
          country: this.form.get('country')?.value
        }
      })
    )
    let province$ = this.form.get('province')!.valueChanges.pipe(
      map(value => {
        return {
          streetName: this.form.get('streetName')?.value,
          streetNumber: this.form.get('streetNumber')?.value,
          zip: this.form.get('zip')?.value,
          city: this.form.get('city')?.value,
          province: value,
          state: this.form.get('state')?.value,
          country: this.form.get('country')?.value
        }
      })
    )
    let state$ = this.form.get('state')!.valueChanges.pipe(
      map(value => {
        return {
          streetName: this.form.get('streetName')?.value,
          streetNumber: this.form.get('streetNumber')?.value,
          zip: this.form.get('zip')?.value,
          city: this.form.get('city')?.value,
          province: this.form.get('province')?.value,
          state: value,
          country: this.form.get('country')?.value
        }
      })
    )
    let country$ = this.form.get('country')!.valueChanges.pipe(
      map((value: string) => {
        return {
          streetName: this.form.get('streetName')?.value,
          streetNumber: this.form.get('streetNumber')?.value,
          zip: this.form.get('zip')?.value,
          city: this.form.get('city')?.value,
          province: this.form.get('province')?.value,
          state: this.form.get('state')?.value,
          country: value
        }
      })
    )

    merge(streetName$, streetNumber$, zip$, city$, province$, state$, country$).subscribe((value: any) => {
      let address = (value.streetName ? value.streetName + ' ' : '') + (value.streetNumber ? value.streetNumber + ', ' : '') + (value.zip ? value.zip + ' ' : '') + (value.city ? value.city + ' ' : '') + (value.province ? value.province + ' ' : '') + (value.state ? value.state + ' ' : '') + (value.country ? value.country : '');
      this.address = address
      this.formattedAddress = address
    })

    this.form.get('streetName')?.updateValueAndValidity()
  }

  checkduplicate() {
    let item = this.form?.value
    let call$ = this.buildingService.findByAddress(item);
    let id = this.form?.get('id')?.value
    console.log(id)

    console.log("ITEM: CONTROLLARE IL TIPO --> ", item)

    if (id === null && item.type == 'Edificio') {

      call$.subscribe((response: any) => {
        let message = ''
        let duplicationEsit = response != null
        this.mainForm?.get('buildingDuplicated')?.setValue(response)
        if (duplicationEsit) {

          // Settaggio messaggio d'errore
          message = this.translate.instant('TOASTR.MESSAGE19') + response.name;

          this.toastr.error(message, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
        }
      })
    }

  }

}
