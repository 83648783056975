import { Component, OnInit } from '@angular/core';
import { TargetsService } from 'src/app/services/targets.service';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss']
})
export class TargetsComponent implements OnInit {

  title = 'Obiettivi';
  subTitle = 'Obiettivi per Sedi e Profili'

  constructor(public navService: NavService) { }

  ngOnInit(): void {

    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
     // { name: 'new', url: 'order/0' },
      { name: 'export', url: ''},
     // { name: 'upload', url: '' }
    ];
    this.navService.setActions(actions);
  }

}
