import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs';
import { NavService } from '../services/nav.service';
import { WikiService } from '../services/wikiservice.service';
import { ActionService } from '../services/action.service';
import { Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "CRM";
  subTitle = "EDAC - CRM";
  actions: any;
  selectedTabIndex: number = 0
  sectionType: any = 'subjects'
  private subRoute: Subscription;


  tabIndex$ = this.route.paramMap.pipe(
    map(params => {
      return params.get('tabName')
    }),
    tap(tabName => {
      switch (tabName) {
        case 'subjects':
          this.selectedTabIndex = 0
          break;
        case 'requests':
          this.selectedTabIndex = 1
          break;
        case 'activities':
          this.selectedTabIndex = 2
          break;
        case 'claims':
          this.selectedTabIndex = 3
          break;
        default:
          this.selectedTabIndex = 0
          break;
      }
    })
  )
  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    public wikiService: WikiService,
    private cdr: ChangeDetectorRef,
    private actionService: ActionService
    ) {}

  goToWiki() {
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }

  checkPermissions(itemType: any, actionType: any){
    return this.actionService.checkPermissions(`${itemType}_${actionType}`)
  }

goToWikiByKey(k:any){
    window.open(this.wikiService.getWikiUrl(k));
  }

  ngOnInit(): void {
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);

    this.actions = [
      {name:'export',url:''},
      { name: 'new', url: 'subject/0', params: {type: 'Soggetto'} }
    ];
    console.log("ACTIONS --> ", this.actions)
    this.navService.setActions(this.actions);
    /* console.log("INIT INDEX --> ", this.tabGroup.selectedIndex); */
    this.navService.setWikiKey('CRM');

    this.subRoute = this.route
      .data
    .subscribe((v:any) => {
      if('sectionType' in v) this.sectionType = v.sectionType
      });

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.tabIndex$.subscribe()
    this.navService.setActions(this.actions);
    this.cdr.detectChanges();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    /* console.log('tabChangeEvent --> ', tabChangeEvent); */
    /* console.log('index --> ', tabChangeEvent.index); */
    this.selectedTabIndex = tabChangeEvent.index
    if (tabChangeEvent.index !== (null || undefined)) {
      if (tabChangeEvent.index === 0) {
        this.actions = [
          {name:'export',url:''},
          { name: 'new', url: 'subject/0', params: {type: 'Soggetto'} }
        ];
      }
      if (tabChangeEvent.index === 1) {
        this.actions = [
          {name:'export',url:''},
          {name:'new',url:'request/0'}
        ];
      }
      if (tabChangeEvent.index === 2) {
        this.actions = [
          {name:'export',url:''},
          {name:'new',url:'activity/0'}
        ];
      }
      if (tabChangeEvent.index === 3) {
        this.actions = [
          {name:'export',url:''},
          {name:'new',url:'claim/0'}
        ];
      }
    }

    this.navService.setActions(this.actions);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subRoute.unsubscribe();
  }

}
