<!-- <baa-progress-spinner mode="indeterminate" [displayProgressSpinner]="loading"></baa-progress-spinner> -->
<div fxLayout="column">
  <mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z0"
    fxFlex
    matSort
    [matSortActive]="sortActive"
    [matSortDirection]="sortDirection"
    matSortDisableClear
  >
    <ng-container [matColumnDef]="col.name" *ngFor="let col of columns" [sticky]="col.sticky">
      <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="col.sortDisabled" [fxFlex]="col.flex">
        <span>{{ col.title | async }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [fxFlex]="col.flex" [ngClass]="col.cssClass ? col.cssClass(element) : '' ">
        <ng-container [ngSwitch]="col.type">
          <ng-container *ngSwitchCase="'icon'">
            <mat-icon
              [color]="col.color ? col.color(element) : null"
              [ngClass]="col.cssClass ? col.cssClass(element) : '' "

              matTooltipPosition="above"
              matTooltipShowDelay="1000"
              >{{ col.value(element) }}</mat-icon
            >
          </ng-container>
          <ng-container *ngSwitchCase="'link'">
            <a
              href="#"
              [routerLink]="col.routerLink? col.routerLink(element): null"

              matTooltipPosition="above"
              matTooltipShowDelay="1000"
              >{{ col.value(element) }}</a
            >
          </ng-container>
          <ng-container *ngSwitchCase="'templateRef'">
            <ng-container
              [ngTemplateOutlet]="col.templateRef ? (col.templateRef(element) | async) : null"
              [ngTemplateOutletContext]="{ col: col, element: element }"

              matTooltipPosition="above"
              matTooltipShowDelay="1000"
            ></ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span class="cell-overflow"
              [matTooltip]="col.value(element)"
              matTooltipPosition="above"
              matTooltipShowDelay="1000"
              >{{ col.value(element) }}</span
            >
          </ng-container>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions" *ngIf="actions">
      <mat-header-cell *matHeaderCellDef [fxFlex]="actions.length * 80 + 'px'"></mat-header-cell>
      <mat-cell *matCellDef="let element" [fxFlex]="actions.length * 80 + 'px'">
        <div *ngFor="let action of actions" >
          <button mat-button [ngClass]="action.cssClass ? action.cssClass(element) : '' " [color]="action.color" (click)="action.onClick(element)" [disabled]="action.disabled?action.disabled(element):false">
            <ng-container *ngIf="!action.iconButton; else iconTpl">
              <mat-icon *ngIf="action.icon!(element)">{{ action.icon!(element) }}</mat-icon>
              <mat-spinner *ngIf="action.spinner" [diameter]="40"></mat-spinner>
              {{ action.label | async }}
            </ng-container>
            <ng-template #iconTpl>
              <mat-icon>{{ action.icon!(element) }}</mat-icon>
            </ng-template>
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <div *ngIf="hasHeader">
      <mat-header-row *matHeaderRowDef="displayedColumns" class="back-table centered"></mat-header-row>
    </div>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selectRow(row)"
      [class.selected]="selected == row"
      baaMatRowHover
      (matRowHover)="onMatRowHover($event, row)"
    ></mat-row>
  </mat-table>
  <mat-paginator
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
    *ngIf="hasPagination"
  ></mat-paginator>
</div>
