<div class="container-fluid my-container">
  <h1>{{ 'CONVENTIONS.mainTitle' | translate }}</h1>
  <br>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">description</mat-icon>
        {{ 'CONVENTIONS.list' | translate }}
      </ng-template>
      <br>
      <button (click)="goToPage({id:0})" id="kt_sign_in_submit" class="btn btn-lg btn-primary">
        <span class="indicator-label text-dark">{{ 'CONVENTIONS.add' | translate }}</span>
      </button>
      <div appearance="fill" class="form-subject">
        <mat-grid-list cols="2" rowHeight="5:1">
    
          <!-- FILTRI -->
           
        </mat-grid-list>
      </div>
    
      <br>
    
    
      <div style="overflow-x: auto;">
    
        <app-settings-data-table [data]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns"
          [actions]="actions" sortActive=""></app-settings-data-table>
    
    
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">how_to_reg</mat-icon>
        {{ 'CONVENTIONS.approvalsConventionRequests' | translate }}
      </ng-template>
      <app-conventions-subject-approvals></app-conventions-subject-approvals>
    </mat-tab>
 
  </mat-tab-group>

</div>