import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NavService } from '../services/nav.service';
import { AuthService} from '../auth/auth.service';
import { UserSessionService} from '../services/user-session.service';
import { TranslatorService } from '../services/translate.service';
import { NotificationService } from '../services/notification.service';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {NotificationsDialogComponent} from './notifications-dialog/notifications-dialog.component';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  ss:any;
  user:any;
  sended: boolean = false;
  dpiUrl: SafeResourceUrl;
  title = "Dpi";
  subTitle = "EDAC - Dpi";
  count = 0
  constructor(
    public navService: NavService, 
    public translate: TranslatorService, 
    private authService: AuthService,
    public dialog: MatDialog,
    private userSessionService: UserSessionService,
    private notificationService: NotificationService,
    ) { }


  ngDoCheck() {

    if (this.sended == false) {
      var frame: HTMLIFrameElement = document.getElementById('DpiIframe') as HTMLIFrameElement;
      if (frame != null) {
        this.sended = true;
        frame.contentWindow?.postMessage('sstorage', this.ss);
        this.dpiUrl = environment.rootDpi + this.user.sub;
      }
    }

  }

  loadLabels(){
    this.translate.get('SIDEBAR.Dpi').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.ss = window.sessionStorage;
    this.user =  this.authService.user();
    console.log('send message to iframe %o',this.ss,this.user);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
    ];
    this.navService.setActions(actions);

    this.userSessionService.notifications(true).subscribe((response: any) => {
      this.notificationService.setNotifications(response) 
      this.count = response.length
    })
  }

  viewNotifications(){
    const dialogRef = this.dialog.open(NotificationsDialogComponent,{
      maxHeight: 400,
      data: {
        notifications: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}



