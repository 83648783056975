import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'app-hom',
  templateUrl: './hom.component.html',
  styleUrls: ['./hom.component.scss']
})
export class HomComponent implements OnInit {
  title = 'Albert (v.4.0)';
  logged_user: any = {};

  constructor(private authService: AuthService) {
  }

  
  ngOnInit(): void {
  }

  login() {
    this.authService.login();
  }

  user() {
    return this.authService.user();
  }

}
