<div class="container-fluid">

  <div style="overflow-x: auto;">
    <br>
    <br>
    <button (click)="openModal()" class="btn btn-lg btn-primary">
      <span class="indicator-label text-dark">{{ 'LABEL.ADD_SUBJECT_RELATION' | translate }}</span>
    </button>
    <br>

    <app-data-table
    [data]="dataSource"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
    [actions]="actions">
    </app-data-table>

  </div>
</div>
