import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-plugin-produzione',
  templateUrl: './plugin-produzione.component.html',
  styleUrls: ['./plugin-produzione.component.scss']
})
export class PluginProduzioneComponent implements OnInit {
  ss:any;
  user:any;
  sended: boolean = false;
  certurl: SafeResourceUrl;
  title = "Certificati";
  subTitle = "EDAC - Certificati";
  innerHeight:any;
  constructor(public navService: NavService, public translate: TranslatorService, private authService: AuthService) { }


  ngDoCheck() {

    if (this.sended == false) {
      var frame: HTMLIFrameElement = document.getElementById('PluginProduzioneIframe') as HTMLIFrameElement;
      if (frame != null) {

        this.certurl = environment.rootPluginProduction; //+ this.user.sub;
        setTimeout(() => {
          frame.contentWindow?.postMessage( JSON.stringify(this.ss), '*') //this.ss);
          console.log('send message to iframe %o', this.ss, this.user);
        }, 4000);

        this.sended = true;
      }
    }

  }

  loadLabels(){
    this.translate.get('LABEL.PLUGIN_PRODUZIONE').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.ss = window.sessionStorage;
    this.user =  this.authService.user();
    console.log('send message to iframe %o',this.ss,this.user);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
    ];
    this.navService.setActions(actions);
    this.innerHeight = (window.innerHeight - 74) + 'px';
  }

}



