import { Observable } from 'rxjs';
import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  baseApi = environment.rootApiCrm;
  constructor(public libService: LibService)
  { }

  getLocations(): Observable<any> {
    return this.libService.getData<any>(this.baseApi + 'v1.0/locations', '');
  }
}
