import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Status } from 'src/app/models/status';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-work-with-us',
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss']
})
export class WorkWithUsComponent implements OnInit {

  @Input()form: UntypedFormGroup;
  @Input()types: any;
  @Input() origins: any;

  statusTemporary: Status
  controlTypes = ['Privato', 'Amministratore'];
  hideLegalEntity: boolean = true;

  constructor(
    private statusService: StatusService,
    public subjectService: SubjectService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.statusService.getById(1, 'Subject').subscribe(res => {
      this.statusTemporary = res
      this.form.get('subject')?.get('status')?.setValue(this.statusTemporary)
      this.form.get('building')?.get('status')?.setValue(this.statusTemporary)
    })

    this.form.get('subject')?.valueChanges.subscribe(subject => {
      console.log("REQUEST SUBJECT --> ", subject);
      if (this.controlTypes.includes(subject.type)) {

        this.hideLegalEntity = true;
        this.form.get('subject')?.get('surname')?.setValidators([Validators.required]);this.form.updateValueAndValidity();
        this.cdr.detectChanges();

      } else {
        this.hideLegalEntity = false;
        // console.log("FORM --> ", this.form);
        this.form.get('subject')?.get('surname')?.clearValidators();this.form.updateValueAndValidity();
        this.cdr.detectChanges();
        // this.form.get('name')?.clearValidators();this.form.updateValueAndValidity();

      }
    })
  }

  selSubject(event: any){
    console.log('Selected: ', event);
    switch (event.type) {
      case 'Edificio':
        this.clearForm('building')
        this.form.get('building')?.patchValue(event)
        break;
      default:
        this.clearForm('subject')
        this.form.get('subject')?.patchValue(event)
        break;
    }
  }

  clearForm(formName: string){
    //set status provvisorio per subject e building
    this.form.get(formName)?.reset()
    this.form.get(formName)?.get('status')?.setValue(this.statusTemporary)
  }

  get subjectForm(){
    return this.form.controls['subject'] as UntypedFormGroup
  }
}
