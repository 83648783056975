import { Component, Inject, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, UntypedFormGroup } from '@angular/forms';
import { SubjectService } from 'src/app/services/subject.service';
import { LibService } from 'src/app/services/libService';
import { UserSessionService } from 'src/app/services/user-session.service';

@Component({
  selector: 'app-dynamic-fields',
  templateUrl: './dynamic-fields.component.html',
  styleUrls: ['./dynamic-fields.component.scss']
})
export class DynamicFieldsComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() components: any;
  @Input() componentCode: any;


  name: string;
  question: string;
  companies: Array<any> = []
  subjectTypes: Array<any> = []
  currentLanguage: any = 'IT'

  geoForm: FormGroup;

  constructor(
    public translate: TranslateService,
    public fb: FormBuilder,
    public subjectService: SubjectService,
    public libService: LibService,
    public userSessionService: UserSessionService,
  ) {

    this.geoForm = this.fb.group({
      company: [null, Validators.required],
      subjectType: [null, Validators.required],
    });

    this.getCurrentLanguage()
  }

  ngOnInit(): void { }

  getSubjectType(event: any) {
    // Ricerca Subject Type Abilitati
    this.libService.lockPage('');
    let currentCompany: any = this.geoForm?.get('company')?.value
    this.subjectTypes = [] // Reset
    this.subjectService.getTypesByCompanyId(currentCompany.id).subscribe((response: any) => {
      this.libService.unlockPage();
      this.subjectTypes = response
    })
  }

  getCurrentLanguage() {
    return this.userSessionService.getCurrentLanguage()?.toUpperCase() || 'IT';
  }

  getCurrentComponent() {
    return this.components.find((component: any) => component.componentCode == this.componentCode)
  }

  getDynamicFields() {
    let attributes = this.getCurrentComponent()?.attributes
    let dynamicFileds: any = []
    if (attributes) {
      for (const [key, value] of Object.entries(attributes)) {
        let filed: any = value
        filed.code = key
        if (attributes[key].hasOwnProperty('formType') && this.form?.get(key)) dynamicFileds.push(filed)
      }
    }
    return dynamicFileds
  }

}
