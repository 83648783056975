<div class="search-wrapper mat-elevation-z1">
  <div class="search" fxLayout="row" fxLayoutAlign="start ">
    <div *ngIf="(isLoading === true)"><mat-spinner  style="float:right;" diameter="25"></mat-spinner></div>

      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text"
      [placeholder]="translate.instant('FORM_FIELD.SEARCH')"
      [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="fulltextSearch($event)"
      [formControl]="myControl"
      [matAutocomplete]="auto"

      >

    <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">

      <div>
        <mat-option *ngFor="let option of optionList " [value]="option" [disabled]="edit">
          <span *ngIf="(service.name == 'SubjectService' || service.name == 'FTIndexAllService') && !(option.name == null) && !(option?.surname == null)">{{option?.name + ' ' + option?.surname}} <b>{{option.type}}</b></span>
          <span *ngIf="(service.name == 'SubjectService' || service.name == 'FTIndexAllService') && !(option.name == null) && (option?.surname == null)">{{option?.name }} <b>{{option.type}}</b></span>
          <span *ngIf="(service.name == 'SubjectService' || service.name == 'FTIndexAllService') && (option.name === null || option.name === undefined) && !(option?.surname == null)">{{option?.surname }} <b>{{option.type}}</b></span>

          <span *ngIf="(service.name == 'BuildingService' || service.name == 'FTIndexAllService') && !(option?.name === null) && (option?.surname == null)">
            {{option?.name +
              (option?.city !== undefined && + option?.city !== null? ", " + option?.city: '' )}} <b>{{'LABEL.'+option?.type | translate}}</b></span>
          <span *ngIf="(service.name == 'BuildingService' || service.name == 'FTIndexAllService') && (option?.name === null)">
            {{option?.streetName + ' ' +
              option?.streetNumber + ', ' +
              option?.city + ', ' +
              option?.state}} <b>{{'LABEL.'+option?.type | translate}} </b></span>

          <span *ngIf="(service.name == 'CondominiumService' || service.name == 'FTIndexAllService') && (option?.name == null) && (option?.surname == null)">
            {{option?.streetName + ' ' +
              option?.streetNumber +
              (option?.city !== undefined && + option?.city !== null? ", " + option?.city: '') }} <b>{{option?.type}}</b></span>

          <span *ngIf="(service.name == 'OrderService')">
            {{option?.code + ' - ' }}
            {{ option?.realPrice + ' - '}}
            {{ option?.nameSubject + " " }}
            {{ option?.nameBuilding }} <b>{{'LABEL.ORDER' | translate }}</b>
          </span>

          <span *ngIf="(service.name == 'RequestService')">
            <!-- {{ option?.id !== null && option?.id !== undefined? option?.id + ', ' : '' }} -->
            {{ option?.subject?.name !== null && option?.subject?.name !== undefined? option?.subject?.name + ' ' : '' }}
            {{ option?.subject?.surname !== null && option?.subject?.surname !== undefined? option?.subject?.surname + ' (' : ' (' }}
            <!-- {{ option?.assignmentOperator !== null && option?.assignmentOperator !== undefined? option?.assignmentOperator + ', (' : '' }} -->
            {{ option?.building?.streetName !== null && option?.building?.streetName !== undefined? option?.building?.streetName + ', ' : '' }}
            {{ option?.building?.streetNumber !== null && option?.building?.streetNumber !== undefined? option?.building?.streetNumber + ', ' : '' }}
            {{ option?.building?.city !== null && option?.building?.city !== undefined? option?.building?.city + ') ' : ')' }}
            <b>{{'REQUEST.REQUEST' | translate }}</b></span>

        </mat-option>
      </div>
      <div *ngIf="(noResults == true)">
        <mat-option disabled>
          <span><b>{{'LABEL.NoSearchResults' | translate}}</b></span>
        </mat-option>
      </div>
    </mat-autocomplete>

      <!-- <div> <span *ngIf="!(option.name == null) && !(option.surname == null)">{{option.name + ' ' + option.surname}}</span>
          <span *ngIf="!(option.name == null) && (option.surname == null)">{{option.name}}</span>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)" >
          <mat-option  *ngFor="let option of options " [value]="option">
            <span *ngIf="!(option.name == null) && !(option.surname == null)">{{option.name + ' ' + option.surname}}</span>
            <span *ngIf="!(option.name == null) && (option.surname == null)">{{option.name}}</span>
          </mat-option>
        </mat-autocomplete>
      </div> *ngIf="(dropdown)"-->

      <button style="background-color:transparent;" matTooltip="Clear" type="button" class="btn" (click)="clear()">
        <mat-icon class="material-icons" style="color:#293683;">clear</mat-icon><!--  btn-primary mx-2 -->
      </button>

  </div>

</div>
<p style="padding-left: 3%; padding-bottom:1.5%;">{{ (service.name === 'OrderService'? 'ALERT.MESS2': 'ALERT.MESS1')| translate }}</p>
