<div class="container-fluid my-container">
    <div class="buttons my-2">
        <div class="form-group" class="mb-3" style="width: 40%">
            <label>Nome:</label><br />
            <input type="text" [(ngModel)]="profile.name" class="form-control" *ngIf="profile">
        </div>
        <div> </div>
        <div class="buttons-right">
            <button style="background-color:transparent;" matTooltip="Salva" type="button" class="btn btn-primary mx-2"
                (click)="save()">
                <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
            </button>
        </div>
    </div>
    <ul *ngIf="treeNodes.length > 0">
        <li *ngFor="let node of treeNodes">
            <div class="d-flex">
                <fa-icon [icon]="faChevronRight" style="margin-right: 5px;" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse' + node.name" class="collapsed"></fa-icon>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" (change)="selectFullNode(node)"
                        [indeterminate]="fullNodeSelectionStatus(node) == 'partial-selected'"
                        [checked]="fullNodeSelectionStatus(node) == 'not-selected' ? false : true" />
                    <label class=" form-check-label">{{ node.name }}</label>
                </div>
            </div>
            <ul *ngIf="node.children.length > 0" [id]="'collapse' + node.name" class="collapse">
                <li *ngFor="let child of node.children">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [checked]="child.selected" [value]="child.id"
                            (change)="child.selected = !child.selected" />
                        <label class=" form-check-label">{{ child.label }}</label>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
    <div class="buttons my-2 d-flex justify-content-end">
        <div class="buttons-right">
            <button style="background-color:transparent;" matTooltip="Salva" type="button" class="btn btn-primary mx-2"
                (click)="save()">
                <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
            </button>
        </div>
    </div>
</div>