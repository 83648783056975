import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnitMeasureService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
  ) { }

  getAllUnitMeasures(): Observable<any> {
    return (this.libService.getData<any>(this.baseApi, 'unitMeasures/'));
  }
}
