<div class="container-fluid my-container">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <span>{{'JOURNEYS.title' | translate}}</span>
      <span class="example-spacer"></span>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="contentJourneys">

    <div class="journeys-search-wrapper mat-elevation-z2">
      <div class="search" fxLayout="row" fxLayoutAlign="start center">
        <form [formGroup]="dateForm" (ngSubmit)="submitSearch()" fxLayout="column">

          <mat-grid-list cols="4" rowHeight="2:1">
            <mat-grid-tile>

              <mat-form-field appearance="fill">
                <mat-label>{{'JOURNEYS.company' | translate}}</mat-label>
                <mat-select formControlName="companies">
                  <mat-option *ngFor="let company of companies" [value]="company">
                    {{company.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </mat-grid-tile>

            <mat-grid-tile>

              <mat-form-field appearance="fill">
                <mat-label>{{'JOURNEYS.fromDate' | translate}}</mat-label>
                <input formControlName="from" matInput [matDatepicker]="fromDate">
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate></mat-datepicker>
              </mat-form-field>

            </mat-grid-tile>

            <mat-grid-tile>

              <mat-form-field appearance="fill">
                <mat-label>{{'JOURNEYS.toDate' | translate}}</mat-label>
                <input formControlName="to" matInput [matDatepicker]="toDate">
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                <mat-datepicker #toDate></mat-datepicker>
              </mat-form-field>

            </mat-grid-tile>

            <mat-grid-tile>


              <div class="buttonBox">
                <button type="submit" mat-raised-button color="primary" [disabled]="!dateForm.valid">
                  <mat-icon>search</mat-icon> {{'JOURNEYS.search' | translate}}
                </button>

                <button mat-raised-button color="warn" (click)="resetFormAndTable()">
                  <mat-icon>close</mat-icon> {{'JOURNEYS.reset' | translate}}
                </button>
              </div>


            </mat-grid-tile>
          </mat-grid-list>

        </form>
      </div>
    </div>



    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">

        <!-- Position Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{'JOURNEYS.date' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.date.toLocaleDateString("it-IT")}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef>{{'JOURNEYS.operator' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.operator}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>{{'JOURNEYS.company' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.company}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="orderAddress">
          <th mat-header-cell *matHeaderCellDef>{{'JOURNEYS.orderAddress' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.orderAddress}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="practice">
          <th mat-header-cell *matHeaderCellDef>{{'JOURNEYS.practice' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.practice}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="hours">
          <th mat-header-cell *matHeaderCellDef>{{'JOURNEYS.hours' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.hours}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="reason">
          <th mat-header-cell *matHeaderCellDef>{{'JOURNEYS.reason' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.reason}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="km">
          <th mat-header-cell *matHeaderCellDef>{{'JOURNEYS.km' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.km}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page">
      </mat-paginator>
    </div>
  </div>

</div>