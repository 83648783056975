import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';
import { DocumentTypesService } from '../services/document-types.service';

@Component({
  selector: 'app-document-types',
  templateUrl: './document-types.component.html',
  styleUrls: ['./document-types.component.scss']
})
export class DocumentTypesComponent implements OnInit {

  displayedColumns = ['id', 'code','mustSigned'];
  dataSource: any;
  kwSearch: any;
 
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;


  constructor(private documentTypesService: DocumentTypesService, public libService: LibService, private router: Router) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.loadStatuses();
  }

  
  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  loadStatuses(): void {
    
    this.libService.lockPage('');
    this.documentTypesService.getDocTypes().subscribe((response) => {
      console.log('loadWorkFlows response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
    
  }

}



