import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaxStepService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
  ) { }

  createStep(taxstep: any): Observable<any> {
    return this.libService.postData<any>(taxstep, this.baseApi, 'fiscalsteps/');
  }

  updateStep(taxstep: any): Observable<any> {
    return this.libService.putData<any>(taxstep, this.baseApi, `fiscalsteps/${taxstep.id.toString()}`);
  }

  deleteStep(taxstepId: any): Observable<any> {
    return this.libService.deleteData<any>(taxstepId, this.baseApi, `fiscalsteps/${taxstepId.toString()}`)
  }

  getAllSteps(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, 'fiscalsteps/');
  }

  getStep(id: string) {
    return this.libService.getData<any>(this.baseApi, `fiscalsteps/${id.toString()}`);
  }
}