import { Building } from "./building";

export class Answer {
  id?: number;
  value?: string;
  question?: Question;
  survey?: Survey;
}

export class Question {
  id?: number;
  text?: string;
  entityName?: string;
  answerType?: string;
  answerName?: string;
  mandatory?: boolean;
}

export class SurveyMaster {
  id?: number;
  name?: string;
  entityName?: string;
  questions?: Question[];
}

export class Survey {
    id?: number;
    answers?: Answer[];
    surveyMasterOrigin?: SurveyMaster;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    building?: Building;
}
