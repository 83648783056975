import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-iframe-modal',
    templateUrl: './iframe-modal.component.html',
    styleUrls: ['./iframe-modal.component.scss']
})
export class IframeModal implements OnInit {

    url: SafeResourceUrl;

    innerHeight: any = '850px';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sanitizer: DomSanitizer,
    ) {

        if (data) {
            if (data.urlDocComposition && data.practiceCode) {
                let currentUrl = this.transform(`${environment.rootAlbertcruscotto}${data.urlDocComposition}${data.practiceCode}`);
                this.url = currentUrl
            }

        }

    }

    ngOnInit(): void {
    }

    transform(value: any) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }

}
