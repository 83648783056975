<div class="full-w">

  <form [formGroup]="form" (submit)="update()" class="form-container">


    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Pratica EDAC' | translate }}</mat-label>
      <input matInput disabled [value]="item.orderNumberEA"
        [placeholder]="translate.instant('COMPLAINTS.Pratica EDAC')">
    </mat-form-field>


    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Anno Del Danno' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Anno Del Danno')" formControlName="damageYear">
    </mat-form-field>


    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Via' | translate }}</mat-label>
      <input matInput disabled [value]="item.streetName" [placeholder]="translate.instant('COMPLAINTS.Via')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Numero' | translate }}</mat-label>
      <input matInput disabled [value]="item.streetNumber" [placeholder]="translate.instant('COMPLAINTS.Numero')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Citta' | translate }}</mat-label>
      <input matInput disabled [value]="item.city" [placeholder]="translate.instant('COMPLAINTS.Citta')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Provincia' | translate }}</mat-label>
      <input matInput disabled [value]="item.province" [placeholder]="translate.instant('COMPLAINTS.Provincia')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Nazione' | translate }}</mat-label>
      <input matInput disabled [value]="item.country" [placeholder]="translate.instant('COMPLAINTS.Nazione')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.CAP' | translate }}</mat-label>
      <input matInput disabled [value]="item.zip" [placeholder]="translate.instant('COMPLAINTS.CAP')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Reclamante' | translate }}</mat-label>
      <input matInput disabled [value]="fullname()" [placeholder]="translate.instant('COMPLAINTS.Reclamante')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Descrizione Rimborso' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Descrizione Rimborso')"
        formControlName="refundDescription">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Descrizione Causa Del Danno' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Descrizione Causa Del Danno')"
        formControlName="damageCauseDescription">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Tipo' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Tipo')" formControlName="type">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Descrizione Tipo' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Descrizione Tipo')"
        formControlName="typeDescription">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data Accordo' | translate }}</mat-label>
      <input matInput [matDatepicker]="pickerAgreementDate" formControlName="agreementDate" />
      <mat-datepicker-toggle matSuffix [for]="pickerAgreementDate"></mat-datepicker-toggle>
      <mat-datepicker #pickerAgreementDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Valore Del Danno Riconosciuto' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Valore Del Danno Riconosciuto')"
        formControlName="recognizedDamageValue">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data Accordo' | translate }}</mat-label>
      <input matInput [matDatepicker]="pickerExpiringPaymentDate" formControlName="expiringPaymentDate" />
      <mat-datepicker-toggle matSuffix [for]="pickerExpiringPaymentDate"></mat-datepicker-toggle>
      <mat-datepicker #pickerExpiringPaymentDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Pagato Da Edac' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Pagato Da Edac')" formControlName="paidFromEA">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Pagato Deducibile' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Pagato Deducibile')"
        formControlName="deductiblePaid">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data Accordo' | translate }}</mat-label>
      <input matInput [matDatepicker]="pickerAccountingDate" formControlName="accountingDate" />
      <mat-datepicker-toggle matSuffix [for]="pickerAccountingDate"></mat-datepicker-toggle>
      <mat-datepicker #pickerAccountingDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Note' | translate }}</mat-label>
      <textarea matInput [placeholder]="translate.instant('COMPLAINTS.Note')" formControlName="note"></textarea>
    </mat-form-field>


    <div class="buttonBox">
      <button class="submitButton" type="submit" mat-raised-button color="primary">
        <mat-icon>save</mat-icon> {{ 'LABEL.SAVE' | translate }}
      </button>
    </div>
    <br>
  </form>

</div>