<table mat-table [dataSource]="dataSource" matSort style="width:100%">

    <ng-container matColumnDef="idProfile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Id</th>
        <td class="tdg" mat-cell *matCellDef="let element">
            {{element.idProfile}} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'PAG_TABLE.NAME' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.name}}</b></span>
        </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'PAG_TABLE.CREATION_DATE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'PAG_TABLE.LASTMODIFY_DATE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
        </td>
    </ng-container>
    <ng-container matColumnDef="editAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="goTo(element, pathTarget)"><i class="material-icons ic">edit</i>
    </ng-container>
    <ng-container matColumnDef="deleteAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><i class="material-icons ic">delete</i>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions">
</mat-paginator>
