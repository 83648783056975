import {
  OrderRows,
  OrderPhases,
} from 'src/app/final-balancer/models/final-balance';
import { PhasePlan } from './phase-plan';

export class RowPlan {
  id: number;
  name: string;
  howMany: number;
  phases: PhasePlan[];

  get phaseNames(): string {
    return this.phases
      .map((phase) => phase.descPhase)
      .join(', ')
      .toLowerCase();
  }

  constructor({
    OrderRowsOrderPhasesRel,
    descMacroPhase,
    id,
    howMany,
  }: OrderRows) {
    this.id = id;
    this.name = descMacroPhase;
    this.howMany = howMany;
    this.phases = OrderRowsOrderPhasesRel.map(
      (phase: OrderPhases) => new PhasePlan(phase)
    );
  }
}
