export const localeIt: any = {

    localeName: 'It',
    localeDesc: 'Italiano (It)',
    localeCode: 'it-IT',

    Object: {
        Yes: 'Si',
        No: 'No',
        Cancel: 'Annulla',
        Ok: 'OK',
        Week: 'Settimana',
        newEvent: 'Nuovo Evento',
        Day: 'Giorno',
        Month: 'Mese',
        Year: 'Anno',
        Agenda: 'Agenda'
    },

    TimePicker: {
        hour: 'Ora',
        minute: 'Minuto',
        second: 'Secondo'
    },

    List: {
        loading: 'Caricamento...',
        selectAll: 'Seleziona tutto'
    },

    Popup: {
        close: 'Chiudi'
    },

    DateHelper: {
        locale: 'it-IT',
        weekStartDay: 1,
        nonWorkingDays: {
            0: true,
            6: true
        },
        weekends: {
            0: true,
            6: true
        },
        unitNames: [
            { single: 'millisecondo', plural: 'ms', abbrev: 'ms' },
            { single: 'secondo', plural: 'secondi', abbrev: 's' },
            { single: 'minuti', plural: 'minuti', abbrev: 'min' },
            { single: 'ora', plural: 'ore', abbrev: 'h' },
            { single: 'giorno', plural: 'giorni', abbrev: 'd' },
            { single: 'settimana', plural: 'settimane', abbrev: 'w' },
            { single: 'mese', plural: 'mesi', abbrev: 'mon' },
            { single: 'quarter', plural: 'quarters', abbrev: 'q' },
            { single: 'anno', plural: 'anni', abbrev: 'yr' },
            { single: 'decade', plural: 'decadi', abbrev: 'dec' }
        ],
        unitAbbreviations: [
            ['mil'],
            ['s', 'sec'],
            ['m', 'min'],
            ['h', 'hr'],
            ['d'],
            ['w', 'wk'],
            ['mo', 'mon', 'mnt'],
            ['q', 'quar', 'qrt'],
            ['y', 'yr'],
            ['dec']
        ],
        parsers: {
            L: 'DD/MM/YYYY',
            LT: 'HH:mm A',
            LTS: 'HH:mm:ss A'
        },
    },

    CellMenu: {
        removeRow: 'Rimuovi'
    },

    RowCopyPaste: {
        copyRecord: 'Copia',
        cutRecord: 'Taglia',
        pasteRecord: 'Incolla',
        rows: 'righe',
        row: 'riga'
    },

    CellCopyPaste: {
        copy: 'Copia',
        cut: 'Taglia',
        paste: 'Incolla'
    },

    EventEdit: {
        Name: 'Nome',
        Resource: 'Risorse',
        Start: 'Inizio',
        End: 'Fine',
        Save: 'Salva',
        Delete: 'Rimuovi',
        Cancel: 'Annulla',
        'Edit event': 'Modifica',
        Repeat: 'Ripeti'
    },

    SchedulerBase: {
        'Add event': 'Aggiungi',
        'Delete event': 'Rimuovi',
        'Unassign event': 'Disassegna',
        color: 'Colore'
    },

    TimeAxisHeaderMenu: {
        pickZoomLevel: 'Zoom',
        activeDateRange: 'Range Date',
        startText: 'Data di Inizio',
        endText: 'Data di Fine',
        todayText: 'Oggi'
    },

    EventCopyPaste: {
        copyEvent: 'Copia',
        cutEvent: 'Taglia',
        pasteEvent: 'Incolla',
    },

    EventFilter: {
        filterEvents: 'Filtra',
        byName: 'per nome'
    },

    PresetManager: {
        secondAndMinute: {
            displayDateFormat: 'll LTS',
            name: 'Secondi'
        },
        minuteAndHour: {
            topDateFormat: 'ddd DD/MM, hA',
            displayDateFormat: 'll LST'
        },
        hourAndDay: {
            topDateFormat: 'ddd DD/MM',
            middleDateFormat: 'LST',
            displayDateFormat: 'll LST',
            name: 'Giorno'
        },
        day: {
            name: 'Giorno/Ore'
        },
        week: {
            name: 'Mese/Ore'
        },
        dayAndWeek: {
            displayDateFormat: 'll LST',
            name: 'Mese/Giorni'
        },
        dayAndMonth: {
            name: 'Mese'
        },
        weekAndDay: {
            displayDateFormat: 'll LST',
            name: 'Settimana'
        },
        weekAndMonth: {
            name: 'Settimane'
        },
        weekAndDayLetter: {
            name: 'Settimane/Fine Settimana'
        },
        weekDateAndMonth: {
            name: 'Mesi/Settimane'
        },
        monthAndYear: {
            name: 'Mesi'
        },
        year: {
            name: 'Anni'
        },
        manyYears: {
            name: 'Anni Multipli'
        }
    },
    Calendar: {
        Today: 'Oggi',
        None: 'Nessuno',
        Week: 'Settimana',
        Month: 'Mese',
        Year: 'Anno',
        Yesterday: 'Ieri',
        Tomorrow: 'Domani'
    }
}