import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Order } from 'src/app/models/order';
import { Status } from 'src/app/models/status';
import { Observable, map, switchMap, merge, forkJoin, Subject, of, combineLatest } from "rxjs";
import { SubjectService } from 'src/app/services/subject.service';
import { BuildingService } from 'src/app/services/building.service';
import { StatusService } from 'src/app/services/status.service';
import { OrderService } from 'src/app/services/order.service';
import { ComplaintTypesService } from 'src/app/services/complaint-types.service';
import { ClaimStatusService } from 'src/app/services/claim-status.service';
import { CompanyService } from 'src/app/services/companyService';
import { CondominiumService } from 'src/app/services/condominium.service';
import { StateCategoryService } from 'src/app/services/state-category.service';
import { eventClick } from '@syncfusion/ej2-angular-schedule';
import { TranslateService } from '@ngx-translate/core';
import { LibService } from 'src/app/services/libService';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() types: any;
  @Input() origins: any;
  @Input() practiceCode: any;

  eventSearch: Subject<void> = new Subject<void>();
  orders: Order[] = [];
  filterIDS: any = { buildingId: 0, subjectId: 0 };
  statusTemporary: Status
  controlTypes = ['Privato', 'Amministratore'];
  hideLegalEntity: boolean = true;
  complaintTypes: any;
  claimStatus: any;
  clean: boolean = false;
  formSelect: UntypedFormGroup;

  statuses: any = [];
  statusDescription: any;
  complaintTypesBackup: any;


  subjectId$: Observable<any>
  buildingId$: Observable<any>
  subjectType$: Observable<any>;
  complaintTypes$ = this.complaintTypesService.getAllComplaintTypes();
  claimStatus$ = this.claimStatusService.getAllClaimStatus();
  statuses$ = this.orderService.getOrderStatuses();
  complaintTypeSections: any = []
  complaintTypesFiltered: any = []

  stateCategories: any = []

  order: any = null;

  constructor(
    public subjectService: SubjectService,
    public buildingService: BuildingService,
    public orderService: OrderService,
    private statusService: StatusService,
    private cdr: ChangeDetectorRef,
    private complaintTypesService: ComplaintTypesService,
    public claimStatusService: ClaimStatusService,
    private companyService: CompanyService,
    public translate: TranslateService,
    private libService: LibService,
    private toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private condominiuService: CondominiumService,
    private stateService: StateService,
    private stateCategoryService: StateCategoryService,
  ) {
    this.formSelect = this.fb.group({
      listaOrdini: null
    })
  }

  initStateCategories() {
    this.stateCategoryService.index().subscribe((response: any) => {
      this.stateCategories = response.filter((stateCategory: any) => stateCategory.itemCategory.code == 'item_categories_practices')
    })
  }

  ngOnInit(): void {

    this.initStateCategories()

    if (this.practiceCode) {
      console.log('practiceCode')
      console.log(this.practiceCode)
      this.orderService.getOrderByCode(this.practiceCode).subscribe((order: any) => {
        console.log("[Order Flattened] ->", order.flat())
        this.order = order[0]
        this.patchAllOrderDetails(order[0])
      })
    }

    this.form.get('subject')?.disable();
    this.form.get('building')?.disable();
    this.form.get('order')?.disable();
    //set status provvisorio per subject e building
    this.statusService.getById(1, 'Subject').subscribe(res => {
      this.statusTemporary = res
      this.form.get('subject')?.get('status')?.setValue(this.statusTemporary)
      this.form.get('building')?.get('status')?.setValue(this.statusTemporary)
    })

    this.formSelect.get('listaOrdini')?.valueChanges.subscribe(order => {
      this.selOrder(order);
    })


    this.form.get('order.idStatus')?.valueChanges.subscribe(idStatus => {
      this.updateOrdereId()
    })




    this.subjectId$ = this.form.get('subject')!.get('id')!.valueChanges.pipe(
      map(subjectId => {
        return {
          subjectId: subjectId,
          // subjectType: subject.type,
          buildingId: this.form.get('building')?.get('id')?.value
        }
      })
    )
    this.subjectType$ = this.form.get('subject')!.get('type')!.valueChanges.pipe(
      map(subjectType => {

        return {
          subjectType: subjectType,
          // subjectType: subject.type,
          buildingId: this.form.get('building')?.get('id')?.value
        }
      })
    )
    this.buildingId$ = this.form.get('building')!.get('id')!.valueChanges.pipe(
      map(buildingId => {
        return {
          subjectId: this.form.get('subject')?.get('id')?.value,
          buildingId: buildingId
        }
      })
    )

    merge(this.subjectId$, this.buildingId$).pipe(
      switchMap((value) => {
        this.filterIDS = value;

        // return of([])
        if (this.clean === false) {
          return (this.filterIDS.buildingId !== null && this.filterIDS.buildingId !== undefined) ||
            (this.filterIDS.subjectId !== null && this.filterIDS.subjectId !== undefined) ?
            this.orderService.findByBuildingAndSubject(value.buildingId, value.subjectId) :
            of([]);

        } else {
          return of([])
        }

      })
    ).subscribe(values => {
      if (values?.length > 0) {
        console.log("RISULTATO CHIAMATA --> ", values);
        this.orders = [];
        this.orders = values
      }


    })

    this.form.get('subject')?.valueChanges.subscribe(subject => {
      console.log("REQUEST SUBJECT --> ", subject);
      if (this.controlTypes.includes(subject.type)) {

        this.hideLegalEntity = true;
        this.form.get('subject')?.get('surname')?.setValidators([Validators.required]); this.form.updateValueAndValidity();
        this.cdr.detectChanges();

      } else {
        this.hideLegalEntity = false;
        this.form.get('subject')?.get('surname')?.clearValidators(); this.form.updateValueAndValidity();
        this.cdr.detectChanges();
      }
    })

    forkJoin([this.complaintTypes$, this.claimStatus$, this.statuses$]).subscribe({
      next: ([complaintTypes, claimStatus, statuses]) => {
        this.complaintTypes = complaintTypes;
        this.complaintTypeSections = []
        this.complaintTypes.forEach((complaintType: any) => {
          if (!this.complaintTypeSections.includes(complaintType.claimCategory)) this.complaintTypeSections.push(complaintType.claimCategory)
        })

        this.complaintTypesBackup = complaintTypes;
        this.checkAndupdatecomplaintTypeSection()

        console.log("COMPLAINT TYPES -->", this.complaintTypes)
        this.claimStatus = claimStatus;
        this.statuses = statuses;
      },
    })
  }

  checkAndupdatecomplaintTypeSection() {
    if (this.form?.get('complaintTypeSection')?.value) {
      this.updatecomplaintTypeSection(this.form?.get('complaintTypeSection')?.value)
    }
  }

  getCategoryLabel(type: any) {
    let currentType = type?.toUpperCase();
    if (type == 'complaints') currentType = "GARANZIE"
    return this.translate.instant('LABEL.' + currentType)
  }

  ngOnDestroy(): void {
    this.form.get('subject')?.reset()
    this.form.get('building')?.reset()

    this.form.get('subject')?.enable()
    this.form.get('building')?.enable()
  }

  selSubject(event: any) {
    console.log('Selected: ', event);
    switch (event.type) {
      case 'Edificio':
        this.clearForm('building')
        this.form.get('building')?.patchValue(event)
        break;
      default:
        this.clearForm('subject')
        this.form.get('subject')?.patchValue(event)
        break;
    }
  }

  updateOrdereId() {

    this.stateService.getStateCategory(this.order.practice.StateId).subscribe((response: any) => {
      console.log(this.order.practice.StateId)
      console.log(response)
      this.statusDescription = response.id
      this.form?.get('order')?.get('statusDescription')?.setValue(response.id)
      this.filterList();
    })

  }

  selOrder(event: any) {
    console.log('Selected: ', event);
    this.order = event
    this.orderService.getOrderByCode(event.code).subscribe((order: any) => {
      console.log("[Order Flattened] ->", order.flat())
      this.patchAllOrderDetails(order[0])
    })

  }

  // selOrder(event: any){
  //   // FARE CHIAMATA PER I PATCH VALUE BUILDING  E SUBJECT E ANCHE SEDE
  //   console.log('Selected: ', event);
  //   let company$: any, building$: any, subject$: any, condominium$: any, admin$: any;
  //   event.idSede !== null && event.idSede !== undefined? company$ = this.companyService.getCompany(event.idSede): company$ = of(null);
  //   event.idBuilding !== null && event.idBuilding !== undefined? building$ = this.buildingService.getById([event.idBuilding]): building$ = of(null);
  //   event.idSubject !== null && event.idSubject !== undefined? subject$ = this.subjectService.getById([event.idSubject]): subject$ = of(null);
  //   event.idCondominius !== null && event.idCondominius !== undefined? condominium$ = this.condominiuService.getById([event.idCondominius]): condominium$ = of(null);
  //   event.idAdminCondominius !== null && event.idAdminCondominius !== undefined? admin$ = this.subjectService.getById([event.idAdminCondominius]): admin$ = of(null);
  //   forkJoin([company$, building$, subject$, condominium$, admin$]).subscribe({
  //     next: ([company, building, subject, condominium, admin]: any) => {
  //       console.log("COMPANY --> ", company);
  //       console.log("BUILDING ASSOCIATO --> ", building);
  //       console.log("SOGGETTO ASSOCIATO --> ", subject);
  //       let subj = subject;
  //       console.log("ADMIN ASSOCIATO --> ", admin);
  //       console.log("CONDOMINIO ASSOCIATO --> ", condominium);
  //       this.form.patchValue({
  //         company: company.code,
  //         building: building !== null && building !== undefined? building[0]: null,
  //         orderSubject: subj !== null && subj !== undefined? subj[0]: null,
  //         condominium: condominium !== null && condominium  !== undefined? condominium[0]: null,
  //         admin: admin !== null && admin !== undefined? admin[0]: null,
  //         order: {
  //           id: event.id,
  //           code: event.code,
  //           nameBuilding: event.nameBuilding,
  //           nameCondominius: event.nameCondominius,
  //           nameAdminCondominius: event.nameAdminCondominius,
  //           nameSubject: event.nameSubject
  //         }
  //       })
  //       console.log("VALORE FORM PRIMA DI SALVARE --> ", this.form.getRawValue());
  //     }
  //   })
  // }

  patchAllOrderDetails(event: any) {
    let company$: any, building$: any, subject$: any, condominium$: any, admin$: any;
    event.idSede !== null && event.idSede !== undefined ? company$ = this.companyService.getCompany(event.idSede) : company$ = of(null);
    event.idBuilding !== null && event.idBuilding !== undefined ? building$ = this.buildingService.getById([event.idBuilding]) : building$ = of(null);
    event.idSubject !== null && event.idSubject !== undefined ? subject$ = this.subjectService.getById([event.idSubject]) : subject$ = of(null);
    event.idCondominius !== null && event.idCondominius !== undefined ? condominium$ = this.condominiuService.getById([event.idCondominius]) : condominium$ = of(null);
    event.idAdminCondominius !== null && event.idAdminCondominius !== undefined ? admin$ = this.subjectService.getById([event.idAdminCondominius]) : admin$ = of(null);

    let practiceId = event?.practice?.id || event?.practiceId

    //this.statusDescription = this.statuses.find((stat: any) => stat.id === event.idStatus);


    this.libService.lockPage('');
    forkJoin([company$, building$, subject$, condominium$, admin$]).subscribe({
      next: ([company, building, subject, condominium, admin]: any) => {
        console.log("COMPANY --> ", company);
        console.log("BUILDING ASSOCIATO --> ", building);
        console.log("SOGGETTO ASSOCIATO --> ", subject);
        let subj = subject !== null && subject !== undefined ? subject[0] : null;
        console.log("ADMIN ASSOCIATO --> ", admin);
        console.log("CONDOMINIO ASSOCIATO --> ", condominium);
        admin !== null && admin !== undefined ? subj = admin[0] : admin = admin;
        // condominium !== null && condominium !== undefined? subject = condominium: condominium = condominium;
        this.form.patchValue({
          company: company.code,
          building: building !== null && building !== undefined ? building[0] : null,
          subject: subj !== null && subj !== undefined ? subj : null,
          // condominium: condominium !== null && condominium  !== undefined? condominium[0]: null,
          // admin: admin !== null && admin !== undefined? admin[0]: null,
          order: {
            id: event.id,
            code: event.code,
            version: event.version,
            idStatus: event?.idStatus,
            nameBuilding: event.nameBuilding,
            nameCondominius: event.nameCondominius,
            nameAdminCondominius: event.nameAdminCondominius,
            nameSubject: event.nameSubject,
            practiceId: practiceId,
            statusDescription: this.statusDescription
          }
        })
        this.libService.unlockPage();
        // console.log("VALORE FORM PRIMA DI SALVARE --> ", this.form.getRawValue());
      }
    })
  }

  clearForm(formName: string) {
    //set status provvisorio per subject e building
    this.form.get(formName)?.reset()
    if (formName === 'order') {
      this.clean = true;
      this.form.get('subject')?.reset();
      this.form.get('building')?.reset();

      this.clean = false;
    }
    this.form.get(formName)?.get('status')?.setValue(this.statusTemporary)
  }

  get addressSubjectValid() {
    let streetName = this.form.get('subject')?.get('streetName')?.valid
    let streetNumber = this.form.get('subject')?.get('streetNumber')?.valid
    let zip = this.form.get('subject')?.get('zip')?.valid
    let city = this.form.get('subject')?.get('city')?.valid
    let state = this.form.get('subject')?.get('state')?.valid
    let country = this.form.get('subject')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get addressBuildingValid() {
    let streetName = this.form.get('building')?.get('streetName')?.valid
    let streetNumber = this.form.get('building')?.get('streetNumber')?.valid
    let zip = this.form.get('building')?.get('zip')?.valid
    let city = this.form.get('building')?.get('city')?.valid
    let state = this.form.get('building')?.get('state')?.valid
    let country = this.form.get('building')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get subjectForm() {
    return this.form.controls['subject'] as UntypedFormGroup
  }

  get buildingForm() {
    return this.form.controls['building'] as UntypedFormGroup
  }

  compareFn(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  filterList() {

    this.stateService.getStateCategory(this.order.practice.StateId).subscribe((response: any) => {

      console.log(response)
      this.complaintTypes = this.complaintTypesBackup;
      if (['state_categories_S64', 'state_categories_S65', 'state_categories_S20', 'state_categories_S11', 'state_categories_S15', 'state_categories_S61'].includes(response.code)) {
        this.complaintTypes = this.complaintTypes.filter((ct: any) => ct.claimCategory !== 'complaints')
        // this.form.get('complaintType')?.enable();
        this.cdr.detectChanges();
      } else if (['state_categories_S34', 'state_categories_S31'].includes(response.code)) {
        this.complaintTypes = this.complaintTypesBackup;
        // this.form.get('complaintType')?.enable();
        // this.form.get('complaintType')?.setValidators(Validators.required)
        this.cdr.detectChanges();
      } else {
        this.complaintTypes = [];
        // this.form.get('complaintType')?.disable();
        // this.form.get('complaintType')?.setValidators(Validators.required)
        this.cdr.detectChanges();
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE37'), this.translate.instant('TOASTR.WARNING'), { timeOut: 10000 })
      }

      this.checkAndupdatecomplaintTypeSection()
    })

  }

  updatecomplaintTypeSection(event: any) {
    let value = event.value || event
    this.complaintTypesFiltered = this.complaintTypes.filter((complaintType: any) => complaintType.claimCategory == value)
  }

}
