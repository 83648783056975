 <h1 mat-dialog-title>{{ 'PLANNER.modifica_operatori' | translate }}</h1>
 <div mat-dialog-content class="Tooltip">
   <form [formGroup]="form" class="d-flex flex-column">
     <mat-form-field appearance="fill">
       <mat-label>{{ 'PLANNER.CODE_COMMESSA' | translate }}</mat-label>
       <mat-select formControlName="orderId" multiple>
         <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="orderSearchControl">
         <mat-option *ngFor="let order of filteredOrders" [value]="order.id">
           {{ order.code }}
         </mat-option>
       </mat-select>
     </mat-form-field>
     <ng-container formArrayName="resources">
       <div class="containerResources" *ngFor="let resource of resources.controls; let i = index" [formGroupName]="i">
         <div [formGroup]="resource" class="form-subject">
           <mat-form-field appearance="fill">
             <mat-label>{{ 'PLANNER.Risorse_da_sostituire' | translate }}</mat-label>
             <mat-select formControlName="resourcesToReplace">
              <input matInput class="inputResource" [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="resourcesToReplaceSearchControls[i]">
               <mat-option *ngFor="let res of availableResourcesFiltered[i]" [value]="res.id">
                 {{ res.name }} ({{ 'WORKTYPE_PROFILES.' + res.ruolo | translate }})
               </mat-option>
             </mat-select>
             <mat-error *ngIf="resource.get('resourcesToReplace').hasError('required')">
              {{ 'SUBJECT.storicBuilding.error' | translate }}
             </mat-error>
           </mat-form-field>
           <mat-form-field appearance="fill">
             <mat-label>{{ 'PLANNER.Risorsa_da_aggiungere' | translate }}</mat-label>
             <mat-select formControlName="resourcesToAdd">
              <input matInput class="inputResource" [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="resourcesToAddSearchControls[i]">
               <mat-option *ngFor="let res of filteredResourcesToAdd[i]" [value]="res.id">
                 {{ res.name }} ({{ 'WORKTYPE_PROFILES.' + res.ruolo | translate }})
               </mat-option>
             </mat-select>
             <mat-error *ngIf="resource.get('resourcesToAdd').hasError('required')">
              {{ 'SUBJECT.storicBuilding.error' | translate }}
             </mat-error>
           </mat-form-field>
         </div>
         <div class="buttonDelete" *ngIf="resources.length > 1">
           <button mat-button type="button" (click)="removeResourceGroup(i)">{{ 'PLANNER.Rimuovi' | translate }}</button>
         </div>
         <hr>
       </div>
     </ng-container>
     <div class="buttonContainer">
       <button mat-button type="button" (click)="initResources()">{{ 'PLANNER.Aggiungi_Risorsa' | translate }}</button>
     </div>
     <div class="alert" *ngIf="showRoleMismatchWarning">
      <mat-card appearance="outlined" class="errorModal">
        <mat-card-content>
          <strong>
            {{ 'PLANNER.MismatchOperators' | translate }}
          </strong>
        </mat-card-content>
      </mat-card>
    </div>
     <mat-form-field appearance="fill">
       <mat-label>{{ 'COMPANIES.START_DATE' | translate }}</mat-label>
       <input matInput [matDatepicker]="startDate" [min]="todayDate | date : 'yyyy-MM-dd'" formControlName="startDate" />
       <mat-hint>GG/MM/AAAA</mat-hint>
       <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
       <mat-datepicker #startDate></mat-datepicker>
     </mat-form-field>
     <mat-form-field appearance="fill">
       <mat-label>{{ 'COMPANIES.END_DATE' | translate }}</mat-label>
       <input matInput [matDatepicker]="endDate" [min]="minEndDate | date : 'yyyy-MM-dd'" [max]="maxEndDate | date : 'yyyy-MM-dd'" formControlName="endDate" />
       <mat-hint>GG/MM/AAAA</mat-hint>
       <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
       <mat-datepicker #endDate></mat-datepicker>
     </mat-form-field>
     <div *ngIf="form.hasError('invalidDateRange')">
       <br>
       <mat-card appearance="outlined" class="errorModal">
         <mat-card-content>
           <strong>
            {{ 'PLANNER.DataError' | translate }}
           </strong>
         </mat-card-content>
       </mat-card>
     </div>
   </form>
   <mat-dialog-actions>
     <button mat-button (click)="onCancel()">{{ 'LABEL.CANCEL' | translate }}</button>
     <button mat-button (click)="onSave()" color="primary" [disabled]="!form.valid">{{ 'PROFILES.SAVE' | translate }}</button>
   </mat-dialog-actions>
 </div>
 

