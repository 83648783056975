<div class="full-w">
  <br><br>
  <br>

  <form [formGroup]="form" class="form-container">

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{'LABEL.STATO' | translate }}</mat-label>
      <mat-select matNativeControl name="status" formControlName="status" [disabled]="true" [compareWith]="compareFn">
        <mat-option *ngFor="let status of statuses" [value]="status">
          {{ 'LABEL.' + status.uiLabel | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="typeOther">
      <mat-label>{{ 'LABEL.SUBJECT_TYPE' | translate }}</mat-label>
      <mat-select matNativeControl name="type" formControlName="type" [disabled]="form.get('id')?.value !== null">
        <mat-option *ngFor="let type of types" [value]="type.description">
          {{type.description}}
        </mat-option>

      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="(type === 'Amministratore')">
      <mat-label>{{ 'LABEL.LEVEL' | translate }}</mat-label>
      <input matInput maxlength="128" inputmode="tel" [placeholder]="translate.instant('LABEL.INSERISCI_NOME')" formControlName="clientStatus">
    </mat-form-field>

    <!-- <mat-form-field appearance="fill" class="form-subject" *ngIf="(type == 'Amministratore')">
      <mat-label>Tipologia</mat-label>
      <mat-select [disabled]="true" formControlName="clientStatus">
        <mat-option value="Cliente">Cliente</mat-option>
        <mat-option value="Vip">Vip</mat-option>
        <mat-option value="SuperVip">Super Vip</mat-option>
      </mat-select>

    </mat-form-field> -->

    <app-address-detail-form [form]="form" [isNew]="isNew" [type]="type" class="form-subject" ></app-address-detail-form>

    <br>

    <mat-form-field appearance="fill" class="form-subject"
      *ngIf="(type == 'Amministratore') || (type == 'Privato')">
      <mat-label>{{ 'LABEL.NAME' | translate }}</mat-label>
      <input matInput maxlength="128" inputmode="tel" [placeholder]="translate.instant('LABEL.INSERISCI_NOME')" formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject"
      *ngIf="(type == 'Condominio')">
      <mat-label>{{ 'LABEL.BUSINESS_NAME' | translate }}</mat-label>
      <input matInput maxlength="128" inputmode="tel" [placeholder]="translate.instant('LABEL.INSERISCI_NOME')" formControlName="businessName">
    </mat-form-field>


    <mat-form-field appearance="fill" class="form-subject" *ngIf="(type == 'Amministratore') || (type == 'Privato')">
      <mat-label>{{ 'LABEL.LAST_NAME' | translate }}</mat-label>
      <input matInput maxlength="128" inputmode="tel" [placeholder]="translate.instant('LABEL.INSERISCI_COGNOME')" formControlName="surname">
    </mat-form-field>

    <div class="form-subject" *ngIf="(type == 'Edificio') ">
      <mat-radio-group formControlName="storicBuilding" name="storicBuilding">
        <label id="example-radio-group-label">{{ 'LABEL.EDIFICIO_STORICO_?' | translate }}</label>
        <br>
        <br>
        <mat-radio-button [value]="true" name="storicBuilding">

          {{ 'LABEL.YES' | translate }}

        </mat-radio-button>
        <mat-radio-button [value]="false" name="storicBuilding">

          {{ 'LABEL.NO' | translate }}

        </mat-radio-button>
        <br>
        <br>

      </mat-radio-group>
      <div class="radio-group-error" [class.show]="!formControlIsValid(form, 'storicBuilding')">
        {{'SUBJECT.storicBuilding.error' | translate}}
      </div>
    </div>

    <mat-form-field appearance="fill" class="form-subject"
      *ngIf="(type !== 'Condominio') && (type !== 'Edificio') && (type !== 'Privato')">
      <mat-label>{{ 'LABEL.LEGAL_ENTITY' | translate }}</mat-label>
      <input matInput maxlength="128" [placeholder]="translate.instant('LABEL.INSERISCI_RAGIONE_SOCIALE')" formControlName="legalEntity">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="(type !== 'Condominio') && (type !== 'Edificio')">
      <mat-label>{{ 'LABEL.EMAIL' | translate }}</mat-label>
      <input matInput type="email" formControlName="email" [placeholder]="translate.instant('LABEL.INSERISCI_EMAIL')">
      <mat-icon matSuffix>mail_outline</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="(type !== 'Edificio') && (type !== 'Condominio')">
      <mat-label>{{ 'LABEL.FIX_TELEPHONE' | translate }}:</mat-label>
      <input matInput type="phone" formControlName="telephone1" [placeholder]="translate.instant('LABEL.INSERISCI_NUMERO_TELEFONO_FISSO')">
      <mat-icon matSuffix>call</mat-icon>
      <mat-hint>{{'LABEL.PHONE_ERROR' | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject"
      *ngIf="(type !== 'Edificio') && (type !== 'Condominio') && (type !== 'Privato')">
      <mat-label>{{ 'LABEL.FAX' | translate }}: </mat-label>
      <input matInput type="phone" formControlName="fax" [placeholder]="translate.instant('LABEL.INSERISCI_FAX')">
      <mat-icon matSuffix>call</mat-icon>
      <mat-hint>{{ 'LABEL.FAX_ERROR' | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="(type !== 'Edificio') && (type !== 'Condominio')">
      <mat-label>{{ 'LABEL.MOBILE' | translate }}</mat-label>
      <input matInput type="phone" formControlName="telephone2" [placeholder]="translate.instant('LABEL.INSERISCI_NUMERO_TELEFONO_MOBILE')">
      <mat-icon matSuffix>smartphone</mat-icon>
      <mat-hint>{{ 'LABEL.PHONE_ERROR' | translate }}</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.Societa' | translate }}</mat-label>
      <input matInput maxlength="128" [placeholder]="translate.instant('LABEL.INSERISCI_AZIENZA')" formControlName="companyLocation" readonly>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.company' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="company">
        <mat-option *ngFor="let company of companies" [value]="company.code">
          {{company.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.GEO_ZONE' | translate }}</mat-label>
      <input matInput maxlength="128" [placeholder]="translate.instant('LABEL.INSERISCI_AREA_GEOGRAFICA')" formControlName="geographicArea"
        #geographicAreaInput readonly>
    </mat-form-field>

    <!-- <mat-form-field appearance="fill" class="form-subject" >
      <mat-label>{{ 'LABEL.Responsabile' | translate }}</mat-label>

      <mat-select formControlName="responsible" [disabled]="disabled">

        <mat-option *ngFor="let responsible of users" [value]="responsible.email">

          {{responsible.email? responsible.email: responsible.username}} <b> {{responsible.UsersProfilesRel[0]?.name}} </b>
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <!-- *ngIf="users.length > 0" -->
    <app-select-search-subject class="form-subject"
      (click)="selectClicked($event)"
      formControlName="responsible"
      [label]="translate.instant('LABEL.Responsabile')"
      [items]="users"
      [viewField]="''"
      [disable]="disable">
    </app-select-search-subject>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="!(type == 'Condominio') ">
      <mat-label>{{ 'REQUEST.CHANNEL' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="contactChannel" [disabled]="disabledContactChannel">
        <mat-option *ngFor="let cc of contactChannels$ | async" [value]="cc.name">
          {{ 'CHANNEL.'+cc.name | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.ORIGIN' | translate }}</mat-label>
      <mat-select formControlName="origin" #originInput [disabled]="disabledOrigin">
        <mat-option *ngFor="let origin of origins$ | async" [value]="origin.origin" [disabled]="origin.disabled">
          {{ 'ORIGINS.'+origin.code | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.MARKETINGNOTES' | translate }}</mat-label>
      <mat-select formControlName="marketingNoteId" #originInput>
        <mat-option *ngFor="let marketingNote of marketingNotes" [value]="marketingNote.id" [disabled]="marketingNote.disabled">
          {{ marketingNote.translations[getCurrentLanguage()] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="(type == 'Amministratore')">
      <mat-label>{{ 'LABEL.ADMIN_ASSOC' | translate }}</mat-label>
      <mat-select formControlName="adminAssociation">
        <mat-option *ngFor="let association of associations$ | async" [value]="association">
          {{association}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="(type == 'Amministratore')">
      <mat-label>{{ 'LABEL.TOT_ADMIN_CONDOMINIUMS' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('LABEL.INSERISCI_NUMERO_CONDOMINI_AMMINISTRATI')"
        formControlName="administeredCondominiums">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject" *ngIf="(type == 'Amministratore')">
      <mat-label>{{ 'LABEL.TOT_ALBERT_CONDOMINIUMS' | translate }}</mat-label>
      <input readonly matInput maxlength="128" [placeholder]="translate.instant('LABEL.INSERISCI_NUMERO_CONSOMINI_ALBERT')"
        formControlName="totAdministeredCondominiums">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.NOTE' | translate }}</mat-label>
      <input matInput maxlength="128" [placeholder]="translate.instant('LABEL.INSERISCI_EVENTUALI_NOTE')" formControlName="notes" #notesInput>
    </mat-form-field>

  </form>

</div>
