import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Row } from '../models/orderPlanningMulti';

@Injectable({
  providedIn: 'root',
})
export class PlannerFormMultiService {
  constructor(private fb: FormBuilder) {}
  plannerFormMulti = this.fb.nonNullable.group({
    orderCode: ['', Validators.required],
    date: [new Date(), Validators.required],
    name: '',
    formRows: this.fb.array<FormGroup>([]),
  });

  get formRows(): FormArray<FormGroup> {
    return this.plannerFormMulti.get('formRows') as FormArray<FormGroup>;
  }

  addFormRows(row: Row, typologyServicePassages: any = []) {
    
    let currentTypology = typologyServicePassages.find((typology:any)=> typology.id == row.TypologyServicePassageId)

    let formRow: FormGroup = this.fb.group({
      id: row.id,
      timeStart: ['08:00', Validators.required],
      timeEnd: [null],
      timeEndMessage: [null],
      startDate: [null],
      endDate: [null],
      weekDays: [null, Validators.required],
      resources: [null, Validators.required],
      descMacroPhase: row.descMacroPhase,
      hoursPerSingleRepetition: row.hoursPerSingleRepetition,
      typologyServicePassageId: [
        currentTypology?.code,
        Validators.required,
      ],
      numberOfRepetitions: row.numberOfRepetitions,
    });
    this.formRows.push(formRow);
  }

  public get formResults() {
    return this.plannerFormMulti.value;
  }
  public get isValid(): boolean {
    return this.plannerFormMulti.valid;
  }

  _tasks: any[] = [];
  public get tasks() {
    return this._tasks;
  }
  public set tasks(tasks: any[]) {
    this._tasks = tasks;
  }
}
