
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ControllerService } from '../services/controller.service';
import { NavService } from '../services/nav.service';
import { ProfileService } from '../services/profileService';
import { LibService } from '../services/libService';
import { TranslatorService } from '../services/translate.service';
import { ActionCategoryService } from '../services/actionCategory.service';
import { ActionService } from '../services/action.service';

export type ParentNode = {
  name: string,
  children: ChildNode[],
}

export type ChildNode = {
  label: string,
  key: string,
  selected: boolean,
  id?: any
}

@Component({
  selector: 'app-profile-item',
  templateUrl: './profile-item.component.html',
  styleUrls: ['./profile-item.component.scss']
})
export class ProfileItemComponent implements OnInit {
  title: string = '';
  subTitle: string = '';

  faChevronRight = faChevronRight;
  treeNodes: ParentNode[] = [];
  profile: any;
  id: any;
  constructor(private controllerService: ControllerService,
    private route: ActivatedRoute,
    private navService: NavService,
    private profileService: ProfileService,
    public libService: LibService,
    private toastr: ToastrService,
    public translate: TranslatorService,
    private actionCategoryService: ActionCategoryService,
    private router: Router,
    private actionService: ActionService
  ) { }

  ngOnInit(): void {

    this.controllerService.getAllControllers().subscribe((result) => {
      this.title = "Nuovo Profilo";
      this.subTitle = "EDAC - Nuovo Profilo";
      this.id = this.route.snapshot.paramMap.get('id');
      if (this.id && parseInt(this.id) > 0) {
        this.title = "Modifica Profilo";
        this.subTitle = "EDAC - Modifica Profilo";
      }
      this.navService.setTitle(this.title);
      this.navService.setSubTitle(this.subTitle);
      this.navService.setWikiKey('NODEF');
      let actions: any;
      actions = [
        { name: 'back', url: 'profiles' }
      ];
      this.navService.setActions(actions);

      if (this.id && parseInt(this.id) > 0) {
        this.profileService.getProfile(this.id!).subscribe((response) => {
          this.profile = response;
          this.setActions()
        });
      }
     
      
    });


  }

  loadProfile(id: any) {
    this.profileService.getProfile(id!).subscribe((response) => {
      this.profile = response;
    });
  }

  setControllers() {
    let rootElements: any = []
    this.actionCategoryService.index().subscribe((response: any) => {
      console.log(response)
      response.forEach((actionCategory: any) => {
        let mainLevel = actionCategory.label
        let actions = actionCategory.Actions.map((action: any) => {
          return {
            id: action.id,
            label: action.name,
            key: action.keyFrontEnd,
            selected: this.profile.ProfilesActionsRel.filter((profileAction: any) => profileAction.id === action.id).length > 0
          }
        });
        rootElements.push({
          name: mainLevel,
          children: actions,
        });
      })
    })
    return rootElements
  }

  getIndex(text: any) {
    let index = this.treeNodes.findIndex((item: any) => item.name == text)

    if (index == -1) {
      this.treeNodes.push({
        name: text,
        children: [],
      })
      index = this.treeNodes.findIndex((item: any) => item.name == text)
    }
    return index
  }

  setActions() {
    let withoutControllerOrCategoryLabel = 'withoutControllerOrCategory'

    this.actionService.index().subscribe((response: any) => {

      response.forEach((action: any) => {
        let index = null
        if (action.Controllers.length > 0) {
          let controller = action.Controllers[0]
          index = this.getIndex(controller.name)
        }
        else if (action.ActionCategory != null) {
          let actionCategory = action.ActionCategory
          index = this.getIndex(actionCategory.code)
        }
        else {
          index = this.getIndex(withoutControllerOrCategoryLabel)
        }

        this.treeNodes[index].children.push(
          {
            id: action.id,
            label: action.keyFrontEnd,
            key: action.keyFrontEnd,
            selected: this.profile.ProfilesActionsRel.filter((profileAction: any) => profileAction.id === action.id).length > 0
          }
        )
      })
    })

  }

  selectFullNode(node: ParentNode) {
    if (this.fullNodeSelectionStatus(node) == 'full-selected') {
      node.children.forEach((child) => {
        child.selected = false
      })
    } else {
      node.children.forEach((child) => {
        child.selected = true
      })
    }
  }

  fullNodeSelectionStatus(node: ParentNode) {
    const selectedChildren = node.children.filter((child) => child.selected);
    if (selectedChildren.length == 0) return 'not-selected'
    else if (selectedChildren.length > 0 && selectedChildren.length < node.children.length) return 'partial-selected'
    else return 'full-selected'
  }

  getActionIds() {
    let actionIds: any[] = [];
    this.treeNodes.forEach((parent: ParentNode) => {
      parent.children.forEach((child: any) => {
        if (child.selected) actionIds.push(child.id);
      })
    });
    return actionIds;
  }

  save() {
    this.libService.lockPage('');
    let actionIds = this.getActionIds()
    this.profileService.touchAndSetActions(this.id, { name: this.profile.name }, actionIds).subscribe(
      (res: any) => {
        this.router.navigate(['profile/' + res.profile.id]);
        this.libService.unlockPage();
      },
      (err: any) => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
      }
    )
  }
}
