<div class="webcam-container">
  <webcam [height]="500" [width]="500" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
          *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
          [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
          (initError)="handleInitError($event)"></webcam>
          <div class="dummy-button">


  <div class="buttons-container">
          <button class="my-bounce btn btn-primary fx-button" (click)="triggerSnapshot();">
    <mat-icon class="material-icons" style="color:#293683;">photo_camera</mat-icon> {{ 'WEBCAM.TAKE_PHOTO' | translate }}
</button>
<button class="my-bounce btn btn-primary fx-button" (click)="showNextWebcam(true);"
          [disabled]="!multipleWebcamsAvailable">
          <mat-icon class="material-icons" style="color:#293683;">flip_camera_ios</mat-icon> {{ 'WEBCAM.CHANGE_CAMERA' | translate }}
        </button>
      </div>
    </div>
</div>
