import { Component,ViewChild, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-select-search-subject-section',
  templateUrl: './select-search-subject-section.component.html',
  styleUrls: ['./select-search-subject-section.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectSearchSubjectSectionComponent,
      multi: true
    }
  ],
})
export class SelectSearchSubjectSectionComponent implements OnInit, OnChanges,ControlValueAccessor {
  @ViewChild('singleSelect') matSelect: MatSelect

  @Input()items: any[]
  @Input()noMatchLabel: string;
  @Input()viewField: string;
  @Input()label: string;
  @Input() disable: boolean;

  currentValue: any;
  disabled: boolean;

  filterControl: UntypedFormControl = new UntypedFormControl();

  filteredItems: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(public cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.filteredItems.next(this.items?.slice());
    console.log("CURRENT VALUE SEARCH SUBJECT --> ", this.currentValue);
    // listen for search field value changes
    this.filterControl.valueChanges
      .subscribe(() => {
        this.filterItems();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
      console.log("ITEMS LENGTH --> ", this.items);
      if(!(changes['items'] == null) && (changes['items'].currentValue !== changes['items'].previousValue)){
        this.filteredItems.next(this.items.slice());
      }
  }

  selectionChanged(event: any){
    this.onChange(event.value)
  }


  protected filterItems() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.filterControl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredItems.next(
      this.items.filter(item => this.viewField !== '' ?
        item[this.viewField].toLowerCase().indexOf(search) > -1:
        item.toLowerCase().indexOf(search) > -1)
    );
  }

  //--------ControlValueAccessor things -------------------->
  onChange = (value: any) => {};

  onTouched = () => {};

  writeValue(obj: any): void {
    this.currentValue = obj;
    if(obj === null && this.matSelect !== undefined){
      this.matSelect.value = []
    }
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
