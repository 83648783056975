import { Component, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ToastrService } from 'ngx-toastr';
import { ConsuntivatoreDubaiService } from '../services/consuntivatoredubai/consuntivatore-dubai.service';
import { AuthService } from '../auth/auth.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from './dialog/confirm-dialog.component'; // Assicurati del percorso corretto
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { UserSessionService } from '../services/user-session.service';

interface TeamLeader {
    Id: number;
    name: string;
    CodiceFiscale: string;
    projects: Project[];
}

interface Project {
    id: number;
    code: string;
    address: string;
    subject: string;  // ✅ Aggiunto campo per il nome della commessa
}


@Component({
    selector: 'app-consuntivatore-dubai',
    templateUrl: './consuntivatore-dubai.component.html',
    styleUrls: ['./consuntivatore-dubai.component.scss']
})
export class ConsuntivatoreDubaiComponent implements OnInit, AfterViewInit {
    constructor(private toastr: ToastrService, private consDubaiService: ConsuntivatoreDubaiService, public authService: AuthService, private cdr: ChangeDetectorRef,
        public dialog: MatDialog, private scrollStrategies: ScrollStrategyOptions, private userSessionService: UserSessionService

    ) { }

    searchTeamLeader: string = '';

    pageSizeLeaders = 5;
    pageIndexLeaders = 0;
    totalLeaders = 0; // Totale per i capi squadra

    pageSizeProjects = 15;
    pageIndexProjects = 0;
    totalProjects = 0; // Totale per le commesse

    hasChanges = false; // Variabile per abilitare/disabilitare il pulsante save

    teamLeaders: TeamLeader[] = [];

    projects: Project[] = [];

    searchProject: string = '';
    searchProjectSubject = new Subject<string>(); // 🔍 Subject per la ricerca
    loading: boolean = true; // 🔥 Variabile di stato per il caricamento
    isLoadingProjects: boolean = false;
    private sub: Subscription;
    idSede: string = '';
    country: string = '';
    invalidCompanySelected = false;


    @ViewChild('paginatorLeaders') paginatorLeaders!: MatPaginator;
    @ViewChild('paginatorProjects') paginatorProjects!: MatPaginator;

    ngOnInit(): void {
        const company = this.userSessionService.getState('working_company');

        console.log('company scelta?: ', company)
        this.idSede = company.id;
        this.country = company.country;
        this.sub = this.userSessionService.$changeCompanyDetector.subscribe(
            (res) => {
                window.location.reload();
            }
        );
        this.loading = true; // ✅ Attiva il loading all'inizio
        if (company?.code === '*') {
            this.invalidCompanySelected = true;
            this.loading = false; // ⛔️ Non caricare nulla
            return; // ⛔️ Ferma subito l'inizializzazione
        }
        // 🔥 Recupera prima operatori e commesse
        this.loadData().then(() => {
            // 🔥 Dopo aver caricato teamLeaders e projects, recuperiamo le relazioni dal DB
            this.consDubaiService.getCommesseConsuntivazioneDubai(
                this.authService.idToken(),
                this.authService.user().email
            ).subscribe(response => {
                console.log('🗄️ Relazioni iniziali dal DB:', response);

                if (response.esito === "OK" && response.item && response.item.Table) {
                    setTimeout(() => { // 🔥 Posticipa il cambiamento
                        this.initializeDragDropAssignments(response.item.Table);
                        this.loading = false; // ✅ Disattiva il loading dopo aver caricato tutto
                        this.cdr.detectChanges(); // ✅ Forza Angular a aggiornare il template
                    });
                }
                this.loading = false; // ✅ Disattiva il loading dopo aver caricato tutto

            }, error => {
                console.error('❌ Errore nel recupero delle commesse assegnate:', error);
                this.loading = false; // ✅ Disattiva il loading dopo aver caricato tutto

            });
        });
        this.searchProjectSubject.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(searchTerm => {
                this.isLoadingProjects = true; // 🔥 Attiva il loading prima della chiamata API
                return searchTerm.trim() === ''
                    ? this.getCommesseObservable()
                    : this.consDubaiService.getConsuntivazioneDubai(this.authService.idToken(), this.authService.user().email, this.idSede, searchTerm);
            })
        ).subscribe(res => {
            console.log('Response ricevuta:', res.item.Table);
            this.projects = res.item.Table;
            this.totalProjects = this.projects.length;
            // 🔹 Reimposta la paginazione alla prima pagina
            if (this.paginatorProjects) {
                this.paginatorProjects.firstPage();
            }
            this.isLoadingProjects = false; // ✅ Disattiva il loading dopo la chiamata
            this.cdr.detectChanges();
        }, error => {
            console.error('❌ Errore nella ricerca delle commesse:', error);
            this.isLoadingProjects = false; // ❌ Disattiva il loading anche in caso di errore
        });

    }
    async loadData(): Promise<void> {
        await Promise.all([
            new Promise<void>((resolve) => {
                this.consDubaiService.getOperators(this.authService.idToken(), this.authService.user().email, this.country, this.idSede, '36')
                    .subscribe(res => {
                        this.teamLeaders = res.item.Table.map((leader: any) => ({
                            ...leader,
                            Id: leader.id, // Assicura che l'ID sia mappato correttamente
                            projects: []
                        }));
                        resolve();
                    });
            }),
            new Promise<void>((resolve) => {
                this.consDubaiService.getConsuntivazioneDubai(this.authService.idToken(), this.authService.user().email, this.idSede)
                    .subscribe(res => {
                        this.projects = res.item.Table;
                        resolve();
                    });
            })
        ]);
    }

    ngAfterViewInit() {

        this.updatePagination();
    }

    updatePaginationProjects(event: any) {
        this.pageSizeProjects = event.pageSize;
        this.pageIndexProjects = event.pageIndex;
        this.cdr.detectChanges();
    }

    updatePaginationLeaders(event: any) {
        this.pageSizeLeaders = event.pageSize;
        this.pageIndexLeaders = event.pageIndex;
        this.cdr.detectChanges();
    }


    initializeDragDropAssignments(assignments: { TeamLeaderId: number, Pratica: string }[]): void {
        assignments.forEach(assignment => {
            const teamLeader = this.teamLeaders.find(tl => tl.Id === assignment.TeamLeaderId);

            if (teamLeader) {
                const practiceIds = assignment.Pratica.split(',').map(p => p.trim());

                const assignedProjects = this.projects.filter(project =>
                    practiceIds.includes(project.id.toString())
                );

                console.log(`🔗 Assegno ${assignedProjects.length} commesse a ${teamLeader.name}`, assignedProjects);

                teamLeader.projects = assignedProjects; // 🔥 Assicura che venga aggiornato correttamente
            }
        });

        this.cdr.detectChanges(); // 🔥 Forza l'aggiornamento della UI
    }



    updatePagination() {
        this.pageIndexLeaders = this.paginatorLeaders?.pageIndex || 0;
        this.pageSizeLeaders = this.paginatorLeaders?.pageSize || 5;

        this.pageIndexProjects = this.paginatorProjects?.pageIndex || 0;
        this.pageSizeProjects = this.paginatorProjects?.pageSize || 15;
    }
    getCommesseObservable(): Observable<any> {
        return this.consDubaiService.getConsuntivazioneDubai(this.authService.idToken(), this.authService.user().email, this.idSede);
    }

    // ✅ Metodo chiamato quando l'utente digita nel campo di ricerca
    onSearchChange(searchValue: string) {
        this.searchProjectSubject.next(searchValue);
    }


    getCommesse() {
        this.consDubaiService.getConsuntivazioneDubai(this.authService.idToken(), this.authService.user().email, this.idSede).subscribe(res => this.projects = res.item.Table)
    }

    get filteredTeamLeaders(): TeamLeader[] {
        let filtered;
        const searchTerm = (this.searchTeamLeader || '').trim().toLowerCase(); // 🔥 Evita il problema di null

        if (!this.searchTeamLeader.trim()) {
            // 🔹 Se non c'è filtro, usa tutti i team leaders
            filtered = [...this.teamLeaders];
        } else {
            // 🔍 Filtra per nome o codice fiscale
            // let searchLower = this.searchTeamLeader.toLowerCase();
            filtered = this.teamLeaders.filter(leader =>
                leader.name.toLowerCase().includes(searchTerm) ||
                (leader.CodiceFiscale && leader.CodiceFiscale.toLowerCase().includes(searchTerm))
            );

        }

        // ✅ Aggiorna il numero totale di risultati filtrati
        this.totalLeaders = filtered.length;

        // ✅ Assicura che `paginatorLeaders` sia aggiornato
        if (this.paginatorLeaders) {
            // this.paginatorLeaders.firstPage();
            this.paginatorLeaders.length = this.totalLeaders;
            // 🔥 Se la pagina corrente è oltre il massimo, torna alla prima pagina
            const maxPageIndex = Math.floor(this.totalLeaders / this.pageSizeLeaders);
            if (this.paginatorLeaders.pageIndex > maxPageIndex) {
                this.paginatorLeaders.firstPage();
            }

            this.pageIndexProjects = this.paginatorProjects.pageIndex; // 🔥 Aggiorna il valore
        }

        // 🔹 Applica la paginazione ai risultati filtrati
        return filtered.slice(
            (this.paginatorLeaders?.pageIndex || 0) * this.pageSizeLeaders,
            ((this.paginatorLeaders?.pageIndex || 0) + 1) * this.pageSizeLeaders
        );
    }


    get filteredProjects(): Project[] {
        let filtered;
        if (this.searchProject.trim() !== '') {
            // 🔍 Se la ricerca è attiva, usa direttamente i dati aggiornati dalla chiamata API
            // return this.projects.slice(this.pageIndexProjects * this.pageSizeProjects, (this.pageIndexProjects + 1) * this.pageSizeProjects);
            filtered = [...this.projects];

        } else {
            // 🔹 Se non c'è ricerca attiva, applica il filtro locale sui dati completi
            filtered = this.projects.filter(project =>
                project.code.toLowerCase().includes(this.searchProject.toLowerCase()) ||
                project.address.toLowerCase().includes(this.searchProject.toLowerCase())
            );
        }

        this.totalProjects = filtered.length; // ✅ Aggiorna il numero totale delle commesse

        // ✅ Se il paginator è inizializzato, aggiorna la paginazione
        if (this.paginatorProjects) {
            this.paginatorProjects.length = this.totalProjects;

            // 🔥 Se la pagina corrente è oltre il massimo, torna alla prima pagina
            const maxPageIndex = Math.floor(this.totalProjects / this.pageSizeProjects);
            if (this.paginatorProjects.pageIndex > maxPageIndex) {
                this.paginatorProjects.firstPage();
            }

            this.pageIndexProjects = this.paginatorProjects.pageIndex; // 🔥 Aggiorna il valore
        }

        // ✅ Paginazione sui risultati filtrati
        return filtered.slice(
            (this.pageIndexProjects || 0) * this.pageSizeProjects,
            ((this.pageIndexProjects || 0) + 1) * this.pageSizeProjects
        );
    }

    get connectedLists(): string[] {
        return this.filteredTeamLeaders
            .map(tl => tl.Id ? `leader-${tl.Id}` : '')
            .filter(id => id !== ''); // ✅ Esclude ID errati
    }

    // drop(event: CdkDragDrop<Project[]>, newTeamLeader?: TeamLeader) {
    //     if (!event.previousContainer || !event.container) return;

    //     const draggedProject: Project = event.previousContainer.data[event.previousIndex];

    //     if (newTeamLeader) {
    //         console.log(`🔄 Trascinamento della pratica ${draggedProject.id} dal capo squadra precedente a ${newTeamLeader.name}`);

    //         // Trova il vecchio team leader a cui la commessa era assegnata
    //         const oldTeamLeader = this.teamLeaders.find(tl =>
    //             tl.projects.some(p => p.id === draggedProject.id)
    //         );

    //         if (oldTeamLeader) {
    //             console.log(`❌ Rimuovo la pratica ${draggedProject.id} da ${oldTeamLeader.name}`);

    //             // 🔥 Rimuovi la commessa dal vecchio team leader
    //             this.consDubaiService.deleteConsuntivazioneDubaiAssociazionePraticaOperatore(
    //                 this.authService.idToken(),
    //                 this.authService.user().email,
    //                 draggedProject.id,   // ID della pratica da rimuovere
    //                 oldTeamLeader.Id // ID del capo squadra precedente
    //             ).subscribe(response => {
    //                 console.log(`✅ Pratica ${draggedProject.id} rimossa con successo da ${oldTeamLeader.name}`, response);
    //                 this.toastr.success('Assegnazione rimossa con successo!', 'Successo');

    //                 // 🔥 Aggiorna la lista delle commesse nel frontend
    //                 oldTeamLeader.projects = oldTeamLeader.projects.filter(p => p.id !== draggedProject.id);

    //                 // ✅ Aggiungi la commessa al nuovo team leader
    //                 this.addProjectToNewTeamLeader(newTeamLeader, draggedProject);
    //             }, error => {
    //                 console.error('❌ Errore nella rimozione della commessa:', error);
    //                 this.toastr.error('Errore nella rimozione della commessa', 'Errore');
    //             });
    //         } else {
    //             // Se non c'era un vecchio team leader, direttamente assegniamo il progetto
    //             this.addProjectToNewTeamLeader(newTeamLeader, draggedProject);
    //         }
    //     }
    // }
    drop(event: CdkDragDrop<Project[]>, newTeamLeader?: TeamLeader) {
        if (!event.previousContainer || !event.container || !newTeamLeader) return;

        const draggedProject: Project = event.previousContainer.data[event.previousIndex];

        // Verifica se il progetto è già assegnato a questo team leader
        const alreadyAssigned = newTeamLeader.projects.some(p => p.id === draggedProject.id);
        if (alreadyAssigned) {
            console.warn(`⚠️ La commessa ${draggedProject.id} è già assegnata a ${newTeamLeader.name}`);
            this.toastr.info('Questa commessa è già assegnata a questo capo cantiere.');
            return;
        }

        // Esegui l'assegnazione al nuovo team leader
        this.addProjectToNewTeamLeader(newTeamLeader, draggedProject);
    }


    /** ✅ Funzione per assegnare la pratica al nuovo capo squadra */
    addProjectToNewTeamLeader(newTeamLeader: TeamLeader, project: Project) {
        console.log(`➕ Aggiungo la pratica ${project.id} a ${newTeamLeader.name}`);
        const alreadyAssigned = newTeamLeader.projects.some(p => p.id === project.id);
        if (alreadyAssigned) {
            console.warn(`⚠️ La commessa ${project.id} è già assegnata a ${newTeamLeader.name}`);
            return; // ✅ evita duplicati nello stesso leader
        }
        this.consDubaiService.setConsuntivazioneDubaiAssociazionePraticaOperatore(
            this.authService.idToken(),
            this.authService.user().email,
            { practiceId: project.id, teamLeaderId: newTeamLeader.Id }
        ).subscribe(response => {
            console.log(`✅ Pratica ${project.id} assegnata con successo a ${newTeamLeader.name}`, response);
            this.toastr.success('Assegnazione salvata con successo!', 'Successo');

            // 🔥 Aggiorna la UI per riflettere la modifica
            newTeamLeader.projects.push(project);
            this.cdr.detectChanges(); // Forza aggiornamento UI
        }, error => {
            console.error('❌ Errore nel salvataggio:', error);
            this.toastr.error('Errore nell\'assegnazione della pratica', 'Errore');
        });
    }


    removeProject(teamLeader: TeamLeader, project: Project) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: { message: 'Sei sicuro di voler rimuovere questa commessa?' },
            scrollStrategy: this.scrollStrategies.reposition() // ✅ Usa ScrollStrategyOptions
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(`🗑️ Eliminazione della pratica ${project.id} dal capo squadra ${teamLeader.Id}`);

                this.consDubaiService.deleteConsuntivazioneDubaiAssociazionePraticaOperatore(
                    this.authService.idToken(),
                    this.authService.user().email,
                    project.id,
                    teamLeader.Id
                ).subscribe(response => {
                    console.log(`✅ Pratica ${project.id} rimossa con successo per il leader ${teamLeader.Id}`, response);
                    this.toastr.success('Assegnazione rimossa con successo!', 'Successo');

                    // 🔹 Rimuove la commessa localmente solo se la richiesta API ha successo
                    teamLeader.projects = teamLeader.projects.filter(p => p.id !== project.id);

                    this.hasChanges = true; // ✅ Attiva il pulsante "Salva"
                }, error => {
                    console.error('❌ Errore nella rimozione:', error);
                    this.toastr.error('Errore nella rimozione dell\'assegnazione', 'Errore');
                });
            }
        });
    }

    sanitizeValue(value: any, fallback: string = 'N/D'): string {
        return (value === null || value === undefined || value.trim() === '') ? fallback : value;
    }

    submitAssignments() {
        this.toastr.success('Le assegnazioni sono state salvate con successo!', 'Salvataggio Completato');
        this.hasChanges = false; // ✅ Dopo il salvataggio, il pulsante viene disabilitato
    }

}
