import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FinanceService } from '../../../services/finance.service'
import { UserSessionService } from '../../../services/user-session.service'
import { StockService } from '../../../services/stock.service'
import { LibService } from '../../../services/libService'
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SearchOrderService } from '../../providers/search-order.service';
import {
  Observable,
  Subscription,
  first,
  tap,
  switchMap,
  finalize,
} from 'rxjs';


@Component({
  selector: 'planner-search',
  templateUrl: './planner-search.component.html',
  styleUrls: ['./planner-search.component.scss'],
})
export class PlannerSearchComponent {
  currentOrder: any;
  searchForm: UntypedFormGroup;
  @Input() plannerType: any = 'core';

  listType: string;

  constructor(
    private userSessionService: UserSessionService,
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    private searchOrderService: SearchOrderService,
    private financeService: FinanceService,
    private libService: LibService,
    private stockService: StockService,
  ) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      code: [null, [Validators.required]],
    });

    this.searchOrderService.code$.subscribe((code) => {
      if(code !== "") {
        this.searchForm.controls['code'].setValue(code);
        this.search();
      }
    });
  }

  getCompany() {
    const company = this.userSessionService.getState('working_company');
    if (!(company == null) && !(company.name == '*')) {
      return company.id;
    } else return 0;
  }

  search() {
    let currentUser = this.userSessionService.getState('user');
    let code = this.searchForm.controls['code'].value;
    let companyId = this.getCompany();
    this.searchOrderService.search(currentUser.email, companyId, code, this.plannerType);
  }

  setStockElencoCall(company: any, stockId: any) {
    console.log('setStockElencoCall')
    let currentCall$: Observable<any>;
    switch (this.plannerType) {
      case 'energy':
        currentCall$ = this.financeService.getStockElencoEnergy(
          stockId
        );
        break;
        case 'energyOperators':
          currentCall$ = this.financeService.getStockElencoEnergy(
            stockId
          );
          break;
      case 'multi':
        currentCall$ = this.financeService.getStockElencoServices(
          company,
          stockId
        );
        break;
      default:
        currentCall$ = this.financeService.getStockElenco(
          company,
          stockId
        );
        break;
    }
    return currentCall$;
  }
}
