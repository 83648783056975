import { filter } from 'rxjs';

import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { ActivitiesService } from '../services/activities.service';
import { LibService } from '../services/libService';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
  @Input() subject: any;
  @Input() isInput: boolean;
  @Input() toDisable: boolean = false;

  @ViewChild(MatPaginator) set paginator(p: MatPaginator) {
    this.dataSource.paginator = p;
  };
  @ViewChild(MatSort) set sort (s: MatSort) {
    this.dataSource.sort = s;
  };
  dataSource: MatTableDataSource<any>;
  activity: any;
  kwSearch: any;
  displayedColumns = ['id', 'owner', 'activityDate', 'type','subject', 'notes', 'editAction'];


  constructor(
      private route: ActivatedRoute,
      private toastr: ToastrService,
      public translate: TranslatorService,
      public libService: LibService,
      public navService: NavService,
      public router: Router,
      private activitiesService: ActivitiesService,
      private authService: AuthService
    )
    {
      this.dataSource = new MatTableDataSource();
    }

  ngOnChanges(changes: SimpleChanges) {
    if (this.subject !== undefined) {
        this.loadDataSetsSubject();
      } else {
        // this.loadDataSets();
    }
  }

  ngOnInit(): void {
    // this.dataSource.filterPredicate = (data: any, filter: string) => data === filter || data.subject?.name === filter;
    if (this.subject) {
      this.loadDataSetsSubject();
    } else {
      if (this.isInput !== true) {
        this.loadDataSets();
      }

    }

    let actions : any;
    actions = [
      {name:'new',url:'activity/0'},
      {name:'export',url:''}
    ];
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  loadDataSets(): void {

    this.libService.lockPage('');
    this.activitiesService.getAllActivityByOwner(this.authService.user().email).subscribe((response) => {
      console.log('ACTIVITIES  response --> %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    },
    err => {
      this.libService.unlockPage();
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'),this.translate.instant('TOASTR.WARNING'));
    });
  }

  loadDataSetsSubject(): void {

    this.activitiesService.getActivityBySubject(this.subject).subscribe({
      next: (acts:any) => {
      if (acts.length > 0) {
        this.dataSource.data = acts;
        this.dataSource.data.sort((a: any, b: any) => a.activityDate.localeCompare(b.activityDate))
      } else {
        for (const message in acts.Messages) {
          this.libService.showMessageError(message);
        }
      }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  deleteRow(e: any) {

    const id = e.id;
      if (id && parseInt(id) > 0) {
        console.log("ID: " + id);
        this.activitiesService.deleteActivity(id, this.activity).subscribe((response: any) => {
          this.libService.unlockPage();
          this.toastr.success(this.translate.instant('LABEL.ROW_DELETED'), this.translate.instant('TOASTR.INFO'));
          console.log(response);
          window.location.reload();
        },
        err => {
          console.log(err);
          this.libService.unlockPage();
          this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
        });
      }
      else {
        this.toastr.success(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.INFO'));
      }
  }

  goTo(e: any) {
    this.router.navigate(['activity/' + e.id]);
  }

  search(e: any) {
    // console.log('e %o', e);
    // console.log(this.dataSource);
    // if (e) {
    //   // this.dataSource.filterPredicate = (data: any, filter: string) => data === filter || data.subject?.name === filter;
    //   this.dataSource.filter = e.trim().toLowerCase();
    //   this.dataSource.data.filter(ds => ds === e || ds.subject.name === e)
    // }
    this.dataSource.filterPredicate = (data, filter: string)  => {
      const accumulator = (currentTerm: any, key: string) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      // Transform the filter by converting it to lowercase and removing whitespace.
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.dataSource.filter = e.trim().toLowerCase();
  }

  createActivity() {
    console.log("SOGGETTO CORRENTE --> ", this.subject);
    this.activitiesService.setSubjectData(this.subject);
    this.router.navigate(['activity/0']);

  }

  nestedFilterCheck(search: any, data: any, key: string) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

}
