<div class="container-fluid my-container">
  <!-- Titolo della pagina -->
  <h1 class="page-title">Verifica Check-in</h1>

  <!-- Paragrafo descrittivo -->
  <div class="page-description">
    <!-- Paragrafo descrittivo -->
    <div class="page-description">
      <p>
        Questa pagina consente di controllare i check-in effettuati dagli operatori di edilizia acrobatica, garantendo
        l'integrità e la validità dei dati registrati in <strong>BlockChain</strong>. Utilizza i filtri di ricerca per
        trovare rapidamente i risultati
        desiderati.
        I filtri vengono applicati al clic sul bottone <strong>"Cerca"</strong>.
        Premendo il tasto <strong>"Verifica"</strong>, puoi controllare l'autenticità dei dati registrati nei check-in
        effettuati dagli operatori.
        Cliccando sull' <strong>icona dell'elmetto</strong>, si apre un modale che mostra i DPI (Dispositivi di
        Protezione Individuale)
        associati al check-in selezionato.
      </p>
    </div>

  </div>
  <div class="filter-container">
    <div class="filter-grid">
      <!-- Filtro per Country -->
      <mat-form-field appearance="outline">
        <mat-label>{{ 'LABEL.COUNTRY' | translate }}</mat-label>
        <mat-select [formControl]="countryControl">
          <mat-option *ngFor="let country of countries; trackBy: trackById" [value]="country.id">
            {{ country.name }}
          </mat-option>
          <mat-option [value]="null">Tutti</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Society Filter -->
      <mat-form-field appearance="outline">
        <mat-label>Seleziona Società</mat-label>
        <mat-select [formControl]="societyControl" multiple>
          <input matInput class="search-input" placeholder="Cerca società..." [formControl]="societySearchControl">
          <mat-option *ngFor="let society of filteredSocieties; trackBy: trackById" [value]="society.id">
            {{ society.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Company Filter -->
      <mat-form-field appearance="outline">
        <mat-label>Seleziona Sede</mat-label>
        <mat-select [formControl]="companyControl" multiple>
          <input matInput class="search-input" placeholder="Cerca sede..." [formControl]="companySearchControl">
          <mat-option *ngFor="let company of filteredCompanies; trackBy: trackById" [value]="company.id">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- User Filter -->
      <kendo-multiselect #multiselect [formControl]="userControl" [data]="filteredUsers" [filterable]="true"
        textField="name" valueField="id" placeholder="Cerca utente..." (filterChange)="onFilterChange($event)">
      </kendo-multiselect>

      <!-- Filtro per Data -->
      <mat-form-field appearance="outline">
        <mat-label>Seleziona Data Check-in </mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Seleziona Data" [formControl]="startDateControl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="filter-buttons">
      <!-- Bottone di ricerca -->
      <button mat-raised-button class="search-filter-button" (click)="onSearchClick()">
        <fa-icon [icon]="'search'" class="search-icon"></fa-icon>
        {{ 'LABEL.SEARCH' | translate }}
      </button>

      <!-- Bottone per resettare i filtri -->
      <button mat-raised-button class="reset-filter-button" (click)="resetFilters()">
        <fa-icon [icon]="'sync-alt'" class="reset-icon"></fa-icon>
        Reset Filtri
      </button>
    </div>

  </div>

  <!-- Tabella -->
  <div style="overflow-x: auto;">
    <table mat-table [dataSource]="dataSource" matSort style="width:100%;" (matSortChange)="updateUserData()">
      <!-- Colonna ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">ID Check-in</th>
        <td class="tdg" mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <!-- Nome Utente -->
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">Operatore</th>
        <td class="tdg" mat-cell *matCellDef="let element">{{ element.userName }}</td>
      </ng-container>

      <!-- Data Check-in -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">Data Check-in</th>
        <td class="tdg" mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
      </ng-container>

      <!-- Colonna Check-in Valido -->
      <ng-container matColumnDef="isValidCheckin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">Check-in Valido/Anomalia </th>
        <td class="tdg" mat-cell *matCellDef="let element">
          <fa-icon *ngIf="element.isValidCheckin; else notValid" [icon]="faCheckCircle" class="valid-icon"></fa-icon>
          <ng-template #notValid>
            <fa-icon [icon]="faTimesCircle" class="invalid-icon"></fa-icon>
          </ng-template>
        </td>
      </ng-container>


      <!-- Pulsante Verifica -->
      <ng-container matColumnDef="verify">
        <th mat-header-cell *matHeaderCellDef class="back-table centered">Verifica Block Chain</th>
        <td class="tdgf" mat-cell *matCellDef="let element">
          <button class="custom-button" (click)="verifyCheckin(element)">Verifica</button>
        </td>
      </ng-container>

      <!-- Pulsante Visualizza DPI -->
      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef class="back-table centered">DPI</th>
        <td class="tdgf" mat-cell *matCellDef="let element">
          <button class="custom-button" (click)="openDpiDetailsModal(element)">
            <fa-icon [icon]="faHardHat" class="icon-dpi"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [length]="totalRows" [pageIndex]="currentPage" (page)="pageChanged($event)" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>