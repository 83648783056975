import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlannerService } from './providers/planner.service';
import { Subscription } from 'rxjs';
import { UserSessionService } from '../services/user-session.service';
import { SidebarService } from '../services/sidebar.service';
import { PlannerFullPageService } from '../services/plannerFullPage.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
})
export class PlannerComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  private subRoute: Subscription;
  selectedVenue: any = false;
  planningFullPage = false;
  plannerType: string = this.route.snapshot.url[0]
    ? this.route.snapshot.url[0].path
    : 'core';

  public get dataFetched(): boolean {
    return this.ps.dataFetched;
  }

  constructor(
    private ps: PlannerService,
    private userSessionService: UserSessionService,
    private sidebarService: SidebarService,
    private plannerFullPageService: PlannerFullPageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    let currentCompany = this.getCompany();
    //console.log(currentCompany)
    this.setPageVisibility(currentCompany);

    this.sub = this.userSessionService.$changeCompanyDetector.subscribe(
      (res) => {
        window.location.reload();
      }
    );

    this.subRoute = this.route.params.subscribe((v: any) => {
      if ('plannerType' in v) this.plannerType = v.plannerType;
    });
  }

  setPlanningFullPage() {
    this.planningFullPage =
      this.plannerFullPageService.getPlannerFullPageValue();
  }

  setPageVisibility(company: any) {
    if (['energy', 'energyOperators'].includes(this.plannerType)) {
      this.selectedVenue = true
    } else {
      this.selectedVenue = company != 0 ? true : false;
    }

  }

  getCompany() {
    const company = this.userSessionService.getState('working_company');
    if (!(company == null) && !(company.name == '*')) {
      return company.id;
    } else return 0;
  }

  ngOnDestroy(): void {
    !this.sub.closed && this.sub.unsubscribe();
    this.subRoute.unsubscribe();
  }

  setPlanningFullScreen(event: any) {
    this.planningFullPage = event;
    this.sidebarService.setCollapsed(event);
    this.plannerFullPageService.setPlannerFullPage(event);
  }
}
