import { MatSelect } from '@angular/material/select';
import { BranchService } from 'src/app/services/branch.service';
import { CategoryService } from 'src/app/services/category.service';
import { WorkTypeService } from './../../services/worktype.service';
import { MacroPhaseService } from './../../services/macrophase.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { codeCharacter } from 'src/app/validators/code.validator';
import { ToastrService } from 'ngx-toastr';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-macrophase-detail',
  templateUrl: './macrophase-detail.component.html',
  styleUrls: ['./macrophase-detail.component.scss']
})
export class MacrophaseDetailComponent implements OnInit {

  isNew: boolean;
  id: any;
  target: any;
  target$: Observable<any>;
  formTargets: UntypedFormGroup;
  countryOptions = ['IT', 'ES', 'FR', 'MC']
  langOptions = ['IT', 'ES', 'FR']
  allCausals: any;
  causals: any;
  allCategories: any;
  categories: any;
  @ViewChild('categorySelection') categorySelection: MatSelect
  nullOption: any

  /** Tree */
  cachedBranches: any;
  branch: any

  constructor(private fb: UntypedFormBuilder,
    private macroPhaseService: MacroPhaseService,
    private branchService: BranchService,
    private categoryService: CategoryService,
    private causalService: WorkTypeService,
    private route: ActivatedRoute,
    private libService: LibService,
    public translate: TranslatorService,
    public navService: NavService,
    private toastService: ToastrService,
    private router: Router) {
    this.causalService.getAllWorkTypes().subscribe(workTypes => {
      this.nullOption = {
        id: -1,
        workType: 'NULL'
      }
      this.translate.instant('WORKTYPES.null', {})
      this.allCausals = [this.nullOption]
      this.allCausals.push(...workTypes)
      this.causals = this.allCausals
    })
    this.categoryService.getAllCategories().subscribe(categories => {
      this.allCategories=categories
      this.categories=this.allCategories
    })
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if(this.id != 0) this.target$ = this.macroPhaseService.getMacroPhase(this.id);
    });
    this.formTargets = this.fb.group({
      category: [null],
      id: [null],
      code: [null, [Validators.required, codeCharacter()]],
      name: [null, Validators.required],
      codCountry: [null, Validators.required],
      codLanguage: [null, Validators.required],
      isActive: [null],
      isPercAmmaloramento: [null],
      isFreeDatas: [null],
      deadLine: [null],
      isMultiService: [null],
      position: [null],
      idWorkType: [null]
    })

    this.branchService.getAllBranches().subscribe((branches: any) => {
      this.cachedBranches = branches
    })
  }

  countryChanged(selected: MatOptionSelectionChange) {
    this.formTargets.controls['idWorkType'].enable()
    if(selected.isUserInput){
      const filtered = this.allCausals.filter((c: any) => c.codCountry == selected.source.value)
      this.causals = [this.nullOption]
      this.causals.push(...filtered)
      const selectedWorkType = this.allCausals.find((f: any) => this.formTargets.controls['idWorkType'].value == f.id)
      if(selectedWorkType != undefined && selectedWorkType.codCountry != selected.source.value){
        this.causals.push(selectedWorkType)
      }
    }
  }

  onChangeFilterCategories(event: KeyboardEvent) {
    const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
    this.categories = this.allCategories.filter((mp: any) => mp.name.toLowerCase().startsWith(searchStr))
  }

  onOpenSearch(searchElement: HTMLInputElement) {
    searchElement.value = '';
    searchElement.focus();
  }

  onCloseSearch(){
    this.categories = this.allCategories;
  }

  update() {
    const formObject = this.formTargets.getRawValue()
    const category = this.allCategories.find((m: any) => m.id == formObject.category)
    const toSend = {
      macroPhase: {...formObject},
      category: {...category}
    }
    delete toSend.macroPhase.category
    delete toSend.category.CategoriesMacroPhasesRel
    if(toSend.macroPhase.idWorkType == -1){
      toSend.macroPhase.idWorkType = null
    }
    if(toSend.macroPhase.id == null){
      // Creation
      delete toSend.macroPhase.id
      let createdID = 0
      this.macroPhaseService.createMacroPhase(toSend).subscribe({
        next(success){
          console.log(success)
          createdID=success?.MacroPhase?.id
        },
        error: (err) => {
          console.error(err)
          this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
        complete: () => {
          console.log('Done')
          this.toastService.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigateByUrl(`genconfig`).then(console.log).catch(console.error)
        }
      })
    }else{
      // Update
      this.macroPhaseService.updateMacroPhase(toSend.macroPhase).subscribe({
        next(success){
          console.log(success)
        },
        error: (err) => {
          console.error(err)
          this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
        },
        complete: () => {
          console.log('Done')
          this.toastService.success(
            this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
        }
      })
    }
  }

  updateTree(){
    const selection = this.categorySelection.value
    const category = this.allCategories.find((m: any) => m.id == selection)
    if(category == undefined) return
    const branch = this.cachedBranches.find((b: any) => b.BranchesCategoriesRel.find((bc: any) => bc.id == category.id))
    this.branch = branch
  }

  ngOnInit(): void {
    this.initNavbar();
    console.log('Macrophase ID: ', this.id)
    if (this.id != 0) {
      this.libService.lockPage('');
      this.target$.subscribe(val => {
        this.formTargets.controls['category'].disable()
        this.formTargets.controls['code'].disable()
        this.formTargets.controls['name'].disable()
        this.formTargets.controls['codCountry'].disable()
        this.formTargets.controls['codLanguage'].disable()
        this.target = val;
        console.log("val -->", val);
        this.formTargets.patchValue(this.target);
        this.formTargets.controls['category'].setValue(val.MacroPhasesPhasesRel[0]?.id)
        this.formTargets.controls['category'].setValue(
          this.allCategories.filter((b: any) => b.CategoriesMacroPhasesRel.find((c: any) => c.id == val.id) != undefined)[0]?.id
        )
        const filtered = this.allCausals?.filter((c: any) => c.codCountry == val.codCountry)
        this.causals = [this.nullOption]
        this.causals.push(...filtered)
        const selectedWorkType = this.allCausals.find((f: any) => this.formTargets.controls['idWorkType'].value == f.id)
        if(selectedWorkType != undefined && selectedWorkType.codCountry != val.codCountry){
          this.causals.push(selectedWorkType)
        }
        this.libService.unlockPage();
        this.updateTree()
      }, () => this.libService.unlockPage())
    } else {
      this.formTargets.controls['idWorkType'].disable()
    }
  }

  initNavbar(){
    setTimeout(() => {
      let actions: any;
      actions = [
        { name: 'back', url: 'genconfig' }
      ];

      let title = this.formTargets.get('id')?.value !== null?
        this.translate.instant('LABEL.EDIT', { field: 'Macrofase' }):
        this.translate.instant('LABEL.NEW', { field: 'Macrofase' })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }
}
