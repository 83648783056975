import { Injectable } from '@angular/core';
import { catchError, first, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FTSearchResponse } from '../models/FTSearchResponse';
import { Subject } from '../models/subject';
import { SubjectTypes } from '../models/subject-types';
import { LibService } from './libService';
import { LogService } from './log.service';
import { UserSessionService } from './user-session.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  baseApi = environment.rootApiCrm + 'v1.0';
  foundationApi = environment.rootApiFoundation;
  name = 'SubjectService';
  model = 'subjects'

  constructor(public libService: LibService,
    public userSessionService: UserSessionService,
    private logService: LogService,
    private authService: AuthService,
  ) { }

  getAttributes(): Observable<any[]> {
    return this.libService.postData(null, `${this.baseApi}/subject/attributes`, '')
  }

  getSubject(id: string) {
    return this.libService.getData<any>(this.baseApi, `/subject/${id.toString()}`);
  }

  getSubjectWithAccessoryAttributes(id: string) {
    return this.libService.getData<any>(this.baseApi, `/subject/${id.toString()}/withAccessoryAttributes`);
  }

  getCond(id: string) {
    return this.libService.getData<any>(this.baseApi, `/condominium/${id.toString()}`);
  }

  getAllSubjects(userEmail: string): Observable<any> {
    // let subjects = this.userSessionService.getState('getAllSubjects');
    // if (subjects !== null && subjects !== undefined) {
    //   return of(subjects);
    // } else {
    return this.libService.getData<any>(this.baseApi, '/subjects/all/' + userEmail)//.pipe(
    //     tap(subjects => {
    //       this.userSessionService.saveState('getAllSubjects', subjects);
    //     })
    //   );
    // }

  }

  updateSubject(id: string, subject: any) {
    console.log("SERVIZIO FRONT END --> ", subject)
    return this.libService.postData<any>(subject, this.baseApi, `/subject/${id.toString()}`).pipe(
      tap(response => {
        this.logService.writeLog('POST', this.baseApi + `/subject/${id.toString()}`, subject, 'OK')
      }),
      catchError(error => {
        this.logService.writeLog('POST', this.baseApi + `/subject/${id.toString()}`, subject, error.status)
        return of(error);
      })
    );
  }

  updateSubjectWithAccessoryAttributes(id: string, subject: any) {
    console.log("SERVIZIO FRONT END --> ", subject)
    return this.libService.postData<any>(subject, this.baseApi, `/subject/${id.toString()}/withAccessoryAttributes`).pipe(
      tap(response => {
        this.logService.writeLog('POST', this.baseApi + `/subject/${id.toString()}/withAccessoryAttributes`, subject, 'OK')
      }),
      catchError(error => {
        this.logService.writeLog('POST', this.baseApi + `/subject/${id.toString()}/withAccessoryAttributes`, subject, error.status)
        return of(error);
      })
    );
  }

  createSubject(subject: any) {
    return this.libService.postData<any>(subject, this.baseApi, '/subject').pipe(
      tap(response => {
        this.logService.writeLog('POST', this.baseApi + '/subject', subject, 'OK')
      }),
      catchError(error => {
        this.logService.writeLog('POST', this.baseApi + '/subject', subject, error.status)
        return of(error);
      })
    );
  }

  createSubjectWithAccessoryAttributes(subject: any) {
    return this.libService.postData<any>(subject, this.baseApi, '/subject/withAccessoryAttributes').pipe(
      tap(response => {
        this.logService.writeLog('POST', this.baseApi + '/subject/withAccessoryAttributes', subject, 'OK')
      }),
      catchError(error => {
        this.logService.writeLog('POST', this.baseApi + '/subject/withAccessoryAttributes', subject, error.status)
        return of(error);
      })
    );
  }

  create(subject: any) {
    return this.libService.postData<any>(subject, this.baseApi, '/subject');
  }

  createWithAccessoryAttributes(subject: any) {
    return this.libService.postData<any>(subject, this.baseApi, '/subject/withAccessoryAttributes');
  }

  getObjSubject(subject: any) {
    console.log("RICHIESTA SERVIZIO FRONTEND -->", subject)
    return this.libService.postData<any>(subject, this.baseApi, '/subjectobj');
  }

  getFTSearchResult(keyword: any) {
    // console.log("getFTSearchResult - KEYWORD SERVICE FRONT --> ", keyword)

    return this.libService.postData<any>(keyword, this.baseApi, '/subjects/search');
  }

  getSubjectIdsFTSearch(keyword: any) {
    console.log("getSubjectIdsFTSearch - KEYWORD SERVICE FRONT --> ", keyword)
    return this.libService.postData<FTSearchResponse>(keyword, this.baseApi, '/subject/subjectsIndexsearch');
  }

  findSubjectFromRequestForm(subject: any) {
    return this.libService.postData<any>(subject, this.baseApi, '/subjectrequest');
  }

  getAllTypes(): Observable<SubjectTypes[]> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, '/subjectTypes');
  }

  deleteSubject() {

  }

  getById(ids: any) {
    // console.log("id Subject da cercare nel DB --> ", ids)
    let uniqueIds = [...new Set(ids)];
    if (uniqueIds) {
      return this.libService.postData<any>(uniqueIds, this.baseApi, `/subjectById`);
    } else {
      return '';
    }
  }

  find(options: { key: string, values: string[] }[]) {
    return this.libService.postData<Subject[]>(options, this.baseApi, '/subjectsFind')
  }

  checkVisibility(id: string, userEmail: string) {
    console.log('check visibily for %o %o', id, userEmail)
    return this.libService.postData<boolean>({ id: id, userEmail: userEmail }, this.baseApi, '/subjectCheckVisibility')
  }

  findSubjectByPhoneAndMail(subject: any) {
    subject?.telephone2?.trim() === "" ? subject.telephone2 = null : null;
    subject?.email?.trim() === "" ? subject.email = null : null;
    console.log("[subject Frontend Service]", subject);
    return this.libService.postData<Subject>(subject, this.baseApi, '/subjectByPhoneAndMail');
  }

  getTypesByCompanyId(companyId: number): Observable<any> {
    let tokenId = this.authService.idToken()
    return this.libService
      .getDataWithAccessToken(
        tokenId,
        this.foundationApi,
        `subjectTypeVisibilities/getSubjectTypesByCompany/${companyId.toString()}`
      )
      .pipe(first());
  }

  getSubjectFormStructure(
    subjectTypeId: number,
    companyId: number | null = null
  ): Observable<any> {
    let tokenId = this.authService.idToken()
    let url = `subjectTypeVisibilities/getStructure/${subjectTypeId.toString()}`
    if (companyId) url += `?companyId=${companyId}`
    return this.libService
      .getDataWithAccessToken(
        tokenId,
        this.foundationApi,
        url
      )
      .pipe(first());
  }

  allWithPagination(filterOptions: any = [], orderOptions: any = [], skip: number = 0, take: number = 10) {
    console.log("allWithPagination")
    let tokenId = this.authService.idToken()
    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption
    }
    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `/subjects/allWithPagination`)
  }

  getRelations(id: number) {
    console.log("getRelations")
    let tokenId = this.authService.idToken()

    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `/${this.model}/${id}/getRelations`)
  }

  getAssociatedConventions(id: number) {
    console.log("getAssociatedConventions")
    let tokenId = this.authService.idToken()

    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `/${this.model}/${id}/getAssociatedConventions`)
  }
  
  getBuildings(id: number) {
    console.log("getBuildings")
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `/subjects/${id}/getBuildings`)
  }

  getContacts(id: number) {
    console.log("getContacts")
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `/subjects/${id}/getContacts`)
  }

  checkDuplicateContact(id: number, value: string, contactTypeId: number) {
    console.log("checkDuplicateContact")
    let tokenId = this.authService.idToken()
    let attributes = {
      value: value,
      contactTypeId: contactTypeId
    }
    return this.libService.postDataWithAccessToken<any>(tokenId, attributes, this.baseApi, `/subjects/${id}/checkDuplicateContact`)
  }

}
