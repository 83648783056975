<form [formGroup]="form" *ngIf="form && checkComponent()">

  <mat-card>
    <mat-card-title>
      {{ 'LABEL.ORDER' | translate }} / {{ 'LABEL.REQUEST' | translate }}
      <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;" (click)="clearForm('order')"
        [disabled]="getDisabled()">
        <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
      </button>
    </mat-card-title>

    <mat-tab-group class="" animationDuration="300ms" #tabGroup [selectedIndex]="selectedTab"
      (selectedTabChange)="onTabChanged($event)">
      <mat-tab [disabled]="getDisabled()">
        <!-- INIZIO TAB PRATICA ---------------------------------------------- -->
        <ng-template mat-tab-label>
          <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
          <span class="ml-1">{{ 'LABEL.ORDER' | translate }}</span>
        </ng-template>

        <br>


      
        <form [formGroup]="formSelect">
          <div class="form-subject" appearance="fill">
            <app-select-search formControlName="listaOrdini" [items]="orders"
              [label]="translate.instant('LABEL.ORDERS')"
              [noUsers]="!(orders.length > 0) || getDisabled()"
              viewField="codePratica"></app-select-search>
          </div>
        </form>
      

        <div formGroupName="order" class="form-container">
          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.ORDER' | translate }}</mat-label>
            <input matInput formControlName="code">
          </mat-form-field>
          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.STATUS' | translate }} {{ 'LABEL.ORDER' | translate }}</mat-label>
            <mat-select formControlName="statusDescription" [compareWith]="compareFn">
              <mat-option *ngFor="let status of statuses" [value]="status">
                {{ 'LABEL.' + status.descx | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('order.nameAdminCondominius')?.value !== null && form.get('order.nameAdminCondominius')?.value !== undefined && form.get('order.nameAdminCondominius')?.value !== '')"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.ADMIN' | translate }}</mat-label>
            <input matInput formControlName="nameAdminCondominius">
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('order.nameBuilding')?.value !== '' && form.get('order.nameBuilding')?.value !== null && form.get('order.nameBuilding')?.value !== undefined)"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.BUILDING' | translate }}</mat-label>
            <input matInput formControlName="nameBuilding" readonly>
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('order.nameCondominius')?.value !== '' && form.get('order.nameCondominius')?.value !== null && form.get('order.nameCondominius')?.value !== undefined)"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.CONDOMINIUM' | translate }}</mat-label>
            <input matInput formControlName="nameCondominius" readonly>
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('order.nameSubject')?.value !== '' && form.get('order.nameSubject')?.value !== null && form.get('order.nameSubject')?.value !== undefined)"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'ACTIVITIES.SUBJ' | translate }}</mat-label>
            <input matInput formControlName="nameSubject" readonly>
          </mat-form-field>
        </div>
        <!-- FINE TAB PRATICA ---------------------------------------------------------- -->
      </mat-tab>

      <mat-tab [disabled]="getDisabled()">
        <!-- INIZIO TAB RICHIESTA ------------------------------------------------------------------------ -->
        <ng-template mat-tab-label>
          <mat-icon class="my-bounce material-icons">report</mat-icon>
          <span class="ml-1">{{ 'LABEL.REQUEST' | translate }}</span>
        </ng-template>

        <br>
        <mat-form-field class="form-subject">
          <mat-label>{{ 'LABEL.REQUESTS' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedRequest" [compareWith]="compareFn" [ngModelOptions]="{standalone: true}"
            [disabled]="!(requests?.length > 0) || getDisabled()"
            (ngModelChange)="selRequest($event)">
            <mat-option *ngFor="let request of requests" [value]="request">
              {{ (request?.subject?.name !== null && request?.subject?.name !== undefined? request.subject.name: '') +
              (request?.building?.name !== null && request?.building?.name !== undefined? " - " + request.building.name:
              ' ') + (request.assignmentOperator !== null? " - " + request.assignmentOperator: '') }} <b>{{
                'LABEL.REQUEST' | translate }}</b>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div formGroupName="solicitRequest" class="form-container">
          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.REQUEST' | translate }}</mat-label>
            <input matInput formControlName="id">
          </mat-form-field>
          <mat-form-field *ngIf="(form.get('solicitRequest.assignmentOperator')?.value !== null)" appearance="fill"
            class="form-subject">
            <mat-label>{{ 'LABEL.responsible' | translate }}</mat-label>
            <input matInput formControlName="assignmentOperator">
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('solicitRequest.nameBuilding')?.value !== '' && form.get('solicitRequest.nameBuilding')?.value !== null && form.get('solicitRequest.nameBuilding')?.value !== undefined)"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.BUILDING' | translate }}</mat-label>
            <input matInput formControlName="nameBuilding" readonly>
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('solicitRequest.nameSubject')?.value !== '' && form.get('solicitRequest.nameSubject')?.value !== null && form.get('solicitRequest.nameSubject')?.value !== undefined)"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'ACTIVITIES.SUBJ' | translate }}</mat-label>
            <input matInput formControlName="nameSubject" readonly>
          </mat-form-field>

        </div>
        <!-- FINE TAB RICHIESTA ------------------------------------------------------------ -->
      </mat-tab>


    </mat-tab-group>
    <div>

    </div>
  </mat-card>
</form>