<div class="full-w" fxLayout="row" fxLayoutAlign="center center" *ngIf="form">
  <form [formGroup]="form">

    <mat-card>
      <mat-card-title>
        {{ 'LABEL.SUBJ' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;"
          (click)="clearForm('subject')">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>
      <div>
        <div formGroupName="subject" class="form-container">
          <app-fulltextsearch2 class="form-subject" [service]="subjectService" searchType="Soggetto"
            (selected)="selSubject($event)"></app-fulltextsearch2>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.SUBJ_TYPE' | translate }}</mat-label>
            <mat-select matNativeControl name="type" formControlName="type" [disabled]="form.get('id')?.value !== null">
              <mat-option *ngFor="let type of types" [value]="type.description">
                {{type.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="hideLegalEntity" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <mat-form-field *ngIf="hideLegalEntity" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
            <input matInput formControlName="surname">
          </mat-form-field>

          <mat-form-field *ngIf="!(hideLegalEntity)" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.MOBILE' | translate }}:</mat-label>
            <input matInput type="phone" formControlName="telephone2"
              [placeholder]="translate.instant('LABEL.INSERISCI_NUMERO_TELEFONO_MOBILE')" readonly>
            <mat-icon matSuffix>smartphone</mat-icon>
            <mat-hint>{{ 'REQUEST.MOBILE_ERROR' | translate }}</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
            <input matInput type="email" formControlName="email"
              [placeholder]="translate.instant('LABEL.INSERISCI_EMAIL')" readonly>
            <mat-icon matSuffix>mail_outline</mat-icon>

          </mat-form-field>

        </div>
      </div>
    </mat-card>
    <mat-card>
      <mat-card-title>
        {{ 'LABEL.BUILDING' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;"
          (click)="clearForm('building')">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>
      <div>
        <div formGroupName="building" class="form-container">
          <app-fulltextsearch2 class="form-subject" [service]="buildingService" searchType="Edificio"
            (selected)="selSubject($event)"></app-fulltextsearch2>

          <app-address-detail-form [form]="buildingForm" class="form-subject"></app-address-detail-form>
        </div>
      </div>
    </mat-card>

    <mat-card>
      <mat-card-title>
        {{ 'LABEL.ORDER' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;"
          (click)="clearForm('order')">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>
      <div>
        <!-- <app-fulltextsearch2 class="form-subject" [searchCleaner]="clean" [events]="eventSearch" [filterIDS]="filterIDS" [service]="orderService" searchType="Order" (selected)="selOrder($event)"></app-fulltextsearch2> -->
        <!-- <app-fulltextsearch2 class="form-subject" [searchCleaner]="clean" [events]="eventSearch" [filterIDS]="filterIDS" [service]="orderService" searchType="Order" (selected)="selOrder($event)"></app-fulltextsearch2>  -->
        <form [formGroup]="formSelect">
          <div class="form-subject" appearance="fill">
            <app-select-search formControlName="listaOrdini" [items]="orders"
              [label]="translate.instant('LABEL.ORDERS')"
              [noUsers]="!(orders.length > 0) || (form.get('id')?.value !== null && form.get('id')?.value !== undefined)"
              viewField="code"></app-select-search>
          </div>
        </form>
        <div formGroupName="order" class="form-container">
          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.ORDER' | translate }}</mat-label>
            <input matInput formControlName="code" readonly>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.STATUS' | translate }} {{ 'LABEL.ORDER' | translate }}</mat-label>
            <mat-select formControlName="statusDescription">
              <mat-option *ngFor="let stateCategory of stateCategories" [value]="stateCategory.id">
                {{ 'STATECATEGORIES.'+ stateCategory?.code | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="(form.get('order.nameAdminCondominius')?.value !== null && form.get('order.nameAdminCondominius')?.value !== undefined && form.get('order.nameAdminCondominius')?.value !== '')"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.ADMIN' | translate }}</mat-label>
            <input matInput formControlName="nameAdminCondominius" readonly>
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('order.nameBuilding')?.value !== '' && form.get('order.nameBuilding')?.value !== null && form.get('order.nameBuilding')?.value !== undefined)"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.BUILDING' | translate }}</mat-label>
            <input matInput formControlName="nameBuilding" readonly>
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('order.nameCondominius')?.value !== '' && form.get('order.nameCondominius')?.value !== null && form.get('order.nameCondominius')?.value !== undefined)"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'LABEL.CONDOMINIUM' | translate }}</mat-label>
            <input matInput formControlName="nameCondominius" readonly>
          </mat-form-field>
          <mat-form-field
            *ngIf="(form.get('order.nameSubject')?.value !== '' && form.get('order.nameSubject')?.value !== null && form.get('order.nameSubject')?.value !== undefined)"
            appearance="fill" class="form-subject">
            <mat-label>{{ 'ACTIVITIES.SUBJ' | translate }}</mat-label>
            <input matInput formControlName="nameSubject" readonly>
          </mat-form-field>
        </div>
      </div>
    </mat-card>

    <mat-card>
      <mat-card-title>
        {{ 'REQUEST.OTHER' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;"
          (click)="clearForm('subject')">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>

      <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'REQUEST.COMPLAINT_CATEGORY' | translate }}</mat-label>
        <mat-select formControlName="complaintTypeSection" (selectionChange)="updatecomplaintTypeSection($event)">
          <mat-option *ngFor="let type of complaintTypeSections" [value]="type">
            {{ getCategoryLabel(type) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'REQUEST.COMPLAINT_REASON' | translate }}</mat-label>
        <mat-select formControlName="complaintType">
          <mat-option *ngFor="let type of complaintTypesFiltered" [value]="type">
            {{type.uiLabel | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </mat-card>
  </form>
</div>