import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibService } from '../../../services/libService';
import { SubjectService } from 'src/app/services/subject.service';
import { ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from 'src/app/services/translate.service';
import { SubjectRelatedToPracticeTypologiesService } from 'src/app/services/subjectRelatedToPracticeTypologies.service';

import { Subject } from 'src/app/models/subject';



@Component({
  selector: 'app-subject-to-practice-modal',
  templateUrl: './subject-to-practice-modal.component.html',
  styleUrls: ['./subject-to-practice-modal.component.scss']
})
export class SubjectToPracticeModal implements OnInit {

  @ViewChild('selectSubjects') selectSubjects: any;
  confirmButtonLabel: any = 'LABEL.ADD'
  cancelButtonLabel: any = 'LABEL.CANCEL'
  form: FormGroup
  searchResults: any[] = [];
  subjectRelatedToPracticeTypologies: any = [];


  subjectAttributes: any = {

    "searchAdress": [
      {
        "required": false
      },
      {
        "editable": true
      }
    ],
    "company": [
      {
        "required": true
      },
      {
        "editable": true
      }
    ],
    "showAdress": [
      {
        "required": false
      },
      {
        "editable": true
      }
    ]
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private libService: LibService,
    private subjectService: SubjectService,
    private toastr: ToastrService,
    private translate: TranslatorService,
    private subjectRelatedToPracticeTypologiesService: SubjectRelatedToPracticeTypologiesService,
  ) {

    this.form = this.fb.group({
      type: ['subjects', Validators.required],
      kwSearchName: [null, []],
      kwSearchSurname: [null, []],
      kwSearchEmail: [null, []],
      kwSearchLegalEntity: [null, []],
      subjectRelatedToPracticeTypologyId: [null, Validators.required],
      subject: this.generateSubjectFormGroup(),
      subjectId: [null, Validators.required],
      note: [null, []],
    })

  }

  getSubjectRelatedToPracticeTypologies() {
    this.subjectRelatedToPracticeTypologiesService.index().subscribe((response: any) => {
      this.subjectRelatedToPracticeTypologies = response;
    });
  }

  generateSubjectFormGroup(): FormGroup {
    const subjectFormGroup: any = {};

    const subject = new Subject();
    for (const key in subject) {
      if (subject.hasOwnProperty(key)) {
        subjectFormGroup[key] = [null, []];
      }
    }
      subjectFormGroup['company'] = [null, [Validators.required]];
    return this.fb.group(subjectFormGroup);
  }

  openSelectSubjects() {
    this.selectSubjects.open();
  }

  addSubject() {
    console.log(this.form.get('subject'))
    let subject = Object.fromEntries(
      Object.entries(this.form?.get('subject')?.getRawValue() || {}).filter(([_, value]) => value != null)
    );
    this.libService.lockPage('');
    this.subjectService.findSubjectByPhoneAndMail(subject).subscribe((response: any) => {
      console.log("risposta duplicate soggetto --> ", response);
      if (response == null) {

        this.subjectService.createSubject(subject).subscribe((res: any) => {
          console.log("risposta soggetto creato --> ", res);
          this.toastr.success(this.translate.instant('LABEL.CreatoConSuccesso'), this.translate.instant('LABEL.Info'));
          let newSubject = res?.body
          this.searchResults = [newSubject]
          this.form.get('subjectId')?.patchValue(newSubject.id)
          this.libService.unlockPage();
        });

      } else {
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE25') + `<br> ${response.businessName ? response.businessName : ''} ${response.name ? response.name : ''} ${response.surname ? response.surname : ''}`, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
        this.libService.unlockPage();
      }


    })

  }

  getType() {
    let type = this.form.get('type')?.value;
    return type;
  }

  isSubjectType() {
    let type = this.form.get('type')?.value;
    return type == 'subjects';
  }

  isAddSubjectType() {
    let type = this.form.get('type')?.value;
    return type == 'addSubjects';
  }

  setSubject(event: any) {
    let id = event.value
    let currentItem = this.searchResults.find((item) => item.id == id)
    console.log(currentItem)
    if (currentItem) this.form.get('subject')?.patchValue(currentItem)
  }

  getFullname(item: any) {
    let type = this.form.get('type')?.value;
    let fullname = item.name
    if (item.email) fullname += ` (${item.email})`
    if (type == 'subjects') {
      fullname = `${item.name} ${item.surname}`
      if (item.legalEntity) fullname = item.legalEntity
      if (item.type) fullname += ` - ${item.type}`
      if (item.email) fullname += ` (${item.email})`
      fullname += ` - ${item?.streetName} ${item?.streetNumber}, ${item?.zip} ${item?.city} (${item?.province}) - ${item?.country}`
    }
    return fullname
  }

  search() {
    const name = this.form.get('kwSearchName')?.value;
    const surname = this.form.get('kwSearchSurname')?.value;
    const legalEntity = this.form.get('kwSearchLegalEntity')?.value;
    const email = this.form.get('kwSearchEmail')?.value;
    this.libService.lockPage('');
    let type = this.form.get('type')?.value;

    let service: any = this.subjectService

    let searchAttributes = {
      name: name,
      surname: surname,
      legalEntity: legalEntity,
      email: email
    }

    if (name || surname || legalEntity || email) {
      service.search(searchAttributes).subscribe((response: any) => {
        this.searchResults = response;
        this.openSelectSubjects()
        this.libService.unlockPage();
      });
    }

  }

  ngOnInit(): void {
    this.getSubjectRelatedToPracticeTypologies()
    this.form.get('type')?.valueChanges.subscribe((value) => {
      this.checkTypeChanges()
    });
  }

  checkTypeChanges() {
    this.form.get('kwSearchName')?.reset();
    this.form.get('kwSearchSurname')?.reset();
    this.form.get('kwSearchEmail')?.reset();
    this.form.get('kwSearchLegalEntity')?.reset();

    this.searchResults = [];
  }

  disabledConfirmButton() {
    let disabled = false
    if (!this.form.valid) disabled = true
    return disabled
  }

}
