import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PecService } from 'src/app/services/pec.service';
import { LibService } from 'src/app/services/libService';
import { SubjectService } from 'src/app/services/subject.service';


@Component({
  selector: 'app-pecs',
  templateUrl: './pecs.component.html',
  styleUrls: ['./pecs.component.scss']
})
export class PecsComponent implements OnInit {

  withFilter: boolean = true
  withSearchBar: boolean = true
  addButton: boolean = true
  viewMode: string = 'full'

  constructor(
    private libService: LibService,
    public subjectService: SubjectService,
    public translate: TranslateService,
  ) { }



  ngOnInit(): void { }
}
