<div class="container-fluid my-container">
  <div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
        [placeholder]="translate.instant('LABEL.SEARCH')" />
    </div>
  </div>
  <div style="overflow-x: auto;">
    <table mat-table [dataSource]="dataSource" matSort style="width:100%" (matSortChange)="updateCompaniesData()">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.ID' | translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element.idProfile}} </td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CODE' | translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.code}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="invoiceSystemCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.INVOICESYSTEMCODE' |
          translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span
            *ngIf="!(element.invoiceSystemCode == null)">{{element.invoiceSystemCode}}</span>
          <span *ngIf="(element.invoiceSystemCode == null)"><i class="material-icons icsave">warning</i></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="masterCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.MASTERCODE' |
          translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span
            *ngIf="!(element.masterCode == null)">{{element.masterCode}}</span>
          <span *ngIf="(element.masterCode == null)"><i class="material-icons icsave">warning</i></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.NAME' | translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.name}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="society">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.SOCIETY' | translate
          }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span
            *ngIf="!(element.CompaniesSocietiesRel == null) && element.CompaniesSocietiesRel?.length > 0">{{element.CompaniesSocietiesRel[0].name}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.TYPE' | translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.type}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="isOpen">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.OPEN' | translate }}
        </th>
        <td mat-cell *matCellDef="let element"><i *ngIf="element.isOpen == true" class="material-icons">checked</i>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.STATUS' | translate
          }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{ element.status ? ('STATUS.' + element.status | translate) : '' }}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="nation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.NATION' | translate
          }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.nation}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="provincie">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.PROVINCE' |
          translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.provincie}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.COMUNE' | translate
          }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.city}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="streetName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.ADDRESS' | translate
          }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.streetName}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="streetNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CIVIC' | translate
          }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.streetNumber}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="zip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CAP' | translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.zip}}</span>
        </td>
      </ng-container>
      <!--
      <ng-container matColumnDef="address_other_info">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Indirizzo (altre info)</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.address_other_info}}</b></span>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="lat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LATITUDE' |
          translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span class="tl">{{element.coordY}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="long">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LONGITUDE' |
          translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span class="tl">{{element.coordX}}</span>
        </td>
      </ng-container>
    -->
      <ng-container matColumnDef="vat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.VAT' | translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.vat}}</b></span>
        </td>
      </ng-container>
      <!--
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CREATION_DATE' |
          translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LASTMODIFIED_DATE' |
          translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
        </td>
      </ng-container>
    -->
      <ng-container matColumnDef="manageSoc">
        <th mat-header-cell *matHeaderCellDef></th>
        <td matTooltip="{{ 'TOOLTIP.SEDI.SOCIETA' | translate }}" mat-cell *matCellDef="let element" matTooltipPosition="below"> 
          <button *ngIf="!(element.CompaniesSocietiesRel?.length > 0)" (click)="setActiveCompany(element)" type="button"
            class="btn" style="background-color:transparent;" data-bs-toggle="modal" 
            data-bs-target="#manageSocietyModal" (click)="selectAttributesSociety(element)"><span
              class="my-bounce material-icons ic">gavel</span></button>
          <button *ngIf="(element.CompaniesSocietiesRel?.length > 0)" (click)="setActiveCompany(element)" type="button"
            class="btn" style="background-color:transparent;" data-bs-toggle="modal"
            data-bs-target="#manageSocietyModal" (click)="selectAttributesSociety(element)"><span
              class="my-bounce material-icons icsave">gavel</span></button>
        </td>
      </ng-container>
      <ng-container matColumnDef="manageLicence">
        <th mat-header-cell *matHeaderCellDef></th>
        <td matTooltip="{{ 'TOOLTIP.SEDI.LICENZE' | translate }}" mat-cell *matCellDef="let element" matTooltipPosition="below">
          <button *ngIf="!(element.CompaniesLicencesRel?.length > 0)" 
            (click)="setActiveCompany(element)" 
            type="button"
            class="btn" 
            style="background-color:transparent;" 
            data-bs-toggle="modal"
            data-bs-target="#manageLicencesModal" 
            (click)="selectAttributesLicense(element)">
              <span class="my-bounce material-icons ic">fingerprint</span>
          </button>
          <button *ngIf="(element.CompaniesLicencesRel?.length > 0)" 
            (click)="setActiveCompany(element)" 
            type="button"
            class="btn" 
            style="background-color:transparent;" 
            data-bs-toggle="modal"
            data-bs-target="#manageLicencesModal" 
            (click)="selectAttributesLicense(element)">
            <span class="my-bounce material-icons icsave">fingerprint</span>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="editAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="goTo(element)"><i class="material-icons ic">edit</i>
      </ng-container>
      <ng-container matColumnDef="deleteAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="testlogin()"><i class="material-icons ic">delete</i>
      </ng-container>
      <ng-container matColumnDef="saveAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="tdgf" mat-cell *matCellDef="let element"><span *ngIf="noEdit(element) == true"
            class="my-bounce material-icons ic">save</span><span *ngIf="noEdit(element) == false"
            class="my-bounce material-icons icsave" (click)="saveCompany(element)">save</span>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" (page)="updateCompaniesData()">
  </mat-paginator>
</div>


<ng-template #societyDialog>
  <h1 mat-dialog-title>{{ 'LABEL.SOCIETY' | translate }}</h1>
  <div mat-dialog-content>
    <div style="width: 100%;">
      <ng-multiselect-dropdown [placeholder]="translate.instant('LABEL.SOCIETY')" [settings]="dropdownSocietySettings"
          [data]="dropdownListSocieties" [(ngModel)]="selectedSociety">
      </ng-multiselect-dropdown>
    </div>
  </div>
</ng-template>

<ng-template #licensesDialog>
  <div>
    <h1 mat-dialog-title>{{ 'LABEL.LICENCES' | translate }}</h1>
    <div mat-dialog-content>
      <div style="width: 100%;">
        <ng-multiselect-dropdown [placeholder]="translate.instant('LABEL.LICENCES')" [settings]="dropdownLicencesSettings"
            [data]="dropdownListLicences" [(ngModel)]="selectedLicences">
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>
</ng-template>
