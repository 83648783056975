import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-order-statuses',
  templateUrl: './order-statuses.component.html',
  styleUrls: ['./order-statuses.component.scss']
})
export class OrderStatusesComponent implements OnInit {

  displayedColumns = ['id', 'code','descx','isConsuntivable','isManagedByAdmin','isManagedByStaff','isManagedByUser','isPlanneable','step'];
  dataSource: any;
  kwSearch: any;
 
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;


  constructor(private orderService: OrderService, public libService: LibService, private router: Router) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.loadStatuses();
  }

  
  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  loadStatuses(): void {
    
    this.libService.lockPage('');
    this.orderService.getOrderStatuses().subscribe((response) => {
      console.log('loadStatuses response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
    
  }

}


