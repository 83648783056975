<!--
    <div class="container-fluid my-container">
   

        <router-outlet></router-outlet>

   

</div>
-->


<div class="container-fluid my-container">
    <mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="tabChanged($event)"
        [selectedIndex]="selectedTabIndex">
        <!-- SUBJECTS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
                <span class="ml-1">{{ 'LABEL.SUBJECTS' | translate }}</span>
            </ng-template>

            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <!-- <app-request-list></app-request-list> -->
                <app-subject-section *ngIf="selectedTabIndex === 0"></app-subject-section>
            </div>
        </mat-tab>

        <!-- BUILDINGS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
                <span class="ml-1">{{ 'LABEL.BUILDINGS' | translate }}</span>
            </ng-template>

            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <!-- <app-request-list></app-request-list> -->
                <app-building *ngIf="selectedTabIndex === 1"></app-building>
            </div>
        </mat-tab>

        <!-- REQUESTS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
                <span class="ml-1">{{ 'LABEL.REQUESTS' | translate }}</span>
            </ng-template>

            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <app-request-list-new *ngIf="selectedTabIndex === 2"></app-request-list-new>
            </div>
        </mat-tab>

        <!-- ACTIVITIES -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">settings</mat-icon>
                <span class="ml-1">{{ 'LABEL.ACTIVITIES' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

                <app-activities *ngIf="selectedTabIndex === 3"></app-activities>

            </div>
        </mat-tab>

        <!-- SEGNALAZIONI -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="my-bounce material-icons">report</mat-icon>
                <span class="ml-1">{{ 'LABEL.SEGNALAZIONI' | translate }}</span>
            </ng-template>
            <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                <app-rapport-section *ngIf="selectedTabIndex === 4" [selectedTabIndex]="selectedSubTabIndex" [selectedSubTabIndex]="selectedSubSubTabIndex" [itemId]="itemId"></app-rapport-section>
            </div>
        </mat-tab>

    </mat-tab-group>

</div>