import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { LibService } from '../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UserSessionService } from '../../services/user-session.service';
import { NavService } from '../../services/nav.service';
import { TranslatorService } from '../../services/translate.service';
import { MatPaginator } from '@angular/material/paginator';
import { ActionService } from '../../services/action.service'
import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';
import { MarketingNoteService } from '../../services/marketingNote.service'
import { ConventionService } from '../../services/convention.service'
import { SubjectSubjectsService } from '../../services/subject-subjects.service'
import * as moment from 'moment';


@Component({
  selector: 'app-conventions-subject-approvals',
  templateUrl: './conventions-subject-approvals.component.html',
  styleUrls: ['./conventions-subject-approvals.component.scss']
})

export class ConventionsSubjectApprovalsComponent implements OnInit, OnDestroy {

  itemType = "conventions"
  sectionName = "conventions"
  dataSource: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  sort: MatSort

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('itemDialog') itemDialog: TemplateRef<any>
  @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  searchTimeout: any;

  marketingNoteList: any = [];

  constructor(public navService: NavService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    public translate: TranslatorService,
    private matDialog: MatDialog,
    private actionService: ActionService,
    private conventionService: ConventionService,
    private subjectSubjectsService: SubjectSubjectsService,
  ) {
    this.dataSource = new MatTableDataSource();
  }


  displayedColumns = ['id', 'subjectFrom', 'subjectTo', 'createdAt'];

  structure: any = {
    id: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
    name: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
  }



  columns: any = [
    {
      name: 'id',
      flex: '6',
      title: this.getTitle('id'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item?.id
    },
    {
      name: "subjectFrom",
      title: this.getTitle('label'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.getSubjectName(item?.subjectFrom)
    },
    {
      name: "subjectTo",
      title: this.getTitle('subjectTo'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.getSubjectName(item?.subjectTo)
    },
    {
      name: "createdAt",
      title: this.getTitle('createdAt'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.dateStringFormat(item?.createdAt)
    },
  ]

  
  dateStringFormat(date: any){
    return moment(date).format("DD-MM-YYYY")
  }

  getSubjectName(item: any){
    let subJectName = ``
    if(item?.name) subJectName += ` ${item?.name}`
    if(item?.surname) subJectName += ` ${item?.surname}`
    if(item?.legalEntity) subJectName += ` ${item?.legalEntity}`
    if(item?.type) subJectName += ` ${item?.type}`
    return subJectName

  }

  getTitle(type: any) {
    let title: any = this.translate.get('LABEL.NOT_PRESENT')
    switch (type) {
      case 'id':
        title = this.translate.get('LABEL.ID')
        break;
      case 'label':
        title = this.translate.get('LABEL.name')
        break;
      case 'startDate':
        title = this.translate.get('LABEL.START_DATE')
        break;
      case 'endDate':
        title = this.translate.get('LABEL.END_DATE')
        break;
      case 'subject':
        title = this.translate.get('LABEL.SUBJ')
        break;
      case 'createdAt':
        title = this.translate.get('LABEL.requestDate')
        break;
      case 'subjectTo':
        title = this.translate.get('LABEL.subjectToConvention')
        break;
    }
    return title
  }


  getBooleanLabel(value: any) {
    if ([null, undefined].includes(value)) {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    } else {
      return value ? this.translate.instant('LABEL.YES') : this.translate.instant('LABEL.NO') as string
    }
  }



  actions: any = [];


  setUserActions() {

    // approval
    this.actions.push({
      label: of(''),
      icon: (item: any) => 'check',
      cssClass: (item: any) => ['greenButton'],
      onClick: (item: any) => this.approvalProcess(item, true),
      color: 'green'
    })

    // rejection
    this.actions.push({
      label: of(''),
      icon: (item: any) => 'close',
      cssClass: (item: any) => ['redButton'],
      onClick: (item: any) => this.approvalProcess(item, false),
      color: 'red'
    })


    // DESTROY
    if (this.actionService.checkPermissions(`${this.itemType}_destroy`)) {
      this.actions.push({
        label: of(''),
        icon: (item: any) => {
          return 'delete';
        },
        onClick: (item: any) => this.deleteItem(item),
        color: 'danger'
      })
    }

  }

  approvalProcess(item:any, status:boolean){
    this.libService.lockPage('');
    this.subjectSubjectsService.approvalProcess(item.id, status).subscribe((responseData: any) => {
      console.log('approvalProcess response %o', responseData);
      this.libService.unlockPage();
      this.loadData()
    });

  }

  iconRoutine(subject: any) {
    return 'edit';
  }

  deleteItem(item: any) {

    if (confirm("Sei sicuro di voler cancellare l'Action?") == true) {
      this.libService.lockPage('');

      this.actionService.destroy(item.id).subscribe((responseData: any) => {
        console.log('Destroy Item response %o', responseData);
        this.loadData()
        this.libService.unlockPage();
      }, err => {
        console.log(err)
        this.libService.unlockPage();
      });
    }

  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }



  openItemDialog(item: any) {
    //this.mapsSocietyState(e);
    const dialogRef = this.matDialog.open(this.itemDialog, {
      panelClass: 'custom-dialog-container',
      width: '60%'
    })
  }


  ngOnInit(): void {

    this.loadData();
    this.setUserActions();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      { name: 'new', url: 'actions/0' },
      //{ name: 'export', url: '' },
    ];
    this.navService.setActions(actions);

    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();

    clearTimeout(this.searchTimeout)
    // this.searchTimeout = setTimeout(() => this.updateCompaniesData(), 1000)
  }



  loadData(): void {

    this.libService.lockPage('');

    this.subjectSubjectsService.conventionRequestsPending().subscribe((responseData: any) => {
      console.log('loadDataSets response %o', responseData);
      this.libService.unlockPage();
      this.dataSource = responseData;
    }, err => {
      console.log(err)
    });

  }

  goToPage(item: any) {
    this.router.navigate([`conventions/` + item.id]);
  }

  saveForm(attributes: any) {
    console.log(attributes)
  }

  filter(event: any) {
    console.log(event)
    let actions = this.marketingNoteList.filter((action: any) => action.TranslationCategoryId == event.value)
    this.dataSource = actions
  }


}
