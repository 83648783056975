import { Component, EventEmitter, Input, OnChanges, AfterViewInit, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-component-card-box',
  templateUrl: './component-card-box.component.html',
  styleUrls: ['./component-card-box.component.scss']
})
export class ComponentCardBoxComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() index: number = 0;
  @Input() component: any;
 

  constructor(private translate: TranslateService) { }

  
  get components(): FormArray {
    return <FormArray> this.form.get('components');
  }

  ngOnInit(): void {

  };

  showCategory(id: any) {
    let componentIds: any = this.form?.get('componentIds')?.value || []
    return componentIds.includes(id)
  }

}
