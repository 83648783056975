<!-- COMPONENTE LINK PRACTICE -->
<app-request-link-practice [components]="components" [id]="requestId" *ngIf="form && components" [form]="form"
  [isNew]="isNew" [edit]="edit"></app-request-link-practice>

<div style="margin-top: 30px">

  <!-- COMPONENTE CONFIGURAZIONE WORKFLOW -->
  <app-workflow-configurator [id]="requestId" *ngIf="form && components" [form]="form" [types]="(types$ | async)"
    [origins]="origins$ | async" [isNew]="isNew" [edit]="edit"></app-workflow-configurator>



  <div *ngIf="contactReason != null" [ngSwitch]="contactReason?.id">
    <div *ngSwitchCase="1">

      <div *ngIf="!isNew">

        <!-- COMPONENTE FORM REQUEST -->
        <app-request-form [components]="components" *ngIf="form && components" [form]="form" [types]="(types$ | async)"
          [origins]="origins$ | async" [isNew]="isNew" [edit]="edit"></app-request-form>

        <!-- COMPONENTE ASSOCIAZIONE EDIFICIO -->
        <div *ngIf="newSection; else noNewSectionBuilding">
          <app-association-building-v2 [components]="components" *ngIf="form && components" [form]="form"
            [types]="(types$ | async)" [origins]="origins$ | async" [isNew]="isNew"
            [edit]="edit"></app-association-building-v2>
        </div>
        <ng-template #noNewSectionBuilding>
        <app-association-building [components]="components" *ngIf="form && components" [form]="form"
          [types]="(types$ | async)" [origins]="origins$ | async" [isNew]="isNew"
          [edit]="edit"></app-association-building>
        </ng-template>


        <!-- COMPONENTE ASSOCIAZIONE SOGGETTO -->
        <div *ngIf="newSection; else noNewSectionSubject">
          <app-association-subject-v2 [components]="components" *ngIf="form && components" [form]="form"
            [types]="(types$ | async)" [origins]="origins$ | async" [isNew]="isNew"
            [edit]="edit"></app-association-subject-v2>
        </div>
        <ng-template #noNewSectionSubject>
        <app-association-subject [components]="components" *ngIf="form && components" [form]="form"
          [types]="(types$ | async)" [origins]="origins$ | async" [isNew]="isNew"
          [edit]="edit"></app-association-subject>
        </ng-template>

      </div>


    </div>
    <div *ngSwitchCase="2">
      <app-preventive-solicit *ngIf="form" [isNew]="isNew" [form]="form" [types]="(types$ | async)"
        [origins]="origins$ | async"></app-preventive-solicit>
    </div>

    <div *ngSwitchCase="3">
      <app-complaint *ngIf="form" [practiceCode]="practiceCode" [form]="form" [types]="(types$ | async)"
        [origins]="origins$ | async"></app-complaint>
    </div>


    <div *ngSwitchDefault>output2</div>
  </div>

</div>