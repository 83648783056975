
<div class="container-fluid my-container">
  <div class="buttons-right">
    <button style="background-color:transparent;" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="save()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>
  </div>
  <form [formGroup]="form">
    <div style="padding-top: 12px">
      <div>
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Current language</mat-label>
          <mat-select [formControl]="currentLanguageControl">
            <mat-option *ngFor="let lang of languages" [value]="lang">{{'Language.'+lang | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div [formGroup]="form" fxLayout="column">
        <div formArrayName="title">
          <div fxLayout="column" *ngFor="let control of titleFormArray.controls; let i = index" [formGroupName]="i">
            <mat-form-field appearance="outline" fxFlex *ngIf="control.value.lang == currentLang">
              <mat-label>{{'Settings.OutgoingEmail.EmailTitle' | translate}}</mat-label>
              <input matInput formControlName="text">
            </mat-form-field>
          </div>
        </div>
        <div formArrayName="text">
          <div fxLayout="column" *ngFor="let control of textFormArray.controls; let i = index" [formGroupName]="i">
            <div [ngxSummernote]="summernoteConfig" formControlName="text" *ngIf="control.value.lang == currentLang"></div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
