import { Component, OnInit, ElementRef, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { TranslatorService } from 'src/app/services/translate.service';
import { MarketingNoteService } from '../../../services/marketingNote.service'
import { EventService } from '../../../services/event.service'
import { UserSessionService } from 'src/app/services/user-session.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import * as QRCode from 'qrcode';
import { UserDataService } from 'src/app/services/users.service';


@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventFormComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() eventTypes: any = [];
  @ViewChild('qrCodeCanvas') qrCodeCanvas!: ElementRef;
  eventUrl: string = ''; // Inizializzato vuoto
  tokenEvent: string = ''; // Memorizza il token

  sectionName = "events"

  id: any;

  marketingNote: any = null;
  event: any = null;

  translationList: any = []

  internalToggle: boolean; // Toggle "utenti interni"
  externalToggle: boolean; // Toggle "utenti esterni"

  hasLoadedUsers: boolean = false;
  hasLoadedExternalUsers: boolean = false;

  internalUsersForm!: FormGroup;
  externalUsersForm!: FormGroup;

  internalUserSearchControl = new FormControl('');
  externalUserSearchControl = new FormControl('');

  // Liste di utenti interni ed esterni
  users: any[] = [];
  // Lista degli utenti esterni
  externalUsers: any[] = [];

  filteredInternalUsers: any[] = [];
  filteredExternalUsers: any[] = [];
  selectedInternalUsers: any[] = [];
  selectedExternalUsers: any[] = [];

  languageCodeList: any = [
    {
      label: 'Italiano',
      code: 'IT'
    },
    {
      label: 'Français',
      code: 'FR'
    },
    {
      label: 'Español',
      code: 'ES'
    },
    {
      label: 'English',
      code: 'EN'
    },
  ]

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public translate: TranslatorService,
    private router: Router,
    private marketingNoteService: MarketingNoteService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    private userSessionService: UserSessionService, // Servizio per ottenere la lista utenti
    private userService: UserDataService
  ) { }


  addTranslation(values: any): FormGroup {

    let translation: FormGroup = this.fb.group(values);

    return translation

  }


  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }


  get translations(): FormArray {
    return <FormArray>this.form.get('translations');
  }

  update() {
    this.libService.lockPage('');
    console.log(this.form.getRawValue())

    let structure = this.form.getRawValue()

    let startDate = new Date(structure.startDate)
    let endDate = new Date(structure.endDate)
    let startTimeSplit = structure.startTime.split(':')
    let endTimeSplit = structure.endTime.split(':')

    let startDateVisibility = new Date(structure.startDateVisibility)
    let endDateVisibility = new Date(structure.endDateVisibility)
    let startTimeVisibilitySplit = structure.startTimeVisibility.split(':')
    let endTimeVisibilitySplit = structure.endTimeVisibility.split(':')

    let startDateRegistrations = new Date(structure.startDateRegistrations)
    let endDateRegistrations = new Date(structure.endDateRegistrations)
    let startTimeRegistrationsSplit = structure.startTimeRegistrations.split(':')
    let endDateRegistrationsSplit = structure.endTimeRegistrations.split(':')


    let startDateMoment = moment(startDate).utc(true).hours(startTimeSplit[0]).minutes(startTimeSplit[1]).seconds(0).milliseconds(0)
    let endDateMoment = moment(endDate).utc(true).hours(endTimeSplit[0]).minutes(endTimeSplit[1]).seconds(0).milliseconds(0)

    let startDateVisibilityMoment = moment(startDateVisibility).utc(true).hours(startTimeVisibilitySplit[0]).minutes(startTimeVisibilitySplit[1]).seconds(0).milliseconds(0)
    let endDateVisibilityMoment = moment(endDateVisibility).utc(true).hours(endTimeVisibilitySplit[0]).minutes(endTimeVisibilitySplit[1]).seconds(0).milliseconds(0)

    let startDateRegistrationsMoment = moment(startDateRegistrations).utc(true).hours(startTimeRegistrationsSplit[0]).minutes(startTimeRegistrationsSplit[1]).seconds(0).milliseconds(0)
    let endDateRegistrationsMoment = moment(endDateRegistrations).utc(true).hours(endDateRegistrationsSplit[0]).minutes(endDateRegistrationsSplit[1]).seconds(0).milliseconds(0)

    structure.startDate = startDateMoment.format()
    structure.endDate = endDateMoment.format()

    structure.startDateVisibility = startDateVisibilityMoment.format()
    structure.endDateVisibility = endDateVisibilityMoment.format()

    structure.startDateRegistrations = startDateRegistrationsMoment.format()
    structure.endDateRegistrations = endDateRegistrationsMoment.format()

    structure.tokenEvent = this.tokenEvent; // Aggiunge il tokenEvent nel payload

    // Aggiungere i campi degli utenti selezionati all'oggetto da inviare
    structure.invitedInternalUsers = this.form.get('invitedInternalUsers')?.value || [];
    structure.invitedExternalUsers = this.form.get('invitedExternalUsers')?.value || [];

    // Se i toggle "Tutti possono partecipare" sono attivi, svuota le liste
    if (this.form.value.everyUserCanParticipate) {
      structure.invitedInternalUsers = [];
    }

    if (this.form.value.everyExternalUserCanParticipate) {
      structure.invitedExternalUsers = [];
    }
    structure.tokenEvent = this.tokenEvent; // Aggiunge il tokenEvent nel payload


    console.log(structure)

    this.eventService.createSettings(structure).subscribe((response: any) => {
      console.log(response)

      this.toastr.success(
        'ok', this.translate.instant('LABEL.Info'));

      this.id = response.id
      this.form?.get('id')?.setValue(this.id)
      this.router.navigate([`/events/`, this.id]).then(() => {
        window.location.reload();
      });

      this.libService.unlockPage();
    })
      


  }

  back() {
    this.router.navigate([`events/`]);
  }

  initTranslations() {

    this.languageCodeList.forEach((lang: any) => {
      let currentTranslation = this.translationList.find((translation: any) => translation.languageCode == lang.code)
      let structure: any = {
        id: [null],
        languageCode: [lang.code, Validators.required],
        text: [null, Validators.required],
      }

      if (currentTranslation?.id) structure.id = currentTranslation?.id
      if (currentTranslation?.languageCode) structure.languageCode = currentTranslation?.languageCode
      if (currentTranslation?.text) structure.text = currentTranslation?.text

      this.translations.push(this.addTranslation(structure))
    })
  }

  ngOnInit(): void {
    this.getUsers();
    this.getExternalUsers();
    this.libService.lockPage('');
    this.id = this.route.snapshot.paramMap.get('id');

    // Creazione Form
    this.form = this.fb.group({
      id: [],
      name: [null, Validators.required],
      description: [null, []],
      everyUserCanParticipate: [true],
      everyExternalUserCanParticipate: [true],
      startDate: [false, Validators.required],
      startTime: ["00:00", Validators.required],
      endDate: [false, Validators.required],
      endTime: ["23:59", Validators.required],
      startDateVisibility: [false, Validators.required],
      startTimeVisibility: ["00:00", Validators.required],
      endDateVisibility: [false, Validators.required],
      endTimeVisibility: ["23:59", Validators.required],
      startTimeRegistrations: ["00:00", Validators.required],
      startDateRegistrations: [false, Validators.required],
      endDateRegistrations: [false, Validators.required],
      endTimeRegistrations: ["23:59", Validators.required],
      EventTypeId: [null, Validators.required],
      invitedInternalUsers: [[]], // Aggiunto campo per gli utenti interni
      invitedExternalUsers: [[]], // Aggiunto campo per gli utenti esterni
    })

    if (['0', 0, null, undefined].includes(this.id)) {
      this.internalToggle = true;  // Imposta toggle interni disabilitati per i nuovi eventi
      this.externalToggle = true;  // Imposta toggle esterni disabilitati per i nuovi eventi
      this.libService.unlockPage();
    } else {
      this.initForm()
    }

    // Inizializzo i controlli di ricerca
    this.internalUserSearchControl.valueChanges.subscribe((searchText: any) => {
      this.filteredInternalUsers = this.users.filter((user: any) =>
        user.name.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    this.externalUserSearchControl.valueChanges.subscribe((searchText: any) => {
      this.filteredExternalUsers = this.externalUsers.filter((user: any) =>
        `${user.name} ${user.surname}`.toLowerCase().includes(searchText.toLowerCase())
      );
    });

  }

  generateEventUrl() {
    this.eventUrl = `${environment.aappUrl}/login?tokenEvent=${this.tokenEvent}`;
    this.cdr.detectChanges(); // ✅ Forza Angular a rilevare la modifica
  }


  copyLink(inputElement: HTMLInputElement) {
    navigator.clipboard.writeText(inputElement.value);
    this.toastr.success('Link copiato!', 'Successo');
  }


  ngAfterViewInit() {
    if (['0', 0, null, undefined].includes(this.id)) {
      this.tokenEvent = uuidv4();
      this.generateEventUrl();
      this.generateQrCode();
    }
  }


  generateQrCode() {
    QRCode.toCanvas(this.qrCodeCanvas.nativeElement, this.eventUrl, {
      width: 150,
      color: {
        dark: "#293683",  // Blu scuro per i moduli QR
        light: "#F7EA48"  // Giallo per lo sfondo
      }
    }, (error) => {
      if (error) console.error(error);
    });
  }

  downloadQrCode() {
    const canvas = this.qrCodeCanvas.nativeElement as HTMLCanvasElement;
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = 'qr-code_Event.png';
    link.click();
  }

  // Gestione toggle e caricamento utenti
  onToggleChange(type: 'internal' | 'external', isChecked: boolean): void {
    if (type === 'internal') {
      this.internalToggle = isChecked;
    } else if (type === 'external') {
      this.externalToggle = isChecked;
    }

    // Carica gli utenti solo se non già caricati
    if (!this.hasLoadedUsers && this.internalToggle) {
      this.getUsers(); // Carica la lista utenti interni
    }
    if (!this.hasLoadedExternalUsers && this.externalToggle) {
      this.getExternalUsers(); // Carica la lista utenti esterni
    }
  }



  getUsers(): void {
    if (this.hasLoadedUsers) return; // Evita chiamate ripetute
    this.userSessionService.getVisibilities().subscribe(
      (userdata) => {
        const idSediCurrentUser = userdata.UsersCompaniesRel.map((company: any) => company.id);
        this.userSessionService.getNamesBySedeAppartenenzaIds(idSediCurrentUser).subscribe(
          (operatorNames: any) => {
            this.users = operatorNames;
            this.filteredInternalUsers = [...this.users]; // Popola la lista iniziale
            this.hasLoadedUsers = true; // Imposta a true dopo aver caricato
          },
          (err) => console.error('Errore nel recupero dei nomi:', err)
        );
      },
      (err) => console.error('Errore nel recupero delle visibilità:', err)
    );
  }


  // Funzione per ottenere utenti esterni
  getExternalUsers(): void {
    if (this.hasLoadedExternalUsers) return; // Evita chiamate ripetute
    this.userService.getExternalUsers().subscribe(
      (users: any[]) => {
        this.externalUsers = users;
        this.filteredExternalUsers = users; // Inizializzo la lista filtrata
        console.log('Utenti esterni caricati:', users);
        this.hasLoadedExternalUsers = true; // Imposta a true dopo aver caricato
      },
      (error) => {
        console.error('Errore durante il caricamento degli utenti esterni:', error);
        this.toastr.error('Errore nel caricamento degli utenti esterni.');
      }
    );
  }


  initForm() {
    this.eventService.settings(this.id).subscribe((response: any) => {
      if (response) {
        this.event = response;

        moment.locale('en');
        this.tokenEvent = response.tokenEvent; 
        this.generateEventUrl(); // ✅ Ora il link è generato correttamente con il valore aggiornato
        this.generateQrCode(); // Aggiorna il QR Code con il nuovo link


        let startDate = new Date(this.event.startDate);
        let endDate = new Date(this.event.endDate);
        let startDateMoment = moment(startDate).utc();
        let endDateMoment = moment(endDate).utc();

        let startDateVisibility = new Date(this.event.startDateVisibility);
        let endDateVisibility = new Date(this.event.endDateVisibility);
        let startDateVisibilityMoment = moment(startDateVisibility).utc();
        let endDateVisibilityMoment = moment(endDateVisibility).utc();

        let startDateRegistrations = new Date(this.event.startDateRegistrations);
        let endDateRegistrations = new Date(this.event.endDateRegistrations);
        let startDateRegistrationsMoment = moment(startDateRegistrations).utc();
        let endDateRegistrationsMoment = moment(endDateRegistrations).utc();

        // Imposta i valori nel form
        this.form.patchValue({
          ...response,
          startTime: startDateMoment.format('HH:mm'),
          endTime: endDateMoment.format('HH:mm'),
          startTimeVisibility: startDateVisibilityMoment.format('HH:mm'),
          endTimeVisibility: endDateVisibilityMoment.format('HH:mm'),
          startTimeRegistrations: startDateRegistrationsMoment.format('HH:mm'),
          endTimeRegistrations: endDateRegistrationsMoment.format('HH:mm'),
          EventTypeId: response?.eventType?.id,
          invitedInternalUsers: response.userInvited?.map((user: any) => user.UserId) || [],
          invitedExternalUsers: response.externalUserInvited?.map((user: any) => user.ExternalUserId) || [],
        });

        this.internalToggle = response.everyUserCanParticipate;
        this.externalToggle = response.everyExternalUserCanParticipate;

        this.libService.unlockPage();
      } else {
        this.libService.unlockPage();
      }
    });
  }


  setCode() {
    if ([undefined, null, 0, '0'].includes(this.id)) {
      let label = this.form?.get('label')?.value
      let code = `marketing_notes_${this.snakeCase(label)}`
      this.form?.get('code')?.setValue(code)
    }
  }

  snakeCase(string: any) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word: any) => word.toLowerCase())
      .join('_');
  };

}