import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import { combineLatest, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Company } from 'src/app/models/company';
import { Society } from 'src/app/models/society';
import { User } from 'src/app/models/user';
import { BuildingService } from 'src/app/services/building.service';
import { CompanyService } from 'src/app/services/companyService';
import { CondominiumService } from 'src/app/services/condominium.service';
import { LibService } from 'src/app/services/libService';
import { RequestService } from 'src/app/services/request.service';
import { SocietyService } from 'src/app/services/societyService';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { UserDataService } from 'src/app/services/users.service';

@Component({
  selector: 'app-solicit-filter',
  templateUrl: './solicit-filter.component.html',
  styleUrls: ['./solicit-filter.component.scss']
})
export class SolicitFilterComponent implements OnInit {

  @Input() type: string;
  @Output() filterResult = new EventEmitter;

  societies: Society[] = [];
  responsibles: string[] = [];
  companies: Company[] = [];
  years: string[] = [];
  statuses: any;
  noUsers: boolean = true;

  form: UntypedFormGroup;

  /* companies$ = this.companyService.getAllCompanies().pipe(
    map((companies: Company[]) => {
      return companies.sort((a, b) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      companies.map(company => {
        if(company.CompaniesSocietiesRel !== undefined){
          this.societies.push(...company.CompaniesSocietiesRel)
        }
        if(company.CompaniesUsersRel !== undefined){
          this.responsibles.push(...company.CompaniesUsersRel!.map(user => user.email!))
        }
      })
      this.societies = this.unique(this.societies, ['code'] )
      this.responsibles = [...new Set(this.responsibles)]
      this.companies = companies
    })
  ); */

  logUsr$ = this.sessionService.getUser(this.authService.user().email)
  // companies$ = this.companyService.getAllCompaniesFast().pipe(
  //   tap(companies => {
  //     this.companies = companies
  //   })
  // )

  // societies$ = this.societyservice.getAllSocieties().pipe(
  //   tap(societies => {
  //     this.societies = societies
  //   })
  // )

  responsibles$ = this.userService.getAllUsersFast().pipe(
    tap(users => {
      users = users.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
      this.responsibles = users.map((user: User) => user.email)
    })
  )

  status$ = this.statusService.getAll("Request").pipe(
    tap(status => {
      this.statuses = status.filter((s: any) => s.type === 'solicit');
      if (this.statuses !== null && this.statuses !== undefined)
        this.statuses.sort((a : any , b: any) => this.translate.instant('LABEL.' + a.uiLabel!) <= this.translate.instant('LABEL.' + b.uiLabel!) ? -1 : 1)
    })
  )

  constructor(
    private fb: UntypedFormBuilder,
    private companyService: CompanyService,
    private societyservice: SocietyService,
    private userService: UserDataService,
    private subjectService: SubjectService,
    private requestService: RequestService,
    private buildingService: BuildingService,
    private condominiumService: CondominiumService,
    private toastr: ToastrService,
    private libService: LibService,
    private authService: AuthService,
    private statusService: StatusService,
    private sessionService: UserSessionService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.generateYears();
    this.form = this.fb.group({
      company: [[], Validators.required],
      companyLocation: [[], null],
      year: [[], /* Validators.required */],
      assignmentOperator: [[], /* Validators.required */],
      statusId: [[], null]
    })
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(form1 => {

      this.sessionService.saveState('RequestFilters', form1);
    })
    this.form.get('company')?.valueChanges.subscribe((current) => {
      console.log(current);
      this.noUsers = true;
      this.cdr.detectChanges();
      if (current.length > 0) {
        this.noUsers = false;
        this.cdr.detectChanges();
        this.userService.getByCompanyCode(current.map((a: any)=> a.code)).subscribe(users => {
          console.log("COMPANY CODES --> ", current);
          console.log("USERS BY COMPANY CODE --> ", users);
          users = users.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
          let pippo = users.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
          this.responsibles = pippo.sort((a : any , b: any) => a.email! <= b.email! ? -1 : 1);//.map((user: User) => user.email)
          // this.responsibles = users;
        })
      }
    })
    combineLatest([this.logUsr$, this.status$]).subscribe(([loggedUser, resps, stats]: any) => {
      let prevFilters = this.sessionService.getState('RequestFilters');
      this.companies = loggedUser[0].UsersCompaniesRel.sort((a : any , b: any) => a.code! <= b.code! ? -1 : 1);
      if (prevFilters?.company?.length > 0 || prevFilters?.year?.length > 0|| prevFilters?.assignmentOperator?.length > 0 || prevFilters?.statusId?.length > 0) {
        this.form.patchValue(prevFilters);
        this.filter();
      }
    })
  }

  filter(){
    console.log("FORM FILTER --> ", this.form.getRawValue());
    if (this.form.valid  === true) {
      let formValues = this.form.getRawValue();
      this.sessionService.saveState('RequestFilters', formValues);
      let options = Object.keys(formValues).map((key, index, array) => {
        let option: any
        //let values: any
        switch (key) {
          case 'company':
            let valuesCompany = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value['code']):
              [null]
            option = {
              key: key,
              values: valuesCompany
            }
            break;
          case 'year':
            let valuesCompanyLocation = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value):
              [null]
            option = {
              key: key,
              values: valuesCompanyLocation
            }
            break;
          case 'assignmentOperator':
            let valuesResponsible = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value):
              [null]
            option = {
              key: key,
              values: valuesResponsible
            }
            break;
          case 'statusId':
            let statuses = formValues[key]?.length !== undefined?
              formValues[key].map((value: any) => value['id']):
              [null]
            option = {
              key: key,
              values: statuses
            }

            break;

          default:
            break;
        }
        return option
      })
      console.log("OPTIONS FILTER --> ", options);
      options = options.filter(option => option?.values?.length > 0)
      this.libService.lockPage('')
      switch (this.type) {
        case 'Sollecito':
          this.requestService.findSolicitsByFilter(options).subscribe({
            next: (requests => {
              requests.sort((a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt))
              this.filterResult.next(requests);
              this.libService.unlockPage()
            }),
            error: (err => {
              this.toastr.error(err)
              this.libService.unlockPage()
            })
          })
          break;
      }
    } else {
      //this.toastr.warning(this.translate.instant("TOASTR.MESSAGE41"), this.translate.instant("TOASTR.WARNING"));
    }

  }

  clear(){
    this.form.reset({
      company: [],
      companyLocation: [],
      responsible: [],
      assignmentOperator: [],
      year: [],
      statusId: []
    })

    this.sessionService.saveState('RequestFilters', this.form.getRawValue());
    this.filterResult.next(null)
  }

  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
     const key = keyProps.map(k => entry[k]).join('|');
     return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
   }

   get companyControl(){
    return this.form.get('company') as UntypedFormControl
   }

   get companyLocationControl(){
    return this.form.get('company') as UntypedFormControl
   }

   get responsibleControl(){
    return this.form.get('company') as UntypedFormControl
   }

   private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      if (typeof obj[propName] === 'object') {
        obj[propName] = this.clean(obj[propName])
      }
    }
    return obj
  }

  generateYears() {

    const moment = require('moment');
    let yToPush = new Date().getFullYear();
    // let i = 1;
    // yToPush = yToPush + ;
    for(let i = 0; i <= 10; i++) {
      this.years.push(yToPush.toString());
      yToPush--;
    }

    console.log("years -->", this.years)
    // moment.locale('it'); // sets words language (optional if current locale is to be used)
    // moment.months() // returns a list of months in the current locale (January, February, etc.)
    // moment.months().forEach((element: any ) => {
    //   this.months.push({name: element, number: i});
    //   i++;
    // });

  }
}
