<!-- <div>
  <div class="list-group">
    <div class="list-group-item p-2" *ngFor="let team of teamsMapped">
      {{ team | json }}
    </div>
  </div>
</div>
 -->

<div class="container-fluid my-container" style="min-height: 436.5px;">
  <div class="search-wrapper mat-elevation-z2" *ngIf="showSearch">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)" placeholder="{{ 'LABEL.SEARCH' | translate }}" />
    </div>
  </div>
  <div *ngIf="showActions">
    <div class="d-flex flex-row justify-content-end my-2 mb-4">
      <span class="text-muted h6 m-0 my-auto">Azioni:</span>
        <a matTooltip="Aggiungi Team" class="nav-link p-0" role="button" aria-expanded="false" (click)="addNewExternalTeam()">
          <fa-icon [icon]="faFileArrowUp" class="text-muted h2 mx-3"></fa-icon>
        </a>
        <a matTooltip="Chiudi" class="nav-link p-0" role="button" aria-expanded="false" (click)="closeExternalSection()">
          <fa-icon [icon]="faClose" class="text-muted h2 mx-3"></fa-icon>
        </a>
    </div>
  </div>
  <div style="overflow-x: auto;">
    <table mat-table [dataSource]="dataSource" matSort style="width:100%">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Id</th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Nome</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.name}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Data creazione</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Data ultima modifica</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="editAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="goTo(element.id)"><i class="my-bounce material-icons ic">edit</i>
      </ng-container>
      <ng-container matColumnDef="deleteAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><i class="my-bounce material-icons ic" data-bs-toggle="modal" data-bs-target="#deleteTeamsModal" (click)="markProfileForDeletion(element)">delete</i> <!--  (click)="markProfileForDeletion(element)" -->
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [length]="5" [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 30]">
  </mat-paginator>
</div>

<div class="modal fade" id="deleteTeamsModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Cancella Team</h5>
        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <p>Sei sicuro di voler cancellare il team {{ elemetToDelete?.code }} - {{ elemetToDelete?.name }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
        <button type="button" class="btn btn-primary" (click)="deleteTeam(elemetToDelete?.id)">Cancella</button>
      </div>
    </div>
  </div>
</div>
