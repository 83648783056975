import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ActivatedRoute, Route } from '@angular/router';
import { map, of, switchMap, tap } from 'rxjs';
import { SurveyMasterService } from 'src/app/services/survey-master.service';
import { TranslatorService } from 'src/app/services/translate.service';
@Component({
  selector: 'app-survey-creator',
  templateUrl: './survey-creator.component.html',
  styleUrls: ['./survey-creator.component.scss']
})
export class SurveyCreatorComponent implements OnInit {
  isNew: boolean;


  questionForm = this.fb.group({
    id: null,
    text: null,
    entityName: null,
    answerType: null,
    answerName: null,
    mandatory: null
  })
  formSurveyMaster = this.fb.group({
    id: null,
    name: [null, Validators.required],
    entityName: [null, Validators.required],
    questions: this.fb.array([])
  });

  id$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    tap(id => {
      this.isNew = id ==='0'? true: false;
    })
  )

  surveyMaster$ = this.id$.pipe(
    switchMap(id => {
      return id !=='0'?this.surveyMasterService.getById(id!): of(null)
    })
  )

  constructor(
    private fb: UntypedFormBuilder,
    private surveyMasterService: SurveyMasterService,
    private route: ActivatedRoute,
    public translate: TranslatorService,
    private datePipe: DatePipe,

  ) { }

  ngOnInit(): void {
    this.surveyMaster$.subscribe(surveyMaster => {
      this.formSurveyMaster.patchValue(surveyMaster!)
    })
  }

  get questions() {
    return this.formSurveyMaster.controls["questions"] as UntypedFormArray;
  }

  get questionControls(){
    return (this.formSurveyMaster.controls["questions"] as UntypedFormArray).controls as UntypedFormGroup[]
  }

  addNewQuestion(){
    this.questions.push(
      this.fb.group({
        id: null,
        text: null,
        entityName: this.formSurveyMaster.get('entityName'),
        answerType: null,
        answerName: null,
        mandatory: false
      })
    );
  }

  update(){
    if(this.isNew){
      let surveyMaster = this.clean(this.formSurveyMaster.getRawValue())
      this.surveyMasterService.create(surveyMaster).subscribe(res => {
        //log result
      })
    }else{
      this.surveyMasterService.update(this.formSurveyMaster.getRawValue()).subscribe(res => [
        //log result
      ])
    }
  }

  private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      if(typeof obj[propName] === 'object'){
        obj[propName] = this.clean(obj[propName])
      }
    }
    return obj
  }
}
