import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { UserSessionService } from './user-session.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimTypesService {

  baseApi = environment.rootApiCrm + 'v1.0';

  constructor(public libService: LibService,
              public userSessionService: UserSessionService) { }

  getAllClaimTypes(): Observable<any> {
    let claimTypes = this.userSessionService.getState('getAllClaimTypes');
    if (claimTypes !== null && claimTypes !== undefined) {
      return of(claimTypes);
    } else {
      // console.log("SERVICE FRONT: "+this.baseApi);
      return this.libService.getData<any>(this.baseApi, `/claimTypes`).pipe(
        tap( claimTypes =>{
          this.userSessionService.saveState('getAllClaimTypes', claimTypes);
        }

        )
      );
    }

  }

}
