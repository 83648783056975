import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibService } from '../../../services/libService';
import { UserDataService } from 'src/app/services/users.service';
import { SubjectService } from 'src/app/services/subject.service';
import { ViewChild } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-signaler-modal',
  templateUrl: './signaler-modal.component.html',
  styleUrls: ['./signaler-modal.component.scss']
})
export class SignalerModal implements OnInit {

  @ViewChild('selectSignalers') selectSignalers: any;
  confirmButtonLabel: any = 'LABEL.ADD'
  cancelButtonLabel: any = 'LABEL.CANCEL'
  form: FormGroup
  searchResults: any[] = [];


  subjectAttributes: any = {

    "searchAdress": [
      {
        "required": false
      },
      {
        "editable": true
      }
    ],
    "company": [
      {
        "required": true
      },
      {
        "editable": true
      }
    ],
    "showAdress": [
      {
        "required": false
      },
      {
        "editable": true
      }
    ]
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private libService: LibService,
    private userService: UserDataService,
    private subjectService: SubjectService,
    private toastr: ToastrService,
    private translate: TranslatorService,
  ) {

    this.form = this.fb.group({
      type: ['users', Validators.required],
      kwSearchName: [null, []],
      kwSearchSurname: [null, []],
      kwSearchEmail: [null, []],
      kwSearchLegalEntity: [null, []],
      signalerId: [null, Validators.required],
      signaler: [null, Validators.required],
      subject: this.fb.group({
        id: [null, []],
        name: [null],
        surname: [null],
        legalEntity: [null, []],
        type: ['Edificio', []],
        vat: [null, []],
        personalFiscalCode: [null, []],
        marketingNoteId: [null],
        taxCode: [null, []],
        telephone1: [null, []], // fixTelephone
        telephone2: [null, []], // mobile
        email: [null, []],
        streetName: [null, []],
        streetNumber: [null, []],
        otherInfo: [null, []],
        city: [null, []],
        province: [null, []],
        state: [null, []],
        zip: [null, []],
        country: [null, []],
        coordX: [null, []],
        coordY: [null, []],
        officeCode: [null, []],
        isProspect: [null, []],
        subjectType: [null, []],
        subjectTypeArray: [[], []],
        condominiums: [null, []],
        subjectscondominiums: [null, []],
        adminAssociation: [null, []],
        subjectRatings: [null, []],
        status: [null, []],
        contacts: [null, []],
        clientStatus: ['Cliente', []],
        company: [null, []],
        companyLocation: [null, []],
        geographicArea: [null, []],
        geographicLocation: [null, []],
        responsible: [null, []],
        origin: [null, []],
        contactChannel: [null, []],
        administeredCondominiums: [null, []],
        albertCondominiums: [null],
        subscribedToFBGroup: [null, []],
        fbLink: [null, []],
        igLink: [null, []],
        linkedinLink: [null, []],
        twitterLink: [null, []],
        webSite: [null, []],
        gender: [null, []],
        birthdate: [null, []],
        contactPreference: [null, []],
        subscribedToEdacApp: [null, []],
        fax: [null, []],
        storicBuilding: [null, []],
        totAdministeredCondominiums: [null, []],
        notes: [null, []],
        businessName: [null, []],
        sysCodeDelta: [null, []],
        documents: [null, []],
        privacyConsent: [null, []],
        eaCourses: [null, []],
        appEa: [null, []],
        surveys: [null, []],
        components: [null, []],
        reportingUserId: [null, []],
        reportingSubjectId: [null, []],
        insertedByRequestId: [null, []],
      }),
    })

  }

  openSelectSignalers() {
    this.selectSignalers.open();
  }

  addSubject() {
    console.log(this.form.get('subject'))
    let subject = this.form?.get('subject')?.getRawValue()
    this.libService.lockPage('');
    this.subjectService.findSubjectByPhoneAndMail(subject).subscribe((response: any) => {
      console.log("risposta duplicate soggetto --> ", response);
      if (response == null) {

        this.subjectService.createSubject(subject).subscribe((res: any) => {
          console.log("risposta soggetto creato --> ", res);
          this.toastr.success(this.translate.instant('LABEL.CreatoConSuccesso'), this.translate.instant('LABEL.Info'));
          let newSubject = res?.body
          this.searchResults = [newSubject]
          this.form.get('signaler')?.setValue(newSubject)
          this.form.get('signalerId')?.setValue(newSubject.id)
          this.libService.unlockPage();
        });

      } else {
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE25') + `<br> ${response.businessName ? response.businessName : ''} ${response.name ? response.name : ''} ${response.surname ? response.surname : ''}`, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
        this.libService.unlockPage();
      }


    })

  }

  getType() {
    let type = this.form.get('type')?.value;
    return type;
  }

  isUserType() {
    let type = this.form.get('type')?.value;
    return type == 'users';
  }

  isSubjectType() {
    let type = this.form.get('type')?.value;
    return type == 'subjects';
  }

  isAddSubjectType() {
    let type = this.form.get('type')?.value;
    return type == 'addSubjects';
  }

  setSignaler(event: any) {
    let id = event.value
    let currentItem = this.searchResults.find((item) => item.id == id)
    if (currentItem) this.form.get('signaler')?.setValue(currentItem)
  }

  getFullname(item: any) {
    let type = this.form.get('type')?.value;
    let fullname = item.name
    if (item.email) fullname += ` (${item.email})`
    if (type == 'subjects') {
      fullname = `${item.name} ${item.surname}`
      if (item.legalEntity) fullname = item.legalEntity
      if (item.type) fullname += ` - ${item.type}`
      if (item.email) fullname += ` (${item.email})`
      fullname += ` - ${item?.streetName} ${item?.streetNumber}, ${item?.zip} ${item?.city} (${item?.province}) - ${item?.country}`
    }
    return fullname
  }

  searchUsers() {
    const name = this.form.get('kwSearchName')?.value;
    const surname = this.form.get('kwSearchSurname')?.value;
    const legalEntity = this.form.get('kwSearchLegalEntity')?.value;
    const email = this.form.get('kwSearchEmail')?.value;
    this.libService.lockPage('');
    let type = this.form.get('type')?.value;

    let service: any = this.userService
    if (type == 'subjects') service = this.subjectService

    let searchAttributes = {
      name: name,
      surname: surname,
      legalEntity: legalEntity,
      email: email
    }

    if (name || surname || legalEntity || email) {
      service.search(searchAttributes).subscribe((response: any) => {
        this.searchResults = response;
        this.openSelectSignalers()
        this.libService.unlockPage();
      });
    }

  }

  ngOnInit(): void {
    this.form.get('type')?.valueChanges.subscribe((value) => {
      this.checkTypeChanges()
    });
  }

  checkTypeChanges() {
    this.form.get('kwSearchName')?.reset();
    this.form.get('kwSearchSurname')?.reset();
    this.form.get('kwSearchEmail')?.reset();
    this.form.get('kwSearchLegalEntity')?.reset();

    this.searchResults = [];
  }

  disabledConfirmButton() {
    let disabled = false
    if (!this.form.valid) disabled = true
    return disabled
  }

}
