

  <mat-grid-list class="cardGrid" [cols]="cols" [rowHeight]="rowHeight" gutterSize="12px">

    <mat-grid-tile *ngFor="let stock of stocks">
      <mat-card class="stockBox" [class]="stock.color">
        <div class="setPadding">
          <mat-card-header>
            <mat-card-title>{{ "PLANNER."+stock.code | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
  
            <div *ngIf="loading; else elseBlock">
              <p>{{ "PLANNER.STOCK" | translate }}:</p>
              <p>{{ "PLANNER.numCommesse" | translate }}:</p>
            </div>
            <ng-template #elseBlock>
              <p>{{ "PLANNER.STOCK" | translate }}:<br> {{stock.stockNumber}}</p>
              <p>{{ "PLANNER.numCommesse" | translate }}:<br> {{stock.numberOfOrders}}</p>
            </ng-template>
  
          </mat-card-content>
          <mat-card-actions>
            <button (click)="openList(stock.code)" color="primary" mat-raised-button>{{ "PLANNER.LIST" | translate }}</button>
          </mat-card-actions>
        </div>
      </mat-card>
    </mat-grid-tile>

  </mat-grid-list>