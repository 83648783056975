import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-estimate-delivery-methods',
  templateUrl: './modal-estimate-delivery-methods.component.html',
  styleUrls: ['./modal-estimate-delivery-methods.component.scss']
})
export class ModalEstimateDeliveryMethods implements OnInit {

  editMode: boolean = false
  form: FormGroup

  reasons: any = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
    )
  {
    this.form = this.fb.group({
      OrderId: [null, [Validators.required]],
      EstimateDeliveryMethodId: [null, [Validators.required]]
    })

    if(data){
      this.form.get('OrderId')?.setValue(`${data.OrderId}`)
    }


  }

  ngOnInit(): void {
  }

}
