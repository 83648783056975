<div class="full-w">

  <form [formGroup]="form" (submit)="update()" class="form-container">

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.STATUS' | translate }}</mat-label>
      <input disabled matInput [value]="item.status.code" [placeholder]="translate.instant('COMPLAINTS.Company')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'CLAIMS.TYPE' | translate }}</mat-label>
      <input matInput disabled [value]="item.type.complaintType" [placeholder]="translate.instant('CLAIMS.TYPE')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Pratica EDAC' | translate }}</mat-label>
      <input matInput disabled [value]="item.orderNumberEA"
        [placeholder]="translate.instant('COMPLAINTS.Pratica EDAC')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Numero Marsh' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Numero Marsh')" formControlName="marshNumberClaim">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data Inizio' | translate }}</mat-label>
      <input matInput disabled [matDatepicker]="pickerStartDate" formControlName="startDate" />
      <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
      <mat-datepicker #pickerStartDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data Fine' | translate }}</mat-label>
      <input matInput [matDatepicker]="pickerEndDate" formControlName="endDate" />
      <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
      <mat-datepicker #pickerEndDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Numero Polizza' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Numero Polizza')" formControlName="policyNumber">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Compagnia Delegataria' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Compagnia Delegataria')"
        formControlName="delegatingCompany">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Garanzia Principale' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Garanzia Principale')"
        formControlName="mainWarranty">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Numero Sinistri Compagnia' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Numero Sinistri Compagnia')"
        formControlName="claimCompanyNumber">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Reclamante' | translate }}</mat-label>
      <input disabled matInput [value]="fullname()"
        [placeholder]="translate.instant('COMPLAINTS.Reclamante')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Descrizione' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Descrizione')" formControlName="description">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Descrizione Tipo' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Descrizione Tipo')"
        formControlName="typeDescription">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Descrizione Causa' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Descrizione Causa')"
        formControlName="causeDescription">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Localita Sinistro' | translate }}</mat-label>
      <input matInput disabled [value]="item.location"
        [placeholder]="translate.instant('COMPLAINTS.Localita Sinistro')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Provincia Sinistro' | translate }}</mat-label>
      <input matInput disabled [value]="item.province"
        [placeholder]="translate.instant('COMPLAINTS.Provincia Sinistro')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Valore del Danno' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Valore del Danno')" formControlName="damageValue">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Franchigia' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Franchigia')" formControlName="deductible">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Riservato' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Riservato')" formControlName="due">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Pagato' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Pagato')" formControlName="paid">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Pagato Da Ea' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Pagato Da Ea')" formControlName="paidFromEA">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data Pagamento Edac' | translate }}</mat-label>
        <input matInput [matDatepicker]="pickerPaymentDateFromEA" formControlName="paymentDateFromEA" />
        <mat-datepicker-toggle matSuffix [for]="pickerPaymentDateFromEA"></mat-datepicker-toggle>
        <mat-datepicker #pickerPaymentDateFromEA></mat-datepicker>
    </mat-form-field>

    <div class="buttonBox">
      <button class="submitButton" type="submit" mat-raised-button color="primary">
        <mat-icon>save</mat-icon> {{ 'LABEL.SAVE' | translate }}
      </button>
    </div>
    <br>
  </form>

</div>