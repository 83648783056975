import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { ModalCloseRequest } from '../modals/modal-close-request/modal-close-request.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestService } from '../../services/request.service';
import { ComponentService } from '../../services/component.service';
import { LibService } from '../../services/libService';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-request-close-button',
  templateUrl: './request-close-button.component.html',
  styleUrls: ['./request-close-button.component.scss']
})
export class RequestCloseButtonComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isNew: any;
  @Input() components: any;
  @Input() documents: any;

  attributes: any;

  disabled: any = true;
  actions: any = [];

  componentName: any = 'close_request'


  constructor(
    private dialog: MatDialog,
    private requestService: RequestService,
    private componentService: ComponentService,
    private router: Router,
    private libService: LibService
  ) { }

  closeRequest(attributes: any) {
    this.libService.lockPage('');
    let id = this.form.get('id')?.value
    this.requestService.closeRequest(id, attributes).subscribe(
      (res: any) => {
        console.log(res)
        this.router.navigate(['request/' + id]).then(_res => {
          window.location.reload()
        })
      },
      (err: any) => {
        console.log(err)
        this.libService.unlockPage();
      },
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    console.log('ngOnInit request close button')
    this.setActions()
    this.setStatusButton()

    this.form.get('building')?.get('documents')?.valueChanges.subscribe(x => {

      this.setStatusButton()
    })
  }

  setStatusButton() {
    //console.log('setStatusButton')
    let tmpStatus = false
    this.setAttributes()
    if (!this.form?.valid) tmpStatus = true
    if (!this.checkNecessaryDocuments()) tmpStatus = true
    this.disabled = tmpStatus
  }

  openModal() {
    const dialogRef = this.dialog.open(ModalCloseRequest, {
      width: '300px',
      data: {
        actions: this.actions
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (![null, undefined].includes(result)) {
        let attributes = result;
        attributes.attributeCode = this.componentName
        this.closeRequest(attributes)
      }

    });


  }

  getAttributes(componentCode: any) {
    let component = this.components?.settings?.find((component: any) => component.componentCode == componentCode)
    return component?.attributes
  }

  checkNecessaryDocuments() {
    //console.log('checkNecessaryDocuments - app-request-close-button')
    let key = 'request_document_manager'
    if (!this.checkComponentFast(key)) key = 'crmDocumentManager'
    let attributes = this.getAttributes(key)
    return this.componentService.checkDocumentsStatus(key, attributes, this.form, this.documents)
  }

  setActions() {
    this.setAttributes()
    if (this.attributes) {
      this.actions = []
      let structure: any = this.componentService.getStructure(this.attributes)
      structure.forEach((s: any) => {
        if (s.type == 'action' && !this.actions.includes(s.type)) this.actions.push(s.code)
      })
      //console.log(this.actions)
    }
  }

  setAttributes() {
    //console.log('setAttributes')
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
  }

  checkComponentFast(componentName: any = null) {
    let key = componentName || this.componentName
    let status = this.components?.codes?.includes(key)
    return status
  }

  checkComponent(componentName: any = null) {
    let key = componentName || this.componentName
    let status = this.components?.codes?.includes(key)
    if (status) {
      this.setAttributes()
      this.setActions()
      this.setStatusButton()
    }
    return status
  }

}
