<form [formGroup]="form" class="form-container" (ngSubmit)="update()">

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'LABEL.name' | translate }}</mat-label>
        <input matInput formControlName="label" (focusout)="setCode()">
    </mat-form-field>
    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'LABEL.code' | translate }}</mat-label>
        <input readonly matInput formControlName="code">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'LABEL.START_DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="startDate" formControlName="startDate" />
        <mat-hint>GG/MM/AAAA</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'LABEL.END_DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="endDate" formControlName="endDate" />
        <mat-hint>GG/MM/AAAA</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>

    <br>

    <mat-card appearance="outlined" class="form-subject">
        <mat-card-content>{{ 'CONVENTIONS.subjectTheConvention' | translate }}</mat-card-content>
        <mat-card-content>
            <app-subject-fulltextsearch class="form-subject" searchType="Soggetto" (selected)="selSubject($event)"
                (clearOut)="clearSubject()"></app-subject-fulltextsearch>
            <hr>
            <p *ngIf="subjectId">
                <span *ngIf="subject?.name">Name: {{subject?.name}}<br></span>
                <span *ngIf="subject?.surname">Surname: {{subject?.surname}}<br></span>
                <span *ngIf="subject?.legalEntity">Legal Entity: {{subject?.legalEntity}}<br></span>
                <span *ngIf="subject?.type">Type: {{subject?.type}}<br></span>
            </p>
        </mat-card-content>
    </mat-card>

    <br>

    <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'LABEL.components' | translate }}</mat-label>
        <mat-select formControlName="componentIds" multiple>
            <mat-option *ngFor="let component of componentList" [value]="component.id">{{component.code}}</mat-option>
        </mat-select>
    </mat-form-field>


    <!-- SEZIONE COMPONETI -->
    <ng-container formArrayName="components">
        <ng-container *ngFor="let component of components.controls; let i = index">
            <app-component-card-box [form]="form" [component]="component" [index]="i" class="form-subject"></app-component-card-box>
        </ng-container>
    </ng-container>

</form>





<div class="buttons-right  my-2">

    <button style="background-color:transparent;" matTooltip="Back" type="button" class="my-bounce btn btn-primary mx-2"
        (click)="back()">
        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
    </button>

    <button style="background-color:transparent;" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()" [disabled]="!form?.valid">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>

</div>