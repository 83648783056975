<div class="full-w">




    <form [formGroup]="buildingForm" *ngIf="form">

      
            <!--
              <app-fulltextsearch2 *ngIf="checkVisibility('search')" class="form-subject" [service]="buildingService"
                searchType="Edificio" (selected)="selSubject($event)"
                [edit]="verifyDeactivation('search')"></app-fulltextsearch2>
                -->

            <app-address-form *ngIf="companies && buildingForm" [disableSearchBar]="disableSearchBar"
                [disableSearchBarButton]="disableSearchBarButton"
                [disableCurrentLocationButton]="disableCurrentLocationButton" [disableGeoButton]="disableGeoButton"
                [form]="buildingForm" [toDisable]="toDisable" [isNew]="isNew" [companies]="companies"></app-address-form>
                

            <!--
              <app-address-search *ngIf="checkVisibility('address_search') && this.form" [multiCompany]="true"
                [mainForm]="this.form" [businessLineCode]="this.form.get('businessLineCode')?.value" [form]="buildingForm"
                class="form-subject" [edit]="verifyDeactivation('address_search')"
                [noButtonSearchCompany]="true"></app-address-search>
            -->

            <app-address-detail-form  [form]="buildingForm"
                class="form-subject" [disabled]="false"></app-address-detail-form>

            <br>

            <mat-form-field appearance="fill" class="form-subject" >
                <mat-label>{{ 'REQUEST.CHANNEL' | translate }}</mat-label>
                <mat-select matNativeControl formControlName="contactChannel">
                    <mat-option *ngFor="let cc of contactChannels$ | async" [value]="cc.name">

                        {{ 'CHANNEL.'+cc.name | translate }}

                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="form-subject" >
                <mat-label>{{ 'REQUEST.ORIGIN' | translate }}</mat-label>
                <mat-select formControlName="origin">
                    <mat-option *ngFor="let origin of origins" [value]="origin.origin" [disabled]="origin.disabled">
                        {{ 'ORIGINS.'+origin.code | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field  appearance="fill" class="form-subject margin-top">
                <mat-label>{{ 'REQUEST.COMPANY' | translate }}</mat-label>
                <mat-select matNativeControl formControlName="company">
                    <mat-option *ngFor="let company of companies" [value]="company.code">
                        <strong>{{company?.name}}</strong> ({{company?.code}})
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field  appearance="fill" class="form-subject margin-top" *ngIf="subjectBuildingRelationTypes">
                <mat-label>{{ 'LABEL.RELATION_TYPE' | translate }}</mat-label>
                <mat-select matNativeControl formControlName="subjectBuildingRelationTypeId">
                    <mat-option *ngFor="let relationType of subjectBuildingRelationTypes" [value]="relationType.id">
                        {{'SUBJECT_BUILDING_RELATION_TYPES.'+relationType?.code | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

    </form>

</div>