<h1 mat-dialog-title>{{ titleLabel | translate }}</h1>

<div mat-dialog-content>

  <p>
    {{ textLabel | translate }}
  </p>

  <app-complaint-form *ngIf="type == 'complaints' && item" [item]="item" (closeModal)="closeModal()"></app-complaint-form>

  <app-claim-form *ngIf="type == 'claims' && item" [item]="item" (closeModal)="closeModal()"></app-claim-form>

  <app-legal-form *ngIf="type == 'legals' && item" [item]="item" (closeModal)="closeModal()"></app-legal-form>
  
  <app-transaction-form *ngIf="type == 'transactions' && item" [item]="item" (closeModal)="closeModal()"></app-transaction-form>

  <app-review-form *ngIf="type == 'reviews' && item" [item]="item" (closeModal)="closeModal()"></app-review-form>

  <app-grievance-form *ngIf="type == 'grievances' && item" [item]="item" (closeModal)="closeModal()"></app-grievance-form>

  <app-pec-form *ngIf="type == 'pecs' && item" [companies]="companies" [item]="item" (closeModal)="closeModal()"></app-pec-form>

</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="">{{ cancelButtonLabel | translate }}</button>
</div>