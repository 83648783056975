import { SchedulerProConfig, ProjectModelConfig } from '@bryntum/schedulerpro-thin';
/**
 * Application config file
 */


export const projectConfig: Partial<ProjectModelConfig> = {
    // Empty project config
};

export const schedulerProConfig: Partial<SchedulerProConfig> = {
  // A Project holds the data and the calculation engine for Scheduler Pro. It also acts as a CrudManager, allowing
  // loading data into all stores at once
  /*
    project : {
        autoLoad  : true,
        transport : {
            load : {
                url : './assets/data/data.json'
            }
        }
    },
    */
  viewPreset: {
    base: 'dayAndWeek',
    tickWidth: 40,
    displayDateFormat: 'LL',
    timeResolution: {
      increment: 1,
      unit: 'd',
    },
    headers: [
      {
        unit: 'w',
        align: 'start',
        dateFormat: 'LL',
      },
      {
        unit: 'd',
        align: 'center',
        dateFormat: 'DD',
      },
    ],
  },
  //eventStyle: 'plain',
  rowHeight: 90,
  barMargin: 15,
  autoHeight: true,
  infiniteScroll: true,
  timeZone: 0,
  /*
    eventLayout: {
        type    : 'stack',
        groupBy : 'prio'
    }
    */
};
