
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-l">
      <!--begin::Layout-->
      <div class="d-flex flex-column flex-lg-row">
        <!--begin::Sidebar-->
        <div class="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
          <!--begin::Card-->
          <div class="card mb-5 mb-xl-8">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Summary-->
              <!--begin::User Info-->
              <div class="d-flex flex-center flex-column py-5">

                <!--end::User Info-->
                <!--end::Summary-->
                <!--begin::Details toggle-->
                <!--end::Details toggle-->
                <!--begin::Details content-->
                <div id="kt_user_view_details" class="collapse show">
                  <div class="pb-5 fs-6">
                    <form [formGroup]="form">
                      <mat-form-field>
                        <mat-label class="fw-bolder mt-5">{{ 'USER_AREA.ACC_ID' | translate }}</mat-label>
                        <input class="text-gray-600" matInput formControlName="id" readonly>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label class="fw-bolder mt-5">{{ 'USER_AREA.EMAIL' | translate }}</mat-label>
                        <input class="text-gray-600" matInput formControlName="email">
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label class="fw-bolder mt-5">{{ 'USER_AREA.ADDRESS' | translate }}</mat-label>
                        <input class="text-gray-600" matInput formControlName="address">
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label class="fw-bolder mt-5">{{ 'USER_AREA.LANGUAGE' | translate }}</mat-label>
                        <mat-select class="text-gray-600" matInput formControlName="locale">
                          <mat-option *ngFor="let lang of languages" [value]="lang.code">
                            {{ lang.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
