import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../services/libService';
import { NavService } from '../services/nav.service';
import { UserSessionService } from '../services/user-session.service';
import { TranslatorService } from '../services/translate.service';
import { ClaimService } from '../services/claim.service';


@Component({
  selector: 'app-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss']
})
export class ClaimListComponent implements OnInit {

  displayedColumns = ['id', 'version', 'n_claim_marsh', 'n_claim_client','startDate','endDate', 'policyNumber', 'delegatingCompany', 'mainGuarantee', 'claimCompanyNumber', 'complainant', 'description', 'type', 'cause', 'claimStatus', 'location', 'province', 'damageValue', 'deductible', 'due', 'paid', 'isLastVersion', 'status', 'createdAt', 'updatedAt', 'editAction', 'deleteAction'];
  dataSource: any;
  kwSearch: any;
  currentUrl: string;
  claim: any;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    public navService: NavService,
    private claimService: ClaimService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public translate: TranslatorService) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.loadDataSets();

    let actions : any;
    actions = [
      {name:'export',url:''},
    ];
    this.navService.setActions(actions);

    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }
  }

  loadDataSets(): void {

    this.libService.lockPage('');
    this.claimService.getAllClaims().subscribe((response) => {
      console.log('loadClaims response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        console.log("LIST COMPONENT");
        console.log(response);
        this.dataSource.data = response;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    },
    err => {
      this.libService.unlockPage();
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  goTo(e: any) {
    this.router.navigate(['claim/' + e.id]);
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  deleteRow(e: any) {

    const id = e.id;
      if (id && parseInt(id) > 0) {
        console.log("ID: " + id);
        this.claimService.deleteClaim(id, this.claim).subscribe((response: any) => {
          this.libService.unlockPage();
          this.toastr.success(this.translate.instant('LABEL.ROW_DELETED'), this.translate.instant('TOASTR.INFO'));
          console.log(response);
          window.location.reload();
        },
        err => {
          console.log(err);
          this.libService.unlockPage();
          this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
        });
      }
      else {
        this.toastr.success(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.INFO'));
      }
  }

}
