import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryBuilderComponent } from '@syncfusion/ej2-angular-querybuilder';
import { combineLatest, EMPTY, map, of, switchMap } from 'rxjs';
import { DataSet } from '../models/dataset';
import { LibService } from '../services/libService';
import { BuildingService } from '../services/building.service';
import { DashboardService } from '../services/dashboard.service';
import { DataSetService } from '../services/dataset.service';
import { SubjectService } from '../services/subject.service';
import { NavService } from '../services/nav.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../services/translate.service';
import { Subject } from '../models/subject';
import { Building } from '../models/building';


@Component({
  selector: 'app-domain-item',
  templateUrl: './domain-item.component.html',
  styleUrls: ['./domain-item.component.scss']
})

export class DomainItemComponent implements OnInit, OnChanges {
  title = "Nuovo Dominio";
  subTitle = "EDAC - Nuovo Dominio";
  @ViewChild('querybuilders') qryBldrObjS: QueryBuilderComponent ;
  // @ViewChild('querybuilderb') qryBldrObjB: QueryBuilderComponent ;
  // //@ViewChild('querybuilderp') qryBldrObjP: QueryBuilderComponent ;
  @ViewChild('querybuilderd') qryBldrObjD: QueryBuilderComponent ;

  isLoading: boolean = true;
  isNew: boolean = false;

  domain$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    switchMap(id => id!=='0'?this.domainService.getDataSet(id!):of(''))
  )


  subjectAttributes$ = of(Object.keys(new Subject())).pipe(
    map(attributes => {
      return attributes.map(attribute =>{
        return {
          field: attribute,
          label: attribute,
          type: 'string'
        }
      })
    })
  )

  buildingAttributes$ = of(Object.keys(new Building())).pipe(
    map(attributes => {
      return attributes.map(attribute =>{
        return {
          field: attribute,
          label: attribute,
          type: 'string'
        }
      })
    })
  )

  // subjectAttributes$ = this.subjectService.getAttributes().pipe(
  //   map(attributes => {
  //     return attributes.map(attribute => {
  //       return {
  //       field: attribute.field,
  //       label: attribute.fieldName,
  //       type: 'string'
  //     }
  //     })

  //   })
  // )

  // buildingAttributes$ = this.buildingService.getAttributes().pipe(
  //   map(attributes => {
  //     return attributes.map(attribute => {
  //       return {
  //       field: attribute.field,
  //       label: attribute.fieldName,
  //       type: 'string'
  //     }
  //     })

  //   })
  // )

  dashboardAttributes$ = this.dashboardService.getAttributes().pipe(
    map(attributes => {
      return attributes.map(attribute => {
        return {
        field: attribute.field,
        label: attribute.fieldName,
        type: 'string'
      }
      })

    })
  )
  subjectAttributes: any[];
  buildingAttributes: any[];
  dashboardAttributes: any[];
  domain: DataSet;

  form = this.fb.group({
    name: '',
    description: ''
  })

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subjectService: SubjectService,
    private buildingService: BuildingService,
    private domainService: DataSetService,
    private dashboardService: DashboardService,
    public navService: NavService,
    private fb: UntypedFormBuilder,
    public libService: LibService,
    public toastr: ToastrService,
    public translate: TranslatorService
    ) {}

    loadLabels(){
      this.translate.get('LABEL.NEW_DOMAIN').subscribe(res => {
        this.title = res;
        this.subTitle = "EDAC - " + res;
      })
    }

  ngOnInit(): void {
    this.loadLabels();
    const id = this.route.snapshot.paramMap.get('id');

    if (id && parseInt(id) > 0) {
      this.title = "Modifica Dominio";
      this.subTitle = "EDAC - Modifica Dominio";
    }
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
      {name:'back',url:'domains'}
    ];
    this.navService.setActions(actions);
    combineLatest([this.subjectAttributes$, this.buildingAttributes$, this.dashboardAttributes$, this.domain$]).subscribe(([subjects, buildings, dashboards, domain]) => {
      this.subjectAttributes = subjects;
      this.buildingAttributes = buildings;
      this.dashboardAttributes = dashboards;

      if(domain !== ''){
        this.domain = domain
        this.form.patchValue(this.domain)
      }else{
        this.domain = new DataSet()
        this.isNew = true
      }
      this.isLoading = false;
      setTimeout(() => {
        if(this.domain.sqlString !== ''){
          this.setQueryBuilders(this.domain.sqlString!)
        }
      }, 1000);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['isLoading'].currentValue !== changes['isLoading'].previousValue){

      }
  }

  goBack() {
    this.router.navigate(['domains']);
  }

  get subjectQryBldrValue() {
    return this.qryBldrObjS.getSqlFromRules(this.qryBldrObjS.getRules())
  }

  get buildingQryBldrValue(){
    // return this.qryBldrObjB.getSqlFromRules(this.qryBldrObjB.getRules())
    return ''
  }

  get dashboardQryBldrValue(){
    return this.qryBldrObjD.getSqlFromRules(this.qryBldrObjD.getRules())
    return ''
  }

  validate()
  {
    // this.qryBldrObjB.summaryView = true;
    this.qryBldrObjS.summaryView = true;
    //this.qryBldrObjP.summaryView = true;
    this.qryBldrObjD.summaryView = true;
    // console.log(this.qryBldrObjS.getSqlFromRules(this.qryBldrObjS.getRules()));
    // console.log(this.qryBldrObjB.getSqlFromRules(this.qryBldrObjB.getRules()));
    // console.log('querybuilders %o',this.qryBldrObjS);
    // console.log('querybuilderb %o',this.qryBldrObjB);
    // console.log('querybuilderd %o',this.qryBldrObjD);

    let subjectSqlString = this.subjectQryBldrValue? 'SELECT id FROM Subjects WHERE ' + this.subjectQryBldrValue: ''
    let buildingSqlString = this.buildingQryBldrValue? 'SELECT id FROM Buildings WHERE '+ this.buildingQryBldrValue: ''
    let dashboardSqlString = this.dashboardQryBldrValue? 'SELECT id FROM Dashboards WHERE '+ this.dashboardQryBldrValue: ''
    let sqlString = `${subjectSqlString};${buildingSqlString};;${dashboardSqlString}`

    this.domainService.validateSqlString(sqlString).subscribe(res=> {
      console.log(res);
      if((subjectSqlString ? (res.subjects.length > 0): true) && (buildingSqlString ? (res.buildings.length > 0): true) && (dashboardSqlString ? (res.dashboards.length > 0): true)){
        //OK
        res.subjects = res.subjects !== ''? res.subjects.map((subject: any) => subject.id): ''
        res.buildings = res.buildings !== '' ? res.buildings.map((building: any) => building.id): ''
        res.dashboards = res.dashboards !== '' ? res.dashboards.map((dashboard: any) => dashboard.id): ''
        this.domain = {
          ...this.domain,
          sqlString: sqlString,
          subjects: res.subjects,
          buildings: res.buildings,
          dashboards: res.dashboards
        }
        this.domainService.applySqlString(this.domain).subscribe(res => {
          console.log(res);
        })
      }else{
        //ERROR no entities match
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_9'), this.translate.instant('TOASTR.WARNING'));
        console.log('one of the queries didnt return results');

      }
    })
  }

  update(){
    this.libService.lockPage('');
    this.domain = {
      ...this.domain,
      name: this.form.get('name')?.value,
      description: this.form.get('description')?.value,
      sqlString: this.getSqlString()
    }
    this.domainService.updateOne(this.domain).subscribe(res=> {
      //TODO: add alert
      this.libService.unlockPage();
      this.toastr.success(
        this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
      console.log('update dataset response:',res);
    },
    err =>{
      this.libService.unlockPage();
      this.toastr.error('Errore nel salvataggio dei dati ' + err.message, 'Attenzione');
    })
  }

  create(){
    this.domain = this.form.getRawValue();
    delete this.domain.id;
    this.libService.lockPage('');
    this.domainService.createOne(this.domain).subscribe(res=> {
      //TODO: add alert
      this.libService.unlockPage();
      this.toastr.success(
        this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
      console.log('create dataset response:',res);
      this.router.navigate(['domain/' + res.id]);
    },
    err =>{
      this.libService.unlockPage();
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE_3') + err.message, this.translate.instant('TOASTR.WARNING'));
    })
  }

  getSqlString(){
    return ''
  }

  setQueryBuilders(sqlString: string){
    let strings = sqlString.split(';')
    //index 0 = subjects
    //index 1 = buildings
    //index 2 = pratiche
    //index 3 = dashboards

    let subjectString = strings[0].replace('SELECT id FROM Subjects WHERE ', '')
    let buildingString = strings[1].replace('SELECT id FROM Buildings WHERE ', '')
    let dashboardString = strings[3].replace('SELECT id FROM Dashboards WHERE ', '')

    if(subjectString !== '')
    this.qryBldrObjS.setRulesFromSql(subjectString)

    // if(buildingString !== '')
    // this.qryBldrObjB.setRulesFromSql(buildingString)

    if(dashboardString !== '')
    this.qryBldrObjD.setRulesFromSql(dashboardString)
  }
}
