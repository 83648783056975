<div class="container-fluid my-container">
  <div class="buttons my-2">
    <div style="display:flex; flex-direction: column;">

    </div>
    <div class="buttons-right">
      <mat-icon class="check-ok" *ngIf="formActivities.valid">check</mat-icon>
      <button style="background-color:transparent;" [disabled]="!formActivities.valid" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>
    </div>
  </div>


  <mat-tab-group class="wbck" animationDuration="300ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">person</mat-icon>
        <span class="ml-1">{{ 'ACTIVITIES.ANAGRAFICA' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <div class="full-w">

        <!-- <div style="margin:5px;">
          <br>
          <mat-radio-group [(ngModel)]="searchType" (ngModelChange)="onSearchTypeChange($event)" [disabled]="isNew === false">
            <mat-label></mat-label>
            <mat-radio-button style="margin-right:5px;" value="Soggetto">&nbsp;{{ 'LABEL.SUBJ' | translate }}&nbsp;</mat-radio-button>
            <mat-radio-button style="margin-right:5px;" value="Pratica">&nbsp;{{ 'LABEL.ORDER' | translate }}&nbsp;</mat-radio-button>
          </mat-radio-group>
        </div> -->
        <form [formGroup]="formActivities" class="form-container">
          <br>
          <table style="width: 60%; margin-left: auto; margin-right:auto;">
            <tr>
              <td>
                <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
                  <mat-label>{{ 'ACTIVITIES.TYPE' | translate }}</mat-label>
                  <mat-select matNativeControl formControlName="type" #typeInput>
                    <mat-option *ngFor="let type of types" [value]="type.activityType"
                      [ngStyle]="{ display : type.activityType === 'Verifica Pratica' ? 'none' : 'block' }">
                      <!-- | async -->
                      {{type.uiLabel | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>

              <td>
                <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
                  <mat-label>{{ 'ACTIVITIES.REASON' | translate }}</mat-label>
                  <mat-select matNativeControl formControlName="reason" #reasonInput>
                    <mat-option *ngFor="let reason of reasons" [value]="reason.activityTypeReason">
                      <!-- | async -->
                      {{ 'ACTIVITIES.' + reason.uiLabel | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
                  <mat-label>{{ 'ACTIVITIES.STATUS' | translate }}</mat-label>
                  <mat-select matNativeControl formControlName="activityStatus" #activityStatusInput [disabled]="true">
                    <mat-option *ngFor="let status of statusActivities" [value]="status">
                      <!-- | async -->
                      {{status.uiLabel | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field appearance="fill" class="form-activities" style="width: 100%;">
                  <mat-label>{{ 'ACTIVITIES.DATE' | translate }}</mat-label>
                  <input matInput type="date" placeholder="inserisci la data dell'attività"
                    formControlName="activityDate" #activityDateInput readonly>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="fill" class="form-activities" style="width: 100%;">
                  <mat-label>{{ 'ACTIVITIES.OWNER' | translate }}</mat-label>
                  <input matInput maxlength="128" placeholder="inserisci l'owner dell'attività" formControlName="owner"
                    #ownerInput readonly>
                </mat-form-field>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <app-fulltextsearch3 class="form-subject" [form]="formActivities" [fieldName]="searchType"
                  [events]="eventsSubject" [service]="subjectService" searchType="Soggetto"
                  (selected)="setSubject($event)" [disabled]="false"></app-fulltextsearch3>

                <!-- <app-fulltextsearch3 *ngIf="disabled === true" class="form-subject" [form]="formActivities" [fieldName]="searchType" [events]="eventsSubject" [service]="service" searchType="Order" (selected)="searchType === 'Soggetto'? setSubject($event): setOrder($event)" [disabled]="true"></app-fulltextsearch3> -->

                <!-- <app-formfield-search *ngIf="!hideSubject" style="" [form]="formActivities" style="width: 100%;" [events]="eventsSubject"
                  [controlName]="'subjectField'" [service]="subjectService" [searchType]="'Soggetto'"
                  (selected)="setSubject($event)" (formOutput)="setFormValue($event)" [readonly]="readonly"></app-formfield-search> -->
                <!-- <app-formfield-search *ngIf="hideSubject" style="" [form]="formActivities" style="width: 100%;" [events]="eventsSubject"
                  [controlName]="'orderField'" [service]="orderService" [searchType]="'Soggetto'"
                  (selected)="setOrder($event)" (formOutput)="setFormValue($event)" [readonly]="readonly"></app-formfield-search> -->
                <!-- <app-fulltextsearch3 *ngIf="searchType === 'Pratica'" class="form-subject" [form]="formActivities" [fieldName]="'orderCode'" [events]="eventsSubject" [service]="orderService" searchType="Order" (selected)="setOrder($event)"></app-fulltextsearch3> -->
              </td>
            </tr>

            <tr>
              <td>
                <mat-form-field appearance="fill" class="form-activities" style="width: 100%;">
                  <mat-label>{{ 'ACTIVITIES.BRANCH' | translate }}</mat-label>
                  <!-- <input matInput maxlength="128" placeholder="inserisci il ramo dell'attività" formControlName="branch"
                  #ownerInput> -->
                  <mat-select matNativeControl formControlName="branch" #branchInput>
                    <mat-option *ngFor="let branch of branches" [value]="branch.businessLineCode">
                      <!-- | async -->
                      {{ 'ACTIVITIES.' + branch.businessLineCode | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="fill" class="form-activities" style="width: 100%;">
                  <mat-label>{{ 'ACTIVITIES.CATEGORY' | translate }}</mat-label>
                  <!-- <input matInput maxlength="128" placeholder="inserisci la categoria dell'attività" formControlName="category"
                  #ownerInput> -->

                  <mat-select matNativeControl formControlName="category" [(ngModel)]="catSelected" #categoryInput>
                    <mat-option *ngFor="let category of categories" [value]="category.code">
                      <!-- | async -->
                      {{ category.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
           
            <tr>
              <td colspan="2">
                <mat-form-field appearance="fill" class="form-activities" style="width: 100%;">
                  <mat-label>{{ 'ACTIVITIES.NOTE' | translate }}</mat-label>
                  <textarea matInput type="textarea" maxlength="128" placeholder="inserisci la categoria dell'attività"
                    formControlName="notes" #notesInput></textarea>
                </mat-form-field>
              </td>
            </tr>

          </table>

          <!-- <app-activities-custom-control style="width: 60%; margin-left: auto; margin-right:auto;" [formActivities]="formActivities"></app-activities-custom-control> -->



        </form>

      </div>
    </mat-tab>
    <!-- <div class="buttons-right  my-2">
      <mat-icon class="check-ok" *ngIf="formActivities.valid">check</mat-icon>
      <button style="background-color:transparent;" [disabled]="!formActivities.valid" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>
    </div> -->
  </mat-tab-group>

  <div class="buttons-right  my-2">
    <mat-icon class="check-ok" *ngIf="formActivities.valid">check</mat-icon>
    <button style="background-color:transparent;" [disabled]="!formActivities.valid" matTooltip="Salva" type="button"
      class="my-bounce btn btn-primary mx-2" (click)="update()">
      <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>
  </div>
</div>