
<div class="container-fluid my-container" style="height: 436.5px;">
  <div class="buttons my-2">
    <div>&nbsp;</div>
    <div class="buttons-right">
      <button style="background-color:transparent;" matTooltip="Salva" type="button" [disabled]="!leaderSeleced" class="my-bounce btn btn-primary mx-2" (click)="saveTeam()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>
      <button *ngIf="external" style="background-color:transparent;" matTooltip="Chiudi" type="button" class="my-bounce btn btn-primary mx-2" (click)="closeExternalEditing()">
        <mat-icon class="material-icons" style="color:#293683;">close</mat-icon>
      </button>
    </div>
  </div>
  <!-- <pre>
    {{ teamToEdit | json }}
  </pre> -->
  <div class="d-flex flex-row">
    <div class="col-4">
      <label for="team-code">Team Code</label>
      <input type="text" class="form-control py-2" name="team-code" id="team-code" placeholder="Team Code" [(ngModel)]="teamToEdit.code">
    </div>
    <div class="col-4">
      <label for="team-type">Team Type</label>
      <input type="text" class="form-control py-2" name="team-type" id="team-type" placeholder="Team Type" [(ngModel)]="teamToEdit.teamType">
    </div>
    <div class="col-4">
      <label for="team-office">Office Team</label>
      <select class="form-select" name="team-office" id="team-office" [(ngModel)]="companySelected" (change)="cleanResources()">
        <option [value]="company.id" *ngFor="let company of companies; let iC = index">
          {{ company.id }} - {{ company.name }} - {{ company.code }}
        </option>
      </select>
    </div>
  </div>
  <div class="d-flex flex-row my-5" *ngIf="companySelected">
    <div class="col-4">
      <label for="team-name">Team Name</label>
      <input type="text" disabled class="form-control py-2" name="team-name" id="team-name" placeholder="Team Name" [(ngModel)]="teamToEdit.name">
    </div>
    <div class="col-4">
      <label for="team-users-lead">Team Users</label>
      <mat-select class="form-control form-control-lg pt-4" [(ngModel)]="usersSelected" multiple (selectionChange)="setUsersLead()">
        <mat-option [value]="user.id" *ngFor="let user of users; let iU = index">
          {{ user.name }} - {{ user.email }}
        </mat-option>
      </mat-select>
    </div>
    <!-- <div class="col-4">
      <label for="team-users-lead">Team Users</label>
      <select class="form-select" name="team-users" id="team-users" [(ngModel)]="usersSelected" multiple (change)="setUsersLead()">
        <option [value]="user.id" *ngFor="let user of users; let iU = index" class="d-flex justify-content-between">
          {{ user.name }} - {{ user.email }}
        </option>
      </select>
    </div> -->
    <!--  -->
    <div class="col-4">
      <label for="team-users-lead">Team User Leader</label>
      <mat-select class="form-control form-control-lg pt-4" name="team-users-lead" id="team-users-lead" [(ngModel)]="leaderSeleced" (selectionChange)="setLeader()">
        <mat-option [value]="userL.id" *ngFor="let userL of usersLeads() as users; let iUL = index" class="d-flex justify-content-between">
          {{ userL.name }} - {{ userL.email }}
        </mat-option>
      </mat-select>
    </div>


    <!-- <div class="col-4">
      <label for="team-users-lead">Team User Leader</label>
      <select class="form-select" name="team-users-lead" id="team-users-lead" [(ngModel)]="leaderSeleced" (change)="setLeader()">
        <option [value]="userL.id" *ngFor="let userL of usersLeads() as users; let iUL = index" class="d-flex justify-content-between">
          {{ userL.name }} - {{ userL.email }}
        </option>
      </select>
    </div> -->
  </div>
</div>
