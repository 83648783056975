<div>
  <!-- Heading -->
  <div id="sidebarHeading" [class.collapsed]="collapsed"
    class="d-flex justify-content-between align-items-center px-5 crs">
    <img (click)="goHome()" src="../../assets/img/acrobatica_logo_giallo.svg" alt="logo albert 4.0" height="40px" *ngIf="!collapsed">
    <img (click)="goHome()" src="../../assets/img/acrobatica_symbol_giallo.svg" alt="logo albert 4.0 min" height="40px" *ngIf="collapsed">
    <div>
      <fa-icon [icon]="faBars" style="cursor: pointer; color: white" (click)="setCollapsed(!collapsed)"></fa-icon>
      <fa-icon [icon]="faArrowRightFromBracket" style="cursor: pointer; color: white" (click)="logout()"
        id="logoutButton"></fa-icon>
    </div>
  </div>
  <!-- Collapsible content -->
  <div id="collapsibleContent" class="vh-100" [class.collapsed]="collapsed">
    <!-- User -->
    <div id="user" class="p-5">
      <div class="row">
        <div class="col-3">
          <img src="../../assets/img/av.png" alt="avatar mock" height="60px" id="avatar">
        </div>
        <div class="col-7" style="padding-left: 20px;" *ngIf="!collapsed">
          <span class="h5 text-white">{{isUser().given_name}} {{isUser().family_name}}</span><br />
          <span class="text-yellow">{{ 'SIDEBAR.PROFILE' | translate }}</span><br />
        </div>
        <small *ngIf="!collapsed" class="text-success">{{isUser().email}}</small>
        <!-- <div class="col-2 text-end px-2" *ngIf="!collapsed" >
          <span style="cursor: pointer;" (click)="goTo('/user-area')">
            <fa-icon [icon]="faCog" class="h3 text-white" ></fa-icon>
          </span>
        </div> -->
      </div>
    </div>
    <!-- /User -->
    <!-- Divider -->
    <hr style="color: black; width: 100%; height: 2px;" class="m-0 mb-5">
    <!-- /Divider -->
    <!-- List menu -->
    <div *ngFor="let item of data$ | async" class="px-3">
      <div *ngIf="item.items && (isLogged() && item.auth) && hasNodes(item) && ['Amministrazione','Operazioni'].includes(item.label)" class="label">
        <span class="label" *ngIf="!collapsed">{{'SIDEBAR.' + item.label | translate}}</span>
        <hr *ngIf="collapsed" />
        <ul class="list-unstyled">
          <li *ngFor="let subitem of item.items" style="cursor: pointer;">

            <a class="nav-link d-flex justify-content-between" routerLink="{{subitem.url}}" [routerLinkActiveOptions]="{exact: true}"
              routerLinkActive="active-link" (click)="goTo(subitem.url)"
              *ngIf="hasNode(subitem) && !(subitem.url == null) && !(subitem.url.indexOf('https') >= 0)">
              <div>
                <img [matTooltip]="getTooltip(subitem.label)"
                  [src]="'../../assets/img/icn_' + getIconName(subitem.label) + '.svg'" class="icon"
                  style="width: 35px;" />
                <span [matTooltip]="getTooltip(subitem.label)" *ngIf="!collapsed">{{ 'SIDEBAR.' + subitem.label |
                  translate }}</span>
              </div>
              <fa-icon [icon]="faChevronRight" class="my-auto" *ngIf="!collapsed"></fa-icon>
            </a>
            <a class="nav-link d-flex justify-content-between" target="_blank" (click)="goToExt(subitem.url)" [routerLinkActiveOptions]="{exact: true}"
              *ngIf="hasNode(subitem) && !(subitem.url == null) && (subitem.url.indexOf('https') >= 0)">
              <div>
                <img [matTooltip]="getTooltip(subitem.label)"
                  [src]="'../../assets/img/icn_' + getIconName(subitem.label) + '.svg'" class="icon"
                  style="width: 35px;" />
                <span [matTooltip]="getTooltip(subitem.label)" *ngIf="!collapsed">{{ 'SIDEBAR.' + subitem.label |
                  translate }}</span>
              </div>
              <fa-icon [icon]="faChevronRight" class="my-auto" *ngIf="!collapsed"></fa-icon>
            </a>
          </li>
        </ul>
      </div>

      <div *ngIf="!item.items" class="label">
        <ul class="list-unstyled">
          <a class="nav-link" style="cursor: pointer;" *ngIf="!collapsed">
            {{ item.label }}
          </a>
        </ul>
      </div>

    </div>

    <!-- /List menu -->
  </div>
  <!-- /Collapsible content -->
</div>


<div class="modal fade" id="userModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Debug User</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Chiudi"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <pre>{{userSessionService.getState('user') | json}}</pre>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div id="sidebar" [class.collapsed]="collapsed" class="position-fixed">
  <div class="d-flex flex-column flex-shrink-0 text-white bg-sidebar vh-100 w-100 sidebar-scrollable">
    <div class="row d-flex align-items-center py-3 px-4" id="headerSidebar" style="min-height: 100px;">
      <div class="col-4" *ngIf="!collapsed">
        <img src="../../assets/img/logo.svg" alt="logo albert 4.0" class="img-fluid">
      </div>
      <div class="col-8">
        <div class="d-flex justify-content-between">
          <span id="edacTitle" *ngIf="!collapsed">EDAC</span>
          <fa-icon [icon]="faSignOut" class="my-auto" style="cursor: pointer" (click)="signOut()" *ngIf="isLogged() && !collapsed"></fa-icon>
          <fa-icon [icon]="faSignIn" class="my-auto" style="cursor: pointer" (click)="signIn()" *ngIf="!isLogged() && !collapsed"></fa-icon>
          <fa-icon [icon]="faBars" class="my-auto" style="cursor: pointer" (click)="collapsed = !collapsed"></fa-icon>
        </div>
      </div>
    </div>
    <hr style="color: black; width: 100%; height: 2px;">
    <div *ngFor="let item of data" class="px-3">
      <div *ngIf="item.items && (isLogged() && item.auth)" class="label">
        <span class="label" *ngIf="!collapsed">{{item.label | uppercase}}</span>
        <ul class="list-unstyled">
          <li *ngFor="let subitem of item.items" style="cursor: pointer;">
            <a class="nav-link d-flex justify-content-between" (click)="goTo(subitem.url)">
              <div>
                <img [src]="'../../assets/img/icn_' + getIconName(subitem.label) + '.svg'" class="icon" style="width: 35px;"/>
                <span *ngIf="!collapsed">{{ subitem.label }}</span>
              </div>
              <fa-icon [icon]="faChevronRight" class="my-auto" *ngIf="!collapsed"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="!item.items" class="label">
        <ul class="list-unstyled">
          <a class="nav-link" style="cursor: pointer;" *ngIf="!collapsed">
            {{ item.label }}
          </a>
        </ul>
      </div>
    </div>
  </div>
</div> -->