import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
    providedIn: 'root'
})
export class workCausalDocumentTypesService extends BaseService {

    constructor(
        public LibService: LibService,
        public AuthService: AuthService,
        public userSessionService: UserSessionService,
        public guardService: GuardService
    ) {
        super(LibService, AuthService)
        this.baseApi = environment.rootApiFoundation
        this.model = 'workCausalDocumentTypes'
    }

}