import { Injectable } from '@angular/core';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../app.component';
import { LibService } from '../services/libService';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private oauthService: OAuthService) {
    this.configure();
  }

  configure() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  login() {
    this.oauthService.initLoginFlow();
  }

  logout() {
    this.oauthService.logOut();
    sessionStorage.clear();
    localStorage.clear();
  }

  refresh() { this.oauthService.silentRefresh(); }

  hasValidToken() { return this.oauthService.hasValidAccessToken(); }

  getToken() {
    return this.oauthService.getAccessToken();
  }

  idToken() {
    // Rigenera il token se non più valido
    if (!this.hasValidIdToken()) {
      try {
        this.oauthService.refreshToken()
      } catch (err) {
        console.log(err)
      }

    }

    return this.oauthService.getIdToken();
  }

  hasValidIdToken() {
    return this.oauthService.hasValidIdToken();
  }

  getJwtAsObject(): object {
    const accessToken: string = this.oauthService.getAccessToken();
    const tokens: string[] = accessToken.split('.');
    return JSON.parse(atob(tokens[1]));
  }

  refreshToken() {
    return this.oauthService.refreshToken()
  }

  user() {
    let claims: any = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims;
  }

  inMaintenance() {
    return false;
  }

}
