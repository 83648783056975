import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class BuildingIndexesService {
  baseApi = environment.rootApiCrm + 'v1.0';

  constructor(public libService: LibService) { }

  getBuildingIndex(id: string) {
    return this.libService.getData<any>(this.baseApi, `/buildingIndex/${id.toString()}`);
  }

  getAllBuildingIndexes(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, '/buildingIndexes');
  }

  createBuildingIndex(obj: any) {
    return this.libService.postData<any>(obj, this.baseApi,'/buildingIndex');
  }

  deleteBuildingIndex(idBuilding: string, obj: any) {
    return this.libService.deleteData<any>(obj, this.baseApi, `/buildingIndex/${idBuilding.toString()}`);
  }

}
