import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';
import { PhaseService } from '../services/phase.service';
import { TranslatorService } from '../services/translate.service';
import { DeleteConfirmationComponent } from '../dialogs/delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.scss']
})
export class PhasesComponent implements OnInit {

  displayedColumns = ['id', 'name','isActive','position','cMater','qProdOraria','isPercAmmaloramento','isOpereSpeciali','isAdditionalMaterialCost','isPreselected','codCountry','codLanguage','createdAt','updatedAt','editAction','deleteAction'];
  dataSource: any;
  kwSearch: any;
  title = "Fasi";
  subTitle = "EDAC - Fasi";
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('closeModal') closeModal: ElementRef
  @ViewChild('closeModal2') closeModal2: ElementRef

  constructor(public dialog: MatDialog, 
    private toastService: ToastrService,public navService: NavService, private phaseService: PhaseService, public libService: LibService, private router: Router, public translate: TranslatorService) {
    this.dataSource = new MatTableDataSource();
  }

  create() {
    this.router.navigate(['phase/0']);
  }

  ngOnInit(): void {
    this.loadPhases();
   // this.navService.setTitle(this.title);
   // this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
   /* 
   let actions: any;
    actions = [
      { name: 'new', url: 'phase/0' },
      { name: 'export', url: '' },
    ];
    this.navService.setActions(actions);
    */
  }

  deleteElement(e: any){
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {data: `${this.translate.instant('LABEL.PHASE')}: ${e.name}`})
    dialogRef.afterClosed().subscribe((result: boolean) => {
      console.log('Result: ', result)
      if(result){
        this.phaseService.deletePhase(e).subscribe({
          next(success){
            console.log(success)
          },
          error: (err) => {
            console.error(err)
            this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
          },
          complete: () => {
            this.toastService.success(
              this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
            this.loadPhases()
          }
        })
      }
    })
  }

  goTo(e: any) {
    this.router.navigate(['phase/' + e]);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  getColorOpen(p: any) {
    if (p)
      return 'color:green;';
    else
      return 'color:azure;';
  }

  getColorUsed(p: any) {
    if (p == null)
      return 'far fa-user green';
    else
      return 'fas fa-user blue';
  }

  loadPhases(): void {
    
    this.libService.lockPage('');
    this.phaseService.getAllPhases().subscribe((response) => {
      console.log('loadPhases response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
    
  }
}