<form [formGroup]="formName">

    <mat-radio-group [formControlName]="field.fieldName" [name]="field.fieldName">
        <label>{{field.label}}</label>
        <br>
        <mat-radio-button *ngFor="let option of field.options"
            [value]="option.value">{{option.label}}</mat-radio-button>
        <br><br>
    </mat-radio-group>

</form>