import { Component, EventEmitter, Input, OnChanges, AfterViewInit, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-component-fields',
  templateUrl: './component-fields.component.html',
  styleUrls: ['./component-fields.component.scss']
})
export class ComponentFieldsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() index: number = 0;
  @Input() parameter: any;

  constructor(private translate: TranslateService) { }

  get components(): FormArray {
    return <FormArray> this.form.get('components');
  }

  


  ngOnInit(): void {

  };

}
