import { Component } from '@angular/core';
import { ItemModel } from '@syncfusion/ej2-angular-splitbuttons';

@Component({
  selector: 'app-user-menu',
  template: `<!-- To render DropDownButton. -->
    <button ejs-dropdownbutton [items]='items' content='User'></button>`,
})
export class UserMenuComponent {
  public items: ItemModel[] = [
    {
      text: 'Edit'
    },
    {
      separator: true
    },
    {
      text: 'Logout'
    }
  ];
}
