import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
  ) { }

  createCategory(category: any): Observable<any> {
    return this.libService.postData<any>(category, this.baseApi, 'categories');
  }

  updateCategory(category: any): Observable<any> {
    return this.libService.putData<any>(category, this.baseApi, `categories/${category.id.toString()}`);
  }

  deleteCategory(category: any): Observable<any> {
    return this.libService.deleteData<any>(category, this.baseApi, `categories/${category.id.toString()}`);
  }

  getAllCategories(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, 'categories/');
  }

  getCategory(id: string) {
    return this.libService.getData<any>(this.baseApi, `categories/${id.toString()}`);
  }

  getCategoriesByLangCode(code:string){
    return this.libService.getData<any>(this.baseApi, `categories/langcode/${code.toString()}`);
  }
}