<div class="full-w" fxLayout="row" fxLayoutAlign="center center" *ngIf="form">
  <form [formGroup]="form">
    <mat-card>
      <mat-card-title>
        {{ 'LABEL.SUBJ' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;"
          (click)="clearForm('subject')">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>
      <div>
        <div formGroupName="subject" class="form-container">
          <app-fulltextsearch2 class="form-subject" [service]="subjectService" searchType="Soggetto"
            (selected)="selSubject($event)" [edit]="edit"></app-fulltextsearch2>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.SUBJ_TYPE' | translate }}</mat-label>
            <mat-select matNativeControl name="type" formControlName="type" [disabled]="form.get('id')?.value !== null">
              <mat-option *ngFor="let type of types" [value]="type.description">
                {{type.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="hideLegalEntity" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <mat-form-field *ngIf="hideLegalEntity" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
            <input matInput formControlName="surname">
          </mat-form-field>

          <mat-form-field *ngIf="!(hideLegalEntity)" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.MOBILE' | translate }}:</mat-label>
            <input matInput type="phone" formControlName="telephone2"
            [placeholder]="translate.instant('LABEL.INSERISCI_NUMERO_TELEFONO_MOBILE')">
            <mat-icon matSuffix>smartphone</mat-icon>
            <mat-hint>{{ 'REQUEST.MOBILE_ERROR' | translate }}</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
            <input matInput type="email" formControlName="email" [placeholder]="translate.instant('LABEL.INSERISCI_EMAIL')">
            <mat-icon matSuffix>mail_outline</mat-icon>
            <!-- <mat-hint>Inserire una email valida</mat-hint>
                        <mat-error **ngIf="(formAnagrafica.get('email')).errors?.required">L'email è obbligatoria
                        </mat-error>
                        <mat-error **ngIf="(formAnagrafica.get('email')).errors?.pattern">E' necessario inserire una
                            email valida
                        </mat-error> -->
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject"
            *ngIf="!([null, undefined].includes(form.get('subject')?.value.contactChannel))">
            <mat-label>{{ 'REQUEST.CHANNEL' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="contactChannel" [disabled]="true">
              <mat-option *ngFor="let cc of contactChannels$ | async" [value]="cc.name">
                {{ 'CHANNEL.'+cc.name | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject"
            *ngIf="!([null, undefined].includes(form.get('subject')?.value.origin))">
            <mat-label>{{ 'REQUEST.ORIGIN' | translate }}</mat-label>
            <mat-select formControlName="origin" [disabled]="true">
              <mat-option *ngFor="let origin of origins" [value]="origin.origin" [disabled]="origin.disabled">
                {{ 'ORIGINS.'+origin.code | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <app-address-search [form]="subjectForm" [edit]="edit" class="form-subject"></app-address-search>
          <app-address-detail-form [form]="subjectForm" class="form-subject"></app-address-detail-form>

          <!-- <mat-form-field appearance="fill" class="form-subject margin-top">
            <mat-label>{{ 'REQUEST.COMPANY' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="company">
              <mat-option *ngFor="let company of companies" [value]="company.code">
                {{company.name}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <mat-form-field appearance="fill" class="form-subject margin-top">
            <mat-label>{{ 'REQUEST.COMPANY' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="company">
              <mat-option *ngFor="let company of companies" [value]="company.code">
                {{company.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card>
      <mat-card-title>
        {{ 'LABEL.BUILDING' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;"
          (click)="clearForm('building')">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>
      <div>
        <div formGroupName="building" class="form-container">
          <app-fulltextsearch2 class="form-subject" [service]="buildingService" searchType="Edificio"
            (selected)="selSubject($event)" [edit]="edit"></app-fulltextsearch2>

          <app-address-search [form]="buildingForm" class="form-subject" [edit]="edit"></app-address-search>

          <app-address-detail-form [form]="buildingForm" class="form-subject"></app-address-detail-form>

          <br>

          <mat-form-field appearance="fill" class="form-subject"
            *ngIf="!([null, undefined].includes(form.get('building')?.value.contactChannel))">
            <mat-label>{{ 'REQUEST.CHANNEL' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="contactChannel" [disabled]="true">
              <mat-option *ngFor="let cc of contactChannels$ | async" [value]="cc.name">

                {{ 'CHANNEL.'+cc.name | translate }}

              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject"
            *ngIf="!([null, undefined].includes(form.get('building')?.value.origin))">
            <mat-label>{{ 'REQUEST.ORIGIN' | translate }}</mat-label>
            <mat-select formControlName="origin" [disabled]="true">
              <mat-option *ngFor="let origin of origins" [value]="origin.origin" [disabled]="origin.disabled">
                {{ 'ORIGINS.'+origin.code | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject margin-top">
            <mat-label>{{ 'REQUEST.COMPANY' | translate }}</mat-label>
            <mat-select matNativeControl formControlName="company"> <!--  -->
              <mat-option *ngFor="let company of companies" [value]="company.code">
                {{company.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
    <mat-card>
      <mat-card-title>
        {{ 'REQUEST.OTHER' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;" (click)="clearForm('')" >
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>

      <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'REQUEST.WORKTYPE' | translate }}</mat-label>
        <mat-select formControlName="workType" [compareWith]="compareFn">
          <mat-option *ngFor="let workType of wt" [value]="workType">
            {{workType.workType}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-subject">
        <mat-label>{{ 'LABEL.NOTE' | translate }}</mat-label>
        <input matInput formControlName="notes">
      </mat-form-field>

    </mat-card>
  </form>
</div>
