export interface Document{
  id?:number;
  type?: string;
  typeName?: DocumentType;
  awsPath?: string;
  name?: string;
  esit?: boolean;
  description?: string;
  sourceUrl?: any;
  userUploader?:string;
  requestId?:number;
  orderId?: number;
  createdAt:Date;
}

export interface DocumentType{
  id?: number;
  code?: string;
  uiLabel?: string;
  requireEsit?: boolean;
  isGeneric?: boolean;
  templateDocument: DocumentTemplate;
  urlDocComposition?:string;
  min?: number;
  max?: number;
  total?: number;
}

export interface DocumentCreationParams{
  file: string;
  filename: string;
  description?:string;
  type: string;
  toEntityType: string;
  toEntityId: string;
  userUploader: string;
  orderValue?:number;
  practiceId?: any;
}

export interface DocumentTemplateCreationParams{
  file: string;
  societyId?: number;
  awsPath?: string;
  typeName?: DocumentType;
}

export interface DocumentTemplate{
  id?: number;
  societyId?: number;
  awsPath?: string;
  typeName?: DocumentType;
}

export interface OrderDocument{
  id?: number;
  document?: Document;
  orderId?: number;
}
