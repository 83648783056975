import { Injectable } from '@angular/core';
import { catchError, first, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FTSearchResponse } from '../models/FTSearchResponse';
import { Subject } from '../models/subject';
import { SubjectTypes } from '../models/subject-types';
import { LibService } from './libService';
import { LogService } from './log.service';
import { UserSessionService } from './user-session.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class SubjectSubjectsService {

  baseApi = environment.rootApiCrm + 'v1.0';
  foundationApi = environment.rootApiFoundation;
  name = 'subjectSubjectsService';
  model = 'subjectSubjects'

  constructor(public libService: LibService,
    public userSessionService: UserSessionService,
    private logService: LogService,
    private authService: AuthService,
  ) { }

  conventionRequestsPending() {
    console.log("conventionRequestsPending")
    let tokenId = this.authService.idToken()

    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `/${this.model}/conventionRequestsPending`)
  }

  approvalProcess(id: number, status: boolean) {
    console.log("approvalProcess")
    let tokenId = this.authService.idToken()
    let attributes = {
      status: status
    }

    return this.libService.postDataWithAccessToken<any>(tokenId, attributes, this.baseApi, `/${this.model}/${id}/approvalProcess`)
  }

  create(attributes: any): Observable<any> {
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      attributes,
      this.baseApi,
      `/${this.model}/`
    );
  }

}
