import { Component, OnInit, ElementRef } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { ConfService } from '../../../services/conf.service';
import { ViewChild } from '@angular/core';
import { WikiService } from '../../../services/wikiservice.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { ActionService } from '../../../services/action.service';
import { ComponentService } from '../../../services/component.service';
import { ProfileService } from '../../../services/profileService';
import { ActionCategoryService } from '../../../services/actionCategory.service';
import { DocumentTypeService } from '../../../services/document-type.service'
import { CountryService } from '../../../services/country.service'
import { BranchService } from '../../../services/branch.service'
import { SubjectService } from '../../../services/subject.service'
import { TranslationGroupService } from '../../../services/translationGroup.service'
import { SubjectTypeVisibilityService } from '../../../services/subjectTypeVisibility.service'
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-subject-type-settings-form',
  templateUrl: './subject-type-settings-form.component.html',
  styleUrls: ['./subject-type-settings-form.component.scss']
})
export class SubjectTypeSettingsFormComponent implements OnInit {

  form: UntypedFormGroup;
  id: any;

  action: any = null;

  actionCategories: any = [];
  profiles: any = [];
  componentList: any = [];

  actionSettings: any = []
  documentTypes: any = []

  branches: any = []
  branchesFilter: any = []
  businessLines: any = []
  societies: any = []
  companies: any = []
  companiesFiltered: any = []
  subjectTypes: any = []
  visibilityStructure: any = []
  translationGroups: any = []

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public confService: ConfService,
    public translate: TranslatorService,
    public wikiService: WikiService,
    private actionService: ActionService,
    private componentService: ComponentService,
    private profileService: ProfileService,
    private actionCategoryService: ActionCategoryService,
    private router: Router,
    private documentTypeService: DocumentTypeService,
    private countryService: CountryService,
    private branchService: BranchService,
    private subjectService: SubjectService,
    private subjectTypeVisibilityService: SubjectTypeVisibilityService,
    private translationGroupService: TranslationGroupService,
  ) {

    this.form = this.fb.group({
      id: [],
      name: [null, Validators.required],
      keyFrontEnd: [null, Validators.required],
      actionCategoryId: [],
      branchIds: [null, Validators.required],
      societyIds: [],
      companyIds: [],
      subjectTypeIds: [null, Validators.required],
      profileIds: [null, Validators.required],
      componentIds: [],
      components: this.fb.array([])
    })
  }

  addComponentGroup(values: any): FormGroup {

    //console.log('this.actionSettings')
    //console.log(this.actionSettings)

    let attributesArray: any = []
    if (values.attributes) {
      values.attributes.forEach((attribute: any) => {
        if(!attribute.disabled) attributesArray.push(this.addAttributeGroup(attribute))
      })
    }


    let component: FormGroup = this.fb.group({
      id: [values.id],
      code: [values.code],
      label: [values.label],
      attributes: this.fb.array(attributesArray)
    });

    return component

  }



  addAttributeGroup(values: any): FormGroup {
    let parametersArray: any = []
    let parametersArrayGroup: any = []
    let tmpGroups: any = {}
    let multiple = values.multiple || false

    if (values.parameters && !multiple) {
      values.parameters.forEach((parameter: any) => {
        parametersArray.push(this.addParameterGroup(parameter, values.id))
      })
    }

    if (values.parameters && multiple) {
      values.parameters.forEach((parameter: any) => {
        let tmpValue = this.actionSettings.filter((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.key == parameter.key && actionSetting.ComponentAttributeId == values.id)
        if (tmpValue) {
          tmpValue.forEach((row: any) => {
            if (!tmpGroups[row.groupCode]) tmpGroups[row.groupCode] = []

            tmpGroups[row.groupCode].push(this.addParameterGroupCode(parameter, row, row.groupCode))
          })
        } else {

        }

      })
    }

    Object.entries(tmpGroups).forEach(object => {
      const [key, value]: any = object;
      parametersArrayGroup.push(
        this.fb.group({
          codeGroup: key,
          parameters: this.fb.array(value),
        })
      )
    })

    let active = this.actionSettings.filter((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.ComponentAttributeId == values.id)

    return this.fb.group({
      id: [values.id],
      code: [values.code],
      label: [values.label],
      groupCodes: this.fb.array(parametersArrayGroup),
      parameterStructure: [values.parameters],
      parameters: this.fb.array(parametersArray),
      active: active?.length > 0,
      multiple: [multiple]
    });
  }

  addParameterGroupCode(values: any, value: any, groupCode: any = null): FormGroup {

    let tmpValue = value || null
    let tmpGroupCode = groupCode || values.groupCode
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
      groupCode: [tmpGroupCode]
    });
  }

  addParameterGroup(values: any, componetAttributeId: any): FormGroup {
    let tmpValue = this.actionSettings.find((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.key == values.key && actionSetting.ComponentAttributeId == componetAttributeId)
    tmpValue = tmpValue || null
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
    });
  }

  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }

  get components(): FormArray {
    return <FormArray>this.form.get('components');
  }

  addActionSettings(attribute: any) {
    console.log('addActionSettings');
    let groupCode = uuidv4();
    let parametersArray: any = []
    let parameterStructure = attribute.get('parameterStructure').value
    if (parameterStructure) {
      parameterStructure.forEach((parameter: any) => {
        let tmpStructure = this.addParameterGroupCode(parameter, null, groupCode)
        parametersArray.push(tmpStructure)
      })
    }


    attribute.get('groupCodes').push(this.fb.group({
      codeGroup: groupCode,
      parameters: this.fb.array(parametersArray),
    }))
    console.log(attribute)
  }

  componentAttributes(index: number): FormArray {
    return this.components.at(index).get("attributes") as FormArray
  }

  getDocumentTypes() {
    this.documentTypeService.getAll().subscribe((response: any) => {
      this.documentTypes = response;
    });

  }

  getActionCategories() {
    this.actionCategoryService.index().subscribe((response: any) => {
      this.actionCategories = response
    })
  }

  getProfiles() {
    this.profileService.getAllProfiles().subscribe((response: any) => {
      this.profiles = response
    })
  }

  getComponentList() {
    this.componentService.index().subscribe((response: any) => {
      this.componentList = response
    })
  }

  setStructure(subjectTypeIds: any) {
    console.log(this.form)
    let structure: any = {
      id: this.form?.get('id')?.value,
      name: this.form?.get('name')?.value,
      isDeveloper: false,
      keyFrontEnd: this.form?.get('keyFrontEnd')?.value,
      ActionCategoryId: this.form?.get('actionCategoryId')?.value,
      profileIds: this.form?.get('profileIds')?.value,
      components: [],
      actionSettings: [],
      subjectTypeVisibilities: [],
    }

    console.log(this.form?.get('companyIds')?.value)
    console.log(this.form?.get('societyIds')?.value)
    let currentVisibilities: any = []

    let companyIds = this.form?.get('companyIds')?.value
    let societyIds = this.form?.get('societyIds')?.value
    let branchIds = this.form?.get('branchIds')?.value

    // Aggiunta delle company, se presenti
    if (companyIds && companyIds.length != 0) {
      companyIds.forEach((id: any) => {
        this.visibilityStructure.filter((structure: any) => structure.companyId == id).forEach((vis: any) => {
          currentVisibilities.push({
            companyId: vis.companyId,
            societyId: vis.societyId,
            branchId: vis.branchId
          })
        })

      })
    }

    // Aggiunta delle societies, solo se non già presenti
    if (societyIds && societyIds.length != 0) {
      societyIds.forEach((id: any) => {
        this.visibilityStructure.filter((structure: any) => structure.societyId == id).forEach((vis: any) => {
          let checkPresence = currentVisibilities.map((current: any) => `${current.societyId}_${current.branchId}`)
          let key = `${vis.societyId}_${vis.branchId}`
          if (!checkPresence.includes(key)) {
            currentVisibilities.push({
              societyId: vis.societyId,
              branchId: vis.branchId,
            })
          }

        })

      })
    }

    // Aggiunta delle businessLines, solo se non già presenti
    if (branchIds && branchIds.length != 0) {
      branchIds.forEach((id: any) => {
        this.visibilityStructure.filter((structure: any) => structure.branchId == id).forEach((vis: any) => {
          let checkPresence = currentVisibilities.map((current: any) => current.branchId)
          if (!checkPresence.includes(vis.branchId)) {
            currentVisibilities.push({
              branchId: vis.branchId,
            })
          }
        })

      })
    }


    // Aggiunta visibilità 
    console.log(currentVisibilities)
    subjectTypeIds.forEach((id: any) => {
      currentVisibilities.forEach((str: any) => {
        let tmpStructure = { ...str }
        tmpStructure.subjectTypeId = id
        structure.subjectTypeVisibilities.push(tmpStructure)
      });
    })



    // Setta Componenti
    let componentIds = this.form?.get('componentIds')?.value || []
    componentIds.forEach((componentId: any) => {
      structure.components.push({
        componentId: componentId
      })
    })


    // Setta action settings solo componenti selezionati
    let components = this.form?.get('components')?.value
    let currentComponents = components.filter((component: any) => componentIds.includes(component.id))

    currentComponents.forEach((component: any) => {
      component.attributes.forEach((attribute: any) => {
        if (attribute.active) {
          console.log(attribute)

          if (!attribute.multiple) {
            attribute.parameters.forEach((parameter: any) => {
              structure.actionSettings.push({
                key: parameter.key,
                value: parameter.value,
                type: parameter.type,
                ComponentAttributeId: attribute.id
              })
            })
          } else {
            attribute.groupCodes.forEach((groupCode: any) => {
              groupCode.parameters.forEach((parameter: any) => {
                structure.actionSettings.push({
                  key: parameter.key,
                  value: parameter.value,
                  type: parameter.type,
                  ComponentAttributeId: attribute.id,
                  groupCode: parameter.groupCode
                })
              })
            })
          }

        }
      })
    })
    return structure
  }

  update() {
    this.libService.lockPage('');
    let subjectTypeIds = this.form?.get('subjectTypeIds')?.value || []
    let structure = this.setStructure(subjectTypeIds);
    console.log(structure)


    this.subjectTypeVisibilityService.createSettings(structure).subscribe((response: any) => {
      //console.log(response)
      this.toastr.success(
        'ok', this.translate.instant('LABEL.Info'));

      this.id = response.id
      this.form?.get('id')?.setValue(this.id)
      this.router.navigate(['/settings/subject-type-settings/', this.id]);
      this.libService.unlockPage();
    })

  }

  showCategory(id: any) {
    let componentIds: any = this.form?.get('componentIds')?.value || []
    return componentIds.includes(id)
  }

  back() {
    this.router.navigate(['settings/subject-type-settings/']);
  }

  initComponents() {
    this.componentService.full().subscribe((response: any) => {
      console.log(response)
      response.forEach((component: any) => {
        if(!component.disabled) this.components.push(this.addComponentGroup(component))
      })
      console.log(this.form)
      this.libService.unlockPage();
    })
  }

  ngOnInit(): void {
    this.libService.lockPage('');
    this.id = this.route.snapshot.paramMap.get('id');

    this.getSubjectTypes();
    this.initBranches();
    this.initTranslationGroups();
    this.getDocumentTypes();
    this.getActionCategories()
    this.getProfiles()
    this.getComponentList()


    console.log('action settings')
    console.log(this.id)

    this.actionService.settingsForSubjectTypes(this.id).subscribe((response: any) => {
      if (response) {
        this.action = response
        if (response?.settings) this.actionSettings = response?.settings
        this.form?.get('id')?.setValue(this.id)
        this.form?.get('name')?.setValue(this.action?.name)
        this.form?.get('keyFrontEnd')?.setValue(this.action?.keyFrontEnd)
        this.form?.get('actionCategoryId')?.setValue(this.action?.ActionCategoryId)
        this.form?.get('profileIds')?.setValue(this.action?.profiles.map((profile: any) => profile.id))

        let componentIds: any = []
        let subjectTypeIds: any = []
        let branchIds: any = []
        let societyIds: any = []
        let companyIds: any = []

        this.action?.componentsActions.forEach((componentsAction: any) => {
          let currentComponentId = componentsAction.ComponentId
          let currentsubjectTypeVisibilities = componentsAction.subjectTypeVisibilities

          currentsubjectTypeVisibilities.forEach((subjectTypeVisibilities: any) => {
            let currentSubjectTypeId = subjectTypeVisibilities.SubjectTypeId

            let currentBusinessLineId = subjectTypeVisibilities.BusinessLineId
            let currentSocietyId = subjectTypeVisibilities.SocietyId
            let currentCompanyId = subjectTypeVisibilities.CompanyId

            if (!componentIds.includes(currentComponentId)) componentIds.push(currentComponentId)
            if (!subjectTypeIds.includes(currentSubjectTypeId)) subjectTypeIds.push(currentSubjectTypeId)

            if (!branchIds.includes(currentBusinessLineId)) branchIds.push(currentBusinessLineId)
            if (!societyIds.includes(currentSocietyId)) societyIds.push(currentSocietyId)
            if (!companyIds.includes(currentCompanyId)) companyIds.push(currentCompanyId)
          })

        })

        this.form?.get('subjectTypeIds')?.setValue(subjectTypeIds)
        this.form?.get('componentIds')?.setValue(componentIds)

        this.form?.get('branchIds')?.setValue(branchIds)
        this.form?.get('societyIds')?.setValue(societyIds)
        this.form?.get('companyIds')?.setValue(companyIds)

        this.initComponents()
        this.getSocietiesAndCountries(branchIds)


      } else {
        this.initComponents()
        this.libService.unlockPage();
      }
    })

  }


  setCode() {
    if ([undefined, null, 0, '0'].includes(this.id)) {
      let label = this.form?.get('name')?.value
      let code = `actions_${this.snakeCase(label)}`
      this.form?.get('keyFrontEnd')?.setValue(code)
    }
  }

  snakeCase(string: any) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word: any) => word.toLowerCase())
      .join('_');
  };

  removeGroup(indexController: any, indexAttribute: any, indexGroup: any) {
    console.log(indexController)
    console.log(indexAttribute)
    console.log(indexGroup)
    console.log(this.components.controls[indexController])
    let currentController = this.components.controls[indexController]
    let currentAttributes: FormArray = currentController?.get('attributes') as FormArray
    let currentAttribute = currentAttributes.controls[indexAttribute]
    let currentGroups: FormArray = currentAttribute?.get('groupCodes') as FormArray
    currentGroups.removeAt(indexGroup)
  }

  initSocieties(countryCode: any) {
    console.log('INIT SOCIETIES')
    this.countryService.determinesConfiguration(countryCode).subscribe((response) => {
      response.forEach((configuration: any) => {

        let tmpSociety: any = { id: configuration.societyId, code: configuration.societyCode }
        let societyIds = this.societies.map((society: any) => society.id)

        if (!societyIds.includes(tmpSociety.id)) this.societies.push(tmpSociety)
      })
    });
  }

  initBusinessLines(countryCode: any) {
    console.log('INIT BUSINESS LINES')
    let currentBusinessLineId: any = this.form.get('businessLineId')?.value
    this.countryService.getBusinessLines(countryCode).subscribe((response) => {
      console.log(response)
      response.forEach((configuration: any) => {

        let tmpbusinessLine: any = { id: configuration.businessLineId, code: configuration.businessLineCode }
        let businessLineIds = this.businessLines.map((businessLine: any) => businessLine.id)

        if (!businessLineIds.includes(tmpbusinessLine.id)) this.businessLines.push(tmpbusinessLine)
      })
      this.initBusinessLineCode(currentBusinessLineId)
    });
  }

  initBusinessLineCode(businessLineId: any) {
    let currentBusinessLineCode = this.businessLines.find((businessLine: any) => businessLine.id == businessLineId)
    this.form.get('businessLineCode')?.setValue(currentBusinessLineCode?.code)
  }

  initBranches() {
    this.branchService.getAllBranches().subscribe((res: any) => {
      console.log(res)
      this.branches = res
      this.branchesFilter = res.filter((branch: any) => branch.isActive)
    })
  }

  initTranslationGroups() {
    this.translationGroupService.index().subscribe((res: any) => {
      this.translationGroups = res
    })
  }

  initSubjectTypes(subjectTypeIds: any) {
    let currentBusinessLineCode = this.subjectTypes.find((subjectType: any) => subjectTypeIds.includes(subjectType.id))
    this.form.get('businessLineCode')?.setValue(currentBusinessLineCode?.code)
  }


  getSocietiesByBusinessLines(event: any) {
    console.log(event)
    console.log('getSocieties')
    this.libService.lockPage('');
    let businessLineIds = event.value
    /*
        this.resetFormValues([
          { formField: 'societyId', selectCollection: 'societies' },
          { formField: 'businessLineId', selectCollection: 'businessLines' },
        ]) // Reset form values
        */

    this.getSocietiesAndCountries(businessLineIds)

  }

  getSocietiesAndCountries(businessLineIds: any) {
    this.branchService.getSocietiesAndCountries(businessLineIds).subscribe((response: any) => {

      let tmpSocieties: any = []
      let tmpSocietyIds: any = []
      let tmpCompanies: any = []
      let tmpCompanyIds: any = []

      this.visibilityStructure = response

      response.forEach((item: any) => {
        let currentSociety = {
          id: item.societyId,
          code: item.societyCode,
          name: item.societyName,
          type: item.societyType,
          branchId: item.brachId
        }
        let currentCompany = {
          id: item.companyId,
          name: item.companyName,
          code: item.companyCode,
          societyId: item.societyId,
          branchId: item.brachId
        }
        if (!tmpSocietyIds.includes(currentSociety.id)) tmpSocieties.push(currentSociety)
        tmpSocietyIds.push(currentSociety.id)

        if (!tmpCompanyIds.includes(currentCompany.id)) tmpCompanies.push(currentCompany)
        tmpCompanyIds.push(currentCompany.id)
      })

      this.societies = tmpSocieties
      this.companies = tmpCompanies

      this.setCompaniesVisibility()

      this.libService.unlockPage();
    })
  }

  companiesFilter(event: any) {
    let societyIds = event.value
    this.setCompaniesVisibility()
  }

  setCompaniesVisibility() {
    let currentSocietyIds = this.form?.get('societyIds')?.value || []
    this.companiesFiltered = this.companies.filter((company: any) => currentSocietyIds.includes(company.societyId))
  }

  getSubjectTypes() {
    this.subjectService.getAllTypes().subscribe((response: any) => {
      console.log(response)
      this.subjectTypes = response;
    })
  }


}

