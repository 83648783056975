import { Injectable } from '@angular/core';
import { Observable, catchError, first, forkJoin, iif, last, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { Store, StreamList } from '../models/store';

@Injectable({
  providedIn: 'root',
})
export class ConfService {
  baseApi = environment.rootApiFoundation + 'confs';
  fbApi = environment.rootApiFoundation + 'finalBalancerConfs';
  configStore = new StreamList<any>('conf', []);

  constructor(public libService: LibService) {}

  getConf(id: string) {
    return this.libService.getData<any>(this.baseApi, `/${id.toString()}`);
  }

  getGlobalConfs(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, '');
  }

  getFbConfs(): Observable<any> {
    return this.libService.getData<any>(this.fbApi, '');
  }

  getAllConfs(refresh?: boolean): Observable<any> {
    const isLoaded = this.configStore.loaded && !refresh;
    const $confStream = this.configStore.$stream.pipe(first());
    const $confs = forkJoin([this.getGlobalConfs(), this.getFbConfs()]).pipe(
      last(),
      tap((confs) => this.configStore.save(confs)),
      catchError((error) => this.libService.handleError(error))
    );
    return iif(() => isLoaded, $confStream, $confs);
  }

  updateConf(id: string, conf: any) {
    return this.libService.putData<any>({ conf: conf }, this.baseApi, `/${id}`);
  }

  updateFbConf(id: string, conf: any) {
    return this.libService.putData<any>({ conf: conf }, this.fbApi, `/${id}`);
  }

  saveFbConf(id: string, conf: any) {
    return iif(
      () => id != (null || undefined),
      this.updateFbConf(id, conf),
      this.createFbConf(conf)
    );
  }

  createConf(conf: string) {
    return this.libService.postData<any>({ conf: conf }, this.baseApi, '');
  }

  createFbConf(conf: string) {
    return this.libService.postData<any>({ conf: conf }, this.fbApi, '');
  }
}
