import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AbsenceModuleService extends BaseService {

  constructor(
    public LibService: LibService,
    public AuthService: AuthService,
    public userSessionService: UserSessionService,
    public guardService: GuardService
  ) {
    super(LibService, AuthService)
    this.baseApi = environment.rootApiFoundation
    this.model = 'absenceModules'
  }
    // Metodo per ottenere i contratti con paginazione
    absenceModuleWithPagination(filters: any, skip: number, take: number): Observable<any> {
      const params = {filters, skip, take };
      return this.libService.postDataWithAccessToken<any>(this.tokenId, params,  this.baseApi, `${this.model}/absenceModuleWithPagination`);
    }
    settings(id: any): Observable<any> {
      return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/${id}/settings`);
    }
}