import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ClaimService } from 'src/app/services/claim.service';
import { LibService } from 'src/app/services/libService';

@Component({
  selector: 'app-claim-form',
  templateUrl: './claim-form.component.html',
  styleUrls: ['./claim-form.component.scss']
})
export class ClaimFormComponent implements OnInit {

  @Input() item: any;
  @Output() closeModal = new EventEmitter;

  form: FormGroup;

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private claimService: ClaimService,
    private libService: LibService,
  ) {
  }

  ngOnInit(): void {

    // Creazione Form
    this.form = this.fb.group({
      id: [null],
      version: [null],
      marshNumberClaim: [null, Validators.required],
      orderNumberEA: [null, Validators.required],
      orderVersion: [null],
      policyNumber: [null],
      delegatingCompany: [null],
      mainWarranty: [null],
      claimCompanyNumber: [null],
      complainant: [null],
      description: [null],
      type: [null],
      typeDescription: [null],
      causeDescription: [null],
      claimStatus: [null],
      claimStatusDescription: [null],
      location: [null],
      province: [null],
      damageValue: [null],
      deductible: [null],
      due: [null],
      paid: [null],
      paidFromEA: [null],
      paymentDateFromEA: [null],
      activities: [null],
      status: [null],
      documents: [null],
      startDate: [null],
      endDate: [null],
    })

    this.form.patchValue(this.item)
  }

  ngOnChanges(changes: SimpleChanges): void { }

  update() {
    //console.log(this.form)
    this.libService.lockPage('');
    this.claimService.updateClaim(this.item?.id?.toString(), this.form.getRawValue()).subscribe((res: any) => {
      //console.log(res);
      this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
      this.closeModal.emit(true);
      this.libService.unlockPage();
    })
  }

  fullname(){
    let complainant : any = this.item?.complainant
    return `${complainant?.name} ${complainant?.surname}`
  }

}