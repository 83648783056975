import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentCreationParams, DocumentTemplate } from '../models/document';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplateService {

  baseApi = environment.rootApiCrm + 'v1.0/documentTemplate/'

  constructor(private libService: LibService) { }

  upload(document: DocumentCreationParams): Observable<DocumentTemplate>{
    return this.libService.postData<DocumentTemplate>(document, this.baseApi, '')
  }

  getManyByDocumentTypeAndSociedyId(documentTypeIds: number[], societyId: string){
    return this.libService.postData<DocumentTemplate[]>({documentTypeIds, societyId}, this.baseApi, 'getManyByDocumentTypeAndSociedyId')
  }
}
