<div class="full-w">

  <form [formGroup]="form" (submit)="update()" class="form-container">

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.STATUS' | translate }}</mat-label>
      <input disabled matInput [value]="item.status.code" [placeholder]="translate.instant('COMPLAINTS.Company')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Pratica EDAC' | translate }}</mat-label>
      <input matInput disabled [value]="item.orderNumberEA"
        [placeholder]="translate.instant('COMPLAINTS.Pratica EDAC')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Giudice ATP' | translate }}</mat-label>
      <input matInput disabled [value]="item.magistrateATP"
        [placeholder]="translate.instant('COMPLAINTS.Giudice ATP')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Stragiudiziali' | translate }}</mat-label>
      <input matInput disabled [value]="item.outOfCourt"
        [placeholder]="translate.instant('COMPLAINTS.Stragiudiziali')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Data provvedimento' | translate }}</mat-label>
      <input matInput disabled [matDatepicker]="pickerMeasureDate" formControlName="measureDate" />
      <mat-datepicker-toggle matSuffix [for]="pickerMeasureDate"></mat-datepicker-toggle>
      <mat-datepicker #pickerMeasureDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Anno' | translate }}</mat-label>
      <input matInput disabled [value]="item.year"
        [placeholder]="translate.instant('COMPLAINTS.Anno')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'LEGALS.MEDIATION_AGREEMENT' | translate }}</mat-label>
      <input matInput disabled [value]="item.mediationAgreement"
        [placeholder]="translate.instant('LEGALS.MEDIATION_AGREEMENT')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Compagnia' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Compagnia')" formControlName="company">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Citta Compagnia' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Citta Compagnia')" formControlName="companyCity">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Provincia' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Provincia')" formControlName="province">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Soggetto Promotore' | translate }}</mat-label>
      <input matInput disabled [value]="item.promotingSubject"
        [placeholder]="translate.instant('COMPLAINTS.Soggetto Promotore')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Studio Legale' | translate }}</mat-label>
      <input matInput [placeholder]="translate.instant('COMPLAINTS.Studio Legale')" formControlName="lawFirm">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Aggiornamento Dai Legali' | translate }}</mat-label>
      <textarea matInput [placeholder]="translate.instant('COMPLAINTS.Aggiornamento Dai Legali')" formControlName="legalUpdates"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Note' | translate }}</mat-label>
      <textarea matInput [placeholder]="translate.instant('COMPLAINTS.Note')" formControlName="note"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Spese Legali' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Spese Legali')" formControlName="legalOutgoings">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'COMPLAINTS.Spese Accordi' | translate }}</mat-label>
      <input matInput type="number" [placeholder]="translate.instant('COMPLAINTS.Spese Accordi')" formControlName="agreementOutgoings">
    </mat-form-field>

    <div class="buttonBox">
      <button class="submitButton" type="submit" mat-raised-button color="primary">
        <mat-icon>save</mat-icon> {{ 'LABEL.SAVE' | translate }}
      </button>
    </div>
    <br>
  </form>

</div>