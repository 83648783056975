import { Component, Input, OnInit } from '@angular/core';

import { GenericModal } from '../modals/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ComponentService } from '../../services/component.service';
import { PracticeService } from '../../services/practice.service';
import { StateService } from '../../services/state.service';
import { LibService } from '../../services/libService';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';



@Component({
  selector: 'app-practice-button-complaint-opening',
  templateUrl: './practice-button-complaint-opening.component.html',
  styleUrls: ['./practice-button-complaint-opening.component.scss']
})
export class PracticeButtonComplaintOpeningComponent implements OnInit {



  @Input() form: FormGroup;
  @Input() isNew: any;
  @Input() components: any;
  @Input() practice: any;

  attributes: any;
  componentName: any = 'complaintOpeningButton'
  objectStructure: any = {}
  settings: any = {}


  constructor(
    private dialog: MatDialog,
    private componentService: ComponentService,
    private libService: LibService,
    private practiceService: PracticeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private stateService: StateService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    console.log('ngOnInit request close button')
  }

  setAttributes() {
    //console.log('setAttributes')
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
    if (this.attributes) this.objectStructure = this.componentService.getObjectStructure(this.attributes)
    //console.log('this.objectStructure')
    //console.log(this.objectStructure)
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) {
      this.setAttributes()
    }
    return status
  }


  getState(stateId: any) {

    if (stateId) {
      this.stateService.getState(stateId).subscribe((response: any) => {
        this.setComponents(response.components)
        this.setAttributes()
        this.getSettings()
        this.openModal(this.settings)
        this.libService.unlockPage();
      })
    } else {
      this.setComponents(null)
      this.setAttributes()
    }
  }

  getSettings() {
    let code = 'restorePreviousState'
    this.settings.titleLabel = 'CHANGESTATEBUTTON.' + code + '_title',
      this.settings.textLabel = 'CHANGESTATEBUTTON.' + code + '_text'
  }

  setComponents(components: any) {
    //console.log('setComponents')
    this.components = { codes: [], settings: [] }
    if (components != null) {
      Object.keys(components).forEach(key => {
        let tmpComponent = {
          componentCode: key,
          attributes: components[key]?.attributes
        }
        this.components.codes.push(key)
        this.components.settings.push(tmpComponent)
      });
    }
  }

  openModalForNonProgrammable(e: any, OrderId: any) {

    // Ottieni i component della pratica
    console.log(e)

    let code = e.CodicePratica
    this.libService.lockPage('');
    this.practiceService.showByAttributes({ code: code }).subscribe((response: any) => {
      this.practice = response
      this.getState(response.StateId)
    })

  }

  openModal(settings: any) {
    console.log(settings)
    const dialogRef = this.dialog.open(GenericModal, {
      width: '600px',
      data: settings
    });

    dialogRef.afterClosed().subscribe(result => {

      if (![null, undefined].includes(result)) {
        let attributes = result;
        attributes.componentName = this.componentName
        this.libService.lockPage('');
        this.practiceService.restorePreviousState(this.practice.id).subscribe(
          ret => {
            this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
            this.libService.unlockPage();
            window.location.reload()
          },
          err => {
            //this.toastr.error(this.translate.instant('LABEL.Error'));
            this.libService.unlockPage();
          }
        );
      }


    });
  }

  goTo() {
    this.router.navigate(['request/0'], { queryParams: { type: 3, practiceCode: this.practice.code } });
  }




}
