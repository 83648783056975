
<!--
  <h1 mat-dialog-title>{{ "PLANNER.reasonNonProgrammableOrders" | translate }}</h1>
-->
<div mat-dialog-content>


  <form [formGroup]="form" class="form-container">
  <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
    <mat-label>{{ 'PLANNER.motivazione' | translate }}</mat-label>
    <mat-select  matNativeControl formControlName="ReasonId">
      <mat-option *ngFor="let reason of data.reasonsList" [value]="''+reason.id">
        <!-- | async -->
        {{ "PLANNER."+ reason.code | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Note</mat-label>
    <textarea matInput rows="5" formControlName="ReasonNotes" placeholder="Note"></textarea>
  </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="!form.valid">{{ "LABEL.SAVE" | translate }}</button>
  <button mat-button [mat-dialog-close]="">{{ "LABEL.CANCEL" | translate }}</button>
</div>