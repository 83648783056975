import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  baseApi = environment.rootApiFoundation;
  public title: any = '';
  public subTitle: any = '';
  public linkWiki: any = '';
  public actions: any = [
    'detail',
    'new',
    'upload',
    'export',
    'export_in_page',
    'back',
  ];
  public conf: any = {};
  public wikiKey: any = '';

  constructor(public libService: LibService) {}

  getTitle() {
    return this.title;
  }

  setTitle(title: any) {
    this.title = title;
  }

  getWikiKey() {
    return this.wikiKey;
  }

  setWikiKey(wikiKey: any) {
    //console.log('set wiki key %o',wikiKey)
    this.wikiKey = wikiKey;
  }

  getConf() {
    return this.conf;
  }

  setConf(conf: any) {
    this.conf = conf;
  }

  getSubTitle() {
    return this.subTitle;
  }

  setSubTitle(subTitle: any) {
    this.subTitle = subTitle;
  }

  getLinkWiki() {
    return this.linkWiki;
  }

  setLinkWiki(linkWiki: any) {
    this.linkWiki = linkWiki;
  }

  getActions() {
    return this.actions;
  }

  setActions(actions: any) {
    this.actions = actions;
  }

  resetActions() {
    this.actions = [];
  }
}
