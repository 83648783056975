import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, FormArray } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { TranslatorService } from 'src/app/services/translate.service';



@Component({
  selector: 'edit-operators-dialog',
  templateUrl: './edit-operators-dialog.html',
  styleUrls: ['./edit-operators-dialog.scss'],
})
export class EditOperatorsDialogComponent implements OnInit {
  faSync = faSync;
  filteredResources: any[];
  resourcesData: any[];
  availableResources: any[][] = [];; // questa proprietà viene impiegata per tenere traccia delle risorse disponibili
  availableResourcesFiltered: any[][] = [];
  filteredResourcesToAdd: any[][] = [];  // per filtrare resourcesToAdd
  //aggiunti per modifica ricerca (codice sottostante)
  orderSearchControl = new FormControl();
  resourceToAddSearchControl = new FormControl();
  resourceToReplaceSearchControl = new FormControl();
  filteredOrders: any[];

  resourcesToReplaceSearchControls: FormControl[] = [];
  resourcesToAddSearchControls: FormControl[] = [];

  showRoleMismatchWarning: boolean = false;



  constructor(
    public dialogRef: MatDialogRef<EditOperatorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public translate: TranslatorService
  ) { }

  form: any = this.fb.group({
    orderId: [null, [Validators.required]],
    resources: this.fb.array([]),
    startDate: [new Date(), [Validators.required]],
    endDate: [null, []],
  }, { validator: this.dateRangeValidator }) as FormGroup<EditOperatorsForm<EditOperatorsFormValue>>;

  maxEndDate: Date; // Aggiungi questa proprietà
  minEndDate: Date = new Date;
  todayDate: Date = new Date;
  isValid: boolean;



  public dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.get('startDate')?.value || !control.get('endDate')?.value) {
      return null;
    }


    let startDate: any = control?.get('startDate')?.value
    let endDate: any = control?.get('endDate')?.value
    let startDateMoment = moment(startDate).utc(true).hours(0).minutes(0).seconds(0).milliseconds(0)
    let endDateMoment = moment(endDate).utc(true).hours(23).minutes(59).seconds(59).milliseconds(59)
    const isValid = startDateMoment.isBefore(endDateMoment)

    if (!isValid) {
      control?.get('startDate')?.setErrors({ notEquivalent: true })
      control?.get('endDate')?.setErrors({ notEquivalent: true })
      return { invalidDateRange: true };
    } else {
      control?.get('startDate')?.setErrors(null)
      control?.get('endDate')?.setErrors(null)
    }

    return null;
  }


  ngOnInit() {

    this.filteredOrders = this.data.orders;

    this.form.get('startDate')?.valueChanges.subscribe(() => {
      this.minEndDate = this.form.controls.startDate.value;
    });

    this.form.get('orderId')?.valueChanges.subscribe((selectedOrderIds: any) => {
      const filteredResources = this.filterResourcesToReplaceByOrderIds(selectedOrderIds);
      this.resources.controls.forEach((group, index) => {
        this.availableResources[index] = filteredResources;
        this.filteredResourcesToAdd[index] = this.data.resources;
        this.availableResourcesFiltered[index] = filteredResources; // Aggiungi questa riga
      });
    });

    this.orderSearchControl.valueChanges.subscribe(value => {
      this.filteredOrders = this.data.orders.filter(order =>
        !value || order.code.toLowerCase().includes(value.toLowerCase())
      );
    });

    this.form.get('resourceToAdd')?.valueChanges.subscribe((resourceToadd: any) =>{
      console.log;
    })

    this.form.get('resources')?.valueChanges.subscribe(() => {
      this.checkAllRoleMismatches();
    });


    this.initResources();
  }


  checkAllRoleMismatches() {
    this.showRoleMismatchWarning = this.resources.controls.some((group, index) => {
      const resourceToReplaceId = group.get('resourcesToReplace')?.value;
      const resourceToAddId = group.get('resourcesToAdd')?.value;

      const resourceToReplace = this.getResourceById(resourceToReplaceId);
      const resourceToAdd = this.getResourceById(resourceToAddId);

      return resourceToReplace && resourceToAdd && resourceToReplace.idRuolo === 36 && resourceToAdd.idRuolo === 35;
    });
  }


  filterResourcesByRole(roleId: number) {
    return this.data.resources.filter(resource => resource.idRuolo === roleId);
  }


  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.form.valid) {
      const formValue = this.enrichPayloadWithRoleIds(this.form.value);
      this.dialogRef.close(formValue);
    }
  }



  checkRoleMismatch(groupIndex: number) {
    const resourceGroup = this.resources.at(groupIndex) as FormGroup;
    const resourceToReplaceId = resourceGroup.get('resourcesToReplace')?.value;
    const resourceToAddId = resourceGroup.get('resourcesToAdd')?.value;

    const resourceToReplace = this.getResourceById(resourceToReplaceId);
    const resourceToAdd = this.getResourceById(resourceToAddId);

    if (resourceToReplace && resourceToAdd && resourceToReplace.idRuolo !== resourceToAdd.idRuolo) {
      this.showRoleMismatchWarning = true;
    } else {
      this.showRoleMismatchWarning = false;
    }
    console.log('qui controllo')
    console.log(this.showRoleMismatchWarning)
  }

  private enrichPayloadWithRoleIds(formValue: any): any {
    return {
      ...formValue,
      resources: formValue.resources.map((resource: any) => {
        const resourceToReplace = this.getResourceById(resource.resourcesToReplace);
        const resourceToAdd = this.getResourceById(resource.resourcesToAdd);
  
        return {
          ...resource,
          resourceToReplaceRuoloId: resourceToReplace ? resourceToReplace.idRuolo : null,
          resourceToAddRuoloId: resourceToAdd ? resourceToAdd.idRuolo : null
        };
      })
    };
  }
  
  private getResourceById(id: number): any {
    return this.data.resources.find((res: any) => res.id === id);
  }
  get resources(): FormArray {
    return <FormArray>this.form.get('resources');
  }


  initResources() {
    this.resources.push(this.createResourceGroup());
  }

  createResourceGroup(): FormGroup {
    const groupIndex = this.resources.length;
    const group = this.fb.group({
      resourcesToReplace: [null, [Validators.required]],
      resourcesToAdd: [null, [Validators.required]],
    });

    this.availableResources[groupIndex] = this.filterResourcesToReplaceByOrderIds(this.form.get('orderId')?.value || []);
    this.filteredResourcesToAdd[groupIndex] = this.data.resources;
    this.availableResourcesFiltered[groupIndex] = this.availableResources[groupIndex]; // Aggiungi questa riga

    const resourceToReplaceSearchControl = new FormControl();
    const filteredResourcesToAdd = new FormControl();

    this.resourcesToReplaceSearchControls.push(resourceToReplaceSearchControl);
    this.resourcesToAddSearchControls.push(filteredResourcesToAdd);

    resourceToReplaceSearchControl.valueChanges.subscribe(value => {
      this.availableResourcesFiltered[groupIndex] = this.availableResources[groupIndex].filter(res =>
        !value || res.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    filteredResourcesToAdd.valueChanges.subscribe(value => {
      this.filteredResourcesToAdd[groupIndex] = this.data.resources.filter(res =>
        !value || res.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    return group;
  }

  filterResourcesToReplaceByOrderIds(orderIds: number[]): any[] {

      let taskUserIds = this.data.tasks
      .filter(task => orderIds.includes(task.orderIdId))
      .map(task => {
        return task.userId
      });
      let resources =  this.data.resources.filter((resource) =>  taskUserIds.includes(resource.id));
      console.log(taskUserIds)
      console.log(resources)
     
    return this.filterUniqueResources(resources, orderIds);
    //return(resources)
  }


  filterUniqueResources(resources: any[], selectedOrderIds: number[]): any[] {
    // Crea un oggetto per tracciare quante volte ogni risorsa appare per i codici commessa selezionati
    const resourceCount = resources.reduce((acc, resource) => {
      if (!acc[resource.id]) {
        acc[resource.id] = new Set();
      }
      acc[resource.id].add(resource.orderId);
      return acc;
    }, {});

    // Filtra le risorse per includere solo quelle che appaiono in tutti i codici commessa selezionati
    const filteredResources = resources.filter(resource => {
      return resourceCount[resource.id].size === selectedOrderIds.length;
    });

    // Rimuove i duplicati
    const uniqueResources = [];
    const seenIds = new Set();
    for (const resource of filteredResources) {
      if (!seenIds.has(resource.id)) {
        uniqueResources.push(resource);
        seenIds.add(resource.id);
      }
    }
  
    return uniqueResources;
  }
  
  

  addResources(values: any): FormGroup {
    let resources: FormGroup = this.fb.group(values);
    return resources
  }

  removeResourceGroup(index: number): void {
    this.resources.removeAt(index);
    this.availableResources.splice(index, 1);  // Rimuovi le risorse disponibili corrispondenti
    this.filteredResourcesToAdd.splice(index, 1); // Rimuovi le risorse filtrate corrispondenti

  }

}


interface DialogData {
  currentUserRole: any;
  orders: any[];
  resources: any[];
  tasks: any[];
}
export interface EditOperatorsFormValue {
  orderId: string | null;
  resources: [{
    resourcesToReplace: string | null,
    resourcesToAdd: string | null
  }] | null;
  startDate: Date;
  endDate: Date;

}
interface ResourcesFormGroup<T extends EditOperatorsFormValue> {
  resourcesToReplace: FormControl<['resourcesToReplace']>;
  resourcesToAdd: FormControl<['resourcesToAdd']>;
}

interface EditOperatorsForm<T extends EditOperatorsFormValue> {
  orderId: FormControl<T['orderId']>;
  resources: FormArray<FormGroup<ResourcesFormGroup<T>>>;
  startDate: FormControl<T['startDate']>;
  endDate: FormControl<T['endDate']>;
}