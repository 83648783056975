import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ReviewService } from 'src/app/services/review.service';
import { LibService } from 'src/app/services/libService';
import { OriginService } from 'src/app/services/origin.service';
import { ReviewEsitTypeService } from 'src/app/services/reviewEsitType.service';
import { combineLatest } from 'rxjs';
import { SubjectService } from 'src/app/services/subject.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/auth/auth.service';

interface formInterface {
  id: number,
  practiceId: number,
  esit: boolean,
  reviewEsitType: number,
  description: string,
  authorId: number,
  itemId: number,
  stateId: number,
  origin: origin,
  subject: subject,
  insertSubjectType: number,
  subjectAlias?: string
};

interface origin {
  code: string
  disabled: boolean,
  id: number,
  origin: string
}

interface subject {
  id?: number,
  name?: string,
  surname?: string,
  legalEntity?: string,
  email?: string,
  telephone2?: string,
}

@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.scss']
})
export class ReviewFormComponent implements OnInit {

  @Input() item: any;
  @Output() closeModal = new EventEmitter;

  form: FormGroup;
  reviewEsitTypes: any = []

  origins: Array<origin> = []

  origins$ = this.originService.getAllOrigins();
  reviewEsitTypes$ = this.reviewEsitTypeService.index();

  userName = this.authService.user().name;

  reviewEsitTypesFiltered: any = []

  practices: any = []

  insertSubjectTypes: any = [
    'search', 'new', 'alias'
  ]

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private reviewService: ReviewService,
    private libService: LibService,
    private originService: OriginService,
    private reviewEsitTypeService: ReviewEsitTypeService,
    public subjectService: SubjectService,
    public orderService: OrderService,
    public authService: AuthService,
  ) {
  }

  getInsertSubjectType() {
    return this.form.get('insertSubjectType')?.value;
  }


  selSubject(event: any) {
    this.form?.get('subject')?.patchValue(event)
    this.researchSubjectPractices()
  }

  getSubject() {
    return this.form?.get('subject')?.value;
  }

  researchSubjectPractices() {
    let subjectId = this.form.get('subject')?.value?.id
    if (subjectId) {
      this.libService.lockPage('');
      this.orderService.getOrderBySubjectId(this.userName, subjectId, 'subject').subscribe((res: any) => {
        this.practices = res
        this.libService.unlockPage();
      })
    }
  }

  setReviewEsitTypesVisibility(esit: any) {
    //console.log('setReviewEsitTypesVisibility', esit)
    this.reviewEsitTypesFiltered = this.reviewEsitTypes.filter((reviewEsitType: any) => reviewEsitType.esit == esit)
  }

  radioChange(event: any) {
    //console.log('radioChange')
    let value = event.value
    this.setReviewEsitTypesVisibility(value)
    this.form?.get('reviewEsitTypeValue')?.setValue(null)
  }

  ngOnInit(): void {

    combineLatest([this.reviewEsitTypes$, this.origins$]).subscribe(([reviewEsitTypes, origins]) => {
      this.reviewEsitTypes = reviewEsitTypes
      if (![null, undefined].includes(this.item?.esit)) this.setReviewEsitTypesVisibility(this.item?.esit)
      this.origins = origins
    })

    // Creazione Form
    this.form = this.fb.group({
      id: [null],
      practiceId: [null],
      esit: [null, [Validators.required]],
      reviewEsitType: [null],
      reviewEsitTypeValue: [null, [Validators.required]],
      description: [null],
      authorId: [null],
      itemId: [null],
      stateId: [null],
      origin: [null],
      originValue: [null, [Validators.required]],
      subject: this.fb.group({
        id: [null],
        name: [null],
        surname: [null],
        legalEntity: [null],
        email: [null],
        telephone2: [null],
      }),
      insertSubjectType: [null],
      subjectAlias: [null]
    })

    this.form.patchValue(this.item)
    this.form?.get('originValue')?.setValue(this.item?.origin?.id)
    this.form?.get('reviewEsitTypeValue')?.setValue(this.item?.reviewEsitType?.id)
    this.setReviewEsitTypesVisibility(this.item?.esit)
    this.setInsertSubjectType()
    this.researchSubjectPractices()
    //console.log(this.form)


  }

  insertSubjectTypeChange(event: any) {
    //console.log('insertSubjectTypeChange')
    //console.log(event)
    this.form.get('subject')?.reset();
    this.form.get('subjectAlias')?.reset();
    this.form.get('practiceId')?.reset();
  }

  setInsertSubjectType() {
    let value = 'search'
    if (this.form?.get('subjectAlias')?.value) value = 'alias'
    this.form?.get('insertSubjectType')?.setValue(value)
  }

  ngOnChanges(changes: SimpleChanges): void { }

  checkduplicate() {

    let item = this.form?.get('subject')?.value
    let insertSubjectType = this.form?.get('insertSubjectType')?.value
    let call$ = this.subjectService.findSubjectByPhoneAndMail(item)
    let id = item?.id
    let message = ''
    if (id === null) {
      call$.subscribe((response: any) => {
        //console.log(response)
        if (response && insertSubjectType == 'new') {
          message += this.translate.instant('TOASTR.MESSAGE17') + (item.name ? item.name : '') + ' ' + (item.surname ? item.surname : '') + this.translate.instant('TOASTR.MESSAGE18');
          if (message != '') this.toastr.error(message, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
        } else {
          this.createOrUpdate()
        }

      })
    } else {
      this.createOrUpdate()
    }

  }

  createOrUpdate() {
    this.libService.lockPage('');
    let id = this.form?.get('id')?.value || null

    // Select current Origin & ReviewEsitType
    let originValue = this.form?.get('originValue')?.value
    let reviewEsitTypeValue = this.form?.get('reviewEsitTypeValue')?.value

    let selectedReviewEsitType = this.reviewEsitTypesFiltered.find((type: any)=> type.id == reviewEsitTypeValue)
    let selectedOrigin = this.origins.find((origin: any)=> origin.id == originValue)

    this.form?.get('origin')?.setValue(selectedOrigin)
    this.form?.get('reviewEsitType')?.setValue(selectedReviewEsitType)

    let formValues = this.form.getRawValue()
    if(formValues.insertSubjectType == 'alias') formValues.subject = null

    if (id) {
      this.reviewService.update(this.item?.id?.toString(), formValues).subscribe((res: any) => {
        //console.log(res);
        this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
        this.closeModal.emit(true);
      })
    } else {
      this.reviewService.create(formValues).subscribe((res: any) => {
        //console.log(res);
        this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
        this.closeModal.emit(true);
        this.libService.unlockPage();
      })
    }
  }

  save() {
    this.checkduplicate()

  }

  itIsAFreeSubject() {
    return this.form?.get('freeSubject')?.value || false
  }

  theSubjectIsPresent() {
    return this.form?.get('subject')?.value || false
  }

  fullname() {
    let complainant: any = this.item?.complainant
    return `${complainant?.name} ${complainant?.surname}`
  }

}