import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypesService  {

  baseApi = environment.rootApiPaperwork + 'orderStatus';

  constructor(public libService: LibService) { }

  getDocTypes() {
    return this.libService.getData<any>(this.baseApi, `/doctypes`);
  }

}