<div class="container-fluid">
  <!--
    <div class="buttons">
      <div class="buttons-right">
        <button (click)="goTo({id: 0})" type="button" class="btn btn-primary"><span style="color:#293683;">+</span></button>
      </div>
    </div>
-->
  <!-- <div class="search-wrapper mat-elevation-z2"> -->
  <!-- <div class="search" fxLayout="row" fxLayoutAlign="start center"> -->
  <!-- <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
        <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
          placeholder="Cerca" /> -->
  <!-- <app-fulltextsearch [service]="searchService" [dropdown]="true" (list)="updateList($event)"
    (clearOut)="clearList($event)" (selected)="goTo($event)"></app-fulltextsearch> -->
  <app-fulltextsearch2 [events]="eventsSubject" [service]="searchService" searchType="Soggetto" (list)="updateList($event)" (clearOut)="clearList($event)" (selected)="goTo($event)"></app-fulltextsearch2>
  <!-- </div> -->
  <!-- </div> -->
  <div style="margin:5px;">

  </div>
  <div style="overflow-x: auto;">
    <app-building-filter [type]="searchType" (filterResult)="filterResultEmitted($event)"></app-building-filter>
    <app-data-table
      [data]="dataSourceBuilding"
      [columns]="columns"
      [displayedColumns]="displayedColumns"
      [actions]="actions"
      sortActive=""
      ></app-data-table>
      <!--
      sortDirection="desc" -->
    <!-- <table mat-table [dataSource]="dataSource" matSort style="width:100%">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Id</th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element?.id}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Nome</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span *ngIf="!(element?.name == null) && element?.name.length > 0"><b>{{element?.name}}</b></span>
          <span *ngIf="!(element == null) && (element?.name == null)"><b>{{element?.streetName}}&nbsp;{{element?.streetNumber}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="responsible">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Responsabile</th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element?.responsible}} </td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Sede</th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element?.company}} </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Tipo</th>
        <td class="tdg" mat-cell *matCellDef="let element"> {{element?.type}}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Stato</th>
        <td class="tdg" mat-cell *matCellDef="let element"> {{element?.status?.description}}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Email</th>
        <td class="tdg" mat-cell *matCellDef="let element"> {{element?.email}}
        </td>
      </ng-container>
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Città</th>
        <td class="tdg" mat-cell *matCellDef="let element"> {{element?.city}}
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Data creazione</th>
        <td class="tdg" mat-cell *matCellDef="let element"> {{element?.createdAt | date}}
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Data ultima modifica</th>
        <td class="tdg" mat-cell *matCellDef="let element"> {{element?.updatedAt | date}}
        </td>
      </ng-container>
      <ng-container matColumnDef="editAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="goTo(element)"><i class=" my-bounce material-icons icsave">edit</i>
      </ng-container>
      <ng-container matColumnDef="deleteAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><i class="my-bounce material-icons icsave">delete</i>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table> -->
  </div>
  <!-- <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
  </mat-paginator> -->
</div>

<ng-template #nameCell let-col="col" let-element="element">
  <div *ngIf="!(element === null) && (element?.legalEntity);then legalEntity else nameOrAddress"></div>
  <ng-template #legalEntity><b>{{element?.legalEntity}}</b></ng-template>
  <ng-template #nameOrAddress>
    <span *ngIf="!(element === null) && (element?.name === null)"><b>{{element?.streetName}}&nbsp;{{element?.streetNumber}}</b></span>
    <span *ngIf="!(element === null) && (element?.name !== null)"><b>{{element?.name}}&nbsp;{{element?.surname}}</b></span>
  </ng-template>

</ng-template>
