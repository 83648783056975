import { UserRoleUtils } from './userRole';

export class ResourcePlan {
  public id: number;
  public userRoleId: number;
  public idRuolo: any;
  public userRoleName: number;
  public name: string;
  public email: string;
  public username: string;
  public role: string;

  public get level(): string {
    return UserRoleUtils.defineLabel(this.userRoleId);
  }

  constructor(resource: any, userRoleId: number) {
    this.id = resource.id;
    this.userRoleId = userRoleId;
    this.name = resource.name;
    this.email = resource.email;
    this.username = resource.username;
    this.role = resource.role;
  }
}
