import { Component, OnInit, ElementRef } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { ConfService } from '../../../services/conf.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { TranslationGroupService } from '../../../services/translationGroup.service'
import { TranslationCategoryService } from '../../../services/translationCategory.service'
import { SettingsModalFormComponent } from '../../settings-modal-form/settings-modal-form.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-translation-settings-form',
  templateUrl: './translation-settings-form.component.html',
  styleUrls: ['./translation-settings-form.component.scss']
})
export class TranslationSettingsFormComponent implements OnInit {

  form: UntypedFormGroup;
  id: any;

  translationGroup: any = null;

  translationGroups: any = []
  translationCategories: any = []
  translationCategoriesFilter: any = []

  translationList: any = []

  languageCodeList: any = [
    {
      label: 'Italiano',
      code: 'IT'
    },
    {
      label: 'Français',
      code: 'FR'
    },
    {
      label: 'Español',
      code: 'ES'
    },
    {
      label: 'English',
      code: 'EN'
    },
  ]

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public confService: ConfService,
    public translate: TranslatorService,
    private router: Router,
    private translationGroupService: TranslationGroupService,
    private translationCategoryService: TranslationCategoryService,
    private matDialog: MatDialog,
  ) {}


  addTranslation(values: any): FormGroup {

    let translation: FormGroup = this.fb.group(values);

    return translation

  }


  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }


  get translations(): FormArray {
    return <FormArray>this.form.get('translations');
  }

  update() {
    this.libService.lockPage('');
    console.log(this.form.value)


    this.translationGroupService.createSettings(this.form.value).subscribe((response: any) => {
      //console.log(response)
      this.toastr.success(
        'ok', this.translate.instant('LABEL.Info'));

      this.id = response.id
      this.form?.get('id')?.setValue(this.id)
      this.router.navigate(['/settings/translation-settings/', this.id]);
      this.libService.unlockPage();
    })

  }

  back() {
    this.router.navigate(['settings/translation-settings/']);
  }


  addTranslationCategories(item: any = null) {
    const dialogRef = this.matDialog.open(SettingsModalFormComponent, {
      width: '600px',
      data: {
        service: this.translationCategoryService,
        model: {
          label: {
            type: "text",
            value: item?.label,
            label: "Label",
            rules: {
              required: true,
            }
          },
          code: {
            type: "text",
            value: item?.code || 'translation_categories_',
            label: "Code",
            rules: {
              required: true,
            }
          },
          disabled: {
            label: "Disabled",
            value: item?.disabled,
            type: "radio",
            options: [
              {
                label: "yes",
                value: 1,
              },
              {
                label: "no",
                value: 0,
              },
            ],
            rules: {
              required: true,
            }
          },
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (![null, undefined].includes(result)) {
        this.saveForm(result)
      }
    });

  }

  saveForm(attributes: any) {
    console.log(attributes)
    if (attributes) this.initTranslationCategories()
  }

  initTranslations() {

    this.languageCodeList.forEach((lang: any) => {
      let currentTranslation = this.translationList.find((translation: any) => translation.languageCode == lang.code)
      let structure: any = {
        id: [null],
        languageCode: [lang.code],
        text: [''],
      }

      if (currentTranslation?.id) structure.id = currentTranslation?.id
      if (currentTranslation?.languageCode) structure.languageCode = currentTranslation?.languageCode
      if (currentTranslation?.text) structure.text = currentTranslation?.text

      this.translations.push(this.addTranslation(structure))
    })
  }

  ngOnInit(): void {
    this.libService.lockPage('');
    this.id = this.route.snapshot.paramMap.get('id');

    // Creazione Form
    this.form = this.fb.group({
      id: [],
      label: [null, Validators.required],
      code: [null, Validators.required],
      disabled: [false],
      translationCategoryId: [null, Validators.required],
      translations: this.fb.array([])
    })

    this.initTranslationCategories();
    this.initTranslationGroups();

    console.log('translation settings')
    console.log(this.id)

    if (['0', 0, null, undefined].includes(this.id)) {
      this.initTranslations()
      this.libService.unlockPage();
    } else {
      this.initForm()
    }

  }

  initForm() {
    this.translationGroupService.settings(this.id).subscribe((response: any) => {
      if (response) {
        this.translationGroup = response
        this.translationList = this.translationGroup?.translations

        this.form?.get('id')?.setValue(this.id)
        this.form?.get('label')?.setValue(this.translationGroup?.label)
        this.form?.get('code')?.setValue(this.translationGroup?.code)
        this.form?.get('translationCategoryId')?.setValue(this.translationGroup?.translationCategory?.id)
        this.form?.get('disabled')?.setValue(this.translationGroup?.disabled)

        this.initTranslations()
        console.log(this.form)

      } else {
        this.initTranslations()
        this.libService.unlockPage();
      }
    })
  }


  setCode() {
    if ([undefined, null, 0, '0'].includes(this.id)) {
      let label = this.form?.get('label')?.value
      let code = `translation_groups_${this.snakeCase(label)}`
      this.form?.get('code')?.setValue(code)
    }
  }

  snakeCase(string: any) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word: any) => word.toLowerCase())
      .join('_');
  };

  initTranslationCategories() {
    this.translationCategoryService.index().subscribe((res: any) => {
      console.log(res)
      this.translationCategories = res
    })
  }

  initTranslationGroups() {
    this.translationGroupService.index().subscribe((res: any) => {
      this.translationGroups = res
    })
  }

}