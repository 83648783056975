<div class="container-fluid my-container">
  <div class="buttons my-2">
    <h3 style="margin-bottom:15px;">{{ 'REQUEST.REQUEST' | translate }}
      <span *ngIf="itIsAConventionRequest()" style="color:#55ca12;"><br>{{getConventionLabel() | translate }}</span>
    </h3>
    
    <div class="buttons-right">
      <mat-icon class="check-ok" *ngIf="formRequest.valid && checkComponentOrNew('request_check_form')">check</mat-icon>
      <mat-icon [matTooltip]="getErrorTooltip()" class="check-ko"
        *ngIf="!formRequest.valid && checkComponentOrNew('request_check_form')">warning</mat-icon>
      <button *ngIf="hide && checkComponentOrNew('request_save_button')" style="background-color:transparent;"
        [disabled]="!formRequest.valid || (selectedIndex == 2)" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>
      <button *ngIf="!hide" style="background-color:transparent;"
        [disabled]="!formRequest.valid || (selectedIndex == 2)" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="updateComplaints()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>

      <!-- COMPONENTE BOTTONE CONVENZIONE -->
      <div>
        <button *ngIf="checkAddedConvention()" matTooltip="Add Convention" type="button"
          class="my-bounce btn addConventionButton mx-2" (click)="openAddConventionModal()">
          {{ 'LABEL.addConvention' | translate }}
        </button>
      </div>


      <!-- COMPONENTE BOTTONE ANNULLA SOPRALLUOGO -->
      <app-request-cancel-button [form]="formRequest" *ngIf="formRequest" [isNew]="isNew" [documents]="documents"
        [components]="components"></app-request-cancel-button>

      <!-- COMPONENTE BOTTONE CLIENTE CONTATTATO-->
      <app-request-customer-contacted-button [form]="formRequest" *ngIf="formRequest" [isNew]="isNew"
        [documents]="documents" [components]="components"></app-request-customer-contacted-button>

      <!-- COMPONENTE BOTTONE CHIUSURA SOPRALLUOGO -->
      <app-request-close-button [form]="formRequest" *ngIf="formRequest" [isNew]="isNew" [documents]="documents"
        [components]="components"></app-request-close-button>

    </div>
  </div>
  <hr>
  <mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="onTabChanged($event)"
    [selectedIndex]="selectedIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">person</mat-icon>
        <span class="ml-1">{{ 'LABEL.REGISTRY' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <app-request-data *ngIf="formRequest && components" [practiceCode]="practiceCode" [companies]="companies"
        [isNew]="isNew" [form]="formRequest" [users]="operatorList" [edit]="edit" [components]="components"
        (hideSaveButton)="hideSaveButton($event)"></app-request-data>
    </mat-tab>

    <mat-tab [disabled]="isNew" *ngIf="checkComponent('request_document_manager')">
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">folder</mat-icon>
        <span class="ml-1">{{ 'LABEL.Documenti' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <app-request-document-manager [form]="formRequest" *ngIf="formRequest" [disableUpload]="disableUpload"
        [isNew]="isNew" [components]="components"></app-request-document-manager>
    </mat-tab>

    <mat-tab [disabled]="isNew" *ngIf="checkComponent('crmDocumentManager')">
      <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">folder</mat-icon>
        <span class="ml-1">{{ 'LABEL.Documenti' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
          class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>

      <app-crm-document-manager *ngIf="checkComponent('crmDocumentManager') && formRequest"
        [disableUpload]="disableUpload" [components]="components.settings" [documents]="documents" [form]="formRequest"
        [itemType]="itemType" [itemId]="itemId"></app-crm-document-manager>

      <app-documents-grid fxFill [documents]="documents"></app-documents-grid>

    </mat-tab>

  </mat-tab-group>

  <div class="buttons-right my-2">
    <mat-icon class="check-ok" *ngIf="formRequest.valid && checkComponentOrNew('request_check_form')">check</mat-icon>
    <mat-icon [matTooltip]="getErrorTooltip()" class="check-ko"
      *ngIf="!formRequest.valid && checkComponentOrNew('request_check_form')">warning</mat-icon>
    <button *ngIf="hide && checkComponentOrNew('request_save_button')" style="background-color:transparent;"
      [disabled]="!formRequest.valid || (selectedIndex == 2)" matTooltip="Salva" type="button"
      class="my-bounce btn btn-primary mx-2" (click)="update()">
      <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>
    <button *ngIf="!hide" style="background-color:transparent;" [disabled]="!formRequest.valid || (selectedIndex == 2)"
      matTooltip="Salva" type="button" class="my-bounce btn btn-primary mx-2" (click)="updateComplaints()">
      <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>

    <!-- COMPONENTE BOTTONE CONVENZIONE -->
    <div>
      <button *ngIf="checkAddedConvention()" matTooltip="Add Convention" type="button"
        class="my-bounce btn addConventionButton mx-2" (click)="openAddConventionModal()">
        {{ 'LABEL.addConvention' | translate }}
      </button>
    </div>

    <!-- COMPONENTE BOTTONE ANNULLA SOPRALLUOGO -->
    <app-request-cancel-button [form]="formRequest" *ngIf="formRequest" [isNew]="isNew" [documents]="documents"
      [components]="components"></app-request-cancel-button>

    <!-- COMPONENTE BOTTONE CLIENTE CONTATTATO-->
    <app-request-customer-contacted-button [form]="formRequest" *ngIf="formRequest" [isNew]="isNew"
      [documents]="documents" [components]="components"></app-request-customer-contacted-button>

    <!-- COMPONENTE BOTTONE CHIUSURA SOPRALLUOGO -->
    <app-request-close-button [form]="formRequest" *ngIf="formRequest" [isNew]="isNew" [documents]="documents"
      [components]="components"></app-request-close-button>

  </div>
</div>