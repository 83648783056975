export const statusChanges = {
    changes: [
        {
            from: 38,//deliberata
            to: 18,//programmata
            checkClose:false
        },
        {
            from: 7,//pianificata
            to: 13,//in_esecuzione
            checkClose:false
        },
        {
            from: 37,//consegnato_al_cliente
            to: 38,// ex 61 DELIBERATA_DA_CONTROLLARE
            checkClose:false
        },
        {
            from: 18,//programmata
            to: 7,//pianificata
            checkClose:false
        },
        {
            from: 13,//IN_ESECUZIONE
            to: 24,//ESEGUITA
            checkClose:false
        },
        {
            from: 63,//CONSEGNATA_AL_CLIENTE_OVER
            to: 62,//DELIBERATA_DA_CONTROLLARE_OVER
            checkClose:false
        },
        {
            from: 24,//eseguita
            to: 66,//CHIUSA
            checkClose:false
        },
        {
            from: 13,//IN_ESECUZIONE
            to: 11,//IN_ESECUZIONE_SOSPESA
            checkClose:false
        },
        {
            from: 11,//IN_ESECUZIONE_SOSPESA
            to: 13,//in_esecuzione
            checkClose:false
        },
        {
            from: 61,//DELIBERATA_DA_CONTROLLARE
            to: 38,//deliberata
            checkClose:false
        },
        {
            from: 62,//DELIBERATA_DA_CONTROLLARE_OVER
            to: 38,//deliberata
            checkClose:false
        },
        {
            from: 64,//DA_VERIFICARE_<20K
            to: 38,//deliberata
            checkClose:false
        },
        {
            from: 65,//DA_VERIFICARE_>20K
            to: 38,//deliberata
            checkClose:false
        },
        {
            from: 2,//garanzia_eseguita
            to: 13,//in_esecuzione
            checkClose:false
        },
        {
            from: 2,//garanzia_eseguita
            to: 24,//eseguita
            checkClose:false
        },
        {
            from: 38,//deliberata
            to: 67,//deliberata_sospesa
            checkClose:false
        },
        {
            from: 67,//deliberata_sospesa
            to: 38,//deliberata
            checkClose:true
        },
        {
            from: 7,//pianificata
            to: 68,//pianificata_sospesa
            checkClose:false
        },
        {
            from: 68,//pianificata_sospesa
            to: 7,//pianificata
            checkClose:false
        },
        {
            from: 18,//programmata
            to: 69,//programmata_sospesa
            checkClose:false
        },
        {
            from: 69,//programmata_sospesa
            to: 18,//programmata
            checkClose:true
        },
        {
            from: 13,//in_esecuzione
            to: 70,//in_esecuzione_sospesa
            checkClose:false
        },
        {
            from: 70,//in_esecuzione_sospesa
            to: 13,//in_:esecuzione
            checkClose:true
        },
        {
            from: 13,//in_esecuzione
            to: 22,//annullata
            checkClose:false
        },
        {
            from: 18,//programmata
            to: 22,//annullata
            checkClose:false
        },
        {
            from: 7,//pianificata
            to: 22,//annullata
            checkClose:false
        },
        {
            from: 38,//deliberata
            to: 22,//annullata
            checkClose:false
        }
    ]

}