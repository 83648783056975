import { Injectable } from '@angular/core';
import {
  finalize,
  first,
  forkJoin,
  Observable,
  tap,
  EMPTY,
  iif,
  map,
  combineLatest,
  shareReplay,
  concat,
  last,
  switchMap,
} from 'rxjs';
import { Task } from 'src/app/final-balancer/models/final-balance';
import { StreamList, StreamQuery } from 'src/app/models/store';
import { LibService } from 'src/app/services/libService';
import { OrderService } from 'src/app/services/order.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { UserDataService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { MultiTask } from '../models/multi-tasks';
import { OrderPlan, OrderSelect } from '../models/order-plan';
import { OrderTask, TaskDates } from '../models/order-tasks';
import { OrderStatus } from '../models/orderStatus-enum';
import { ResourcePlan } from '../models/resource-plan';
import { TeamLeaderTask } from '../models/teamLeader-task';
import { UserRole } from '../models/userRole';
import { PhasePlan } from '../models/phase-plan';

@Injectable()
export class PlannerService {
  private baseApi = environment.rootApiPaperwork;
  // STORES
  public ordersStore: StreamList<OrderPlan> = new StreamList<OrderPlan>(
    'orders',
    []
  );
  public teamLeadersStore: StreamList<any> = new StreamList<any>(
    'teamLeaders',
    []
  );
  public assistantsStore: StreamList<any> = new StreamList<any>(
    'assistants',
    []
  );
  public multiservicesStore: StreamList<any> = new StreamList<any>('multi', []);
  public searchQuery: StreamQuery = new StreamQuery('searchQuery');

  // STORES GETTERS
  public get $orders(): Observable<OrderPlan[]> {
    return this.ordersStore.$stream;
  }
  public get $ordersCore(): Observable<OrderPlan[]> {
    return this.ordersStore.$stream.pipe(
      map((orders) => orders.filter((order) => !order.isMultiservice))
    );
  }
  public get $ordersMulti(): Observable<OrderPlan[]> {
    return this.ordersStore.$stream.pipe(
      map((orders) => orders.filter((order) => order.isMultiservice))
    );
  }
  public get $ordersSelect(): Observable<OrderSelect[]> {
    return this.$ordersCore.pipe(
      map((orders) => orders.map((order) => new OrderSelect(order)))
    );
  }
  public get $ordersMultiSelect(): Observable<OrderSelect[]> {
    return this.$ordersMulti.pipe(
      map((orders) => orders.map((order) => new OrderSelect(order)))
    );
  }
  public get $teamLeaders(): Observable<ResourcePlan[]> {
    return this.teamLeadersStore.$stream;
  }
  public get $assistants(): Observable<ResourcePlan[]> {
    return this.assistantsStore.$stream;
  }
  public get $multi(): Observable<ResourcePlan[]> {
    return this.multiservicesStore.$stream;
  }
  public get $coreResources(): Observable<ResourcePlan[]> {
    return combineLatest([this.$teamLeaders, this.$assistants]).pipe(
      first(),
      shareReplay(),
      map(([teamLeaders, assistants]) =>
        new Array(...teamLeaders, ...assistants).sort(
          (a: ResourcePlan, b: ResourcePlan) => (a.name > b.name ? 1 : -1)
        )
      )
    );
  }

  // GETTERS
  public get dataFetched(): boolean {
    return this.ordersStore.length > 0 && this.userId;
  }
  public get user() {
    return this.userSessionService.getState('user');
  }
  public get userEmail(): string {
    return this.user.email;
  }
  public get userId(): any {
    return this.user.id;
  }
  public get companyId(): number {
    const company = this.userSessionService.getState('working_company');
    if (company !== null && company.name !== '*') {
      return company.id;
    } else return 0;
  }
  public get isAdmin(): boolean {
    if (this.user == null) return false;
    const isRa = this.user.UsersProfilesRel.find(
      (u: any) =>
        u.code.includes('PRD-RA') ||
        u.code.includes('PRD-CO') ||
        u.code.includes('PRD-CI') ||
        u.code.includes('HQ-IT') ||
        u.code.includes('HQ-AM') ||
        u.code.includes('HQ-BRD') ||
        u.code.includes('AFF')
    );
    return isRa ? true : false;
  }

  public get $changeCompanyDetector(): Observable<boolean> {
    return this.userSessionService.$changeCompanyDetector;
  }

  constructor(
    private userSessionService: UserSessionService,
    private userDataService: UserDataService,
    public orderService: OrderService,
    private libService: LibService
  ) {}

  // METODS
  private $getResources(role: number): Observable<any> {
    return this.userDataService
      .getResourcesByCompany(this.userEmail, this.userId, role, this.companyId)
      .pipe(
        map((resources) =>
          resources.map((resource) => new ResourcePlan(resource, role))
        )
      );
  }

  private $getOrders(): Observable<any> {
    return this.orderService
      .getAdminOrdersPlanning(this.userEmail, this.userId, this.companyId)
      .pipe(
        map((orders) =>
          orders
            .map((order: any) => new OrderPlan(order))
            .sort(this.sortByStatus)
        )
      );
  }

  private $loadStores(): Observable<any[]> {
    return forkJoin([
      this.$getResources(UserRole.TEAMLEADER),
      this.$getResources(UserRole.ASSISTANCE),
      this.$getResources(UserRole.MULTI),
      [],
      // this.$getOrders(),
    ]).pipe(
      tap(([teamLeaders, assistants, multiservices, orders]) => {
        this.ordersStore.save(orders);
        this.teamLeadersStore.save(teamLeaders);
        this.assistantsStore.save(assistants);
        this.multiservicesStore.save(multiservices);
      })
    );
  }

  public $initStores(): Observable<any[]> {
    this.libService.lockPage('');
    return iif(() => this.companyId > 0, this.$loadStores(), EMPTY).pipe(
      first(),
      finalize(() => this.libService.unlockPage())
    );
  }

  public $refreshOrderTasks(): Observable<any> {
    return this.$getOrders().pipe(
      tap((orders) => this.ordersStore.save(orders)),
      switchMap(() => this.$getTasksByOrders())
    );
  }

  public $refreshMultiTasks(): Observable<any> {
    return this.$getOrders().pipe(
      tap((orders) => this.ordersStore.save(orders)),
      switchMap(() => this.$getMultiTasks())
    );
  }

  public $getTasksByOrders(): Observable<(TeamLeaderTask | OrderTask)[]> {
    return this.$ordersCore.pipe(
      map((orders) => this.defineTasksByOrder(orders))
    );
  }

  public $getMultiTasks(): Observable<(TeamLeaderTask | OrderTask)[]> {
    return this.$ordersMulti.pipe(
      map((orders) => this.defineMultiTasks(orders))
    );
  }

  public $planOrder(payload: {
    orderid: number;
    resources: any[];
    from: Date;
    hours?: number;
  }) {
    this.libService.lockPage('');
    return this.libService
      .postData(payload, this.baseApi, 'orders/planOrder')
      .pipe(
        first(),
        finalize(() => this.libService.unlockPage())
      );
  }

  public $planMultiOrder(payload: {
    orderid: number;
    resources: any[];
    from: Date;
    hours?: number;
    who: string;
    recurrence: number;
    rows: any[];
  }) {
    this.libService.lockPage('');
    return this.libService
      .postData(payload, this.baseApi, 'orders/planOrderMulti')
      .pipe(
        first(),
        finalize(() => this.libService.unlockPage())
      );
  }

  public $resizeOrder(payload: {
    orderid: number;
    deltaStart: number;
    deltaEnd: number;
  }): Observable<any> {
    return this.libService
      .postData(payload, this.baseApi, 'orders/resizeOrder')
      .pipe(first());
  }

  public $moveOrder(payload: {
    orderid: number;
    delta: number;
  }): Observable<any> {
    return this.libService
      .postData(payload, this.baseApi, 'orders/moveOrder')
      .pipe(first());
  }

  public $getSuggestion(
    users: number[],
    year: number,
    month: number,
    day: number
  ) {
    return this.libService.postData<any>(
      {},
      this.baseApi,
      `tasks/suggestions/?us=${users}&ye=${year}&mo=${month}&da=${day}`
    );
  }

  public $updateOrder(tasksToSave: Array<{ mode: string; task: TaskDates }>) {
    const updatesCalls: Observable<any>[] = tasksToSave.map(
      ({ mode, task }) => {
        // calculate startDate and endDate comparing them to the initial dates
        const deltaStart: number = this.libService.getDaysDelta(
          task.start_date,
          task.start_date_compare
        );
        const deltaEnd: number = this.libService.getDaysDelta(
          task.end_date,
          task.end_date_compare
        );
        const orderid = task.orderid;
        if (mode == 'resize') {
          return this.$resizeOrder({ orderid, deltaStart, deltaEnd });
        } else {
          return this.$moveOrder({ orderid, delta: deltaStart });
        }
      }
    );
    this.libService.lockPage('');
    return concat(...updatesCalls).pipe(
      last(),
      finalize(() => this.libService.unlockPage())
    );
  }

  // UTILS
  private sortByStatus(a: any, b: any) {
    if (a.idStatus === OrderStatus.progg && b.idStatus !== OrderStatus.progg) {
      return -1;
    }
    if (a.idStatus !== OrderStatus.progg && b.idStatus === OrderStatus.progg) {
      return 1;
    }
    return a.idStatus - b.idStatus;
  }

  private defineTasksByOrder(orders: OrderPlan[]): any[] {
    const teamLeadersNode = new Map();
    const orderNode = new Map();
    // to get all the tasks ordered by teamleader cycle through the orders,
    // find the teamleader for each phase and associate the tasks in which he participates
    orders.forEach((order: OrderPlan) => {
      order.phases.forEach((phase) => {
        // define phase Teamleader
        const teamleader: TeamLeaderTask | null = phase.getTeamLeader();
        // if phase has a teamleader add all tasks
        if (teamleader) {
          // if teamleader not exist as node add it to the teamleaders node list
          const teamLeaderLoaded: boolean = teamLeadersNode.has(
            teamleader?.name
          );
          !teamLeaderLoaded && teamLeadersNode.set(teamleader.name, teamleader);
          // define orderTask by teamleader's associated tasks
          const orderNodeKey: string = `${order.code}-${teamleader.name}`;
          const orderTaskLoaded: boolean = orderNode.has(orderNodeKey);
          !orderTaskLoaded &&
            orderNode.set(orderNodeKey, new OrderTask(order, teamleader.id));
        }
      });
    });

    const teamleadersList = Array.from(teamLeadersNode.values()).sort((a, b) =>
      a.text > b.text ? 1 : -1
    );
    const ordersList = Array.from(orderNode.values()).sort((a, b) =>
      a.text > b.text ? 1 : -1
    );

    return [...teamleadersList, ...ordersList];
  }

  private defineMultiTasks(orders: OrderPlan[]): any[] {
    const multiNode = new Map();
    const orderNode = new Map();
    const phaseNode = new Array();
    // to get all the tasks ordered by multiservices operator cycle through the orders,
    // find the multiservices operator for each phase and associate the tasks in which he participates
    orders.forEach((order: OrderPlan) => {
      order.phases.forEach((phase) => {
        const multi = phase.getTeamLeader({ multi: true });
        const multiLoaded: boolean = multiNode.has(multi?.name);
        // if multiservices operator not exist as node add it to the teamleaders node list
        if (multi && !multiLoaded) {
          multiNode.set(multi.name, multi);
        }
        // if phase has a multiservices operator add all tasks
        if (multi) {
          // define orderTask by multiservices operator's associated tasks
          const orderNodeKey: string = `${order.id}-${multi.id}`;
          const orderLoaded = orderNode.has(orderNodeKey);
          !orderLoaded &&
            orderNode.set(orderNodeKey, new OrderTask(order, multi.id));
          // define orderTask by multiservices operator's associated tasks
          const phaseTask = new MultiTask(multi, phase, order.id, order.id);
          phaseNode.push(phaseTask);
        }
      });
    });

    return [
      ...Array.from(multiNode.values()),
      ...Array.from(orderNode.values()),
      ...phaseNode,
    ];
  }
}
