<form (submit)="onSignup(signupForm)" #signupForm="ngForm">
    <label for="email"><b>{{ 'LABEL.FIRST_NAME' | translate }}</b></label>
    <input type="text" ngModel #fnameInput="ngModel" [(ngModel)]="fname" [placeholder]="translate.getInsertLabel('LABEL.FIRST_NAME') | async" name="fname" id="fname" required>

    <label for="email"><b>{{ 'LABEL.LAST_NAME' | translate }}</b></label>
    <input type="text" ngModel #lnameInput="ngModel" [(ngModel)]="lname" [placeholder]="translate.getInsertLabel('LABEL.LAST_NAME') | async" name="lname" id="lname" required>

    <label for="email"><b>{{ 'LABEL.UNIVERSITY' | translate }}</b></label>
    <input type="text" ngModel #universityInput="ngModel" [(ngModel)]="university" [placeholder]="translate.getInsertLabel('LABEL.UNIVERSITY') | async" name="university" id="university" required>

    <label for="email"><b>{{ 'LABEL.EMAIL' | translate }}</b></label>
    <input type="text" ngModel #emailInput="ngModel" [(ngModel)]="email" [placeholder]="translate.getInsertLabel('LABEL.EMAIL') | async" name="email" id="email" required>

    <label for="email"><b>{{ 'LABEL.MOBILE' | translate }}</b></label>
    <input type="text" ngModel #mobileNoInput="ngModel" [(ngModel)]="mobileNo" [placeholder]="translate.getInsertLabel('LABEL.MOBILE') | async" name="mobileNo" id="mobileNo" required>

    <label for="psw"><b>{{ 'LABEL.PASSWORD' | translate }}</b></label>
    <input type="password" name="password" ngModel minlength="8" #passwordInput="ngModel" [(ngModel)]="password" [placeholder]="translate.getInsertLabel('LABEL.PASSWORD') | async" id="psw" required>

    <button type="submit" class="registerbtn">{{ 'LABEL.REGISTER' | translate }}</button>
</form>
