import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NavService } from '../services/nav.service';
import { AuthService} from '../auth/auth.service';
import { TranslatorService } from '../services/translate.service';


@Component({
  selector: 'app-datain',
  templateUrl: './datain.component.html',
  styleUrls: ['./datain.component.scss']
})
export class DatainComponent implements OnInit {
  ss:any;
  user:any;
  sended: boolean = false;
  url: SafeResourceUrl;
  title = "DataIn";
  subTitle = "EDAC - DataIn";
  innerHeight: any
  constructor(public navService: NavService, public translate: TranslatorService, private authService: AuthService) { }


  ngDoCheck() {

    if (this.sended == false) {
      var frame: HTMLIFrameElement = document.getElementById('CertificatesIframe') as HTMLIFrameElement;
      if (frame != null) {
        this.sended = true;
        frame.contentWindow?.postMessage('sstorage', this.ss);
        this.url = environment.rootAlbertcruscotto + 'DataIn' + `?token=${this.user.sub}`;
      }
    }

  }

  loadLabels(){
    this.translate.get('LABEL.DATAIN').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.ss = window.sessionStorage;
    this.user =  this.authService.user();
    console.log('send message to iframe %o',this.ss,this.user);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
    ];
    this.navService.setActions(actions);

    this.innerHeight = (window.innerHeight - 74) + 'px';
  }

}


