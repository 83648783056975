<div class="container-fluid my-container">
  <h1>Actions</h1>
  <!--
  <div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
        [placeholder]="translate.instant('LABEL.SEARCH')" />
    </div>
  </div>
  -->

  <div style="overflow-x: auto;">

    <app-settings-data-table [data]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns"
      [actions]="actions" sortActive=""></app-settings-data-table>


  </div>

</div>


<ng-template #itemDialog>
  <app-action-form></app-action-form>
</ng-template>