import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef, Output } from '@angular/core';
import { LibService } from '../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserSessionService } from '../../services/user-session.service';
import { NavService } from '../../services/nav.service';
import { TranslatorService } from '../../services/translate.service';
import { ActionService } from '../../services/action.service'
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, Subject as Subject2, tap } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CalendarContractService } from 'src/app/services/calendar-contrcats-service';
import { BranchService } from 'src/app/services/branch.service';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HistoryBalanceService } from 'src/app/final-balancer/providers/history-balance.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { CountryService } from 'src/app/services/country.service';
import { ProfileService } from 'src/app/services/profileService';
import { CompanyService } from 'src/app/services/companyService';






@Component({
  selector: 'calendar-contracts-component',
  templateUrl: './calendar-contracts.component.html',
  styleUrls: ['./calendar-contracts.component.scss']
})

export class CalendarContractsComponent implements OnInit {

  dataSource: any;
  actionList: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  selectedTabIndex: number = 2;
  countries: any;
  branches: any;
  branchesFilter: any;
  totalRows: number = 10;
  pageInit: number = 0;
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions = [5, 10, 20];
  orderOptions: any = {}




  // @ViewChild('itemDialog') itemDialog: TemplateRef<any>
  // @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  searchTimeout: any;

  marketingNoteList: any = [];
  workCausals: any;
  filteredCausals: any;
  societies: any;
  filteredSocieties: any;
  filteredSocietiesInput: any;
  filteredCompanies: any;
  companies: any;
  countriesUser: any;
  filteredCountries: any;
  profiles: any;
  filteredProfiles: any;
  filteredBranches: any;
  countrySearchControl = new FormControl();
  companySearchControl = new FormControl();
  societySearchControl = new FormControl();
  profilesSearchControl = new FormControl();
  branchesSearchControl = new FormControl();
  causalSearchControl = new FormControl();

  constructor(public navService: NavService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    public translate: TranslatorService,
    private matDialog: MatDialog,
    private actionService: ActionService,
    private CalendarContractService: CalendarContractService,
    public BranchService: BranchService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private HistoryBalanceService: HistoryBalanceService,
    public countryService: CountryService,
    public profileService: ProfileService,
    private companyService: CompanyService,

  ) {
    this.dataSource = new MatTableDataSource();
  }

  searchForm: UntypedFormGroup = this.fb.group({
    countries: [null],
    societies: [null],
    companies: [null],
    branches: [null],
    profiles: [null],
    workCausals: [null]
  });


  // pageChanged(event: PageEvent) {
  //   this.changePagination.next(event);
  // }

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }



  updateDataBySortParams(event: any) {

    // console.log("updateDataBySortParams(event: any)")
    // console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }


  loadDataSource() {
    // console.log('loadDataSource')
    this.libService.lockPage('');

    // if (reset) {
    //   this.resetOrder()
    //   this.resetPagination()
    //   this.filterOptions.ids = []
    // }

    this.CalendarContractService.getContractsWithPagination(this.searchForm.value, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      this.totalRows = responseData.count;
      this.actionList = responseData.data;
      this.dataSource = responseData.data;
      this.libService.resetLockPage();
    }, (err: any) => {
      this.libService.resetLockPage();
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });


  }



  public reset(): void {
    this.searchForm.reset();
    this.currentPage = 0;
    this.pageInit = 0;
    this.loadDataSource();
    // this.onSubmit()
  }

  public onSubmit(): void {
    // console.log('SUBMIT', this.searchForm.value);
    this.libService.lockPage('');
    this.currentPage = 0;
    this.pageInit = 0;
    this.loadDataSource();
  }



  displayedColumns = ['id', 'description', 'maximumWeeklyHours', 'maximumExtraHoursPerWeek', 'startDateOfValidity', 'endDateOfValidity', 'startDate', 'endDate'];
  //, 'countries', 'societies', 'companies', 'workCausals', 'branches', 'profiles'

  structure: any = {
    id: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
    name: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
  }




  ciao = 'ciao'

  columns: any = [
    {
      name: 'id',
      flex: '6',
      title: this.getTitle('id'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''} `] ,
      value: (item: any) => item.id 
    },
    {
      name: "description",
      title: this.translate.get('LABEL.DESCRIPTION'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item.description
    },
    {
      name: "maximumWeeklyHours",
      title: this.getTitle('maximumWeeklyHours'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item.maximumWeeklyHours
    },
    {
      name: "maximumExtraHoursPerWeek",
      title: this.getTitle('maximumExtraHoursPerWeek'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item.maximumExtraHoursPerWeek
    },
    {
      name: "startDateOfValidity",
      title: this.getTitle('startDateOfValidity'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => `${this.padWithZero(item.startDayOfValidity)}/${this.padWithZero(item.startMonthOfValidity)}`
    },
    {
      name: "endDateOfValidity",
      title: this.getTitle('endDateOfValidity'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => `${this.padWithZero(item.endDayOfValidity)}/${this.padWithZero(item.endMonthOfValidity)}`
    },
    {
      name: "startDate",
      title: this.getTitle('startDate'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.formatDateToDDMMYYYY(item.startDate)
    },
    {
      name: "endDate",
      title: this.getTitle('endDate'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.formatDateToDDMMYYYY(item.endDate)
    },
    // {
    //   name: "countries",
    //   title: this.getTitle('countries'),
    //   cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
    //   value: (item: any) => item.countries.map((country: any) => country.isoCode).join(', ')
    // },
    // {
    //   name: "societies",
    //   title: this.getTitle('societies'),
    //   cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
    //   value: (item: any) => item.societies.map((society: any) => society.name).join(', ')
    // },
    // {
    //   name: "companies",
    //   title: this.getTitle('companies'),
    //   cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
    //   value: (item: any) => item.companies.map((company: any) => company.name).join(', ')
    // },
    // {
    //   name: "workCausals",
    //   title: this.getTitle('workCausals'),
    //   cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
    //   value: (item: any) => item.workCausals.map((workCausal: any) => workCausal.causalCode).join(', ')
    // },
    // {
    //   name: "branches",
    //   title: this.getTitle('branches'),
    //   cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
    //   value: (item: any) => item.branches.map((branch: any) => branch.name).join(', ')
    // },
    // {
    //   name: "profiles",
    //   title: this.getTitle('profiles'),
    //   cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
    //   value: (item: any) => item.profiles.map((profile: any) => profile.name).join(', ')
    // },

  ]

  getTitle(type: any) {
    let title: any = this.translate.get('LABEL.NOT_PRESENT')
    switch (type) {
      case 'id':
        title = this.translate.get('ID')
        break;
      case 'description':
        title = this.translate.get('LABEL.Description')
        break;
      case 'maximumWeeklyHours':
        title = this.translate.get('Massimo di ore ordinarie per settimana')
        break;
      case 'maximumExtraHoursPerWeek':
        title = this.translate.get('Massimo di ore EXTRA per settimana')
        break;
      case 'startDateOfValidity':
        title = this.translate.get('data di inizio validità')
        break;
      case 'endDateOfValidity':
        title = this.translate.get('Data di fine validità')
        break;
      case 'startDate':
        title = this.translate.get('Data di inizio')
        break;
      case 'endDate':
        title = this.translate.get('Data di fine')
        break;
      case 'countries':
        title = this.translate.get('Country')
        break;
      case 'societies':
        title = this.translate.get('Society')
        break;
      case 'companies':
        title = this.translate.get('Company')
        break;
      case 'workCausals':
        title = this.translate.get('Work Causal')
        break;
      case 'branches':
        title = this.translate.get('Branches')
        break;
      case 'profiles':
        title = this.translate.get('Profiles')
        break;

    }
    return title
  }

  padWithZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  formatDateToDDMMYYYY(dateString: string | null | undefined): string {
    if (!dateString) {
      return '-'; // Se la data è vuota o non definita, restituisci un placeholder
    }
  
    const date = new Date(dateString); // Crea un oggetto Date dalla stringa ISO
  
    // Controlla se la data è valida
    if (isNaN(date.getTime())) {
      return '-'; // Restituisci un placeholder se la data non è valida
    }
  
    // Usa toLocaleDateString per formattare la data come DD/MM/YYYY
    return date.toLocaleDateString('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }
  


  setUserActions() {
    // SHOW
    this.actions.push({
      label: of(''),
      icon: (item: any) => {
        return this.iconRoutine(item);
      },
      onClick: (item: any) => this.goToPageForm(item.id),
      color: 'primary'
    })

  }

  iconRoutine(subject: any) {
    return 'edit';
  }


  filterByBusinessLineId(event: any) {
    const selectedBusinessLineIds = event.value; // Array di business line selezionate

    const actions = this.actionList.filter((action: any) => {
      let status = false;
      action.branches.forEach((branch: any) => {
        const businessLineIds = action.branches.map((branchId: any) => branchId.id);
        if (selectedBusinessLineIds.some((id: number) => businessLineIds.includes(id))) {
          status = true;
        }
      });
      return status;
    });

    this.dataSource = actions;
  }



  initBranches() {
    this.BranchService.getAllBranches().subscribe((res: any) => {
      // console.log(res)
      this.branches = res
      this.branchesFilter = res.filter((branch: any) => branch.isActive)
    })
  }






  actions: any = [];





  // openItemDialog(item: any) {
  //   //this.mapsSocietyState(e);
  //   const dialogRef = this.matDialog.open(this.itemDialog, {
  //     panelClass: 'custom-dialog-container',
  //     width: '60%'
  //   })
  // }


  ngOnInit(): void {
    // this.initBranches();
    // // this.loadData();
    // this.setUserActions();
    // this.loadDataSource();

    // // console.log('actionList: ', this.actionList)
    // this.navService.setTitle(this.title);
    // this.navService.setSubTitle(this.subTitle);
    // this.navService.setWikiKey('NODEF');
    // let actions: any;
    // actions = [
    //   { name: 'new', url: 'actions/0' },
    //   //{ name: 'export', url: '' },
    // ];
    // this.navService.setActions(actions);

    // //GET STATE
    // this.currentUrl = window.location.href

    // combineLatest([
    //   this.getCountries(),
    //   this.getCompanies(),
    //   this.getProfiles().pipe(catchError((error: any) => of([]))),
    //   this.getBranches().pipe(catchError((error: any) => of([])))
    // ]).subscribe(([countriesResponse, companiesResponse, profilesResponse, branchesresponse]) => {
    //   // Gestisci la risposta delle nazioni
    //   // this.countries = countriesResponse;
    //   // console.log('Countries:', this.countries);

    //   // Gestisci la risposta delle companies e societies
    //   // console.log('Societies:', this.filteredSocieties);
    //   // console.log('Companies:', this.companies);
    //   // console.log('Profiles:', profilesResponse);
    //   // console.log('branches: ', branchesresponse)
    //   this.profiles = profilesResponse;
    //   this.filteredProfiles = this.profiles;
    //   this.branches = branchesresponse;
    //   this.filteredBranches = this.branches;
    //   // this.removeDuplicates(this.filteredBranches);
    // });

    // this.countrySearchControl.valueChanges.subscribe((value: any) => {
    //   //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
    //   this.filteredCountries = this.countries.filter((country: any) => {
    //     return !value || country.name.toLowerCase().includes(value.toLowerCase())
    //   });
    // });


    // this.profilesSearchControl.valueChanges.subscribe((value: any) => {
    //   //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
    //   this.filteredProfiles = this.profiles.filter((profile: any) => {
    //     return !value || profile.name.toLowerCase().includes(value.toLowerCase())
    //   });
    // });

    // this.branchesSearchControl.valueChanges.subscribe((value: any) => {
    //   //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
    //   this.filteredBranches = this.branches.filter((branche: any) => {
    //     return !value || branche.name.toLowerCase().includes(value.toLowerCase())
    //   });
    // });

    // this.searchForm.get('countries')?.valueChanges.subscribe((value: any) => {
    //   this.filterSocietiesByCountry(value);
    // });


    // this.searchForm.get('societies')?.valueChanges.subscribe((value: any) => {
    //   this.filterCompanyBySociety(value);
    // });

    // this.societySearchControl.valueChanges.subscribe(value => {
    //   // console.log(value)
    //   //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
    //   this.filteredSocieties = this.filteredSocietiesInput.filter((society: any) => {
    //     return !value || society.name.toLowerCase().includes(value.toLowerCase())
    //   });
    // });

    // this.companySearchControl.valueChanges.subscribe(value => {
    //   //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
    //   this.filteredCompanies = this.companies.filter((company: any) =>
    //     !value || company.name.toLowerCase().includes(value.toLowerCase())
    //   );
    // });

    // this.getWorkCausals(); // Carica i workCausals
    // // console.log('ciao')

    // // Solo dopo aver ricevuto i workCausals esegui i filtri o altre operazioni
    // this.causalSearchControl.valueChanges.subscribe(value => {
    //   this.filteredCausals = this.workCausals.filter((causal: any) =>
    //     !value || causal?.name.toLowerCase().includes(value.toLowerCase())
    //   );
    // });


  }





  loadData(): void {

    this.libService.lockPage('');

    this.CalendarContractService.getContractsWithPagination(this.searchForm.value, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      // console.log('loadDataSets response %o', responseData);
      this.libService.unlockPage();
      // console.log('responseData: ', responseData)
      this.actionList = responseData;
      this.dataSource = responseData;
    }, (err: any) => {
      // console.log(err)
    });

  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    /* console.log('tabChangeEvent --> ', tabChangeEvent); */
    /* console.log('index --> ', tabChangeEvent.index); */
    this.selectedTabIndex = tabChangeEvent.index
    if (tabChangeEvent.index !== (null || undefined)) {
      if (tabChangeEvent.index === 0) {
        this.goToPage('final-balancer/history')
      }
      if (tabChangeEvent.index === 1) {
        this.goToPage('settings/calendar-settings')
      }
      if (tabChangeEvent.index === 2) {

      }
      if (tabChangeEvent.index === 3) {
        this.goToPage('settings/absence-modules')

      }
    }


  }

  goToPage(link: any) {
    this.router.navigate([link]);
  }
  goToPageForm(id: number = 0) {
    // console.log(id)
    this.router.navigate([`settings/calendar-contracts-form/`, id]);
  }




  getWorkCausals(): void {
    this.HistoryBalanceService.$workCasuals.pipe(
      tap((res: any) => {
        if (res && res.length > 0) {
          this.workCausals = res.map((causal: any) => {
            return {
              id: causal?.id,
              name: causal?.causalCode
            };
          });
          // console.log('workCausals:', this.workCausals); // Controllo dei dati ricevuti
          this.filteredCausals = this.workCausals; // Popolazione di `filteredCausals`
        } else {
          console.error('Nessun dato ricevuto per workCausals');
        }
      })
    ).subscribe();
  }


  removeDuplicates(societies: any) {
    const uniqueSocieties = societies.reduce((acc: any, current: any) => {
      // Usa un oggetto per tracciare gli id unici
      if (!acc.some((society: any) => society.id === current.id)) {
        acc.push(current);
      }
      return acc;
    }, []);
    return uniqueSocieties;
  }


  filterSocietiesByCountry(selectedCountries: string[]) {
    this.searchForm.get('societies')?.reset();
    this.searchForm.get('companies')?.reset();
    // this.filteredSocieties = [];
    this.societies = this.removeDuplicates(this.societies);

    // console.log('Selected countries:', selectedCountries);
    // Verifica se ci sono country selezionati
    if (selectedCountries && selectedCountries.length > 0) {
      // Ricava i nomi corrispondenti agli id dei country selezionati
      const selectedCountryNames = selectedCountries.map((countries) => {
        const foundCountry = this.countries?.find((country: any) => country.id === countries);
        return foundCountry ? foundCountry.name : null; // Se trova il paese, restituisce il nome, altrimenti null
      }).filter((name) => name !== null); // Filtra eventuali valori null

      // Filtra le società in base ai nomi dei country trovati
      this.filteredSocieties = this.societies.filter((society: any) => {
        return selectedCountryNames.includes(society.societyCountry);
      });

      this.filteredSocietiesInput = this.filteredSocieties;
    } else {
      this.filteredSocieties = [];
    }
    // console.log('Filtered societies:', this.filteredSocieties);
  }



  filterCompanyBySociety(selectedSocieties: string[]) {
    this.searchForm.get('companies')?.reset();

    if (selectedSocieties && selectedSocieties.length > 0) {
      this.filteredCompanies = this.companies.filter((company: any) =>
        selectedSocieties.includes(company.societyId)
      );
    } else {
      this.filteredCompanies = [];
    }
  }


  getCountries(): Observable<any> {
    this.countriesUser = this.userSessionService.getCountries();
    return this.countryService.index().pipe(
      tap((response: any) => {
        this.countries = response.filter((country: any) =>
          this.countriesUser.includes(country.isoCode)
        ).map((country: any) => ({
          id: country?.id,
          name: country?.isoCode
        }));
        this.filteredCountries = this.countries;
      })
    );
  }
  // Metodo per ottenere i profili
  getProfiles(): Observable<any> {
    return this.profileService.getAllProfiles().pipe(
      map((response: any) => {
        return response.map((profile: any) => {
          return {
            id: profile.id,
            name: profile.name
          };
        });
      })
    );
  }

  getBranches(): Observable<any> {
    return this.BranchService.getAllBranches().pipe(
      map((response: any) => {
        return response.map((branche: any) => {
          return {
            id: branche.id,
            name: branche.name,
            codeLang: branche.codCountry
          };
        });
      })
    );
  }

  getCompanies(): Observable<any> {
    return this.companyService.getSocietiesAndCountries().pipe(
      tap((res: any) => {
        // console.log(res)
        if (res && res.length > 0) {
          this.societies = res.map((society: any) => ({
            id: society?.societyId,
            name: society?.societyName,
            societyCountry: society?.societyCountry
          }));
          //   this.filteredSocieties = this.societies;
          // console.log('societafitrate: ', this.filteredSocieties)
          this.companies = res.map((company: any) => ({
            id: company?.companyId,
            name: company?.companyName,
            societyId: company?.societyId
          }));
        } else {
          console.error('Nessun dato ricevuto dal server per companies e societies');
        }
      })
    );
  }


}