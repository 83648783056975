import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EstimateDeliveryMethodService } from '../../services/estimate-delivery-method.service';
import { ReasonService } from '../../services/reason.service';
import { LibService } from '../../services/libService';
import { StateService } from '../../services/state.service';
import { TranslatorService } from '../../services/translate.service';
import { PecService } from '../../services/pec.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pec-action-modal',
  templateUrl: './pec-action-modal.component.html',
  styleUrls: ['./pec-action-modal.component.scss']
})
export class PecActionModalComponent implements OnInit {

  editMode: boolean = false
  formIsActive: boolean = false
  titleLabel: string = ''
  textLabel: string = ''
  confirmButtonLabel: any = 'LABEL.SAVE'
  cancelButtonLabel: any = 'LABEL.CANCEL'

  form: FormGroup

  type: any
  item: any
  textareaReasonClose: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PecActionModalComponent>,
    private estimateDeliveryMethodService: EstimateDeliveryMethodService,
    private fb: UntypedFormBuilder,
    private reasonService: ReasonService,
    private libService: LibService,
    private stateService: StateService,
    private translate: TranslatorService,
    private router: Router,
    private pecService: PecService,
    private toastr: ToastrService,
  ) {

    if (data) {
      console.log(data)
      if (data.item) this.item = data.item
    }



  }

  ngOnInit(): void {
    if(this.item?.closingReason) this.textareaReasonClose = this.item?.closingReason
  }

  closeModal() {
    this.dialogRef.close(true);
  }

  goToComplaint(complaintTypeSection: any) {
    let queryParams: any = {
      type: 3,
      pecId: this.item.id,
      complaintTypeSection: complaintTypeSection
    }
    if (this.item?.practice?.code) queryParams.practiceCode = this.item?.practice?.code
    this.router.navigate(['request/0'], { queryParams: queryParams });
    this.closeModal()
  }

  goToItem(id: any, section: any) {
    let queryParams = { subTabName: 'complaints', subSubTabName: section, itemId: id }
    this.closeModal()
    this.router.navigate(['crm', { tabName: 'rapports' }], { queryParams: queryParams }).then(() => {
      window.location.reload();
    });
   
  }

  closePec() {
    let now = new Date();
    if (!['', null, undefined].includes(this.textareaReasonClose.replace(/\s/g, ""))) {
      this.pecService.create(
        {
          id: this.item.id,
          closingDate: now,
          closingReason: this.textareaReasonClose
        }
      ).subscribe((res: any) => {
        this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
        this.closeModal()
        this.libService.unlockPage();
      })
    } else {
      this.toastr.error(this.translate.instant('LABEL.MotivazioneChiusuraObbligatoria'),this.translate.instant('TOASTR.WARNING'));
    }

  }

  alreadyClosed() {
    let value = false
    if (this.item.closingDate) value = true
    return value
  }

}
