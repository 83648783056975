import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DataSetService } from '../services/dataset.service';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSessionService } from '../services/user-session.service';
import { NavService } from '../services/nav.service';
import { MatPaginator } from '@angular/material/paginator';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements OnInit, OnDestroy {

  displayedColumns = ['id', 'name','description','howMany', 'createdAt', 'updatedAt', 'editAction', 'deleteAction'];
  dataSource: any;
  kwSearch: any;
  title = this.translate.instant('LABEL.DOMAINS');
  subTitle = "EDAC - " + this.translate.instant('LABEL.DOMAINS');
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  currentUrl: string;

  constructor(public navService: NavService, private dataSetService: DataSetService, public libService: LibService, private router: Router, private userSessionService: UserSessionService, private route: ActivatedRoute, public translate: TranslatorService) {
    this.dataSource = new MatTableDataSource();
    //this.profileService.getAllProfiles().subscribe(r => this.data = r);
  }

  loadLabels(){
    this.translate.get('LABEL.DOMAINS').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.loadLabels();
    this.loadDataSets();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
      {name:'new',url:'domain/0'},
      {name:'export',url:''},
    ];
    this.navService.setActions(actions);
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  goTo(e: any) {
    this.router.navigate(['domain/' + e.id]);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  loadDataSets(): void {

    this.libService.lockPage('');
    this.dataSetService.getAllDataSets().subscribe((response) => {
      console.log('loadDataSets response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
  }

}
