import { Component, OnInit, ElementRef } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { codeCharacter } from '../../validators/code.validator';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../services/libService';
import { Observable } from 'rxjs';
import { ConfService } from '../../services/conf.service';
import { CompanyService } from '../../services/companyService';
import { SocietyService } from '../../services/societyService';
import { UserDataService } from '../../services/users.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { GeocoderResponse } from '../../models/geocoder';
import { GeocodingService } from '../../services/geocoding.service';
import { WikiService } from '../../services/wikiservice.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { LicenceService } from 'src/app/services/licenseService';
import { UserSessionService } from 'src/app/services/user-session.service';
import { Licence } from 'src/app/models/licence';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'app-company-dtail',
    templateUrl: './company-dtail.component.html',
    styleUrls: ['./company-dtail.component.scss']
})
export class CompanyDtailComponent implements OnInit {

    company: any;
    form: UntypedFormGroup;
    licenceForm: UntypedFormGroup;
    isNew: boolean = true;
    conf: any;
    id: any;
    actualLic: any = {};
    societies: any;
    licences: any;
    editingLicences: any;
    allSocieties: any;
    usersManager: any;
    usersAreaManager: any;
    allUsers: any;
    isEditingLicences: boolean = false
    allLicences: any
    hasSave: boolean = true

    mapZoom = 12;
    mapCenter: google.maps.LatLng;
    mapOptions: google.maps.MapOptions = {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        maxZoom: 20,
        minZoom: 4,
    };

    markerInfoContent = '';
    markerOptions: google.maps.MarkerOptions = {
        draggable: false,
        animation: google.maps.Animation.DROP,
    };

    geocoderWorking = false;
    geolocationWorking = false;

    address: string;
    formattedAddress?: string | null = null;
    locationCoords?: google.maps.LatLng | null = null;

    typeGlobal: any = '';

    @ViewChild('tabGroup') tabGroup: MatTabGroup;

    constructor(
        public navService: NavService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        public libService: LibService,
        private toastr: ToastrService,
        public confService: ConfService,
        private geocodingService: GeocodingService,
        public translate: TranslatorService,
        private companyService: CompanyService,
        private societyService: SocietyService,
        private userDataService: UserDataService,
        private licenceService: LicenceService,
        private elementRef: ElementRef,
        public wikiService: WikiService,
        public userSessionService: UserSessionService,
    ) {
        this.societyService.getAllSocieties().subscribe(societies => {
            this.societies = societies;
            this.allSocieties = societies;
        })
        this.userDataService.getAllUsersFast().subscribe(users => {
            this.usersManager = users;
            this.usersAreaManager = users;
            this.allUsers = users;
        })
        this.licenceService.getAllLicense().subscribe(licences => {
            this.allLicences = licences
            this.licences = licences
        })
    }

    @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
    @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

    get isWorking(): boolean {
        return this.geolocationWorking || this.geocoderWorking;
    }

    onChangeFilterSocieties(event: KeyboardEvent) {
        const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
        this.societies = this.allSocieties.filter((mp: any) => mp.name.toLowerCase().startsWith(searchStr))
    }

    onChangeFilterUsersManager(event: KeyboardEvent) {
        const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
        this.usersManager = this.allUsers.filter((mp: any) => mp.name.toLowerCase().startsWith(searchStr))
    }

    onChangeFilterUsersAreaManager(event: KeyboardEvent) {
        const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
        this.usersAreaManager = this.allUsers.filter((mp: any) => mp.name.toLowerCase().startsWith(searchStr))
    }

    onChangeFilterLicences(event: KeyboardEvent) {
        const searchStr = ((event.target as HTMLInputElement).value || '').toLowerCase()
        this.licences = this.licences.filter((l: Licence) => l.code?.toLowerCase().startsWith(searchStr))
    }

    onOpenSearch(searchElement: HTMLInputElement){
        searchElement.value = '';
        searchElement.focus();
    }

    onCloseSearch(topic: String){
        if (topic == 'society') this.societies = this.allSocieties;
        if (topic == 'manager') this.usersManager = this.allUsers;
        if (topic == 'areaManager') this.usersAreaManager = this.allUsers;
        if (topic == 'licences') this.licences = this.allLicences.filter((l: Licence) => !this.editingLicences.find((l2: Licence) => l2.id == l.id));
    }

    openInfoWindow(marker: MapMarker) {
        this.infoWindow.open(marker);
    }

    goToWiki() {
        window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
    }

    goToWikiByKey(k: any) {
        window.open(this.wikiService.getWikiUrl(k));
    }

    updateCompany(){
        if (this.form.valid) {
            this.company.isOpen = true;
            if (this.form.get('stato')?.value == 'FP')
                this.company.isOpen = false;
            this.libService.lockPage('');
            if (this.isNew == false) {
                this.company.status = this.form.get('status')?.value;
            }

            this.company.name = this.form.get('name')?.value;
            this.company.code = this.form.get('code')?.value;
            this.company.isOpen = this.form.get('isOpen')?.value;
            this.company.codezone = this.form.get('codezone')?.value;
            this.company.UsersCompaniesManagerRelId = this.form.get('manager')?.value;
            this.company.UsersCompaniesAreaManagerRelId = this.form.get('areaManager')?.value;
            this.company.macroarea = this.form.get('macroArea')?.value;
            this.company.country = this.form.get('country')?.value;
            this.company.streetName = this.form.get('streetName')?.value;
            this.company.streetNumber = this.form.get('streetNumber')?.value;
            this.company.zip = this.form.get('zip')?.value;
            this.company.city = this.form.get('city')?.value;
            this.company.state = this.form.get('state')?.value;
            this.company.coordY = this.form.get('latitude')?.value;
            this.company.coordX = this.form.get('longitude')?.value;
            this.company.telefono = this.form.get('phone')?.value;
            this.company.email = this.form.get('email')?.value;
            this.company.piva = this.form.get('piva')?.value;
            this.company.invoicesystem_code = this.form.get('invoiceCode')?.value;
            this.company.mastercode = this.form.get('masterCode')?.value;

            const id = this.route.snapshot.paramMap.get('id');
            if (id && parseInt(id) > 0) {
                console.log("Update Company", this.company);
                this.companyService.updateCompany(this.company).subscribe((response) => {
                    this.libService.unlockPage();
                    // this.subject = response;
                    this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
                });
            }
            else {
                this.companyService.createCompany(this.company).subscribe((response) => {
                    this.libService.unlockPage();
                    // this.subject = response;
                    this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
                    // console.log(response);
                });
            }
        }
        else {
            this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
        }
    }


    update() {
        if(this.tabGroup.selectedIndex == 0){
            this.updateCompany()
        }
        if(this.tabGroup.selectedIndex == 3){
            this.updateLicences()
        }
    }


    placeChangedCallback(place: any) {
        //console.log('place %o',place);
        place.address_components.forEach((add: any) => {
            add.types.forEach((addType: any) => {
                console.log('place %o', add);
                if (addType == "street_number")
                    this.company.streetNumber = add.short_name;
                if (addType == "route")
                    this.company.streetName = add.long_name;
                if (addType == "locality" || addType == "sublocality_level_1")
                    this.company.city = add.long_name;
                if (addType == "administrative_area_level_1")
                    this.company.state = add.long_name;
                if (addType == "country")
                    this.company.country = add.long_name;
                if (addType == "postal_code")
                    this.company.zip = add.long_name;
            });
        });
    }


    onMapDragEnd(event: any) {
        console.log('EVENT %o', event);
        const point: google.maps.LatLngLiteral = {
            lat: event.latLng!.lat(),
            lng: event.latLng!.lng(),
        };

        this.geocoderWorking = true;
        this.geocodingService
            .geocodeLatLng(point)
            .then((response: GeocoderResponse) => {
                if (response.status === 'OK') {
                    if (response.results.length) {
                        const value = response.results[0];

                        this.locationCoords = new google.maps.LatLng(point);

                        this.mapCenter = new google.maps.LatLng(point);
                        this.map.panTo(point);

                        this.address = value.formatted_address;
                        this.formattedAddress = value.formatted_address;

                        this.markerOptions = {
                            draggable: true,
                            animation: google.maps.Animation.DROP,
                        };

                        this.markerInfoContent = value.formatted_address;
                    }
                }
            })
            .finally(() => {
                this.geocoderWorking = false;
            });
    }

    findAddress() {
        if (!this.address || this.address.length === 0) {
            return;
        }

        let currentLanguage = this.userSessionService.getCurrentLanguage()

        this.geocoderWorking = true;
        this.geocodingService
            .getLocation(this.address, currentLanguage)
            .subscribe(
                (response: GeocoderResponse) => {
                    if (response.status === 'OK' && response.results?.length) {
                        console.log('response for geocoding %o', response);
                        this.placeChangedCallback(response.results[0]);
                        const location = response.results[0];
                        const loc: any = location.geometry.location;
                        this.company.coordX = loc.lng;
                        this.company.coordY = loc.lat;
                        this.locationCoords = new google.maps.LatLng(loc.lat, loc.lng);

                        this.mapCenter = location.geometry.location;

                        setTimeout(() => {
                            if (this.map !== undefined) {
                                this.map.panTo(location.geometry.location);
                            }
                        }, 500);

                        this.address = location.formatted_address;
                        this.formattedAddress = location.formatted_address;
                        this.markerInfoContent = location.formatted_address;

                        this.markerOptions = {
                            draggable: true,
                            animation: google.maps.Animation.DROP,
                        };
                    } else {
                        this.toastr.error(response.error_message, response.status);
                    }
                },
                (err: HttpErrorResponse) => {
                    console.error('geocoder error', err);
                }
            )
            .add(() => {
                this.geocoderWorking = false;
            });
    }

    getCurrentLocation() {
        this.geolocationWorking = true;
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.geolocationWorking = false;

                const point: google.maps.LatLngLiteral = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.company.coordX = position.coords.longitude;
                this.company.coordY = position.coords.latitude;
                this.geocoderWorking = true;
                this.geocodingService
                    .geocodeLatLng(point)
                    .then((response: GeocoderResponse) => {
                        if (response.status === 'OK' && response.results?.length) {
                            this.placeChangedCallback(response.results[0]);
                            const value = response.results[0];

                            this.locationCoords = new google.maps.LatLng(point);

                            this.mapCenter = new google.maps.LatLng(point);
                            this.map.panTo(point);

                            this.address = value.formatted_address;
                            this.formattedAddress = value.formatted_address;
                            this.markerInfoContent = value.formatted_address;

                            this.markerOptions = {
                                draggable: true,
                                animation: google.maps.Animation.DROP,
                            };
                        } else {
                            this.toastr.error(response.error_message, response.status);
                        }
                    })
                    .finally(() => {
                        this.geocoderWorking = false;
                    });
            },
            (error) => {
                this.geolocationWorking = false;

                if (error.PERMISSION_DENIED) {
                    this.toastr.error(this.translate.instant('LOCATION_ERROR'), this.translate.instant('TOASTR.ACCESS_DENIED'));
                } else if (error.POSITION_UNAVAILABLE) {
                    this.toastr.error(
                        this.translate.instant('LOCATION_ERROR'),
                        'Position unavailable'
                    );
                } else if (error.TIMEOUT) {
                    this.toastr.error(this.translate.instant('LOCATION_ERROR'), this.translate.instant('TOASTR.TIMEOUT'));
                } else {
                    this.toastr.error(error.message, `Error: ${error.code}`);
                }
            },
            { enableHighAccuracy: true }
        );
    }

    initNavbar() {
        setTimeout(() => {
            let actions: any;
            actions = [
                { name: 'back', url: 'genconfig' }
            ];

            let title = this.form.get('id')?.value !== null ?
                this.translate.instant('LABEL.EDIT', { field: this.translate.instant('LABEL.HEAD_OFFICE') }) :
                this.translate.instant('LABEL.NEW', { field: this.translate.instant('LABEL.HEAD_OFFICE') })
            let subTitle = "EDAC - " + title
            this.navService.setActions(actions);
            this.navService.setTitle(title);
            this.navService.setSubTitle(subTitle);
        }, 1000);

    }

    removeLicence(licence: Licence){
        this.editingLicences = this.editingLicences.filter((l: Licence) => l.id != licence.id)
    }

    addLicence(){
        this.editingLicences.push(
            this.allLicences.find((l: Licence) => l.id == this.licenceForm.controls["licenceSelect"].value)
        )
        this.onCloseSearch('licences')
        this.licenceForm.controls["licenceSelect"].setValue(undefined)
    }

    toggleEditLicence(){
        this.isEditingLicences = !this.isEditingLicences
        this.hasSave = this.isEditingLicences
    }

    updateLicences(){
        console.log("Update licences", this.editingLicences);
        this.company.licences = this.editingLicences.map((el: Licence) => el.id)
        this.companyService.updateCompanyLicences(this.company).subscribe({
            next(success){
                console.log(success)
            },
            error: (err) => {
                console.error(err)
                this.toastr.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
            },
            complete: () => {
                this.ngOnInit()
                console.log('Done')
                this.toastr.success(
                this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
            }
        });
    }

    changeTab(event: MatTabChangeEvent){
        switch(event.index){
            case 0: // Anagrafica
                this.hasSave = true
                break
            case 1: // Localizzazione
                this.hasSave = false
                break
            case 2: // Società
                this.hasSave = false
                break
            case 3: // Licenze
                if(this.isEditingLicences) this.hasSave = true
                else this.hasSave = false
                break
            case 4: // Risorse
                this.hasSave = false
                break
        }
    }

    ngOnInit(): void {
        this.initNavbar();
        // console.log('conf in manage company %o', this.conf);
        this.id = this.route.snapshot.paramMap.get('id');
        this.company = {};
        this.company.status = 'JUNIOR';
        this.form = this.fb.group({
            status: [null],
            name: [null, [Validators.required, Validators.minLength(10)]],
            type: [null, [Validators.required]],
            code: [null, [Validators.required, codeCharacter()]],
            isActive: [null],
            codezone: [null],
            isOpen: [null],
            manager: [null],
            society: [null, [Validators.required]],
            areaManager: [null],
            macroArea: [null],
            country: [null, [Validators.required]],
            streetName: [null],
            streetNumber: [null],
            zip: [null],
            city: [null],
            state: [null],
            latitude: [null],
            longitude: [null],
            phone: [null],
            email: [[null],[Validators.email]],
            piva: [null],
            invoiceCode: [null],
            masterCode: [null]

        });

        this.licenceForm = this.fb.group({
            licenceSelect: [null]
        })

        this.company.isOpen = true;
        if (this.company.stato == 'FP')
            this.company.isOpen = false;
        if (this.id && parseInt(this.id) > 0) {
            this.form.controls['status'].disable()
            this.form.controls['type'].disable()
            this.form.controls['society'].disable()
            this.isNew = false;
            this.libService.lockPage('');
            this.companyService.getCompany(this.id!).subscribe((response) => {
                this.libService.unlockPage();
                this.company = response;
                this.typeGlobal = this.company.type;
                
                this.editingLicences = [...this.company.CompaniesLicencesRel]
                this.licences = this.allLicences.filter((l: Licence) => !this.company.CompaniesLicencesRel.find((l2: Licence) => l2.id == l.id));
                
                this.form.patchValue({
                    name: this.company.name,
                    type: this.company.type,
                    status: this.company.status,
                    code: this.company.code,
                    codezone: this.company.codezone,
                    isOpen: this.company.isOpen,
                    society: this.company.CompaniesSocietiesRel[0]?.name,
                    manager: this.company.UsersCompaniesManagerRelId,
                    areaManager: this.company.UsersCompaniesAreaManagerRelId,
                    macroArea: this.company.macroarea,
                    country: this.company.country,
                    streetName: this.company.streetName,
                    streetNumber: this.company.streetNumber,
                    zip: this.company.zip,
                    city: this.company.city,
                    state: this.company.state,
                    latitude: this.company.coordY,
                    longitude: this.company.coordX,
                    phone: this.company.telefono,
                    email: this.company.email,
                    piva: this.company.piva,
                    invoiceCode: this.company.invoicesystem_code,
                    masterCode: this.company.mastercode
                });
                this.address = this.company.streetName + ' ' + this.company.streetNumber + ', ' + this.company.zip + ' ' + this.company.city + ' ' + this.company.state + ' ' + this.company.country;
                this.formattedAddress = this.company.streetName + ' ' + this.company.streetNumber + ', ' + this.company.zip + ' ' + this.company.city + ' ' + this.company.state + ' ' + this.company.country;
                this.markerInfoContent = this.company.streetName + ' ' + this.company.streetNumber + ', ' + this.company.zip + ' ' + this.company.city + ' ' + this.company.state + ' ' + this.company.country;
                const point: google.maps.LatLngLiteral = {
                    lat: this.company.coordY,
                    lng: this.company.coordX,
                };
                this.locationCoords = new google.maps.LatLng(point);
                this.mapCenter = new google.maps.LatLng(point);
                this.map.panTo(point);
            });

        }
        else {
            this.isNew = true;

            this.form.patchValue({
                name: this.company.name,
                type: this.company.type,
                status: this.company.status,
                code: this.company.code,
                codezone: this.company.codezone,
                isOpen: this.company.isOpen,
                // manager: this.company.manager,
                // society: this.company.society
            });
        }
    }
    companiesFilterDeleted(element: any) {
        const result = element?.filter((a:any) => a.CompaniesUsers?.deletedAt === null)
        return result
    }

    profilesFilterDeleted(element: any) {
        const result = element?.filter((a:any) => a.UsersProfiles?.deletedAt === null)
        return result
    }
}

