import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { FinanceService } from "src/app/services/finance.service";
import { LibService } from "src/app/services/libService";
import { StockService } from "src/app/services/stock.service";
import { OrderPlan } from "../models/order-plan";
@Injectable({
  providedIn: 'root',
})
export class SearchOrderService {
  constructor(
    private financeService: FinanceService,
    private libService: LibService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private stockService: StockService,
    private router: Router
  ) {}

  private orderSubject = new BehaviorSubject<OrderPlan[]>([]);
  public order$ = this.orderSubject.asObservable();
  public codeSubject = new BehaviorSubject<string>('');
  public code$ = this.codeSubject.asObservable();

  setStockElencoDettaglioConCodicePraticaCall(
    email: any,
    company: any,
    code: any,
    plannerType: string
  ) {
    let currentCall$ =
      this.financeService.getStockElencoDettaglioConCodicePratica(
        company,
        code
      );

    switch (plannerType) {
      case 'core':
        currentCall$ =
          this.financeService.getStockElencoDettaglioConCodicePratica(
            company,
            code
          );
        break;
      case 'multi':
        currentCall$ =
          this.financeService.getStockElencoDettaglioConCodicePraticaServices(
            company,
            code
          );
        break;
      case 'energy':
        currentCall$ =
          this.financeService.getStockElencoDettaglioConCodicePraticaEnergy(
            company,
            code
          );
        break;
        case 'energyOperators':
          currentCall$ =
            this.financeService.getStockElencoDettaglioConCodicePraticaEnergy(
              company,
              code
            );
          break;
      default:
        currentCall$ =
          this.financeService.getStockElencoDettaglioConCodicePratica(
            company,
            code
          );
        break;
    }
    return currentCall$;
  }

  search(
    currentUser: any,
    companyId: number,
    code: string,
    plannerType: string
  ) {
    this.libService.lockPage('');
    let call$ = this.setStockElencoDettaglioConCodicePraticaCall(
      currentUser.email,
      companyId,
      code,
      plannerType
    );
    call$.subscribe({
      next: (res: any) => {
        if (res.esito == 'OK') {
          if (res.item.Table.length > 0) {
            let order = res.item.Table[0];
            this.orderSubject.next([res.item.Table[0]]);
            this.goToBox(
              order.TipoStock,
              order.CodicePratica,
              plannerType
            );
          } else {
            this.toastr.error(
              this.translate.instant('LABEL.NoSearchResults'),
              this.translate.instant('TOASTR.WARNING')
            );
          }
        }
        this.libService.unlockPage();
      },
      error: (e: any) => {
        console.log(e);
        this.libService.unlockPage();
        this.toastr.error(
          this.translate.instant('LABEL.NoSearchResults'),
          this.translate.instant('TOASTR.WARNING')
        );
      },
    });
  }

  goToBox(sectionId: any, code: any, plannerType: string) {
    let section = this.stockService.getStockCode(sectionId, plannerType);
    let specificPath = this.getSpecificPath(plannerType);
    this.router.navigate([`/planner/${specificPath}`, 'list', section, code]);
  }

  getSpecificPath(plannerType: string) {
    return plannerType == 'core' ? '' : `${plannerType}/`;
  }
}
