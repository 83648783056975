import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from '../services/profileService';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';
import { trigger, state,style,transition,animate} from '@angular/animations';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [
    trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4,0.0, 0.2, 1)')),
    ])]
})
export class SettingsComponent implements OnInit {

  @Input() embed: any;

  displayedColumns = ['id', 'name', 'isStaff','createdAt', 'updatedAt', 'viewCostAction', 'editAction', 'deleteAction'];
  displayedColumnsSlave = [ 'name', 'level', 'causalId', 'causalCode','isIncrement','cStandard', 'causalUiLabel'];

  dataSource: any;
  dataSourceSlave: any;
  oldSelectedElement:any;
  kwSearch: any;
  expandedElement: any;
  title = "Profili";
  subTitle = "EDAC - Profili";
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @Input()
  newProfileName: string;

  @ViewChild('closeModal') closeModal: ElementRef
  @ViewChild('closeModal2') closeModal2: ElementRef

  @Input()
  selectedProfile: any;

  constructor(public navService: NavService, private profileService: ProfileService, public libService: LibService, private router: Router) {
    this.dataSource = new MatTableDataSource();
  }


  clickPP(element:any) {


    this.expandedElement = this.expandedElement === element ? null : element;

    if (this.expandedElement === element) {
      console.log('UGUALI');
    }
    else {
      console.log('DIVERSI');
    }

    if (this.oldSelectedElement != null && this.oldSelectedElement != element)
      this.oldSelectedElement.selected = false;

    if (element.selected == false) {
      if (this.expandedElement != null)
        this.expandedElement.selected = true;
      element.selected = true;
      element.touched = true;
    }
    else {
      element.selected = false;
      if (this.expandedElement != null)
        this.expandedElement.selected = false;

    }
    this.oldSelectedElement = element;

  }


  ngOnInit(): void {
    this.loadProfiles();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('PRH');
    let actions: any;
    actions = [
      { name: 'new', url: 'profile/0' },
     // { name: 'export', url: '' },
    ];
    this.navService.setActions(actions);
  }

  markProfileForDeletion(profile: any) {
    this.selectedProfile = profile;
  }

  goTo(e: any) {
    this.router.navigate(['profile/' + e]);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  getColorOpen(p: any) {
    if (p)
      return 'color:green;';
    else
      return 'color:azure;';
  }

  getColorUsed(p: any) {
    if (p == null)
      return 'far fa-user green';
    else
      return 'fas fa-user blue';
  }

  loadProfiles(): void {
    this.libService.lockPage('');
    this.profileService.getAllProfiles().subscribe((response) => {
      console.log('loadProfiles response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
  }

  createProfile(): void {
    this.libService.lockPage('');
    this.profileService.createProfile(this.newProfileName).subscribe((response) => {
      this.closeModal.nativeElement.click();
      this.libService.unlockPage();
      this.dataSource.data.push(response);
      this.dataSource.data = this.dataSource.data.slice();
    });
  }

  deleteProfile(id: number): void {
    this.libService.lockPage('');
    this.profileService.deleteProfile(id).subscribe(() => {
      this.closeModal2.nativeElement.click();
      this.libService.unlockPage();
      this.dataSource.data = this.dataSource.data.filter((x: any) => x.id !== id);
      this.dataSource.data = this.dataSource.data.slice();
    })
  }
}




