<div class="container-fluid my-container">
  <div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
        placeholder="{{ 'LABEL.SEARCH' | translate }}" />
    </div>
  </div>
  <div style="overflow-x: auto;">
    <table mat-table [dataSource]="dataSource" style="width:100%" multiTemplateDataRows>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{'LABEL.ID' | translate}}</th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{'LABEL.Nome' | translate}}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.name}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="isStaff">
        <th mat-header-cell *matHeaderCellDef>{{'LABEL.isStaff' | translate}}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><i *ngIf="element.isStaff == true" class="material-icons">checked</i></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>{{'LABEL.CREATION_DATE' | translate}}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef>{{'LABEL.UPDATE_DATE' | translate}}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="viewCostAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="clickPP(element)"><i
            *ngIf="element['PSCRel'] == null || element['PSCRel']['ProfilesSocietiesCosts'] == null || element['PSCRel']['ProfilesSocietiesCosts'].length == 0"
            class="my-bounce material-icons ic">attach_money</i>
          <i *ngIf="!(element['PSCRel'] == null) && !(element['PSCRel']['ProfilesSocietiesCosts'] == null) && element['PSCRel']['ProfilesSocietiesCosts'].length > 0"
            class="my-bounce material-icons icsave">attach_money</i>
      </ng-container>

      <ng-container matColumnDef="editAction">

          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element"><i *ngIf="!(embed == true)" (click)="goTo(element.id)"
              class="my-bounce material-icons icsave">edit</i>

      </ng-container>
      <ng-container matColumnDef="deleteAction">

        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><i *ngIf="!(embed == true)" class="my-bounce material-icons icsave"
            (click)="markProfileForDeletion(element)" data-bs-toggle="modal"
            data-bs-target="#deleteUserModal">delete</i>

      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="ee-element-detail" [@detailExpand]="element ===
        expandedElement ? 'expanded' : 'collapsed'">

            <table *ngIf="!(element['PSCRel'] == null) && !(element['PSCRel']['ProfilesSocietiesCosts'] == null)" mat-table [dataSource]="element['PSCRel']['ProfilesSocietiesCosts']" style="width:100%">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>{{'LABEL.SOCIETY' | translate}}</th>
                <td mat-cell *matCellDef="let element2" style="color:#008DE1"> {{element2.socName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="level">
                <th mat-header-cell *matHeaderCellDef>{{'LABEL.LEVEL' | translate}}</th>
                <td mat-cell *matCellDef="let element2" style="color:#008DE1"> {{element2.level}}
                </td>
              </ng-container>

              <ng-container matColumnDef="causalId">
                <th mat-header-cell *matHeaderCellDef>{{'LABEL.causalId' | translate}}</th>
                <td mat-cell *matCellDef="let element2" style="color:#008DE1">
                  {{element2.causalId}}
                </td>
              </ng-container>

              <ng-container matColumnDef="causalCode">
                <th mat-header-cell *matHeaderCellDef>{{'LABEL.causalCode' | translate}}</th>
                <td mat-cell *matCellDef="let element2" style="color:#008DE1">
                  {{element2.causalCode}}
                </td>
              </ng-container>

              <ng-container matColumnDef="isIncrement">
                <th mat-header-cell *matHeaderCellDef>{{'LABEL.isIncrement' | translate}}</th>
                <td mat-cell *matCellDef="let element2" style="color:#008DE1">
                  {{element2.isIncrement}}
                </td>
              </ng-container>

              <ng-container matColumnDef="cStandard">
                <th mat-header-cell *matHeaderCellDef>{{'LABEL.cStandard' | translate}}</th>
                <td mat-cell *matCellDef="let element2" style="color:#008DE1">
                  <span *ngIf="!(element2.isIncrement == true)">{{element2.cStandard | number: '1.2-2'}}</span>
                  <span *ngIf="(element2.isIncrement == true)">{{element2.cStandard | number: '1.2-2'}}%</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="causalUiLabel">
                <th mat-header-cell *matHeaderCellDef>{{'LABEL.causalUiLabel' | translate}}</th>
                <td mat-cell *matCellDef="let element2" style="color:#008DE1">
                  {{element2.causalUiLabel | translate}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsSlave;"></tr>
              <tr mat-row *matRowDef="let row; let element2; columns: displayedColumnsSlave;">
              </tr>
            </table>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="ee-element-row"
        [class.ee-expanded-row]="expandedElement === element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="ee-detail-row"></tr>

    </table>
  </div>
  <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
  </mat-paginator>
</div>

<div class="modal fade" id="newUserModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'PROFILES.CREATE' | translate }}</h5>
        <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="recipient-name" class="col-form-label">{{ 'PROFILES.NAME' | translate }}</label>
          <input type="text" class="form-control" id="recipient-name" [(ngModel)]="newProfileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'PROFILES.CLOSE' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="createProfile()">{{ 'PROFILES.SAVE' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteUserModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ 'PROFILES.DELETE' | translate }}</h5>
        <button #closeModal2 type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <p *ngIf="selectedProfile">{{ 'PROFILES.DELETE_CONFIRM' | translate }} {{selectedProfile.name}}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'PROFILES.CLOSE' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="deleteProfile(selectedProfile.id)">{{ 'PROFILES.CANCEL' | translate }}</button>
      </div>
    </div>
  </div>
</div>
