import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-request-link-practice',
  templateUrl: './request-link-practice.component.html',
  styleUrls: ['./request-link-practice.component.scss']
})
export class RequestLinkPracticeComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isNew: any;
  @Input() components: any;

  attributes: any;

  orderCode: string;

  componentName: any = 'request_link_practice'

  constructor(
    private orderService: OrderService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes - ', this.componentName)
    console.log(changes)

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'form': {
            let form = changes[propName].currentValue
            let id = form.get('id')?.value
            this.showOrderLink(id);
          }
        }
      }
    }
  }

  ngOnInit(): void {

  }

  setAttributes() {
    //console.log('setAttributes')
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) this.setAttributes()
    return status
  }

  showOrderLink(requestId: any = null) {
    console.log('showOrderLink')
    if (this.checkComponent()) {
      let id: any = requestId || this.form?.get('id')?.value
      this.orderService.getOrderByRifRequest(id).subscribe(order => {
        console.log("ORDER DA CHIAMATA RIF REQUEST --> ", order);
        order.data ?
          this.orderCode = order.data.code :
          console.log("No Orders for this request");
        console.log("Order Code --> ", this.orderCode);
      })
    }
  }

}
