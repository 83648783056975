<div class="container-fluid my-container">
  <h1>{{ 'MARKETINGNOTES.mainTitle' | translate }}</h1>
  <button (click)="goToPage({id:0})" id="kt_sign_in_submit" class="btn btn-lg btn-primary">
    <span class="indicator-label text-dark">{{ 'MARKETINGNOTES.add' | translate }}</span>
  </button>
  <div appearance="fill" class="form-subject">
    <mat-grid-list cols="2" rowHeight="5:1">

      <!-- FILTRI -->
       
    </mat-grid-list>
  </div>

  <br>


  <div style="overflow-x: auto;">

    <app-settings-data-table [data]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns"
      [actions]="actions" sortActive=""></app-settings-data-table>


  </div>

</div>


<ng-template #itemDialog>
  <app-action-form></app-action-form>
</ng-template>