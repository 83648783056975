<mat-form-field appearance="fill" style="width: 100%;">
  <mat-label>{{label}}</mat-label>
  <mat-select [(ngModel)]="currentValue" (selectionChange)="selectionChanged($event)" #singleSelect [disabled]="disable">
    <mat-option>
      <ngx-mat-select-search
        *ngIf="true"
        [formControl]="filterControl"
        [preventHomeEndKeyPropagation]="true"
        [placeholderLabel]="'LABEL.SEARCH' | translate">
        <span ngxMatSelectNoEntriesFound>
          {{noMatchLabel}}
        </span>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option> - </mat-option>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      <div *ngIf="viewField !== ''">
        {{item[viewField]}}
      </div>
      <div *ngIf="viewField === ''">
        {{item}}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
