import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WorkType } from '../models/workType';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class WorkTypeService {

  baseApi = environment.rootApiCrm + 'v1.0';

  constructor(public libService: LibService) { }



  getAllWorkTypes(): Observable<WorkType[]> {
    console.log("SERVICE FRONT: "+this.baseApi);
    return this.libService.getData<any>(this.baseApi, `/workTypes`);
  }

  getById(id: any): Observable<WorkType> {
    return this.libService.getData<any>(this.baseApi, `/worktype/${id}`);
  }

  createWT(workType: any): Observable<string> {
    return this.libService.postData<any>(workType, this.baseApi, `/workType`)
  }

  updateWT(workType: any): Observable<string> {
    return this.libService.postData<any>(workType, this.baseApi, `/workType/${workType.id}`)
  }

  deleteWT(wt: any) {
    return this.libService.deleteData<any>(wt, this.baseApi, `/workType/${wt.id.toString()}`);
  }
  /* getRequest(id: string) {
    return this.libService.getData<any>(this.baseApi, `/request/${id.toString()}`);
  }
  updateRequest(id: string, request: any) {

    //return this.libService.putData<any>({request}, this.baseApi, `/request/${id.toString()}`);
    return this.libService.postData<any>(request, this.baseApi, `/request/${id.toString()}`)
  }



  createRequest(request: any) {
    console.log("OGGETTO REQUEST SERVIZIO ", request);
    return this.libService.postData<any>(request, `${this.baseApi}`,'/request');
  } */

}












