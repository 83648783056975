import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

@Component({
  selector: 'app-paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.scss']
})
export class PaginatedTableComponent implements OnInit, AfterViewInit {

  @Input()
  displayedColumns: any

  @Input()
  dataSource: any;

  @Input()
  kwSearch: any;

  @Input()
  length: any;

  @Input()
  pageSize: any;

  @Input()
  pageSizeOptions: any;

  @Input()
  pathTarget: string;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      this.dataSource.paginator = this.paginator;
  }

  // TODO fix here
  goTo(element: any, target: string) {
    this.router.navigate([target + element.idProfile]);
  }
}
