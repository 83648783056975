import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { LibService } from '../../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UserSessionService } from '../../../services/user-session.service';
import { NavService } from '../../../services/nav.service';
import { TranslatorService } from '../../../services/translate.service';
import { MatPaginator } from '@angular/material/paginator';
import { ActionService } from '../../../services/action.service'
import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';
import { MarketingNoteService } from '../../../services/marketingNote.service';
import { EventService } from '../../../services/event.service';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import * as XLSX from 'xlsx';
import { EventUploadDocumentModal } from '../event-modal/event-upload-document-modal/event-upload-document-modal.component';
import { EventDocumentService } from '../../../services/eventDocument.service';


@Component({
  selector: 'app-event-documents',
  templateUrl: './event-documents.component.html',
  styleUrls: ['./event-documents.component.scss']
})

export class EventDocumentsComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;

  itemType = "marketing_notes"
  sectionName = "marketing-note-settings"
  dataSource: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  sort: MatSort

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('itemDialog') itemDialog: TemplateRef<any>
  @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  searchTimeout: any;

  marketingNoteList: any = [];

  constructor(public navService: NavService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    public translate: TranslatorService,
    private actionService: ActionService,
    private marketingNoteService: MarketingNoteService,
    private eventService: EventService,
    private dialog: MatDialog,
    private eventDocumentService: EventDocumentService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

 
  displayedColumns = ['type', 'name', 'description', 'path', 'fileType', 'createdAt'];

  structure: any = {
    id: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
    name: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
  }



  columns: any = [
    {
      name: 'type',
      flex: '6',
      title: this.getTitle('type'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item?.eventDocumentType?.label
    },
    {
      name: "name",
      title: this.getTitle('name'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.name
    },
    {
      name: "description",
      title: this.getTitle('description'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.description
    },
    {
      name: "path",
      title: this.getTitle('path'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.awsPath
    },
    {
      name: "fileType",
      title: this.getTitle('fileType'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.type
    },
    {
      name: "createdAt",
      title: this.getTitle('createdAt'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.createdAt
    },
  ]

  getTitle(type: any) {
    let title: any = this.translate.get('LABEL.NOT_PRESENT')
    switch (type) {
      case 'type':
        title = this.translate.get('LABEL.type')
        break;
      case 'name':
        title = this.translate.get('LABEL.name')
        break;
      case 'description':
        title = this.translate.get('LABEL.description')
        break;
        case 'path':
        title = this.translate.get('LABEL.path')
        break;
      case 'fileType':
        title = this.translate.get('LABEL.fileType')
        break;
      case 'createdAt':
        title = this.translate.get('LABEL.createdAt')
        break;

    }
    return title
  }


  getBooleanLabel(value: any) {
    if ([null, undefined].includes(value)) {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    } else {
      return value ? this.translate.instant('LABEL.YES') : this.translate.instant('LABEL.NO') as string
    }
  }



  actions: any = [];

  openDocumentUploader(){

    const dialogRef = this.dialog.open(EventUploadDocumentModal, {
      width: '400px',
      data: []
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (![null, undefined].includes(result)) {
       
 
      }

    });
  }


  setUserActions() {

    this.actions.push({
      label: of(''),
      cssClass: (item: any) => ['redButton'],
      icon: (item: any) => {
        return 'cancel';
      },
      onClick: (item: any) => this.removeItem(item),
      color: 'primary'
    })


  }



  removeItem(item: any) {
    if (confirm("Sei sicuro di voler rimuovere questo media?") == true) {
      this.eventDocumentService.destroy(item.id).subscribe((responseData: any) => {
        this.loadData();
      });
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }



  ngOnInit(): void {

    this.loadData();
    this.setUserActions();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      { name: 'new', url: 'actions/0' },
      //{ name: 'export', url: '' },
    ];
    this.navService.setActions(actions);

    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();

    clearTimeout(this.searchTimeout)
    // this.searchTimeout = setTimeout(() => this.updateCompaniesData(), 1000)
  }



  loadData(): void {

    this.libService.lockPage('');

    let eventId = this.form?.get('id')?.value

    this.eventService.documents(eventId).subscribe((responseData: any) => {
      console.log('loadDataSets response %o', responseData);
      this.libService.unlockPage();
      this.dataSource = responseData;
    }, err => {
      console.log(err)
    });

  }

  goToPage(item: any) {
    this.router.navigate([`settings/${this.sectionName}/` + item.id]);
  }

  saveForm(attributes: any) {
    console.log(attributes)
  }

  filter(event: any) {
    console.log(event)
    let actions = this.marketingNoteList.filter((action: any) => action.TranslationCategoryId == event.value)
    this.dataSource = actions
  }

  downloadExport() {
    let stampDate = new Date().getTime()
      let sheet = XLSX.utils.json_to_sheet(this.dataSource);
      XLSX.utils.sheet_add_aoa(sheet, [this.displayedColumns], { origin: 'A1' });
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, sheet, `EventRegistrations`);

      var bin = XLSX.writeFile(workbook, `${stampDate}_eventRegistrations.xlsx`);
  }


}
