import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JourneyService {

  baseApi = environment.rootApiPaperwork;

  constructor(
    public libService: LibService,
  ) { }

  getJourneys(companyId: number, fromDate: number, toDate: number): Observable<any> {
    return this.libService.postData<any>(
      {
        company : companyId.toString(),
        fromdate : fromDate.toString(),
        todate : toDate.toString()
      },
      this.baseApi, 
      'orders/getjourneys'
      );
  }

}
