import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FundingService {

  baseApi = environment.rootApiPaperwork;

  constructor(
    public libService: LibService,
  ) { }

  getFundingsBySocietyAndBusinessLine(societyIds: Array<any>, businessLineIds: Array<any>): Observable<any> {
    return this.libService.postData<any>(
      {
        businessLineIds : businessLineIds,
        societyIds: societyIds
      },
      this.baseApi, 
      'fundings/getFundingsBySocietyAndBusinessLine'
      );
  }

}
