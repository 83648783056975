import { AbstractControl } from '@angular/forms';
import { Society } from './../../models/society';
import { SocietyService } from './../../services/societyService';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BranchService } from 'src/app/services/branch.service';
import { CompanyService } from 'src/app/services/companyService';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { codeCharacter } from 'src/app/validators/code.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-branch-company',
  templateUrl: './branch-company.component.html',
  styleUrls: ['./branch-company.component.scss']
})
export class BranchCompanyComponent implements OnInit {

  isNew: boolean;
  id: any;
  target: any;
  target$: Observable<any>;
  formTargets: UntypedFormGroup = new UntypedFormGroup({})
  allCompanies: any;
  allSocieties: any;
  selectedCompanies: number[] = [];
  groupedSocieties: any;
  @ViewChild('companiesSelection') companiesSelection: MatSelect;
  doneCreatingForm = false
  allSelected: boolean = false

  constructor(private fb: UntypedFormBuilder,
    private branchService: BranchService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private societyService: SocietyService,
    private libService: LibService,
    public translate: TranslatorService,
    private toastService: ToastrService,
    public navService: NavService,
    private router: Router) {
    this.formTargets = this.fb.group({
      societies: this.fb.array([])
    })
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if(this.id != 0) this.branchService.getBranc(this.id).subscribe(branch => {
        this.target = branch
      

        this.companyService.getAllCompaniesFast().subscribe(companies => {
          this.allCompanies = companies
          
          this.societyService.getAllSocieties().subscribe(societies => {
            this.allSocieties = societies
            
            this.groupedSocieties = this.allSocieties.map((s: any) => ({
              companies: this.allCompanies.filter((c: any) => c.CompaniesSocietiesRel != undefined && c.CompaniesSocietiesRel.length > 0 && c.CompaniesSocietiesRel[0].id == s.id),
              ...s
            }))
            this.groupedSocieties.push({
              companies: this.allCompanies.filter((c: any) => c.CompaniesSocietiesRel == undefined || c.CompaniesSocietiesRel.length == 0),
              name: this.translate.instant('LABEL.COMPANIES_WITHOUT_SOCIETIES')
            })
            this.groupedSocieties.forEach((gs: any) => {
              this.societies.push(this.fb.group({
                society: gs,
                selected: false,
                companies: this.fb.array(gs.companies.map((c: any) => {
                  return this.fb.group({
                    company: c,
                    selected: this.target.BCRel.find((c2: any) => c2.id == c.id)
                  })
                }))
              }))
            })
            this.societiesControls.forEach((s: UntypedFormGroup) => {
              s.controls['selected'].setValue(this.allCompaniesSelected(s))
            })

            this.allSelected = this.societiesControls.reduce((prev: boolean, curr: UntypedFormGroup) => {
              if(! this.allCompaniesSelected(curr)) return false
              else return prev
            }, true)

            this.doneCreatingForm = true
          })
        })
      })
    });
  }

  get societies() {
    return this.formTargets.get('societies') as UntypedFormArray;
  }

  get societiesControls() {
    return (this.formTargets.get('societies') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  allCompaniesSelected(society: UntypedFormGroup){
    if(this.getCompaniesOfSociety(society).length == 0) return false
    return this.getCompaniesOfSociety(society).reduce((p: boolean, c: UntypedFormGroup) => {
      if(! c.controls['selected'].value) return false
      else return p
    }, true)
  }

  someSelected(society: UntypedFormGroup){
    return this.getCompaniesOfSociety(society).reduce((p: boolean, c: UntypedFormGroup) => {
      if(c.controls['selected'].value) return true
      else return p
    }, false) && !this.allCompaniesSelected(society)
  }

  getCompaniesOfSociety(society: UntypedFormGroup){
    return (society.controls['companies'] as UntypedFormArray).controls as UntypedFormGroup[]
  }

  getFormControl(form: AbstractControl){
    return form as UntypedFormControl
  }

  selectAll(){
    for(let society of this.societiesControls){
      this.selectSociety(society, !this.allSelected)
    }
    this.allSelected = !this.allSelected
  }

  selectSociety(society: UntypedFormGroup, value?: boolean){
    const toggle = value != undefined ? value : society.controls['selected'].value
    if(value != undefined){
      society.controls['selected'].setValue(value)
    }
    this.getCompaniesOfSociety(society).forEach(fg => {
      fg.controls['selected'].setValue(toggle)
    })
  }
  
  update() {
    const formObject = this.formTargets.getRawValue()
    console.log(formObject)
    const toSend = {
      id: this.target.id,
      branch: {...this.target},
      companies: {
        ids: formObject.societies.reduce((p: number[], c: any) => {
          if(c.companies?.length > 0){
            const filtered = c.companies.filter((c2: any) => c2.selected)
            p = p.concat(filtered?.map((f:any) => f.company.id))
          }
          return p
        }, new Array<number>()),
      }
    }
    this.branchService.updateBranch(toSend).subscribe({
      next(success){
        console.log(success)
      },
      error: (err) => {
        console.error(err)
        this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
      },
      complete: () => {
        console.log('Done')
        this.toastService.success(
          this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
      }
    })
  }

  ngOnInit(): void {
    this.initNavbar();
  }

  initNavbar(){
    setTimeout(() => {
      let actions: any;
      actions = [
        { name: 'back', url: 'genconfig' }
      ];

      let title = this.formTargets.get('id')?.value !== null?
        this.translate.instant('LABEL.EDIT', { field: 'Ramo' }):
        this.translate.instant('LABEL.NEW', { field: 'Ramo' })
      let subTitle = "EDAC - " + title
      this.navService.setActions(actions);
      this.navService.setTitle(title);
      this.navService.setSubTitle(subTitle);
    }, 1000);

  }

}
