<div class="container-fluid" #container>
  <button mat-raised-button style="float:right;" (click)="create()">
    {{ 'LABEL.CREATE_TARGET' | translate }}
  </button>
  <table mat-table [dataSource]="targets" matSort style="width:100%;">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align:center;" class="back-table centered">{{ 'LABEL.ID' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element" style="padding: 0; width: auto; text-align: center;">
        {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.TARGET_DESCR' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element" style="padding: 0; width: auto; "> <span><b>{{ element.description }}</b></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="entityName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.ENTITY_NAME' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element" style="padding: 0; width: auto; "> <span>{{ element.entityName }}</span>
      </td>
    </ng-container>


    <ng-container matColumnDef="editAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="goTo(element)"><i class="material-icons icsave">edit</i></td>
    </ng-container>
    <ng-container matColumnDef="deleteAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" (click)="deleteRow(element)"><i class="material-icons icsave">delete</i></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
