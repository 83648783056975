import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GeocodingService } from '../services/geocoding.service';
import { LibService } from '../services/libService';
import { NavService } from '../services/nav.service';
import { of, map, Observable, startWith, switchMap } from 'rxjs';
import { TranslatorService } from '../services/translate.service';
import { ClaimService } from '../services/claim.service';
import { WikiService } from '../services/wikiservice.service';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit {

  title = "Nuovo Sinistro";
  subTitle = "EDAC - Nuovo Sinistro";
  form: UntypedFormGroup;
  formS: UntypedFormGroup;
  tabIndex: any;
  id: string;
  versionValue = 1;

  isNew: boolean =  false;
  request: any;

  claim$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    switchMap(id => {
      if(id!== '0'){
        this.isNew = false;
        this.title = "Modifica Sinistro";
        this.subTitle = "EDAC - Modifica Sinistro";
        this.id = id!;
      }else{
        this.isNew = true;
        this.id = '0';
      }
      return id!=='0'?this.claimService.getClaim(id!):of('')})
  )

  myControl: UntypedFormControl = new UntypedFormControl('');
  options: any[] = ["primo", "secondo", "terzo"];
  filteredOptions: Observable<any>;
  kwSearch: any;
  dataSource: any;


  constructor(public navService: NavService,
    public wikiService: WikiService,
    private fb: UntypedFormBuilder,
    public libService: LibService,
    private router: Router,
    private route: ActivatedRoute,
    private claimService: ClaimService,
    private geocodingService: GeocodingService,
    private toastr: ToastrService,
    public translate: TranslatorService) { }

  ngOnInit(): void {

    this.loadLabels();

    //Form for New / Edit a Claim
    this.form = this.fb.group({
      n_claim_marsh: [null],
      n_claim_client: [null],
      version: [null,[Validators.required]],
      startDate: [null],
      endDate: [null],
      policyNumber: [null],
      delegatingCompany: [null],
      mainGuarantee: [null],
      claimCompanyNumber:[null],
      complainant: [null],
      description: [null],
      type: [null],
      cause: [null],
      claimStatus: [null],
      location: [null],
      province: [null],
      damageValue:[null],
      deductible: [null],
      due:[null],
      paid: [null]
    });

    // If Edit, patch Value into the form
    this.claim$.subscribe((claim: any)=> {
      console.log("CLAIM");
      console.log(claim);
      if(claim !== ''){
        this.form.patchValue(claim);
      }else
      {
        console.log("HERE");
        this.form.setValue({version: 1, n_claim_marsh: "",n_claim_client: "", startDate: "", endDate: "", policyNumber: "", delegatingCompany: "", mainGuarantee: "", claimCompanyNumber:"", complainant: "", description: "", type: "", cause: "", claimStatus: "", location: "", province: "", damageValue:0, deductible: 0, due:0, paid: 0});
      }
    })

  }

  loadLabels(): void {
    this.translate.get('LABEL.NEW_CLAIM').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }


goToWiki() {
  window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
}

goToWikiByKey(k:any){
  window.open(this.wikiService.getWikiUrl(k));
}

  save(): void {
    if(this.form.valid){
      this.libService.lockPage('');

      //Update
      if (this.isNew == false) {
        this.request = this.form.getRawValue();
        this.request.id = this.id;

        console.log("[COMPONENT] UPDATE");
        console.log(this.request);

        this.claimService.updateClaim(this.request.id, this.request).subscribe((response: any) => {
          this.libService.unlockPage();
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('LABEL.Info'));
        },
        err => {
          this.libService.unlockPage();
          this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
        });
      }
      else{
        //Insert
        this.request = this.form.getRawValue();

        console.log("[COMPONENT] INSERT");
        console.log(this.request);

        this.claimService.createClaim(this.request).subscribe((response: any) => {
          this.libService.unlockPage();
          //this.router.navigate(["claims"]);
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('LABEL.Info'));
        },
        err => {
          this.libService.unlockPage();
          this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
        });
      }
    }
  }

}
