import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { LogService } from './log.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class ComplaintService {

  baseApi = environment.rootApiCrm + 'v1.0';
  private baseApiFinance = 'https://albertfinance.azurewebsites.net/V1/';

  constructor(
    public libService: LibService, 
    private logService: LogService,
    private authService: AuthService,
  ) { }

  getComplaint(id: string) {
    return this.libService.getData<any>(this.baseApi, `/complaint/${id.toString()}`);
  }

  getAllComplaints(): Observable<any> {
    console.log("SERVICE FRONT: "+this.baseApi);
    return this.libService.getData<any>(this.baseApi, `/complaints`);
  }

  updateComplaint(id: any, claim: any) {
    return this.libService.postData<any>(claim, this.baseApi, `/complaint/${id.toString()}`).pipe(
      tap(response => {
          this.logService.writeLog('POST', this.baseApi +`/complaint/${id.toString()}`, claim, 'OK')
      }),
      catchError(error => {
          this.logService.writeLog('POST', this.baseApi +`/complaint/${id.toString()}`, claim, error.status)
          return of(error);
      })
    )
  }

  deleteComplaint(id: any, claim: any) {
    return this.libService.deleteData<any>(claim, this.baseApi, `/complaint/${id.toString()}`);
  }

  createComplaint(claim: any) {
    return this.libService.postData<any>(claim, `${this.baseApi}`,'/complaint').pipe(
      tap(response => {
          this.logService.writeLog('POST', this.baseApi +'/complaint', claim, 'OK')
      }),
      catchError(error => {
          this.logService.writeLog('POST', this.baseApi +'/complaint', claim, error.status)
          return of(error);
      })
    );
  }

  getOrderDetail(complaint: any) {
    console.log("COMPLAINT FRONTEND SERVICE -->", complaint)
    return this.libService.postData<any>({code: complaint.orderNumberEA, version: complaint.orderVersion}, `${this.baseApi}`,'/orderDetail');
  }

  getComplaintByOrder(code: string, version: number) {
    return this.libService.postData<any>({code: code, version: version}, `${this.baseApi}`,'/findByCodeVersion');
  }

  allWithPagination(filterOptions: any = [], orderOptions: any = [], skip: number = 0, take: number = 10) {
    console.log("allWithPagination")
    let tokenId = this.authService.idToken()
    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption
    }
    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `/complaints/allWithPagination`)
  }

}
