import { Component, NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { AuthConfig, OAuthLogger } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthService } from './auth/auth.service';
import { environment } from 'src/environments/environment';
import { LibService } from './services/libService';
import { NavService } from './services/nav.service';
import { SidebarService } from './services/sidebar.service';
import { PlannerFullPageService } from './services/plannerFullPage.service';
import { ConfService } from './services/conf.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { UserSessionService } from './services/user-session.service';
import { combineLatest } from 'rxjs';
import { ActiveSessionService } from './services/active-session.service';
import { ActiveSession } from './models/activeSession';
import { Router, ActivatedRoute } from '@angular/router';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer:
    'https://cognito-idp.eu-west-1.amazonaws.com/' +
    environment.cognitoUserPoolId, // eu-west-1_vq0waCEDJ',//eu-west-1_XV7eAu2uH',

  // URL of the SPA to redirect the user to after login
  redirectUri: environment.redirectUri,
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: environment.cognitoAppClientId, // '7btp64tds3cvn5d07gtn643g7v',//'19i39aj252hfpbbe2pgsrbu1s3',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',
  //oidc:true, // ID_Token
  responseType: 'code',
  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid profile email',
  strictDiscoveryDocumentValidation: false,
  showDebugInformation: true,
  //revocationEndpoint: 'https://edacopenid.auth.eu-west-1.amazoncognito.com/oauth2/revoke'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  maintenance: boolean = false;
  alternativeLogin: boolean = false;

  constructor(
    private oauthService: OAuthService,
    public libService: LibService,
    private authService: AuthService,
    private sidebarService: SidebarService,
    public navService: NavService,
    public confService: ConfService,
    private router: Router,
    translate: TranslateService,
    private userSessionService: UserSessionService,
    private activeSessionService: ActiveSessionService,
    private plannerFullPageService: PlannerFullPageService,
    private route: ActivatedRoute,
  ) {

    // console.log('OAuth init logout %o', authCodeFlowConfig.postLogoutRedirectUri);
    this.oauthService.configure(authCodeFlowConfig);
    // this.oauthService.setStorage(sessionStorage);
    // this.oauthService.initLoginFlow();
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // this.oauthService.setStorage(localStorage);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    //this.libService.lockPage('');
    this.confService.getAllConfs().subscribe((response) => {
      //console.log('loadConfs response %o', response);
      //this.libService.unlockPage();
      if (response.length > 0) {
        this.navService.setConf(response[0]);
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
    translate.addLangs(['en', 'it', 'fr', 'es']);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('it');
    // the lang to use, if the lang isn't available, it will use the current loader to get them

    if (!(this.userSessionService == null)) {
      let currentLanguage = this.userSessionService.getCurrentLanguage();
      translate.use(currentLanguage);
    } else translate.use('it');

    // Init fase di manutenzione del sistema
    this.initMaintenance()

  }

  areYouAMaintainer(profiles: any = []) {
    let maintainer = profiles.find((profile: any) => profile.code == "HQ-MAN")
    return ![undefined, null].includes(maintainer)
  }

  initMaintenance() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params)
        console.log('alternativeLogin' in params)

        // Verifico se è un login alternativo
        if ('alternativeLogin' in params) {
          this.alternativeLogin = true;
          this.maintenance = false;
        } else {
          // Altrimenti verifico se l'utente è un manutentore
          this.userSessionService.getVisibilities().subscribe((res) => {

            if ('UsersProfilesRel' in res) {
              let profiles = res.UsersProfilesRel

              // Se l'utente è un manutentore può proseguire
              if (!this.areYouAMaintainer(profiles)) {
                this.setMaintenance()
              } else {

              }

            } else {
              this.setMaintenance()
            }

          })
        }
      }
      );
  }

  setMaintenance() {
    if (this.authService.inMaintenance()) {
      this.maintenance = true;
      this.authService.logout();
    } else {
      this.maintenance = false;
    }
  }

  user() {
    //console.log('user %o', this.authService.user());
    return this.authService.user();
  }

  login() {
    this.userSessionService.saveState(
      'post_login_redirect',
      window.location.pathname
    );
    this.authService.login();
  }

  isSidebarCollapsed() {
    return this.sidebarService.getCollapsed();
  }

  planningFullPage() {
    return this.plannerFullPageService.getPlannerFullPageValue();
  }
}
