import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { Subscriber, Observable, Subject, debounceTime, switchMap, forkJoin, distinctUntilChanged, pipe, takeUntil, combineLatest, mergeMap, merge, tap, map, of, Subscription } from 'rxjs';
import { SubjectService } from '../services/subject.service'

@Component({
  selector: 'app-subject-fulltextsearch',
  templateUrl: './subject-fulltextsearch.component.html',
  styleUrls: ['./subject-fulltextsearch.component.scss']
})
export class SubjectFulltextsearchComponent implements OnInit {

  @Input() component: any;
  @Input() searchType?: 'Soggetto';
  @Input() events: Observable<void>;
  @Input() searchCleaner: boolean;
  type: string;
  @Output() selected = new EventEmitter;
  @Output() clearOut = new EventEmitter;
  @Output() list = new EventEmitter;


  noResults: boolean = false;
  fullTextSearchSubscriber: Subscriber<any>;
  myControl: UntypedFormControl = new UntypedFormControl('');
  kwSearch: any;
  isLoading: boolean = false;
  minChar = 2;
  private eventsSubscription: Subscription;

  optionList: Array<any>;
  input = new Subject<string>();
  get field() {
    let campo: string = '';

    campo = "idSubject";

    return campo;
  }

  selectedSub: any;
  clearFields: boolean = false;
  inputValue$ = this.input.pipe(debounceTime(500), distinctUntilChanged());
  searchIds$ = this.inputValue$.pipe(
    switchMap((val) => {
      let words: any;
      val.includes(" ") ? words = val.split(" ") : undefined;
      this.isLoading = true;
      if (words !== undefined) {
        val = "";
        words = words.filter((a: any) => a.length > 2 || this.isNumeric(a) == true);
        words = words.map((a: any) => a.trim());
        console.log("WORDS/ PAROLE USATE PER RICERCA --> ", words);
        val = words.join(' ')
        console.log("PAROLA DA MANDARE AL DB --> ", val)
        // words.forEach((element: any) => {
        //   val = val + element.trim() + " ";
        // });
      }

      if (this.minChar) {
        return this.subjectService.getSubjectIdsFTSearch({ kwsearch: val.toLowerCase() }) as Observable<any>
      } else {
        return this.optionList = []
      }
    }),
    tap((subs) => {
      console.log("Soggetti --> ", subs, subs.length)
      subs.length == 0 ? this.optionList = [] : this.isLoading = true;
      this.type = subs.type
    }),
    map(subs => subs.data)
  );

  subjects$ = this.searchIds$

  indexAll$ = this.searchIds$.pipe(
    mergeMap(subs => {
      return subs.length > 0 ?
        forkJoin([[subs]].map((sub: any) => {
          let input = sub.flat(2);
          console.log("VETTORE FLATTATO --> ", sub.flat(2));
          return this.subjectService.getById(input);
        }))
        :
        of([]);
    }),
    tap(subs => {
      this.type = 'all'
    }))

  constructor(
    public translate: TranslateService,
    private datePipe: DatePipe,
    public subjectService: SubjectService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.searchCleaner === true ? this.clear() : undefined;

  }

  isNumeric(str: any): boolean {
    let control = true;
    let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    Array.from(str).forEach((element: any) => {
      console.log("LETTERA --> ", element);
      numbers.includes(element) ? undefined : control = false;
    })

    return control;
  }

  ngOnInit(): void {
    // console.log("EVENTS --> ", this.events);
    if (this.events !== undefined && this.events !== null) {
      this.eventsSubscription = this.events.subscribe(() => {
        console.log("change search type -->");
        this.kwSearch = "";
      });
    }

    this.searchType ? this.kwSearch = '' : undefined;


    this.subjects$.subscribe((res: any) => {
      // this.unSub();

      console.log('subjects mio: ', res);

      this.optionList = res.filter((op: any) => op !== null);
      console.log("OPTION LIST -->", this.optionList);

      this.noResults = this.optionList?.length > 0 ? false : true;
      this.isLoading = false;
      this.list.emit(this.optionList);
    });

    this.isLoading = false;


  }

  fulltextSearch(event: any) {
    // this.unSub();
    let keyWord = event?.toString()
    event = keyWord?.replace(/\s+/g, " ");
    if (!event) {
      this.isLoading = false
      console.log("ricerca VUOTA");
      this.optionList = [];
      this.list.emit(this.optionList);
    } else {
      // console.log("[Trigger Ricerca] - [FullTextSearch2 Component]", event);
      event.length > 2 ?
        this.input.next(event) :
        undefined;
    }
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    console.log("TIPO DI SELEZIONE --> ", typeof event.option.value)
    if (typeof event.option.value == 'undefined') {
      console.log("DO NOTHING --> ",)
    } else {
      this.selectedSub = event.option.value;

      console.log("SOGGETTO SELEZIONATO --> ", this.selectedSub);

      this.myControl.setValue(
        (this.selectedSub?.name !== null && this.selectedSub?.name !== undefined ?
          this.selectedSub?.name?.trim() :
          this.selectedSub?.legalEntity?.trim()) +
        ' ' +
        (this.selectedSub?.surname !== null && this.selectedSub?.surname !== undefined ?
          this.selectedSub?.surname?.trim() :
          ''));

      this.selected.emit(this.selectedSub);
    }
  }

  clear() {
    this.clearFields = true;
    this.kwSearch = "";
    this.clearOut.emit(this.clearFields);
    this.clearFields = false;
  }

  unSub() {
    console.log("SUBSCRIPTION --> ", this.eventsSubscription);
    this.eventsSubscription.unsubscribe();
  }

}
