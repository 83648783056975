<div class="search-wrapper mat-elevation-z1">
  <div class="search" fxLayout="row" fxLayoutAlign="start ">
    <div *ngIf="(isLoading === true)"><mat-spinner style="float:right;" diameter="25"></mat-spinner></div>

    <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
    <input type="text" [placeholder]="translate.instant('FORM_FIELD.SEARCH')" [(ngModel)]="kwSearch" [value]="kwSearch"
      (ngModelChange)="fulltextSearch($event)" [formControl]="myControl" [matAutocomplete]="auto">

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">

      <div>
        <mat-option *ngFor="let option of optionList " [value]="option">
          <span *ngIf="!(option.name == null) && !(option?.surname == null)">{{option?.name + ' ' + option?.surname}}
            <b>{{option.type}}</b></span>
          <span *ngIf="!(option.name == null) && (option?.surname == null)">{{option?.name }}
            <b>{{option.type}}</b></span>
          <span
            *ngIf="(option.name === null || option.name === undefined) && !(option?.surname == null)">{{option?.surname
            }} <b>{{option.type}}</b></span>
        </mat-option>
      </div>
      <div *ngIf="(noResults == true)">
        <mat-option disabled>
          <span><b>{{'LABEL.NoSearchResults' | translate}}</b></span>
        </mat-option>
      </div>
    </mat-autocomplete>

    <button style="background-color:transparent;" matTooltip="Clear" type="button" class="btn" (click)="clear()">
      <mat-icon class="material-icons" style="color:#293683;">clear</mat-icon>
    </button>

  </div>

</div>
<p style="padding-left: 3%; padding-bottom:1.5%;">{{ 'ALERT.MESS1'| translate }}</p>