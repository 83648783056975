<div class="container-fluid">
    <div style="display: flex;">
        <div class="search-wrapper mat-elevation-z2" style="width: 100%;">
            <div class="search" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
                <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
                    placeholder="{{ 'LABEL.SEARCH' | translate }}" />
            </div>
        </div>
        <button mat-raised-button style="float: right; margin: 24px 0px 24px 0px;" (click)="preventFormSubmit($event)" (click)="create()">{{ 'LABEL.ADD' | translate }}</button>
    </div>
    <div style="overflow-x: auto;">
        <table mat-table [dataSource]="dataSource" matSort style="width:100%">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Id</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.id}} </td>
            </ng-container>       
            <ng-container matColumnDef="percStep">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">% Richiesta</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.percStep}}</span>
            </ng-container>
            <ng-container matColumnDef="percIncrement">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">% Incremento società core</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.percIncrement}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="percIncrementFranchising">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">% Incremento società franchising</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.percIncrementFranchising}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Data creazione</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Data ultima modifica
                </th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="editAction">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" (click)="goTo(element.id)"><i class="material-icons icsave">edit</i>
            </ng-container>
            <ng-container matColumnDef="deleteAction">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" (click)="deleteTaxStep(element.id)" ><i class="material-icons icsave">delete</i>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
    </mat-paginator>
</div>
