<div class="container-fluid my-container">
	<div class="full-w">
		<form [formGroup]="formTargets">

			<table style="padding-bottom: 16px">
				<tr>
					<th style="padding-right: 16px">{{ 'LABEL.BRANCH' | translate}}</th>
					<td style="padding-right: 64px">{{ branch?.name || ('LABEL.undefined' | translate)}}</td>
					<th style="padding-right: 16px">{{ 'LABEL.CODE' | translate}}</th>
					<td style="padding-right: 64px">{{ branch?.code || ('LABEL.undefined' | translate)}}</td>
				</tr>
			</table>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.CATEGORY' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="category" (selectionChange)="updateTree()" #categorySelection
					(opened)="onOpenSearch(searchSelect)"
					(closed)="onCloseSearch()">
					<mat-form-field id="searchSelect" appearance="fill">
						<mat-label>{{ 'LABEL.SEARCH' | translate}}</mat-label>
						<input matNativeControl type="text" (keyup)="onChangeFilterCategories($event)" #searchSelect>
					</mat-form-field>
					<mat-option *ngFor="let category of categories" [value]="category.id"> {{ category.name }} ({{ category.codCountry }})</mat-option>
					<mat-option *ngIf="categories?.length == 0" disabled> Nessun risultato </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.CODE' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="code">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.NAME' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="name">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH_STATE' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="codCountry">
					<mat-option *ngFor="let country of countryOptions" [value]="country" (onSelectionChange)="countryChanged($event)"> {{ country }} </mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH_LANG' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="codLanguage">
					<mat-option *ngFor="let lang of langOptions" [value]="lang"> {{ lang }} </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-checkbox matNativeControl formControlName="isPercAmmaloramento">{{ 'LABEL.PERCAMMALORAMENTO' | translate }}</mat-checkbox>
			<mat-checkbox matNativeControl formControlName="isFreeDatas">{{ 'LABEL.isFreeDatas' | translate }}</mat-checkbox>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.DEADLINE' | translate }}</mat-label>
				<input matNativeControl type="date" formControlName="deadLine">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.POSITION' | translate }}</mat-label>
				<input matNativeControl type="number" formControlName="position">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.WORKTYPE' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="idWorkType">
					<mat-option *ngFor="let causal of causals" [value]="causal.id"> {{ causal.workType }} </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-checkbox matNativeControl formControlName="isMultiService">{{ 'LABEL.ISMULTISERVIZI' | translate }}</mat-checkbox>

			<mat-checkbox appearance="fill" matNativeControl formControlName="isActive">{{ 'LABEL.ISACTIVE' | translate }}</mat-checkbox>
		</form>

		<div class="buttons-right my-2">
			<button style="background-color:transparent;" [disabled]="!formTargets.valid || formTargets.pristine"
				matTooltip="{{ 'LABEL.SAVE' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
				(click)="update()">
				<mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
			</button>
		</div>
	</div>
</div>
