<form [formGroup]="form" *ngIf="form && checkComponent()">
  <mat-card>
    <mat-card-title>
      {{ 'LABEL.SUBJ' | translate }}
      <button *ngIf="checkVisibility('reload_subject')" class="my-bounce btn btn-primary mx-2"
        style="background-color:transparent;" (click)="clearForm('subject')">
        <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
      </button>
    </mat-card-title>
    <div>
      <div formGroupName="subject" class="form-container">
        <app-fulltextsearch2 *ngIf="checkVisibility('search')" class="form-subject" [service]="subjectService"
          searchType="Soggetto" (selected)="selSubject($event)" [edit]="verifyDeactivation('search')"></app-fulltextsearch2>

        <mat-form-field appearance="fill" class="form-subject" *ngIf="checkVisibility('type')">
          <mat-label>{{ 'REQUEST.SUBJ_TYPE' | translate }}</mat-label>
          <mat-select matNativeControl name="type" formControlName="type">
            <mat-option *ngFor="let type of types$ | async" [value]="type.description">
              {{type.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="hideLegalEntity && checkVisibility('name')" appearance="fill" class="form-subject">
          <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field *ngIf="hideLegalEntity && checkVisibility('surname')" appearance="fill" class="form-subject">
          <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
          <input matInput formControlName="surname">
        </mat-form-field>

        <mat-form-field *ngIf="!(hideLegalEntity) && checkVisibility('legalEntity')" appearance="fill"
          class="form-subject">
          <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject" *ngIf="checkVisibility('telephone2')">
          <mat-label>{{ 'REQUEST.MOBILE' | translate }}:</mat-label>
          <input matInput type="phone" formControlName="telephone2"
          [placeholder]="translate.instant('LABEL.INSERISCI_NUMERO_TELEFONO_MOBILE')">
          <mat-icon matSuffix>smartphone</mat-icon>
          <mat-hint>{{ 'REQUEST.MOBILE_ERROR' | translate }}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject" *ngIf="checkVisibility('email')">
          <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
          <input matInput type="email" formControlName="email" [placeholder]="translate.instant('LABEL.INSERISCI_EMAIL')">
          <mat-icon matSuffix>mail_outline</mat-icon>
          <!-- <mat-hint>Inserire una email valida</mat-hint>
                        <mat-error **ngIf="(formAnagrafica.get('email')).errors?.required">L'email è obbligatoria
                        </mat-error>
                        <mat-error **ngIf="(formAnagrafica.get('email')).errors?.pattern">E' necessario inserire una
                            email valida
                        </mat-error> -->
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject" *ngIf="checkVisibility('contactChannel')">
          <mat-label>{{ 'REQUEST.CHANNEL' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="contactChannel">
            <mat-option *ngFor="let cc of contactChannels$ | async" [value]="cc.name">
              {{ 'CHANNEL.'+cc.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject" *ngIf="checkVisibility('origin')">
          <mat-label>{{ 'REQUEST.ORIGIN' | translate }}</mat-label>
          <mat-select formControlName="origin">
            <mat-option *ngFor="let origin of origins$ | async" [value]="origin.origin" [disabled]="origin.disabled">
              {{ 'ORIGINS.'+origin.code | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

          <app-address-search *ngIf="checkVisibility('searchAdress') && this.form" [multiCompany]="true"
          [mainForm]="this.form" [businessLineCode]="this.form.get('businessLineCode')?.value" [form]="subjectForm"
          class="form-subject" [edit]="verifyDeactivation('searchAdress')"
          [noButtonSearchCompany]="false" (searchRes)="searchRes($event)"></app-address-search>

        <app-address-detail-form *ngIf="checkVisibility('showAdress')" [form]="subjectForm" class="form-subject"
          [disabled]="verifyDeactivation('showAdress')"></app-address-detail-form>

        <mat-form-field appearance="fill" class="form-subject margin-top" *ngIf="checkVisibility('company')">
          <mat-label>{{ 'REQUEST.COMPANY' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="company">
            <mat-option *ngFor="let company of companies" [value]="company.code">
              {{company.code}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
</form>