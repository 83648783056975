import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivityTypes } from '../models/activityType';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class ActivityTypesService {

  baseApi = environment.rootApiCrm + 'v1.0';
  constructor(private libService: LibService) { }

  getAllActivityTypes(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `/activityTypes`);
  }

  getAllALLActivityTypes(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `/activityTypes/ALL`);
  }

  getById(id: any): Observable<ActivityTypes> {
    return this.libService.getData<any>(this.baseApi, `/activityType/${id}`);
  }

  createAT(workType: any): Observable<string> {
    return this.libService.postData<any>(workType, this.baseApi, `/activityType`)
  }

  updateAT(at: any): Observable<string> {
    return this.libService.postData<any>(at, this.baseApi, `/activityType/${at.id}`)
  }

  deleteAT(at: any) {
    return this.libService.deleteData<any>(at, this.baseApi, `/activityType/${at.id.toString()}`);
  }
}
