import { Component, OnInit, EventEmitter, OnChanges, Input, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SubjectTypes } from 'src/app/models/subject-types';
import { CompanyService } from 'src/app/services/companyService';
import { OriginService } from 'src/app/services/origin.service';
import { combineLatest, map, Observable, of, ReplaySubject, Subject, Subscriber, Subscription, switchMap, takeUntil, tap } from "rxjs";
import { Status } from 'src/app/models/status';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/models/user';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserSessionService } from 'src/app/services/user-session.service';
import { RoleService } from 'src/app/services/role.service';
import { UserDataService } from 'src/app/services/users.service';
import { ChannelService } from 'src/app/services/channel.service';
import { MarketingNoteService } from 'src/app/services/marketingNote.service';

@Component({
  selector: 'app-subject-data',
  templateUrl: './subject-data.component.html',
  styleUrls: ['./subject-data.component.scss']
})
export class SubjectDataComponent implements OnInit, OnChanges {

  @Input() form: UntypedFormGroup;
  @Input() types?: SubjectTypes[]
  @Input() statuses?: Status[]
  @Input() companies: any[] = []
  @Input() loggedUser: any;
  @Input() users: any;
  @Input() disable: boolean;
  @Input() isNew: boolean;
  @Input() globalType: string;
  // @Input() navigate: any;
  @Output() nme: string;

  typeOther: boolean;
  type: string;
  disabled: boolean = false;
  addressMissing = false;
  companyLocal: any;
  mailList = [];
  dataFilterCtrl = new UntypedFormControl();
  _onDestroy = new Subject<void>();
  filteredData: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  disabledContactChannel: boolean = false;
  disabledOrigin: boolean = false;

  responsibles: any[] = []

  contactChannels$ = this.channelService.getAllChannels();

  origins$ = this.originService.getAllOrigins();

  associations$ = of([
    'AIAC',
    'ALAC',
    'ALTRO',
    'ANACI',
    'ANAMMI',
    'ANAP',
    'APE',
    'ARAI',
    'GESTICOND',
    'LIBERO',
    'THNET',
    'UNAI'
  ])

  addressWatcherSub: Subscription

  marketingNotes: any = []

  constructor(
    private companyService: CompanyService,
    private originService: OriginService,
    private authService: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private userSessionService: UserSessionService,
    private roleService: RoleService,
    private userService: UserDataService,
    private cdr: ChangeDetectorRef,
    private channelService: ChannelService,
    private marketingNoteService: MarketingNoteService,
  ) { }

  ngOnInit(): void {

    this.initMarketingNotes()

    this.dataFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterData();
      });
    // this.disableFields();
    this.mailList = this.users?.map((usr: any) => usr.email);
    console.log("MAIL LIST --> ", this.mailList);
    console.log('[SubjectData] init');
    this.type = this.form.get('type')?.value;
    this.typeOther = this.checkTypeOther(this.type);
    this.onTypeChange(this.type);

    this.form.get('type')?.valueChanges.subscribe(type => {
      console.log('[SubjectData] type: ', type);

      this.type = type;
      this.typeOther = this.checkTypeOther(type)
      this.onTypeChange(type)
    })

    this.form.get('company')?.valueChanges.subscribe(value => {

      this.setCompanyLocationAndResponsibles(value);
      this.companyLocal ? this.backOfficeRespAssignment(value) : undefined;

    })

    this.checkIfTheSourceAndChannelFieldsArePopulated()

    //this.companies$.subscribe()

    // this.form.valueChanges.subscribe((value: any) => {
    //   console.log('formvaluechanges',value);
    //   console.log('rawValue', this.clean(this.form.getRawValue()))
    //   this.form.markAllAsTouched()
    //   //this.form.updateValueAndValidity() // can't do, it will enter infinite loop
    //   this.form.get('name')?.markAsTouched()
    //   //this.formChanged.emit(this.clean(this.form.getRawValue()))
    // })

    //this.initAddressWatcher()
  }

  initMarketingNotes(){
    this.marketingNoteService.index().subscribe((res:any)=>{
      res.forEach((marketingNote:any)=>{
        let structure: any = {
          id: marketingNote?.id,
          code: marketingNote?.id,
          disabled: marketingNote?.disabled,
          translations:{}
        }
        marketingNote?.translationGroup?.translations.forEach((translation:any)=>{
          structure.translations[translation.languageCode] = translation.text
        })

        if(marketingNote.subject) this.marketingNotes.push(structure)
      })
      
    })

  }

  checkIfTheSourceAndChannelFieldsArePopulated() {

    this.form.get('contactChannel')?.valueChanges.subscribe(value => {

      // se è presente il valore ma la select non è cambiata -> disabilità la select
      if (![null, undefined].includes(value) && !this.form.get('contactChannel')?.dirty) {
        this.disabledContactChannel = true;
      }

    })

    this.form.get('origin')?.valueChanges.subscribe(value => {

      // se è presente il valore ma la select non è cambiata -> disabilità la select
      if (![null, undefined].includes(value) && !this.form.get('origin')?.dirty) {
        this.disabledOrigin = true;
      }

    })

  }

  filterData() {
    if (!this.users) {
      return;
    }
    let search = this.dataFilterCtrl.value;
    if (!search) {
      this.filteredData.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredData.next(
      this.mailList.filter(
        (x: any) => x.toLowerCase().indexOf(search) > -1
      )
    );
  }

  private setCompanyLocationAndResponsibles(companyCode: string) {
    if (companyCode && (this.companies && this.companies.length > 0)) {

      if (companyCode !== null && companyCode !== undefined) {
        let sessionComp = this.userSessionService.getState(companyCode);
        sessionComp !== null && sessionComp !== undefined ? this.companyLocal = sessionComp :
          this.companyService.getCompanyByCode(companyCode).pipe(
            map((com) => {
              this.companyService.getCompanyOperational(com.id).subscribe(company => {
                console.log("COMPANY TROVATA --> ", company);
                this.companyLocal = company;
                this.userSessionService.saveState(this.companyLocal.code, this.companyLocal)
              })
            })
          ).subscribe()

      }
      // let companyLocal = this.companies.find(company => company.code === companyCode)
      if (this.companyLocal !== null && this.companyLocal !== undefined) {
        try {
          this.form.get('companyLocation')?.setValue(this.companyLocal.CompaniesSocietiesRel[0].name)
        } catch (error) {
          this.toastr.error(this.translate.instant('ERROR.COMPANYLOC'))
        }

        try {

        } catch (error) {
          this.toastr.error(this.translate.instant('ERROR.COMPANYRESP'))
        }
      }
    }
  }

  private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('[SubjectData] changes', changes);
    console.log("USERS --> ", this.users);
    this.form.markAllAsTouched();
    if (changes['companies'] && (changes['companies'].currentValue.length > 0)) {
      this.setCompanyLocationAndResponsibles(this.form.get('company')?.value)
    }
    this.cdr.detectChanges();
  }


  onTypeChange(type: string) {

    if (type) {

      this.clearFormValidators();
      let controlArrayRequired: (AbstractControl | null)[] = []
      let controlArrayEmail: (AbstractControl | null)[] = []

      switch (type) {
        case 'Edificio':
          controlArrayRequired.push(this.form.get('storicBuilding'))
          controlArrayRequired.push(this.form.get('streetName'))
          controlArrayRequired.push(this.form.get('streetNumber'))
          controlArrayRequired.push(this.form.get('city'))
          controlArrayRequired.push(this.form.get('country'))
          controlArrayRequired.push(this.form.get('zip'))
          controlArrayRequired.push(this.form.get('company'))
          controlArrayRequired.push(this.form.get('origin'))
          controlArrayRequired.push(this.form.get('contactChannel'))
          break;

        case 'Privato':
          controlArrayRequired.push(this.form.get('name'))
          controlArrayRequired.push(this.form.get('surname'))
          // controlArrayEmail.push(this.form.get('email'))
          // controlArrayRequired.push(this.form.get('telephone2'))
          controlArrayRequired.push(this.form.get('streetName'))
          controlArrayRequired.push(this.form.get('streetNumber'))
          controlArrayRequired.push(this.form.get('city'))
          controlArrayRequired.push(this.form.get('country'))
          controlArrayRequired.push(this.form.get('zip'))
          controlArrayRequired.push(this.form.get('responsible'))
          controlArrayRequired.push(this.form.get('origin'))
          controlArrayRequired.push(this.form.get('contactChannel'))
          break;

        case 'Società': case 'Ente': case 'Negozio': case 'Società Immobiliare': case 'Hotel': case 'Studio Tecnico': case 'Ospedale': case 'Struttura Religiosa': case 'Pubblica Amministrazione': case 'Scuola':
          controlArrayRequired.push(this.form.get('legalEntity'))
          // controlArrayEmail.push(this.form.get('email'))
          // controlArrayRequired.push(this.form.get('telephone2'))
          controlArrayRequired.push(this.form.get('streetName'))
          controlArrayRequired.push(this.form.get('streetNumber'))
          controlArrayRequired.push(this.form.get('city'))
          controlArrayRequired.push(this.form.get('country'))
          controlArrayRequired.push(this.form.get('zip'))
          controlArrayRequired.push(this.form.get('responsible'))
          controlArrayRequired.push(this.form.get('origin'))
          controlArrayRequired.push(this.form.get('contactChannel'))
          break;

        case 'Amministratore':
          controlArrayRequired.push(this.form.get('name'))
          controlArrayRequired.push(this.form.get('surname'))
          controlArrayRequired.push(this.form.get('legalEntity'))
          // controlArrayRequired.push(this.form.get('telephone2'))
          controlArrayRequired.push(this.form.get('streetName'))
          controlArrayRequired.push(this.form.get('streetNumber'))
          controlArrayRequired.push(this.form.get('city'))
          controlArrayRequired.push(this.form.get('country'))
          controlArrayRequired.push(this.form.get('zip'))
          controlArrayRequired.push(this.form.get('responsible'))
          controlArrayRequired.push(this.form.get('origin'))
          controlArrayRequired.push(this.form.get('contactChannel'))
          break;

        case 'Condominio':
          // controlArrayRequired.push(this.form.get('legalEntity'))
          controlArrayRequired.push(this.form.get('streetName'))
          controlArrayRequired.push(this.form.get('streetNumber'))
          controlArrayRequired.push(this.form.get('city'))
          controlArrayRequired.push(this.form.get('country'))
          controlArrayRequired.push(this.form.get('zip'))
          controlArrayRequired.push(this.form.get('origin'))
          break;

        case 'Soggetto':
          controlArrayRequired.push(this.form.get('type'));
      }

      controlArrayRequired.forEach(control => {
        control?.setValidators([Validators.required])
        control?.updateValueAndValidity()
      })

      controlArrayEmail.forEach(control => {
        control?.setValidators([Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")])
        control?.updateValueAndValidity()
      })
    }
  }

  // initAddressWatcher(){
  //   let streetName$ = this.form.get('streetName')?.valueChanges
  //   let streetNumber$ = this.form.get('streetNumber')?.valueChanges
  //   let zip$ = this.form.get('zip')?.valueChanges
  //   let city$ = this.form.get('city')?.valueChanges
  //   let state$ = this.form.get('state')?.valueChanges
  //   let country$ = this.form.get('country')?.valueChanges

  //   this.addressWatcherSub = combineLatest([streetName$, streetNumber$, zip$, city$, state$, country$]).subscribe(([streetName, streetNumber, zip, city,state,country]: any[]) => {
  //     if(!streetName || !streetNumber || !zip || !city || /* !state || */ !country ){
  //       this.addressMissing = true;
  //       console.log('[SubjectData]: address missing: ', this.addressMissing, streetName, streetNumber, zip,city,country);

  //     }else{
  //       this.addressMissing = false;
  //     }


  //   })
  // }

  get addressValid() {
    let streetName = this.form.get('streetName')?.valid
    let streetNumber = this.form.get('streetNumber')?.valid
    let zip = this.form.get('zip')?.valid
    let city = this.form.get('city')?.valid
    let state = this.form.get('state')?.valid
    let country = this.form.get('country')?.valid

    let latExists = this.form.get('coordX')?.value ? true : false;
    let longExists = this.form.get('coordY')?.value ? true : false;

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = 'red';
    } else if (!latExists || !longExists) {
      addressValid = 'yellow';
    } else {
      addressValid = 'green';
    }


    return addressValid
  }

  clearFormValidators() {
    let controls = Object.keys(this.form.controls).filter(control => control !== 'type')
    console.log('clearFormValidators: ', controls);

    controls.forEach(controlName => {
      //console.log("Validator previous for", controlName, ':',this.form.get(controlName)?.validator)
      this.form.get(controlName)?.clearValidators();
      this.form.get(controlName)?.updateValueAndValidity();
      //console.log("Validator for", controlName, ':',this.form.get(controlName)?.validator)
    });
  }

  checkTypeOther(type: string) {
    let typesVisualized = ['Edificio', 'Amministratore', 'Condominio']

    return !typesVisualized.includes(type)
  }

  public formControlIsValid(formGroup: any, formCtrl: any): boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }

  checkValidators() {
    let controls = Object.keys(this.form.controls)
    controls.forEach(controlName => {
      console.log(controlName, 'valid: ', this.form.controls[controlName].valid);
    });
  }

  backOfficeRespAssignment(companyCode: any) {
    console.log("USER LOGGATO SUBJECT DATA -->", this.loggedUser);
    let companyLocal = this.companyLocal;
    let respArea;
    this.roleService.getMainRole(this.loggedUser);
    if (this.form.get('id')!.value !== null && this.form.get('id')!.value !== undefined) {
      // SUBJECT ESISTENTE

    } else {
      //NUOVO SUBJECT
      switch (this.loggedUser[0].role) {
        case 'Responsabile Area':
          this.disabled = false;
          let companyRA = this.loggedUser[0].UsersCompaniesRel.find((company: any) => company.code === companyCode);
          // console.log("COMPANY RA --> ", companyRA);
          respArea = companyLocal?.CompaniesUsersRel?.find((user: User) => user.id === companyLocal.UsersCompaniesManagerRelId);
          companyRA !== undefined ? this.form.patchValue({ responsible: this.loggedUser[0].email }) : this.form.patchValue({ responsible: respArea?.email });
          break;
        case 'Tecnico Commerciale':
          this.disabled = false;
          let companyTC = this.loggedUser[0].UsersCompaniesRel.find((company: any) => company.code === companyCode);
          // console.log("COMPANY TC --> ", companyTC);
          respArea = companyLocal.CompaniesUsersRel.find((user: User) => user.id === companyLocal.UsersCompaniesManagerRelId);
          companyTC !== undefined ? this.form.patchValue({ responsible: this.loggedUser[0].email }) : this.form.patchValue({ responsible: respArea?.email });
          break;
        case 'BackOffice':
          this.disabled = true;
          // let companyLocal = this.companies.find(company => company.code === companyCode);
          let responsible = companyLocal.CompaniesUsersRel.find((user: User) => user.id === companyLocal?.UsersCompaniesManagerRelId);
          this.form.patchValue({ responsible: responsible?.email });
          break;
        default:
          break;
      }
    }

  }

  // getMainRole() {
  //   let role: any;

  //   let profiles = [ {name: 'BackOffice', value: 1}, {name: 'Tecnico Commerciale', value: 2}, {name: 'Responsabile Area', value: 3},{name: 'Area Manager', value: 3},{name: 'Admin', value: 3}];
  //   this.loggedUser[0].UsersProfilesRel.forEach((element: any) => {
  //     if (!role) {
  //       role = profiles.filter(function (e) { return e.name === element.name })[0];
  //     } else {
  //       profiles.filter(e => e.name === element.name)[0]?.value > role?.value?
  //         role = profiles.filter(e => e.name === element.name)[0]: undefined;
  //     }
  //   });
  //   this.loggedUser[0].role = role.name;
  // }

  disableFields() {
    this.roleService.getMainRole(this.loggedUser);
    switch (this.loggedUser[0].role) {
      case 'BackOffice':
        this.disabled = true;
        break;


      default:
        break;
    }
  }

  compareFn(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  selectClicked(e: any) {
    console.log("HERE WE ARE");
    let company = this.form.get('company')?.value;
    let operatorList: any;
    try {
      let acceptedCompanies: Array<any> = [];
      acceptedCompanies.push(company);
      let opList: Array<any> = [];

      if (acceptedCompanies.length > 0) {

        this.userService.getByCompanyCode(acceptedCompanies).subscribe(comps => {
          this.users = comps.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
          this.users.forEach((element: any) => {
            element.UsersCompaniesRel.forEach((element2: any) => {
              acceptedCompanies?.includes(element2.code) ? opList.push(element) : undefined;
            });
          });
          operatorList = [... new Set(opList.map(tag => tag))];
          this.users = operatorList.map((u: any) => u.email);
          console.log("OPERATOR LIST --> ", this.users);
        })
      }


    } catch {

    }
  }
}
