<div fxLayout="column">
  <form [formGroup]="form" fxLayout="row" fxLayoutAlign="center end" >
    <!-- <multiple-autocompleter [control]="companyControl" [items]="companies" label="company"></multiple-autocompleter>
    <multiple-autocompleter [control]="companyLocationControl" [items]="societies" label="company"></multiple-autocompleter>
    <multiple-autocompleter [control]="responsibleControl" [items]="responsibles" label="company"></multiple-autocompleter> -->

    <app-multi-select-dropdown
      formControlName="company"
      [items]="companies"
      firstSelectionViewField="code"
      [firstSelectionLabel]="'LABEL.Sede' | translate"
      fxFlex>
    </app-multi-select-dropdown>
    <!-- <app-multi-select-dropdown
      formControlName="companyLocation"
      [items]="societies"
      firstSelectionViewField="code"
      [firstSelectionLabel]="'LABEL.Societa' | translate"
      fxFlex>
    </app-multi-select-dropdown> -->
    <app-multi-select-dropdown
      formControlName="responsible"
      [items]="responsibles"
      firstSelectionViewField="email"
      [firstSelectionLabel]="'LABEL.Responsabile' | translate" [noUsers]="noUsers"
      fxFlex>
    </app-multi-select-dropdown>
  </form>
  <div fxLayout="row" fxLayoutAlign="end center" style="padding:10px 10px">
    <button type="button" class="btn btn-primary ps-7 text-dark" style="margin:10px 10px" (click)="filter()" fxLayoutAlign="center center">
            <mat-icon class="material-icons" style="color:#293683;">search</mat-icon>
            {{ 'SUBJECT.FILTER' | translate }}
          </button>
    <button type="button" class="btn btn-primary ps-7 text-dark" style="margin:10px 10px" (click)="clear()" fxLayoutAlign="center center">
        <!-- <mat-icon class="material-icons" style="color:#293683;">save</mat-icon> -->
        {{ 'SUBJECT.CLEAR' | translate }}
      </button>
  </div>
</div>
