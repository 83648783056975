import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subject-consents',
  templateUrl: './subject-consents.component.html',
  styleUrls: ['./subject-consents.component.scss']
})
export class SubjectConsentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
