import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TransactionsService } from 'src/app/services/transactions.service';
import { LibService } from 'src/app/services/libService';

@Component({
  selector: 'app-transaction-form',
  templateUrl: './transaction-form.component.html',
  styleUrls: ['./transaction-form.component.scss']
})
export class TransactionFormComponent implements OnInit {

  @Input() item: any;
  @Output() closeModal = new EventEmitter;

  form: FormGroup;

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private transactionsService: TransactionsService,
    private libService: LibService,
  ) {
  }

  ngOnInit(): void {

    // Creazione Form
    this.form = this.fb.group({
      id: [null],
      orderNumberEA: [null, Validators.required],
      damageYear: [null],
      complainant: [null],
      streetName: [null],
      streetNumber: [null],
      city: [null],
      province: [null],
      country: [null],
      zip: [null],
      refundDescription: [null],
      damageCauseDescription: [null],
      type: [null],
      typeDescription: [null],
      recognizedDamageValue: [null],
      paidFromEA: [null],
      deductiblePaid: [null],
      note: [null],
      expiringPaymentDate: [null],
      accountingDate: [null],
      agreementDate: [null],
    })

    this.form.patchValue(this.item)
  }

  ngOnChanges(changes: SimpleChanges): void { }

  update() {
    //console.log(this.form)
    this.libService.lockPage('');
    this.transactionsService.updateTransaction(this.item?.id?.toString(), this.form.getRawValue()).subscribe((res: any) => {
      //console.log(res);
      this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
      this.closeModal.emit(true);
      this.libService.unlockPage();
    })
  }

  fullname(){
    let complainant : any = this.item?.complainant
    return `${complainant?.name} ${complainant?.surname}`
  }

}