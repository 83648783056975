import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class GuardService {

  getKeyFrontEnd(user: any) {
    let keyFrontEnds: any = []
    let profiles = user.UsersProfilesRel

    for (let i = 0; i < profiles.length; i++) {
      let actions = profiles[i].ProfilesActionsRel
      if (actions != null) {
        for (let a = 0; a < actions.length; a++) {
          if (!this.isItAlreadyPresentInTheRoutes(keyFrontEnds, actions[a]?.id)) {
            keyFrontEnds.push(actions[a]?.keyFrontEnd)
          }
        }
      }

    }

    return keyFrontEnds
  }

  isItAlreadyPresentInTheRoutes(array: any, routeId: any) {
    let record = array.find((a: any) => a.id == routeId)
    return ![null, undefined].includes(record)
  }
}
;