<form [formGroup]="form" class="form-container">

  <!-- CONTACT REASON -->
  <mat-form-field appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.CONTACT_REASON' | translate }}</mat-label>
    <mat-select formControlName="contactReason" [compareWith]="compareFn"
      (selectionChange)="setContactReasonId($event)">
      <mat-option *ngFor="let cr of contactReasons$ | async" [value]="cr">
        {{ cr.uiLabel | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- STATE -->
  <mat-form-field *ngIf="!isNew && getContactReasonId() == 1" appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.STATE' | translate }}</mat-label>
    <mat-select matNativeControl name="stateId" formControlName="stateId">
      <mat-option *ngFor="let state of states" [value]="state.id">
        {{ 'STATECATEGORIES.'+ state?.stateCategory?.code | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- COUNTRY -->
  <mat-form-field *ngIf="getContactReasonId() == 1" appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.COUNTRY' | translate }}</mat-label>
    <mat-select matNativeControl name="countryCode" formControlName="countryCode"
      (selectionChange)="getBusinessLinesByCountryCode($event)">
      <mat-option *ngFor="let country of countries" [value]="country">
        {{ country }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- SOCIETY -->
  <!--
  <mat-form-field *ngIf="getContactReasonId() == 1" appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.SOCIETY' | translate }}</mat-label>
    <mat-select matNativeControl name="societyId" formControlName="societyId"
      (selectionChange)="getBusinessLines($event)">
      <mat-option *ngFor="let society of societies" [value]="society.id">
        {{ society.code }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  -->

  <!-- BUSINESS LINE -->
  <mat-form-field *ngIf="getContactReasonId() == 1" appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.BRANCHES' | translate }}</mat-label>
    <mat-select matNativeControl name="businessLineId" formControlName="businessLineId">
      <mat-option *ngFor="let businessLine of businessLines" [value]="businessLine.id">
        {{ 'ACTIVITIES.'+ businessLine.code | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

</form>