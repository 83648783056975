import { Component, OnInit, Input } from '@angular/core';
import { WikiService } from '../services/wikiservice.service';
import { NavService } from '../services/nav.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Location } from '@angular/common';
@Component({
  selector: 'app-claim-workflow',
  templateUrl: './claim-workflow.component.html',
  styleUrls: ['./claim-workflow.component.scss']
})
export class ClaimWorkflowComponent implements OnInit {

  actions: any;
  @Input() selectedTabIndex: number = 0;
  @Input() itemId: any = null;

  firstChange: any = true
  initSelectedTabIndex: any;

  constructor(
    public navService: NavService,
    public wikiService: WikiService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.initSelectedTabIndex = this.selectedTabIndex
  }

  getItemId(index: any, currentIndex: any) {
    let value = null;
    if (index == currentIndex) value = this.itemId
    return value
  }


  goToWiki() {
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }

  goToWikiByKey(k: any) {
    window.open(this.wikiService.getWikiUrl(k));
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    /* console.log('tabChangeEvent --> ', tabChangeEvent); */
    /* console.log('index --> ', tabChangeEvent.index); */

    if (this.firstChange) {
      this.initSelectedTabIndex = this.selectedTabIndex
      this.firstChange = false
    }

    this.selectedTabIndex = tabChangeEvent.index
    if (tabChangeEvent.index !== (null || undefined)) {
      if (tabChangeEvent.index === 0) {
        this.actions = [
        ];
         this.location.replaceState("crm;tabName=rapports", "subTabName=complaints&subSubTabName=pecs");
      }
      if (tabChangeEvent.index === 1) {
        this.actions = [
          { name: 'new', url: 'request/0', queryParams: { type: 3 } }
        ];
          this.location.replaceState("crm;tabName=rapports", "subTabName=complaints&subSubTabName=warranty");
      }
      if (tabChangeEvent.index === 2) {
        this.actions = [
          { name: 'new', url: 'request/0', queryParams: { type: 3 } }
        ];
          this.location.replaceState("crm;tabName=rapports", "subTabName=complaints&subSubTabName=claims");
      }
      if (tabChangeEvent.index === 3) {
        this.actions = [
          { name: 'new', url: 'request/0', queryParams: { type: 3 } }
        ];
          this.location.replaceState("crm;tabName=rapports", "subTabName=complaints&subSubTabName=legals");
      }
      if (tabChangeEvent.index === 4) {
        this.actions = [
          { name: 'new', url: 'request/0', queryParams: { type: 3 } }
        ];
         this.location.replaceState("crm;tabName=rapports", "subTabName=complaints&subSubTabName=transactions");
      }

    }



  }
}
