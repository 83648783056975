import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class GrievanceService extends BaseService {

  constructor(
    public LibService: LibService,
    public AuthService: AuthService,
    public userSessionService: UserSessionService,
    public guardService: GuardService
  ) {
    super(LibService, AuthService)
    this.baseApi = environment.rootApiCrm + 'v1.0/';
    this.model = 'grievances'
  }

  allWithPagination(filterOptions: any = [], orderOptions: any = [], skip: number = 0, take: number = 10) {
    console.log("allWithPagination")
    let tokenId = this.authService.idToken()
    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption
    }
    return this.libService.postDataWithAccessToken<any>(this.tokenId, options, this.baseApi, `${this.model}/withPagination`);
  }



  

}










