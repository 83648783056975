<div class="container-fluid my-container" >
  <div class="buttons my-2">

    <h3 style="margin-bottom:15px;">{{ 'LABEL.SURVEYS' | translate }}</h3>
  <div class="buttons-right">
    <mat-icon class="check-ok" *ngIf="formSurveyMaster.valid">check</mat-icon>
    <button style="background-color:transparent;" [disabled]="!formSurveyMaster.valid" matTooltip="Salva" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="update()">
        <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
    </button>
  </div>
</div>
  <form [formGroup]="formSurveyMaster" class="form-container">

      <mat-form-field>
        <mat-label>{{ 'LABEL.NAME' | translate }}</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'LABEL.ENTITY_NAME' | translate }}</mat-label>
        <mat-select formControlName="entityName">
          <mat-option value="Edificio">{{ 'LABEL.BUILDING' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

        <div *ngFor="let questionForm of questionControls; index as idx; last as isLast">
          <form [formGroup]="questionForm" class="form-container">
            <mat-form-field class="form-subject">
              <mat-label>{{ 'LABEL.TEXT' | translate }}</mat-label>
              <input matInput formControlName="text">
            </mat-form-field>
            <mat-checkbox formControlName="mandatory" class="form-subject">{{ 'LABEL.MANDATORY' | translate }}</mat-checkbox>
          </form>
        </div>


  </form>
  <button type="button" (click)="addNewQuestion()">{{ 'LABEL.ADD' | translate }}</button>

</div>
