import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { EnergyFormValue } from '../planner/providers/planner-form-energy.service';
import { User } from '../models/user';
import { Event } from '../planner/models/event';

@Injectable({
  providedIn: 'root',
})
export class PlanningService {
  baseApi = environment.rootApiPaperwork;

  constructor(
    public libService: LibService,
    private authService: AuthService
  ) { }

  createScheduleFirst(orderId: any, params: any): Observable<any[]> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any[]>(
      tokenId,
      params,
      this.baseApi,
      `plannings/createScheduleFirst/${orderId}`
    );
  }

  createScheduleFirstMulti(orderId: any, params: any): Observable<any[]> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any[]>(
      tokenId,
      params,
      this.baseApi,
      `plannings/createScheduleFirstMulti/${orderId}`
    );
  }
  createScheduleFirstEnergy(orderId: number, params: Partial<EnergyFormValue> | Partial<User>): Observable<any> {
    const tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      params,
      this.baseApi,
      `plannings/createScheduleFirstEnergy/${orderId}`
    );
  }

  updateSchedule(code: any, params: any): Observable<any[]> {
    return this.libService.putData<any[]>(
      params,
      this.baseApi,
      `plannings/${code}`
    );
  }

  resizePlanning(code: any, params: any): Observable<any[]> {
    return this.libService.putData<any[]>(
      params,
      this.baseApi,
      `plannings/resize/${code}`
    );
  }

  copyPlanning(code: any, params: any): Observable<any[]> {
    return this.libService.postData<any[]>(
      params,
      this.baseApi,
      `plannings/copy/${code}`
    );
  }

  changeAssistants(code: any, params: any): Observable<any[]> {
    return this.libService.postData<any[]>(
      params,
      this.baseApi,
      `plannings/changeAssistants/${code}`
    );
  }

  deletePlanning(code: any, params: any): Observable<any[]> {
    return this.libService.deleteData<any[]>(
      params,
      this.baseApi,
      `plannings/${code}`
    );
  }

  getFinalBalancers(usr: any, orderIds: any): Observable<any[]> {
    return this.libService.postData<any[]>(
      { user: usr, orderIds: orderIds },
      this.baseApi,
      'plannings/getFinalBalancers'
    );
  }

  getOrdersWithTasks(usr: any, id: any, idSede: any): Observable<any> {
    return this.libService.postData<any>(
      { user: usr, id: id, idSede: idSede },
      this.baseApi,
      'plannings/getOrdersWithTasks'
    );
  }

  resetPlanning(code: any): Observable<any[]> {
    return this.libService.deleteData<any[]>(
      [],
      this.baseApi,
      `plannings/${code}/reset`
    );
  }

  resetPlanningMulti(code: any): Observable<any[]> {
    return this.libService.deleteData<any[]>(
      [],
      this.baseApi,
      `plannings/multi/${code}/reset`
    );
  }

  updateCalendar(taskId: any, changes: any): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.putDataWithAccessToken<any>(
      tokenId,
      changes,
      this.baseApi,
      `plannings/calendar/${taskId}`
    );
  }

  addResources(tasks: any): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      tasks,
      this.baseApi,
      `plannings/calendar/resources`
    );
  }
  removeResources(params: any): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      params,
      this.baseApi,
      `plannings/calendar/delete-resources`
    );
  }
  deleteTask(taskCode: string): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.deleteDataWithAccessToken<any>(
      tokenId,
      {},
      this.baseApi,
      `plannings/calendar/task/${taskCode}`
    );
  }
  
  getEventsEnergy(): Observable<Event[]> {
    let tokenId = this.authService.idToken();
    return this.libService.getDataWithAccessToken(
      tokenId,
      this.baseApi,
      'plannings/getEventsEnergy',
    );
  }
  getEventsEnergyWithPagination(startDate: any, endDate: any): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      {
        startDate: startDate,
        endDate: endDate
      },
      this.baseApi,
      `plannings/getEventsEnergyWithPagination`
    );
  }
  
  addEventEnergy(event: Event): Observable<Event> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken(
      tokenId,
      event,
      this.baseApi,
      'events',
    );
  }

  editEventEnergy(id: any, attributes: any = {}): Observable<Event> {
    let tokenId = this.authService.idToken();
    return this.libService.putDataWithAccessToken(
      tokenId,
      attributes,
      this.baseApi,
      `events/${id}`,
    );
  }

  canceledEvent(id: any,): Observable<Event> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken(
      tokenId,
      {},
      this.baseApi,
      `events/${id}/canceled`,
    );
  }

  getOrdersAndTasksWithPagination(idSede: number, startDate: any, endDate: any): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      {
        idSede: idSede,
        startDate: startDate,
        endDate: endDate
      },
      this.baseApi,
      `plannings/getOrdersAndTasksWithPagination`
    );
  }

  getOrdersAndTasksEnergy(): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      {},
      this.baseApi,
      `plannings/getOrdersAndTasksEnergy`
    );
  }

  getOrdersAndTasksEnergyWithPagination(startDate: any, endDate: any, withCoreTasks: any = true): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      {
        startDate: startDate,
        endDate: endDate,
        withCoreTasks: withCoreTasks
      },
      this.baseApi,
      `plannings/getOrdersAndTasksEnergyWithPagination`
    );
  }

  energyPracticeCheckAndSecondDownPayment(orderId: any): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.getDataWithAccessToken<any>(
      tokenId,
      this.baseApi,
      `plannings/energyPracticeCheckAndSecondDownPayment/${orderId}`
    );
  }

  resourceReplacement(attributes: any = {}): Observable<any> {
    let tokenId = this.authService.idToken();
    return this.libService.postDataWithAccessToken<any>(
      tokenId,
      attributes,
      this.baseApi,
      `plannings/resourceReplacement`
    );
  }
}
