import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatorService } from '../../../services/translate.service';

@Component({
  selector: 'app-change-sales-manager-modal',
  templateUrl: './change-sales-manager-modal.component.html',
  styleUrls: ['./change-sales-manager-modal.component.scss']
})
export class ChangeSalesManagerModal implements OnInit {

  confirmButtonLabel: any = 'LABEL.SAVE'
  cancelButtonLabel: any = 'LABEL.CANCEL'
  form: FormGroup
  respComm: any = []
  localData: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private translate: TranslatorService,
  ) {
    let formStructure: any = {}

    if (data) {
      this.localData = data

      if (data.respComm) {
        formStructure['responsabileCommerciale'] = [null, Validators.required]
        this.respComm = data.respComm
      }

      this.form = this.fb.group(formStructure)
    }

  }

  getLabel(type: any) {

    switch (type) {
      case 'titleLabel':
        return this.translate.instant('LABEL.modificaResponsabileCommerciale');
      case 'textLabel':
        return '';
      default:
        break;
    }
    return '';
  }

  ngOnInit(): void {
  }

  disabledConfirmButton() {
    let disabled = false
    if (!this.form.valid) disabled = true
    return disabled
  }

  getLabelResp() {
    return this.translate.instant('LABEL.ResponsabileCommerciale');
  }

}
