<div [formGroup]="component">
    <mat-accordion *ngIf="showCategory(component.get('id')?.value)">
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="expanded">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{component.get('label').value}}
                </mat-panel-title>
                <mat-panel-description>
                    <div *ngIf="showAttributesCount">
                        Attributi: {{component.get('attributes').value.length}}
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- Attributi Componente -->
            <mat-tab-group animationDuration="0ms">
                <ng-container formArrayName="attributes">
                    <mat-tab [label]="attribute.get('label')?.value"
                        *ngFor="let attribute of component.get('attributes').controls; let c = index">
                        <app-component-attribute-card-box [form]="form" [attribute]="attribute" [index]="c"
                            [selectFields]="selectFields"></app-component-attribute-card-box>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>

        </mat-expansion-panel>
    </mat-accordion>
</div>