import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class LegalService {
  baseApi = environment.rootApiCrm + 'v1.0';

  constructor(
    public libService: LibService,
    public authService: AuthService,
  ) { }

  getLegal(id: string) {
    return this.libService.getData<any>(this.baseApi, `/legal/${id.toString()}`);
  }

  getAllLegals(): Observable<any> {
    console.log("SERVICE FRONT: "+this.baseApi);
    return this.libService.getData<any>(this.baseApi, `/legals`);
  }

  updateLegal(id: any, legal: any) {
    return this.libService.postData<any>(legal, this.baseApi, `/legal/${id.toString()}`)
  }

  deleteLegal(id: any, legal: any) {
    return this.libService.deleteData<any>(legal, this.baseApi, `/legal/${id.toString()}`);
  }

  createLegal(legal: any) {
    return this.libService.postData<any>(legal, `${this.baseApi}`,'/legal');
  }

  getAllLegalsYesNo() {
    return this.libService.getData<any>(this.baseApi, `/legalsYesNo`);
  }

  allWithPagination(filterOptions: any = [], orderOptions: any = [], skip: number = 0, take: number = 10) {
    console.log("allWithPagination")
    let tokenId = this.authService.idToken()
    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption
    }
    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `/legals/allWithPagination`)
  }
}
