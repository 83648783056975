import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingFilterComponent } from './building-filter/building-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../shared/shared.module';
import { BuildingComponent } from './building.component';
import { FullTextSearchModule } from '../fulltextsearch2/fulltextsearch.module';
import { MatRadioModule } from '@angular/material/radio';
import { BuildingDetailComponent } from './building-detail/building-detail.component';
import { TypeRadioComponent } from './building-detail/type-radio/type-radio.component';
import { BuildingPracticesComponent } from './building-detail/building-practices/building-practices.component';
import { BuildingSurveyComponent } from './building-detail/building-survey/building-survey.component';
import { BuildingRelationsComponent } from './building-detail/building-relations/building-relations.component';
import { BuildingMarketingComponent } from './building-detail/building-marketing/building-marketing.component';
import { BuildingLocalizationComponent } from './building-detail/building-localization/building-localization.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { BuildingDocumentsComponent } from './building-detail/building-documents/building-documents.component';
import { BuildingDataComponent } from './building-detail/building-data/building-data.component';
import { BuildingConsentsComponent } from './building-detail/building-consents/building-consents.component';
import { ActivitiesModule } from '../activities/activities.module';
import { SelectSearchBuildingComponent } from './select-search-building/select-search-building.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const MATERIAL_MODULES = [
  MatIconModule,
  MatFormFieldModule,
  MatRadioModule,
  MatTableModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatTabsModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatInputModule,
  MatSelectModule
]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    BuildingFilterComponent,
    BuildingComponent,
    BuildingDetailComponent,
    TypeRadioComponent,
    BuildingPracticesComponent,
    BuildingSurveyComponent,
    BuildingRelationsComponent,
    BuildingMarketingComponent,
    BuildingLocalizationComponent,
    BuildingDocumentsComponent,
    BuildingDataComponent,
    BuildingConsentsComponent,
    SelectSearchBuildingComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    GooglePlaceModule,
    GoogleMapsModule,
    FullTextSearchModule,
    HttpClientModule,
    SharedModule,
    NgxMatSelectSearchModule,
    ActivitiesModule,
    FullTextSearchModule,
    TranslateModule.forChild(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        },
      }
    ),
    MATERIAL_MODULES
  ],
  exports: [
    BuildingComponent,
  ]
})
export class BuildingModule { }
