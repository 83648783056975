import { Component, NgZone, EventEmitter, Input, OnChanges, AfterViewInit, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddEvent, CancelEvent, CellClickEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { combineLatest, switchMap, take, map } from 'rxjs';
import { Complaint } from 'src/app/models/complaint';
import { ClaimStatusService } from 'src/app/services/claim-status.service';
import { ComplaintTypesService } from 'src/app/services/complaint-types.service';
import { ComplaintService } from 'src/app/services/complaint.service';
import { LibService } from 'src/app/services/libService';
import { OrderService } from 'src/app/services/order.service';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { OrderDialogComponent } from '../order-dialog/order-dialog.component';
import { process } from '@progress/kendo-data-query';
import { Observable, Subject, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DatatableAction } from 'src/app/shared/data-table/data-table.component';
import { BehaviorSubject, of } from 'rxjs';

import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { DocumentsGridComponent } from 'src/app/shared/documents-grid/documents-grid.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component'
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { ModalFormComponent } from 'src/app//forms/modal-form/modal-form.component';

export class DatatableColumn<T> {
  name: string;
  title: Observable<string>;
  type?: 'text' | 'link' | 'icon' | 'templateRef' = 'text';
  flex?: string;
  sticky?: boolean;
  color?: (element: T) => 'primary' | 'warn' | 'error';
  cssClass?: (element: T) => string[];
  sortDisabled?: boolean;
  routerLink?: (element: T) => string[];
  value(element: T): any {
    return null;
  }
  templateRef?: (element: T) => Observable<TemplateRef<any>>;
  tooltip?: (element: T) => Observable<String>;

  constructor(column: Partial<DatatableColumn<T>>) {
    Object.assign(this, column);
  }
}

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss']
})
export class ComplaintsComponent implements OnInit {

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild(GridComponent)

  public grid: GridComponent;

  @Input() itemId: any = null;

  search: any;

  formComplaints: UntypedFormGroup;
  formAll: UntypedFormGroup;
  formDates: UntypedFormGroup;
  complaints: Complaint[];
  allComplaints: Complaint[];
  complaint: Complaint;
  complaintCommit$: any;
  complaintTypes: any;
  whileEditing: boolean = false;
  statuses: any;
  upStatus: boolean;
  disableUpload: boolean = false;

  documentTypes: any = []

  complaintTypes$ = this.complaintTypesService.getComplaintTypesByCategory('complaints');
  complaints$ = this.complaintService.allWithPagination();
  claimStatus$ = this.statusService.getAll('Claims');

  private timeout?: number;


  dataSource: any;

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;

  filterOptions: any = {};
  currentPage = 0;
  companyIdsFilter: any = []
  responsiblesFilter: any = []
  idsFilter: any = []
  orderOptions: any = {}
  allGlobalDocumentTypes: any = []

  test: any = []

  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

  actions: DatatableAction<any>[] = [
    {
      label: of(''),
      icon: complaint => {
        return 'edit'
      },
      onClick: complaint => this.openEditDialog(complaint),
      color: 'primary'
    },
    {
      label: of(''),
      icon: complaint => {
        return 'upload_file'
      },
      onClick: complaint => this.openUploadDialog('new', complaint),
      color: 'primary'
    },
    {
      label: of(''),
      icon: complaint => {
        return 'folder'
      },
      onClick: complaint => this.viewDocuments(complaint?.documents),
      color: 'primary'
    },
    /*
    {
      label: of(''),
      icon: subject => {
        return 'delete'
      }, // 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    }
    */
  ];

  public openEditDialog(item: any) {

      this.dialog.open(ModalFormComponent, {
        data: {
          type: 'complaints',
          item: item,
        },
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      })
        .afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ', res);
          if (res) {
            this.loadDataSource()
          }
        })
  }

  public openUploadDialog(mode: 'new' | 'current', complaint: any) {

    if (mode === 'new') {

      let docTypes = complaint.status?.allowedDocumentTypes.filter((a: any) => a.code !== 'SINISTRO' && a.code !== 'TRANSITIVO');

      this.documentTypes = [...this.allGlobalDocumentTypes, ...docTypes]

      this.dialog.open(FileUploadModalComponent, {
        data: {
          inputMode: 'complaints',
          toEntityId: complaint.id,
          practiceId: complaint.practiceId,
          documentTypes: of(this.documentTypes)
        },
        scrollStrategy: new NoopScrollStrategy(),
      })
        .afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ', res);
          if (res) {
            this.loadDataSource()
          }
        })
    }

  }

  public viewDocuments(documents: any) {
    console.log("DOCUMENTI --> ", documents)
    if (documents.length > 0) {
      let docs = this.dialog.open(DocumentsGridComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });
      let instance = docs.componentInstance;
      instance.documents = documents;
    } else {
      this.dialog.open(SimpleDialogComponent, {
        height: '70%',
        width: '70%',
        scrollStrategy: new NoopScrollStrategy(),
      });

    }
    // console.log("DOCUMENTS INSIDE -->", );
  }

  displayedColumns: string[] = [
    'id',
    'orderNumberEA',
    'company',
    'complaintType',
    'status',
    'creationDate',
    'closingDate',
    'managerArea',
    'delay',
    'motherOrder'
  ];

  columns: DatatableColumn<any>[] = [
    {
      name: "id",
      flex: '7',
      title: this.translate.get('LABEL.ID'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints.id
    },
    {
      name: "orderNumberEA",
      title: this.translate.get('COMPLAINTS.Pratica EDAC'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints.orderNumberEA as string,
    },
    {
      name: "company",
      title: this.translate.get('COMPLAINTS.Company'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints.company as string,
    },
    {
      name: "complaintType",
      title: this.translate.get('COMPLAINTS.TYPE'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints?.complaintType?.uiLabel ? this.translate.instant(complaints?.complaintType?.uiLabel) : complaints?.complaintType?.uiLabel,
    },
    {
      name: "status",
      title: this.translate.get('COMPLAINTS.STATUS'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints?.status?.uiLabel ? this.translate.instant(complaints?.status?.uiLabel) : complaints?.status?.uiLabel
    },
    {
      name: "creationDate",
      title: this.translate.get('COMPLAINTS.Data creazione'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints.creationDate as string,
    },
    {
      name: "closingDate",
      title: this.translate.get('COMPLAINTS.Data chiusura'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints.closingDate as string,
    },
    {
      name: "managerArea",
      title: this.translate.get('COMPLAINTS.Area Manager'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints.managerArea as string,
    },
    {
      name: "delay",
      title: this.translate.get('COMPLAINTS.Ritardo'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints.delay as string,
    },
    {
      name: "motherOrder",
      title: this.translate.get('COMPLAINTS.Ordine madre'),
      cssClass: complaints => ['column-padding'],
      value: complaints => complaints.motherOrder as string,
    },
  ]

  constructor(private libService: LibService,
    public complaintService: ComplaintService,
    public subjectService: SubjectService,
    private fb: UntypedFormBuilder,
    private complaintTypesService: ComplaintTypesService,
    private ngZone: NgZone,
    private router: Router,
    public translate: TranslateService,
    private statusService: StatusService,
    private dialog: MatDialog,
    private orderService: OrderService,
    private datePipe: DatePipe,
    private documentTypeService: DocumentTypeService,
  ) {

    this.formDates = this.fb.group({
      creationDate: [null],
      closingDate: [null],
      deliberateDate: [null],
      firstInsertmentDate: [null],
      lastInsertmentDate: [null]
    });

    this.formComplaints = this.fb.group({
      id: [null],
      // version: [null, Validators.required],
      company: [null],
      orderNumberEA: [null, Validators.required],
      orderVersion: [null],
      orderId: [null],
      practiceId: [null],
      complaintType: [null],
      managerArea: [null],
      delay: [null],
      deliberateValue: [null],
      totalProducedValue: [null],
      totalNetTurnover: [null],
      totalGrossTurnover: [null],
      totalGrossReceived: [null],
      totalHours: [null],
      hoursValue: [null],
      materialsValue: [null],
      NCNumber: [null],
      NCValue: [null],
      status: [null],
      totalGrossExpired: [null],
      averageDays: [null],
      motherOrder: [null, Validators.required],
      documents: null
    });

    this.formAll = this.fb.group({
      ...this.formComplaints.controls,
      ...this.formDates.controls
    });

  }

  loadDataSource(reset: boolean = false) {
    console.log('loadDataSource')
    this.libService.lockPage('');

    /*
      if (reset) {
        this.resetOrder()
        this.resetPagination()
        this.filterOptions.ids = []
      }
        */

      if(this.itemId){
        this.filterOptions.ids = [this.itemId]
      }

    this.complaintService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize).subscribe((responseData: any) => {
      this.complaints = responseData.items;
      this.allComplaints = responseData.items;
      this.totalRows = responseData.count;
      this.libService.resetLockPage();
    }, (err: any) => {
      // this.lockrequest = false;
      this.libService.resetLockPage();
      //this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });

  }

  goTo(e: any) {
    this.router.navigate(['request/' + e.id]);
  }

  iconRoutine(request: any) {
    //INIZIO ROUTINE PER VISIBILITY
    let profile: any;
    return 'edit'
    /*
        this.roleService.getMainRole(this.loggedUser);
    
        switch (this.loggedUser[0].role) {
          case 'Responsabile Area': case 'Affiliato':
            return this.acceptedCompanies.includes(request.building?.company) || request.assignmentOperator === this.loggedUser[0].email ? 'edit' : 'visibility';
            break;
    
          case 'Tecnico Commerciale':
            // !(this.acceptedCompanies.includes(request.building?.company) &&
            return !(request.assignmentOperator === this.loggedUser[0].email) ? 'visibility' : 'edit'
            break;
    
          case 'BackOffice':
            return 'visibility';
            break;
          case 'Responsabile Commerciale': case 'Coordinatore Country': case 'Direzione': case 'Top Management': case 'Contabilita': case 'Amministrazione': case 'HR': case 'Responsabile Mkt':
            return this.acceptedCompanies.includes(request.building?.company) || request.assignmentOperator === this.loggedUser[0].email ? 'edit' : 'visibility';
    
            break;
          case 'Coordinatore':
            this.dataSource = [];
            return 'visibility';
            break;
          default:
            return 'edit'
            break;
        }
            */
  }

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }

  openEdit(complaintTypeSection: any){
      let queryParams: any = {
        type: 3,
        complaintTypeSection: complaintTypeSection
      }
      this.router.navigate(['request/0'], { queryParams: queryParams });
  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }

  ngOnInit(): void {

    if(this.itemId){
      this.filterOptions.ids = [this.itemId]
    }

    this.complaints$ = this.complaintService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize);

    combineLatest([this.complaints$, this.complaintTypes$, this.claimStatus$, this.globalDocumentTypes$]).subscribe({
      next: ([complaints, types, status, globalDocumentTypes]) => {
        this.complaints = complaints.items;
        this.allComplaints = complaints.items;
        this.totalRows = complaints.count;
        console.log("COMPLAINTS --> ", this.complaints);
        this.complaintTypes = types;
        this.statuses = status;
        this.allGlobalDocumentTypes = globalDocumentTypes;
        this.complaints.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return b.updatedAt.localeCompare(a.updatedAt);
        });
        this.allComplaints.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return b.updatedAt.localeCompare(a.updatedAt);
        });
        console.log("STATUSESSSSSS --> ", this.statuses);
      },
      error: (error) => {
        this.libService.showMessageError(error.message);
      }
    })
  }


  addHandler(e: AddEvent) {
    this.whileEditing = true;
    console.log("EVENTO AGGIUNGI --> ", e);
    this.formComplaints.reset;
    this.formDates.reset;
    this.formAll.reset();
    console.log("FORM ALL --> ", this.formAll);
    e.sender.addRow(this.formAll);
  }

  removeHandler(e: RemoveEvent) {
    let calls = this.complaintService.deleteComplaint(e.dataItem.id, e.dataItem).pipe(switchMap((val: any) => {
      return this.complaints$
    }));
    this.libService.lockPage('');
    calls.subscribe((val: any) => {
      this.complaints = val.items;
      this.totalRows = val.count;
      this.libService.unlockPage();
    })
  }

  saveHandler(e: SaveEvent) {
    this.whileEditing = false;
    this.complaint = this.formAll.getRawValue();

    // this.complaint.creationDate = this.formDates.get('startDate')?.value;
    // this.complaint.closingDate = this.formDates.get('endDate')?.value;
    // this.complaint.deliberateDate = this.formDates.get('paymentDateFromEA')?.value;
    const statusCode = this.complaintTypes.find((arr: any) => { return arr.complaintType === this.complaint.complaintType });
    this.complaint.complaintType = statusCode;
    console.log("EVENTO DEL SALVATAGGIO --> ", e);
    console.log("COMPLAINT DA SALVARE A DB --> ", this.complaint);
    this.upStatus === false ?
      delete this.complaint['status'] : undefined;

    delete this.complaint['closingDate'];
    delete this.complaint['delay'];

    e.rowIndex == -1 ?
      this.complaintCommit$ = this.complaintService.createComplaint(this.complaint) :
      this.complaintCommit$ = this.complaintService.updateComplaint(this.complaint.id?.toString(), this.complaint);

    this.libService.lockPage(' ');
    let calls = this.complaintCommit$.pipe(switchMap((val: any) => {
      e.sender.closeRow(e.rowIndex);
      return this.complaints$
    }));

    calls.subscribe((val: any) => {
      this.complaints = val;
      this.complaints.sort(function (a: any, b: any) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return b.updatedAt.localeCompare(a.updatedAt);
      });
      this.libService.unlockPage();
    })

    this.formComplaints.reset;
    this.formDates.reset;
  }

  cancelHandler(e: CancelEvent) {
    this.whileEditing = false;
    e.sender.closeRow(e.rowIndex);
  }

  editHandler(e: EditEvent) {
    this.orderService.getOrderByCodeVersionV2(e.dataItem.orderNumberEA, e.dataItem.orderVersion).subscribe(ord => {
      console.log("[Order by code and version] -> ", ord);
      this.formComplaints.patchValue({ orderId: ord.data[0].id })
      this.formComplaints.patchValue({ practiceId: ord.data[0].PracticeId })
    })
    this.whileEditing = true;
    console.log("CONTROLLARE LA DATA FINE --> ", e.dataItem)
    for (const property in e.dataItem) {
      (property.toLowerCase().includes("date") && e.dataItem[property] !== null) ?
        e.dataItem[property] = new Date(e.dataItem[property]) : undefined;
    }
    this.formAll.patchValue(e.dataItem);

    console.log("STATUS ---> ", e.dataItem.status)
    if (e.dataItem.status.id === 5 || e.dataItem.status.id === 4 || e.dataItem.status.id === 6) {
      this.disableUpload = true;
    } else {
      this.disableUpload = false;
    }
    // this.formComplaints.patchValue({
    //   complaintType: e.dataItem.complaintType?.complaintType,

    // });
    this.formAll.patchValue({
      formComplaints: {
        status: e.dataItem.status
      }
    })
    console.log("FORM DI TUTTO --> ", this.formAll);
    e.sender.editRow(e.rowIndex, this.formAll);

  }

  setSubject(e: any) {

  }

  setFormValue(e: any) {

  }

  compareFn(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  updateStatus(e: any) {
    this.upStatus = e;
  }

  orderDetailPopup(e: CellClickEvent) {
    // console.log("EVENTO DEL CLICK -->", e)

    if ((e.isEdited === true && (e.columnIndex === 3)) ||
      (e.isEdited === false && (e.columnIndex === 2))) {

      if (e.dataItem.orderNumberEA !== null && e.dataItem.orderNumberEA !== undefined) {
        const dialogRef = this.dialog.open(OrderDialogComponent,
          {
            data: { complaint: e.dataItem },
            scrollStrategy: new NoopScrollStrategy(),
            height: '80%',
            width: '50%',
          });

        dialogRef.afterClosed().subscribe(result => {
          console.log('Risultato dialog --> ', result);

        });
      }
    }

    if ((e.isEdited === true && (e.columnIndex === 11)) ||
      (e.isEdited === false && (e.columnIndex === 10))) {
      this.router.navigate(['practices/' + e.dataItem.orderNumberEA])
    }

  }

  openDialog(): void {

  }

  onFilter(): void {
    window.clearTimeout(this.timeout);

    if (!this.search || this.search.length == 0) {
      this.complaints = this.allComplaints
      this.filterOptions.search = null

    } else {
      this.filterOptions.search = this.search
    }

    if (this.search.length < 2 && this.search.length != 0) return

    this.timeout = window.setTimeout(() => this.loadDataSource(), 500);
  }

}
