<mat-tab-group mat-align-tabs="center" (selectedTabChange)="changeTab($event)">
  <mat-tab label="PEC" *ngIf="showForm()">
    <br>

    <div class="full-w">

      <form [formGroup]="form" (submit)="save()" class="form-container">

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label>{{ 'REVIEWS.origin' | translate }}</mat-label>
          <mat-select formControlName="pecTypeValue">
            <mat-option *ngFor="let pecType of pecTypes" [value]="pecType.id" [disabled]="pecType.disabled">
              {{ pecType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br>

        <div appearance="fill" class="form-subject">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title> {{ 'LABEL.MITTENTEPEC' | translate }} </mat-card-title>

            </mat-card-header>
            <mat-card-content>


              <mat-form-field appearance="fill" class="form-subject">
                <mat-label>{{ 'REVIEWS.insertSubjectType' | translate }}</mat-label>
                <mat-select formControlName="insertSubjectType" (selectionChange)="insertSubjectTypeChange($event)">
                  <mat-option *ngFor="let insertSubjectType of insertSubjectTypes" [value]="insertSubjectType">
                    {{ 'INSERTSUBJECTTYPE.' + insertSubjectType | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div *ngIf="getInsertSubjectType() == 'search'">

                <app-fulltextsearch2 class="form-subject" [service]="subjectService" searchType="Soggetto"
                  (selected)="selSubject($event)" [edit]="false"></app-fulltextsearch2>

                <div *ngIf="theSubjectIsPresent()">

                  <mat-form-field appearance="fill" class="form-subject">
                    <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
                    <input matInput readonly [value]="getSubject()?.name"
                      [placeholder]="translate.instant('REQUEST.NAME')">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="form-subject">
                    <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
                    <input matInput readonly [value]="getSubject()?.surname"
                      [placeholder]="translate.instant('REQUEST.SURNAME')">
                  </mat-form-field>

                  <mat-form-field *ngIf="item.subject?.legalEntity" appearance="fill" class="form-subject">
                    <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
                    <input matInput readonly [value]="getSubject()?.legalEntity"
                      [placeholder]="translate.instant('REQUEST.LEGAL_ENTITY')">
                  </mat-form-field>

                  <mat-form-field *ngIf="item.subject?.email" appearance="fill" class="form-subject">
                    <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
                    <input matInput readonly [value]="getSubject()?.email"
                      [placeholder]="translate.instant('REQUEST.EMAIL')">
                  </mat-form-field>

                  <mat-form-field *ngIf="item.subject?.telephone2" appearance="fill" class="form-subject">
                    <mat-label>{{ 'LABEL.PHONE' | translate }}</mat-label>
                    <input matInput readonly [value]="getSubject()?.telephone2"
                      [placeholder]="translate.instant('LABEL.PHONE')">
                  </mat-form-field>

                  <mat-form-field appearance="fill" class="form-subject">
                    <mat-label>{{ 'REVIEWS.practice' | translate }}</mat-label>
                    <mat-select formControlName="practiceId">
                      <mat-option *ngFor="let practice of practices" [value]="practice.PracticeId">
                        {{ practice.code }} - {{ practice.streetName }} {{ practice.streetNumber }} {{ practice.city }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                </div>

              </div>

              <div formGroupName="subject" *ngIf="getInsertSubjectType() == 'new'" class="form-container">

                <mat-form-field appearance="fill" class="form-subject">
                  <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
                  <input matInput formControlName="name" [placeholder]="translate.instant('REQUEST.NAME')">
                </mat-form-field>

                <mat-form-field appearance="fill" class="form-subject">
                  <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
                  <input matInput formControlName="surname" [placeholder]="translate.instant('REQUEST.SURNAME')">
                </mat-form-field>

                <mat-form-field appearance="fill" class="form-subject">
                  <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
                  <input matInput formControlName="legalEntity"
                    [placeholder]="translate.instant('REQUEST.LEGAL_ENTITY')">
                </mat-form-field>

                <mat-form-field appearance="fill" class="form-subject">
                  <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
                  <input matInput formControlName="email" [placeholder]="translate.instant('REQUEST.EMAIL')">
                </mat-form-field>

                <mat-form-field appearance="fill" class="form-subject">
                  <mat-label>{{ 'LABEL.PHONE' | translate }}</mat-label>
                  <input matInput formControlName="telephone2" [placeholder]="translate.instant('LABEL.PHONE')">
                </mat-form-field>

              </div>

              <div *ngIf="getInsertSubjectType() == 'alias'">
                <mat-form-field appearance="fill" class="form-subject">
                  <mat-label>{{ 'REVIEWS.subjectAlias' | translate }}</mat-label>
                  <input matInput formControlName="subjectAlias"
                    [placeholder]="translate.instant('REVIEWS.subjectAlias')">
                </mat-form-field>
              </div>



            </mat-card-content>
          </mat-card>
        </div>

        <br>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label>{{ 'LABEL.company' | translate }}</mat-label>
          <mat-select formControlName="companyId">
            <mat-option *ngFor="let company of companies" [value]="company.id" [disabled]="!company.isOpen">
              {{ company.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label>{{ 'LABEL.EMAILPEC' | translate }}</mat-label>
          <input matInput formControlName="emailPec" [placeholder]="translate.instant('LABEL.EMAILPEC')">
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label> {{ "LABEL.receiptDate" | translate }} </mat-label>
          <input matInput [matDatepicker]="picker" formControlName="receiptDate" required />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label> {{ "LABEL.forwardDate" | translate }} </mat-label>
          <input matInput [matDatepicker]="pickerForwardDate" formControlName="forwardDate" required />
          <mat-datepicker-toggle matSuffix [for]="pickerForwardDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerForwardDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label> {{ "LABEL.feedbackDate" | translate }} </mat-label>
          <input matInput [matDatepicker]="pickerFeedbackDate" formControlName="feedbackDate" />
          <mat-datepicker-toggle matSuffix [for]="pickerFeedbackDate"></mat-datepicker-toggle>
          <mat-datepicker #pickerFeedbackDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label>{{ 'LABEL.emailSubject' | translate }}</mat-label>
          <textarea matInput [placeholder]="translate.instant('PEC.emailSubject')"
            formControlName="emailSubject"></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label>{{ 'REVIEWS.description' | translate }}</mat-label>
          <textarea matInput [placeholder]="translate.instant('REVIEWS.description')"
            formControlName="description"></textarea>
        </mat-form-field>



        <div class="buttonBox">
          <button [disabled]="!form.valid" class="submitButton" type="submit" mat-raised-button color="primary">
            <mat-icon>save</mat-icon> {{ 'LABEL.SAVE' | translate }}
          </button>
        </div>
        <br>
      </form>

    </div>

  </mat-tab>

  <mat-tab label="Attività" *ngIf="pecId && checkPermissions('actions_show_pec_activities')">
    <br>
    <form [formGroup]="form" (submit)="save()" class="form-container">
      <div class="form-subject">
        <h2>Attività</h2>
      </div>

      <div class="form-subject">
        <button type="button" class="btn btn-primary btn-sm me-3 text-dark" (click)="addActivity()"
          *ngIf="pecId && checkPermissions('actions_insert_pec_activity')">
          <div>{{'LABEL.addActivity' | translate}}</div>
        </button>
      </div>

      <br>

      <div class="matCardNoteBox form-subject">

        <ng-container formArrayName="activities">

          <div *ngFor="let activity of activities.controls; let p = index" class="noteBox">
            <div [formGroup]="activity">

              <div style="display: flex; justify-content: space-between; width: 100%; margin-bottom: 10px;">
                <div><strong>Autore:</strong> {{activity?.get('authorEmail')?.value}}</div>
                <div><strong>Data Inserimento:</strong> {{activity?.get('insertDate')?.value | date}}</div>
                <div *ngIf="!activity?.get('id')?.value">
                  <button mat-icon-button type="button" (click)="deleteActivity(p)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>

              <mat-form-field appearance="fill" class="noteValueBox">
                <mat-label>{{ 'LABEL.type' | translate }}</mat-label>
                <mat-select formControlName="pecActivityTypeValue" (selectionChange)="setPecActivityTypeValue($event, p)">
                  <mat-option *ngFor="let pecActivityType of pecActivityTypes" [value]="pecActivityType.id"
                    [disabled]="pecActivityType.disabled">
                    {{ pecActivityType.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" class="noteValueBox">
                <mat-label>{{ 'LABEL.activityText' | translate }}</mat-label>
                <textarea matInput rows="4" [placeholder]="translate.instant('LABEL.activityText')"
                  formControlName="note"></textarea>
              </mat-form-field>

            </div>
          </div>

        </ng-container>




      </div>


      <div class="buttonBox">
        <button [disabled]="!form.valid" class="submitButton" type="submit" mat-raised-button color="primary">
          <mat-icon>save</mat-icon> {{ 'LABEL.SAVE' | translate }}
        </button>
      </div>

    </form>
  </mat-tab>

  <mat-tab label="Note" *ngIf="pecId && checkPermissions('actions_show_pec_note')">
    <br>
    <form [formGroup]="form" (submit)="save()" class="form-container">
      <div class="form-subject">
        <h2>Note</h2>
      </div>

      <div class="form-subject">
        <button type="button" class="btn btn-primary btn-sm me-3 text-dark" (click)="addNote()"
          *ngIf="pecId && checkPermissions('actions_insert_pec_note')">
          <div>{{'LABEL.addNote' | translate}}</div>
        </button>
      </div>

      <br>

      <div class="matCardNoteBox form-subject">

        <ng-container formArrayName="notes">

          <div *ngFor="let note of notes.controls; let p = index" class="noteBox">
            <div [formGroup]="note">

              <div style="display: flex; justify-content: space-between; width: 100%; margin-bottom: 10px;">
                <div><strong>Autore:</strong> {{note?.get('authorEmail')?.value}}</div>
                <div><strong>Data Inserimento:</strong> {{note?.get('insertDate')?.value | date}}</div>
                <div *ngIf="!note?.get('id')?.value">
                  <button mat-icon-button type="button" (click)="deleteNote(p)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>

              <mat-form-field appearance="fill" class="noteValueBox">
                <mat-label>{{ 'LABEL.noteText' | translate }}</mat-label>
                <textarea matInput rows="4" [placeholder]="translate.instant('LABEL.noteText')"
                  formControlName="note"></textarea>
              </mat-form-field>

            </div>
          </div>

        </ng-container>




      </div>


      <div class="buttonBox">
        <button [disabled]="!form.valid" class="submitButton" type="submit" mat-raised-button color="primary">
          <mat-icon>save</mat-icon> {{ 'LABEL.SAVE' | translate }}
        </button>
      </div>


    </form>
  </mat-tab>

  <mat-tab label="Associazioni tra PEC" *ngIf="pecId && checkPermissions('actions_list_associations_between_pecs')">
    <app-associations-between-pecs [pecId]="pecId" *ngIf="pecId && tabIndex == 3"></app-associations-between-pecs>
  </mat-tab>
</mat-tab-group>