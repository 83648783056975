import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { LibService } from '../../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UserSessionService } from '../../../services/user-session.service';
import { NavService } from '../../../services/nav.service';
import { TranslatorService } from '../../../services/translate.service';
import { MatPaginator } from '@angular/material/paginator';
import { ActionService } from '../../../services/action.service'
import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';
import { MarketingNoteService } from '../../../services/marketingNote.service';
import { EventService } from '../../../services/event.service';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import * as XLSX from 'xlsx';
import { EventUploadDocumentModal } from '../event-modal/event-upload-document-modal/event-upload-document-modal.component';
import { BuildingModal } from '../../../modals/building-modal/building-modal.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { BuildingService } from '../../../services/building.service';
import { ToastrService } from 'ngx-toastr';
import { EventsBuildingService } from '../../../services/eventsBuilding.service';

@Component({
  selector: 'app-event-buildings',
  templateUrl: './event-buildings.component.html',
  styleUrls: ['./event-buildings.component.scss']
})

export class EventBuildingsComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;

  itemType = "marketing_notes"
  sectionName = "marketing-note-settings"
  dataSource: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  sort: MatSort
  companies: any = []

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('itemDialog') itemDialog: TemplateRef<any>
  @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  searchTimeout: any;

  marketingNoteList: any = [];

  constructor(public navService: NavService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    public translate: TranslatorService,
    private actionService: ActionService,
    private marketingNoteService: MarketingNoteService,
    private eventService: EventService,
    private dialog: MatDialog,
    private buildingService: BuildingService,
    private toastr: ToastrService,
    private eventsBuildingService: EventsBuildingService,
  ) {
    this.dataSource = new MatTableDataSource();
  }


  EventId
    :
    2
  buildingTypeId
    :
    null
  city
    :
    "Barletta"
  company
    :
    "EDAC_BAT"
  companyLocation
    :
    null
  condominiumId
    :
    null
  contactChannel
    :
    null
  coordX
    :
    16.2685722
  coordY
    :
    41.3095149
  country
    :
    "Italia"
  createdAt
    :
    "2024-11-29T12:37:12.323Z"
  deletedAt
    :
    null
  formattedAddress
    :
    "Via dei Pini, 76121 Barletta BT, Italia"
  geographicArea
    :
    ""
  geographicLocation
    :
    null
  googlePlaceId
    :
    "ChIJWUHf8QkcOBMRiD968TFrelo"
  height
    :
    null
  id
    :
    241899
  name
    :
    null
  neighborhood
    :
    null
  notes
    :
    null
  numberOfFloors
    :
    null
  origin
    :
    null
  otherInfo
    :
    null
  province
    :
    "BT"
  responsible
    :
    null
  state
    :
    "Puglia"
  status
    :
    null
  storicBuilding
    :
    null
  streetName
    :
    "Via dei Pini"
  streetNumber
    :
    "4"
  sublocality
    :
    null
  supportImport
    :
    null
  sysCodeDelta
    :
    null
  type
    :
    "Edificio"
  updatedAt
    :
    "2024-11-29T12:37:12.323Z"
  width
    :
    null
  zip
    :
    "76121"


  displayedColumns = ['streetName', 'streetNumber', 'zip', 'city', 'province', 'country', 'coordX', 'coordY'];

  columns: any = [
    {
      name: 'streetName',
      flex: '6',
      title: this.getTitle('streetName'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item?.streetName
    },
    {
      name: "streetNumber",
      title: this.getTitle('streetNumber'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.streetNumber
    },
    {
      name: "zip",
      title: this.getTitle('zip'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.zip
    },
    {
      name: "city",
      title: this.getTitle('city'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.city
    },
    {
      name: "province",
      title: this.getTitle('province'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.province
    },
    {
      name: "country",
      title: this.getTitle('country'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.country
    },
    {
      name: "coordX",
      title: this.getTitle('coordX'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.coordX
    },
    {
      name: "coordY",
      title: this.getTitle('coordY'),
      cssClass: (item: any) => ['column-padding', `${item?.canceledAt ? 'notApproval' : ''}`],
      value: (item: any) => item.coordY
    },
  ]

  removeItem(item: any) {
    if (confirm("Sei sicuro di voler rimuovere questa location?") == true) {
      this.eventsBuildingService.destroy(item.EventBuildingId).subscribe((responseData: any) => {
        this.loadData();
      });
    }
  }

  getTitle(type: any) {
    let title: any = this.translate.get('LABEL.NOT_PRESENT')
    switch (type) {
      case 'streetName':
        title = this.translate.get('LABEL.streetName')
        break;
      case 'streetNumber':
        title = this.translate.get('LABEL.streetNumber')
        break;
      case 'zip':
        title = this.translate.get('LABEL.zip')
        break;
      case 'city':
        title = this.translate.get('LABEL.city')
        break;
      case 'province':
        title = this.translate.get('LABEL.province')
        break;
      case 'country':
        title = this.translate.get('LABEL.country')
        break;
      case 'coordX':
        title = this.translate.get('LABEL.coordX')
        break;
      case 'coordY':
        title = this.translate.get('LABEL.coordY')
        break;

    }
    return title
  }


  getBooleanLabel(value: any) {
    if ([null, undefined].includes(value)) {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    } else {
      return value ? this.translate.instant('LABEL.YES') : this.translate.instant('LABEL.NO') as string
    }
  }



  actions: any = [];

  removeDocument(item: any) {
    console.log(item)
  }


  openDocumentUploader() {

    const dialogRef = this.dialog.open(EventUploadDocumentModal, {
      width: '400px',
      data: []
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (![null, undefined].includes(result)) {


      }

    });
  }


  setUserActions() {

    this.actions.push({
      label: of(''),
      cssClass: (item: any) => ['redButton'],
      icon: (item: any) => {
        return 'cancel';
      },
      onClick: (item: any) => this.removeItem(item),
      color: 'primary'
    })


  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }



  ngOnInit(): void {
    this.currentUserCountryVisibilities()
    this.loadData();
    this.setUserActions();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      { name: 'new', url: 'actions/0' },
      //{ name: 'export', url: '' },
    ];
    this.navService.setActions(actions);

    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();

    clearTimeout(this.searchTimeout)
    // this.searchTimeout = setTimeout(() => this.updateCompaniesData(), 1000)
  }



  loadData(): void {

    this.libService.lockPage('');

    let eventId = this.form?.get('id')?.value

    this.eventService.buildings(eventId).subscribe((responseData: any) => {
      console.log('loadDataSets response %o', responseData);
      this.libService.unlockPage();
      this.dataSource = responseData;
    }, err => {
      console.log(err)
    });

  }

  goToPage(item: any) {
    this.router.navigate([`settings/${this.sectionName}/` + item.id]);
  }

  saveForm(attributes: any) {
    console.log(attributes)
  }

  filter(event: any) {
    console.log(event)
    let actions = this.marketingNoteList.filter((action: any) => action.TranslationCategoryId == event.value)
    this.dataSource = actions
  }

  downloadExport() {
    let stampDate = new Date().getTime()
    let sheet = XLSX.utils.json_to_sheet(this.dataSource);
    XLSX.utils.sheet_add_aoa(sheet, [this.displayedColumns], { origin: 'A1' });
    let workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, `EventRegistrations`);

    var bin = XLSX.writeFile(workbook, `${stampDate}_eventRegistrations.xlsx`);
  }

  openBuildingModal() {
    console.log('openBuildingModal')
    let eventId = this.form?.get('id')?.value
    this.dialog.open(BuildingModal, {
      data: {
        type: 'eventBuilding',
        companies: this.companies,
        eventId: eventId,

      },
      scrollStrategy: new NoopScrollStrategy(),
      width: '70%'
    })
      .afterClosed()
      .subscribe(res => {
        console.log('uploadDialogres: ', res);
        //this.subject.documents.push(res)
        if (res) {
          let building = res.building
          this.checkDuplicate(eventId, building)

          //  this.buildingSorter(res)
        }
        //this.subjectsBuildingsItems = [...this.subjectsBuildings.getRawValue(), ...this.buildingSubjectsBuildings.getRawValue()]
      })
  }

  currentUserCountryVisibilities() {
    this.userSessionService.getVisibilities().subscribe(
      (v) => {
        this.companies = v.UsersCompaniesRel
      },
      (err) => {
        console.log(err);
      }
    );
  }

  checkDuplicate(eventId: any, building: any) {
    if (building?.id === null) {
      this.buildingService.findByAddress(building).subscribe((response: any) => {
        let message = ''
        if (response != null) {
          message = message + this.translate.instant('TOASTR.MESSAGE19') + response.name;
          this.toastr.error(message, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
          return true
        } else {
          this.eventService.addBuilding(eventId, building).subscribe((response: any) => {
            console.log(response)
            this.loadData()
          });
          return false
        }
      })
    } else {
      this.eventService.addBuilding(eventId, building).subscribe((response: any) => {
        console.log(response)
        this.loadData()
      });
    }
  }

}
