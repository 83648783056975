import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pec-association-modal',
  templateUrl: './pec-association-modal.component.html',
  styleUrls: ['./pec-association-modal.component.scss']
})
export class PecAssociationModalComponent implements OnInit {

  confirmButtonLabel: any = 'LABEL.SAVE'
  cancelButtonLabel: any = 'LABEL.CANCEL'

  withFilter: boolean = false
  withSearchBar: boolean = true
  addButton: boolean = false
  viewMode: string = 'pecsAssociations'

  currentPec: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PecAssociationModalComponent>,
  ) { }

  ngOnInit(): void { }

  selectedPec(event: any) {
    console.log(event)
    this.currentPec = event
  }

  closeModal() {
    this.dialogRef.close(true);
  }

}
