import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { UserSessionService } from './user-session.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  subject: any = {};

  baseApi = environment.rootApiCrm + 'v1.0';
  constructor(public libService: LibService,
              public userSessionService: UserSessionService) { }

  getAllActivities(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `/activities`);

  }

  deleteActivity(id: string, request: any) {
    return this.libService.deleteData<any>(request, this.baseApi, `/activity/${id.toString()}`);
  }

  createActivity(obj: any) {
    console.log("ATTIVITA FRONT END --> ", obj);
    return this.libService.postData<any>(obj, this.baseApi,'/activity');
  }

  updateActivity(obj: any){
    return this.libService.postData<any>(obj, this.baseApi, `/activity/${obj.id}`);
  }

  getActivityById(idActivity: number) {
    console.log("ID ACTIVITY FRONT END --> ", idActivity)
    return this.libService.getData<any>(this.baseApi, `/activities/${idActivity}`);
  }

  getActivityBySubject(subject: any) {
    console.log("ID ACTIVITY FRONT END --> ", subject)
    return this.libService.getData<any>( this.baseApi, `/activitiesBySubject/${subject.id}`);
  }

  getActivityByOwner(email: any) {
    console.log("email owner FRONT END --> ", email)
    return this.libService.postData<any>({email: email}, this.baseApi, `/activitiesByOwner`);
  }

  getAllActivityByOwner(email: any) {
    // let activities = this.userSessionService.getState(`activities&${email}`)
    // console.log("email owner FRONT END --> ", email)
    // if (activities !== null && activities !== undefined) {
    //   return of(activities);
    // } else {
      return this.libService.postData<any>({email: email}, this.baseApi, `/allActivitiesByOwner`);
      // .pipe(
      //   tap(acts => {
      //     this.userSessionService.saveState(`activities&${email}`, acts)
      //   })
      // );

  }

  setSubjectData(subject: any){
    this.subject = subject;
  }

  getSubjectData(): any {
    console.log("soggetto da richiedere -->", this.subject);
    return this.subject;
  }
}
;
