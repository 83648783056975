<div class="container-fluid my-container">
	<div class="full-w">
		<form [formGroup]="formTargets">

			<table style="padding-bottom: 16px">
				<tr>
					<th style="padding-right: 16px">{{ 'LABEL.BRANCH' | translate}}</th>
					<td style="padding-right: 64px">{{ branch?.name || ('LABEL.undefined' | translate)}}</td>
					<th style="padding-right: 16px">{{ 'LABEL.CODE' | translate}}</th>
					<td style="padding-right: 64px">{{ branch?.code || ('LABEL.undefined' | translate)}}</td>
				</tr>
				<tr>
					<th style="padding-right: 16px">{{ 'LABEL.CATEGORY' | translate}}</th>
					<td style="padding-right: 64px">{{ category?.name || ('LABEL.undefined' | translate)}}</td>
					<th style="padding-right: 16px">{{ 'LABEL.CODE' | translate}}</th>
					<td style="padding-right: 64px">{{ category?.code || ('LABEL.undefined' | translate)}}</td>
				</tr>
			</table>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.MACROPHASE' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="macrophase" (selectionChange)="updateTree()" #macroSelection
					(opened)="onOpenSearch(searchMacrophase)"
					(closed)="onCloseSearch('Macrophase')">
					<mat-form-field id="searchSelect" appearance="fill">
						<mat-label>{{ 'LABEL.SEARCH' | translate}}</mat-label>
						<input matNativeControl type="text" (keyup)="onChangeFilterMacroPhase($event)" #searchMacrophase>
					</mat-form-field>
					<mat-option *ngFor="let macrophase of macroPhases" [value]="macrophase.id"> {{ macrophase.name }}  ({{ macrophase.codCountry }}) </mat-option>
					<mat-option *ngIf="macroPhases?.length == 0" disabled> Nessun risultato </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.CODE' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="code">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.NAME' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="name">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH_STATE' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="codCountry">
					<mat-option *ngFor="let country of countryOptions" [value]="country"> {{ country }} </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH_LANG' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="codLanguage">
					<mat-option *ngFor="let lang of langOptions" [value]="lang"> {{ lang }} </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.QPRODORARIA' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="qProdOraria">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.CMATER' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="cMater">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.DESCRIPTION' | translate	}}</mat-label>
				<textarea matInput matNativeControl formControlName="desL"></textarea>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.POSITION' | translate}}</mat-label>
				<input type="number" matNativeControl formControlName="position">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.UNITMEASURE' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="UnitMeasureIdId"
					(opened)="onOpenSearch(searchUnitMeasure)"
					(closed)="onCloseSearch('UnitMeasure')">
					<mat-form-field id="searchSelect" appearance="fill">
						<mat-label>{{ 'LABEL.SEARCH' | translate}}</mat-label>
						<input matNativeControl type="text" (keyup)="onChangeFilterUnitMeasure($event)" #searchUnitMeasure>
					</mat-form-field>
					<mat-option *ngFor="let unit of unitMeasures" [value]="unit.id"> {{ unit.code }} </mat-option>
					<mat-option *ngIf="unitMeasures?.length == 0" disabled> Nessun risultato </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-checkbox appearance="fill" matNativeControl formControlName="isPercAmmaloramento">{{ 'LABEL.ISPERCAMMALORAMENTO' | translate }}</mat-checkbox>

			<mat-checkbox appearance="fill" matNativeControl formControlName="isOpereSpeciali">{{ 'LABEL.ISOPERESPECIALI' | translate }}</mat-checkbox>

			<mat-checkbox appearance="fill" matNativeControl formControlName="isAdditionalMaterialCost">{{ 'LABEL.ISADDITIONALMATERIALCOST' | translate }}</mat-checkbox>

			<mat-checkbox appearance="fill" matNativeControl formControlName="isPreselected">{{ 'LABEL.isPreselected' | translate }}</mat-checkbox>

			<mat-checkbox appearance="fill" matNativeControl formControlName="isOtherCost">{{ 'LABEL.ISOTHERCOST' | translate }}</mat-checkbox>

			<mat-checkbox appearance="fill" matNativeControl formControlName="isActive">{{ 'LABEL.ISACTIVE' | translate }}</mat-checkbox>
		</form>

		<div class="buttons-right my-2">
			<button style="background-color:transparent;" [disabled]="!formTargets.valid || formTargets.pristine"
				matTooltip="{{ 'LABEL.SAVE' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
				(click)="update()">
				<mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
			</button>
		</div>
	</div>
</div>
