import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { GeocoderResponse } from 'src/app/models/geocoder';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { FinanceService } from 'src/app/services/finance.service';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, startWith, Observable } from "rxjs";
import { CrmFormDialogComponent } from '../crm-form-dialog/crm-form-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SubjectService } from 'src/app/services/subject.service';
import { CountryService } from 'src/app/services/country.service';

@Component({
  selector: 'app-crm-form-localization',
  templateUrl: './crm-form-localization.component.html',
  styleUrls: ['./crm-form-localization.component.scss'],
})
export class CrmFormLocalizationComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() mainForm: UntypedFormGroup;
  @Input() toDisable: boolean;
  @Input() isNew: boolean;
  @Input() type: string;
  @Input()subjectAttributes : any[] = [];

  address: string;
  formattedAddress: string;

  locationCoords: google.maps.LatLng;
  mapCenter: any;
  mapZoom = 12;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
  };
  markerInfoContent: string = '';
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,
  };

  geocoderWorking = false;
  geolocationWorking = false;

  token: any;

  get isWorking(): boolean {
    return this.geolocationWorking || this.geocoderWorking;
  }

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

  constructor(
    private geocodingService: GeocodingService,
    private toastr: ToastrService,
    private http: HttpClient,
    private dialog: MatDialog,
    public translate: TranslateService,
    public userSessionService: UserSessionService,
    public financeService: FinanceService,
    public subjectService: SubjectService,
    public countryService: CountryService,
  ) { }

  ngOnInit(): void {

    console.log("TIPO -->", this.type);
    console.log("ISNEW --> ", this.isNew);
    let streetName$ = this.form.get('streetName')?.valueChanges
    let streetNumber$ = this.form.get('streetNumber')?.valueChanges
    let zip$ = this.form.get('zip')?.valueChanges
    let city$ = this.form.get('city')?.valueChanges
    let province$ = this.form.get('province')?.valueChanges
    let state$ = this.form.get('state')?.valueChanges
    let country$ = this.form.get('country')?.valueChanges

    combineLatest([streetName$, streetNumber$, zip$, city$, province$, state$, country$]).subscribe(([streetName, streetNumber, zip, city, province, state, country]: any[]) => {
      let address = (streetName ? streetName : '') + ' ' + (streetNumber ? streetNumber : '') + ', ' + (zip ? zip : '') + ' ' + (city ? city : '') + ' ' + (province ? province : '') + ' ' + (state ? state : '') + ' ' + (country ? country : '');

      (address.trim().length > 1) ? undefined : address = '';
      this.address = address;
      this.formattedAddress = address
      this.markerInfoContent = address

    })

    let coordX$ = this.form.get('coordX')?.valueChanges
    let coordY$ = this.form.get('coordY')?.valueChanges
    combineLatest([coordX$, coordY$]).subscribe(([coordX, coordY]: any[]) => {
      this.locationCoords = new google.maps.LatLng(coordY, coordX);
      this.mapCenter = this.locationCoords
    })
  }

  handleOnAddressChange(event: any) {
    this.address = event.formatted_address
  }

  checkEditability(){
    let attributesArray = []
    if (this.subjectAttributes) {
      for (const [key, value] of Object.entries(this.subjectAttributes)) {
        if(value.find((v:any)=> v.editable == true)) attributesArray.push(key)
      }
    }

    return !attributesArray.includes('searchAdress')
  }

  findAddress() {
    if (!this.address || this.address.length === 0) {
      return;
    }

    let currentLanguage = this.userSessionService.getCurrentLanguage()

    this.geocoderWorking = true;
    this.geocodingService
      .getLocation(this.address, currentLanguage)
      .subscribe(
        (response: GeocoderResponse) => {
          if (response.status === 'OK' && response.results?.length) {
            console.log('response for geocoding %o', response);
            this.placeChangedCallback(response.results[0]);
            const location = response.results[0];
            const loc: any = location.geometry.location;
            this.form.get('coordX')?.setValue(loc.lng);
            this.form.get('coordY')?.setValue(loc.lat);
            this.locationCoords = new google.maps.LatLng(loc.lat, loc.lng);

            this.mapCenter = location.geometry.location;

            setTimeout(() => {
              if (this.map !== undefined) {
                this.map.panTo(location.geometry.location);
              }
            }, 500);

            this.address = location.formatted_address;
            this.formattedAddress = location.formatted_address;
            this.markerInfoContent = location.formatted_address;

            //OGNI VOLTA CHE CERCO UN INDIRIZZO AGGIORNO IL FORM DEI DETTAGLI INDIZZO
            /* this.form.patchValue({
              streetName: this.form.get('streetName')!.value,
              streetNumber: this.form.get('streetNumber')!.value,
              city: this.form.get('city')!.value,
              province: this.form.get('province')?.value,
              zip: this.form.get('zip')!.value,
              country: this.form.get('country')!.value,
            }); */

            this.markerOptions = {
              draggable: true,
              animation: google.maps.Animation.DROP,
            };
          } else {
            this.toastr.error(response.error_message, response.status);
          }
        },
        (err: HttpErrorResponse) => {
          console.error('geocoder error', err);
        }
      )
      .add(() => {
        this.geocoderWorking = false;
      });
  }

  placeChangedCallback(place: any) {
    //console.log('place %o',place);
    place.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        console.log('place %o', add);
        switch (addType) {
          case 'street_number':
            this.form.get('streetNumber')?.setValue(add.short_name);
            break;
          case 'route':
            this.form.get('streetName')?.setValue(add.long_name);
            break;
          case 'locality':
            this.form.get('city')?.setValue(add.long_name);
            break;
          case 'administrative_area_level_1':
            this.form.get('state')?.setValue(add.long_name);
            break;
          case 'administrative_area_level_2':
            this.form.get('province')?.setValue(add.short_name);
            break;
          case 'country':
            this.form.get('country')?.setValue(add.long_name);
            break;
          case 'postal_code':
            this.form.get('zip')?.setValue(add.long_name);
            break;

          default:
            break;
        }
      });
    });
  }

  getCurrentLocation() {
    this.geolocationWorking = true;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.geolocationWorking = false;

        const point: google.maps.LatLngLiteral = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.form.get('coordX')?.setValue(position.coords.longitude)
        this.form.get('coordY')?.setValue(position.coords.latitude)
        this.geocoderWorking = true;
        this.geocodingService
          .geocodeLatLng(point)
          .then((response: GeocoderResponse) => {
            if (response.status === 'OK' && response.results?.length) {
              this.placeChangedCallback(response.results[0]);
              const value = response.results[0];

              this.locationCoords = new google.maps.LatLng(point);

              this.mapCenter = new google.maps.LatLng(point);
              if (!(this.map == null))
                this.map.panTo(point);

              this.address = value.formatted_address;
              this.formattedAddress = value.formatted_address;
              this.markerInfoContent = value.formatted_address;

              this.markerOptions = {
                draggable: true,
                animation: google.maps.Animation.DROP,
              };
            } else {
              this.toastr.error(response.error_message, response.status);
            }
          })
          .finally(() => {
            this.geocoderWorking = false;
          });
      },
      (error) => {
        this.geolocationWorking = false;

        if (error.PERMISSION_DENIED) {
          this.toastr.error(this.translate.instant('TOASTR.MESSAGE28'), this.translate.instant('TOASTR.ACCES_DENIED'));
        } else if (error.POSITION_UNAVAILABLE) {
          this.toastr.error(
            this.translate.instant('TOASTR.MESSAGE28'),
            this.translate.instant('TOASTR.MESSAGE29')
          );
        } else if (error.TIMEOUT) {
          this.toastr.error(this.translate.instant('TOASTR.MESSAGE28'), this.translate.instant('TOASTR.TIMEOUT'));
        } else {
          this.toastr.error(error.message, this.translate.instant('TOASTR.ERROR') + `: ${error.code}`);
        }
      },
      { enableHighAccuracy: true }
    );
  }

  cercaZonaJaka() {

    let currentUser = this.userSessionService.getState('user');
    let lat = this.form.get('coordY')!.value;
    let lon = this.form.get('coordX')!.value;

    this.financeService.getGeoArea(currentUser.email, lat, lon).subscribe((response: any) => {
      console.log('getGeoArea CRM REQUEST')

      if (response.esito == 'OK') {
        let geo: [] = response.item.Table

        if (geo.length == 0) {
          let businessLineIdValue = this.mainForm.get('businessLineId')?.value
          console.log(businessLineIdValue)
          this.countryService.getCompaniesbyBusinessLineId(businessLineIdValue).subscribe((response: any) => {
            geo = response.sort(this.compare)
            if (geo.length > 0) {
              console.log(geo)
              this.openDialog(geo);
            } else {
              this.toastr.error(this.translate.instant('TOASTR.MESSAGE_2'), this.translate.instant('TOASTR.WARNING'));
            }
          });

        } else {
          console.log(geo)
          this.openDialog(geo);
        }

      } else {
        console.log("CATCH ERROR PATCH VALUE REQUEST");
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_2'), this.translate.instant('TOASTR.WARNING'));
      }
    })

  }

  compare(a: any, b: any) {
    if (a.code < b.code) {
      return -1;
    }
    if (a.code > b.code) {
      return 1;
    }
    return 0;
  }

  private chiamaHttp(link: any): any {
    let res = this.http.get(link);
    return res;

  }

  openDialog(sedi: any) {
    console.log("DIALOG NEW ");
    const dialogRef = this.dialog.open(CrmFormDialogComponent, {
      data: sedi
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result:`);
      console.log(result);

      if (result) {
        console.log("sede settata", result);
        let selectedCompany = result.company
        this.form.patchValue({
          company: selectedCompany.code,
          geographicArea: ''
        })
        // Setteggio tipologia soggetto
        this.form?.get('subjectType')?.setValue(result.subjectType)
        this.form?.get('subjectTypeArray')?.setValue(result.subjectTypeArray)
        this.form?.get('subjectTypeArray')?.setValue(result.subjectTypeArray)
        this.getStructure(result.subjectType.id, selectedCompany.id)
        this.toastr.success(this.translate.instant('TOASTR.MESSAGEGEOAREAFOUND'), this.translate.instant('LABEL.Info'));
      }

    });
  }

  getStructure(subjectTypeId: number, companyId: number) {
    this.subjectService.getSubjectFormStructure(subjectTypeId, companyId).subscribe((response: any) => {
      this.form?.get('components')?.setValue(response)
    })
  }

  openInfoWindow(marker: MapMarker) {
    this.infoWindow.open(marker);
  }

  onMapDragEnd(event: any) {
    console.log('EVENT %o', event);
    const point: google.maps.LatLngLiteral = {
      lat: event.latLng!.lat(),
      lng: event.latLng!.lng(),
    };

    this.geocoderWorking = true;
    this.geocodingService
      .geocodeLatLng(point)
      .then((response: GeocoderResponse) => {
        if (response.status === 'OK') {
          if (response.results.length) {
            const value = response.results[0];

            this.locationCoords = new google.maps.LatLng(point);

            this.mapCenter = new google.maps.LatLng(point);
            if (!(this.map == null))
              this.map.panTo(point);

            this.address = value.formatted_address;
            this.formattedAddress = value.formatted_address;

            this.markerOptions = {
              draggable: true,
              animation: google.maps.Animation.DROP,
            };

            this.markerInfoContent = value.formatted_address;
          }
        }
      })
      .finally(() => {
        this.geocoderWorking = false;
      });
  }

  disableButtons(): boolean {
  return true
  }

}
