<div class="container-fluid my-container">
    
    <div class="search-wrapper mat-elevation-z2">
        <div class="search" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
            <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
                [placeholder]="translate.instant('LABEL.SEARCH')" />
        </div>
    </div>
    <div style="overflow-x: auto;">
    <table mat-table [dataSource]="dataSource" matSort style="width:100%">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.ID' | translate }}</th>
            <td class="tdg" mat-cell *matCellDef="let element">
                {{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CODE' | translate }}</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.code}}</b></span>
            </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.V_START_DATE' | translate }}</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.startDate | date}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.V_END_DATE' | translate }}</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.endDate | date}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.TYPE' | translate }}</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.type}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="numberOfUsers">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.NUMBER_OF' | translate:{'field': translate.instant('LABEL.USERS') } }}</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.numberOfUsers}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="cost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LICENCE_COST' | translate }}</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.cost | number: '1.2-2'}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="isOpen">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LICENCE_OPEN' | translate }}</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.isOpen}}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="isUsed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.FREE' | translate }}</th>
            <td class="tdg" mat-cell *matCellDef="let element"> <span *ngIf="!(element.isUsed == true)"><i class="my-bounce material-icons ic">check</i></span>
            </td>
        </ng-container>
        <ng-container matColumnDef="editAction" >
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"><i class="my-bounce material-icons ic">edit</i>
          </ng-container>
        <ng-container matColumnDef="deleteAction" >
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"><i class="my-bounce material-icons ic">delete</i>
          </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </div>
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
    </mat-paginator>
</div>