import { Component, OnInit, ElementRef, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray, FormControl, FormControlName, AbstractControl, ValidationErrors, FormControlDirective } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { TranslatorService } from 'src/app/services/translate.service';
import { CalendarContractService } from 'src/app/services/calendar-contrcats-service';
import { AbsenceModuleService } from 'src/app/services/absence-module-service';
import { UserSessionService } from '../../../services/user-session.service';
import { CompanyService } from 'src/app/services/companyService';
import { catchError, combineLatest, map, Observable, of, tap, throwError } from 'rxjs';
import { HistoryBalanceService } from 'src/app/final-balancer/providers/history-balance.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { CountryService } from 'src/app/services/country.service';
import { ProfileService } from 'src/app/services/profileService';
import { BranchService } from 'src/app/services/branch.service';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { workCausalDocumentTypesService } from '../../../services/workCausalDocumentTypesService';
import { UserDataService } from '../../../services/users.service';
import { AuthService } from 'src/app/auth/auth.service';






@Component({
    selector: 'absence-modules-form',
    templateUrl: './absence-modules-form.html',
    styleUrls: ['./absence-modules-form.scss'],
})
export class AbsenceModulesFormComponent implements OnInit {

    @Input() documentTypes: DocumentType[] | null;
    @Output() selectFileClickedEvent = new EventEmitter<File>();

    selectedWorkCausalDocuments: any[] = []; // Array per contenere i documenti relativi al workCausal selezionato
    uploadedFiles: { [documentId: number]: File } = {}; // Oggetto per tracciare i file selezionati
    selectedFiles: { [index: number]: File } = {}; // Oggetto per mostrare il nome dei file selezionati


    //   form: UntypedFormGroup;
    id: any;
    causalSearchControl = new FormControl();
    workCausals?: any;
    filteredCausals?: any;
    minEndDate?: Date;
    form: UntypedFormGroup;

    formTitle: string;

    //aggiunti per lo sviluppo
    user = new FormControl();
    filteredUsers: any;
    operatorAvailable: any;
    loggedUser: any;
    idSediCurrentUser: any;
    operatorSearchControl = new FormControl();
    hours = new FormControl();
    contractDescription: any;
    boxDocumentBool: boolean = false;
    boxDocumentEmptyBool: boolean = false;
    configurationCalendarId: any;
    WorkCausalDocumentTypeId: any;



    constructor(
        public navService: NavService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public libService: LibService,
        private toastr: ToastrService,
        public translate: TranslatorService,
        private router: Router,
        private userSessionService: UserSessionService,
        private CalendarContractService: CalendarContractService,
        private AbsenceModuleService: AbsenceModuleService,
        private HistoryBalanceService: HistoryBalanceService,
        public countryService: CountryService,
        public profileService: ProfileService,
        public BranchService: BranchService,
        private userDataService: UserDataService,
        private authService: AuthService,

    ) { }

    getUsers() {
        this.userSessionService.getVisibilities().subscribe(
            (userdata) => {
                // console.log(userdata);

                // Ricavo gli ID delle sedi legate all'utente
                this.idSediCurrentUser = userdata.UsersCompaniesRel.map((company: any) => company.id);
                // console.log(this.idSediCurrentUser);

                // Chiamo il metodo per ottenere i nomi degli utenti legati agli ID delle sedi
                this.userSessionService.getNamesBySedeAppartenenzaIds(this.idSediCurrentUser).subscribe(
                    (operatorNames: any) => {
                        this.operatorAvailable = operatorNames; // Salvo i nomi degli utenti ottenuti
                        // console.log(this.operatorAvailable); // Stampo i nomi degli utenti
                        this.filteredUsers = this.operatorAvailable;
                    },
                    (err) => {
                        console.log('Errore nel recupero dei nomi:', err);
                    }
                );
            },
            (err) => {
                console.log('Errore nel recupero delle visibilità:', err);
            }
        );
    }

    getWorkCausalFromContract(UserId: any, startDate: any, endDate: any): Observable<any> {
        // Prepara i dati per la chiamata al servizio
        const requestData = {
            UserId: UserId, // Verifica che 'users' abbia un campo 'id'
            startDate: moment(startDate).format('YYYY-MM-DD'), // Formatta la data nel formato richiesto
            endDate: moment(endDate).format('YYYY-MM-DD')      // Formatta la data con Moment.js
        };

        // console.log('UserId:', UserId);
        // console.log('StartDate:', startDate);
        // console.log('EndDate:', endDate);

        // Verifica che tutti i campi necessari siano presenti
        if (!requestData.UserId || !requestData.startDate || !requestData.endDate) {
            console.error('Dati mancanti per la richiesta:', requestData);
            this.toastr.error('Dati mancanti per il recupero delle causali di assenza');
            return new Observable<any>(); // Restituisci un Observable vuoto in caso di errore
        }

        // Chiamata al servizio per trovare i contratti con le causali di assenza
        return this.CalendarContractService.findContractsByUserAndDateRange(requestData.UserId, requestData.startDate, requestData.endDate)
            .pipe(
                tap((response: any) => {
                    // Gestisci la risposta del servizio
                    // console.log('Causali ricevute:', response);

                }),
                catchError((error: any) => {
                    // Usa console.warn invece di console.error
                    console.warn('Warning: Problema nella chiamata al servizio:', error);
                    return of([]); // Restituisce un array vuoto in caso di errore per mantenere il flusso
                })
            );
    }




    ngOnInit(): void {



        this.getUsers();


        this.form = this.fb.group({
            UserId: [null, [Validators.required]],
            startDate: [null, [Validators.required]],
            endDate: [null, [Validators.required]],
            workCausalDocuments: this.fb.array([]), // FormArray per i documenti
            // documentUpload: [null, [Validators.required]],
            hours: [null, [Validators.required]],
            description: [null],
            WorkCausalId: [null, [Validators.required]],
        },);
        // this.populateWorkCausalDocuments();

        // Ascolta i cambiamenti del campo WorkCausalId per resettare i documenti
        this.form.get('WorkCausalId')?.valueChanges.subscribe(() => {
            this.resetSelectedDocuments();
        });

        this.id = this.route?.snapshot?.paramMap?.get('id');
        // console.log(this.id)
        if (['0', 0, null, undefined].includes(this.id)) {
            this.formTitle = 'Aggiungi assenza';
            combineLatest([
                this.form.get('UserId')!.valueChanges,
                this.form.get('startDate')!.valueChanges,
                this.form.get('endDate')!.valueChanges,
            ]).subscribe(([user, startDate, endDate]) => {
                // Quando la startDate cambia, resetta il campo endDate
                if (user && startDate && endDate) {

                    // Se i tre campi sono popolati, chiama il servizio
                    this.getWorkCausalFromContract(user, startDate, endDate).subscribe({
                        next: (response) => {
                            // console.log('response', response)
                            if (response && response.length > 0) {
                                this.boxDocumentBool = true;
                                this.boxDocumentEmptyBool = false;
                                this.contractDescription = response[0].description || ''; // Prende la descrizione del primo contratto
                                this.configurationCalendarId = response[0].id;
                                // this.WorkCausalDocumentTypeId = response[0].WorkCausals.Documents.id;
                                // console.log('WorkCausalDocumentTypeId: ', this.WorkCausalDocumentTypeId)

                                // Mappa l'array di response per ottenere un array di oggetti con struttura {name: causalCode, id: id, documents: []}
                                this.workCausals = response?.flatMap((contract: any) =>
                                    contract.workCausals.map((workCausal: any) => ({
                                        name: workCausal.causalCode,
                                        id: workCausal.id,
                                        documents: workCausal.Documents || [], // Associa i documenti
                                    }))
                                );
                            } else if (response.message === 'Nessun contratto trovato per i criteri specificati.') {
                                this.boxDocumentEmptyBool = true;
                                this.boxDocumentBool = false;

                                // Se la response contiene il messaggio specificato
                                this.contractDescription = response.message;
                            };

                            // console.log('Descrizione contratto:', this.contractDescription);
                            // console.log("workCausals: ", this.workCausals)
                        },
                        error: (error) => {
                            console.error('Errore durante il recupero delle causali di lavoro:', error);
                            // Gestisci l'errore qui (ad esempio mostrare un messaggio di errore)
                        }
                    });
                }
            });
        } else {
            this.formTitle = 'Modifica assenza';
            this.boxDocumentBool = true;
            this.boxDocumentEmptyBool = false;
            this.initForm();  // Aggiungi logica per la modifica di un contratto esistente
        }



        // Solo dopo aver ricevuto i workCausals esegui i filtri o altre operazioni
        this.operatorSearchControl.valueChanges.subscribe(value => {
            this.filteredUsers = this.operatorAvailable.filter((causal: any) =>
                !value || causal?.name.toLowerCase().includes(value.toLowerCase())
            );
        });

        this.form.get('startDate')?.valueChanges.subscribe((value: any) => {
            // console.log('startDate: ', value)
            this.minEndDate = value;
        })

        // this.libService.lockPage('');


    }


    resetSelectedDocuments(): void {
        // Resetta il FormArray dei documenti
        const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;
        workCausalDocumentsArray.clear(); // Pulisce l'array dei documenti

        // Resetta l'array selectedFiles
        this.selectedFiles = {};
    }

    // // Popola il FormArray con i documenti
    // populateWorkCausalDocuments() {
    //     const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;

    //     this.selectedWorkCausalDocuments.forEach(document => {
    //         workCausalDocumentsArray.push(
    //             this.fb.group({
    //                 documentId: [document.id],  // ID del documento
    //                 fileName: ['', Validators.required],  // Nome del file caricato (inizialmente vuoto)
    //                 fileContent: ['']
    //             })
    //         );
    //     });
    // }


    onFileSelected(event: any, index: number) {
        const file: File = event.target.files[0];
        if (!file) {
            console.error('Nessun file selezionato.');
            return;
        }

        // console.log('File selezionato:', file);

        // Aggiungi il file selezionato nell'oggetto uploadedFiles
        this.uploadedFiles[this.selectedWorkCausalDocuments[index].id] = file;
        this.selectedFiles[index] = file;  // Per mostrare il nome del file

        // Aggiorna il FormArray con il nome del file selezionato
        const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;
        workCausalDocumentsArray.at(index).get('fileName')?.setValue(file.name);

        // Leggi il file come base64 e aggiungi il campo 'fileContent' solo per il payload
        const reader = new FileReader();
        reader.onload = () => {
            // console.log('reader: ', reader)
            const base64String = reader.result as string;
            // console.log('base64', base64String)

            // Aggiungi il contenuto base64 al payload (campo fileContent, solo per il payload)
            const formValue = workCausalDocumentsArray.at(index).value;
            formValue['fileContent'] = base64String;

            // Sostituisci il valore nel FormArray con il nuovo valore che include fileContent
            workCausalDocumentsArray.at(index).setValue(formValue);
        };

        reader.onerror = (error) => {
            console.error('Errore durante la lettura del file:', error);
        };

        // Leggi il file come base64
        reader.readAsDataURL(file);

        // Controlla la validità del form
        this.checkFormValidity();
    }




    checkFormValidity() {
        const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;

        // Controlla se ogni elemento del FormArray ha un fileName valido
        const allDocumentsUploaded = workCausalDocumentsArray.controls.every(control => control.get('fileName')?.valid);

        if (allDocumentsUploaded) {
            this.form.get('workCausalDocuments')?.setErrors(null); // Nessun errore, form valido
        } else {
            this.form.get('workCausalDocuments')?.setErrors({ required: true }); // Form non valido
        }
    }


    onWorkCausalChange(workCausalId: number) {
        // console.log('WorkCausalId selezionato:', workCausalId);

        // Trova la causale selezionata dall'array workCausals
        const selectedCausal: any = this.workCausals.find((causal: any) => causal.id === workCausalId);
        // console.log('Causale selezionata:', selectedCausal);

        if (selectedCausal) {
            // Verifica la presenza di documenti associati alla causale
            this.selectedWorkCausalDocuments = selectedCausal.documents && selectedCausal.documents.length > 0
                ? selectedCausal.documents.map((doc: any) => ({ id: doc.id, label: doc.label }))
                : [];

            // console.log('Documenti della causale selezionata:', this.selectedWorkCausalDocuments);

            // Resetta e popola il FormArray per i documenti
            const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;
            workCausalDocumentsArray.clear(); // Pulisci il FormArray prima di aggiungere nuovi documenti
            // console.log('FormArray ripulito, inizio popolamento...');

            // Aggiungi un controllo per ciascun documento della causale selezionata
            this.selectedWorkCausalDocuments.forEach(document => {
                // console.log('Aggiungo documento al FormArray:', document);
                workCausalDocumentsArray.push(
                    this.fb.group({
                        documentId: [document.id],  // ID del documento
                        fileName: ['', Validators.required],  // Nome del file caricato (inizialmente vuoto)
                        fileContent: [null]
                    })
                );
            });

            // console.log('FormArray popolato con i documenti:', workCausalDocumentsArray.value);
        } else {
            console.log('Nessuna causale selezionata o nessun documento associato.');
            this.selectedWorkCausalDocuments = [];
        }
    }




    isFileSelected(index: number): boolean {
        const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;
        return workCausalDocumentsArray.at(index).get('fileName')?.valid || false;
    }


    initForm() {
        // Chiama il servizio per ottenere i dati del modulo di assenza con l'ID specificato
        this.AbsenceModuleService.settings(this.id).subscribe((response: any) => {
            if (response && response.absenceModule) {
                const module = response.absenceModule;
                this.contractDescription = module.ConfigurationCalendar.description || ''; // Prende la descrizione del primo contratto


                // Popola la descrizione del contratto (ConfigurationCalendar)
                this.contractDescription = module.ConfigurationCalendar.description || '';

                // Popola la descrizione del contratto (ConfigurationCalendar)
                this.contractDescription = module.ConfigurationCalendar.description || '';

                // Popola i campi del form con i dati ricevuti
                this.form?.get('description')?.setValue(module.description);
                this.form?.get('hours')?.setValue(module.hours);
                this.form?.get('startDate')?.setValue(module?.startDate ? new Date(module.startDate) : null);
                this.form?.get('endDate')?.setValue(module?.endDate ? new Date(module.endDate) : null);
                this.form?.get('UserId')?.setValue(module.UserId);
                this.form?.get('WorkCausalId')?.setValue(module.WorkCausalId);

                // Pulisce eventuali documenti precedenti nel FormArray
                const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;
                workCausalDocumentsArray.clear();

                // Popolamento dei documenti relativi alle causali di lavoro
                if (module.AbsenceDocument && module.AbsenceDocument.length > 0) {
                    // Passa l'array di AbsenceDocument
                    this.setWorkCausalDocuments(module.WorkCausalId, module.AbsenceDocument);
                    console.log('Popolazione documenti');
                } else {
                    console.log('Nessun documento associato');
                }

                // Assegna il valore del ConfigurationCalendarId
                this.configurationCalendarId = module.ConfigurationCalendarId;

                // Popola i `workCausals` nel form con id e causalCode
                if (response.workCausals && response.workCausals.length > 0) {
                    // Mappa gli id e i causalCode per visualizzazione o utilizzo futuro
                    const workCausals = response.workCausals.map((causal: any) => ({
                        id: causal.id,
                        name: causal.causalCode,
                        documents: causal.documents || [], // Associa i documenti

                    }));
                    // Aggiorna un campo nel form se esiste o crea un campo visivo separato
                    this.form?.get('workCausals')?.setValue(workCausals);
                    this.workCausals = workCausals;  // Popola la variabile `workCausals` con gli id e causalCode
                }

                // console.log(this.form);
            } else {
                console.error('Errore: Nessuna risposta dal server per il modulo di assenza con ID:', this.id);
            }
        }, error => {
            console.error('Errore durante il recupero dei dati del modulo di assenza:', error);
        });
    }





    setWorkCausalDocuments(workCausalId: number, absenceDocuments: any[]): void {
        const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;

        // Pulisci eventuali dati precedenti
        workCausalDocumentsArray.clear();

        // Verifica se absenceDocuments contiene dati validi
        // console.log('AbsenceDocuments:', absenceDocuments);

        if (absenceDocuments.length > 0) {
            // Aggiungi ogni documento nel FormArray
            this.selectedWorkCausalDocuments = absenceDocuments.map(doc => ({
                id: doc.WorkCausalDocumentTypeId,
                name: doc.name,
                label: doc.label // Usa il campo 'label' dal payload
            }));

            absenceDocuments.forEach((doc, index) => {
                // console.log(`Elaborando documento ${index + 1}:`, doc);

                const documentGroup = this.fb.group({
                    documentId: [doc.WorkCausalDocumentTypeId],  // ID del documento
                    fileName: [doc.name, Validators.required],   // Nome del file preso da AbsenceDocument
                    fileContent: ['']  // Vuoto inizialmente, sarà popolato in fase di upload
                });

                // Aggiungi il documento al FormArray
                workCausalDocumentsArray.push(documentGroup);

                // Verifica il gruppo aggiunto
                // console.log(`Documento ${index + 1} aggiunto:`, documentGroup.value);
            });

            // Mostra lo stato finale del FormArray
            // console.log('FormArray finale:', workCausalDocumentsArray.value);
        } else {
            console.log(`Nessun documento trovato per la WorkCausalId: ${workCausalId}`);
        }
    }








    back() {
        this.router.navigate([`settings/absence-modules`]);
    }


    onSave(): void {
        // Estrai i documenti caricati dal form array
        const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;
        const workCausalDocuments = workCausalDocumentsArray.value.map((document: any, index: number) => {
            const file = this.selectedFiles[index]; // Ottieni il file selezionato
            return {
                ...document,
                type: file ? file.type : null  // Aggiungi il tipo di file (MIME type) nel payload solo se esiste un file
            };
        });

        // Costruzione del payload finale
        const payload = {
            ...this.form.value,
            ConfigurationCalendarId: this.configurationCalendarId,  // Aggiungi il valore dell'ID al payload
            workCausalDocuments, // Aggiungi i documenti con il tipo di file incluso
        };

        // console.log('Payload finale:', payload);

        // Ottieni l'ID dal route (se esiste, eseguire un aggiornamento)
        const moduleId = this.route.snapshot.paramMap.get('id'); // Estrai l'ID dall'URL
        // console.log('id: ', moduleId);

        if (moduleId && moduleId !== '0') {
            // Aggiorna un modulo esistente
            this.AbsenceModuleService.update(moduleId, payload).subscribe(
                (response: any) => {
                    this.toastr.success('Modulo aggiornato con successo', this.translate.instant('LABEL.Info'));
                    this.libService.unlockPage();
                    this.back(); // Torna alla pagina precedente o alla lista
                },
                (error: any) => {
                    this.toastr.error('Errore durante l\'aggiornamento del modulo', this.translate.instant('LABEL.Error'));
                    this.libService.unlockPage();
                }
            );
        } else {
            // Crea un nuovo modulo di assenza
            this.AbsenceModuleService.create(payload).subscribe(
                (response: any) => {
                    this.toastr.success('Nuovo modulo creato con successo', this.translate.instant('LABEL.Info'));
                    this.libService.unlockPage();
                    this.back(); // Torna alla pagina precedente o alla lista
                },
                (error: any) => {
                    this.toastr.error('Errore durante la creazione del modulo', this.translate.instant('LABEL.Error'));
                    this.libService.unlockPage();
                }
            );
        }
    }




}
