<br />

<div class="full-w">
  <form
    [formGroup]="form"
    (submit)="sendNotificationSettings()"
    class="form-container"
  >
    <div class="button-group form-subject" style="text-align: center">
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="openUserModal('internal')"
      >
        Aggiungi Utenti
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="openUserModal('external')"
      >
        Aggiungi Utenti Esterni
      </button>
    </div>

    <mat-card appearance="fill" class="form-subject">
      <mat-card-header>
        <mat-card-title>Lista destinatari (Interni + Esterni)</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-chip-list aria-label="Fish selection">
          <mat-chip
            [color]="getColor(user)"
            selected
            *ngFor="let user of usersSelected"
            (removed)="removeUser(user)"
          >
            {{ user.name }} {{ user.surname }} <{{ user.email }}>
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </mat-card-content>
    </mat-card>

    <br />

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ "LABEL.canale" | translate }}</mat-label>
      <mat-select formControlName="notificationChannels" multiple>
        <mat-option
          *ngFor="let channel of notificationChannels"
          [value]="channel.code"
          [disabled]="channel.disabled"
        >
          {{ channel.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ "LABEL.title" | translate }}</mat-label>
      <input
        matInput
        formControlName="title"
        [placeholder]="translate.instant('LABEL.title')"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ "LABEL.body" | translate }}</mat-label>
      <textarea
        rows="20"
        matInput
        [placeholder]="translate.instant('LABEL.body')"
        formControlName="body"
      ></textarea>
    </mat-form-field>

    <div class="buttonBox">
      <button
        [disabled]="!form.valid"
        class="submitButton"
        type="submit"
        mat-raised-button
        color="primary"
      >
        <mat-icon>send</mat-icon> {{ "LABEL.invia" | translate }}
      </button>
    </div>
    <br />
  </form>
</div>
