<h1 mat-dialog-title>{{ "LABEL.addSignaler" | translate }}</h1>

<div mat-dialog-content>


  <form [formGroup]="form" class="form-container">

    <div class="form-subject">
      <mat-radio-group formControlName="type" name="type">
        <mat-radio-button style="margin-right:20px;" value="users" name="users">
          {{ "LABEL.searchIntenalUser" | translate }}
        </mat-radio-button>
        <mat-radio-button style="margin-right:20px;" value="subjects" name="subjects">
          {{ "LABEL.searchSubject" | translate }}
        </mat-radio-button>
        <mat-radio-button style="margin-right:20px;" value="addSubjects" name="addSubjects">
          {{ "LABEL.addSubject" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

<!-- RICERCA UTENTE/SOGGETTO -->
    <mat-card class="search-card" *ngIf="isUserType() || isSubjectType()">
      <mat-card-title>{{ "LABEL.signalerSearch" | translate }}</mat-card-title>
      <mat-card-content class="search-container">

        <div style="width: 100%;" appearance="fill" class="flex50">
          <input formControlName="kwSearchName" placeholder="Nome Segnalatore" type="text" class="searchBox">
        </div>

        <div style="width: 100%;" appearance="fill" class="flex50">
          <input formControlName="kwSearchSurname" placeholder="Cognome Segnalatore" type="text" class="searchBox">
        </div>

        <div *ngIf="isSubjectType()" style="width: 100%;" appearance="fill" class="flex50">
          <input formControlName="kwSearchLegalEntity" placeholder="Entità Legale Segnalatore" type="text"
            class="searchBox">
        </div>

        <div style="width: 100%;" appearance="fill" [ngClass]="isUserType() ? 'flex100' : 'flex50'">
          <input formControlName="kwSearchEmail" placeholder="Email Segnalatore" type="text" class="searchBox">
        </div>

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="button" mat-button (click)="searchUsers()"
          class="btn clsButtonDefault btnSearch">
          {{
          'LABEL.SEARCH' | translate }}</button>
      </mat-card-actions>
    </mat-card>

    <!-- AGGIUNTA SOGGETTO -->
    <mat-card class="search-card" *ngIf="isAddSubjectType()">
      <mat-card-title>{{ "LABEL.newSignalerAdd" | translate }}</mat-card-title>
      <mat-card-content class="search-container">

        <app-crm-form style="width:100%;" [mainForm]="form" class="subjectFormBox" [documentsSection]="false" [companies]="[]" [subjectAttributes]="subjectAttributes"></app-crm-form>

       

      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button type="button" mat-button (click)="addSubject()" [disabled]="!this.form.get('subject').valid"
          class="btn clsButtonDefault btnSearch">
          {{
          'LABEL.CREATE' | translate }}</button>
      </mat-card-actions>
    </mat-card>


    <mat-form-field *ngIf="isUserType() || isSubjectType()" style="width: 100%;" appearance="fill" class="form-activities">
      <mat-label>{{ 'LABEL.signaler' | translate }}</mat-label>
      <mat-select #selectSignalers matNativeControl formControlName="signalerId" (selectionChange)="setSignaler($event)" >
        <mat-option *ngFor="let item of searchResults" [value]="item.id">
          {{ getFullname(item) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="disabledConfirmButton()">{{ confirmButtonLabel |
    translate }}</button>
  <button mat-button [mat-dialog-close]="">{{ cancelButtonLabel | translate }}</button>
</div>