
export class Utils {

    checkStatus(changes: any, statusFrom: any, statusTo: any) {
        for (let a = 0; a < changes.changes.length; a++) {
            if (changes.changes[a].from == statusFrom && changes.changes[a].to == statusTo) {
                return true;
            }
        }
        return false;
    }
}