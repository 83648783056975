import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class CalendarContractService extends BaseService {

  constructor(
    public LibService: LibService,
    public AuthService: AuthService,
    public userSessionService: UserSessionService,
    public guardService: GuardService
  ) {
    super(LibService, AuthService)
    this.baseApi = environment.rootApiFoundation
    this.model = 'configurationCalendars'
  }
  settings(id: any): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/${id}/settings`);
  }

  // Metodo per creare un nuovo contratto
  createSettings(data: any): Observable<any> {
    return this.libService.postDataWithAccessToken<any>(this.tokenId, data, this.baseApi, this.model);
  }

  // Metodo per aggiornare un contratto esistente
  updateSettings(id: string, payload: any): Observable<any> {
    return this.libService.putDataWithAccessToken<any>(this.tokenId, payload, this.baseApi, `${this.model}/${id}`);
}

  // Metodo per ottenere i contratti con paginazione
  getContractsWithPagination(filters: any, skip: number, take: number): Observable<any> {
    const params = {filters, skip, take };
    return this.libService.postDataWithAccessToken<any>(this.tokenId, params,  this.baseApi, `${this.model}/configurationCalendarWithPagination`);
  }

  findContractsByUserAndDateRange(userId: number, startDate: string, endDate: string): Observable<any> {
    const payload = { userId, startDate, endDate };
    return this.libService.postDataWithAccessToken<any>(this.tokenId, payload, this.baseApi, `${this.model}/findContractsByUserAndDateRange`)
      .pipe(
        catchError((error) => {
          console.error('Errore nel recupero dei contratti per userId e date:', error);
          return throwError(error);
        })
      );
  }
}