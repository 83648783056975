<div class="fileTable">
  <app-data-table
  [data]="documentTypes || []"
  [displayedColumns]="displayedColumns"
  [columns]="columns"
  [actions]="actions"
  [hasPagination]="false"
  [hasHeader]="true"
  >
</app-data-table>
</div>

