<ng-container>
    <button *ngFor="let button of buttons" [matTooltip]="button?.buttonTranslationCode[getCurrentLanguage()]" type="button"
        [ngClass]="button.class" class="btn mx-2" (click)="buttonAction(button)"
        [disabled]="disabledButton(button)"
        >
        {{ button?.buttonTranslationCode[getCurrentLanguage()] }}
    </button>
</ng-container>


