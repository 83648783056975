<!-- SUBJECTS -->
<div *ngIf="sectionType == 'subjects'">
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
        <app-subject></app-subject>
    </div>
</div>

<!-- REQUESTS -->
<div *ngIf="sectionType == 'requests'">
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
        <mat-tab-group class="wbck" animationDuration="300ms"
            #tabGroup>

            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
                    <span class="ml-1">{{ 'LABEL.REQUESTS' | translate }}</span>
                </ng-template>
                <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                    <app-request-list-new *ngIf="selectedTabIndex === 1"></app-request-list-new>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="my-bounce material-icons">report</mat-icon>
                    <span class="ml-1">{{ 'LABEL.SOLICITS' | translate }}</span>
                </ng-template>
                <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
                    <app-solicit-list *ngIf="selectedTabIndex === 1"></app-solicit-list>
                </div>
            </mat-tab>

        </mat-tab-group>

    </div>
</div>

<!-- ACTIVITIES -->
<mat-tab *ngIf="sectionType == 'activities'">

    <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">settings</mat-icon>
        <span class="ml-1">{{ 'LABEL.ACTIVITIES' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl('CRMACT')==null) && wikiService.getWikiUrl('CRMACT').length > 0"
            (click)="goToWikiByKey('CRMACT')" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

        <app-activities *ngIf="selectedTabIndex === 2"></app-activities>

    </div>
</mat-tab>

<!-- CLAIMS -->
<mat-tab *ngIf="sectionType == 'claims'">
    <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">report</mat-icon>
        <span class="ml-1">{{ 'LABEL.CLAIMS' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl('CRMCLAIMS')==null) && wikiService.getWikiUrl('CRMCLAIMS').length > 0"
            class="crs" (click)="goToWikiByKey('CRMCLAIMS')" matTooltip="AlbertWiki"
            class="material-icons icw">help_outline</i>
    </ng-template>
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
        <app-claim-workflow *ngIf="selectedTabIndex === 3"></app-claim-workflow>
    </div>
</mat-tab>