import { OrderPhases, Task } from 'src/app/final-balancer/models/final-balance';
import { UserRole } from './userRole';
import { TeamLeaderTask } from './teamLeader-task';

export class PhasePlan {
  public id: number;
  public idMacroPhase: number;
  public qtToProduct: number;
  public qtOreManodopera: number;
  public descPhase: string;
  public freeDescription: string;
  public isGaranzia: boolean;
  public tasks: Task[];

  get hasTasks(): boolean {
    return this.tasks.length > 0;
  }

  get totalHours(): number {
    if (!this.tasks.length) return 0;
    return (
      this.tasks?.map((task) => task.amountHours)?.reduce((a, b) => a + b) ?? 0
    );
  }

  get hoursToPlan(): number {
    const hoursToPlan = Math.floor(this.qtOreManodopera - this.totalHours);
    return hoursToPlan > 0 ? hoursToPlan : 0;
  }

  constructor({
    id,
    idMacroPhase,
    qtToProduct,
    qtOreManodopera,
    descPhase,
    freeDescription,
    isGaranzia,
    OrdersPhasesTasksRel,
  }: OrderPhases) {
    this.id = id;
    this.idMacroPhase = idMacroPhase;
    this.qtToProduct = qtToProduct;
    this.qtOreManodopera = qtOreManodopera;
    this.descPhase = descPhase;
    this.freeDescription = freeDescription;
    this.isGaranzia = isGaranzia;
    this.tasks = OrdersPhasesTasksRel;
  }

  public getTeamLeader(options?: { multi: boolean }): TeamLeaderTask | null {
    const roleId = options?.multi ? UserRole.MULTI : UserRole.TEAMLEADER;
    const teamleader = this.tasks
      .sort((a, b) => (a.userName > b.userName ? 1 : -1))
      .find((task: Task) => task.userRoleId === roleId);
    const startDate = this.tasks[0]?.tsStart ?? teamleader?.tsStart;

    if (teamleader) {
      return new TeamLeaderTask(teamleader, startDate);
    }
    return null;
  }

  public getTeamLeaders(options?: { multi: boolean }): TeamLeaderTask[] {
    const roleId = options?.multi ? UserRole.MULTI : UserRole.TEAMLEADER;
    const teamleaders = this.tasks.filter(
      (task: Task) => task.userRoleId === roleId
    );
    const startDate = this.tasks[0]?.tsStart ?? teamleaders[0]?.tsStart;

    if (teamleaders.length) {
      return teamleaders.map(
        (teamleader) => new TeamLeaderTask(teamleader, startDate)
      );
    }
    return [];
  }
}
