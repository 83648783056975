import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DataSet } from '../models/dataset';

@Injectable({
  providedIn: 'root'
})
export class DataSetService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
  ) { }

  getAllDataSets(): Observable<any> {
    return this.libService.getData<any>(this.baseApi + 'datasets', '');
  }

  getDataSet(id: string) {
    return this.libService.getData<any>(this.baseApi + 'datasets/', id.toString());
  }

  createOne(dataset: DataSet){
    return this.libService.postData<any>(dataset, this.baseApi + 'datasets', '')
  }

  updateOne(dataset: DataSet){
    return this.libService.putData<any>(dataset, this.baseApi + 'datasets/', dataset.id!.toString())
  }

  validateSqlString(sqlString: string){
    return this.libService.postData<any>({sqlString: sqlString}, this.baseApi + 'datasets/', 'validateSqlString')
  }

  applySqlString(dataset: DataSet){
    return this.libService.postData<any>(dataset, this.baseApi + 'datasets/', 'applySqlString')
  }
}
