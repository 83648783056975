import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class PlannerFullPageService {

  private isPlannerFullPage: boolean = false;

  constructor() { }

  public setPlannerFullPage(value: boolean): void {
    this.isPlannerFullPage = value;
  }

  public getPlannerFullPageValue(): boolean {
    return this.isPlannerFullPage;
  }
}
