<div class="container-fluid my-container">
  <h1>Configurazione stati del Workflow</h1>


  <br>


  <div style="overflow-x: auto;">

    <app-settings-data-table [data]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns"
      [actions]="actions" sortActive=""></app-settings-data-table>


  </div>

</div>


<ng-template #itemDialog>
  <app-action-form></app-action-form>
</ng-template>