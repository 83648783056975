<!-- <div class="container-fluid my-container"> -->
<mat-tab-group class="wbck" animationDuration="300ms" #tabGroup (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedTabIndex">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">email</mat-icon>
      <span class="ml-1">{{ 'LABEL.PECS' | translate }}</span>
    </ng-template>
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

      <app-pecs *ngIf="selectedTabIndex === 0" [itemId]="getItemId(0,initSelectedTabIndex)"></app-pecs>

    </div>
  </mat-tab>


  <mat-tab>

    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">pending_actions</mat-icon>
      <span class="ml-1">{{ 'LABEL.GARANZIE' | translate }}</span>
    </ng-template>
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

      <app-complaints *ngIf="selectedTabIndex === 1" [itemId]="getItemId(1,initSelectedTabIndex)"></app-complaints>

    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">report</mat-icon>
      <span class="ml-1">{{ 'LABEL.CLAIMS' | translate }}</span>
    </ng-template>
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

      <app-claims *ngIf="selectedTabIndex === 2" [itemId]="getItemId(2,initSelectedTabIndex)"></app-claims>

    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">policy</mat-icon>
      <span class="ml-1">{{ 'LABEL.LEGAL' | translate }}</span>
    </ng-template>
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

      <app-legals *ngIf="selectedTabIndex === 3" [itemId]="getItemId(3,initSelectedTabIndex)"></app-legals>

    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="my-bounce material-icons">paid</mat-icon>
      <span class="ml-1">{{ 'LABEL.TRANSACTIONS' | translate }}</span>
    </ng-template>
    <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

      <app-transactions *ngIf="selectedTabIndex === 4" [itemId]="getItemId(4,initSelectedTabIndex)"></app-transactions>

    </div>
  </mat-tab>

</mat-tab-group>

<!-- </div> -->