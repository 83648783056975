import { Injectable } from '@angular/core';
import { Observable, tap, iif, first } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Controller } from '../models/controller';
import { LibService } from './libService';
import { StreamList } from '../models/store';

@Injectable({
  providedIn: 'root',
})
export class ControllerService {
  baseApi = environment.rootApiFoundation + 'controllers';
  controllers: StreamList<any> = new StreamList<any>('controllers', []);

  constructor(public libService: LibService) {}

  getController(id: string) {
    return this.libService.getData<any>(this.baseApi, `/${id.toString()}`);
  }

  getAllControllers(refresh?: boolean): Observable<Controller[]> {
    const storedControllers = this.controllers.$stream;
    const isLoaded = this.controllers.length > 0 && !refresh;
    const $getControllers = this.libService
      .getData<any>(this.baseApi, '')
      .pipe(tap((controllers: any) => this.controllers.save(controllers)));
    return iif(() => isLoaded, storedControllers, $getControllers).pipe(
      first()
    );
  }
}
