import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Licence } from '../models/licence';
import { LicenceService } from '../services/licenseService';
import { DatatableAction, DatatableColumn } from '../shared/data-table/data-table.component';
import { of } from "rxjs";
import { LibService } from '../services/libService';
import { NavService } from '../services/nav.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-licence',
  templateUrl: './admin-licence.component.html',
  styleUrls: ['./admin-licence.component.scss']
})
export class AdminLicenceComponent implements OnInit {

  codeKwSearch:string;
  allLicences: Licence[];

  displayedColumns = ['id', 'code', 'startDate', 'endDate', 'semaphore','type', 'numberOfUsers', 'cost','isOpen', 'isUsed','isActive'];
  columns: DatatableColumn<Licence>[] = [
    {
      name: 'id',
      flex: '5',
      title: this.translate.get('LABEL.ID'),
      cssClass: licence => ['column-padding'],
      value: licence => licence.id
    },
    {
      name: 'code',
      title: this.translate.get('LABEL.CODE'),
      cssClass: licence => ['column-padding', 'bold-text'],
      value: licence => licence.code
    },
    {
      name: 'startDate',
      title: this.translate.get('LABEL.V_START_DATE'),
      cssClass: licence => ['column-padding'],
      value: licence => this.datePipe.transform(licence.startDate)
    },
    {
      name: 'endDate',
      title: this.translate.get('LABEL.V_END_DATE'),
      cssClass: licence => ['column-padding'],
      value: licence => this.datePipe.transform(licence.endDate)
    },
    {
      name: 'type',
      title: this.translate.get('LABEL.TYPE'),
      cssClass: licence => ['column-padding'],
      value: licence => this.translate.instant(`OTHER.${licence.type}`)
    },
    {
      name: 'numberOfUsers',
      title: this.translate.get('LABEL.NUMBER_OF_USERS'),
      cssClass: licence => ['column-padding'],
      value: licence => licence.numberOfUsers
    },
    {
      name: 'cost',
      title: this.translate.get('LABEL.LICENCE_COST'),
      cssClass: licence => ['column-padding'],
      value: licence => licence.cost
    },
    {
      name: 'isOpen',
      title: this.translate.get('LABEL.LICENCE_OPEN'),
      type: 'icon',
      cssClass: licence => ['column-padding'],
      value: licence => licence.isOpen?'check_circle_outline':'close'
    },
    {
      name: 'isUsed',
      title: this.translate.get('LABEL.USED'),
      type: 'icon',
      cssClass: licence => ['column-padding'],
      value: licence => licence.isUsed?'check_circle_outline':'close'
    },
    {
      name: 'isActive',
      title: this.translate.get('LABEL.ACTIVE'),
      type: 'icon',
      cssClass: licence => ['column-padding'],
      value: licence => licence.isUsed?'check_circle_outline':'close'
    },
    {
      name: 'semaphore',
      type: 'icon',
      title: of('')/* this.translate.get('LABEL.Semaphore') */,
      cssClass: licence => {
        const date = new Date(licence.endDate!)
        const remainingDays = this.calculateDiffInDays(date)
        if(remainingDays > 30){
          return ['column-padding','green']
        }else if(remainingDays > 0){
          return ['column-padding','yellow']
        }else{
          return ['column-padding','red']
        }
      },
      value: licence => {
        const date = new Date(licence.endDate!)
        const remainingDays = this.calculateDiffInDays(date)
        return remainingDays > 30 ? 'check_circle_outline':'error_outline'
      }
    }
  ]
  actions: DatatableAction<Licence>[] = [
    {
      label: of(''),
      icon: subject => {
        //'edit',
        return 'edit'
      },
      onClick: licence => this.goTo(licence),
      color: 'primary'
    },
    {
      label: of(''),
      icon: subject => {
        //'delete',
        return 'delete'
      },
      disabled: licence => true,
      onClick: licence => this.deleteRow(licence),
      color: 'primary'
    }
  ];
  dataSource: any;

  constructor(
    private licenseService: LicenceService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private router: Router,
    private libService: LibService,
    private navService: NavService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loadLicenze()
    this.setNavBar()
  }

  loadLicenze(): void {

    this.libService.lockPage('');
    this.licenseService.getAllLicense().subscribe((response) => {
      console.log('loadLicenze response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.allLicences = response;
        this.dataSource = response;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
  }

  deleteRow(licence: Licence){
    this.libService.lockPage('')
  }

  searchByCode(kw: any){
    console.log('searchbycode',kw);
    this.dataSource = this.allLicences.filter(licence => licence.code?.includes(kw) )
  }

  filterResultEmitted(event: Licence[]){
    if(event !== null){
      this.dataSource = event
    }else{
      this.toastr.warning(this.translate.instant('TOASTR.MESSAGE_2'))
      this.dataSource = this.allLicences
    }
  }

  setNavBar(){
    this.translate.get('LABEL.LICENCES').subscribe(res => {
      this.navService.setTitle(res);
      this.navService.setSubTitle("EDAC - " + res);
    })

    let actions = [
      {name: 'new', url: 'ad-license/0'}
    ];
    this.navService.setActions(actions);
  }

  calculateDiffInDays(date: Date){
    let currentDate = new Date();
    let days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }

  goTo(e: any) {
    this.router.navigate(['ad-license/' + e.id]);
  }

}
