import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, map, tap, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Company } from 'src/app/models/company';
import { Status } from 'src/app/models/status';
import { SubjectType } from 'src/app/models/subjectType';
import { User } from 'src/app/models/user';
import { WorkType } from 'src/app/models/workType';
import { BuildingService } from 'src/app/services/building.service';
import { ChannelService } from 'src/app/services/channel.service';
import { CompanyService } from 'src/app/services/companyService';
import { FTIndexAllService } from 'src/app/services/ftindex-all.service';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { WorkTypeService } from 'src/app/services/worktype.service';
import { ComponentService } from 'src/app/services/component.service';
import { FinanceService } from 'src/app/services/finance.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { CountryService } from 'src/app/services/country.service'

@Component({
  selector: 'app-association-building-v2',
  templateUrl: './association-building-v2.component.html',
  styleUrls: ['./association-building-v2.component.scss']
})
export class AssociationBuildingV2Component implements OnInit, OnDestroy {

  @Input() form: UntypedFormGroup;
  @Input() types: any
  @Input() origins: any;
  @Input() edit: boolean;
  @Input() isNew: boolean;
  @Input() components: any;

  geoAreaNotFound: any = false

  attributes: any;

  forceLocationSelection: any = false;
  allCompanies: any = []

  componentName: any = 'request_building_box'


  statusTemporary: Status
  controlTypes = ['Privato', 'Amministratore'];
  hideLegalEntity: boolean = true;
  workTypes: any;
  wt: any;

  contactChannels$ = this.channelService.getAllChannels();

  compare(a: any, b: any) {
    if (a.code < b.code) {
      return -1;
    }
    if (a.code > b.code) {
      return 1;
    }
    return 0;
  }

  getCompaniesbyBusinessLineId(businessLineId: any = null) {
    let businessLineIdValue = businessLineId || this.form.get('businessLineId')?.value
    this.countryService.getCompaniesbyBusinessLineId(businessLineIdValue).subscribe((response) => {
      if ([undefined, null].includes(this.form.get('building.company')?.value)) {
        this.companies = response.sort(this.compare)
      } else {
        this.companies = this.allCompanies
      }
    });
  }

  getSubjectType() {
    // Ricerca Subject Type Abilitati
    //this.libService.lockPage('');
    let currentCompanyId: any = this.form?.get('companyId')?.value
    this.form?.get('subjectTypeArray')?.setValue([]) // Reset
    if(currentCompanyId){
      this.subjectService.getTypesByCompanyId(currentCompanyId).subscribe((response: any) => {
        //this.libService.unlockPage();
        this.form?.get('subjectTypeArray')?.setValue(response)
  
      })
    }

  }


  companies: Company[]

  companies$ = this.companyService.getAllCompaniesForRequest().pipe(
    map((companies: any[]) => {
      return companies.sort((a, b) => a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      this.companies = [...new Set(companies)]
      this.companies.push({ id: 0, code: undefined, name: '' })
      this.allCompanies = this.companies
    })
  );

  workTypes$ = this.workTypeService.getAllWorkTypes()//.pipe(
  // map(workTypes => {
  //   return workTypes.filter(workType => workType.codLanguage?.toLocaleLowerCase() === this.form.get('building.company')?.value)//this.translate.currentLang
  // })
  //)

  constructor(
    public subjectService: SubjectService,
    public buildingService: BuildingService,
    private companyService: CompanyService,
    private workTypeService: WorkTypeService,
    private statusService: StatusService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private channelService: ChannelService,
    private componentService: ComponentService,
    private userSessionService: UserSessionService,
    private financeService: FinanceService,
    private countryService: CountryService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.addValidations()
    this.setEditabilityOfFields()

    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.types !== null && this.types !== undefined)
      this.types.sort((a: any, b: any) => a.description!.toUpperCase() <= b.description!.toUpperCase() ? -1 : 1)
    if (this.origins !== null && this.origins !== undefined)
      this.origins.sort((a: any, b: any) => a.origin!.toUpperCase() <= b.origin!.toUpperCase() ? -1 : 1)
    console.log("WORKTYPES --> ", this.wt);

  }
  ngOnInit(): void {

    forkJoin([this.companies$, this.workTypes$]).subscribe({
      next: ([companies, workTypes]: any) => {
        this.workTypes = workTypes;
        if (this.workTypes !== null && this.workTypes !== undefined)
          this.workTypes.sort((a: any, b: any) => a.workType!.toUpperCase() <= b.workType!.toUpperCase() ? -1 : 1)

        if (this.form.get('building.company')?.value !== undefined && this.form.get('building.company')?.value !== null) {
          let currCompany = this.companies?.find(company => company.code === this.form.get('building.company')?.value);
          const wt = this.workTypes?.filter((workType: any) => {
            return workType.codCountry == currCompany?.country
          })
          console.log("TIPI DI LAVORAZIONE --> ", wt)
          this.wt = wt;

        }
      },
    })

    this.form.get('building.company')?.valueChanges.subscribe(val => {
      let currCompany = this.companies?.find(company => company.code === val);
      const wt = this.workTypes?.filter((workType: any) => {
        return workType.codCountry == currCompany?.country
      }).sort((a: any, b: any) => a.workType <= b.workType ? -1 : 1)
      console.log("TIPI DI LAVORAZIONE --> ", wt)
      this.wt = wt;
    })
    //set status provvisorio per subject e building
    this.statusService.getById(1, 'Subject').subscribe(res => {
      this.statusTemporary = res
      this.form.get('subject')?.get('status')?.setValue(this.statusTemporary)
      this.form.get('building')?.get('status')?.setValue(this.statusTemporary)

    })

    this.form.get('company')?.valueChanges.subscribe(company => {
      this.setCompanyLocationAndResponsibles(company)
    })

    this.form.get('companyId')?.valueChanges.subscribe(company => {
      this.getSubjectType()
    })

    /*
    this.form.get('subject.type')?.valueChanges.subscribe(subject => {
      console.log("REQUEST SUBJECT --> ", subject);
      // console.log("COMPANY SUBJECT-->", this.form.get('subject.company')?.value)
      (this.controlTypes.includes(subject)) ? this.hideLegalEntity = true : this.hideLegalEntity = false;

      this.form.updateValueAndValidity();
      this.cdr.detectChanges();
    })
    */
  }

  ngOnDestroy(): void {
    this.form.get('subject')?.reset()
    this.form.get('building')?.reset()
  }

  selSubject(event: any) {
    console.log('Selected: ', event);

    this.companies = this.allCompanies
    switch (event.type) {
      case 'Edificio':
        this.clearForm('building')
        this.form.get('building')?.patchValue(event)
        break;
      default:
        this.clearForm('subject')
        this.form.get('subject')?.patchValue(event)
        break;
    }
  }

  checkDuplicate() {
    // let subject = this.formRequest.getRawValue();
    let building: any = this.form.get('building')?.value
    console.log("SUBJCT: CONTROLLARE IL TIPO --> ", building)
    if (building?.id === null) {
      this.buildingService.findByAddress(building).subscribe((response: any) => {
        let message = ''
        if (response != null) {
          message = message + this.translate.instant('TOASTR.MESSAGE19') + response.name;
          this.toastr.error(message, this.translate.instant('TOASTR.WARNING'), { enableHtml: true, timeOut: 10000 });
        }
      })
    }
  }

  clearForm(formName: string) {
    //set status provvisorio per subject e building
    this.form.get(formName)?.reset()
    this.form.get(formName)?.get('type')?.setValue('Edificio')
    this.form.get(formName)?.get('status')?.setValue(this.statusTemporary)
  }

  get addressSubjectValid() {
    let streetName = this.form.get('subject')?.get('streetName')?.valid
    let streetNumber = this.form.get('subject')?.get('streetNumber')?.valid
    let zip = this.form.get('subject')?.get('zip')?.valid
    let city = this.form.get('subject')?.get('city')?.valid
    let state = this.form.get('subject')?.get('state')?.valid
    let country = this.form.get('subject')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get addressBuildingValid() {
    let streetName = this.form.get('building')?.get('streetName')?.valid
    let streetNumber = this.form.get('building')?.get('streetNumber')?.valid
    let zip = this.form.get('building')?.get('zip')?.valid
    let city = this.form.get('building')?.get('city')?.valid
    let state = this.form.get('building')?.get('state')?.valid
    let country = this.form.get('building')?.get('country')?.valid

    let addressValid;
    if (!streetName || !streetNumber || !zip || !city || !state || !country) {
      addressValid = false;
    } else {
      addressValid = true;
    }
    return addressValid
  }

  get subjectForm() {
    return this.form.controls['subject'] as UntypedFormGroup
  }

  get buildingForm() {
    return this.form.controls['building'] as UntypedFormGroup
  }

  compareFn(o1: WorkType, o2: WorkType) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2
  }

  private setCompanyLocationAndResponsibles(companyCode: string) {
    if (companyCode && (this.companies && this.companies.length > 0)) {
      let companyLocal = this.companies.find(company => company.code === companyCode)
      try {
        this.form.get('companyLocation')?.setValue(companyLocal!.CompaniesSocietiesRel![0].name)
      } catch (error) {
        this.toastr.error(this.translate.instant('ERROR.COMPANYLOC'))
      }

      try {
        let responsibles = companyLocal!.CompaniesUsersRel!.map((user: User) => user.email)
        let responsible = responsibles.includes(this.authService.user().name) ? this.authService.user().name : responsibles[0]
        this.form.get('responsible')?.setValue(responsible)
      } catch (error) {
        this.toastr.error(this.translate.instant('ERROR.COMPANYRESP'))
      }
    }
  }

  getAttributes(field: any) {
    return this.componentService.getAttributes(field, this.attributes)
  }

  checkVisibility(field: any) {
    return this.componentService.checkVisibility(field, this.attributes)
  }

  verifyDeactivation(field: any) {
    return !this.componentService.checkEditability(field, this.attributes)
  }

  mandatoryVerification(field: any) {
    return this.componentService.mandatoryVerification(field, this.attributes)
  }

  addValidations() {
    //console.log('addValidation - Building')
    //console.log(this.attributes)
    if (this.attributes) {
      for (const [key, value] of Object.entries(this.attributes)) {
        if (this.mandatoryVerification(key)) this.form.get('building')!.get(key)?.addValidators(Validators.required);
      }
    }
  }

  setEditabilityOfFields() {
    console.log('setEditabilityOfFields - Building')
    if (this.attributes) {
      for (const [key, value] of Object.entries(this.attributes)) {
        let status = this.componentService.checkEditability(key, this.attributes)
        status ? this.form!.get('building')?.get(key)?.enable() : this.form!.get('building')?.get(key)?.disable()
      }
    }
    this.enableCompanyField()
  }

  enableCompanyField() {
    if (this.forceLocationSelection) this.form?.get('companyId')?.enable()
  }

  setAttributes() {
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) this.setAttributes()
    if (status) this.addValidations()
    return status
  }

  cercaZonaJaka() {

    let currentUser = this.userSessionService.getState('user');
    let businessLineCode = this.form.get('businessLineCode')?.value
    let lat = this.form?.get('building')?.get('coordY')!.value;
    let lon = this.form?.get('building')?.get('coordX')!.value;

    this.checkDuplicate()

    this.financeService.getGeoArea(currentUser.email, lat, lon, businessLineCode).subscribe((response: any) => {
      console.log('getGeoArea')
      // console.log(response)
      if (response.esito == 'OK') {
        let geo: any = response.item.Table[0]

        if (geo) {
          console.log(geo)
          this.form?.get('companyId')?.setValue(geo.id)
          this.form?.get('companyCode')?.setValue(geo.code)
          this.form?.get('societyId')?.setValue(geo.SocietiesCompaniesRelId)
          this.form?.get('building')?.get('company')?.setValue(geo.code)
          this.toastr.success(this.translate.instant('TOASTR.MESSAGEGEOAREAFOUND'), this.translate.instant('LABEL.Info'));
          this.geoAreaNotFound = false
          this.setCompanyIdEditability()
        } else {
          this.toastr.error(this.translate.instant('TOASTR.MESSAGEGEOAREANOTFOUND'), this.translate.instant('TOASTR.WARNING'));
          this.forceLocationSelection = true;
          this.enableCompanyField()
          this.geoAreaNotFound = true
          this.getCompaniesbyBusinessLineId()
          this.setCompanyIdEditability()
        }
      } else {
        console.log("CATCH ERROR PATCH VALUE REQUEST");
        this.toastr.error(this.translate.instant('TOASTR.MESSAGEGEOAREANOTFOUND'), this.translate.instant('TOASTR.WARNING'));
        this.geoAreaNotFound = true
        this.getCompaniesbyBusinessLineId()
        this.setCompanyIdEditability()
      }
    })

  }

  setCompanyIdEditability() {
    this.geoAreaNotFound ? this.form?.get('companyId')?.enable() : this.form?.get('companyId')?.disable()
    this.geoAreaNotFound ? this.form?.get('building.company')?.enable() : this.form?.get('building.company')?.disable()
  }
}
