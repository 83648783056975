<div class="b2">

    <div *ngIf="order" id="fixedresume" class="com-cont" [ngClass]="{
        'openBox': orderSummaryBoxStatus,
        'closeBox': !orderSummaryBoxStatus,
        'limitWidth': !showFieldsNotYetImplemented
    }" cdkDrag [cdkDragFreeDragPosition]="dragPosition">

        <mat-toolbar color="primary" class="moveCursor" cdkDragHandle>
            <button (click)="toggleOrderSummaryBox($event)" mat-icon-button>
                <mat-icon>{{orderSummaryBoxIcon}}</mat-icon>
            </button>
            <span (click)="toggleOrderSummaryBox($event)" class="title">{{'LABEL.RiepilogoOrdine' | translate }}</span>
        </mat-toolbar>

        <div class="box" *ngIf="orderSummaryBoxStatus">

            <div class="card-title">
                <span class="material-icons-outlined">vpn_key</span>
                <div class="c1">&nbsp;{{'LABEL.Codice' | translate }}</div>
                <div class="c2_1"><b>{{order.code}}</b></div>
                <div class="c3_1">&nbsp; </div>
                <div class="c4_1">

                </div>
            </div>
            <div class="card-title">
                <span class="material-icons-outlined">person</span>
                <div class="c1">&nbsp;{{'LABEL.UltimaModifica' | translate }}</div>
                <div class="c2"><b>{{order.who}}</b></div>
            </div>
            <div class="card-title">
                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.RicavoStandard'
                    |
                    translate }}</div>
                <div class="c1"><b>{{getRicavoTotale() | number: '1.2-2'}}</b></div>
                <div class="c1"><span class="material-icons-outlined">payments</span>&nbsp;{{'LABEL.CostoStandard' |
                    translate }}</div>
                <div class="c1"><b>{{getCostoTotale() | number: '1.2-2'}}</b></div>

                <div *ngIf="showFieldsNotYetImplemented" class="c1"><span
                        class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.MaterialePreventivato' |
                    translate }}</div>
                <div *ngIf="showFieldsNotYetImplemented" class="c1"><b>{{getMaterialePreventivato()}}</b></div>

                <div class="c1"><span class="material-icons-outlined">savings</span>&nbsp;{{'LABEL.MargineStandard' |
                    translate }}</div>
                <div class="c1"><b>{{getMargineTotale() | number: '1.2-2'}}%</b></div>
            </div>
            <div class="card-title">
                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.RicavoReale' |
                    translate }}</div>
                <div class="c1">
                    <b>{{getRicavoReale() | number: '1.2-2'}}</b>
                </div>
                <div class="c1"><span
                        class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.CostoOneriSicurezza'
                    |
                    translate }}
                </div>
                <div class="c1">

                    <b>{{getOneriReale() | number: '1.2-2'}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">account_balance</span>&nbsp;{{'LABEL.CostoOneriFinanziamento'
                    |
                    translate }}
                </div>
                <div class="c1">

                    <b>{{getOneriFinanziamento() | number: '1.2-2'}}</b>
                </div>

                <div class="c1" [ngStyle]="{color:getColorMargin()}"><span
                        class="material-icons-outlined">savings</span>&nbsp;{{'LABEL.MargineReale' | translate
                    }}</div>
                <div class="c1" [ngStyle]="{color:getColorMargin()}"><b>{{getMargineReale() | number: '1.2-2'}}%</b>
                </div>
            </div>
            <div class="card-title">
                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.DeliberatoReale'
                    |
                    translate }}</div>
                <div class="c1">
                    <b>{{getValueDeliber() | number: '1.2-2'}}</b>
                </div>
                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.CostoCessione' |
                    translate }}
                </div>
                <div class="c1">
                    <b>{{getCostoCessione() | number: '1.2-2'}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">account_balance</span>&nbsp;{{'LABEL.MaggFinanziamento' |
                    translate }}
                </div>
                <div class="c1">
                    <b>{{getMaggiorazioneFinanziamento() | number: '1.2-2'}}</b>
                </div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.Venduto' |
                    translate }}</div>
                <div class="c1"><b>{{getCostoVenduto() | number: '1.2-2'}}</b></div>
            </div>

            <div *ngIf="showFieldsNotYetImplemented" class="card-title">
                <div class="c1"></div>
            </div>

            <div *ngIf="showFieldsNotYetImplemented" class="card-title">

                <div class="c1"><span
                        class="material-icons-outlined">hourglass_empty</span>&nbsp;{{'LABEL.OreDeliberate'
                    |
                    translate }}</div>
                <div class="c1">
                    <b>{{getOreDeliberate()}}</b>
                </div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.ValoreProdotto'
                    |
                    translate }}
                </div>
                <div class="c1">
                    <b>{{getValoreProdotto()}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.MaterialeConsuntivato' |
                    translate }}</div>
                <div class="c1"><b>{{getMaterialeConsuntivato()}}</b></div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.Certificato' |
                    translate }}</div>
                <div class="c1"><b>{{getCertificato()}}</b></div>
            </div>

            <div *ngIf="showFieldsNotYetImplemented" class="card-title">

                <div class="c1"><span
                        class="material-icons-outlined">hourglass_empty</span>&nbsp;{{'LABEL.OrePianificate'
                    |
                    translate }}</div>
                <div class="c1">
                    <b>{{getOrePianificate()}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">percent</span>&nbsp;{{'LABEL.PercentualeAvanzamentoSAL' |
                    translate }}
                </div>
                <div class="c1">
                    <b>{{getPercentualeAvanzamentoSAL()}}</b>
                </div>

                <div class="c1">&nbsp;</div>
                <div class="c1"></div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.Fatturato' |
                    translate }}</div>
                <div class="c1"><b>{{getFatturato()}}</b></div>
            </div>

            <div *ngIf="showFieldsNotYetImplemented" class="card-title">

                <div class="c1"><span
                        class="material-icons-outlined">hourglass_empty</span>&nbsp;{{'LABEL.OreConsuntivate' |
                    translate }}</div>
                <div class="c1">
                    <b>{{getOreConsuntivate()}}</b>
                </div>

                <div class="c1">
                    <span 
                        class="material-icons-outlined">attach_money</span>&nbsp;<span
                        >{{'LABEL.ValoreTragitto' |
                        translate }}
                    </span>
                </div>
                <div class="c1">
                    <b>{{preventivoTragitti() | number: '1.2-2'}}</b>
                </div>

                <div class="c1"><span
                        class="material-icons-outlined">percent</span>&nbsp;{{'LABEL.PercentualeIncidenzaMateriali'
                    |
                    translate }}</div>
                <div class="c1"><b>{{getPercentualeIncidenzaMateriali()}}</b></div>

                <div class="c1"><span class="material-icons-outlined">attach_money</span>&nbsp;{{'LABEL.Incassato' |
                    translate }}</div>
                <div class="c1"><b>{{getIncassato()}}</b></div>
            </div>

        </div>
    </div>


</div>
<div class="container-fluid my-container">

    <div class="b2">

        <div class="com-cont" style="width:40%">
            <div class="card-title">
                <div class="cb">
                    <span>{{'LABEL.' + this.getStatus(orderFormGroup.controls['idStatus'].value).descx |
                        translate}}</span>
                </div>
                &nbsp;-&nbsp;
                <div *ngIf="!(orderStatusesManaged==null) && orderStatusesManaged.length > 0" class="cb">
                    <mat-select placeholder="Cambia Stato Commessa"
                        [formControl]="$any(orderFormGroup.controls['idStatus'])">
                        <mat-option *ngFor="let stat of orderStatusesManaged" [value]="stat.id"
                            [disabled]="stat.disabled">
                            {{'LABEL.' + stat.descx | translate}}
                        </mat-option>
                    </mat-select>
                </div>

            </div>
            <div class="card-title" *ngIf="isWarningApprove() == true" style="color:#833d29;"> <mat-icon
                    class="material-icons">warning</mat-icon>{{'LABEL.WARNING_APPROVAL' | translate}}</div>
            <div class="card-title" *ngIf="isWarningApproveOver() == true" style="color:#833d29;"> <mat-icon
                    class="material-icons">warning</mat-icon>{{'LABEL.WARNING_APPROVAL_OVER' | translate}}</div>
            <div class="card-title">
                <div *ngIf="(!(order==null))"><b>{{order.code}}.{{order.version}} - {{order.note}}</b></div>
            </div>
            <div *ngIf="(!(order==null))" class="card-title">
                <div
                    *ngIf="(!(order == null)) && (!(order.OPRel == null)) && (order.OPRel.length > 0) && (!(order.OPRel[0].codeOldSystem == null))">
                    {{'LABEL.CODE_OLD_SYSTEM' | translate}}: <b>{{order.OPRel[0].codeOldSystem}}</b></div>
            </div>
            <div *ngIf="(!(order == null) && order.isSalCento==true)" class="card-title">
                <mat-icon class="material-icons">warning</mat-icon><b>SAL 100</b>
            </div>
        </div>
    </div>
    <div class="buttons-right mar-top-icons">
        <button style="background-color:transparent;" matTooltip="Gestisci documenti" type="button"
            class="my-bounce btn btn-primary mx-2">
            <mat-icon class="material-icons" style="color:#293683;" (click)="openUploadDialog()">description</mat-icon>
        </button>
        <button style="background-color:transparent;" matTooltip="Salva" type="button"
            class="my-bounce btn btn-primary mx-2" (click)="update(false)" [disabled]="isFreeze == true">
            <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
        </button>
    </div>
    <br>
    <mat-stepper class="example-stepper" [orientation]="(stepperOrientation | async)!"
        (selectionChange)="selectionChangeStepper($event)" #stepper>
        <mat-step [stepControl]="buildingFormGroup" label="{{'LABEL.BUILDING' | translate}}" state="checked"
            #first_step>
            <ng-container *ngIf="stepper.selected == null || stepper.selected == first_step">
                <form [formGroup]="buildingFormGroup" class="myform">
                    <h3>{{'LABEL.Edificio' | translate }}</h3>
                    <h4 *ngIf="(buildingFormGroup.controls['building'] == null) || !(buildingFormGroup.controls['building'].value?.id > 0)"
                        style="color:red;">{{'LABEL.NessunEdificioSelezionato' | translate }}</h4>
                    <table
                        *ngIf="!(buildingFormGroup.controls['building'] == null) && buildingFormGroup.controls['building'].value?.id > 0"
                        class="container">
                        <tbody>
                            <tr>
                                <th style="width:30%;">{{'LABEL.Codice' | translate }}</th>
                                <td style="width:52%;">{{ buildingFormGroup.controls['building'].value?.id }}</td>
                                <td style="width:6%;">
                                    <span [matTooltip]="translate.instant('LABEL.Vedi')"
                                        (click)="clickEdificio( buildingFormGroup.controls['building'].value?.id)"><i
                                            class="my-bounce material-icons icsave">travel_explore</i></span>
                                </td>
                                <td style="width:6%;">
                                    <span [matTooltip]="translate.instant('LABEL.CompilaRelazioni')"
                                        (click)="compileRelations()"><i
                                            class="my-bounce material-icons icsave">file_upload</i></span>
                                </td>

                            </tr>
                            <tr>
                                <th style="width:30%;">{{'LABEL.SEDERIF' | translate}}</th>
                                <td style="width:52%;">{{ buildingFormGroup.controls['building'].value?.company }}</td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                            </tr>
                            <tr>
                                <th style="width:30%;">{{'LABEL.Nome' | translate}}</th>
                                <td style="width:52%;">{{ buildingFormGroup.controls['building'].value?.name }}</td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                            </tr>
                            <tr>
                                <th style="width:30%;">{{'LABEL.Tipo' | translate}}</th>
                                <td style="width:52%;">{{ buildingFormGroup.controls['building'].value?.type }}</td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                            </tr>
                            <tr>
                                <th style="width:30%;">{{'LABEL.ADDRESS' | translate}}</th>
                                <td style="width:58%;">{{ buildingFormGroup.controls['building'].value?.streetName + ' '
                                    +
                                    buildingFormGroup.controls['building'].value?.streetNumber + ', ' +
                                    buildingFormGroup.controls['building'].value?.city + ' ' +
                                    buildingFormGroup.controls['building'].value?.zip + ' (' +
                                    buildingFormGroup.controls['building'].value?.province + ') ' +
                                    buildingFormGroup.controls['building'].value?.state }}</td>
                                <td style="width:6%;"></td>
                                <td style="width:6%;"></td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div class="mrg-com-cont">
                    <mat-expansion-panel [matBadge]="getNumberDocumentsBuilding()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>{{'LABEL.Documenti' | translate}}</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div
                            *ngIf="!(buildingFormGroup.controls['building'] == null) && !(buildingFormGroup.controls['building'].value == null) && !(buildingFormGroup.controls['building'].value.documents == null)">
                            <div *ngFor="let document of requestDocuments">

                                <div *ngIf="!(document == null)" class="mycard">
                                    <div>
                                        <span style="font-size: 16px;"><b>{{document.name}}</b> <br></span>
                                        <span *ngIf="!(document.typeName == null)"
                                            matTooltip="{{'FILE-UPLOAD.DOCUMENT-TYPE' | translate}}"
                                            style="font-size: 12px;"><b>{{'LABEL.' + document.typeName!.uiLabel |
                                                translate}}</b></span>
                                        <span *ngIf="(document.typeName == null)"
                                            matTooltip="{{'FILE-UPLOAD.DOCUMENT-TYPE' | translate}}"
                                            style="font-size: 12px;"><b>{{'LABEL.NULL_DOCUMENT_TYPE' |
                                                translate}}</b></span>
                                    </div>
                                    <div class="d-flex">
                                        <a class="btn btn-primary btn-sm me-3 text-dark"
                                            (click)="downloadDocument(document)">{{ 'FILE-UPLOAD.DOWNLOAD' |
                                            translate}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
                <div class="mrg-com-cont">
                    <mat-expansion-panel [matBadge]="getNumberSurveys()">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>{{'LABEL.Questionari' | translate}}</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div
                            *ngIf="!(buildingFormGroup.controls['building'] == null) && !(buildingFormGroup.controls['building'].value == null) && !(buildingFormGroup.controls['building'].value.surveys == null)">
                            <div *ngFor="let survey of buildingFormGroup.controls['building'].value.surveys">
                                <div class="mycard">
                                    {{survey.surveyMasterOrigin.name}}&nbsp;{{'LABEL.del' |
                                    translate}}&nbsp;<b>{{survey.updatedAt | date:'short'}}</b>
                                    <br /><br />
                                    <div *ngFor="let a of survey.answers">
                                        {{a.question.text}}:&nbsp;<b>{{a.value}}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary" matStepperNext>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step [stepControl]="subjectFormGroup" label="{{'LABEL.SUBJECTS' | translate}}" state="checked"
            #second_step>
            <ng-container *ngIf="stepper.selected == null || stepper.selected == second_step">
                <h3>{{ 'LABEL.SUBJECTS' | translate }}</h3>
                <h4
                    *ngIf="!(subjectFormGroup.controls['subject'].value?.id > 0) && !(condominiumFormGroup.controls['condominium'].value?.id > 0) && !(adminFormGroup.controls['admin'].value?.id > 0) ">
                    {{ 'ORDERS.NO_OBJ_SEL' | translate }}</h4>

                <table *ngIf="condominiumFormGroup.controls['condominium'].value?.id > 0" class="container">
                    <tbody>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}}</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.id }}</td>
                            <td style="width:6%;">
                                <span [matTooltip]="translate.instant('LABEL.Vedi')"
                                    (click)="clickCondominio(condominiumFormGroup.controls['condominium'].value?.id)"><i
                                        class="my-bounce material-icons icsave">travel_explore</i></span>
                            </td>

                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}} Delta</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.sysCodeDelta
                                }}
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.SEDERIF' | translate}}</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.company }}
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Nome' | translate}}</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.name }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Tipo' | translate}}</th>
                            <td style="width:40%;"><b>{{ condominiumFormGroup.controls['condominium'].value?.type }}</b>
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 1</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.telephone1 }}
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 2</th>
                            <td style="width:40%;">{{ condominiumFormGroup.controls['condominium'].value?.telephone2 }}
                            </td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                    </tbody>
                </table>
                <hr *ngIf="condominiumFormGroup.controls['condominium'].value?.id > 0">
                <table *ngIf="subjectFormGroup.controls['subject'].value?.id > 0" class="container">
                    <tbody>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}}</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.id }}</td>
                            <td style="width:6%;">
                                <span [matTooltip]="translate.instant('LABEL.Vedi')"
                                    (click)="clickSoggetto(subjectFormGroup.controls['subject'].value?.id)"><i
                                        class="my-bounce material-icons icsave">travel_explore</i></span>
                            </td>

                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}} Delta</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.sysCodeDelta }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.SEDERIF' | translate}}</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.company }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Nome' | translate}}</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.name }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Tipo' | translate}}</th>
                            <td style="width:40%;"><b>{{ subjectFormGroup.controls['subject'].value?.type }}</b></td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 1</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.telephone1 }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 2</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.telephone2 }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.EMAIL' | translate}}</th>
                            <td style="width:40%;">{{ subjectFormGroup.controls['subject'].value?.email }}</td>
                            <td style="width:6%;"></td>
                            <td style="width:24%;">
                                <form [formGroup]="subjectFormGroup">
                                    <mat-checkbox class="cb-margin" formControlName="isSigning">
                                        {{'LABEL.FirmaElettronica' | translate }}</mat-checkbox>
                                </form>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <hr *ngIf="subjectFormGroup.controls['subject'].value?.id > 0">
                <table *ngIf="adminFormGroup.controls['admin'].value?.id > 0" class="container">
                    <tbody>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}}</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.id }}</td>

                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.CODE' | translate}} Delta</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.sysCodeDelta }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.SEDERIF' | translate}}</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.company }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Nome' | translate}}</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.name }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.Tipo' | translate}}</th>
                            <td style="width:40%;"><b>{{ adminFormGroup.controls['admin'].value?.type }}</b></td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 1</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.telephone1 }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.PHONE' | translate}} 2</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.telephone2 }}</td>
                            <td style="width:30%;"></td>
                        </tr>
                        <tr>
                            <th style="width:30%;">{{'LABEL.EMAIL' | translate}}</th>
                            <td style="width:40%;">{{ adminFormGroup.controls['admin'].value?.email }}</td>
                            <td style="width:30%;">
                                <form [formGroup]="adminFormGroup">
                                    <mat-checkbox class="cb-margin" formControlName="isSigning">
                                        {{'LABEL.FirmaElettronica' | translate }}</mat-checkbox>
                                </form>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary"
                        matStepperPrevious>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                    </button>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary" matStepperNext>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step [stepControl]="orderFormGroup" label="{{'LABEL.PREVENTIVI' | translate}}" state="checked" #third_step>
            <ng-container>
                <span *ngFor="let v of versions">

                    <mat-card *ngIf="(v.version == actualversion)" class="mrg-com-cont bck-sel">
                        <mat-card-title>
                            <span style="display: flex; flex-direction: row; justify-content:space-between !important;">
                                <span class="card-title">
                                    <span class="material-icons">check_circle</span>&nbsp;
                                    <span> {{ order.code }}.{{ order.version }}</span>&nbsp;
                                    <span *ngIf="!(v.name == null) && !(v.name == 'null')">&nbsp;-&nbsp;{{ v.name
                                        }}</span>
                                </span>
                                <span class="card-title-rigth">
                                    <div *ngIf="isDeliberableVar == true && iconDeliber()==true"
                                        [matTooltip]="getToolTipDeliberable()" (click)="checkDelibera()"
                                        class="bt-consegna">
                                        <span class="material-icons" style="color:green;">check_circle</span>
                                    </div>
                                    <div *ngIf="!(isDeliberableVar == true) && iconDeliber()==true"
                                        [matTooltip]="getToolTipDeliberable()" (click)="checkDelibera()"
                                        class="bt-consegna">
                                        <span class="material-icons" style="color:red;">error</span>
                                    </div>
                                    <div [matTooltip]="getToolTipPrint()" class="bt-consegna"
                                        (click)="downloadPrev(order)">
                                        <span class="material-icons">print</span>
                                    </div>
                                    <div matTooltip="Consegna al cliente" class="bt-consegna"
                                         (click)="openModalConfirmPrev(e, order)">
                                        <span class="material-icons">assignment</span>
                                    </div>
                                </span>
                            </span>
                        </mat-card-title>
                    </mat-card>
                </span>
                <mat-expansion-panel *ngIf="!(versions == null) && (versions.length > 1)"
                    [matBadge]="versions.length -1">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'LABEL.Versioni' | translate}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <span *ngFor="let v of versions">
                        <mat-expansion-panel *ngIf="!(v.version == actualversion)" class="mrg-com-cont icnc">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span
                                        style="display: flex; flex-direction: row; justify-content:space-between !important; width:100%;">
                                        <div
                                            style="display: flex; flex-direction: row; justify-content:flex-start !important;">
                                            <span *ngIf="!(v.idStatus == getSTATUS_NULLED())"
                                                (click)="goToVersion({isUserInput:true},v.version)"
                                                class="card-title hvA">
                                                <span class="material-icons">done</span>
                                                <span> {{ order.code }}.{{ v.version }}</span>&nbsp;
                                                <span><b>{{'LABEL.' + getStatus(v.idStatus).descx |
                                                        translate}}</b></span>&nbsp;
                                                <span *ngIf="!(v.name == null) && !(v.name == 'null')">&nbsp;-&nbsp;{{
                                                    v.name
                                                    }}&nbsp;</span>
                                            </span>
                                            <span *ngIf="(v.idStatus == getSTATUS_NULLED())" class="card-title">
                                                <span class="material-icons">delete</span>&nbsp;&nbsp;
                                                <span> {{ order.code }}.{{ v.version }}</span>&nbsp;
                                                <span><b>{{'LABEL.' + getStatus(v.idStatus).descx |
                                                        translate}}</b></span>&nbsp;
                                                <span *ngIf="!(v.name == null) && !(v.name == 'null')">&nbsp;-&nbsp;{{
                                                    v.name
                                                    }}&nbsp;</span>
                                            </span>
                                        </div>
                                        <span *ngIf="!(v.idStatus == getSTATUS_NULLED())" class="card-title">
                                            &nbsp;&nbsp;
                                            <div class="bt-consegna" (click)="nullall(v.id)"><span
                                                    class="material-icons">delete</span></div>
                                        </span>
                                    </span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="getDetail(v)"></div>
                        </mat-expansion-panel>

                    </span>

                </mat-expansion-panel>
                <form [formGroup]="orderFormGroup">
                    <div class="com-cont">
                        <!-- DATI GENERALI -->
                        <mat-card class="mrg-com-cont">
                            <mat-card-title class="card-title">
                                <span class="material-icons">assignment</span>&nbsp;
                                <span> {{ 'LABEL.GENERAL_DATA' | translate }}</span>
                                <div *ngIf="!(requests == null) && (requests.length > 0) && !(requests[0] == null)"
                                    [matTooltip]="getToolTipRequest(requests[0])">
                                    <div class="little-text">
                                        &nbsp;{{ 'REQUEST.REQUEST' | translate
                                        }}&nbsp;<b>{{requests[0].id}}</b>&nbsp;-&nbsp;{{requests[0].assignmentOperator}}
                                    </div>
                                </div>
                            </mat-card-title>
                            <mat-card-content>
                                <div class="form-row">

                                    <div class="card-row">
                                        <mat-form-field class="mrg-com-cont full" appearance="fill">
                                            <mat-label>{{'REQUEST.COMPANY' | translate }}</mat-label>
                                            <mat-select formControlName="sede" [disabled]="true">
                                                <mat-option (onSelectionChange)="onChangeFirstLevel('companies',$event)"
                                                    *ngFor="let company of companies" [value]="company">
                                                    {{company.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="card-row">
                                        <span class="mrg-com-cont-sm full"
                                            *ngIf="(!(resourcesRespComm == null) && (resourcesRespComm.length > 0))">
                                            <app-select-search appearance="fill"
                                                formControlName="responsabileCommerciale" [label]="getLabelResp()"
                                                [items]="resourcesRespComm" viewField="name">
                                            </app-select-search>
                                        </span>
                                        <span class="mrg-com-cont-sm full"
                                            *ngIf="(!(resourcesCoord == null) && (resourcesCoord.length > 0))">
                                            <app-select-search appearance="fill" formControlName="coordinatore"
                                                [label]="getLabelCoord()" [items]="resourcesCoord" viewField="name">
                                            </app-select-search>
                                        </span>
                                    </div>

                                    <div class="card-row">
                                        <mat-form-field class="mrg-com-cont full" appearance="fill">
                                            <mat-label>{{'LABEL.NOTE' | translate}}</mat-label>
                                            <textarea matInput formControlName="note">
                                        </textarea>
                                        </mat-form-field>
                                    </div>

                                    <div class="card-row">
                                        <mat-form-field class="mrg-com-cont full" appearance="fill">
                                            <mat-label>{{'LABEL.PaymentMethod' | translate }}</mat-label>
                                            <mat-select
                                                formControlName="paymentMethodId">
                                                <mat-option [disabled]="disablepaymentOption(paymentMethod)"
                                                    [selected]="paymentMethod.id == orderFormGroup.controls['paymentMethodId'].value"
                                                    *ngFor="let paymentMethod of paymentMethods"
                                                    [value]="paymentMethod.id">
                                                    {{'PAYMENTMETHODS.' + paymentMethod.code | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="card-row">

                                        <span class="mrg-com-cont full" appearance="fill">
                                            <mat-form-field class="example-full-width dis">
                                                <mat-label>{{'LABEL.OneriSicurezza' | translate}}</mat-label>
                                                <input matInput formControlName="oneriSicurezza" readonly>
                                            </mat-form-field>
<!--
                                            <mat-checkbox class="cb-margin" formControlName="isSedeInterna"
                                                [disabled]="orderFormGroup.controls['macrophases'].value.length > 0">
                                                {{'LABEL.SedeInterna' | translate}}</mat-checkbox>
-->
                                            <mat-checkbox class="cb-margin" formControlName="isMultiservice"
                                                [disabled]="orderFormGroup.controls['macrophases'].value.length > 0">{{'LABEL.ISMULTISERVIZI'
                                                | translate}}</mat-checkbox>
                                        </span>

                                        <span class="mrg-com-cont full" appearance="fill">
                                            <mat-slide-toggle (change)="changeFinancingToggleActivation($event)"
                                                color="primary" *ngIf="financingToggleActivation"
                                                formControlName="financingSectionActivation">Abilita
                                                Finanziamento</mat-slide-toggle>
                                        </span>

                                    </div>

                                </div>
                            </mat-card-content>
                        </mat-card>
                        <!-- FINANZIAMNENTO -->
                        <mat-card class="mrg-com-cont" *ngIf="theFundingHasBeenActivated()">
                            <mat-card-title class="card-title">
                                <span class="material-icons">account_balance</span>&nbsp;
                                <span> {{ 'LABEL.FINANZIAMENTO' | translate }}</span>
                            </mat-card-title>
                            <mat-card-content>
                                <div class="form-row">
                                    <div class="card-row">
                                        <span class="mrg-com-cont-sm">
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{'LABEL.ValoreFinanziato' | translate}}</mat-label>
                                                <!-- <input [max]="valoreMassimoFinanziamento()" (change)="setFinanziamento($event)" type="number" matInput formControlName="fundedValue">-->
                                                <input (change)="setFinanziamento($event)" type="number" matInput
                                                    formControlName="fundedValue">
                                                <mat-hint align="end">{{hintValoreFinanziato}}</mat-hint>
                                            </mat-form-field>
                                        </span>

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.NumeroRate' | translate }}</mat-label>
                                            <mat-select (selectionChange)="changeRataFinanziamento($event)"
                                                formControlName="fundingId">
                                                <mat-option [disabled]="funding.disabled"
                                                    [selected]="funding.id == orderFormGroup.controls['fundingId'].value"
                                                    *ngFor="let funding of fundings" [value]="funding.id">
                                                    {{funding.label}} - {{funding.source}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field class="mrg-com-cont" appearance="fill">
                                            <mat-label>{{'LABEL.numberOfCondominiums' | translate }}</mat-label>
                                            <input type="number" matInput
                                                    formControlName="numberOfCondominiums">
                                        </mat-form-field>

                                        <span class="mrg-com-cont-sm">
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{'LABEL.MaggiorazioneFinanziamento' |
                                                    translate}}</mat-label>
                                                <input type="number" matInput
                                                    formControlName="maggiorazioneFinanziamento" [readonly]="true">
                                            </mat-form-field>
                                        </span>
                                        <!--
                                        <span class="mrg-com-cont-sm">
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{'LABEL.anteprimaFinanziamento' | translate}}</mat-label>
                                                <input type="number" matInput formControlName="anteprimaFinanziamento"
                                                    [readonly]="true">
                                            </mat-form-field>
                                        </span>
                                        -->



                                    </div>

                                </div>
                            </mat-card-content>
                        </mat-card>
                        <!-- LAVORAZIONI -->
                        <mat-card class="mrg-com-cont">
                            <mat-card-title class="card-title row-panel">
                                <span class="row-panel-group-rigth">
                                    <span class="material-icons">engineering</span>&nbsp;
                                    <span> {{ 'ORDERS.LAVORAZIONI' | translate }}</span>
                                </span>
                                <button aria-label="Aggiungi lavorazione" style="background-color:transparent;"
                                    type="button" class="btn btn-primary" (click)="addRow(order.rows.length)">
                                    <mat-icon class="material-icons" style="color:#293683;">plus_one</mat-icon>
                                </button>
                            </mat-card-title>
                            <mat-card-content>
                                <span *ngFor="let r of order?.rows; let i = index">
                                    <mat-card class="card-title row-panel">
                                        <div *ngIf="!(orderFormGroup.controls['macrophases'].value[i]==null)"
                                            class="form-row">
                                            <mat-form-field class="mrg-com-cont" appearance="fill">
                                                <mat-label>{{'LABEL.BRANCH' | translate}}</mat-label>
                                                <mat-select
                                                    [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['branch']"
                                                    (selectionChange)="onChangeBranchesSelect($event)" required>
                                                    <mat-option (onSelectionChange)="onChange('branches',i,$event)"
                                                        *ngFor="let branch of filterBranchesForSelect()"
                                                        [value]="branch" [disabled]="!(branch.isActive == true)">
                                                        {{branch.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-hint
                                                    *ngIf="orderFormGroup.controls['macrophases'].value[i].controls['branch']">
                                                    <b>{{orderFormGroup.controls['macrophases'].value[i].controls['branch'].value.name}}</b>
                                                </mat-hint>
                                            </mat-form-field>
                                            <mat-form-field class="mrg-com-cont" appearance="fill">
                                                <mat-label>{{'LABEL.CATEGORY' | translate}}</mat-label>
                                                <mat-select
                                                    [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['category']"
                                                    required
                                                    [disabled]="!(orderFormGroup.controls['macrophases'].value[i].controls['branch'].value?.id > 0)">
                                                    <mat-option (onSelectionChange)="onChange('categories',i,$event)"
                                                        *ngFor="let category of filterCategories(orderFormGroup.controls['macrophases'].value[i].controls['branch'].value)"
                                                        [value]="category" [disabled]="!(category.isActive == true)">
                                                        {{category.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-hint
                                                    *ngIf="orderFormGroup.controls['macrophases'].value[i].controls['category'].value.id > 0">
                                                    <b>{{orderFormGroup.controls['macrophases'].value[i].controls['category'].value.name}}</b>
                                                </mat-hint>
                                            </mat-form-field>
                                            <mat-form-field class="mrg-com-cont" appearance="fill">
                                                <mat-label>{{'LABEL.MACROPHASE' | translate}}</mat-label>
                                                <mat-select
                                                    [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['macrophase']"
                                                    required
                                                    [disabled]="!(orderFormGroup.controls['macrophases'].value[i].controls['category'].value?.id > 0)||(isMacroPhaseChangeble(i)==false)">
                                                    <mat-option (onSelectionChange)="onChange('macrophases',i,$event)"
                                                        *ngFor="let mPhase of filterMacroPhases(orderFormGroup.controls['macrophases'].value[i].controls['category'].value)"
                                                        [value]="mPhase" [disabled]="!(mPhase.isActive == true)">
                                                        {{mPhase.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-hint
                                                    *ngIf="orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].value.id > 0">
                                                    <b>{{orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].value.name}}</b>
                                                </mat-hint>
                                            </mat-form-field>

                                            <mat-form-field
                                                *ngIf="(!(orderFormGroup.controls['sede'].value ==null) && orderFormGroup.controls['sede'].value.CompaniesSocietiesRel[0].nation == 'IT')"
                                                class="mrg-com-cont" appearance="fill">
                                                <mat-label>{{'LABEL.CessioneCredito' | translate}}</mat-label>
                                                <mat-select
                                                    [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['percCredit']">
                                                    <mat-option *ngFor="let taxStep of taxSteps" [value]="taxStep">
                                                        {{taxStep.percStep}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <span class="block-cb-master">

                                                <span class="block-cb-slave">
                                                    <mat-checkbox class="cb-margin"
                                                        [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['isNotturno']">
                                                        {{'WORKTYPES.NIGTH' | translate}}</mat-checkbox>
                                                    <mat-checkbox class="cb-margin"
                                                        [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['isTrasferta']">
                                                        {{'WORKTYPES.TRAS' | translate}}</mat-checkbox>
                                                    <mat-checkbox class="cb-margin"
                                                        [formControl]="orderFormGroup.controls['macrophases'].value[i].controls['isFestivo']">
                                                        {{'WORKTYPES.FEST' | translate}}</mat-checkbox>
                                                </span>
                                                <span class="block-cb-slave">
                                                    <mat-form-field
                                                        *ngIf="this.orderFormGroup.controls['isMultiservice'].value==true"
                                                        class="field-row-phase">
                                                        <input matInput type="number" min="1" max="120"
                                                            [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['howMany']"
                                                            placeholder="Numero ripetizioni"
                                                            (ngModelChange)="changeHowMany(i,$event)">
                                                    </mat-form-field>

                                                </span>
                                            </span>
                                        </div>

                                        <span class="form-column-rev">
                                            <span class="form-row-rev">
                                                <span class="material-icons-outlined icnc"
                                                    (click)="removeRow(i)">remove</span>

                                                <mat-icon *ngIf="openDetails[i].open == false"
                                                    [matBadge]="getNumberBadge(i)" matBadgeColor="primary"
                                                    (click)="expandRow(i)">expand_more</mat-icon>
                                                <mat-icon *ngIf="openDetails[i].open == true"
                                                    [matBadge]="getNumberBadge(i)" matBadgeColor="primary"
                                                    (click)="expandRow(i)">expand_less</mat-icon>

                                            </span>
                                            <span class="form-row-rev">
                                                <mat-icon
                                                    *ngIf="orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].value.isFreeDatas==true || isWarranty==true"
                                                    (click)="addFreeRow(i)">add</mat-icon>
                                            </span>
                                        </span>

                                    </mat-card>

                                    <div *ngIf="openDetails[i].open == true">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th *ngIf="isVisibleDelibera() == true">Delibera</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr [ngClass]="(!this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.isActive && this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDescription'].value != true) ? 'phaseDisabled' : ''"
                                                    *ngFor="let phase of  this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value; let j = index">
                                                    <!--<td><pre>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value|json}}</pre></td>-->
                                                    <td>
                                                        <mat-checkbox *ngIf="isVisibleDelibera() == true"
                                                            class="cb-margin" (change)="onChangeDeliberato($event,i,j)"
                                                            [disabled]="!(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value) || isReview == true  || isWarranty == true"
                                                            [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isDeliberato']">
                                                        </mat-checkbox>
                                                    </td>
                                                    <td>
                                                        <mat-checkbox class="cb-margin"
                                                            [disabled]="(!this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.isActive && this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDescription'].value != true) || isDisabledForTC() || isReview == true || (isWarranty == true && !(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true))"
                                                            [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected']">
                                                        </mat-checkbox>
                                                    </td>
                                                    <td>
                                                        <span class="group-phase-name">
                                                            <span
                                                                class="phase-name"><b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.name}}</b></span>
                                                            <input class="input-phase-desc"
                                                                *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDescription'].value == true"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['freeDescription']"
                                                                placeholder="Descrizione libera">
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true)"
                                                            class="info-row-phase">
                                                            <label>{{'LABEL.ProdOrariaRif' | translate}}</label>
                                                            <span>&nbsp;<b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.qProdOraria}}</b></span>
                                                        </div>

                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true"
                                                            class="field-row-phase">
                                                            <input [readonly]="isDisabledForTC() || isReview == true"
                                                                matInput type="number"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qProdOrariaRif']"
                                                                [placeholder]="translate.instant('LABEL.ProdOrariaRif')"
                                                                (ngModelChange)="changeAmmQt(i,j,$event)">
                                                        </mat-form-field>

                                                    </td>
                                                    <td>
                                                        <div *ngIf="!(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true)"
                                                            class="info-row-phase">
                                                            <label>{{'LABEL.CostoMaterialeRif' | translate}}</label>
                                                            <span>&nbsp;<b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.cMater}}</b></span>
                                                        </div>

                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isFreeDatas'].value==true"
                                                            class="field-row-phase">
                                                            <input [readonly]="isDisabledForTC() || isReview == true"
                                                                matInput type="number"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['cMaterRif']"
                                                                [placeholder]="translate.instant('LABEL.CostoMaterialeRif')"
                                                                (ngModelChange)="changeAmmQt(i,j,$event)">
                                                        </mat-form-field>

                                                    </td>
                                                    <td>
                                                        <div class="info-row-phase">
                                                            <label>{{'LABEL.OreManodopera' | translate}}</label>
                                                            <span>&nbsp;<b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qtOreManodopera'].value}}</b></span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="info-row-phase">
                                                            <label>{{'LABEL.TotaleListino' | translate}}</label>
                                                            <span>&nbsp;<b>{{this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['price'].value}}</b></span>
                                                        </div>

                                                    </td>

                                                    <td>
                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value==true"
                                                            class="field-row-phase">
                                                            <input [readonly]="isDisabledForTC() || isReview == true"
                                                                matInput type="number" min="20" max="100"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['percAmmaloramento']"
                                                                [placeholder]="translate.instant('LABEL.ISPERCAMMALORAMENTO')"
                                                                (ngModelChange)="changeAmmQt(i,j,$event)">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value==true"
                                                            class="field-row-phase">
                                                            <input [readonly]="isDisabledForTC()" matInput type="number"
                                                                min="1"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qtToProduct']"
                                                                placeholder="Quantità da produrre"
                                                                (ngModelChange)="changeAmmQt(i,j,$event)">
                                                        </mat-form-field>
                                                    </td>
                                                    <td>
                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value==true"
                                                            class="field-row-phase">
                                                            <input
                                                                [readonly]="isDisabledForTC() || isReview == true || isWarranty==true"
                                                                matInput type="number"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['priceNet']"
                                                                placeholder="Prezzo Netto"
                                                                (ngModelChange)="setFinanziamento()">
                                                        </mat-form-field>
                                                    </td>
                                                    <td
                                                        *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['phase'].value.isOtherCost == true">
                                                        <mat-form-field
                                                            *ngIf="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['selected'].value==true"
                                                            class="field-row-phase">
                                                            <input
                                                                [readonly]="isDisabledForTC() || isReview == true || isWarranty==true"
                                                                matInput type="number"
                                                                [formControl]="this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['otherCost']"
                                                                placeholder="Altri costi">
                                                        </mat-form-field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </form>
                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary"
                        matStepperPrevious>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                    </button>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary" matStepperNext>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step label="Finance" state="checked" #four_step>
            <ng-container>
                <mat-expansion-panel style="margin:5px; width: 100%;">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="my-bounce material-icons">redeem</span>
                            <div class="fw-bolder text-dark"> {{ 'LABEL.Certificati' | translate }}</div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row g-6 g-xl-9">

                        <iframe id="CertificatesIframe" style="width: 100%; height: 769px;" [src]="certurl | safe"
                            title="Invoice Certificates"></iframe>

                    </div>
                </mat-expansion-panel>

                <mat-tab-group class="wbck" animationDuration="300ms">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="material-icons">dynamic_feed</mat-icon>
                            <span class="ml-1">{{'LABEL.Fatture' | translate}}</span>
                            <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
                                class="crs" (click)="goToWiki()" matTooltip="AlbertWiki"
                                class="material-icons icw">help_outline</i>
                        </ng-template>
                        <!--  <app-invoices *ngIf="!(order == null)" [practice]="order.code"></app-invoices>-->
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="material-icons">dynamic_feed</mat-icon>
                            <span class="ml-1">{{'LABEL.Incassi' | translate}}</span>
                            <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"
                                class="crs" (click)="goToWiki()" matTooltip="AlbertWiki"
                                class="material-icons icw">help_outline</i>
                        </ng-template>
                        <!-- <app-collections *ngIf="!(order == null)" [practice]="order.code"></app-collections> -->
                    </mat-tab>
                </mat-tab-group>

                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary"
                        matStepperPrevious>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                    </button>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary" matStepperNext>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step label="WorkFlow" #five_step>
            <ng-container *ngIf="stepper.selected == null || stepper.selected == five_step">
                <div *ngIf="!(order == null)" class="flex-column mrg">
                    <h2 class="mb-1">{{'LABEL.HINT' | translate}}</h2>

                    <div [ngClass]="getClassWorkflow(1)">
                        <div class="intest-type-document">
                            <mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(1)">circle</mat-icon>
                            &nbsp;&nbsp;<span *ngIf="getStepFromStatus(order.idStatus) > 1">{{'LABEL.HINT_ORDER_1' |
                                translate}}</span><span
                                *ngIf="getStepFromStatus(order.idStatus) <= 1">{{'LABEL.HINT_ORDER_1_WORK' |
                                translate}}</span>&nbsp;&nbsp;<mat-icon *ngIf="getStepFromStatus(order.idStatus) == 1"
                                class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                        </div>
                        <div class="group-type-document">
                            <div *ngIf="!(this.documentsFromStatus == null) && getStepFromStatus(order.idStatus) == 1">
                                <div *ngFor="let doc of this.documentsFromStatus">
                                    <div *ngIf="doc.isGeneric == true" style="margin-left:40px;">- {{'LABEL.' +
                                        doc.uiLabel
                                        |translate}} ({{'LABEL.FAC' |translate}})</div>
                                    <div *ngIf="!(doc.isGeneric == true)" style="margin-left:40px;">- <b>{{'LABEL.' +
                                            doc.uiLabel |translate}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="getClassWorkflow(2)">
                        <div class="intest-type-document">
                            <mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(2)">circle</mat-icon>
                            &nbsp;&nbsp;<span *ngIf="getStepFromStatus(order.idStatus) > 2">{{'LABEL.HINT_ORDER_2' |
                                translate}}</span><span
                                *ngIf="getStepFromStatus(order.idStatus) <= 2">{{'LABEL.HINT_ORDER_2_WORK' |
                                translate}}</span>&nbsp;&nbsp;<mat-icon *ngIf="getStepFromStatus(order.idStatus) == 2"
                                class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                        </div>
                        <div class="group-type-document">
                            <div *ngIf="!(this.documentsFromStatus == null) && getStepFromStatus(order.idStatus) == 2">
                                <div *ngFor="let doc of this.documentsFromStatus">
                                    <div *ngIf="doc.isGeneric == true" style="margin-left:40px;">- {{'LABEL.' +
                                        doc.uiLabel
                                        |translate}} ({{'LABEL.FAC' |translate}})</div>
                                    <div *ngIf="!(doc.isGeneric == true)" style="margin-left:40px;">- <b>{{'LABEL.' +
                                            doc.uiLabel |translate}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="getClassWorkflow(3)">
                        <div class="intest-type-document">
                            <mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(3)">circle</mat-icon>
                            &nbsp;&nbsp;<span *ngIf="getStepFromStatus(order.idStatus) > 3">{{'LABEL.HINT_ORDER_3' |
                                translate}}</span><span
                                *ngIf="getStepFromStatus(order.idStatus) <= 3">{{'LABEL.HINT_ORDER_3_WORK' |
                                translate}}</span>&nbsp;&nbsp;<mat-icon *ngIf="getStepFromStatus(order.idStatus) == 3"
                                class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                        </div>
                        <div class="group-type-document">
                            <div *ngIf="!(this.documentsFromStatus == null) && getStepFromStatus(order.idStatus) == 3">
                                <div *ngFor="let doc of this.documentsFromStatus">
                                    <div *ngIf="doc.isGeneric == true" style="margin-left:40px;">- {{'LABEL.' +
                                        doc.uiLabel
                                        |translate}} ({{'LABEL.FAC' |translate}})</div>
                                    <div *ngIf="!(doc.isGeneric == true)" style="margin-left:40px;">- <b>{{'LABEL.' +
                                            doc.uiLabel |translate}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="getClassWorkflow(4)">
                        <div class="intest-type-document">
                            <mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(4)">circle</mat-icon>
                            &nbsp;&nbsp;<span *ngIf="getStepFromStatus(order.idStatus) > 4">{{'LABEL.HINT_ORDER_4' |
                                translate}}</span><span
                                *ngIf="getStepFromStatus(order.idStatus) <= 4">{{'LABEL.HINT_ORDER_4_WORK' |
                                translate}}</span>&nbsp;&nbsp;<mat-icon *ngIf="getStepFromStatus(order.idStatus) == 4"
                                class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                        </div>
                        <div class="group-type-document">
                            <div *ngIf="!(this.documentsFromStatus == null) && getStepFromStatus(order.idStatus) == 4">
                                <div *ngFor="let doc of this.documentsFromStatus">
                                    <div *ngIf="doc.isGeneric == true" style="margin-left:40px;">- {{'LABEL.' +
                                        doc.uiLabel
                                        |translate}} ({{'LABEL.FAC' |translate}})</div>
                                    <div *ngIf="!(doc.isGeneric == true)" style="margin-left:40px;">- <b>{{'LABEL.' +
                                            doc.uiLabel |translate}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="getClassWorkflow(5)">
                        <div class="intest-type-document">
                            <mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(5)">circle</mat-icon>
                            &nbsp;&nbsp;<span *ngIf="getStepFromStatus(order.idStatus) > 5">{{'LABEL.HINT_ORDER_5' |
                                translate}}</span><span
                                *ngIf="getStepFromStatus(order.idStatus) <= 5">{{'LABEL.HINT_ORDER_5_WORK' |
                                translate}}</span>&nbsp;&nbsp;<mat-icon *ngIf="getStepFromStatus(order.idStatus) == 5"
                                class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                        </div>
                        <div class="group-type-document">
                            <div *ngIf="!(this.documentsFromStatus == null) && getStepFromStatus(order.idStatus) == 5">
                                <div *ngFor="let doc of this.documentsFromStatus">
                                    <div *ngIf="doc.isGeneric == true" style="margin-left:40px;">- {{'LABEL.' +
                                        doc.uiLabel
                                        |translate}} ({{'LABEL.FAC' |translate}})</div>
                                    <div *ngIf="!(doc.isGeneric == true)" style="margin-left:40px;">- <b>{{'LABEL.' +
                                            doc.uiLabel |translate}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="getClassWorkflow(6)">
                        <div class="intest-type-document">
                            <mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(6)">circle</mat-icon>
                            &nbsp;&nbsp;<span *ngIf="getStepFromStatus(order.idStatus) > 6">{{'LABEL.HINT_ORDER_6' |
                                translate}}</span><span
                                *ngIf="getStepFromStatus(order.idStatus) <= 6">{{'LABEL.HINT_ORDER_6_WORK' |
                                translate}}</span>&nbsp;&nbsp;<mat-icon *ngIf="getStepFromStatus(order.idStatus) == 6"
                                class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                        </div>
                        <div class="group-type-document">
                            <div *ngIf="!(this.documentsFromStatus == null) && getStepFromStatus(order.idStatus) == 6">
                                <div *ngFor="let doc of this.documentsFromStatus">
                                    <div *ngIf="doc.isGeneric == true" style="margin-left:40px;">- {{'LABEL.' +
                                        doc.uiLabel
                                        |translate}} ({{'LABEL.FAC' |translate}})</div>
                                    <div *ngIf="!(doc.isGeneric == true)" style="margin-left:40px;">- <b>{{'LABEL.' +
                                            doc.uiLabel |translate}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <br />
                <div *ngIf="!(order == null) && !(order.documents == null)">
                    <app-documents-grid [documents]="order.documents"></app-documents-grid>
                </div>
                <br />
                <button
                    [disabled]="!(order == null) && (order.idStatus == getSTATUS_PRESENTED()) && (countPresented()>1)"
                    class="btn btn-primary btn-sm me-3 text-dark" (click)="openUploadDialog()">{{'LABEL.UPLOADDOCUMENT'
                    |
                    translate}}</button>
                <button class="btn btn-primary btn-sm me-3 text-dark" (click)="sign()"
                    [disabled]="!isDeliberable()">{{'LABEL.SIGN'
                    |
                    translate}}</button>
                <div>
                    <button style="background-color:transparent;" type="button" class="btn btn-primary"
                        matStepperPrevious>
                        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
                    </button>
                </div>
            </ng-container>
        </mat-step>
        <mat-step label="{{'LABEL.DOCUMENTS' | translate}}" [editable]="false" #six_step>
            <ng-container *ngIf="stepper.selected == null || stepper.selected == six_step">

                <app-order-document [orderVersions]="orderVersions"></app-order-document>


            </ng-container>
        </mat-step>


    </mat-stepper>
    <br>
    <div class="buttons-right">
        <button style="background-color:transparent;" matTooltip="Gestisci documenti" type="button"
            class="my-bounce btn btn-primary mx-2">
            <mat-icon class="material-icons" style="color:#293683;" (click)="openUploadDialog()">description</mat-icon>
        </button>
        <button style="background-color:transparent;" matTooltip="Salva" type="button"
            class="my-bounce btn btn-primary mx-2" (click)="update(false)" [disabled]="isFreeze == true">
            <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
        </button>
    </div>
</div>