<div class="full-w" >
    <br>
    <br>
    <div class="col col-md-12">
        <div class="row">
          <div class="col pr-0" >
            <!-- [disabled]="disableButtons()" -->
            <input type="text" id="autocomplete" class="form-control" [(ngModel)]="address" (onAddressChange)="handleOnAddressChange($event)"
              ngx-google-places-autocomplete (keydown.enter)="findAddress()" placeholder="{{ 'LABEL.INSERISCI_INDIRIZZO' | translate }}"
              [disabled]="isWorking" [disabled]="disableSearchBar" *ngIf="!disableSearchBarButton">
          </div>

          <div class="col-auto ml-auto group-button-map">
            <br>
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">

              <mat-button-toggle value="search" (click)="findAddress()" [disabled]="disableSearchBarButton">
                <mat-icon>search</mat-icon> <span>{{ 'LABEL.FIND' | translate }}</span>
              </mat-button-toggle>

              <mat-button-toggle value="localize" (click)="getCurrentLocation()"  [disabled]="disableCurrentLocationButton">
                <mat-icon>maps</mat-icon> <span>{{ 'LABEL.LOCALIZE_ME' | translate }}</span>
              </mat-button-toggle>

              <mat-button-toggle value="searchCompany" (click)="cercaZonaJaka()" [disabled]="disableGeoButton" class="findCompanyButton">
                <mat-icon>search</mat-icon> <span>{{ 'LABEL.FIND_COMPANY' | translate }}</span>
              </mat-button-toggle>

            </mat-button-toggle-group>
            <!--
            <button mat-button color="primary" (click)="findAddress()"
  
              [disabled]="disableSearchBarButton"
              class="with-icon" >
              <mat-icon>search</mat-icon> <span>{{ 'LABEL.FIND' | translate }}</span>
            </button>
  
            <button mat-button color="primary" (click)="getCurrentLocation()"
              [disabled]="disableCurrentLocationButton"
              class="with-icon" >
              <mat-icon>maps</mat-icon> <span>{{ 'LABEL.LOCALIZE_ME' | translate }}</span>
            </button>

            <button mat-button color="primary" (click)="cercaZonaJaka()" [disabled]="disableGeoButton"
             class="with-icon findCompanyButton" >
              <mat-icon>search</mat-icon> <span>{{ 'LABEL.FIND_COMPANY' | translate }}</span>
            </button>
          -->
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 col-md-6 pr-md-0">
            <div id="info" class="shadow-sm rounded border p-3 h-100">
              <h5>{{ 'LABEL.ADDRESS' | translate }}</h5>
              <p>
                {{ formattedAddress?.length ? formattedAddress : 'N/A' }}
              </p>
              <h5>{{ 'LABEL.COORDINATES' | translate }}</h5>
              <p class="mb-0">
                {{ 'LABEL.LATITUDE' | translate }}: {{ locationCoords ? locationCoords?.lat() : 'N/A' }}<br>
                {{ 'LABEL.LONGITUDE' | translate }}: {{ locationCoords ? locationCoords?.lng() : 'N/A' }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="google_map_container shadow-sm rounded mt-3 mt-md-0">
              <google-map [zoom]="mapZoom" [center]="mapCenter" [options]="mapOptions">
                <map-marker #marker="mapMarker" [position]="mapCenter" [options]="markerOptions"
                  (mapClick)="openInfoWindow(marker)" (mapDragend)="onMapDragEnd($event)">
                </map-marker>
                <map-info-window>{{ markerInfoContent }}</map-info-window>
              </google-map>
            </div>
          </div>
        </div>
    </div>
  
    <!--
              <iframe width="600" height="450" style="border:0" loading="lazy" allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBMKHOxlZc_oirDPSyqQj150G1m5Sy3DDQ&q=Milano">
              </iframe>
          -->
  
  </div>
  