<!-- <div class="container-fluid my-container"> -->
  <mat-tab-group class="wbck" animationDuration="300ms" #tabGroup ><!-- (selectedTabChange)="tabChanged($event)" -->
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="my-bounce material-icons">report</mat-icon>
            <span class="ml-1">{{ 'LABEL.COMPANYTARGETS' | translate }}</span>
            <!-- <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i> -->
        </ng-template>
        <div class="full-w" fxLayout="row" fxLayoutAlign="center center">
          <app-company-targets></app-company-targets>
        </div>
    </mat-tab>

    <mat-tab>

        <ng-template mat-tab-label>
            <mat-icon class="my-bounce material-icons">pending_actions</mat-icon>
            <span class="ml-1">{{ 'LABEL.PROFILETARGETS' | translate }}</span>
            <!-- <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i> -->
        </ng-template>
        <div class="full-w" fxLayout="row" fxLayoutAlign="center center">

          <app-profile-targets></app-profile-targets>
        </div>
    </mat-tab>
</mat-tab-group>

<!-- </div> -->
