import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class DeliberService {
  checkDeliberaApi = environment.checkDelibera;
  getDeliberaApi = environment.getDelibera;

  constructor(public libService: LibService) { }

  checkDelibera(account: string, commessa: string) {
    /*
        let py = {
          account:account,
          commessa:commessa
        };
        return this.libService.postData<any>(py, this.checkDeliberaApi, '');
    
    */

    return this.libService.getData<any>(this.checkDeliberaApi, '/SetDeliberaPratica' + '?account=' + account + '&commessa=' + commessa);
  }



  getDelibera(account: string, commessa: string) {
    /*
        let py = {
          account:account,
          commessa:commessa
        };
        return this.libService.postData<any>(py, this.getDeliberaApi, '');
      */
    return this.libService.getData<any>(this.getDeliberaApi, '/GetDeliberaPratica' + '?account=' + account + '&commessa=' + commessa);
  }
}
