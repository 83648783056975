import { LocationService } from './../services/location.service';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DataSetService } from '../services/dataset.service';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSessionService } from '../services/user-session.service';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {


  displayedColumns = ['id', 'status','description'];
  dataSource: any;
  kwSearch: any;
  title = "Sedi";
  subTitle = "EDAC - Sedi";
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  currentUrl: string;

  constructor(public navService: NavService, private locationService: LocationService, public libService: LibService, private router: Router, private userSessionService: UserSessionService, private route: ActivatedRoute) {
    this.dataSource = new MatTableDataSource();
    //this.profileService.getAllProfiles().subscribe(r => this.data = r);
  }

  ngOnInit(): void {
    this.loadLocations();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
      {name:'new',url:'domain/0'},
      {name:'export',url:''},
    ];
    this.navService.setActions(actions);
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  goTo(e: any) {
    this.router.navigate(['domain/' + e.id]);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  loadLocations(): void {

    this.libService.lockPage('');
    this.locationService.getLocations().subscribe((response) => {
      console.log('loadLocations response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        console.log("RISPOSTA" + response);
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
  }
}
