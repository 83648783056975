<div class="container-fluid">
  <app-fulltextsearch2 [service]="requestService" (selected)="goTo($event)" (list)="updateList($event)"></app-fulltextsearch2>
   <div style="overflow-x: auto;">
     <app-solicit-filter [type]="'Sollecito'" (filterResult)="filterResultEmitted($event)"></app-solicit-filter>
     <div class="d-flex flex-row">

      <div class="col-4">
        <button class="btn btn-primary btn-sm me-3 text-dark reviewAdd" (click)="openEdit()">
          <div>{{'LABEL.AggiungiNuovo' | translate}}</div>
        </button>
      </div>
      <div class="col-4">
      </div>
    </div>
  
    <br>

     <app-data-table
       [data]="dataSource"
       [displayedColumns]="displayedColumns"
       [columns]="columns"
       [actions]="actions"
       sortActive="updatedAt"
       sortDirection="desc"
       ></app-data-table>
   </div>
 </div>

 <ng-template #workTypeCell let-col="col" let-element="element">
   <span *ngIf="!(element.workType==null)"><b>{{element.workType.workType}}</b></span>
   <span *ngIf="(element.workType==null)"> <i class="material-icons icsave">warning</i></span>
 </ng-template>

 <ng-template #addressCell let-col="col" let-element="element">
   <span *ngIf="!(element.building == null)"><b>{{element.building.streetName + ', ' + element.building.streetNumber + ', ' + element.building.zip + ', ' + element.building.city + ', ' + element.building.country}}</b></span>
   <span *ngIf="(element.building == null)"><i class="material-icons icsave">warning</i></span>
 </ng-template>
