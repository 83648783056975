import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EstimateDeliveryMethodService } from '../../../../services/estimate-delivery-method.service';
import { ReasonService } from '../../../../services/reason.service';
import { LibService } from '../../../../services/libService';
import { StateService } from '../../../../services/state.service';
import { TranslatorService } from '../../../../services/translate.service';

@Component({
  selector: 'app-event-building-modal',
  templateUrl: './event-building-modal.component.html',
  styleUrls: ['./event-building-modal.component.scss']
})
export class EventBuildingModal implements OnInit {

  editMode: boolean = false
  formIsActive: boolean = false
  titleLabel: string = ''
  textLabel: string = ''
  confirmButtonLabel: any = 'LABEL.SAVE'
  cancelButtonLabel: any = 'LABEL.CANCEL'

  form: FormGroup

  reasons: any = null

  actions: any = []

  estimateDeliveryMethods: any = null

  requiresNotesField: boolean = false
  localData: any
  nextStateForCancellation: any = ''

  listOfStatesAvailableForMigration: any = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private estimateDeliveryMethodService: EstimateDeliveryMethodService,
    private fb: UntypedFormBuilder,
    private reasonService: ReasonService,
    private libService: LibService,
    private stateService: StateService,
    private translate: TranslatorService
  ) {
    let formIsActive = false
    let formStructure: any = {}

    if (data) {
      console.log(data)
      this.localData = data
      if (data.actions) this.actions = data.actions
      if (data.titleLabel) this.titleLabel = data.titleLabel
      if (data.textLabel) this.textLabel = data.textLabel
      if (data.confirmButtonLabel) this.confirmButtonLabel = data.confirmButtonLabel
      if (data.cancelButtonLabel) this.cancelButtonLabel = data.cancelButtonLabel

      if (data?.listOfStatesAvailableForMigration?.length > 0){
        formIsActive = true
        data.listOfStatesAvailableForMigration.forEach((state:any) => {
          
          this.listOfStatesAvailableForMigration.push({
            id: state.stateId,
            code: state.stateCode,
            disabled: state.disabled,
            label: this.translate.instant('STATECATEGORIES.' + state.stateCategoryCode)
          })
          
        });
        this.listOfStatesAvailableForMigration.sort!((a:any, b:any) => a.label.localeCompare(b.label))
      
        formStructure['migrationState'] = [null, Validators.required]
      } 

      if (data.initEstimateDeliveryMethods) {
        this.initEstimateDeliveryMethods()
        formIsActive = true
        formStructure['EstimateDeliveryMethodId'] = [null, Validators.required]
      }

      if (data.initReasons) {
        this.initReasons(data.reasonList)
        formIsActive = true
        formStructure['ReasonCode'] = [null, Validators.required]
      }

      if (data.notes) {
        formIsActive = true
        this.requiresNotesField = true
        formStructure['notes'] = data.notes.required ? [null, Validators.required] : [null]
      }

      if (data.type) {
        formStructure['type'] = [data.type]
      }


      this.formIsActive = formIsActive
      this.form = this.fb.group(formStructure)
    }



  }

  ngOnInit(): void {
  }

  initEstimateDeliveryMethods() {
    this.libService.lockPage('');
    this.estimateDeliveryMethodService.getEstimateDeliveryMethods().subscribe(estimateDeliveryMethods => {
      console.log('getEstimateDeliveryMethods')

      this.estimateDeliveryMethods = [] // Reset
      this.estimateDeliveryMethods = estimateDeliveryMethods
      this.libService.unlockPage();
    });
  }

  initReasons(reasonList: any) {
    this.libService.lockPage('');
    let codes = reasonList.map((reason: any) => reason.reasonCode)
    this.reasonService.indexByAttributes({ code: codes }).subscribe(response => {
      console.log('indexByAttributes')
      this.reasons = [] // Reset
      this.reasons = response
      this.libService.unlockPage();
    });
  }


  disabledConfirmButton() {
    let disabled = false
    if (this.formIsActive && !this.form.valid) disabled = true
    return disabled
  }

  onChangeReasonsSelect(event: any) {
    let currentReason = this.localData.reasonList.find((reason: any) => reason.reasonCode == event.value)
    let nextStateCode = currentReason.nextStateCode
    this.nextStateForCancellation = ''
    this.stateService.getStateCategoryByCode(nextStateCode).subscribe((response)=>{
      this.nextStateForCancellation = this.translate.instant('STATECATEGORIES.' + response.code)
    })

  }

  getReasonAlert() {
    return this.nextStateForCancellation
  }

  
  onFileSelected(event: any, index: number) {
    const file: File = event.target.files[0];
    if (!file) {
        console.error('Nessun file selezionato.');
        return;
    }

    // console.log('File selezionato:', file);

    // Aggiungi il file selezionato nell'oggetto uploadedFiles
   // this.uploadedFiles[this.selectedWorkCausalDocuments[index].id] = file;
    //this.selectedFiles[index] = file;  // Per mostrare il nome del file

    // Aggiorna il FormArray con il nome del file selezionato
    const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;
    workCausalDocumentsArray.at(index).get('fileName')?.setValue(file.name);

    // Leggi il file come base64 e aggiungi il campo 'fileContent' solo per il payload
    const reader = new FileReader();
    reader.onload = () => {
        // console.log('reader: ', reader)
        const base64String = reader.result as string;
        // console.log('base64', base64String)

        // Aggiungi il contenuto base64 al payload (campo fileContent, solo per il payload)
        const formValue = workCausalDocumentsArray.at(index).value;
        formValue['fileContent'] = base64String;

        // Sostituisci il valore nel FormArray con il nuovo valore che include fileContent
        workCausalDocumentsArray.at(index).setValue(formValue);
    };

    reader.onerror = (error) => {
        console.error('Errore durante la lettura del file:', error);
    };

    // Leggi il file come base64
    reader.readAsDataURL(file);

    // Controlla la validità del form
    //this.checkFormValidity();
}


}
