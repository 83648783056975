import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { AddEvent, CancelEvent, CellClickEvent, EditEvent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { CompanyService } from 'src/app/services/companyService';
import { LibService } from 'src/app/services/libService';
import { ProfileService } from 'src/app/services/profileService';
import { TargetsService } from 'src/app/services/targets.service';


const createFormGroup = (dataItem: any) =>
  new UntypedFormGroup({
    id: new UntypedFormControl(dataItem.id),
    ingress: new UntypedFormControl(dataItem.ingress),
    regime: new UntypedFormControl(dataItem.regime),
    year: new UntypedFormControl(dataItem.year),
    january: new UntypedFormControl(dataItem.january),      //1
    february: new UntypedFormControl(dataItem.february),     //2
    march: new UntypedFormControl(dataItem.march),        //3
    april: new UntypedFormControl(dataItem.april),        //4
    may: new UntypedFormControl(dataItem.may),          //5
    june: new UntypedFormControl(dataItem.june),         //6
    july: new UntypedFormControl(dataItem.july),         //7
    august: new UntypedFormControl(dataItem.august),       //8
    september: new UntypedFormControl(dataItem.september),    //9
    october: new UntypedFormControl(dataItem.october),      //10
    november: new UntypedFormControl(dataItem.november),     //11
    december: new UntypedFormControl(dataItem.december),     //12
    profile: new UntypedFormControl(dataItem.profile),
  });



@Component({
  selector: 'app-profile-targets',
  templateUrl: './profile-targets.component.html',
  styleUrls: ['./profile-targets.component.scss']
})
export class ProfileTargetsComponent implements OnInit {
  year = new Date().getFullYear();
  maxLengthYear = 4;
  maxLengthMonth = 2;
  decimals = 0;
  decimals2 = 2;
  maxMonth = 12;
  month = ["january","february","march","april","may","june","july","august","september","october","november","december"];

  d = new Date();
  currentMonth = this.month[this.d.getMonth()];

  formAll: UntypedFormGroup;
  formType: UntypedFormGroup;
  formEdit: UntypedFormGroup;
  formProfileTargets: UntypedFormGroup;

  profiles: any;
  types: any;
  profileTargets: any;
  editRowIndex: any;
  event: any;

  whileEditing: boolean = false;
  visible: boolean  = false;

  companyTarget: any;

  selectedType: any;
  selectedYear: any;
  save: boolean = false;
  years: any[] = [];
  months: any[] = [];



  targetCommit$: Observable<any>;
  formTypeChange$: Observable<any>;
  targetTypes$ = this.targetService.getTargetTypesProfile();
  profiles$ = this.profileService.getAllProfiles().pipe(
    map((profiles: any[]) => {
      return profiles.sort((a, b) => a.name.toUpperCase() <= b.name.toUpperCase() ? -1 : 1)
    }),
    tap(profiles => {
      this.profiles = [...new Set(profiles)]//TODO: FIX
      this.profiles.push({id: 0, code: null, name: ''})
    })
  );

  constructor(public targetService: TargetsService,
              public fb: UntypedFormBuilder,
              public companyService:CompanyService,
              public profileService: ProfileService,
              private libService: LibService)
  {
    this.allData = this.allData.bind(this);
    this.generateYearArray();
    this.formProfileTargets = this.fb.group({
      id: [null],
      ingress: [null],
      regime: [null],
      description: [null],
      year: [null],
      profile: [null],
      january: [null],      //1
      february: [null],     //2
      march: [null],        //3
      april: [null],        //4
      may: [null],          //5
      june: [null],         //6
      july: [null],         //7
      august: [null],       //8
      september: [null],    //9
      october: [null],      //10
      november: [null],     //11
      december: [null]      //12
    });
    this.formEdit = this.fb.group({
      ...this.formProfileTargets.controls
    });
    this.formAll = this.fb.group({
      ...this.formProfileTargets.controls
    });
    this.formType = this.fb.group({
      targetType: [null],
      year: [null]
    });

    this.formTypeChange$ = this.formType.valueChanges.pipe(switchMap( (val: any) => {
      this.libService.lockPage('');
      this.selectedYear = val.year;
      this.selectedType = val.targetType;
      this.selectedType && this.selectedYear? this.visible = true: this.visible = false;
      if (this.visible) {

        return this.targetService.getProfileTargetsByTargetAndYear(this.selectedType, this.selectedYear).pipe(tap( val => this.libService.unlockPage()));
      } else {
        this.libService.unlockPage();
        return of(null) as Observable<any>;

      }

    }));
  }

  ngOnInit(): void {

    this.libService.lockPage('');
    combineLatest([this.targetTypes$, this.profiles$]).subscribe({
      next: ([targetTypes, profiles]: any) => {
        this.types = targetTypes;
        this.profiles = profiles;
        this.formType.patchValue({
          targetType: this.types[0],
          year: this.years[0]
        })
        this.libService.unlockPage();
      }
    });

    this.formTypeChange$.subscribe({
      next: res => {
        console.log("RESPONSE PROFILE BY TARGETS --> ", res);
        this.profileTargets = res;
        this.profileTargets? this.profileTargets.sort((a: any, b: any) =>  a.month-b.month): undefined;
        this.event? this.event.sender.closeRow(this.editRowIndex): undefined;
        this.libService.unlockPage();
      }
    });
  }

  addHandler(e: AddEvent){
    let body = {};
    let array: any[] = [];
    this.whileEditing = true;
    this.profiles.forEach((profile: any) => {
      this.years.forEach((year: any) => {
        body = { type: this.selectedType.description, year: year, profile: profile.name };
        array.push(body);
      });
    });
    console.log("OGGETTO DA SALVARE --> ", array);
    this.targetService.createProfileTargetFromArray(array).subscribe(val => {
      console.log(val);
    });

  }

  saveHandler(e: any) {
    console.log("CLICK SALVA");
    this.whileEditing = false;

    this.companyTarget = this.formProfileTargets.getRawValue();
    this.targetCommit$ = this.targetService.updateProfileTarget(this.companyTarget);

    this.libService.lockPage(' ');
    console.log("EVENTO SALVA --> ", e);
    let calls = this.targetCommit$.pipe(switchMap((val:any) => {

      e.sender.closeRow(e.rowIndex);
      return this.targetService.getProfileTargetsByTargetAndYear(this.selectedType, this.selectedYear);
    }));

    calls.subscribe((val: any) => {
      this.profileTargets = val;
      // console.log("COMPANY TARGETS --> ", this.companyTargets);

      this.profileTargets? this.profileTargets.sort((a: any, b: any) =>  a.month-b.month): undefined;
      this.libService.unlockPage();
    })
    this.formProfileTargets.reset;
  }

  cancelHandler(e: CancelEvent) {
    this.whileEditing = false;
    // console.log("EVENTO CANEL --> ", e);
    e.sender.closeRow(e.rowIndex);
  }

  editHandler(e: EditEvent) {
    this.whileEditing = true;
    console.log("DATI DA MODIFICARE --> ", e.dataItem);
    // for (const property in e.dataItem) {
    //    (property.toLowerCase().includes("date") && e.dataItem[property] !== null)?
    //     e.dataItem[property] = new Date(e.dataItem[property]): undefined;
    // }
    this.formProfileTargets.patchValue(e.dataItem);
    // console.log("FORM DI TUTTO --> ", this.formAll);
    // this.formClaims.patchValue({
    //   type: e.dataItem.type?.claimType,
    //   claimStatus: e.dataItem.claimStatus?.claimStatus
    // });
    // console.log("FORM CLAIMS --> ", this.formClaims);
    e.sender.editRow(e.rowIndex, this.formProfileTargets);
  }

  generateYearArray() {
    const moment = require('moment');
    let yToPush = this.year;
    let i = 1;

    for(let i = 0; i <= 5; i++) {
      this.years.push(yToPush);
      yToPush++;
    }

    // moment.locale('it'); // sets words language (optional if current locale is to be used)
    // moment.months() // returns a list of months in the current locale (January, February, etc.)
    // moment.months().forEach((element: any ) => {
    //   this.months.push({name: element, number: i});
    //   i++;
    // });

  }

  public editClick(e: CellClickEvent) {
    this.event = e;
    if (this.save) {
      this.saveCurrent(e);
      this.save = false;
    } else {
      console.log("CLICK RIGA");
      this.whileEditing = true;
      let idOld = this.formProfileTargets.get('id')?.value;
      this.formEdit = createFormGroup(e.dataItem);
      idOld? this.companyTarget = this.formProfileTargets.getRawValue(): e.sender.editRow(e.rowIndex, this.formEdit);;
      this.formProfileTargets.patchValue(e.dataItem);
      console.log("CHIUDI RIGA ", this.editRowIndex);
      this.editRowIndex !== undefined? e.sender.closeRow(this.editRowIndex): undefined;


      console.log("APRI RIGA ", e.rowIndex);
      e.sender.editRow(e.rowIndex, this.formEdit);
      this.editRowIndex = e.rowIndex;
      this.formProfileTargets = this.formEdit;
  }
  }

  saveCurrent(e: any) {
    this.whileEditing = false;

    this.companyTarget = this.formProfileTargets.getRawValue();
    this.targetCommit$ = this.targetService.updateProfileTarget(this.companyTarget);

    this.libService.lockPage(' ');
    let calls = this.targetCommit$.pipe(switchMap((val:any) => {

      return this.targetService.getProfileTargetsByTargetAndYear(this.selectedType, this.selectedYear);
    }));

    calls.subscribe((val: any) => {
      this.profileTargets = val;
      this.profileTargets? this.profileTargets.sort((a: any, b: any) =>  a.month-b.month): undefined;
      e.sender.closeRow(e.rowIndex);
      this.libService.unlockPage();
    })
    this.formProfileTargets.reset;
  }

  // saveCurrent(e: any) {
  //   this.targetCommit$ = this.targetService.updateCompanyTarget(this.companyTarget);

  //   this.libService.lockPage(' ');
  //   let calls = this.targetCommit$.pipe(switchMap((val:any) => {
  //     e.sender.closeRow(e.rowIndex);
  //     return this.targetService.getCompanyTargetsByTargetAndYear(this.selectedType, this.selectedYear);
  //   }));

  //   calls.subscribe((val: any) => {
  //     this.companyTargets = val;
  //     // console.log("COMPANY TARGETS --> ", this.companyTargets);
  //     this.companyTargets? this.companyTargets.sort((a: any, b: any) =>  a.month-b.month): undefined;
  //     this.libService.unlockPage();
  //     this.formProfileTargets.patchValue(e.dataItem);
  //     // console.log("CHIUDI RIGA ", this.editRowIndex);
  //     // this.editRowIndex !== undefined? e.sender.closeRow(this.editRowIndex): undefined;

  //     console.log("APRI RIGA", e.rowIndex);
  //     e.sender.editRow(e.rowIndex, this.formEdit);
  //     // this.editRowIndex = e.rowIndex;
  //     this.formProfileTargets = this.formEdit;
  //   })
  // }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.profileTargets, {
        sort: [{ field: "company", dir: "asc" }],
      }).data,

    };

    return result;
  }
}
