import { Document } from "./document"
import { Survey } from "./survey";

export class Building {
  id?: number = undefined;
  storicBuilding?: boolean= undefined;
  type?: string= undefined;
  name?: string= undefined;
  company?: string= undefined;
  companyLocation?: string= undefined;
  geographicArea?: string= undefined;
  geographicLocation?: string= undefined;
  streetName?: string= undefined;
  streetNumber?: string= undefined;
  otherInfo?: string= undefined;
  city?: string= undefined;
  province?: string= undefined;
  state?: string= undefined;
  zip?: string= undefined;
  country?: string= undefined;
  coordX?: number= undefined;
  coordY?: number= undefined;
  height?: string= undefined;
  width?: string= undefined;
  contactChannel?: string= undefined;
  origin?: string= undefined;
  numberOfFloors?: string= undefined;
  //condominium?: Condominiums
  sysCodeDelta?: string= undefined;
  notes?: string= undefined;
  createdAt?: Date= undefined;
  updatedAt?: Date= undefined;
  deletedAt?: Date= undefined;
  documents?: Document[]= undefined;
  surveys?: Survey[] = undefined;
  }
