import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, FormGroup } from '@angular/forms';

import { GenericModal } from '../modals/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestService } from '../../services/request.service';
import { ComponentService } from '../../services/component.service';
import { PracticeService } from '../../services/practice.service';
import { UserSessionService } from '../../services/user-session.service';
import { CompanyManagementService } from '../../services/company-management.service';
import { LibService } from '../../services/libService';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-request-action-buttons',
  templateUrl: './request-action-buttons.component.html',
  styleUrls: ['./request-action-buttons.component.scss']
})
export class RequestActionButtonsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isNew: any;
  @Input() components: any;
  @Input() practice: any;
  @Input() costoVenduto: any;
  @Input() order: any;
  @Input() documents: any;

  attributes: any;

  disabled: any = true;
  actions: any = [];

  componentName: any = 'request_change_state'

  objectStructure: any = {}

  buttons: any = []

  listOfStatesAvailableForMigration: any = []


  constructor(
    private dialog: MatDialog,
    private requestService: RequestService,
    private componentService: ComponentService,
    private router: Router,
    private libService: LibService,
    private practiceService: PracticeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userSessionService: UserSessionService,
    private companyManagementService: CompanyManagementService,
  ) { }

  getClass() {
    if (this.objectStructure?.settings?.type) return 'clsButton' + this.objectStructure?.settings?.type
    return 'clsButtonDefault'
  }

  getAvailableStatuses() {
    console.log('getAvailableStatuses')
    let id = this.form.get('id')?.value
    if (id) {
      this.requestService.getAvailableStatuses(id).subscribe((res: any) => {
        console.log(res)
        this.listOfStatesAvailableForMigration = res.filter((state: any) => state.disabled == false)
      })
    }

  }

  closeRequest(attributes: any) {
    this.libService.lockPage('');
    let id = this.form.get('id')?.value
    this.requestService.closeRequest(id, attributes).subscribe(
      (res: any) => {
        console.log(res)
        this.router.navigate(['request/' + id]).then(_res => {
          window.location.reload()
        })
      },
      (err: any) => {
        console.log(err)
        this.libService.unlockPage();
      },
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges request action buttons')
    console.log(changes)
    this.setActions()
    this.getButtons()
    this.setStatusButton()
  }

  ngOnInit(): void {
    console.log('ngOnInit request action buttons')
    this.setActions()
    this.setStatusButton()
    this.getAvailableStatuses()
  }

  setStatusButton() {
    //console.log('setStatusButton')
    let tmpStatus = false
    this.setAttributes()
    //if (!this.form?.valid) tmpStatus = true
    if (!this.checkNecessaryDocuments()) tmpStatus = true
    this.disabled = tmpStatus
  }

  enableCancelButton() {
    return 'cancelButton' in this.objectStructure
  }

  getCancelButtonTitleCode() {
    return this.objectStructure?.cancelButton?.code + '_title'
  }

  openModal(type: any) {
    let code = this.objectStructure?.settings?.code
    if (type == 'cancel') code = this.objectStructure?.cancelButton?.code
    if (type == 'restore') code = this.objectStructure?.restorePreviousStateButton?.code

    let dataStructure: any = {
      actions: this.actions,
      titleLabel: 'CHANGESTATEBUTTON.' + code + '_title',
      textLabel: 'CHANGESTATEBUTTON.' + code + '_text',
      type: type
    }
    console.log(this.objectStructure)

    let hasReasons = this.objectStructure?.cancelButton?.hasReasons
    if (type == 'next') dataStructure.initEstimateDeliveryMethods = this.objectStructure?.selectDeliverToCustomer?.require
    if (type == 'cancel') dataStructure.initReasons = hasReasons
    if (type == 'cancel' && hasReasons) dataStructure.reasonList = this.objectStructure?.reasonList || []

    const dialogRef = this.dialog.open(GenericModal, {
      width: '400px',
      data: dataStructure
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (![null, undefined].includes(result)) {
        let attributes = result;
        attributes.attributeCode = this.componentName
        attributes.costoVenduto = this.costoVenduto
        this.libService.lockPage('');

        if (attributes.type == 'restore') {
          this.practiceService.restorePreviousState(this.practice.id).subscribe(
            ret => {
              this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
              this.libService.unlockPage();
              this.router.navigate(['practices/' + this.practice.code]).then(_res => {
                window.location.reload()
              })
            },
            err => {
              //this.toastr.error(this.translate.instant('LABEL.Error'));
              this.libService.unlockPage();
            }
          );
        } else {
          this.practiceService.changeState(this.practice.id, attributes, this.componentName, type).subscribe(
            ret => {
              this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
              this.libService.unlockPage();
              this.router.navigate(['practices/' + this.practice.code]).then(_res => {
                window.location.reload()
              })
            },
            err => {
              //this.toastr.error(this.translate.instant('LABEL.Error'));
              this.libService.unlockPage();
            }
          );
        }

      }

    });


  }

  checkFormValidityAndNotDirty() {
    return this.form?.valid && !this.form?.dirty
  }

  disabledButton(button: any) {
    if (button.checkFormValidity) return !this.checkFormValidityAndNotDirty()
    if (button.checkPresenceOfNecessaryDocuments) return this.disabled
    return false
  }

  getButtons() {
    console.log('getButtons')
    this.buttons = [] // reset
    this.objectStructure?.buttons?.forEach((button: any) => {
      let transationKeys = ['buttonTranslationCode', 'modalTitleTranslationCode', 'modalBodyTranslationCode']
      let currentButton: any = {
        code: button?.code,
        order: button?.order,
        class: button?.buttonType,
        reasonList: [],
        checkFormValidity: button?.checkFormValidity,
        checkPresenceOfNecessaryDocuments: button?.checkPresenceOfNecessaryDocuments,
        notesFieldVisible: button?.notesFieldVisible,
        mandatoryNotesField: button?.mandatoryNotesField,
        migrationStates: button?.migrationStates,
      }

      // Aggiunta delle motivazioni se presenti
      let currentReasonList = this.objectStructure?.reasonList?.filter((reason: any) => reason.reasonGroupCode == button.reasonGroupCode)
      if (currentReasonList) currentButton.reasonList = currentReasonList

      // Aggiunta delle traduzioni
      transationKeys.forEach((key: any) => {
        let currentTransation = this.objectStructure?.translations.find((translation: any) => translation.code == button?.[key]) || []
        if (currentTransation) {
          currentButton[key] = {}
          currentTransation?.translations?.forEach((t: any) => {
            currentButton[key][t.languageCode] = t.text;
          })
        }
      })

      this.buttons.push(currentButton)
    })
    this.buttons.sort((a: any, b: any) => a.order - b.order);
    console.log(this.buttons)
  }

  getCurrentLanguage() {
    return this.userSessionService.getCurrentLanguage()?.toUpperCase() || 'IT';
  }

  getAttributes(componentCode: any) {
    let component = this.components?.settings?.find((component: any) => component.componentCode == componentCode)
    return component?.attributes
  }

  checkNecessaryDocuments() {
    //console.log('checkNecessaryDocuments - app-request-close-button')
    let key = 'request_document_manager'
    if (!this.checkComponentFast(key)) key = 'crmDocumentManager'
    let attributes = this.getAttributes(key)
    return this.componentService.checkDocumentsStatus(key, attributes, this.form, this.documents)
  }

  checkComponentFast(componentName: any = null) {
    let key = componentName || this.componentName
    let status = this.components?.codes?.includes(key)
    return status
  }

  setActions() {
    this.setAttributes()
    if (this.attributes) {
      this.actions = []
      let structure: any = this.componentService.getStructure(this.attributes)
      structure.forEach((s: any) => {
        if (s.type == 'action' && !this.actions.includes(s.type)) this.actions.push(s.code)
      })
      //console.log(this.actions)
    }
  }

  setAttributes() {
    console.log('setAttributes')
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
    if (this.attributes) this.objectStructure = this.componentService.getObjectStructure(this.attributes)
    //console.log('this.objectStructure')
    console.log(this.objectStructure)
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) {
      this.setAttributes()
      this.setActions()
      this.setStatusButton()
    }
    return status
  }

  checkStateList() {
    return 'stateList' in this.attributes
  }

  checkrestorePreviousStateButton() {
    if (this.attributes) {
      return 'restorePreviousStateButton' in this.attributes
    }
    return false
  }

  restoreDisabled(documentControlRequired: any = false) {
    return documentControlRequired ? this.disabled : false
  }

  goTo(e: any) {
    this.setPracticeCompanyOnUI()
    this.router.navigate([e]);
  }

  setPracticeCompanyOnUI() {
    let user = this.userSessionService.getState('user');
    let workingCompany = this.userSessionService.getState('working_company');

    // Setta la company della pratica
    if (workingCompany == null || workingCompany.id != this.practice.idSede) {
      let tmp_company = user.UsersCompaniesRel.find((c: any) => c.id == this.practice.idSede);
      let res = {
        isOpen: tmp_company.isOpen,
        name: tmp_company.name,
        code: tmp_company.code,
        id: tmp_company.id,
        country: tmp_company?.country
      }
      this.userSessionService.saveState('working_company', res);

      this.companyManagementService.add(res);

    }
  }

  buttonAction(buttonStructure: any) {
    console.log('buttonAction')
    console.log(buttonStructure)

    let code = buttonStructure.code
    // if (type == 'cancel') code = this.objectStructure?.cancelButton?.code
    // if (type == 'restore') code = this.objectStructure?.restorePreviousStateButton?.code

    let textLabel = buttonStructure.modalBodyTranslationCode && Object.keys(buttonStructure.modalBodyTranslationCode).length ? buttonStructure.modalBodyTranslationCode : 'LABEL.testoCortesiaModale'

    let dataStructure: any = {
      actions: this.actions,
      titleLabel: buttonStructure.modalTitleTranslationCode,
      textLabel: textLabel,
      type: code,
    }
    console.log(this.objectStructure)

    if (buttonStructure.migrationStates) {
      dataStructure.listOfStatesAvailableForMigration = this.listOfStatesAvailableForMigration;
    }


    if (buttonStructure.notesFieldVisible || buttonStructure.mandatoryNotesField) {
      let required = buttonStructure.mandatoryNotesField || false
      dataStructure.notes = { required: required };
    }


    dataStructure.initReasons = buttonStructure.reasonList.length > 0
    dataStructure.reasonList = buttonStructure.reasonList || []

    console.log(dataStructure)

    const dialogRef = this.dialog.open(GenericModal, {
      width: '400px',
      data: dataStructure
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (![null, undefined].includes(result)) {
        let attributes = result;
        attributes.attributeCode = this.componentName
        console.log(attributes)
        this.libService.lockPage('');


        let requestId = this.form.get('id')?.value


        this.requestService.changeState(requestId, attributes).subscribe((res: any) => {
          console.log(res)
          this.libService.unlockPage();
          window.location.reload()
        })



      }

    });

  }

}
