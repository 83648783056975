<form [formGroup]="form">
<mat-form-field appearance="fill" class="form-subject">
  <mat-label>{{ 'REVIEWS.insertSubjectType' | translate }}</mat-label>
  <mat-select formControlName="insertSubjectType" (selectionChange)="insertSubjectTypeChange($event)">
    <mat-option *ngFor="let insertSubjectType of insertSubjectTypes" [value]="insertSubjectType">
      {{ 'INSERTSUBJECTTYPE.' + insertSubjectType | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>

<div *ngIf="getInsertSubjectType() == 'search'">

  <app-fulltextsearch2 class="form-subject" [service]="subjectService" searchType="Soggetto"
    (selected)="selSubject($event)" [edit]="false"></app-fulltextsearch2>

  <div *ngIf="theSubjectIsPresent()">

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
      <input matInput readonly [value]="getSubject()?.name" [placeholder]="translate.instant('REQUEST.NAME')">
    </mat-form-field>

    <mat-form-field appearance="fill" class="form-subject">
      <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
      <input matInput readonly [value]="getSubject()?.surname" [placeholder]="translate.instant('REQUEST.SURNAME')">
    </mat-form-field>

    <mat-form-field *ngIf="getSubject()?.legalEntity" appearance="fill" class="form-subject">
      <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
      <input matInput readonly [value]="getSubject()?.legalEntity"
        [placeholder]="translate.instant('REQUEST.LEGAL_ENTITY')">
    </mat-form-field>

    <mat-form-field *ngIf="getSubject()?.email" appearance="fill" class="form-subject">
      <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
      <input matInput readonly [value]="getSubject()?.email" [placeholder]="translate.instant('REQUEST.EMAIL')">
    </mat-form-field>

    <mat-form-field *ngIf="getSubject()?.telephone2" appearance="fill" class="form-subject">
      <mat-label>{{ 'LABEL.PHONE' | translate }}</mat-label>
      <input matInput readonly [value]="getSubject()?.telephone2" [placeholder]="translate.instant('LABEL.PHONE')">
    </mat-form-field>


  </div>

</div>

<div formGroupName="subject" *ngIf="getInsertSubjectType() == 'new'" class="form-container">

  <mat-form-field appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
    <input matInput formControlName="name" [placeholder]="translate.instant('REQUEST.NAME')">
  </mat-form-field>

  <mat-form-field appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
    <input matInput formControlName="surname" [placeholder]="translate.instant('REQUEST.SURNAME')">
  </mat-form-field>

  <mat-form-field appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
    <input matInput formControlName="legalEntity" [placeholder]="translate.instant('REQUEST.LEGAL_ENTITY')">
  </mat-form-field>

  <mat-form-field appearance="fill" class="form-subject">
    <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
    <input matInput formControlName="email" [placeholder]="translate.instant('REQUEST.EMAIL')">
  </mat-form-field>

  <mat-form-field appearance="fill" class="form-subject">
    <mat-label>{{ 'LABEL.PHONE' | translate }}</mat-label>
    <input matInput formControlName="telephone2" [placeholder]="translate.instant('LABEL.PHONE')">
  </mat-form-field>

</div>

<div *ngIf="getInsertSubjectType() == 'alias'">
  <mat-form-field appearance="fill" class="form-subject">
    <mat-label>{{ 'REVIEWS.subjectAlias' | translate }}</mat-label>
    <input matInput formControlName="subjectAlias" [placeholder]="translate.instant('REVIEWS.subjectAlias')">
  </mat-form-field>
</div>
</form>