
<div class="container-fluid">
    <div class="search-wrapper mat-elevation-z2">
      <div class="search" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
        <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
        [placeholder]="translate.instant('LABEL.SEARCH')" />
      </div>
    </div>
    <div style="overflow-x: auto;">
    <table mat-table [dataSource]="dataSource" matSort style="width:100%">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.ID' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.VERSION' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.version}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="n_claim_marsh">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.N_CLAIM_MARSH' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.n_claim_marsh}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="n_claim_client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.N_CLAIM_CLIENT' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.n_claim_client}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.START_DATE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element.startDate}} </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.END_DATE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.endDate}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="policyNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.POLICY_NUMBER' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.policyNumber}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="delegatingCompany">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.DELEGATING_COMPANY' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.delegatingCompany}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="mainGuarantee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.MAIN_GUARANTEE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.mainGuarantee}}</b></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="claimCompanyNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CLAIM_COMPANY_NUMBER' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.claimCompanyNumber}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="complainant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.COMPLAINANT' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.complainant}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.DESCRIPTION' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.description}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.TYPE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.type}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="cause">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CAUSE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.cause}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="claimStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CLAIM_STATUS' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.claimStatus}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LOCATION' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.location}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="province">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.PROVINCE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.province}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="damageValue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.DAMAGE_VALUE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.damageValue}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="deductible">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.DEDUCTIBLE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.deductible}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="due">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.DUE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.due}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="paid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.PAID' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.paid}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="isLastVersion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LAST_VERSION' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.isLastVersion}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.STATUS' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span *ngIf="!(element.status == null)">{{element.status.uiLabel  | translate}}</span><span *ngIf="(element.status == null)">{{'LABEL.NOT_PRESENT'  | translate}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.CREATION_DATE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.createdAt | date}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LABEL.LASTMODIFIED_DATE' | translate }}</th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.updatedAt | date}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="editAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="goTo(element)"><i class="material-icons ic">edit</i>
      </ng-container>

      <ng-container matColumnDef="deleteAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="deleteRow(element)"><i class="material-icons ic">delete</i>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </div>
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
    </mat-paginator>
  </div>
