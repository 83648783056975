import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Dashboard } from '../models/dashboard';
import { LibService } from './libService';
import { LogService } from './log.service';
import { UserSessionService } from './user-session.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseApi = environment.rootApiFoundation;
  lambdaBaseApi = environment.lambdaBaseUrl;

  constructor(
    public libService: LibService, 
    private authService: AuthService, 
    private logService: LogService,
    private userSessionService: UserSessionService,
  ) { }

  getAllDashboards() {
    return this.libService.getData<any>(this.baseApi + 'dashboards', '');
  }

  getDashboard(id: string) {
    return this.libService.getData<Dashboard>(this.baseApi + 'dashboards/', id.toString());
  }

  createOne(dashboard: Dashboard) {
    return this.libService.postData<Dashboard>(dashboard, this.baseApi + 'dashboards', '')
  }

  updateOne(dashboard: Dashboard) {
    return this.libService.putData<Dashboard>(dashboard, this.baseApi + 'dashboards/', dashboard.id.toString())
  }

  getDashboardUrl(dashboardId: string, userEmail: any = null) {

    let authServiceUser = this.authService.user()
    let userSessionServiceUser  = this.userSessionService.getState('user');
    let sessionEmail = userSessionServiceUser?.email || authServiceUser?.email?.toLowerCase()

    let email = userEmail || sessionEmail
    let body = {
      user: {
        email: email
      },
      dashboard: {
        id: dashboardId
      }
    }
    return this.libService.postData<any>(body, this.baseApi + 'dashboards/', 'getDashUrl').pipe(
      tap(response => {
        this.logService.writeLog('POST', this.baseApi + 'dashboards/' + 'getDashUrl', body.dashboard, 'OK')
      }),
      catchError(error => {
        this.logService.writeLog('POST', this.baseApi + 'dashboards/' + 'getDashUrl', body.dashboard, error.status)
        return of(error);
      })
    )
  }

  getDashboardUrlNew() {
    let authServiceUser = this.authService.user()
    let userSessionServiceUser  = this.userSessionService.getState('user');
    let email = userSessionServiceUser?.email || authServiceUser?.email?.toLowerCase()
    let body = {
      user: {
        email: email
      }
    }
    //console.log('authStore %o localStorage %o authQuery %o',this.authStore, localStorage, u );
    return this.libService.postData<any>(body, this.baseApi + 'dashboards/', 'getDashUrlNew')
    //return this.libService.getData(this.lambdaBaseApi + 'get-qs-embed-url-anon' + `?dashboardId=${dashboardId}&qsCountry=${qsCountry}&user=` + u, '');
  }

  getAttributes(): Observable<any[]> {
    return this.libService.postData(null, `${this.baseApi + 'dashboards/'}/attributes`, '')
  }

  getCockpit(): Observable<any>{
    let authServiceUser = this.authService.user()
    let userSessionServiceUser  = this.userSessionService.getState('user');
    let email = userSessionServiceUser?.email || authServiceUser?.email?.toLowerCase()

    let body = {
      user: {
        email: email
      }
    }
    return this.libService.postData(body, `${this.baseApi + 'dashboards'}/getCockpit`, '')
  }
}
