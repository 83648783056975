<h1 mat-dialog-title>Aggiungi Partecipanti</h1>

<div mat-dialog-content>

  <div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
        <input type="text" [(ngModel)]="kwSearchUser" [value]="kwSearchUser" (ngModelChange)="searchUser($event)"
            [placeholder]="translate.instant('LABEL.SEARCH')" />
    </div>
  </div>
  <br>
  <app-data-table [data]="users" [columns]="columns" [displayedColumns]="displayedColumns"
  [actions]="actions" sortActive=""></app-data-table>
  
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="">Annulla</button>
  <button mat-button [mat-dialog-close]="usersSelected">Aggiungi</button>
</div>