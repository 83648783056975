import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SimpleChanges, ChangeDetectorRef  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';

import { debounceTime, distinctUntilChanged, forkJoin, map, mergeMap, Observable, of, Subject, Subscriber, Subscription, switchMap, tap } from 'rxjs';


@Component({
  selector: 'app-formfield-search',
  templateUrl: './formfield-search.component.html',
  styleUrls: ['./formfield-search.component.scss'],
})
export class FormfieldSearchComponent implements OnInit {

  @Input() service: any;
  @Input() component: any;
  @Input() searchType: any;
  @Input() form: UntypedFormGroup;
  @Input() controlName: any;
  @Input() readonly: any;
  @Input() events: Observable<void>;

  @Output() selected = new EventEmitter;
  @Output() clearOut = new EventEmitter;
  @Output() list = new EventEmitter;
  @Output() formOutput = new EventEmitter;

  get field() {
    let campo: string = '';
    switch (this.service.name) {
      case "SubjectService":
        campo = "idSubject";
        break;

      case "BuildingService":
        console.log("BUILDING")
        campo = "idBuilding";
        break;

      case "RequestService":
        console.log("REQUEST")
        campo = "idRequest";
        break;

      case "OrderService":
        console.log("order")
        campo = "orderId";
        break;
    }
    return campo;
  }

  fullTextSearchSubscriber: Subscriber<any>;
  myControl: UntypedFormControl = new UntypedFormControl('');
  formFieldSearch: UntypedFormGroup;
  richiesteDB: any[] = [];
  kwSearch: any;
  options: any;
  subList: any = [];
  optionList: Array<any> = [];
  label: any;
  input = new Subject<string>();
  selectedSub: any;
  clearFields: boolean = false;
  subject: any;
  request: any;
  private eventsSubscription: Subscription;

  inputValue$ = this.input.pipe(debounceTime(300), distinctUntilChanged());
  searchIds$ = this.inputValue$.pipe(
    switchMap((val) => val.length > 2? this.service.getSubjectIdsFTSearch({ kwsearch: val.toLowerCase() }) as Observable<any>: this.optionList = []),
    tap((subs: any) => {
      console.log("Soggetti --> ", subs)
      subs.length == 0? this.optionList = [] : undefined;
    }),
    map(subs => subs.data)
  );
  // subjects$ = this.searchIds$.pipe(
  //   mergeMap(subs => {
  //     return subs.length > 0?
  //       forkJoin(subs.map((sub: any) =>
  //         this.service.getById(sub[this.field])
  //         ))
  //       :
  //         this.optionList = [];
  // }));

  subjects$ = this.searchIds$;
  // pipe(
  //   mergeMap(subs => {
  //     let ret: any;
  //     this.richiesteDB = [];
  //     if (subs.length > 0) {
  //       forkJoin(subs.map((sub: any) => {
  //         this.richiesteDB.push(sub[this.field]);
  //       }))
  //       ret = this.service.getById(this.richiesteDB)
  //     }
  //     else {
  //       ret = of([]);
  //     }
  //     return ret;
  //   }))



  constructor(private fb: UntypedFormBuilder,
              public translate: TranslateService) {
    this.formFieldSearch = this.fb.group({
      subjectField: [null, Validators.required],
      requestField: [null],
      orderField: null
    });
  }

  ngOnInit(): void {

    if (this.events !== undefined && this.events !== null) {
      this.eventsSubscription = this.events.subscribe(() =>{
        console.log("change search type -->");
        this.kwSearch = "";
      });
    }

    this.form?.valueChanges.subscribe(formValue => {
      console.log("FORM VALUE --> ", formValue);
      formValue?.subject? this.subject = formValue.subject: undefined;
      this.subject? this.formFieldSearch.patchValue({ subjectField: this.subject.name + ' ' + this.subject.surname }): undefined;
      formValue?.complainant? this.subject = formValue.complainant: undefined;
      this.subject? this.formFieldSearch.patchValue({ subjectField: this.subject.name + ' ' + this.subject.surname }): undefined;
    })

    this.assignLabel();
    this.searchType? this.kwSearch = '': undefined;

    this.subjects$.subscribe((res:any) => {
      this.optionList = res;
    });

    this.subject = this.form?.get('complainant')?.value;
    if (this.subject !== undefined && this.subject !== null) {
      this.kwSearch = this.subject.name + ' ' + this.subject.surname;
    }
    this.subject = this.form?.get('promotingSubject')?.value;
    if (this.subject !== undefined && this.subject !== null) {
      this.kwSearch = this.subject.name + ' ' + this.subject.surname;
    }
  }

  fullTextSearch(event: any) {
    if (!event) {
      this.optionList = [];
    }else {
      event.length > 2? this.input.next(event) : undefined
    }
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.selectedSub = event.option.value;
    let nameToPatch = this.selectedSub.name? this.selectedSub.name: undefined;
    this.selectedSub.surname? nameToPatch = nameToPatch + ' ' + this.selectedSub.surname: undefined;
    // console.log("SOGGETTO SELEZIONATO  --> ", this.selectedSub)
    this.controlName == 'subjectField'? this.formFieldSearch.patchValue({ subjectField: nameToPatch }): undefined;
    // this.kwSearch = event.option.value.name;
    this.selected.emit(this.selectedSub);
    this.formOutput.emit(this.form);

  }

  clear() {
    this.clearFields = true;
    this.kwSearch = "";
    // this.clearOut.emit(this.clearFields);
    this.clearFields = false;
  }

  assignLabel() {
    switch (this.controlName) {
      case 'subjectField':
        this.label = "Soggetto Associato all'attività";
        break;
      case 'requestField':
        this.label = "Richiesta Associata all'attività";
        break;
      case 'orderField':
        this.label = "Pratica Associata all'attività";
        break;
    }
  }
}
