<form [formGroup]="form" *ngIf="form && checkComponent()">
  <mat-card>
    <mat-card-title>
      {{ 'LABEL.SUBJ' | translate }}
      <button *ngIf="checkVisibility('reload_subject')" class="my-bounce btn btn-primary mx-2"
        style="background-color:transparent;" (click)="clearForm('subject')">
        <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
      </button>
    </mat-card-title>
    <div>
      <div formGroupName="subject" class="form-container">
        <app-fulltextsearch2 *ngIf="checkVisibility('search')" class="form-subject" [service]="subjectService"
          searchType="Soggetto" (selected)="selSubject($event)"
          [edit]="verifyDeactivation('search')"></app-fulltextsearch2>

        <app-crm-form [mainForm]="form" class="subjectFormBox" [documentsSection]="false" [companies]="companies" [subjectAttributes]="attributes"></app-crm-form>


      </div>
    </div>
  </mat-card>
</form>