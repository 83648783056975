import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslatorService } from '../services/translate.service';

@Injectable({
  providedIn: 'root'
})
export class ElecSignService {

  baseApi = environment.elecSign;
  keyApi = environment.elecApiKey;

  constructor(
    private http: HttpClient,
    private translate: TranslatorService,
  ) { }

  getFileExample(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html'
      })
    };
    return this.http.get<any>('http://localhost:4200/assets/img/testFirmaElettronica.pdf',httpOptions);
  }

  postFile(fileName: any, content: any): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.keyApi
      })
    };

    let py = {
      name:fileName,
      content:content
    }

    return this.http.post<any>(this.baseApi + 'files', py, httpOptions);
  }

  postDocument(filePath: any): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + this.keyApi
      })
    };

    let py = new FormData();

    py.append('file', filePath);
    py.append('nature', "signable_document");
    py.append('parse_anchors', "true");

    return this.http.post<any>(this.baseApi + 'documents', py, httpOptions);
  }

  postRequest(documentId: string, email: string, name: string, surname: string, phone_number: string, locale: string, code: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.keyApi
      })
    };
    let py = {
      name: code,
      delivery_mode: "email",
      timezone: "Europe/Rome",
      documents: [
        documentId
      ],
      signers: [
        {
          info: {
            first_name: name,
            last_name: surname,
            email: email,
            phone_number: phone_number,
            locale: locale
          },
          custom_text: {
            request_subject: this.translate.instant('ELEC_SIGN.request_subject'),
            request_body: this.translate.instant('ELEC_SIGN.request_body'),
            reminder_subject: this.translate.instant('ELEC_SIGN.reminder_subject'),
            reminder_body: this.translate.instant('ELEC_SIGN.reminder_body')
          },
          signature_level: "advanced_electronic_signature",
          signature_authentication_mode: "otp_email"
        }
      ]
    }
    return this.http.post<any>(this.baseApi + 'signature_requests', py, httpOptions);
  }

  postActivateRequest(signatureRequestId: any): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.keyApi
      })
    };

    return this.http.post<any>(this.baseApi + 'signature_requests/' + signatureRequestId + '/activate', {}, httpOptions);

  }

}