import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MacroPhaseService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
  ) { }

  createMacroPhase(macrophase: any): Observable<any> {
    return this.libService.postData<any>(macrophase, this.baseApi, 'macrophases');
  }

  updateMacroPhase(macrophase: any): Observable<any> {
    return this.libService.putData<any>(macrophase, this.baseApi, `macrophases/${macrophase.id.toString()}`);
  }

  deleteMacroPhase(macrophase: any): Observable<any> {
    return this.libService.deleteData<any>(macrophase, this.baseApi, `macrophases/${macrophase.id.toString()}`);
  }

  getAllMacroPhases(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, 'macrophases/'); 
  }

  getMacroPhase(id: string) {
    return this.libService.getData<any>(this.baseApi, `macrophases/${id.toString()}`);
  }

  getMacroPhasesByLangCode(code:string){
    return this.libService.getData<any>(this.baseApi, `macrophases/langcode/${code.toString()}`);
  }
}