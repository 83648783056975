import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectFilterComponent } from './subject-filter/subject-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../shared/shared.module';
import { SubjectComponent } from './subject.component';
import { FullTextSearchModule } from '../fulltextsearch2/fulltextsearch.module';
import { MatRadioModule } from '@angular/material/radio';
import { SubjectDetailComponent } from './subject-detail/subject-detail.component';
import { TypeRadioComponent } from './subject-detail/type-radio/type-radio.component';
import { SubjectsPracticesComponent } from './subject-detail/subjects-practices/subjects-practices.component';
import { SubjectSurveyComponent } from './subject-detail/subject-survey/subject-survey.component';
import { SubjectRelationsComponent } from './subject-detail/subject-relations/subject-relations.component';
import { SubjectMarketingComponent } from './subject-detail/subject-marketing/subject-marketing.component';
import { SubjectLocalizationComponent } from './subject-detail/subject-localization/subject-localization.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { SubjectDocumentsComponent } from './subject-detail/subject-documents/subject-documents.component';
import { SubjectDataComponent } from './subject-detail/subject-data/subject-data.component';
import { SubjectConsentsComponent } from './subject-detail/subject-consents/subject-consents.component';
import { ActivitiesModule } from '../activities/activities.module';
import { SelectSearchSubjectComponent } from './select-search-subject/select-search-subject.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const MATERIAL_MODULES = [
  MatIconModule,
  MatFormFieldModule,
  MatRadioModule,
  MatTableModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatTabsModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatInputModule,
  MatSelectModule
]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    SubjectFilterComponent,
    SubjectComponent,
    SubjectDetailComponent,
    TypeRadioComponent,
    SubjectsPracticesComponent,
    SubjectSurveyComponent,
    SubjectRelationsComponent,
    SubjectMarketingComponent,
    SubjectLocalizationComponent,
    SubjectDocumentsComponent,
    SubjectDataComponent,
    SubjectConsentsComponent,
    SelectSearchSubjectComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    GooglePlaceModule,
    GoogleMapsModule,
    FullTextSearchModule,
    HttpClientModule,
    SharedModule,
    NgxMatSelectSearchModule,
    ActivitiesModule,
    FullTextSearchModule,
    TranslateModule.forChild(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        },
      }
    ),
    MATERIAL_MODULES
  ],
  exports: [
    SubjectComponent,
  ]
})
export class SubjectModule { }
