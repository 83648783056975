export class DataSet {
  id?: number;
  name?: string;
  description?: string;
  sqlString?: string;
  howMany?: number;
  createdAt?: string;
  updatedAt?: string;
  DatasetsSubjectsRel?: any[];
  DatasetsBuildingsRel?: any[];
  DatasetsDashboardsRel?: any[];
  subjects?: any[];
  buildings?: any[];
  dashboards?: any[]
}
