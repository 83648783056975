import { GanttTask } from './gantt';
import { PhasePlan } from './phase-plan';

export class MultiTask implements GanttTask {
  id: number;
  start_date: Date;
  end_date: Date;
  text: string;
  progress: number;
  duration: number;
  parent: number;
  userId: number;
  orderId: number;
  class: string = 'child-task';

  constructor(user: any, phase: PhasePlan, orderId: number, parent: number) {
    const startDate = phase.tasks[0].tsStart;
    const endDate =
      phase.tasks[phase.tasks.length - 1].tsEnd;

    this.id = phase.id;
    this.userId = user.id;
    this.orderId = orderId;
    this.text = phase.descPhase ?? phase.freeDescription;
    this.start_date = new Date(Number(startDate) * 1000);
    this.end_date = new Date(Number(endDate) * 1000);
    this.parent = parent;
  }
}
