import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class PracticeService extends BaseService {

  name = 'PracticeService';

  constructor(
    public LibService: LibService,
    public AuthService: AuthService,
    public userSessionService: UserSessionService,
    public guardService: GuardService
  ) {
    super(LibService, AuthService)
    this.baseApi = environment.rootApiPaperwork
    this.model = 'practices'
  }




  indexWithPagination(user: any, filterOptions: any, orderOptions: any, skip: number, take: number, enableFinancialData: true): Observable<any[]> {
    let tokenId = this.authService.idToken()

    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      user: user,
      filters: filterOptions,
      order: orderOptions,
      skip: skipOption,
      take: takeOption,
      enableFinancialData: enableFinancialData || false
    }

    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `${this.model}/withPagination`)
  }

  changeState(id: any, attributes: any, componentName: any, buttonType: any): Observable<any> {
    let tokenId = this.authService.idToken()

    let options = {
      EstimateDeliveryMethodId: attributes.EstimateDeliveryMethodId,
      componentName: componentName,
      buttonType: buttonType,
      ReasonCode: attributes.ReasonCode,
      costoVenduto: attributes.costoVenduto,
      currentSignaler: attributes.currentSignaler,
    }

    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `${this.model}/${id}/changeState`)
  }

  stateMigration(code: any, stateId: any, note: any = '', reasonCode: any = null): Observable<any> {
    let tokenId = this.authService.idToken()

    let options = {
      note: note,
      stateId: stateId,
      reasonCode: reasonCode
    }

    return this.libService.postDataWithAccessToken<any>(tokenId, options, this.baseApi, `${this.model}/${code}/stateMigration`)
  }

  setAsNonProgrammable(id: any, attributes: any): Observable<any> {
    let tokenId = this.authService.idToken()

    return this.libService.postDataWithAccessToken<any>(tokenId, attributes, this.baseApi, `${this.model}/${id}/setAsNonProgrammable`)
  }

  restorePreviousState(id: any): Observable<any> {
    let tokenId = this.authService.idToken()

    return this.libService.postDataWithAccessToken<any>(tokenId, {}, this.baseApi, `${this.model}/${id}/restorePreviousState`)
  }

  getHistory(id: any): Observable<any> {
    let tokenId = this.authService.idToken()

    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `${this.model}/${id}/getHistory`)
  }

  getCrmReports(id: any): Observable<any> {
    let tokenId = this.authService.idToken()

    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `${this.model}/${id}/getCrmReports`)
  }

  downPaymentCheck(id: any): Observable<any> {
    let tokenId = this.authService.idToken()

    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `${this.model}/${id}/downPaymentCheck`)
  }

  getforbalancing(companyIds: any): Observable<any> {
    let tokenId = this.authService.idToken()
    let attributes = {
      companyIds: companyIds
    }

    return this.libService.postDataWithAccessToken<any>(tokenId, attributes, this.baseApi, `${this.model}/getforbalancing`)
  }

  addConvention(id: number, conventionId: number): Observable<any> {
    let tokenId = this.authService.idToken()
    let attributes = {
      ConventionId: conventionId
    }

    return this.libService.putDataWithAccessToken<any>(tokenId, attributes, this.baseApi, `${this.model}/${id}/addConvention`)
  }

  getAvailableStatuses(code: any): Observable<any> {
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `${this.model}/${code}/getAvailableStatuses`)
  }

  getSubjectIdsFTSearch(kwsearch: any, filterIDS?: any) {
    let tokenId = this.authService.idToken()
    console.log('TRIGGER RICERCA --> ', kwsearch);
    console.log('[Filtri per ricerca] -> ', filterIDS);
    if (
      (filterIDS?.buildingId !== 0 && filterIDS?.buildingId !== null) ||
      (filterIDS?.subjectId !== 0 && filterIDS?.subjectId !== null)
    ) {
      return this.libService.postDataWithAccessToken<any>(
        tokenId,
        { kwsearch: kwsearch, filterIDS: filterIDS },
        this.baseApi,
        `${this.model}/search`
      );
    } else {
      return this.libService.postDataWithAccessToken<any>(
        tokenId,
        { kwsearch: kwsearch },
        this.baseApi,
        `${this.model}/search`
      );
    }
  }

  getKw(code: any): Observable<any> {
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `${this.model}/${code}/getKw`)
  }

  getElectricianSetup(code: any): Observable<any> {
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `${this.model}/${code}/getElectricianSetup`)
  }

  buttonChangeState(id: any, attributes: any) {
    console.log("buttonChangeState")
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<any>(tokenId, attributes, this.baseApi, `${this.model}/${id}/buttonChangeState`)
  }

  changeSalesManager(id: any, respCommerciale: any,): Observable<any> {
    let tokenId = this.authService.idToken()
    return this.libService.postDataWithAccessToken<any>(tokenId, respCommerciale, this.baseApi, `${this.model}/${id}/changeSalesManager`)
  }

  

}