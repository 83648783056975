import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { UserSessionService } from 'src/app/services/user-session.service';

@Component({
  selector: 'app-user-area-edit-modal',
  templateUrl: './user-area-edit-modal.component.html',
  styleUrls: ['./user-area-edit-modal.component.scss']
})
export class UserAreaEditModalComponent implements OnInit {

  editMode: boolean = false
  form: UntypedFormGroup
  languages = [{
    code: 'en',
    name: 'English'
  },
  {
    code: 'it',
    name: 'Italiano'
  }]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private userService: UserSessionService,
    private authService: AuthService)
  {
    this.form = this.fb.group({
      id: '',
      email: [null, [Validators.required, Validators.email]],
      address: '',
      locale: [null, [Validators.required]]
    })

    if(data){
      this.form.patchValue(data)
    }

  }

  ngOnInit(): void {
  }

}
