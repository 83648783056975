import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service'


@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  baseApi = environment.rootApiFoundation;
  model = 'users'

  constructor(
    public libService: LibService,
    private authService: AuthService
  ) {}

  getResources(usr: string, id: any, p: any): Observable<any[]> {
    console.log(this.baseApi);
    return this.libService.getData<any[]>(
      this.baseApi,
      `users/all/${usr.toString()}/${id}/${p}`
    );
  }

  getResourcesByCompany(
    usr: string,
    id: any,
    p: any,
    cp: any
  ): Observable<any[]> {
    console.log(this.baseApi);
    return this.libService.getData<any[]>(
      this.baseApi,
      `users/allbycompany/${usr.toString()}/${id}/${p}/${cp}`
    );
  }

  getAllUsers(): Observable<any> {
    return this.libService.getData<any>(this.baseApi + 'users', '');
  }

  getAllUsersFast(): Observable<any> {
    return this.libService.getData(this.baseApi + 'users', '/fast');
  }

  getUser(id: string) {
    return this.libService.getData<any>(this.baseApi + 'users/', id.toString());
  }

  getByProfiles(profiles: number[]): Observable<any> {
    return this.libService.postData<any>(
      { profiles },
      this.baseApi + 'users/',
      'getByProfiles'
    );
  }

  getByCompanyCode(companies: string[]): Observable<any> {
    return this.libService.postData<any>(
      { companies },
      this.baseApi + 'users/',
      'getByCompanyCode'
    );
  }

  setDatasets(user: any) {
    return this.libService.postData<any>(
      user,
      this.baseApi + 'users/',
      'setDatasets'
    );
  }

  addDatasets(user: any, datasets: any[]) {
    return this.libService.postData<any>(
      { user, datasets: datasets },
      this.baseApi + 'users/',
      'addDatasets'
    );
  }

  deleteDatasets(user: any, datasets: any[]) {
    return this.libService.postData<any>(
      { user, datasets: datasets },
      this.baseApi + 'users/',
      'deleteDatasets'
    );
  }

  setCompanies(user: any) {
    return this.libService.postData<any>(
      user,
      this.baseApi + 'users/',
      'setCompanies'
    );
  }

  addCompanies(user: any, companies: any[]) {
    return this.libService.postData<any>(
      { user, companies: companies },
      this.baseApi + 'users/',
      'addCompanies'
    );
  }

  deleteCompanies(user: any, companies: any[]) {
    return this.libService.postData<any>(
      { user, companies: companies },
      this.baseApi + 'users/',
      'deleteCompanies'
    );
  }

  setProfiles(user: any) {
    return this.libService.postData<any>(
      user,
      this.baseApi + 'users/',
      'setProfiles'
    );
  }

  addProfiles(user: any, profiles: any[]) {
    return this.libService.postData<any>(
      { user, profiles: profiles },
      this.baseApi + 'users/',
      'addProfiles'
    );
  }

  deleteProfiles(user: any, profiles: any[]) {
    return this.libService.postData<any>(
      { user, profiles: profiles },
      this.baseApi + 'users/',
      'deleteProfiles'
    );
  }

  setLicences(user: any) {
    return this.libService.postData<any>(
      user,
      this.baseApi + 'users/',
      'setLicences'
    );
  }

  addLicences(user: any, licences: any[]) {
    return this.libService.postData<any>(
      { user, licences: licences },
      this.baseApi + 'users/',
      'addLicences'
    );
  }

  setLevel(user: any) {
    return this.libService.postData<any>(
      user,
      this.baseApi + 'users/',
      'setLevel'
    );
  }

  deleteLicences(user: any, licences: any[]) {
    return this.libService.postData<any>(
      { user, licences: licences },
      this.baseApi + 'users/',
      'deleteLicences'
    );
  }

  getIp() {
    return this.libService.postData({}, this.baseApi + 'users/', 'getIp');
  }

  checkSession(email: string) {
    return this.libService.postData<boolean>(
      { userEmail: email },
      this.baseApi + 'users/',
      'checkSession'
    );
  }

  
  index(){
    console.log("Users Index")
    let tokenId = this.authService.idToken()
    return this.libService.getDataWithAccessToken<any>(tokenId, this.baseApi, `${this.model}/index`)
  }
}
