<br>




<mat-card>
    <mat-card-title>{{stateCategoryLabel}}</mat-card-title>
    <mat-card-subtitle>
        <kendo-multiselect class="marginBottom10" #multiselect [(ngModel)]="workflowsSelected" [data]="selectWorkflows" [filterable]="true"
            textField="label" valueField="id" placeholder="Cerca Workflow..."
            (filterChange)="onFilterWorkflowsChange($event)" (valueChange)="onValueChangeWorkflows($event)">
        </kendo-multiselect>
        <br>
        <kendo-multiselect class="marginBottom10" #multiselect [(ngModel)]="workflowStatesSelected" [data]="selectWorkflowActions"
            [filterable]="true" textField="fullname" valueField="id" placeholder="Cerca Stato del Workflow..."
            (filterChange)="onFilterChange($event)">
        </kendo-multiselect>
    </mat-card-subtitle>
    <mat-card-content>
        <!-- Stati dei workflow -->
        <mat-tab-group animationDuration="0ms">
            <ng-container>
                <mat-tab [label]="workflowStates.name"
                    *ngFor="let workflowStates of this.workflowStatesSelected; let c = index">
                    <br>
                    <app-action-settings-form [settings]="workflowStates" [backButton]="false"
                        [globalVariables]="globalVariables" [reloadAfterSave]="false"></app-action-settings-form>
                </mat-tab>
            </ng-container>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<br>


<div class="buttons-right  my-2">

    <button style="background-color:transparent;" matTooltip="Back" type="button" class="my-bounce btn btn-primary mx-2"
        (click)="back()">
        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
    </button>

</div>