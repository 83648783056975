import { FormfieldSearchComponent } from './../formfield-search/formfield-search.component';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIcon, MatIconModule } from "@angular/material/icon";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { Fulltextsearch2Component } from "./fulltextsearch2.component";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Fulltextsearch3Component } from "../fulltextsearch3/fulltextsearch3.component";
import { Fulltextsearch2WithPaginationComponent } from "../fulltextsearch2-with-pagination/fulltextsearch2-with-pagination.component";
import { PracticesFulltextsearchWithPaginationComponent } from "../practices-fulltextsearch-with-pagination/practices-fulltextsearch-with-pagination.component";

const MATERIAL_MODULES = [
  MatProgressSpinnerModule,
  MatAutocompleteModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    Fulltextsearch2Component,
    FormfieldSearchComponent,
    Fulltextsearch3Component,
    Fulltextsearch2WithPaginationComponent,
    PracticesFulltextsearchWithPaginationComponent
    ],
  imports: [
    MATERIAL_MODULES,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        },
      }
    ),
  ],
  exports: [
    Fulltextsearch2Component,
    FormfieldSearchComponent,
    Fulltextsearch3Component,
    Fulltextsearch2WithPaginationComponent,
    PracticesFulltextsearchWithPaginationComponent
  ]
})
export class FullTextSearchModule { }
