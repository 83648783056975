import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslatorService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: string, public translate: TranslatorService
  ) { }

  ngOnInit(): void {
  }

  confirm(){
    this.dialogRef.close(true)
  }

  cancel(){
    this.dialogRef.close(false)
  }

}
