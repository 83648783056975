import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-close-request',
  templateUrl: './modal-close-request.component.html',
  styleUrls: ['./modal-close-request.component.scss']
})
export class ModalCloseRequest implements OnInit {

  editMode: boolean = false
  form: FormGroup

  reasons: any = []

  actions: any = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
    )
  {
    this.form = this.fb.group({
      esit: [null, Validators.required]
    })

    if(data){
      this.actions = data.actions
    }
    


  }

  ngOnInit(): void {
  }

}
