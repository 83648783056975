<h1 mat-dialog-title>{{ getLabel('titleLabel') }}</h1>

<div mat-dialog-content>

  <p>
    {{ getLabel('textLabel')}}
  </p>

  <form [formGroup]="form" class="form-container">

    <app-select-search class="width100" appearance="fill" formControlName="responsabileCommerciale" [items]="respComm" viewField="name" [label]="getLabelResp()">
    </app-select-search>

  </form>

</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="disabledConfirmButton()">{{ confirmButtonLabel |
    translate }}</button>
  <button mat-button [mat-dialog-close]="">{{ cancelButtonLabel | translate }}</button>
</div>