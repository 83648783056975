<div class="main-container">
  <div class="title-container">
    <h1>{{"FILE-UPLOAD.TITLE" | translate}}</h1>
    <div class="footer">
      <button class="btn btn-primary btn-sm me-3 text-dark" (click)="onSubmit()" [disabled]="formGroup.invalid"
        value="upload">{{"FILE-UPLOAD.UPLOAD" | translate}}</button>
      <button class="btn btn-light btn-sm" (click)="closeDialog(null)">{{"FILE-UPLOAD.CLOSE" | translate}}</button>
    </div>
  </div>


  <div class="buttons-container">
    <!-- <label for="file-input" class="btn btn-primary btn-sm me-3 text-dark">
      {{"FILE-UPLOAD.SELECT-FILE" | translate}}
    </label> -->
    <input hidden type="file" id="file-input" (change)="onFileChange($event)" [accept]="fileTypeAccept"
      class="btn btn-primary btn-sm me-3 text-dark" />
    <button class="btn btn-primary btn-sm me-3 text-dark" (click)="onShowWebcam()">{{"FILE-UPLOAD.SHOW-WEBCAM" |
      translate}}</button>
  </div>

  <form class="form-container" [formGroup]="formGroup" style="width:100%;">
    <div fxLayout="row" style="width:100%;">

      <div class="boxFileTable">

        <app-file-document-type-table *ngIf="showDescription" [order]="this.orderObject"
          [documentTypes]="documentTypes$ | async"
          (selectFileClickedEvent)="documentTypeSelected($event)"></app-file-document-type-table>

      </div>

      <br>

    </div>

    <mat-form-field appearance="fill" class="form-field-full-width" *ngIf="selectedDocumentType">
      <mat-label>{{ 'INSPECTIONCONTACTS.INSPECTIONCONTACTS' | translate }}</mat-label>
      <mat-select formControlName="inspectionContactId" #inspectionContactIdInput>
        <mat-option *ngFor="let inspectionContact of inspectionContacts$ | async" [value]="inspectionContact.id"
          [disabled]="inspectionContact.disabled">
          {{ 'INSPECTIONCONTACTS.'+inspectionContact.code | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-field-full-width" appearance="fill" *ngIf="showFilename">
      <mat-label>{{"FILE-UPLOAD.FILENAME" | translate}}</mat-label>
      <input matInput formControlName="filename">
      <mat-error>{{"FILE-UPLOAD.FILENAME-ERROR" | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field class="form-field-full-width" appearance="fill" *ngIf="showDescription">
      <mat-label>{{"FILE-UPLOAD.DESCRIPTION" | translate}}</mat-label>
      <input matInput formControlName="description">
    </mat-form-field>

  </form>
  <div class="cropper-container">
    <app-webcam *ngIf="showWebcam" (onImageSnapshot)="onImageEmitted($event)"></app-webcam>
    <img [src]="preview" *ngIf="showPreview" style="width:100%;margin-bottom:15px;">
  </div>
  <div class="footer">
    <button class="btn btn-primary btn-sm me-3 text-dark" (click)="onSubmit()" [disabled]="formGroup.invalid"
      value="upload">{{"FILE-UPLOAD.UPLOAD" | translate}}</button>
    <button class="btn btn-light btn-sm" (click)="closeDialog(null)">{{"FILE-UPLOAD.CLOSE" | translate}}</button>
  </div>
</div>