import { Component, OnInit, ElementRef } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { ConfService } from '../../../services/conf.service';
import { CompanyService } from '../../../services/companyService';
import { ViewChild } from '@angular/core';
import { WikiService } from '../../../services/wikiservice.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'app-action-form',
    templateUrl: './action-form.component.html',
    styleUrls: ['./action-form.component.scss']
})
export class ActionFormComponent implements OnInit {

    company: any;
    form: UntypedFormGroup;
    licenceForm: UntypedFormGroup;
    isNew: boolean = true;
    conf: any;
    id: any;
    actualLic: any = {};
    societies: any;
    licences: any;
    editingLicences: any;
    allSocieties: any;
    usersManager: any;
    usersAreaManager: any;
    allUsers: any;
    isEditingLicences: boolean = false
    allLicences: any
    hasSave: boolean = true

    mapZoom = 12;
    mapCenter: google.maps.LatLng;
    mapOptions: google.maps.MapOptions = {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        maxZoom: 20,
        minZoom: 4,
    };

    markerInfoContent = '';
    markerOptions: google.maps.MarkerOptions = {
        draggable: false,
        animation: google.maps.Animation.DROP,
    };

    geocoderWorking = false;
    geolocationWorking = false;

    address: string;
    formattedAddress?: string | null = null;
    locationCoords?: google.maps.LatLng | null = null;

    typeGlobal: any = '';

    @ViewChild('tabGroup') tabGroup: MatTabGroup;

    constructor(
        public navService: NavService,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        public libService: LibService,
        private toastr: ToastrService,
        public confService: ConfService,
        public translate: TranslatorService,
        private companyService: CompanyService,
        public wikiService: WikiService
    ) {
    }

    update(){}


    updateCompany(){
        if (this.form.valid) {
            this.company.isOpen = true;
            if (this.form.get('stato')?.value == 'FP')
                this.company.isOpen = false;
            this.libService.lockPage('');
            if (this.isNew == false) {
                this.company.status = this.form.get('status')?.value;
            }

            this.company.name = this.form.get('name')?.value;
            this.company.code = this.form.get('code')?.value;
            this.company.isOpen = this.form.get('isOpen')?.value;
            this.company.codezone = this.form.get('codezone')?.value;
            this.company.UsersCompaniesManagerRelId = this.form.get('manager')?.value;
            this.company.UsersCompaniesAreaManagerRelId = this.form.get('areaManager')?.value;
            this.company.macroarea = this.form.get('macroArea')?.value;
            this.company.country = this.form.get('country')?.value;
            this.company.streetName = this.form.get('streetName')?.value;
            this.company.streetNumber = this.form.get('streetNumber')?.value;
            this.company.zip = this.form.get('zip')?.value;
            this.company.city = this.form.get('city')?.value;
            this.company.state = this.form.get('state')?.value;
            this.company.coordY = this.form.get('latitude')?.value;
            this.company.coordX = this.form.get('longitude')?.value;
            this.company.telefono = this.form.get('phone')?.value;
            this.company.email = this.form.get('email')?.value;
            this.company.piva = this.form.get('piva')?.value;
            this.company.invoicesystem_code = this.form.get('invoiceCode')?.value;
            this.company.mastercode = this.form.get('masterCode')?.value;

            const id = this.route.snapshot.paramMap.get('id');
            if (id && parseInt(id) > 0) {
                console.log("Update Company", this.company);
                this.companyService.updateCompany(this.company).subscribe((response) => {
                    this.libService.unlockPage();
                    // this.subject = response;
                    this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
                });
            }
            else {
                this.companyService.createCompany(this.company).subscribe((response) => {
                    this.libService.unlockPage();
                    // this.subject = response;
                    this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
                    // console.log(response);
                });
            }
        }
        else {
            this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
        }
    }




   
    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.company = {};
        this.company.status = 'JUNIOR';
        this.form = this.fb.group({
            id: [null],
            name: [null, [Validators.required]],
            keyFrontEnd: [null, [Validators.required]]
        });
    }
}

