<div>
<div class="container-fluid">
  <app-fulltextsearch2-with-pagination [service]="requestService" (selected)="goTo($event)" (list)="updateList($event)" (clearOut)="clearList($event)"></app-fulltextsearch2-with-pagination>
  <div style="overflow-x: auto;">
    <app-request-filter-with-pagination *ngIf="stateCategories" [states]="stateCategories" [branches]="activeBranches" [type]="'Richiesta'" (filterResultReset)="filterResultResetEmitted($event)" (filterResult)="filterResultEmitted($event)"></app-request-filter-with-pagination>
 
    <app-data-table-with-pagination
    [data]="dataSource"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
    [actions]="actions"
    [totalRows]="totalRows"
    [pageInit]="pageInit"
    [currentPage]="currentPage"
    (changePagination)="pageChanged($event)"
    (changeOrder)="updateDataBySortParams($event)"
    sortActive=""
    ></app-data-table-with-pagination>

  </div>
</div>

<ng-template #addressCell let-col="col" let-element="element">
  <span *ngIf="!(element.building == null)"><b>{{ element.building.streetName + ', ' +
                                                  (element.building.streetNumber !== null? element.building.streetNumber + ', ' : '') +
                                                  (element.building.zip !== null? element.building.zip + ', ': '') +
                                                  (element.building.city !== null? element.building.city + ', ': '') +
                                                  (element.building.country !== null? element.building.country: '')}}</b></span>
  <span *ngIf="(element.building == null)"><i class="material-icons icsave">warning</i></span>
</ng-template>
</div>