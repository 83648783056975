import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { LibService } from '../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UserSessionService } from '../../services/user-session.service';
import { NavService } from '../../services/nav.service';
import { TranslatorService } from '../../services/translate.service';
import { MatPaginator } from '@angular/material/paginator';
import { ActionService } from '../../services/action.service'
import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';

import { SettingsModalFormComponent } from '../settings-modal-form/settings-modal-form.component';
import { TranslationGroupService } from '../../services/translationGroup.service'
import { TranslationCategoryService } from '../../services/translationCategory.service'


@Component({
  selector: 'app-translation-settings',
  templateUrl: './translation-settings.component.html',
  styleUrls: ['./translation-settings.component.scss']
})

export class TranslationSettingsComponent implements OnInit, OnDestroy {

  itemType = "translation_groups"
  dataSource: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  sort: MatSort

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('itemDialog') itemDialog: TemplateRef<any>
  @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  searchTimeout: any;

  translationCategories: any = [];
  translationGroupList: any = [];

  constructor(public navService: NavService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    public translate: TranslatorService,
    private matDialog: MatDialog,
    private actionService: ActionService,
    private translationGroupService: TranslationGroupService,
    private translationCategoryService: TranslationCategoryService,
  ) {
    this.dataSource = new MatTableDataSource();
  }


  displayedColumns = ['id', 'label', 'code', 'translationCategoryId'];

  structure: any = {
    id: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
    name: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
  }



  columns: any = [
    {
      name: 'id',
      flex: '6',
      title: this.translate.get('LABEL.ID'),
      cssClass: (item: any) => ['column-padding'],
      value: (item: any) => item.id
    },
    {
      name: "label",
      title: this.translate.get('LABEL.name'),
      cssClass: (item: any) => ['column-padding'],
      value: (item: any) => item.label
    },
    {
      name: "code",
      title: this.translate.get('LABEL.keyFrontEnd'),
      cssClass: (item: any) => ['column-padding'],
      value: (item: any) => item.code
    },
    {
      name: "translationCategoryId",
      title: this.translate.get('LABEL.actionCategory'),
      cssClass: (item: any) => ['column-padding'],
      value: (item: any) => item?.TranslationCategoryId
    },
  ]



  actions: any = [];


  setUserActions() {
    // SHOW
    if (this.actionService.checkPermissions(`${this.itemType}_show`)) {
      this.actions.push({
        label: of(''),
        icon: (item: any) => {
          return this.iconRoutine(item);
        },
        onClick: (item: any) => this.goToPage(item),
        color: 'primary'
      })
    }

    // DESTROY
    if (this.actionService.checkPermissions(`${this.itemType}_destroy`)) {
      this.actions.push({
        label: of(''),
        icon: (item: any) => {
          return 'delete';
        },
        onClick: (item: any) => this.deleteItem(item),
        color: 'danger'
      })
    }

  }

  iconRoutine(subject: any) {
    return 'edit';
  }

  deleteItem(item: any) {

    if (confirm("Sei sicuro di voler cancellare l'Action?") == true) {
      this.libService.lockPage('');

      this.actionService.destroy(item.id).subscribe((responseData: any) => {
        console.log('Destroy Item response %o', responseData);
        this.loadData()
        this.libService.unlockPage();
      }, err => {
        console.log(err)
        this.libService.unlockPage();
      });
    }

  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }



  openItemDialog(item: any) {
    //this.mapsSocietyState(e);
    const dialogRef = this.matDialog.open(this.itemDialog, {
      panelClass: 'custom-dialog-container',
      width: '60%'
    })
  }


  ngOnInit(): void {

    this.initTranslationCategories()

    this.loadData();
    this.setUserActions();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      { name: 'new', url: 'actions/0' },
      //{ name: 'export', url: '' },
    ];
    this.navService.setActions(actions);

    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();

    clearTimeout(this.searchTimeout)
    // this.searchTimeout = setTimeout(() => this.updateCompaniesData(), 1000)
  }


  initTranslationCategories() {
    this.translationCategoryService.index().subscribe((responseData: any) => {
      this.translationCategories = responseData;
    });
  }


  loadData(): void {

    this.libService.lockPage('');

    this.translationGroupService.index().subscribe((responseData: any) => {
      console.log('loadDataSets response %o', responseData);
      this.libService.unlockPage();
      this.translationGroupList = responseData
      this.dataSource = responseData;
    }, err => {
      console.log(err)
    });

  }

  goToPage(item: any) {
    this.router.navigate(['settings/translation-settings/' + item.id]);
  }

  openModalForm(item: any) {
    const dialogRef = this.matDialog.open(SettingsModalFormComponent, {
      width: '600px',
      data: {
        item: item,
        model: {
          id: {
            type: "number",
            value: item.id,
            label: "Id",
            rules: {
              required: true,
            }
          },
          name: {
            type: "text",
            value: item.name,
            label: "Label",
            rules: {
              required: true,
            }
          },
          keyFrontEnd: {
            type: "text",
            value: item.keyFrontEnd,
            label: "Code",
            rules: {
              required: true,
            }
          },
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (![null, undefined].includes(result)) {
        this.saveForm(result)
      }
    });


  }

  saveForm(attributes: any) {
    console.log(attributes)
  }

  filter(event: any) {
    console.log(event)
    let actions = this.translationGroupList.filter((action: any) => action.TranslationCategoryId == event.value)
    this.dataSource = actions
  }

  filterByTranslationCategoryId(event: any) {
    let translationCategoryId = event.value
    let translationGroups = this.translationGroupList

    if (translationCategoryId != '') {
      translationGroups = this.translationGroupList.filter((translationGroup: any) => {
        let status = false
        if (translationGroup.TranslationCategoryId == translationCategoryId) status = true
        return status
      });
    }

    this.dataSource = translationGroups
  }


}
