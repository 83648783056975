<h1 mat-dialog-title>{{ "LABEL.sceltaConvenzione" | translate }}</h1>

<div mat-dialog-content>


  <form [formGroup]="form" class="form-container">
    <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
      <mat-label>{{ 'LABEL.convenzione' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="conventionId">
        <mat-option *ngFor="let convention of conventions" [value]="convention.id">
          {{ convention.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="">{{ "LABEL.CANCEL" | translate }}</button>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="!form.valid">{{ "LABEL.ADD" | translate }}</button>
</div>