import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  map,
  Observable,
  of,
  tap,
  Subject,
  throttleTime,
  shareReplay,
  catchError,
  throwError,
} from 'rxjs';
import { LibService } from './libService';
import { User } from '../models/user';
import { AuthService } from '../auth/auth.service';
import { SecurityService } from '../services/security.service';
import { GuardService } from '../services/guard.service';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  baseApi = environment.rootApiFoundation + 'users';
  private $changeCompanySubject: Subject<boolean> = new Subject<boolean>();
  public get $changeCompanyDetector(): Observable<boolean> {
    return this.$changeCompanySubject
      .asObservable()
      .pipe(throttleTime(800), shareReplay());
  }

  constructor(
    public libService: LibService,
    public authService: AuthService,
    public securityService: SecurityService,
    public guardService: GuardService
  ) { }

  /*
  for route, use window.location.href
  for obj, use and object like {searchNgModel: 'value'}  */
  saveState(route: string, obj: any) {
    sessionStorage.setItem(route, JSON.stringify(obj));
    this.$changeCompanySubject.next(true);
  }

  getUser(str: string): Observable<User[]> {
    let userSessionStorage = this.getState<User[]>(`user&${str}`);
    if (userSessionStorage === null) {
      return this.libService
        .getData<User[]>(this.baseApi, `/email/${str}`)
        .pipe(
          tap((user) => {
            this.saveState(`user&${str}`, user);
            let locale = this.formatLanguageCode(user[0].locale)
            this.setCurrentLanguage(locale)
          })
        );
    } else {
      return of(userSessionStorage);
    }
  }

  getCountries() {
    let email = this.authService.user().email;
    let key = `user&${email}`;
    let user = this.getState(key);
    let countryCodes: any = [];
    user[0].UsersCompaniesRel.forEach((company: any) => {
      if (!countryCodes.includes(company.country)) {
        if (![null, undefined, ''].includes(company.country)) countryCodes.push(company.country);
      }
    });

    return countryCodes;
  }

  getCompanies() {
    let email = this.authService.user().email;
    let key = `user&${email}`;
    let user = this.getState(key);
    return user[0].UsersCompaniesRel
  }

  getState<T = any>(route: string): T | null {
    const state = sessionStorage.getItem(route);
    if (state) {
      return JSON.parse(state);
    } else {
      return null;
    }
  }

  getAccessToken(): any | null {
    const access_token = sessionStorage.getItem('access_token');
    if (access_token) {
      return access_token;
    } else {
      return null;
    }
  }

  getFinanceToken(): any | null {
    let email = this.authService.user().email;
    let key = `user&${email}`;
    let user = this.getState(key);
    let lastjresponse = user[0].lastjresponse;
    let jsonResponse = JSON.parse(lastjresponse)
    return jsonResponse.sub;
    // console.log('user:', user)
    // console.log('lastJresponse:', jsonResponse)
  }

  getIdToken(): any | null {
    const id_token = sessionStorage.getItem('id_token');
    if (id_token) {
      return id_token;
    } else {
      return null;
    }
  }

  getRefreshToken(): any | null {
    const refresh_token = sessionStorage.getItem('refresh_token');
    if (refresh_token) {
      return refresh_token;
    } else {
      return null;
    }
  }

  getExpiresAt(): any | null {
    const expires_at = sessionStorage.getItem('expires_at');
    if (expires_at) {
      return parseInt(expires_at, 10);
    } else {
      return null;
    }
  }

  getIdTokenExpiresAt(): any | null {
    const id_token_expires_at = sessionStorage.getItem('id_token_expires_at');
    if (id_token_expires_at) {
      return parseInt(id_token_expires_at, 10);
    } else {
      return null;
    }
  }

  getSignedUrl(objectUrl: string): Observable<string> {
    return this.libService.postData<any>({ objectUrl: objectUrl }, this.baseApi, '/getSignedUrl').pipe(
      map(res => res.signedUrl)
    )
  }

  updateUser(user: User) {
    return this.libService.putData(user, this.baseApi, `/${user.id}`)
  }

  checkVisibilities() {
    return this.libService
      .getDataWithAccessToken<any>(this.authService.idToken(), this.baseApi, `/currentUser/getVisibilities`)
  }

  changeLanguage(language: any) {
    return this.libService
      .putDataWithAccessToken<any>(this.authService.idToken(), { language: language }, this.baseApi, `/currentUser/changeLanguage`)
  }

  notifications(onlyUnread: any = false) {
    let attributes = {
      onlyUnread: onlyUnread
    }
    return this.libService
      .postDataWithAccessToken<any>(this.authService.idToken(), attributes, this.baseApi, `/currentUser/notifications`)
  }

  getCurrentLanguage() {
    let currentLanguage = this.getState('currentLanguage')
    return currentLanguage || 'it';
  }

  setCurrentLanguage(language: any) {
    this.saveState('currentLanguage', language);
  }

  formatLanguageCode(userLocale: any) {
    let locale = userLocale.split('-');
    return locale[0]
  }

  getSessionVisibilities() {
    let email = this.authService.user().email
    let key = `v&${email}`
    return this.getState(key);
  }

  getActionCodes() {
    let state = this.getSessionVisibilities();
    if (state != null) {
      let visivilities = this.securityService.decrypt(
        state,
        'sessionVisibilities'
      );
      return this.guardService.getKeyFrontEnd(visivilities);
    } else {
      return [];
    }
  }

  isAdmin() {
    //console.log('isAdmin')
    let state = this.getSessionVisibilities();
    if (state != null) {
      let visivilities = this.securityService.decrypt(
        state,
        'sessionVisibilities'
      );

      let profileCodes = visivilities.UsersProfilesRel.map((profile:any)=>profile.code) || []
      return profileCodes.includes('HQ-IT')
    } else {
      return false;
    }
  }

  isAreaManager() {
    //console.log('isAdmin')
    let state = this.getSessionVisibilities();
    if (state != null) {
      let visivilities = this.securityService.decrypt(
        state,
        'sessionVisibilities'
      );

      let profileCodes = visivilities.UsersProfilesRel.map((profile:any)=>profile.code) || []
      return profileCodes.includes('HQ-AM')
    } else {
      return false;
    }
  }

  getCurrentUserId() {
    let state = this.getSessionVisibilities();

    if (state != null) {
      let visivilities = this.securityService.decrypt(
        state,
        'sessionVisibilities'
      );
      return visivilities.id;
    } else {
      return null;
    }
  }

  getCurrentUserEmail() {
    let state = this.getSessionVisibilities();

    if (state != null) {
      let visivilities = this.securityService.decrypt(
        state,
        'sessionVisibilities'
      );
      return visivilities?.email;
    } else {
      return null;
    }
  }

  getVisibilities() {
    let email = this.authService.user()?.email;
    if (email) {
      let key = `v&${email}`;
      let state = this.getState(key);
      let visibilities = state;

      if (state != null) {
        visibilities = this.securityService.decrypt(state, 'sessionVisibilities');
      }
      if (visibilities === null) {
        return this.checkVisibilities().pipe(
          tap((vis) => {
            let value = this.securityService.encrypt(vis, 'sessionVisibilities');
            this.saveState(key, value);
          }),
          catchError((err) => {
            return throwError(err);
          })
        );
      } else {
        return of(visibilities);
      }
    } else {
      return of({});
    }
  }

  setVisibilities() {
    let email = this.authService.user().email;
    let key = `v&${email}`;

    return this.checkVisibilities().pipe(
      tap((vis) => {
        let value = this.securityService.encrypt(vis, 'sessionVisibilities');
        this.saveState(key, value);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getNamesBySedeAppartenenzaIds(ids: number[]) {
    let attributes = {
      ids: ids
    };
  
    return this.libService
      .postDataWithAccessToken<any>(this.authService.idToken(), attributes, this.baseApi, `/getNamesBySedeAppartenenzaIds`);
  }
  
}
