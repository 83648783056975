<div class="container-fluid my-container">
  <div class="buttons my-2">
      <div> </div>
      <div class="buttons-right">
          <button (click)="save()" style="background-color:transparent;" matTooltip="Salva" type="button" class="btn btn-primary mx-2"
              >
              <mat-icon class="my-bounce material-icons" style="color:#293683;">save</mat-icon>
          </button>
      </div>
  </div>
  <div class="full-w center" fxLayout="row">
    <form [formGroup]="form">
      <div class="inline-div">
        <mat-slide-toggle style="margin:5px;margin-bottom: 10px;" formControlName="isOpen">
            {{ 'LABEL.LICENCE_OPEN' | translate }}
        </mat-slide-toggle>
        <mat-slide-toggle style="margin:5px;margin-bottom: 10px;" formControlName="isActive">
          {{ 'LABEL.ACTIVE' | translate }}
      </mat-slide-toggle>
      </div>
      <div class="inline-div">
        <div *ngIf="!(form == null) && !(form.get('numberOfUsers')== null)" class="column-div"><span>{{ 'LABEL.NUMBER_OF' | translate:{'field': translate.instant('LABEL.USERS') } }}</span><span><b>{{form.get('numberOfUsers')!.value}}</b></span></div>
        <div *ngIf="!(form == null) && !(form.get('numberOfUsers')== null)" class="column-div"><span>{{ 'LABEL.LICENCE_COST' | translate }}</span><span><b>{{form.get('cost')!.value | number: '1.2-2'}}</b></span></div>
      </div>
      <div class="inline-div">
        <mat-form-field style="margin:5px; width: 246px;" appearance="fill" class="form-subject">
          <mat-label>{{ 'LABEL.TYPE' | translate }}</mat-label>
          <mat-select matNativeControl name="tipo" formControlName="type">
            <mat-option value="bronze">{{ 'OTHER.bronze' | translate }}</mat-option>
            <mat-option value="silver">{{ 'OTHER.silver' | translate }}</mat-option>
            <mat-option value="gold">{{ 'OTHER.gold' | translate }}</mat-option>
            <mat-option value="bronze_l2">{{ 'OTHER.bronze_l2' | translate }}</mat-option>
            <mat-option value="silver_l2">{{ 'OTHER.silver_l2' | translate }}</mat-option>
            <mat-option value="gold_l2">{{ 'OTHER.gold_l2' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
     </div>
     <div class="inline-div">
      <mat-form-field style="margin:5px; width: 246px; border-radius: 5px;" appearance="fill">
          <mat-label>{{ 'LABEL.V_DATE' | translate:{'field': translate.instant('LABEL.LICENCE') } }}</mat-label>
          <mat-date-range-input [formGroup]="form" [rangePicker]="campaignOnePicker">
              <input matStartDate placeholder="Data inizio" formControlName="startDate">
              <input matEndDate placeholder="Data fine" formControlName="endDate">
          </mat-date-range-input>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
      </mat-form-field>
  </div>
    </form>
  </div>
</div>
