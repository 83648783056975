<h1 mat-dialog-title>Nuova tipologia di documento</h1>
<div mat-dialog-content>
  <form [formGroup]="documentForm">
    <!-- Campo per inserire il nuovo documento -->
    <mat-form-field class="document">
      <mat-label>Documento</mat-label>
      <input matInput formControlName="documentId" placeholder="Inserisci il nuovo documento">
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Annulla</button>
  <button mat-button (click)="onSubmit()" [mat-dialog-close]="documentForm.value" [disabled]="documentForm.invalid">Salva</button>
</div>
