import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(
  ) { }

  getStockId(type: any) {
    let res = null
    switch (type) {
      case 'deliberatedForMoreThan7Days':
        res = 4
        break;
      case 'approvedForLessThan7Days':
        res = 3
        break;
      case 'planningOrders':
        res = 2
        break;
      case 'suspendedOrders':
        res = 1
        break;
      case 'nonProgrammableOrders':
        res = 5
        break;
      case 'bothUnscheduled':
        res = 1
        break;
      case 'unplannedEnergy':
        res = 2
        break;
      case 'buildingsNotScheduled':
        res = 3
        break;
      case 'bothScheduled':
        res = 4
        break;
      case 'nonProgrammable':
        res = 5
        break;
    }
    return res
  }

  getStockCode(id: any, plannerType: any) {
    let res = null
    switch (id) {
      case 1:
        res = ['energy', 'energyOperators'].includes(plannerType) ? 'bothUnscheduled' : 'suspendedOrders'
        break;

      case 2:
        res = ['energy', 'energyOperators'].includes(plannerType) ? 'unplannedEnergy' : 'planningOrders'
        break;

      case 3:
        res = ['energy', 'energyOperators'].includes(plannerType) ? 'buildingsNotScheduled' : 'approvedForLessThan7Days'
        break;

      case 4:
        res = ['energy', 'energyOperators'].includes(plannerType) ? 'bothScheduled' : 'deliberatedForMoreThan7Days'
        break;

      case 5:
        res = ['energy', 'energyOperators'].includes(plannerType) ? 'nonProgrammable' : 'nonProgrammableOrders'
        break;
    }
    return res
  }

}
