import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class ComplaintTypesService {

  baseApi = environment.rootApiCrm + 'v1.0';

  constructor(public libService: LibService) { }

  getAllComplaintTypes(): Observable<any> {
    console.log("SERVICE FRONT: "+this.baseApi);
    return this.libService.getData<any>(this.baseApi, `/complaintTypes`);
  }

  getComplaintTypesByCategory(category: 'claims' | 'legals'  | 'transactions' | 'complaints') {
    return this.libService.getData<any>(this.baseApi, `/complaintTypes/${category}`);
  }

}
