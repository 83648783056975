import { style } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';

@Component({
  selector: 'app-crm-form-practices',
  templateUrl: './crm-form-practices.component.html',
  styleUrls: ['./crm-form-practices.component.scss']
})
export class CrmFormPracticesComponent implements OnInit {

  @Input() subject: any;
  @Input() form: UntypedFormGroup;



  displayedColumns = ['code', 'version', 'realPrice', 'nameSubject', 'nameBuilding', 'note', 'createdAt', 'updatedAt'];
  dataSource: any;
  kwSearch: any;
  userName = this.authService.user().name;
  orders: any;

  practices$: Observable<any>;

  // @ViewChild(MatSort) set matSort(sort: MatSort) {
  //   this.dataSource.sort = sort;
  // }
  // @ViewChild(MatPaginator) set paginator(pag: MatPaginator) {
  //   this.dataSource.paginator = pag;
  // }



  // @ViewChild(MatTable) table: MatTable<any>;

  columns: DatatableColumn<any>[] = [
    {
      name: 'code',
      flex: '13',

      title: of(this.translate.instant('LABEL.CODE')),

      cssClass: licence => ['column-padding', 'boldText'],
      value: order => order.code
    },
    {
      name: 'version',
      flex: '5',
      title: of(this.translate.instant('LABEL.VERSION')),
      cssClass: relation => ['column-padding'],
      value: order => order.version
    },
    {
      name: 'realPrice',
      flex: '10',
      title: of(this.translate.instant('LABEL.VALUE')) ,
      cssClass: relation => ['column-padding'],
      value: order => (!([null, undefined].includes(order.realPrice)))? order.realPrice +  " ": '0.00 '
    },
    {
      name: 'nameSubject',

      title: of(this.translate.instant('LABEL.SUBJ')),
      cssClass: relation => ['column-padding'],
      value: order => order.nameSubject
    },
    {
      name: 'nameBuilding',

      title: of(this.translate.instant('LABEL.BUILDING')),
      cssClass: relation => ['column-padding'],
      value: order => order.nameBuilding
    },
    {
      name: 'note',

      title: of(this.translate.instant('LABEL.NOTE')),
      cssClass: relation => ['column-padding'],
      value: order => order.note
    },
    {
      name: 'createdAt',
      flex: '10',
      title: of(this.translate.instant('LABEL.CREATION_DATE')),
      cssClass: relation => ['column-padding'],
      value: order => this.datePipe.transform(order.createdAt)
    },
    {
      name: 'updatedAt',
      flex: '10',
      title: of(this.translate.instant('MACROPHASES.LASTMODIFY_DATE')),
      cssClass: relation => ['column-padding'],
      value: order => this.datePipe.transform(order.updatedAt)
    },
  ]

  actions: DatatableAction<any>[] = [
    {
      label: of(''),
      icon: order => 'edit',
      onClick: order => this.goTo(order),
      color: 'primary'
    }
  ];

  constructor(private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    public orderService: OrderService)
    {

    }

  ngOnInit(): void {
    console.log("init del soggetto pratiche");
    this.practices$ = this.orderService.getOrderBySubjectId(this.userName, this.form.getRawValue().id, this.form.getRawValue().type);

    // this.dataSource.sortingDataAccessor = (item: any, property: any) => {
    //   switch (property) {
    //     case 'price': return item.realPrice;
    //     case 'sal100': return item.isSalCento;
    //     case 'subjectId': return item.nameSubject;
    //     case 'buildingId': return item.nameBuilding;

    //     default: return item[property];
    //   }
    // };

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if ((this.practices$ !== null && this.practices$ !== undefined)) {
      this.practices$.subscribe(val => {
        console.log("VAL --> ", val)
        this.dataSource = val;

        this.orders = val;
        if (!(this.dataSource == null) && !(this.dataSource.paginator == null))
          // this.dataSource.paginator = this.paginator;
        //this.cdr.detectChanges();
        console.log("PRATICHE RELATIVE AL SOGGETTO --> ", this.dataSource);
      })
    }

  }

  ngOnChanges(): void {
    console.log("CHIAMATA EFFETTUATA AL FIGLIO");

  }

  goTo(e: any) {
    this.router.navigate(['practices/' + e.code]);
  }

  goToFinalBalancer(e: any){
      this.router.navigate(['final-balancer/' + e.code + '/' + e.version]);
  }

  search(e: any) {

    if (e.trim() !== "" && e !== null && e !== undefined) {
      const filteredData = this.orders.filter((item: any) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(e.toLowerCase())
        )
      );
      this.dataSource = filteredData;
      // console.log("FILTERED DATA --> ", filteredData);
    } else {
      this.dataSource = this.orders;
    }
    console.log("DATASOURCE --> ", this.dataSource);
  }

}
