import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, first, iif, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StreamList } from '../models/store';

@Injectable({
  providedIn: 'root',
})
export class SocietyService {
  baseApi = environment.rootApiFoundation + 'societies';
  societies: StreamList<any> = new StreamList<any>('societies', []);

  constructor(public libService: LibService) {}

  getAllSocieties(refresh?: boolean): Observable<any> {
    const storedSocieties = this.societies.$stream;
    const isLoaded = this.societies.length > 0 && !refresh;
    const $getSocieties = this.libService
      .getData<any>(this.baseApi, '')
      .pipe(tap((societies: any) => this.societies.save(societies)));
    return iif(() => isLoaded, storedSocieties, $getSocieties).pipe(first());
  }

  getSociety(id: string) {
    return this.libService.getData<any>(this.baseApi + '/', id.toString());
  }
}
