import { Component, OnInit, ElementRef } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../services/libService';
import { TranslatorService } from 'src/app/services/translate.service';
import { MarketingNoteService } from '../../services/marketingNote.service'
import { EventService } from '../../services/event.service'
import { EventTypeService } from '../../services/eventType.service'

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {

  sectionName = "marketing-note-settings"

  form: UntypedFormGroup;
  id: any;

  marketingNote: any = null;
  event: any = null;
  eventId: any = null;

  translationList: any = []
  eventTypes: any = []

  languageCodeList: any = [
    {
      label: 'Italiano',
      code: 'IT'
    },
    {
      label: 'Français',
      code: 'FR'
    },
    {
      label: 'Español',
      code: 'ES'
    },
    {
      label: 'English',
      code: 'EN'
    },
  ]

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public translate: TranslatorService,
    private router: Router,
    private marketingNoteService: MarketingNoteService,
    private eventService: EventService,
    private eventTypeService: EventTypeService,
  ) {}


  addTranslation(values: any): FormGroup {

    let translation: FormGroup = this.fb.group(values);

    return translation

  }


  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }


  get translations(): FormArray {
    return <FormArray>this.form.get('translations');
  }

  update() {
    this.libService.lockPage('');
    console.log(this.form.value)


    this.marketingNoteService.createSettings(this.form.value).subscribe((response: any) => {
      //console.log(response)
      
      this.toastr.success(
        'ok', this.translate.instant('LABEL.Info'));

      this.id = response.id
      this.form?.get('id')?.setValue(this.id)
      this.form?.get('TranslationGroupId')?.setValue(response.TranslationGroupId)
      this.form?.get('code')?.setValue(response.code)
      this.router.navigate([`/settings/${this.sectionName}/`, this.id]);
      
      this.libService.unlockPage();
    })
    

  }

  back() {
    this.router.navigate([`settings/${this.sectionName}/`]);
  }

  initTranslations() {

    this.languageCodeList.forEach((lang: any) => {
      let currentTranslation = this.translationList.find((translation: any) => translation.languageCode == lang.code)
      let structure: any = {
        id: [null],
        languageCode: [lang.code, Validators.required],
        text: [null, Validators.required],
      }

      if (currentTranslation?.id) structure.id = currentTranslation?.id
      if (currentTranslation?.languageCode) structure.languageCode = currentTranslation?.languageCode
      if (currentTranslation?.text) structure.text = currentTranslation?.text

      this.translations.push(this.addTranslation(structure))
    })
  }

  ngOnInit(): void {
    this.libService.lockPage('');
    this.initEventTypes()
    this.id = this.route.snapshot.paramMap.get('id');

    // Creazione Form
    this.form = this.fb.group({
      id: [],
      name: [null, Validators.required],
      description: [null, Validators.required],
      everyUserCanParticipate: [true],
      everyExternalUserCanParticipate: [true],
      startDate: [false, Validators.required],
      endDate: [false, Validators.required],
      startDateVisibility: [false, Validators.required],
      endDateVisibility: [false, Validators.required],
      startDateRegistrations: [false, Validators.required],
      endDateRegistrations: [false, Validators.required],
      EventTypeId: [null, Validators.required],
    })

    if (['0', 0, null, undefined].includes(this.id)) {
      this.libService.unlockPage();
    } else {
      this.initForm()
    }

  }

  initForm() {
    this.eventService.settings(this.id).subscribe((response: any) => {
      if (response) {
        this.event = response

        this.form.patchValue(response)
        let eventId = this.form?.get('id')?.value
        if(eventId) this.eventId = eventId

        this.libService.unlockPage();
      } else {
        this.libService.unlockPage();
      }
    })
  }

  
  initEventTypes() {
    this.eventTypeService.index().subscribe((response: any) => {
        this.eventTypes = response
    })
  }


  setCode() {
    if ([undefined, null, 0, '0'].includes(this.id)) {
      let label = this.form?.get('label')?.value
      let code = `marketing_notes_${this.snakeCase(label)}`
      this.form?.get('code')?.setValue(code)
    }
  }

  snakeCase(string: any) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word: any) => word.toLowerCase())
      .join('_');
  };

}