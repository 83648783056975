import { Component, OnInit, ElementRef } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { ConfService } from '../../../services/conf.service';
import { CompanyService } from '../../../services/companyService';
import { ViewChild } from '@angular/core';
import { WikiService } from '../../../services/wikiservice.service';
import { TranslatorService } from 'src/app/services/translate.service';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { ActionService } from '../../../services/action.service';
import { ComponentService } from '../../../services/component.service';
import { ProfileService } from '../../../services/profileService';
import { ActionSettingService } from '../../../services/actionSetting.service';
import { StateService } from '../../../services/state.service';
import { ActionCategoryService } from '../../../services/actionCategory.service';
import { WorkflowService } from '../../../services/workflow.service';
import { BranchService } from '../../../services/branch.service';
import { ItemCategoryService } from '../../../services/itemCategory.service';
import { ContactReasonService } from '../../../services/contact-reason.service';
import { StateCategoryService } from '../../../services/state-category.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-workflow-settings-form',
  templateUrl: './workflow-settings-form.component.html',
  styleUrls: ['./workflow-settings-form.component.scss']
})
export class WorkflowSettingsFormComponent implements OnInit {

  company: any;
  form: UntypedFormGroup;
  licenceForm: UntypedFormGroup;
  isNew: boolean = true;
  conf: any;
  id: any;
  actualLic: any = {};
  societies: any;
  licences: any;
  editingLicences: any;
  allSocieties: any;
  usersManager: any;
  usersAreaManager: any;
  allUsers: any;
  isEditingLicences: boolean = false
  allLicences: any
  hasSave: boolean = true
  structure: any = {}

  mapZoom = 12;
  mapCenter: google.maps.LatLng;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
  };

  markerInfoContent = '';
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    animation: google.maps.Animation.DROP,
  };

  geocoderWorking = false;
  geolocationWorking = false;

  address: string;
  formattedAddress?: string | null = null;
  locationCoords?: google.maps.LatLng | null = null;

  typeGlobal: any = '';
  workflow: any = null;

  actionCategories: any = [];
  profiles: any = [];
  states: any = [];
  componentList: any = [];

  actionSettings: any = []

  itemCategories: any = []
  businessLines: any = []
  contactReasons: any = []
  stateCategories: any = []
  currentStateCategories: any = []
  HTMLStructure: any = '<p>Struttura</p>';

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public confService: ConfService,
    public translate: TranslatorService,
    private companyService: CompanyService,
    public wikiService: WikiService,
    private actionService: ActionService,
    private componentService: ComponentService,
    private profileService: ProfileService,
    private actionSettingService: ActionSettingService,
    private stateService: StateService,
    private actionCategoryService: ActionCategoryService,
    private router: Router,
    private workflowService: WorkflowService,
    private branchService: BranchService,
    private itemCategoryService: ItemCategoryService,
    private contactReasonService: ContactReasonService,
    private stateCategoryService: StateCategoryService,
  ) {

    this.form = this.fb.group({
      id: [],
      label: [],
      code: ['workflows_', Validators.required],
      disabled: [false, Validators.required],
      firstStateId: [null],
      BusinessLineId: [null, Validators.required],
      ItemCategoryId: [null, Validators.required],
      ContactReasonId: [],
      stateCategoryIds: [],
      componentIds: [],
      components: this.fb.array([])
    })
  }

  setCode() {
    if ([undefined, null, 0, '0'].includes(this.id)) {
      let label = this.form?.get('label')?.value
      let code = `workflows_${this.snakeCase(label)}`
      this.form?.get('code')?.setValue(code)
    }
  }

  snakeCase(string: any) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word: any) => word.toLowerCase())
      .join('_');
  };

  addComponentGroup(values: any): FormGroup {

    console.log('this.actionSettings')
    console.log(this.actionSettings)

    let attributesArray: any = []
    if (values.attributes) {
      values.attributes.forEach((attribute: any) => {
        attributesArray.push(this.addAttributeGroup(attribute))
      })
    }


    let component: FormGroup = this.fb.group({
      id: [values.id],
      code: [values.code],
      label: [values.label],
      attributes: this.fb.array(attributesArray)
    });

    return component

  }



  addAttributeGroup(values: any): FormGroup {
    let parametersArray: any = []
    let parametersArrayGroup: any = []
    let tmpGroups: any = {}
    let multiple = values.multiple || false

    if (values.parameters && !multiple) {
      values.parameters.forEach((parameter: any) => {
        parametersArray.push(this.addParameterGroup(parameter, values.id))
      })
    }

    if (values.parameters && multiple) {
      values.parameters.forEach((parameter: any) => {
        let tmpValue = this.actionSettings.filter((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.key == parameter.key && actionSetting.ComponentAttributeId == values.id)
        if (tmpValue) {
          tmpValue.forEach((row: any) => {
            if (!tmpGroups[row.groupCode]) tmpGroups[row.groupCode] = []

            tmpGroups[row.groupCode].push(this.addParameterGroupCode(parameter, row, row.groupCode))
          })
        } else {

        }

      })
    }

    Object.entries(tmpGroups).forEach(object => {
      const [key, value]: any = object;
      parametersArrayGroup.push(
        this.fb.group({
          codeGroup: key,
          parameters: this.fb.array(value),
        })
      )
    })

    let active = this.actionSettings.filter((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.ComponentAttributeId == values.id)

    return this.fb.group({
      id: [values.id],
      code: [values.code],
      label: [values.label],
      groupCodes: this.fb.array(parametersArrayGroup),
      parameterStructure: [values.parameters],
      parameters: this.fb.array(parametersArray),
      active: active?.length > 0,
      multiple: [multiple]
    });
  }

  addParameterGroupCode(values: any, value: any, groupCode: any = null): FormGroup {

    let tmpValue = value || null
    let tmpGroupCode = groupCode || values.groupCode
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
      groupCode: [tmpGroupCode]
    });
  }

  addParameterGroup(values: any, componetAttributeId: any): FormGroup {
    let tmpValue = this.actionSettings.find((actionSetting: any) => actionSetting.ActionId == this.id && actionSetting.key == values.key && actionSetting.ComponentAttributeId == componetAttributeId)
    tmpValue = tmpValue || null
    return this.fb.group({
      id: [values.id],
      key: [values.key],
      type: [values.type],
      value: [this.determinesValue(tmpValue?.value, values.type)],
    });
  }

  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }

  get components(): FormArray {
    return <FormArray>this.form.get('components');
  }

  addActionSettings(attribute: any) {
    console.log('addActionSettings');
    let groupCode = uuidv4();
    let parametersArray: any = []
    let parameterStructure = attribute.get('parameterStructure').value
    if (parameterStructure) {
      parameterStructure.forEach((parameter: any) => {
        let tmpStructure = this.addParameterGroupCode(parameter, null, groupCode)
        parametersArray.push(tmpStructure)
      })
    }


    attribute.get('groupCodes').push(this.fb.group({
      codeGroup: groupCode,
      parameters: this.fb.array(parametersArray),
    }))
    console.log(attribute)
  }

  componentAttributes(index: number): FormArray {
    return this.components.at(index).get("attributes") as FormArray
  }

  deleteLesson(lessonIndex: number) {
    this.components.removeAt(lessonIndex);
  }

  setStateCategories(itemcategoryId: any = null) {
    let activeStateCategories = this.stateCategories.filter((stateCategory: any) => !stateCategory.disabled)
    this.currentStateCategories = itemcategoryId ? activeStateCategories.filter((stateCategory: any) => stateCategory.ItemCategoryId == itemcategoryId) : activeStateCategories
  }

  getStateCategories() {
    this.stateCategoryService.index().subscribe((response: any) => {
      this.stateCategories = response
    })
  }

  getItemCategories() {
    this.itemCategoryService.index().subscribe((response: any) => {
      this.itemCategories = response
    })
  }

  getContactReasons() {
    this.contactReasonService.getAll().subscribe((response: any) => {
      this.contactReasons = response
    })
  }

  getBusinessLines() {
    this.branchService.getAllBranches().subscribe((response: any) => {
      this.businessLines = response
    })
  }

  getActionCategories() {
    this.actionCategoryService.index().subscribe((response: any) => {
      this.actionCategories = response
    })
  }

  getProfiles() {
    this.profileService.getAllProfiles().subscribe((response: any) => {
      this.profiles = response
    })
  }

  getStates() {
    this.stateService.index().subscribe((response: any) => {
      this.states = response.filter((state: any) => state.WorkflowId == this.id)
    })
  }

  getComponentList() {
    this.componentService.index().subscribe((response: any) => {
      this.componentList = response
    })
  }

  update() {
    // this.libService.lockPage('');
    console.log(this.form)
    let structure: any = {
      id: this.form?.get('id')?.value,
      label: this.form?.get('label')?.value,
      code: this.form?.get('code')?.value,
      disabled: this.form?.get('disabled')?.value,
      firstStateId: this.form?.get('firstStateId')?.value,
      ActionCategoryId: this.form?.get('actionCategoryId')?.value,
      profileIds: this.form?.get('profileIds')?.value,
      itemCateroriesWorkflow: {
        BusinessLineId: this.form?.get('BusinessLineId')?.value,
        ContactReasonId: this.form?.get('ContactReasonId')?.value,
        ItemCategoryId: this.form?.get('ItemCategoryId')?.value
      },
      stateCategoryIds: this.form?.get('stateCategoryIds')?.value
    }

    console.log(structure)

    this.workflowService.createSettings(structure).subscribe((response: any) => {
      console.log(response)
      this.id = response.id
      this.form?.get('id')?.setValue(this.id)
      this.router.navigate(['/settings/workflow-settings/', this.id]);
      this.libService.unlockPage();
    })



  }

  showCategory(id: any) {
    let componentIds: any = this.form?.get('componentIds')?.value || []
    return componentIds.includes(id)
  }

  back() {
    this.router.navigate(['settings/workflow-settings/']);
  }

  initComponents() {
    this.componentService.full().subscribe((response: any) => {
      console.log(response)
      response.forEach((component: any) => {
        // this.components.push(this.addComponentGroup(component))
      })
      console.log(this.form)
      this.libService.unlockPage();
    })
  }

  ngOnInit(): void {
    this.libService.lockPage('');
    this.id = this.route.snapshot.paramMap.get('id');

    this.getBusinessLines()
    this.getItemCategories()
    this.getContactReasons()
    this.getStateCategories()

    this.getActionCategories()
    this.getProfiles()
    this.getStates()
    this.getComponentList()


    console.log('action settings')
    console.log(this.id)

    this.workflowService.show(this.id).subscribe((response: any) => {
      if (response) {
        this.workflow = response
        //if (response?.settings) this.actionSettings = response?.settings
        this.form?.get('id')?.setValue(this.id)
        this.form?.get('label')?.setValue(this.workflow?.label)
        this.form?.get('code')?.setValue(this.workflow?.code)
        this.form?.get('disabled')?.setValue(this.workflow?.disabled)
        this.form?.get('firstStateId')?.setValue(this.workflow?.firstStateId)


        let itemCateroriesWorkflow = this.workflow?.itemCateroriesWorkflow
        if (itemCateroriesWorkflow) {
          if (itemCateroriesWorkflow?.BusinessLineId) this.form?.get('BusinessLineId')?.setValue(itemCateroriesWorkflow?.BusinessLineId)
          if (itemCateroriesWorkflow?.ContactReasonId) this.form?.get('ContactReasonId')?.setValue(itemCateroriesWorkflow?.ContactReasonId)
          if (itemCateroriesWorkflow?.ItemCategoryId) this.form?.get('ItemCategoryId')?.setValue(itemCateroriesWorkflow?.ItemCategoryId)

          if (itemCateroriesWorkflow?.ItemCategoryId) this.setStateCategories(itemCateroriesWorkflow?.ItemCategoryId)
        }

        let workflowStates = this.workflow?.states
        if (workflowStates) {
          this.form?.get('stateCategoryIds')?.setValue(workflowStates.map((state: any) => state.StateCategoryId))
        }

        // this.form?.get('actionCategoryId')?.setValue(this.workflow?.ActionCategoryId)
        //this.form?.get('profileIds')?.setValue(this.workflow?.profiles.map((profile: any) => profile.id))

        // let stateIds = this.workflow?.componentsStates.map((componentsState: any) => componentsState.StateId)
        // let componentIds = this.workflow?.componentsStates.map((componentsState: any) => componentsState.ComponentId)

        // var uniqueStateIds = stateIds.filter((value: any, index: any, array: any) => array.indexOf(value) === index);
        // var uniqueComponentIds = componentIds.filter((value: any, index: any, array: any) => array.indexOf(value) === index);

        //this.form?.get('stateIds')?.setValue(uniqueStateIds)
        //this.form?.get('componentIds')?.setValue(uniqueComponentIds)

        // this.initComponents()

        this.getWorkflowStructure()


      } else {
        // this.initComponents()
        this.libService.unlockPage();
      }
    })

  }

  onChangeItemCategory(event: any) {
    console.log(event)
    let currentItemCategory = event.value
    this.currentStateCategories = [] // Reset
    this.form?.get('stateCategoryIds')?.setValue([])
    this.setStateCategories(currentItemCategory)
  }

  getWorkflowStructure() {
    let attributes = {
      workflowId: this.form.get('id')?.value,
      businessLineId: this.form.get('BusinessLineId')?.value
    }
    this.workflowService.getWorkflowStructure(attributes).subscribe((response: any) => {
      let structure: any = {}
      let firstState: any = response[0].firstState
      //console.log(response[0])

      response.forEach((setting: any) => {
        console.log(setting)

        //let currentState = setting.currentState.stateCode
        //let nextStates = setting.nextStates.stateCode

        let currentState = setting.currentState.stateCategoryLabel.replaceAll(' ', '_')
        let nextStates = setting.nextStates.stateCategoryLabel.replaceAll(' ', '_')
        if (!structure[currentState]) structure[currentState] = {
          currentState: setting.currentState,
          nextStates: setting.nextStates,
          actionId: setting.actionId,
          typeButton: setting.typeButton,
          nextStatesLabel: []
        }
        structure[currentState].nextStatesLabel.push(nextStates)
      })

      //console.log(response)
      // console.log(structure)



      //tmp[firstState.stateCode] = {}
      //this.createStructure(structure, firstState.stateCode, [firstState.stateCode])

      this.createStructure(structure, firstState.stateCategoryLabel.replaceAll(' ', '_'), [firstState.stateCategoryLabel.replaceAll(' ', '_')])
      /*
      structure[firstState.stateCode].forEach((str:any)=>{
        tmp[firstState.stateCode][str] = {}
        console.log(this.createStructure(structure,str))
      })

      console.log(tmp)
      */
      console.log(this.structure)
      this.HTMLStructure = '<p>Struttura</p>'

      for (const [key, value] of Object.entries(this.structure)) {
        let val: any = value
        let level = [1]
        let levelNumber = level.length
        let levelSpace = level.map((l: any) => '&nbsp;&nbsp;&nbsp;').join('')
        this.HTMLStructure += `<p>${levelSpace} ${levelNumber} <a target="_blank" href="http://localhost:4200/settings/action-settings/${val.actionId}">${key}</a></p>`
        this.HTMLStructure += this.getHtmlStructure(val, level)
      }

    })
  }

  getHtmlStructure(value: any, level: any) {
    let text = ``
    let localLevel = [...level]
    localLevel.push(1)
    let obj = Object.entries(value)
    let levelNumber = localLevel.length
    let levelSpace = localLevel.map((l: any) => '&nbsp;&nbsp;&nbsp;').join('')
    if (obj.length != 0) {
      for (const [k, v] of obj) {
        let currentValue: any = v;
        if (!['actionId', 'typeButton'].includes(k)) {
          text += `<p>${levelSpace} ${levelNumber} <a target="_blank" href="http://localhost:4200/settings/action-settings/${currentValue.actionId}">${k}</a></p>`
          text += this.getHtmlStructure(v, localLevel)
        }
      }
    }

    return text
  }

  createStructure(structure: any, key: any, keys: any) {
    //console.log(structure)
    //console.log(key)
    //console.log(structure[key])
    //console.log(keys)

    let currentkey = keys


    let keysString = currentkey.join('.')


    let currentStructure: any = `this.structure.${keysString}`



    if (eval(currentStructure) == undefined) eval(`${currentStructure} = {}`)
    if (!("actionId" in eval(currentStructure)) && structure[key]?.actionId) eval(`${currentStructure}.actionId = ${structure[key].actionId}`)
    if (!("typeButton" in eval(currentStructure)) && structure[key]?.typeButton) eval(`${currentStructure}.typeButton = '${structure[key].typeButton}'`)


    //console.log(key)
    //console.log(structure[key])

    if (structure[key] != undefined) {
      structure[key].nextStatesLabel.forEach((str: any) => {
        //console.log(str)
        let localKeys = [...currentkey]
        //console.log(localKeys)
        if (!localKeys.includes(str) && str != 'ANNULLATA') {
          localKeys.push(str)

          if (eval(`${currentStructure}.${str}`) == undefined) eval(`${currentStructure}.${str} = {}`)
          this.createStructure(structure, str, localKeys)
        }
      })
    }
  }


}

