import { FTIndexAllService } from './../../services/ftindex-all.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/material/stepper';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RequestService } from '../../services/request.service';
import { NavService } from '../../services/nav.service';
import { Building } from '../../models/building';
import { Order } from '../../models/order';
import { OrderRow } from '../../models/orderrow';
import { BuildingService } from 'src/app/services/building.service';
import { SubjectService } from '../../services/subject.service';
import { UserDataService } from 'src/app/services/users.service';
import { BranchService } from '../../services/branch.service';
import { CategoryService } from '../../services/category.service';
import { MacroPhaseService } from '../../services/macrophase.service';
import { PhaseService } from '../../services/phase.service';
import { TaxStepService } from '../../services/taxstep.service';
import { CompanyService } from 'src/app/services/companyService';
import { ElecSignService } from 'src/app/services/elec-sign.service';
import { forkJoin } from 'rxjs';
import { LibService } from '../../services/libService';
import { AuthService } from '../../auth/auth.service';
import { OrderService } from '../../services/order.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BuildingValidator } from '../../validators/building.validator';
import { SubjectsRelationsService } from 'src/app/services/subjects-relations.service';
import { CondominiumService } from 'src/app/services/condominium.service';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { DocumentService } from '../../services/document.service';
import { Document, DocumentType } from '../../models/document';
import { WikiService } from '../../services/wikiservice.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { DocumentTemplateService } from 'src/app/services/document-template.service';
import { DocumentWorkflowService } from 'src/app/services/document-workflow.service';
import { DeliberService } from 'src/app/services/deliber.service';
import { Subject as Subject2 } from 'rxjs';
import { UserSessionService } from '../../services/user-session.service';
import { Utils } from '../utils';
import { statusChanges } from '../status-changes';
import { FundingService } from '../../services/fundingService';
import { PaymentMethodService } from '../../services/payment-method.service';
import { EstimateDeliveryMethodService } from '../../services/estimate-delivery-method.service';
import { FinanceService } from '../../services/finance.service'
import { ModalEstimateDeliveryMethods } from '../../modals/modal-estimate-delivery-methods/modal-estimate-delivery-methods.component';
import { interval } from 'rxjs';
import { mergeMap, timeout } from 'rxjs/operators';

const STATUS_DELIBER = 38;
const STATUS_CLOSED = 66;
const STATUS_EXECUTED = 24;
const STATUS_PRE_DELIBER = 61;
const STATUS_PRE_DELIBER_OVER = 62;
const STATUS_BALANCING = 7;
const STATUS_PLANNING = 38;
const STATUS_CREATA = 8;
const STATUS_REQUEST = 20;
const STATUS_PRESENTED = 37;
const STATUS_PRESENTED_OVER = 63;
const STATUS_NULLED = 22;
const STATUS_SUSPENDED = [11, 67, 68, 69, 70];
const STATUS_WARRANTY = 35;
const STATUS_EXECUTION = 13;
const DIFFICULT = 1;
const PERSONS = 2;

const PROFILE_RA = 14;
const PROFILE_TC = 13;
const PROFILE_RC = 38;
const PROFILE_AFF = 64;
const PROFILE_COORD = 37;

@Component({
  selector: 'app-order-dtail',
  templateUrl: './order-dtail.component.html',
  styleUrls: ['./order-dtail.component.scss']
})
export class OrderDtailComponent implements OnInit {
  title = "Nuova Pratica";
  subTitle = "EDAC - Nuova Pratica";
  id: any;
  isNew: any;
  kwSearch: any;
  building: Building;
  orderStatuses: any;
  orderStatusesManaged: any;
  condAdmin: {};
  condominium: {};
  person: {};
  company: {};
  subject: {};
  order: Order;
  ricMateriali: any;
  ricManodopera: any;
  costoManodoperaRif: any;
  ricMaterialiMultiServizi: any;
  ricOreManodoperaMultiServizi: any;
  firstTheshold: any;
  secondTheshold: any;
  firstThesholdMultiServizi: any;
  secondThesholdMultiServizi: any;
  percOneriSicurezza: any;
  versions: any[];
  actualversion: any;
  actualIndexVersion: any;
  certurl: SafeResourceUrl;
  sended: boolean = false;
  user: any;
  isDeliberableVar: boolean = false;
  percIncrementoFestivoRif: any;
  percIncrementoTrasfertaRif: any;
  percIncrementoNotturnoRif: any;
  refresh: boolean = false;
  documentsFromStatus: any[];
  branches: any[];
  categories: any[];
  macroPhases: any[];
  phases: any[];
  masterBranches: any[];
  masterCategories: any[];
  masterMacroPhases: any[];
  masterPhases: any[];
  taxSteps: any[];
  openDetails: any[];
  companies: any[];
  resources: any[] = [];

  resourcesCoord: any[] = [];
  resourcesRespComm: any[] = [];
  requests: any[];
  orderVersions: any[];
  buildingRelations: any;
  actualStatus: any;
  isReview: boolean = false;
  oldIdStatus = 0;
  ultimoRicavoReale: any = null

  isOperational: boolean = false;
  isStaff: boolean = false;
  isAdmin: boolean = false;
  isFreeze: boolean = false;
  isWarranty: boolean = false;
  isVersionsBlocked: boolean = false;
  isOverApproval: boolean = false;
  financingToggleActivation: boolean = false;
  requestDocuments: Document[] = [];
  searchType: string = 'Soggetto';
  onereApertura: number = 0;

  oneriFinanziamento: any = {}
  rincaroFinanziamento: any = {}

  //hintValoreFinanziato: string = 'Selezionare il numero di rate per determinare il valore massimo finanziabile';
  hintValoreFinanziato: string = '';

  maggiorazioneDiCommessa: number = 0;

  fundings: any = []
  paymentMethods: any = []
  estimateDeliveryMethods: any = []

  orderSummaryBoxStatus = true;
  orderSummaryBoxIcon = 'close';
  dragPosition: any;
  showFieldsNotYetImplemented: boolean = true
  taxes: any = [];

  toggleOrderSummaryBox(event: any) {
    //console.log(event)
    this.orderSummaryBoxStatus = !this.orderSummaryBoxStatus;
    this.orderSummaryBoxIcon = this.orderSummaryBoxStatus ? 'close' : 'open_in_full'
    this.dragPosition = { x: 0, y: 0 };
  }

  changeFinancingToggleActivation(event: any) {
    if (event.checked) {
      this.orderFormGroup.controls["fundedValue"].addValidators([Validators.required, Validators.min(1)]);
      this.orderFormGroup.controls["fundingId"].addValidators([Validators.required]);
    } else {
      this.orderFormGroup.controls["fundedValue"].clearValidators();
      this.orderFormGroup.controls["fundingId"].addValidators([]);
    }
  }

  initEstimateDeliveryMethods() {

    this.estimateDeliveryMethodService.getEstimateDeliveryMethods().subscribe(estimateDeliveryMethods => {
      console.log('getEstimateDeliveryMethods')

      this.estimateDeliveryMethods = [] // Reset
      this.estimateDeliveryMethods = estimateDeliveryMethods

    });
  }

  setFinancingToggleActivation() {

    // Recupero le BusinessLine usate nell'ordine.
    let businessLineIds = this.getBusinessLineIds()

    // Recupero l'id delle Societies collegate all'Order
    let societyIds = this.orderFormGroup.controls['sede'].value.CompaniesSocietiesRel.map((society: any) => society.id)

    this.fundingService.getFundingsBySocietyAndBusinessLine(societyIds, businessLineIds).subscribe(f => {
      console.log('getFundingsBySocietyAndBusinessLine')

      this.fundings = [] // Reset

      f.forEach((fund: any) => {
        let tmp_funding: any = fund
        tmp_funding['Taxes'] = fund.Taxes

        // Verifico se è già presente un finanziamento con il medesimo codice. Se è presente non lo inserisco
        let alreadyEntered = this.fundings.find((funding: any) => funding.code == tmp_funding.code)

        if ([null, undefined].includes(alreadyEntered)) {
          this.fundings.push(tmp_funding)
        }

      })

      this.financingToggleActivation = this.fundings.length > 0 ? true : false
      if (![null, undefined].includes(this.orderFormGroup.controls['fundingId'].value)) {
        this.orderFormGroup.controls['financingSectionActivation'].setValue(true)
        this.setFinanziamento()
      }

    });
  }

  initializePaymentMethods() {

    // Recupero le BusinessLine usate nell'ordine.
    let businessLineIds = this.getBusinessLineIds()

    // Recupero l'id delle Societies collegate all'Order
    let societyIds = this.orderFormGroup.controls['sede'].value.CompaniesSocietiesRel.map((society: any) => society.id)

    this.paymentMethodService.getBySocietyAndBusinessLine(societyIds, businessLineIds).subscribe(pm => {
      console.log('getBySocietyAndBusinessLine')

      this.paymentMethods = [] // Reset

      pm.forEach((fund: any) => {
        this.paymentMethods.push(fund)
      })

    });
  }

  getBusinessLineIds() {
    console.log(this.order)
    let ids = []
    let macrophases = this.orderFormGroup.controls['macrophases'].value
    for (let a = 0; a < macrophases.length; a++) {
      let businessLine = macrophases[a].controls['branch'].value
      ids.push(businessLine.id)
    }
    return ids
  }

  buildingFormGroup: UntypedFormGroup = new UntypedFormGroup({
    building: new UntypedFormControl({}, [BuildingValidator()])
  });

  condominiumFormGroup: UntypedFormGroup = new UntypedFormGroup({
    condominium: new UntypedFormControl()
  });

  subjectFormGroup: UntypedFormGroup = new UntypedFormGroup({
    subject: new UntypedFormControl(),
    isSigning: new UntypedFormControl(false)
  });

  adminFormGroup: UntypedFormGroup = new UntypedFormGroup({
    admin: new UntypedFormControl(),
    isSigning: new UntypedFormControl(false)
  });

  realPrice: number;
  realDescont: number;


  orderFormGroup = new UntypedFormGroup({
    sede: new UntypedFormControl({}),
    richiesta: new UntypedFormControl({}),
    responsabileCommerciale: new UntypedFormControl({}),
    coordinatore: new UntypedFormControl({}),
    note: new UntypedFormControl('', [Validators.required]),
    isSedeInterna: new UntypedFormControl(false),
    isMultiservice: new UntypedFormControl(false),
    idStatus: new UntypedFormControl(14),
    oneriSicurezza: new UntypedFormControl(0),
    macrophases: new UntypedFormArray([]),
    financingSectionActivation: new UntypedFormControl(0),
    fundedValue: new UntypedFormControl(0),
    funding: new UntypedFormControl(null),
    maggiorazioneFinanziamento: new UntypedFormControl(0),
    anteprimaFinanziamento: new UntypedFormControl(0),
    fundingId: new UntypedFormControl(null),
    numberOfCondominiums: new UntypedFormControl(null),
    paymentMethodId: new UntypedFormControl(null, [Validators.required]),
    estimateDeliveryMethodId: new UntypedFormControl(null),
  });

  stepperOrientation: Observable<StepperOrientation>;

  searchService: any;

  currentUrl: string;

  subjects$ = this.subjectService.getAllSubjects(this.authService.user().email)
  buildings$ = this.buildingService.getAllBuildings(this.authService.user().email)
  condominiums$ = this.condominiumService.getAllCondominiums(this.authService.user().email)
  eventsSubject: Subject2<void> = new Subject2<void>();

  constructor(
    public navService: NavService,
    private router: Router,
    private route: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    breakpointObserver: BreakpointObserver,
    public translate: TranslateService,
    private branchService: BranchService,
    private categoryService: CategoryService,
    private macroPhaseService: MacroPhaseService,
    private companyService: CompanyService,
    private phaseService: PhaseService,
    public libService: LibService,
    private taxStepService: TaxStepService,
    private authService: AuthService,
    private orderService: OrderService,
    private userDataService: UserDataService,
    private toastr: ToastrService,
    public subjectService: SubjectService,
    public buildingService: BuildingService,
    private requestService: RequestService,
    private relationService: SubjectsRelationsService,
    public condominiumService: CondominiumService,
    private dialog: MatDialog,
    public indexAllService: FTIndexAllService,
    private documentService: DocumentService,
    private documentTypeService: DocumentTypeService,
    private documentWorkflowService: DocumentWorkflowService,
    private elecSignService: ElecSignService,
    public wikiService: WikiService,
    public deliberService: DeliberService,
    private documentTemplateService: DocumentTemplateService,
    public userSessionService: UserSessionService,
    private fundingService: FundingService,
    private paymentMethodService: PaymentMethodService,
    private financeService: FinanceService,
    private estimateDeliveryMethodService: EstimateDeliveryMethodService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }
  valoreTragitti: any = 0;
  getSTATUS_DELIBER() {
    return STATUS_DELIBER;
  }
  getSTATUS_BALANCING() {
    return STATUS_BALANCING;
  }
  getSTATUS_PLANNING() {
    return STATUS_PLANNING;
  }
  getSTATUS_CREATA() {
    return STATUS_CREATA;
  }
  getSTATUS_PRESENTED() {
    return STATUS_PRESENTED;
  }
  getSTATUS_NULLED() {
    return STATUS_NULLED;
  }
  getSTATUS_PRE_DELIBER() {
    return STATUS_PRE_DELIBER;
  }
  getSTATUS_EXECUTED() {
    return STATUS_EXECUTED;
  }
  getSTATUS_CLOSED() {
    return STATUS_CLOSED;
  }
  getSTATUS_WARRANTY() {
    return STATUS_WARRANTY;
  }

  getNumberBadge(i: any) {
    //console.log('print row %o', this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value);
    let ret = 0;
    if (!(this.orderFormGroup.controls['macrophases'].value[i] == null) && this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value.length > 0) {
      for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value.length; a++) {
        if (this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[a].controls['selected'].value == true)
          ret++;
      }

    }
    return ret;
  }

  onSearchTypeChange(e: any) {
    console.log('change search type %o', e);

    switch (e) {
      case 'Soggetto':
        this.searchService = this.subjectService;
        this.emitEventToFTSearch();
        break;
      case 'Edificio':
        this.searchService = this.buildingService;
        this.emitEventToFTSearch();
        break;
      case 'Condominio':
        this.searchService = this.condominiumService;
        this.emitEventToFTSearch();
        break;
      default:
        break;
    }
  }

  emitEventToFTSearch() {
    // console.log("EVENTO INNESTATO");
    this.eventsSubject.next();
  }

  selectionChangeStepper(e: any) {
    //this.sended = false;

    //console.log("EVENTO selectionChangeStepper %o", e);
    if (e.selectedIndex == 3 && this.sended == false && !(this.order == null) && !(this.order.code == null)) {
      //console.log("EVENTO selectionChangeStepper A %o", e);
      var frame: HTMLIFrameElement = document.getElementById('CertificatesIframe') as HTMLIFrameElement;
      if (frame != null && this.sended == false) {
        this.sended = true;
        this.certurl = environment.rootInvoiceCertificatesPractice + this.user.sub + '&commessa=' + this.order.code + '&dt=' + (new Date()).getTime();
        frame.contentWindow?.postMessage('', '*');
        //console.log("EVENTO selectionChangeStepper B %o", e);
        console.log('refresh url iframe');
      }
    }
    else {
      //reset
    }

  }

  ngDoCheck() {

    if (this.isOperational == true
      && this.isStaff == false
      && this.isAdmin == false
      && !(this.order == null)
      && (this.order.idStatus == STATUS_PRE_DELIBER))
      this.isFreeze = true;
    else
      this.isFreeze = false;

    if (
      this.isOperational == false &&
      this.isStaff == false &&
      this.isAdmin == false &&
      this.isOverApproval == false) {
      if (!(this.userSessionService.getState('user') == null)) {
        console.log('DO CHECK BETA %o', this.userSessionService.getState('user'));
        for (let a = 0; a < this.userSessionService.getState('user').UsersProfilesRel.length; a++) {
          //console.log('-----------------------------------> profile %o', this.userSessionService.getState('user').UsersProfilesRel[a].code)
          switch (this.userSessionService.getState('user').UsersProfilesRel[a].code) {
            case 'SLS-TC':
              this.isOperational = true;
              break;
            case 'PRD-CO':
              this.isOperational = true;
              break;
            case 'PRD-RA':
              this.isStaff = true;
              break;
            case 'AFF':
              this.isStaff = true;
              break;
            case 'HQ-AM':
              this.isAdmin = true;
              break;
            case 'HQ-BRD':
              this.isAdmin = true;
              break;
            case 'HQ-IT':
              this.isAdmin = true;
              break;
            case 'HQ-APP':
              this.isOverApproval = true;
              break;
            default:
              break;
          }
        }
      }
    }
  }

  getSede(id: any) {
    for (let a = 0; a < this.companies.length; a++) {
      if (id == this.companies[a].id)
        return (this.companies[a]);
    }
    return this.companies[0];
  }

  getPercTaxStep(perc: any) {
    for (let a = 0; a < this.taxSteps.length; a++) {
      if (perc == this.taxSteps[a].percIncrement)
        return (this.taxSteps[a]);
    }
    return this.taxSteps[0];
  }

  getBranch(id: any) {
    for (let a = 0; a < this.branches.length; a++) {
      if (this.branches[a].id == id)
        return this.branches[a];
    }
    return {};
  }

  getCategory(id: any) {
    for (let a = 0; a < this.categories.length; a++) {
      if (this.categories[a].id == id)
        return this.categories[a];
    }
    return {};
  }

  getMacroPhase(id: any) {
    for (let a = 0; a < this.macroPhases.length; a++) {
      if (this.macroPhases[a].id == id)
        return this.macroPhases[a];
    }
    return {};
  }

  getPhase(id: any) {
    for (let a = 0; a < this.phases.length; a++) {
      if (this.phases[a].id == id)
        return this.phases[a];
    }
    return {};
  }

  getPercCredit(perc: any) {
    let perfDef = { percStep: 0, percIncrement: 1 };
    for (let a = 0; a < this.taxSteps.length; a++) {
      //console.log('percCredit %o', this.taxSteps[a]);
      if (this.taxSteps[a].percStep == perc)
        return this.taxSteps[a];
    }
    return perfDef;
  }

  findIndex(id: any, arr: any) {
    for (let ret = 0; ret < arr.length; ret++) {
      if (id == arr[ret].id)
        return (ret);
    }
    return -1;
  }

  getRequest(id: any) {
    for (let a = 0; a < this.requests.length; a++) {
      if (this.requests[a].id == id)
        return this.requests[a];
    }
    return {};
  }

  getNumberDocuments() {
    if (!(this.order == null) && !(this.order.documents == null) && !(this.buildingFormGroup == null) && !(this.buildingFormGroup.controls == null) && !(this.buildingFormGroup.controls['building'] == null) && !(this.buildingFormGroup.controls['building'].value == null) && !(this.buildingFormGroup.controls['building'].value.documents == null))
      //return this.buildingFormGroup.controls['building'].value.documents.length;
      return this.order.documents.length;
    else
      return 0;
  }

  getNumberDocumentsBuilding() {
    if (!(this.order == null) && !(this.order.documents == null) && !(this.buildingFormGroup == null) && !(this.buildingFormGroup.controls == null) && !(this.buildingFormGroup.controls['building'] == null) && !(this.buildingFormGroup.controls['building'].value == null) && !(this.requestDocuments == null))
      //return this.buildingFormGroup.controls['building'].value.documents.length;
      return this.requestDocuments.length;
    else
      return 0;
  }


  getNumberSurveys() {
    if (!(this.buildingFormGroup == null) && !(this.buildingFormGroup.controls == null) && !(this.buildingFormGroup.controls['building'] == null) && !(this.buildingFormGroup.controls['building'].value == null) && !(this.buildingFormGroup.controls['building'].value.surveys == null))
      return this.buildingFormGroup.controls['building'].value.surveys.length;
    else
      return 0;
  }

  getClassWorkflow(step: any) {
    let stepP = 0;//<- step of actual status
    //console.log('status  of statuses %o', this.orderFormGroup.controls['idStatus'].value, this.orderStatuses)
    if (!(this.orderStatuses == null) && (this.orderStatuses.length > 0)) {
      for (let a = 0; a < this.orderStatuses.length; a++) {
        if (this.orderFormGroup.controls['idStatus'].value == this.orderStatuses[a].id) {
          if (this.orderStatuses[a].step > 0)
            stepP = this.orderStatuses[a].step;
        }
      }
    }

    if (step == stepP)
      return 'fs-6 fw-bold aln row-al mrg-top card-title group-type-document font-big';

    return 'fs-6 fw-bold text-muted aln row-al mrg-top card-title group-type-document font-big'

  }

  getStepFromStatus(idStatus: any) {
    for (let a = 0; a < this.orderStatuses.length; a++) {
      if (this.orderStatuses[a].id == idStatus)
        return this.orderStatuses[a].step;
    }
    return 0;
  }

  getPercPian() {
    return 0;
  }

  getPercCons() {
    return 0;
  }

  getClassIconWorkflow(step: any) {
    let stepP = 0;//<- step of actual status

    if (!(this.orderStatuses == null) && (this.orderStatuses.length > 0)) {
      for (let a = 0; a < this.orderStatuses.length; a++) {
        if (this.orderFormGroup.controls['idStatus'].value == this.orderStatuses[a].id) {
          if (this.orderStatuses[a].step > 0)
            stepP = this.orderStatuses[a].step;
        }
      }
    }
    if (step < stepP)
      return 'check-ok';

    if (step == stepP)
      return 'check-warning';

    return ''

  }

  sign() {
    console.log('sign privato %o amm %o total %o', this.subjectFormGroup.controls['isSigning'].value, this.adminFormGroup.controls['isSigning'].value, this.subjectFormGroup.controls['subject']);
    let email = '';
    let countSign = 0;
    if (this.subjectFormGroup.controls['isSigning'].value == true) {
      if (!(this.subjectFormGroup.controls['subject'].value == null) && !(this.subjectFormGroup.controls['subject'].value?.email == null)) {
        countSign++;
        email = this.subjectFormGroup.controls['subject'].value?.email;
      }
    }
    if (this.adminFormGroup.controls['isSigning'].value == true) {
      if (!(this.adminFormGroup.controls['admin'].value == null) && !(this.adminFormGroup.controls['admin'].value?.email == null)) {
        countSign++;
        email = this.adminFormGroup.controls['admin'].value?.email;
      }
    }
    if (countSign == 0) {
      window.alert('Attenzione: non è stato specificato un soggetto con email compilata per la firma per delibera.');
      return;
    }
    if (countSign > 1) {
      window.alert('Attenzione: è necessario specificare un solo destinatario per la firma elettronica del documento.');
      return;
    }
    if (window.confirm("Sei sicuro di voler richiedere la firma elettronica alla mail " + email + "?")) {

      //post file
      //read the content
      this.elecSignService.getFileExample().subscribe(f => {
        console.log('file for test %o', f);
        this.elecSignService.postFile('prova.pdf', f).subscribe(
          resFile => {
            console.log('response post file for elec_Sign %o', resFile);
          }
        );
      });
      //post document

      //createRequest

      //activateRequest

      //const fileContent = await this.libService.readFileContent('./../assets/img/testFirmaElettronica.pdf');
      /*
            this. elecSignService.postDocument('').subscribe(
              resFile => {
                console.log('response post document for elec_Sign %o',resFile);
              }
            );
          */
    }

  }

  countPresented() {
    let c = 0;
    for (let a = 0; a < this.versions.length; a++) {

      if (this.versions[a].idStatus == STATUS_PRESENTED)
        c++;
    }
    return c;
  }

  checkDelibera() {

    let c = 0;
    for (let a = 0; a < this.versions.length; a++) {

      if (this.versions[a].idStatus == STATUS_PRESENTED)
        c++;
    }

    if (c > 1) {
      window.alert(this.translate.instant('LABEL.TOOMANYPRESENT'));
    }
    else {
      this.libService.lockPage('');
      console.log('check delibera');
      this.deliberService.checkDelibera(this.user.email, this.order.code).subscribe(
        res => {
          console.log('res checkDeliber %o', res);
          this.libService.unlockPage();
          if (res.esito == 'OK') {
            this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
          }
          else {
            this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), res.item.error);
          }
        }
      );
    }
  }


  iconDeliber() {
    if (this.orderFormGroup.controls['idStatus'].value == STATUS_PRESENTED || this.orderFormGroup.controls['idStatus'].value == STATUS_PRESENTED_OVER)
      return true;
    else
      return false;
  }

  isDeliberable() {
    //console.log('ORDER %o', this.orderFormGroup.controls['idStatus'].value);
    if ((this.orderFormGroup.controls['idStatus'].value == STATUS_PRESENTED || this.orderFormGroup.controls['idStatus'].value == STATUS_PRESENTED_OVER) && (this.isDeliberableVar == true))
      return true;
    else
      return false;
  }

  transRequests(r: any) {
    let ret: any[] = [];
    for (let i = 0; i < r.length; i++) {
      if (!(r[i].workType == null))
        ret.push({
          id: r[i].id,
          value: r[i].id + ' - ' + r[i].workType.workType
        });
      else
        ret.push({
          id: r[i].id,
          value: r[i].id + ' - Non specificato'
        });
    }
    return ret;
  }

  updateList(e: any) {
    //console.log("EVENTO --> %o", e);
  }


  clearList(e: any) {
    console.log("CANCELLA --> ", e);
  }

  selSubject(e: any) {
    console.log("SET E ALFA --> %o searchType", e, this.searchType);
    // this.adminFormGroup.controls['admin'].patchValue(e);
    // this.condominiumFormGroup.controls['condominium'].patchValue(e);
    // this.subjectFormGroup.controls['subject'].patchValue(e);
    if (e.type == 'Amministratore') {
      this.adminFormGroup = new UntypedFormGroup({
        admin: new UntypedFormControl(e)
      });
    }
    else if (e.type == 'Condominio') {
      this.condominiumFormGroup = new UntypedFormGroup({
        condominium: new UntypedFormControl(e)
      });
    }
    else {
      this.subjectFormGroup = new UntypedFormGroup({
        subject: new UntypedFormControl(e)
      });
    }
    /*
    switch (e.type) {
      case "Ammnistratore":
        this.adminFormGroup = new FormGroup({
          admin: new FormControl(e, [BuildingValidator()])
        });
        break;

      case "Condominio":
        this.condominiumFormGroup = new FormGroup({
          condominium: new FormControl(e, [BuildingValidator()])
        });
        break;

      default:
        this.subjectFormGroup = new FormGroup({
          subject: new FormControl(e, [BuildingValidator()])
        });
        break;
    }
    */
    console.log('admin %o cond %o subj %o', this.adminFormGroup, this.condominiumFormGroup, this.subjectFormGroup)
  }

  clickSoggetto(id: any) {
    console.log('click %o', id);
    this.router.navigate(['subject/' + id, { type: 'Soggetto' }]);
  }

  clickEdificio(id: any) {
    console.log('click %o', id);
    this.router.navigate(['subject/' + id, { type: 'Edificio' }]);
  }

  clickCondominio(id: any) {
    console.log('click %o', id);
    this.router.navigate(['subject/' + id, { type: 'Condominio' }]);
  }

  selBuilding(e: any) {
    console.log("SET E BETA --> %o", e);
    this.buildingFormGroup = new UntypedFormGroup({
      building: new UntypedFormControl(e, [BuildingValidator()])
    });
    //this.buildingFormGroup.controls['building'].patchValue(e);
    this.loadDatasets(e.id);
  }


  loadDatasets(id: any) {
    this.libService.lockPage('');
    this.relationService.getSubjectRelations(id).subscribe((response) => {

      //console.log("RELAZIONI --> ", response);
      response.forEach((element: any) => {

        // if (element.subjectToType == 'Subject' || element.subjectToType == 'Privato' || element.subjectToType == 'Amministratore') {
        if (!(element.subjectToType == 'Edificio') && !(element.subjectToType == 'Condominio')) {
          this.subjectService.getSubject(element.subjectTo).subscribe(sub => {
            element.subjectTo = sub;
            element.subjectTo.name = sub.name + ' ' + sub.surname;

            //console.log("ELEMENT --> ", element);
          });

        } else if (element.subjectToType == 'Edificio') {
          //ACCEDERE ALLA TABELLA BUILDING PER RECUPERARE TUTTE LE INFO DEL SOGGETTO COLLEGATO
          this.buildingService.findBuildingById(element.subjectTo).subscribe(sub => {
            element.subjectTo = sub;
            element.subjectTo.name = sub.name

            element.subjectTo.streetName = sub.streetName;
            element.subjectTo.streetNumber = sub.streetNumber;
            element.subjectTo.city = sub.city;

            //console.log("ELEMENT --> ", element);
          });

        } else if (element.subjectToType == 'Condominio') {
          //ACCEDERE ALLA TABELLA CONDOMINIUMS PER RECUPERARE TUTTE LE INFO DEL SOGGETTO COLLEGATO
          this.condominiumService.findCondominiumById(element.subjectTo).subscribe(sub => {
            if (sub) {
              element.subjectTo = sub;
              element.subjectTo.name = sub.businessName
              //console.log("ELEMENT Condominio --> ", element);
            }


          });
        }
      });
      this.libService.unlockPage();
      if (response.length > 0) {
        this.buildingRelations = response;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }


    },
      err => {
        this.libService.unlockPage();
        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
      });

  }

  compileRelations() {
    if (!(this.buildingRelations == null) && this.buildingRelations.length > 0 && this.buildingRelations.length < 10) {
      if (window.confirm("Vuoi compilare i soggetti con le relazioni dell'edificio?")) {
        for (let a = 0; a < this.buildingRelations.length; a++) {
          //console.log('compile %o %o %o', this.buildingRelations[a], this.buildingRelations[a].subjectToType, this.buildingRelations[a].subjectTo);
          if (this.buildingRelations[a].subjectToType == 'Amministratore') {
            this.adminFormGroup = new UntypedFormGroup({
              admin: new UntypedFormControl(this.buildingRelations[a].subjectTo)
            });
          }
          else if (this.buildingRelations[a].subjectToType == 'Condominio') {
            this.condominiumFormGroup = new UntypedFormGroup({
              condominium: new UntypedFormControl(this.buildingRelations[a].subjectTo)
            });
          }
          else {
            this.subjectFormGroup = new UntypedFormGroup({
              subject: new UntypedFormControl(this.buildingRelations[a].subjectTo)
            });
          }
        }
      }
    }
    else {
      console.log('WTF %o', this.buildingRelations);
    }
  }

  downloadDocument(document: Document) {
    this.documentService.getSignedUrl(document.awsPath!).subscribe({
      next: (signedUrl: string) => {
        window.open(signedUrl)
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.message)
      }
    })
  }

  getCostoOrarioByBranc(id: any, isMultiservice: any) {

    for (let a = 0; a < this.branches.length; a++) {
      if (id == this.branches[a].id) {
        // console.log('trovato costo orario id %o in %o cOrario %o',id, this.branches[a],  this.branches[a].cOrario, this.branches[a].multCostoOrario)
        //check for multiservice, how?
        //console.log('TROVATO costo orario multiservizi %o normale %o', this.branches[a].multCostoOrario, this.branches[a].cOrario)
        if (isMultiservice == true)
          return (this.branches[a].multCostoOrario)
        else
          return (this.branches[a].cOrario)
      }
    }
    // console.log('NON trovato costo orario id branch %o in %o', id, this.branches)
    return 0;

  }
  getStatus(id: any) {
    if (!(this.orderStatuses == null)) {
      for (let a = 0; a < this.orderStatuses.length; a++) {
        if (id == this.orderStatuses[a].id)
          return (this.orderStatuses[a])
      }
    }
    return { descx: 'undefined' };
  }

  goToVersion(e: any, vs: any) {
    //console.log('GOTOVERSION event %o vs', e, vs);
    if (e.isUserInput == true) {

      this.orderService.getOrderByCodeVersionV2(this.order.code, vs).subscribe(os => {
        if (os.data == null || !(os.data.length > 0))
          return;
        let orderversionscode = os.data[0];
        //console.log('GOTOVERSION on %o rows %o', orderversionscode);
        this.orderFormGroup.reset();
        let countMacroPhase = 0;
        this.order = new Order();
        this.order.rows = [];
        this.openDetails = [];
        this.order.code = orderversionscode.code;
        this.order.id = orderversionscode.id;
        this.order.idSede = orderversionscode.idSede;
        this.order.version = orderversionscode.version;
        this.order.isLast = orderversionscode.isLast;
        this.order.note = orderversionscode.note;
        this.order.financingSectionActivation = orderversionscode.financingSectionActivation;
        this.order.fundedValue = orderversionscode.fundedValue;
        //this.order.funding = orderversionscode.funding;
        this.order.maggiorazioneFinanziamento = orderversionscode.maggiorazioneFinanziamento;
        this.order.anteprimaFinanziamento = orderversionscode.anteprimaFinanziamento;
        this.order.fundingId = orderversionscode.fundingId;
        this.order.numberOfCondominiums = orderversionscode.numberOfCondominiums;
        this.order.paymentMethodId = orderversionscode.paymentMethodId;
        this.order.estimateDeliveryMethodId = orderversionscode.estimateDeliveryMethodId;
        this.actualversion = orderversionscode.version;
        this.order.who = orderversionscode.who;
        this.documentService.findByOrder(this.order.id.toString()).subscribe(documents => {
          this.order.documents = documents
        })
        //this.order.documents = orderversionscode.documents;
        this.order.idStatus = orderversionscode.idStatus;

        this.order.streetName = orderversionscode.streetName;
        this.order.streetNumber = orderversionscode.streetNumber;
        this.order.otherInfo = orderversionscode.otherInfo;
        this.order.city = orderversionscode.city;
        this.order.province = orderversionscode.province;
        this.order.state = orderversionscode.state;
        this.order.zip = orderversionscode.zip;
        this.order.country = orderversionscode.country;
        this.order.probChiusuraPratica = orderversionscode.probChiusuraPratica;
        this.order.isAssemblea = orderversionscode.isAssemblea;
        this.order.dataPrevistaConfermaOrdine = orderversionscode.dataPrevistaConfermaOrdine;
        this.order.dataPrevistaAssemblea = orderversionscode.dataPrevistaAssemblea;
        this.order.isTCAssemblea = orderversionscode.isTCAssemblea;
        this.order.deadLine = orderversionscode.deadLine;
        this.order.isDeliberato = orderversionscode.isDeliberato;
        this.order.dateDelibera = orderversionscode.dateDelibera;
        // this.order.OPRel = orderversionscode.OPRel;
        this.order.percOneriSicurezza = orderversionscode.percOneriSicurezza;
        this.order.isSalCento = orderversionscode.isSalCento;

        this.order.startx = orderversionscode.startx;
        this.order.starty = orderversionscode.starty;
        this.order.endx = orderversionscode.endx;
        this.order.endy = orderversionscode.endy;
        this.order.km = orderversionscode.km;
        this.order.kmRitorno = orderversionscode.kmRitorno;

        if (this.order.idStatus == STATUS_PRE_DELIBER || this.order.idStatus == STATUS_PRE_DELIBER_OVER)
          this.isReview = true;
        else
          this.isReview = false;
        this.actualStatus = this.getStatus(orderversionscode.idStatus);
        this.order.isMultiservice = orderversionscode.isMultiservice;
        this.order.isSedeInterna = orderversionscode.isSedeInterna;
        this.order.idStatus = orderversionscode.idStatus;
        this.oldIdStatus = orderversionscode.idStatus;
        let sed = this.getSede(orderversionscode.idSede);
        let rq = this.getRequest(orderversionscode.idRifRequest);
        this.orderFormGroup.controls['sede'].patchValue(sed);
        this.orderFormGroup.controls['richiesta'].patchValue(rq);
        this.orderFormGroup.controls['note'].patchValue(orderversionscode.note);
        this.orderFormGroup.controls['financingSectionActivation'].patchValue(orderversionscode.financingSectionActivation);
        this.orderFormGroup.controls['fundedValue'].patchValue(orderversionscode.fundedValue);
        //this.orderFormGroup.controls['funding'].patchValue(orderversionscode.funding);
        this.orderFormGroup.controls['maggiorazioneFinanziamento'].patchValue(orderversionscode.maggiorazioneFinanziamento);
        this.orderFormGroup.controls['anteprimaFinanziamento'].patchValue(orderversionscode.anteprimaFinanziamento);
        this.orderFormGroup.controls['fundingId'].patchValue(orderversionscode.FundingId);
        this.orderFormGroup.controls['numberOfCondominiums'].patchValue(orderversionscode.numberOfCondominiums);
        this.orderFormGroup.controls['paymentMethodId'].patchValue(orderversionscode.PaymentMethodId);
        this.orderFormGroup.controls['estimateDeliveryMethodId'].patchValue(orderversionscode.EstimateDeliveryMethodId);
        this.orderFormGroup.controls['idStatus'].patchValue(orderversionscode.idStatus);
        this.orderFormGroup.controls['isSedeInterna'].patchValue(orderversionscode.isSedeInterna);
        this.orderFormGroup.controls['isMultiservice'].patchValue(orderversionscode.isMultiservice);
        this.orderFormGroup.controls['oneriSicurezza'].patchValue(orderversionscode.percOneriSicurezza);

        if (!(sed.CompaniesSocietiesRel == null) && sed.CompaniesSocietiesRel.length > 0) {
          this.ricMateriali = sed.CompaniesSocietiesRel[0].ricMateriali;
          this.ricManodopera = sed.CompaniesSocietiesRel[0].ricOreManodopera;
          this.ricMaterialiMultiServizi = sed.CompaniesSocietiesRel[0].ricMaterialiMultiServizi;
          this.ricOreManodoperaMultiServizi = sed.CompaniesSocietiesRel[0].ricOreManodoperaMultiServizi;
          this.firstTheshold = sed.CompaniesSocietiesRel[0].firstTheshold;
          this.secondTheshold = sed.CompaniesSocietiesRel[0].secondTheshold;
          this.firstThesholdMultiServizi = sed.CompaniesSocietiesRel[0].firstThesholdMultiServizi;
          this.secondThesholdMultiServizi = sed.CompaniesSocietiesRel[0].secondThesholdMultiServizi;
        }


        if (orderversionscode.idSubject > 0) {
          this.subjectService.getSubject(orderversionscode.idSubject).subscribe(s => {
            //console.log('-----------------------> subject %o',s);
            this.subjectFormGroup.controls['subject'].patchValue(s);
          });
        }
        if (orderversionscode.idBuilding > 0) {
          this.buildingService.findBuildingById(orderversionscode.idBuilding).subscribe(s => {
            //console.log('-----------------------> subject %o',s);
            this.buildingFormGroup.controls['building'].patchValue(s);
            this.loadDatasets(orderversionscode.idBuilding);
          });
        }
        if (orderversionscode.idCondominius > 0) {
          this.subjectService.getSubject(orderversionscode.idCondominius).subscribe(s => {
            //console.log('-----------------------> subject %o',s);
            this.condominiumFormGroup.controls['condominium'].patchValue(s);
          });
        }
        if (orderversionscode.idAdminCondominius > 0) {
          this.subjectService.getSubject(orderversionscode.idAdminCondominius).subscribe(s => {
            //console.log('-----------------------> subject %o',s);
            this.adminFormGroup.controls['admin'].patchValue(s);
          });
        }

        for (let a = 0; a < orderversionscode.OrdersOrderRowsRel.length; a++) {
          //console.log('PUSH MACROPHASES');
          this.order.rows.push(new OrderRow());
          this.openDetails.push({ open: false });
          let percCessioneCredito = 0;
          if (orderversionscode.OrdersOrderRowsRel[a].percCessioneCredito > 0)
            percCessioneCredito = orderversionscode.OrdersOrderRowsRel[a].percCessioneCredito;
          this.orderFormGroup.controls['macrophases'].value.push(new UntypedFormGroup({
            index: new UntypedFormControl(a),
            branch: new UntypedFormControl(this.getBranch(orderversionscode.OrdersOrderRowsRel[a].idBranch)),
            category: new UntypedFormControl(this.getCategory(orderversionscode.OrdersOrderRowsRel[a].idCategory)),
            macrophase: new UntypedFormControl(this.getMacroPhase(orderversionscode.OrdersOrderRowsRel[a].idMacroPhase)),
            percCredit: new UntypedFormControl(this.getPercCredit(percCessioneCredito)),
            isOpereSpeciali: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].isOpereSpeciali),
            isTrasferta: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].isTrasferta),
            isNotturno: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].isNotturno),
            isFestivo: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].isFestivo),
            isFreeDatas: new UntypedFormControl(this.getMacroPhase(orderversionscode.OrdersOrderRowsRel[a].idMacroPhase).isFreeDatas),
            howMany: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].howMany),
            realPrice: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].realPrice),
            costoCessioni: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].costoCessioni),
            costoOneri: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].costoOneri),
            countMF: new UntypedFormControl(countMacroPhase),
            phases: new UntypedFormArray([])
          }));



          let macroPhase = this.getMacroPhase(orderversionscode.OrdersOrderRowsRel[a].idMacroPhase);

          for (let b = 0; b < orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel.length; b++) {
            console.log('PUSH PHASES %o', orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b]);
            this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.push(new UntypedFormGroup({
              selected: new UntypedFormControl(true),
              branch: new UntypedFormControl(this.getBranch(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idBranch)),
              category: new UntypedFormControl(this.getCategory(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory)),
              macrophase: new UntypedFormControl(this.getMacroPhase(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idMacroPhase)),
              phase: new UntypedFormControl(this.getPhase(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idPhase)),
              percAmmaloramento: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].percAmmaloramento),
              qProdOrariaRif: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].qProdOrariaRif),
              cMaterRif: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].cMaterRif),
              qtToProduct: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].qtToProduct),
              qtOreManodopera: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].qtOreManodopera),
              ricMateriali: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].ricMateriali),
              ricOreManodopera: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].ricOreManodopera),
              costoManodoperaRif: new UntypedFormControl(this.getCategory(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory).costoManodoperaRif),
              percIncrementoFestivoRif: new UntypedFormControl(this.getCategory(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory).percIncrementoFestivoRif),
              percIncrementoTrasfertaRif: new UntypedFormControl(this.getCategory(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory).percIncrementoTrasfertaRif),
              percIncrementoNotturnoRif: new UntypedFormControl(this.getCategory(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory).percIncrementoNotturnoRif),
              isFreeDescription: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isFreeDescription),
              isFreeDatas: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isFreeDatas),
              isGaranzia: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isGaranzia),
              freeDescription: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].freeDescription),
              price: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].price),
              priceNet: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].priceNet),
              totalCost: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].totalCost),
              otherCost: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].otherCost),
              countMF: new UntypedFormControl(countMacroPhase),
              isDeliberato: new UntypedFormControl(orderversionscode.OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isDeliberato)
            }));

          }

          for (let a1 = 0; a1 < this.phases.length; a1++) {
            for (let b1 = 0; b1 < macroPhase.MacroPhasesPhasesRel.length; b1++) {
              //console.log('check phase %o and id %o with phase %o', macroPhase.MacroPhasesPhasesRel[b], macroPhase.MacroPhasesPhasesRel[b].MacroPhasesPhases.PhaseId, this.phases[a]);
              if (macroPhase.MacroPhasesPhasesRel[b1].MacroPhasesPhases.PhaseId == this.phases[a1].id && (this.notIn(this.phases[a1].id, this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value) == true)) {
                // console.log('PUSH id PHASE %o',this.phases[a1].id);
                this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.push(
                  new UntypedFormGroup({
                    selected: new UntypedFormControl(false),
                    branch: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('branch').value),
                    category: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value),
                    macrophase: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('macrophase').value),
                    phase: new UntypedFormControl(this.phases[a1]),
                    percAmmaloramento: new UntypedFormControl(100),
                    qProdOrariaRif: new UntypedFormControl(this.phases[a1].qProdOraria),
                    cMaterRif: new UntypedFormControl(this.phases[a1].cMater),
                    qtToProduct: new UntypedFormControl(0),
                    qtOreManodopera: new UntypedFormControl(0),
                    ricMateriali: new UntypedFormControl(this.ricMateriali),
                    ricOreManodopera: new UntypedFormControl(this.ricManodopera),
                    costoManodoperaRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.costoManodoperaRif),
                    percIncrementoFestivoRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.percIncrementoFestivoRif),
                    percIncrementoTrasfertaRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.percIncrementoTrasfertaRif),
                    percIncrementoNotturnoRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.percIncrementoNotturnoRif),
                    isFreeDescription: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.isFreeDescription),
                    isFreeDatas: new UntypedFormControl(false),
                    isGaranzia: new UntypedFormControl(false),
                    freeDescription: new UntypedFormControl(''),
                    countMF: new UntypedFormControl(countMacroPhase),
                    price: new UntypedFormControl(0),
                    priceNet: new UntypedFormControl(0),
                    totalCost: new UntypedFormControl(0),
                    otherCost: new UntypedFormControl(0),
                    isDeliberato: new UntypedFormControl(null),
                  })
                );
              }
            }
          }
          countMacroPhase++;
        }
        if (orderversionscode.realPrice > 0) {
          this.realPrice = Math.round(orderversionscode.realPrice * 100) / 100;
          this.order.realPrice = Math.round(orderversionscode.realPrice * 100) / 100;
        }
        else {
          this.realPrice = Math.round(this.getRicavoTotale() * 100) / 100;
          this.order.realPrice = Math.round(this.getRicavoTotale() * 100) / 100;
        }

        if (orderversionscode.realDescont > 0) {
          this.realDescont = orderversionscode.realDescont;
          this.order.realDescont = orderversionscode.realDescont;
        }
        else {
          this.realDescont = 0;
          this.order.realDescont = 0;
        }
        if (orderversionscode.idSede > 0) {
          this.libService.lockPage('');
          this.companyService.getCompany(orderversionscode.idSede).subscribe(c => {
            // console.log('people for company %o', c.CompaniesUsersRel);

            // console.log('------company %o for idSede %o', c, orderversionscode.idSede);

            this.resources = [];
            this.resourcesRespComm = [];
            this.resourcesCoord = [];
            let find = false;
            if (c == null) {
              this.libService.unlockPage();
              this.toastr.error(this.translate.instant('TOASTR.MESSAGE14') + orderversionscode.idSede + this.translate.instant('TOASTR.MESSAGE15'), this.translate.instant('TOASTR.INFO'));
              return;
            }


            if (!(c.CompaniesUsersRel == null) && c.CompaniesUsersRel.length > 0) {
              for (let iu = 0; iu < c.CompaniesUsersRel.length; iu++) {
                //console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', c.CompaniesUsersRel[iu])
                if (!(c.CompaniesUsersRel[iu].email == null) && c.CompaniesUsersRel[iu].email.length > 5) {
                  let el = { id: c.CompaniesUsersRel[iu].id, name: c.CompaniesUsersRel[iu].name, email: c.CompaniesUsersRel[iu].email };
                  this.resources.push(el);
                  if (this.checkCoordinatoriList(c.CompaniesUsersRel[iu]))
                    this.resourcesCoord.push(el)
                  if (this.checkRespComm(c.CompaniesUsersRel[iu]))
                    this.resourcesRespComm.push(el)

                }
              }
            }
            //console.log('userslist after init %o %o', this.resourcesCoord, this.resourcesRespComm)
            if (orderversionscode.idCoordinatore > 0) {
              if (orderversionscode.idRespCommerciale > 0) {
                forkJoin({
                  coordinatore: this.userDataService.getUser(orderversionscode.idCoordinatore),
                  responsabileCommerciale: this.userDataService.getUser(orderversionscode.idRespCommerciale)
                }).subscribe(
                  res => {
                    this.libService.unlockPage();
                    let iCoordinatore = this.findIndex(res.coordinatore.id, this.resources);
                    if (iCoordinatore == -1) {
                      iCoordinatore = this.resources.length;
                      // console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', res)
                      if (!(res.coordinatore.email == null) && res.coordinatore.email.length > 5) {
                        let el = { id: res.coordinatore.id, name: res.coordinatore.name, email: res.coordinatore.email };
                        this.resources.push(el);
                        this.resourcesCoord.push(el)

                      }
                    }
                    console.log('PATCH VALUE 1 %o', this.resources[iCoordinatore])
                    this.orderFormGroup.controls['coordinatore'].patchValue(this.resources[iCoordinatore]);

                    let iResponsabileCommerciale = this.findIndex(res.responsabileCommerciale.id, this.resources);
                    if (iResponsabileCommerciale == -1) {
                      iResponsabileCommerciale = this.resources.length;
                      // console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', res)


                      let el = { id: res.responsabileCommerciale.id, name: res.responsabileCommerciale.name, email: res.responsabileCommerciale.email };
                      this.resources.push(el);
                      this.resourcesRespComm.push(el);

                    }
                    console.log('PATCH VALUE 1 %o', this.resources[iResponsabileCommerciale])
                    this.orderFormGroup.controls['responsabileCommerciale'].patchValue(this.resources[iResponsabileCommerciale]);
                    //  console.log('orderFormGroup form B %o resources %o', res.coordinatore, this.resources);
                  },
                  err => {
                    this.libService.unlockPage();
                    this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
                  }
                );
              }
              else {
                this.userDataService.getUser(orderversionscode.idCoordinatore).subscribe(u => {
                  this.libService.unlockPage();
                  let iCoordinatore = this.findIndex(u.id, this.resources);
                  if (iCoordinatore == -1) {
                    iCoordinatore = this.resources.length;
                    // console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', u)
                    if (!(u.email == null) && u.email.length > 5) {
                      let el = { id: u.id, name: u.name, email: u.email };
                      this.resources.push(el);
                      this.resourcesRespComm.push(el);
                    }
                  }
                  console.log('PATCH VALUE 2 %o', this.resources[iCoordinatore])
                  this.orderFormGroup.controls['coordinatore'].patchValue(this.resources[iCoordinatore]);
                }, err => {
                  this.libService.unlockPage();
                  this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
                });
              }
            }
            else {
              if (orderversionscode.idRespCommerciale > 0) {
                this.userDataService.getUser(orderversionscode.idRespCommerciale).subscribe(u => {
                  let iResponsabileCommerciale = this.findIndex(u.id, this.resources);
                  if (iResponsabileCommerciale == -1) {
                    iResponsabileCommerciale = this.resources.length;
                    // console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', u)
                    if (!(u.email == null) && u.email.length > 5) {
                      let el = { id: u.id, name: u.name, email: u.email };
                      this.resources.push(el);
                      this.resourcesRespComm.push(el);
                    }
                  }
                  console.log('PATCH VALUE 2 %o', this.resources[iResponsabileCommerciale])
                  this.orderFormGroup.controls['responsabileCommerciale'].patchValue(this.resources[iResponsabileCommerciale]);
                  this.libService.unlockPage();
                }, err => {
                  this.libService.unlockPage();
                  this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
                });
              }
              else {
                this.libService.unlockPage();
              }
            }
          },
            err => {
              this.libService.unlockPage();
              this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
            });
        }
        this.setFinancingToggleActivation()
        this.initializePaymentMethods()
      })
    }

  }

  isDisabledForTC() {

    if (this.isFreeze == true)
      return true;
    else
      return false;
  }

  isVisibleDelibera() {
    if (!(this.actualStatus == null))
      return (this.actualStatus.isDeliberable == true)
    else return false;
  }

  getToolTipRequest(req: any) {
    let ret = 'Richiesta non presente';
    if (!(req == null) && !(req.workType == null))
      req.workType.workType + ' ' + req.building.name + ' ' + req.building.streetName + ' - ' + req.building.streetNumber + ', ' + req.building.city;

    return (ret);
  }

  getToolTipPrint() {
    return this.translate.instant('LABEL.DOWNLOAD_PREV');
  }

  getToolTipPreview() {
    return 'tooltip'
  }

  getValueDeliber() {
    let ret = 0.0;
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {
        if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isDeliberato'].value == true && this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value == true) {
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value > 0)
            ret = ret + this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value;
          else {
            if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['price'].value > 0)
              ret = ret + this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['price'].value;
          }
        }
      }
    }
    return ret;
  }

  getCostoCessione() {

    let ret = 0.0;

    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      let mf = 0;
      for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {
        if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value == true) {
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value > 0)
            mf = mf + this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value;
        }
      }
      //console.log('perc credit %o',this.orderFormGroup.controls['macrophases'].value[a].controls['percCredit'].value.percIncrement)
      //add pr oneri a mf
      // mf = mf + Math.round(mf * this.percOneriSicurezza) / 100;

      let sed = this.getSede(this.orderFormGroup.controls['sede'].value.id);
      let percIncrementCessione = this.orderFormGroup.controls['macrophases'].value[a].controls['percCredit'].value.percIncrement;
      if (sed.CompaniesSocietiesRel[0].type == 'FR')
        percIncrementCessione = this.orderFormGroup.controls['macrophases'].value[a].controls['percCredit'].value.percIncrementFranchising;
      //console.log('this.orderFormGroup.controls[sede].patchValue(sed) %o', percIncrementCessione)
      let mult = 1;
      /*
      if (this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService == true && this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value > 0)
        mult = this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value;
*/
      if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['percCredit'].value == null) && this.orderFormGroup.controls['macrophases'].value[a].controls['percCredit'].value.percIncrement > 1)
        ret = ret + (mf * (percIncrementCessione - 1) * mult);
    }
    return ret;
  }

  getMaggiorazioneFinanziamento() {
    return this.orderFormGroup.controls['maggiorazioneFinanziamento'].value
  }

  getCostoCessioneMacrofase(macrofase: any) {

    let ret = 0.0;
    let mf = 0;

    for (let b = 0; b < macrofase.controls['phases'].value.length; b++) {
      if (macrofase.controls['phases'].value[b].controls['selected'].value == true) {
        if (macrofase.controls['phases'].value[b].controls['priceNet'].value > 0)
          mf = mf + macrofase.controls['phases'].value[b].controls['priceNet'].value;
      }
    }

    let sed = this.getSede(this.orderFormGroup.controls['sede'].value.id);
    let percIncrementCessione = macrofase.controls['percCredit'].value.percIncrement;
    if (sed.CompaniesSocietiesRel[0].type == 'FR')
      percIncrementCessione = macrofase.controls['percCredit'].value.percIncrementFranchising;

    let mult = 1;

    if (!(macrofase.controls['percCredit'].value == null) && macrofase.controls['percCredit'].value.percIncrement > 1)
      ret = ret + (mf * (percIncrementCessione - 1) * mult);

    //ret = Math.round(ret * 100) / 100;
    return ret;
  }

  getDetail(v: any) {
    let ret = '';
    //console.log('search for version %o',v);
    for (let i = 0; i < this.orderVersions.length; i++) {
      if (this.orderVersions[i].version == v.id) {
        //console.log('find version %o - %o',v,this.orderVersions[i]);
        for (let a = 0; a < this.orderVersions[i].OrdersOrderRowsRel.length; a++) {
          let macroPhase = this.getMacroPhase(this.orderVersions[i].OrdersOrderRowsRel[a].idMacroPhase);
          //console.log('find macrophase %o',macroPhase);
          ret = ret + '<b>' + macroPhase.name + '</b><br />';
          for (let b = 0; b < this.orderVersions[i].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel.length; b++) {
            //console.log('PUSH PHASES');
            ret = ret + '&nbsp;&nbsp;' + this.orderVersions[i].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].descPhase + '&nbsp;&nbsp;<b>' + this.orderVersions[i].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].priceNet + '</b><br />';
          }
        }
      }
    }
    return ret;
  }

  confirmPrev(order: any) {
    console.log('confirm %o valueDeliber %o blocked %o', order, this.getCostoVenduto(), this.isVersionsBlocked);
    //count consegnato
    if (!(order.isLast == true) && this.isVersionsBlocked == true) {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE44'), this.translate.instant('LABEL.Attenzione'));
      return;
    }

    if (!(order.idStatus == STATUS_CREATA || order.idStatus == STATUS_REQUEST || order.idStatus == STATUS_PRESENTED || order.idStatus == STATUS_PRESENTED_OVER)) {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE44'), this.translate.instant('LABEL.Attenzione'));
      return;
    }


    if (window.confirm(this.translate.instant('LABEL.PRESENT'))) {
      order.realPrice = this.getRicavoReale();
      this.libService.lockPage('');
      this.orderService.presentOrder(order, this.getCostoVenduto()).subscribe(
        ret => {
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
          this.libService.unlockPage();
          this.ngOnInit();
          // AR this.router.navigate(['orders']);
        },
        err => {
          this.toastr.error(this.translate.instant('LABEL.Error'));
          this.libService.unlockPage();
        }
      );
    }
  }

  nullall(idorder: any) {
    if (window.confirm(this.translate.instant('LABEL.NULLALL'))) {
      this.libService.lockPage('');
      this.orderService.nullOrder(idorder).subscribe(
        ret => {
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
          //setTimeout('', 3000);
          this.libService.unlockPage();
          this.ngOnInit();
          // AR this.router.navigate(['orders']);
        },
        err => {
          this.toastr.error(this.translate.instant('LABEL.Error'));
          this.libService.unlockPage();
        }
      );
    }
  }

  newversion() {
    if (!(this.order.isLast == true) && this.isVersionsBlocked == true) {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE44'), this.translate.instant('LABEL.Attenzione'));
      return;
    }
    this.libService.lockPage('');
    if (window.confirm(this.translate.instant('LABEL.NEWVERSION'))) {
      this.update(true);
      this.libService.unlockPage();
    }
    else {
      this.libService.unlockPage();
    }
  }

  filterBranches(branches: any, idSede: any) {
    let ret = [];
    if (!(idSede == null)) {
      //  console.log('console.log idSede %o', this.order.idSede);
      for (let a = 0; a < branches.length; a++) {
        for (let b = 0; b < branches[a].BCRel.length; b++) {
          if (branches[a].BCRel[b].id == idSede)
            ret.push(branches[a])
        }
      }
    }
    return ret;
  }

  updateBranchesAndSoOn() {
    let langCode = this.translate.currentLang.toUpperCase();
    forkJoin({
      branches: this.branchService.getBranchesByLangCode(langCode),
      categories: this.categoryService.getCategoriesByLangCode(langCode),
      macroPhases: this.macroPhaseService.getMacroPhasesByLangCode(langCode),
      phases: this.phaseService.getPhasesByLangCode(langCode),
    }).subscribe(res => {
      //console.log('RES %o', res);
      this.branches = res.branches;
      this.categories = res.categories;
      this.macroPhases = res.macroPhases;
      this.phases = res.phases;
    });
  }

  filterBySede(arr: any, sede: any) {

    let ret: any[] = [];
    let code = 'IT';
    switch (sede['CompaniesSocietiesRel'][0].nation.toLowerCase()) {
      case 'espana':
        code = 'ES';
        break;

      case 'es':
        code = 'ES';
        break;

      case 'esp':
        code = 'ES';
        break;

      case 'fra':
        code = 'FR';
        break;

      case 'fr':
        code = 'FR';
        break;

      case 'france':
        code = 'FR';
        break;

      case 'mc':
        code = 'MC';
        break;

      case 'monaco':
        code = 'MC';
        break;

      default:
        code = 'IT';
        break;
    }

    for (let a = 0; a < arr.length; a++) {
      if (arr[a].codCountry == code)
        ret.push(arr[a]);
    }
    //console.log('SEDE to filter %o ret %o', sede['CompaniesSocietiesRel'][0].nation, ret)
    return ret;
  }

  filterStatusManaged(statuses: any) {
    let ret: any[] = [];
    //the user is tc or ra?
    console.log('user %o', this.userSessionService.getState('user'));
    for (let a = 0; a < statuses.length; a++) {
      //this.isAdmin
      //this.isStaff
      //this.isOperational
      let find = false;
      if (this.isAdmin && statuses[a].isManagedByAdmin == 1) {
        let ts = statuses[a];
        ts.disabled = false;
        ret.push(ts);
        find = true;
      }
      if (find == false && this.isStaff && statuses[a].isManagedByStaff == 1) {
        let ts = statuses[a];
        ts.disabled = false;
        ret.push(ts);
        find = true;
      }
      if (find == false && this.isOperational && statuses[a].isManagedByUser == 1) {
        let ts = statuses[a];
        ts.disabled = false;
        ret.push(ts);
      }
    }
    return ret;
  }

  addFreeRow(i: any) {
    console.log('phases %o', this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value);
    let isGaranzia = false;
    if (this.order.idStatus == STATUS_WARRANTY)
      isGaranzia = true;
    this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value.unshift(
      new UntypedFormGroup({
        selected: new UntypedFormControl(true),
        branch: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].controls['branch'].value),
        category: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].controls['category'].value),
        macrophase: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].value),
        phase: new UntypedFormControl(0),
        percAmmaloramento: new UntypedFormControl(100),
        qProdOrariaRif: new UntypedFormControl(0),
        cMaterRif: new UntypedFormControl(0),
        qtToProduct: new UntypedFormControl(0),
        qtOreManodopera: new UntypedFormControl(0),
        ricMateriali: new UntypedFormControl(0),
        ricOreManodopera: new UntypedFormControl(0),
        costoManodoperaRif: new UntypedFormControl(0),
        percIncrementoFestivoRif: new UntypedFormControl(0),
        percIncrementoTrasfertaRif: new UntypedFormControl(0),
        percIncrementoNotturnoRif: new UntypedFormControl(0),
        isFreeDescription: new UntypedFormControl(true),
        isFreeDatas: new UntypedFormControl(true),
        freeDescription: new UntypedFormControl(''),
        isGaranzia: new UntypedFormControl(isGaranzia),
        isDeliberato: new UntypedFormControl(false),
        totalCost: new UntypedFormControl(0),
        otherCost: new UntypedFormControl(0),
        key: new UntypedFormControl(0),
        price: new UntypedFormControl(0),
        countMF: new UntypedFormControl(0),
        priceNet: new UntypedFormControl(0)
      }));
  }

  checkCoordinatoriList(u: any) {
    // console.log('checkCoordinatoriList for %o', u)
    if (!(u.UsersProfilesRel == null) && u.UsersProfilesRel.length > 0) {
      for (let a = 0; a < u.UsersProfilesRel.length; a++) {
        // console.log('checkCoordinatoriList single profile for %o', u.UsersProfilesRel[a].UsersProfiles.ProfileId)
        if (u.UsersProfilesRel[a].UsersProfiles.ProfileId == PROFILE_RA)
          return true;
        if (u.UsersProfilesRel[a].UsersProfiles.ProfileId == PROFILE_AFF)
          return true;
        if (u.UsersProfilesRel[a].UsersProfiles.ProfileId == PROFILE_COORD)
          return true;

      }
    }
    return false;
  }

  checkRespComm(u: any) {
    if (!(u.UsersProfilesRel == null) && u.UsersProfilesRel.length > 0) {
      for (let a = 0; a < u.UsersProfilesRel.length; a++) {
        if (u.UsersProfilesRel[a].UsersProfiles.ProfileId == PROFILE_RA)
          return true;
        if (u.UsersProfilesRel[a].UsersProfiles.ProfileId == PROFILE_AFF)
          return true;
        if (u.UsersProfilesRel[a].UsersProfiles.ProfileId == PROFILE_TC)
          return true;
        if (u.UsersProfilesRel[a].UsersProfiles.ProfileId == PROFILE_RC)
          return true;
      }
    }
    return false;
  }

  getIsVersionsBlocked(idStatus: any) {
    console.log('-------> getIsVersionsBlocked parameter %o', idStatus)
    if (idStatus == STATUS_CREATA || idStatus == STATUS_REQUEST || idStatus == STATUS_PRESENTED || idStatus == STATUS_PRESENTED_OVER) {
      return false;
    }
    else {
      return true;
    }
  }


  ngOnInit(): void {


    // AL_EDAC -> Recupera il valore del parametro "edacDev" passato nell'url per la visulizzazione in Dev di parti di codice in sviluppo.
    // la variabile showFieldsNotYetImplemented, a seconda del valore, nascone o mostra alcune funzionalità.
    this.route.queryParams
      .subscribe((params: any) => {
        if (params.edacDev) {
          //this.showFieldsNotYetImplemented = [true, 'true'].includes(params.edacDev) ? true : false;
        }
      }
      );

    this.sended = false;
    this.resources = [];
    this.resourcesCoord = [];
    this.resourcesRespComm = [];
    this.buildingFormGroup = new UntypedFormGroup({
      building: new UntypedFormControl({}, [BuildingValidator()])
    });

    this.condominiumFormGroup = new UntypedFormGroup({
      condominium: new UntypedFormControl()
    });

    this.subjectFormGroup = new UntypedFormGroup({
      subject: new UntypedFormControl(),
      isSigning: new UntypedFormControl(false)
    });

    this.adminFormGroup = new UntypedFormGroup({
      admin: new UntypedFormControl(),
      isSigning: new UntypedFormControl(false)
    });

    this.orderFormGroup = new UntypedFormGroup({
      sede: new UntypedFormControl({}),
      richiesta: new UntypedFormControl({}),
      responsabileCommerciale: new UntypedFormControl({}),
      coordinatore: new UntypedFormControl({}),
      note: new UntypedFormControl('', [Validators.required]),
      financingSectionActivation: new UntypedFormControl(0),
      fundedValue: new UntypedFormControl(0),
      funding: new UntypedFormControl(null),
      maggiorazioneFinanziamento: new UntypedFormControl(0),
      anteprimaFinanziamento: new UntypedFormControl(0),
      fundingId: new UntypedFormControl(null),
      numberOfCondominiums: new UntypedFormControl(null),
      paymentMethodId: new UntypedFormControl(null, [Validators.required]),
      estimateDeliveryMethodId: new UntypedFormControl(null),
      isSedeInterna: new UntypedFormControl(false),
      isMultiservice: new UntypedFormControl(false),
      idStatus: new UntypedFormControl(14),
      oneriSicurezza: new UntypedFormControl(0),
      macrophases: new UntypedFormArray([])
    });

    this.searchService = this.subjectService;
    let actions: any;
    actions = [
      { name: 'back', url: 'orders' }
    ];
    this.title = this.translate.instant('LABEL.NEW_ORDER');
    this.navService.setActions(actions);
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id && this.id.length > 0 && !(this.id == '0')) {
      this.title = this.translate.instant('LABEL.EDIT_ORDER');
      this.subTitle = "EDAC - " + this.title;
      this.isNew = false;
    }
    this.libService.lockPage('');
    this.navService.setWikiKey('NODEF');
    this.user = this.authService.user();
    //let langCode = this.translate.currentLang.toUpperCase();

    forkJoin({
      //branches: this.branchService.getBranchesByLangCode(langCode),
      //categories: this.categoryService.getCategoriesByLangCode(langCode),
      //macroPhases: this.macroPhaseService.getMacroPhasesByLangCode(langCode),
      //phases: this.phaseService.getPhasesByLangCode(langCode),
      branches: this.branchService.getAllBranches(),
      categories: this.categoryService.getAllCategories(),
      macroPhases: this.macroPhaseService.getAllMacroPhases(),
      phases: this.phaseService.getAllPhases(),
      taxSteps: this.taxStepService.getAllSteps(),
      companies: this.companyService.getAllOpenCompanies(),
      statuses: this.orderService.getOrderStatuses(),

      // requests: this.requestService.getAllRequestsByAssignedOperator(this.authService.user().email)
    }).subscribe(res => {

      console.log('RES %o', res);

      this.masterBranches = res.branches;
      this.masterCategories = res.categories;
      this.masterMacroPhases = res.macroPhases;
      this.masterPhases = res.phases;
      this.taxSteps = res.taxSteps;
      this.companies = res.companies;
      this.orderStatuses = res.statuses;
      this.orderStatusesManaged = this.filterStatusManaged(res.statuses);
      let self = this;
      let countMacroPhase = 0;
      //    this.requests = this.transRequests(res.requests);
      this.requests = [];

      if (this.id && this.id.length > 0 && !(this.id == '0')) {
        console.log('-------> getOrderByCode parameter %o', this.id)
        this.orderService.getOrderByCode(this.id).subscribe((responseData: any) => {
          console.log('-------> getOrderByCode %o', responseData);
          //calc isVersionsBlocked->is about idStatus of isLast
          this.isVersionsBlocked = this.getIsVersionsBlocked(responseData[0].idStatus);
          if (responseData[0].idStatus == STATUS_WARRANTY)
            this.isWarranty = true;
          this.orderService.getOrderVersions(responseData[0].code).subscribe(versions => {
            this.versions = versions;
            this.libService.unlockPage();
            if (responseData.length > 0) {
              console.log(responseData, 'order-' + this.id);
              //get request
              // put it in requests
              this.orderVersions = responseData;

              this.actualversion = responseData[0].version;
              this.actualIndexVersion = 0;

              console.log('VERSIONS %o actualVersion %o actualIndexVersion %o', this.versions, this.actualversion, this.actualIndexVersion)

              this.order = new Order();
              this.order.rows = [];
              this.openDetails = [];
              this.order.code = responseData[this.actualIndexVersion].code;
              this.order.idSede = responseData[this.actualIndexVersion].idSede;
              this.order.id = responseData[this.actualIndexVersion].id;
              this.getPreventivoTragitti(responseData[this.actualIndexVersion].id);
              this.order.version = responseData[this.actualIndexVersion].version;
              this.order.who = responseData[this.actualIndexVersion].who;
              this.order.note = responseData[this.actualIndexVersion].note;

              this.order.financingSectionActivation = responseData[this.actualIndexVersion].financingSectionActivation;
              this.order.fundedValue = responseData[this.actualIndexVersion].fundedValue;
              //this.order.funding = responseData[this.actualIndexVersion].funding;
              this.order.maggiorazioneFinanziamento = responseData[this.actualIndexVersion].maggiorazioneFinanziamento;
              this.order.anteprimaFinanziamento = responseData[this.actualIndexVersion].anteprimaFinanziamento;
              this.order.fundingId = responseData[this.actualIndexVersion].fundingId;
              this.order.numberOfCondominiums = responseData[this.actualIndexVersion].numberOfCondominiums;
              this.order.paymentMethodId = responseData[this.actualIndexVersion].paymentMethodId;
              this.order.estimateDeliveryMethodId = responseData[this.actualIndexVersion].estimateDeliveryMethodId;
              this.order.fatturato = responseData[this.actualIndexVersion].fatturato;
              this.order.incassato = responseData[this.actualIndexVersion].incassato;
              this.order.certificato = responseData[this.actualIndexVersion].certificato;
              this.order.valoreProdotto = responseData[this.actualIndexVersion].valoreProdotto;
              this.order.valoreSalTotal = responseData[this.actualIndexVersion].valoreSalTotal;
              this.order.finalMaterial = responseData[this.actualIndexVersion].finalMaterial;
              this.order.qtOreManodoperaTotal = responseData[this.actualIndexVersion].qtOreManodoperaTotal;
              this.order.salProgressPercentage = responseData[this.actualIndexVersion].salProgressPercentage;
              this.order.salTotal = responseData[this.actualIndexVersion].salTotal;
              this.order.totaleOreConsuntivate = responseData[this.actualIndexVersion].totaleOreConsuntivate;
              this.order.materialePreventivato = responseData[this.actualIndexVersion].materialePreventivato;
              this.order.mlcCommessa = responseData[this.actualIndexVersion].mlcCommessa;

              this.documentService.findByOrder(this.order.id.toString()).subscribe(documents => {
                this.order.documents = documents
              })
              //this.order.documents = responseData[this.actualIndexVersion].documents;
              this.order.idStatus = responseData[this.actualIndexVersion].idStatus;
              //  this.order.OPRel = responseData[this.actualIndexVersion].OPRel;
              this.order.streetName = responseData[this.actualIndexVersion].streetName;
              this.order.streetNumber = responseData[this.actualIndexVersion].streetNumber;
              this.order.otherInfo = responseData[this.actualIndexVersion].otherInfo;
              this.order.city = responseData[this.actualIndexVersion].city;
              this.order.province = responseData[this.actualIndexVersion].province;
              this.order.state = responseData[this.actualIndexVersion].state;
              this.order.isLast = responseData[this.actualIndexVersion].isLast;
              this.order.zip = responseData[this.actualIndexVersion].zip;
              this.order.country = responseData[this.actualIndexVersion].country;
              this.order.probChiusuraPratica = responseData[this.actualIndexVersion].probChiusuraPratica;
              this.order.isAssemblea = responseData[this.actualIndexVersion].isAssemblea;
              this.order.dataPrevistaConfermaOrdine = responseData[this.actualIndexVersion].dataPrevistaConfermaOrdine;
              this.order.dataPrevistaAssemblea = responseData[this.actualIndexVersion].dataPrevistaAssemblea;
              this.order.isTCAssemblea = responseData[this.actualIndexVersion].isTCAssemblea;
              this.order.deadLine = responseData[this.actualIndexVersion].deadLine;
              this.order.isDeliberato = responseData[this.actualIndexVersion].isDeliberato;
              this.order.dateDelibera = responseData[this.actualIndexVersion].dateDelibera;
              this.order.percOneriSicurezza = responseData[this.actualIndexVersion].percOneriSicurezza;
              this.order.isSalCento = responseData[this.actualIndexVersion].isSalCento;

              this.order.startx = responseData[this.actualIndexVersion].startx;
              this.order.starty = responseData[this.actualIndexVersion].starty;
              this.order.endx = responseData[this.actualIndexVersion].endx;
              this.order.endy = responseData[this.actualIndexVersion].endy;
              this.order.km = responseData[this.actualIndexVersion].km;
              this.order.kmRitorno = responseData[this.actualIndexVersion].kmRitorno;

              if (this.order.idStatus == STATUS_PRE_DELIBER || this.order.idStatus == STATUS_PRE_DELIBER_OVER)
                this.isReview = true;
              else
                this.isReview = false;
              this.actualStatus = this.getStatus(responseData[this.actualIndexVersion].idStatus);
              this.order.idStatus = responseData[this.actualIndexVersion].idStatus;
              this.oldIdStatus = responseData[this.actualIndexVersion].idStatus;
              this.order.isMultiservice = responseData[this.actualIndexVersion].isMultiservice;
              this.order.isSedeInterna = responseData[this.actualIndexVersion].isSedeInterna;
              this.order.idRifRequest = responseData[this.actualIndexVersion].idRifRequest;
              if (!(this.order.idRifRequest == null)) {
                this.documentService.find({ where: { requestId: this.order.idRifRequest } }).subscribe(documents => {
                  this.requestDocuments = documents
                })
              }
              else
                this.requestDocuments = [];
              let sed = this.getSede(responseData[this.actualIndexVersion].idSede);
              console.log('----------------------------------- SEDE %o', sed);
              this.branches = this.filterBySede(this.masterBranches, sed);
              this.categories = this.filterBySede(this.masterCategories, sed);
              this.macroPhases = this.filterBySede(this.masterMacroPhases, sed);
              this.phases = this.filterBySede(this.masterPhases, sed);
              //  console.log('-----------------------------------after filter sede BRANCHES %o CATEGORIES %o MACROPHASES %o PHASES %o SEDE %o', this.branches, this.categories, this.macroPhases, this.phases);
              this.orderFormGroup.controls['sede'].patchValue(sed);
              this.orderFormGroup.controls['idStatus'].patchValue(responseData[this.actualIndexVersion].idStatus);
              //  console.log('----------------------------- STATUS %o id %o', this.orderFormGroup.controls['idStatus'].value, this.orderFormGroup.controls['idStatus'].value.id);
              this.orderFormGroup.controls['note'].patchValue(responseData[this.actualIndexVersion].note);
              this.orderFormGroup.controls['financingSectionActivation'].patchValue(responseData[this.actualIndexVersion].financingSectionActivation);
              this.orderFormGroup.controls['fundedValue'].patchValue(responseData[this.actualIndexVersion].fundedValue);

              // this.orderFormGroup.controls['funding'].patchValue(responseData[this.actualIndexVersion].funding);

              this.orderFormGroup.controls['maggiorazioneFinanziamento'].patchValue(responseData[this.actualIndexVersion].maggiorazioneFinanziamento);
              this.orderFormGroup.controls['anteprimaFinanziamento'].patchValue(responseData[this.actualIndexVersion].anteprimaFinanziamento);
              this.orderFormGroup.controls['fundingId'].patchValue(responseData[this.actualIndexVersion].fundingId);
              this.orderFormGroup.controls['numberOfCondominiums'].patchValue(responseData[this.actualIndexVersion].numberOfCondominiums);
              this.orderFormGroup.controls['paymentMethodId'].patchValue(responseData[this.actualIndexVersion].paymentMethodId);
              this.orderFormGroup.controls['estimateDeliveryMethodId'].patchValue(responseData[this.actualIndexVersion].estimateDeliveryMethodId);
              this.orderFormGroup.controls['isSedeInterna'].patchValue(responseData[this.actualIndexVersion].isSedeInterna);
              this.orderFormGroup.controls['isMultiservice'].patchValue(responseData[this.actualIndexVersion].isMultiservice);
              this.orderFormGroup.controls['oneriSicurezza'].patchValue(responseData[this.actualIndexVersion].percOneriSicurezza);
              //this.branches = this.filterBranches(res.branches, this.order.idSede);
              if (!(sed.CompaniesSocietiesRel == null) && sed.CompaniesSocietiesRel.length > 0) {
                this.ricMateriali = sed.CompaniesSocietiesRel[0].ricMateriali;
                this.ricManodopera = sed.CompaniesSocietiesRel[0].ricOreManodopera;
                this.ricMateriali = sed.CompaniesSocietiesRel[0].ricMateriali;
                this.ricManodopera = sed.CompaniesSocietiesRel[0].ricOreManodopera;
                this.ricMaterialiMultiServizi = sed.CompaniesSocietiesRel[0].ricMaterialiMultiServizi;
                this.ricOreManodoperaMultiServizi = sed.CompaniesSocietiesRel[0].ricOreManodoperaMultiServizi;
                this.firstTheshold = sed.CompaniesSocietiesRel[0].firstTheshold;
                this.secondTheshold = sed.CompaniesSocietiesRel[0].secondTheshold;
                this.firstThesholdMultiServizi = sed.CompaniesSocietiesRel[0].firstThesholdMultiServizi;
                this.secondThesholdMultiServizi = sed.CompaniesSocietiesRel[0].secondThesholdMultiServizi;
              }

              if (responseData[this.actualIndexVersion].idSubject > 0) {
                this.subjectService.getSubject(responseData[0].idSubject).subscribe(s => {
                  //console.log('-----------------------> subject %o',s);
                  this.subjectFormGroup.controls['subject'].patchValue(s);
                });
              }
              if (responseData[this.actualIndexVersion].idBuilding > 0) {
                this.buildingService.findBuildingById(responseData[0].idBuilding).subscribe(s => {
                  //console.log('-----------------------> subject %o',s);
                  this.buildingFormGroup.controls['building'].patchValue(s);
                  this.loadDatasets(responseData[0].idBuilding);
                });
              }
              if (responseData[this.actualIndexVersion].idCondominius > 0) {
                this.subjectService.getCond(responseData[0].idCondominius).subscribe(s => {
                  //console.log('-----------------------> subject %o',s);
                  this.condominiumFormGroup.controls['condominium'].patchValue(s);
                });
              }
              if (responseData[this.actualIndexVersion].idAdminCondominius > 0) {
                this.subjectService.getSubject(responseData[0].idAdminCondominius).subscribe(s => {
                  //console.log('-----------------------> subject %o',s);
                  this.adminFormGroup.controls['admin'].patchValue(s);
                });
              }
              if (responseData[this.actualIndexVersion].idStatus > 0) {
                this.documentWorkflowService.getByStatusId(this.user.email, responseData[0].idStatus).subscribe(s => {
                  //console.log('-----------------------> document by status %o', s);
                  if (!(s == null) && (s.length > 0))
                    this.documentsFromStatus = s[0]['StatusChangesDocTypesRel'];
                });
              }

              let idReq = responseData[this.actualIndexVersion].idRifRequest;
              if (idReq > 0) {
                // console.log('search for idreq %o', idReq)
                this.requestService.getRequest(idReq).subscribe(rq => {
                  // console.log('-----------------------> request find %o', rq);
                  if (!(rq == null)) {
                    self.requests.push(rq);
                    let rqor = this.getRequest(rq.id);
                    self.orderFormGroup.controls['richiesta'].patchValue(rqor);
                  }
                });
              }

              for (let a = 0; a < responseData[this.actualIndexVersion].OrdersOrderRowsRel.length; a++) {
                this.order.rows.push(new OrderRow());
                this.openDetails.push({ open: false });
                let percCessioneCredito = 0;
                if (responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].percCessioneCredito > 0)
                  percCessioneCredito = responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].percCessioneCredito;
                this.orderFormGroup.controls['macrophases'].value.push(new UntypedFormGroup({
                  index: new UntypedFormControl(a),
                  branch: new UntypedFormControl(this.getBranch(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].idBranch)),
                  category: new UntypedFormControl(this.getCategory(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].idCategory)),
                  macrophase: new UntypedFormControl(this.getMacroPhase(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].idMacroPhase)),
                  percCredit: new UntypedFormControl(this.getPercCredit(percCessioneCredito)),
                  isOpereSpeciali: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].isOpereSpeciali),
                  isTrasferta: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].isTrasferta),
                  isNotturno: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].isNotturno),
                  isFestivo: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].isFestivo),
                  isFreeDatas: new UntypedFormControl(this.getMacroPhase(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].idMacroPhase).isFreeDatas),
                  howMany: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].howMany),
                  realPrice: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].realPrice),
                  costoCessioni: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].costoCessioni),
                  costoOneri: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].costoOneri),
                  countMF: new UntypedFormControl(countMacroPhase),
                  key: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].id),
                  phases: new UntypedFormArray([])
                }));

                let macroPhase = this.getMacroPhase(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].idMacroPhase);
                for (let b = 0; b < responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel.length; b++) {
                  let priceNet = responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].priceNet;


                  let price = responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].price;
                  if (!(priceNet > 0))
                    priceNet = price;
                  console.log('PUSH PHASES %o', responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isFreeDatas);
                  this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.push(new UntypedFormGroup({
                    selected: new UntypedFormControl(true),
                    branch: new UntypedFormControl(this.getBranch(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idBranch)),
                    category: new UntypedFormControl(this.getCategory(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory)),
                    macrophase: new UntypedFormControl(this.getMacroPhase(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idMacroPhase)),
                    phase: new UntypedFormControl(this.getPhase(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idPhase)),
                    percAmmaloramento: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].percAmmaloramento),
                    qProdOrariaRif: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].qProdOrariaRif),
                    cMaterRif: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].cMaterRif),
                    qtToProduct: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].qtToProduct),
                    qtOreManodopera: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].qtOreManodopera),
                    ricMateriali: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].ricMateriali),
                    ricOreManodopera: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].ricOreManodopera),
                    costoManodoperaRif: new UntypedFormControl(this.getCategory(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory).costoManodoperaRif),
                    percIncrementoFestivoRif: new UntypedFormControl(this.getCategory(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory).percIncrementoFestivoRif),
                    percIncrementoTrasfertaRif: new UntypedFormControl(this.getCategory(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory).percIncrementoTrasfertaRif),
                    percIncrementoNotturnoRif: new UntypedFormControl(this.getCategory(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].idCategory).percIncrementoNotturnoRif),
                    isFreeDescription: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isFreeDescription),
                    isFreeDatas: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isFreeDatas),
                    freeDescription: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].freeDescription),
                    isDeliberato: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isDeliberato),
                    isGaranzia: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].isGaranzia),
                    totalCost: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].totalCost),
                    otherCost: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].otherCost),
                    key: new UntypedFormControl(responseData[this.actualIndexVersion].OrdersOrderRowsRel[a].OrderRowsOrderPhasesRel[b].id),
                    price: new UntypedFormControl(price),
                    countMF: new UntypedFormControl(countMacroPhase),
                    priceNet: new UntypedFormControl(priceNet)
                  }));

                }

                for (let a1 = 0; a1 < this.phases.length; a1++) {
                  if (!(macroPhase.MacroPhasesPhasesRel == null)) {
                    for (let b1 = 0; b1 < macroPhase.MacroPhasesPhasesRel.length; b1++) {
                      //console.log('check phase %o and id %o with phase %o', macroPhase.MacroPhasesPhasesRel[b1], macroPhase.MacroPhasesPhasesRel[b1].MacroPhasesPhases.PhaseId, this.phases[a]);
                      if (macroPhase.MacroPhasesPhasesRel[b1].MacroPhasesPhases.PhaseId == this.phases[a1].id && (this.notIn(this.phases[a1].id, this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value) == true)) {
                        // console.log('PUSH id PHASE %o',this.phases[a1].id);
                        this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.push(
                          new UntypedFormGroup({
                            selected: new UntypedFormControl(false),
                            branch: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('branch').value),
                            category: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value),
                            macrophase: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('macrophase').value),
                            phase: new UntypedFormControl(this.phases[a1]),
                            percAmmaloramento: new UntypedFormControl(100),
                            qProdOrariaRif: new UntypedFormControl(this.phases[a1].qProdOraria),
                            cMaterRif: new UntypedFormControl(this.phases[a1].cMater),
                            qtToProduct: new UntypedFormControl(0),
                            qtOreManodopera: new UntypedFormControl(0),
                            ricMateriali: new UntypedFormControl(this.ricMateriali),
                            ricOreManodopera: new UntypedFormControl(this.ricManodopera),
                            costoManodoperaRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.costoManodoperaRif),
                            percIncrementoFestivoRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.percIncrementoFestivoRif),
                            percIncrementoTrasfertaRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.percIncrementoTrasfertaRif),
                            percIncrementoNotturnoRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.percIncrementoNotturnoRif),
                            isFreeDescription: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[a].get('category').value.isFreeDescription),
                            isFreeDatas: new UntypedFormControl(false),
                            isGaranzia: new UntypedFormControl(false),
                            freeDescription: new UntypedFormControl(''),
                            isDeliberato: new UntypedFormControl(null),
                            countMF: new UntypedFormControl(countMacroPhase),
                            key: new UntypedFormControl(0),
                            price: new UntypedFormControl(0),
                            priceNet: new UntypedFormControl(0),
                            totalCost: new UntypedFormControl(0),
                            otherCost: new UntypedFormControl(0)
                          })
                        );
                      }
                    }
                  }
                }
                countMacroPhase++;
              }

              this.setFinancingToggleActivation()
              this.initializePaymentMethods()

              if (responseData[this.actualIndexVersion].realPrice > 0) {
                this.realPrice = Math.round(responseData[this.actualIndexVersion].realPrice * 100) / 100;
                this.order.realPrice = Math.round(responseData[this.actualIndexVersion].realPrice * 100) / 100;
              }
              else {
                this.realPrice = Math.round(this.getRicavoTotale() * 100) / 100;
                this.order.realPrice = Math.round(this.getRicavoTotale() * 100) / 100;
              }

              if (responseData[this.actualIndexVersion].realDescont > 0) {
                this.realDescont = responseData[this.actualIndexVersion].realDescont;
                this.order.realDescont = responseData[this.actualIndexVersion].realDescont;
              }
              else {
                this.realDescont = 0;
                this.order.realDescont = 0;
              }
              if (responseData[this.actualIndexVersion].idSede > 0) {
                this.libService.lockPage('');
                this.companyService.getCompany(responseData[this.actualIndexVersion].idSede).subscribe(c => {
                  //console.log('------company %o for idSede %o oneri %o', c, responseData[this.actualIndexVersion].idSede,c.CompaniesSocietiesRel[0].percOneriSicurezza);
                  if ((!(this.order.percOneriSicurezza > 0) || !(this.order.percOneriSicurezza == 0)) && c.CompaniesSocietiesRel[0].percOneriSicurezza > 0) {
                    this.order.percOneriSicurezza = c.CompaniesSocietiesRel[0].percOneriSicurezza;
                    this.percOneriSicurezza = c.CompaniesSocietiesRel[0].percOneriSicurezza;
                    this.orderFormGroup.controls['oneriSicurezza'].patchValue(this.percOneriSicurezza);
                  }
                  //oneriSicurezz!
                  this.resources = [];
                  this.resourcesCoord = [];
                  this.resourcesRespComm = [];
                  let find = false;
                  if (c == null) {
                    this.libService.unlockPage();
                    this.toastr.error(this.translate.instant('TOASTR.MESSAGE14') + responseData[this.actualIndexVersion].idSede + this.translate.instant('TOASTR.MESSAGE15'), this.translate.instant('TOASTR.WARNING'));
                    return;
                  }
                  this.company = c;
                  if (!(c.CompaniesUsersRel == null) && c.CompaniesUsersRel.length > 0) {
                    for (let iu = 0; iu < c.CompaniesUsersRel.length; iu++) {
                      // console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', c.CompaniesUsersRel[iu])
                      if (!(c.CompaniesUsersRel[iu].email == null) && c.CompaniesUsersRel[iu].email.length > 5) {
                        let el = { id: c.CompaniesUsersRel[iu].id, name: c.CompaniesUsersRel[iu].name, email: c.CompaniesUsersRel[iu].email };
                        this.resources.push(el);
                        if (this.checkCoordinatoriList(c.CompaniesUsersRel[iu]))
                          this.resourcesCoord.push(el)
                        if (this.checkRespComm(c.CompaniesUsersRel[iu]))
                          this.resourcesRespComm.push(el)
                      }
                    }
                  }
                  //console.log('userslist after init %o %o', this.resourcesCoord, this.resourcesRespComm)

                  if (responseData[this.actualIndexVersion].idCoordinatore > 0) {
                    if (responseData[this.actualIndexVersion].idRespCommerciale > 0) {
                      forkJoin({
                        coordinatore: this.userDataService.getUser(responseData[this.actualIndexVersion].idCoordinatore),
                        responsabileCommerciale: this.userDataService.getUser(responseData[this.actualIndexVersion].idRespCommerciale)
                      }).subscribe(
                        res => {
                          this.libService.unlockPage();

                          let iCoordinatore = 0;
                          if (!(res.coordinatore == null)) {
                            iCoordinatore = this.findIndex(res.coordinatore.id, this.resources);
                            if (iCoordinatore == -1) {
                              iCoordinatore = this.resources.length;
                              // console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ (a) push resource %o', res)
                              if (!(res.coordinatore.email == null) && res.coordinatore.email.length > 5) {
                                let el = { id: res.coordinatore.id, name: res.coordinatore.name, email: res.coordinatore.email };
                                this.resources.push(el);
                                this.resourcesRespComm.push(el);
                              }
                            }
                          }
                          //console.log('PATCH VALUE 3 %o', this.resources[iCoordinatore])
                          this.orderFormGroup.controls['coordinatore'].patchValue(this.resources[iCoordinatore]);

                          let iResponsabileCommerciale = 0;
                          if (!(res.responsabileCommerciale == null)) {
                            iResponsabileCommerciale = this.findIndex(res.responsabileCommerciale.id, this.resources);
                            if (iResponsabileCommerciale == -1) {
                              iResponsabileCommerciale = this.resources.length;
                              //console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', res)
                              {
                                let el = { id: res.responsabileCommerciale.id, name: res.responsabileCommerciale.name, email: res.responsabileCommerciale.email };
                                this.resources.push(el);
                                this.resourcesRespComm.push(el);

                              }
                            }
                          }
                          // console.log('PATCH VALUE 3 %o', this.resources[iResponsabileCommerciale])
                          this.orderFormGroup.controls['responsabileCommerciale'].patchValue(this.resources[iResponsabileCommerciale]);

                          //  console.log('orderFormGroup form B %o resources %o', res.coordinatore, this.resources);
                        },
                        err => {
                          this.libService.unlockPage();
                          this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
                        }
                      );
                    }
                    else {
                      this.userDataService.getUser(responseData[this.actualIndexVersion].idCoordinatore).subscribe(u => {
                        this.libService.unlockPage();
                        let iCoordinatore = this.findIndex(u.id, this.resources);
                        if (iCoordinatore == -1) {
                          iCoordinatore = this.resources.length;
                          //console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', u)
                          if (!(u.email == null) && u.email.length > 5) {
                            let el = { id: u.id, name: u.name, email: u.email };
                            this.resources.push(el);
                            this.resourcesRespComm.push(el);
                          }
                        }
                        // console.log('PATCH VALUE 4 %o', this.resources[iCoordinatore])
                        this.orderFormGroup.controls['coordinatore'].patchValue(this.resources[iCoordinatore]);
                      }, err => {
                        this.libService.unlockPage();
                        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
                      });
                    }
                  }
                  else {
                    if (responseData[this.actualIndexVersion].idRespCommerciale > 0) {
                      this.userDataService.getUser(responseData[this.actualIndexVersion].idRespCommerciale).subscribe(u => {
                        if (!(u == null)) {
                          let iResponsabileCommerciale = this.findIndex(u.id, this.resources);
                          if (iResponsabileCommerciale == -1) {
                            iResponsabileCommerciale = this.resources.length;
                            // console.log(' ----------------------------- +++++++++++++++++++++++++++++++++++ --------------------------------- +++++++++++++++++++++++++++++ push resource %o', u)
                            if (!(u.email == null) && u.email.length > 5) {
                              let el = { id: u.id, name: u.name, email: u.email };
                              this.resources.push(el);
                              this.resourcesRespComm.push(el);

                            }
                          }
                          // console.log('PATCH VALUE 4 %o', this.resources[iResponsabileCommerciale])
                          this.orderFormGroup.controls['responsabileCommerciale'].patchValue(this.resources[iResponsabileCommerciale]);
                        }
                        this.libService.unlockPage();
                      }, err => {
                        this.libService.unlockPage();
                        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
                      });
                    }
                    else {
                      this.libService.unlockPage();
                    }
                  }
                },
                  err => {
                    this.libService.unlockPage();
                    this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
                  });
              }
            } else {
              for (const message in responseData.Messages) {
                this.libService.showMessageError(message);
              }
            }
          });
        });


      }
      else {
        this.versions = [1];
        this.actualversion = 1;
        this.isNew = true;
        this.title = this.translate.instant('LABEL.NEW_ORDER');
        this.subTitle = "EDAC - " + this.title;
        this.building = new Building();
        this.buildingFormGroup = this._formBuilder.group({
          id: ['', Validators.required],
        });
        this.order = new Order();
        this.order.rows = [];
        this.openDetails = [];
        this.order.code = 'NC';
        this.order.version = 1;
        this.order.isMultiservice = false;
        this.order.isSedeInterna = false;
        this.order.who = this.isUser().email;
        this.order.realPrice = 0;
        this.order.realDescont = 0;
        this.order.isSalCento = 0;
        this.realDescont = 0;
        this.realPrice = 0;
        this.orderService.getNewCode().subscribe(nc => {
          const yy = new Date().getFullYear().toString().slice(-2);
          // let newCode = nc.id.toString().padStart(6, '0') + 'SSSSS' + yy;
          let newCode = yy + 'SSSSS' + nc.id.toString().padStart(6, '0'); //order.order.mastercode + nc.id.toString().padStart(6, '0');
          this.order.code = newCode;
          this.libService.unlockPage();
        });
      }
    },
      error => {
        this.libService.unlockPage();
        //  console.log('Error in init order dtil %o', error);
        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
      });

    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');

    this.initEstimateDeliveryMethods();
  }

  notIn(id: any, arr: any) {
    for (let a = 0; a < arr.length; a++) {
      //console.log('element %o',arr[a].value.phase);
      if (id == arr[a].value.phase.id) {
        // console.log('NOT IN %o',id);
        return false;
      }
    }
    return true;
  }

  filterBranchesForSelect() {
    let ret: any = [];
    for (let a = 0; a < this.branches.length; a++) {
      if (this.orderFormGroup.controls['isSedeInterna'].value == true) {
        if (this.branches[a].isStaff == true)
          ret.push(this.branches[a]);
      }
      else {
        ret.push(this.branches[a]);
      }
    }
    return ret;
  }

  filterCategories(branch: any) {
    let ret: any = [];
    if (!(branch == null) && !(branch.BranchesCategoriesRel == null)) {
      for (let a = 0; a < this.categories.length; a++) {
        for (let b = 0; b < branch.BranchesCategoriesRel.length; b++) {
          if (branch.BranchesCategoriesRel[b].BranchesCategories.CategoryId == this.categories[a].id)
            ret.push(this.categories[a]);
        }
      }
    }
    return ret;
  }

  filterMacroPhases(category: any) {

    let ret: any = [];
    if (!(category == null) && !(category.CategoriesMacroPhasesRel == null)) {
      for (let a = 0; a < this.macroPhases.length; a++) {
        for (let b = 0; b < category.CategoriesMacroPhasesRel.length; b++) {
          let valueFromMacroPhase = this.macroPhases[a].isMultiService;
          if (!(valueFromMacroPhase == true))
            valueFromMacroPhase = false;
          let valueFromUX = this.orderFormGroup.controls['isMultiservice'].value;
          if (!(valueFromUX == true))
            valueFromUX = false;
          // if (category.CategoriesMacroPhasesRel[b].CategoriesMacroPhases.MacroPhaseId == this.macroPhases[a].id)
          //   console.log('start test id from cat %o id from anag %o valueFromMacroPhase %o valueFromUX %o', category.CategoriesMacroPhasesRel[b].CategoriesMacroPhases.MacroPhaseId, this.macroPhases[a].id, valueFromMacroPhase, valueFromUX);
          if ((category.CategoriesMacroPhasesRel[b].CategoriesMacroPhases.MacroPhaseId == this.macroPhases[a].id) &&
            valueFromUX == valueFromMacroPhase) {
            ret.push(this.macroPhases[a]);
          }
        }
      }
    }
    // console.log(' -- filterMacroPhases for category %o ret %o', category, ret);
    return ret;
  }

  filterPhases(macroPhase: any, i: any) {
    //return phases by macroPhase
    // console.log('filterPhase from macrophase %o', macroPhase);
    this.orderFormGroup.controls['macrophases'].value[i].controls['phases'] = new UntypedFormArray([]);
    if (!(macroPhase == null) && !(macroPhase.MacroPhasesPhasesRel == null)) {
      for (let a = 0; a < this.phases.length; a++) {
        for (let b = 0; b < macroPhase.MacroPhasesPhasesRel.length; b++) {
          //console.log('check phase %o and id %o with phase %o', macroPhase.MacroPhasesPhasesRel[b], macroPhase.MacroPhasesPhasesRel[b].MacroPhasesPhases.PhaseId, this.phases[a]);
          if (macroPhase.MacroPhasesPhasesRel[b].MacroPhasesPhases.PhaseId == this.phases[a].id) {
            //console.log('---------------------- find phase %o mphase %o',this.orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].status,this.orderFormGroup.controls['macrophases'].value[i].get('macrophase').value);
            this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value.push(
              new UntypedFormGroup({
                selected: new UntypedFormControl(this.phases[a].isPreselected),
                branch: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('branch').value),
                category: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('category').value),
                macrophase: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('macrophase').value),
                phase: new UntypedFormControl(this.phases[a]),
                percAmmaloramento: new UntypedFormControl(100),
                qProdOrariaRif: new UntypedFormControl(this.phases[a].qProdOraria),
                cMaterRif: new UntypedFormControl(this.phases[a].cMater),
                qtToProduct: new UntypedFormControl(0),
                qtOreManodopera: new UntypedFormControl(0),
                ricMateriali: new UntypedFormControl(this.ricMateriali),
                ricOreManodopera: new UntypedFormControl(this.ricManodopera),
                costoManodoperaRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('category').value.costoManodoperaRif),
                percIncrementoFestivoRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('category').value.percIncrementoFestivoRif),
                percIncrementoTrasfertaRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('category').value.percIncrementoTrasfertaRif),
                percIncrementoNotturnoRif: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('category').value.percIncrementoNotturnoRif),
                isFreeDescription: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('category').value.isFreeDescription),
                //isFreeDatas: new FormControl(this.orderFormGroup.controls['macrophases'].value[i].get('macrophase').value.isFreeDatas),
                isFreeDatas: new UntypedFormControl(false),
                isGaranzia: new UntypedFormControl(false),
                freeDescription: new UntypedFormControl(''),
                countMF: new UntypedFormControl(this.orderFormGroup.controls['macrophases'].value[i].get('countMF').value),
                price: new UntypedFormControl(0),
                key: new UntypedFormControl(0),
                priceNet: new UntypedFormControl(0),
                totalCost: new UntypedFormControl(0),
                otherCost: new UntypedFormControl(0),
                isDeliberato: new UntypedFormControl(null)
              })
            );
          }
        }
      }
    }
  }

  changeTotal(e: any, w: any) {
    //console.log('change %o in %o', w, e);
    if (w == 'price') {
      //calcolo margin
      this.realDescont = Math.round(((this.getRicavoTotale() - parseFloat(e)) / this.getRicavoTotale()) * 10000) / 100;
    }
    if (w == 'perc') {
      //calcolo price
      this.realPrice = Math.round((this.getRicavoTotale() * (1 - (parseFloat(e) / 100))) * 100) / 100;
    }
  }

  getToolTipDeliberable() {
    if (this.isDeliberable() == true)
      return this.translate.instant('LABEL.DELIBERABLE')
    else
      return this.translate.instant('LABEL.NOT_DELIBERABLE')
  }

  getRicavoReale() {
    //somma dei realPrice
    let ret = 0.0;
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      let retMF = 0.0;
      for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {
        let check = false;
        if (this.isVisibleDelibera() == true) {
          /*
          check = (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value &&
            this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isDeliberato'].value == true);
          */
          check = this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value;
        }
        else {
          check = this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value;
        }
        if (check) {
          let price = this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['price'].value;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value > 0)
            price = this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value;
          if (price > 0) {
            //  if (this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService == true && this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value > 0)
            //    ret = ret + price * this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value;
            //  else
            ret = ret + price;
          }
        }
      }
    }
    return ret;

  }

  getScontoReale() {
    let ricavoreale = this.getRicavoReale();
    let ricavototale = this.getRicavoTotale();
    //console.log('calcolo sconto %o %o %o',ricavoreale,ricavototale,ricavoreale-ricavototale)
    if (ricavototale > 0) {
      // console.log('A');
      if ((ricavoreale - ricavototale) < 0) {
        //  console.log('B');
        let s = Math.round(((ricavototale - ricavoreale) / ricavototale) * 10000) / 100;
        return s;
      }
      if ((ricavoreale - ricavototale) > 0) {
        //  console.log('C');
        let s = Math.round(((ricavoreale - ricavototale) / ricavototale) * 10000) / 100;
        return s * -1;
      }
    }
    return 0;
  }

  getColorMargin() {
    // let tc = parseFloat(this.realDescont.toString());
    let tc = this.getMargineReale();
    //console.log('check %o %o %o', tc,this.realDescont.toString(),this.realDescont );
    if (this.orderFormGroup.controls['isMultiservice'].value == true) {
      if (tc < (this.secondThesholdMultiServizi - 0.01))
        return 'red';
      if (tc < (this.firstThesholdMultiServizi - 0.01))
        return 'orange';
      return 'green';
    }
    else {
      if (tc < (this.secondTheshold - 0.01))
        return 'red';
      if (tc < (this.firstTheshold - 0.01))
        return 'orange';
      return 'green';
    }
  }

  isWarningApprove() {
    if (this.isReview == true && !(this.getRicavoReale() > 20000))
      return true;
    else
      return false;
  }

  isWarningApproveOver() {
    if (this.isReview == true && (this.getRicavoReale() > 20000))
      return true;
    else
      return false;
  }

  downloadPrev(order: any) {
    console.log("CALL DOC COMPOSITION CON PREVENTIVO");
    let payLoad = {
      numeroPratica: order.code,
      versionePratica: order.version.toString()
    }
    this.libService.lockPage('');
    this.documentService.callDocComp(environment.callDocCompPrev, payLoad).subscribe(res => {
      this.libService.unlockPage();
      console.log('[DocumentTypeTableComponent.downloadTemplate.] call callDocComp payload %o response %o', payLoad, res['document']);
      let str = res['document'];
      var byteCharacters = atob(str);
      var bytes = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        bytes[i] = byteCharacters.charCodeAt(i);
      }
      var byteCharactersN = new Uint8Array(bytes);

      const blob = new Blob([byteCharactersN], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = "MCOM4.docx";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

    },
      error => {
        this.libService.unlockPage();
      });
  }

  downloadTemplate(documentType: DocumentType) {
    console.log('[DocumentTypeTableComponent.downloadTemplate] - documentType %o', documentType.urlDocComposition);
    if (!(documentType.urlDocComposition == null) && (documentType.urlDocComposition.length > 0)) {
      console.log('[DocumentTypeTableComponent.downloadTemplate] - callDocumentComposition %o', documentType.urlDocComposition);
      let payLoad = {
        numeroPratica: this.order.code,
        versionePratica: this.order.version.toString()
      }
      this.documentService.callDocComp(documentType.urlDocComposition, payLoad).subscribe(res => {
        console.log('[DocumentTypeTableComponent.downloadTemplate.] call callDocComp payload %o response %o', payLoad, res['document']);
        let str = res['document'];
        var byteCharacters = atob(str);
        var bytes = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          bytes[i] = byteCharacters.charCodeAt(i);
        }
        var byteCharactersN = new Uint8Array(bytes);

        const blob = new Blob([byteCharactersN], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = documentType.code + ".docx";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

      });
    }
    else {
      if (documentType.templateDocument) {
        this.documentService.getSignedUrl(documentType.templateDocument.awsPath!).subscribe({
          next: (signedUrl: string) => {
            window.open(signedUrl)
          },
          error: (err: any) => {
            console.log(err);
            this.toastr.error(err.message)
          }
        })
      }
    }
  }

  changeAmmQt(i: any, j: any, e: any) {
    let p = 0;

    let qProdOrariaRif = this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qProdOrariaRif'].value;
    let qtToProduct = this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qtToProduct'].value;
    let percAmmaloramento = this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['percAmmaloramento'].value;

    if (!(qProdOrariaRif > 0) && !(qProdOrariaRif < 0))
      qProdOrariaRif = 1;
    if (!(qtToProduct > 0) && !(qtToProduct < 0))
      qtToProduct = 1;
    if (!(percAmmaloramento > 0) && !(percAmmaloramento < 0))
      percAmmaloramento = 1;

    console.log('qProdOrariaRif  %o qtToProduct %o  percAmmaloramento %o ', qProdOrariaRif, qtToProduct, percAmmaloramento);
    if (parseFloat(qProdOrariaRif) > 0) {
      /*
      p = Math.round(((
        parseFloat(qtToProduct) /
        parseFloat(qProdOrariaRif))
        *
        (parseFloat(percAmmaloramento) / 100)) * 100) / 100;
        */

      if (this.orderFormGroup.controls['macrophases'].value[i].controls['macrophase'].value.isMultiService == true)
        p = Math.round(((qtToProduct / qProdOrariaRif) * DIFFICULT) * 100) / 100;
      else
        p = Math.round(((qtToProduct / qProdOrariaRif) * DIFFICULT * PERSONS) * 100) / 100;
    }
    //p con percAmmaloramento
    if (percAmmaloramento > 0) {
      p = (p * percAmmaloramento) / 100;
    }

    this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['qtOreManodopera'].setValue(p);
    let nv = this.getValuePhase(this.orderFormGroup.controls['macrophases'].value[i], this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j]);
    let cv = this.getCostPhase(this.orderFormGroup.controls['macrophases'].value[i], this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j]);
    this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['totalCost'].setValue(cv);
    if (!(this.isReview == true) || !(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['price'].value > 0)) {
      if (this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isGaranzia'].value == true)
        this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['price'].setValue(0);
      else
        this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['price'].setValue(nv);
    }
    if (!(this.isReview == true) || !(this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['priceNet'].value > 0)) {
      if (this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['isGaranzia'].value == true)
        this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['priceNet'].setValue(0);
      else
        this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[j].controls['priceNet'].setValue(nv);
    }
    this.setFinanziamento()
  }

  changeHowMany(i: any, e: any) {
    console.log('change HowMany for getTotal %o', this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value.length);
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value.length; a++) {
      let nv = this.getValuePhase(this.orderFormGroup.controls['macrophases'].value[i], this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[a]);
      let cv = this.getCostPhase(this.orderFormGroup.controls['macrophases'].value[i], this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[a]);
      this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[a].controls['totalCost'].setValue(cv);
      if (!(this.isReview == true))
        this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[a].controls['price'].setValue(nv);
      if (!(this.isReview == true))
        this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[a].controls['priceNet'].setValue(nv);
    }
  }

  getValuePhase(macrophase: any, phase: any) {
    if (phase.controls['isGaranzia'].value == true)
      return 0;

    let qtToProduct = 0;
    if (phase.controls['qtToProduct'].value > 0 || phase.controls['qtToProduct'].value < 0)
      qtToProduct = parseFloat(phase.controls['qtToProduct'].value);

    let qProdOraria = 0;
    if (phase.controls['qProdOrariaRif'].value > 0 || phase.controls['qProdOrariaRif'].value < 0)
      qProdOraria = parseFloat(phase.controls['qProdOrariaRif'].value);

    let costoManodoperaRif = 0;

    if (!(phase.controls['branch'].value == null)) {
      // console.log(' +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ macrophase.controls %o',  macrophase.controls['macrophase'].value['isMultiService']);

      costoManodoperaRif = this.getCostoOrarioByBranc(phase.controls['branch'].value.id, macrophase.controls['macrophase'].value['isMultiService']);
    }
    // console.log('[getValuePhase] --- costoManodopera rif 1 %o', costoManodoperaRif);
    /*
    if (phase.controls['costoManodoperaRif'].value > 0 || phase.controls['costoManodoperaRif'].value < 0)
      costoManodoperaRif = parseFloat(phase.controls['costoManodoperaRif'].value);
    console.log('[getValuePhase] --- costoManodopera rif 2 %o', costoManodoperaRif);
    */
    let costoMaterialiRif = 0;
    if (phase.controls['cMaterRif'].value > 0 || phase.controls['cMaterRif'].value < 0)
      costoMaterialiRif = parseFloat(phase.controls['cMaterRif'].value);

    let percAmmaloramento = 0;
    if (phase.controls['percAmmaloramento'].value > 0 || phase.controls['percAmmaloramento'].value < 0)
      percAmmaloramento = parseFloat(phase.controls['percAmmaloramento'].value);

    if (!(this.ricManodopera > 0) && !(this.ricManodopera < 0))
      this.ricManodopera = 1;

    if (!(this.ricMateriali > 0) && !(this.ricMateriali < 0))
      this.ricMateriali = 1;

    //let valuePhase = Math.round((((qtToProduct / qProdOraria))  * ((costoManodoperaRif * this.ricManodopera) + (costoMaterialiRif * this.ricMateriali)) * (percAmmaloramento / 100)) * 100) / 10

    let qt = (qtToProduct / qProdOraria) * DIFFICULT;
    if (!(macrophase.controls['macrophase'].value['isMultiService'] == true))
      qt = qt * PERSONS;
    let incrementoAmmaloramento = (percAmmaloramento / 100);
    let incrementoOre = this.ricManodopera;
    let incrementoCosti = this.ricMateriali;
    if (macrophase.controls['macrophase'].value['isMultiService'] == true) {
      incrementoOre = this.ricOreManodoperaMultiServizi;
      incrementoCosti = this.ricMaterialiMultiServizi;
      console.log('incremento MultiServizi %o %o', incrementoOre, incrementoCosti);
    }
    let stepOre = qt * incrementoAmmaloramento * costoManodoperaRif * incrementoOre;

    let incrementoFlag = 1;

    if (macrophase.controls['isTrasferta'].value == true &&
      parseFloat(phase.controls['percIncrementoTrasfertaRif'].value) > 0.0) {
      //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoTrasfertaRif'].value));
      incrementoFlag = incrementoFlag + (parseFloat(phase.controls['percIncrementoTrasfertaRif'].value) - 1)
    }
    if (macrophase.controls['isNotturno'].value == true &&
      parseFloat(phase.controls['percIncrementoNotturnoRif'].value) > 0.0) {
      //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoNotturnoRif'].value));
      incrementoFlag = incrementoFlag + (parseFloat(phase.controls['percIncrementoNotturnoRif'].value) - 1)
    }
    if (macrophase.controls['isFestivo'].value == true &&
      parseFloat(phase.controls['percIncrementoFestivoRif'].value) > 0.0) {
      //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoFestivoRif'].value));
      incrementoFlag = incrementoFlag + (parseFloat(phase.controls['percIncrementoFestivoRif'].value) - 1)
    }

    stepOre = stepOre * incrementoFlag;


    let stepMateriali = qtToProduct * costoMaterialiRif * incrementoCosti;
    let afterRound = (stepOre + stepMateriali);
    let valuePhase = Math.round(afterRound * 100) / 100;

    if (parseFloat(phase.controls['otherCost'].value) > 0.0) {
      valuePhase = valuePhase + parseFloat(phase.controls['otherCost'].value);
    }

    if (qProdOraria > 0) {
      if ((macrophase.controls['macrophase'].value['isMultiService'] == true) && (macrophase.controls['howMany'].value > 1))
        valuePhase = valuePhase + valuePhase * (macrophase.controls['howMany'].value - 1);

      return valuePhase;
    }
    else
      return (0);

  }

  getCostPhase(macrophase: any, phase: any) {
    let qtToProduct = 0;
    if (phase.controls['qtToProduct'].value > 0 || phase.controls['qtToProduct'].value < 0)
      qtToProduct = parseFloat(phase.controls['qtToProduct'].value);

    let qProdOraria = 0;
    if (phase.controls['qProdOrariaRif'].value > 0 || phase.controls['qProdOrariaRif'].value < 0)
      qProdOraria = parseFloat(phase.controls['qProdOrariaRif'].value);

    let costoManodoperaRif = 0;

    if (!(phase.controls['branch'].value == null)) {
      // console.log(' +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ macrophase.controls %o',  macrophase.controls['macrophase'].value['isMultiService']);

      costoManodoperaRif = this.getCostoOrarioByBranc(phase.controls['branch'].value.id, macrophase.controls['macrophase'].value['isMultiService']);
    }
    console.log('[getValuePhase] --- costoManodopera rif 1 %o', costoManodoperaRif);

    let costoMaterialiRif = 0;
    if (phase.controls['cMaterRif'].value > 0 || phase.controls['cMaterRif'].value < 0)
      costoMaterialiRif = parseFloat(phase.controls['cMaterRif'].value);

    let percAmmaloramento = 0;
    if (phase.controls['percAmmaloramento'].value > 0 || phase.controls['percAmmaloramento'].value < 0)
      percAmmaloramento = parseFloat(phase.controls['percAmmaloramento'].value);

    let qt = (qtToProduct / qProdOraria) * DIFFICULT;
    if (!(macrophase.controls['macrophase'].value['isMultiService'] == true))
      qt = qt * PERSONS;
    let incrementoAmmaloramento = (percAmmaloramento / 100);

    let stepOre = qt * incrementoAmmaloramento * costoManodoperaRif;

    let incrementoFlag = 1;

    if (macrophase.controls['isTrasferta'].value == true &&
      parseFloat(phase.controls['percIncrementoTrasfertaRif'].value) > 0.0) {
      //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoTrasfertaRif'].value));
      incrementoFlag = incrementoFlag + (parseFloat(phase.controls['percIncrementoTrasfertaRif'].value) - 1)
    }
    if (macrophase.controls['isNotturno'].value == true &&
      parseFloat(phase.controls['percIncrementoNotturnoRif'].value) > 0.0) {
      //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoNotturnoRif'].value));
      incrementoFlag = incrementoFlag + (parseFloat(phase.controls['percIncrementoNotturnoRif'].value) - 1)
    }
    if (macrophase.controls['isFestivo'].value == true &&
      parseFloat(phase.controls['percIncrementoFestivoRif'].value) > 0.0) {
      //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoFestivoRif'].value));
      incrementoFlag = incrementoFlag + (parseFloat(phase.controls['percIncrementoFestivoRif'].value) - 1)
    }

    stepOre = stepOre * incrementoFlag;

    let stepMateriali = qtToProduct * costoMaterialiRif;
    let afterRound = (stepOre + stepMateriali);
    let valuePhase = Math.round(afterRound * 100) / 100;

    if (parseFloat(phase.controls['otherCost'].value) > 0.0) {
      valuePhase = valuePhase + parseFloat(phase.controls['otherCost'].value);
    }
    if (qProdOraria > 0) {
      console.log('flag %o howMany %o', macrophase.controls['macrophase'].value['isMultiService'], macrophase.controls['howMany'].value)
      if ((macrophase.controls['macrophase'].value['isMultiService'] == true) && (macrophase.controls['howMany'].value > 1))
        valuePhase = valuePhase + valuePhase * (macrophase.controls['howMany'].value - 1);

      return valuePhase;
    }
    else
      return (0);

  }

  getRicavoTotale() {
    let ret = 0.0;
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      let retMF = 0.0;
      for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {
        let check = false;
        if (this.isVisibleDelibera() == true) {
          check = (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value);
        }
        else {
          check = this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value;
        }
        if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isGaranzia'].value == true)
          check = false;
        if (check) {


          let qtToProduct = 0;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtToProduct'].value > 0 || this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtToProduct'].value < 0)
            qtToProduct = parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtToProduct'].value);

          let qProdOraria = 0;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qProdOrariaRif'].value > 0 || this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qProdOrariaRif'].value < 0)
            qProdOraria = parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qProdOrariaRif'].value);
          //console.log('-------------------BRANCH %o',this.orderFormGroup.controls['macrophases'].value[a].controls['branch'].value);
          let costoManodoperaRif = 0;
          if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['branch'].value == null)) {
            // console.log('get cost from %o',this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService);
            costoManodoperaRif = this.getCostoOrarioByBranc(this.orderFormGroup.controls['macrophases'].value[a].controls['branch'].value.id, this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService);
          }

          let costoMaterialiRif = 0;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value > 0 || this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value < 0)
            costoMaterialiRif = parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value);

          let percAmmaloramento = 0;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percAmmaloramento'].value > 0 || this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percAmmaloramento'].value < 0)
            percAmmaloramento = parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percAmmaloramento'].value);

          let oneriSicurezza = 0;
          if (this.orderFormGroup.controls['oneriSicurezza'].value > 0 || this.orderFormGroup.controls['oneriSicurezza'].value < 0)
            oneriSicurezza = parseFloat(this.orderFormGroup.controls['oneriSicurezza'].value);

          if (qProdOraria > 0) {

            let incrementoOneri = 1//; + (oneriSicurezza / 100);
            let qt = (qtToProduct / qProdOraria) * DIFFICULT;
            if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService == true))
              qt = qt * PERSONS;

            let incrementoAmmaloramento = (percAmmaloramento / 100);
            let incrementoOre = this.ricManodopera;
            let incrementoCosti = this.ricMateriali;
            if (this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService) {
              incrementoOre = this.ricOreManodoperaMultiServizi;
              incrementoCosti = this.ricMaterialiMultiServizi;
            }
            let stepOre = qt * incrementoAmmaloramento * costoManodoperaRif * incrementoOre;

            let incrementoFlag = 1;

            if (this.orderFormGroup.controls['macrophases'].value[a].controls['isTrasferta'].value == true &&
              parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoTrasfertaRif'].value) > 0.0) {
              //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoTrasfertaRif'].value));
              incrementoFlag = incrementoFlag + (parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoTrasfertaRif'].value) - 1)
            }
            if (this.orderFormGroup.controls['macrophases'].value[a].controls['isNotturno'].value == true &&
              parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoNotturnoRif'].value) > 0.0) {
              //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoNotturnoRif'].value));
              incrementoFlag = incrementoFlag + (parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoNotturnoRif'].value) - 1)
            }
            if (this.orderFormGroup.controls['macrophases'].value[a].controls['isFestivo'].value == true &&
              parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoFestivoRif'].value) > 0.0) {
              //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoFestivoRif'].value));
              incrementoFlag = incrementoFlag + (parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoFestivoRif'].value) - 1)
            }

            stepOre = stepOre * incrementoFlag;

            let stepMateriali = qtToProduct * costoMaterialiRif * incrementoCosti;

            let afterRound = (stepOre + stepMateriali) * incrementoOneri;
            /*
            console.log('phase parameter this.ricOreManodoperaMultiServizi %o', this.ricOreManodoperaMultiServizi);
            console.log('phase parameter this.ricMaterialiMultiServizi %o', this.ricMaterialiMultiServizi);
            console.log('phase parameter qtToProduct %o', qtToProduct);
            console.log('phase parameter qProdOraria %o', qProdOraria);
            console.log('phase parameter costoManodoperaRif %o', costoManodoperaRif);
            console.log('phase parameter percAmmaloramento %o', percAmmaloramento);
            console.log('phase parameter oneriSicurezza %o', oneriSicurezza);
            console.log('phase parameter stepMateriali %o', stepMateriali);
            console.log('phase parameter stepOre %o', stepOre);
            */
            let valuePhase = Math.round(afterRound * 100) / 100;
            //console.log(' -----  valuePhase %o', valuePhase);
            if (parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['otherCost'].value) > 0) {
              valuePhase = valuePhase + parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['otherCost'].value);
            }

            if ((this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService == true) && (this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value > 1))
              valuePhase = valuePhase + valuePhase * (this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value - 1);

            retMF = retMF + valuePhase;
            //console.log(' ----- this.orderFormGroup.controls[macrophases].value[a].controls[howMany] %o retMF %o', this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value, retMF);
          }
        }
      }

      ret = ret + retMF;

    }
    // console.log('ret %o', ret);
    // this.realPrice= ret;
    return ret;
  }

  getCostoTotale() {
    let ret = 0;
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {
        let check = false;
        if (this.isVisibleDelibera() == true) {
          /*
          check = (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value &&
            this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isDeliberato'].value == true
            );
          */
          check = this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value;
        }
        else {
          check = this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value;
        }
        if (check) {

          // if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value == true) {

          let qtToProduct = 0;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtToProduct'].value > 0 || this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtToProduct'].value < 0)
            qtToProduct = parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtToProduct'].value);

          let qProdOraria = 0;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qProdOrariaRif'].value > 0 || this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qProdOrariaRif'].value < 0)
            qProdOraria = parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qProdOrariaRif'].value);

          let costoManodoperaRif = 0;
          if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['branch'] == null) && this.orderFormGroup.controls['macrophases'].value[a].controls['branch'].value.id > 0)
            costoManodoperaRif = this.getCostoOrarioByBranc(this.orderFormGroup.controls['macrophases'].value[a].controls['branch'].value.id, this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService);

          //console.log('costo manodopera rif per costo %o from %o',costoManodoperaRif,this.orderFormGroup.controls['macrophases'].value[a].controls['branch'].value.id);
          let costoMaterialiRif = 0;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value > 0 || this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value < 0)
            costoMaterialiRif = parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value);


          let percAmmaloramento = 0;
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percAmmaloramento'].value > 0 || this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percAmmaloramento'].value < 0)
            percAmmaloramento = parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percAmmaloramento'].value);

          if (qProdOraria > 0) {
            //console.log('phase parameter %o %o %o %o %o %o', qtToProduct, qProdOraria, costoManodoperaRif, percAmmaloramento);
            let qt = (qtToProduct / qProdOraria) * DIFFICULT;
            if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService == true))
              qt = qt * PERSONS;
            let incrementoAmmaloramento = (percAmmaloramento / 100);
            let stepOre = qt * incrementoAmmaloramento * costoManodoperaRif;

            let incrementoFlag = 1;

            if (this.orderFormGroup.controls['macrophases'].value[a].controls['isTrasferta'].value == true &&
              parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoTrasfertaRif'].value) > 0.0) {
              //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoTrasfertaRif'].value));
              incrementoFlag = incrementoFlag + (parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoTrasfertaRif'].value) - 1)
            }
            if (this.orderFormGroup.controls['macrophases'].value[a].controls['isNotturno'].value == true &&
              parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoNotturnoRif'].value) > 0.0) {
              //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoNotturnoRif'].value));
              incrementoFlag = incrementoFlag + (parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoNotturnoRif'].value) - 1)
            }
            if (this.orderFormGroup.controls['macrophases'].value[a].controls['isFestivo'].value == true &&
              parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoFestivoRif'].value) > 0.0) {
              //stepOre = stepOre * (parseFloat(phase.controls['percIncrementoFestivoRif'].value));
              incrementoFlag = incrementoFlag + (parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percIncrementoFestivoRif'].value) - 1)
            }

            stepOre = stepOre * incrementoFlag;


            let stepMateriali = qtToProduct * costoMaterialiRif;
            let afterRound = (stepOre + stepMateriali);


            //console.log('********************* costo riga ------ ');
            //console.log('********************* costo riga ------ persone %o', stepOre);
            //console.log('********************* costo riga ------ materiali %o', stepMateriali);

            //ret = ret + Math.round(afterRound * 100) / 100;
            let cost = Math.round(afterRound * 100) / 100;
            //console.log('******************************************* costo riga totale %o', afterRound);
            if (parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['otherCost'].value) > 0.0) {
              cost = cost + parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['otherCost'].value);
            }
            //console.log('******************************************* costo riga totale ripetizioni %o othercost %o', parseFloat(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['otherCost'].value), this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value);

            if ((this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value.isMultiService == true) && (this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value > 1))
              cost = cost * (this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value);

            //console.log('------------------------- total costo %o',cost);
            ret = ret + cost;

          }
        }
      }
    }
    return ret;
  }

  getMargineTotale() {
    let rt = this.getRicavoTotale();
    let ct = this.getCostoTotale();
    if (!(rt > 0))
      return 0;
    if (!(ct > 0))
      return 0;
    let mt = ((rt - ct) / rt) * 100
    return mt;
  }

  getMargineReale() {
    let ct = this.getCostoTotale();
    let rr = this.getRicavoReale();
    if (!(rr > 0))
      return 0;
    if (!(ct > 0))
      return 0;

    let mt = ((rr - ct) / rr) * 100
    return mt;

  }

  getMaterialePreventivato() {
    let ret = 0;
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {
        if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isDeliberato'].value == true) {
          ret += +this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value
        }
      }
    }
    return ret.toFixed(2);
  }

  getOreDeliberate() {
    let ret = 0;
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {
        if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isDeliberato'].value == true) {
          ret += +this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtOreManodopera'].value
        }
      }
    }
    return ret;
  }

  getValoreProdotto() {
    return this.order.valoreSalTotal.toFixed(2);
  }

  getOrePianificate() {
    return this.order.qtOreManodoperaTotal;
  }

  getOreConsuntivate() {
    return this.order.totaleOreConsuntivate;
  }

  getMLCCommessa(){
    return this.order.mlcCommessa;
  }

  getPreventivoTragitti(orderId: any) {
    console.log("****** getPreventivoTragitti  *******");
    //this.libService.lockPage('');
    //let currentUser = this.userSessionService.getState('user');
    this.valoreTragitti = 0
    /*
    this.financeService.getPreventivoTragitti(currentUser.email, orderId).subscribe({
      next: (res: any) => {
        console.log(res);
        if (res.esito == 'OK') {
          this.valoreTragitti = res.item.Table[0].Valore;    

          if(this.valoreTragitti == null) {this.valoreTragitti = 0}
        }
        this.libService.unlockPage();
      },
      error: (e: any) => {
        console.log(e);
        this.libService.unlockPage();
      }
    });
    */
  }
  preventivoTragitti() {
    return this.valoreTragitti
  }

  getFatturato() {
    return this.order.fatturato
  }

  getIncassato() {
    return this.order.incassato
  }

  getCertificato() {
    return this.order.certificato
  }

  getMaterialeConsuntivato() {
    return this.order.finalMaterial
  }

  getPercentualeAvanzamentoSAL() {
    return this.order.salProgressPercentage + '%'
  }

  getPercentualeIncidenzaMateriali() {
    return this.order.valoreSalTotal / this.order.finalMaterial
  }

  getCostoVenduto() {
    let ret = 0;
    let oneri = this.getOneriReale();
    let ricavo = this.getRicavoReale();
    let cessione = this.getCostoCessione();
    let tragitti = this.valoreTragitti;

    if (oneri > 0)
      ret = ret + oneri;
    if (ricavo > 0)
      ret = ret + ricavo;
    if (cessione > 0)
      ret = ret + cessione;
    if (tragitti > 0)
      ret = ret + tragitti;

    if (this.theFundingHasBeenActivated()) {
      ret = ret + this.onereApertura
      ret = ret + this.orderFormGroup.controls['maggiorazioneFinanziamento'].value
    }
    return ret;
  }

  getOneriReale() {
    let ricavo = this.getRicavoReale();
    let cessione = this.getCostoCessione();

    let rr = 0;
    if (ricavo > 0)
      rr = rr + ricavo;
    if (cessione > 0)
      rr = rr + cessione;
    let mt = Math.round(rr * this.percOneriSicurezza) / 100;
    return mt;
  }

  getOneriFinanziamento() {
    return this.onereApertura
  }

  getOneriMacroFase(ricavo: any, macrofase: any) {
    let cessione = this.getCostoCessioneMacrofase(macrofase);

    let rr = 0;
    if (ricavo > 0)
      rr = rr + ricavo;
    if (cessione > 0)
      rr = rr + cessione;
    let mt = (rr * this.percOneriSicurezza) / 100;
    return mt;
  }

  search(e: any, where: any) {
    console.log('search %o in %o', e, where);

  }

  deleteBuilding() {
    this.buildingFormGroup = new UntypedFormGroup({
      building: new UntypedFormControl({}, [BuildingValidator()])
    });
  }

  deleteSubject() {
    this.subjectFormGroup = new UntypedFormGroup({
      subject: new UntypedFormControl()
    });
  }

  deleteCondominium() {
    this.condominiumFormGroup = new UntypedFormGroup({
      condominium: new UntypedFormControl()
    });
  }

  deleteAdmin() {
    this.adminFormGroup = new UntypedFormGroup({
      admin: new UntypedFormControl()
    });
  }

  goBack() {
    this.router.navigate(['orders']);
  }

  getMaxVersion() {
    let m = 0;
    /*
    for (let a = 0; a < this.orderVersions.length; a++) {
      if (this.orderVersions[a].version > m)
        m = this.orderVersions[a].version;
    }
    */
    for (let a = 0; a < this.versions.length; a++) {
      if (this.versions[a].version > m)
        m = this.versions[a].version;
    }
    return m;
  }

  onChangeDeliberato($event: any, i: any, j: any) {

  }

  getSelected(phases: any) {
    let c = 0;
    for (let b = 0; b < phases.length; b++) {
      if (phases[b].controls['selected'].value == true)
        c++;
    }
    return c;
  }

  setupTheFdingSection() {

  }

  getTheFdingSection() {

  }

  changeRataFinanziamento(event: any) {
    let funding = this.fundings.find((f: any) => f.id == event.value)
    console.log(funding)
    this.orderFormGroup.controls['funding'].setValue(funding)
    this.orderFormGroup.controls['fundingId'].setValue(event.value)
    this.setFinanziamento()
  }

  setValoreMassimoFinanziamento() {
    /*
    let valoreMassimoFinanziamento = this.valoreMassimoFinanziamento()
    if (valoreMassimoFinanziamento == null) {
      this.hintValoreFinanziato = 'Selezionare il numero di rate per determinare il valore massimo finanziabile'
    } else {
      this.hintValoreFinanziato = 'Il valore massimo finanziabile è: ' + valoreMassimoFinanziamento
      this.orderFormGroup.controls["fundedValue"].clearValidators(); // Reset Validazioni
      this.orderFormGroup.controls["fundedValue"].addValidators([Validators.required, Validators.min(1), Validators.max(valoreMassimoFinanziamento)]);
    }
    */
  }

  theFundingHasBeenActivated() {
    return this.orderFormGroup.controls['financingSectionActivation'].value == true
  }

  setFundingForTheOrdersRows() {
    return {
      percStep: null,
      percIncrement: this.maggiorazioneDiCommessa
    }
  }

  getAllTaxes() {
    // Reset Valori
    let tmp_taxes: any = []
    this.taxes = []
    this.ultimoRicavoReale = null

    if (this.theFundingHasBeenActivated()) {
      this.taxes.push(this.oneriFinanziamento)
      this.taxes.push(this.rincaroFinanziamento)
    }

    // Ordinamento di tutti gli Oneri sulla base della "priority"
    this.taxes.sort((a: any, b: any) => a.priority - b.priority)

    this.taxes.forEach((tax: any) => {
      tmp_taxes.push(this.setTaxForServer(tax))
    })
    return tmp_taxes
  }

  setTaxForServer(tax: any) {
    // Inizializza ultimoRicavoReale con getRicavoReale se il valore è nullo
    if (this.ultimoRicavoReale == null) {
      this.ultimoRicavoReale = this.getRicavoReale() + this.getOneriReale() + this.getCostoCessione()
    }

    let tmp_tax = tax

    if (tmp_tax.percentage) {
      tmp_tax.increase = this.maggiorazioneDiCommessa,
        tmp_tax.calculated = this.ultimoRicavoReale * tmp_tax.maggiorazioneDiCommessa
      tmp_tax.maggiorazioneDiCommessa = tmp_tax.maggiorazioneDiCommessa
      this.ultimoRicavoReale += tmp_tax.calculated
    } else {
      tmp_tax.calculated = tax.value.value
    }

    


    return tmp_tax

  }

  taxCalculator(tax: any, maggiorazioneDiCommessa: any) {

    let percentage = tax.TaxCategory.percentage

    let taxConfiguration: any = {
      code: tax.code,
      value: tax,
      increase: null,
      calculated: null,
      percentage: percentage,
      priority: tax.TaxCategory.priority,
      maggiorazioneDiCommessa: maggiorazioneDiCommessa
    }

    return taxConfiguration
  }

  setFinanziamento() {
    if (this.theFundingHasBeenActivated()) {
      let funding = this.getFundingValue()

      let rincaroFinanziamento = funding?.Taxes?.find((tax: any) => tax.TaxCategory.code == "taxCategoriesRincaroFinanziamento")
      let onereApertura = funding?.Taxes?.find((tax: any) => tax.TaxCategory.code == "taxCategoriesOnereAperturaFinanziamento")
      let ricavoReale = this.getRicavoReale() + this.getOneriReale() + this.getCostoCessione()

      // Setto il valore massimo, la validazione del form e l'hint dell'input del form
      this.setValoreMassimoFinanziamento()

      let onere = parseFloat(rincaroFinanziamento?.value)
      let fundedValue = parseFloat(this.orderFormGroup.controls['fundedValue'].value)
      let maggiorazioneFinanziamento = fundedValue * onere

      this.orderFormGroup.controls['maggiorazioneFinanziamento'].setValue(maggiorazioneFinanziamento)

      let anteprimaFinanziamento = fundedValue + maggiorazioneFinanziamento
      this.orderFormGroup.controls['anteprimaFinanziamento'].setValue(anteprimaFinanziamento)

      this.maggiorazioneDiCommessa = maggiorazioneFinanziamento / ricavoReale

      if (funding != undefined) {
        this.onereApertura = onereApertura.value

        this.oneriFinanziamento = this.taxCalculator(onereApertura, null)
        this.rincaroFinanziamento = this.taxCalculator(rincaroFinanziamento, this.maggiorazioneDiCommessa)
      }

    }
  }

  valoreMassimoFinanziamento() {
    let funding = this.getFundingValue()
    let maxFinanceable = funding?.maxFinanceable
    let ricavoReale = this.getRicavoReale() + this.getOneriReale()

    if ([null, undefined].includes(maxFinanceable)) {
      return null
    } else {
      return ricavoReale * maxFinanceable
    }

  }

  getFundingValue() {
    let funding = this.orderFormGroup.controls['funding'].value
    if (funding == null) {
      funding = this.fundings.find((f: any) => f.id == this.orderFormGroup.controls['fundingId'].value)
    }
    return funding
  }

  getDataError() {
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'] == null)
        && this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length > 0 &&
        this.getSelected(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value) > 0) {
        for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {
          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value == true && this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isFreeDatas'].value == true) {
            //checkfreeDatas
            if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['freeDescription'].value == null || !(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['freeDescription'].value.length > 0))
              return true;
            if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qProdOrariaRif'].value > 0))
              return true;
            /*
          if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value > 0))
            return true;
            */
            if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value > 0))
              this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].patchValue(0);
          }
        }
      }
    }

    return false;
  }

  checkSuspended(idStatus: any) {
    if (idStatus == STATUS_NULLED)
      return true;
    for (let a = 0; a < STATUS_SUSPENDED.length; a++) {
      if (idStatus == STATUS_SUSPENDED[a])
        return true;
    }
    return false;
  }

  update(isCreate: any) {
    console.log('isfreeze %o %o', this.isFreeze, this.order);
    let utils = new Utils();
    console.log('check change status for start %o to %o in %o', this.order.idStatus, this.orderFormGroup.controls['idStatus'].value, statusChanges)

    console.log('ISLAST in update %o', this.order.isLast);
    if (!(this.order.isLast == true) && this.isVersionsBlocked == true) {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE44'), this.translate.instant('LABEL.Attenzione'));
      return;
    }

    if (!(this.order.idStatus == STATUS_CREATA || this.order.idStatus == STATUS_REQUEST || this.order.idStatus == STATUS_PRESENTED || this.order.idStatus == STATUS_PRESENTED_OVER) && (isCreate == true)) {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE43'), this.translate.instant('LABEL.Attenzione'));

      return;
    }
    if (this.order.idStatus == STATUS_PRE_DELIBER_OVER && this.orderFormGroup.controls['idStatus'].value == STATUS_DELIBER && !(this.isOverApproval == true)) {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE38'), this.translate.instant('LABEL.Attenzione'));
      return;
    }

    let dataError = this.getDataError();

    if (dataError == true) {
      this.toastr.error(this.translate.instant('TOASTR.MESSAGE46'), this.translate.instant('LABEL.Attenzione'));
      return;
    }

    console.log('1 step passed ', this.order.idStatus, this.orderFormGroup.controls['idStatus'].value, statusChanges)
    if (this.isFreeze == false) {
      if (this.buildingFormGroup.controls['building'] == null || !(this.buildingFormGroup.controls['building'].value?.id)) {
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE16'), this.translate.instant('LABEL.Attenzione'));
        return;
      }
      console.log('2 step passed ', this.order.idStatus, this.orderFormGroup.controls['idStatus'].value, statusChanges)
      if (!this.orderFormGroup.valid) {
        this.toastr.error(this.translate.instant('TOASTR.MESSAGE_1'), this.translate.instant('LABEL.Attenzione'));
        return;
      }
      console.log('3 step passed ', this.order.idStatus, this.orderFormGroup.controls['idStatus'].value, statusChanges)
      if (!(this.order.idStatus == this.orderFormGroup.controls['idStatus'].value)) {
        //CHECK FOR CHANGE STATUS
        console.log('4 step passed ', this.order.idStatus, this.orderFormGroup.controls['idStatus'].value, statusChanges)
        let consent = utils.checkStatus(statusChanges, this.oldIdStatus, this.orderFormGroup.controls['idStatus'].value);
        console.log('5 step passed %o', consent)
        if (consent == false) {
          this.toastr.error(this.translate.instant('TOASTR.MESSAGE40'), this.translate.instant('LABEL.Attenzione'));
          return;
        }
      }

      let order: any = {};
      order.code = this.order.code;
      order.id = this.order.id;
      order.version = this.order.version;

      order.streetName = this.order.streetName;
      order.streetNumber = this.order.streetNumber;
      order.otherInfo = this.order.otherInfo;
      order.city = this.order.city;
      order.province = this.order.province;
      order.state = this.order.state;
      order.zip = this.order.zip;
      order.country = this.order.country;
      order.probChiusuraPratica = this.order.probChiusuraPratica;
      order.isAssemblea = this.order.isAssemblea;
      order.dataPrevistaConfermaOrdine = this.order.dataPrevistaConfermaOrdine;
      order.dataPrevistaAssemblea = this.order.dataPrevistaAssemblea;
      order.isTCAssemblea = this.order.isTCAssemblea;
      order.deadLine = this.order.deadLine;
      order.isDeliberato = this.order.isDeliberato;
      order.dateDelibera = this.order.dateDelibera;
      order.isSalCento = this.order.isSalCento;
      order.startx = this.order.startx;
      order.starty = this.order.starty;
      order.endx = this.order.endx;
      order.endy = this.order.endy;
      order.km = this.order.km;
      order.kmRitorno = this.order.kmRitorno;
      order.costoCessioni = this.getCostoCessione();
      order.costoOneri = this.getOneriReale();
      order.taxTragitti = this.valoreTragitti;

      order.PaymentMethodId = this.orderFormGroup.controls['paymentMethodId'].value
      order.EstimateDeliveryMethodId = this.orderFormGroup.controls['estimateDeliveryMethodId'].value
      order.numberOfCondominiums = this.orderFormGroup.controls['numberOfCondominiums'].value

      order.financingSectionActivation = this.theFundingHasBeenActivated()


      if (this.theFundingHasBeenActivated()) {
        order.FundingId = this.orderFormGroup.controls['fundingId'].value
        order.fundedValue = this.orderFormGroup.controls['fundedValue'].value
        //order.percCredit = this.setFundingForTheOrdersRows()
      } else {
        order.FundingId = null
        order.fundedValue = null
      }

      //Riallinea tutti i dati di finanziamento prima di recuperare le tasse (a causa dell'onere di sicurezza che è asincrono)
      this.setFinanziamento()
          // Recupero il gruppo di oneri da collegare all'ordine
    order.Taxes = this.getAllTaxes()

    if ((this.order.idStatus == STATUS_CREATA || this.order.idStatus == STATUS_REQUEST || this.order.idStatus == STATUS_PRESENTED || this.order.idStatus == STATUS_PRESENTED_OVER))

      order.version = this.getMaxVersion() + 1;

    order.who = this.isUser().email;
    order.ts = Date.now();
    if (!(this.orderFormGroup.controls['sede'] == null))
      order.idSede = this.orderFormGroup.controls['sede'].value.id;
    if (!(this.orderFormGroup.controls['responsabileCommerciale'] == null))
      order.idRespCommerciale = this.orderFormGroup.controls['responsabileCommerciale'].value.id;
    if (!(this.orderFormGroup.controls['coordinatore'] == null))
      order.idCoordinatore = this.orderFormGroup.controls['coordinatore'].value.id;
    if (!(this.orderFormGroup.controls['note'] == null))
      order.note = this.orderFormGroup.controls['note'].value;
    order.isSedeInterna = this.orderFormGroup.controls['isSedeInterna'].value;
    order.percOneriSicurezza = this.orderFormGroup.controls['oneriSicurezza'].value;
    order.isMultiservice = this.orderFormGroup.controls['isMultiservice'].value;
    order.isSedeInterna = this.orderFormGroup.controls['isSedeInterna'].value;
    order.idRifRequest = this.orderFormGroup.controls['richiesta'].value.id;
    console.log('----------------------------- STATUS %o', this.orderFormGroup.controls['idStatus'].value);
    order.idStatus = this.orderFormGroup.controls['idStatus'].value;
    if (isCreate == true)
      order.idStatus = STATUS_CREATA;
    order.realDescont = this.realDescont;
    order.realPrice = this.getRicavoReale();// this.realPrice;

    if (this.subjectFormGroup.controls['subject'].value?.id > 0) {
      order.idSubject = this.subjectFormGroup.controls['subject'].value?.id;
      order.nameSubject = this.subjectFormGroup.controls['subject'].value?.name;
    }

    if (this.buildingFormGroup.controls['building'].value?.id > 0) {
      order.idBuilding = this.buildingFormGroup.controls['building'].value?.id;
      order.nameBuilding = this.buildingFormGroup.controls['building'].value?.name;
    }

    if (this.condominiumFormGroup.controls['condominium'].value?.id > 0) {
      order.idCondominius = this.condominiumFormGroup.controls['condominium'].value?.id;
      order.nameCondominius = this.condominiumFormGroup.controls['condominium'].value?.name;
    }

    if (this.adminFormGroup.controls['admin'].value?.id > 0) {
      order.idAdminCondominius = this.adminFormGroup.controls['admin'].value?.id;
      order.nameAdminCondominius = this.adminFormGroup.controls['admin'].value?.name;
    }

    order.macrophases = [];

    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value.length; a++) {
      if (!(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'] == null)
        && this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length > 0 &&
        this.getSelected(this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value) > 0) {
        let phases: any[] = [];
        let realPrice = 0;
        let costoCessioni = 0;

        for (let b = 0; b < this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value.length; b++) {

          if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['selected'].value == true) {
            if (this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value > 0)
              realPrice = realPrice + this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value;

            //console.log('PUSH phase %o', this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b]);
            phases.push({
              branch: this.orderFormGroup.controls['macrophases'].value[a].controls['branch'].value,//this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['branch'].value,
              category: this.orderFormGroup.controls['macrophases'].value[a].controls['category'].value,//this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['category'].value,
              macrophase: this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value,//this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['macrophase'].value,
              phase: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['phase'].value,
              percAmmaloramento: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['percAmmaloramento'].value,
              qProdOrariaRif: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qProdOrariaRif'].value,
              cMaterRif: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['cMaterRif'].value,
              qtToProduct: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtToProduct'].value,
              qtOreManodopera: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['qtOreManodopera'].value,
              ricMateriali: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['ricMateriali'].value,
              ricOreManodopera: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['ricOreManodopera'].value,
              costoManodoperaRif: this.orderFormGroup.controls['macrophases'].value[a].controls['category'].value.costoManodoperaRif,
              percIncrementoFestivoRif: this.orderFormGroup.controls['macrophases'].value[a].controls['category'].value.percIncrementoFestivoRif,
              percIncrementoTrasfertaRif: this.orderFormGroup.controls['macrophases'].value[a].controls['category'].value.percIncrementoTrasfertaRif,
              percIncrementoNotturnoRif: this.orderFormGroup.controls['macrophases'].value[a].controls['category'].value.percIncrementoNotturnoRif,
              isFreeDescription: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isFreeDescription'].value,
              isFreeDatas: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isFreeDatas'].value,
              isGaranzia: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isGaranzia'].value,
              freeDescription: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['freeDescription'].value,
              price: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['price'].value,
              priceNet: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['priceNet'].value,
              totalCost: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['totalCost'].value,
              otherCost: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['otherCost'].value,
              isDeliberato: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['isDeliberato'].value,
              key: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['key'].value,
              countMF: this.orderFormGroup.controls['macrophases'].value[a].controls['phases'].value[b].controls['countMF'].value,
            }
            );
          }
        }

        order.macrophases.push({
          branch: this.orderFormGroup.controls['macrophases'].value[a].controls['branch'].value,
          category: this.orderFormGroup.controls['macrophases'].value[a].controls['category'].value,
          macrophase: this.orderFormGroup.controls['macrophases'].value[a].controls['macrophase'].value,
          percCredit: this.orderFormGroup.controls['macrophases'].value[a].controls['percCredit'].value,
          isOpereSpeciali: this.orderFormGroup.controls['macrophases'].value[a].controls['isOpereSpeciali'].value,
          isTrasferta: this.orderFormGroup.controls['macrophases'].value[a].controls['isTrasferta'].value,
          isFreeDatas: this.orderFormGroup.controls['macrophases'].value[a].controls['isFreeDatas'].value,
          isNotturno: this.orderFormGroup.controls['macrophases'].value[a].controls['isNotturno'].value,
          isFestivo: this.orderFormGroup.controls['macrophases'].value[a].controls['isFestivo'].value,
          howMany: this.orderFormGroup.controls['macrophases'].value[a].controls['howMany'].value,
          key: this.orderFormGroup.controls['macrophases'].value[a].controls['key'].value,
          realPrice: realPrice,
          costoCessioni: this.getCostoCessioneMacrofase(this.orderFormGroup.controls['macrophases'].value[a]),
          costoOneri: this.getOneriMacroFase(realPrice, this.orderFormGroup.controls['macrophases'].value[a]),
          countMF: this.orderFormGroup.controls['macrophases'].value[a].controls['countMF'].value,
          phases: phases
        });
      }
    }
/*
      console.log('orderform %o', this.orderFormGroup.value);
      console.log('order builded %o', order);
      console.log('branches %o', this.branches);
      console.log('categories %o', this.categories);
      console.log('macrophases %o', this.macroPhases);
      console.log('phases %o', this.phases);
      */
    this.libService.lockPage('');
    if (!(order.realPrice > 0) || !(order.realPrice > 0))
      order.realPrice = Math.round(this.getRicavoTotale() * 100) / 100;

/*Nuova versione pratica*/
    this.orderService.createOrder(order).subscribe((res) => {
      console.log('Updated res %o!', res);
      this.order = order;
      //this.order.version = this.order.version + 1;
      // this.order.version++;
      this.libService.unlockPage();
      this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), 'Informazione');
      //this.router.navigate(['order/' + this.order.code]);
      // AR this.router.navigate(['orders']);
      this.ngOnInit();
    },
      err => {
        this.libService.unlockPage();
        console.log('Error in create order %o', err);
        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('LABEL.Attenzione'));
      }
    );
}
  }

  print() {

  }


  goToWiki() {
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }

  goToWikiByKey(k: any) {
    window.open(this.wikiService.getWikiUrl(k));
  }

  addRow(i: any) {
    this.orderFormGroup.controls['macrophases'].value.push(
      new UntypedFormGroup({
        index: new UntypedFormControl(i),
        branch: new UntypedFormControl({}),
        category: new UntypedFormControl({}),
        macrophase: new UntypedFormControl({}),
        percCredit: new UntypedFormControl({ percStep: 0, percIncrement: 1 }),
        isOpereSpeciali: new UntypedFormControl(false),
        isTrasferta: new UntypedFormControl(false),
        isNotturno: new UntypedFormControl(false),
        isFestivo: new UntypedFormControl(false),
        isFreeDatas: new UntypedFormControl(false),
        howMany: new UntypedFormControl(1),
        realPrice: new UntypedFormControl(0),
        key: new UntypedFormControl(0),
        costoCessioni: new UntypedFormControl(0),
        costoOneri: new UntypedFormControl(0),
        countMF: new UntypedFormControl(0),
        phases: new UntypedFormArray([])
      })
    );
    this.order.rows.push(new OrderRow());
    this.openDetails.push({ open: false });
  }

  removeRow(i: any) {
    if (window.confirm("Sei sicuro di voler cancellare la lavorazione?")) {
      this.order.rows.splice(i, 1);
      this.orderFormGroup.controls['macrophases'].value.splice(i, 1);
      this.openDetails.splice(i, 1);
    }

  }

  expandRow(i: any) {
    this.openDetails[i].open = !this.openDetails[i].open;
  }

  isUser() {
    return this.authService.user();
  }


  isMacroPhaseChangeble(i: any) {
    // console.log('phasese toe check %o', this.orderFormGroup.controls['macrophases'].value[i].controls['phases']);
    for (let a = 0; a < this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value.length; a++) {
      if (this.orderFormGroup.controls['macrophases'].value[i].controls['phases'].value[a].controls['selected'].value == true)
        return false;
    }
    return true;
  }

  onChangeBranchesSelect(event: any) {
    this.initializePaymentMethods()
  }


  onChange(entity: any, i: any, e: any) {

    if (e.isUserInput) {
      // console.log('onChange entity %o event %o', entity, e);
      switch (entity) {
        case "branches":
          this.orderFormGroup.controls['macrophases'].value[i].controls['category'] = new UntypedFormControl({});
          this.orderFormGroup.controls['macrophases'].value[i].controls['macrophase'] = new UntypedFormControl({});
          this.orderFormGroup.controls['macrophases'].value[i].controls['phases'] = new UntypedFormArray([]);
          break;

        case "categories":
          this.orderFormGroup.controls['macrophases'].value[i].controls['macrophase'] = new UntypedFormControl({});
          this.orderFormGroup.controls['macrophases'].value[i].controls['phases'] = new UntypedFormArray([]);
          break;

        case "macrophases":
          //this.orderFormGroup.controls['macrophases'].value[i].controls['phases'] =
          //check selected
          if (this.isMacroPhaseChangeble(i) == true)
            this.filterPhases(e.source.value, i);
          else
            this.toastr.error(this.translate.instant('LABEL.PHASE_SELECTED'), this.translate.instant('LABEL.Attenzione'));
          break;

        default:

          break;
      }
    }
  }

  onChangeFirstLevel(entity: any, e: any) {
    console.log('----- on change first level %o', e.source.value.CompaniesSocietiesRel[0]);
    if (e.isUserInput == true && !(e.source.value.CompaniesSocietiesRel == null) && e.source.value.CompaniesSocietiesRel.length > 0) {
      this.orderFormGroup.controls['oneriSicurezza'].patchValue(e.source.value.CompaniesSocietiesRel[0].percOneriSicurezza);

      this.ricMateriali = e.source.value.CompaniesSocietiesRel[0].ricMateriali;
      this.ricManodopera = e.source.value.CompaniesSocietiesRel[0].ricOreManodopera;
      this.ricMaterialiMultiServizi = e.source.value.CompaniesSocietiesRel[0].ricMaterialiMultiServizi;
      this.ricOreManodoperaMultiServizi = e.source.value.CompaniesSocietiesRel[0].ricOreManodoperaMultiServizi;
      this.firstTheshold = e.source.value.CompaniesSocietiesRel[0].firstTheshold;
      this.secondTheshold = e.source.value.CompaniesSocietiesRel[0].secondTheshold;
      this.firstThesholdMultiServizi = e.source.value.CompaniesSocietiesRel[0].firstThesholdMultiServizi;
      this.secondThesholdMultiServizi = e.source.value.CompaniesSocietiesRel[0].secondThesholdMultiServizi;
      console.log('----- on change first level this.ricMateriali %o', this.ricMateriali);
      console.log('----- on change first level this.ricManodopera %o', this.ricManodopera);
      console.log('----- on change first level this.ricMaterialiMultiServizi %o', this.ricMaterialiMultiServizi);
      console.log('----- on change first level this.ricOreManodoperaMultiServizi %o', this.ricOreManodoperaMultiServizi);
      console.log('----- on change first level this.firstTheshold %o', this.firstTheshold);
      console.log('----- on change first level this.secondTheshold %o', this.secondTheshold);
      console.log('----- on change first level this.firstThesholdMultiServizi %o', this.firstThesholdMultiServizi);
      console.log('----- on change first level this.secondThesholdMultiServizi %o', this.secondThesholdMultiServizi);
      this.costoManodoperaRif = e.source.value.CompaniesSocietiesRel[0].costoManodoperaRif;

      this.branches = this.filterBySede(this.masterBranches, e.source.value);
      this.categories = this.filterBySede(this.masterCategories, e.source.value);
      this.macroPhases = this.filterBySede(this.masterMacroPhases, e.source.value);
      this.phases = this.filterBySede(this.masterPhases, e.source.value);

      this.libService.lockPage('');

      this.companyService.getCompany(e.source.value.id).subscribe(c => {
        console.log('people for company %o', c.CompaniesUsersRel);
        this.resources = c.CompaniesUsersRel;

        this.libService.unlockPage();
      })

    }
  }

  openUploadDialog() {
    //call getDelibera
    //are we try to deliber?
    if (this.order.idStatus == STATUS_PRESENTED) {
      this.deliberService.getDelibera(this.user.email, this.order.code).subscribe(checkDelibera => {
        console.log('check delibera response %o', checkDelibera);
        // if (checkDelibera.esito == 'OK') {
        if (!(checkDelibera.esito == null)) {
          let globalDocumentTypes$ = this.documentTypeService.getAllGeneric()
          let specificDocumentTypes$ = this.orderService.getDocumentsFromStatusId(this.orderFormGroup.controls['idStatus'].value, this.authService.user().email)
          let documentTypes$ = forkJoin([globalDocumentTypes$, specificDocumentTypes$]).pipe(
            map(([global, specific]) => {
              return [...global || []
                , ...specific.length > 0 ? specific[0].StatusChangesDocTypesRel : [] || []]
            }),
            switchMap((documentTypes: DocumentType[]) => {
              let documentTypesIds = documentTypes.map(documentType => documentType.id!)
              let societyId = ""
              if (societyId !== "") {
                return combineLatest([
                  this.documentTemplateService.getManyByDocumentTypeAndSociedyId(documentTypesIds, societyId),
                  of(documentTypes)])
              } else {
                return combineLatest([
                  of([]), of(documentTypes)
                ])
              }

            }),
            map(([documentTemplates, documentTypes]) => {
              documentTypes = this.unique(documentTypes, ['code'])
              return documentTypes.map(documentType => {
                return {
                  ...documentType,
                  documentTemplate: documentTemplates.find(documentTemplate => documentTemplate.typeName === documentType.id)
                }
              })
            })
          )
          console.log('[FileUploadModalComponent] this.company %o', this.company);
          this.dialog.open(FileUploadModalComponent, {
            data: {
              inputMode: 'orders',
              toEntityId: this.order.id,
              toEntityCode: this.order.code,
              orderObject: {
                code: this.order.code,
                idStatus: this.order.idStatus,
                version: this.order.version
              },
              documentTypes: documentTypes$,
              orderValue: this.getRicavoReale()
            }
          }).afterClosed()
            .subscribe(res => {
              console.log('uploadDialogres: ', res);
              //this.subject.documents.push(res)
              if (res.statusChanged) {
                //TODO: refresh page
                this.ngOnInit()
              }
            })
        }
        else {
          window.alert(this.translate.instant('ERROR.FINANCEKO'));
        }
      });
    }
    else {
      let globalDocumentTypes$ = this.documentTypeService.getAllGeneric()
      let specificDocumentTypes$ = this.orderService.getDocumentsFromStatusId(this.orderFormGroup.controls['idStatus'].value, this.authService.user().email)
      let documentTypes$ = forkJoin([globalDocumentTypes$, specificDocumentTypes$]).pipe(
        map(([global, specific]) => {
          return [...global || []
            , ...specific.length > 0 ? specific[0].StatusChangesDocTypesRel : [] || []]
        }),
        switchMap((documentTypes: DocumentType[]) => {
          console.log(' - documentTypesIds %o', documentTypes)
          let documentTypesIds = documentTypes.map(documentType => documentType.id!)
          let societyId = ""
          if (societyId !== "") {
            return combineLatest([
              this.documentTemplateService.getManyByDocumentTypeAndSociedyId(documentTypesIds, societyId),
              of(documentTypes)])
          } else {
            return combineLatest([
              of([]), of(documentTypes)
            ])
          }

        }),
        map(([documentTemplates, documentTypes]) => {
          documentTypes = this.unique(documentTypes, ['code'])
          return documentTypes.map(documentType => {
            return {
              ...documentType,
              documentTemplate: documentTemplates.find(documentTemplate => documentTemplate.typeName === documentType.id)
            }
          })
        })
      )
      console.log('[FileUploadModalComponent] this.company %o', this.company);
      this.dialog.open(FileUploadModalComponent, {
        data: {
          inputMode: 'orders',
          toEntityId: this.order.id,
          toEntityCode: this.order.code,
          orderObject: {
            code: this.order.code,
            idStatus: this.order.idStatus,
            version: this.order.version
          },
          documentTypes: documentTypes$
        }
      }).afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ', res);
          //this.subject.documents.push(res)
          if (!(res == null) && res.statusChanged) {
            //TODO: refresh page
            this.ngOnInit()
          }
        })
    }
  }

  getLabelResp() {
    return this.translate.instant('LABEL.ResponsabileCommerciale')
  }

  getLabelCoord() {
    return this.translate.instant('LABEL.Coordinatore')
  }

  disablepaymentOption(option: any) {
    let disabled = !(this.getCostoVenduto() > option.minimumDeliberateValue && this.getCostoVenduto() <= option.maximumDeliberateValue)
    let currentPaymentMethodId = this.orderFormGroup.controls['paymentMethodId'].value
    if (disabled && currentPaymentMethodId == option.id) this.orderFormGroup.controls['paymentMethodId'].setValue(null)
    return disabled
  }

  openModalConfirmPrev(e: any, order: any) {
    if (this.orderFormGroup.controls['estimateDeliveryMethodId'].value == null || this.orderFormGroup.controls['estimateDeliveryMethodId'].value == undefined) {
      const dialogRef = this.dialog.open(ModalEstimateDeliveryMethods, {
        width: '600px',
        data: {
          OrderId: order.id,
          estimateDeliveryMethodsList: this.estimateDeliveryMethods
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (![null, undefined].includes(result)) {
          //console.log(result)

          this.orderService.addEstimateDeliveryMethod(order.id, result.EstimateDeliveryMethodId).subscribe(res => {
            this.confirmPrev(order)
          });
        }
      });

    } else {
      this.confirmPrev(order)
    }

  }

  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }
}
