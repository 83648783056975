import { Component, Input, OnInit, SimpleChanges  } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericModal } from '../modals/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ComponentService } from '../../services/component.service';
import { PracticeService } from '../../services/practice.service';
import { LibService } from '../../services/libService';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserSessionService } from '../../services/user-session.service';



@Component({
  selector: 'app-change-single-state-button',
  templateUrl: './change-single-state-button.component.html',
  styleUrls: ['./change-single-state-button.component.scss']
})
export class ChangeSingleStateButtonComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isNew: any;
  @Input() components: any;
  @Input() practice: any;
  @Input() costoVenduto: any;
  @Input() order: any;

  attributes: any;

  disabled: any = true;
  actions: any = [];

  componentName: any = 'unlockWithReasons'

  objectStructure: any = {}

  listOfStatesAvailableForMigration: any = []


  constructor(
    private dialog: MatDialog,
    private componentService: ComponentService,
    private router: Router,
    private libService: LibService,
    private practiceService: PracticeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userSessionService: UserSessionService,
  ) { }

  getClass() {
    if (this.objectStructure?.settings?.type) return 'clsButton' + this.objectStructure?.settings?.type
    return 'clsButtonDefault'
  }

  ngOnChange(changes: SimpleChanges): void {
    this.setStatusButton()
  }
  ngOnInit(): void {
    console.log('ngOnInit unlockWithReasons')
    this.setActions()

    this.setStatusButton()
    this.form?.get('documents')?.valueChanges.subscribe(val => {
      this.setStatusButton()
    });
  }

  getTranslations() {
    let translations = this.objectStructure?.translations || []
    return translations
  }

  getCurrentLanguage() {
    return this.userSessionService.getCurrentLanguage()?.toUpperCase() || 'IT';
  }

  getLabel(code: string) {
    let currentTranslation = this.getTranslations().find((translation: any) => translation.code == code)
    let currentLanguage = this.getCurrentLanguage()
    let translation = currentTranslation?.translations.find((translation: any) => translation.languageCode == currentLanguage)
    return translation?.text || ''
  }

  openModal() {
    let dataStructure: any = {
      titleLabel: 'CHANGESTATEBUTTON.' + 'unlockWithReasons' + '_title',
      textLabel: 'CHANGESTATEBUTTON.' + 'unlockWithReasons' + '_text',
    }
    console.log(this.objectStructure)
    dataStructure.initReasons = this.objectStructure?.reasonList.length > 0
    dataStructure.reasonList = this.objectStructure?.reasonList || []
    dataStructure.translations = this.objectStructure?.translations || []

    const dialogRef = this.dialog.open(GenericModal, {
      width: '400px',
      data: dataStructure
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (![null, undefined].includes(result)) {
        
        let attributes = result;
        console.log(attributes)
        
        this.libService.lockPage('');

        this.practiceService.stateMigration(this.practice.code, attributes.nextStateCode, '', attributes.ReasonCode).subscribe(
          ret => {
            this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
            this.libService.unlockPage();
            this.router.navigate(['practices/' + this.practice.code]).then(_res => {
              window.location.reload()
            })
          },
          err => {
            //this.toastr.error(this.translate.instant('LABEL.Error'));
            this.libService.unlockPage();
          }
        );

      }

    });
  }

  getAttributes(componentCode: any) {
    let component = this.components?.settings?.find((component: any) => component.componentCode == componentCode)
    return component?.attributes
  }

  setActions() {
    this.setAttributes()
    if (this.attributes) {
      this.actions = []
      let structure: any = this.componentService.getStructure(this.attributes)
      structure.forEach((s: any) => {
        if (s.type == 'action' && !this.actions.includes(s.type)) this.actions.push(s.code)
      })
    }
  }

  setAttributes() {
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
    if (this.attributes) this.objectStructure = this.componentService.getObjectStructure(this.attributes)
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) {
      this.setAttributes()
      this.setActions()
      this.setStatusButton()
    }
    return status
  }

  setStatusButton() {
    let tmpStatus = false
    this.setAttributes()
    //if (!this.form?.valid) tmpStatus = true
    if (!this.checkNecessaryDocuments()) tmpStatus = true
    this.disabled = tmpStatus
  }

  checkNecessaryDocuments() {
    let requestDocumentManagerAttributes = this.getAttributes('practice_workflow_manager')
    let documentList = requestDocumentManagerAttributes?.documentList;
    let tmpStructure: any = {}
    if (documentList) {
      documentList.forEach((doc: any) => {
        let key = `document${doc.documentTypeCode}`
        if (!tmpStructure[key]) tmpStructure[key] = []
        tmpStructure[key].push(doc)
      })
    }
    return this.componentService.checkNecessaryDocumentsForPractices(this.form?.get('documents')?.value, tmpStructure, this.form, this.order).status
  }

}
