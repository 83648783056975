
<div>
  <div style="margin-left: 2%; ">
    <br><br>
    <form [formGroup]="formType">
      <mat-form-field appearance="fill" class="form-subject">
        <mat-label>Tipo Obiettivo</mat-label>
        <mat-select matNativeControl formControlName="targetType">
          <mat-option *ngFor="let type of types" [value]="type">
            {{type.description}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin-left: 2%;" appearance="fill" class="form-subject">
        <mat-label>Anno</mat-label>
        <mat-select  matNativeControl formControlName="year">
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </form>

    <br>
    <span>
      <p>  Selezione Effettuata: <b>{{ selectedType?.description }}</b>  -  <b>{{ selectedYear }}</b></p>
    </span>
  </div>
  <br>

  <div >
    <form  [formGroup]="formCompanyTargets" (keydown.enter)="$event.preventDefault()" >
      <kendo-grid [pageable]="true"
                  [sortable]="true"
                  [filterable]="true"
                  [data]="companyTargets"
                  [pageSize]="30"
                  [kendoGridBinding]="companyTargets"
                  (edit)="editHandler($event)"
                  (cancel)="cancelHandler($event)"
                  (add)="addHandler($event)"
                  (cellClick)="editClick($event)"
                  [resizable]="true"
                  *ngIf="visible"
                  >
                  <!-- (save)="saveHandler($event)"  -->
        <ng-template kendoGridToolbarTemplate>
          <button kendoGridAddCommand [disabled]="whileEditing">Aggiungi record</button>
        </ng-template>
        <ng-template kendoGridToolbarTemplate>
          <button kendoGridExcelCommand icon="file-excel">
            Esportare in Excel
          </button>
        </ng-template>
        <kendo-grid-command-column *ngIf="whileEditing" [title]="translate.instant('COMPLAINTS.Azioni Disponibili')" [width]="150">
          <ng-template kendoGridCellTemplate>
            <!-- <button kendoGridEditCommand [disabled]="whileEditing">Modifica</button>
            <button kendoGridRemoveCommand [primary]="true" [disabled]="whileEditing">Cancella</button> -->
            <button kendoGridSaveCommand (click)="save = true" [disabled]="formAll.invalid">
              Salva
            </button>
            <button kendoGridCancelCommand>
              Chiudi
            </button>
          </ng-template>
        </kendo-grid-command-column>

        <!-- <kendo-grid-column [locked]="true" field="id" title="ID" [width]="100" [editable]="false">  </kendo-grid-column> -->

        <kendo-grid-column field="company" name="company" title="Sede" [width]="220" [filterable]="true" [editable]="false" >
          <ng-template kendoGridCellTemplate  let-dataItem>
            <span>{{ dataItem.company }}</span>
          </ng-template>
        </kendo-grid-column>

        <!-- <kendo-grid-column field="type" name="type" title="Tipo Obiettivo" [width]="180" [editable]="false">
          <ng-template kendoGridCellTemplate  let-dataItem>
            <span>{{ dataItem.type }}</span>
          </ng-template>
        </kendo-grid-column> -->

        <!-- <kendo-grid-column field="description" name="description" title="Valore Obiettivo" [width]="180" > </kendo-grid-column> -->

        <!-- <kendo-grid-column field="year" name="year" title="Anno" [width]="90" [editable]="false">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="year"
              [min]="2000"
              [maxlength]="maxLengthYear"
              [format]="'#'"
              [decimals]="decimals">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column> -->

        <kendo-grid-column field="january" name="january" title="Gennaio" id="january"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'january'? 'currentMonth': ''"
        [class]="currentMonth === 'january'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="january"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="february" name="february" title="Febbraio"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'february'? 'currentMonth': ''"
        [class]="currentMonth === 'february'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="february"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="march" name="march" title="Marzo"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'march'? 'currentMonth': ''"
        [class]="currentMonth === 'march'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="march"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="april" name="april" title="Aprile"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'april'? 'currentMonth': ''"
        [class]="currentMonth === 'april'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="april"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="may" name="may" title="Maggio"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'may'? 'currentMonth': ''"
        [class]="currentMonth === 'may'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="may"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="june" name="june" title="Giugno"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'june'? 'currentMonth': ''"
        [class]="currentMonth === 'june'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="june"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="july" name="july" title="Luglio"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'july'? 'currentMonth': ''"
        [class]="currentMonth === 'july'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="july"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="august" name="august" title="Agosto"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'august'? 'currentMonth': ''"
        [class]="currentMonth === 'august'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="august"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="september" name="september" title="Settembre"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'september'? 'currentMonth': ''"
        [class]="currentMonth === 'september'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="september"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="october" name="october" title="Ottobre"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'october'? 'currentMonth': ''"
        [class]="currentMonth === 'october'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="october"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="november" name="november" title="Novembre"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'november'? 'currentMonth': ''"
        [class]="currentMonth === 'november'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="november"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="december" name="december" title="Dicembre"
        [width]="90"
        [filterable]="false"
        [headerClass]="currentMonth === 'december'? 'currentMonth': ''"
        [class]="currentMonth === 'december'? 'currentMonthBody': ''">
          <ng-template kendoGridEditTemplate>
            <kendo-numerictextbox
              formControlName="december"
              [spinners]="false"
              [format]="'n2'"
              [decimals]="decimals2">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-excel
        fileName="CompanyTargets.xlsx"
        [fetchData]="allData">
        </kendo-grid-excel>
      </kendo-grid>
    </form>
  </div>
