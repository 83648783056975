import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  EventEmitter,
  Output
} from '@angular/core';

import { ResourcePlan } from '../../models/resource-plan';

import {
  map,
  EMPTY,
  filter,
  switchMap,
  throttleTime,
  tap,
  Observable,
  Subscription,
} from 'rxjs';

import { BryntumSchedulerProComponent, BryntumSchedulerProProjectModelComponent } from '@bryntum/schedulerpro-angular-thin';
import { Button, EventHelper, StringHelper, TextField, Store, MessageDialog, LocaleManager, DateHelper, Checkbox, DateField } from '@bryntum/core-thin';
import { SchedulerPro, CalendarModel } from '@bryntum/schedulerpro-thin';
import { ColumnStore } from '@bryntum/grid-thin';

import { schedulerProConfig, projectConfig } from './planner-scheduler-energy-operators.config';
import { localeIt } from './locate-it';
import { localeFr } from './locate-fr';
import { localeEs } from './locate-es';

import { PlannerService } from '../../providers/planner.service';
import { FinanceService } from '../../../services/finance.service'
import { UserSessionService } from '../../../services/user-session.service'

import { LibService } from '../../../services/libService'
import { PlanningService } from '../../../services/planning.service'
import { OrderService } from '../../../services/order.service'
import { TranslatorService } from '../../../services/translate.service';
import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MyTimeRangeStore } from './MyTimeRange';
import { CheckBox } from '@syncfusion/ej2-angular-buttons';
import * as moment from 'moment';
import { UserRole } from '../../models/userRole';
import { User } from 'src/app/models/user';
import { Company } from 'src/app/models/company';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'planner-scheduler-energy-operators',
  templateUrl: './planner-scheduler-energy-operators.component.html',
  styleUrls: ['./planner-scheduler-energy-operators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlannerSchedulerEnergyOperatorsComponent implements OnInit, AfterViewInit {
  private schedulerPro: SchedulerPro;

  @Output() setPlanningFullScreen = new EventEmitter();
  @Input() fullScreen = false;

  plannerType: string = this.router.url.split('/')[2]
    ? this.router.url.split('/')[2]
    : 'core';

  myTimeRangeStore = new MyTimeRangeStore();

  planningStartDate: any = moment().subtract(15, 'days').toDate();
  planningEndDate: any = moment().add(2, 'months').toDate();
  currentUser: User = this.userSessionService.getState<User>('user')!;

  plannerViewOperators: any = true;


  teamLeaderLabel = 'CAPO SQUADRA';

  columns: any = [
    {
      text: this.translate.instant('PLANNER.CAPOSQUADRA'),
      field: 'name',
      width: 200,
    },
  ];

  defaultColor: string = "#3F51B5";
  colorList = {
    Black: "#000000",
    Red: "#FF0000",
    Green: "#008000",
    Blue: "#0000FF",
    Maroon: "#800000",
    Olive: "#808000",
    Navy: "#000080",
    Teal: "#008080",
    Fuchsia: "#FF00FF",
    Orange: "#FFA500",
    Crimson: "#DC143C",
    DarkGoldenRod: "#B8860B",
    DarkMagenta: "#8B008B",
    SaddleBrown: "#8B4513",
    FireBrick: "#B22222",
    Chocolate: "#D2691E",
    DarkOrange: "#FF8C00"
  };
  colorValues = Object.values(this.colorList);

  cls = 'custom-style';
  consuntivoLabel = this.translate.instant('SIDEBAR.Consuntivi');
  eventiLabel = this.translate.instant('LABEL.Certificati');

  calendars: any = [
    // Calendar definition
    {
      id: 'weekends',
      name: 'Weekends',
      // Intervals to define all SA and SU as non-working days
      intervals: [
        {
          recurrentStartDate: 'on Sat at 0:00',
          recurrentEndDate: 'on Mon at 0:00',
          isWorking: false,
        },
      ],
    },
  ];

  timeRanges: any = [
    {
      id: 1,
      name: '',
      recurrenceRule: 'FREQ=WEEKLY;BYDAY=SU;',
      startDate: '2021-07-14 00:00',
      endDate: '2021-07-15 00:00',
    },
    {
      id: 2,
      name: '',
      recurrenceRule: 'FREQ=WEEKLY;BYDAY=SA;',
      startDate: '2021-07-14 00:00',
      endDate: '2021-07-15 00:00',
    },
  ];

  eventLayout: any = {
    type: 'stack',
    //groupBy: (event: any) => (event.prio === 'high' ? 1 : 2)
    groupBy: 'prio',
  };

  hashCode(text: any) {
    var hash = 0,
      i,
      chr;
    if (text.length === 0) return hash;
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  changeColumsStructure(event: any) {
    this.plannerViewOperators = event.checked;
    if(!this.plannerViewOperators) this.router.navigate(['planner', 'energy']);
  }

  features: any = {
    dependencyEdit: false,
    dependencies: false,
    filterBar: true,
    percentBar: {
      allowResize: false,
      showPercentage: false, // showing a tooltip instead
    },
    timeRanges: {
      enableResizing: false,
      showCurrentTimeLine: false,
      showHeaderElements: false,
    },
    nonWorkingTime: true,
    eventNonWorkingTime: {
      enableResizing: true,
    },
    cellEdit: false,
    cellMenu: {
      items: {
        removeRow: false,
      },
    },
    eventTooltip: {
      align: 'l-r',
      template: (data: any) => {
        //console.log(data)
        let templateTooltip = '';
        if (
          data.eventRecord.get('finalBalancer') ||
          data.eventRecord.get('withFinalBalancer')
        ) {
          templateTooltip = `<dl>
${data.eventRecord.get('code')
              ? `<dt>${this.translate.instant(
                'PLANNER.CODE_COMMESSA'
              )}:</dt><dd>${data.eventRecord.get('code')}</dd>`
              : ''
            }
${data.eventRecord.get('clientName')
              ? `<dt>${this.translate.instant(
                'PLANNER.SUBJ'
              )}:</dt><dd>${data.eventRecord.get('clientName')}</dd>`
              : ''
            }
${data.eventRecord.get('nameBuilding')
              ? `<dt>${this.translate.instant(
                'PLANNER.Edificio'
              )}:</dt><dd>${data.eventRecord.get('nameBuilding')}</dd>`
              : ''
            }
${data.eventRecord.get('assistantsName')
              ? `<dt>${this.translate.instant(
                'PLANNER.WORKER_ASSISTANT'
              )}:</dt><dd>- ${data.eventRecord
                .get('assistantsName')
                .join('<br>- ')}</dd>`
              : ''
            }
<hr>
${data.eventRecord.get('OrderHoursTotal').toString()
              ? `<dt>${this.translate.instant(
                'PLANNER.OreDeliberate'
              )}:</dt><dd>${data.eventRecord.get('OrderHoursTotal')}</dd>`
              : ''
            }
${data.eventRecord.get('TaskHoursTotal').toString()
              ? `<dt>${this.translate.instant(
                'PLANNER.OrePianificate'
              )}:</dt><dd>${data.eventRecord.get('TaskHoursTotal')}</dd>`
              : ''
            }
${data.eventRecord.get('PassiveHoursTotal').toString()
              ? `<dt>${this.translate.instant(
                'PLANNER.OreConsuntivate'
              )}:</dt><dd>${data.eventRecord.get('PassiveHoursTotal')}</dd>`
              : ''
            }
<hr>
${data.eventRecord.get('deliberateQuantityToBeProduced')
              ? `<dt>${this.translate.instant(
                'PLANNER.QuantitaTotaleDeliberata'
              )}:</dt><dd>${data.eventRecord.get(
                'deliberateQuantityToBeProduced'
              )}</dd>`
              : ''
            }
${data.eventRecord.get('totalSAL')
              ? `<dt>${this.translate.instant(
                'PLANNER.QuantitaTotaleProdotta'
              )}:</dt><dd>${data.eventRecord.get('totalSAL')} ( ${data.eventRecord.get(
                'percentDone'
              )}% )</dd>`
              : ''
            }
${data.eventRecord.get('weekSAL')
              ? `<dt>${this.translate.instant(
                'PLANNER.QuantitaSettimanaleProdotta'
              )}:</dt><dd>${data.eventRecord.get('weekSAL')} ( ${data.eventRecord.get(
                'weekSALpercent'
              )}% )</dd>`
              : ''
            }
<hr>
${data.eventRecord.get('deliberateValueToBeProduced')
              ? `<dt>${this.translate.instant(
                'PLANNER.ValoreTotaleDeliberato'
              )}:</dt><dd>${data.eventRecord.get('deliberateValueToBeProduced')}</dd>`
              : ''
            }
${data.eventRecord.get('totalSALValue')
              ? `<dt>${this.translate.instant(
                'PLANNER.ValoreTotaleProdotto'
              )}:</dt><dd>${data.eventRecord.get(
                'totalSALValue'
              )} ( ${data.eventRecord.get('totalSALValuePercent')}% )</dd>`
              : ''
            }
${data.eventRecord.get('weekSALValue')
              ? `<dt>${this.translate.instant(
                'PLANNER.ValoreSettimanaleProdotto'
              )}:</dt><dd>${data.eventRecord.get(
                'weekSALValue'
              )} ( ${data.eventRecord.get('weekSALValuePercent')}% )</dd>`
              : ''
            }
<hr>
${this.translate.instant('PLANNER.GGCONSUNTIVATI')}:
<table class="consTable">
<th class="${data.eventRecord.get('weekDays').includes('Mo') ? 'mark' : ''
            }">L</th>
<th class="${data.eventRecord.get('weekDays').includes('Tu') ? 'mark' : ''
            }">M</th>
<th class="${data.eventRecord.get('weekDays').includes('We') ? 'mark' : ''
            }">M</th>
<th class="${data.eventRecord.get('weekDays').includes('Th') ? 'mark' : ''
            }">G</th>
<th class="${data.eventRecord.get('weekDays').includes('Fr') ? 'mark' : ''
            }">V</th>
<th class="${data.eventRecord.get('weekDays').includes('Sa') ? 'mark' : ''
            }">S</th>
<th class="${data.eventRecord.get('weekDays').includes('Su') ? 'mark' : ''
            }">D</th>
</table>
</dl>`;
        } else {
          templateTooltip = `<dl>
${data.eventRecord.get('code')
              ? `<dt>${this.translate.instant(
                'PLANNER.CODE_COMMESSA'
              )}:</dt><dd>${data.eventRecord.get('code')}</dd>`
              : ''
            }
${data.eventRecord.get('clientName')
              ? `<dt>${this.translate.instant(
                'PLANNER.SUBJ'
              )}:</dt><dd>${data.eventRecord.get('clientName')}</dd>`
              : ''
            }
${data.eventRecord.get('nameBuilding')
              ? `<dt>${this.translate.instant(
                'PLANNER.Edificio'
              )}:</dt><dd>${data.eventRecord.get('nameBuilding')}</dd>`
              : ''
            }
${data.eventRecord.get('assistantsName')
              ? `<dt>${this.translate.instant(
                'PLANNER.WORKER_ASSISTANT'
              )}:</dt><dd>- ${data.eventRecord
                .get('assistantsName')
                .join('<br>- ')}</dd>`
              : ''
            }
<hr>
${data.eventRecord.get('OrderHoursTotal')?.toString()
              ? `<dt>${this.translate.instant(
                'PLANNER.OreDeliberate'
              )}:</dt><dd>${data.eventRecord.get('OrderHoursTotal')}</dd>`
              : ''
            }
${data.eventRecord.get('TaskHoursTotal')?.toString()
              ? `<dt>${this.translate.instant(
                'PLANNER.OrePianificate'
              )}:</dt><dd>${data.eventRecord.get('TaskHoursTotal')}</dd>`
              : ''
            }
${data.eventRecord.get('PassiveHoursTotal')?.toString()
              ? `<dt>${this.translate.instant(
                'PLANNER.OreConsuntivate'
              )}:</dt><dd>${data.eventRecord.get('PassiveHoursTotal')}</dd>`
              : ''
            }
</dl>`;
        }
        return templateTooltip;
      },
    },
    taskEdit: false,
    eventMenu: {
      items: {
        // Add extra items shown for each event

        /*
        split : {
            text   : 'Split',
            icon   : 'b-fa b-fa-fw b-fa-cut',
            weight : 520,
            onItem({eventRecord}: any) {
              eventRecord.flagged = true;
              let res = eventRecord.split();
              console.log(res)
          }
        },
        */

        reset: {
          ref: 'reset',
          text: 'Reset',
          icon: 'b-fa b-fa-fw b-fa-xmark',
          weight: 520,
          onItem: ({ eventRecord }: any) =>
            this.resetOfThePlannedOrder(eventRecord),
        },

        eventi: {
          ref: 'eventi',
          text: this.eventiLabel,
          icon: 'b-fa b-fa-file-invoice-dollar',
          weight: 520,
          onItem({ eventRecord }: any) {
            window.open(
              `/certificates`,
              '_blank'
            );
          },
        },

        // Edit a built in item
        editEvent: {},

        // Hide a built in item
        unassignEvent: false,
        splitEvent: false,
        cutEvent: false,
      },
    },
    scheduleMenu: {
      items: {
        // Remove "Add event" default item
        addEvent: false,
      },
    },
  };

  tbarConfig = {
    items: [
      {
        type: 'textfield',
        ref: 'filterByName',
        icon: 'b-fa b-fa-filter',
        placeholder: this.translate.instant('PLANNER.cercacommessa'),
        clearable: true,
        width: '15em',
        keyStrokeChangeDelay: 100,
        triggers: {
          filter: {
            align: 'start',
            cls: 'b-fa b-fa-filter',
          },
        },
      },
      {
        type: 'datefield',
        ref: 'startDateField',
        width: 130,
        editable: false,
        step: 1,
        value: this.planningStartDate
      },
      {
        type: 'datefield',
        ref: 'endDateField',
        width: 130,
        editable: false,
        step: 1,
        value: this.planningEndDate
      },
      '->',
      {
        type: 'button',
        id: 'fullPlanning',
        ref: 'fullPlanning',
        icon: 'b-icon-fullscreen',
        text: '',
      },
      {
        type: 'button',
        ref: 'loadTasksButton',
        icon: 'b-icon-sync',
        text: '',
      },
      {
        type: 'button',
        ref: 'zoomInButton',
        icon: 'b-icon-search-plus',
        text: 'Zoom in',
      },
      {
        type: 'button',
        ref: 'zoomOutButton',
        icon: 'b-icon b-icon-search-minus',
        text: 'Zoom out',
      },
    ],
  };

  listeners = {};

  resetOfThePlannedOrder(eventRecord: any) {
    console.log('RESET');
    console.log(eventRecord);

    let orderId = eventRecord.orderId;

    MessageDialog.confirm({
      title: 'Reset schedule',
      message: 'Are you sure you want to reset the schedule?',
    }).then((result: any) => {
      if (result === 1) {
        this.planningService.resetPlanning(orderId).subscribe(
          (responseData: any) => {
            this.loadTasks(true);
            //context.finalize(true);
          },
          (err) => {
            //context.finalize(false);
          }
        );
      } else {
        // context.finalize(false);
      }
    });
  }

  /*
  eventRenderer({ eventRecord, resourceRecord, renderData }: any) {
    renderData.cls[`b-type-${eventRecord.type}`] = 1;

    return [
        {
            class    : 'header',
            children : [
                {
                    class    : 'progress-outer',
                    children : [{
                        class : 'progress-fill',
                        style : {
                            width : (eventRecord.progress || 50) + '%'
                        }
                    }]
                },
                {
                    class : 'b-fa b-fa-ellipsis'
                }
            ]
        },
        {
            class    : 'footer',
            children : [
                eventRecord.type === 'meeting' ? {
                    class : 'b-fa b-fa-people-group'
                } : null,
                {
                    tag  : 'span',
                    text : eventRecord.name
                },

            ]
        }
    ];
}
*/

  goToFinalBalancer(code: any, version: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/final-balancer/', `${code}`, `${version}`])
    );

    window.open(url, '_blank');
  }
  resources: any = [];

  events: any = [];

  assignments: any = [
    { event: 1, resource: 1 },
    { event: 2, resource: 2 },
  ];

  dependencies: any = [];

  schedulerProConfig = schedulerProConfig;
  projectConfig = projectConfig;

  ordersView: any = false;

  public teamLeaders: any = [];
  public assistants: any = [];
  public resourcesList: any = [];
  public assistantOptions: any = [];

  //@ViewChild('schedulerpro') schedulerProComponent!: BryntumSchedulerProComponent;
  @ViewChild(BryntumSchedulerProComponent)
  schedulerProComponent: BryntumSchedulerProComponent;
  @ViewChild('project')
  projectComponent!: BryntumSchedulerProProjectModelComponent;

  rawTasks: any = [];

  public orderCodes: any = [];
  public orderIds: any = [];

  public eventStructure = [];
  public tasksCodeStructure: any = {};
  public finalBalancesCodeStructure: any = {};

  public tasksEvents: any = [];
  public finalBalancesEvents: any = [];

  public tasksWithFinalBalancesEvents: any = [];
  public tasksWithFinalBalancesAssignments: any = [];

  public tasksAssignments: any = [];
  public finalBalancesAssignments: any = [];
  orderColors: any = {};

  constructor(
    private plannerService: PlannerService,
    private financeService: FinanceService,
    private userSessionService: UserSessionService,
    private libService: LibService,
    private planningService: PlanningService,
    private orderService: OrderService,
    public translate: TranslatorService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  // getAndSetOrderColor(orderCode: any) {
  //   let color = this.getRandomColor();
  //   if (!this.orderColors[orderCode]) this.orderColors[orderCode] = color;
  //   return this.orderColors[orderCode];
  // }

  getNextDay(startDate: any, numberOfDays: any) {
    let start = moment(startDate, 'YYYY-MM-DD');
    return start.add(numberOfDays, 'days');
  }

  changeViewPlanning() {
    const columns = this.schedulerPro.columns as ColumnStore;
    this.initLoadTasks(true);
    columns.get('name').text = this.translate.instant('PLANNER.CAPOSQUADRA');

  }

  initLoadTasks(loading: any = false) {
    this.getResources();
    this.loadTasks(loading);
  }

  resetFilterByName() {
    // Ripulisce il campo che filtra sulla base del nome della commessa
    if (this.schedulerPro) {
      const { filterByName } = this.schedulerPro.widgetMap as {
        filterByName: TextField;
      };
      filterByName.clear();
    }
  }

  loadOrders(loading: any = false) {
    console.log('LOAD ORDERS');
    if (loading) this.libService.lockPage('');
    let currentUser = this.userSessionService.getState('user');

    this.resetFilterByName();

    // Reset Variabili
    this.tasksCodeStructure = {};
    this.tasksEvents = [];
    this.tasksAssignments = [];

    this.planningService
      .getOrdersWithTasks(currentUser.email, currentUser.id, this.getCompany())
      .subscribe((orders: any) => {
        let tmp_code: any = {};
        if ([null, undefined].includes(orders)) {
          this.schedulerPro.resourceStore.removeAll();
        } else {
          let orderResources = orders.map((order: any) => {
            return {
              id: order.id,
              name: `${order.code} - ${!['', null, undefined].includes(order.nameSubject)
                ? order.nameSubject
                : order.nameAdminCondominius
                } - ${order.nameBuilding} - ${order.city}`,
            };
          });
          this.schedulerPro.resourceStore.removeAll();

          orderResources.forEach((resource: any) => {
            this.schedulerPro.resourceStore.add(resource);
          });

          orders.forEach((order: any) => {
            order.Tasks.forEach((task: any) => {
              let tmpDate = this.getDateFromTimestampInPieces(task.tsStart);
              let tmpDateEnd = this.getDateFromTimestampInPieces(task.tsEnd);
              let week = moment(
                `${tmpDate.year}-${tmpDate.month + 1}-${tmpDate.day}`,
                'YYYY-MM-DD'
              ).format('WW');
              let weekCode = `${tmpDate.year}_${week}`;

              // let taskCode = task.code
              let taskCode = `${order.code}_${weekCode}`;

              let old = true;

              let startDate = moment(
                `${tmpDate.year}-${tmpDate.month + 1}-${tmpDate.day}`,
                'YYYY-MM-DD'
              );
              let endDate = moment(
                `${tmpDateEnd.year}-${tmpDateEnd.month + 1}-${tmpDateEnd.day}`,
                'YYYY-MM-DD'
              );
              let targetDateIndex = 0;

              /*
                          if (taskCode.length < 8) {
                            taskCode = `${order.code}_${week}`
                            old = true
                          }
                          */

              // Recupero il code di tutti gli order visualizzati
              if (!this.orderCodes.includes(order.code))
                this.orderCodes.push(order.code);
              if (!this.orderIds.includes(order.id))
                this.orderIds.push(order.id);

              let name = order.nameSubject;
              if (['', null, undefined].includes(name))
                name = order.nameAdminCondominius;

              if (!this.tasksCodeStructure[taskCode]) {
                this.tasksCodeStructure[taskCode] = {
                  teamLeaderIds: [],
                  assistantIds: [],
                  assistantNameList: [],
                  days: [],
                  orderCode: order.code,
                  orderId: order.id,
                  eventColor: task?.practice?.color,
                  // eventColor: this.getAndSetOrderColor(order.code),   
                  clientName: name,
                  nameBuilding: `${order.nameBuilding} - ${order.city}`,
                  version: order.version,
                  weekCode: weekCode,
                  multipleCodeWeek: [],
                  old: old,
                  draggable: false,
                  resizable: false,
                  PassiveHoursTotal: order.PassiveHoursTotal,
                  OrderHoursTotal: order.OrderHoursTotal,
                  TaskHoursTotal: order.TaskHoursTotal,
                };
              }

              while (
                this.getNextDay(startDate, targetDateIndex).isSameOrBefore(
                  endDate
                )
              ) {
                let curretTargetDate = this.getNextDay(
                  startDate,
                  targetDateIndex
                );

                let tmpCodeWeek = `${curretTargetDate.format(
                  'YYYY'
                )}_${curretTargetDate.format('WW')}`;
                if (
                  !this.tasksCodeStructure[taskCode].multipleCodeWeek.includes(
                    tmpCodeWeek
                  )
                )
                  this.tasksCodeStructure[taskCode].multipleCodeWeek.push(
                    tmpCodeWeek
                  );
                targetDateIndex += 1;
              }

              if (old) {
                this.tasksCodeStructure[taskCode].draggable = false;
                this.tasksCodeStructure[taskCode].resizable = false;
              }

              // se il task è di un assistente, lo aggiunge all'array e termina il ciclo
              if (task.userRoleId != 36) {
                if (
                  !this.tasksCodeStructure[taskCode].assistantIds.includes(
                    task.userId
                  )
                )
                  this.tasksCodeStructure[taskCode].assistantIds.push(
                    task.userId
                  );
                if (
                  !this.tasksCodeStructure[taskCode].assistantNameList.includes(
                    task.userName
                  )
                )
                  this.tasksCodeStructure[taskCode].assistantNameList.push(
                    task.userName
                  );
                return;
              }

              if (
                !this.tasksCodeStructure[taskCode].days.includes(task.tsStart)
              ) {
                this.tasksCodeStructure[taskCode].days.push(task.tsStart);
              }

              if (
                !this.tasksCodeStructure[taskCode].days.includes(task.tsEnd)
              ) {
                this.tasksCodeStructure[taskCode].days.push(task.tsEnd);
              }

              if (task.userRoleId == 36) {
                this.tasksCodeStructure[taskCode].teamLeaderIds.push(
                  task.userId
                );
              }

              if (!tmp_code[taskCode]) {
                tmp_code[taskCode] = [];
              }
              tmp_code[taskCode].push(task);
            });
          });

          //console.log(this.tasksCodeStructure)
          // WIP
          for (const [key, value] of Object.entries(this.tasksCodeStructure)) {
            //console.log(this.codeStructure[key].days)
            if (this.tasksCodeStructure[key].days.length != 0) {
              this.tasksCodeStructure[key].days.sort(function (x: any, y: any) {
                return x - y;
              });
              //console.log(key)
              let tmpEvent = {
                id: key,
                startDate: this.getDateFromTimestamp(
                  this.tasksCodeStructure[key].days[0]
                ),
                endDate: this.getDateFromTimestamp(
                  this.tasksCodeStructure[key].days[
                  this.tasksCodeStructure[key].days.length - 1
                  ]
                ),
                name: `${this.tasksCodeStructure[key].orderCode} - ${this.tasksCodeStructure[key].clientName} - ${this.tasksCodeStructure[key].nameBuilding}`,
                code: this.tasksCodeStructure[key].orderCode,
                orderId: this.tasksCodeStructure[key].orderId,
                clientName: this.tasksCodeStructure[key].clientName,
                nameBuilding: this.tasksCodeStructure[key].nameBuilding,
                assistants: this.tasksCodeStructure[key].assistantIds,
                assistantsName: this.tasksCodeStructure[key].assistantNameList,
                eventColor: this.tasksCodeStructure[key].eventColor,
                version: this.tasksCodeStructure[key].version,
                finalBalancer: false,
                weekCode: `${this.tasksCodeStructure[key].teamLeaderId}_${this.tasksCodeStructure[key].weekCode}`,
                multipleCodeWeek: this.tasksCodeStructure[
                  key
                ].multipleCodeWeek.map(
                  (cw: any) => `${this.tasksCodeStructure[key].orderCode}_${cw}`
                ),
                days: this.tasksCodeStructure[key].days,
                prio: 'high',
                draggable: this.tasksCodeStructure[key].draggable,
                resizable: this.tasksCodeStructure[key].resizable,
                old: this.tasksCodeStructure[key].old,
                PassiveHoursTotal:
                  this.tasksCodeStructure[key].PassiveHoursTotal,
                OrderHoursTotal: this.tasksCodeStructure[key].OrderHoursTotal,
                TaskHoursTotal: this.tasksCodeStructure[key].TaskHoursTotal,
              };

              this.tasksEvents.push(tmpEvent);
              this.tasksAssignments.push({
                event: key,
                resource: this.tasksCodeStructure[key].orderId,
              });
            }
          }
          //console.log(this.tasksEvents)
          //console.log(this.tasksAssignments)
        }

        this.codeStructureUnion();
        if (loading) this.libService.unlockPage();
      });
  }

  getCurrentWeekNumber() {
    let today = new Date();
    let structure = {
      year: today.getUTCFullYear(),
      month: today.getUTCMonth(),
      day: today.getUTCDate(),
    };
    let week = moment(
      `${structure.year}-${structure.month + 1}-${structure.day}`,
      'YYYY-MM-DD'
    ).format('WW');
    return week;
  }

  loadTasks(loading: any = false) {
    console.log('LOAD TASKS');
    if (loading) this.libService.lockPage('');
    let currentUser = this.userSessionService.getState('user');

    this.resetFilterByName();

    // Reset Variabili
    this.tasksCodeStructure = {};
    this.tasksEvents = [];
    this.tasksAssignments = [];

    let teamWithTheScheduleForTheCurrentWeek: any = [];
    console.log(this.plannerType);
    const manageData = (tasks: any) => {
      this.rawTasks = tasks;
      let tmp_code: any = {};
      console.log(tasks);

      tasks.forEach((task: any) => {
        let order = task.Order;
        let taskCode = task.code;

        let orderCode = task?.mainCode || order.code;
        let tmpDate = this.getDateFromTimestampInPieces(task.tsStart);
        let tmpDateEnd = this.getDateFromTimestampInPieces(task.tsEnd);
        let week = moment(
          `${tmpDate.year}-${tmpDate.month + 1}-${tmpDate.day}`,
          'YYYY-MM-DD'
        ).format('WW');
        let weekCode = `${tmpDate.year}_${week}`;
        let old = false;

        let startDate = moment(
          `${tmpDate.year}-${tmpDate.month + 1}-${tmpDate.day}`,
          'YYYY-MM-DD'
        );
        let endDate = moment(
          `${tmpDateEnd.year}-${tmpDateEnd.month + 1}-${tmpDateEnd.day}`,
          'YYYY-MM-DD'
        );
        let targetDateIndex = 0;

        if (taskCode.length < 8) {
          taskCode = `${orderCode}_${week}`;
          old = true;
        }

        if (task.code.length > 8) {
          // Recupero il code di tutti gli order visualizzati
          if (!this.orderCodes.includes(orderCode))
            this.orderCodes.push(orderCode);
          if (!this.orderIds.includes(order.id)) this.orderIds.push(order.id);

          let name = order.nameSubject;
          if (['', null, undefined].includes(name))
            name = order.nameAdminCondominius;

          if (!this.tasksCodeStructure[taskCode]) {
            this.tasksCodeStructure[taskCode] = {
              teamLeaderId: null,
              assistantIds: [],
              assistantNameList: [],
              days: [],
              orderCode: orderCode,
              orderId: order.id,
              eventColor: task?.practice?.color ? task?.practice?.color : this.defaultColor,
              color: task?.practice?.color ? task?.practice?.color : this.defaultColor,
              clientName: name,
              nameBuilding: `${order.nameBuilding} - ${order.city}`,
              version: order.version,
              weekCode: weekCode,
              multipleCodeWeek: [],
              old: old,
              PassiveHoursTotal: task.PassiveHoursTotal,
              OrderHoursTotal: task.OrderHoursTotal,
              TaskHoursTotal: task.TaskHoursTotal,
              week: week,
              currentWeek: this.getCurrentWeekNumber(),
            };

            this.tasksCodeStructure[taskCode].draggable = true;
            this.tasksCodeStructure[taskCode].resizable = true;

          }

          while (
            this.getNextDay(startDate, targetDateIndex).isSameOrBefore(endDate)
          ) {
            let curretTargetDate = this.getNextDay(startDate, targetDateIndex);

            let tmpCodeWeek = `${curretTargetDate.format(
              'YYYY'
            )}_${curretTargetDate.format('WW')}`;
            if (
              !this.tasksCodeStructure[taskCode].multipleCodeWeek.includes(
                tmpCodeWeek
              )
            )
              this.tasksCodeStructure[taskCode].multipleCodeWeek.push(
                tmpCodeWeek
              );
            targetDateIndex += 1;
          }

          if (old) {
            this.tasksCodeStructure[taskCode].draggable = false;
            this.tasksCodeStructure[taskCode].resizable = false;
          }

          // se il task è di un assistente, lo aggiunge all'array e termina il ciclo
          if (task.userRoleId != 36 && task.userRoleId != UserRole.ENERGY) {
            if (
              !this.tasksCodeStructure[taskCode].assistantIds.includes(
                task.userId
              )
            )
              this.tasksCodeStructure[taskCode].assistantIds.push(task.userId);
            if (
              !this.tasksCodeStructure[taskCode].assistantNameList.includes(
                task.userName
              )
            )
              this.tasksCodeStructure[taskCode].assistantNameList.push(
                task.userName
              );
            return;
          }

          if (!this.tasksCodeStructure[taskCode].days.includes(task.tsStart)) {
            this.tasksCodeStructure[taskCode].days.push(task.tsStart);
          }

          if (!this.tasksCodeStructure[taskCode].days.includes(task.tsEnd)) {
            this.tasksCodeStructure[taskCode].days.push(task.tsEnd);
          }

          if (task.userRoleId == 36 || task.userRoleId == UserRole.ENERGY) {
            this.tasksCodeStructure[taskCode].teamLeaderId = task.userId;
          }
        }

        if (!tmp_code[taskCode]) {
          tmp_code[taskCode] = [];
        }
        tmp_code[taskCode].push(task);
      });

      console.log(this.tasksCodeStructure);
      // WIP
      for (const [key, value] of Object.entries(this.tasksCodeStructure)) {
        //console.log(this.codeStructure[key].days)
        if (this.tasksCodeStructure[key].days.length != 0) {
          this.tasksCodeStructure[key].days.sort(function (x: any, y: any) {
            return x - y;
          });
          //console.log(key)
          let tmpEvent = {
            id: key,
            startDate: this.getDateFromTimestamp(
              this.tasksCodeStructure[key].days[0]
            ),
            endDate: this.getDateFromTimestamp(
              this.tasksCodeStructure[key].days[
              this.tasksCodeStructure[key].days.length - 1
              ]
            ),
            name: `${this.tasksCodeStructure[key].orderCode} - ${this.tasksCodeStructure[key].clientName} - ${this.tasksCodeStructure[key].nameBuilding}`,
            code: this.tasksCodeStructure[key].orderCode,
            orderId: this.tasksCodeStructure[key].orderId,
            clientName: this.tasksCodeStructure[key].clientName,
            nameBuilding: this.tasksCodeStructure[key].nameBuilding,
            assistants: this.tasksCodeStructure[key].assistantIds,
            assistantsName: this.tasksCodeStructure[key].assistantNameList,
            eventColor: this.tasksCodeStructure[key].eventColor,
            color: this.tasksCodeStructure[key].eventColor,
            version: this.tasksCodeStructure[key].version,
            finalBalancer: false,
            weekCode: `${this.tasksCodeStructure[key].teamLeaderId}_${this.tasksCodeStructure[key].weekCode}`,
            multipleCodeWeek: this.tasksCodeStructure[key].multipleCodeWeek.map(
              (cw: any) =>
                `${this.tasksCodeStructure[key].teamLeaderId}_${cw}_${this.tasksCodeStructure[key].orderCode}`
            ),
            days: this.tasksCodeStructure[key].days,
            prio: 'high',
            draggable: this.tasksCodeStructure[key].draggable,
            resizable: this.tasksCodeStructure[key].resizable,
            old: this.tasksCodeStructure[key].old,
            PassiveHoursTotal: this.tasksCodeStructure[key].PassiveHoursTotal,
            OrderHoursTotal: this.tasksCodeStructure[key].OrderHoursTotal,
            TaskHoursTotal: this.tasksCodeStructure[key].TaskHoursTotal,
            teamLeaderId: this.tasksCodeStructure[key].teamLeaderId,
            week: this.tasksCodeStructure[key].week,
            currentWeek: this.tasksCodeStructure[key].currentWeek,
          };

          // Segno quali sono i capo cantiere che hanno commesse nella settimana corrente
          if (
            this.tasksCodeStructure[key].week ==
            this.tasksCodeStructure[key].currentWeek
          ) {
            teamWithTheScheduleForTheCurrentWeek.push(
              this.tasksCodeStructure[key].teamLeaderId
            );
          }

          this.tasksEvents.push(tmpEvent);
          this.tasksAssignments.push({
            event: key,
            resource: this.tasksCodeStructure[key].teamLeaderId,
          });
        }
      }
      console.log(this.tasksEvents);
      console.log(this.tasksAssignments);

      // Ordina la lista dei capo cantieri in base alle commesse pianificate nella settimana corrente
      this.schedulerPro.resourceStore.sort((a: any, b: any) => {
        return (
          teamWithTheScheduleForTheCurrentWeek.includes(b.id) -
          teamWithTheScheduleForTheCurrentWeek.includes(a.id)
        );
      });

      //console.log(this.tasksEvents)
      //console.log(this.tasksAssignments)
      this.codeStructureUnion();

      if (loading) this.libService.unlockPage();

      //console.log(this.orderCodes)


      //console.log(tmp_code)
      //console.log(this.events)
      //console.log(this.codeStructure)
    };

    let withCoreTasks = false
    this.planningService
      .getOrdersAndTasksEnergyWithPagination(this.planningStartDate, this.planningEndDate, withCoreTasks)
      .subscribe((tasks: any) => {
        manageData(tasks);
      });


  }


  setStartDate({ value, userAction }: any) {
    if (userAction) {
      console.log(value)
      this.planningStartDate = value
      this.loadTasks(true);
    }

  }

  setEndDate({ value, userAction }: any) {
    if (userAction) {
      let endDate: any = moment(value).utc(true).hours(23).minutes(59).seconds(59).milliseconds(59).toDate()
      this.planningEndDate = endDate
      this.loadTasks(true);
    }
  }

  ngOnInit(): void {
    this.initLoadTasks();
  }

  // getRandomColor() {

  //   // return this.colorList[Math.floor(Math.random() * this.colorList.length)];
  //   const colors = Object.values(this.colorList);
  //   return colors[Math.floor(Math.random() * colors.length)];
  // }

  getResources() {
    this.financeGetRisorse(UserRole.ENERGY); // Assistants
  }

  getCompany() {
    const company = this.userSessionService.getState('working_company');
    if (!(company == null) && !(company.name == '*')) {
      return company.id;
    } else return 0;
  }

  financeGetRisorse(idRole: any) {
    let currentUser = this.userSessionService.getState('user');
    this.financeService
      .getRisorse(currentUser.email, 0, idRole)
      .subscribe({
        next: (res: any) => {
          if (res.esito == 'OK') {
            this.schedulerPro = this.schedulerProComponent.instance;
            const editor = this.schedulerPro.features.eventEdit.editor;
            switch (idRole) {
              case UserRole.ENERGY:
                this.teamLeaders = res.item.Table;
                this.schedulerPro.resourceStore.removeAll();
                this.teamLeaders.forEach((team: any) => {
                  this.schedulerPro.resourceStore.add({
                    id: team.id,
                    name: team.name,
                  });
                });
                break;
            }
          }
        },
        error: (e: any) => {
          console.log(e);
          this.teamLeaders = [];
        },
      });
  }

  getDateFromTimestamp(ts: any) {
    let timestamp = parseInt(ts);
    let date = new Date(timestamp * 1000);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes()
    );
  }

  getDateFromTimestampWithHour(ts: any, start: boolean = true) {
    let timestamp = parseInt(ts);
    let date = new Date(timestamp * 1000);
    if (start)
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0,
        0
      );
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      23,
      59
    );
  }

  getDateFromTimestampWithHourNotUTC(ts: any, start: boolean = true) {
    let timestamp = parseInt(ts);
    let date = new Date(timestamp * 1000);
    if (start)
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0
      );
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59
    );
  }

  getDateFromTimestampInPieces(ts: any) {
    let timestamp = parseInt(ts);
    let date = new Date(timestamp * 1000);
    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth(),
      day: date.getUTCDate(),
    };
  }

  getEventEdit() {
    return {
      editorConfig: {
        width: '32em',
      },

      // Uncomment to make event editor readonly from the start
      // readOnly : true,
      // Add items to the event editor
      items: {
        nameField: {
          readOnly: true,
        },
        startDateField: false,
        startTimeField: false,
        endDateField: false,
        endTimeField: false,
        recurrenceCombo: false,
        resourceField: false,
        colorField: false,
        assistantsField: {
          type: 'combo',
          name: 'assistants',
          label: 'Assistants',
          required: true,
          multiSelect: true,
          valueField: 'id',
          displayField: 'name',
          weight: 120,
          items: this.assistantOptions,
        },

        eventColorField: {
          label: 'Color',
          type: 'colorField',
          name: 'eventColor',
          items: this.colorValues,
          weight: 213,
          colors: this.colorValues
        },
      },
    };
  }

  dateForBryntum(date: any) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  clickZoomIn(): void {
    this.schedulerPro.zoomIn();
  }

  clickZoomOut(): void {
    this.schedulerPro.zoomOut();
  }

  filterEvents(event: any): void {
    const value = event.value.replace(/[.*+?^${}(),[\]\\]/g, '\\$&');
    this.schedulerPro.eventStore.filter({
      filters: (event: any) => event.name.match(new RegExp(value, 'i')),
      replace: true,
    });

    // Filtro i Team Leader sulla base delle commesse ricercate
    this.schedulerPro.resourceStore.filter({
      filters: (resource: any) =>
        resource.events.some((e: any) => e.name.match(new RegExp(value, 'i'))),
      replace: true,
    });
  }

  async refreshRows() {
    this.changeViewPlanning();
  }

  getWeekNumber(d: any) {
    // Copy date so don't modify original

    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // Set to nearest Thursday: current date + 4 - current day number

    // Make Sunday's day number 7

    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    // Get first day of year

    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    // Calculate full weeks to nearest Thursday

    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);

    // Return array of year and week number

    return [d.getUTCFullYear(), weekNo];
  }

  getDateFromUnixTimestamp(timestamp: any) {
    let ts = timestamp * 1000;
    return new Date(ts);
  }

  codeStructureUnion(withFinalBalances = false) {
    // Reset Variabili
    this.events = [];
    this.assignments = [];

    if (withFinalBalances) {
      // Unione eventi e assegnazioni
      let tasksWithFinalBalancesEventsIds =
        this.tasksWithFinalBalancesEvents.map((e: any) => e.id);
      let tasksWitoutFinalBalances = this.tasksEvents.filter(
        (e: any) => !tasksWithFinalBalancesEventsIds.includes(e.id)
      );
      //console.log(this.tasksAssignments)
      let assignmentsWitoutFinalBalances = this.tasksAssignments.filter(
        (e: any) => !tasksWithFinalBalancesEventsIds.includes(e.event)
      );

      this.events = [
        ...this.finalBalancesEvents,
        ...this.tasksWithFinalBalancesEvents,
        ...tasksWitoutFinalBalances,
      ];
      this.assignments = [
        ...this.finalBalancesAssignments,
        ...this.tasksWithFinalBalancesAssignments,
        ...assignmentsWitoutFinalBalances,
      ];
    } else {
      this.events = [...this.tasksEvents, ...[]];
      this.assignments = [...this.tasksAssignments, ...[]];
    }

    // Aggiornamento diello schedulerPro
    if (this.schedulerPro) {
      //console.log('Reload')
      this.schedulerPro.eventStore.data = this.events;
      this.schedulerPro.assignmentStore.data = this.assignments;
    }

    if (this.schedulerPro) {
      /*
       this.schedulerPro.overlappingEventSorter = (
         a: any,
         b: any,
       ) => {

         const same = (a.priority == b.priority);

         if (same) {
           return a.prio < b.prio ? -1 : 1;
         } else {
           return (a.prio > b.prio) ? -1 : 1;
         }
       };
       */
    }
  }



  getAllIndexes(arr: any, val: any) {
    var indexes = [],
      i;
    for (i = 0; i < arr.length; i++)
      if (arr[i]?.multipleCodeWeek.includes(val)) indexes.push(i);
    return indexes;
  }



  fullPlanning() {
    console.log('fullPlanning');
    this.fullScreen = !this.fullScreen;
    this.setPlanningFullScreen.next(this.fullScreen);
  }


  /**
   * Called after View is initialized
   */
  ngAfterViewInit(): void {
    // SchedulerPro instance
    this.schedulerPro = this.schedulerProComponent.instance;
    this.applySchedulerLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: any) => {
      this.applySchedulerLocale(event.lang);
      // this.consuntivoLabel = this.translate.instant('SIDEBAR.Consuntivi');
      // console.log(this.schedulerPro)
    });

    this.schedulerPro.scrollToDate(new Date(), {
      block: 'start',
    });

    const {
      zoomInButton,
      zoomOutButton,
      filterByName,
      loadTasksButton,
      fullPlanning,
      startDateField,
      endDateField,
    } = this.schedulerPro.widgetMap as {
      loadTasksButton: Button;
      zoomInButton: Button;
      zoomOutButton: Button;
      filterByName: TextField;
      fullPlanning: Button;
      startDateField: DateField;
      endDateField: DateField;
    };

    loadTasksButton.on({ click: this.refreshRows, thisObj: this });
    zoomInButton.on({ click: this.clickZoomIn, thisObj: this });
    zoomOutButton.on({ click: this.clickZoomOut, thisObj: this });
    filterByName.on({ change: this.filterEvents, thisObj: this });
    fullPlanning.on({ click: this.fullPlanning, thisObj: this });
    startDateField.on({ change: this.setStartDate, thisObj: this });
    endDateField.on({ change: this.setEndDate, thisObj: this });

    this.schedulerPro.on('beforeeventdropfinalize', ({ context }: any) => {
      console.log('EVENTO SPOSTATO');
      this.libService.lockPage('');
      let currentUser = this.userSessionService.getState('user');
      let structure = this.getStructureForDrop(context, currentUser);
      //console.log(structure)
      context.async = true;
      let code = this.getCode(context);

      this.planningService.updateSchedule(code, structure).subscribe(
        (responseData: any) => {
          context.finalize(true);
          this.libService.unlockPage();
          //this.toastr.success(this.translate.instant('LABEL.DATA_ERROR'),this.translate.instant('TOASTR.WARNING'));
          this.loadTasks(true);
        },
        (err) => {
          context.finalize(false);
          //this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'),this.translate.instant('TOASTR.WARNING'));
          this.libService.unlockPage();
        }
      );
    });

    this.schedulerPro.on('beforeEventResizeFinalize', ({ context }: any) => {
      console.log('EVENTO RIDIMENSIONATO');
      //console.log(context)

      let startDate = this.setDateToUTCZone(context.startDate);
      let endDate = this.setDateToUTCZone(context.endDate);

      if (context.edge == 'right') {
        endDate = this.setDateToUTCZone(context.date);
      } else {
        startDate = this.setDateToUTCZone(context.date);
      }

      let currentUser = this.userSessionService.getState('user');

      this.libService.lockPage('');
      let structure = this.getStructureForResize(
        context,
        startDate,
        endDate,
        currentUser
      );
      // console.log(structure)
      context.async = true;
      let code = this.getCode(context);

      //context.eventRecord.split(); // wip

      this.planningService.resizePlanning(code, structure).subscribe(
        (responseData: any) => {
          context.finalize(true);
          this.libService.unlockPage();
          this.loadTasks(true);
        },
        (err) => {
          context.finalize(false);
          this.libService.unlockPage();
        }
      );
    });

    this.schedulerPro.on('beforeEventEdit', (event: any) => {
      console.log('EDIT');
      console.log(event)

      // Rimozione degli utenti con due ruoli, ha dato problemi il 24/08/2023 per la sede di "Perpignan 1"
      let tmpAssistantOptions: any = [];
      let countId: any = [];
      this.assistantOptions.forEach((assistant: any) => {
        if (!countId.includes(assistant.id))
          tmpAssistantOptions.push(assistant);
        countId.push(assistant.id);
      });

      event.eventEdit.editor.widgetMap.assistantsField.items =
        tmpAssistantOptions;
      navigator.clipboard.writeText(event.eventRecord.code);

      // Se l'evento è una vecchi pianificazione o un evento di consuntivazione blocca l'azione
      let status = false;
      status =
        (event.eventRecord.old && !event.eventRecord.finalBalancer) ||
        event.eventRecord.finalBalancer;
      return !status;
    });

    this.schedulerPro.on('eventMenuBeforeShow', (event: any) => {
      console.log('SHOW MENU');
      //console.log(event)
      navigator.clipboard.writeText(event.eventRecord.code);

      // Se l'evento è una vecchi pianificazione o un evento di consuntivazione blocca l'azione
      let status = false;
      status =
        (event.eventRecord.old && !event.eventRecord.finalBalancer) ||
        event.eventRecord.finalBalancer;
      return !status;
    });

    this.schedulerPro.on('beforePaste', (event: any) => {
      console.log('INCOLLA');
      //console.log(event)
      //console.log(this.teamLeaders)
      let assistantIds = event.eventRecords[0].assistants;
      let resources = this.getAssistants(assistantIds);
      let teamLeaderId = event.resourceRecord.originalData.id;
      resources.push(this.getTeamLeader(teamLeaderId));

      let currentUser = this.userSessionService.getState('user');

      // this.libService.lockPage('');
      let structure = this.getStructureForCopy(event, resources, currentUser);
      //console.log(structure)
      let code = structure.id;

      //context.eventRecord.split(); // wip

      this.planningService.copyPlanning(code, structure).subscribe(
        (responseData: any) => {
          this.libService.unlockPage();
          this.loadTasks(true);
          return true;
        },
        (err) => {
          this.libService.unlockPage();
          return false;
        }
      );
    });

    this.schedulerPro.on('beforeDragCreate', (resource: any) => {
      // console.log('beforeDragCreate')
      return false;
    });

    this.schedulerPro.on(
      'beforeEventDelete',
      ({ eventRecords, context }: any) => {
        console.log('RIMUOVI');
        console.log(eventRecords);
        //console.log(context)
        let code = eventRecords[0].id;
        // Show custom confirmation dialog (pseudo code)
        let structure = this.getStructureForDelete(eventRecords[0]);

        MessageDialog.confirm({
          title: 'Dialog Title',
          message: 'Are you sure to Delete?',
        }).then((result) => {
          if (result === 1) {
            this.planningService.deletePlanning(code, structure).subscribe(
              (responseData: any) => {
                this.loadTasks(true);
                context.finalize(true);
              },
              (err) => {
                context.finalize(false);
              }
            );
          } else {
            context.finalize(false);
          }
        });

        // Prevent default behaviour
        return false;
      }
    );

    // Reset del blocco di Bryntum
    localStorage.removeItem('b-schedulerpro-verify-date');
    localStorage.removeItem('b-schedulerpro-trial-start');
  }

  getTeamLeader(id: any) {
    return this.teamLeaders.find((t: any) => t.id == id);
  }

  getAssistants(ids: any) {
    return this.assistants.filter((t: any) => ids.includes(t.id));
  }

  saveEvent(event: any) {
    console.log('SAVE');
    //console.log(event)
    this.libService.lockPage('');
    let values = event.values;
    let assistantIds = values.assistants;
    let color = values.eventColor;

    let currentUser = this.userSessionService.getState('user');

    let code = event.eventRecord.id;

    let params = {
      code: code,
      assistants: this.getAssistants(assistantIds),
      currentUser: currentUser.email,
      authorId: currentUser.id,
      color: color
    };


    this.planningService.changeAssistants(code, params).subscribe(
      (responseData: any) => {
        this.libService.unlockPage();
        this.loadTasks(true);
        return true;
      },
      (err) => {
        this.libService.unlockPage();
        return false;
      }
    );
  }

  getStructureForDrop(context: any, currentUser: any) {
    let originalData = context.eventRecord.originalData;

    let id = originalData.id;
    let assistants = originalData.assistants;
    let startDate = this.setDateToUTCZone(context.startDate);
    let endDate = this.setDateToUTCZone(context.endDate);
    let newResource = context.newResource.originalData;
    let teamLeaderId = originalData.teamLeaderId;
    let orderId = originalData.orderId;

    return {
      id: id,
      assistants: assistants,
      startDate: startDate,
      endDate: endDate,
      resource: newResource,
      currentUser: currentUser.email,
      authorId: currentUser.id,
      teamLeaderId: teamLeaderId,
      orderId: orderId,
    };
  }

  setDateToUTCZone(date: any) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let newDate = new Date(Date.UTC(year, month, day));
    return newDate;
  }

  getStructureForResize(
    context: any,
    startDate: any,
    endDate: any,
    currentUser: any
  ) {
    let originalData = context.eventRecord.originalData;
    let id = originalData.id;
    let assistants = originalData.assistants;
    let teamLeaderId = originalData.teamLeaderId;
    let orderId = originalData.orderId;

    return {
      id: id,
      assistants: assistants,
      startDate: startDate,
      endDate: endDate,
      currentUser: currentUser.email,
      authorId: currentUser.id,
      teamLeaderId: teamLeaderId,
      orderId: orderId,
    };
  }

  getStructureForDelete(eventRecord: any) {
    let originalData = eventRecord.originalData;
    let teamLeaderId = originalData.teamLeaderId;
    let orderId = originalData.orderId;

    return {
      teamLeaderId: teamLeaderId,
      orderId: orderId,
    };
  }

  getStructureForCopy(context: any, resources: any, currentUser: any) {
    let resource = context.resourceRecord.originalData;
    let eventRecords = context.eventRecords[0];
    let code = eventRecords.code;
    let assistants = eventRecords.assistants;

    let id = eventRecords.id;

    //let startDate = this.setDateToUTCZone(context.startDate)
    //let endDate = this.setDateToUTCZone(context.endDate)
    let structure = {
      id: id,
      resources: resources,
      newStartDate: this.setDateToUTCZone(context.date),
      // startDate: startDate,
      // endDate: endDate,
      currentUser: currentUser.email,
      authorId: currentUser.id,
    };

    return structure;
  }

  getCode(context: any) {
    let originalData = context.eventRecord.originalData;
    return originalData.id;
  }

  applySchedulerLocale = (schedulerLocale: string): void => {
    switch (schedulerLocale) {
      case 'it':
        LocaleManager.applyLocale(localeIt);
        break;
      case 'fr':
        LocaleManager.applyLocale(localeFr);
        break;
      case 'es':
        LocaleManager.applyLocale(localeEs);
        break;
      case 'en':
        LocaleManager.locale = 'En';
        break;
      default:
        LocaleManager.locale = 'En';
        break;
    }
  };
}
