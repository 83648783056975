// models/calendar-models.ts

export interface Country {
    id: number;
    name: string;
  }
  
  export interface Society {
    societyId: any;
    societyName: any;
    societyCountry: string | number;
    id: number;
    name: string;
    countryId: number;
  }
  
  export interface Company {
    companyId: any;
    companyName: any;
    id: number;
    name: string;
    societyId: number;
  }
  
  export interface WorkCausal {
    id: number;
    name: string;
  }
  
  export interface HolidayCalendar {
    id: number;
    startDate: string;
    endDate: string;
    description: string;
    hours: number;
    workCausalId: number;
    countryId?: any;
    societyId?: any;
    companyId?: any;
  }
  