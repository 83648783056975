import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Log } from '../models/log';
import { LibService } from './libService';
import { CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";

export interface FindManyOptions{
  select?: any //object like {fieldName: true, fieldname2: true}
  where?: any // https://typeorm.io/find-options for more infos about querying
  skip?: number;
  take?: number;
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  baseApiRoot = environment.rootApiLog + 'log';

  constructor(private libService: LibService) { }

  find(queryParams: CreateQueryParams): Observable<Log[]>{
    const query = RequestQueryBuilder.create(queryParams).query()
    return this.libService.getData<Log[]>(this.baseApiRoot, `?${query}`).pipe(
      map((logs: Log[]) => {
        return logs.map((log: any) => {
          return {
            ...log,
            id : log.id[0]
          }
        })
      })
    )
  }

  writeLog(method: string, path: string, request?: any, response?: any) {
    let action = {
      method: method,
      path: path,
      params: request
    };
    let uname = 'not logged';
    if (this.libService.authService.user())
      uname = this.libService.authService.user().name;
    let log: Log = {
      userEmail: uname,
      timestamp: new Date(),
      action: JSON.stringify(action).substring(0,4096),
      response: response
    };

    const baseApiUrl = environment.rootApiLog + 'log';

    return this.libService.postData<any>(log, baseApiUrl, '').subscribe()

  }
}
