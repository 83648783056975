import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { OrderService } from '../services/order.service';
import { LibService } from '../services/libService';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSessionService } from '../services/user-session.service';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';

import { ElecSignService } from 'src/app/services/elec-sign.service';
import { Subject } from 'rxjs';

import { CompanyManagementService } from '../services/company-management.service'


const STATUS_DELIBER = 38;
const STATUS_CLOSED = 66;
const STATUS_EXECUTED = 24;
const STATUS_PROGRAMMED = 18;
const STATUS_PLANNED = 7;
const STATUS_EXECUTION = 13;
const STATUS_CREATA = 8;
const STATUS_PRESENTED = 37;
const STATUS_PRESENTED_OVER = 63;
const STATUS_NULLED = 22;
const STATUS_WARRANTY = 35;
const STATUS_WEND = 2;
const STATUS_PRE_DELIBER = 61;
const STATUS_PRE_DELIBER_OVER = 62;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  shortColumnsStructure = ['code', 'responsible', 'company', 'price', 'status', 'subjectId', 'buildingId', 'note', 'createdAt', 'editAction', 'goToFinalBalancer', 'deleteAction'];
  //longColumnsStructure = ['code', 'responsible', 'company', 'buildingId', 'status', 'valoreDeliberato', 'valoreProdotto', 'datoMLC', 'fatturato', 'incassato', 'dataUltimoSAL', 'editAction', 'goToFinalBalancer', 'deleteAction'];
  longColumnsStructure = ['code', 'responsible', 'company', 'buildingId', 'status', 'valoreDeliberato', 'valoreProdotto', 'fatturato', 'incassato', 'dataUltimoSAL', 'editAction', 'goToFinalBalancer', 'deleteAction'];

  displayedColumns = this.shortColumnsStructure;
  dataSource: any;
  eventsSubject: Subject<void> = new Subject<void>();
  kwSearch: any;
  title = "Pratiche";
  subTitle = "EDAC - Pratiche";
  user: any;
  dataSourceStandard: any;
  isOperational: boolean = false;
  isStaff: boolean = false;
  isAdmin: boolean = false;
  isFreeze: boolean = false;
  disabled: boolean = false;
  filterOptions: any = {};
  orderOptions: any = { active: 'createdAt', direction: 'desc' };
  orderIds: any = [];







  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;
  isLoading: boolean = false;
  filterCompanyCodes: Array<string> = []
  filterResponsibles: Array<string> = []
  buildingsSection: boolean = false;
  order: object = { name: "asc" };








  hasPagination = true;
  hasHeader = true;

  pageSizeOptions = [5, 10, 20];
  selected: any;


  currentPage = 0;

  companies: Array<any> = []

  companyIdsFilter: Array<any> = [];
  assignmentOperatorFilter: Array<any> = [];
  statusIdFilter: Array<any> = [];
  yearFilter: Array<any> = [];
  enableFinancialData: any = false;





  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;

  currentUrl: string;

  constructor(
    public navService: NavService,
    public orderService: OrderService,
    public libService: LibService,
    private router: Router,
    public translate: TranslatorService,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private elecSignService: ElecSignService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private companyManagementService: CompanyManagementService
  ) {
    this.dataSource = new MatTableDataSource();
  }


  ngDoCheck() {
    if (
      this.isOperational == false &&
      this.isStaff == false &&
      this.isAdmin == false) {
      if (!(this.userSessionService.getState('user') == null)) {
        for (let a = 0; a < this.userSessionService.getState('user').UsersProfilesRel.length; a++) {
          switch (this.userSessionService.getState('user').UsersProfilesRel[a].code) {
            case 'SLS-TC':
              this.isOperational = true;
              break;
            case 'PRD-CO':
              this.isOperational = true;
              break;
            case 'PRD-RA':
              this.isStaff = true;
              break;
            case 'AFF':
              this.isStaff = true;
              break;
            case 'HQ-AM':
              this.isAdmin = true;
              break;
            case 'HQ-BRD':
              this.isAdmin = true;
              break;
            default:
              break;
          }
          if (this.isAdmin == true) {
            let actions: any;
            actions = [
              { name: 'new', url: 'order/0' },
              //{ name: 'export', url: '' },
            ];
            this.navService.setActions(actions);
          }
        }
      }
    }
  }


  loadLabels() {
    this.translate.get('LABEL.ORDERS').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  getValue(element: any) {
    //console.log('order %o',element);
    if (element == null)
      return 0;
    if (element.realPrice > 0 || element.realPrice < 0)
      return element.realPrice;

    return 0;
  }

  getDataUltimoSAL(element: any) {
    let res = ""
    if (element.OrdersOrderRowsRel) {
      let allOPFABRel = element.OrdersOrderRowsRel.flatMap((row: any) =>
        row.OrderRowsOrderPhasesRel.flatMap((phase: any) =>
          phase.OPFABRel
        ))
      allOPFABRel.sort(function (x: any, y: any) {
        return y.ts - x.ts;
      })
      if (![null, undefined].includes(allOPFABRel[0])) {
        var date = new Date(allOPFABRel[0].ts * 1000);
        res = date.toISOString()
      }
    }
    return res
  }

  ngOnInit(): void {

    // AL_EDAC -> Recupera il valore del parametro "edacDev" passato nell'url per la visulizzazione in Dev di parti di codice in sviluppo.
    this.route.queryParams
      .subscribe((params: any) => {
        if (params.edacDev) {
          this.enableFinancialData = [true, 'true'].includes(params.edacDev) ? true : false;
        }
      }
      );

    this.companies = this.getUserCompanies()

    console.log('initUserCompaniesForFilter')
    this.filterOptions = this.initFilters()

    this.user = this.userSessionService.getState('user');
    console.log('this.user %o', this.user);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      // { name: 'new', url: 'order/0' },
      //{ name: 'export', url: '' },
    ];

    this.navService.setActions(actions);
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

    this.loadPractices() // Caricamento delle pratiche
  }

  getUserCompanies(): Array<any> {
    let companies = [];
    let us = this.userSessionService.getState('user');

    if (!(us == null) && !(us.UsersCompaniesRel == null)) {
      for (let a = 0; a < us.UsersCompaniesRel.length; a++) {
        companies.push({
          isOpen: us.UsersCompaniesRel[a].isOpen,
          name: us.UsersCompaniesRel[a].name,
          code: us.UsersCompaniesRel[a].code,
          id: us.UsersCompaniesRel[a].id
        });
      }
    }
    return companies;
  }

  getClassRow(id: any) {
    let ret = 'tdg ';
    switch (id) {
      case STATUS_DELIBER:
        {
          ret = ret + 'row-ok';
          break;
        }
      case STATUS_PRE_DELIBER:
        {
          ret = ret + 'row-wrn';
          break;
        }
      case STATUS_PRE_DELIBER_OVER:
        {
          ret = ret + 'row-wrn';
          break;
        }
      case STATUS_PROGRAMMED:
        {
          ret = ret + 'row-ok';
          break;
        }
      case STATUS_CLOSED:
        {
          ret = ret + 'row-tok';
          break;
        }
      case STATUS_PLANNED:
        {
          ret = ret + 'row-ok';
          break;
        }
      case STATUS_EXECUTION:
        {
          ret = ret + 'row-ok';
          break;
        }
      case STATUS_EXECUTED:
        {
          ret = ret + 'row-ok';
          break;
        }
      case STATUS_CREATA:
        {
          ret = ret + 'row-to-work';
          break;
        }
      case STATUS_PRESENTED:
        {
          ret = ret + 'row-to-work';
          break;
        }
      case STATUS_WARRANTY:
        {
          ret = ret + 'row-to-work';
          break;
        }
      case STATUS_WEND:
        {
          ret = ret + 'row-ok';
          break;
        }
      case STATUS_PRESENTED_OVER:
        {
          ret = ret + 'row-to-work';
          break;
        }
      case STATUS_NULLED:
        {
          ret = ret + 'row-ko';
          break;
        }
      default:
        {
          ret = ret + 'row-to-work';
          break;
        }
    }

    return ret;
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch });
  }

  goTo(e: any) {
    this.router.navigate(['order/' + e.code]);
  }

  goToFinalBalancer(e: any) {

    if (e.Status.isConsuntivable) {

      let user = this.userSessionService.getState('user');
      let workingCompany = this.userSessionService.getState('working_company');

      // Setta la company della pratica
      if (workingCompany == null || workingCompany.id != e.idSede) {
        let tmp_company = user.UsersCompaniesRel.find((c: any) => c.id == e.idSede);
        let res = {
          isOpen: tmp_company.isOpen,
          name: tmp_company.name,
          code: tmp_company.code,
          id: tmp_company.id,
          country: tmp_company?.country
        }
        this.userSessionService.saveState('working_company', res);

        this.companyManagementService.add(res);

      }

      // Va alla sezione consuntivazione della pratica
      this.router.navigate(['final-balancer/' + e.code + '/' + e.version]);
    }
  }

  goToT(e: any) {
    this.elecSignService.getFileExample().subscribe(f => {
      console.log('file for test %o', f);
      this.elecSignService.postFile('prova.pdf', f).subscribe(
        resFile => {
          console.log('response post file for elec_Sign %o', resFile);
        }
      );
    });
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  updateList(e: any) {
    console.log("EVENTO AGGIORNAMENTO LISTA --> ", e);
    console.log("DATASOURCE --> ", this.dataSource);
    console.log("DATASOURCE STANDARD --> ", this.dataSourceStandard);

    if (e.length > 0) {
      this.orderIds = e.map((o: any) => o.id)
      this.resetPagination()
      this.loadPractices()
      this.disabled = false;
      this.cdr.detectChanges();

    } else {
      // this.dataSourceStandard !== null && this.dataSourceStandard !== undefined ?
      this.orderIds = []
      this.loadPractices()

      this.disabled = false;
      this.cdr.detectChanges();
    }
  }

  getFilterSelectionEmit(event: any) {
    console.log(event)
    this.filterOptions = event
    this.resetPagination()
    this.loadPractices()
  }

  filterResultEmitted(event: { data: Request[], clear: boolean }) {
    if (event.clear !== true) {
      console.log("EVENTO LISTA --> ", event);
      this.dataSource.data = event.data
      this.paginator.length = event.data.length;
      this.dataSource.paginator = this.paginator
      !(event.data.length > 0) ? this.toastr.warning(this.translate.instant('TOASTR.MESSAGE_2')) :
        console.log("");
    } else {
      this.loadPractices()
    }
  }

  clearList(e: any) {
    console.log("CANCELLA --> ", e);
    if (e) {
      this.orderIds = []
      this.loadPractices()
    }
  }

  getOrRefreshToken() {
    // Recupera l'id token dell'utente loggato
    let id_token = this.userSessionService.getIdToken();

    // Verifica se l'id token è valido, altrimento lo rinnova
    if (new Date(this.userSessionService.getIdTokenExpiresAt()) < new Date()) {
      this.authService.refreshToken().then((res) => {
        id_token = res.id_token
      }).finally(() => console.log('end refreshToken'))
      return id_token
    } else {
      return id_token
    }

  }

  loadPractices() {

    let id_token = this.getOrRefreshToken()

    this.libService.lockPage('');

    let user = {
      email: this.user.email,
      id: this.user.id,
      companyIds: this.companies.map((company: any) => company.id)
    }

    this.filterOptions['orderIds'] = []
    if (this.orderIds.length > 0) {
      console.log(this.orderIds)
      this.filterOptions['orderIds'] = this.orderIds
    }
    
    this.orderService.getOrdersWithPagination(id_token, this.filterOptions, this.orderOptions, this.pageInit, this.pageSize, this.enableFinancialData).subscribe((responseData: any) => {

      this.totalRows = responseData.count;
      this.libService.unlockPage();
      this.dataSource.data = responseData.rows
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.libService.unlockPage();
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });

  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.resetPagination()
    this.loadPractices()

  }

  resetPagination() {
    this.currentPage = 0;
    this.pageInit = 0;
  }


  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadPractices()
  }

  updateSortData(event: any) {
    console.log('updateSortData')
    console.log(event)
  }

  getSede(id: any) {
    for (let a = 0; a < this.companies.length; a++) {
      if (id == this.companies[a].id)
        return (this.companies[a]);
    }
    return this.companies[0];
  }

  initFilters() {
    let filters = this.userSessionService.getState('OrderFilters')
    if (![null, undefined].includes(filters)) {
      Object.keys(filters).forEach(key => {
        switch (key) {
          case 'company':
            this.companyIdsFilter = filters[key].map((company: any) => company.id)
            break;
          case 'assignmentOperator':
            this.assignmentOperatorFilter = filters[key]
            break;
          case 'statusId':
            this.statusIdFilter = filters[key].map((status: any) => status.id)
            break;
          case 'year':
            this.yearFilter = filters[key]
            break;
          default:
            console.log(filters[key]);
        }
      });
      return {
        company: this.companyIdsFilter,
        year: this.yearFilter,
        assignmentOperator: this.assignmentOperatorFilter,
        statusId: this.statusIdFilter
      }
    } else {
      return {}
    }
  }

  changeColumsStructure(event: any) {
    if (event.checked) {
      this.displayedColumns = this.longColumnsStructure
    } else {
      this.displayedColumns = this.shortColumnsStructure
    }


  }

}