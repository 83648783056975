import { Injectable, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class CompanyManagementService {
    public itemAdded$: EventEmitter<any>;
    private company: any = null;

    constructor() {
        this.itemAdded$ = new EventEmitter();
    }

    public add(item: any): void {
        this.company = item;
        this.itemAdded$.emit(item);
    }
}