import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-type-radio',
  templateUrl: './type-radio.component.html',
  styleUrls: ['./type-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TypeRadioComponent,
      multi: true
    }
  ],
})
export class TypeRadioComponent implements ControlValueAccessor {

  type: string;
  @Input()disabled: boolean;
  constructor() { }

  onChange = (values: any) => {}

  writeValue(value: any): void {
    console.log('writeValue');

    let typesVisualized = ['Edificio', 'Amministratore', 'Condominio']
    if(typesVisualized.includes(value)){
      this.type = value
    }else{
      this.type = 'Edificio'
    }
    console.log("TIPOIN TYPE RADIO COMPONENT --> ", this.type);
    // this.onChange(this.type);
  }

  registerOnChange(fn: any): void {
    console.log('registeredOnchange');

    this.onChange = fn
  }

  registerOnTouched(fn: any): void {

  }

  onValueChange(event: any){
    this.onChange(event.value)
  }

  setDisabledState?(isDisabled: boolean): void {
    console.log('setDisabledState ---------------------------------->');

    this.disabled = isDisabled;
  }
}
