
<div class="tab-pane fade show active" id="kt_user_view_overview_tab" role="tabpanel">
  <div class="card card-flush mb-6 mb-xl-9">
    <div class="card-header mt-6" *ngIf="userArea">
      <div class="card-title flex-column">
        <h2 class="mb-1" >{{ 'LABEL.USER_TASKS' | translate }} {{ requests? "(" + requests?.length + ")": undefined }}</h2>
        <!-- <div class="fs-6 fw-bold text-muted">{{ 'LABEL.TOTAL' | translate }}: {{ requests?.length }} {{ 'LABEL.BACKLOG_TASKS' | translate }}</div> -->
      </div>
      <div class="card-toolbar">
      </div>
    </div>

    <div ><!-- class="card-body d-flex flex-column" -->
      <div class="container-fluid">

        <div style="overflow-x: auto; border-radius: 3%;">
          <table mat-table [dataSource]="dataSource" matSort style="width: 100%;" matSortActive="createdAt" matSortDirection="desc">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; background-color: white; color: #293683;" class="">{{ 'LABEL.ID' | translate }}</th>
              <td  mat-cell *matCellDef="let element" style="padding: 0; width: auto; text-align: center;" (click)="goTo(element)">
                {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="contactReason">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; background-color: white; color: #293683;" class="back-table centered">{{ 'LABEL.CONTACT_REASON' | translate }}</th>
              <td class="tdg" mat-cell *matCellDef="let element" style="padding: 1; width: auto; " (click)="goTo(element)"> <span>{{ element.contactReason?.name !== null && element.contactReason?.name  !== undefined? element.contactReason?.name: element.reason + ' - ' + element.type }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef mat-sort-header  style="font-size: 1.1em; background-color: white; color: #293683;" class="back-table centered">{{ 'ACTIVITIES.SUBJ' | translate }} / {{ 'LABEL.ORDER' | translate }}</th>
              <td class="tdg" mat-cell *matCellDef="let element" style="padding: 1; width: auto; " (click)="goTo(element)"> <span><b>{{ element.subject !== null && element.subject !== undefined? element.subject?.name + ' ' + element.subject.surname: element.orderCode }}</b></span>
              </td>
            </ng-container>

            <ng-container matColumnDef="building">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; background-color: white; color: #293683;;" class="back-table centered">{{ 'LABEL.BUILDING' | translate }}</th>
              <td class="tdg" mat-cell *matCellDef="let element" style="padding: 1; width: auto; " (click)="goTo(element)"> <span>{{ element.building? element.building.streetName + ', ' + element.building.streetNumber + ', ' + element.building.city: undefined }}</span></td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; background-color: white; color: #293683;" class="">{{ 'LABEL.CREATION_DATE' | translate }}</th>
              <td  mat-cell *matCellDef="let element" style="padding: 0; width: auto; text-align: center;" (click)="goTo(element)">
                {{element.createdAt | date}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="editAction">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" (click)="goTo(element)"><i class="material-icons icsave">edit</i></td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
        </mat-paginator>
      </div>

    </div>
  </div>
</div>
