<div *ngIf="loading" class="loading-container">
    <mat-spinner></mat-spinner> <!-- 🔄 Indicatore di caricamento -->
</div>
<div *ngIf="!loading">
    <div *ngIf="invalidCompanySelected" class="invalid-company-message">
        <h2>⚠️ Seleziona una sede per continuare</h2>
    </div>

    <div *ngIf="!invalidCompanySelected" class="container">
        <div class="intro-container">
            <h2>Consuntivatore Dubai</h2>
            <p>
                In questa sezione puoi assegnare le commesse ai capi squadra tramite il meccanismo di
                <strong>drag & drop</strong>.
                Trascina una commessa dalla lista delle commesse e rilasciala sopra un capo squadra per assegnarla.
                Puoi rimuovere una commessa assegnata cliccando sull'icona
                <mat-icon class="inline-icon">close</mat-icon>.
            </p>
        </div>
        <div class="grid-layout">

            <!-- 📋 Lista Capi Squadra -->
            <div class="team-container">
                <div class="search-wrapperr">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Cerca team leader..." [(ngModel)]="searchTeamLeader">
                    </mat-form-field>

                    <button mat-icon-button matTooltip="Puoi cercare per nome o codice fiscale."
                        class="info-icon-absolute">
                        <mat-icon fontIcon="info" class="info-icon-small"></mat-icon>
                    </button>
                </div>



                <div *ngFor="let leader of filteredTeamLeaders" class="team-leader-box">
                    <mat-card>
                        <mat-card-title>
                            {{ sanitizeValue(leader.name, 'Soggetto Mancante') }}
                            ({{ sanitizeValue(leader.CodiceFiscale, 'Codice Fiscale Mancante') }})
                        </mat-card-title>
                        <mat-card-content>
                            <h4>Commesse assegnate:</h4>
                            <div cdkDropList id="leader-{{ leader.Id }}" [cdkDropListData]="leader.projects"
                                [cdkDropListConnectedTo]="connectedLists" (cdkDropListDropped)="drop($event, leader)"
                                class="drop-container">

                                <div *ngFor="let project of leader.projects" cdkDrag class="drag-item">
                                    <p><strong>{{ sanitizeValue(project.code) }}</strong> -
                                        {{ sanitizeValue(project.nameSubject, 'Soggetto Mancante') }} -
                                        {{ sanitizeValue(project.nameBuilding, 'Indirizzo mancante') }}
                                    </p>

                                    <button class="remove-btn" (click)="removeProject(leader, project)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>


                <mat-paginator #paginatorLeaders [length]="totalLeaders" [pageSize]="pageSizeLeaders"
                    [pageSizeOptions]="[5, 10, 15]" (page)="updatePaginationLeaders($event)">
                </mat-paginator>
            </div>

            <!-- 📋 Lista Commesse -->

            <div class="project-container">
                <div class="search-wrapperr">
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Cerca commessa..." [(ngModel)]="searchProject"
                            (ngModelChange)="onSearchChange($event)">
                    </mat-form-field>

                    <button mat-icon-button class="info-icon-absolute"
                        [matTooltip]="'Puoi cercare per codice commessa, soggetto o indirizzo'">
                        <mat-icon fontIcon="info" class="info-icon-small"></mat-icon>
                    </button>
                </div>


                <mat-card class="projects-container">
                    <mat-card-title>Elenco Commesse</mat-card-title>
                    <mat-card-content>
                        <!-- 🔄 Mostra il loading se isLoadingProjects è true -->
                        <div *ngIf="isLoadingProjects" class="loading-container-project">
                            <mat-spinner diameter="40"></mat-spinner>
                        </div>

                        <!-- ✅ Mostra i risultati solo quando isLoadingProjects è false -->
                        <div *ngIf="!isLoadingProjects">
                            <div cdkDropList #projectList="cdkDropList" id="projectList"
                                [cdkDropListData]="filteredProjects" [cdkDropListConnectedTo]="connectedLists"
                                (cdkDropListDropped)="drop($event)" class="drop-container">

                                <div *ngFor="let project of filteredProjects" cdkDrag class="drag-item">
                                    <p><strong>{{ sanitizeValue(project.code) }}</strong> -
                                        {{ sanitizeValue(project.nameSubject, 'Soggetto Mancante') }} -
                                        {{ sanitizeValue(project.nameBuilding, 'Indirizzo mancante') }}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-paginator #paginatorProjects [length]="totalProjects" [pageSize]="pageSizeProjects"
                    [pageSizeOptions]="[5, 10, 15]" (page)="updatePaginationProjects($event)">
                </mat-paginator>
            </div>
        </div>
    </div>


</div>