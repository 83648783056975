<mat-expansion-panel appearance="fill" #accordion >
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ 'ADDRESS.DETAIL' | translate }}
      <mat-icon *ngIf="addressValid === 'red'" class="error">error_outline</mat-icon>
      <mat-icon *ngIf="addressValid === 'green'" class="green">check_circle_outline</mat-icon>
      <mat-icon *ngIf="addressValid === 'yellow'" class = "warning">error_outline</mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <form [formGroup]="form" >
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between center" >
      <div fxFlex="100" *ngIf="formattedAddress">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.FULL' | translate }}</mat-label>
          <input matInput type="text" formControlName="formattedAddress" [readonly]="true">
        </mat-form-field>
      </div>
      <div fxFlex="75">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.STREETNAME' | translate }}</mat-label>
          <input matInput type="text" formControlName="streetName" [readonly]="disabled">
        </mat-form-field>
      </div>
      <div fxFlex="20">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.NUMBER' | translate }}</mat-label>
          <input matInput type="text" formControlName="streetNumber" [readonly]="disabled">
        </mat-form-field>
      </div>
      <div fxFlex="33">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.CITY' | translate }}</mat-label>
          <input matInput type="text" formControlName="city" [readonly]="disabled">
        </mat-form-field>
      </div>
      <div fxFlex="33">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.PROVINCE' | translate }}</mat-label>
          <input matInput type="text" formControlName="province" [readonly]="disabled">
        </mat-form-field>
      </div>

      <div fxFlex="33">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.ZIP' | translate }}</mat-label>
          <input matInput type="text" formControlName="zip" [readonly]="disabled">
        </mat-form-field>
      </div>

      <div fxFlex="50" *ngIf="neighborhood">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.NEIGHBORHOOD' | translate }}</mat-label>
          <input matInput type="text" formControlName="neighborhood" [readonly]="disabled">
        </mat-form-field>
      </div>

      <div fxFlex="49" *ngIf="sublocality">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.SUBLOCALY' | translate }}</mat-label>
          <input matInput type="text" formControlName="sublocality" [readonly]="disabled">
        </mat-form-field>
      </div>

      <div fxFlex="50" *ngIf="state">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.REGION' | translate }}</mat-label>
          <input matInput type="text" formControlName="state" [readonly]="disabled">
        </mat-form-field>
      </div>
      
      <div fxFlex="49">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>{{ 'ADDRESS.STATE' | translate }}</mat-label>
          <input matInput type="text" formControlName="country" [readonly]="disabled">
        </mat-form-field>
      </div>


    </div>
  </form>
</mat-expansion-panel>
