import { Task } from 'src/app/final-balancer/models/final-balance';
import { GanttTask } from './gantt';
import { UserRoleUtils } from './userRole';

export class TeamLeaderTask implements GanttTask {
  id: number;
  start_date: Date;
  text: string;
  progress: number;
  duration: number;
  parent: number = 0;
  userRoleId: number;
  name: string;
  class: string = 'parent-task';

  public get level(): string {
    return UserRoleUtils.defineLabel(this.userRoleId);
  }

  constructor(task: Task, startDate: string) {
    this.id = task.userId;
    this.userRoleId = task.userRoleId;
    this.name = task.userName;
    this.text = `${task.userName} - ${this.level}`;
    this.start_date = new Date(Number(startDate) * 1000);
  }
}
