import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  Inject
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubjectService } from 'src/app/services/subject.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/auth/auth.service';
import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-add-users-modal',
  templateUrl: './add-users-modal.component.html',
  styleUrls: ['./add-users-modal.component.scss'],
})
export class AddUsersModal implements OnInit {
  @Output() closeModal = new EventEmitter();
  sort: MatSort;
  users: any = [];
  actions: any = [];

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.users.sort = sort;
    this.sort = sort;
  }

  @ViewChild('itemDialog') itemDialog: TemplateRef<any>

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  usersSelected: any = [];
  searchTimeout: any;

  kwSearchUser: any;

  localData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public subjectService: SubjectService,
    public orderService: OrderService,
    public authService: AuthService,
  ) {
    if (data) {
      console.log(data)
      this.localData = data
    }
  }

  displayedColumns = [
    'fullname',
    'email',
  ];

  otherDisplayedColumns = [
    'company',
    'createdAt',
    'updatedAt',
    'canceledAt',
    'approvalStatus',
  ]


  getFullname(item: any) {
    return `${item?.user?.name || ''} ${item?.user?.surname || ''}`;
  }

  columns: any = [
    {
      name: 'fullname',
      title: this.getTitle('fullname'),
      cssClass: (item: any) => [
        'column-padding',
        `${item?.canceledAt ? 'notApproval' : ''}`,
      ],
      value: (item: any) => this.getFullname(item),
    },
    {
      name: 'email',
      title: this.getTitle('email'),
      cssClass: (item: any) => [
        'column-padding',
        `${item?.canceledAt ? 'notApproval' : ''}`,
      ],
      value: (item: any) => item?.user?.email,
    },
  ];

  otherColumns: any = [
    {
      name: 'company',
      title: this.getTitle('company'),
      cssClass: (item: any) => [
        'column-padding',
        `${item?.canceledAt ? 'notApproval' : ''}`,
      ],
      value: (item: any) => item?.company?.name,
    },
    {
      name: 'createdAt',
      title: this.getTitle('createdAt'),
      cssClass: (item: any) => [
        'column-padding',
        `${item?.canceledAt ? 'notApproval' : ''}`,
      ],
      value: (item: any) => item?.createdAt,
    },
    {
      name: 'updatedAt',
      title: this.getTitle('updatedAt'),
      cssClass: (item: any) => [
        'column-padding',
        `${item?.canceledAt ? 'notApproval' : ''}`,
      ],
      value: (item: any) => item.updatedAt,
    },
    {
      name: 'canceledAt',
      title: this.getTitle('canceledAt'),
      cssClass: (item: any) => [
        'column-padding',
        `${item?.canceledAt ? 'notApproval' : ''}`,
      ],
      value: (item: any) => item.canceledAt,
    },
    {
      name: 'approvalStatus',
      title: this.getTitle('approvalStatus'),
      cssClass: (item: any) => [
        'column-padding',
        `${item?.canceledAt ? 'notApproval' : ''}`,
      ],
      value: (item: any) => '',
    },
  ]

  selectPrimary(item: any) {
    let status = this.usersSelected.find((user: any) => user.id === item?.user?.id)
    if (status) {
      this.usersSelected = this.usersSelected.filter((user: any) => user.id !== item?.user?.id);
    } else {
      this.usersSelected.push(
        {
          id: item?.user.id,
          name: item?.user.name,
          surname: item?.user.surname,
          email: item?.user.email,
          type: item.type
        }
      );
    }
  }

  setUserActions() {
    this.actions.push({
      label: of(''),
      icon: (item: any) => {
        let unchecked = 'radio_button_unchecked';
        let checked = 'radio_button_checked';
        let currentIcon = this.usersSelected.find((user: any) => user.id === item?.user?.id)
          ? checked
          : unchecked;
        return currentIcon;
      },
      onClick: (item: any) => this.selectPrimary(item),
      color: 'primary',
    });
  }

  getTitle(type: any) {
    let title: any = this.translate.get('LABEL.NOT_PRESENT');
    switch (type) {
      case 'type':
        title = this.translate.get('LABEL.type');
        break;
      case 'fullname':
        title = this.translate.get('LABEL.fullname');
        break;
      case 'name':
        title = this.translate.get('LABEL.name');
        break;
      case 'surname':
        title = this.translate.get('LABEL.surname');
        break;
      case 'email':
        title = this.translate.get('LABEL.email');
        break;
      case 'company':
        title = this.translate.get('LABEL.company');
        break;
      case 'createdAt':
        title = this.translate.get('LABEL.createdAt');
        break;
      case 'updatedAt':
        title = this.translate.get('LABEL.updatedAt');
        break;
      case 'canceledAt':
        title = this.translate.get('LABEL.canceledAt');
        break;
      case 'approvalStatus':
        title = this.translate.get('LABEL.approvalStatus');
        break;
      case 'lunch':
        title = this.translate.get('LABEL.lunch');
        break;
      case 'intoleranceDetails':
        title = this.translate.get('LABEL.intoleranceDetails');
        break;
      case 'qrcode':
        title = this.translate.get('LABEL.qrcode');
        break;
    }
    return title;
  }

  ngAfterViewInit(): void {
    if (this.localData) {
      if (this.localData.users) this.users = this.localData.users
      if (!this.localData.global) {
        this.displayedColumns = this.displayedColumns.concat(this.otherDisplayedColumns)
        this.columns = this.columns.concat(this.otherColumns)
      }
    }

  }


  ngOnInit(): void {
    this.setUserActions();
  }

  searchUser(e: any) {
    if (e) {
      this.users = this.users.filter((item: any) => {
        return (
          item?.type?.toLowerCase().includes(e.toLowerCase()) ||
          item?.user?.name?.toLowerCase().includes(e.toLowerCase()) ||
          item?.user?.surname?.toLowerCase().includes(e.toLowerCase()) ||
          item?.user?.email?.toLowerCase().includes(e.toLowerCase()) ||
          item?.company?.name?.toLowerCase().includes(e.toLowerCase()) ||
          item?.company?.city?.toLowerCase().includes(e.toLowerCase())
        );
      });
    }

    clearTimeout(this.searchTimeout);
  }

}
