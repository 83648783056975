export const statusChanges = {
    changes: [
        {
            from: 38,
            to: 18
        },
        {
            from: 7,
            to: 13
        },
        {
            from: 37,
            to: 61
        },
        {
            from: 18,
            to: 7
        },
        {
            from: 13,
            to: 24
        },
        {
            from: 63,
            to: 62
        },
        {
            from: 24,
            to: 66
        },
        {
            from: 13,
            to: 11
        },
        {
            from: 11,
            to: 13
        },
        {
            from: 61,
            to: 38
        },
        {
            from: 62,
            to: 38
        },
        {
            from: 64,
            to: 38
        },
        {
            from: 65,
            to: 38
        },
        {
            from: 2,
            to: 13
        },
        {
            from: 2,
            to: 24
        }
    ]

}