import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhaseService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
  ) { }

  createPhase(phase: any): Observable<any> {
    return this.libService.postData<any>(phase, this.baseApi, 'phases');
  }

  updatePhase(phase: any): Observable<any> {
    return this.libService.putData<any>(phase, this.baseApi, `phases/${phase.id.toString()}`);
  }

  deletePhase(phase: any): Observable<any> {
    return this.libService.deleteData<any>(phase, this.baseApi, `phases/${phase.id.toString()}`);
  }

  getAllPhases(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, 'phases/');
  }

  getPhase(id: string) {
    return this.libService.getData<any>(this.baseApi, `phases/${id.toString()}`);
  }

  getPhasesByLangCode(code:string){
    return this.libService.getData<any>(this.baseApi, `phases/langcode/${code.toString()}`);
  }
}