import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs';
import { NavService } from '../services/nav.service';
import { WikiService } from '../services/wikiservice.service';
import { ActionService } from '../services/action.service';
import { Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-customer-relationship-management',
  templateUrl: './customer-relationship-management.component.html',
  styleUrls: ['./customer-relationship-management.component.scss']
})
export class CustomerRelationshipManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "CRM";
  subTitle = "EDAC - CRM";
  actions: any;
  selectedTabIndex: number = 0
  selectedSubTabIndex: number = 0
  selectedSubSubTabIndex: number = 0
  itemId: any = null
  sectionType: any = 'subjects'
  private subRoute: Subscription;


  tabIndex$ = this.route.paramMap.pipe(
    map(params => {
      return params.get('tabName')
    }),
    tap(tabName => {
      switch (tabName) {
        case 'subjects':
          this.selectedTabIndex = 0
          break;
        case 'buildings':
          this.selectedTabIndex = 1
          break;
        case 'requests':
          this.selectedTabIndex = 2
          break;
        case 'activities':
          this.selectedTabIndex = 3
          break;
        case 'rapports':
          this.selectedTabIndex = 4
          break;

        default:
          this.selectedTabIndex = 0
          break;
      }

      this.setActions(this.selectedTabIndex)
    })
  )

  subTabIndex$ = this.route.queryParamMap.pipe(
    map(params => {
      return params.get('subTabName')
    }),
    tap(tabName => {
      switch (tabName) {
        case 'reviews':
          this.selectedSubTabIndex = 0
          break;
        case 'solicits':
          this.selectedSubTabIndex = 1
          break;
        case 'grievances':
          this.selectedSubTabIndex = 2
          break;
        case 'complaints':
          this.selectedSubTabIndex = 3
          break;
        default:
          this.selectedSubTabIndex = 0
          break;
      }

      // this.setActions(this.selectedTabIndex)
    })
  )

  subSubTabIndex$ = this.route.queryParamMap.pipe(
    map(params => {
      return params.get('subSubTabName')
    }),
    tap(tabName => {
      switch (tabName) {
        case 'pecs':
          this.selectedSubSubTabIndex = 0
          break;
        case 'warranty':
          this.selectedSubSubTabIndex = 1
          break;
        case 'claims':
          this.selectedSubSubTabIndex = 2
          break;
        case 'legals':
          this.selectedSubSubTabIndex = 3
          break;
        case 'transactions':
          this.selectedSubSubTabIndex = 4
          break;
        default:
          this.selectedSubSubTabIndex = 0
          break;
      }

      // this.setActions(this.selectedTabIndex)
    })
  )

  itemId$ = this.route.queryParamMap.pipe(
    map(params => {
      return params.get('itemId')
    }),
    tap(itemId => {
      this.itemId = itemId || null
    })
  )

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    public wikiService: WikiService,
    private cdr: ChangeDetectorRef,
    private actionService: ActionService
  ) { }

  goToWiki() {
    window.open(this.wikiService.getWikiUrl(this.navService.getWikiKey()));
  }

  checkPermissions(itemType: any, actionType: any) {
    return this.actionService.checkPermissions(`${itemType}_${actionType}`)
  }

  goToWikiByKey(k: any) {
    window.open(this.wikiService.getWikiUrl(k));
  }

  ngOnInit(): void {
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);

    this.actions = [
    ];
    console.log("ACTIONS --> ", this.actions)
    this.navService.setActions(this.actions);
    /* console.log("INIT INDEX --> ", this.tabGroup.selectedIndex); */
    this.navService.setWikiKey('CRM');

    this.subRoute = this.route
      .data
      .subscribe((v: any) => {
        if ('sectionType' in v) this.sectionType = v.sectionType
      });

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.tabIndex$.subscribe()
    this.subTabIndex$.subscribe()
    this.subSubTabIndex$.subscribe()
    this.itemId$.subscribe()
    this.navService.setActions(this.actions);
    this.cdr.detectChanges();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    /* console.log('tabChangeEvent --> ', tabChangeEvent); */
    /* console.log('index --> ', tabChangeEvent.index); */
    this.selectedTabIndex = tabChangeEvent.index
    this.setActions(tabChangeEvent.index)

    this.navService.setActions(this.actions);
    this.cdr.detectChanges();
  }

  setActions(index: any = 0) {
    if (index !== (null || undefined)) {
      if (index === 0) {
        this.actions = [
          // { name: 'export', url: '' },
          { name: 'new', url: 'subjects/0', params: { type: 'Soggetto' } }
        ];
      }
      if (index === 1) {
        this.actions = [
          // { name: 'export', url: '' },
          { name: 'new', url: 'buildings/0' }
        ];
      }
      if (index === 2) {
        this.actions = [
          // { name: 'export', url: '' },
          {
            type: 'dynamic',
            icon: 'faFileMedical',
            name: 'newRequest',
            url: 'request/0', queryParams: { type: 1 }
          }
        ];
      }
      if (index === 3) {
        this.actions = [
          // { name: 'export', url: '' },
          { name: 'new', url: 'activity/0' }
        ];
      }
    }
  }

  ngOnDestroy(): void {
    this.subRoute.unsubscribe();
  }

}
