import {
  OrderPhases,
  OrderRows,
  Orders,
  Task,
} from 'src/app/final-balancer/models/final-balance';
import { parseUnixTimestamp } from 'src/app/final-balancer/utils/date-helper';
import { OrderStatus } from './orderStatus-enum';
import { PhasePlan } from './phase-plan';
import { RowPlan } from './row-plan';

export class OrderPlan {
  public id: number;
  public code: string;
  public who: string;
  public country: string;
  public city: string;
  public streetName: string;
  public nameSubject: string;
  public nameAdminCondominius: string;
  public idStatus: number;
  public status: string;
  public statusClass: string;
  public isMultiservice: boolean;
  public timeStart: string;
  public timeEnd: string;
  public tsStart: number;
  public tsEnd: number;

  public rows: RowPlan[];

  get phases(): PhasePlan[] {
    return this.rows.flatMap((row: RowPlan) => row.phases);
  }


  public idSede: any;


  get tasks(): Task[] {
    return this.phases.flatMap((phase: PhasePlan) => phase.tasks);
  }

  get sortedTasks(): Task[] {
    return this.tasks.sort((a, b) => Number(a.tsStart) - Number(b.tsStart));
  }

  get hasTasks(): boolean {
    return this.tasks.length > 0;
  }

  get plannedHours(): number {
    if (!this.phases.length) return 0;
    return this.phases
      .map((phase) => phase.qtOreManodopera)
      .reduce((a, b) => a + b);
  }

  get totalHours(): number {
    if (!this.tasks.length) return 0;
    return (
      this.tasks?.map((task) => task.amountHours)?.reduce((a, b) => a + b) ?? 0
    );
  }

  get hoursToPlan(): number {
    const hoursToPlan = Math.floor(this.plannedHours - this.totalHours);
    return hoursToPlan > 0 ? hoursToPlan : 0;
  }

  constructor({
    id,
    code,
    who,
    country,
    city,
    streetName,
    nameSubject,
    nameAdminCondominius,
    idStatus,
    isMultiservice,
    OrdersOrderRowsRel,
    idSede
  }: Orders) {
    this.id = id;
    this.code = code;
    this.who = who;
    this.country = country;
    this.city = city;
    this.streetName = streetName;
    this.nameSubject = nameSubject;
    this.nameAdminCondominius = nameAdminCondominius;
    this.idStatus = idStatus;
    this.isMultiservice = isMultiservice;
    this.rows = OrdersOrderRowsRel.map((row) => new RowPlan(row));
    const { status, statusClass } = defineStatus(idStatus);
    this.status = status;
    this.statusClass = statusClass;
    this.idSede = idSede

    if (this.sortedTasks.length) {
      const tsStart = Number(this.sortedTasks[0]?.tsStart);
      const tsEnd = Number(
        this.sortedTasks[this.sortedTasks.length - 1]?.tsEnd
      );
      this.timeStart = parseUnixTimestamp(tsStart);
      this.timeEnd = parseUnixTimestamp(tsEnd);
      this.tsStart = tsStart;
      this.tsEnd = tsEnd;
    }
  }
}

export class OrderSelect {
  public id: number;
  public idStatus: number;
  public name: string;
  public orderData?: any;

  constructor(order: OrderPlan) {
    this.id = order.id;
    this.idStatus = order.idStatus;
    this.name = `${defineStatus(order.idStatus)['status']} - ${order.code} - ${
      order.streetName
    }`;
    this.orderData = order;
  }
}

export function defineStatus(idStatus: number): {
  status: string;
  statusClass: string;
} {
  switch (idStatus) {
    case OrderStatus.progg: {
      return { status: 'Programmata', statusClass: 'progg' };
    }
    case OrderStatus.plan: {
      return { status: 'Pianificata', statusClass: 'plan' };
    }
    case OrderStatus.esec: {
      return { status: 'In esecuzione', statusClass: 'esec' };
    }
    case OrderStatus.deliberate: {
      return { status: 'Deliberata', statusClass: 'delib' };
    }
    case OrderStatus.guarantee: {
      return { status: 'Garanzia accetta', statusClass: 'garancy' };
    }
    default: {
      return { status: 'Nessuno', statusClass: 'noStatus' };
    }
  }
}
