import {Injectable} from "@angular/core";
import {LinkV2} from "../models/linkv2";

@Injectable()
export class Linkv2Service {
    get(): Promise<LinkV2[]> {
        return Promise.resolve([
            {id: 1, source: 1, target: 2, type: "0"}
        ]);
    }
}
