import { DeleteConfirmationComponent } from './../dialogs/delete-confirmation/delete-confirmation.component';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';
import { TaxStepService } from '../services/taxstep.service';
import { TranslatorService } from '../services/translate.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-taxsteps',
  templateUrl: './taxsteps.component.html',
  styleUrls: ['./taxsteps.component.scss']
})
export class TaxstepsComponent implements OnInit {

  displayedColumns = ['id', 'percStep', 'percIncrement','percIncrementFranchising','createdAt','updatedAt','editAction','deleteAction'];
  dataSource: any;
  kwSearch: any;
 
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  constructor(public dialog:MatDialog, 
    public translate: TranslatorService, 
    public navService: NavService, 
    private taxStepService: TaxStepService, 
    public libService: LibService,
    private toastService: ToastrService, 
    private router: Router) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.loadTaxSteps();
  }

  goTo(e: any) {
    this.router.navigate(['fiscalsteps/' + e]);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  preventFormSubmit($event: Event) {
    $event.preventDefault();
  }
  
  create() {
    this.router.navigate(['fiscalsteps/0']);
  }

  deleteTaxStep(id: any){
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {data: `${this.translate.instant('GEN_CONFIG.CREDIT_CESSION')} - id: ${id}`})
    dialogRef.afterClosed().subscribe((result: boolean) => {
        console.log('Result: ', result)
        if(result){
            this.taxStepService.deleteStep(id).subscribe({
                error: (err) => {
                    console.error(err);
                    this.toastService.error(this.translate.instant('TOASTR.MESSAGE_3'), this.translate.instant('TOASTR.WARNING'));
                },
                complete: () => {
                  this.toastService.success(
                    this.translate.instant('LABEL.OK'), this.translate.instant('LABEL.Info'));
                    this.dataSource.data = this.dataSource.data.filter((row: any) => row.id !== id)
                }
            })
        }
      })
    
  }

  loadTaxSteps(): void {
    
    this.libService.lockPage('');
    this.taxStepService.getAllSteps().subscribe((response) => {
      console.log('loadTaxSteps response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.dataSource.data = response;
        this.dataSource.paginator = this.paginator;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    });
    
  }
}