<h1 mat-dialog-title>{{ titleLabel | translate }}</h1>

<div mat-dialog-content>

  <p>
    GESTIONE PEC
  </p>
  <div class="flex-container">

    <mat-card class="example-card" appearance="outlined">
      <mat-card-header>
        <mat-card-title>Garanzie</mat-card-title>
        <mat-card-subtitle>
          <button class="btn btn-primary btn-sm text-dark buttonPec" (click)="goToComplaint('complaints')"
            [disabled]="alreadyClosed()">
            <div>{{'LABEL.ApriGaranzia' | translate}}</div>
          </button>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>Totale Garanzie collegate: {{item?.claimComplaints?.length}}</p>
        <div *ngIf="item?.claimComplaints?.length > 0">
          <mat-list>
            <mat-list-item *ngFor="let obj of item?.claimComplaints" class="link">
              <mat-icon matListItemIcon>policy</mat-icon>
              <div matListItemTitle>
                <a [routerLink]="" (click)="goToItem(obj.id, 'warranty')">{{obj.id}}</a>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>


    <mat-card class="example-card" appearance="outlined">
      <mat-card-header>
        <mat-card-title>Sinistri</mat-card-title>
        <mat-card-subtitle>
          <button class="btn btn-primary btn-sm text-dark buttonPec" (click)="goToComplaint('claims')"
            [disabled]="alreadyClosed()">
            <div>{{'LABEL.ApriSinistro' | translate}}</div>
          </button>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>Totale Sinistri collegati: {{item?.claims?.length}}</p>
        <div *ngIf="item?.claims?.length > 0">
          <mat-list>
            <mat-list-item *ngFor="let obj of item?.claims" class="link">
              <mat-icon matListItemIcon>policy</mat-icon>
              <div matListItemTitle>
                <a [routerLink]="" (click)="goToItem(obj.id, 'claims')">{{obj.id}}</a>
              </div>
            </mat-list-item>
          </mat-list>
        </div>

      </mat-card-content>
    </mat-card>


    <mat-card class="example-card" appearance="outlined">
      <mat-card-header>
        <mat-card-title>Legali</mat-card-title>
        <mat-card-subtitle>
          <button class="btn btn-primary btn-sm text-dark buttonPec" (click)="goToComplaint('legals')"
            [disabled]="alreadyClosed()">
            <div>{{'LABEL.ApriLegale' | translate}}</div>
          </button>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>Totale Legali collegati: {{item?.claimLegals?.length}}</p>
        <div *ngIf="item?.claimLegals?.length > 0">
          <mat-list>
            <mat-list-item *ngFor="let obj of item?.claimLegals" class="link">
              <mat-icon matListItemIcon>policy</mat-icon>
              <a [routerLink]="" (click)="goToItem(obj.id, 'legals')">{{obj.id}}</a>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>


    <mat-card class="example-card" appearance="outlined">
      <mat-card-header>
        <mat-card-title>Transazioni</mat-card-title>
        <mat-card-subtitle>
          <button class="btn btn-primary btn-sm text-dark buttonPec" (click)="goToComplaint('transactions')"
            [disabled]="alreadyClosed()">
            <div>{{'LABEL.ApriTransazione' | translate}}</div>
          </button>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>Totale Transazioni collegate: {{item?.claimTransactions?.length}}</p>
        <div *ngIf="item?.claimTransactions?.length > 0">
          <mat-list>
            <mat-list-item *ngFor="let obj of item?.claimTransactions" class="link">
              <mat-icon matListItemIcon>policy</mat-icon>
              <a [routerLink]="" (click)="goToItem(obj.id, 'transactions')">{{obj.id}}</a>
            </mat-list-item>
          </mat-list>
        </div>

      </mat-card-content>
    </mat-card>


  </div>

  <br>

  <mat-card class="example-card" appearance="outlined">
    <mat-card-header>
      <mat-card-title>Chiudi Gestione PEC</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <textarea [(ngModel)]="textareaReasonClose" [disabled]="alreadyClosed()" rows="5" class="textareaReasonClose" type="textarea" placeholder="Motivazione Chiusura*" #notesInput></textarea>

    </mat-card-content>
    <mat-card-actions>
      <button class="btn btn-primary btn-sm text-dark buttonPec" (click)="closePec()" [disabled]="alreadyClosed()">
        <div>{{'LABEL.PECGestita' | translate}}</div>
      </button>
    </mat-card-actions>


  </mat-card>





</div>