import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { NgxSummernoteModule } from 'ngx-summernote'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EmailTemplateListComponent } from './email-template-list/email-template-list.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';


const MATERIAL_MODULES = [
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatIconModule
]

@NgModule({
  declarations: [
    EmailTemplateComponent,
    EmailTemplateListComponent
  ],
  imports: [
    ...MATERIAL_MODULES,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    NgxSummernoteModule,
    SharedModule
  ],
  exports: [EmailTemplateListComponent]
})
export class EmailTemplateModule { }
