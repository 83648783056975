import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SurveyMaster } from '../models/survey';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class SurveyMasterService {

  baseApi = environment.rootApiCrm + 'v1.0/surveyMaster'

  constructor(private libService: LibService) { }

  create(survey: SurveyMaster){
    return this.libService.postData(survey, this.baseApi, '')
  }

  update(survey: SurveyMaster){
    return this.libService.postData(survey, this.baseApi, `/${survey.id}`)
  }

  getByEntityName(entityName: string){
    return this.libService.postData<SurveyMaster[]>({entityName}, this.baseApi, 's/getByEntityName')
  }

  getById(id: string){
    return this.libService.getData<SurveyMaster>(this.baseApi, `/${id}`)
  }
}
