import { Component, Input, OnInit } from '@angular/core';

import { GenericModal } from '../modals/generic-modal/generic-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ComponentService } from '../../services/component.service';
import { PracticeService } from '../../services/practice.service';
import { StateService } from '../../services/state.service';
import { LibService } from '../../services/libService';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-planning-button-set-non-programmable',
  templateUrl: './planning-button-set-non-programmable.component.html',
  styleUrls: ['./planning-button-set-non-programmable.component.scss'],
})
export class PlanningButtonSetNonProgrammableComponent {
  @Input() practice: any;
  @Input() element: any;

  components: any;
  attributes: any;
  componentName: any = 'planningSetAsNonProgrammable';
  objectStructure: any = {};
  settings: any = {};

  constructor(
    private dialog: MatDialog,
    private componentService: ComponentService,
    private libService: LibService,
    private practiceService: PracticeService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private stateService: StateService
  ) {}
  setAttributes() {
    //console.log('setAttributes')
    let component = this.components?.settings?.find(
      (component: any) => component.componentCode == this.componentName
    );
    this.attributes = component?.attributes;
    if (this.attributes)
      this.objectStructure = this.componentService.getObjectStructure(
        this.attributes
      );
  }

  getState(stateId: any) {
    if (stateId) {
      this.stateService.getState(stateId).subscribe((response: any) => {
        this.setComponents(response.components);
        this.setAttributes();
        this.getSettings();
        this.openModal(this.settings);
        this.libService.unlockPage();
      });
    } else {
      this.setComponents(null);
      this.setAttributes();
    }
  }

  getSettings() {
    let code = 'nonProgrammable';
    (this.settings.titleLabel = 'CHANGESTATEBUTTON.' + code + '_title'),
      (this.settings.textLabel = 'CHANGESTATEBUTTON.' + code + '_text');
    this.settings.initReasons = 'reasonList' in this.objectStructure;
    this.settings.reasonList = this.objectStructure.reasonList || [];
    if ('notes' in this.objectStructure)
      this.settings.notes = { required: this.objectStructure.notes.required };
  }

  setComponents(components: any) {
    //console.log('setComponents')
    this.components = { codes: [], settings: [] };
    if (components != null) {
      Object.keys(components).forEach((key) => {
        let tmpComponent = {
          componentCode: key,
          attributes: components[key]?.attributes,
        };
        this.components.codes.push(key);
        this.components.settings.push(tmpComponent);
      });
    }
  }

  openModalForNonProgrammable(e: any) {
    // Ottieni i component della pratica
    console.log(e);

    let code = e.CodicePratica;
    this.libService.lockPage('');
    this.practiceService
      .showByAttributes({ code: code })
      .subscribe((response: any) => {
        this.practice = response;
        this.getState(response.StateId);
      });
  }

  openModal(settings: any) {
    console.log(settings);
    const dialogRef = this.dialog.open(GenericModal, {
      width: '600px',
      data: settings,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (![null, undefined].includes(result)) {
        let attributes = result;
        attributes.componentName = this.componentName;
        this.libService.lockPage('');
        this.practiceService
          .setAsNonProgrammable(this.practice.id, attributes)
          .subscribe(
            (ret) => {
              this.toastr.success(
                this.translate.instant('LABEL.DATA_SUCCESS'),
                this.translate.instant('TOASTR.INFO')
              );
              this.libService.unlockPage();
              window.location.reload();
            },
            (err) => {
              //this.toastr.error(this.translate.instant('LABEL.Error'));
              this.libService.unlockPage();
            }
          );
      }
    });
  }
}
