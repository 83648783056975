import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, of } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Request } from 'src/app/models/request';
import { LibService } from 'src/app/services/libService';
import { NavService } from 'src/app/services/nav.service';
import { RequestService } from 'src/app/services/request.service';
import { RoleService } from 'src/app/services/role.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';

@Component({
  selector: 'app-solicit-list',
  templateUrl: './solicit-list.component.html',
  styleUrls: ['./solicit-list.component.scss']
})
export class SolicitListComponent implements OnInit {
  @ViewChild('workTypeCell', { read: TemplateRef }) workTypeCell: TemplateRef<any>;
  workTypeCellTemplateRef$ = new BehaviorSubject<any>(null);
  @ViewChild('addressCell', { read: TemplateRef }) addressCell: TemplateRef<any>;
  addressCellTemplateRef$ = new BehaviorSubject<any>(null);

  loggedUser: any;
  acceptedCompanies: any;
  profiles: any;

  columns: DatatableColumn<Request>[] = [
    {
      name: "id",
      flex: '7',
      title: this.translate.get('LABEL.ID'),
      cssClass: request => ['column-padding'],
      value: request => request.id
    },
    {
      name: "solicitReason",
      title: this.translate.get('LABEL.SOLICIT_REASON'),
      cssClass: request => ['column-padding'],
      value: request => request.solicitReason !== null && request.solicitReason !== undefined? this.translate.instant('LABEL.'+request.solicitReason): '',
      templateRef: request => this.workTypeCellTemplateRef$
    },
    {
      name: "address",
      type: 'templateRef',
      title: this.translate.get('LABEL.ADDRESS'),
      cssClass: request => ['column-padding'],
      value: request => request,
      templateRef: request => this.addressCellTemplateRef$
    },
    {
      name: "assignmentOperator",
      flex: '20',
      title: this.translate.get('LABEL.WORKER_ASS'),
      cssClass: request => ['column-padding'],
      value: request => request.assignmentOperator
    },
    {
      name: "statusRequest",
      title: this.translate.get('LABEL.STATUS'),
      cssClass: request => ['column-padding'],
      value: request => {
        return request.status !== null?
          this.translate.instant('LABEL.' + request.status?.uiLabel):
          this.translate.instant('LABEL.NOT_PRESENT')
      }
    },
    {
      name: "notes",
      title: this.translate.get('LABEL.NOTE'),
      cssClass: request => ['column-padding'],
      value: request => request.notes
    },
    {
      name: "createdAt",
      flex: '5',
      title: this.translate.get('LABEL.CREATION_DATE'),
      cssClass: request => ['column-padding'],
      value: request => this.datePipe.transform(request.createdAt?.toString())
    },
    {
      name: "updatedAt",
      flex: '5',
      title: this.translate.get('LABEL.LASTMODIFIED_DATE'),
      cssClass: request => ['column-padding'],
      value: request => this.datePipe.transform(request.updatedAt?.toString())
    }
  ]

  actions: DatatableAction<Request>[] = [
    {
      label: of(''),
      icon: request => {
        return this.iconRoutine(request)
      } ,
      onClick: request => this.goTo(request),
      color: 'primary'
    },
    {
      label: of(''),
      icon:  subject => {
        return 'delete'
      }, // 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    }
  ];

  displayedColumns: string[] = ['id', 'solicitReason', 'address', 'statusRequest', 'notes', 'createdAt', 'updatedAt']; //'contact','phone_mail',
  dataSource: any;
  requests: any;
  kwSearch: any;
  //title = "Requests";
  //subTitle = "EDAC - Requests";
  // @ViewChild(MatSort) set matSort(sort: MatSort) {
  //   this.dataSource.sort = sort;
  // }

  currentUrl: string;
  request: any;

  constructor(
    public navService: NavService,
    public requestService: RequestService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private roleService: RoleService)

    {
      this.userSessionService.getUser(this.authService.user().email).subscribe(val => {
        this.loggedUser = val;
        this.profiles = this.loggedUser[0]?.UsersProfilesRel.map((a: any) => a.name);
        this.acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
      });
    }

  ngOnInit(): void {
    this.loadDataSets();
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }
  }

  ngAfterViewInit() {
    this.workTypeCellTemplateRef$.next(this.workTypeCell);
    this.addressCellTemplateRef$.next(this.addressCell);
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  openEdit(){
    let queryParams: any = {
      type: 2
    }
    this.router.navigate(['request/0'], { queryParams: queryParams });
}

  goTo(e: any) {
    this.router.navigate(['request/' + e.id]);
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  deleteRow(e: any) {

    const id = e.id;
      if (id && parseInt(id) > 0) {
        console.log("ID: " + id);
        this.requestService.deleteRequest(id, this.request).subscribe((response: any) => {
          this.libService.unlockPage();
          this.toastr.success(this.translate.instant('LABEL.ROW_DELETED'), this.translate.instant('TOASTR.INFO'));
          console.log(response);
          window.location.reload();
        },
        err => {
          console.log(err);
          this.libService.unlockPage();
          this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
        });
      }
      else {
        this.toastr.success(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.INFO'));
      }
  }

  loadDataSets(): void {
    this.libService.lockPage('');
    this.requestService.getAllSolicits().subscribe((response) => {
      console.log('loadRequests response %o', response);
      this.libService.unlockPage();
      if (response.length > 0) {
        this.requests = response;
        this.dataSource = response;
      } else {
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
    },
    err => {
      this.libService.unlockPage();
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });
  }

  iconRoutine(request: any) {
    //INIZIO ROUTINE PER VISIBILITY
    let profile: any;

    this.roleService.getMainRole(this.loggedUser);

    switch (this.loggedUser[0].role) {
      case 'Responsabile Area': case 'Affiliato':
        return this.acceptedCompanies.includes(request.building?.company) || request.assignmentOperator === this.loggedUser[0].email? 'edit': 'visibility';
        break;

      case 'Tecnico Commerciale':
        // !(this.acceptedCompanies.includes(request.building?.company) &&
        return !(request.assignmentOperator === this.loggedUser[0].email)? 'visibility' : 'edit'
        break;

      case 'BackOffice':
        return 'visibility';
        break;
      case 'Responsabile Commerciale': case 'Coordinatore Country': case 'Direzione': case 'Top Management': case 'Contabilita': case 'Amministrazione': case 'HR': case 'Responsabile Mkt':
        return this.acceptedCompanies.includes(request.building?.company) || request.assignmentOperator === this.loggedUser[0].email? 'edit': 'visibility';

        break;
      case 'Coordinatore':
        this.dataSource = [];
        return 'visibility';
        break;
      default:
        return 'edit'
        break;
    }
  }

  filterResultEmitted(event: Request[]) {
    console.log("EVENT --> ", event);
    if (event !== null) {
      this.dataSource = event
    } else {
      this.toastr.warning(this.translate.instant('TOASTR.MESSAGE_2'))
      this.dataSource = this.requests;
    }
  }

  updateList(e: any) {
    console.log("EVENTO LISTA -->",e)
    if (e?.length > 0) {
      this.dataSource = e;
    } else {
      this.dataSource = this.requests
    }
  }

}
