import { CalendarConfig, ProjectModelConfig } from '@bryntum/calendar-thin';
import { environment } from 'src/environments/environment';

export const projectConfig: Partial<ProjectModelConfig> = {
  // Empty project config
};

const baseApi = environment.rootApiPaperwork;

export const calendarConfig: Partial<CalendarConfig> = {
  date: new Date(),
  timeZone: 0,

  crudManager: {
    // autoSync: true,
    transport: {
      load: {
        url: `${baseApi}plannings/getOrdersAndTasksMultiWithPagination`,
        credentials: 'omit',
      },
      // sync: {
      //   url: `${baseApi}plannings/syncTaskMulti`,
      //   method: 'POST',
      //   credentials: 'omit',
      // }
    },
  },
  modes: {
    day: {
      showResourceAvatars: true,
      autoCreate: false,
      hourHeight: 150
    },
    week: {
      showResourceAvatars: true,
      autoCreate: false,
      hourHeight: 150
    },
    month: {
      showResourceAvatars: true,
      autoCreate: false,
    },
    year: {
      autoCreate: false,
    },
  },
  features: {
    loadOnDemand: true,
    scheduleMenu: {
      items: {
        addEvent: null,
      },
    },
    eventEdit: {
      items: {
        nameField: {
          required: false
        },
        recurrenceCombo: null,
        editRecurrenceButton: null,
        resourceField: {
          label: 'Risorse',
        },
        allDay: null,
      },
    },
    eventTooltip: {
      disabled: true,
    },
  },
};
