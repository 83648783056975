<div fxLayout="column"><!--  -->
  <form [formGroup]="form" fxLayout="row" fxLayoutAlign="center end" ><!-- -->

    <app-multi-select-dropdown fxFlex
      formControlName="company"
      [items]="companies"
      firstSelectionViewField="name"
      [firstSelectionLabel]="'LABEL.Sede' | translate"
      [noUsers]="disabled"
      [disableChipList]="disabled"
      >
    </app-multi-select-dropdown>

    <app-multi-select-dropdown fxFlex
      formControlName="branch"
      [items]="branches"
      firstSelectionViewField="branchCode"
      [firstSelectionLabel]="'ACTIVITIES.BRANCH' | translate"
      [noUsers]="disabled"
      [disableChipList]="disabled"
      >
    </app-multi-select-dropdown>

    <app-multi-select-dropdown fxFlex
      formControlName="year"
      [items]="years"
      firstSelectionViewField=""
      [firstSelectionLabel]="'LABEL.YEAR' | translate"
      [noUsers]="disabled"
      [disableChipList]="disabled"
      >
    </app-multi-select-dropdown>
    <app-multi-select-dropdown fxFlex
      formControlName="assignmentOperator"
      [items]="responsibles"
      firstSelectionViewField="email"
      [firstSelectionLabel]="'LABEL.Responsabile' | translate"
      [noUsers]="noUsers || disabled"
      [disableChipList]="disabled"
      >
    </app-multi-select-dropdown>
    <app-multi-select-dropdown  fxFlex
      formControlName="states"
      [items]="stateCategories"
      firstSelectionViewField="stateCode"
      [firstSelectionLabel]="'LABEL.STATE' | translate"
      [noUsers]="disabled"
      [disableChipList]="disabled"
      >
    </app-multi-select-dropdown>
  
  </form>
  <div fxLayout="row" fxLayoutAlign="end center" >
    <button type="button" class="btn btn-primary ps-7 text-dark" style="margin:10px 10px" (click)="getFilter()" fxLayoutAlign="center center" [disabled]="disabled">
            <mat-icon class="material-icons" style="color:#293683;">search</mat-icon>
            {{ 'SUBJECT.FILTER' | translate }}
          </button>
    <button type="button" class="btn btn-primary ps-7 text-dark"  (click)="clear()" fxLayoutAlign="center center" [disabled]="disabled">
        <!-- <mat-icon class="material-icons" style="color:#293683;">save</mat-icon> -->
        {{ 'SUBJECT.CLEAR' | translate }}
      </button>
  </div>
</div>
