<mat-card class="mb-4">
  <form [formGroup]="searchForm">
<div class="full-w" fxLayout="row" fxLayoutAlign="center center">
  <div class="row" fxFill>
    <div class="col pr-0">

      <input matInput formControlName="code" type="text" id="commessaInput" class="form-control" 
                  ngx-google-places-autocomplete  [placeholder]="translate.instant('PLANNER.cercacommessa')">
    </div>
    <div class="col-auto ml-auto group-button-map">

      <button mat-button color="primary" (click)="search()" [disabled]="!searchForm.valid"
      class="with-icon">
        <mat-icon>search</mat-icon> <span>{{ 'ADDRESS.FIND' | translate }}</span>
      </button>
    </div>
  </div>
</div>
</form>
</mat-card>

