import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  combineLatest,
  finalize,
  first,
  last,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { Company } from 'src/app/models/company';
import { Society } from 'src/app/models/society';
import { StreamList, StreamQuery } from 'src/app/models/store';
import { LibService } from 'src/app/services/libService';
import { SocietyService } from 'src/app/services/societyService';
import { UserSessionService } from 'src/app/services/user-session.service';
import { environment } from 'src/environments/environment';
import { WorkCasuals } from '../models/final-balance';
import { FinalBalnceHistory } from '../models/final-balance-history';
import { FinanceService } from 'src/app/services/finance.service';
import { UserRole } from 'src/app/planner/models/userRole';

@Injectable()
export class HistoryBalanceService {
  private baseApi = environment.rootApiFoundation;
  private baseApiOperational = environment.rootApiPaperwork;
  private baseApiFinance = environment.rootApiFinance;

  public historySource: StreamList<FinalBalnceHistory> =
    new StreamList<FinalBalnceHistory>('final-balance-history', []);

  public societiesSource: StreamList<Society> = new StreamList<Society>(
    'societies',
    []
  );

  public searchQuery: StreamQuery = new StreamQuery('searchQuery');

  public workCasualsSource: StreamList<WorkCasuals> =
    new StreamList<WorkCasuals>('work-casuals', []);

  constructor(
    private libService: LibService,
    private userSessionService: UserSessionService,
    private societyService: SocietyService,
    private financeService: FinanceService
  ) {}

  get accessToken(): string {
    return this.userSessionService.getIdToken();
  }

  get user() {
    return this.userSessionService.getState('user');
  }

  get userEmail(): string {
    return this.user.email;
  }

  get companies(): Company[] {
    return this.user.UsersCompaniesRel as Company[];
  }

  get $societies(): Observable<Society[]> {
    if (this.societiesSource.length) {
      return this.societiesSource.$stream.pipe(first());
    }
    return this.societyService.getAllSocieties().pipe(
      first(),
      shareReplay(1),
      tap((societies) => this.societiesSource.save(societies)),
      switchMap(() => this.societiesSource.$stream.pipe(first()))
    );
  }

  get $workCasuals(): Observable<WorkCasuals[]> {
    if (this.workCasualsSource.length) {
      return this.workCasualsSource.$stream.pipe(first());
    }
    return this.libService.getData<any[]>(this.baseApi, `workcausals`).pipe(
      first(),
      shareReplay(1),
      tap((workCasuals) => this.workCasualsSource.save(workCasuals)),
      switchMap(() => this.workCasualsSource.$stream.pipe(first()))
    );
  }

  public $loadData() {
    this.libService.lockPage('');

    return this.$getBalanceHistory().pipe(
      last(),
      finalize(() => this.libService.unlockPage()),
      catchError(() => of([]))
    );
  }

  $getBalanceHistory(params: string = ''): Observable<any> {
    if (this.historySource.length && !params.length) {
      return this.historySource.$stream.pipe(first());
    }
    return this.libService
      .getData<any[]>(
        this.baseApiFinance,
        `/V1/Input/AnomalieConsuntivazione?account=${this.userEmail}${params}`
      )
      .pipe(
        first(),
        map((history: any) => history.item.Table),
        tap((history) => this.historySource.save(history)),
        shareReplay(1)
      );
  }

  public $workCasualsByUser(userId: number): Observable<WorkCasuals[]> {
    return this.libService
      .getDataWithAccessToken<any[]>(
        this.accessToken,
        this.baseApi,
        `users/${userId}/getWorkCausals`
      )
      .pipe(first());
  }

  public $allWorkCasuals(userId: number): Observable<WorkCasuals[]> {
    return this.libService
      .getDataWithAccessToken<any[]>(
        this.accessToken,
        this.baseApi,
        `users/${userId}/getWorkCausals`
      )
      .pipe(first());
  }
  
  public $updateUserWorkCasual(balanceId: number, workCausalId: number) {
    return this.libService
      .putDataWithAccessToken<any[]>(
        this.accessToken,
        {},
        this.baseApiOperational,
        `finalPassiveHoursBalances/${balanceId}/causalWorkChange/${workCausalId}`
      )
      .pipe(first());
  }

  public $getResources(
    companyId: number,
    userRole: UserRole = UserRole.ASSISTANCE
  ): Observable<any> {
    return this.financeService
      .getRisorse(this.userEmail, companyId, userRole)
      .pipe(
        first(),
        map((results) => results.item.Table)
      );
  }

  public $getAllResources(companyId: number): Observable<any> {
    this.libService.lockPage('');
    return combineLatest([
      this.$getResources(companyId),
      this.$getResources(companyId, UserRole.TEAMLEADER),
    ]).pipe(
      last(),
      finalize(() => this.libService.unlockPage()),
      catchError(() => of([]))
    );
  }
}
