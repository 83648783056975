import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { PracticeService } from '../services/practice.service';
import { OrderService } from '../services/order.service';
import { LibService } from '../services/libService';
import { MatPaginator, PageEvent, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSessionService } from '../services/user-session.service';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';
import { ToastrService } from 'ngx-toastr';
import { StateCategoryService } from 'src/app/services/state-category.service';
import { StateService } from 'src/app/services/state.service';
import { BranchService } from 'src/app/services/branch.service';

import { ElecSignService } from 'src/app/services/elec-sign.service';
import { Subject } from 'rxjs';

import { CompanyManagementService } from '../services/company-management.service'
import { LangChangeEvent } from '@ngx-translate/core';


@Component({
  selector: 'app-practices',
  templateUrl: './practices.component.html',
  styleUrls: ['./practices.component.scss']
})
export class PracticesComponent implements OnInit {

  branches: any[];

  shortColumnsStructure = ['code', 'branch', 'responsible', 'company', 'price', 'status', 'subjectId', 'buildingId', 'crmReports', 'note', 'createdAt', 'editAction', 'goToFinalBalancer'];
  //longColumnsStructure = ['code', 'responsible', 'company', 'buildingId', 'status', 'valoreDeliberato', 'valoreProdotto', 'datoMLC', 'fatturato', 'incassato', 'dataUltimoSAL', 'editAction', 'goToFinalBalancer'];
  longColumnsStructure = ['code', 'branch', 'responsible', 'company', 'buildingId', 'status', 'valoreDeliberato', 'valoreProdotto', 'fatturato', 'incassato', 'dataUltimoSAL', 'editAction', 'goToFinalBalancer'];

  displayedColumns = this.shortColumnsStructure;
  dataSource: any;
  eventsSubject: Subject<void> = new Subject<void>();
  kwSearch: any;
  title = "Pratiche";
  subTitle = "EDAC - Pratiche";
  user: any;
  orderStatuses: any;
  dataSourceStandard: any;
  isOperational: boolean = false;
  isStaff: boolean = false;
  isAdmin: boolean = false;
  isFreeze: boolean = false;
  disabled: boolean = false;
  filterOptions: any = {};
  practiceIds: any = [];
  statesForFinalBalancerIds: any = [];

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;
  isLoading: boolean = false;
  filterCompanyCodes: Array<string> = []
  filterResponsibles: Array<string> = []
  buildingsSection: boolean = false;
  practice: object = { name: "asc" };

  hasPagination = true;
  hasHeader = true;

  pageSizeOptions = [5, 10, 20];
  selected: any;

  currentPage = 0;

  companies: Array<any> = []

  companyIdsFilter: Array<any> = [];
  assignmentOperatorFilter: Array<any> = [];
  statusIdFilter: Array<any> = [];
  yearFilter: Array<any> = [];
  enableFinancialData: any = false;

  stateCategories: any = []
  stateCategoriesFilter: any = []

  branchesFilter: any = []

  states: any = []

  private onDestroy = new Subject<void>(); 
  langChangeSubscription: any;


  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;

  currentUrl: string;

  constructor(
    public navService: NavService,
    public practiceService: PracticeService,
    public orderService: OrderService,
    public libService: LibService,
    private router: Router,
    public translate: TranslatorService,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private elecSignService: ElecSignService,
    private cdr: ChangeDetectorRef,
    private companyManagementService: CompanyManagementService,
    private stateCategoryService: StateCategoryService,
    private branchService: BranchService,
    private stateService: StateService,
    public MatPaginatorIntl: MatPaginatorIntl
  ) {
    this.dataSource = new MatTableDataSource();
  }


  ngDoCheck() {
    if (
      this.isOperational == false &&
      this.isStaff == false &&
      this.isAdmin == false) {
      if (!(this.userSessionService.getState('user') == null)) {
        for (let a = 0; a < this.userSessionService.getState('user').UsersProfilesRel.length; a++) {
          switch (this.userSessionService.getState('user').UsersProfilesRel[a].code) {
            case 'SLS-TC':
              this.isOperational = true;
              break;
            case 'PRD-CO':
              this.isOperational = true;
              break;
            case 'PRD-RA':
              this.isStaff = true;
              break;
            case 'AFF':
              this.isStaff = true;
              break;
            case 'HQ-AM':
              this.isAdmin = true;
              break;
            case 'HQ-BRD':
              this.isAdmin = true;
              break;
            default:
              break;
          }
          if (this.isAdmin == true) {
            let actions: any;
            actions = [
              { name: 'new', url: 'practices/0' },
             // { name: 'export', url: '' },
            ];
            this.navService.setActions(actions);
          }
        }
      }
    }
  }

  setTranslationsItemsPerPageLabel() {
    this.MatPaginatorIntl.itemsPerPageLabel = this.translate.instant('LABEL.ELEMENTI_PER_PAGINA');
    this.MatPaginatorIntl.nextPageLabel = this.translate.instant('LABEL.PROSSIMA_PAGINA');
    this.MatPaginatorIntl.previousPageLabel = this.translate.instant('LABEL.PAGINA_PRECEDENTE');
    this.MatPaginatorIntl.changes.next();
  }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  crmReportsPresent(element: any) {
    return element?.crmReports?.length > 0
  }

  initBranches() {
    this.branchService.getAllBranches().subscribe((res: any) => {
      //console.log(res)
      this.branches = res
      this.branchesFilter = res.filter((branch:any)=> branch.isActive )
    })
  }

  initStates() {
    this.stateService.index().subscribe((response: any) => {
      this.states = response
    })
  }

  initStatesForFinalBalancer() {
    this.stateService.statesByComponents(['isConsuntivable']).subscribe((response: any) => {
      this.statesForFinalBalancerIds = response.map((state: any) => state.id)
    })
  }

  initStateCategories() {
    this.stateCategoryService.index().subscribe((response: any) => {
      this.stateCategories = response.filter((stateCategory: any) => stateCategory.itemCategory.code == 'item_categories_practices')
      this.stateCategoriesFilter = response.filter((stateCategory: any) => stateCategory.itemCategory.code == 'item_categories_practices' && !stateCategory.disabled)
    })
  }

  getBranchLabel(businessLineId: any) {
    let currentBranch: any = ''
    if (this.branches) currentBranch = this.branches.find((branch: any) => branch.id == businessLineId)
    return currentBranch?.code
    //return this.translate.instant('LABEL.NOT_PRESENT')
  }


  loadLabels() {
    this.translate.get('LABEL.PRACTICES').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  getLastOrder(practice: any) {
    let orders = practice.orders
    return orders ? practice.orders.find((order: any) => order.isLast) : {}
  }

  getValue(element: any) {
    //console.log('practice %o',element);
    let lastOrder = this.getLastOrder(element)
    if (lastOrder == null)
      return 0;
    if (lastOrder.realPrice > 0 || lastOrder.realPrice < 0)
      return lastOrder.realPrice;

    return 0;
  }

  getSubject(element: any) {
    let lastOrder = this.getLastOrder(element)
    let currentSubject = ""
    if (!(lastOrder?.nameSubject == null) && lastOrder?.nameSubject?.length > 0) {
      currentSubject = lastOrder.nameSubject
    }
    if (((lastOrder?.nameSubject == null) || (lastOrder?.nameSubject?.length == 0)) && (!(lastOrder?.nameAdminCondominius == null) && lastOrder?.nameAdminCondominius?.length > 0)) {
      currentSubject = lastOrder?.nameAdminCondominius
    }
    return currentSubject
  }

  getBuildingName(element: any) {
    let lastOrder = this.getLastOrder(element)
    return lastOrder?.nameBuilding
  }

  getNote(element: any) {
    let lastOrder = this.getLastOrder(element)
    return lastOrder?.note
  }

  getDataUltimoSAL(element: any) {
    let res = ""
    if (element.OrdersOrderRowsRel) {
      let allOPFABRel = element.OrdersOrderRowsRel.flatMap((row: any) =>
        row.OrderRowsOrderPhasesRel.flatMap((phase: any) =>
          phase.OPFABRel
        ))
      allOPFABRel.sort(function (x: any, y: any) {
        return y.ts - x.ts;
      })
      if (![null, undefined].includes(allOPFABRel[0])) {
        var date = new Date(allOPFABRel[0].ts * 1000);
        res = date.toISOString()
      }
    }
    return res
  }

  ngOnInit(): void {

    this.setTranslationsItemsPerPageLabel();

    // Ascolta i cambiamenti di lingua
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setTranslationsItemsPerPageLabel();
    });

    this.initStates()
    this.initStateCategories()
    this.initBranches()
    this.initStatesForFinalBalancer()

    // AL_EDAC -> Recupera il valore del parametro "edacDev" passato nell'url per la visulizzazione in Dev di parti di codice in sviluppo.
    this.route.queryParams
      .subscribe((params: any) => {
        if (params.edacDev) {
          this.enableFinancialData = [true, 'true'].includes(params.edacDev) ? true : false;
        }
      }
      );

    this.companies = this.getUserCompanies()

    console.log('initUserCompaniesForFilter')
    this.filterOptions = this.initFilters()


    //LOGICA PER ORDINAMENTO TABELLA - GIORGIO CERUTTI
    // this.dataSource = new MatTableDataSource(yourData);
    this.dataSource.sortingDataAccessor = (item: any, property: any) => {
      switch (property) {
        case 'price': return item.realPrice;
        case 'status': return 'LABEL.' + this.getStatusLabel(item.idStatus);
        case 'sal100': return item.isSalCento;
        case 'subjectId': return item.nameSubject;
        case 'buildingId': return item.nameBuilding;

        default: return item[property];
      }
    };
    ['code', 'version', 'price', 'status', 'sal100', 'subjectId', 'buildingId', 'note', 'createdAt', 'updatedAt', 'editAction'];
    // this.dataSource.sort = sort;

    this.user = this.userSessionService.getState('user');
    console.log('this.user %o', this.user);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      // { name: 'new', url: 'practices/0' },
      //{ name: 'export', url: '' },
    ];

    this.navService.setActions(actions);
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

    this.loadOrderStatuses(true) // Caricamento degli stati delle pratiche e delle Pratiche Iniziali
  }

  getUserCompanies(): Array<any> {
    let companies = [];
    let us = this.userSessionService.getState('user');

    if (!(us == null) && !(us.UsersCompaniesRel == null)) {
      for (let a = 0; a < us.UsersCompaniesRel.length; a++) {
        companies.push({
          isOpen: us.UsersCompaniesRel[a].isOpen,
          name: us.UsersCompaniesRel[a].name,
          code: us.UsersCompaniesRel[a].code,
          id: us.UsersCompaniesRel[a].id
        });
      }
    }
    return companies;
  }

  getClassRow(id: any) {

    let currentState = this.stateCategories.find((stateCategory: any) => stateCategory?.states?.map((state: any) => state.id)?.includes(id))
    let ret = 'tdg ';
    switch (currentState?.code) {
      case 'state_categories_S20':
        {
          ret = ret + 'row-ok';
          break;
        }
      case 'state_categories_S72':
        {
          ret = ret + 'row-wrn';
          break;
        }
      case 'state_categories_S73':
        {
          ret = ret + 'row-wrn';
          break;
        }
      case 'state_categories_S75':
        {
          ret = ret + 'row-wrn';
          break;
        }
      case 'state_categories_S82':
        {
          ret = ret + 'row-wrn';
          break;
        }
      case 'state_categories_S11':
        {
          ret = ret + 'row-ok';
          break;
        }
      case 'state_categories_S66':
        {
          ret = ret + 'row-tok';
          break;
        }
      case 'state_categories_S15':
        {
          ret = ret + 'row-ok';
          break;
        }
      case 'state_categories_S34':
        {
          ret = ret + 'row-ok';
          break;
        }
      case 'state_categories_S31':
        {
          ret = ret + 'row-ok';
          break;
        }
      case 'state_categories_S13':
        {
          ret = ret + 'row-to-work';
          break;
        }
      case 'state_categories_S44':
        {
          ret = ret + 'row-to-work';
          break;
        }
      case 'state_categories_S21':
        {
          ret = ret + 'row-to-work';
          break;
        }
      case 'state_categories_S28':
        {
          ret = ret + 'row-ok';
          break;
        }
      case 'state_categories_S2':
        {
          ret = ret + 'row-ko';
          break;
        }
        case 'state_categories_S84':
          {
            ret = ret + 'row-ko';
            break;
          }
      default:
        {
          ret = ret + 'row-to-work';
          break;
        }
    }

    return ret;
  }

  getStatusLabel(stateId: any) {
    if (stateId !== null && this.stateCategories) {
      let currentState = this.stateCategories.find((stateCategory: any) => stateCategory?.states?.map((state: any) => state.id)?.includes(stateId))
      return this.translate.instant('STATECATEGORIES.' + currentState?.code) as string
    } else {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    }
  }

  isConsuntivable(id: any) {
    return this.statesForFinalBalancerIds.includes(id)
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch });
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  goTo(e: any) {
    this.router.navigate(['practices/' + e.code]);
  }

  goToFinalBalancer(e: any) {

    if (this.isConsuntivable(e.StateId)) {

      let user = this.userSessionService.getState('user');
      let workingCompany = this.userSessionService.getState('working_company');

      // Setta la company della pratica
      if (workingCompany == null || workingCompany.id != e.idSede) {
        let tmp_company = user.UsersCompaniesRel.find((c: any) => c.id == e.idSede);
        let res = {
          isOpen: tmp_company.isOpen,
          name: tmp_company.name,
          code: tmp_company.code,
          id: tmp_company.id
        }
        this.userSessionService.saveState('working_company', res);

        this.companyManagementService.add(res);

      }

      // Va alla sezione consuntivazione della pratica
      if (e.BusinessLineId == 22) {
        this.router.navigate(['final-balancer-multi/']);
      } else {
        let lastOrder = this.getLastOrder(e)
        this.router.navigate(['final-balancer/' + lastOrder.code + '/' + lastOrder.version]);
      }

    }
  }

  goToT(e: any) {
    this.elecSignService.getFileExample().subscribe(f => {
      console.log('file for test %o', f);
      this.elecSignService.postFile('prova.pdf', f).subscribe(
        resFile => {
          console.log('response post file for elec_Sign %o', resFile);
        }
      );
    });
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
  }

  updateList(e: any) {
    
    console.log("EVENTO AGGIORNAMENTO LISTA --> ", e);
    console.log("DATASOURCE --> ", this.dataSource);
    console.log("DATASOURCE STANDARD --> ", this.dataSourceStandard);

    if (e.length > 0) {
      
      this.practiceIds = e.map((o: any) => o.id)
      this.resetPagination()
      this.loadPractices()
      this.disabled = false;
      this.cdr.detectChanges();

    } else {
      // this.dataSourceStandard !== null && this.dataSourceStandard !== undefined ?
      this.practiceIds = []
      this.loadPractices()

      this.disabled = false;
      this.cdr.detectChanges();
    }
    
  }

  getFilterSelectionEmit(event: any) {
    console.log(event)
    this.filterOptions = event
    this.resetPagination()
    this.loadPractices()
  }

  filterResultEmitted(event: { data: Request[], clear: boolean }) {
    if (event.clear !== true) {
      console.log("EVENTO LISTA --> ", event);
      this.dataSource.data = event.data
      this.paginator.length = event.data.length;
      this.dataSource.paginator = this.paginator
      !(event.data.length > 0) ? this.toastr.warning(this.translate.instant('TOASTR.MESSAGE_2')) :
        console.log("");
    } else {
      this.loadPractices()
    }
  }

  clearList(e: any) {
    console.log("CANCELLA --> ", e);
    if (e) {
      this.practiceIds = []
      this.loadPractices()
    }
  }

  loadOrderStatuses(initPractices: boolean = false) {
    /*
    this.practiceService.getOrderStatuses().subscribe(sts => {
      this.orderStatuses = sts;

      // Le Pratiche vengono inizializzate insieme agli stati solo se necessario
      if (initPractices) {
        this.loadPractices()
      }
    }, err => {
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });
    */
  }

  loadPractices() {

    this.user = this.userSessionService.getState('user');

    this.libService.lockPage('');

    let user = {
      email: this.user.email,
      id: this.user.id,
      companyIds: this.companies.map((company: any) => company.id)
    }

    this.filterOptions['orderIds'] = []
    if (this.practiceIds.length > 0) {
      console.log(this.practiceIds)
      this.filterOptions['orderIds'] = this.practiceIds
    }

    this.practiceService.indexWithPagination(user, this.filterOptions, {}, this.pageInit, this.pageSize, this.enableFinancialData).subscribe((responseData: any) => {
      this.totalRows = responseData.count;
      this.libService.unlockPage();
      this.dataSource.data = responseData.rows
      this.dataSource.paginator = this.paginator;
    }, err => {
      this.libService.unlockPage();
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    });

  }

  updateDataBySortParams(event: any) {
    /*
    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    console.log(this.buildingStatuses)
    let option: any = {};
    option[event.active] = event.direction
    this.order = option
    if(event.active == "status"){
      this.order = {status:{uiLabel: event.direction }}
    }
    this.resetPagination()
    this.loadBuildings()
    */
  }

  resetPagination() {
    this.currentPage = 0;
    this.pageInit = 0;
  }


  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadPractices()
  }

  updateSortData(event: any) {
    console.log('updateSortData')
    console.log(event)
  }

  getSede(id: any) {
    for (let a = 0; a < this.companies.length; a++) {
      if (id == this.companies[a].id)
        return (this.companies[a]);
    }
    return this.companies[0];
  }

  initFilters() {
    let filters = this.userSessionService.getState('OrderFilters')
    if (![null, undefined].includes(filters)) {
      Object.keys(filters).forEach(key => {
        switch (key) {
          case 'company':
            this.companyIdsFilter = filters[key].map((company: any) => company.id)
            break;
          case 'assignmentOperator':
            this.assignmentOperatorFilter = filters[key]
            break;
          case 'statusId':
            this.statusIdFilter = filters[key].map((status: any) => status.id)
            break;
          case 'year':
            this.yearFilter = filters[key]
            break;
          default:
            console.log(filters[key]);
        }
      });
      return {
        company: this.companyIdsFilter,
        year: this.yearFilter,
        assignmentOperator: this.assignmentOperatorFilter,
        statusId: this.statusIdFilter
      }
    } else {
      return {}
    }
  }

  changeColumsStructure(event: any) {
    if (event.checked) {
      this.displayedColumns = this.longColumnsStructure
    } else {
      this.displayedColumns = this.shortColumnsStructure
    }


  }

}