<div class="container-fluid my-container">
	<div class="full-w">
		<div class="buttons-right my-2">
			<button style="background-color:transparent;" [disabled]="!formTargets.valid"
				matTooltip="{{ 'LABEL.SAVE' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
				(click)="update()">
				<mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
			</button>
		</div>
		<form *ngIf="doneCreatingForm" [formGroup]="formTargets">
			<div>
				<mat-checkbox appearance="fill" matNativeControl style="margin-right: 8px" 
					(change)="selectAll()"
					[checked]="allSelected"
					>{{allSelected ? 'Deseleziona tutte' : 'Seleziona tutte'}}</mat-checkbox>
				<mat-expansion-panel *ngFor="let society of societiesControls; let i=index" style="margin-top: 16px;" formArrayName="societies">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-checkbox appearance="fill" matNativeControl style="margin-right: 8px" 
								(change)="selectSociety(society)" 
								(click)="$event.stopPropagation()"
								[formControl]="getFormControl(society.controls['selected'])"
								[indeterminate]="someSelected(society)"
								[checked]="allCompaniesSelected(society)"
								></mat-checkbox>

							{{society.controls['society'].value.name}}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div *ngFor="let company of getCompaniesOfSociety(society)">
						<mat-checkbox appearance="fill" matNativeControl [formControl]="getFormControl(company.controls['selected'])" [checked]="getFormControl(company.controls['selected']).value">
							{{ company.controls['company'].value.name }}
						</mat-checkbox>
					</div>
				</mat-expansion-panel>
			</div>
		</form>
		<div class="buttons-right my-2">
			<button style="background-color:transparent;" [disabled]="!formTargets.valid"
				matTooltip="{{ 'LABEL.SAVE' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
				(click)="update()">
				<mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
			</button>
		</div>
	</div>
</div>