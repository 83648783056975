
<div class="container-fluid my-container">
  <div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
        <input type="text" [(ngModel)]="codeKwSearch" [value]="codeKwSearch" (ngModelChange)="searchByCode($event)"
            [placeholder]="translate.instant('LABEL.SEARCH')" />
    </div>
  </div>
  <div style="overflow-x: auto;">
    <app-admin-licence-filter (filterResult)="filterResultEmitted($event)"></app-admin-licence-filter>
    <app-data-table
      [data]="dataSource"
      [columns]="columns"
      [displayedColumns]="displayedColumns"
      [actions]="actions"
      sortActive="updatedAt"
      sortDirection="desc"
    ></app-data-table>
  </div>
</div>
