import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor() { }

  getMainRole(loggedUser: any) {
    let role: any;

    let profiles = [{ name: 'BackOffice', value: 1 }, { name: 'Tecnico Commerciale', value: 2 }, { name: 'Responsabile Commerciale', value: 3 },
    { name: 'Coordinatore', value: 4 }, { name: 'Responsabile Area', value: 5 }, { name: 'Affiliato', value: 5 },
    { name: 'Coordinatore Country', value: 6 }, { name: 'Area Manager', value: 7 }, { name: 'Direzione', value: 8 }, { name: 'Admin', value: 8 },
    { name: 'Top Management', value: 9 }, { name: 'Contabilita', value: 10 }, { name: 'Amministrazione', value: 11 },
    { name: 'HR', value: 12 }, { name: 'Responsabile Mkt', value: 13 }];
    // console.log('-------------- this.loggedUser[0] %o', loggedUser[0]);
    loggedUser[0].UsersProfilesRel.forEach((element: any) => {
      if (!role) {
        role = profiles.filter(function (e) { return e.name === element.name })[0];
      } else {
        profiles.filter(e => e.name === element.name)[0]?.value > role?.value ?
          role = profiles.filter(e => e.name === element.name)[0] : undefined;
      }
    });
    // console.log('-------------- ROLE %o', role);
    loggedUser[0].role = role?.name;
    // console.log("RETURN LOGGED USER -->", loggedUser);
    return loggedUser;
  }
}
