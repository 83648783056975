import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { TranslatorService } from 'src/app/services/translate.service';

@Component({
    selector: 'planner-riepilogo-dialog',
    templateUrl: './planner-riepilogo-dialog.html',
    styleUrls: ['./planner-riepilogo-dialog.scss'],
})
export class plannerRiepilogoDialogComponent implements OnInit {

    displayDate: string;
    displayDataRange: string;
    startDate: any = this.data.startDate;
    endDate: any = this.data.endDate;
    operators: { name: string; commessa: string; hours: number }[] = [];
    displayedColumns: string[] = ['name', 'commessa', 'hours'];
    noOrdersMessage: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private toastr: ToastrService,
        public translate: TranslatorService
    ) { }

    ngOnInit(): void {
        this.updateDisplayDate();
        this.processSummary(this.data.summary);
    }

    // Metodo per aggiornare il display della data
    updateDisplayDate(): void {

        this.displayDataRange = `<strong>${this.formatDateWithMoment(this.data.dataRangeStart)}</strong> - <strong>${this.formatDateWithMoment(this.data.dataRangeEnd)}</strong>`;

        const isInRange = moment(this.startDate).isBetween(this.data.dataRangeStart, this.data.dataRangeEnd, 'day', '[]') &&
            moment(this.endDate).isBetween(this.data.dataRangeStart, this.data.dataRangeEnd, 'day', '[]');

        if (isInRange) {
            this.noOrdersMessage = this.translate.instant('PLANNER_RIEPILOGO.NO_PLANNED_ORDERS');
        } else {
            this.noOrdersMessage = `${this.translate.instant('PLANNER_RIEPILOGO.OUT_OF_RANGE')} ${this.displayDataRange}`;
        }

        const dateDifference = this.getDateDifference(this.startDate, this.endDate);

        if (dateDifference > 5) {
            this.displayDate = `${this.formatDate(this.startDate)} - ${this.formatDate(this.endDate)}`;
        } else {
            this.displayDate = `${this.formatDate(this.startDate)}`;
        }

        this.displayDataRange = `${this.formatDateWithMoment(this.data.dataRangeStart)} - ${this.formatDateWithMoment(this.data.dataRangeEnd)}`;
    }

    processSummary(summary: any): void {
        const operatorData: { name: string; commessa: string; hours: number }[] = [];

        // Itera su tutte le chiavi (date) nel summary
        Object.keys(summary).forEach(dateKey => {
            const data = summary[dateKey];

            // Itera su tutte le commesse per ogni data
            data.commesse.forEach((commessa: any) => {
                // Itera sugli operatori all'interno di ciascuna commessa
                commessa.operators.forEach((operator: { name: string, role: string, hours: number }) => {
                    operatorData.push({
                        name: operator.name,
                        commessa: `${commessa.code} (${operator.role})`, // Combina codice commessa e ruolo
                        hours: operator.hours || 0
                    });
                });
            });
        });

        // Raggruppa i dati per operatore
        const groupedOperators = operatorData.reduce((acc, operator) => {
            if (!acc[operator.name]) {
                acc[operator.name] = {
                    commesse: new Map<string, Set<string>>(), // Mappa per codici commessa e ruoli associati (con Set per evitare duplicati)
                    totalHours: 0
                };
            }

            // Se la commessa esiste già, aggiungi il ruolo solo se non esiste già
            if (!acc[operator.name].commesse.has(operator.commessa)) {
                acc[operator.name].commesse.set(operator.commessa, new Set([operator.commessa]));
            }

            acc[operator.name].totalHours += operator.hours;
            return acc;
        }, {} as { [key: string]: { commesse: Map<string, Set<string>>, totalHours: number } });

        // Crea un array per la visualizzazione
        const allOperators: { name: string; commessa: string; hours: number }[] = [];

        Object.keys(groupedOperators).forEach(operatorName => {
            const operatorEntry = groupedOperators[operatorName];
            const commesseArray = Array.from(operatorEntry.commesse.keys());

            // Raggruppa tutti i codici commessa e i ruoli associati in una singola riga separati da '\n'
            const commesse = commesseArray.join('\n');

            allOperators.push({
                name: operatorName,
                commessa: commesse,  // Visualizza le commesse nella stessa riga
                hours: operatorEntry.totalHours
            });
        });

        // Ordina gli operatori per nome in ordine alfabetico
        allOperators.sort((a, b) => a.name.localeCompare(b.name));

        // Assegna gli operatori totali da visualizzare
        this.operators = allOperators;
    }


    get uniqueOperatorCount(): number {
        return new Set(this.operators.map(op => op.name)).size;
    }

    // Metodo per calcolare la differenza tra due date
    getDateDifference(date1: Date, date2: Date): number {
        const timeDifference = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(timeDifference / (1000 * 3600 * 24));
    }

    formatDateWithMoment(date: Date | string): string {
        return moment(date).format('DD/MM/YYYY');
    }

    // Metodo per formattare la data in 'gg/mm/yyyy'
    formatDate(date: Date): string {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

}

interface DialogData {
    startDate: Date;
    endDate: Date;
    summary: any; // La struttura summary che contiene gli operatori e le commesse
    dataRangeStart: Date;
    dataRangeEnd: Date;
}
