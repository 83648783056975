import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  baseApi = environment.rootApiPaperwork;
  /* baseApi = "http://localhost:80/"; */

  constructor(
    public libService: LibService,
  ) { }

  getTeams(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, 'teams');
  }

  getTeam(id: string): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `teams/${id.toString()}`);
  }

  getTeamOffice(id: string): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `teams/office/${id.toString()}`);
  }

  actionTeam(data: any, id?: string | number): Observable<any> {
    if (id) {
      return this.libService.putData<any>({team: data}, this.baseApi, `teams/${id}`);
    } else {
      return this.libService.postData<any>(data, this.baseApi, `teams/`);
    }
  }

  deleteTeam(id: number | string) {
    return this.libService.deleteData<any>({}, this.baseApi, `teams/${id.toString()}`);
  }
}
