<div mat-content class="Tooltip container">
    <h1 class="mt-5 mb-7">{{ formTitle }}</h1>
    <form [formGroup]="form" class="d-flex flex-column">
        <p>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.DESCRIZIONE_VISUALIZZA_CAUSALI' | translate }}</p>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_OPERATORE' | translate }}</mat-label>
            <mat-select formControlName="UserId">
                <input matInput [placeholder]="translate.instant('LABEL.SEARCH')" [formControl]="operatorSearchControl"
                    class="padd-input">
                <mat-option *ngFor="let user of filteredUsers" [value]="user.id">
                    {{ user.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'COMPANIES.START_DATE' | translate }} - {{
                'PLANNER.PLANNER_CALENDAR_DIALOG.IMPOSTA_DATA_INIZIO_ASSENZA' | translate }}</mat-label>
            <input matInput [matDatepicker]="startDate" formControlName="startDate" />
            <mat-hint>GG/MM/AAAA</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{ 'COMPANIES.END_DATE' | translate }} - {{
                'PLANNER.PLANNER_CALENDAR_DIALOG.IMPOSTA_DATA_FINE_ASSENZA' | translate }}</mat-label>
            <input matInput [matDatepicker]="endDate" [min]="minEndDate | date : 'yyyy-MM-dd'"
                formControlName="endDate" />
            <mat-hint>GG/MM/AAAA</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>

        <div *ngIf="boxDocumentEmptyBool" class="mt-8 mb-8">
            <mat-card-header class="d-flex justify-content-center">
                <mat-card-title><span>{{ contractDescription }}</span></mat-card-title>
            </mat-card-header>
        </div>
        <div *ngIf="boxDocumentBool" class="boxDocument">
            <mat-card-header class="d-flex justify-content-center mb-6">
                <mat-card-title>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.CONTRATTO_SELEZIONATO' | translate }}: <span>{{
                        contractDescription }}</span></mat-card-title>
            </mat-card-header>
            <p>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.DESCRIZIONE_SELEZIONA_CAUSALE' | translate }}</p>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SELEZIONA_LA_CAUSALE_DI_ASSENZA_LAVORO' | translate
                    }}</mat-label>
                <mat-select formControlName="WorkCausalId" (selectionChange)="onWorkCausalChange($event.value)">
                    <input matInput [placeholder]="translate.instant('LABEL.SEARCH')"
                        [formControl]="causalSearchControl" class="padd-input">
                    <mat-option *ngFor="let workCausal of workCausals" [value]="workCausal.id">
                        {{ workCausal.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="selectedWorkCausalDocuments.length > 0" class="mt-4 mb-6">
                <h4>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.DOCUMENTI_PER_CAUSALE_SELEZIONATA' | translate }}</h4>
                <div *ngFor="let document of selectedWorkCausalDocuments; let i = index"
                    class="d-flex justify-content-between align-items-center my-5">
                    <!-- Mostra il label del documento -->
                    <span class="document-label">{{ document.label }}</span>

                    <div class="d-flex justify-content-end align-items-center">
                        <!-- Mostra il nome del file selezionato -->
                        <span class="file-name">{{ selectedFiles[i]?.name || document.name }}</span>

                        <!-- Etichetta personalizzata per l'input -->
                        <label [for]="'fileInput_' + i"
                            [ngClass]="{'custom-file-label': true, 'valid': isFileSelected(i)}">
                            {{ 'PLANNER.PLANNER_CALENDAR_DIALOG.SCEGLI_FILE' | translate }}
                        </label>

                        <!-- Input file nascosto -->
                        <input type="file" id="fileInput_{{i}}" (change)="onFileSelected($event, i)"
                            class="file-input" />
                    </div>
                </div>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.ORE_DI_ASSENZA_PER_GIORNO' | translate }}</mat-label>
                <input matInput formControlName="hours" type="number" min="1" max="8"
                    placeholder="{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.INSERISCI_ORE' | translate }}" class="hours">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.DESCRIZIONE_FACOLTATIVA' | translate }}</mat-label>
                <input matInput formControlName="description"
                    placeholder="{{ 'PLANNER.PLANNER_CALENDAR_DIALOG.INSERISCI_DESCRIZIONE' | translate }}">
            </mat-form-field>
        </div>
    </form>
    <mat-dialog-actions class="justify-content-end">
        <div class="buttons-right my-2 d-flex align-items-center">
            <button style="background-color:transparent;" [matTooltip]="translate.instant('MARKETINGNOTES.back')"
                type="button" class="my-bounce btn btn-primary mx-2" (click)="back()">
                <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
            </button>

            <button style="background-color:transparent;" [matTooltip]="translate.instant('MARKETINGNOTES.save')"
                type="button" class="my-bounce btn btn-primary mx-2" (click)="onSave()" [disabled]="!form.valid">
                <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
            </button>
        </div>
    </mat-dialog-actions>
</div>