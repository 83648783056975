import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { NavService } from '../services/nav.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
@Component({
  selector: 'app-rapport-section',
  templateUrl: './rapport-section.component.html',
  styleUrls: ['./rapport-section.component.scss']
})
export class RapportSectionComponent implements OnInit {

  actions: any;

  @Input() selectedTabIndex: number = 0;
  @Input() selectedSubTabIndex: number = 0;
  @Input() itemId: any = null;

  constructor(
    public navService: NavService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
  }


tabChanged(tabChangeEvent: MatTabChangeEvent): void {
  this.selectedTabIndex = tabChangeEvent.index
  if (tabChangeEvent.index !== (null || undefined)) {
    if (tabChangeEvent.index === 0) {
      this.actions = [
      ];
    }
    if (tabChangeEvent.index === 1) {
      this.actions = [
        {
          type: 'dynamic',
          icon: 'faFileMedical',
          name: 'newSolicit',
          url: 'request/0',
          queryParams: { type: 2 }
        },
      ];
    }
    if (tabChangeEvent.index === 2) {
      this.actions = [
      ];
    }
    if (tabChangeEvent.index === 3) {
    this.actions = [
      { name: 'new', url: 'request/0', queryParams: { type: 3 } }
    ]
    }
   
  }


  this.navService.setActions(this.actions);
  this.cdr.detectChanges();
}



}
