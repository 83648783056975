import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { TranslatorService } from 'src/app/services/translate.service';
import { MarketingNoteService } from '../../../services/marketingNote.service'
import { EventService } from '../../../services/event.service'
import * as moment from 'moment';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventFormComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() eventTypes: any = [];

  sectionName = "events"

  id: any;

  marketingNote: any = null;
  event: any = null;

  translationList: any = []

  languageCodeList: any = [
    {
      label: 'Italiano',
      code: 'IT'
    },
    {
      label: 'Français',
      code: 'FR'
    },
    {
      label: 'Español',
      code: 'ES'
    },
    {
      label: 'English',
      code: 'EN'
    },
  ]

  constructor(
    public navService: NavService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public libService: LibService,
    private toastr: ToastrService,
    public translate: TranslatorService,
    private router: Router,
    private marketingNoteService: MarketingNoteService,
    private eventService: EventService,
  ) { }


  addTranslation(values: any): FormGroup {

    let translation: FormGroup = this.fb.group(values);

    return translation

  }


  determinesValue = (value: any, type: any) => {

    let valueStructure: any = null
    let tmpValue = [null, undefined, []].includes(value) ? 0 : value

    switch (type) {
      case 'boolean':
        valueStructure = parseInt(tmpValue) !== 0
        break;
      case 'number':
        valueStructure = parseInt(tmpValue)
        break;
      case 'string':
        valueStructure = value
        break;
    }

    return valueStructure
  }


  get translations(): FormArray {
    return <FormArray>this.form.get('translations');
  }

  update() {
    this.libService.lockPage('');
    console.log(this.form.getRawValue())

    let structure = this.form.getRawValue()

    let startDate = new Date(structure.startDate)
    let endDate = new Date(structure.endDate)
    let startTimeSplit = structure.startTime.split(':')
    let endTimeSplit = structure.endTime.split(':')

    let startDateVisibility = new Date(structure.startDateVisibility)
    let endDateVisibility = new Date(structure.endDateVisibility)
    let startTimeVisibilitySplit = structure.startTimeVisibility.split(':')
    let endTimeVisibilitySplit = structure.endTimeVisibility.split(':')

    let startDateRegistrations = new Date(structure.startDateRegistrations)
    let endDateRegistrations = new Date(structure.endDateRegistrations)
    let startTimeRegistrationsSplit = structure.startTimeRegistrations.split(':')
    let endDateRegistrationsSplit = structure.endTimeRegistrations.split(':')


    let startDateMoment = moment(startDate).utc(true).hours(startTimeSplit[0]).minutes(startTimeSplit[1]).seconds(0).milliseconds(0)
    let endDateMoment = moment(endDate).utc(true).hours(endTimeSplit[0]).minutes(endTimeSplit[1]).seconds(0).milliseconds(0)

    let startDateVisibilityMoment = moment(startDateVisibility).utc(true).hours(startTimeVisibilitySplit[0]).minutes(startTimeVisibilitySplit[1]).seconds(0).milliseconds(0)
    let endDateVisibilityMoment = moment(endDateVisibility).utc(true).hours(endTimeVisibilitySplit[0]).minutes(endTimeVisibilitySplit[1]).seconds(0).milliseconds(0)

    let startDateRegistrationsMoment = moment(startDateRegistrations).utc(true).hours(startTimeRegistrationsSplit[0]).minutes(startTimeRegistrationsSplit[1]).seconds(0).milliseconds(0)
    let endDateRegistrationsMoment = moment(endDateRegistrations).utc(true).hours(endDateRegistrationsSplit[0]).minutes(endDateRegistrationsSplit[1]).seconds(0).milliseconds(0)

    structure.startDate = startDateMoment.format()
    structure.endDate = endDateMoment.format()

    structure.startDateVisibility = startDateVisibilityMoment.format()
    structure.endDateVisibility = endDateVisibilityMoment.format()

    structure.startDateRegistrations = startDateRegistrationsMoment.format()
    structure.endDateRegistrations = endDateRegistrationsMoment.format()

    console.log(structure)

    this.eventService.createSettings(structure).subscribe((response: any) => {
      //console.log(response)

      this.toastr.success(
        'ok', this.translate.instant('LABEL.Info'));

      this.id = response.id
      this.form?.get('id')?.setValue(this.id)
      this.router.navigate([`/events/`, this.id]);

      this.libService.unlockPage();
    })
      


  }

  back() {
    this.router.navigate([`events/`]);
  }

  initTranslations() {

    this.languageCodeList.forEach((lang: any) => {
      let currentTranslation = this.translationList.find((translation: any) => translation.languageCode == lang.code)
      let structure: any = {
        id: [null],
        languageCode: [lang.code, Validators.required],
        text: [null, Validators.required],
      }

      if (currentTranslation?.id) structure.id = currentTranslation?.id
      if (currentTranslation?.languageCode) structure.languageCode = currentTranslation?.languageCode
      if (currentTranslation?.text) structure.text = currentTranslation?.text

      this.translations.push(this.addTranslation(structure))
    })
  }

  ngOnInit(): void {
    this.libService.lockPage('');
    this.id = this.route.snapshot.paramMap.get('id');

    // Creazione Form
    this.form = this.fb.group({
      id: [],
      name: [null, Validators.required],
      description: [null, Validators.required],
      everyUserCanParticipate: [true],
      everyExternalUserCanParticipate: [true],
      startDate: [false, Validators.required],
      startTime: ["00:00", Validators.required],
      endDate: [false, Validators.required],
      endTime: ["23:59", Validators.required],
      startDateVisibility: [false, Validators.required],
      startTimeVisibility: [false, Validators.required],
      endDateVisibility: [false, Validators.required],
      endTimeVisibility: [false, Validators.required],
      startTimeRegistrations: [false, Validators.required],
      startDateRegistrations: [false, Validators.required],
      endDateRegistrations: [false, Validators.required],
      endTimeRegistrations: [false, Validators.required],
      eventType: [null, Validators.required],
    })

    if (['0', 0, null, undefined].includes(this.id)) {
      this.libService.unlockPage();
    } else {
      this.initForm()
    }

  }

  initForm() {
    this.eventService.settings(this.id).subscribe((response: any) => {
      if (response) {
        this.event = response


        moment.locale('en')

        let startDate = new Date(this.event.startDate)
        let endDate = new Date(this.event.endDate)
        let startDateMoment = moment(startDate).utc()
        let endDateMoment = moment(endDate).utc()

        let startDateVisibility = new Date(this.event.startDateVisibility)
        let endDateVisibility = new Date(this.event.endDateVisibility)
        let startDateVisibilityMoment = moment(startDateVisibility).utc()
        let endDateVisibilityMoment = moment(endDateVisibility).utc()

        let startDateRegistrations = new Date(this.event.startDateRegistrations)
        let endDateRegistrations = new Date(this.event.endDateRegistrations)
        let startDateRegistrationsMoment = moment(startDateRegistrations).utc()
        let endDateRegistrationsMoment = moment(endDateRegistrations).utc()

        this.form.patchValue(response)
        this.form?.get('startTime')?.setValue(startDateMoment.format('HH:mm'))
        this.form?.get('endTime')?.setValue(endDateMoment.format('HH:mm'))

        this.form?.get('startTimeVisibility')?.setValue(startDateVisibilityMoment.format('HH:mm'))
        this.form?.get('endTimeVisibility')?.setValue(endDateVisibilityMoment.format('HH:mm'))

        this.form?.get('startTimeRegistrations')?.setValue(startDateRegistrationsMoment.format('HH:mm'))
        this.form?.get('endTimeRegistrations')?.setValue(endDateRegistrationsMoment.format('HH:mm'))

        this.form?.get('eventType')?.setValue({ id: response?.eventType?.id })

        this.libService.unlockPage();
      } else {
        this.libService.unlockPage();
      }
    })
  }

  setCode() {
    if ([undefined, null, 0, '0'].includes(this.id)) {
      let label = this.form?.get('label')?.value
      let code = `marketing_notes_${this.snakeCase(label)}`
      this.form?.get('code')?.setValue(code)
    }
  }

  snakeCase(string: any) {
    return string.replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word: any) => word.toLowerCase())
      .join('_');
  };

}