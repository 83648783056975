<h1 mat-dialog-title>{{ "REQUEST.esitoSopralluogo" | translate }}</h1>

<div mat-dialog-content>

  <form [formGroup]="form" class="form-container">

    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" formControlName="esit">
      <mat-radio-button color="primary" class="example-radio-button" *ngFor="let action of actions" [value]="action">
        {{ "CLOSEREQUEST." + action | translate }}
      </mat-radio-button>
    </mat-radio-group>

  </form>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="form.value" [disabled]="!form.valid">{{ "LABEL.SAVE" | translate }}</button>
  <button mat-button [mat-dialog-close]="">{{ "LABEL.CANCEL" | translate }}</button>
</div>