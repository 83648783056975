import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { UserSessionService } from './user-session.service';
import { GuardService } from './guard.service';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService {

  constructor(
    public LibService: LibService,
    public AuthService: AuthService,
    public userSessionService: UserSessionService,
    public guardService: GuardService
  ) {
    super(LibService, AuthService)
    this.baseApi = environment.rootApiFoundation
    this.model = 'events'
  }

  allWithPagination(skip: number, take: number) {
    console.log("Events - allWithPagination")
    let skipOption = skip || 0;
    let takeOption = take || 10;

    let options = {
      skip: skipOption,
      take: takeOption
    }
    return this.libService.postDataWithAccessToken<any>(this.tokenId, options, this.baseApi, `${this.model}/withPagination`)
  }

  settings(id: any): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/${id}/settings`);
  }

  createSettings(attributes: any): Observable<any> {
    return this.libService.postDataWithAccessToken<any>(this.tokenId, attributes, this.baseApi, `${this.model}/createSettings`);
  }

  registrations(id: any): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/${id}/registrations`);
  }

  documents(id: any): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/${id}/documents`);
  }

  buildings(id: any): Observable<any> {
    return this.libService.getDataWithAccessToken<any>(this.tokenId, this.baseApi, `${this.model}/${id}/buildings`);
  }

  addBuilding(id: any, building: any): Observable<any> {
    let attributes = {
      building: building
    }
    return this.libService.postDataWithAccessToken<any>(this.tokenId, attributes, this.baseApi, `${this.model}/${id}/addBuilding`);
  }

}