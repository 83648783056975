import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class StatusActivitiesService {

  baseApi = environment.rootApiCrm + 'v1.0';
  constructor(public libService: LibService) { }

  getAllActivityStatus(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `/statusActivities`);
  }

}
