import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'material-search',
  templateUrl: './material-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialSearchComponent implements OnInit, OnDestroy {
  @Output() searchTrigger = new EventEmitter<string>();
  subscription: Subscription;
  searchInput: UntypedFormControl = new UntypedFormControl('');

  ngOnInit(): void {
    this.subscription = this.searchInput.valueChanges.subscribe((query) =>
      this.searchTrigger.emit(query.toLowerCase())
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
