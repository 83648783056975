import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-subject-relation-modal',
  templateUrl: './subject-relation-modal.component.html',
  styleUrls: ['./subject-relation-modal.component.scss']
})
export class SubjectRelationModalComponent implements OnInit {

  editMode: boolean = false
  form: UntypedFormGroup

  subjectRelationTypes: any = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
    )
  {
    this.form = this.fb.group({
      subjectFromId: [null, [Validators.required]],
      subjectToId: [null, [Validators.required]],
      subjectRelationTypeId: [null, [Validators.required]],
    })

    if(data){
      if(data?.subjectFromId) this.form.get('subjectFromId')?.setValue(`${data?.subjectFromId}`)
      if(data?.subjectRelationTypes) this.subjectRelationTypes = data?.subjectRelationTypes
    }


  }

  ngOnInit(): void {
  }

  clearSubject(){
    console.log('clearSubject')
    this.form?.get('subjectToId')?.setValue(null)
  }

  selSubject(event: any) {
    console.log('Selected: ', event);
    if(event?.id){
      this.form?.get('subjectToId')?.setValue(event.id)
    } 
  }

  requiresApproval(subjectRelationType: any){
    let requiresApprovalString = ``
    if(subjectRelationType.requiresApproval) requiresApprovalString = 'SUBJECT_RELATION_TYPES.requiresApproval'
    return requiresApprovalString
  }

}
