import { GanttTask } from './gantt';
import { OrderPlan } from './order-plan';

export interface TaskListRel {
  taskId: number;
  userId: number;
}

export interface TaskDates {
  orderid: number;
  start_date: Date;
  start_date_compare: Date;
  end_date: Date;
  end_date_compare: Date;
}

export class OrderTask implements GanttTask {
  id: number | string;
  orderid: number;
  text: string;
  start_date: Date;
  end_date: Date;
  duration: number;
  open: boolean;
  progress: number = 0;
  parent: number;
  taskListRel: TaskListRel[];
  statusClass: string;
  updated: boolean = false;
  initialDates: { start_date: Date; end_date: Date };

  get class(): string {
    if (this.updated) {
      return `order-task ${this.statusClass} updated`;
    }
    return `order-task ${this.statusClass}`;
  }

  constructor(order: OrderPlan, parent: number) {
    this.parent = parent;
    this.id = 0;
    this.orderid = order.id;
    this.text = order.code;
    this.duration = order.totalHours;
    this.statusClass = order.statusClass;
    this.id = this.defineUid();
    this.setDates(new Date(order.tsStart * 1000), new Date(order.tsEnd * 1000));

    if (order.hasTasks) {
      // list of task's ids references
      this.taskListRel = order.tasks.map((task) => ({
        taskId: task.id,
        userId: task.userId,
      }));
    }
  }

  private defineUid(): string {
    const randomNumber = Date.now() * Math.floor(Math.random() * 100);
    return `${this.parent}-${this.orderid}-${randomNumber}`;
  }

  public copyDates(): TaskDates {
    return {
      orderid: this.orderid,
      start_date: this.start_date,
      start_date_compare: this.initialDates.start_date,
      end_date: this.end_date,
      end_date_compare: this.initialDates.end_date,
    };
  }

  public setDates(dateStart: any, dateEnd: any): void {
    this.start_date = dateStart;
    this.end_date = dateEnd;
    this.initialDates = {
      start_date: this.start_date,
      end_date: this.end_date,
    };
  }

  public updateDates(): void {
    this.initialDates.start_date = this.start_date;
    this.initialDates.end_date = this.end_date;
    !this.updated && (this.updated = true);
  }

  public restoreDates(): void {
    this.start_date = this.initialDates.start_date;
    this.end_date = this.initialDates.end_date;
  }
}
