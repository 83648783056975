<div class="container-fluid my-container">
  <h2 class="float-left">{{ 'LABEL.EDIT' | translate:{'field': translate.instant('LABEL.DOMAIN') } }} <button type="button" class="btn btn-primary" (click)="goBack()"><<</button></h2>
  <form [formGroup]="dashboardForm" fxLayout="column">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{ 'LABEL.ID' | translate }}</mat-label>
      <input matInput readonly="true" formControlName="id" readonly>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{ 'LABEL.NAME' | translate }}</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{ 'LABEL.DASHBOARD' | translate }} {{ 'LABEL.ID' | translate }}</mat-label>
      <input matInput formControlName="dashId">
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>{{ 'LABEL.COUNTRY' | translate }}</mat-label>
      <mat-select formControlName="country">
        <mat-option *ngFor="let country of countries" [value]="country.value">
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <button mat-flat-button (click)="save()">{{ 'LABEL.SAVE' | translate }}</button>
</div>
