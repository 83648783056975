import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map, Subscription, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FileUploadModalComponent } from '../modals/file-upload-modal/file-upload-modal.component';
import { Dashboard } from '../models/dashboard';
import { DashboardService } from '../services/dashboard.service';
import { NavService } from '../services/nav.service';
import { NewsService } from '../services/news.service';
import { LibService } from '../services/libService';
import { TranslatorService } from '../services/translate.service';
import { UserSessionService } from '../services/user-session.service';
import { CompanyManagementService } from '../services/company-management.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ɵNullViewportScroller } from '@angular/common';


@Component({
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss']
})
export class CockpitComponent implements OnInit, OnDestroy {

  title = "Home";
  subTitle = "EDAC - Home";
  news: any;
  dashboardsByCategory: any = [];
  innerHeight: any

  ss: any;
  user: any;

  sended: boolean = false;
  certurl: SafeResourceUrl;

  workingCompany: any;

  private sub: Subscription;

  dashboards$ = this.dashboardService.getCockpit().pipe(
    map(dashboards => dashboards.map((dashboard: any) => {
      return {
        dashId: dashboard.DashboardId,
        name: dashboard.Name
      } as Dashboard
    })),
    map((dashboards: any[]) => dashboards.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    )
  )

  @ViewChild('iFrameCruscotto') iFrameCruscotto: ElementRef;

  constructor(public navService: NavService,
    private dashboardService: DashboardService,
    private router: Router,
    private authService: AuthService,
    public translate: TranslatorService,
    private newsService: NewsService,
    private dialog: MatDialog,
    private libService: LibService,
    private toastr: ToastrService,
    private userSessionService: UserSessionService,
    private companyManagementService: CompanyManagementService,
  ) {

    window.addEventListener(
      "message",
      (event) => {
        if (`${event.origin}/` !== environment.rootAlbertcruscotto) return;
        console.log(event.data);
        let structure = JSON.parse(event.data);
        if (structure.target && structure.type == 'goTo') {
          if (Array.isArray(structure.target)) {
            this.goTo(structure.target)
          } else {
            let dashboardStructure = structure?.target?.dashboardId ? {dashId: structure.target.dashboardId} : null
            let userEmail = structure.target.userEmail
            if (dashboardStructure && userEmail) this.openDashboardToExt(dashboardStructure, userEmail)
          }

        }
      },
      false,
    );
  }

  goTo(route: any = []) {
    this.router.navigate(route);
  }

  checkRAProfile() {
    let currentUser = this.userSessionService.getState('user');
    let profiles = currentUser?.UsersProfilesRel || []
    let companies = currentUser?.UsersCompaniesRel || []
    let idSedeAppartenenza = currentUser?.idSedeAppartenenza;
    // console.log('profiles')
    // console.log(profiles)
    let raProfile = profiles.find((profile: any) => profile?.code == "PRD-RA")
    let raProfileCode = raProfile?.code;
    let itProfile = companies.find((company: any) => company?.id == idSedeAppartenenza)
    let profileCountry = itProfile?.country;
    let RAProfileIT: boolean = (profileCountry === "IT") && (raProfileCode === "PRD-RA");
    // console.log(RAProfileIT)
    // console.log(profileCountry)
    // console.log(raProfile)
    return RAProfileIT
  }

  loadLabels() {
    this.translate.get('LABEL.COCKPIT').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {

    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    this.loadLabels();
    console.log(this.authService.user());

    this.navService.setActions([]);
    this.libService.lockPage('');
    this.newsService.getNews().subscribe(n => {
      this.libService.unlockPage();
      console.log('news %o', n)
      this.news = n.data.reverse().slice(0, 10);
    })
    this.initDashboardsByCategory()




    this.ss = window.sessionStorage;
    this.user = this.authService.user();
    console.log('send message to iframe %o', this.ss, this.user);
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
    ];
    this.navService.setActions(actions);

    this.innerHeight = (window.innerHeight - 74) + 'px';

    if (this.checkRAProfile()) {
      this.sub = this.userSessionService.$changeCompanyDetector.subscribe((res: any) => {
        this.setIFrameUrl()
      })
    }

  }


  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  initDashboardsByCategory() {
    this.dashboards$.subscribe(dashboad => {
      for (let i = 0; i < dashboad.length; i++) {
        let splitName: any = dashboad[i].name.split('_')

        // Cerco nel nome la categoria, se non è presente di default è 'Dashboard'
        let currentCategoryIndex = splitName[1] == undefined ? 'Dashboard' : `Dashboard ${splitName[1]}`
        let currentCategory = this.retrieveInstantiatedCategory(currentCategoryIndex)

        // Istanzio la categoria se non esiste
        if (currentCategory == undefined) {
          this.dashboardsByCategory.push({ label: currentCategoryIndex, data: [] })
          currentCategory = this.retrieveInstantiatedCategory(currentCategoryIndex)
        }

        // aggiungo la dashbord alla categoria corrispondente
        currentCategory.data.push({ dashId: dashboad[i].dashId, name: splitName[0] })

      }
      this.dashboardsByCategory.sort((a: any, b: any) => {
        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })

    })
  }

  retrieveInstantiatedCategory(label: any) {
    return this.dashboardsByCategory.find((category: any) => category.label == label)
  }

  //TODO: cancel this
  openDialogTest(test: string) {
    this.dialog.open(FileUploadModalComponent, {
      data: {
        inputMode: test, toEntityId: 350220
      }
    })
      .afterClosed()
      .subscribe(res => {
        console.log(res);

      })
  }

  viewDashboard(dashboard: Dashboard) {
    this.router.navigate(['dashboard/' + dashboard.dashId])
  }

  openDashboardToExt(dashboard: any, userEmail: any = null) {
    //window.open('dashboard/'+ dashboard.dashId, "_blank");
    this.dashboardService.getDashboardUrl(dashboard.dashId, userEmail).subscribe((res: any) => {
      if (!(res == null) && !(res['url'] == null))
        window.open(`${res['url']}&printEnabled=true`, "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=400, height=400");
    })
  }

  setIFrameUrl() {
    console.log('setIFrameUrl')
    let currentUser = this.userSessionService.getState('user');
    let userToken = currentUser?.email
    let currentWorkingCompany = this.userSessionService.getState('working_company')
    let companyId = currentWorkingCompany.id

    this.certurl = `${environment.rootAlbertcruscotto}Cruscotto/Index?token=${userToken}&idSede=${companyId}`;

  }

  initIFrame() {
    console.log('initIFrame')

    var frame: HTMLIFrameElement = document.getElementById('CertificatesIframe') as HTMLIFrameElement;
    if (frame != null) {
      this.sended = true;
      frame.contentWindow?.postMessage('sstorage', this.ss);
    }
  }


  ngDoCheck() {

    if (this.sended == false) {
      if (this.checkRAProfile()) {

        // Settaggio sede prima visibilità utente corrente
        let currentUser = this.userSessionService.getState('user');
        let userCompanies = currentUser.UsersCompaniesRel
        let idSedeAppartenenza = currentUser.idSedeAppartenenza
        let currentOpenCompanies = userCompanies.filter((company: any) => company.isOpen == true)
        let currentWorkingCompany = this.userSessionService.getState('working_company')

        let raCompany = currentOpenCompanies.find((company: any) => company.id == idSedeAppartenenza) || currentOpenCompanies[0]

        if (currentWorkingCompany.code == '*' && currentOpenCompanies.length > 0) {
          let res = {
            "isOpen": true,
            "name": raCompany.name,
            "code": raCompany.code,
            "id": raCompany.id
          }
          this.userSessionService.saveState('working_company', res);

          this.companyManagementService.add(res);
        }

        // Settaggio cruscotto
        this.setIFrameUrl()
        this.initIFrame()
      }


    }

  }

}
