import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { LibService } from '../../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { UserSessionService } from '../../services/user-session.service';
import { NavService } from '../../services/nav.service';
import { TranslatorService } from '../../services/translate.service';
import { MatPaginator } from '@angular/material/paginator';
import { ActionService } from '../../services/action.service'
import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';
import { MarketingNoteService } from '../../services/marketingNote.service'


@Component({
  selector: 'app-marketing-note-settings',
  templateUrl: './marketing-note-settings.component.html',
  styleUrls: ['./marketing-note-settings.component.scss']
})

export class MarketingNoteSettingsComponent implements OnInit, OnDestroy {

  itemType = "marketing_notes"
  sectionName = "marketing-note-settings"
  dataSource: any;
  kwSearch: any;
  title: string; //= this.translate.instant('SIDEBAR.Sedi');
  subTitle: string;// = "EDAC - " + this.translate.instant('SIDEBAR.Sedi');
  sort: MatSort

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.sort = sort
  }

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild('itemDialog') itemDialog: TemplateRef<any>
  @ViewChild('licensesDialog') licensesDialog: TemplateRef<any>

  currentUrl: string;

  searchTimeout: any;

  marketingNoteList: any = [];

  constructor(public navService: NavService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    public translate: TranslatorService,
    private matDialog: MatDialog,
    private actionService: ActionService,
    private marketingNoteService: MarketingNoteService,
  ) {
    this.dataSource = new MatTableDataSource();
  }


  displayedColumns = ['id', 'label', 'subject', 'request', 'disabled'];

  structure: any = {
    id: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
    name: {
      type: 'numeric',
      required: true,
      disabled: false,
      readOnly: false
    },
  }



  columns: any = [
    {
      name: 'id',
      flex: '6',
      title: this.getTitle('id'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item.id
    },
    {
      name: "label",
      title: this.getTitle('label'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => item.label
    },
    {
      name: "subject",
      title: this.getTitle('subject'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.getBooleanLabel(item?.subject)
    },
    {
      name: "request",
      title: this.getTitle('request'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.getBooleanLabel(item?.request)
    },
    {
      name: "disabled",
      title: this.getTitle('disabled'),
      cssClass: (item: any) => ['column-padding', `${item?.disabled ? 'disabled' : ''}`],
      value: (item: any) => this.getBooleanLabel(item?.disabled)
    },
  ]

  getTitle(type: any) {
    let title: any = this.translate.get('LABEL.NOT_PRESENT')
    switch (type) {
      case 'id':
        title = this.translate.get('LABEL.ID')
        break;
      case 'label':
        title = this.translate.get('LABEL.name')
        break;
      case 'subject':
        title = this.translate.get('LABEL.forSubject')
        break;
      case 'request':
        title = this.translate.get('LABEL.forRequest')
        break;
      case 'disabled':
        title = this.translate.get('LABEL.disabled')
        break;

    }
    return title
  }


  getBooleanLabel(value: any) {
    if ([null, undefined].includes(value)) {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    } else {
      return value ? this.translate.instant('LABEL.YES') : this.translate.instant('LABEL.NO') as string
    }
  }



  actions: any = [];


  setUserActions() {
    // SHOW
    this.actions.push({
      label: of(''),
      icon: (item: any) => {
        return this.iconRoutine(item);
      },
      onClick: (item: any) => this.goToPage(item),
      color: 'primary'
    })


    // DESTROY
    if (this.actionService.checkPermissions(`${this.itemType}_destroy`)) {
      this.actions.push({
        label: of(''),
        icon: (item: any) => {
          return 'delete';
        },
        onClick: (item: any) => this.deleteItem(item),
        color: 'danger'
      })
    }

  }

  iconRoutine(subject: any) {
    return 'edit';
  }

  deleteItem(item: any) {

    if (confirm("Sei sicuro di voler cancellare l'Action?") == true) {
      this.libService.lockPage('');

      this.actionService.destroy(item.id).subscribe((responseData: any) => {
        console.log('Destroy Item response %o', responseData);
        this.loadData()
        this.libService.unlockPage();
      }, err => {
        console.log(err)
        this.libService.unlockPage();
      });
    }

  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }



  openItemDialog(item: any) {
    //this.mapsSocietyState(e);
    const dialogRef = this.matDialog.open(this.itemDialog, {
      panelClass: 'custom-dialog-container',
      width: '60%'
    })
  }


  ngOnInit(): void {

    this.loadData();
    this.setUserActions();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
      { name: 'new', url: 'actions/0' },
      //{ name: 'export', url: '' },
    ];
    this.navService.setActions(actions);

    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }

  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  search(e: any) {
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();

    clearTimeout(this.searchTimeout)
    // this.searchTimeout = setTimeout(() => this.updateCompaniesData(), 1000)
  }



  loadData(): void {

    this.libService.lockPage('');

    this.marketingNoteService.index().subscribe((responseData: any) => {
      console.log('loadDataSets response %o', responseData);
      this.libService.unlockPage();
      this.marketingNoteList = responseData
      this.dataSource = responseData;
    }, err => {
      console.log(err)
    });

  }

  goToPage(item: any) {
    this.router.navigate([`settings/${this.sectionName}/` + item.id]);
  }

  saveForm(attributes: any) {
    console.log(attributes)
  }

  filter(event: any) {
    console.log(event)
    let actions = this.marketingNoteList.filter((action: any) => action.TranslationCategoryId == event.value)
    this.dataSource = actions
  }


}
