import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Survey } from '../models/survey';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  baseApi = environment.rootApiCrm + 'v1.0/survey'

  constructor(private libService: LibService) { }

  create(survey: Survey){
    return this.libService.postData(survey, this.baseApi, '')
  }
}
