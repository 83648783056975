import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LibService } from 'src/app/services/libService';
import { combineLatest } from 'rxjs';
import { SubjectService } from 'src/app/services/subject.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/auth/auth.service';

import { OriginService } from 'src/app/services/origin.service';
import { ChannelService } from 'src/app/services/channel.service';

//import { SubjectBuildingRelationTypeService } from 'src/app/services/subject-building-relation-type.service';

interface formInterface {
  id: number,
  practiceId: number,
  pecType: number,
  description: string,
  authorId: number,
  itemId: number,
  stateId: number,
  subject: subject,
  insertSubjectType: number,
  subjectAlias?: string
};

interface subject {
  id?: number,
  name?: string,
  surname?: string,
  legalEntity?: string,
  email?: string,
  telephone2?: string,
}

@Component({
  selector: 'app-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: ['./building-form.component.scss']
})
export class BuildingFormComponent implements OnInit {

  @Input() item: any;
  @Input() companies: any;
  @Output() closeModal = new EventEmitter;
  @Input() requestForm: FormGroup;
  @Input() form: FormGroup;
  @Input() subjectBuildingRelationTypes: any = []

  pecTypes: any = []

  toDisable = false
  isNew = true

  contactChannels$ = this.channelService.getAllChannels();

  origins$ = this.originService.getAllOrigins();

  //pecTypes$ = this.pecTypeService.index();

  userName = this.authService.user().name;

  practices: any = []

  insertSubjectTypes: any = [
    'search', 'new'
  ]

  disableSearchBar: boolean = false;
  disableSearchBarButton: boolean = false;
  disableCurrentLocationButton: boolean = false;
  disableGeoButton: boolean = false;

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private libService: LibService,
    public subjectService: SubjectService,
    public orderService: OrderService,
    public authService: AuthService,
    public originService: OriginService,
    public channelService: ChannelService,
    //public subjectBuildingRelationTypeService: SubjectBuildingRelationTypeService,
  ) {
  }

  get buildingForm() {
    if (this.requestForm) return this.requestForm.controls['building'] as FormGroup
    if (this.form) return this.form as FormGroup
    return {} as FormGroup
  }

  getInsertSubjectType() {
    return this.buildingForm?.get('insertSubjectType')?.value;
  }

  initSubjectBuildingRelationTypeService() {
    /*
    if (this.subjectBuildingRelationTypes == null) {
      this.subjectBuildingRelationTypeService.index().subscribe((res: any) => {
        this.subjectBuildingRelationTypes = res
      })
    }
      */
  }



  ngOnInit(): void {
    console.log('SubjectFormComponent')

    this.initSubjectBuildingRelationTypeService()

    if (this.buildingId) {
      this.buildingForm?.patchValue(this.item)
      this.disableSearchBar = true;
      this.disableSearchBarButton = true;
      this.disableCurrentLocationButton = true;
      this.disableGeoButton = false;
    }

  }

  openBuildingModal() {
    console.log('openBuildingModal')
  }



  ngOnChanges(changes: SimpleChanges): void { }


  createOrUpdate() {
    this.libService.lockPage('');
    let id = this.buildingForm?.get('id')?.value || null

    // Select current PecType
    let pecTypeValue = this.buildingForm?.get('pecTypeValue')?.value
    this.buildingForm?.get('pecType')?.setValue({ id: pecTypeValue })
    /*
        if (id) {
          this.pecService.update(this.item?.id?.toString(), this.form.getRawValue()).subscribe((res: any) => {
            //console.log(res);
            this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
            this.closeModal.emit(true);
            this.libService.unlockPage();
          })
        } else {
          this.pecService.create(this.form.getRawValue()).subscribe((res: any) => {
            //console.log(res);
            this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
            this.closeModal.emit(true);
            this.libService.unlockPage();
          })
        }
          */
  }


  get buildingId() {
    return this.buildingForm?.get('id')?.value
  }

  save() {
    //this.checkduplicate()

  }

  itIsAFreeSubject() {
    return this.form?.get('freeSubject')?.value || false
  }

  theSubjectIsPresent() {
    return this.form?.get('subject')?.value || false
  }

  fullname() {
    let complainant: any = this.item?.complainant
    return `${complainant?.name} ${complainant?.surname}`
  }

  public formControlIsValid(formGroup: any, formCtrl: any): boolean {
    if (formGroup.controls.hasOwnProperty(formCtrl)) {
      if (!formGroup.controls[formCtrl].touched) return true;
      return formGroup.controls[formCtrl].valid;
    }
    return false;
  }

}