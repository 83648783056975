import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivityTypeReasons } from '../models/activityTypeReasons';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class ActivityTypeReasonsService {

  baseApi = environment.rootApiCrm + 'v1.0';
  constructor(private libService: LibService) { }

  getAllActivityTypeReasons(): Observable<any> {
    return this.libService.getData<any>(this.baseApi, `/activityTypeReasons`);
  }

  getById(id: any): Observable<ActivityTypeReasons> {
    return this.libService.getData<any>(this.baseApi, `/activityTypeReason/${id}`);
  }

  createAT(workType: any): Observable<string> {
    return this.libService.postData<any>(workType, this.baseApi, `/activityTypeReason`)
  }

  updateAT(at: any): Observable<string> {
    return this.libService.postData<any>(at, this.baseApi, `/activityTypeReason/${at.id}`)
  }

  deleteAT(at: any) {
    return this.libService.deleteData<any>(at, this.baseApi, `/activityTypeReason/${at.id.toString()}`);
  }
}
