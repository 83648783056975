import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibService } from '../../services/libService';
import { TranslatorService } from '../../services/translate.service';
import { BuildingService } from '../../services/building.service';
import { SubjectService } from '../../services/subject.service';
import { DatatableAction, DatatableColumn } from '../../shared/data-table/data-table.component';
import { of } from "rxjs";

@Component({
    selector: 'app-building-modal',
    templateUrl: './building-modal.component.html',
    styleUrls: ['./building-modal.component.scss']
})
export class BuildingModal implements OnInit {

    editMode: boolean = false
    formIsActive: boolean = false
    titleLabel: string = ''
    textLabel: string = ''
    confirmButtonLabel: any = 'LABEL.ADD'
    cancelButtonLabel: any = 'LABEL.CANCEL'

    item: any = null
    companies: any = []
    toDisable = false
    isNew = true

    type: any = null

    insertMethod: any = 'search'
    subjectBuildingRelationTypes: any = null


    displayedColumns = ['id', 'name', 'relationType'];
    columns: DatatableColumn<any>[] = [
        {
            name: 'id',
            flex: '5',
            title: this.translate.get('LABEL.ID'),
            cssClass: item => ['column-padding'],
            value: item => item?.building?.id
        },
        {
            name: 'name',
            title: this.translate.get('LABEL.CODE'),
            cssClass: item => ['column-padding', 'bold-text'],
            value: item => item?.building?.streetName
        },


        {
            name: 'relationType',
            title: this.translate.get('SUBJECT_BUILDING_RELATION_TYPES.relationType'),
            cssClass: item => ['column-padding'],
            value: item => this.translate.instant(`SUBJECT_BUILDING_RELATION_TYPES.${item?.subjectBuildingRelationType?.code}`)
        },
    ]
    actions: DatatableAction<any>[] = [
        {
            label: of(''),
            icon: item => {
                //'edit',
                let unchecked = 'radio_button_unchecked'
                let checked = 'radio_button_checked'
                let currentIcon = this.item?.id == item?.id ? checked : unchecked
                return currentIcon
            },
            onClick: item => this.select(item),
            color: 'primary'
        },

    ];
    dataSource: any;

    form: FormGroup
    formBuilding: FormGroup

    reasons: any = null

    estimateDeliveryMethods: any = null

    requiresNotesField: boolean = false
    localData: any
    nextStateForCancellation: any = ''

    listOfStatesAvailableForMigration: any = []

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private libService: LibService,
        private translate: TranslatorService,
        public buildingService: BuildingService,
        private fb: FormBuilder,
        private subjectService: SubjectService,
    ) {

        if (data) {
            console.log(data)

            if (data.form) {
                this.form = data.form
            }

            if (data.subjectBuildingRelationTypes) {
                this.subjectBuildingRelationTypes = data.subjectBuildingRelationTypes
            }

            if (data.type) {
                this.type = data.type
            }

        }



    }

    disabledAddModalButton() {
        let status = true
        if (this.item != null) status = false
        return false
    }

    select(item: any) {
        this.item = null // Reset Item
        this.item = item
    }

    showForm() {
        return this.insertMethod == 'insert' || (this.insertMethod == 'search' && this.buildingId)
    }

    selBuilding(event: any) {
        if (event) {
            let id = event.id
            this.item = null // Reset Item
            this.buildingService.findBuildingById(parseInt(id)).subscribe((res: any) => {
                this.item = res
                this.formBuilding?.patchValue(this.item)
            })
        }
    }

    changeInsertMethod(event: any) {
        this.item = null // Reset Item
    }

    get buildingId() {
        return this.formBuilding?.get('id')?.value
    }

    ngOnInit(): void {

        if (this.data.companies) {
            this.companies = this.data.companies
        }

        if (this.data.subjectId && this.type == 'subjectBuilding') {
            this.subjectService.getBuildings(this.data.subjectId).subscribe((res: any) => {
                console.log(res)
                this.dataSource = res
            })
        }


if(this.type == 'subjectBuilding'){
    this.formBuilding = this.fb.group({
        id: [null, []],
        name: [null],
        type: [null, []],
        streetName: [null, []],
        streetNumber: [null, []],
        otherInfo: [null, []],
        city: [null, []],
        province: [null, []],
        state: [null, []],
        zip: [null, []],
        googlePlaceId: [null, []],
        formattedAddress: [null, []],
        neighborhood: [null, []],
        sublocality: [null, []],
        country: [null, []],
        coordX: [null, []],
        coordY: [null, []],
        isProspect: [null, []],
        company: [null, [Validators.required]],
        companyLocation: [null, []],
        geographicArea: [null, []],
        geographicLocation: [null, []],
        origin: [null, []],
        contactChannel: [null, []],
        storicBuilding: [null, []],
        notes: [null, []],
        documents: [null, []],
        subjectBuildingRelationTypeId: [null, [Validators.required]],
    })
}

if(this.type == 'eventBuilding'){
    this.formBuilding = this.fb.group({
        id: [null, []],
        name: [null],
        type: ['Edificio', []],
        streetName: [null, []],
        streetNumber: [null, []],
        otherInfo: [null, []],
        city: [null, []],
        province: [null, []],
        state: [null, []],
        zip: [null, []],
        googlePlaceId: [null, []],
        formattedAddress: [null, []],
        neighborhood: [null, []],
        sublocality: [null, []],
        country: [null, []],
        coordX: [null, []],
        coordY: [null, []],
        isProspect: [null, []],
        company: [null, [Validators.required]],
        companyLocation: [null, []],
        geographicArea: [null, []],
        geographicLocation: [null, []],
        origin: [null, []],
        contactChannel: [null, []],
        storicBuilding: [null, []],
        notes: [null, []],
        documents: [null, []],
    })
}
       
    }

    getStructure() {
        let structure = {}
        if (this.type == 'subjectBuilding') {
            let subjectBuildingRelationTypeId = this.formBuilding?.get('subjectBuildingRelationTypeId')?.value
            let currentSubjectBuildingRelationType = this.subjectBuildingRelationTypes.find((item: any) => item.id == subjectBuildingRelationTypeId)
            structure = {
                "id": null,
                "building": this.formBuilding?.value,
                "subjectBuildingRelationType": currentSubjectBuildingRelationType
            }
        }
        if (this.type == 'eventBuilding') {
            structure = {
                "id": null,
                "building": this.formBuilding?.value,
            }
        }
        return structure
    }

}