import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-dynamic-form",
  templateUrl: "./dynamic-form.component.html",
  styleUrls: ["./dynamic-form.component.css"],
})
export class DynamicFormComponent implements OnInit {
  @Input() model: any = {};
  @Input() service: any;
  @Output() closeModal: any = new EventEmitter;
  public dynamicFormGroup: UntypedFormGroup;
  public fields: any = [];

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    const formGroupFields = this.getFormControlsFields();
    this.dynamicFormGroup = new UntypedFormGroup(formGroupFields);
  }

  private getFormControlsFields() {
    const formGroupFields: any = {};
    for (const field of Object.keys(this.model)) {

      const fieldProps: any = this.model[field];
      const validators: any = this.addValidator(fieldProps.rules);

      formGroupFields[field] = new UntypedFormControl(fieldProps.value, validators);
      this.fields.push({ ...fieldProps, fieldName: field });
    }

    return formGroupFields;
  }

  private addValidator(rules: any) {
    if (!rules) {
      return [];
    }

    const validators = Object.keys(rules).map((rule: any) => {
      switch (rule) {
        case "required":
          return Validators.required;
        //add more case for future.
        default:
          return null
      }
    });
    return validators;
  }

  submit() {
    this.service.create(this.dynamicFormGroup.value).subscribe((res: any) => {
      console.log(res)
      this.closeModal.emit(true);
    })
  }

  close() {
    console.log('close')
  }
}
