import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { LibService } from './libService';
import { SubjectsRelations } from '../models/subjectsRelations';

@Injectable({
  providedIn: 'root'
})
export class SubjectsRelationsService {

  baseApi = environment.rootApiCrm + 'v1.0';
  constructor(public libService: LibService) { }

  getSubjectRelation(id: string) {
    return this.libService.getData<any>(this.baseApi, `/subjectsRelation/${id.toString()}`);
  }

  getAllSubjectsRelations(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, '/subjectsRelations');
  }

  getSubjectRelations(idSubject: number): Observable<any> {
    console.log("ID SOGGETTO DA CERCARE --> ", idSubject);
    return this.libService.getData<any>(this.baseApi, `/subjectRelations/${idSubject}`);
  }

  getSubjectRelationsFull(idSubject: number, subjectType: string){
    return this.libService.postData<SubjectsRelations[]>({id: idSubject, type: subjectType},this.baseApi, '/getSubjectRelationsFull')
  }

  updateSubjectRelation(id: string, obj: any) {
    console.log("SERVIZIO FRONT END --> ", obj)
    return this.libService.postData<any>(obj, this.baseApi, `/subjectsRelation/${id.toString()}`);
  }

  createSubjectRelation(obj: any) {
    return this.libService.postData<any>(obj, this.baseApi,'/subjectsRelation');
  }

  deleteSubjectsRelation(id: string, obj: any) {
    return this.libService.deleteData<any>(obj, this.baseApi, `/subjectsRelation/${id.toString()}`);
  }
}
