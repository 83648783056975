import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NavService } from '../../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, Validators, FormGroup, FormArray, FormControl, FormControlName, AbstractControl, ValidationErrors, FormControlDirective } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LibService } from '../../../services/libService';
import { TranslatorService } from 'src/app/services/translate.service';
import { CalendarContractService } from 'src/app/services/calendar-contrcats-service';
import { UserSessionService } from '../../../services/user-session.service';
import { CompanyService } from 'src/app/services/companyService';
import { catchError, combineLatest, map, Observable, of, tap } from 'rxjs';
import { HistoryBalanceService } from 'src/app/final-balancer/providers/history-balance.service';
import { CountryService } from 'src/app/services/country.service';
import { ProfileService } from 'src/app/services/profileService';
import { BranchService } from 'src/app/services/branch.service';
import { MatDialog } from '@angular/material/dialog';
import { NewDocumentDialogComponent } from './new-document-dialog/new-document-dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { workCausalDocumentTypesService } from '../../../services/workCausalDocumentTypesService';




@Component({
    selector: 'calendar-contracts-form',
    templateUrl: './calendar-contracts-form.html',
    styleUrls: ['./calendar-contracts-form.scss'],
})
export class CalendarContractsFormComponent implements OnInit {



    //   form: UntypedFormGroup;
    id: any;
    countries?: any;
    filteredCountries?: any;
    filteredProfiles?: any;
    filteredDocumentList?: any;
    countrySearchControl = new FormControl();
    companySearchControl = new FormControl();
    societySearchControl = new FormControl();
    profilesSearchControl = new FormControl();
    branchesSearchControl = new FormControl();
    causalSearchControl = new FormControl();
    documentSearchControl = new FormControl();
    documentId = new FormControl();
    companies?: any;
    societies?: any;
    profiles?: any;
    branches?: any;
    filteredBranches?: any;
    filteredSocieties?: any;
    filteredSocietiesInput?: any;
    filteredCompanies?: any;
    workCausals?: any;
    filteredCausals?: any;
    maxEndDate?: Date;
    minEndDate?: Date;
    minEndDateValidity?: Date;
    countriesUser?: any;
    form: UntypedFormGroup;
    weeklyList: any = []



    weekly: any = [
        {
            dayOfWeekLabel: 'Lunedì',
            dayOfWeekNumber: 1,
        },
        {
            dayOfWeekLabel: 'Martedì',
            dayOfWeekNumber: 2
        },
        {
            dayOfWeekLabel: 'Mercoledì',
            dayOfWeekNumber: 3
        },
        {
            dayOfWeekLabel: 'Giovedì',
            dayOfWeekNumber: 4
        },
        {
            dayOfWeekLabel: 'Venerdì',
            dayOfWeekNumber: 5
        },
        {
            dayOfWeekLabel: 'Sabato',
            dayOfWeekNumber: 6
        },
        {
            dayOfWeekLabel: 'Domenica',
            dayOfWeekNumber: 7
        },
    ]
    formTitle: string;
    documentList: any;

    constructor(
        public navService: NavService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public libService: LibService,
        private toastr: ToastrService,
        public translate: TranslatorService,
        private router: Router,
        private userSessionService: UserSessionService,
        private CalendarContractService: CalendarContractService,
        private companyService: CompanyService,
        private HistoryBalanceService: HistoryBalanceService,
        private cdr: ChangeDetectorRef,
        public countryService: CountryService,
        public profileService: ProfileService,
        public BranchService: BranchService,
        private dialog: MatDialog,  // <-- Aggiungi questa riga
        private workCausalDocumentTypesService: workCausalDocumentTypesService, // Usa il servizio
    ) { }


    // getWorkCausals(): void {
    //     this.HistoryBalanceService.$workCasuals.pipe(
    //         tap((res: any) => {
    //             if (res && res.length > 0) {
    //                 this.workCausals = res.map((causal: any) => {
    //                     return {
    //                         id: causal?.id,
    //                         name: causal?.causalCode
    //                     };
    //                 });
    //                 console.log('workCausals:', this.workCausals); // Controllo dei dati ricevuti
    //                 this.filteredCausals = this.workCausals; // Popolazione di `filteredCausals`
    //             } else {
    //                 console.error('Nessun dato ricevuto per workCausals');
    //             }
    //         })
    //     ).subscribe();
    // }

    toggleAllSelection(selectAll: boolean): void {
        if (selectAll) {
            // Se la checkbox è selezionata, aggiungi tutti gli ID dei causali
            const allCausalIds = this.filteredCausals.map((causal: any) => causal.id);
            this.form.get('workCausals')?.patchValue(allCausalIds);
        } else {
            // Se la checkbox è deselezionata, rimuovi tutti gli ID
            this.form.get('workCausals')?.patchValue([]);
        }
    }

    areAllSelected(): boolean {
        const selectedCausals = this.form.get('workCausals')?.value || [];
        return selectedCausals.length === this.filteredCausals.length && this.filteredCausals.length > 0;
    }



    getWorkCausals(): Observable<any> {
        return this.HistoryBalanceService.$workCasuals.pipe(
            tap((response: any) => {
                if (response && response.length > 0) {
                    // Filtra le workCausals con isPresence a false
                    this.workCausals = response
                        .filter((causal: any) => causal?.isPresence === false) // Filtro applicato qui
                        .map((causal: any) => ({
                            id: causal?.id,
                            name: causal?.causalCode
                        }));

                    this.filteredCausals = this.workCausals;
                    console.log('workCausals:', this.workCausals);
                } else {
                    console.error('Nessun dato ricevuto per workCausals');
                }
            })
        );
    }

    update() {
        this.libService.lockPage('');
        console.log(this.form.value)

        this.CalendarContractService.create(this.form.value).subscribe((response: any) => {
            //console.log(response)
            this.toastr.success(
                'ok', this.translate.instant('LABEL.Info'));
            this.libService.unlockPage();
        })
    }

    removeDuplicates(societies: any) {
        const uniqueSocieties = societies.reduce((acc: any, current: any) => {
            // Usa un oggetto per tracciare gli id unici
            if (!acc.some((society: any) => society.id === current.id)) {
                acc.push(current);
            }
            return acc;
        }, []);
        return uniqueSocieties;
    }

    filterSocietiesByCountry(selectedCountries: string[]) {
        this.form.get('societies')?.reset();
        this.form.get('companies')?.reset();
        // this.filteredSocieties = [];
        this.societies = this.removeDuplicates(this.societies);

        console.log('Selected countries:', selectedCountries);
        // Verifica se ci sono country selezionati
        if (selectedCountries && selectedCountries.length > 0) {
            // Ricava i nomi corrispondenti agli id dei country selezionati
            const selectedCountryNames = selectedCountries?.map((countries) => {
                const foundCountry = this.countries.find((country: any) => country.id === countries);
                return foundCountry ? foundCountry.name : null; // Se trova il paese, restituisce il nome, altrimenti null
            }).filter((name) => name !== null); // Filtra eventuali valori null

            // Filtra le società in base ai nomi dei country trovati
            this.filteredSocieties = this.societies.filter((society: any) => {
                return selectedCountryNames.includes(society.societyCountry);
            });

            this.filteredSocietiesInput = this.filteredSocieties;
        } else {
            this.filteredSocieties = [];
        }
        console.log('Filtered societies:', this.filteredSocieties);
    }



    filterCompanyBySociety(selectedSocieties: string[]) {
        this.form.get('companies')?.reset();

        if (selectedSocieties && selectedSocieties.length > 0) {
            this.filteredCompanies = this.companies.filter((company: any) =>
                selectedSocieties.includes(company.societyId)
            );
        } else {
            this.filteredCompanies = [];
        }
    }


    getCountries(): Observable<any> {
        this.countriesUser = this.userSessionService.getCountries();
        return this.countryService.index().pipe(
            tap((response: any) => {
                this.countries = response.filter((country: any) =>
                    this.countriesUser.includes(country.isoCode)
                )?.map((country: any) => ({
                    id: country?.id,
                    name: country?.isoCode
                }));
                this.filteredCountries = this.countries;
            })
        );
    }
    // Metodo per ottenere i profili
    getProfiles(): Observable<any> {
        return this.profileService.getAllProfiles()?.pipe(
            map((response: any) => {
                return response
                    ?.filter((profile: any) => profile?.basic === true) // Filtro per `basic` a true
                    .map((profile: any) => {
                        return {
                            id: profile?.id,
                            name: profile.name
                        };
                    });
            })
        );
    }


    // getBranches(): Observable<any> {
    //     return this.BranchService.getAllBranches()?.pipe(
    //         map((response: any) => {
    //             return response
    //                 ?.filter((branche: any) => branche?.isActive === true) // Filtro per `isActive` a true
    //                 .map((branche: any) => {
    //                     return {
    //                         id: branche?.id,
    //                         name: branche?.name,
    //                         codeLang: branche?.codCountry
    //                     };
    //                 });
    //         })
    //     );
    // }
    getBranchesByCountries(countryCodes: string[]): void {
        this.BranchService.getAllBranches()?.pipe(
            map((response: any) => {
                console.log('res_branches', response)
                this.branches = response
                    ?.filter((branch: any) => branch?.isActive === true && countryCodes.includes(branch?.codCountry)) // Filtro per `codCountry`
                    .map((branch: any) => ({
                        id: branch?.id,
                        name: branch?.name,
                        codeLang: branch?.codCountry
                    }));

                this.filteredBranches = this.branches;
            })
        ).subscribe();
    }





    getCompanies(): Observable<any> {
        return this.companyService.getSocietiesAndCountries().pipe(
            tap((res: any) => {
                console.log(res)
                if (res && res.length > 0) {
                    this.societies = res?.map((society: any) => ({
                        id: society?.societyId,
                        name: society?.societyName,
                        societyCountry: society?.societyCountry
                    }));
                    //   this.filteredSocieties = this.societies;
                    console.log('societafitrate: ', this.filteredSocieties)
                    this.companies = res?.map((company: any) => ({
                        id: company?.companyId,
                        name: company?.companyName,
                        societyId: company?.societyId
                    }));
                } else {
                    console.error('Nessun dato ricevuto dal server per companies e societies');
                }
            })
        );
    }

    getDocuments(): void {
        this.workCausalDocumentTypesService.index().subscribe(
            (response: any) => {
                // Popoliamo la lista dei documenti con la risposta del server
                this.documentList = response;
                this.filteredDocumentList = this.documentList;
                console.log('Documenti ricevuti:', this.documentList);
            },
            (error) => {
                console.error('Errore nel recupero dei documenti:', error);
                this.toastr.error('Errore nel caricamento dei documenti');
            }
        );
    }

    initWorkCausalDocuments() {
        const workCausals = this.form.get('workCausals')?.value;

        // Crea una mappa per i documenti esistenti
        const existingDocumentsMap = new Map<number, any>();
        this.workCausalDocumentsArray.controls.forEach(control => {
            const workCausalId = control?.get('workCausalId')?.value;
            const documentId = control?.get('documentId')?.value;
            existingDocumentsMap?.set(workCausalId, documentId);
        });

        // Rimuovi tutti gli attuali controlli
        this.workCausalDocumentsArray?.clear();

        // Aggiungi nuovi controlli preservando i documenti esistenti per le causali non rimosse
        workCausals.forEach((causal: any) => {
            this.workCausalDocumentsArray?.push(
                this.fb.group({
                    workCausalId: [causal],
                    documentId: [existingDocumentsMap?.get(causal) || null],  // Preserva il documento esistente o inizializza a null
                })
            );
        });
    }


    get workCausalDocumentsArray(): FormArray {
        return this.form.get('workCausalDocuments') as FormArray;
    }

    addNewDocument() {
        const dialogRef = this.dialog.open(NewDocumentDialogComponent, {
            width: '400px',
            data: {},
            scrollStrategy: new NoopScrollStrategy(),

        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // Se c'è un risultato, richiama il metodo per aggiornare la lista dei documenti
                this.getDocuments();  // Richiama il servizio per ricaricare la lista aggiornata
            }
        });
    }

    getWorkCausalName(workCausalId: number): string {
        const workCausal = this.workCausals?.find((causal: any) => causal.id === workCausalId);

        if (workCausal && workCausal?.name) {
            return workCausal?.name
                .toLowerCase()
                .split(' ')
                .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }

        return '';
    }

    ngOnInit(): void {
        this.getProfiles();
        console.log('DOVE SONONOONO')
        this.form = this.fb.group({
            description: [null, [Validators.required]],
            countries: [[], [Validators.required]],
            companies: [null],
            societies: [null],
            profiles: [null, [Validators.required]],
            branches: [null],
            workCausals: [null, [Validators.required]],
            maximumWeeklyHours: [null, [Validators.required]],
            maximumExtraHoursPerWeek: [null, [Validators.required]],
            startDateOfValidity: [null, [Validators.required]],
            endDateOfValidity: [null, [Validators.required]],
            startDate: [null],
            endDate: [null],
            weeklySchedules: this.fb.array([]),
            // Aggiungi un FormArray per gestire le workCausalDocuments
            workCausalDocuments: this.fb.array([])
        },);


        combineLatest([
            this.getCountries(),
            this.getCompanies(),
            this.getProfiles().pipe(catchError((error: any) => of([]))),
            // this.getBranches().pipe(catchError((error: any) => of([]))),
            this.getWorkCausals().pipe(catchError((error: any) => of([]))),
        ]).subscribe(([countriesResponse, companiesResponse, profilesResponse]) => {
            // Gestisci la risposta delle nazioni
            // this.countries = countriesResponse;
            console.log('Countries:', this.countries);

            // Gestisci la risposta delle companies e societies
            console.log('Societies:', this.filteredSocieties);
            console.log('Companies:', this.companies);
            console.log('Profiles:', profilesResponse);
            // console.log('branches: ', branchesresponse)
            this.profiles = profilesResponse;
            this.filteredProfiles = this.profiles;
            // this.branches = branchesresponse;
            this.filteredBranches = this.branches;
            // this.removeDuplicates(this.filteredBranches);
        });


        this.form.get('countries')?.valueChanges.subscribe((selectedCountryIds: any[]) => {
            if (selectedCountryIds && selectedCountryIds.length > 0) {
                console.log('selectedCountryIds', selectedCountryIds); // Logga l'array degli ID dei country selezionati

                // Trova tutti i `name` corrispondenti agli ID selezionati
                const selectedCountryNames = selectedCountryIds.map((selectedCountryId: number) => {
                    const country = this.countries.find((country: any) => country.id === selectedCountryId);
                    return country ? country.name : null; // Restituisce `name` o `null` se non trovato
                }).filter((name: string | null) => name !== null); // Rimuove eventuali `null`

                // Esegui il filtro dei branches usando i nomi dei paesi
                this.getBranchesByCountries(selectedCountryNames);
            } else {
                // Se non ci sono paesi selezionati, pulisci la lista dei branches
                this.filteredBranches = [];
            }
        });





        this.countrySearchControl.valueChanges.subscribe((value: any) => {
            //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
            this.filteredCountries = this.countries.filter((country: any) => {
                return !value || country.name.toLowerCase().includes(value.toLowerCase())
            });
        });


        this.profilesSearchControl.valueChanges.subscribe((value: any) => {
            //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
            this.filteredProfiles = this.profiles.filter((profile: any) => {
                return !value || profile.name.toLowerCase().includes(value.toLowerCase())
            });
        });

        this.branchesSearchControl.valueChanges.subscribe((value: any) => {
            //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
            this.filteredBranches = this.branches.filter((branche: any) => {
                return !value || branche.name.toLowerCase().includes(value.toLowerCase())
            });
        });

        this.form.get('countries')?.valueChanges.subscribe((value: any) => {
            this.filterSocietiesByCountry(value);
        });


        this.form.get('societies')?.valueChanges.subscribe((value: any) => {
            this.filterCompanyBySociety(value);
        });

        this.societySearchControl.valueChanges.subscribe(value => {
            console.log(value)
            //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
            this.filteredSocieties = this.filteredSocietiesInput.filter((society: any) => {
                return !value || society.name.toLowerCase().includes(value.toLowerCase())
            });
        });

        this.companySearchControl.valueChanges.subscribe(value => {
            //non si può scrivere this.filteredCountries = this.filteredCountries.filter perchè facendo così se elimino dei caratteri dalla ricerca, la ricerca non si resetta
            this.filteredCompanies = this.companies.filter((company: any) =>
                !value || company.name.toLowerCase().includes(value.toLowerCase())
            );
        });

        console.log('ciao')
        console.log("workCausals.length > 0", this.workCausals)
        // Solo dopo aver ricevuto i workCausals esegui i filtri o altre operazioni
        this.causalSearchControl.valueChanges.subscribe(value => {
            this.filteredCausals = this.workCausals.filter((causal: any) =>
                !value || causal?.name.toLowerCase().includes(value.toLowerCase())
            );
        });

        this.form.get('workCausals')?.valueChanges.subscribe((workCausals: any[]) => {
            console.log(workCausals)
            if (workCausals && workCausals.length > 0) {
                this.initWorkCausalDocuments(); // Esegui initWorkCausalDocuments solo dopo che workCausals è stato selezionato
            } else {
                const workCausalDocumentsArray = this.form.get('workCausalDocuments') as FormArray;
                workCausalDocumentsArray.clear(); // Pulisci l'array dei documenti se non ci sono workCausals
            }
        });

        this.getDocuments();

        this.documentSearchControl.valueChanges.subscribe(value => {
            this.filteredDocumentList = this.documentList.filter((document: any) =>
                !value || document?.label.toLowerCase().includes(value.toLowerCase())
            );
        });


        this.form.get('startDateOfValidity')?.valueChanges.subscribe((value: any) => {
            console.log('startDate: ', value)
            this.minEndDateValidity = value;
            // Forza Angular a ricalcolare dopo aver aggiornato `minEndDate`

        })
        this.form.get('startDate')?.valueChanges.subscribe((value: any) => {
            console.log('startDate: ', value)
            this.minEndDate = value;
            // Forza Angular a ricalcolare dopo aver aggiornato `minEndDate`

        })

        this.libService.lockPage('');

        this.id = this.route?.snapshot?.paramMap?.get('id');
        console.log(this.id)
        if (['0', 0, null, undefined].includes(this.id)) {
            this.initWeeklySchedules();  // Aggiungi logica per la creazione di un nuovo contratto
            this.formTitle = 'Aggiungi contratto';
        } else {
            this.formTitle = 'Modifica contratto';
            this.initForm();  // Aggiungi logica per la modifica di un contratto esistente
        }

    }

    get weeklySchedules(): FormArray {
        return <FormArray>this.form.get('weeklySchedules');
    }

    addWeeklySchedules(values: any): FormGroup {
        let weeklySchedule: FormGroup = this.fb.group(values);
        return weeklySchedule
    }

    initWeeklySchedules() {

        this.weekly.forEach((days: any) => {
            let currentWeeklySchedules = this.weeklyList.find((day: any) => day.dayOfWeekNumber == days.dayOfWeekNumber)
            let structure: any = {
                dayOfWeekNumber: [days.dayOfWeekNumber],
                dayOfWeekLabel: [days.dayOfWeekLabel],
                maxHours: [null, Validators.required],
                maxExtraHours: [null, Validators.required],
            }

            if (currentWeeklySchedules?.id) structure.id = currentWeeklySchedules?.id
            if (currentWeeklySchedules?.dayOfWeekNumber) structure.dayOfWeekNumber = currentWeeklySchedules?.dayOfWeekNumber
            if (currentWeeklySchedules?.dayOfWeekLabel) structure.dayOfWeekLabel = currentWeeklySchedules?.dayOfWeekLabel
            if (currentWeeklySchedules?.maxHours) structure.maxHours = currentWeeklySchedules?.maxHours
            if (currentWeeklySchedules?.maxExtraHours) structure.maxExtraHours = currentWeeklySchedules?.maxExtraHours

            this.weeklySchedules.push(this.addWeeklySchedules(structure))

        });
        console.log('Weekly schedules aggiunti:', this.weeklySchedules.controls);
        this.libService.unlockPage();
    }

    initForm() {
        this.CalendarContractService.settings(this.id).subscribe((response: any) => {
            if (response) {
                console.log('response: ', response)

                this.form?.get('description')?.setValue(response.description)
                this.form?.get('maximumWeeklyHours')?.setValue(response.maximumWeeklyHours);
                this.form?.get('maximumExtraHoursPerWeek')?.setValue(response.maximumExtraHoursPerWeek);

                // Imposta i valori per le date
                //imposto le date di startDateOfValidity e endDateOfValidity considerando che mi arriva il giorno e il mese dalla response
                const dayStart = response.startDayOfValidity;
                const monthStart = response.startMonthOfValidity;
                const startDateOfValidity = this.createDateFromDayMonth(dayStart + '/' + monthStart)

                const dayEnd = response.endDayOfValidity;
                const monthEnd = response.endMonthOfValidity;
                const endDateOfValidity = this.createDateFromDayMonth(dayEnd + '/' + monthEnd)

                this.form?.get('startDateOfValidity')?.setValue(startDateOfValidity);
                this.form?.get('endDateOfValidity')?.setValue(endDateOfValidity);
                this.form?.get('startDate')?.setValue(response?.startDate ? new Date(response.startDate) : null);
                this.form?.get('endDate')?.setValue(response?.endDate ? new Date(response.endDate) : null);
                this.form?.get('countries')?.setValue(response?.countries?.map((country: any) => country.id));
                this.form?.get('societies')?.setValue(response?.societies?.map((society: any) => society.id));
                this.form?.get('companies')?.setValue(response?.companies?.map((company: any) => company.id));
                this.form?.get('workCausals')?.setValue(response?.workCausals?.map((workCausal: any) => workCausal.id));
                this.form?.get('branches')?.setValue(response?.branches?.map((branch: any) => branch.id));
                this.form?.get('profiles')?.setValue(response?.profiles?.map((profile: any) => profile.id));

                this.setWeeklySchedules(response?.weeklySchedules);
                console.log('responseDocument:', response?.workCausalContractsDocumentTypes)
                this.setWorkCausalDocumentTypes(response?.workCausalContractsDocumentTypes)

                console.log(this.form)
                // this.initWeeklySchedules();


                console.log(this.form)
                this.libService.unlockPage();
            } else {
                this.libService.unlockPage();
            }
        })
    }

    setWeeklySchedules(weeklySchedules: any[]): void {
        const weeklySchedulesFormArray = this.form.get('weeklySchedules') as FormArray;

        // Pulisci eventuali dati precedenti
        weeklySchedulesFormArray.clear();

        // Aggiungi ogni weeklySchedule nel FormArray
        weeklySchedules.forEach(schedule => {
            const scheduleGroup = this.fb.group({
                dayOfWeekNumber: [schedule.dayOfWeekNumber],
                dayOfWeekLabel: [schedule.dayOfWeekLabel],
                maxHours: [schedule.maxHours, Validators.required],
                maxExtraHours: [schedule.maxExtraHours, Validators.required]
            });

            weeklySchedulesFormArray.push(scheduleGroup);
        });

        console.log('Weekly schedules caricati:', weeklySchedulesFormArray.controls);
    }

    setWorkCausalDocumentTypes(workCausalContractsDocumentTypes: any[]): void {
        const workCausalDocumentsFormArray = this.form.get('workCausalDocuments') as FormArray;

        // Pulisci eventuali dati precedenti
        workCausalDocumentsFormArray.clear();

        // Mappa per raggruppare i documenti per WorkCausalId
        const workCausalMap = new Map<number, number[]>();

        // Itera sui documenti e raggruppa i documenti per lo stesso WorkCausalId
        workCausalContractsDocumentTypes?.forEach((documentType) => {
            const workCausalId = documentType?.WorkCausalId;
            const documentId = documentType?.WorkCausalDocumentTypeId;

            if (!workCausalMap?.has(workCausalId)) {
                workCausalMap?.set(workCausalId, []);
            }

            // Aggiungi il documentId all'array corrispondente a workCausalId
            workCausalMap?.get(workCausalId)?.push(documentId);
        });

        // Ora itera sulla mappa per creare i controlli del form
        workCausalMap?.forEach((documentIds, workCausalId) => {
            // Ottieni le label per ciascun documento
            const documentLabels = documentIds?.map(documentId => this.getDocumentLabelById(documentId));

            // Crea il gruppo di controllo per ogni WorkCausalId
            const documentGroup = this.fb.group({
                workCausalId: [workCausalId], // Associa l'ID della workCausal
                documentId: [documentIds], // Imposta gli ID dei documenti come array
                labels: [documentLabels] // Aggiungi le label corrispondenti
            });

            // Aggiungi il gruppo di controllo al FormArray
            workCausalDocumentsFormArray?.push(documentGroup);
        });

        console.log('WorkCausalDocumentTypes caricati:', workCausalDocumentsFormArray?.controls);
    }




    // Metodo per ottenere la label dal documentList basato sull'id
    getDocumentLabelById(id: number): string {
        const document = this.documentList?.find((doc: any) => doc.id === id);
        return document ? document.label : 'Unknown';
    }





    createDateFromDayMonth(dayMonthString: string): Date {
        const [day, month] = dayMonthString?.split('/').map(Number); // Divide la stringa in giorno e mese e li converte in numeri
        const currentYear = new Date().getFullYear(); // Ottieni l'anno corrente

        // Crea una nuova data con giorno, mese (mese parte da 0 in JavaScript) e l'anno corrente
        return new Date(currentYear, month - 1, day);
    }


    back() {
        this.router.navigate([`settings/calendar-contracts`]);
    }


    onSave(): void {
        // Estrai i valori dal form
        const startDateOfValidity = this.form.get('startDateOfValidity')?.value;
        const endDateOfValidity = this.form.get('endDateOfValidity')?.value;

        // Calcola giorno e mese
        const payload = {
            ...this.form.value, // Include tutti i campi del form
            startDayOfValidity: startDateOfValidity ? new Date(startDateOfValidity).getDate() : null, // Giorno di startDateOfValidity
            startMonthOfValidity: startDateOfValidity ? new Date(startDateOfValidity).getMonth() + 1 : null, // Mese di startDateOfValidity
            endDayOfValidity: endDateOfValidity ? new Date(endDateOfValidity).getDate() : null, // Giorno di endDateOfValidity
            endMonthOfValidity: endDateOfValidity ? new Date(endDateOfValidity).getMonth() + 1 : null, // Mese di endDateOfValidity
        };

        // Verifica l'ID dall'URL: se diverso da 0, esegui l'aggiornamento, altrimenti crea un nuovo contratto
        const contractId = this.route.snapshot.paramMap.get('id'); // Estrae l'ID dall'URL
        console.log('id: ', contractId)

        if (contractId && contractId !== '0') {
            // Aggiorna un contratto esistente
            this.CalendarContractService.updateSettings(contractId, payload).subscribe(
                (response: any) => {
                    this.toastr.success('Contratto aggiornato con successo', this.translate.instant('LABEL.Info'));
                    this.libService.unlockPage();
                    this.back(); // Torna indietro alla lista o alla vista dettagli
                },
                (error: any) => {
                    this.toastr.error('Errore durante l\'aggiornamento del contratto', this.translate.instant('LABEL.Error'));
                    this.libService.unlockPage();
                }
            );
        } else {
            // Crea un nuovo contratto
            this.CalendarContractService.createSettings(payload).subscribe(
                (response: any) => {
                    this.toastr.success('Nuovo contratto creato con successo', this.translate.instant('LABEL.Info'));
                    this.libService.unlockPage();
                    this.back(); // Torna indietro alla lista o alla vista dettagli
                },
                (error: any) => {
                    this.toastr.error('Errore durante la creazione del contratto', this.translate.instant('LABEL.Error'));
                    this.libService.unlockPage();
                }
            );
        }
    }


}
