<div class="container-fluid my-container">
	<div class="full-w">
		<form [formGroup]="formTargets">
			<div style="position: relative; width: 100%">
				<mat-form-field appearance="fill">
					<mat-label>{{ 'LABEL.COMPANY' | translate}}</mat-label>
					<mat-select matNativeControl formControlName="company" #companiesSelection multiple
						(selectionChange)="updateCompaniesSelection($event)"
						(opened)="onOpenSearch(searchSelect)"
						(closed)="onCloseSearch()">
						<mat-form-field id="searchSelect" appearance="fill">
							<mat-label>{{ 'LABEL.SEARCH' | translate}}</mat-label>
							<input matNativeControl type="text" (keyup)="onChangeFilterCompanies($event)" #searchSelect>
						</mat-form-field>
						<mat-option (onSelectionChange)="selectAllCompanies()"> {{allSelected ? 'Deseleziona tutte' : 'Seleziona tutte'}} </mat-option>
						<mat-option *ngFor="let company of companies" [value]="company.id"> {{ company.name }} </mat-option>
						<mat-option *ngIf="companies?.length == 0" disabled> Nessun risultato </mat-option>
					</mat-select>
					<!-- <ng-multiselect-dropdown [placeholder]="translate.instant('LABEL.SOCIETY')" [settings]="dropdownSocietySettings"
						[data]="dropdownListSocieties" [(ngModel)]="selectedSociety">
					</ng-multiselect-dropdown> -->
				</mat-form-field>
				<button style="background-color:transparent; position: absolute; right: 5%; top: 0px;"
					matTooltip="{{ 'LABEL.EDIT_BRANCH_COMPANIES_RELATION' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
					(click)="editCompanies()" *ngIf="id != 0">
					<mat-icon class="material-icons" style="color:#293683;">edit</mat-icon>
				</button>
			</div>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.CODE' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="code">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.NAME' | translate}}</mat-label>
				<input type="text" matNativeControl formControlName="name">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH_STATE' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="codCountry">
					<mat-option *ngFor="let country of countryOptions" [value]="country"> {{ country }} </mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.BRANCH_LANG' | translate}}</mat-label>
				<mat-select matNativeControl formControlName="codLanguage">
					<mat-option *ngFor="let lang of langOptions" [value]="lang"> {{ lang }} </mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.CORARIO' | translate }}</mat-label>
				<input matNativeControl type="number" formControlName="cOrario">
			</mat-form-field>

			<mat-form-field appearance="fill">
				<mat-label>{{ 'LABEL.CORARIOMULT' | translate }}</mat-label>
				<input matNativeControl type="number" formControlName="multCostoOrario">
			</mat-form-field>

			<!-- <mat-checkbox appearance="fill" matNativeControl formControlName="isStaff">{{ 'LABEL.isStaff' | translate }}</mat-checkbox> -->

			<mat-checkbox appearance="fill" matNativeControl formControlName="isActive">{{ 'LABEL.ISACTIVE' | translate }}</mat-checkbox>
		</form>

		<div class="buttons-right my-2">
			<button style="background-color:transparent;" [disabled]="!formTargets.valid || formTargets.pristine"
				matTooltip="{{ 'LABEL.SAVE' | translate }}" type="button" class="my-bounce btn btn-primary mx-2"
				(click)="update()">
				<mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
			</button>
		</div>
	</div>
</div>
