
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { of, combineLatest, map, switchMap } from 'rxjs';
import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { Document, DocumentType } from 'src/app/models/document';
import { DocumentTemplateService } from 'src/app/services/document-template.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';
import { DocumentsGridComponent } from 'src/app/shared/documents-grid/documents-grid.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-claims-document',
  templateUrl: './claims-document.component.html',
  styleUrls: ['./claims-document.component.scss']
})
export class ClaimsDocumentComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() disableUpload: boolean;
  @Input() isNew: boolean;
  @Input() type: string;
  @Output() updateStatus = new EventEmitter;

  documents: Document[];
  documentTypes: DocumentType[];
  // globalDocumentTypes$ = this.documentTypeService.getAllGeneric()
  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

  constructor(private dialog: MatDialog, private documentTypeService: DocumentTypeService, private documentTemplateService: DocumentTemplateService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.form?.get('documents')?.valueChanges.subscribe((documents: Document[]) => {
      console.log("DOCUMENTS VALUE CHANGE --> ", documents);
      this.documents = documents;
    })
    console.log('this.form.get(status) %o', this.form.get('status'));

    console.log("FORM TOTALE --> ", this.form);
    this.setDocumentTypes()

  }

  isDisabled() {
    if (this.form?.get('status') == null || this.form?.get('status')?.value == null || this.form?.get('status')?.value.code == null)
      return true;
    if (this.form?.get('status')?.value.code == 'NEW')
      return false;
    else
      return true;
  }

  getClassWorkflow(step: any) {
    if (!(this.form == null) && !(this.form?.value == null) && !(this.form?.value.status == null)) {
      let stepP = this.form?.value.status.step;//ex 2 <- actual status
      //console.log('request status %o',this.form.value.status.step);

      if (step == stepP)
        return 'fs-6 fw-bold aln row-al mrg-top card-title';

      return 'fs-6 fw-bold text-muted aln row-al mrg-top card-title'
    }
    return '';

  }

  getClassIconWorkflow(step: any) {
    if (!(this.form == null) && !(this.form?.value == null) && !(this.form?.value.status == null)) {
      let stepF = this.form.value.status.step; //ex 2 <- actual status

      if (step < stepF) {
        return 'check-ok';
      }

      if (step == stepF)
        return 'check-warning';
    }
    return ''

  }

  public openUploadDialog(mode: 'new' | 'current') {
    if (mode === 'new') {
      console.log("FORM VALUE --> ", this.form.getRawValue());
      this.dialog.open(FileUploadModalComponent, {
        data: {
          //file: this.webcamImage.imageAsDataUrl,
          inputMode: this.type,
          toEntityId: this.form?.get('id')?.value,
          orderId: this.form.get('orderId')?.value,
          practiceId: this.form.get('practiceId')?.value,
          // requestId: this.form?.get('id')?.value,
          documentTypes: of(this.documentTypes)
        }
      })
        .afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ', res);
          //this.subject.documents.push(res)
          if (res) {
            this.form?.get('documents')?.setValue([...this.form?.get('documents')?.value, res.document])
            this.updateStatus.emit(false);
          }
        })
    }
  }

  public viewDocuments() {
    console.log("DOCUMENTI --> ", this.form.get('documents')?.value)
    if (this.form.get('documents')?.value.length > 0) {
      let docs = this.dialog.open(DocumentsGridComponent, {
        height: '70%',
        width: '70%',
      });
      let instance = docs.componentInstance;
      instance.documents = this.form.get('documents')?.value;
    } else {
      this.dialog.open(SimpleDialogComponent, {
        height: '70%',
        width: '70%',
      });

    }
    // console.log("DOCUMENTS INSIDE -->", );
  }

  setDocumentTypes(){
    if (this.isNew === false) { //

      this.globalDocumentTypes$.pipe(map(globalTypes => {
        console.log("GLOBAL TYPES --> ", globalTypes);
        console.log("FORM VALUE DOCUMENT TYPES --> ", this.form.get('status')?.value?.allowedDocumentTypes);

        let docTypes = this.type === 'claims'?  this.form.get('status')?.value?.allowedDocumentTypes.filter((a: any) => a.code === 'SINISTRO' || a.code === 'TRANSITIVO'):
                                                this.form.get('status')?.value?.allowedDocumentTypes.filter((a: any) => a.code !== 'SINISTRO' && a.code !== 'TRANSITIVO');
        // globalTypes.push(this.form.get('status')?.value.allowedDocumentTypes);
        return [...globalTypes, ...docTypes];
      })).subscribe(docTypes => {
        // this.documentTypes = val.filter(type => type.code === 'INSP' || type.code === 'PHOTO');
        // console.log("COMPLAINT -->", this.form.getRawValue());
        this.documentTypes = docTypes;

      })
    }
  }

  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
     const key = keyProps.map(k => entry[k]).join('|');
     return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
   }
}
// =======
// import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { FormGroup } from '@angular/forms';
// import { MatDialog } from '@angular/material/dialog';
// import { TranslateService } from '@ngx-translate/core';
// import { of, combineLatest, map, switchMap } from 'rxjs';
// import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
// import { Document, DocumentType } from 'src/app/models/document';
// import { DocumentTemplateService } from 'src/app/services/document-template.service';
// import { DocumentTypeService } from 'src/app/services/document-type.service';
// import { DocumentsGridComponent } from 'src/app/shared/documents-grid/documents-grid.component';
// import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';

// @Component({
//   selector: 'app-claims-document',
//   templateUrl: './claims-document.component.html',
//   styleUrls: ['./claims-document.component.scss']
// })
// export class ClaimsDocumentComponent implements OnInit {

//   @Input() form: FormGroup;
//   @Input() disableUpload: boolean;
//   @Input() isNew: boolean;
//   @Input() type: string;
//   @Input() type: string;
//   @Output() updateStatus = new EventEmitter;

//   documents: Document[];
//   documentTypes: DocumentType[];
//   // globalDocumentTypes$ = this.documentTypeService.getAllGeneric()
//   globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

//   constructor(private dialog: MatDialog, private documentTypeService: DocumentTypeService, private documentTemplateService: DocumentTemplateService,
//     public translate: TranslateService) { }

//   ngOnInit(): void {
//     this.form?.get('documents')?.valueChanges.subscribe((documents: Document[]) => {
//       console.log("DOCUMENTS VALUE CHANGE --> ", documents);
//       this.documents = documents;
//     })
//     console.log('this.form.get(status) %o', this.form.get('status'));

//     console.log("FORM TOTALE --> ", this.form);
//     console.log("FORM TOTALE --> ", this.form);
//     this.setDocumentTypes()

//   }

//   isDisabled() {
//     if (this.form?.get('status') == null || this.form?.get('status')?.value == null || this.form?.get('status')?.value.code == null)
//       return true;
//     if (this.form?.get('status')?.value.code == 'NEW')
//       return false;
//     else
//       return true;
//   }

//   getClassWorkflow(step: any) {
//     if (!(this.form == null) && !(this.form?.value == null) && !(this.form?.value.status == null)) {
//       let stepP = this.form?.value.status.step;//ex 2 <- actual status
//       //console.log('request status %o',this.form.value.status.step);

//       if (step == stepP)
//         return 'fs-6 fw-bold aln row-al mrg-top card-title';

//       return 'fs-6 fw-bold text-muted aln row-al mrg-top card-title'
//     }
//     return '';

//   }

//   getClassIconWorkflow(step: any) {
//     if (!(this.form == null) && !(this.form?.value == null) && !(this.form?.value.status == null)) {
//       let stepF = this.form.value.status.step; //ex 2 <- actual status

//       if (step < stepF) {
//         return 'check-ok';
//       }

//       if (step == stepF)
//         return 'check-warning';
//     }
//     return ''

//   }

//   public openUploadDialog(mode: 'new' | 'current') {
//     if (mode === 'new') {
//       console.log("FORM VALUE --> ", this.form.getRawValue());
//       this.dialog.open(FileUploadModalComponent, {
//         data: {
//           //file: this.webcamImage.imageAsDataUrl,
//           inputMode: this.type,
//           toEntityId: this.form?.get('id')?.value,
//           orderId: this.form.get('orderId')?.value,
//           // requestId: this.form?.get('id')?.value,
//           documentTypes: of(this.documentTypes)
//         }
//       })
//         .afterClosed()
//         .subscribe(res => {
//           console.log('uploadDialogres: ', res);
//           //this.subject.documents.push(res)
//           if (res) {
//             this.form?.get('documents')?.setValue([...this.form?.get('documents')?.value, res.document])
//             this.updateStatus.emit(false);
//           }
//         })
//     }
//   }

//   public viewDocuments() {
//     console.log("DOCUMENTI --> ", this.form.get('documents')?.value)
//     if (this.form.get('documents')?.value.length > 0) {
//       let docs = this.dialog.open(DocumentsGridComponent, {
//         height: '70%',
//         width: '70%',
//       });
//       let instance = docs.componentInstance;
//       instance.documents = this.form.get('documents')?.value;
//     } else {
//       this.dialog.open(SimpleDialogComponent, {
//         height: '70%',
//         width: '70%',
//       });

//     }
//     // console.log("DOCUMENTS INSIDE -->", );
//   }

//   setDocumentTypes(){
//     if (this.isNew === false) { //

//       this.globalDocumentTypes$.pipe(map(globalTypes => {
//         console.log("GLOBAL TYPES --> ", globalTypes);
//         console.log("FORM VALUE DOCUMENT TYPES DOCUMENT TYPES --> ", this.form.get('status')?.value?.allowedDocumentTypes);

//         let docTypes = this.type === 'claims'?  this.form.get('status')?.value??.allowedDocumentTypes);

//         let docTypes = this.type === 'claims'?  this.form.get('status')?.value?.allowedDocumentTypes.filter((a: any) => a.code === 'SINISTRO' || a.code === 'TRANSITIVO'):
//                                                 this.form.get('status')?.value?.allowedDocumentTypes.filter((a: any) => a.code !== 'SINISTRO' && a.code !== 'TRANSITIVO'.filter((a: any) => a.code === 'SINISTRO' || a.code === 'TRANSITIVO'):
//                                                 this.form.get('status')?.value?.allowedDocumentTypes.filter((a: any) => a.code !== 'SINISTRO' && a.code !== 'TRANSITIVO');
//         // globalTypes.push(this.form.get('status')?.value.allowedDocumentTypes);
//         return [...globalTypes, ...docTypes];
//       })).subscribe(docTypes => {
//         // this.documentTypes = val.filter(type => type.code === 'INSP' || type.code === 'PHOTO');
//         // console.log("COMPLAINT -->", this.form.getRawValue());
//         this.documentTypes = docTypes;

//       })
//     }
//   }

//   private unique(arr: any, keyProps: string[]): any[] {
//     const kvArray = arr.map((entry: any) => {
//      const key = keyProps.map(k => entry[k]).join('|');
//      return [key, entry];
//     });
//     const map = new Map(kvArray);
//     return Array.from(map.values());
//    }
// }
// >>>>>>> 03f80577d1c63e1059e73c729e51cdecf4c9b1c1
