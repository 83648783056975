import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, first, iif, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StreamList } from '../models/store';

@Injectable({
  providedIn: 'root',
})
export class LevelService {
  baseApi = environment.rootApiFoundation;
  levels: StreamList<any> = new StreamList<any>('levels', []);

  constructor(public libService: LibService) {}

  getAllLevels(refresh?: boolean): Observable<any> {
    const storedLevels = this.levels.$stream;
    const isLoaded = this.levels.length > 0 && !refresh;
    const $getLevels = this.libService
      .getData<any>(this.baseApi, 'levels/')
      .pipe(tap((levels: any) => this.levels.save(levels)));
    return iif(() => isLoaded, storedLevels, $getLevels).pipe(first());
  }

  getLevelById(id: any): Observable<any> {
    // console.log("GET LEVEL BY ID -> ", id);
    return this.libService.getData<any>(
      this.baseApi,
      `levels/${id.toString()}`
    );
  }

  createLevel(level: any): Observable<any> {
    return this.libService.postData<any>(level, this.baseApi, `levels/create`);
  }

  updateLevel(level: any): Observable<any> {
    return this.libService.postData<any>(level, this.baseApi, `levels/update`);
  }

  deleteLevel(level: any): Observable<any> {
    return this.libService.deleteData<any>(
      level,
      this.baseApi,
      `levels/delete/${level.id.toString()}`
    );
  }
}
