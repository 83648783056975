import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentService } from '../../services/component.service';
import { SignalerModal } from '../modals/signaler-modal/signaler-modal.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-add-signaler-button',
  templateUrl: './add-signaler-button.component.html',
  styleUrls: ['./add-signaler-button.component.scss']
})
export class AddSignalerButtonComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() components: any;
 
  componentName: any = 'add_signaler_button'

  constructor(
    private dialog: MatDialog,
    private componentService: ComponentService,
  ) { }

  ngOnInit(): void {}

  openModal() {

    let dataStructure: any = {
    }

    const dialogRef = this.dialog.open(SignalerModal, {
      width: '85%',
      data: dataStructure,
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (![null, undefined].includes(result)) {

        if (result.type == 'users') {
          this.form.get('reportingUserId')?.setValue(result.signalerId)
          this.form.get('reportingSubjectId')?.setValue(null)

          let name = result.signaler?.name
          let email = result.signaler?.email
          this.form.get('signaler')?.setValue(`${name} - ${email}`)

          this.form.markAsDirty();
        } else if (result.type == 'subjects' || result.type == 'addSubjects') {
          this.form.get('reportingUserId')?.setValue(null)
          this.form.get('reportingSubjectId')?.setValue(result.signalerId)

          let name = result.signaler?.name
          let surname = result.signaler?.surname
          let email = result.signaler?.email
          this.form.get('signaler')?.setValue(`${name} ${surname} - ${email}`)

          this.form.markAsDirty();
        }

      }

    });


  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    return status
  }

  disabledButton(){
    let status = false
    let createdBySignaler = this.form.get('createdBySignaler')?.value
    if(createdBySignaler) status = true
    return status
  }

}
