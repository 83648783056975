import { LibService } from './libService';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service'

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
    private authService: AuthService
  ) { }

  getAllProfiles(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, 'profiles/fast');
  }

  getAllProfilesFast(): Observable<any> {
    console.log(this.baseApi);
    return this.libService.getData<any>(this.baseApi, 'profiles/fast');
  }

  getProfile(id: number) {
    return this.libService.getData<any>(this.baseApi, `profiles/${id.toString()}`);
  }

  updateProfile(id: string, profile: any) {
    return this.libService.putData<any>({profile: profile}, this.baseApi, `profiles/${id.toString()}`);
  }

  setActions(profile: any){
    return this.libService.postData<any>({profile: profile}, this.baseApi, `profiles/setActions`);
  }

  createProfile(profile: any) {
    return this.libService.postData<any>({profile : profile}, this.baseApi, 'profiles');
  }

  deleteProfile(id: number) {
    return this.libService.deleteData<any>({}, this.baseApi, `profiles/${id.toString()}`);
  }

  touchAndSetActions(id: any, attributes: any, actionIds: any){
    let tokenId = this.authService.idToken()

    let attrs = {
      attributes: attributes,
      actionIds: actionIds
    }

    return this.libService.postDataWithAccessToken<any>(tokenId, attrs, this.baseApi, `profiles/${id}/touchAndSetActions`);
  }
}
