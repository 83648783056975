import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subject, pipe } from 'rxjs';
import { UserDataService } from '../services/users.service';
import { TeamService } from '../services/team.service';
import { CompanyService } from '../services/companyService';

import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';

import { faFile, faFileArrowUp, faFileArrowDown, faSave, faClose } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {
  public route_id: string = this.route.snapshot.params['id'] !== undefined ? this.route.snapshot.params['id'] : '';
  private innerWidth: any;
  private innerHeight: any;
  private title: string = 'Squadre';
  private subTitle: string = 'EDAC - Squadre';

  public displayedColumns = ['id', 'name', 'createdAt', 'updatedAt', 'editAction', 'deleteAction'];
  public dataSource: any;
  public kwSearch: any;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input() public external: boolean = false;
  @Input() public showSearch: boolean = true;
  @Input() public showActions: boolean = false;
  @Output() public closeTeams = new EventEmitter<any>();
  @Output() public setTeamSelected = new EventEmitter<any>();

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild('closeModal') closeModal: ElementRef

  faFileArrowDown = faFileArrowDown;
  faFileArrowUp = faFileArrowUp;
  faFile = faFile;
  faClose = faClose;

  private users: any[] = [];
  private companies: any[] = [];
  private teams: any[] = [];
  public teamsMapped: any[] = [];
  public actions: any[] = [];
  public elemetToDelete: any;

  constructor(
    private route: ActivatedRoute,
    private userDataService: UserDataService,
    private companyService: CompanyService,
    private teamService: TeamService,
    public navService: NavService,
    private libService: LibService,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
this.navService.setWikiKey('NODEF');

    this.actions = [
      { name:'new', url: 'teams/new' }
    ];
    this.navService.setActions(this.actions);

    this.libService.lockPage('');

    forkJoin({
      users: this.userDataService.getAllUsers(),
      companies: this.companyService.getAllCompanies(),
      teams: this.teamService.getTeams(),
    }).subscribe(res => {
      this.users = res.users;
      this.companies = res.companies;
      this.teams = res.teams;

      this.libService.unlockPage();

      this.teamsMapped = res.teams.map((el: any) => ({
        ...el,
        "resources": JSON.parse(el.resources),
      }))
      console.log(res, 'RES TEAMS');

      this.dataSource.data = this.teamsMapped;
      this.dataSource.paginator = this.paginator;
    });
  }

  public goTo(id: number | string) {
    if (!this.external) {
      this.router.navigate([`teams/${+id}`])
    } else {
      this.setTeamSelected.emit(+id);
    }
  }

  public addNewExternalTeam(): void {
    this.setTeamSelected.emit('new');
  }

  public updateTeams(): void {
    forkJoin({
      teams: this.teamService.getTeams(),
    }).subscribe(res => {
      this.teams = res.teams;

      this.libService.unlockPage();

      this.teamsMapped = res.teams.map((el: any) => ({
        ...el,
        "resources": JSON.parse(el.resources),
      }))
      console.log(res, 'RES TEAMS');

      this.dataSource.data = this.teamsMapped;
      this.dataSource.paginator = this.paginator;
    });
  }

  public closeExternalSection() {
    this.closeTeams.emit();
  }

  public markProfileForDeletion(element: any) {
    this.elemetToDelete = element;
  }

  public deleteTeam(id: number): void {
    this.libService.lockPage('');
    this.teamService.deleteTeam(id).subscribe(() => {
      this.closeModal.nativeElement.click();
      this.libService.unlockPage();
      this.dataSource.data = this.dataSource.data.filter((el: any) => el.id !== id);
      this.dataSource.data = this.dataSource.data.slice();
    })
  }

  public search(element: any) {
    if (element)
    this.dataSource.filter = element.trim().toLowerCase();
  }

}
