<mat-grid-list [cols]="breakpoint" (window:resize)="onResize($event)">
  <mat-grid-tile>

    <!-- CALENDARIO FESTIVITA'-->
    <mat-card class="example-card">
      <mat-card-header class="cardHeader">
        <mat-card-title> <mat-icon class="cardHeaderIcon" aria-hidden="false" aria-label="Example home icon" fontIcon="calendar_month"></mat-icon> {{ 'SIDEBAR.Holiday Calendar' | translate }}</mat-card-title>
      </mat-card-header>
    
      <mat-card-actions class="cardButton">
        <button (click)="goTo('paychecks-per-ten/calendar-settings')" mat-button>{{ 'LABEL.VAIALLASEZIONE' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>

  <!-- ANOMALIE CONSUNTIVAZIONE -->
  <mat-grid-tile>
    <mat-card class="example-card">
      <mat-card-header class="cardHeader">
        <mat-card-title> <mat-icon class="cardHeaderIcon" aria-hidden="false" aria-label="Example home icon" fontIcon="group"></mat-icon> {{ 'SIDEBAR.AnomalieConsuntivazione' | translate }}</mat-card-title>
      </mat-card-header>

      <mat-card-actions class="cardButton">
        <button (click)="goTo('final-balancer/history')"  mat-button>{{ 'LABEL.VAIALLASEZIONE' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>

  <!-- CONTRATTI -->
  <mat-grid-tile>
    <mat-card class="example-card">
      <mat-card-header class="cardHeader">
        <mat-card-title> <mat-icon class="cardHeaderIcon" aria-hidden="false" aria-label="Example home icon" fontIcon="contact_page"></mat-icon> {{ 'SIDEBAR.Contratti' | translate }} (coming soon)</mat-card-title>
      </mat-card-header>
 
      <mat-card-actions class="cardButton">
        <button (click)="goTo('paychecks-per-ten/calendar-contracts')" mat-button>{{ 'LABEL.VAIALLASEZIONE' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </mat-grid-tile>

</mat-grid-list>