<div class="container-fluid">
    <div class="search-wrapper mat-elevation-z2">
        <div class="search" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
            <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
                placeholder="{{ 'LABEL.SEARCH' | translate }}" />
        </div>
    </div>
    <div style="overflow-x: auto;">
        <table mat-table [dataSource]="dataSource" matSort style="width:100%">
            <ng-container matColumnDef="CODICE_COMMESSA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.CODICE_COMMESSA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.CODICE_COMMESSA}} </td>
            </ng-container>
            <ng-container matColumnDef="CODICE_SOGGETTO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.CODICE_SOGGETTO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.CODICE_SOGGETTO}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="DATA_INCASSO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.DATA_INCASSO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.DATA_INCASSO | date}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="DATA_SCADENZA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.DATA_SCADENZA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.DATA_SCADENZA | date}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="DESCRIZIONE_SOGGETTO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.DESCRIZIONE_SOGGETTO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.DESCRIZIONE_SOGGETTO}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="ID_COMMESSA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ID_COMMESSA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.ID_COMMESSA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ID_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ID_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.ID_FATTURA}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="IMPORTO_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.IMPORTO_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.IMPORTO_FATTURA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="IMPORTO_INCASSO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.IMPORTO_INCASSO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.IMPORTO_INCASSO}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="IdSede">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.IdSede'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.IdSede}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="NUMERO_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.NUMERO_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.NUMERO_FATTURA}} </td>
            </ng-container>
            <ng-container matColumnDef="NUMERO_FATTURA_STORNATA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.NUMERO_FATTURA_STORNATA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.NUMERO_FATTURA_STORNATA}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="NomeSede">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.NomeSede'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.NomeSede }}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ORIGINE_INCASSO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ORIGINE_INCASSO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.ORIGINE_INCASSO}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="RESPONSABILE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.RESPONSABILE'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.RESPONSABILE}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="RESPONSABILE_CREDITO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.RESPONSABILE_CREDITO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.RESPONSABILE_CREDITO}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="RESPONSABILE_ID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.RESPONSABILE_ID'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.RESPONSABILE_ID}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="SOCIETA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.SOCIETA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.SOCIETA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="SOCIETA_ID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.SOCIETA_ID'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.SOCIETA_ID}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TIPO_SOCIETA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TIPO_SOCIETA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.TIPO_SOCIETA}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TOTALE_NC">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TOTALE_NC'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.TOTALE_NC}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TipoSede">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TipoSede'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.TipoSede}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.code'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.code}}</b></span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
    </mat-paginator>
</div>
