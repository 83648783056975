import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Company } from 'src/app/models/company';
import { Society } from 'src/app/models/society';
import { CompanyService } from 'src/app/services/companyService';
import { map, tap } from "rxjs";
import { LibService } from 'src/app/services/libService';
import { LicenceService } from 'src/app/services/licenseService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-licence-filter',
  templateUrl: './admin-licence-filter.component.html',
  styleUrls: ['./admin-licence-filter.component.scss']
})
export class AdminLicenceFilterComponent implements OnInit {

  form: UntypedFormGroup
  @Output() filterResult = new EventEmitter;
  companies: Company[] = []

  companies$ = this.companyService.getAllCompanies().pipe(
    map((companies: Company[]) => {
      return companies.sort((a, b) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
     this.companies = companies
    })
  );

  constructor(
    private fb:UntypedFormBuilder,
    private companyService: CompanyService,
    private libService: LibService,
    private licenceService: LicenceService,
    private toastr: ToastrService,
  ) {
    this.form = this.fb.group({
      company: [[], null],
    })
   }

  ngOnInit(): void {

    this.companies$.subscribe()
  }

  filter(){
    let formValues = this.form.getRawValue()
    let options = formValues.company.map((company: Company) => company.code)
    this.licenceService.findManyByCompanies(options).subscribe({
      next: (licences => {
        let array: any[] = []
        licences.map(licence => {
          array.push(...licence.CompaniesLicencesRel)
        })
        this.filterResult.next(array)
        this.libService.unlockPage()
      }),
      error: (err => {
        this.toastr.error(err)
        this.libService.unlockPage()
      })
    })
  }

  clear(){
    this.form.reset({
      company: []
    })
    this.filterResult.next(null)
  }

  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
     const key = keyProps.map(k => entry[k]).join('|');
     return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
   }

   get companyControl(){
    return this.form.get('company') as UntypedFormControl
   }

   private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      if (typeof obj[propName] === 'object') {
        obj[propName] = this.clean(obj[propName])
      }
    }
    return obj
  }

}
