<h1 mat-dialog-title>Documenti Caricati</h1>
<div mat-dialog-content class="d-flex justify-content-center flex-column">
  <div *ngFor="let document of signedUrls$ | async" class="document-item">
    <!-- Collegamento per scaricare il file -->
    <a (click)="downloadDocument(document)">
      <!-- Icona di download a sinistra -->
      <mat-icon>download</mat-icon>
      {{ document.name }}
    </a>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Chiudi</button>
</div>
