import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { JourneyService } from '../services/journeyService';
import { LibService } from '../services/libService';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { NavService } from '../services/nav.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserSessionService } from '../services/user-session.service';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


export interface Journey {
  date: Date,
  operator: String,
  company: string,
  orderAddress: string,
  km: number,
  hours: number,
  practice: string,
  reason: string
}

@Component({
  selector: 'app-journeys',
  templateUrl: './journeys.component.html',
  styleUrls: ['./journeys.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4,0.0, 0.2, 1)')),
    ])]
})
export class JourneysComponent implements OnInit {

  constructor(public navService: NavService, private fb: UntypedFormBuilder, private journeyService: JourneyService, public libService: LibService, private router: Router, private userSessionService: UserSessionService) {
    this.dateForm = this.fb.group({
      companies: [null, Validators.required],
      from: [null, Validators.required],
      to: [null, Validators.required]
    });
    this.dataSource = new MatTableDataSource();
  }

  displayedColumns: string[] = ['date', 'operator', 'company', 'practice', 'orderAddress', 'hours', 'reason', 'km'];
  dataSource: any = [];
  companies: any = [];
  selectedValue: string;
  dateForm: UntypedFormGroup

  title = "Tragitti";
  subTitle = "EDAC - Tragitti";

  faFileArrowDown = faFileArrowDown
  fileName = 'journeys.xlsx'

  exportOnSidebar: any = {
    name: 'export_in_page',
    items: [],
    header: ["Date", "Operator", "Company", "Order", "Address", "Hours", "Causals", "KM"],
    sheetName: "Journeys",
    fileName: this.fileName
  };


  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.getCompanyCode();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    //this.navService.setWikiKey('PRH'); //TODO
    let actions: any;
    actions = [
      //{ name: 'new', url: 'journeys/0' },
      this.exportOnSidebar,
    ];
    this.navService.setActions(actions);
  }

  getCompanyCode() {
    this.companies = [];
    let us = this.userSessionService.getState('user');

    if (!(us == null) && !(us.UsersCompaniesRel == null)) {
      console.log('this.userSessionService.getState(user) %o', us.UsersCompaniesRel);
      for (let a = 0; a < us.UsersCompaniesRel.length; a++) {
        if (us.UsersCompaniesRel[a].country.toLowerCase() == "fr") {
          this.companies.push(
            { label: us.UsersCompaniesRel[a].code, value: us.UsersCompaniesRel[a].id }
          );
        }
      }
      this.companies.sort((a: any, b: any) => a.label.toUpperCase() <= b.label.toUpperCase() ? -1 : 1)
    }
  }

  submitSearch() {
    let companyName = this.dateForm.get('companies')!.value.label
    let companyId = this.dateForm.get('companies')!.value.value
    let fromDate = moment(this.dateForm.get('from')!.value).toDate()
    let toDate = moment(this.dateForm.get('to')!.value).toDate()

    if (this.dateForm.valid) {
      this.loadJourneys(companyName, companyId, fromDate.getTime() / 1000 | 0, toDate.getTime() / 1000 | 0)
    }

  }

  loadJourneys(companyName: string, companyId: number, fromDate: number, toDate: number): void {



    let tmp_journeys: Array<Journey> = [];
    this.libService.lockPage('');
    this.journeyService.getJourneys(companyId, fromDate, toDate).subscribe((response) => {
      console.log('loadJourneys response %o', response);
      response.forEach((item: any) => {
        tmp_journeys.push(
          {
            date: new Date(item.tsStart * 1000),
            operator: item.name,
            company: companyName,
            practice: item.code,
            orderAddress: this.getAddressofTheOrder([
              item.streetName,
              item.streetNumber,
              item.city,
              item.country,
              item.province,
              item.state,
              item.zip
            ]),
            hours: item.hours,
            reason: item.uiLabel,
            km: item.orderKm
          }
        )
      });

      if (tmp_journeys.length > 0) {
        tmp_journeys.sort((a: any, b: any) => a.date - b.date);
        this.dataSource.data = tmp_journeys;
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource.data = [];
        for (const message in response.Messages) {
          this.libService.showMessageError(message);
        }
      }
      this.touchDownloadExportOnNavbar(this.dataSource.data) // verifica se abilitare il download del report da navbar
      this.libService.unlockPage();
    });
  }


  getAddressofTheOrder(items: Array<any>) {
    let tmp_address = items.filter(n => n).join(', ');
    return tmp_address
  }

  // Attiva il download dalla navbar solo se sono presenti dei dati
  touchDownloadExportOnNavbar(items: any) {
    this.exportOnSidebar.items = items; // Aggiunge i record che popolano la tabella
    this.navService.setActions([this.exportOnSidebar]);
  }

  resetFormAndTable() {
    this.libService.lockPage('');
    this.dateForm.reset()
    this.dataSource.data = [];
    this.touchDownloadExportOnNavbar(this.dataSource.data) // blocca il download dell'export dalla navbar
    this.libService.unlockPage();
  }

}





