<p [innerHTML]="question">
  {{question}}
</p>

<form [formGroup]="geoForm">

  <!-- CAMPI STANDARD DELL'ORDINE -->

  <mat-form-field style="width: 100%;" appearance="fill" class="form-activities">
    <mat-label>{{ 'GEN_CONFIG.COMPANIES' | translate }}</mat-label>
    <mat-select matNativeControl formControlName="company" #typeInput>
      <mat-option *ngFor="let company of companies" [value]="company">
        {{company.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  </form>

<footer>
  <button [disabled]="!geoForm.valid" mat-button style="background-color:transparent; color:#293683;" [mat-dialog-close]="geoForm.value">{{ 'LABEL.YES' | translate }}</button>
  <button mat-button [mat-dialog-close]="false" >{{ 'LABEL.NO' | translate }}</button>
</footer>

