
<h2 mat-dialog-title>{{ 'LABEL.DETAIL' | translate }} {{ 'LABEL.ORDER' | translate }} - {{ complaint.orderNumberEA }} </h2>
<div mat-dialog-content>
  <br>
  <form [formGroup]="formOrders">

    <!-- CAMPI STANDARD DELL'ORDINE -->

    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{ 'LABEL.ORDER' | translate }}</mat-label>
      <input matInput type="text" formControlName="code">
    </mat-form-field>
    <br>
    <mat-form-field style="width:100%;" appearance="outline" *ngIf="(formOrders.get('nameBuilding')?.value !== null && formOrders.get('nameBuilding')?.value !== undefined)">
      <mat-label>{{ 'LABEL.BUILDING' | translate }}</mat-label>
      <input matInput type="text" formControlName="nameBuilding" readonly >

    </mat-form-field>

    <br>
    <mat-form-field appearance="outline" *ngIf="(formOrders.get('nameSubject')?.value !== null && formOrders.get('nameSubject')?.value !== undefined)">
      <mat-label>{{ 'LABEL.SUBJ' | translate }}</mat-label>
      <input matInput type="text" formControlName="nameSubject" readonly>

    </mat-form-field>

    <mat-form-field style="width:100%;" appearance="outline" *ngIf="(formOrders.get('nameCondominius')?.value !== null && formOrders.get('nameCondominius')?.value !== undefined)">
      <mat-label>{{ 'LABEL.CONDOMINIUM' | translate }}</mat-label>
      <input matInput type="text" formControlName="nameCondominius" readonly>

    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="(formOrders.get('nameAdminCondominius')?.value !== null && formOrders.get('nameAdminCondominius')?.value !== undefined)">
      <mat-label>{{ 'LABEL.ADMIN' | translate }}</mat-label>
      <input matInput type="text" formControlName="nameAdminCondominius" readonly>
    </mat-form-field>
  <hr>
    <!-- CAMPI DELLA VIEW COMMESSE -->
    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.DATA_DELIBERA' | translate }}</mat-label>
      <input matInput type="text" formControlName="DATA_DELIBERA" readonly>

    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'LABEL.VALORE_DELIBERATO' | translate }}</mat-label>
      <input matInput type="text" formControlName="VALORE_DELIBERATO" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.Valore Totale Prodotto' | translate }}</mat-label>
      <input matInput type="text" formControlName="VALORE_PRODOTTO" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.Tot. fatturato lordo' | translate }}</mat-label>
      <input matInput type="text" formControlName="FATTURATO" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.Tot. incassato lordo' | translate }}</mat-label>
      <input matInput type="text" formControlName="INCASSATO" readonly>
    </mat-form-field>

<!-- CAMPI ORE QV ORE - LOGICA DA IMPLEMENTARE
    <!-     DATA PRIMO INS ORE  = DATA DELLA PRIMA CONSUNTIVAZIONE
            DATA ULTIMO INS ORE = DATA DELLA ULTIMA CONSUNTIVAZIONE
            TOTALE ORE          = SOMMA DELA QTA ORE
            VALORE ORE          = MEDIA DEL VALORE DELLE ORE (MEDIA DI ValoreOre)
    ->
-->
  <hr>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.QUANTITA_ORE' | translate }}</mat-label>
      <input matInput type="text" formControlName="QuantitaOre" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.VALORE_ORE' | translate }}</mat-label>
      <input matInput type="text" formControlName="ValoreOre" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.PRIMO_INS' | translate }}</mat-label>
      <input matInput type="text" formControlName="primoIns" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.ULTIMO_INS' | translate }}</mat-label>
      <input matInput type="text" formControlName="ultimoIns" readonly>
    </mat-form-field>
<!--  VIEW_FATTURAZIONE_FATTURE -->
    <!--
          NUMERO NOTA CREDITO   = NUMERO_FATTURA
          VALORE NOTE  CREDITO  = TOTALE_FATTURA
          TOTALE SCADUTO LORDO  = SOMMA DELLE FATTURE LORDE DELLE NC CHE HANNO PASSATO LA DATA DI SCADENZA
          GG MEDI RITARDO       = GIORNI DI RITARDO DEL PAGAMENTO DELLA FATTURA -> MEDIA DEI GIORNI DI RITARDO DI PAGAMENTO DELLE FATTURE NC -->
  <hr>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.Numero NC' | translate }}</mat-label>
      <input matInput type="text" formControlName="NUMERO_FATTURA" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.Valore NC' | translate }}</mat-label>
      <input matInput type="text" formControlName="TOTALE_FATTURA" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.TOT_SCADUTO_LORDO' | translate }}</mat-label>
      <input matInput type="text" formControlName="TOT_SCADUTO_LORDO" readonly>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.GG_MEDI_RIT' | translate }}</mat-label>
      <input matInput type="text" formControlName="GG_MEDI_RIT" readonly>
    </mat-form-field>
    <!--  QV_COSTI -->
      <!--
            VALORE MATERIALI = Valore (view Costi)
        -->

    <mat-form-field appearance="outline">
      <mat-label>{{ 'COMPLAINTS.VALORE_MATERIALI' | translate }}</mat-label>
      <input matInput type="text" formControlName="Valore" readonly>
    </mat-form-field>

  </form>

</div>
<div mat-dialog-actions [align]="'end'">
  <button mat-button style="float: right;" (click)="closeDialog()"><mat-label>{{ 'LABEL.CLOSE' | translate }}</mat-label></button>
</div>

