import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActiveSession } from '../models/activeSession';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class ActiveSessionService {

  baseApi = environment.rootApiFoundation;

  constructor(
    public libService: LibService,
  ) { }

  create(activeSession: ActiveSession){
    return this.libService.postData<ActiveSession>(activeSession, this.baseApi, 'activeSession')
  }

  update(activeSession: ActiveSession){
    return this.libService.putData(activeSession, this.baseApi, `activeSession/${activeSession.id}`)
  }

  delete(activeSessionId: number){
    return this.libService.deleteData(null, this.baseApi, `activeSession/${activeSessionId}`)
  }
}
