<div>
  <br>
  <br>
  <input type="text" class="form-control" [placeholder]="translate.instant('FORM_FIELD.SEARCH')" [(ngModel)]="search"
    [value]="search" (ngModelChange)="onFilter()">

  <br>

  <button class="btn btn-primary btn-sm me-3 text-dark reviewAdd" (click)="openEditDialog({})">
    <div>{{'LABEL.AggiungiNuovo' | translate}}</div>
  </button>

  <app-data-table-with-pagination [data]="items" [columns]="columns" [displayedColumns]="displayedColumns"
    [actions]="actions" [totalRows]="totalRows" [pageInit]="pageInit" [currentPage]="currentPage"
    (changePagination)="pageChanged($event)" (changeOrder)="updateDataBySortParams($event)"
    sortActive=""></app-data-table-with-pagination>

</div>