import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { CreateQueryParams, QueryJoin, QueryJoinArr, RequestQueryBuilder } from '@nestjsx/crud-request';
import { Observable } from 'rxjs';
import { OutgoingEmailSettings } from '../models/email-template';

export interface FindOneQueryParams {
  join?: QueryJoin | QueryJoinArr | Array<QueryJoin | QueryJoinArr>;
  fields?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class OutgoingEmailService {

  baseApi = environment.rootAlbertJob + 'email'

  constructor(private libService: LibService) { }

  findAll(){
    return this.libService.getData(this.baseApi, '')
  }

  findOne(id: string, params: FindOneQueryParams = {}): Observable<OutgoingEmailSettings> {
    let queryParams: CreateQueryParams;
    let qb;
    if (params) {
      queryParams = {
        join: params.join,
        fields: params.fields
      }
      qb = RequestQueryBuilder.create(queryParams);
    }
    return this.libService.getData(`${this.baseApi}/${id}${qb ? '?' + qb.query() : ''}`, '');
  }

  update(id: string, data: any){
    return this.libService.putData(data,`${this.baseApi}/${id}`, '')
  }
}
