<ng-container *ngIf="showIframeCruscotto(); else noRA">
    <iframe #iFrameCruscotto [style.height]="innerHeight" id="CertificatesIframe" style="width: 100%"
        [src]="certurl | safe" title="Invoice Certificates"></iframe>
</ng-container>
<ng-template #noRA>
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Post-->

        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
            <!--begin::Post-->

            <div class="post d-flex flex-column-fluid" id="kt_post">

                <!--begin::Container-->
                <div id="kt_content_container" class="container-xxl">

                    <div *ngFor="let category of dashboardsByCategory">
                        <mat-expansion-panel style="background-color: whitesmoke;">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span class="material-icons inline-icon">query_stats</span>
                                    <div class="fw-bolder text-dark"> {{ category.label }}</div>
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <div class="row g-6 g-xl-9">


                                <div class="col-md-6 col-xl-4" *ngFor="let dashboard of category.data">
                                    <!--begin::Card-->
                                    <div *ngIf="!(dashboard == null)" class="card h-100">
                                        <!--begin::Card body-->
                                        <div class="card-body p-9">
                                            <div class="fs-3 fw-bolder text-dark mb-7" *ngIf="dashboard !== null">
                                                {{dashboard.name}}
                                            </div>
                                            <div class="d-flex">
                                                <a class="btn btn-primary btn-sm me-3 text-dark"
                                                    (click)="viewDashboard(dashboard)">{{
                                                    'LABEL.VIEW'
                                                    | translate:{'field': translate.instant('LABEL.DASHBOARD') } }}</a>

                                                <a class="alignMiddle btn btn-primary btn-sm me-3 text-dark"
                                                    (click)="openDashboardToExt(dashboard)" extended>
                                                    <mat-icon>open_in_new</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <br>
                    </div>

                    <br>

                </div>
            </div>
        </div>
    </div>
</ng-template>
