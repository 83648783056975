<div class="full-w" fxLayout="row" fxLayoutAlign="center center" *ngIf="form">
  <form [formGroup]="form" >

    <div class="form-subject" *ngIf="(form.get('id')?.value !== null && form.get('id')?.value !== undefined)">
      <mat-radio-group formControlName="solicitHandled" name="solicitHandled">
        <label id="example-radio-group-label">{{ 'LABEL.SOLICIT_HANDLED' | translate }}</label>
        <br>
        <br>
        <mat-radio-button [value]="true" name="solicitHandled">

          {{ 'LABEL.YES' | translate }}

        </mat-radio-button>
        <mat-radio-button [value]="false" name="solicitHandled" style="margin-left: 2em;">

          {{ 'LABEL.NO' | translate }}

        </mat-radio-button>
        <br>
        <br>

      </mat-radio-group>
    </div>
    <mat-card>
      <mat-card-title>
        {{ 'LABEL.SUBJ' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;" (click)="clearForm('subject')" [disabled]="(form.get('id')?.value !== null && form.get('id')?.value !== undefined)">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>
      <div>
        <div formGroupName="subject"  class="form-container">
          <app-fulltextsearch2 *ngIf="!(form.get('id')?.value !== null && form.get('id')?.value !== undefined)" class="form-subject" [service]="subjectService" searchType="Soggetto" (selected)="selSubject($event)"></app-fulltextsearch2>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.SUBJ_TYPE' | translate }}</mat-label>
            <mat-select matNativeControl name="type" formControlName="type" [disabled]="form.get('id')?.value !== null">
              <mat-option *ngFor="let type of types" [value]="type.description">
                {{type.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="hideLegalEntity" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.NAME' | translate }}</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <mat-form-field *ngIf="hideLegalEntity" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.SURNAME' | translate }}</mat-label>
            <input matInput formControlName="surname">
          </mat-form-field>

          <mat-form-field *ngIf="!(hideLegalEntity)" appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.LEGAL_ENTITY' | translate }}</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.MOBILE' | translate }}:</mat-label>
            <input matInput type="phone" formControlName="telephone2"
            [placeholder]="translate.instant('LABEL.INSERISCI_NUMERO_TELEFONO_MOBILE')" readonly>
            <mat-icon matSuffix>smartphone</mat-icon>
            <mat-hint>{{ 'REQUEST.MOBILE_ERROR' | translate }}</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.EMAIL' | translate }}</mat-label>
            <input matInput type="email" formControlName="email" [placeholder]="translate.instant('LABEL.INSERISCI_EMAIL')" readonly>
            <mat-icon matSuffix>mail_outline</mat-icon>
            <!-- <mat-hint>Inserire una email valida</mat-hint>
                        <mat-error **ngIf="(formAnagrafica.get('email')).errors?.required">L'email è obbligatoria
                        </mat-error>
                        <mat-error **ngIf="(formAnagrafica.get('email')).errors?.pattern">E' necessario inserire una
                            email valida
                        </mat-error> -->
          </mat-form-field>

          <mat-form-field appearance="fill" class="form-subject">
            <mat-label>{{ 'REQUEST.ORIGIN' | translate }}</mat-label>
            <mat-select formControlName="origin">
              <mat-option *ngFor="let origin of origins" [value]="origin.origin" [disabled]="origin.disabled">
                {{ 'ORIGINS.'+origin.code | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <app-address-detail-form [form]="subjectForm" class="form-subject"></app-address-detail-form>
        </div>
      </div>
    </mat-card>
    <mat-card>
      <mat-card-title>
        {{ 'LABEL.BUILDING' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;" (click)="clearForm('building')" [disabled]="(form.get('id')?.value !== null && form.get('id')?.value !== undefined)">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>
      <div>
        <div formGroupName="building"  class="form-container">
          <app-fulltextsearch2 *ngIf="!(form.get('id')?.value !== null && form.get('id')?.value !== undefined)" class="form-subject" [service]="buildingService" searchType="Edificio" (selected)="selSubject($event)"></app-fulltextsearch2>

          <!-- <app-address-search [form]="buildingForm" class="form-subject"></app-address-search> -->

          <app-address-detail-form [form]="buildingForm" class="form-subject"></app-address-detail-form>
        </div>
      </div>
    </mat-card>

  <mat-card>
      <mat-card-title>
        {{ 'LABEL.ORDER' | translate }} / {{ 'LABEL.REQUEST' | translate }}
        <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;" (click)="clearForm('order')" [disabled]="(form.get('id')?.value !== null && form.get('id')?.value !== undefined)">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button>
      </mat-card-title>

      <mat-tab-group class="" animationDuration="300ms" #tabGroup  [selectedIndex]="selectedTab" (selectedTabChange)="onTabChanged($event)" ><!--  wbck -->
        <mat-tab [disabled]="(form.get('id')?.value !== null && form.get('id')?.value !== undefined)">
          <!-- INIZIO TAB PRATICA ---------------------------------------------- -->
          <ng-template mat-tab-label>
              <mat-icon class="my-bounce material-icons">request_quote</mat-icon>
              <span class="ml-1">{{ 'LABEL.ORDER' | translate }}</span>
          </ng-template>
              <!-- <app-fulltextsearch2 class="form-subject" [searchCleaner]="clean" [events]="eventSearch" [filterIDS]="filterIDS" [service]="orderService" searchType="Order" (selected)="selOrder($event)"></app-fulltextsearch2>  -->
              <br>
              <!-- <mat-form-field appearance="fill" class="form-subject" >
                <mat-label>{{ 'LABEL.ORDERS' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedOrder"  [compareWith]="compareFn" [ngModelOptions]="{standalone: true}" [disabled]="!(orders.length > 0) || (form.get('id')?.value !== null && form.get('id')?.value !== undefined)" (ngModelChange)="selOrder($event)">
                  <mat-option *ngFor="let order of orders" [value]="order">
                    {{ order.code + " v" + order.version }} - <b>{{ 'LABEL.ORDER' | translate }}</b>
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
              <form [formGroup]="formSelect">
                <div class="form-subject" appearance="fill">
                  <app-select-search formControlName="listaOrdini" [items]="orders" [label]="translate.instant('LABEL.ORDERS')"
                  [noUsers]="!(orders.length > 0) || (form.get('id')?.value !== null && form.get('id')?.value !== undefined)"
                                  viewField="codePratica"></app-select-search>
                </div>
              </form>

                <div formGroupName="order" class="form-container">
                  <mat-form-field  appearance="fill" class="form-subject" >
                    <mat-label>{{ 'LABEL.ORDER' | translate }}</mat-label>
                    <input matInput formControlName="code" >
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="form-subject">
                    <mat-label>{{ 'LABEL.STATUS' | translate }} {{ 'LABEL.ORDER' | translate }}</mat-label>
                    <mat-select formControlName="statusDescription" [compareWith]="compareFn">
                      <mat-option *ngFor="let status of statuses" [value]="status" >
                        {{ 'LABEL.' + status.descx | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="(form.get('order.nameAdminCondominius')?.value !== null && form.get('order.nameAdminCondominius')?.value !== undefined && form.get('order.nameAdminCondominius')?.value !== '')"
                    appearance="fill" class="form-subject" >
                    <mat-label>{{ 'LABEL.ADMIN' | translate }}</mat-label>
                    <input matInput formControlName="nameAdminCondominius" >
                  </mat-form-field>
                  <mat-form-field *ngIf="(form.get('order.nameBuilding')?.value !== '' && form.get('order.nameBuilding')?.value !== null && form.get('order.nameBuilding')?.value !== undefined)"
                    appearance="fill" class="form-subject" >
                    <mat-label>{{ 'LABEL.BUILDING' | translate }}</mat-label>
                    <input matInput formControlName="nameBuilding" readonly>
                  </mat-form-field>
                  <mat-form-field *ngIf="(form.get('order.nameCondominius')?.value !== '' && form.get('order.nameCondominius')?.value !== null && form.get('order.nameCondominius')?.value !== undefined)"
                    appearance="fill" class="form-subject">
                    <mat-label>{{ 'LABEL.CONDOMINIUM' | translate }}</mat-label>
                    <input matInput formControlName="nameCondominius" readonly>
                  </mat-form-field>
                  <mat-form-field *ngIf="(form.get('order.nameSubject')?.value !== '' && form.get('order.nameSubject')?.value !== null && form.get('order.nameSubject')?.value !== undefined)"
                    appearance="fill" class="form-subject">
                    <mat-label>{{ 'ACTIVITIES.SUBJ' | translate }}</mat-label>
                    <input matInput formControlName="nameSubject" readonly>
                  </mat-form-field>
                </div>
              <!-- FINE TAB PRATICA ---------------------------------------------------------- -->
        </mat-tab>
        <mat-tab [disabled]="(form.get('id')?.value !== null && form.get('id')?.value !== undefined)">
          <!-- INIZIO TAB RICHIESTA ------------------------------------------------------------------------ -->
          <ng-template mat-tab-label>
              <mat-icon class="my-bounce material-icons">report</mat-icon>
              <span class="ml-1">{{ 'LABEL.REQUEST' | translate }}</span>
          </ng-template>

            <!-- <app-fulltextsearch2 class="form-subject" [searchCleaner]="clean" [events]="eventSearch" [filterIDS]="filterIDS" [service]="orderService" searchType="Order" (selected)="selOrder($event)"></app-fulltextsearch2>  -->
            <!-- <app-fulltextsearch2 [service]="requestService" (selected)="selRequest($event)" ></app-fulltextsearch2> (list)="updateList($event)" -->
            <br>
              <mat-form-field class="form-subject">
                <mat-label>{{ 'LABEL.REQUESTS' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedRequest" [compareWith]="compareFn" [ngModelOptions]="{standalone: true}" [disabled]="!(requests?.length > 0) || (form.get('id')?.value !== null && form.get('id')?.value !== undefined)" (ngModelChange)="selRequest($event)">
                  <mat-option *ngFor="let request of requests" [value]="request">
                    {{ (request?.subject?.name !== null && request?.subject?.name !== undefined? request.subject.name: '') + (request?.building?.name !== null && request?.building?.name !== undefined? " - " + request.building.name: ' ') + (request.assignmentOperator !== null? " - " + request.assignmentOperator: '') }}  <b>{{ 'LABEL.REQUEST' | translate }}</b>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div formGroupName="solicitRequest" class="form-container">
                <mat-form-field  appearance="fill" class="form-subject" >
                  <mat-label>{{ 'LABEL.REQUEST' | translate }}</mat-label>
                  <input matInput formControlName="id" >
                </mat-form-field>
                <mat-form-field *ngIf="(form.get('solicitRequest.assignmentOperator')?.value !== null)"
                  appearance="fill" class="form-subject" >
                  <mat-label>{{ 'LABEL.responsible' | translate }}</mat-label>
                  <input matInput formControlName="assignmentOperator" >
                </mat-form-field>
                <mat-form-field *ngIf="(form.get('solicitRequest.nameBuilding')?.value !== '' && form.get('solicitRequest.nameBuilding')?.value !== null && form.get('solicitRequest.nameBuilding')?.value !== undefined)"
                  appearance="fill" class="form-subject" >
                  <mat-label>{{ 'LABEL.BUILDING' | translate }}</mat-label>
                  <input matInput formControlName="nameBuilding" readonly>
                </mat-form-field>
                <mat-form-field *ngIf="(form.get('solicitRequest.nameSubject')?.value !== '' && form.get('solicitRequest.nameSubject')?.value !== null && form.get('solicitRequest.nameSubject')?.value !== undefined)"
                  appearance="fill" class="form-subject">
                  <mat-label>{{ 'ACTIVITIES.SUBJ' | translate }}</mat-label>
                  <input matInput formControlName="nameSubject" readonly>
                </mat-form-field>

              </div>
            <!-- FINE TAB RICHIESTA ------------------------------------------------------------ -->
        </mat-tab>
      </mat-tab-group>
      <div>
        <!-- <app-fulltextsearch2 class="form-subject" [service]="orderService" searchType="Order" (selected)="selOrder($event)"></app-fulltextsearch2> -->

      </div>
    </mat-card>
    <mat-card>
      <mat-card-title>
        {{ 'REQUEST.OTHER' | translate }}
        <!-- <button class="my-bounce btn btn-primary mx-2" style="background-color:transparent;" (click)="clearForm('subject')">
          <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
        </button> -->
      </mat-card-title>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label>{{ 'REQUEST.SOLICIT_REASON' | translate }}</mat-label>
          <mat-select formControlName="solicitReason" [disabled]="disable">
            <mat-option *ngFor="let reason of solicitReasons" [value]="reason.code">
              {{ 'LABEL.' + reason.uiLabel | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject">
          <mat-label>{{ 'LABEL.NOTE' | translate }}</mat-label>
          <input matInput formControlName="notes">
        </mat-form-field>

    </mat-card>
  </form>
</div>
