import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor() { 
  }

  public encrypt(value: any, type: string) {
    let key = this.getKey(type)
    let jsonString = JSON.stringify(value)
    return CryptoJS.AES.encrypt(jsonString, key).toString()
  }

  public decrypt(value: any, type: string) {
    let key = this.getKey(type)
    let obj = CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
    return JSON.parse(obj) 
  }

  private getKey(type: string){
    let key = ''
    switch(type) {
      case 'sessionVisibilities':
        key = environment.sessionToken
        break;
      default:
        key = ''
    }
    return key
  }
}
