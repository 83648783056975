import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';


import { TranslateService } from '@ngx-translate/core';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';


@Component({
  selector: 'app-practices-history',
  templateUrl: './practices-history.component.html',
  styleUrls: ['./practices-history.component.scss']
})
export class PracticeHistoryComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() components: any;
  @Input() practiceLogs: any;
  @Input() stateCategories: any;
  @Input() reasons: any;

  attributes: any;

  orderCode: string;

  componentName: any = 'practices_history'

  sortActive:any = 'createdAt'


  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {

  }

  setAttributes() {
    //console.log('setAttributes')
    let component = this.components?.settings?.find((component: any) => component.componentCode == this.componentName)
    this.attributes = component?.attributes
  }

  checkComponent() {
    let status = this.components?.codes?.includes(this.componentName)
    if (status) this.setAttributes()
    return status
  }

  columns: DatatableColumn<any>[] = [
    {
      name: "StateId",
      title: this.translate.get('PRACTICEHISTORY.state'),
      cssClass: history => ['column-padding'],
      value: history => this.getStatusLabel(history.StateId)
    },
    {
      name: "PreviousStateId",
      title: this.translate.get('PRACTICEHISTORY.previousState'),
      cssClass: history => ['column-padding'],
      value: history => this.getStatusLabel(history.PreviousStateId)
    },
    {
      name: "ReasonsId",
      title: this.translate.get('PRACTICEHISTORY.reason'),
      cssClass: history => ['column-padding'],
      value: history => this.getReasonLabel(history.ReasonsId)
    },
    {
      name: "note",
      title: this.translate.get('PRACTICEHISTORY.note'),
      cssClass: history => ['column-padding'],
      value: history => history?.note
    },
    {
      name: "author",
      title: this.translate.get('PRACTICEHISTORY.author'),
      cssClass: history => ['column-padding'],
      value: history => history?.author?.name
    },
    {
      name: "createdAt",
      title: this.translate.get('PRACTICEHISTORY.created'),
      cssClass: history => ['column-padding'],
      value: history => this.datePipe.transform(history.createdAt?.toString(), 'd/M/yy, h:mm a')
    }
  ]

  actions: any= [];

  displayedColumns: string[] = ['StateId', 'PreviousStateId', 'ReasonsId', 'note', 'author', 'createdAt'];

  getStatusLabel(stateId: any) {
    if (stateId !== null && this.stateCategories) {
      let currentState = this.stateCategories.find((stateCategory: any) => stateCategory?.states?.map((state: any) => state.id)?.includes(stateId))
      return this.translate.instant('STATECATEGORIES.' + currentState?.code) as string
    } else {
      return this.translate.instant('LABEL.NOT_PRESENT') as string
    }
  }

  getReasonLabel(resonId: any) {
    if (resonId !== null && this.reasons) {
      let currentReason = this.reasons.find((reason: any) => reason.id == resonId )
      return this.translate.instant('REASONS.' + currentReason?.code) as string
    }
    return resonId
  }

}
