import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { workCausalDocumentTypesService } from '../../../../services/workCausalDocumentTypesService'; // Importa il servizio
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from 'src/app/services/translate.service';




@Component({
  selector: 'app-new-document-dialog',
  templateUrl: './new-document-dialog.html',
  styleUrls: ['./new-document-dialog.scss'],
})
export class NewDocumentDialogComponent implements OnInit {
  documentForm: FormGroup;
  documentId = new FormControl();

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  // Ricevi dati tramite DI
    private workCausalDocumentTypesService: workCausalDocumentTypesService, // Usa il servizio
    public translate: TranslatorService,

  ) {}

  ngOnInit(): void {
    this.documentForm = this.fb.group({
      workCausalId: [{ value: this.data.workCausalId, disabled: true }],  // Mostra l'ID ma lo disabilita
      documentId: ['', Validators.required]  // Campo per il nuovo documento
    });
  }

  onSubmit() {
    // Se il form è valido, crea il documento
    if (this.documentForm.valid) {
      const documentName = this.documentForm.get('documentId')?.value;

      // Creazione del payload
      const payload = {
        label: documentName, // Nome del documento
      };

      this.workCausalDocumentTypesService.create(payload).subscribe(
        (response) => {
          // Successo, passa il risultato indietro al dialogRef
          this.toastr.success('Documento "' + payload.label + '" aggiunto con successo', this.translate.instant('LABEL.Info'));
          this.dialogRef.close(response);
        },
        (error) => {
          // Gestisci l'errore
          console.error('Errore nella creazione del documento:', error);
        }
      );
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
