import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, of, switchMap } from 'rxjs';
import { Dashboard } from '../models/dashboard';
import { DashboardService } from '../services/dashboard.service';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-dashboard-create',
  templateUrl: './dashboard-create.component.html',
  styleUrls: ['./dashboard-create.component.scss']
})
export class DashboardCreateComponent implements OnInit {


  isNew: boolean = false;
  dashboardForm: UntypedFormGroup
  countries = [
    {
      value: 'ITA',
      name: 'Italy'
    },
    {
      value: 'FRA',
      name: 'France'
    },
    {
      value: 'SPA',
      name: 'Spain'
    },
    {
      value: '*',
      name: '*'
    }
  ];

  dashboard$ = this.route.paramMap.pipe(
    map(params => params.get('id')),
    switchMap(id => {
      if(id!== '0'){
        this.isNew = false;
      }else{
        this.isNew = true;
      }
      return id!=='0'?this.dashboardService.getDashboard(id!):of('')})
  )

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dashboardService: DashboardService,
    public translate: TranslatorService) { }

  ngOnInit(): void {
    this.dashboardForm = this.fb.group({
      id: '',
      name: '',
      dashId: '',
      country: ''
    })

    this.dashboard$.subscribe((dashboard: any)=> {
      if(dashboard !== ''){
        this.dashboardForm.patchValue(dashboard)
      }
    })
  }



  save(){
    let dashboard = this.dashboardForm.getRawValue()
    if(this.isNew){
      delete dashboard.id
      this.dashboardService.createOne(dashboard).subscribe(res=> {
        console.log(res);
      })
    }else{
      this.dashboardService.updateOne(dashboard).subscribe(res=> {
        console.log(res);

      })
    }
  }

  goBack() {
    this.router.navigate(['']);
  }

}
