import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of, combineLatest, map, switchMap } from 'rxjs';
import { FileUploadModalComponent } from 'src/app/modals/file-upload-modal/file-upload-modal.component';
import { Document, DocumentType } from 'src/app/models/document';
import { DocumentTemplateService } from 'src/app/services/document-template.service';
import { DocumentTypeService } from 'src/app/services/document-type.service';

@Component({
  selector: 'app-request-document',
  templateUrl: './request-document.component.html',
  styleUrls: ['./request-document.component.scss']
})
export class RequestDocumentComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() disableUpload: boolean;
  @Input() isNew: boolean;

  documents: Document[];
  documentTypes: DocumentType[];
  globalDocumentTypes$ = this.documentTypeService.getAllGeneric()

  constructor(private dialog: MatDialog, private documentTypeService: DocumentTypeService, private documentTemplateService: DocumentTemplateService) { }

  ngOnInit(): void {
    this.form.get('building')?.get('documents')?.value !== null?
      this.documents = this.form.get('building')?.get('documents')?.value.filter((document: Document) => document.requestId === this.form.get('id')?.value):
      console.log("=null")
    this.form.get('building')?.get('documents')?.valueChanges.subscribe((documents: Document[]) => {
      this.documents = documents?.filter(document => document.requestId === this.form.get('id')?.value)
    })
    console.log('this.form.get(status) %o', this.form.get('status'));

    this.setDocumentTypes()

  }

  isDisabled() {
    if (this.form.get('status') == null || this.form.get('status')?.value == null || this.form.get('status')?.value.code == null)
      return true;
    if (this.form.get('status')?.value.code == 'NEW')
      return false;
    else
      return true;
  }

  getClassWorkflow(step: any) {
    if (!(this.form == null) && !(this.form.value == null) && !(this.form.value.status == null)) {
      let stepP = this.form.value.status.step;//ex 2 <- actual status
      //console.log('request status %o',this.form.value.status.step);

      if (step == stepP)
        return 'fs-6 fw-bold aln row-al mrg-top card-title';

      return 'fs-6 fw-bold text-muted aln row-al mrg-top card-title'
    }
    return '';

  }

  getClassIconWorkflow(step: any) {
    if (!(this.form == null) && !(this.form.value == null) && !(this.form.value.status == null)) {
      let stepF = this.form.value.status.step; //ex 2 <- actual status

      if (step < stepF) {
        return 'check-ok';
      }

      if (step == stepF)
        return 'check-warning';
    }
    return ''

  }

  public openUploadDialog(mode: 'new' | 'current') {
    if (mode === 'new') {
      this.dialog.open(FileUploadModalComponent, {
        data: {
          //file: this.webcamImage.imageAsDataUrl,
          inputMode: 'buildings',
          toEntityId: this.form.get('building')?.get('id')?.value,
          requestId: this.form.get('id')?.value,
          documentTypes: of(this.documentTypes)
        }
      })
        .afterClosed()
        .subscribe(res => {
          console.log('uploadDialogres: ', res);
          //this.subject.documents.push(res)
          if (res) {
            this.form.get('building')?.get('documents')?.setValue([...this.form.get('building')?.get('documents')?.value, res.document])
          }
        })
    }
  }

  setDocumentTypes(){
    if (this.isNew === false) {
      combineLatest([this.form.get('status')!.valueChanges, this.globalDocumentTypes$]).pipe(
        map(([status, globalDocumentTypes]) =>{
          if (status && status.allowedDocumentTypes) {
            return [...status.allowedDocumentTypes, ...globalDocumentTypes]
          } else {
            return globalDocumentTypes
          }
        }),
        switchMap((documentTypes:DocumentType[]) => {
          let documentTypesIds = documentTypes.map(documentType => documentType.id!)
          let societyId = this.form.get('building')?.get('companyLocation')!.value
          if(societyId !== ""){
            return combineLatest([
              this.documentTemplateService.getManyByDocumentTypeAndSociedyId(documentTypesIds,societyId),
            of(documentTypes)])
          }else{
            return combineLatest([
              of([]), of(documentTypes)
            ])
          }

        }),
        map(([documentTemplates, documentTypes]) => {
          documentTypes = this.unique(documentTypes, ['code'])
          return documentTypes.map(documentType => {
            return {
              ...documentType,
              documentTemplate: documentTemplates.find(documentTemplate => documentTemplate.typeName === documentType.id)
            }
          })
        })
      ).subscribe((documentTypes) => {
          this.documentTypes = documentTypes
          console.log("DOCUMENT TYPES --> ", this.documentTypes);
      })
    }
  }

  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
     const key = keyProps.map(k => entry[k]).join('|');
     return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
   }
}
