<div class="container-fluid my-container">
  <div class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
      <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
        placeholder="{{ 'LABEL.SEARCH' | translate }}" />
    </div>
  </div>
  <div style="overflow-x: auto;">
  <table mat-table [dataSource]="dataSource" matSort style="width:100%">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">Id</th>
      <td class="tdg" mat-cell *matCellDef="let element">
        {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LOCATIONS.NAME' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.status}}</b></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{ 'LOCATIONS.DESCRIPTION' | translate }}</th>
      <td class="tdg" mat-cell *matCellDef="let element">
        {{element.description}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  </div>
  <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
  </mat-paginator>
</div>
