<h3 class="title">Dettaglio Evento</h3>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
    <mat-tab label="Form">
        <br>
        <app-event-form [form]="form" [eventTypes]="eventTypes"></app-event-form>
    </mat-tab>
    <mat-tab label="Locations Evento">
        <app-event-buildings *ngIf="eventId" [form]="form"></app-event-buildings>
    </mat-tab>
    <mat-tab label="Banner e Media">
        <app-event-documents *ngIf="eventId" [form]="form"></app-event-documents>
    </mat-tab>
    <mat-tab label="Registrazioni">
        <app-event-registrations *ngIf="eventId" [form]="form"></app-event-registrations>
    </mat-tab>
    <mat-tab label="Gestione Comunicazioni">
        <app-event-notifications *ngIf="eventId" [form]="form"></app-event-notifications>
    </mat-tab>
    
</mat-tab-group>


<div class="buttons-right  my-2">

    <button style="background-color:transparent;" [matTooltip]="translate.instant('EVENTS.back')" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="back()">
        <mat-icon class="material-icons" style="color:#293683;">arrow_back</mat-icon>
    </button>

</div>