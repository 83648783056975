import { BehaviorSubject, map, of, Subject as Subject2 } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, Output, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { SubjectService } from '../services/subject.service';
import { LibService } from '../services/libService';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { UserSessionService } from '../services/user-session.service';
import { combineLatest } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subject } from '../models/subject';
import { DatatableAction, DatatableColumn } from '../shared/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { StatusService } from '../services/status.service';
import { Status } from '../models/status';
import { RoleService } from '../services/role.service';


@Component({
  selector: 'app-subject-section',
  templateUrl: './subject-section.component.html',
  styleUrls: ['./subject-section.component.scss']
})
export class SubjectSectionComponent implements OnInit {

  @ViewChild('nameCell', { read: TemplateRef }) nameCell: TemplateRef<any>;
  nameCellTemplateRef$ = new BehaviorSubject<any>(null);

  eventsSubject: Subject2<void> = new Subject2<void>();
  displayedColumns = ['id', 'subjectName', 'responsible', 'company', 'type', 'status', 'email', 'city', 'createdAt', 'updatedAt'];
  loggedUser: any;
  profiles: any;
  acceptedCompanies: any;

  totalRows: number = 20;
  pageSize: number = 10;
  pageInit: number = 0;

  filterOptions: any = {};
  currentPage = 0;
  companyIdsFilter: any = []
  responsiblesFilter: any = []
  idsFilter: any = []
  orderOptions: any = {}

  lockrequest: any = false;

  subjects: any;

  columns: DatatableColumn<Subject>[] = [
    {
      name: 'id',
      flex: '6',
      title: this.translate.get('LABEL.ID'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.id
    },
    {
      name: "subjectName",
      //type: 'templateRef',
      title: this.translate.get('LABEL.name'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.type !== 'Amministratore' && subject.type !== 'Condominio' ? subject.name : subject.legalEntity,
    },
    {
      name: "businessName",
      type: 'templateRef',
      title: this.translate.get('LABEL.name'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.businessName,
      templateRef: subject => this.nameCellTemplateRef$
    },
    {
      name: 'responsible',
      //flex: '20',
      title: this.translate.get('LABEL.responsible'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.responsible
    },
    {
      name: 'company',
      //flex: '7',
      title: this.translate.get('LABEL.company'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.company
    },
    {
      name: 'type',
      //flex: '7',
      title: this.translate.get('LABEL.type'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.type
    },
    {
      name: 'status',
      title: this.translate.get('LABEL.STATUS'),
      value: subject => {

        let statusId: any = subject.statusId;
        let status: any;
        if (typeof (statusId) === 'number') {
          switch (subject.type) {
            default: //soggetto, privato ecc...
              status = this.subjectStatuses.find(statusS => statusS.id === statusId)
              break;
          }
        } else if (statusId?.uiLabel !== undefined && statusId?.uiLabel !== null) {
          status = subject.status;
        }
        return status !== undefined ?
          this.translate.instant('LABEL.' + status!.uiLabel) :
          this.translate.instant('LABEL.NOT_PRESENT')
      }
    },
    {
      name: 'email',
      //flex: '15',
      title: this.translate.get('LABEL.email'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.email
    },
    {
      name: 'city',
      title: this.translate.get('LABEL.city'),
      cssClass: subject => ['column-padding'],
      value: subject => subject.city
    },
    {
      name: "createdAt",
      flex: '5',
      title: this.translate.get('LABEL.CREATION_DATE'),
      cssClass: request => ['column-padding'],
      value: request => this.datePipe.transform(request.createdAt)
    },
    {
      name: "updatedAt",
      flex: '5',
      title: this.translate.get('LABEL.LASTMODIFIED_DATE'),
      cssClass: request => ['column-padding'],
      value: request => this.datePipe.transform(request.updatedAt)
    },
  ]



  actions: DatatableAction<Subject>[] = [
    {
      label: of(''),
      icon: (subject: any) => {
        return this.iconRoutine(subject);
      },
      onClick: subject => this.goTo(subject),
      color: 'primary'
    }/* ,
    {
      label: of(''),
      icon: 'delete',
      onClick: request => this.deleteRow(request),
      color: 'primary'
    } */
  ];
  dataSource: any;
  kwSearch: any;
  searchType: string = 'Soggetto';
  title = "Soggetti";
  subTitle = "EDAC - Soggetti";
  firstSubjects: any;
  searchService: any;

  // @ViewChild(MatSort) set matSort(sort: MatSort) {
  //   this.dataSource.sort = sort;
  // }
  // @ViewChild(MatPaginator)
  // paginator: MatPaginator;

  currentUrl: string;

  subjectStatuses: Status[] = []


  subjectStatuses$ = this.statusService.getAll('Subject')
  // loggedUser$ = this.userSessionService.getUser(this.authService.user().email).pipe(map(val => this.loggedUser = val));

  subjects$ = this.subjectService.allWithPagination()


  constructor(
    public subjectService: SubjectService,
    public libService: LibService,
    private router: Router,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private statusService: StatusService,
    private cdr: ChangeDetectorRef,
    private roleService: RoleService) {
    this.userSessionService.getUser(this.authService.user().email).subscribe(val => {
      this.loggedUser = val;
      this.profiles = this.loggedUser[0]?.UsersProfilesRel.map((a: any) => a.name);
      this.acceptedCompanies = this.loggedUser[0].UsersCompaniesRel.map((a: any) => a.code);
    });
  }


  ngAfterViewInit() {
    this.nameCellTemplateRef$.next(this.nameCell);
  }

  ngOnInit(): void {
    this.searchService = this.subjectService;
    //this.loadSubjects();
    this.loadDataSource();

    //GET STATUSES
    combineLatest([this.subjectStatuses$]).subscribe(([subjectStatuses]) => {
      this.subjectStatuses = subjectStatuses

    })
    //GET STATE
    this.currentUrl = window.location.href
    const state = this.userSessionService.getState(this.currentUrl)
    if (state) {
      this.kwSearch = state.kwSearch
      this.search(this.kwSearch)
    }
  }

  ngOnDestroy(): void {
    //SET STATE
    this.userSessionService.saveState(this.currentUrl, { kwSearch: this.kwSearch })
  }

  goTo(e: any) {
    this.router.navigate(['subjects/' + e.id, { type: this.searchType }]);
    /*
    switch (e.type) {
      case 'Edificio':
        this.router.navigate(['subjects/' + e.id, e.type]);
        break;
      default:
        this.router.navigate(['subjects/' + e.id, e.type]);
        break;
    }
    */
  }

  search(e: any) {
    /*
    console.log('e %o', e);
    if (e)
      this.dataSource.filter = e.trim().toLowerCase();
    */
  }

  loadSubjects(): void {

    this.libService.lockPage('');
    combineLatest([this.subjects$]).subscribe({
      next: ([subjects]) => {

        this.firstSubjects = subjects.length > 0 ? subjects.items : null;

        this.checkCompanyUserSubject();
        this.libService.unlockPage();

      },
      error: (error) => {
        this.libService.showMessageError(error.message);
      }
    })
  }


  clearList(e: any) {
    console.log("CANCELLA --> ", e);
    if (e) {
      combineLatest([this.subjects$]).subscribe({
        next: ([subjects]) => {
          console.log('loadSubjects response %o', subjects);

          this.firstSubjects = subjects.length > 0 ? subjects.items : null;

          this.libService.unlockPage();
        },
        error: (error) => {
          this.libService.showMessageError(error.message);
        }
      })
    }
  }

  filterResultEmitted(event: Subject[]) {
    if (event !== null) {
      //   this.dataSource = event
    } else {
      this.toastr.warning(this.translate.instant('TOASTR.MESSAGE_2'))
      //this.dataSource = this.firstSubjects
    }
  }

  emitEventToFTSearch() {
    console.log("EVENTO INNESTATO");
    this.eventsSubject.next();
  }

  checkCompanyUserSubject() {

  }

  iconRoutine(subject: any) {
    //INIZIO ROUTINE PER VISIBILITY
    let profile: any;
    this.roleService.getMainRole(this.loggedUser);
    // if (this.profiles?.includes('BackOffice') || this.profiles?.includes('Tecnico Commerciale') || this.profiles?.includes('Area Manager') || this.profiles?.includes('Responsabile Area')) {
    //   this.profiles.includes('BackOffice')? profile = 'BackOffice': undefined;
    //   this.profiles.includes('Tecnico Commerciale')? profile = 'Tecnico Commerciale': undefined;
    //   this.profiles.includes('Area Manager') || this.profiles.includes('Responsabile Area')? profile = 'Area Manager': undefined;
    // }

    //console.log("PROFILO DELL'UTENTE -->", this.loggedUser[0].role);
    switch (this.loggedUser[0].role) {
      case 'Area Manager': case 'Responsabile Area': case 'Affiliato':
        return !this.acceptedCompanies.includes(subject.company) ? 'visibility' : 'edit';
        break;

      case 'Tecnico Commerciale':
        return !(subject.responsible === this.loggedUser[0].email) ? 'visibility' : 'edit'
        break;

      case 'BackOffice':
        return 'edit';
        break;

      case 'Direzione':
        return 'edit';
        break;

      case 'Responsabile Commerciale': case 'Coordinatore Country': case 'Direzione': case 'Top Management': case 'Contabilita': case 'Amministrazione': case 'HR': case 'Responsabile Mkt':
        return !(this.acceptedCompanies.includes(subject.company) && subject.responsible === this.loggedUser[0].email) ? 'visibility' : 'edit';
        break;
      case 'Coordinatore':
        //  this.dataSource = [];
        this.cdr.detectChanges();
        return 'visibility';
        break;
      default:
        return 'edit'
        break;
    }
  }

  resetOrder() {
    this.orderOptions = {}
  }

  resetPagination() {
    this.currentPage = 0;
    this.pageInit = 0;
  }

  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    this.pageInit = this.currentPage * this.pageSize;
    this.loadDataSource()
  }

  updateDataBySortParams(event: any) {

    console.log("updateDataBySortParams(event: any)")
    console.log(event)
    this.orderOptions = event

    this.loadDataSource()

  }

  loadDataSource(reset: boolean = false) {
    console.log('loadDataSource')
    this.libService.lockPage('');
    if (!this.lockrequest) {
      this.lockrequest = true;



      if (reset) {
        this.resetOrder()
        this.resetPagination()
        this.filterOptions.ids = []
      }

      this.subjectService.allWithPagination(this.filterOptions, this.orderOptions, this.pageInit, this.pageSize).subscribe((responseData: any) => {

        this.totalRows = responseData.count;
        this.dataSource = responseData.items
        this.subjects = responseData.items;
        this.lockrequest = false;
        this.libService.resetLockPage();
      }, (err: any) => {
        this.lockrequest = false;
        this.libService.resetLockPage();
        this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
      });
    }

  }

  updateList(e: any) {
    console.log("EVENTO  LISTA --> ", e);
    if (e.length > 0) {
      //  this.dataSource = e;
      console.log(e)
      this.idsFilter = e.map((item: any) => item.id)
      this.filterOptions.ids = this.idsFilter
    }else{
      this.filterOptions.ids = [] // Reset
    }
    this.loadDataSource()
  }

}
