<div class="container-fluid my-container">
  <div class="buttons-right">
    <button style="background-color:transparent;" *ngIf="!isNew" matTooltip="Salva" type="button" class="btn btn-primary mx-2" (click)="update()">
      <mat-icon class="material-icons" style="color: #293683;">save</mat-icon>
    </button>
    <button style="background-color:transparent;" *ngIf="isNew" matTooltip="Salva" type="button" class="btn btn-primary mx-2" (click)="create()">
      <mat-icon class="material-icons" style="color: #293683;">save</mat-icon>
    </button>
    <button matTooltip="Consolida" type="button" class="btn btn-primary mx-2" (click)="validate()">
      <mat-icon class="material-icons" style="color: #293683;">open_with</mat-icon>
    </button>
  </div>

  <div *ngIf="!isLoading" class="control_wrapper bck">
    <h2>{{ 'LABEL.GENERAL_DATA' | translate }}</h2>
    <form [formGroup]="form" class="form-container">
      <mat-form-field appearance="fill" class="column">
        <mat-label>{{ 'LABEL.NAME' | translate }}</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="fill" class="column">
        <mat-label>{{ 'LABEL.DESCRIPTION' | translate }}</mat-label>
        <input matInput formControlName="description">
      </mat-form-field>
    </form>
    <div >
      <h2>{{ 'LABEL.FILTER' | translate:{'field': translate.instant('LABEL.SUBJECTS') } }}</h2>
      <ejs-querybuilder #querybuilders width="70%" [columns]="subjectAttributes">
      </ejs-querybuilder>
      <h2>{{ 'LABEL.EDIT' | translate:{'field': translate.instant('LABEL.BUILDINGS') } }}</h2>
      <ejs-querybuilder #querybuilderb width="70%" [columns]="buildingAttributes">
      </ejs-querybuilder>
    </div>
    <h3>{{ 'LABEL.DASHBOARD' | translate }}</h3>
    <ejs-querybuilder #querybuilderd width="70%" [columns]="dashboardAttributes">
    </ejs-querybuilder>
    <!--
    <h3>Pratiche</h3>
    <ejs-querybuilder #querybuilderp width="70%">
      <e-columns>
        <e-column field="EdificioId" label="Id Edificio" type="number"></e-column>
        <e-column field="EdificioType" label="Tipo Edificio" type="string"></e-column>
        <e-column field="EdificioNation" label="Nazione" type="string"></e-column>
        <e-column field="EdificioRegione" label="Regione" type="string"></e-column>
        <e-column field="EdificioProvincia" label="Provincia" type="string"></e-column>
        <e-column field="EdificioComune" label="Comune" type="string"></e-column>
        <e-column field="EdificioCAP" label="CAP" type="string"></e-column>
        <e-column field="EdificioGeoCode" label="Codice GeoMarketing" type="string"></e-column>
      </e-columns>
    </ejs-querybuilder>

  </div>
</div>

<div class="ctn-row">
  <button *ngIf="!isNew" type="button" class="btn btn-primary mrg" (click)="update()">Aggiorna</button>
  <button *ngIf="isNew" type="button" class="btn btn-primary mrg" (click)="create()">Crea</button>
  <button type="button" class="btn btn-primary mrg" (click)="validate()">Consolida</button>
</div> -->
  </div>
  <div class="buttons-right">
    <button style="background-color:transparent;" *ngIf="!isNew" matTooltip="Salva" type="button" class="btn btn-primary mx-2" (click)="update()">
      <mat-icon class="material-icons" style="color: #293683;">save</mat-icon>
    </button>
    <button style="background-color:transparent;" *ngIf="isNew" matTooltip="Salva" type="button" class="btn btn-primary mx-2" (click)="create()">
      <mat-icon class="material-icons" style="color: #293683;">save</mat-icon>
    </button>
    <button matTooltip="Consolida" type="button" class="btn btn-primary mx-2" (click)="validate()">
      <mat-icon class="material-icons" style="color: #293683;">open_with</mat-icon>
    </button>
  </div>
</div>
