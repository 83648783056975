<div class="container-fluid my-container">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Shiba Inu</mat-card-title>
      <mat-card-subtitle>Dog Breed</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>This card indeterminates progress bar.</p>
      <p>testo</p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions>
  </mat-card>
</div>
