import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor() {
  }

  public setStructure(form: any, structure: any = []) {

    let key = [
      'streetNumber',
      'streetName',
      'city',
      'state',
      'province',
      'country',
      'zip',
      'googlePlaceId',
      'formattedAddress',
      'neighborhood',
      'sublocality',
      'coordX',
      'coordY',
    ]

    key.forEach((k) => {
      form.get(k)?.setValue(structure[k]);
    })
  }

  public getStructure(place: any) {
    console.log(place)
    let structure: any = {
      streetNumber: null,
      streetName: null,
      city: null,
      state: null,
      province: null,
      country: null,
      zip: null,
      googlePlaceId: null,
      formattedAddress: null,
      neighborhood: null,
      sublocality: null,
      coordX: null,
      coordY: null
    }

    let loc: any = place.geometry.location

    if (place.formatted_address) structure.formattedAddress = place.formatted_address
    if (place.place_id) structure.googlePlaceId = place.place_id
    if (place.place_id) structure.coordX = loc.lng
    if (place.place_id) structure.coordY = loc.lat

    place.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        console.log('place %o', add);
        switch (addType) {
          case 'street_number':
            structure.streetNumber = add.short_name
            break;
          case 'route':
            structure.streetName = add.long_name
            break;
          case 'neighborhood':
            structure.neighborhood = add.long_name
            break;
          case 'locality':
            structure.city = add.long_name
            break;
          case 'sublocality':
            structure.sublocality = add.long_name
            break;
          case 'administrative_area_level_1':
            structure.state = add.long_name
            break;
          case 'administrative_area_level_2':
            structure.province = add.short_name
            break;
          case 'country':
            structure.country = add.long_name
            break;
          case 'postal_code':
            structure.zip = add.long_name
            break;

          default:
            break;
        }
      });
    });

  if(structure.neighborhood) structure.streetName = `${structure.streetName} - ${structure.neighborhood}`
  if(!structure.zip) structure.zip = structure.sublocality

  console.log(structure)
    return structure
  }



}
