import {Injectable} from "@angular/core";
import {TaskV2} from "../models/taskv2";
import { LibService } from './libService';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class Taskv2Service {

  baseApi = environment.rootApiPaperwork;

  constructor(public libService: LibService) {}

    get(): Observable<any>{
      return this.libService.getData( this.baseApi, `tasks`)
    }

    insert(task: Task):  Observable<any> {
        return this.libService.postData(task, this.baseApi, `tasks`)     
    }

    update(task: Task):  Observable<any> {
      return this.libService.postData(task, this.baseApi, `tasks/update`)
    }

    remove(id: number):  Observable<any> {
      return this.libService.deleteData<any>({}, this.baseApi, `tasks/${id.toString()}`);
    }
}