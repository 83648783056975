import { AuthService } from './../../auth/auth.service';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ToastrService } from 'ngx-toastr';
import { combineLatest, tap } from 'rxjs';
import { Company } from 'src/app/models/company';
import { Society } from 'src/app/models/society';
import { User } from 'src/app/models/user';
import { BuildingService } from 'src/app/services/building.service';
import { CompanyService } from 'src/app/services/companyService';
import { CondominiumService } from 'src/app/services/condominium.service';
import { LibService } from 'src/app/services/libService';
import { RequestService } from 'src/app/services/request.service';
import { SocietyService } from 'src/app/services/societyService';
import { StatusService } from 'src/app/services/status.service';
import { SubjectService } from 'src/app/services/subject.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { UserDataService } from 'src/app/services/users.service';
import { BranchService } from 'src/app/services/branch.service';

@Component({
  selector: 'app-request-filter-with-pagination',
  templateUrl: './request-filter-with-pagination.component.html',
  styleUrls: ['./request-filter-with-pagination.component.scss']
})
export class RequestFilterWithPaginationComponent implements OnInit {

  @Input() type: string;
  @Input() states: any;
  @Input() branches: any;
  @Output() filterResult = new EventEmitter;
  @Output() filterResultReset = new EventEmitter;

  societies: Society[] = [];
  responsibles: string[] = [];
  companies: Company[] = [];
  years: string[] = [];

  noUsers: boolean = true;

  form: FormGroup;

  /* companies$ = this.companyService.getAllCompanies().pipe(
    map((companies: Company[]) => {
      return companies.sort((a, b) => a.name!.toUpperCase() <= b.name!.toUpperCase() ? -1 : 1)
    }),
    tap(companies => {
      companies.map(company => {
        if(company.CompaniesSocietiesRel !== undefined){
          this.societies.push(...company.CompaniesSocietiesRel)
        }
        if(company.CompaniesUsersRel !== undefined){
          this.responsibles.push(...company.CompaniesUsersRel!.map(user => user.email!))
        }
      })
      this.societies = this.unique(this.societies, ['code'] )
      this.responsibles = [...new Set(this.responsibles)]
      this.companies = companies
    })
  ); */

  logUsr$ = this.sessionService.getUser(this.authService.user().email)
  // companies$ = this.companyService.getAllCompaniesFast().pipe(
  //   tap(companies => {
  //     this.companies = companies
  //   })
  // )

  // societies$ = this.societyservice.getAllSocieties().pipe(
  //   tap(societies => {
  //     this.societies = societies
  //   })
  // )

  // responsibles$ = this.userService.getAllUsersFast().pipe(
  //   tap(users => {
  //     users = users.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
  //     let pippo = users.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
  //     this.responsibles = pippo.map((user: User) => user.email)
  //     // this.responsibles = users.map((user: User) => user.email)
  //   })
  // )

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private societyservice: SocietyService,
    private userService: UserDataService,
    private subjectService: SubjectService,
    private requestService: RequestService,
    private buildingService: BuildingService,
    private condominiumService: CondominiumService,
    private authService: AuthService,
    private toastr: ToastrService,
    private libService: LibService,
    private statusService: StatusService,
    private sessionService: UserSessionService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private branchService: BranchService
  ) {
    this.generateYears();
    this.form = this.fb.group({
      company: [[], /* Validators.required */],
      companyLocation: [[], null],
      year: [[],],/* Validators.required */
      branch: [[],],/* Validators.required */
      assignmentOperator: [[], /* Validators.required */],
      states: [[], null]
    })
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(form1 => {

      this.sessionService.saveState('RequestFilters', form1);
    })
    this.form.get('company')?.valueChanges.subscribe((current) => {
      console.log(current);
      this.noUsers = true;
      this.cdr.detectChanges();
      if (current.length > 0) {
        this.noUsers = false;
        this.cdr.detectChanges();
        this.userService.getByCompanyCode(current.map((a: any) => a.code)).subscribe(users => {
          console.log("COMPANY CODES --> ", current);
          console.log("USERS BY COMPANY CODE --> ", users);
          users = users.filter((usr: any) => usr.email !== null && usr.email !== undefined && usr.email.trim() !== '');
          let pippo = users.filter((user: any) => user.UsersProfilesRel.map((profile: any) => profile.id).includes(14) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(13) || user.UsersProfilesRel.map((profile: any) => profile.id).includes(64));
          this.responsibles = pippo.sort((a: any, b: any) => a.email! <= b.email! ? -1 : 1);//.map((user: User) => user.email)
          // this.responsibles = users;
        })
      }
    })
    combineLatest([this.logUsr$]).subscribe(([loggedUser, resps, stats]: any) => {
      let prevFilters = this.sessionService.getState('RequestFilters');
      this.companies = loggedUser[0].UsersCompaniesRel.sort((a: any, b: any) => a.code! <= b.code! ? -1 : 1);
      if (prevFilters?.company?.length > 0 || prevFilters?.year?.length > 0 || prevFilters?.assignmentOperator?.length > 0 || prevFilters?.statusId?.length > 0) {
        this.form.patchValue(prevFilters);
        this.filter();
      }
    })

  }

  filter() {

    let formValues = this.form.getRawValue();
    let stateIds: any = []
    if (formValues['states']) {
      formValues['states'].forEach((stateCategory: any) => {
        stateCategory.states.forEach((state: any) => {
          stateIds.push(state.id)
        })
      })
    }

    let filters = {
      companies: formValues['company'].map((company: any) => company.id),
      responsibles: formValues['assignmentOperator'].map((user: any) => user.email),
      years: formValues['year'],
      states: stateIds,
      branches: formValues['branch'].map((branch: any) => branch.id),
    }
    this.sessionService.saveState('RequestFilters', formValues);

    if (this.form.valid === true) {
      this.filterResult.next(filters)
    } else {
      this.filterResult.next({})
      this.toastr.warning(this.translate.instant("TOASTR.MESSAGE39"), this.translate.instant("TOASTR.WARNING"));
    }
  }

  clear() {
    this.form.reset({
      company: [],
      companyLocation: [],
      responsible: [],
      assignmentOperator: [],
      branch: [],
      year: [],
      statusId: []
    })

    this.sessionService.saveState('RequestFilters', this.form.getRawValue());
    this.filterResultReset.next({})
  }

  private unique(arr: any, keyProps: string[]): any[] {
    const kvArray = arr.map((entry: any) => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  get companyControl() {
    return this.form.get('company') as FormControl
  }

  get companyLocationControl() {
    return this.form.get('company') as FormControl
  }

  get responsibleControl() {
    return this.form.get('company') as FormControl
  }

  private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      if (typeof obj[propName] === 'object') {
        obj[propName] = this.clean(obj[propName])
      }
    }
    return obj
  }

  generateYears() {

    const moment = require('moment');
    let yToPush = new Date().getFullYear();
    // let i = 1;
    // yToPush = yToPush + ;
    for (let i = 0; i <= 10; i++) {
      this.years.push(yToPush.toString());
      yToPush--;
    }

    console.log("years -->", this.years)

  }
}