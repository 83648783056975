import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LegalService } from 'src/app/services/legal.service';
import { LibService } from 'src/app/services/libService';

@Component({
  selector: 'app-legal-form',
  templateUrl: './legal-form.component.html',
  styleUrls: ['./legal-form.component.scss']
})
export class LegalFormComponent implements OnInit {

  @Input() item: any;
  @Output() closeModal = new EventEmitter;

  form: FormGroup;

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private legalService: LegalService,
    private libService: LibService,
  ) {
  }

  ngOnInit(): void {

    // Creazione Form
    this.form = this.fb.group({
      id: [null],
      // version: [null],
      orderNumberEA: [null, Validators.required],
      year: [null],
      magistrateATP: [null],
      outOfCourt: [null],
      mediationAgreement: [null],
      company: [null],
      companyCity: [null],
      province: [null],
      promotingSubject: [null],
      lawFirm: [null],
      legalUpdates: [null],
      legalStatus: [null],
      note: [null],
      legalOutgoings: [null],
      agreementOutgoings: [null],
      measureDate: [null],
    })

    this.form.patchValue(this.item)
  }

  ngOnChanges(changes: SimpleChanges): void { }

  update() {
    //console.log(this.form)
    this.libService.lockPage('');
    this.legalService.updateLegal(this.item?.id?.toString(), this.form.getRawValue()).subscribe((res: any) => {
      //console.log(res);
      this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.INFO'));
      this.closeModal.emit(true);
      this.libService.unlockPage();
    })
  }

}