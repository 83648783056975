import * as moment from 'moment';

export enum TimeFormat {
  default = 'DD-MM-YYYY HH:mm',
}

export function simpleDate(date: string | number, format = 'L'): string {
  return moment(date).locale('IT').format(format);
}

export function simpleTime(
  date: string | number,
  format = 'L',
  time?: { hours: number; minute: number }
): string {
  return moment(date)
    .locale('IT')
    .set({ hours: time?.hours ?? 0, minute: time?.minute ?? 0 })
    .format(format);
}

export function getTimestamp(date?: string | undefined) {
  if (!date) return moment().unix();
  return moment(date).unix();
}

export function setDateToUTCZone(dateString: any) {
  let date = new Date(dateString)
  let year = date.getFullYear()
  let month = date.getMonth()
  let day = date.getDate()
  let newDate = new Date(Date.UTC(year, month, day))
  return newDate
}

export function getActualDate(): string {
  return moment().locale('IT').format('L');
}

export function getDaysDelta(date: Date, compared: Date) {
  return moment(date).diff(compared, 'days');
}

export function parseUnixTimestamp(ts: string | number, format = 'L'): string {
  return moment.unix(Number(ts)).locale('IT').format(format);
}

export function parseTimestamp(ts: string): string {
  return moment(Number(ts)).locale('IT').format('L');
}

export function parseISOstring(timestamp: string) {
  moment(timestamp, moment.ISO_8601).locale('IT').format('L');
}
