import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { OutgoingEmailSettings } from 'src/app/models/email-template';
import { OutgoingEmailService } from 'src/app/services/outgoing-email.service';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';

@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.scss']
})
export class EmailTemplateListComponent implements OnInit {

  displayedColumns = ['id'];
  dataSource: any;

  columns: DatatableColumn<OutgoingEmailSettings>[] = [
    {
      name: 'id',
      title: this.translate.get('LABEL.ID'),
      cssClass: setting => ['column-padding'],
      value: setting => setting.id
    }
  ]

  actions: DatatableAction<OutgoingEmailSettings>[] = [
    {
      label: of(''),
      icon: (setting: any) => 'edit',
      onClick: setting => this.goTo(setting),
      color: 'primary'
    }
  ]

  constructor(
    private translate: TranslateService,
    private router: Router,
    private outgoingEmailService: OutgoingEmailService
  ) { }

  ngOnInit(): void {
    this.outgoingEmailService.findAll().subscribe(res => {
      this.dataSource = res
    })
  }

  goTo(e: any){
    this.router.navigate(['outgoing-email/' + e.id])
  }

}
