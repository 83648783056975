import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService extends TranslateService{

  public getInsertLabel(fieldNameStr: string){
    return this.get(fieldNameStr).pipe(
        switchMap(fieldName => {
            return this.get('LABEL.INSERT', {field: fieldName})
        })
    )
  }

  public getRequiredLabel(fieldNameStr: string){
    return this.get(fieldNameStr).pipe(
        switchMap(fieldName => {
            return this.get('LABEL.REQUIRED', {field: fieldName})
        })
    )
  }

  public getEditLabel(fieldNameStr: string){
    return this.get(fieldNameStr).pipe(
        switchMap(fieldName => {
            return this.get('LABEL.EDIT', {field: fieldName})
        })
    )
  }
}
