import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Survey } from 'src/app/models/survey';
import { SurveyService } from 'src/app/services/survey.service';

export interface SurveyViewComponentDataInput {
  surveyForm: UntypedFormGroup;
}

@Component({
  selector: 'app-survey-view',
  templateUrl: './survey-view.component.html',
  styleUrls: ['./survey-view.component.scss']
})
export class SurveyViewComponent implements OnInit {

  @Input()formSurvey: UntypedFormGroup;
  isNew: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SurveyViewComponentDataInput,
    public dialogRef: MatDialogRef<SurveyViewComponent>,
    private surveyService: SurveyService
  ) { }

  ngOnInit(): void {
    this.formSurvey = this.data.surveyForm
    this.isNew = this.formSurvey.get('id')?.value ? false: true;
  }

  get answerControls(){
    if(this.formSurvey){
      return (this.formSurvey.controls["answers"] as UntypedFormArray).controls as UntypedFormGroup[]
    }
    else{
      throw new Error("[SurveyViewComponent] Error reading form!!!");

    }
  }

  get surveyMasterOriginName(){
    if(this.formSurvey){
      return this.formSurvey.controls["surveyMasterOrigin"].value.name
    }
    else{
      throw new Error("[SurveyViewComponent] Error reading form!!!");

    }
  }

  questionText(form: UntypedFormGroup){
    return (form.controls['question'] as UntypedFormGroup).controls['text'].value
  }

  save(){
    if(this.isNew){
      this.surveyService.create(this.clean(this.formSurvey.getRawValue())).subscribe(res => {
        this.dialogRef.close(res)
      })
    }else{

    }
  }

  private clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      if(typeof obj[propName] === 'object'){
        obj[propName] = this.clean(obj[propName])
      }
    }
    return obj
  }

}
