import { catchError, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Building } from '../models/building';
import { FTSearchResponse } from '../models/FTSearchResponse';
import { LibService } from './libService';
import { UserSessionService } from './user-session.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  baseApi = environment.rootApiCrm + 'v1.0/buildings';
  name = "BuildingService";

  constructor(public libService: LibService,
              public userSessionService: UserSessionService,
              private logService: LogService) { }

  getAttributes(): Observable<any[]>{
    return this.libService.postData(null, this.baseApi + '/attributes', '')
  }


  getAllBuildings(userEmail: string): Observable<any> {
    // let buildings = this.userSessionService.getState('getAllBuildings');
    // console.log(this.baseApi);
    // if (buildings !== undefined && buildings !== null) {
    //   return of(buildings);
    // } else {
      return this.libService.getData<any>(this.baseApi, '/all/' + userEmail)//.pipe(
      //   tap(buildings => {
      //     this.userSessionService.saveState('getAllBuildings', buildings);
      //   })
      // );
    // }

  }

  findBuildingFromRequestForm(building: any) {
    return this.libService.postData<any>(building, environment.rootApiCrm + 'v1.0/', 'buildingrequest');
  }

  findBuildingById(idBuilding: number) {
    return this.libService.getData<any>(environment.rootApiCrm + 'v1.0/', `building/${idBuilding}`);
  }

  /* getFTSearchResult(keyword: any) {
    console.log("getFTSearchResult - KEYWORD SERVICE FRONT --> ", keyword)

    return this.libService.postData<any>(keyword, environment.rootApiCrm + 'v1.0', '/buildings/search');
  } */

  getSubjectIdsFTSearch(keyword: any) {
    console.log("getBuldingIdsFTSearch - KEYWORD SERVICE FRONT --> ", keyword)
    return this.libService.postData<FTSearchResponse>(keyword, this.baseApi, '/buildingsIndexsearch');
  }

  create(building: Building): Observable<any>{
    return this.libService.postData(building, this.baseApi , '').pipe(
      tap(response => {
          this.logService.writeLog('POST', this.baseApi, building, 'OK')
      }),
      catchError(error => {
          this.logService.writeLog('POST', this.baseApi, building, error.status)
          return of(error);
      })
    )
  }

  update(building: Building): Observable<any>{
    console.log("BUILDING PER CREAZIONE FRONTEND --> ", building);
    return this.libService.postData<any>(building, this.baseApi, `/${building.id}`).pipe(
      tap(response => {
          this.logService.writeLog('POST', this.baseApi, building, 'OK')
      }),
      catchError(error => {
          this.logService.writeLog('POST', this.baseApi + `/${building.id}`, building, error.status)
          return of(error);
      })
    )
  }

  getById(ids: any) {
    console.log("id Building da cercare nel DB --> ", ids)
    let uniqueIds = [...new Set(ids)];
    if (uniqueIds) {
      return this.libService.postData<any>(uniqueIds, this.baseApi, `/buildingById`);
    } else {
      return '';
    }
  }

  find(options: {key: string, values: string[]}[]){
    return this.libService.postData<Building[]>(options, this.baseApi, '/buildingsFind')
  }

  checkVisibility(id: string, userEmail: string){
    return this.libService.postData<boolean>({id: id, userEmail: userEmail}, this.baseApi, '/buildingCheckVisibility')
  }

  findByAddress(building: any) {
    return this.libService.postData<Building>(building, this.baseApi, '/buildingByAddress');
  }
}
