import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LibService } from './libService';
import { Resource } from '../models/resource';
import { ApiResponse } from '../planner/models/apiResponse';
import { Order } from '../planner/models/order';
import { StockTotali } from '../planner/models/stockTotali';
import { Stock } from '../planner/models/stock';
import { UserSessionService } from './user-session.service';



@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  baseApi = environment.rootApiFinance;

  constructor(
    public libService: LibService,
    public userSessionService: UserSessionService,
  ) { }

  getRisorse(userEmail: string, idSede: number, idProfilo: number) {
    return this.libService.getData<ApiResponse<Resource[]>>(
      this.baseApi,
      `/V1/output/GetRisorse?account=${userEmail}&idSede=${idSede}&idProfilo=${idProfilo}`
    );
  }

  getStockTotali(userEmail: any, idSede: any) {
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetStockTotali?account=${userEmail}&idSede=${idSede}`
    );
  }

  getStockTotaliServices(userEmail: any, idSede: any) {
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetStockTotaliServices?account=${userEmail}&idSede=${idSede}`
      );
    }
  getStockTotaliEnergy(userEmail: string, idSede: number) {
    return this.libService.getData<ApiResponse<StockTotali[]>>(
      this.baseApi,
      `/V1/output/GetStockTotaliEnergy?account=${userEmail}&idSede=${idSede}`
    );
  }

  getStockElenco(idSede: any, idStock: any) {
    let currentUser = this.userSessionService.getState('user');
    let userEmail = currentUser?.email
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetStockElenco?account=${userEmail}&idSede=${idSede}&tipoStock=${idStock}`
    );
  }

  getStockElencoServices(idSede: any, idStock: any) {
    let currentUser = this.userSessionService.getState('user');
    let userEmail = currentUser?.email
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetStockElencoServices?account=${userEmail}&idSede=${idSede}&tipoStock=${idStock}`
    );
  }
  getStockElencoEnergy(idStock: number) {
    let currentUser = this.userSessionService.getState('user');
    let userEmail = currentUser?.email
    console.log(currentUser)
    console.log(userEmail)
    return this.libService.getData<ApiResponse<Stock[]>>(
      this.baseApi,
      `/V1/output/GetStockElencoEnergy?account=${userEmail}&tipoStock=${idStock}`
    );
  }

  getStockElencoDettaglio(userEmail: string, idSede: number, idOrder: number) {
    return this.libService.getData<ApiResponse<Order[]>>(
      this.baseApi,
      `/V1/output/GetStockElenco?account=${userEmail}&idSede=${idSede}&idOrder=${idOrder}`
    );
  }

  getStockElencoDettaglioEnergy(userEmail: string, idSede: number, idOrder: number) {
    return this.libService.getData<ApiResponse<Order[]>>(
      this.baseApi,
      `/V1/output/GetStockElencoEnergy?account=${userEmail}&idSede=${idSede}&idOrder=${idOrder}`
    );
  }

  getStockElencoDettaglioConCodicePratica(
    idSede: any,
    code: any
  ) {
    let currentUser = this.userSessionService.getState('user');
    let userEmail = currentUser?.email
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetStockElenco?account=${userEmail}&idSede=${idSede}&codiceCommessa=${code}`
    );
  }

  getStockElencoDettaglioConCodicePraticaServices(
    idSede: any,
    code: any
  ) {
    let currentUser = this.userSessionService.getState('user');
    let userEmail = currentUser?.email
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetStockElencoServices?account=${userEmail}&idSede=${idSede}&codiceCommessa=${code}`
    );
  }

  getStockElencoDettaglioConCodicePraticaEnergy(
    idSede: any,
    code: any
  ) {
    let currentUser = this.userSessionService.getState('user');
    let userEmail = currentUser?.email
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetStockElencoEnergy?account=${userEmail}&idSede=${idSede}&codiceCommessa=${code}`
    );
  }

  getElencoMotivazioniNonProgrammabile(userEmail: any) {
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetElencoMotivazioniNonProgrammabile?account=${userEmail}`
    );
  }

  setOrderNotProgrammable(userEmail: any, params: any) {
    return this.libService.postData<any>(
      params,
      this.baseApi,
      `/V1/input/SetOrderNotProgrammable?account=${userEmail}`
    );
  }

  getPreventivoTragitti(userEmail: any, orderId: any) {
    return this.libService.getData<any>(
      this.baseApi,
      `/V1/output/GetPreventivoTragitti?account=${userEmail}&orderId=${orderId}`
    );
  }

  getGeoArea(
    userEmail: any,
    latitude: any,
    longitude: any,
    businessLineCode: any = null
  ) {
    let url = `/V1/geo/GetGeoArea?account=${userEmail}&latitudine=${latitude}&longitudine=${longitude}`
    if (businessLineCode) url += `&lob=${businessLineCode}`
    return this.libService.getData<any>(
      this.baseApi,
      url
    );
  }

  setOrderBackToProgrammable(userEmail: any, OrderId: any) {
    let params = {
      OrderId: '' + OrderId,
    };
    return this.libService.postData<any>(
      params,
      this.baseApi,
      `/V1/input/SetOrderBackToProgrammable?account=${userEmail}`
    );
  }

  setUserToken(userEmail: any, attributes: any) {
    return this.libService.postData<any>(attributes, this.baseApi, `/V1/input/SetUserToken?account=${userEmail}`);
  }

  getOrdersTotalHours(userEmail: any, ids: any) {

    let ordersList = ids.map((id: any) => {
      return { "OrdersId": `${id}` }
    })
    let attributes = {
      "ordersList": ordersList
    }
    let url = `/V1/output/GetOrdersTotalHours?account=${userEmail}`

    return this.libService.postData<any>(
      attributes,
      this.baseApi,
      url
    );
  }

}
