import {
  AfterViewInit,
  Attribute,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  BryntumCalendarComponent,
  BryntumCalendarProjectModelComponent,
} from '@bryntum/calendar-angular-thin';
import { combineLatest, map, Observable } from 'rxjs';
import { UserSessionService } from 'src/app/services/user-session.service';
import { Calendar, CalendarConfig } from '@bryntum/calendar-thin';
import * as moment from 'moment';
import { User } from 'src/app/models/user';
import { EventModel, EventModelConfig, SchedulerPro } from '@bryntum/schedulerpro-thin';
// import { CalendarTask } from 'src/app/models/calendarTask';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Button, LocaleManager, TextField } from '@bryntum/core-thin';
import '@bryntum/calendar-thin/lib/localization/It';
import { UserRole } from '../../planner/models/userRole';
import { localeIt } from '../../planner/components/planner-scheduler/locate-it';
import { localeFr } from '../../planner/components/planner-scheduler/locate-fr';
import { localeEs } from '../../planner/components/planner-scheduler/locate-es';
import { TranslatorService } from 'src/app/services/translate.service';
import { HolidayCalendarService } from 'src/app/services/holiday-calendar-service';
import { EditEventCalendarDialog } from './components/edit-event-dialog';
import { AuthService } from '../../auth/auth.service';
import { ChangeDetectorRef } from '@angular/core';

import { MatTabChangeEvent } from '@angular/material/tabs';

import {
  Country,
  Society,
  Company,
  WorkCausal,
  HolidayCalendar,
} from '../../models/holidayCalendar';



import {

  projectConfig,
} from './config/calendar-settings.config';
import { CrudManagerConfig } from '../../planner/models/crudManagerConfig';
import { CompanyService } from 'src/app/services/companyService';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { BryntumSchedulerProComponent } from '@bryntum/schedulerpro-angular-thin';
import { ToastrService } from 'ngx-toastr';
import { HistoryBalanceService } from 'src/app/final-balancer/providers/history-balance.service';
import { environment } from 'src/environments/environment';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';


LocaleManager.locale = 'It';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-calendar-settings',
  templateUrl: './calendar-settings.component.html',
  styleUrls: ['./calendar-settings.component.scss'],
})


export class CalendarSettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('calendar') calendarComponent!: BryntumCalendarComponent;
  @ViewChild('project') projectComponent!: BryntumCalendarProjectModelComponent;

  schedulerPro: SchedulerPro;


  calendar: Calendar;
  currentUser: User = this.userSessionService.getState<User>('user')!;

  countries: any[] = [];
  societies: Society[] = [];
  companies: Company[] = [];
  workCausals: any = [];
  dataSource: any;

  selectedTabIndex: number = 1


  filteredSocieties: Society[] = [];
  filteredCompanies: Company[] = [];
  filteredWorkCausals: WorkCausal[] = [];

  selectedCountry: number | null = null;
  selectedSociety: number | null = null;
  selectedCompany: number | null = null;
  selectedWorkCausal: number | null = null;
  holidayDescription: string = '';
  startDate: string = '';
  endDate: string = '';
  hours: number = 0;
  events: any;
  dataCrud: any;
  @ViewChild(BryntumSchedulerProComponent)

  schedulerProComponent: BryntumSchedulerProComponent;
  tbarConfig: Partial<CalendarConfig>;

  // getSocietiesCompanies$ = this.companyService.getSocietiesAndCountries();
  societyAndCompany: any

  constructor(
    public translate: TranslatorService,
    private toastr: ToastrService,
    private userSessionService: UserSessionService,
    private companyService: CompanyService,
    private HistoryBalanceService: HistoryBalanceService,
    private holidayCalendarService: HolidayCalendarService,
    public dialog: MatDialog,
    public authService: AuthService,
    public router: Router,
    private cdr: ChangeDetectorRef // Aggiungi ChangeDetectorRef qui

    // private hbs: HistoryBalanceService
  ) { }

  



  // Assicurati di chiamare la funzione per ottenere la configurazione

  createCalendarConfig(): Partial<CalendarConfig> {
    const baseApi = environment.rootApiFoundation;
    let tokenId = this.authService.idToken();

    return {
      date: new Date(),
      timeZone: 0,
      crudManager: {
        transport: {
          load: {
            url: `${baseApi}holidayCalendars/range`,
            headers: {
              accesstoken: tokenId
            },
            credentials: 'omit',
          },
        },
      },

      modes: {
        day: null,
        week: null,
        month: {
          showResourceAvatars: true,
          autoCreate: true,
          eventRenderer: ({ eventRecord, renderData }: { eventRecord: any, renderData: any }) => {
            const isSingleDayEvent = moment(eventRecord?.startDate).isSame(eventRecord?.endDate, 'day');
            if (isSingleDayEvent) {
              renderData.style = { ...renderData.style, backgroundColor: eventRecord?.color, borderRadius: '4px', opacity: '0.8', boxSizing: 'border-box' };
              return {
                children: [
                  {
                    tag: 'div',
                    className: 'b-cal-event',
                    style: `color: white;`, // Utilizza il colore dell'evento
                    html: `<span class="b-cal-event-label">${eventRecord?.data.description}&nbsp;&nbsp;-&nbsp;&nbsp;</span><span class="b-cal-event-label"> ${this.translate.instant('PLANNER.PLANNER_CALENDAR_DIALOG.ORE_TOOLTIP')}${eventRecord?.data.hours}</span>`
                  }
                ]
              };
            } else {
              return {
                children: [
                  {
                    tag: 'div',
                    className: 'b-cal-event-body',
                    style: `background-color: ${eventRecord?.color};`, // Utilizza il colore dell'evento
                    html: `<span class="b-cal-event-label">${eventRecord?.data.description}&nbsp;&nbsp;-&nbsp;&nbsp;</span><span class="b-cal-event-label"> ${this.translate.instant('PLANNER.PLANNER_CALENDAR_DIALOG.ORE_TOOLTIP')}${eventRecord?.data.hours}</span>`
                  }
                ]
              };
            }
          }
        },
        year: {
          autoCreate: false
        },
      },
      features: {
        loadOnDemand: true,
        scheduleMenu: {
          items: {
            addEvent: null,
          },
        },

        eventMenu: {
          items: {
            duplicate: null,
          },
        },
        eventEdit: {
          items: {
            nameField: {
              required: false
            },
            recurrenceCombo: null,
            editRecurrenceButton: null,
            allDay: null,
          },
        },
        eventTooltip: {
          align: 'b-t',
          showOn: 'mouseover',
          renderer: (eventRecord: any) => {
            const id = eventRecord?.eventRecord?.data?.id || 'id-non-disponibile';

            const templateTooltip =
              `<dl>
                <dt>${this.translate.instant('PLANNER.PLANNER_CALENDAR_DIALOG.NOME_CALENDARIO_TOOLTIP')}</dt>
                <dd>${eventRecord?.eventRecord?.data.description || ''}</dd>
            </dl>
            ${eventRecord?.eventRecord?.data?.countryCode
                ? `<dt>${this.translate.instant('PLANNER.PLANNER_CALENDAR_DIALOG.NAZIONE_TOOLTIP')}</dt><dd>${eventRecord?.eventRecord?.data?.countryCode}</dd>`
                : ''
              }</dl>
            ${eventRecord?.eventRecord?.data?.SocietyName
                ? `<dt>${this.translate.instant('PLANNER.PLANNER_CALENDAR_DIALOG.SOCIETA_TOOLTIP')}</dt><dd>${eventRecord?.eventRecord?.data?.SocietyName}</dd>`
                : ''
              }</dl>
            </dl>
            ${eventRecord?.eventRecord?.data?.CompanyName
                ? `<dt>${this.translate.instant('PLANNER.PLANNER_CALENDAR_DIALOG.SEDE_TOOLTIP')}</dt><dd>${eventRecord?.eventRecord?.data?.CompanyName}</dd>`
                : ''
              }</dl>
            </dl>
            ${eventRecord?.eventRecord?.data?.WorkCausalName
                ? `<dt>${this.translate.instant('PLANNER.PLANNER_CALENDAR_DIALOG.CAUSALE_ASSENZA_LAVORO_TOOLTIP')}</dt><dd>${eventRecord?.eventRecord?.data?.WorkCausalName}</dd>`
                : ''
              }</dl>
            ${eventRecord?.eventRecord?.data?.hours
                ? `<dt>${this.translate.instant('PLANNER.PLANNER_CALENDAR_DIALOG.ORE_TOOLTIP')}</dt><dd>${eventRecord?.eventRecord?.data?.hours}</dd>`
                : ''
              }</dl>`;
            // console.log(eventRecord)

            return templateTooltip;
          },
        },
      },

    }
  }

  loadingConfigCalendar(): boolean{
    return this.societies?.length > 0 && this.companies?.length > 0 ? true : false;
    
  }
  
  getTbarConfig(): Partial<CalendarConfig> {
    return {
      items: [
        {
          type: 'button',
          icon: 'b-icon b-fa-chevron-left',
          onClick: () => {
            this.calendar.shiftPrevious();
          },
          tooltip: 'Previous'
        },
        {
          type: 'button',
          icon: 'b-icon b-fa-chevron-right',
          onClick: () => {
              this.calendar.shiftNext();
          },
          tooltip: 'Next'
        },
        {
          type: 'button',
          ref: 'editEventsCalendar',
          icon: 'b-fa-calendar-plus',
          text: 'ADD CALENDAR EVENT',
        },
        {
          width: '100px',
          type: 'combobox', // Combo per filtrare le nazioni
          label: 'Filter Country',
          ref: 'countryCombo',
          items: this.countries, // Popola le opzioni della combo con le nazioni
          onSelect: (record: any) => this.onCountrySelect(record),
        },
        {
          width: '150px',
          type: 'combobox',
          label: 'Filter Society',
          ref: 'societyCombo', // Riferimento univoco
          items: this.filteredSocieties,
        },        
        {
          width: '150px',
          type: 'combobox', // Combo per filtrare le sedi
          label: 'Filter Companies',
          ref: 'companyCombo', // Riferimento univoco
          items: this.filteredCompanies        
        },
        {
          type: 'button',
          text: 'Reset Filters',
          icon: 'b-icon b-fa-times-circle',
          onClick: () => this.resetFilters(),
          tooltip: 'Resetta tutti i filtri combo',
        },
        {
          type: 'button',
          style: 'position: absolute; right: 100px',
          text: 'Mese',
          icon: 'b-icon b-fa-calendar-alt',
          onClick: () => {
              this.calendar.mode = 'month'; // Cambia la vista a quella mensile
          },
          tooltip: 'Mese'
        },
        {
          type: 'button',
          style: 'position: absolute; right: 20px',
          text: 'Anno',
          icon: 'b-icon b-fa-calendar-alt',
          onClick: () => {
              this.calendar.mode = 'year'; // Cambia la vista a quella annuale
          },
          tooltip: 'Anno'
        }
      ],
    }
  }

  

  openEditEventDialog(event: any): void {
    const dialogRef = this.dialog.open(EditEventCalendarDialog, {
      data: {
        description: event.data.description || '',
        hours: event.data.hours || 0,
        country: event.data.CountryId || null,
        SocietyId: event.data.SocietyId || null,
        CompanyId: event.data.CompanyId || null,
        startDate: event.data.startDate || new Date(),
        endDate: event.data.endDate || new Date(),
        WorkCausalId: event.data.WorkCausalId || null,
        countries: this.countries,
        societies: this.societies,
        companies: this.companies,
        workCausals: this.workCausals,
        countryCode: event.data.countryCode,
        code: event.data.code,
        cyclical: event.data.cyclical
      }
    });
    // console.log('evento')
    // console.log(event.data.description);
    // console.log(event);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result);
        const originalCode = event.data.code; // Mantieni il code originale

        // Step 1: Elimina tutti gli eventi con il code specifico
        this.holidayCalendarService.deleteByCodeAndCreate(originalCode, result).subscribe(() => {
          this.loadCalendarServices();

          // console.log(this.calendar.datePicker.startDate)
          // console.log(this.calendar.datePicker.endDate)
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.SUCCESS'));

        }, (error: any) => {
          this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.ERROR'));
        });
      }
    });
  }

  loadSocietiesAndCompanies() {
    this.companyService.getSocietiesAndCountries().subscribe((res: any) => {
      this.societies = res.map((item: any) => ({
        id: item.societyId,
        name: item.societyName,
        societyCountry: item.societyCountry
      }));

      this.companies = res.map((item: any) => ({
        id: item.companyId,
        name: item.companyName,
        societyId: item.societyId
      }));
    });
  }

  ngOnInit(): void {
    this.getCountries();
    this.loadSocietiesAndCompanies();
    this.getWorkCausals();
    // Manteniamo la configurazione della lingua
    this.applySchedulerLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: any) => {
      this.applySchedulerLocale(event.lang);
    });
  }
  
  resetFilters() {
    if (this.calendar) {
      // Resetta il valore del combo Nazione
      const countryCombo = this.calendar.widgetMap['countryCombo'] as any;
      if (countryCombo) {
        countryCombo.value = null;

      }
  
      // Resetta il combo Società
      const societyCombo = this.calendar.widgetMap['societyCombo'] as any;
      if (societyCombo) {
        societyCombo.value = null;
        societyCombo.setConfig({ items: [] });  // Svuota le opzioni del combo
      }
  
      // Resetta il combo Sede
      const companyCombo = this.calendar.widgetMap['companyCombo'] as any;
      if (companyCombo) {
        companyCombo.value = null;
        companyCombo.setConfig({ items: [] });  // Svuota le opzioni del combo
      }

      this.selectedCountry = null;
      this.selectedSociety = null;
      this.selectedCompany = null;

      this.applyFilters();

    }
  }
  
  reconfigureCalendarSociety() {
    if (this.calendar) {
      const societyCombo = this.calendar.widgetMap['societyCombo']; // Accedi al combo delle società
      societyCombo.setConfig({
        items: this.filteredSocieties.reduce((acc: any[], society: any) => {
          if (!acc.some(item => item.id === society.id)) {
            acc.push({
              id: society.id,
              text: society.name,
            });
          }
          return acc;
        }, []),
        editable: false, // Impedisce di scrivere sopra
        onSelect: (record: any) => this.onSocietySelect(record),
      });
      // console.log(societyCombo) 

    }
  }
  reconfigureCalendarCompany() {
    if (this.calendar) {
      const societyCombo = this.calendar.widgetMap['companyCombo']; // Accedi al combo delle società
      societyCombo.setConfig({
        items: this.filteredCompanies.map((company: any) => {
          return {
            id: company?.id,
            text: company?.name,
          }
        }),
        editable: true, // Impedisce di scrivere sopra
        onSelect: (record: any) => this.onCompanySelect(record),
      });
      // console.log(societyCombo) 

    }
  }

  filterSocietiesByCountry(countryId: string | number) {
    if (countryId) {
      this.filteredSocieties = this.societies.filter(society => society.societyCountry === countryId);
      // console.log('societa filtrate: ', this.filteredSocieties)
      this.reconfigureCalendarSociety();
    } else {
      this.filteredSocieties = [];
    }
  }
  
  filterCompaniesBySociety(societyId: string | number) {
    if (societyId) {
      this.filteredCompanies = this.companies.filter(company => company.societyId === societyId);
      // console.log('company filtrate: ', this.filteredCompanies)
      this.reconfigureCalendarCompany();
    } else {
      this.filteredCompanies = [];
    }
  }
  ngAfterViewInit(): void {

    this.calendar = this.calendarComponent.instance;
    this.calendar.features.drag.disabled = true;

    this.calendar.crudManager.on('beforesend', (config: CrudManagerConfig) => {
      if (config.requestType === 'load') {
        const startDate = this.calendar.datePicker.startDate;
        const endDate = this.calendar.datePicker.endDate;

        
        const startDateStr = startDate.toISOString().split('T')[0];
        const endDateStr = endDate.toISOString().split('T')[0];
        if(!config.params.startDate) config.params.startDate = startDateStr;
        if(!config.params.endDate) config.params.endDate = endDateStr;

        //parametri per il filtraggio sedi
        if (!config.params.countryCode) config.params.countryCode = this.selectedCountry || null;
        if (!config.params.societyId) config.params.societyId = this.selectedSociety || null;
        if (!config.params.companyId) config.params.companyId = this.selectedCompany || null;

    
      }
    });

    const { editEventsCalendar } = this.calendar.widgetMap as {
      editEventsCalendar: Button,
    };
    console.log('selected country: ', this.selectedCountry)

    editEventsCalendar.on({ click: this.editEventsCalendar, thisObj: this });

    this.calendar.on('beforeEventEdit', (event: any) => {

      console.log('selected country: ', this.selectedCountry)

      // Logica personalizzata prima dell'apertura del dialogo
      this.openEditEventDialog(event.eventRecord);
      // Previene l'apertura del dialogo predefinito
      this.calendar.refresh();
      return false;
    });

    this.calendar.on('beforeEventDelete', (event: any) => {
      this.onRemoveEvent(event);
      return false; // Previene la rimozione predefinita
    });



  }

  applyFilters() {
    const startDate = this.calendar.datePicker.startDate.toISOString().split('T')[0];
    const endDate = this.calendar.datePicker.endDate.toISOString().split('T')[0];

    // Prepara i parametri della richiesta
    const params: any = {
      startDate: startDate,
      endDate: endDate,
      countryCode: this.selectedCountry || null,
    };

    // Aggiungi societyId solo se definito
    if (this.selectedSociety !== null && this.selectedSociety !== undefined) {
      params.societyId = this.selectedSociety;
    } else {
      params.societyId = null;
    }

    // Aggiungi companyId solo se definito
    if (this.selectedCompany !== null && this.selectedCompany !== undefined) {
      params.companyId = this.selectedCompany;
    } else {
      params.companyId = null;
    }

    // console.log('params', params);

    // Ricarica il calendario con i nuovi parametri
    if (this.calendar) {
      this.calendar.crudManager.load({
        request: {
          params: params,
        },
      })
        .then(() => {
          console.log("Dati caricati con successo");
        })
        .catch((error: any) => {
          if (error.cancelled) {
            console.log("La richiesta è stata annullata", error);
          } else {
            console.error("Errore durante il caricamento dei dati", error);
          }
        });
    }
  }





  onCountrySelect(country: any) {
    this.selectedCountry = country?.record?.data?.text;

    // Resetta la società e la sede quando la nazione cambia
    this.selectedSociety = null;
    this.selectedCompany = null;

    // Resetta il valore visualizzato nelle combo utilizzando setConfig
    const societyCombo = this.calendar.widgetMap['societyCombo'];
    const companyCombo = this.calendar.widgetMap['companyCombo'];

    if (societyCombo) {
      societyCombo.setConfig({
        value: null,  // Resetta il valore
        items: []     // Pulisci le opzioni se necessario
      });
    }

    if (companyCombo) {
      companyCombo.setConfig({
        value: null,  // Resetta il valore
        items: []     // Pulisci le opzioni se necessario
      });
    }

    this.filterSocietiesByCountry(country?.record?.data?.text); // Filtra le società in base alla nazione
    // this.reconfigureCalendarSociety(); // Riconfigura la combo delle società
    // this.reconfigureCalendarCompany(); // Riconfigura la combo delle sedi
    this.applyFilters(); // Applica i filtri e ricarica
  }


  // Metodo per aggiornare il filtro società
  onSocietySelect(society: any) {
    this.selectedCompany = null;

    // Resetta il valore visualizzato nella combo company utilizzando setConfig
    const companyCombo = this.calendar.widgetMap['companyCombo'];

    if (companyCombo) {
      companyCombo.setConfig({
        value: null,  // Resetta il valore
        items: []     // Pulisci le opzioni se necessario
      });
    }

    this.selectedSociety = society?.record?.data?.id;
    this.filterCompaniesBySociety(society?.record?.data?.id);
    this.applyFilters();
  }

  // Metodo per aggiornare il filtro sede
  onCompanySelect(company: any) {
    // console.log('company: ', company?.record?.data?.id)
    this.selectedCompany = company?.record?.data?.id;
    this.applyFilters(); // Applica i filtri e ricarica

  }

  editEventsCalendar() {
    // console.log('editOperators');
    // console.log(this.companies);
    // console.log(this.countries)
    const dialogRef = this.dialog.open(EditEventCalendarDialog, {
      data: { countries: this.countries, societies: this.societies, companies: this.companies, workCausals: this.workCausals },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // console.log('The dialog was closed');
        this.holidayCalendarService.create(result).subscribe((res: any) => {
          this.loadCalendarServices();
          this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.SUCCESS'));
        }, (error: any) => {
          this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.ERROR'));
        });

      }
    });
  }


  getCountries() {
    this.countries = this.userSessionService.getCountries();
    console.log(this.countries)
  }



  getWorkCausals() {
    this.HistoryBalanceService.$workCasuals.subscribe((res: any) => {
      // console.log(res);
      this.workCausals = res
        .filter((causal: any) => causal.isHolidayCalendar)  // Filtra i record con isHolidayCalendar true
        .map((causal: any) => {
          return {
            id: causal?.id,
            name: causal?.causalCode
          };
        });
    });
  }

  determineEventColor(event: any): string {

    const hasCountryId = event.countryCode !== null && event.countryCode !== undefined && event.countryCode !== 0;
    const hasSocietyId = event.SocietyId !== null && event.SocietyId !== undefined && event.SocietyId !== 0;
    const hasCompanyId = event.CompanyId !== null && event.CompanyId !== undefined && event.CompanyId !== 0;

    if (hasCountryId && !hasSocietyId && !hasCompanyId) {
      return '#1e81b0'; // Blu scuro
    } else if (hasCountryId && hasSocietyId && !hasCompanyId) {
      return '#8B008B'; // Viola scuro
    } else if (hasCountryId && hasSocietyId && hasCompanyId) {
      return '#e28743'; // arancione scuro
    } else {
      return '#808080'; // Grigio (colore di default se nessuna condizione è soddisfatta)
    }
  }

  onRemoveEvent(event: any): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
    // console.log(event.eventRecords[0].data.code)

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // L'utente ha confermato, elimina l'evento
        this.holidayCalendarService.deleteByCode(event.eventRecords[0].data.code).subscribe(
          () => {
            this.loadCalendarServices(); // Ricarica gli eventi dopo la rimozione
            this.toastr.success(this.translate.instant('LABEL.DATA_SUCCESS'), this.translate.instant('TOASTR.SUCCESS'));
          },
          error => {
            this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.ERROR'));
          }
        );

      }
    });
  }




  loadCalendarServices() {
    this.calendar.crudManager.load();
    
  }



  applySchedulerLocale = (schedulerLocale: string): void => {
    switch (schedulerLocale) {
      case 'it':
        LocaleManager.applyLocale(localeIt);
        break;
      case 'fr':
        LocaleManager.applyLocale(localeFr);
        break;
      case 'es':
        LocaleManager.applyLocale(localeEs);
        break;
      case 'en':
        LocaleManager.locale = 'En';
        break;
      default:
        LocaleManager.locale = 'En';
        break;
    }
  };

  calendarConfig = this.createCalendarConfig();
  projectConfig = projectConfig;

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    /* console.log('tabChangeEvent --> ', tabChangeEvent); */
    /* console.log('index --> ', tabChangeEvent.index); */
    this.selectedTabIndex = tabChangeEvent.index
    if (tabChangeEvent.index !== (null || undefined)) {
      if (tabChangeEvent.index === 0) {
        this.goTo('final-balancer/history')
      }
      if (tabChangeEvent.index === 1) {
      
      }
      if (tabChangeEvent.index === 2) {
        this.goTo('settings/calendar-contracts')
      }
      if (tabChangeEvent.index === 3) {
        this.goTo('settings/absence-modules')

      }
    }

 
  }

  goTo(link:any) {
    this.router.navigate([link]);
  }

}
