<mat-card class="popup-card">
  <mat-card-content>
    <h3>{{ 'PLANNER_RIEPILOGO.DATE_LABEL' | translate }} {{ displayDate }}</h3>
    <p *ngIf="operators.length > 0">{{ 'PLANNER_RIEPILOGO.TOTAL_OPERATORS_PLANNED' | translate }} {{ uniqueOperatorCount
      }}</p>

    <!-- Messaggio in base alla condizione -->
    <div *ngIf="operators.length === 0" class="no-commesse" [innerHTML]="noOrdersMessage">
      {{ noOrdersMessage }}
    </div>

    <!-- Contenitore scrollabile per la tabella -->
    <div *ngIf="operators.length > 0" class="scroll-container">
      <table mat-table [dataSource]="operators" class="mat-elevation-z8">

        <!-- Colonna Nome Operatore -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> {{ 'PLANNER_RIEPILOGO.NAME_COLUMN' | translate }} </th>
          <td mat-cell *matCellDef="let operator"> {{ operator.name }} </td>
        </ng-container>

        <!-- Colonna Commesse -->
        <ng-container matColumnDef="commessa">
          <th mat-header-cell *matHeaderCellDef> {{ 'PLANNER_RIEPILOGO.CODE_COLUMN' | translate }} </th>
          <td mat-cell *matCellDef="let operator">
            <div>{{ operator.commessa }}</div>
          </td>
        </ng-container>

        <!-- Colonna Ruolo Operatore -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> {{ 'PLANNER_RIEPILOGO.ROLE_COLUMN' | translate }} </th>
          <td mat-cell *matCellDef="let operator">
            <div>{{ getTranslatedRole(operator.role) }}</div>
          </td>
        </ng-container>

        <!-- Colonna Ore Totali -->
        <ng-container matColumnDef="hours">
          <th mat-header-cell *matHeaderCellDef> {{ 'PLANNER_RIEPILOGO.HOURS_COLUMN' | translate }} </th>
          <td mat-cell *matCellDef="let operator"> {{ operator.hours }} </td>
        </ng-container>

        <!-- Header e righe della tabella -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>