<div class="container-fluid">


    <app-fulltextsearch2-with-pagination [service]="subjectService" (selected)="goTo($event)" (list)="updateList($event)" (clearOut)="clearList($event)"></app-fulltextsearch2-with-pagination>

  <div style="margin:5px;">

  </div>
  <div style="overflow-x: auto;">

    <app-data-table-with-pagination [data]="dataSource" [columns]="columns" [displayedColumns]="displayedColumns"
      [actions]="actions" [totalRows]="totalRows" [pageInit]="pageInit" [currentPage]="currentPage"
      (changePagination)="pageChanged($event)" (changeOrder)="updateDataBySortParams($event)"
      sortActive=""></app-data-table-with-pagination>

  </div>

</div>