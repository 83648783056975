import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ReviewService } from 'src/app/services/review.service';
import { LibService } from 'src/app/services/libService';
import { OriginService } from 'src/app/services/origin.service';
import { ReviewEsitTypeService } from 'src/app/services/reviewEsitType.service';
import { combineLatest } from 'rxjs';
import { SubjectService } from 'src/app/services/subject.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-search-or-create-user',
  templateUrl: './search-or-create-user.component.html',
  styleUrls: ['./search-or-create-user.component.scss']
})
export class SearchOrCreateUserComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isNew: boolean;
  @Input() type: string;
  @Input() disabled: boolean = false;

  reviewEsitTypes: any = []

  origins$ = this.originService.getAllOrigins();
  reviewEsitTypes$ = this.reviewEsitTypeService.index();

  userName = this.authService.user().name;

  reviewEsitTypesFiltered: any = []

  practices: any = []

  insertSubjectTypes: any = [
    'search', 'new'
  ]

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private reviewService: ReviewService,
    private libService: LibService,
    private originService: OriginService,
    private reviewEsitTypeService: ReviewEsitTypeService,
    public subjectService: SubjectService,
    public orderService: OrderService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.setInsertSubjectType()
  }

  ngOnChanges(changes: SimpleChanges): void {}


  insertSubjectTypeChange(event: any) {
    //console.log('insertSubjectTypeChange')
    //console.log(event)
    this.form.get('subject')?.reset();
    this.form.get('subjectAlias')?.reset();
    this.form.get('practiceId')?.reset();
  }



  setInsertSubjectType() {
    let value = 'search'
    if (this.form?.get('subjectAlias')?.value) value = 'alias'
    this.form?.get('insertSubjectType')?.setValue(value)
  }

  getInsertSubjectType() {
    return this.form?.get('insertSubjectType')?.value;
  }

  
  itIsAFreeSubject() {
    return this.form?.get('freeSubject')?.value || false
  }

  theSubjectIsPresent() {
    return this.form?.get('subject')?.value || false
  }


  selSubject(event: any) {
    //console.log('Selected: ', event);
    this.form?.get('subject')?.patchValue(event)
    this.researchSubjectPractices()
  }

  getSubject() {
    return this.form?.get('subject')?.value;
  }

  researchSubjectPractices() {
    let subjectId = this.form.get('subject')?.value?.id
    if (subjectId) {
      this.libService.lockPage('');
      this.orderService.getOrderBySubjectId(this.userName, subjectId, 'subject').subscribe((res: any) => {
        this.practices = res
        this.libService.unlockPage();
      })
    }
  }

  setReviewEsitTypesVisibility(esit: any) {
    //console.log('setReviewEsitTypesVisibility', esit)
    this.reviewEsitTypesFiltered = this.reviewEsitTypes.filter((reviewEsitType: any) => reviewEsitType.esit == esit)
  }

  radioChange(event: any) {
    //console.log('radioChange')
    let value = event.value
    this.setReviewEsitTypesVisibility(value)
    this.form?.get('reviewEsitTypeValue')?.setValue(null)
  }

}
