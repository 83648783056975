<div class="container-fluid">

  <div style="overflow-x: auto;">
    <br>
    <br>
    <mat-expansion-panel style="width: 100%; margin-left:0;" appearance="fill" class="form-subject" [disabled]="toDisable">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'LABEL.CREATE_RELATION' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>


          <div style="margin:5px;">
            <mat-radio-group [(ngModel)]="searchType" (ngModelChange)="onSearchTypeChange($event)">
              <mat-radio-button style="margin-right:5px;" value="Soggetto">&nbsp;{{ 'LABEL.SUBJ' | translate }}&nbsp;</mat-radio-button>
              <mat-radio-button style="margin-right:5px;" value="Edificio">&nbsp;{{ 'LABEL.BUILDING' | translate }}&nbsp;</mat-radio-button>
              <mat-radio-button style="margin-right:5px;" value="Condominio">&nbsp;{{ 'LABEL.CONDOMINIUM' | translate }}&nbsp;</mat-radio-button>
            </mat-radio-group>
          </div>
          <br>
          <!--
        <app-formfield-search style="border-top: 50%;" style="width: 100%;" [events]="eventsSubject"
                  [controlName]="'subjectField'" [service]="searchService" [searchType]="'Soggetto'"
                  (selected)="setSubject($event)" ></app-formfield-search> -->
                  <!-- (formOutput)="setFormValue($event)" -->

      <button style="background-color:transparent; float: right; " matTooltip="{{ 'LABEL.CREATE_RELATION' | translate }}" type="button"
        class="my-bounce btn btn-primary mx-2" (click)="creaRelazione()">
        <mat-icon class="material-icons" style="color:#293683;">done</mat-icon>
      </button>
    </mat-expansion-panel>
    <br>
    <!-- <table mat-table [dataSource]="relations" matSort style="width:100%">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">ID Relazione</th>
        <td class="tdg" mat-cell *matCellDef="let element">
          {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="subject2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.SoggettoRelazioneDA' | translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.subjectFrom?.name }}</b>&nbsp;<span style="font-size:12px;">({{element.subjectFrom?.type }})</span></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="subject1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table centered">{{'LABEL.SoggettoRelazioneA' | translate }}
        </th>
        <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.subjectTo?.name }}</b>&nbsp;<span style="font-size:12px;">({{element.subjectTo?.type }})</span></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="deleteAction">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" (click)="deleteRow(element)"><i
            class="my-bounce material-icons ic">delete</i>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table centered"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table> -->

    <app-data-table
    [data]="dataSource"
    [columns]="columns"
    [displayedColumns]="displayedColumns"
    [actions]="actions">
    </app-data-table>
  </div>
</div>
