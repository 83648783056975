import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactReason } from '../models/contact-reason';
import { LibService } from './libService';

@Injectable({
  providedIn: 'root'
})
export class ContactReasonService {

  baseApi = environment.rootApiCrm + 'v1.0/contactReason';

  constructor(
    private libService: LibService
  ) { }

  getAll(){
    return this.libService.getData<ContactReason[]>(this.baseApi, '')
  }
}
