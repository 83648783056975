import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable, map, catchError, throwError } from "rxjs";
import { UserSessionService } from "../services/user-session.service";
import { GuardService } from "../services/guard.service";

@Injectable({
  providedIn: 'root'
})
export class GenericActionVerifierGuard implements CanActivate {

  constructor(
    private router: Router,
    private userSessionService: UserSessionService,
    private guardService: GuardService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.userSessionService.getVisibilities().subscribe(
      v => {
        // Recupero le keyFrontEnds dalle action di cui l'utente ha visibilità
        let keyFrontEnds = this.guardService.getKeyFrontEnd(v)

        // Verifico se la keyFrontEnds della rotta attuale è contenuta tra quelle dell'utente
        let canSeeTheSection = keyFrontEnds.includes(route.data['keyFrontEnd'])

        // Concedo la visibilità della sezione o re-indirizzo all'home page
        if (canSeeTheSection) {
          return canSeeTheSection
        } else {
          this.router.navigate(['/'])
          return false;
        }

      },
      err => {
        return throwError(err);
      }
    );
    return true

    // CHIAMATA PUNTUALE 
    /*
        return this.userSessionService.checkVisibilities().pipe(
          map(r => {
            // Recupero le keyFrontEnds dalle action di cui l'utente ha visibilità
            let keyFrontEnds = this.guardService.getKeyFrontEnd(r)
    
            // Verifico se la keyFrontEnds della rotta attuale è contenuta tra quelle dell'utente
            let canSeeTheSection = keyFrontEnds.includes(route.data['keyFrontEnd'])
    
            // Concedo la visibilità della sezione o re-indirizzo all'home page
            if (canSeeTheSection) {
              return canSeeTheSection
            } else {
              this.router.navigate(['/'])
              return false;
            }
    
          }),
          catchError(err => {
            return throwError(err);
          })
        )
        */

  }
}
