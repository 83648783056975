import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  BryntumCalendarComponent,
  BryntumCalendarProjectModelComponent,
} from '@bryntum/calendar-angular-thin';

import { Subject } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { Resource } from 'src/app/models/resource';
import { Task } from 'src/app/models/task';
import { Calendar } from '@bryntum/calendar-thin';
import { Assignement } from 'src/app/models/assignement';
import { FinanceService } from 'src/app/services/finance.service';
import { PlannerFormMultiService } from '../../providers/planner-form-multi.service';
import * as moment from 'moment';
import { LibService } from 'src/app/services/libService';
import { PlanningService } from 'src/app/services/planning.service';
import { UpdateTaskChanges } from '../../models/updateTaskChanges';
import { filter, forkJoin } from 'rxjs';
import { Company } from 'src/app/models/company';
import { User } from 'src/app/models/user';
import { EventModelConfig } from '@bryntum/schedulerpro-thin';
import { CalendarTask } from 'src/app/models/calendarTask';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LocaleManager, MessageDialog, Panel } from '@bryntum/core-thin';
import '@bryntum/calendar-thin/lib/localization/It';
import { UserRole } from '../../models/userRole';
import { localeIt } from '../planner-scheduler/locate-it';
import { localeFr } from '../planner-scheduler/locate-fr';
import { localeEs } from '../planner-scheduler/locate-es';
import { TranslateService } from '@ngx-translate/core';



import {
  calendarConfig,
  projectConfig,
} from './config/planner-calendar.config';
import { CrudManagerConfig } from '../../models/crudManagerConfig';
LocaleManager.locale = 'It';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'planner-calendar',
  templateUrl: './planner-calendar.component.html',
  styleUrls: ['./planner-calendar.component.scss'],
})
export class PlannerCalendarComponent implements OnInit, AfterViewInit {
  constructor(
    private orderService: OrderService,
    private userSessionService: UserSessionService,
    private financeService: FinanceService,
    private formService: PlannerFormMultiService,
    private libService: LibService,
    private planningService: PlanningService,
    private router: Router,
    public translate: TranslateService,

  ) { }
  @ViewChild('calendar') calendarComponent!: BryntumCalendarComponent;
  @ViewChild('project') projectComponent!: BryntumCalendarProjectModelComponent;

  calendar: Calendar;
  resources: Resource[] = [];
  calendarTasks: CalendarTask[] = [];
  assignements: Assignement[] = [];
  currentUser: User = this.userSessionService.getState<User>('user')!;

  taskDestructionCounter: any = []
  resourceRemovalCounterPerCode: any = []
  isDeletingEvent: boolean = false;

  private onDestroy = new Subject<void>(); 


  eventMenu: any = {
    items: {
      duplicate: null,
      reset: {
        ref: 'reset',
        text: 'Reset',
        icon: 'b-fa b-fa-fw b-fa-xmark',
        weight: 520,
        onItem: ({ eventRecord }: any) =>
          this.resetOfThePlannedOrder(eventRecord),
      },
    },
  }

  // Life cycles
  ngOnInit(): void {
    //this.getData(this.currentUser);
    this.liveUpdatePlanning();
    this.applySchedulerLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: any) => {
      this.applySchedulerLocale(event.lang);  
    });
  }

  ngAfterViewInit(): void {

    // this.applySchedulerLocale(this.translate.currentLang);
    // this.translate.onLangChange.subscribe((event: any) => {
    //   this.applySchedulerLocale(event.lang);  
    // });

    const isForm = this.router.url.indexOf('/form/') > -1;
    this.calendar = this.calendarComponent.instance;
    this.calendar.readOnly = isForm;
    this.calendar.features.eventMenu.disabled = isForm;
    this.calendar.features.scheduleMenu.disabled = isForm;
    // Set the crudManager params before making the load request
    this.calendar.crudManager.on('beforesend', (config: CrudManagerConfig) => {
      //console.log('config', config);

      if (config.requestType === 'load') {
        config.params.id = this.currentUser.id;
        config.params.user = this.currentUser.email;
        config.params.idSede = this.getCompanyId();
      }
    });

    this.router.events
      .pipe(
        filter(
          (e: Event | RouterEvent): e is NavigationEnd =>
            e instanceof NavigationEnd
        )
      )
      .subscribe((e) => {
        const isForm = e.url.indexOf('/form/') > -1;
        if (isForm) {
          this.calendar.features.eventMenu.disabled = true;
          this.calendar.features.scheduleMenu.disabled = true;
          this.calendar.readOnly = true;
        } else {
          this.calendar.features.eventMenu.disabled = false;
          this.calendar.features.scheduleMenu.disabled = false;
          this.calendar.readOnly = false;
          this.formService.tasks = [];

          /*
          this.calendar.eventStore.data = this.calendarTasks;
          this.calendar.assignmentStore.data = this.assignements;
          */
        }
      });
  }

  // Get Data
  getData(currentUser: User): void {
    this.libService.lockPage('');
    const resources$ = this.financeService.getRisorse(
      currentUser.email!,
      this.getCompanyId(),
      UserRole.MULTI
    );
    const tasks$ = this.orderService.getOrdersAndTasksMulti(
      currentUser.email!,
      currentUser.id!,
      this.getCompanyId()
    );
    forkJoin([resources$, tasks$]).subscribe(([resources, tasks]) => {
      this.setCalendarResources(resources.esito, resources.item.Table);
      // this.setCalendarTasks(tasks);
      this.libService.unlockPage();
    });
  }

  setCalendarResources(esito: string, resources: Resource[]): void {
    if (esito === 'OK') {
      this.resources = [...resources];
      this.calendar.resourceStore.data = this.resources;
    }
  }

  setCalendarTasks(tasks: Task[]): void {
    tasks.reduce(
      ([tasksRef, assignementsRef], task, index) => {
        if (!tasksRef.includes(task.code + task.tsStart)) {
          this.calendarTasks.push({
            id: task.code + task.tsStart,
            name: `${task.Order.code} - ${task.Order.nameSubject} - ${task.Order.nameBuilding}`,
            startDate: this.getDateFromTimestamp(task.tsStart),
            endDate: this.getDateFromTimestamp(task.tsEnd),
            code: task.code,
          });
          tasksRef.push(task.code + task.tsStart);
        }
        if (!assignementsRef.includes(task.userId + task.code + task.tsStart)) {
          this.assignements.push({
            id: index.toString(),
            eventId: task.code + task.tsStart,
            resourceId: task.userId,
          });
          assignementsRef.push(task.userId + task.code + task.tsStart);
        }
        return [tasksRef, assignementsRef];
      },
      [[], []] as Array<string[]>
    );
    this.calendar.eventStore.data = this.calendarTasks;
    this.calendar.assignmentStore.data = this.assignements;
  }

  getCompanyId(): number {
    const company =
      this.userSessionService.getState<Company>('working_company');
    if (!(company?.name == '*')) {
      return company?.id!;
    } else return 0;
  }

  // manage calendar preview
  liveUpdatePlanning(): void {
    if (this.formService) {
      this.formService.plannerFormMulti.valueChanges.subscribe((form: any) => {
        if (this.formService.isValid) {
          let newCalendarTasks: Partial<EventModelConfig>[] = [];
          let newTasks: {
            startDate: Date;
            endDate: Date;
            resources: Resource[];
            hoursPerSingleRepetition: number;
            rowId: number;
          }[] = [];
          let newAssignements: Assignement[] = [];
          for (const formRow of form.formRows) {
            let intervalUnit: moment.unitOfTime.Base = 'weeks';
            const numberOfIntervals =
              formRow.numberOfRepetitions / formRow.weekDays.length;
            let currentRepetition = 0;
            let intervalsMultiplier = 1;

            switch (formRow.typologyServicePassageId) {
              case 'typology_service_passages_parziale':
              case 'typology_service_passages_settimanale':
                intervalUnit = 'weeks';
                break;
              case 'typology_service_passage_passaggio_completo':
                intervalUnit = 'weeks';
                break;
              case 'typology_service_passages_mensile':
                intervalUnit = 'months';
                break;
              case 'typology_service_passages_bimestrale':
                intervalUnit = 'months';
                intervalsMultiplier = 2;
                break;
              case 'typology_service_passages_quadrimestrale':
                intervalUnit = 'months';
                intervalsMultiplier = 4;
                break;
              case 'typology_service_passages_semestrale':
                intervalUnit = 'months';
                intervalsMultiplier = 6;
                break;
            }
            for (const weekDay of formRow.weekDays) {
              const firstRepetitionWeekDay = moment(formRow.startDate).day(
                weekDay
              );
              const firstRepetitionWeekDayIsBeforeStartDate =
                firstRepetitionWeekDay.isBefore(formRow.startDate);
              let startDate = formRow.startDate;
              let endDate = formRow.endDate;
              for (
                let currentNumberOfIntervals = 0;
                currentNumberOfIntervals < numberOfIntervals;
                currentNumberOfIntervals++
              ) {
                if (firstRepetitionWeekDayIsBeforeStartDate) {
                  startDate = new Date(
                    moment(formRow.startDate).day(weekDay).add(1, 'week').format()
                  );
                  endDate = new Date(
                    moment(formRow.endDate).day(weekDay).add(1, 'week').format()
                  );
                } else {
                  startDate = new Date(
                    moment(formRow.startDate).day(weekDay).format()
                  );
                  endDate = new Date(
                    moment(formRow.endDate).day(weekDay).format()
                  );
                }
                if (currentRepetition < formRow.numberOfRepetitions) {
                  startDate = new Date(
                    moment(startDate)
                      .add(
                        currentNumberOfIntervals * intervalsMultiplier,
                        intervalUnit
                      )
                      .day(weekDay)
                      .format()
                  );
                  endDate = new Date(
                    moment(endDate)
                      .add(
                        currentNumberOfIntervals * intervalsMultiplier,
                        intervalUnit
                      )
                      .day(weekDay)
                      .format());

                      let currentHoursPerSingleRepetition = formRow.hoursPerSingleRepetition / formRow.resources.length

                  newCalendarTasks.push({
                    id:
                      formRow.id +
                      weekDay +
                      currentNumberOfIntervals +
                      'local',
                    name: form.name,
                    startDate,
                    endDate,
                  });
                  newTasks.push({
                    startDate,
                    endDate,
                    resources: formRow.resources,
                    hoursPerSingleRepetition: currentHoursPerSingleRepetition,
                    rowId: formRow.id,
                  });
                  for (const resource of formRow.resources) {
                    newAssignements.push({
                      id:
                        formRow.id +
                        resource.id +
                        weekDay +
                        currentNumberOfIntervals +
                        'local',
                      eventId: formRow.id + weekDay + currentNumberOfIntervals,
                      resourceId: resource.id,
                    });
                  }
                  currentRepetition++;
                }
              }
            }
          }

          this.formService.tasks = newTasks;


        }
      });

    }

    if (this.calendar) {
      this.calendar.eventStore.data = [
        ...this.calendarTasks,
        //...newCalendarTasks,
      ];
      this.calendar.assignmentStore.data = [
        ...this.assignements,
        //...newAssignements,
      ];
    }

  }

  // manage Tasks CRUD
  getChanges(event: any) {
    //console.log(event)
    if (event.action !== 'dataset') {
      switch (event.action) {
        case 'update':
          this.updateTask(event.record.data.code, event.changes);
          break;
        case 'add':
          this.addResources(event.records);
          break;
        case 'remove':
          if (event.store.changes?.removed?.length > 0) {
            this.checkDeletionType(event.records);

          }
          // console.log(event.store.changes?.removed?.length);


          break;
      }
    }
  }

  checkDeletionType(records: any) {
    //console.log('check deletion type', records);

    for (const { data } of records) {
      //console.log('data', data);

      if (data.id !== null)

        if (!Object.keys(data).includes('resource')) {
          //console.log('delete task');

          this.deleteTask(records);
          this.isDeletingEvent = true;
        } else {
          //console.log('delete resource');

          if (!this.isDeletingEvent) {
            this.removeResources(records);
          }
        }
      this.isDeletingEvent = false;
    }
  }

  removeResources(records: any) {
    console.log('remove resources');

    this.libService.lockPage('');
    let params: any = {}

    for (const { data } of records) {
      let code = data.event.code
      if (!this.taskDestructionCounter.includes(code)) {
        if (!params[code]) params[code] = []
        params[code].push(data.resourceId)
      }
    }

    if (Object.keys(params).length > 0) {
      for (const [key, value] of Object.entries(params)) {
        let vKey: any = key
        let vValue: any = value

        const params = {
          taskCodes: [key],
          resourceIds: value,
        };

        let unicCode = `${vKey}_${vValue.join('_')}`

        if (!this.resourceRemovalCounterPerCode.includes(unicCode)) {
          this.resourceRemovalCounterPerCode.push(unicCode)
          this.planningService
            .removeResources(params)
            .subscribe(() => this.libService.unlockPage());
        } else {
          this.libService.unlockPage();
        }

      }
    } else {
      this.libService.unlockPage();
    }
  }

  addResources(records: any) {
    console.log('add resources', records);

    this.libService.lockPage('');
    const tasks = [];
    let { email: who, id: authorId } = this.currentUser;
    for (const {
      event: {
        code,
        amountHours,
        startDate,
        endDate,
        officeId,
        orderIdId,
        OrderRowId,
      },
      data: {
        resource: {
          id: userId,
          name: userName,
          role: userRole,
          roleId: userRoleId,
        },
      },
    } of records) {
      const task = {
        code,
        who,
        amountHours,
        tsStart: this.setDateToUTCZone(new Date(startDate)),
        tsEnd: this.setDateToUTCZone(new Date(endDate)),
        userId,
        userName,
        userRole,
        userRoleId,
        officeId,
        taskType: 'order',
        isRolling: 1,
        orderIdId,
        authorId,
        OrderRowId,
      };
      tasks.push(task);
    }
    //console.log('tasks', tasks);

    const isArrayEmpty = (array: any[]) => array.length === 0;
    if (!isArrayEmpty(tasks)) {
      this.planningService
        .addResources(tasks)
        .subscribe(() => this.libService.unlockPage());
    } else {
      this.libService.unlockPage();
    }
  }
  updateTask(taskId: string, changes: any) {
    this.libService.lockPage('');
    let formattedChanges: UpdateTaskChanges = {};
    for (const changedProperty in changes) {
      switch (changedProperty) {
        case 'startDate':
          formattedChanges.tsStart = this.getUnixTimestamp(
            changes[changedProperty].value
          );
          break;
        case 'endDate':
          formattedChanges.tsEnd = this.getUnixTimestamp(
            changes[changedProperty].value
          );
          break;
      }
    }
    const isObjEmpty = (obj: Object): boolean => Object.keys(obj).length === 0;
    if (!isObjEmpty(formattedChanges)) {
      this.planningService
        .updateCalendar(taskId, formattedChanges)
        .subscribe(() => this.libService.unlockPage());
    } else {
      this.libService.unlockPage();
    }
  }
  deleteTask(records: any) {
    this.libService.lockPage('');
    const taskCode = records[0].data.code;
    if (taskCode !== null) {
      this.taskDestructionCounter.push(taskCode)
      this.planningService.deleteTask(taskCode).subscribe(() => {
        this.libService.unlockPage();
      });
    } else {
      this.libService.unlockPage();
    }
  }

  // Utilities
  getDateFromTimestamp(timestamp: string) {
    const date = new Date(parseInt(timestamp) * 1000);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes()
    );
  }
  setDateToUTCZone(date: any) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let newDate = new Date(Date.UTC(year, month, day, hours, minutes));
    return newDate;
  }
  getUnixTimestamp(date: Date): number {
    return Math.floor(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      ) / 1000
    );
  }

  resetOfThePlannedOrder(eventRecord: any) {
    console.log('RESET');
    //console.log(eventRecord);

    let orderId = eventRecord.orderIdId;

    MessageDialog.confirm({
      title: 'Reset Pianificazione Commessa',
      message: 'Sei sicuro di voler procedere al reset della Pianificazione?',
    }).then((result: any) => {
      if (result === 1) {
        console.log(this.calendar.eventStore)
        let events = this.calendar.eventStore.findByField('orderIdId', orderId)

        
      
        this.planningService.resetPlanningMulti(orderId).subscribe(
          (responseData: any) => {
            this.calendar.features.loadOnDemand.refresh()
          },
          (err) => {
            console.log(err)
          }
        );
        
      }
    });
  }

  applySchedulerLocale = (schedulerLocale: string): void => {
    switch (schedulerLocale) {
      case 'it':
        LocaleManager.applyLocale(localeIt);
        break;
      case 'fr':
        LocaleManager.applyLocale(localeFr);
        break;
      case 'es':
        LocaleManager.applyLocale(localeEs);
        break;
      case 'en':
        LocaleManager.locale = 'En';
        break;
      default:
        LocaleManager.locale = 'En';
        break;
    }
  }


  calendarConfig = calendarConfig;
  projectConfig = projectConfig;
}
