<div class="container-fluid my-container" >
  <div class="buttons my-2">
    <div style="display:flex; flex-direction: column;">
      <form [formGroup]="formSubject">
        <h3 style="margin-bottom:15px;">{{ 'LABEL.SUBJECT_TYPE' | translate }}</h3>
        <!--  [(ngModel)]="type" (ngModelChange)="onTypeChange($event)" -->
        <app-type-radio formControlName="type"  [disabled]="(params$ | async)!.id !== '0'"></app-type-radio>

      </form>
    </div>
    <div class="buttons-right">
      <mat-icon class="check-ok" *ngIf="formSubject.valid">check</mat-icon>
      <mat-icon [matTooltip]="getErrorTooltip()" class="check-ko" *ngIf="!formSubject.valid">warning</mat-icon>
      <button style="background-color:transparent;" [disabled]="!formSubject.valid" matTooltip="Salva" type="button"
          class="my-bounce btn btn-primary mx-2" (click)="update()">
          <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>
    </div>
  </div>
  <hr>
  <mat-tab-group class="wbck" animationDuration="300ms" (selectedTabChange)="changeTab($event)">
      <mat-tab *ngIf="!(type === 'Ente')">

        <ng-template mat-tab-label>
            <mat-icon class="my-bounce material-icons">maps_home_work</mat-icon>
            <span class="ml-1">{{ 'LABEL.LOCALIZATION' | translate }}</span>
            <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
        </ng-template>
        <app-building-localization [form]="formSubject" [toDisable]="toDisable" [isNew]="isNew" [companies]="companies" [type]="type"></app-building-localization>
    </mat-tab>
      <mat-tab>
          <ng-template mat-tab-label>
              <mat-icon class="my-bounce material-icons">person</mat-icon>
              <span class="ml-1">{{ 'LABEL.ANAGRAFICA' | translate }}</span>
              <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
          </ng-template>
          <fieldset ng-disabled="boolView">
            <app-building-data [form]="formSubject" [types]="(types$ | async)!" [statuses]="statuses" [companies]="companies" [loggedUser]="loggedUser" [users]="oplist2" [disable]="toDisable" [isNew]="isNew" [globalType]="type"></app-building-data>
          </fieldset>

      </mat-tab>
      <!-- <mat-tab *ngIf="!(type == 'Edificio' || type  == 'Privato' || type == 'Condominio' || type == 'Amministratore' || type == 'other') ">
          <ng-template mat-tab-label>
              <mat-icon class="my-bounce material-icons">verified</mat-icon>
              <span class="ml-1">Consensi</span>
              <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
          </ng-template>
      </mat-tab>-->
      <mat-tab [disabled]="isNew">
          <ng-template mat-tab-label>
              <mat-icon class="my-bounce material-icons">folder</mat-icon>
              <span class="ml-1">{{ 'LABEL.Documenti' | translate }}</span>
              <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
          </ng-template>
          <app-building-documents [form]="formSubject" [toDisable]="toDisable"></app-building-documents>
      </mat-tab>
      <mat-tab *ngIf="(type === 'Edificio') && !isNew">
          <ng-template mat-tab-label>
              <mat-icon class="my-bounce material-icons">list</mat-icon>
              <span class="ml-1">{{ 'LABEL.Questionari' | translate }}</span>
              <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
          </ng-template>
          <app-building-survey [form]="formSubject" [toDisable]="toDisable"></app-building-survey>
      </mat-tab>
      <mat-tab [disabled]="isNew" #tabRelations>
          <ng-template mat-tab-label >
              <mat-icon class="my-bounce material-icons">group</mat-icon>
              <span class="ml-1">{{ 'LABEL.Relazioni' | translate }}</span>
              <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
          </ng-template>
          <div *ngIf="tabRelations.isActive">

            <app-building-relations  [form]="formSubject" [toDisable]="toDisable" [loggedUser]="loggedUser"></app-building-relations>

          </div>
      </mat-tab>

      <mat-tab *ngIf="!(type == 'Edificio' || type == 'Condominio') ">
        <ng-template mat-tab-label>
            <mat-icon class="my-bounce material-icons">info</mat-icon>
            <span class="ml-1">{{ 'LABEL.MARKETING' | translate }}</span>
            <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
        </ng-template>
        <app-building-marketing [form]="formSubject"></app-building-marketing>
    </mat-tab>

    <mat-tab  [disabled]="isNew">

      <ng-template mat-tab-label>
          <mat-icon class="my-bounce material-icons">settings</mat-icon>
          <span class="ml-1">{{ 'LABEL.ACTIVITIES' | translate }}</span>
          <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
      </ng-template>
      <div class="full-w" >
        <app-activities *ngIf="subject !== null" [subject]="subject" [isInput]="true" [toDisable]="disableActivity"></app-activities>
      </div>
  </mat-tab>

  <mat-tab  [disabled]="isNew" #tabOrders>

    <ng-template mat-tab-label>
        <mat-icon class="my-bounce material-icons">description</mat-icon>
        <span class="ml-1">{{ 'LABEL.PRACTICES' | translate }}</span>
        <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>
    </ng-template>
    <div class="full-w" *ngIf="tabOrders.isActive">
      <app-building-practices *ngIf="(subject !== null)" [subject]="subject" [form]="formSubject"></app-building-practices>
    </div>
</mat-tab>

  </mat-tab-group>
  <div class="buttons-right  my-2">
    <mat-icon class="check-ok" *ngIf="formSubject.valid">check</mat-icon>
      <button style="background-color:transparent;" [disabled]="!formSubject.valid" matTooltip="Salva" type="button"
          class="my-bounce btn btn-primary mx-2" (click)="update()">
          <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
      </button>
  </div>
</div>

