<bryntum-calendar-project-model
  #project
  [events]="calendarTasks"
  [resources]="resources"
  [assignments]="assignements"
></bryntum-calendar-project-model>

<bryntum-calendar
  #calendar
  [date]="calendarConfig.date!"
  [project]="project"
  [modes]="calendarConfig.modes"
  [eventMenuFeature]="eventMenu"
  [eventEditFeature]="calendarConfig.features?.eventEdit"
  [scheduleMenuFeature]="calendarConfig.features?.scheduleMenu"
  [eventTooltipFeature]="calendarConfig.features?.eventTooltip"
  [loadOnDemandFeature]="calendarConfig.features?.loadOnDemand"
  [crudManager]="calendarConfig.crudManager"
  [timeZone]="calendarConfig.timeZone"
  minHeight="800px"
  [allowOverlap]="true"
  (onDataChange) = "getChanges($event)"
></bryntum-calendar>
