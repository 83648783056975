<div class="content d-flex flex-column flex-column-fluid" id="kt_content">

    <div *ngFor="let category of dashboardsByCategory">
    <mat-expansion-panel style="background-color: whitesmoke;">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span class="material-icons inline-icon">query_stats</span>
                <div class="fw-bolder text-dark"> {{ category.label }}</div>
            </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="row g-6 g-xl-9">
                <div class="col-md-6 col-xl-4" *ngFor="let dashboard of category.data">
                    <!--begin::Card-->
                    <div *ngIf="!(dashboard == null)" class="card h-100">
                        <!--begin::Card body-->
                        <div class="card-body p-9">
                            <div class="fs-3 fw-bolder text-dark mb-7" *ngIf="dashboard !== null">
                                {{dashboard.name}}
                            </div>
                            <div class="d-flex">
                                <a class="btn btn-primary btn-sm me-3 text-dark" (click)="viewDashboard(dashboard)">{{
                                    'LABEL.VIEW'
                                    | translate:{'field': translate.instant('LABEL.DASHBOARD') } }}</a>
                              
                                <a class="alignMiddle btn btn-primary btn-sm me-3 text-dark" (click)="openDashboardToExt(dashboard)" extended>
                                    <mat-icon>open_in_new</mat-icon>
                                  </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
    <br>
    </div>

    <mat-expansion-panel style="background-color: whitesmoke;">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span class="material-icons-outlined inline-icon">fact_check</span>
                <div class="fw-bolder text-dark"> {{ 'LABEL.TODOLIST' | translate }} {{ requests? "(" + requests.length
                    + ")": undefined }}</div>
            </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="row g-6 g-xl-9">
            <app-user-tasks [userArea]="false" [requests]="requests"></app-user-tasks>
        </div>
    </mat-expansion-panel>
    <br>
    <!-- <div id="kt_content_container" class="dis_flex_row  container-xxl">

        <div class="col-lg-6 col-xxl-4">

            <div class="card h-100">

                <div class="card-body p-9">

                    <div class="fs-2hx fw-bolder">237</div>
                    <div class="fs-4 fw-bold text-gray-400 mb-7">{{ 'LABEL.ORDERS' | translate }}</div>

                    <div class="d-flex flex-wrap">

                        <div class="d-flex flex-center h-100px w-100px me-9 mb-5">
                            <canvas id="kt_project_list_chart"></canvas>
                        </div>

                        <div class="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">

                            <div class="d-flex fs-6 fw-bold align-items-center mb-3">
                                <div class="bullet bg-primary me-3"></div>
                                <div class="text-gray-400">{{ 'LABEL.WIP' | translate }}</div>
                                <div class="ms-auto fw-bolder text-gray-700">30</div>
                            </div>

                            <div class="d-flex fs-6 fw-bold align-items-center mb-3">
                                <div class="bullet bg-success me-3"></div>
                                <div class="text-gray-400">{{ 'LABEL.COMPLETED_M' | translate }}</div>
                                <div class="ms-auto fw-bolder text-gray-700">45</div>
                            </div>

                            <div class="d-flex fs-6 fw-bold align-items-center">
                                <div class="bullet bg-gray-300 me-3"></div>
                                <div class="text-gray-400">{{ 'LABEL.TOSTART' | translate }}</div>
                                <div class="ms-auto fw-bolder text-gray-700">25</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-xxl-4">
            <div class="card h-100">
                <div class="card-body p-9">
                    <div class="fs-2hx fw-bolder">3,290.00</div>
                    <div class="fs-4 fw-bold text-gray-400 mb-7">{{ 'LABEL.TOT_ACTIVE_ORDERS' | translate }}
                    </div>
                    <div class="fs-6 d-flex justify-content-between mb-4">
                        <div class="fw-bold">{{ 'LABEL.MEDIUM_VALUE_ORDERS' | translate }}</div>
                        <div class="d-flex fw-bolder">

                            <span class="svg-icon svg-icon-3 me-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M13.4 10L5.3 18.1C4.9 18.5 4.9 19.1 5.3 19.5C5.7 19.9 6.29999 19.9 6.69999 19.5L14.8 11.4L13.4 10Z"
                                        fill="currentColor" />
                                    <path opacity="0.3" d="M19.8 16.3L8.5 5H18.8C19.4 5 19.8 5.4 19.8 6V16.3Z"
                                        fill="currentColor" />
                                </svg>
                            </span>
                            6.570,00
                        </div>
                    </div>
                    <div class="separator separator-dashed"></div>
                    <div class="fs-6 d-flex justify-content-between my-4">
                        <div class="fw-bold">{{ 'LABEL.THESHOLD_VALUE_ORDER' | translate }}</div>
                        <div class="d-flex fw-bolder">

                            <span class="svg-icon svg-icon-3 me-1 svg-icon-danger">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M13.4 14.8L5.3 6.69999C4.9 6.29999 4.9 5.7 5.3 5.3C5.7 4.9 6.29999 4.9 6.69999 5.3L14.8 13.4L13.4 14.8Z"
                                        fill="currentColor" />
                                    <path opacity="0.3" d="M19.8 8.5L8.5 19.8H18.8C19.4 19.8 19.8 19.4 19.8 18.8V8.5Z"
                                        fill="currentColor" />
                                </svg>
                            </span>
                            408,00
                        </div>
                    </div>
                    <div class="separator separator-dashed"></div>
                    <div class="fs-6 d-flex justify-content-between mt-4">
                        <div class="fw-bold">{{ 'LABEL.TOP_INCREMENT_ORDER' | translate }}</div>
                        <div class="d-flex fw-bolder">

                            <span class="svg-icon svg-icon-3 me-1 svg-icon-success">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path
                                        d="M13.4 10L5.3 18.1C4.9 18.5 4.9 19.1 5.3 19.5C5.7 19.9 6.29999 19.9 6.69999 19.5L14.8 11.4L13.4 10Z"
                                        fill="currentColor" />
                                    <path opacity="0.3" d="M19.8 16.3L8.5 5H18.8C19.4 5 19.8 5.4 19.8 6V16.3Z"
                                        fill="currentColor" />
                                </svg>
                            </span>
                            920,00
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-6 col-xxl-4">

            <div class="card h-100">
                <div class="card-body p-9">

                    <div class="fs-2hx fw-bolder">49</div>
                    <div class="fs-4 fw-bold text-gray-400 mb-7">{{ 'LABEL.SUBJECTS' | translate }}</div>

                    <div class="symbol-group symbol-hover mb-9">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Nome Utente">
                            <span class="symbol-label bg-warning text-inverse-warning fw-bolder">A</span>
                        </div>

                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Nome Utente">
                            <span class="symbol-label bg-info text-inverse-info fw-bolder">P</span>
                        </div>

                        <a class="symbol symbol-35px symbol-circle" data-bs-toggle="modal"
                            data-bs-target="#kt_modal_view_users">
                            <span class="symbol-label bg-dark text-gray-300 fs-8 fw-bolder">+42</span>
                        </a>
                    </div>

                    <div class="d-flex">
                        <a class="btn btn-primary btn-sm me-3 text-dark">{{ 'LABEL.ALLS' | translate }}</a>
                        <a class="btn btn-light btn-sm">{{ 'LABEL.CALL' | translate }}</a>
                    </div>

                </div>
            </div>

        </div>


        <br>


    </div> -->

    <!-- <app-dashboard-view
 [dashboardId]="'144'"></app-dashboard-view>
 -->

</div>
