<div class="container-fluid my-container">

    <span class="ml-1">{{ 'LABEL.CLAIM_DATA' | translate }}</span>
    <i *ngIf="!(wikiService.getWikiUrl(navService.getWikiKey())==null) && wikiService.getWikiUrl(navService.getWikiKey()).length > 0"  class="crs" (click)="goToWiki()" matTooltip="AlbertWiki" class="material-icons icw">help_outline</i>


    <div class="full-w" fxLayout="row" fxLayoutAlign="center center" >

        <form [formGroup]="form" class="form-container, full-w" (ngSubmit)="save()" style="text-align: center;">
            <br><br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.N_CLAIM_MARSH' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.N_CLAIM_MARSH') | async" formControlName="n_claim_marsh">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.N_CLAIM_CLIENT' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.N_CLAIM_CLIENT') | async" formControlName="n_claim_client">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.START_DATE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.START_DATE') | async" formControlName="startDate">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.END_DATE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.END_DATE') | async" formControlName="endDate">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.DELEGATING_COMPANY' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.DELEGATING_COMPANY') | async" formControlName="delegatingCompany">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.MAIN_GUARANTEE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.MAIN_GUARANTEE') | async" formControlName="mainGuarantee">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.COMPLAINANT' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.COMPLAINANT') | async" formControlName="complainant">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.DESCRIPTION' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.DESCRIPTION') | async" formControlName="description">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.TYPE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.TYPE') | async" formControlName="type">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.CAUSE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.CAUSE') | async" formControlName="cause">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.CLAIM_STATUS' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.CLAIM_STATUS') | async" formControlName="claimStatus">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.LOCATION' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.LOCATION') | async" formControlName="location">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.PROVINCE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="text" [placeholder]="translate.getInsertLabel('LABEL.PROVINCE') | async" formControlName="province">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.DAMAGE_VALUE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="number" [placeholder]="translate.getInsertLabel('LABEL.DAMAGE_VALUE') | async" formControlName="damageValue">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.DEDUCTIBLE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="number" [placeholder]="translate.getInsertLabel('LABEL.DEDUCTIBLE') | async" formControlName="deductible">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.DUE' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="number" [placeholder]="translate.getInsertLabel('LABEL.DUE') | async" formControlName="due">
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill" class="form-request" style="width: 60%;">
                <mat-label>{{ 'LABEL.PAID' | translate }}</mat-label>
                <input matInput maxlength="128" inputmode="number" [placeholder]="translate.getInsertLabel('LABEL.PAID') | async" formControlName="paid">
            </mat-form-field>

            <br>

        </form>

        <div class="buttons-right  my-2">
            <button style="background-color:transparent;" matTooltip="Salva" type="button" class="my-bounce btn btn-primary mx-2" (click)="save()">
                <mat-icon class="material-icons" style="color:#293683;">save</mat-icon>
            </button>
        </div>
    </div>
    
</div>