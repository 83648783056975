import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FileUploadModalComponent } from '../modals/file-upload-modal/file-upload-modal.component';
import { Dashboard } from '../models/dashboard';
import { DashboardService } from '../services/dashboard.service';
import { NavService } from '../services/nav.service';
import { NewsService } from '../services/news.service';
import { LibService } from '../services/libService';
import { TranslatorService } from '../services/translate.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { RequestService } from '../services/request.service';


@Component({
  selector: 'app-cockpit-by-profile',
  templateUrl: './cockpit-by-profile.component.html',
  styleUrls: ['./cockpit-by-profile.component.scss']
})
export class CockpitByProfileComponent implements OnInit {

  title = "Cruscotto";
  subTitle = "EDAC - Cruscotto";
  windows:any;
  dashboardsByCategory: any = [];
  dashboards$ = this.dashboardService.getCockpit().pipe(
    map(dashboards => dashboards.map((dashboard: any) => {
      return {
        dashId: dashboard.DashboardId,
        name: dashboard.Name
      } as Dashboard
    })
    ),
    map((dashboards: any[]) => dashboards.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    )
  )
  requests: any;

  requests$ = this.requestService.getAllRequestsByOperatorAndStatus(this.authService.user().email)

  constructor( public navService: NavService,
    private dashboardService: DashboardService,
    private router: Router,
    private authService: AuthService,
    public translate: TranslatorService,
    private newsService: NewsService,
    private dialog: MatDialog,
    private libService:LibService,
    private requestService: RequestService) { }

    loadLabels(){
      this.translate.get('LABEL.COCKPIT').subscribe(res => {
        this.title = res;
        this.subTitle = "EDAC - " + res;
      })
    }

  ngOnInit(): void {

    this.requests$.subscribe(val => {
      this.requests = val;
    })

    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('CRUSH');
    this.loadLabels();
    console.log(this.authService.user());

    this.navService.setActions([]);

    this.dashboards$.subscribe(res => {
      console.log('Dashboards: ',res);

    })

    this.initDashboardsByCategory()
  }

  initDashboardsByCategory() {
    this.dashboards$.subscribe(dashboad => {
      for (let i = 0; i < dashboad.length; i++) {
        let splitName: any = dashboad[i].name.split('_')

        // Cerco nel nome la categoria, se non è presente di default è 'Dashboard'
        let currentCategoryIndex = splitName[1] == undefined ? 'Dashboard' : `Dashboard ${splitName[1]}`
        let currentCategory = this.retrieveInstantiatedCategory(currentCategoryIndex)

        // Istanzio la categoria se non esiste
        if( currentCategory == undefined ){
          this.dashboardsByCategory.push({ label: currentCategoryIndex, data: [] })
          currentCategory = this.retrieveInstantiatedCategory(currentCategoryIndex)
        }
    
        // aggiungo la dashbord alla categoria corrispondente
        currentCategory.data.push({ dashId: dashboad[i].dashId, name: splitName[0] })

      }
      this.dashboardsByCategory.sort((a: any, b: any) => {
        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
  
        // names must be equal
        return 0;
      })
      
    })
  }

  retrieveInstantiatedCategory(label: any){
    return this.dashboardsByCategory.find((category: any) => category.label == label)
  }

  viewDashboard(dashboard: Dashboard) {
    this.router.navigate(['dashboard/'+ dashboard.dashId])
  }

  openDashboardToExt(dashboard: Dashboard) {
    //window.open('dashboard/'+ dashboard.dashId, "_blank");
    this.dashboardService.getDashboardUrl(dashboard.dashId).subscribe(res => {
      if (!(res == null) && !(res['url'] == null))
        window.open(`${res['url']}&printEnabled=true`, "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=400, height=400");
    })
  }
  
}
