import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTab } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LibService } from 'src/app/services/libService';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-user-tasks',
  templateUrl: './user-tasks.component.html',
  styleUrls: ['./user-tasks.component.scss']
})
export class UserTasksComponent implements OnInit {

  @Input() requests: any;
  @Input() userArea: boolean;

  @ViewChild(MatSort) sort: MatSort;

  dataSource: any;



  displayedColumns = ["id", "subject", "building", "contactReason", "createdAt"];

  constructor(private requestService: RequestService,
              private authService: AuthService,
              private libService: LibService,
              private router: Router)
  {
    // this.dataSource =  new MatTableDataSource();
  }

  ngOnInit(): void {
    if (this.dataSource !== null && this.dataSource !== undefined){

    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.requests !== null && this.requests !== undefined){
      console.log("ENTRATO")
      this.dataSource = new MatTableDataSource(this.requests);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: any, property: any) => {
        switch(property) {
          case 'subject': return item.subject?.name !== null && item.subject?.name !== undefined? item.subject.name: item.orderCode;
          // case 'status': return 'LABEL.' + this.getStatusLabel(item.idStatus);
          case 'building': return item.building?.streetName;
          case 'contactReason': return item.contactReason?.name;
          case 'id': return item.id;
          default: return item[property];
        }
      }
    }
  }

  goTo(e: any) {
    //
    console.log("OGGETTO CLICCATO --> ", e);
    if (e.building !== null && e.building !== undefined) {
      this.router.navigate(['request/' + e.id]);
    } else {
      e.type === 'SOPRALLUOGO'?
      this.router.navigate(['crm/', { tabName: 'claims'}]): this.router.navigate(['activity/', e.id]);
    }
  }

}
