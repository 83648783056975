import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-subject-section-marketing',
  templateUrl: './subject-section-marketing.component.html',
  styleUrls: ['./subject-section-marketing.component.scss']
})
export class SubjectSectionMarketingComponent implements OnInit {

  @Input()form : UntypedFormGroup;
  @Input()components : any;
  @Input()subject : any;

  componentCode: string = "subjectMarketingForm"

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('[subjectMarketingForm] changes', changes);

    this.form.markAllAsTouched();

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'components': {
            this.addDynamicFields()
            this.addValidations()
            this.setEditabilityOfFields()
          }
        }
      }
    }
  }

  getCurrentComponent() {
    return this.components.find((component: any) => component.componentCode == this.componentCode)
  }

  checkVisibility(field: any) {
    return this.getCurrentComponent()?.attributes?.hasOwnProperty(field)
  }

  addValidations() {
    console.log('addValidations')
    let attributes = this.getCurrentComponent()?.attributes

    if (attributes) {
      for (const [key, value] of Object.entries(attributes)) {
        if (this.mandatoryVerification(key, attributes)) this.form?.get(key)?.addValidators(Validators.required);
      }
    }

  }

  mandatoryVerification(field: any, attributes: any) {
    let status = false
    if (attributes.hasOwnProperty(field) && attributes[field].required == true) status = true
    return status
  }

  setEditabilityOfFields() {
    console.log('setEditabilityOfFields')
    let attributes = this.getCurrentComponent()?.attributes
    if (attributes) {
      for (const [key, value] of Object.entries(attributes)) {
        let status = this.checkEditability(key, attributes)
        status ? this.form?.get(key)?.enable() : this.form?.get(key)?.disable()
      }
    }
  }

  checkEditability(field: any, attributes: any) {
    let status = false
    if (attributes.hasOwnProperty(field) && attributes[field].editable == true) status = true
    return status
  }

  addDynamicFields() {
    console.log('addDynamicFields')
    let attributes = this.getCurrentComponent()?.attributes
    if (attributes) {
      for (const [key, value] of Object.entries(attributes)) {
        
        let val = (this.subject && this.subject.hasOwnProperty(key)) ? this.subject[key] : null
        if (attributes[key].hasOwnProperty('formType')) this.form?.addControl(key, new FormControl(val, []));
      }
    }

  }

  getDynamicFields() {
    let attributes = this.getCurrentComponent()?.attributes
    let dynamicFileds: any = []
    if (attributes) {
      for (const [key, value] of Object.entries(attributes)) {
        let filed: any = value
        filed.code = key
        if (attributes[key].hasOwnProperty('formType') && this.form?.get(key)) dynamicFileds.push(filed)
      }
    }
    return dynamicFileds
  }

}
