<div class="full-w" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row">
    <div class="card-title flex-column mrg">
      <h2 class="mb-1">{{'LABEL.HINT' | translate}}</h2>
      <div [ngClass]="getClassWorkflow(1)"><mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(1)">circle</mat-icon>&nbsp;&nbsp;{{'LABEL.HINT_REQUEST' | translate}}</div>
    </div>
    <br>
    <div style="margin:5px; width: 100%;">
      <div class="photo-block">
        <div class="photo-button-block">
          <button class="my-bounce btn btn-primary fx-button aln"
            (click)="openUploadDialog('new')" [disabled]="isDisabled()" [disabled]="disableUpload">
            <mat-icon class="material-icons" style="color:#293683;">upload</mat-icon><span style="color:#293683;">{{'LABEL.Carica' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
    <br/>
  </div>
  <app-documents-grid fxFill [documents]="documents"></app-documents-grid>
</div>

