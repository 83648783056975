<form [formGroup]="form" *ngIf="form && checkComponent()">
  <mat-card>
    <mat-card-title>
      {{ 'LABEL.BUILDING' | translate }}
      <button *ngIf="checkVisibility('reload_building')" class="my-bounce btn btn-primary mx-2"
        style="background-color:transparent;" (click)="clearForm('building')">
        <mat-icon class="material-icons" style="color:#293683;">restore_page</mat-icon>
      </button>
    </mat-card-title>
    <div>
      <div formGroupName="building" class="form-container">
        <app-fulltextsearch2 *ngIf="checkVisibility('search')" class="form-subject" [service]="buildingService"
          searchType="Edificio" (selected)="selSubject($event)" [edit]="verifyDeactivation('search')"></app-fulltextsearch2>

        <app-address-search *ngIf="checkVisibility('address_search') && this.form" [multiCompany]="true"
          [mainForm]="this.form" [businessLineCode]="this.form.get('businessLineCode')?.value" [form]="buildingForm"
          class="form-subject" [edit]="verifyDeactivation('address_search')"
          [noButtonSearchCompany]="true"></app-address-search>

        <app-address-detail-form *ngIf="checkVisibility('address_detail')" [form]="buildingForm" class="form-subject"
          [disabled]="verifyDeactivation('address_detail')"></app-address-detail-form>

        <br>

        <mat-form-field appearance="fill" class="form-subject" *ngIf="checkVisibility('contactChannel')">
          <mat-label>{{ 'REQUEST.CHANNEL' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="contactChannel">
            <mat-option *ngFor="let cc of contactChannels$ | async" [value]="cc.name">

              {{ 'CHANNEL.'+cc.name | translate }}

            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-subject" *ngIf="checkVisibility('origin')">
          <mat-label>{{ 'REQUEST.ORIGIN' | translate }}</mat-label>
          <mat-select formControlName="origin">
            <mat-option *ngFor="let origin of origins" [value]="origin.origin" [disabled]="origin.disabled">
              {{ 'ORIGINS.'+origin.code | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="checkVisibility('company')" appearance="fill" class="form-subject margin-top">
          <mat-label>{{ 'REQUEST.COMPANY' | translate }}</mat-label>
          <mat-select matNativeControl formControlName="company">
            <mat-option *ngFor="let company of companies" [value]="company.code">
              {{company.code}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="checkVisibility('find_company')" appearance="fill" class="form-subject margin-top">
          <button mat-button color="primary" (click)="cercaZonaJaka()" [disabled]="verifyDeactivation('find_company')"
            class="with-icon findCompanyButton">
            <mat-icon>search</mat-icon> <span>{{ 'ADDRESS.FIND_COMPANY' | translate }}</span>
          </button>
        </div>

      </div>
    </div>
  </mat-card>
</form>