export const localeFr: any = {

    localeName: 'Fr',
    localeDesc: 'Français (Fr)',
    localeCode: 'fr-FR',

    Object: {
        Yes: 'Oui',
        No: 'Non',
        Cancel: 'Annuler',
        Ok: 'OK',
        Week: 'Semaine',
        newEvent: 'Nouvel événement'
    },

    TimePicker: {
        hour: 'Heure',
        minute: 'Minute',
        second: 'Seconde'
    },

    List: {
        loading: 'Chargement...',
        selectAll: 'Sélectionner tout'
    },

    Popup: {
        close: 'Fermer'
    },

    DateHelper: {
        locale: 'fr-FR',
        weekStartDay: 1,
        nonWorkingDays: {
            0: true,
            6: true
        },
        weekends: {
            0: true,
            6: true
        },
        unitNames: [
            { single: 'milliseconde', plural: 'ms', abbrev: 'ms' },
            { single: 'seconde', plural: 'secondes', abbrev: 's' },
            { single: 'minutes', plural: 'minutes', abbrev: 'min' },
            { single: 'heure', plural: 'heures', abbrev: 'h' },
            { single: 'jour', plural: 'jours', abbrev: 'd' },
            { single: 'semaine', plural: 'semaines', abbrev: 'w' },
            { single: 'mois', plural: 'mois', abbrev: 'mon' },
            { single: 'quarter', plural: 'quarters', abbrev: 'q' },
            { single: 'année', plural: 'années', abbrev: 'yr' },
            { single: 'décennie', plural: 'décennies', abbrev: 'dec' }
        ],
        unitAbbreviations: [
            ['mil'],
            ['s', 'sec'],
            ['m', 'min'],
            ['h', 'hr'],
            ['d'],
            ['w', 'wk'],
            ['mo', 'mon', 'mnt'],
            ['q', 'quar', 'qrt'],
            ['y', 'yr'],
            ['dec']
        ],
        parsers: {
            L: 'DD/MM/YYYY',
            LT: 'HH:mm A',
            LTS: 'HH:mm:ss A'
        },
    },

    CellMenu: {
        removeRow: 'Supprimer'
    },

    RowCopyPaste: {
        copyRecord: 'Copier',
        cutRecord: 'Couper',
        pasteRecord: 'Coller',
        rows: 'lignes',
        row: 'ligne'
    },

    CellCopyPaste: {
        copy: 'Copier',
        cut: 'Couper',
        paste: 'Coller'
    },

    EventEdit: {
        Name: 'Nom',
        Resource: 'Ressources',
        Start: 'Début',
        End: 'Fin',
        Save: 'Enregistrer',
        Delete: 'Supprimer',
        Cancel: 'Annuler',
        'Edit event': 'Modifier',
        Repeat: 'Répéter'
    },

    SchedulerBase: {
        'Add event': 'Ajouter',
        'Delete event': 'Supprimer',
        'Unassign event': 'Désassigner',
        color: 'Couleur'
    },

    TimeAxisHeaderMenu: {
        pickZoomLevel: 'Zoom',
        activeDateRange: 'Range Date',
        startText: 'Date de début',
        endText: 'Date de fin',
        todayText: "Aujourd'hui"
    },

    EventCopyPaste: {
        copyEvent: 'Copier',
        cutEvent: 'Couper',
        pasteEvent: 'Coller'
    },

    EventFilter: {
        filterEvents: 'Filtrer',
        byName: 'par nom'
    },

    PresetManager: {
        secondAndMinute: {
            displayDateFormat: 'll LTS',
            name: 'Secondes'
        },
        minuteAndHour: {
            topDateFormat: 'ddd DD/MM, hA',
            displayDateFormat: 'll LST'
        },
        hourAndDay: {
            topDateFormat: 'ddd DD/MM',
            middleDateFormat: 'LST',
            displayDateFormat: 'll LST',
            name: 'Jour'
        },
        day: {
            name: 'Jour/Heures'
        },
        week: {
            name: 'Mois/Heures'
        },
        dayAndWeek: {
            displayDateFormat: 'll LST',
            name: 'Mois/Jours'
        },
        dayAndMonth: {
            name: 'Mois'
        },
        weekAndDay: {
            displayDateFormat: 'll LST',
            name: 'Semaine'
        },
        weekAndMonth: {
            name: 'Semaines'
        },
        weekAndDayLetter: {
            name: 'Semaines/Fin de semaine'
        },
        weekDateAndMonth: {
            name: 'Mois/Semaines'
        },
        monthAndYear: {
            name: 'Mois'
        },
        year: {
            name: 'Années'
        },
        manyYears: {
            name: 'Années multiples'
        }
    },

}