import { Component, OnInit } from '@angular/core';
import { NavService } from '../services/nav.service';
import { AuthService } from '../auth/auth.service';
import { TranslatorService } from '../services/translate.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-paychecks-per-ten',
  templateUrl: './paychecks-per-ten.component.html',
  styleUrls: ['./paychecks-per-ten.component.scss']
})
export class PaychecksPerTenComponent implements OnInit {
  user: any;
  title = "Cedolini al 10";
  subTitle = "EDAC - Cedolini al 10";
  breakpoint: any = 3

  constructor(
    public navService: NavService,
    public translate: TranslatorService,
    private authService: AuthService,
    private router: Router,
  ) { }


  ngDoCheck() { }

  loadLabels() {
    this.translate.get('LABEL.CEDOLINOAL10').subscribe(res => {
      this.title = res;
      this.subTitle = "EDAC - " + res;
    })
  }

  ngOnInit(): void {
    this.user = this.authService.user();
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions: any;
    actions = [
    ];
    this.navService.setActions(actions);
    this.breakpoint = (window.innerWidth <= 600) ? 1 : 3;
  }

  onResize(event: any) {
    this.breakpoint = (event.target.innerWidth <= 600) ? 1 : 3;
  }

  goTo(url: any) {
    this.router.navigate([url]);
  }

}


