import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Event } from '../../../../models/event';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../services/translate.service';

@Component({
  selector: 'add-event-dialog',
  templateUrl: './add-event-dialog.component.html',
  styleUrls: ['./add-event-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEventDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AddEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public translate: TranslatorService,
    private toastr: ToastrService,
  ) { }

  defaultDate: any = moment().utc(true).toDate()


  form = this.fb.group({
    title: ['', Validators.required],
    description: '',
    note: '',
    tsStart: [this.defaultDate, [Validators.required]],
    tsEnd: [this.defaultDate, [Validators.required]],
    eventTypeId: [null, Validators.required],
    eventCategoryId: [null, Validators.required],
    practiceId: [null, Validators.required],
  },
    { validator: this.dateRangeValidator }
  ) as FormGroup<AddEventForm<AddEventFormData>>;
  onSubmit() {

    let tsStart: any = this.form.value.tsStart
    let tsEnd: any = this.form.value.tsEnd

    let tsStartMoment = moment(tsStart).utc(true).hours(0).minutes(0).seconds(0).milliseconds(0)
    let tsEndMoment = moment(tsEnd).utc(true).hours(23).minutes(59).seconds(59).milliseconds(59)

    // Verifica che la data di inizio sia minore della data di fine

    if (tsStartMoment.isBefore(tsEndMoment)) {
      const event: Event = {
        title: this.form.value.title!,
        description: this.form.value.description,
        note: this.form.value.note,
        tsStart: tsStartMoment.format(),
        tsEnd: tsEndMoment.format(),
        EventTypeId: this.form.value.eventTypeId,
        EventCategoryId: this.form.value.eventCategoryId,
        AuthorId: 1,
        PracticeId: this.form.value.practiceId!,
      };
      this.dialogRef.close(event);
    } else {
      this.form?.get('tsStart')?.setErrors({ 'incorrect': true });
      this.form?.get('tsEnd')?.setErrors({ 'incorrect': true });
      this.toastr.error(this.translate.instant('LABEL.DATA_ERROR'), this.translate.instant('TOASTR.WARNING'));
    }

  }

  public dateRangeValidator(control: AbstractControl): ValidationErrors | null {

    if (!control.get('tsStart')?.value || !control.get('tsEnd')?.value)
      return null;

    // NOTE Compare fields
    let tsStart: any = control?.get('tsStart')?.value
    let tsEnd: any = control?.get('tsEnd')?.value
    let tsStartMoment = moment(tsStart).utc(true).hours(0).minutes(0).seconds(0).milliseconds(0)
    let tsEndMoment = moment(tsEnd).utc(true).hours(23).minutes(59).seconds(59).milliseconds(59)
    const isValid = tsStartMoment.isBefore(tsEndMoment)



    // NOTE Invalid
    if (!isValid) {
      control?.get('tsStart')?.setErrors({ notEquivalent: true })
      control?.get('tsEnd')?.setErrors({ notEquivalent: true })
      return { invalidDateRange: true };
    } else {
      control?.get('tsStart')?.setErrors(null)
      control?.get('tsEnd')?.setErrors(null)
    }

    // NOTE Valid
    return null;
  }

  ngOnInit(): void {

    if (this.data.source) {
      let source: any = this.data.source

      let endDate: any = moment(source.tsEnd).utc(false).hours(0).minutes(0).seconds(0).milliseconds(0).toDate()

      this.form?.get('title')?.setValue(source.title);
      this.form?.get('description')?.setValue(source.description);
      this.form?.get('note')?.setValue(source.note);
      this.form?.get('tsStart')?.setValue(source.tsStart);
      this.form?.get('tsEnd')?.setValue(endDate);
      this.form?.get('eventTypeId')?.setValue(source.eventType.id);
      this.form?.get('eventCategoryId')?.setValue(source.eventCategory.id);
      this.form?.get('practiceId')?.setValue(source.practice.id);
    }
  }

  dateValidator(control: AbstractControl): any {
    console.log(control)
    let tsStart: any = control?.get('tsStart')?.value
    let tsEnd: any = control?.get('tsEnd')?.value

    console.log(tsStart)
    console.log(tsEnd)

    if (tsStart && tsEnd) {

      let tsStartMoment = moment(tsStart).utc(true).hours(0).minutes(0).seconds(0).milliseconds(0)
      let tsEndMoment = moment(tsEnd).utc(true).hours(23).minutes(59).seconds(59).milliseconds(59)

      if (tsStartMoment.isBefore(tsEndMoment)) {
        return { 'invalidDate': true }
      }
    }
    return null;
  }

  testDate() {

  }

}

interface AddEventFormData {
  title: string;
  description: string;
  note: string;
  tsStart: Date;
  tsEnd: Date;
  eventTypeId: number;
  eventCategoryId: number;
  practiceId: number;
}
interface AddEventForm<T extends AddEventFormData> {
  title: FormControl<T['title']>;
  description: FormControl<T['description']>;
  note: FormControl<T['note']>;
  tsStart: FormControl<T['tsStart']>;
  tsEnd: FormControl<T['tsEnd']>;
  eventTypeId: FormControl<T['eventTypeId']>;
  eventCategoryId: FormControl<T['eventCategoryId']>;
  practiceId: FormControl<T['practiceId']>;
}
