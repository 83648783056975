import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WikiService {

  baseUrlWiki = environment.baseUrlWiki;
  codes;

  constructor() {
    this.codes = {
      CRM: "bin/view/Main/Operazioni/2.%20CRM/",
      CRMSOGG: "bin/view/Main/Operazioni/2.%20CRM/Soggetti/",
      CRMREQ: "bin/view/Main/Operazioni/2.%20CRM/Richieste/",
      CRMACT: "bin/view/Main/Operazioni/2.%20CRM/Attività/",
      CRMCLAIMS: "bin/view/Main/Operazioni/2.%20CRM/Sinistri/"
    };
  }

  getWikiUrl(code: string): any {
    switch (code) {
      /*
      case 'CRUSH':
        return this.baseUrlWiki + this.codes[code];
      case 'PRH':
        return this.baseUrlWiki + this.codes[code];
      */
      case 'CRM':
        return this.baseUrlWiki + this.codes[code];
      case 'CRMSOGG':
        return this.baseUrlWiki + this.codes[code];
      case 'CRMREQ':
        return this.baseUrlWiki + this.codes[code];
      case 'CRMACT':
        return this.baseUrlWiki + this.codes[code];
      case 'CRMCLAIMS':
        return this.baseUrlWiki + this.codes[code];
      default: 
        return null;
    }

  }
}
