

<h3 > {{ 'LABEL.Documenti' | translate }}</h3>


<div class="row g-6 g-xl-9" *ngIf="documents && documents.length > 0">
  <div class="col-md-6 col-xl-4" *ngFor="let document of documents">
    <!--begin::Card-->
    <div style="background-color:rgb(228, 223, 223);" class="card h-100">
      <!--begin::Card body-->
      <div class="card-body p-9" fxLayout="row" fxLayoutAlign="space-between center">
        <div >
          <div *ngIf="!(document == null)" class="fs-3 mb-7">
            <span style="font-size: 16px;"><b>{{document.name}}</b><br></span>
            <span *ngIf="!(document.typeName == null)" matTooltip="{{'FILE-UPLOAD.DOCUMENT-TYPE' | translate}}" style="font-size: 12px;"><b>{{'LABEL.' + document.typeName!.uiLabel | translate}}</b></span>
            <span *ngIf="(document.typeName == null)" matTooltip="{{'FILE-UPLOAD.DOCUMENT-TYPE' | translate}}" style="font-size: 12px;"><b>{{'LABEL.NULL_DOCUMENT_TYPE' | translate}}</b></span>
            <span *ngIf="document.esit == true" style="color:green;"><br>{{'FILE-UPLOAD.DOCUMENT-ESIT-POSITIVE' | translate}}</span>
            <br><span style="font-size: 12px;">{{document.userUploader}}</span>
            <br><span style="font-size: 12px;">{{document.createdAt | date: 'dd/MM/yyyy'}}</span>
            <br><span style="font-size: 12px;">{{document.description}}</span>
          </div>
          <div class="d-flex">
            <a class="btn btn-primary btn-sm me-3 text-dark" (click)="downloadDocument(document)">{{ 'DOCUMENTS.DOWNLOAD' | translate }}</a>
          </div>

        </div>
        <!-- <div class="preview-container">
          <img class="preview-dimentions" *ngIf="document.type!.includes('image')" [src]="document.sourceUrl! | async">
          <mat-icon class="preview-icon-dimentions" *ngIf="!document.type!.includes('image')" >insert_drive_file</mat-icon>
        </div> -->
      </div>
    </div>
  </div>
</div>
