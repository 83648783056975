<div class="full-w" fxLayout="column" fxLayoutAlign="center center" *ngIf="form && checkComponent()">

  <div class="documentHintBoxFlex">
    <div class="documentHintBox" *ngFor="let item of structureOfNecessaryDocuments() | keyvalue">

      <h3>{{'request_document_manager.' + item.key | translate}}</h3>
      <p>
        {{'DOCUMENTMANAGER.totaleFileCaricati' | translate}}: <strong>{{item.value.total}}</strong><br>
        {{'DOCUMENTMANAGER.documentiMinimiRichiesti' | translate}}: <strong>{{item.value.min}}</strong><br>
        {{'DOCUMENTMANAGER.documentiMassimiRichiesti' | translate}}: <strong>{{item.value.max ? item.value.max : '-' }}</strong><br>
        {{'DOCUMENTMANAGER.statoDelCaricamento' | translate}}: <mat-icon class="material-icons"
          [ngClass]="getColor(item.value.status)">{{getIcon(item.value.status)}}</mat-icon>
      </p>

    </div>
  </div>

  <p *ngIf="!checkBuilding()" style="color:#f44336;font-weight: 900;">
    {{'LABEL.edificioNecessario' | translate}}
  </p>

  <div fxLayout="row">
    <!--
    <div class="card-title flex-column mrg">
      <h2 class="mb-1">{{'LABEL.HINT' | translate}}</h2>
      <div [ngClass]="getClassWorkflow(1)"><mat-icon class="material-icons" [ngClass]="getClassIconWorkflow(1)">circle</mat-icon>&nbsp;&nbsp;{{'LABEL.HINT_REQUEST' | translate}}</div>
    </div>
    <br>
  -->
    <div style="margin:5px; width: 100%;">
      <div class="photo-block">
        <div class="photo-button-block">
          <button class="my-bounce btn btn-primary fx-button aln" (click)="openUploadDialog('new')"
            [disabled]="!checkBuilding()">
            <mat-icon class="material-icons" style="color:#293683;">upload</mat-icon><span
              style="color:#293683;">{{'LABEL.Carica' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
    <br />
  </div>

  <app-documents-grid fxFill [documents]="documents"></app-documents-grid>
</div>