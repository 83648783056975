import { ActivatedRoute, Router } from '@angular/router';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import * as QuicksightEmbedding from 'amazon-quicksight-embedding-sdk';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { forkJoin, map, of, switchMap, tap } from 'rxjs';
import { NavService } from '../services/nav.service';
import { TranslatorService } from '../services/translate.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dashboardId: any;
  dashboard: any;
  showSpinner = true;
  title = "Dashboard";
  subTitle = "EDAC - Dashboard";

  dashboardId$ = this.route.paramMap.pipe(
    map(param => param.get('id')),
    tap(id => this.dashboardId = id)
    //switchMap(id => id? this.dashboardService.getDashboard(id): of(null))
  )

  dashboardUrl$ = this.dashboardId$.pipe(
    switchMap(dashboardId => {
      /* if(dashboardId === '100'){
        return this.dashboardService.getDashboardUrlNew()
      } */
      return dashboardId? this.dashboardService.getDashboardUrl(dashboardId): of(null)
    })
  )

  constructor(
    public navService: NavService,
    private router: Router,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private translate: TranslatorService) {}

    loadLabels(){
      this.translate.get('LABEL.DASHBOARD').subscribe(res => {
        this.title = res;
        this.subTitle = "EDAC - " + res;
      })
    }

  ngOnInit() {
    this.loadLabels();
    this.navService.setTitle(this.title);
    this.navService.setSubTitle(this.subTitle);
    this.navService.setWikiKey('NODEF');
    let actions : any;
    actions = [
    ];
    this.navService.setActions(actions);
    this.dashboardUrl$.subscribe(res=>{
      console.log(res);
      this.getDashboard(res['url'])
    })

    forkJoin([this.dashboardUrl$, this.dashboardId$]).subscribe((res) => {
      console.log(res);

      /* this.dashboardId = dashboardId;
      this.getDashboard(dashboardUrl['url']) */
    })

    /* this.dashboard$.subscribe(dashboard => {
      this.dashboard = dashboard;
      this.getDashboard(environment.lambdaBaseUrl+ 'get-qs-embed-url-anon' + `?dashboardId=${dashboard?.dashId}&qsCountry=${dashboard?.country}&user=`)
    }) */
    // if(this.dashboardUrl) {
    //   this.getDashboard(this.dashboardUrl)
    // }
  }

  /* ngOnChanges(changes: SimpleChanges): void {
    if(changes['dashboardUrl'].currentValue && changes['dashboardUrl'].currentValue !== changes['dashboardUrl'].previousValue) {
      console.log('dashboardUrl', this.dashboardUrl)
      this.getDashboard(this.dashboardUrl)
    }
  } */

  public getDashboard(embeddedURL: any) {
    this.dashboard = null;
    this.showSpinner = true;
    let containerDiv = document.getElementById("dashboardContainer");
    containerDiv!.textContent = '';
    const options = {
      loadCallback: this.hideSpinner(),
      url: embeddedURL,
      container: containerDiv,
      scrolling: "yes",
      height: "AutoFit",
      width: "100%",
      printEnabled: true
    };
    this.dashboard = QuicksightEmbedding.embedDashboard(options);
  }

  hideSpinner(){
    console.log("Fully loaded Dashboard");
    this.showSpinner = false;
  }

  onLogout(): void {
    let poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.cognitoAppClientId
    };
    let userPool = new CognitoUserPool(poolData);
    let cognitoUser = userPool.getCurrentUser();
    cognitoUser?.signOut();
    this.router.navigate(["signin"])
  }
}
