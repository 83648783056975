<div class="container-fluid">
    <div class="search-wrapper mat-elevation-z2">
        <div class="search" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'animate' : true }">search</mat-icon>
            <input type="text" [(ngModel)]="kwSearch" [value]="kwSearch" (ngModelChange)="search($event)"
                placeholder="{{ 'LABEL.SEARCH' | translate }}" />
        </div>
    </div>
    <div style="overflow-x: auto;">
        <table mat-table [dataSource]="dataSource" matSort style="width:100%">

            <ng-container matColumnDef="AREA_MANAGER">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.AREA_MANAGER' | translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.AREA_MANAGER}} </td>
            </ng-container>
           
            <ng-container matColumnDef="CESSIONE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.CESSIONE'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.CESSIONE}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="CODICE_CLIENTE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.CODICE_CLIENTE'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.CODICE_CLIENTE}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="COMMESSA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.COMMESSA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.COMMESSA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="DATA_SCADENZA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.DATA_SCADENZA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.DATA_SCADENZA | date}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="DATA_ULTIMO_INCASSO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.DATA_ULTIMO_INCASSO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.DATA_ULTIMO_INCASSO | date}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="DESCRIZIONE_RATA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.DESCRIZIONE_RATA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.DESCRIZIONE_RATA }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="DataDocumento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.DataDocumento'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.DataDocumento | date}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="IDFATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.IDFATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.IDFATTURA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="IDSTATO_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.IDSTATO_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.IDSTATO_FATTURA}}</b></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="ID_CLIENTE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ID_CLIENTE'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.ID_CLIENTE}} </td>
            </ng-container>
            <ng-container matColumnDef="ID_RESPONSABILE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ID_RESPONSABILE'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.ID_RESPONSABILE}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ID_RESPONSABILE_CREDITO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ID_RESPONSABILE_CREDITO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.ID_RESPONSABILE_CREDITO}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ID_SEDE_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ID_SEDE_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.ID_SEDE_FATTURA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ID_SOCIETA_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ID_SOCIETA_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.ID_SOCIETA_FATTURA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ID_STATO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.ID_STATO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.ID_STATO}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="IVA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.IVA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.IVA }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="NOME_CLIENTE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.NOME_CLIENTE'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.NOME_CLIENTE}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="NOME_SEDE_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.NOME_SEDE_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.NOME_SEDE_FATTURA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="NOME_SOCIETA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.NOME_SOCIETA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.NOME_SOCIETA}}</b></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="NOME_SOCIETA_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.NOME_SOCIETA_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element">
                    {{element.NOME_SOCIETA_FATTURA}} </td>
            </ng-container>
            <ng-container matColumnDef="NUMERO_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.NUMERO_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.NUMERO_FATTURA}}</b></span>
                </td>
            </ng-container>


            <ng-container matColumnDef="PDF">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.PDF'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.PDF}}</b></span>
                </td>
            </ng-container>

     <ng-container matColumnDef="RESPONSABILE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.RESPONSABILE'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.RESPONSABILE}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="RESPONSABILE_CREDITO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.RESPONSABILE_CREDITO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.RESPONSABILE_CREDITO}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="STATO">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.STATO'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.STATO}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TIPO_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TIPO_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.TIPO_FATTURA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TIPO_SOCIETA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TIPO_SOCIETA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.TIPO_SOCIETA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TOTALE_FATTURA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TOTALE_FATTURA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.TOTALE_FATTURA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TOTALE_INCASSI">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TOTALE_INCASSI'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.TOTALE_INCASSI}}</b></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TOTALE_NC">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TOTALE_NC'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.TOTALE_NC}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TOTALE_RITENUTA">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TOTALE_RITENUTA'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span>{{element.TOTALE_RITENUTA}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="TipoDocumento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="back-table">{{'LABEL.TipoDocumento'|translate}}</th>
                <td class="tdg" mat-cell *matCellDef="let element"> <span><b>{{element.TipoDocumento}}</b></span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="back-table"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="10" [pageSize]="10" [pageSizeOptions]="[10, 20, 30]">
    </mat-paginator>
</div>

